
.fadedDivsWithNoClick {
  opacity: 0.6;
  pointer-events: none;
}

.reqModalHeader {
  display: flex;
  justify-content: space-between;
  /* background-color: blue; */
  margin: 0 auto;
  max-width: 90%;
  min-width: 90%;
  margin-top: 5px;
  min-height: 50px;
  max-height: 50px;
  align-items: center;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.closeReqModalImg {
  min-width: 30px;
  padding: 7px;
  margin-left: 0px;
  /* background-color: blue; */
  cursor: pointer;
}

.closeReqModalImg:hover {
  opacity: 0.8;
}

.reqLeaveTitle {
  color: #213855;
  font-size: 1.3em;
  font-weight: 500;
  margin-left: -20px;
}

.reqDial {
  width: 25px;
  margin-top: 4px;
  visibility: hidden;
}

.reqScrollableContainer {
  /* background-color: pink; */
  min-width: 340px;
  /* min-width: 100%; */
  /* overflow: scroll; */
  /* overflow-x: visible; */
  /* border-top: 2px #e6eaee solid; */
  /* position: absolute; */
  /* top: 284px; */
  /* padding-top: 8px; */
  /* margin-top: 14px; */
  /* border-radius: 12px 12px 0 0; */
  /* bottom: 60px; */
  max-height: calc(100% - 300px);
  /* padding-bottom: 45px; */
  /* animation-name: fadeModalIn !important;
  animation-duration: 1s !important; */
}

.requestPage1 {
  /* background-color: lime; */
  min-height: 380px;
}

.datePickerContainer {
  /* background-color: orange; */
}

.previewContainer {
  /* background-color: orange; */
  max-width: 90%;
  margin: 0 auto;
  height: 50px;
}

.reqDateRangeTxt {
  /* color: #d9e4f3; */
  color: #07f;
  /* color: white; */
  font-weight: 500;
  font-size: 16px;
}

.reqLikelyTxt {
  color: #143051;
  margin-top: 6px;
  font-size: 14px;
  font-weight: 500;
}

.alreadyOffTxt {
  color: #c1cfe0;
  margin-top: 8px;
  font-weight: 500;
}

.likely {
  font-weight: 400;
  color: #a8cc9a;
}

.unlikely {
  font-weight: 400;
  color: #fb8888;
}

.typeContainer {
  /* background-color: purple; */
  margin-top: -12px;
margin-bottom:-6px;
  justify-content: space-between;
  /* min-height: 44px; */
}

.typeTxt {
  text-align: left;
  /* margin-left: 5%; */
  margin-top: 9px;
  margin-left:5%;
  font-size: 12px;
  /* color: #c0d6f0; */
  color: #143051;
  font-weight: 500;
  /* font-size: 1.2em; */
}

.typeOnlyTxt {
  margin-left:6%;
  display: none;
}

.typesBox {
  display: block;
  max-width: 100vw;
  align-items: center;
  font-family: "Work Sans";
  color: #143051;
  position: relative;
  top: 14px;
  padding: 0 4px;
  min-height: 49px;
  overflow-x: scroll;
  white-space: nowrap;
}

.typesInnerContainer {
  display: inline-flex;
  justify-content: space-between;
  margin-top: -4px;
  padding-top: 4px;
  float: left;
  padding-right: 20px;
  min-height: 49px;
}

.reqTypeItem {
  min-width: auto;
  padding-left: 14px;
  padding-right: 14px;
  color: #213855;
  font-weight: 300;
  font-size: 1em;
  background-color: #fff;
  /* padding-bottom: 1px; */
  margin-left: 8px;
  height: 35px;
  line-height: 30px;
  border-radius: 5px;
  font-weight: 500;
  border: 1px solid #dde9f9;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selTypeItem {
  background-color: #19a5c8;
  background-color: #0077ff;
  font-weight: 500;
  color: white;
  border: 2px solid #214978;
  line-height: 30px;
}

.reqLoadingGifImg {
  max-width: 120px;
  margin-top: 40px;
  margin-bottom: -13px;
}

.reqModalFooterContainer {
    border-top: 1px solid #dde9f9;
    color: white;
    display: flex;
    margin-left: 5%;
    max-width: 90%;
    max-height: 50px;
    min-height: 50px;
    justify-content: space-between;
    align-items: center;
    padding-top:3px;
 
}

.reqPage1Footer {
  padding-top: 8px;
}

.addNoteTxt {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  padding-left: 4px;
  padding-right: 4px;
  min-height: 30px;
  max-height: 30px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 20px;
}

.addNoteTxt:hover {
  background-color: #bbcada;
}

.reqAndCloseBtns {
  display: flex;
  /* background-color: #fff; */
  /* background-color: blue; */
  /* border-radius: 25px 0 0 20px; */
  /* padding-top: 3px;
  padding-bottom: 5px; */
  /* min-width: 50%; */
  justify-content: flex-end;
  align-items: center;
  /* margin-right: 5%; */
  /* min-height: 36px; */
  /* font-size: em; */
  font-weight: 500;
  /* min-width: 180px; */
}

.reqAndCloseBtnsPage2 {
  padding-top: 6px;
}

.reqBtn {
  background-color: #07f;
  border: #0469DD 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  font-weight: 500;
  /* line-height: 33px; */
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 0px;
  max-width: 67px;
  min-width: 75px;
  cursor: pointer;
}

.reqBtn:hover {
  background-color: #0469de;
}

.loadingReqSubmission {
  height: 36px;
  font-weight: 500;
  line-height: 36px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 30px;
  font-size: 0.9em;
  margin-top: 0px;
  max-width: 97px;
  min-width: 97px;
}

.reqLoaderImg {
  min-width: 40px;
  max-width: 40px;
}

.closeReqTxt {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 30px;
  margin-top: 3px;
}

.closeReqTxt:hover {
  background-color: #214978;
}
.datePickerTitleAndAMPMBtnContainer {
  /* background-color: lime; */
  padding-top: 15px;
  padding-bottom: 3px;
  display: flex;
  justify-content: space-between;
}

.reqAmPmBtn {
  margin-right: 5%;
  /* background-color: #2?;/;14978; */
  height: 28px;
  line-height: 28px;
  margin-top: -0px;
  color: white;
  font-size: 0.75em;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 30px;
}

.reqAmPmBtnFaded {
  margin-right: 5%;
  /* background-color: #214978; */
  height: 28px;
  opacity: 0.4;
  pointer-events: none;
  line-height: 28px;
  margin-top: -0px;
  color: white;
  font-size: 0.75em;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 30px;
}

.calHolder {
  /* background-color: yellow; */
  /* min-height: 500px; */
  max-width: 414px;
}

.vanillaDatePickerTable {
  color: white;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
  table-layout: fixed;
  padding-bottom: 24px;
  /* border-collapse: collapse; */
}

#dayNameRowDatePicker {
  height: 40px;
  line-height: 40px;
}

.dayNameTxt {
  font-weight: 500;
  font-size: 1em;
  opacity: 0.6;
}

.vanDPRow {
  /* background-color: black; */
  margin-top: 10px;
}

#table-body {
  color: white;
  /* background-color: blue; */
  table-layout: fixed;
}

.block {
  height: 30px;
  width: 14%;
  border: none;
  /* background-color: yellow; */
  cursor: pointer;
  font-weight: 500;
}

.datePickerMonthYearCaption {
  /* background-color: black; */
  display: flex;
  justify-content: space-between;
}

.dpMmonth {
  text-align: left;
  color: #00ccff;
  /* margin-top: 24px; */
  /* padding-bottom: 4px; */
  /* margin-left: 10%; */
  font-weight: 500;
  font-size: 1.2em;
  margin-top: 18px;
  margin: 18px auto;
}

.typeDropdown {
  max-width: 200px;
  min-width: 200px;
  background: url("../img/general/downArrowWhite.svg") no-repeat right #516b8b;
  -webkit-appearance: none;
  background-position-x: 175px;
  border-radius: 100px;
  color: white;
  padding-left: 14px;
  font-size: 0.9em;
  /* text-align: right; */
  height: 40px;
  border: 1px solid #143051;
  margin-right: 5%;
}

.weekendBlock {
  background-color: #213855;
}

.reqBtnNext {
  background-color: #0bf;
  border: 1px solid #0af;
  height: 35px;
  font-weight: 500;
  /* line-height: 34px; */
  font-size:14px;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 5px;
  /* font-size: 0.9em; */
  /* margin-top: 0px; */
  /* max-width: 84px;
  min-width: 84px; */
  /* padding-bottom: 1px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-right: 7px; */
  /* margin-left: 2px; */
  max-width: 62px;
  min-width: 62px;
  cursor: pointer;
}

.reqBtnNext:hover {
  background-color: #0af;
}
.reqPageKey {
  /* background-color: yellow; */
  display: flex;
  justify-content: center;
  margin-top: 5px;
  padding-bottom: 5px;
}

.page1Bullet {
  min-height: 4px;
  max-height: 4px;
  max-width: 4px;
  min-width: 4px;
  background-color: #8cabd1;
  max-width: 4px;
  border-radius: 100%;
  margin-right: 3px;
}

.page2Bullet {
  min-height: 4px;
  min-width: 4px;
  background-color: #8cabd1;
  max-width: 4px;
  border-radius: 100%;
  margin-left: 3px;
}

.pageBulletSelected {
  /* background-color: white; */
  background-color: #07f;
  max-width: 4px;
  min-width: 4px;
}

.dpEndPointStart {
  background-color: white;
  color: #143051;
  border-radius: 10px 0px 0px 10px;
}
.startHalf {
  background-color: white;
  background: linear-gradient(45deg, #35557a 50%, #fff 50%);
  color: #143051;
  border-radius: 10px 0px 0px 10px;
}

.startHalfSolo {
  background-color: yellow;
  /* background: linear-gradient(45deg, #35557a 50%, #fff 50%); */
  color: #143051;
  /* border-radius: 10px 10px 10px 10px; */
}

.endHalf {
  background-color: white;
  background: linear-gradient(45deg, #fff 50%, #35557a 50%);
  color: #143051;
  border-radius: 0px 10px 10px 0px;
}

.endHalfDud {
  background-color: white;
  background: linear-gradient(45deg, #35557a 50%, #fff 50%);
  color: #143051;
  border-radius: 0px 10px 10px 0px;
}

.endHalfSolo {
  background-color: yellow;
  /* background: linear-gradient(45deg, #fff 50%, #35557a 50%); */
  color: #143051;
  /* border-radius: 10px 10px 10px 10px; */
}

.dpEndPointEnd {
  background-color: white;
  color: #143051;
  border-radius: 0px 10px 10px 0px;
}

.dpEndPointBoth {
  background-color: white;
  color: #143051;
  border-radius: 10px;
}

.dpBetween {
  background-color: white;
  color: #143051;
}

.dudStart {
  background-color: #516b8b;
  border-radius: 10px 0px 0px 10px;
}

.dudEnd {
  background-color: #516b8b;
  border-radius: 0px 10px 10px 0px;
}

.dudEnd {
  background-color: #516b8b;
  border-radius: 0px 10px 10px 0px;
}

.dudBetween {
  background-color: #516b8b;
  /* border-radius: 10px 0px 0px 10px; */
}

.dudBoth {
  background-color: #516b8b;
  border-radius: 10px;
}
.startHalfStartDud {
  background-color: #516b8b;
  /* background: linear-gradient(45deg, #35557a 50%, #fff 50%); */
  color: orange;
  border-radius: 10px 0px 0px 10px;
}
.startHalfEndDud {
  background-color: #516b8b;
  /* background: linear-gradient(45deg, #fff 50%, #35557a 50%); */
  color: orange;
  border-radius: 10px 0px 0px 10px;
}

.endHalfStartDud {
  background-color: #516b8b;
  /* background: linear-gradient(45deg, #35557a 50%, #fff 50%); */
  color: orange;
  border-radius: 0px 10px 10px 0px;
}
.endHalfEndDud {
  background-color: #516b8b;
  /* background: linear-gradient(45deg, #fff 50%, #35557a 50%); */
  color: orange;
  border-radius: 0px 10px 10px 0px;
}

.betweenHalfStartDud {
  background-color: #516b8b;
  /* background: linear-gradient(45deg, #35557a 50%, #fff 50%); */
  color: orange;
  border-radius: 0px;
}
.betweenHalfEndDud {
  background-color: #516b8b;
  /* background: linear-gradient(45deg, #fff 50%, #35557a 50%); */
  color: orange;
  border-radius: 0px;
}

.bothHalfStartDud {
  background-color: #516b8b;
  /* background: linear-gradient(45deg, #35557a 50%, #fff 50%); */
  color: orange;
  border-radius: 10px;
}
.bothHalfEndDud {
  background-color: #516b8b;
  /* background: linear-gradient(45deg, #fff 50%, #35557a 50%); */
  color: orange;
  border-radius: 10px;
}

.endHalfDud {
  background-color: #516b8b;
  /* background: linear-gradient(45deg, #35557a 50%, #fff 50%); */
  color: orange;
  border-radius: 0px 10px 10px 0px;
}

.selectDatesTxt {
  color: #516b8b;
  font-size: 12px;
  margin-top: 0px;
  font-weight: 400;
}

.reqDurationLoaderImg {
  max-width: 30px;
  margin-top: 12px;
}

.notworkinganywaytxt {
  font-size: 0.9em;
  margin-top: 10px;
  color: white;
}

.unlikelyUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999999999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

@keyframes underlayFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.unlikelyModalBox {
  background-color: #496375;
  min-width: 90vw;
  max-width: 90vw;
  /* height: 150px; */
  margin-top: -10vh;
  border-radius: 15px;
  padding-bottom: 15px;
  padding-top: 15px;

  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  color: white;
  text-align: center;
  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
}

.understaffedTitle {
  /* background-color: yellow; */
  font-size: 1.2em;
  font-weight: 500;
  text-align: center;
  padding-bottom: 20px;
  margin-top: 5px;
}

.understaffedInfo {
  /* background-color: yellow; */
  font-size: 0.9em;
  font-weight: 300;
  text-align: center;
  padding-bottom: 20px;
  line-height: 20px;
  max-width: 90%;
  margin: 0 auto;
}

.understaffOKbtn {
  background-color: #00ccff;
  max-width: 60px;
  margin: 0 auto;
  margin-top: 15px;
  border-radius: 15px;
  min-height: 30px;
  line-height: 30px;
}

.understaffedItemsContainer {
  padding-top: 20px;
  padding-bottom: 20px;
  max-height: 200px;
  overflow: scroll;
  background-color: #355064;
}

.understaffedDayItem {
  /* background-color: grey; */
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  font-size: 0.9em;
  justify-content: space-between;
  border-bottom: 1px solid rgb(85, 118, 128);
  height: 35px;
  align-items: center;
}

.understaffedDayItem:last-of-type {
  /* background-color: grey; */
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  font-size: 0.9em;
  justify-content: space-between;
  border: none;
  height: 35px;
  align-items: center;
}

.understaffItemDate {
  font-weight: 400;
}

.understaffItemTeam {
  font-weight: 300;
}

.understaffDivider {
  min-height: 1px;
  min-width: 100%;
  background-color: white;
}

.submitReqAnywayInfo {
  font-size: 0.9em;
  font-weight: 300;
  text-align: center;
  padding-bottom: 0px;
  line-height: 20px;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 20px;
}

.halfTimeModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999999999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

@keyframes underlayFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.halfTimeModalBox {
  background-color: white;
  min-width: 300px;
  max-width: 300px;
  border-radius: 10px;
  padding-bottom: 15px;
  padding-top: 15px;
  color: #143051;
  text-align: center;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.halfTimeModal {
  font-weight: 500;
  font-size: 16px;
  margin-top: 4px;
  padding-bottom: 7px;
}




.valuee {
  margin-right: 5%;
  padding-top: 1px;
}

.alreadyOff {
  margin-right: 7%;
  margin-top: 4px;
  color: #8ea7c5;
  font-weight: 500;
}

.halfTimeDivider {
  background-color: #8ea7c5;
  min-height: 1px;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 2px;
}

.unselHalf {
  color: #355064;
  line-height: 30px;
  min-height: 30px;
  margin-right: 8px;
  font-weight: 500;
}

.selHalf {
  background-color: #143051;
  color: white;
  border-radius: 15px;
  line-height: 30px;
  min-height: 29px;
  padding-top: 6px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 500;
  margin-right: 8px;
}

.startEndLabel {
  margin-right: 18px;
  font-size: 1em;
  font-weight: 500;
  color: #8cabd1;
}

.startEndLabelForFixedVar {
  margin-right: 10px;
  font-size: 0.83em;
  font-weight: 500;
  /* background-color: black; */
  line-height: 8px;
  height: 10px;
  color: #9a74eb;
}

.halfModalFooter {
  min-height: 35px;
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
  align-items: center;
  max-width: 90%;
  margin-left: 5%;
  margin-bottom: -5px;
}

.cancelHalfModal {
  min-width: 70px;
  font-size: 0.9em;
  font-weight: 500;
  color: #50647c;
}
.saveBtnHalfModal {
  background-color: #0bf;
  border: 1px solid #0af;
  font-size:14px;
  color:white;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 35px;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  min-width: 62px;
  max-width: 62px;
}

.saveBtnHalfModal:hover {
  background-color: #0af;
}

.halfStartTime {
  background: #0077ff;
  font-size: 12px;
  color: white;
  border-radius: 4px;
  padding-left: 3px;
  padding-right: 3px;
}

.noteTitleX {
  font-weight: 500;
  color: #143051;
  font-size: 12px;
  margin-top: 12px;
  text-align: left;
  margin-left: 5%;
}
.noteTitleXval {
  color: #516b8b;
  text-align: left;
  font-size: 14px;
  margin-left: 5%;
  margin-bottom:30px;
  line-height: 18px;}

.noteInputModalBox {
  min-width: 90%;
  position: fixed;
  top: 20%;
  /* background-color: yellow; */
}

.nextDisabled {
  opacity: 0.25;
  /* color: #0cf; */
  /* background-color: white; */

  pointer-events: none;
}

.reqNoteBoxInput {
  background-color: rgb(142, 167, 197, 0.1);
  border: 1px solid #8ea7c5;
  border-radius: 30px;
  min-height: 40px;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 10px;
  color: white;
  line-height: 25px;
  padding-top: 0px;
  padding-left: 5%;
}

.addNoteSelected {
  background-color: white;
  min-height: 32px;
  max-height: 32px;
  max-width: 109px;
  min-width: 109px;
  line-height: 32px;
  display: flex;
  justify-content: space-around;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 30px;
  margin-left: 5%;
  margin-top: 10px;
}

.closeNoteImg {
  max-width: 10px;
  margin-right: 2px;
}

.addNoteTxtBtnSel {
  color: #143051;
  line-height: 32px;
}

.addNoteBtn {
  border: 1px solid #8ea7c5;
  max-width: 96px;
  min-height: 30px;
  line-height: 30px;
  border-radius: 30px;
  padding-left: 12px;
  margin: 0 auto;
  padding-right: 8px;
  margin-top: 22px;
}

.removeNoteBtn {
  border: 1px solid #8ea7c5;
  background-color: #0d2f55;
  max-width: 128px;
  min-height: 30px;
  line-height: 30px;
  border-radius: 30px;
  padding-left: 12px;
  margin: 0 auto;
  padding-right: 8px;
  margin-top: 22px;
}

.noteHeader {
  display: flex;
  justify-content: space-between;
}

.noteBtnsBar {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  min-height: 100px;
  margin-top: 8px;
}

.saveNoteBtn {
  background-color: #00ccff;
  border: none;
  color: white;
  padding-top: 5px;
  text-align: center;
  /* margin-right: 5%; */
  padding-right: 12px;
  padding-left: 12px;
  border-radius: 30px;
  margin-top: 5px;
  max-height: 30px;
}

.closeNoteEditor {
  color: white;
  margin-top: 10px;
  padding-left: 10px;
  max-width: 60px;
}

.closeNoteBlank {
  color: white;
  margin-top: 10px;
  margin-right: 20px;
  opacity: 0;
  max-width: 60px;
}

.noteTxt {
  color: white;
  /* background-color: green; */
  text-align: left;
  margin-top: 14px;
  margin-left: 8%;
  max-width: 84%;
  line-height: 25px;
  max-height: 120px;
  overflow: scroll;
  font-size: 0.9em;
  border: 1px solid #8ea7c5;
  border-radius: 15px;
  padding: 10px;
}

.requestPage1 {
}

.requestPage2 {
  max-height: 442px;
  min-height: 442px;
  /* border: 2px solid #172c43; */
  border-radius: 10px 10px 0 0;
  border-left: 1px solid #e7ebef;
  border-right: 1px solid #e7ebef;
  border-top: 2px solid #e7ebef;
  border-bottom: none;
  background-color: #193555;
  background-color: white;
  max-width: 90%;
  margin: 0px auto;
  /* overflow: scroll; */
  position: relative;
  z-index: 1;
}

.allowanceInfoContainer {
  /* overflow: scroll; */
  border: none;
  margin: 0 auto;
  color: #8ea7c5;
  color: white;
  line-height: 25px;
  padding-top: 18px;
  /* padding-bottom: 0px; */
  padding-left: 8px;
  padding-right: 8px;
  /* border-bottom: 1px solid #8ea7c5; */
  /* border-top: 1px solid #8ea7c5; */
  margin-top: 0px;
  /* background-color: lime; */
  max-height: 445px;
  overflow-y: scroll;
  padding-bottom:30px
  /* margin-bottom: 10px; */
}

.noScroll {
  overflow: hidden !important;
}

.showNoteContainer {
  border: none;
  margin: 0 auto;
  color: #8ea7c5;
  color: #213855;
  line-height: 25px;
  padding-top: 18px;
  /* padding-bottom: 0px; */
  padding-left: 20px;
  padding-right: 20px;
  /* border-bottom: 1px solid #8ea7c5; */
  /* border-top: 1px solid #8ea7c5; */
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding-left: 10px; */
}

.enterNoteTitle {
  font-weight: 500;
  color: #213855;
  margin-top: 10px;
}

.reqNoteTextArea {
  margin-top: 5px;
  max-width: 266px;
  min-width: 266px;
  min-height: 250px;
  border-radius: 5px;
  margin-left: -2px;
  resize: none;
  overflow: scroll;
  background-color: #ebf0f7;
  border-color: #c1cfe0;
  padding: 10px;
  color: #213855;
  font-size: 1em;
}
.avgMpdBoldSpan {
  font-weight: 500;
}

.zeroZMinsExplainer {
  margin-top: 10px !important;
  margin-bottom: 12px !important;
}

.zeroDisplayContainer {
  margin-bottom: 4px !important;
}

.zeroTxtSpacerMakeReq {
  min-height: 9px;
  /* background-color: black; */
  min-width: 20px;
}

.remAlowSpan {
  color: #214978;
  font-size: 1.4em;
  font-weight: 500;
  margin-top: 12px;
  text-align: left;
  margin-left: 20px;
  /* margin-bottom: 4px; */
}

.zMinsSplitter {
  min-width: 90%;
  max-width: 90%;
  min-height: 1px;
  max-height: 1px;
  background-color: #8ea7c5;
  margin: 0px auto;
  margin-top: -20px;
}

.noteUnderlay {
  background: rgb(21, 31, 45);
  background: linear-gradient(
    0deg,
    rgba(21, 31, 45, 1) 0%,
    rgba(20, 48, 81, 1) 100%
  );
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999999999999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

@keyframes underlayFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.alreadyExistsUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999999999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.alreadyExistsModalBox {
  background-color: white;
  max-width: 90%;
  border-radius: 15px;
  color: #143051;
  line-height: 20px;
  padding: 20px;
  padding-top: 25px;
}

.okayBtn {
  height: 35px;
  line-height: 35px;
  background-color: #143051;
  border-radius: 30px;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  margin: 0 auto;
  margin-top: 20px;
  max-width: 80px;
}

.calendarLinkSpan {
  color: #00ccff;
}

.ofyourAllow {
  /* margin-top: 14px; */
}

.zMinsDisplayContainer {
  padding-left: 10px;
  border-left: 3px solid #bbcada;
  margin-top: 7px;
  font-size: 20px;
  color: #143051;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: -20px;
  text-align: left;
  margin-left: 27px;
}

.zMinsExplainTxt {
  font-size: 14px;
  line-height: 18px;
  color: #143051;
  max-width: 90%;
  margin: 0px auto;
  text-align: left;
  font-weight: 400;
  padding-bottom: 18px;
  padding-left: 10px;
}

.likeliHoodTxt {
  border-left: 3px solid #bbcada;
  padding-bottom: 0px;
  padding-left: 13px;
  margin-bottom: 26px;}

.allowanceYrBold {
  font-weight:500
}


.ofYourAllowanceTxt {
  font-size: 14px;
  line-height: 22px;
  color: #143051;
  max-width: 90%;
  margin: 0px auto;
  text-align: left;
  font-weight: 400;
  padding-bottom: 10px;
  padding-left: 10px;
  margin-top:30px;
  margin-bottom:-24px;
}

.zMinsExplainMargin20 {
  margin-top: 20px;
}

.overlappingDsArr {
  margin-top: -20px;
  color: #2066b6;
  padding-left: 6px;
  overflow: scroll;
  margin-left: 15px;

}

.overlappingDayOff {
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  align-items: center;
  border-radius: 5px;
  color: #496375;
  font-weight: 500;
  font-size: 14px;
  background-color: #e3e8ee;
  color: #4f566b;
  margin-top: 6px;
  margin-left: 10px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.moveIfApprovedTxtDown {
  margin-top: 18px;
}

.fixedVarHalfWayThruTxt {
  font-size: 0.8em;
}

.fixedVarHalfSelectContainer {
  font-size: 0.9em;
  display: flex;
}

.fixedVarHalfSelectTxtLine1 {
  text-align: right;
  /* margin-top: 7px; */
  font-size: 0.88em;
  color: #516b8b;
}

.fixedVarHalfSelectTxtLine2 {
  text-align: right;
  margin-top: -1px;
  font-size: 0.88em;
  color: #516b8b;
}

.makeTxt00ccff {
  color: #00ccff;
}

.halfWayThruTxtBox {
  /* background-color: yellow; */
  display: block;
  margin-top: 1px;
  margin-right: 12px;
}

.halfStartEndKnob {
  margin-top: 0px !important;
  margin-right: 0px;
  margin-left:8px;
}

.calInputCog {
  position: fixed;
  right: 0px;
  bottom: 100px;
  opacity: 0.8;
  max-width: 40px;
}

.prefThumbsContainer {
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  margin-top: 20px;
  max-width: 80%;
  padding-bottom: 20px;
  padding-top: 15px;
}

/* .inputPrefBottomFooter {
  border-top: 1px solid #8ea7c5;
} */

.inputPrefBottomCloseBtn {
  margin-top: 12px;
  font-weight: 500;
}

.requestDateInputField {


  border-radius: 5px;
  border: 1px solid #e6eaee;
  background-color: #fff;
  color: #496375;
  min-height: 35px;
  font-weight: 500;
  font-size:16px;
  padding-left: 12px;
  padding-right: 8px;
  padding-top: 0px;
  letter-spacing: 1px;
  max-width: 169px;
  min-width: 169px;
  background: url(/static/media/calicongrey.9c7e1aba.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 137px;
  cursor: text;
}




.requestDateInputField:hover {
  border-color: #bac5d3;
}

.invalidReqSubmitBtnFade {
  opacity: 0;
  pointer-events: none;
}

.dateInputterInvalid {
  /* border: 2px solid #ba0000; */
  background: url(/static/media/calicongrey.9c7e1aba.svg) no-repeat right #fff;
  border-color: #fb8888;
  -webkit-appearance: none;
  background-position-x: 130px;
}

.dateIsInvalidTxt {
  color: white;
  font-size: 0.8em;
  margin-top: 5px;
  text-indent: 10px;
}

.hideTxt {
  visibility: hidden;
}

.dateReqInputHolder {
  display: flex;
  margin-top: 4px;
  /* background-color: yellow; */
  overflow-x: visible;
  /* min-width: 110%; */
}

.dateInputUnit {
  /* background-color: orange; */
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  overflow-x: visible;
}

.requestDateInputWrapper {
  /* background-color: black; */
  color: white;
  max-width: 96%;
  margin: 0 auto;
  text-align: left;
  overflow-x: visible;
  margin-top: -10px;
}

.startEndTxtForDateInput {
  padding-bottom: 5px;
  text-indent: 14px;
  font-size: 0.9em;
  /* margin-top: 3px; */
}

/* .startAndClearHolder {
  display: flex;
} */

.inputterHalfShiftContainer {
  display: flex;
  font-size: 0.85em;
  align-items: center;
  /* margin-right: 14px; */
  /* background-color: green; */
  min-width: 100px;
  padding-left: 6px;
  /* color: blue; */
}

.halfShiftInactive {
  /* font-size: */
  color: #8cabd1;
  font-weight: 500;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  text-align: center;
  margin-top: -2px;
  cursor: pointer;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 14px;
}

.halfShiftInactive:hover {
  color: #355064;
  transform: scale(1.05);
}

.halfShiftActive {
  font-weight: 500;
  background-color: #0077ff;
  border: 1px solid #0469de;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 5px;
  min-width: 52px;
  max-width: 52px;
  text-align: center;
  color:white
}

.fadedActive {
  /* background-color: #5d97a5; */
}

/* .halfShiftSpacer {
  background-color: orange;
  min-width: 14px;
} */

.none {
  display: none;
}

.halfWayThruContainerForFixedVarOnly {
  font-size: 0.8em;
  display: flex;
  min-width: 130px;
  /* background-color: yellow; */
  justify-content: flex-end;
  margin-left: -90px;
}

.halfWayThruTxt {
  color: #8ea7c5;
  margin-top: 4px;
  font-weight: 500;
  font-size: 11px;
  min-width: fit-content;
}

.killPaddingRight {
  margin-right: 6%;
}

.startAndEndVerticalDivider {
  min-width: 10px;
  min-height: 14px;
  /* background-color: blue; */
}

.fadeAndDisable {
  opacity: 0.2;
  pointer-events: none;
}

.clearDateInputsRequest {
  font-size: 0.85em;
  /* margin-left: 5%; */
  margin-top: 25px;
  opacity: 0.2;
  pointer-events: none;
  background-color: #fff;
  color: #859dbb;
  max-width: 52px;
  border: 1px solid #e6eaee;
  text-align: center;
  min-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* line-height: 25px; */
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
}

.clearDateInputsRequest:hover {
  border-color: #bac5d3;
}

.fullOpac {
  opacity: 1;
  pointer-events: auto;
}

.excessMinsDisclaimerTxt {
  font-size: 0.85em;
  line-height: 20px;
  margin-top: -20px;
  color: white;
  background-color: #214978;
  padding: 10px;
  border-radius: 15px;
}

.findoutmoreexcessmins {
  font-weight: 500;
  font-size: 0.9em;
  line-height: 33px;
}

.makeReqNoteTitle {
  font-size: 1.2em;
  margin-bottom: 12px;
}

.requestNoteInput {
  /* background-color: yellow; */
  min-width: 90%;
  margin: 0 auto;
  border: none;
  background-color: #1e4572;
  color: white;
  font-size: 1.1em;
  border-radius: 15px;
  border: 1px solid #34659d;
  padding: 15px;
  min-height: 50px;
}

.highlightHalfDayBookedBlock {
  color: orange;
}

.overlappingReqModalUnderlay {
  background: rgba(0, 0, 0, 0.3);
  /* max-height: calc(100% - 58px); */
  height: 100%;
  min-width: 100vw;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999999;
  top: 0;
  left: 0;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.overlappingReqModalBox {
  background-color: #f4f8fe;
  color: #143051;
  border-radius: 5px;
  min-height: 35px;
  min-width: 320px;
  max-width: 320px;
  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  cursor: auto;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  /* border: 2px solid #8ea7c5; */
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 14px;
  padding-right: 14px;
  margin-top: -100px;
}

.overlappingTitle {
  font-weight: 500;
  max-width: 90%;
  padding-left: 6%;
  padding-right: 6%;
  padding-bottom: 18px;
  margin: 5px auto;
  margin-bottom: 20px;
  line-height: 1.5em;
  border-bottom: 1px solid #8997a8;
}

.overlappingDs {
  margin: 10px auto;
  /* margin-top: 10px; */
  background-color: #2066b6;
  border: 1px solid #214978;
  color: #fff;
  min-width: fit-content;
  max-width: fit-content;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 5px;
  font-size: 0.95em;
  font-weight: 500;
  padding-top: 4px;
  padding-bottom: 4px;
}

.overlappingIssueTxt {
  max-width: 90%;
  margin: 20px auto;
  border-top: 1px solid #8997a8;
  padding-top: 18px;
  padding-left: 6%;
  padding-right: 6%;
  font-size: 0.9em;
  line-height: 1.4em;
  color: #516b8b;
  /* text-align: left;  */
}

.moreInfoOverlapsTxt {
  font-size: 0.9em;
  margin-top: 20px;
  color: #516b8b;
}

.moreInfoFoundHereOverlapsSpan {
  font-weight: 500;
  color: #00ccff;
}

.closeOverlapModal {
  font-size: 0.9em;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 2px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  line-height: 26px;
  border-radius: 5px;
  min-height: 30px;
  max-width: 60px;
  /* margin: 28px auto; */
  /* margin-bottom: 6px; */
  cursor: pointer;
  /* margin-bottom: -10px; */
}

.closeOverlapModalContainer {
  /* background-color: yellow; */
  display: flex;
  max-height: 40px;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 30px;
}

.helpButtonOverlapModal {
  font-weight: 500;
  cursor: pointer;
  color: #3b597c;
  font-size: 0.9em;
}

.overlapsSplitter {
  margin-top: 34px;
  margin-bottom: 40px;
}

.estimateTimeYouWillBeP {
  margin-top: 20px;
}

.reqMakerAllowanceBox {
  background-color: #f4f8fe;
  border: 1px solid #e7ebef;
  color: #143051;
  border-radius: 5px;
  max-width: 90%;
  margin: 0px auto;
  margin-top:70px;
  min-height: 92px;
  padding-top: 5px;

}

.reqMakerAllowRow {
  display: flex;
  max-width: 90%;
  margin: 0 auto;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #081d35;
  padding: 7px;
  padding-left: 0px;
}

.reqMakerAllowRow:first-child {
  border-top: none;
}

.reqMakerLeaveTypeName {
  font-size: 14px;

  border-radius: 5px;

  border: 1px solid #e7ebef;
  background-color: #fff;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 12px;
  padding-right: 12px;
  /* margin: 0 auto; */
  margin-top: 20px;
  margin-bottom: -13px;
  position: relative;
  z-index: 2;
  font-weight: 500;
  color: #496375;
  margin-left: 33px;
  min-height: 30px;
  display: flex;
  align-items: center;
  border-left: 3px solid #e7ebef;
}

.unableToMakeReqSpan {
  color: #fff;
  background-color: #ba0000;
  border-radius: 10px;
  padding: 10px;
  max-width: fit-content;
  display: flex;
  align-items: center;
  /* margin: 0 auto; */
  margin-bottom: -25px;
  /* margin-left: -5px; */
  /* padding-left: 20px; */
  /* padding-right: 20px; */
  /* justify-content: baseline; */
  /* text-align: right; */
}

.cannotOverlapTxt {
  font-size: 0.9em;
  max-width: 90%;
  margin: -20px auto;
  text-align: left;
}

.dailyValueReq {
  opacity: 0.5;
}

.reqMakerAllowRowValue {
  text-align: right;
  font-size: 16px;
  font-weight: 600;
}

.extendAllowanceTxtReq {
  font-size: 14px;
  line-height: 14px;
  min-width: 100px;
  margin-top: 2px;
  margin-bottom: 8px;
  color: #8ea7c5;
  /* min-width: 320px; */
}

.reqConversionSpan {
  color: #8ea7c5;
  font-size: 17px;
  padding-left: 2px;
}
.reqMakerAllowRowTitle {
  text-align: left;
  /* line-height: 16 px; */
  font-size: 12px;
}

.addNoteToReqContainer {
  /* background-color: blue; */
  min-height: 35px;
  max-height: 35px;
  margin-bottom: -30px;
  display: flex;
  justify-content: space-between;
  padding-right: 5%;
}

.addNoteToReqBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 78px;
  padding-left: 7px;
  padding-right: 4px;
  /* min-height: 35px; */
  /* max-height: 35px; */
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
  /* margin-top: 0px; */
  margin-right: 2px;
}

@media only screen and (min-width: 820px)  {
  .addNoteToReqBtn:hover {
    background-color: #bbcada;
  }
}

.addNoteToReqBtnImg {
  width: 9px;
  margin-left: 6px;
  margin-right: 4px;
}




.noteActive {
  min-width: 6px;
  max-width: 6px;
  min-height: 6px;
  max-height: 6px;
  background-color: #0077dd;
  border-radius: 10px;
  margin-right: 2px;
  margin-left: 6px;
}

.closeNoteBtnX {
  max-width: 10px;
  margin-left: 6px;
}

.desktopTypesDropdown {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 280px;
  min-width: 280px;
  line-height: 26px;
  height: 35px;
  -webkit-appearance: none;
  background-position-x: 92px;
  display: flex;
  cursor: pointer;
  margin-left: 20px;
  /* margin-top: 18px; */
  margin: 18px auto;
  border: 1px solid #e6eaee;
  background-color: #0bf;
  background-color: white;
  color: #3b597c;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 255px;
}

.desktopTypesDropdown:hover {
  border-color: #d7dfe9;
}

.reqCostDiv {
  max-width: 80%;
  border: 1px solid #dde9f9;
  border-right: none;
  border-top: none;
  border-radius: 0px 0px 0px 5px;
  margin-bottom: 24px;
  animation-name: costDivDown !important;
  animation-duration: 0.12s !important;
  margin-left: 15px;
}

.reqCostDivToggle {
  display: flex;
  justify-content: space-between;
  max-width: 190px;
  padding-right: 40px;
  /* border-bottom: 1px solid #d7e1ee; */
  /* cursor:   pointer; */
}

.mobReqCostDivToggle {
  max-width: 213px;
}

.reqCostDropImg {
  cursor: pointer;
  min-width: 22px;
  max-width: 22px;
  max-height: 22px;
  min-height: 22px;
  background-color: #cbd7e6;
  border: 1px solid #bbcada;
  padding: 6px;
  border-radius: 5px;
  margin-left: 10px;
  margin-top: -2px;
  /* margin-right: 120px; */
}

.reqCostDropImg:hover {
  background-color: #bbcada;
}

.noBorder {
  border: none;
}

.showCostImgInactive {
  transform: rotate(90deg);
}

.costArrRow {
  padding-bottom: 12px;
  padding-left: 12px;
  border-bottom: 1px solid #d7e1ee;
}

.costArrRow:last-of-type {
  border: none;
}

.costArrTitle {
  margin-left: 5px;
  font-size: 10px;
  color: #214978;
  font-weight: 500;
  margin-top: 12px;
  /* text-transform: uppercase; */
  background-color: #fff;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 4px;
  border-radius: 3px;
  padding-left: 6px;
  padding-right: 6px;
  
  border: 1px solid #e3e8ee;
}

.costArrValue {
  color: #143051;
  margin-left: 9%;
  font-size: 14px;
  font-weight: 500;
  margin-top: 6px;
  line-height:17px
}

.costContractedExcessSpan {
  font-size: 10px;
  margin-left: 1px;
  font-weight: 500;
  color: #bbcada;
}

.whiteGoActive {
  transform: rotate(180deg);
}

.reqMakerLimitsBox {
  /* color: blue; */
  border-bottom: 1px solid #d7e1ee;
  margin-bottom: 20px;
  padding-bottom: 12px;
}

.limRowFlexBox {
  display: flex;
  justify-content: space-between;
  max-width: 90%;
  margin-left: 5%;
  color: black;
  margin-top: -4px;
}

.limRowDs {
  text-align: left;
  padding-left: 10px;
  color: #bbcada;
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 12px;
  margin-top: -4px;
  display: flex;
  align-items: center;
}
.limExceedRedX {
  width:12px;
  margin-right:6px;
}

.reqMakerLimitrRow {
  border: 1px solid #445a74;
  background-color: #516b8b;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 9px;
  border-radius: 5px;
  max-width: 92%;
  margin-left: 4%;
}

.reqMakerLimitrRow:first-of-type {
  margin-top: 20px;
}

.reqMakerLimitrRow:last-of-type {
  margin-bottom: 15px;
}
.limRowLeft {
  text-align: left;
  min-width: 15%;
  max-width: 15%;
}

.limRowMiddle {
  text-align: left;
  min-width: 35%;
  max-width: 35%;

  padding-left: 6px;
  border-left: 1px solid #6c7f96;
  /* min-height: fit-content; */
}

.limRowRight {
  padding-left: 6px;
  text-align: left;
  min-width: 55%;
  max-width: 55%;
  /* padding-bottom: 6px; */
  border-left: 1px solid #6c7f96;
  min-height: fit-content;
}

.limRowTitle {
  color: #fff;
  font-size: 10px;
  font-weight: 500;
}

.limRowValue {
  font-size: 12px;
  font-weight: 500;
  margin-top: -4px;
  line-height: 16px;
  margin-top: -3px;
  font-weight: 500;
  color: #b0c4dd;
  min-height: -moz-fit-content;
  min-height: fit-content;
  /* background-color: blue; */
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-right: 12px;
}

.limRowValueChecker {
  font-size: 12px;
  font-weight: 500;
  margin-top: -4px;
  line-height: 14px;
  margin-top: 0px;
  font-weight: 500;
  color: #b0c4dd;
  min-height: -moz-fit-content;
  min-height: fit-content;
  /* background-color: blue; */
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-right: 12px;
}

.costBoxContainer {
  color: #496375;
  /* margin-left: 10px; */
  /* border-bottom: 1px solid #d7e1ee; */
  padding-bottom: 14px;
  padding-top: 2px;
  min-width: 96%;
  max-width: 96%;
  padding-left: 10px;
  background-color: #f4f8fe;
  border-radius: 5px;
  margin-top: 4px;
  padding-bottom: 4px;
  border: 1px solid #e6eaee;
  cursor: pointer;
}

.costBoxContainer:hover {
  background-color: #fafcff;
}

.allowBoxContainer {
  margin-top:10px;
  
}

.usrAllowBoxContainer {
  margin-top: 11px;
    max-width: 175px !important;
    min-width: 175px !important;
    margin-left: 19px;
}

.userCostBoxContainer {
  color: #496375;
  /* margin-left: 10px; */
  /* border-bottom: 1px solid #d7e1ee; */
  padding-bottom: 14px;
  padding-top: 2px;
  min-width: 86%;
  max-width: 86%;
  padding-left: 10px;
  background-color: #f4f8fe;
  border-radius: 5px;
  margin-top: 4px;
  padding-bottom: 4px;
  border: 1px solid #e6eaee;
  cursor: pointer;
  margin-left: 18px;
  margin-top: 22px;
}

.userCostBoxContainer:hover {
  background-color: #fafcff;
}

.reqFooterBackUnit {
  min-width: 10%;
  cursor: pointer;
  /* background-color: blue; */
  min-height: 50px;
  border-right: 1px #96acc4 solid;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reqFooterBackUnit:hover {
  background-color: #e7ebef;
}

.reqBackImg {
  max-width: 6px;
}

.payableSplitter {
  margin-top: 0px !important;
}

.toUseSplitter {
  margin-top: 0px !important;
}


.sureDeclinedReqModalMob {
  max-height: 362px !important;
  min-height: 362px !important;
}

.sureDeclinedReqModal {
  max-height: 360px !important;
  min-height: 360px !important;
}

.sureDeclinedWithReganModal {
  /* background-color: blue  !important; */
  max-height: 413px !important;
  min-height: 413px !important;
}

.sureDeclinedWithReganModalMob {
  max-height: 403px !important;
  min-height: 403px !important; 
}

.sureDeclineModalBoxNotYetApprovedWithOutRegen {
  max-height: 323px !important;
  min-height: 323px !important; 
}

.sureDeclineModalBoxNotYetApprovedWithOutRegenMob {
  max-height: 323px !important;
  min-height: 323px !important;
}

.sureDeclinedReasonContainerWithReganModalMob {
  max-height: 253px !important;
  min-height: 253px !important; 
}


.addLeaveModalBox {
  background-color: #f4f8fe;
  color: #143051;
  min-width: 380px;
  max-width: 380px;
  border-radius: 10px;
  padding-top: 7px;
  text-align: left;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 45px;
  cursor: auto;
  border: 1px solid #e7ebef;
  /* -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
}

.addLeaveModalBoxMob {
  min-width:100vw;
  max-width:100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  border-radius: 5px 5px 0 0
}

.addLeaveModalBoxHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* max-width:90%; */
  padding-left:8px;
  padding-right:10px;
  padding-bottom:4px;
}

.addLeaveModalHeaderboxCloseImg {
  cursor: pointer;
}

.addLeaveModalHeaderboxCloseImg:hover {
  opacity: 0.8;
}
.addLeaveModalHeaderBoxTitle {
  font-weight:600;
  font-size:16px;
}

.addLeaveToUserModalFooter {
  display: flex;
  min-height:50px;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #bac5d3;
  flex-direction: row-reverse;
  /* flex-direction: row-reverse; */
}

.flexDirectionRow {
  flex-direction: row-reverse !important;

}
.saveAddLeaveToUserBtn {
  font-size: 14px;
  color: #fff;
  background-color: #0bf;
  border: 1px solid #0af;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  max-width: 62px;
  min-width: 62px;
}
.saveAddLeaveToUserBtn:hover {
  background-color: #0af;
}

.addLeaveDatesRow {
  display: flex;
justify-content: space-between;
/* padding-left:5%;
padding-right:5%; */
flex-direction: column;
border-bottom: 1px solid #e0ebf8;
padding-bottom:20px;
}




.addLeaveDatesTitle {
  font-weight:400;
  font-size:12px;
  color:#214978;
  margin-bottom:-10px;
  margin-left:7%;
  
}

.addLeaveOverlapsOtherReqsTitle {
  font-weight:600;
  font-size:12px;
  color:#143051;
  margin-bottom:-10px;
  margin-left:14px;
}

.addLeaveOverlappingDsArr {
  display: flex;
  flex-wrap: wrap;
  margin-top:-5px;
  margin-bottom:18px;
  padding-left:4px;

}

.overlapReqAddLeaveItem {
  background-color: blue;
  font-size:12px;
  max-width: fit-content;
  min-width: fit-content;
  max-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight:500;
  color:white;
  background-color: #bccada;
  border: 1px solid #bac5d3;
  padding-left:5px;
  padding-right:5px;
  border-radius: 5px;
  margin-left:10px;
  margin-top:5px;
  cursor: pointer;
}

.overlapReqAddLeaveItem:hover {
  background-color: #bac5d3;
}


.addLeaveDateInput {
  border-radius: 5px;
  border: none;
  background-color: #fff;
  color: #496375;
  min-height: 35px;
  line-height: 35px;
  font-size: 16px;
  padding-left: 14px;
  padding-right: 8px;
  letter-spacing: 1px;
  padding-top: 1px;
  font-weight: 500;
  max-width: 174px;
  min-width: 174px;
  background: url(/static/media/calicongrey.9c7e1aba.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 142px;
  border: 2px solid #dde9f9;
  margin-left: 0px;
  margin-top: 4px;
  cursor: text;

}

.addLeaveDateInput:hover {
  border-color: #bac5d3
}

.addLeaveModalLeaveSelectorDropdown {
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  padding-left: 10px;
  text-align: left !important;
  max-width: 254px;
  min-width: 254px;
  line-height: 26px;
  height: 35px;
  /* border:  2px solid #e6eaee; */
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border-color: #2c7eb9;
  border: 2px solid #dde9f9;
  background-color: white;
  color: #496375;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 228px;
  margin-top:7px;
  margin-bottom:10px;
  margin-left:5%;
}
.addLeaveModalLeaveSelectorDropdown:hover {
  border-color: #bac5d3

}

.lastDayDivWithNoEnd {
  min-width:100%;
  display: flex;
  justify-content: flex-start;
  padding-left:5%;
  min-height: 96px;
}

.lastDayL {
min-width:176px;
}


.ongoingBtn {
  cursor: pointer;
  display: flex;
  border: 2px solid #dde9f9;
  /* background-color: #a387df; */
  color: #62548e;
  border-radius: 5px;
  min-height: 35px;
  line-height: 35px;
  padding-left: 9px;
  padding-right: 7px;
  font-size: 12px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  /* margin-left: 18px; */
  font-weight: 500;
  margin-top: 17px;
  max-width: fit-content;
  max-height:30px;
  align-items: center;
}

.ongoingBtn:hover {
  border: 2px solid #bac5d3;
  color: #4b1caf;


  /* background-color: #8e76c1; */
}

.ongoingActive {
  background-color: #9f89d3;
  border-color: #31146f;
  color:white
}

.ongoingActive:hover {
  background-color: #8973bc;
  border-color: #31146f;
  color:white
  /* border-color: #1b054a; */
}

.ongoingInputTxt {
  font-weight: 500;
  color: #bbcada;
  font-size: 12px;
  line-height: 16px;
  /* padding-right: 80px; */
  max-width: 174px;
  min-width: 174px;
  max-height: 35px;
  min-height: 35px;
  margin-top: 6px;
  margin-left: 0px;
  padding-left: 8px;
  border-top: 1px solid #f5f5f5;
  padding-top: 5px;
  margin-bottom: 4px;
}

.addLeaveFirstDayRowFlex {
  display: flex;
  /* border-bottom: 1px solid #e0ebf8; */
  padding-bottom:12px;
  padding-left:5%;

  /* min-width:100%; */

}

.addLeaveFirstDayBorderBottom {
    border-bottom: 1px solid #e0ebf8;

}

.lastDayR {
  margin-top:35px;
  /* background-color: blue; */
  font-size:14px;
  font-weight:600;
  display: flex;
  max-width:110px;
  align-items: center;
  margin-left:20px;
  padding-bottom:10px;
  padding-left:5px;
  padding-right:2px;
  max-height:40px;
  border-radius:5px;
  border: 1px solid white;
  color:#bbcada
}
.lastDayR:hover {
  /* background-color: #f2f7fd; */
  /* border: 1px solid #bbcada */
  color: #90aac7

}

.lastDayRFx {
  margin-top:4px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-left: 20px;
  padding-bottom: 7px;
  max-height: 54px;
  border: 1px solid white;
  min-height:78px;
  color: #bbcada;
}

.addLeaveFixSchedStripFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width:100px;
  margin-top: 21px;
  margin-left:10px;
  max-height:30px
}

.addLeaveHalfStripTxt {
  max-height:50px;
  line-height:15px;
  margin-top:10px;
  font-size:12px;
  cursor: pointer;

}

.addLeaveHalfStripTxtFs {
  cursor: pointer;
  background-color: #fff;
  border-bottom: 2px solid #fff;
  color:#143051;
  /* pointer-events: none; */
  /* cursor: default; */
  /* border-radius: 5px; */
  padding-left:4px;
  padding-right:4px;
  max-width: fit-content;
  max-height:24px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.addLeaveHalfStripTxtFs:hover {
opacity: 0.8;}

.selectedFsHalfStrip {
  background-color: #fff;
  border-bottom: 2px solid #a387df;
  color:#143051;
  pointer-events: none;
  cursor: default;
  /* border-radius: 5px; */
  padding-left:4px;
  padding-right:4px;
  max-width: fit-content;
  max-height:24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectedFsHalfStripNonHalf {
  /* background-color: #cbd7e6;
  border: 1px solid #bac5d3; */
}

.addLeaveHalfStartKnob {
  /* background-color: pink; */
  min-width: 38px;
  max-height:30px;
  margin-bottom:3px;
}

.addLeaveHalfStartActive {
  background-color: #214978;
  border-color: #143051;
  color:white;
}

.addLeaveHalfStartActive:hover {
  background-color: #143051;
  border-color: #143051;
  color:white;

}

.startsBeforeEndsAddLeave {
  border-color: #ba0000
}

.startsBeforeEndsAddLeave:hover {
  border-color: #ba0000
}

.addLeaveOngoingAndDataDiv {
  display: flex;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: 20px;
  min-width: 100%;
}

.addLeaveDataDiv {
  min-width: 92%;
  max-width:92%;
  margin-left:4%;
  margin-top: 10px;
  border-radius: 5px;
  border: 2px solid #214978;
  margin-right: 8px;
  margin-bottom: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #f4f8fe;
  border-left:10px solid #143051

}

.addLeaveDataDivRow {
  height:33px;
  border-bottom: 1px solid #e0ebf8;
  max-width:94%;
  min-width:94%;
  margin-left:3%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left:5px;
  padding-right:5px;
  font-size:14px;
  font-weight:600;
  color: #143051;
}

.addLeaveDataDivRowValue {
  color: #0077ff;
  font-size:14px;
}


.addLeaveReqAllowDataDiv {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
}

.recalcAddLeaveDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right:5%;
  padding-left:5%;
  margin-top:12px;

}

.recalcAddLeaveBtn {
  border: 1px solid #8e76c1;
  background-color: #a387df;
  color:white;
  border-radius:5px;
  display: flex;
  min-width: fit-content;
  padding-left:4px;
  padding-right:8px;
  font-weight:600;
  max-height:35px;
  align-items: center;
  font-size:14px;
  cursor: pointer;
  margin-bottom:10px;
}

.recalcAddLeaveBtn:hover {
  background-color: #8e76c1;
}

.withOutSpan {
  color: #bbcada;
  font-size:10px;
}

.calcRefreshIconImg {
  max-width:22px;
  margin-right:4px;

}

.desktopAddLeaveModalBody {
  max-height: 572px !important;
}

.formRespBody {
  max-height: 450px !important
}

.addLeaveDataNoLeaveRequired {
  font-weight: 500;
  text-align: center;
}

.startsOrEndsHalf {
  font-weight:600;
  font-size:12px;
  float:right;
  margin-top:-26px;
  margin-right:27px;
  color: #bbcada
}



.firstHalfOffTxtV {
  margin-top: -8px;
  margin-bottom: -16px;
  float: right;
  text-align: right;
  margin-right:27px;

  

}

.ongoingSelectImg {
  max-width: 14px;
  min-width:14px;
  min-height:14px;
    margin-left: 7px;
    margin-right: 4px;
    border: 1px solid #d8d3e3;
    border-radius: 3px;
    background: #e6e2f0;
}

.singleDayBothHalfs {
  font-size: 14px;
  line-height: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 14px;
  padding-bottom: 15px;
  background-color: #de6969;
  color: white;
  border-radius: 5px;
  max-width: 90%;
  margin-left: 5%;
  margin-bottom: 9px;
  margin-top: 7px;
  border: 2px solid #ba0000;
  font-weight:600;
  text-align: center;
  
}

.addLeaveStartEndInvalid {
  text-align: center;
  font-weight: 500;
  color: #143051
}

.redDocsBtn {
  background-color: #ba0000;
  /* color: #fff !important; */
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 5px;
  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-left:5px;
cursor: pointer;
}

.redDocsBtn:hover {
  background-color: #ae0e0e;
}

  .redDocsBtnPopUpImg {
    max-width:9px;
    margin-left:3px;
  }

  .addLeaveModalInfoBtn {
    background-color: #143051;
    color: white !important;
    font-weight: 500;
    min-height: 35px;
    display: flex;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: 0 auto;
    align-items: center;
    padding-left: 11px;
    padding-right: 11px;
    border-radius: 5px;
    font-size: 14px;
    margin-top: 10px;
    float: left;
    margin-right: 7px;
  }

  .addLeaveModalInfoPopUp {
    max-width:12px;
    margin-left:3px;
  }


  .calcSpanModalTxt {
    color: #a387df;
    font-weight:600
  }

  .needToRecalcAddBtn {
    /* opacity: 0.6; */
    color: #bbcada;
    border-color: #bbcada;
    background:none
  }

  .needToRecalcAddBtn:hover {
    background-color: #bbcada;
    color:white
  }

  .overlapWarnIcon {
    margin-right: 5px;
    max-width: 16px;
    margin-bottom: -2px;
    padding-top: 3px;  }

.zeroAllowAddLeaveExplainDiv {
  padding-left:5px;
  min-height:35px;
  padding-top:10px;
  padding-bottom:10px;
  /* background-color: blue; */

}
    .zeroAllowAddLeaveExplainTxt {
      color: #bbcada;
      font-size:10px;
      font-weight:500;
      line-height:12px;
      max-width:192px;
      /* background-color:pink; */
    }

    .zeroAllowAddLeaveExplainInfoBtn {
      background-color: #bbcada;
      border: 1px solid #bac5d3;
      color:white !important;
      font-weight:500;
      display: flex;
      align-items: center;
      font-size:12px;
      max-height:20px;
      padding-left:5px;
      padding-right:5px;
      border-radius:5px;
      cursor: pointer;
    }

    .zeroAllowAddLeaveExplainImg {
      max-width:12px;
      margin-left:3px;
    }

    .zeroAllowAddLeaveExplainInfoBtn:hover {
      background-color: #bac5d3;
    }


    .cancelAddLeaveModalBtn {
      font-size: 14px;
      color: #fff;
      background-color: #3b597c;
      border: solid #214978 1px;
      font-weight: 500;
      cursor: pointer;
      padding-left: 9px;
      padding-right: 9px;
      max-height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      min-height: 35px;
      max-width: 70px;
      min-width: 70px;
      
    }
    .cancelAddLeaveModalBtn:hover {
      background-color: #214978;
    }


    .greenNestedAddLeaveBorderLeft {
      border-left:10px solid #7fbb67
    }

    .redNestedAddLeaveBorderLeft {
      border-left:10px solid #ba0000
    }

    .sufficientImg {
      max-width:12px;
      margin-left:4px;
    }

    .addLeaveFixedSchedDivParent {
      height:22px;
      /* background-color: black; */
    }

    .addLeaveFixedSchedSub {
      color: #bbcada;
      font-size:12px;
      margin-top:-10px;
      /* height:20px; */
    }

    .addLeaveNoteBtn {
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      padding-left: 7px;
      padding-right: 7px;
      max-height: 30px;
      /* margin-right: 20px; */
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      min-height: 35px;
      margin-top: 17px;
      /* margin-bottom: 8px; */
      color: #496375;
      border: #e3e8ee solid 2px;
      background-color: #fff;
      min-width: 78px; 
      cursor: pointer;
       }
       

      .addLeaveNoteBtn:hover {
        border-color: #b2bbc7
      }
      .showAddNoteCloseImg {
        max-width: 9px;
        margin-left:6px;
        margin-right:2px;
      }

      .addLeaveAddNoteDiv {
        max-width:90%;
        margin-left:5%;
        border-top: 1px solid #e0ebf8;
        border-bottom: 1px solid #e0ebf8;
        padding-top:20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }
.addLeaveFirstDayRowFlexLeft {
  min-width: fit-content;
}
      .addLeaveNoteAddNoteDivLeft {
      width:75%
      }

      .addLeaveNoteAddNoteDivRight {
        /* background-color: orange; */

      }

      .addNoteNoteTextAreaInput {
        border: 2px solid #dde9f9;
        border-radius: 5px;
        padding-left: 6px;
        background-color: #fdfeff;
        resize: none;
        width: 100%;
        min-height: 90px;
        padding: 5px;
        padding-left: 10px;
        color: #516b8b;
        font-size: 16px;
        font-weight: 500;
        color: #496375;
        font-weight: 500;
        /* margin-bottom: 60px; */
      }

      .addNoteNoteTextAreaInput:hover {
        border-color: #bac5d3
      }

      .showAddNoteAddNoteBtn {
        border: solid 1px #bbcada;
        background-color: #cbd7e6;
        min-height: 35px;
        border-radius: 5px;
        min-width: 68px;
        color: white;
        font-weight: 500;
        max-height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        cursor: pointer;
        margin-top: 27px;
      }

      .showAddNoteAddNoteBtn:hover {
        background-color: #bbcada;
      }

      .addLeaveNoteTitle {
        font-weight: 500;
        font-size: 12px;
        line-height:12px;
        margin-bottom:14px;
        color: #143051;
        margin-left:10px;
        /* max-height:20px; */
        /* margin-top:-30px; */
      }

.absenceModalFooterLeft {
  font-weight: 500;
  color: #bbcada;
  margin-top: -2px;
  font-size: 12px;

  margin-left: -5px;
}

.isAbsenceSubHeaderCorrection {
  margin-bottom: -1px;
  /* margin-top: 2px; */
}

.noReqCostSplitter {
  min-height:1px;
  min-width:100%;
  background-color: #d7e1ee;
  min-width:90%;
  margin-right:10px;
}


.areYouSureModalYesBtn {
  font-size: 14px;
  color: #fff !important;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  margin-top: 20px;
  width:48%;

}

.areYouSureModalYesBtn:hover {
  background-color: #214978;
}

.areYouModalNoBtn {
  font-size: 14px;
  color: #8ea7c5;
  background-color: #f4f8fe;
  border: solid #bbcada 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  margin-top: 20px;
  width:48%;
}

.areYouModalNoBtn:hover {
  background-color: #dde9f9;
}

.areYouModalBtnsDiv {
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  align-items: center;
}

.lastDayRparent {
  /* background-color: blue; */
}

.fxLastHalfOffCorrection {
  margin-right:22px;
}


.addLeaveLoadingImg {
  max-width:45px;
  margin-right:8px;
}

.reqOngoingSpan {
  /* background-color: #9e79ab; */
  border: 1px solid #f5f5f5;
  /* border-left: 3px solid #634698; */
  color:#bbcada;
  border-radius:3px;
  font-size:12px;
  font-weight:600;
  min-height:20px;
  padding-left:4px;
  padding-right:4px;
  padding-bottom:1px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
}

.ongoingTxtFormat {
  font-size: 12px;
  padding-right: 20px;
  color: #859dbb;
  display: flex;
  justify-content: space-between;
  min-width: 210px;
  cursor: default;
  line-height: 14px;

}


.ongoingUpToNowSpan {
  color:#bbcada;
  font-weight:400
}

.openedToEndOngoingDateInput {
  border-color: #00ccff
}


.endOngoingNowBtn {
  background-color: #00ccff;
  border: 1px solid #0af;
  color:white;
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left:20px;
  min-height:35px;
  border-radius: 5px;
  font-size:14px;
  cursor: pointer;
}

.endOngoingNowBtn:hover {
  background-color: #0af;
}

.willBeUnder {
  color: #143051;
  font-weight: 700;
  font-size: 11px;
  margin-top: 4px;
  margin-left: 12px;
  min-height: 30px;
  text-align: center;
padding-right:8px;
  margin-bottom: -15px;
}

.soFar {
  margin-top:-2px;
}

.onGoingPullUp {
  margin-top:34px;
}


.reqModalContainer {

  min-height: 660px;
  max-height: 660px;
  min-width: 400px;
  max-width: 400px;

  border-radius: 10px;

  /* box-shadow: 0px 0px 3px 3px rgb(0 0 0 / 10%); */
  cursor: auto;
  animation-name: zoomModalIn ;
  animation-duration: 0.1s !important;
  border: 1px solid #e7ebef !important;
  background-color: #f4f8fe!important;

}

.mobReqModalContainer {
  min-width: 100vw !important;
  max-width: 100vw !important;
  position: fixed;
  bottom: 0;
  border-radius: 15px 15px 0 0;
  animation-name: moveEditTeamUserBoxUp !important;
  animation-duration: 0.1s !important;
}


.decideReqActionsDiv {
  /* background-color: blue; */
  display: flex;
  align-items: center;
}

.reqDecisionReturnArrImg {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;  max-width:35px;
  max-height:35px;
  min-height:35px;
  min-width:35px;
  padding:7px;
  border-radius:5px;
  cursor: pointer;
  margin-right:8px;
}

.reqDecisionReturnArrImg:hover {
  background-color: #bbcada;
}


.reqRate {
  border: 2px solid #1c3f68;
  min-width: 60px;
  border-radius: 5px;
  min-height: 40px;
  margin-top: -10px;
  background: #f3e6ff;
}

.x2i3i2 {
  background-color: #ebf4ff;
}


.reqRate0 {
  font-size: 9px;
  font-weight: 500;
  margin-top: 3px;
  margin-bottom: -3px;
  /* margin-left: 3px; */
  text-align: center;
}


.reqRate1 {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 2px;
  margin-top: 1px;
  text-align: center;
}


/* .reqRateDivider {
  min-height: 2px;
  background-color: #1c3f68;
  min-width: 60px;
  margin-top: -10px;
  margin-bottom: 20px;
  display: none;
} */
.x28838482 {
  margin-top:10px !important
}

.addLeaveFixedSchedParent {
  /* background-color: blue; */
}

.leaveStartsWhen {
  /* background-color: yellow; */
  margin-top: 0px;
  margin-bottom: -30px;
  font-size: 12px;
  color: #214978;
  padding-left:11px;
  font-weight: 400;}


  .x7733 {
    margin-left: -10px;
  }

  .annLeavePlaneTopRight {
    max-width: 25px;
    margin-left: 3px;
    margin-bottom: -6px;  }



    .sameDataPushDown {
      /* margin-top:8px; */
    }

    .fontSize14Rate {
font-size:14px;
    }

    .x9101pp {
      margin-top:15px
    }

    .beforeTodayReqCal {
      opacity: 0.5;
      pointer-events: none;
    }

    .reqWkRow {
      /* border-bottom: 1px solid black; */
      margin-top: 12px;
      min-height: 30px;
      /* background-color: blue; */
      padding-left: 5px;
      padding-right: 5px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      max-width: 92%;
      margin-left: 4%;
      cursor: pointer;
      }
      @media only screen and (min-width: 820px)  {
        .reqCalItemHover:hover {
          background-color: #e3e8ee;
          border-radius:5px;
        }
      }


   

      .selReqSnakeItem {
        background-color: #143051;
        color:white !important;
        border-radius:5px;
      }

      .selReqSnakeItem:hover {
        background-color: #143051;
      }



      .selReqSnakeItemClosed {
        background-color: #dde9f9;
        color:white !important;
        border-radius:5px;
      }

      .selReqSnakeItemClosed:hover {
        background-color: #dde9f9;
      }

      .reqHalfDiv {
        /* background-color: blue; */
        min-height:50px;
        max-height:50px;
        /* color:black */
      }

.halfReqSelRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 94%;
  margin-left: 3%;
  border-top: 1px solid #dde9f9;
  align-items: center;
  padding-top: 5px;
}

.x991010101111 {
  padding-top:1px;
}

.halfReqTitleTxt {
  font-size: 10px;
  font-weight: 500;
  color: #214978;
  margin-right: 5px;
  margin-left: 10px
}

  .halfReqSelRowBlock {
    display: flex;
    align-items: center;
  }

  .halfReqSelRowBlockInline {
    display: flex;
    align-items: center;
  }

  .closedReqDaySelector {
color:#bbcada  }

.fsReqblock {
  margin-top:5px;
}

.x1981911311 {
  border-left: 1px solid #dde9f9;
  margin-left: 12px;}

  .halfSelStartReqItem {
    background: rgb(255,255,255);
    background: linear-gradient(135deg, rgba(255,255,255,0) 50%, rgba(20,48,81,1) 50%);  
  }

    .halfSelStartReqItem:hover {
      background: rgb(255,255,255);
      background: linear-gradient(135deg, rgba(255,255,255,0) 50%, rgba(20,48,81,1) 50%);  
    }

    .x88s {
      color:#143051
    }

    .x881jj {
      color: #143051 !important;
    }

  .halfSelEndReqItem {
    background: rgb(255,255,255);
    background: linear-gradient(310deg, rgba(255,255,255,0) 50%, rgba(20,48,81,1) 50%);  }


  .halfSelEndReqItem:hover {
    background: rgb(255,255,255);
    background: linear-gradient(310deg, rgba(255,255,255,0) 50%, rgba(20,48,81,1) 50%);  }


    .reqMonthDropMakerDropdown {
      border-radius: 5px;
      font-size: 14px;
      font-weight: 500;
      padding-left: 12px;
      text-align: left !important;
      max-width: 160px;
      min-width: 160px;
      line-height: 26px;
      height: 35px;
      -webkit-appearance: none;
      background-position-x: 92px;
      display: flex;
      cursor: pointer;
      margin-left: 20px;
      /* margin-top: 18px; */
      margin: 0px auto;
      border: 1px solid #e6eaee;
      background-color: #0bf;
      background-color: white;
      color: #3b597c;
      background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right white;
      background-position-x: 135px;
    }
    @media only screen and (min-width: 820px)  {

    .reqMonthDropMakerDropdown:hover {
      border-color:#d7dfe9
    }
  }

  .makeReqFooterLeft {
    display: flex;
    align-items: center;
    margin-top:-6px
  }

  .reqBackBtn {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 60px;
    padding-left: 4px;
    padding-right: 4px;
    min-height: 35px;
    max-height: 35px;
    border: solid 1px #bbcada;
    background-color: #cbd7e6;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 0px;
    margin-left: 10px;
  }

  .reqBackBtn:hover {
    background-color: #bbcada;
  }

  .insufficAllow {
    color: #ba0000;
    margin-top: 40px;
    margin-bottom: -50px;
    max-width: fit-content;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 14px;
    font-weight: 500;
    margin-left: 27px;
    border-left: 3px solid #ba0000;  }

    .sufficAllow {
      color: #6eb450;
      margin-top: 40px;
      margin-bottom: -50px;
      max-width: -moz-fit-content;
      max-width: fit-content;
      /* padding-left: 8px; */
      padding-right: 8px;
      font-size: 14px;
      font-weight: 500;
      margin-left: 27px;
      /* border-left: 3px solid #ba0000; */
      display: flex;
  
    align-items: center;
    }

      .sufficTickImg {
        width: 14px;
        margin-left: 7px;
      }






.addReqTextBox {
  min-width: 90%;
  max-width: 90%;
  min-height: 130px;
  resize: none;
  border-radius: 5px;
  margin-top: 8px;
  font-size: 1em;
  overflow: scroll;
  line-height: 20px;
  font-size: 16px;
  margin-top: 9px;
  font-weight: 400;
  color: #496375;
  /* border: none; */
  border: 2px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px;
  color: #6a8bb1;
  padding: 8px;
  font-weight: 500;

    }

    .addReqTextBox:focus {
      border-color:#bbcada
    }

    .addReqTextBox::placeholder {
      color:#bbcada
    }

.clearReqNoteBtn {
  color: #bbcada;
  font-weight: 500;
  font-size: 14px;
  margin-right: 12px;
  cursor: pointer;}

  .clearReqNoteBtn:hover {
    color:#a0b0c1
  }

  .x9900ss {
    background-color: #4f657f !important;
    border-radius:5px;
  }

  .lastHovReqDs {
    background-color: #143051 !important; 
  }

  .x98298131133 {
    /* color:orange */
  }

  .openSnakItem {
    border-radius:7px 0 0 7px;
  }

  .openSnakItem:hover {
    border-radius:7px 0 0 7px;

  }


  .absenceModalMgr {
    background-color: #e5eaf1 !important;
  }


  .tilExtra {
    margin-top:6px
  }
.tilExtraRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-weight: 500;
  font-size: 12px;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 20px;
  margin-bottom: 2px;
  margin-top: 0px;
}

.tilExTitle {
  max-width: 170px;
  text-align: left;
  opacity: 0.5;
  font-size: 10px;
}

.showConvArr {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  border: 1px solid #8ea7c5;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  min-width: 96px;
  min-height: 24px;
  padding-bottom: 1px;
  margin-top:10px;
  margin-right:14px;
  cursor: pointer;
}
@media only screen and (min-width: 820px)  {
  .showConvArr:hover {
    border-color: #7593b6;
  }
}

.convWhiteTri {
  max-width: 6px;
  margin-left:5px;
  margin-top: 2px;

}

.convWhiteTriActive {
  /* transform: rotate(-90deg); */
  transform: rotate(-90deg);
  overflow: hidden;
  transition-duration: 0.25s;
  transition-property: transform;
}

.convArr {
  margin-top:50px;
}

.addToCalBtn {
  max-height: 35px;
  font-size: 12px;
  max-width: 70px;
  text-align: left;
  padding-left: 8px;
  line-height: 12px;
  font-weight: 500;
  padding-top: 5px;}