.editUserUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
  cursor: pointer;
  z-index: 99999999;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.editUserModalBox {
  background-color: #f4f8fe;
  min-width: 100%;
  max-width: 500px;
  min-height: 90vh;
  max-height: 90vh;
  height: 80vh;
  /* border-radius: 6px 6px 0 0; */
  border-radius: 5px 5px 0 0;
  /* margin-top: -40px; */
  /* display: flex; */
  /* justify-content: center; */
  align-items: center;
  color: #143051;
  position: fixed;
  bottom: 0;
  text-align: center;
  /* -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  cursor: default;
  /* z-index: 111 !important; */
}

.desktopEditUser {
  min-width: 500px;
  bottom: 5vh;
  border-radius: 10px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.mobEditUser {
  animation-name: moveEditTeamUserBoxUp !important;
  animation-duration: 0.1s !important;
  border-radius: 20px 20px 0 0;
}

@keyframes moveEditTeamUserBoxUp {
  from {
    bottom: -100%;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

.editUserModalHeader {
  /* background-color: yellow; */
  display: flex;
  justify-content: space-around;
  min-width: 100%;
  max-width: 100%;
  text-align: left;
  min-height: 47px;
  margin-top: 5px;
  margin-bottom: -5px;
  padding-bottom: 0px;
}

.closeEditUserImg {
  width: 30px;
  padding: 7px;
  margin-top: 10px !important;
  margin-left: 16px !important;
  cursor: pointer;
}

.closeEditUserImgMob {
  margin-left: 18px !important;
}

.closeEditUserImg:hover {
  opacity: 0.8;
}

.left {
  margin-left: 50%;
  margin-top: 16px;
}

.editUserNameTitle {
  margin-top: 13px;
  /* margin-left: -10px; */
  font-weight: 500;
  font-size: 18px;
  min-width: 76%;
  max-width: 76%;
  display: flex;
  justify-content: center;
}

.editUserNameTitleMobModal {
  /* justify-content: flex-end; */
  margin-right: 10px;
  margin-top: 15px;
}

.editUserModalMenu {
  /* background-color: lime; */
  display: flex;
  justify-content: space-around;
  font-size: 1em;
  max-width: 94%;
  margin: 0 auto;
  margin-top: 5px;
  color: #143051;
  font-weight: 300;
}

.editUserMenuItem {
  line-height: 25px;
  font-weight: 500 !important;
  color: #8ea7c5;
  font-size: 14px;
  cursor: pointer;
}

.editUserMenuItemDesktopOnly:hover {
  color: #6f88a6;
}

.editUserMenuItem:active {
  transform: scale(0.95);
}

.editUserMenuItemSelected {
  font-weight: 500;
  padding-bottom: 0px;
  border-bottom: solid 3px #ffa8ff;
  color: #143051;
  cursor: default;
  margin-bottom: 3px;
}

.editUserMenuItemSelected:active {
  transform: scale(1);
}

.headerSideHolder {
  min-width: 12%;
  max-width: 12%;
}

/* Main body of the edit user modal */
.editUserMainBodyContainer {
  /* background-color: pink; */
  display: flex;
  position: relative;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;

  margin-top: 6px;
  border-bottom: solid 2px #e6e6e6;
  max-height: calc(100% - 139px);
  min-height: calc(100% - 139px);
  overflow: hidden;
  z-index: 999;
}

.editUserModalFooterContainer {
  /* background-color: yellow; */
  max-width: 90%;
  margin: 0 auto;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editUserModalFooterLeftBox {
  /* background-color: orange; */
  display: flex;
}

.editUserModalFooterRightBox {
  background-color: #214978;
  border: #143051 1px solid;

  display: flex;
  height: 35px;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  /* line-height: 30px; */
  color: white;
  font-size: 14px;
  border-radius: 5px;
  margin-right: 3px;
  padding-left: 13px;
  padding-right: 13px;
  cursor: pointer;
}

.editUserModalFooterRightBox:hover {
  background-color: #143051;
}

.deleteUserBtn {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /* min-width: 72px; */
  padding-left: 13px;
  padding-right: 13px;
  min-height: 35px;
  max-height: 35px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
}
.deleteUserBtn:hover {
  background-color: #bbcada;
}
.sureDelete {
  color: #143051;
  font-weight: 500;
}

.absolutelySure {
  color: #ba0000;
  font-size: 1em;
  /* margin-top: 1px; */
}

/* edit user row */
.DetailsContainer {
  display: block;
  min-width: 100%;
  max-height: calc(100% - 160px);
  min-height: calc(100% - 160px);
  overflow-y: auto;
  border-top: solid 2px #e6eaee;
  border-radius: 5px 5px 0px 0px;

  background-color: white;
  z-index: 100;
}

/* edit user row */
.EditUserTeamsContainer {
  display: block;
  min-width: 100%;
  max-height: calc(100% - 160px);
  min-height: calc(100% - 160px);
  overflow-y: scroll;
  /* -ms-overflow-style: none; IE and Edge */
  /* scrollbar-width: none; Firefox */
  /* padding-bottom: 10px; */
  border-top: solid 2px #e6eaee;
  border-radius: 5px 5px 0px 0px;
  /* padding-top: 10px; */
  /* background-color: rgb(252, 253, 255); */
  background-color: white;
}

.loadingEditUserWhiteSplash {
  display: block;
  min-width: 100%;
  max-height: calc(100% - 160px);
  min-height: calc(100% - 160px);
  overflow: scroll;
  /* -ms-overflow-style: none; IE and Edge */
  /* scrollbar-width: none; Firefox */
  /* padding-bottom: 10px; */
  border-top: solid 2px #e6eaee;
  border-radius: 5px 5px 0px 0px;
  /* padding-top: 10px; */
  /* background-color: rgb(252, 253, 255); */
  background-color: white;
}

.editUserLoadBar1 {
  min-height: 30px;
  max-height: 30px;
  max-width: 80%;
  min-width: 80%;
  background-color: #e2ebf5;
  margin-top: 30px;
  margin-left: 30px;
  border-radius: 5px;
}

.editUserLoadBar2 {
  min-height: 20px;
  max-height: 20px;
  max-width: 40%;
  min-width: 40%;
  background-color: #e2ebf5;
  margin-top: 15px;
  margin-left: 30px;
  border-radius: 5px;
  opacity: 0.8;
}

.editUserLoadBar3 {
  min-height: 20px;
  max-height: 20px;
  max-width: 40%;
  min-width: 40%;
  background-color: #e2ebf5;
  margin-top: 15px;
  margin-left: 30px;
  border-radius: 5px;
  opacity: 0.8;
}
.editUserRow {
  /* background-color: cyan; */
  min-height: 110px;
  max-height: 110px;
  padding-top: 20px;
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  /* padding-bottom: -10px; */
  align-items: center;
  border-bottom: 1px solid #ddebf4;
  font-size: 14px;
}
.firstEditUserRow {
  margin-top: 40px;
}

.editUserTeamRow {
  /* background-color: cyan; */
  min-height: 110px;
  /* max-height: 110px; */
  padding-top: 20px;
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  /* padding-bottom: -10px; */
  align-items: flex-start;
  border-bottom: 1px solid #ddebf4;
  font-size: 1.2em;
}

.editWPatternRowOnly {
  max-height: 180px;
  /* margin-top: -50px; */
}

.knobContainerEditUser {
  margin-top: 0px;
}

.editUserRow:last-of-type {
  border: none;
}

.editUserEditTitle {
  font-weight: 500;
  color: #143051;
  min-height: 35px;
  padding-top: 5px;
  text-align: left;
  margin-left: 5%;
  font-size: 14px;
}

.jobTitle {
  /* background-color: yellow; */
  min-width: 66px;
}

.jobTitleValue {
  max-width: 62vw;
  /* background-color: lime; */
}

.clockInKnob {
  margin-top: -2px;
  /* margin-left: -20px; */
}

.notSetTxt {
  font-size: 1em;
  color: #143051;
  font-weight: 500;
  opacity: 0.3;
}

.editUserReqClockOutKnobContainer {
  /* background-color: yellow; */
  margin-right: 8px;
}

.wPatternContainer {
  font-size: 1em;
  display: flex;
  justify-content: space-around;
  /* background-color: #ddebf4; */
  /* border: solid 2px #143051; */
  box-shadow: inset -2px 0 0 #e2ebf5, inset 0 -2px 0 #e2ebf5,
    inset 2px 0 0 #e2ebf5, inset 0 2px 0 #e2ebf5;

  min-height: 30px;
  border-radius: 10px;
  min-width: 120px;
  padding-left: 1px;
  line-height: 30px;
  text-align: center;
  align-items: center;
  /* padding-left: 15px;
  padding-right: 15px; */
}

.casualTxt {
  margin-right: 10px;
  padding-left: 6px;
  /* margin-top: -1px; */
}

.fixedTxt {
  margin-left: 5px;
  padding-right: 18px;
  font-weight: 500;
  margin-top: 0px;
  /* padding-top: 2px; */
  color: #143051;
}

.wPatSel {
  background-color: #00aaff;
  color: white;
  font-weight: 500;
  padding-left: 20px;
  padding-right: 18px;
  border-radius: 10px;
  margin-left: -1px;
  border: 1px solid #143051;

  /* padding-top: 4px; */
  /* margin-top: -2px; */
}

.fixedSel {
  background-color: #00aaff;
  color: white;
  font-weight: 500;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
  margin-left: 4px;
  border: 1px solid #143051;
  margin-bottom: 0px;
  /* margin-top: -1px; */
}

.casualUnsel {
  margin-left: 10px;
  font-weight: 500;
  color: #143051;
}

.casualExplained {
  width: 93%;
  text-align: left;
  background-color: #8ea7c5;
  color: white;
  margin: 0 auto;
  font-size: 1em;
  font-weight: 500;
  padding-top: 10px;
  margin-top: 15px;
  padding-bottom: 10px;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 5px;
}

.fixedPattContainer {
  /* background-color: #f4f9ff; */
  display: flex;
  margin-top: 0px;
  /* justify-content: space-between; */
}

.editSchedContainer {
  width: 100%;
  /* background-color: lime; */
  max-width: 145px;
  min-height: 45px;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-around; */
  margin: 15px auto;
}

.editSchedButton {
  background-color: #9a74eb;
  height: 35px;
  display: flex;
  line-height: 35px;
  padding-left: 15px;
  padding-right: 10px;
  margin-top: 0px;
  margin-right: 5%;
  color: white;
  border-radius: 35px;
  font-size: 0.9em;
}

.editSchedTxt {
  padding-right: 10px;
}

.editSchedImg {
  width: 11px;
}

.scheduleContainer {
  width: 100%;
  display: block;
  margin-top: 20px;
  background-color: #fdfeff;
  border-top: solid 2px #e6eaee;
  border-radius: 15px 15px 0px 0px;
  border-right: 1px solid #dde1e6;
  border-left: 1px solid #dde1e6;
}

.scheduleDividingBar {
  min-width: 3px;
  max-width: 3px;
  margin-left: 5%;
  background-color: #c0d1e9;
  min-height: 50px;
  visibility: hidden;
  display: block;
}

.fixedScheduledTitle {
  font-size: 0.94em;
  font-weight: 500;
  text-align: center;
  /* margin-left: 5%; */
  margin-top: 14px;
  color: #496375;
  /* background-color: blue; */

  /* position: absolute; */
  /* top: 100px; */
}

.fixedSchedSubDivBar {
  min-width: 90%;
  max-width: 90%;
  min-height: 1px;
  max-height: 1px;
  background-color: #dde1e6;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.fixedCont {
  /* background-color: cyan; */
  /* border-bottom: solid 1px #143051; */
  display: flex;
  width: 100%;
  /* height: 80px; */
  justify-content: space-between;
  align-items: center;
  margin-top: -60px;
  padding-bottom: 0px;
  /* min-height: 120px; */
}

.hpw {
  color: #19a5c8;
  background-color: #7390b1;
  border: 1px solid #214978;
  /* padding-left: 15px; */
  padding-bottom: 20px;
  border-radius: 10px;
  width: 40%;
  max-width: 90px;
  font-size: 1em;
  padding-top: 11px;
  margin-left: 10px;
  text-align: left;
  margin-top: 24px;
  max-height: 58px;
  text-align: center;
  line-height: 0px;
  /* margin-left: -2px; */
}

.fixVarInstruction {
}

.fixedVarInstructionTxt {
  font-size: 0.75em;
  width: 80%;
  max-width: 70px;
  border-radius: 30px;
  color: #fff;
  margin: 0 auto;
  /* padding-top: 10px; */
  padding-bottom: 10px;
  font-weight: 500;
  margin-top: -10px;
  margin-bottom: 20px;
  background-color: #abbed3;
  line-height: 24px;
  max-height: 24px;
}

.noBorder {
  border: none;
}

.schedVarContainer {
  font-size: 1em;
  display: flex;
  justify-content: space-around;
  background-color: white;
  /* border: solid 2px #143051; */
  box-shadow: inset -2px 0 0 #e2ebf5, inset 0 -2px 0 #e2ebf5,
    inset 2px 0 0 #e2ebf5, inset 0 2px 0 #e2ebf5;
  min-height: 30px;
  border-radius: 10px;
  align-items: center;
  /* min-width: 120px; */
  max-width: 200px;
  line-height: 31px;
  text-align: center;
  margin-right: 5%;
  /* padding-left: 15px;
    padding-right: 15px; */
}

.schedVarSched {
  margin-right: 10px;
  /* padding-top: 0px; */
  /* background-color: orange; */
}

.schedVarVar {
  margin-left: 5px;
  padding-right: 10px;
  /* padding-top: 2px; */
  /* background-color: blue; */
  padding-right: 22px;
  font-weight: 500;
}

.schedVarSchedSel {
  background-color: #00aaff;
  border: 1px solid #143051;
  color: white;
  text-align: center;
  font-weight: 500;
  padding-left: 12px;
  padding-right: 14px;
  border-radius: 10px;
  /* margin-left: -1px; */
}

.schedVarVarSel {
  background-color: #00aaff;
  border: 1px solid #143051;
  color: white;
  font-weight: 500;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 10px;
  margin-left: 0px;
}

.schedVarSchedUnsel {
  /* background-color: #0077ff; */
  margin-left: 10px;
  padding-left: 6px;
  font-weight: 500;
  font-size: 1em;
}

.fixedSchedContainer {
  /* background-color: purple; */
  margin-top: 10px;
  max-width: 100%;
}

.fixedVarContainer {
  /* background-color: lime; */
}

.schedRow {
  display: block;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 105px;
  /* padding-bottom: 20px; */
  border-bottom: solid 1px #dde9f9;
}

.schedRow:last-of-type {
  border: none;
}

.titleRow {
  max-height: 22px;
  margin-top: 8px;
}

.hidden {
  visibility: hidden;
}

.clearImg {
  width: 28px;
  margin-right: 12px;
}

.editDayHoursUnit {
  /* background-color: lime; */
  width: 60%;
  display: flex;
  margin-right: 3%;
  justify-content: center;
  justify-content: flex-end;
  align-items: center;
}

.schedRowunit {
  /* background-color: lime; */
  width: 35%;
  max-width: 90px;
  display: flex;
  justify-content: center;
  /* padding-left: 3%; */
}

.timelineHourlyVal {
  /* background-color: lime; */
  width: 100%;
  max-width: 90px;
  /* margin-left: 10px; */
  display: flex;
  flex-direction: column;
  /* text-align: center; */
  align-items: center;
}

.perHourTxt {
  font-size: 0.8em;
  /* margin-right: 14px; */
  padding-top: 0px;
  font-weight: 500;
}

.schedRowunitBreak {
  width: 20%;
  max-width: 90px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
}

.inputRow {
  /* background-color: orange; */
  display: flex;
  max-width: 260px;
  margin: 0 auto;
  /* padding-left: 5%; */
  /* font-size: 1.2em; */
  /* margin-left: 3%; */
}

.inputRowTitle {
  /* background-color: yellow; */
  display: flex;
  max-height: 20px;
  /* padding-left: 5%; */
  max-width: 260px;
  margin: 0 auto;
  font-size: 1.1em;
  align-items: center;
  /* text-align: center; */
}

.schedRowunitDay {
  /* background-color: lime; */
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  /* margin-left: 3%; */
}

.editing {
  background-color: #f4f9ff;
  /* border-left: 2px #143051 solid; */
  /* background-color: blue; */
}

.firstEditingSchedRow {
  border-radius: 20px 20px 0 0;
  border-top: 2px #e6eaee solid;
}
.lastEditingSchedRow {
  border-radius: 0px 0px 20px 20px;
  border-bottom: 2px #e6eaee solid;
}

.schedDay {
  color: #19a5c8;
  font-weight: 500;
  font-size: 1.2em;
  margin: 0 auto;
  /* text-align: center; */
  /* margin-left: %; */
  /* text-align: right; */
  /* background-color: blue; */
}

.editDayHoursImg {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 18px;
}

.saveDayHoursImg {
  width: 28px;
}

.schedTitle {
  color: #143051;

  font-size: 0.7em;
}

.schedTime {
  background-color: #e6eaee;
  color: #496375;
  border: 1px solid #859dbb;
  border-radius: 10px;
  height: 30px;
  font-weight: 500;
  line-height: 27px;
  padding-left: 7px;
  padding-right: 7px;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  font-size: 0.95em;
  /* padding-bottom: 1px; */
}

.editDayBtn {
  background-color: #143051;
  /* height: 20px; */
  line-height: 20px;
  color: white;
  font-size: 0.9em;
  border-radius: 20px;
  width: 45px;
  height: 20px;
}

.schedTimeTitle {
  /* background-color: #e6eaee; */
  color: #143051;
  border-radius: 30px;
  height: 20px;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 20px;
  /* padding-left: 7px; */
  /* padding-right: 7px; */
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-left: 3%;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(100%);
  margin-right: 6px;
  /* background-color: blue; */
}

.schedTimeInput {
  background-color: #496375;
  color: white;
  border-radius: 10px;
  border: solid 2px #143051;
  height: 30px;
  font-weight: 500;
  line-height: 30px;
  padding-left: 2px;
  padding-right: 0px;
  /* width: 100%; */
  min-width: 76px;
  max-width: 76px;
  margin: 0 auto;
  font-size: 0.94em;
  text-align: center;
}

#monEndInput {
  margin-left: 10px;
}

.schedBrk {
  background-color: #eaf3fd;
  color: #496375;
  border-radius: 10px;
  border: 1px solid #8ea7c5;
  height: 30px;
  width: 80%;
  font-weight: 500;
  font-size: 0.95em;
  text-align: center;
  line-height: 27px;
  padding-left: 7px;
  padding-right: 7px;
}

.schedBrkTitle {
  /* background-color: #c0d1e9; */
  color: #143051;
  border-radius: 30px;
  height: 30px;
  width: 80%;
  font-weight: 500;
  font-size: 0.8em;
  text-align: center;
  line-height: 30px;
  /* padding-left: 7px; */
  /* padding-right: 7px; */
}

.schedBrkInput {
  background-color: #8ea7c5;
  color: white;
  border-radius: 10px;
  height: 30px;
  width: 80%;
  border: none;
  border: solid 2px #143051;
  font-size: 1.1em;
  font-weight: 500;
  text-align: center;
  line-height: 30px;
  padding-left: 7px;
  padding-right: 7px;
}

.fixedSchedInfoContainer {
  /* background-color: yellow; */
  font-size: 0.7em;
  display: flex;
  height: 53px;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  z-index: 99999;
  margin-top: -24px;
  /* padding-top: 30px; */
}

.FixedSchedInfoTxt {
  max-width: 50%;
  margin-right: 0px;
  /* padding-top: 6px; */
  /* margin-top: -20px; */
  text-align: right;
  font-size: 1.2em;
  font-weight: 500;
  color: #496375;
  padding-top: 20px;
  /* min-height: 10px; */
  /* margin-top: 20px; */
  /* position: relative; */
  /* z-index: 9999; */
  /* margin-bottom: 3px; */
}

.curvyArrowImg {
  width: 20px;
  /* margin-top: -40px; */
  margin-right: 3%;
  padding-bottom: 33px;
}

.fixedVarInputContainer {
  /* background-color: yellow; */
  display: flex;
  margin-top: 0px;
  justify-content: center;
}

.fixedVarInputWrapper {
  width: 240px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.saveHoursBtnHolder {
  /* background-color: lime; */
  max-width: 152px;
  margin: 0 auto;
  max-height: 90px;
}

.fixedVarHours {
  height: 35px;
  line-height: 35px;
  color: white;
  border: solid 2px #143051;
  text-align: center;
  font-size: 1.2em;
  font-weight: 500;
  background-color: #496375;
  width: 60px;
  border-radius: 35px;
  margin-right: 0px;
  margin-left: 10px;
}

.fixedVarUnitTxt {
  font-size: 1em;
  margin-left: -10px;
  font-weight: 500;
  color: #496375;
}

.dayOff {
  font-size: 0.8em;
  min-width: 78px;
  max-width: 78px;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 500;
  background-color: #496375;
  color: white;
  height: 22px;
  line-height: 23px;
  border-radius: 15px;
  margin-right: 14px;
}

.dayOffBtnImg {
  width: 28px;
  margin-right: 12px;
}

.editSchedContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding-bottom: 0px;
}

.editSchedBtnContainer {
  background-color: #9a74eb;
  /* background-color: #0077ff; */
  display: flex;
  min-width: 135px;
  height: 30px;
  line-height: 29px;
  margin-left: 3%;
  justify-content: space-around;
  border-radius: 10px;
  /* padding-bottom: 2px; */
  border: 1px #592eb6 solid;
}

.editBgFaded {
  background-color: #d0c5e8;
  border: 1px #9982cb solid;
}

.tapToEditSched {
  font-size: 0.94em;
  height: 30px;
  line-height: 26px;
  margin-left: 6px;
  color: white;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
}

.editSchedIcon {
  width: 10px;
  margin-right: 12px;
}

.crossWhiteIcon {
  width: 16px;
  margin-right: 6px;
}

.saveSchedBtnContainer {
  background-color: #00ccff;
  border: 1px solid #143051;
  display: flex;
  width: 150px;
  text-align: center;
  justify-content: center;
  height: 35px;
  padding-top: 4px;
  font-size: 1.15em;
  justify-content: space-around;
  border-radius: 10px;
  margin-right: 3%;
  padding-left: 3px;
  margin-top: 15px;
  /* margin: -10px auto; */
  margin-bottom: 120px;
}

.tapToSaveSched {
  font-size: 0.9em;
  height: 25px;
  /* font-weight: 500; */
  line-height: 25px;
  text-align: center;
  margin-left: 6px;
  color: white;
  font-weight: 500;
}

.saveSchedIcon {
  width: 12px;
  margin-right: 8px;
  margin-top: -3px;
}

.hideSaveBtn {
  visibility: hidden;
  pointer-events: none;
}

.bottomSaveBtnContainer {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  max-width: 100%;
}

.nonEdit {
  background-color: #e6eaee;
  border: 1px solid #496375;
  color: #496375;
  font-weight: 500;
  font-size: 1.2em;
}

.editFixVar {
  background-color: #9a74eb;
}

.errorMins {
  background-color: #ba0000;
}

.opacity4 {
  opacity: 0.4;
  pointer-events: none;
}

.sureCloseUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-width: 100vw;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.sureCloseEditUserBox {
  min-width: 90vw;
  max-width: 90vw;
  /* height: 300px; */
  background-color: #fff;

  border-radius: 15px;
  /* display: flex;
  justify-content: center; */
  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  border: solid 2px #143051;
  margin-top: -130px;
}

.sureCloseEditUserBoxText {
  text-align: center;
  color: #496375;
  padding-top: 30px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 1.2em;
  font-weight: 500;
}

.sureBtnContainer {
  display: flex;
  justify-content: space-around;
  flex-direction: row-reverse;

  max-width: 55%;
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 25px;
  font-weight: 500;
  font-size: 1.2em;
}

.sureBtn1 {
  background-color: #00ccff;
  border: 1px solid #143051;
  width: 90px;
  color: white;
  height: 40px;
  line-height: 40px;
  border-radius: 35px;
}

.sureBtn2 {
  /* background-color: orange; */
  width: 70px;
  color: #143051;
  height: 40px;
  line-height: 40px;
}

/* Modal for userAdminSettingInput */
.userAdminSettingInputUnderlay {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999999;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.userAdminSettingInputModal {
  background-color: #fff;
  border: 1px solid #dae1eb;
  height: 160px;
  min-width: 300px;
  max-width: 300px;
  /* max-width: 300px; */
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 80px;
  margin-left: 120px;
  display: block;
  color: #143051;
  justify-content: center;
}

.userAdminSettingInputHeader {
  margin-top: 20px;
  font-weight: 500;
  font-size: 1.1em;
}

.userAdminSettingInputInput {
  margin-top: 26px;
  width: 86%;
  background-color: #fff;
  border: none;
  border-bottom: solid 1px #143051;
  color: #143051;
  font-size: 1.3em;
  font-weight: 500;
  padding-bottom: 10px;
  padding-top: 2px;
  text-indent: 3%;
  height: 30px;
  line-height: 43px;
}

.userAdminSettingInputInput::placeholder {
  color: #8ea7c5;
}

.userAdminSettingInputFooter {
  display: flex;
  justify-content: space-between;
  /* margin-top: 18px; */
  max-width: 70%;
  margin: 18px auto;
  /* font-size: 0.98em; */
}

.userAdminSettingInputCancel {
  color: #8ea7c5;
  height: 30px;
  line-height: 26px;
  font-size: 1em;
  margin-left: 10%;
  font-weight: 500;
  margin-top: 0px;
}

.userAdminSettingInputSave {
  background-color: #00ccff;
  border: 1px solid #143051;
  color: #214978;
  color: white;
  height: 30px;
  width: 64px;
  font-size: 1.05em;
  border-radius: 10px;
  margin-right: 10%;
  font-weight: 500;
  line-height: 25px;
}

.saveBtnFaded {
  opacity: 0.3;
  color: #143051;
  background-color: white;
  pointer-events: none;
  border: none;
}

.removeEmpNum {
  color: #c0d1e9;
  height: 30px;
  line-height: 26px;
  font-size: 1em;
  font-weight: 500;
  margin-top: 0px;
  display: none;
}

.tickTeam {
  width: 30px;
}

.teamSelecterDropdown {
  color: white;
  width: 250px;
  margin-top: 20px;
  border: solid #143051 2px;
  border-radius: 10px;
  padding-left: 18px;
  background: url("../../img/general/downArrowWhite.svg") no-repeat right
    #0077ff;
  -webkit-appearance: none;
  background-position-x: 224px;
  height: 35px;
  font-weight: 500;
  padding-top: 2px;
  font-size: 1.05em;
  /* margin: 0 auto; */
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
    #214978;
  border: 1px solid #143051;
  -webkit-appearance: none;
  /* background-position-x: 56px; */
  /* display: flex; */
  cursor: pointer;

  color: #496375;
  background-color: white;
  border: 1px solid #dde9f9;
  background-position-x: 224px;
}

.hpwValue {
  font-weight: 500;
  font-size: 1.1em;
  margin-top: 5px;
  line-height: 16px;
  padding-top: 2px;
  color: #fff;

  /* margin-bottom: -3px; */

  /* padding-top: 4px; */
  text-align: center;
  /* background-color: black; */
}

.h {
  font-weight: 500;
  font-size: 0.9em;
}

.perWeek {
  font-weight: 500;
  color: #cbdeeb;
  font-size: 0.8em;
  /* margin-top: -3px; */
  /* margin-bottom: 3px; */
}

.openAllTeams {
  background-color: #cbdeeb;
  color: #143051;
  /* min-height: 47px; */
  line-height: 36px;
  padding: 4px 10px;
  /* text-indent: 3px; */
  padding-top: 3px;
  font-weight: 500;
  /* max-height: 28px; */
  font-size: 0.9em;
  border-radius: 10px;
  border: 1px solid #143051;
  margin-bottom: 8px;
  margin-top: -9px;
  background-color: #516b8b;
  border: 1px #143051 solid;
  color: white;
  background-color: white;
  color: #496375;
  border-color: #dde9f9;
  /* margin-top: px; */
  /* opacity: 0.6; */
}

.noTeamsEditUserTxt {
  pointer-events: none;
}

.allTeamsQty {
  margin-left: 7px;
  background-color: #143051;
  /* width: 20px; */
  border-radius: 10px;
  font-size: 0.9em;
  /* min-width: 30px; */
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 2px;
  color: white;

  /* min-width: 28px; */
}

.showAllTeamsModalUnderlay {
  background: rgba(0, 0, 0, 0.5);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.pTeamTitle {
  min-width: 40%;
}

.allTeamsModalBox {
  background-color: white;
  min-width: 90vw;
  max-width: 90vw;
  /* min-height: 80vh; */
  max-height: 80vh;
  padding-top: 20px;
  /* padding-bottom: 20px; */
  /* height: 80vh; */
  border-radius: 15px;
  margin-top: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #143051;
  text-align: center;
  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
}

.allTeamsContentContainer {
  /* background-color: #00ccff; */
  max-width: 90%;
  min-width: 90%;
  /* display: flex; */
}

.allTeamsTeamRow {
  display: flex;
  justify-content: space-between;
  min-width: 90%;
  border-bottom: 1px solid #ddebf4;
  /* border-top: 1px solid #ddebf4; */
  padding-top: 14px;
  padding-bottom: 14px;
}

.allTeamsTeamRow:last-of-type {
  border: none;
}

.mgrKey {
  display: flex;
  /* justify-content: space-between; */
  /* max-width: 80%; */
  /* margin: 0 auto; */
  padding-bottom: 20px;
  /* margin-top: 17px; */
  justify-content: center;
}

.allTeamsTeamName {
  margin-top: 2px;
  margin-left: 5%;
  text-align: left;
  max-width: 60%;
  font-weight: 500;
  font-size: 0.9em;
  color: #8997a8;
  /* background-color: grey; */
}

.keyMgrIcon {
  min-width: 14px;
  max-width: 14px;
}

.allTeamsMgr {
  width: 18px;
  margin-right: 20px;
}

.allTeamsRemove {
  min-width: 20px;
  max-width: 20px;
  margin-right: 20px;
}

.allTeamsTitle {
  font-size: 1.2em;
  font-weight: 500;
  padding-bottom: 20px;
}

.mgrKeyTxt {
  font-size: 0.9em;
  text-align: left;
  color: #8997a8;
}

.mgrIconAndRemoveIconContainer {
  /* background-color: yellow; */
  display: flex;
  /* max-width: 30%; */
}

.teamsListContainer {
  overflow: scroll;
  max-height: 240px;
  padding-bottom: 10px;
}

.allTeamsFooterContainer {
  /* background-color: orange; */
  border-top: 2px solid #143051;
  min-height: 50px;
  line-height: 50px;
  font-size: 1em;
  font-weight: 500;
  color: #8ea7c5;
}

.hideMgrIcon {
  visibility: hidden;
}

.noTeamsText {
  /* font-size: 0.9em; */
  padding-top: 20px;
  color: #8ea7c5;
  padding-bottom: 20px;
}

/*  - - -  */
.isMgrNotMbrContainer {
  background-color: #e0ebf8;
  border-radius: 10px;
  width: 100%;
  color: #143051;
  padding: 10px;
  font-size: 0.8em;
  font-weight: 400;
  margin-top: 7px;
  margin-bottom: 18px;
  display: flex;
  /* text-align: left; */
}

.isMgrNotMbrText {
  max-width: 70%;
  margin: 0 auto;
  font-weight: 500;
  text-align: left;
}

.viewMgrNotMbrBtn {
  font-size: 0.9em;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 2px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  line-height: 26px;
  border-radius: 10px;
  min-height: 30px;
  max-width: 70px;
  margin: 2px auto;
  /* margin-bottom: 2px; */
}

.closeColour {
  background-color: #516b8b;
  max-width: 64px;
  min-width: 64px;
  /* padding-right: 3px; */
  /* padding-left: 3px; */
}

.mgrNotMbrData {
  /* margin-top: 18px; */
  /* margin-bottom: 6px; */
  display: block;
  max-width: 90%;
  margin: -18px auto;
  margin-bottom: 20px;
  background-color: #edf2f9;
  border-top: 2px solid #c1d1e6;
  border-radius: 0 0 10px 10px;
}

.mgrNotMbrRow {
  display: flex;
  padding-top: 14px;
  padding-bottom: 14px;
  border-top: 1px solid #8ea7c5;
  /* margin-bottom: 4px; */
  justify-content: space-between;
  max-width: 90%;
  margin: 0 auto;
}

.mgrNotMbrRow:first-of-type {
  display: flex;
  margin-top: 0px;
  /* margin-bottom: 4px; */
  justify-content: space-between;
  border: none;
}

.mgrNotMbrTeam {
  font-size: 0.9em;
  min-width: 75%;
  text-align: left;
  line-height: 22px;
  height: 22px;
  /* background-color: yellow; */
}

.mgrNotMbrMgrIconImg {
  /* min-width: 10%; */
  /* max-width: 10%; */
  /* max-height: 22px; */
  max-width: 16px;
  /* background-color: lime; */
}

.mgrNotMbrDeleteIconImg {
  /* background-color: red; */
  width: 10%;
  max-width: 20px;

  margin-left: 5%;
}

.mgrNotMbrQtyTxt {
  color: #00ccff;
}

/* shifts panel */
.userShiftsContainer {
  /* background-color: blue; */
  min-width: 100%;
}

.shiftRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 70px;
  border-bottom: 2px solid #e0ebf8;
  min-width: 100%;
  font-size: 0.9em;
  /* background-color: orange; */
  background-color: #ddebf4;
  background-color: white;
  border-radius: 10px;
  margin-top: 5px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.shiftRow:last-of-type {
  /* border-bottom: none; */
}

.shiftDateString {
  text-align: left;
  /* margin-left: 5px; */
  /* background-color: pink; */
  font-weight: 500;
  font-size: 1.2em;
  color: #496375;
  min-height: 24px;
  min-width: 110px;
}

.shiftTimesString {
  color: #496375;
  color: #859dbb;
  font-weight: 500;
  text-align: left;
  margin-top: -3px;
  font-size: 1.03em;
  /* margin-right: -3px; */
  /* margin-left: 5px; */
}

.shiftDateTxtSpan {
  /* min-width: 140px;
  max-width: 140px; */
  /* background-color: lime; */
}

.paidDayOff {
  font-size: 0.95em;
}

.shiftTilNameTxt {
  /* color: #fff; */
  font-size: 10px;
  text-align: left;
  margin-left: 5px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 5px;
  /* padding-top: 2px; */
  /* min-height: 30px; */
  /* padding-bottom: 2px; */
  /* background-color: #8ea7c5; */
  /* line-height: 15px; */
  max-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #bbcada;
  font-weight: 400;
  /* background-color: #214978; */
  border: 1px solid #bbcada;
  /* border: 1px solid #7f93ac; */
  /* margin-top: -1px; */
}

.shiftDurationString {
  color: #143051;
  margin-right: 5px;
  font-weight: 300;
  /* background-color: blue; */
  min-width: 70px;
  text-align: right;
}

.shiftsMonthsContainer {
  /* background-color: purple; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.shiftMonthSelectDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left;
  max-width: 88px;
  line-height: 26px;
  height: 30px;
  background: url("../../img/general/downArrowWhite2.svg") no-repeat right
    #214978;
  /* background: url('../../img/general/downArrowNavy.svg') no-repeat right #fff; */
  border: 1px solid #143051;
  -webkit-appearance: none;
  background-position-x: 66px;
  /* margin: 0 auto; */
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
    #214978;
  border: 1px solid #143051;
  -webkit-appearance: none;
  /* background-position-x: 56px; */
  display: flex;
  cursor: pointer;

  color: #496375;
  background-color: white;
  border: 1px solid #dde9f9;
  background-position-x: 64px;
}

.monthDurationTxt {
  /* color: #9a74eb; */
  color: #8ea7c5;
  font-weight: 500;
  margin-right: 15px;
  font-size: 1em;
}

.userShiftsHolder {
  /* background-color: #f5f5f5; */
  min-height: calc(100vh - 262px);
  max-height: calc(100vh - 262px);
  max-width: 100%;
  min-width: 100%;
  z-index: 0;
  position: relative;
  padding-top: 8px;
  overflow: scroll;
  padding-bottom: 100px;
  border-top: 1px solid #143051;
}

.adminAbsenceHolder {
  margin-right: 10px !important;
  /* padding-right: 0px; */
}

.adminUnitWidth {
  max-width: 100%;
  min-width: 100%;
}

/* background-color: #f5f5f5;
min-height: calc(100vh - 262px);
max-height: calc(100vh - 262px);
max-width: 100%;
min-width: 100%;
z-index: 0;
position: relative;
padding-top: 8px;
overflow: scroll;
padding-bottom: 100px; */

.shiftRowL {
  /* background-color: yellow; */
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 8%;
  max-width: 8%;
}

.shiftRowM {
  /* background-color: lime; */
  display: block;
  min-width: 60%;
  max-width: 60%;
  padding-left: 1px;
}

.horiztonalBallsUserShifts {
  margin-top: 20px;
}

.shiftRowR {
  /* background-color: orange; */
  display: block;
  min-width: 38%;
  min-width: 38%;
  max-width: 35%;
  /* padding-right: 80px; */
}

.alignRight {
  text-align: right;
  /* background-color: blue; */
  min-width: 110px;
  padding-right: 48px;
}

.shiftWorkedGreen {
  min-width: 9px;
  max-width: 9px;
  min-height: 9px;
  max-height: 9px;
  background-color: #a8cc9a;
  border-radius: 100px;
}

.shiftWorkedBlue {
  min-width: 6px;
  max-width: 6px;
  min-height: 6px;
  max-height: 6px;
  background-color: #e0ebf8;
  border-radius: 100px;
}

.shiftWorkedRed {
  min-width: 9px;
  max-width: 9px;
  min-height: 9px;
  max-height: 9px;
  background-color: #ba0000;
  border-radius: 100px;
}

.shiftWorkedRedAndGreen {
  min-width: 9px;
  max-width: 9px;
  min-height: 9px;
  max-height: 9px;
  background: rgb(186, 0, 0);
  background: linear-gradient(
    66deg,
    rgba(186, 0, 0, 1) 50%,
    rgba(168, 204, 154, 1) 50%
  );
  border-radius: 100px;
}

.shiftWorkedRedAndBlue {
  min-width: 9px;
  max-width: 9px;
  min-height: 9px;
  max-height: 9px;
  background: rgb(186, 0, 0);
  background: linear-gradient(
    66deg,
    rgba(186, 0, 0, 1) 50%,
    rgba(20, 48, 81, 1) 50%
  );
  border-radius: 100px;
}

.unworkedOpac {
  opacity: 0.7;
}

.ghostShiftUnit {
  background-color: #deedff;
  opacity: 1;
  border-bottom: 2px solid #cce3ff;

  /* display: none; */
}

.ghostDotColour {
  background-color: #a8cc9a;
}
/* Edit shift modal */
.editShiftModalUnderlay {
  background: rgba(0, 0, 0, 0.45);
  /* background-color: #fff; */
  /* height: 130px; */
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 100vw;
  position: fixed;
  z-index: 999999998;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.075s;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.editShiftModalBox {
  background-color: #f4f8fe;
  color: #143051;
  min-width: 380px;
  max-width: 380px;
  border-radius: 10px;
  padding-top: 20px;
  text-align: left;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 45px;
  cursor: auto;
  /* border: 1px solid #e7ebef; */
  /* -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
}

.addUserToTeamHeaderContainer {
  max-height: 45px;
}
.shiftCloseHolder {
  min-width: 70px;
  max-width: 70px;
  margin-left: -5px;
  min-width: 33%;
  max-width: 33%;
}

.headerTitleColumnIncludingPubStatus {
  display: flex;
  flex-direction: column;
}

.shiftPublishedStatus {
  max-height: 24px;
  line-height: 21px;
  background-color: #a8cc9a;
  border: 1px #6db451 solid;
  color: white;
  font-size: 0.85em;
  font-weight: 500;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  /* margin-top: -7px; */
  max-width: fit-content;
  margin-top: 10px;
  margin-bottom: -13px;
  margin-left: 20px;
  position: relative;
  z-index: 9999999;
  /* animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important; */
}

.unpubBg {
  background-color: #8997a8;
  border: 1px #5b728d solid;
}

.addUserToTeamTitle {
  text-align: center;
  /* background-color: black; */
  min-width: fit-content;
  padding-left: 14px;
  font-size: 18px;
  font-weight: 500 !important;
}

.shiftCloseHolderRight {
  /* margin-left: -10px; */
  min-width: 90px;
  max-width: 90px;
  margin-left: -35px;
}

.editShiftDurationTopRightTxt {
  text-align: right;
  font-size: 1em;
  font-weight: 500;
  color: #8ea7c5;

  /* margin-right: 13px; */
}

.editShiftModalFooter {
  /* background-color: purple; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 50px;
  min-height: 50px;
  border-top: 1px solid #bac5d3;
}

.deleteShiftBtn {
  font-weight: 500;
  font-size: 14px;
  font-weight: 500;
  color: #592eb6;
  text-align: center;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  min-height: 35px;
  max-height: 35px;
  /* line-height: 26px; */
  background-color: #859dbb;
  border: 1px solid #7c8fa5;
  align-items: center;
  margin-top: 0px;
  border-radius: 5px;
  /* padding-top: 2px; */
  justify-content: center;
  cursor: pointer;
  color: white;
  margin-left: 10px;
  min-width: 68px;
  max-width: 68px;
}

.deleteShiftBtn:hover {
  background-color: #7c8fa5;
}

.notSureDeleteShiftBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 72px;
  padding-left: 9px;
  padding-right: 9px;
  min-height: 35px;
  max-height: 35px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  min-width: 70px;
  flex-direction: row-reverse;
  margin-right: 1px;
}

.notSureDeleteShiftBtn:hover {
  background-color: #bbcada;
}

.pubShiftBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: fit-content;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 35px;
  max-height: 35px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
}

.pubShiftBtn:active {
  /* transform: scale(0.997); */
}

.pubShiftBtn:hover {
  background-color: #bbcada;
}

.needToPubColour {
  background-color: #07f;
  border: solid 1px #0469de;
  min-width: 70px;
  /* margin-right: -4px; */
}

.needToPubColour:hover {
  background-color: #0469de;
}
.closeEditShiftBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  max-width: 62px;
  min-width: 62px;
}
.closeEditShiftBtn:hover {
  background-color: #214978;
}

.closeMgrPurpleBtn {
  background-color: #663fa7 !important;
  border: 1px solid #e6eaee !important;
}

.editShiftModalBody {
  /* background-color: yellow; */
  border-top: solid 2px #e6eaee;
  border-right: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;

  border-radius: 5px 5px 0px 0px;
  max-width: 100%;
  /* max-width: 200px; */
  overflow: auto;
  overflow-x: hidden;
  /* max-height: 280px; */
  /* -ms-overflow-style: none; /* IE and Edge */
  /* scrollbar-width: none; Firefox */
  padding-top: 10px;
  background-color: rgb(252, 253, 255);
  background-color: white;
  max-height: 450px;
  min-height: 450px;
  /* min-width: 500px; */
}

/* .editShiftModalBody::-webkit-scrollbar {
  display: none;
} */

@media only screen and (max-width: 480px) {
  .editShiftModalBody {
    /* -ms-overflow-style: none; IE and Edge */
    /* scrollbar-width: none; Firefox */
    /* min-width: 500px; */
  }

  .editShiftModalBody::-webkit-scrollbar {
    /* display: none; */
  }

  .editShiftModalBox {
    min-width: 100vw;
    max-width: 100vw;
    margin-top: -80px;
  }
}

.editShiftRow {
  /* background-color: #fff; */
  border-bottom: 1px solid #e0ebf8;
  display: flex;
  padding-top: 3px;
  justify-content: space-between;
  /* min-height: 55px; */
}

.clickShiftRow {
  cursor: pointer;
}

.clickShiftRow:hover {
  background-color: #edf3f9;
}

.clickShiftRow:active {
  background-color: #f4f8fe;
}

.editShiftRow:last-of-type {
  border-bottom: none;
  padding-bottom: 15px;
}

.shiftEditTitle {
  margin-left: 18px;
  font-size: 18px;

  font-weight: 500;
  color: #143051;
}

.specialNameTitle {
  line-height: 22px;
  margin-top: 12px;
  margin-bottom: 12px;
  padding-right: 17px;
  color: #07f;
}

.shiftNoteRowSpace {
  /* background-color: pink; */
  margin-left: 5px;
  line-height: 20px;
  font-size: 16px;
  margin-top: -6px;
  /* max-width: 70%; */
  padding-bottom: 14px;
  padding-right: 10px;
  color: #496375;
  font-weight: 400;
  /* font-weight: 300; */
}

.editShiftPencil {
  margin-right: 18px;
  margin-top: 20px;
  cursor: pointer;
  min-width: 35px;
  max-width: 35px;
  min-height: 35px;
  max-height: 35px;
  border-radius: 5px;
  padding: 9px;
  border: #e3e8ee solid 2px;
  background-color: white;
}

.editShiftPencil:hover {
  border-color: #c5d1e1;
  /* opacity: 0.8; */
  /* transform: scale(1.1); */
}

.knobEditShift {
  margin-top: 6px;
  margin-right: 15px;
}

.absentBannerShift {
  text-align: center;
  background-color: #ba0000;
  color: white;
  max-height: 25px;
  line-height: 25px;
  font-size: 0.8em;
  margin-top: -10px;
  border-radius: 51px;
  max-width: 60px;
  margin: 0 auto;
  margin-top: -8px;
}

.editShiftInfoWarning {
  font-size: 0.72em;
  line-height: 20px;
  margin-left: 8px;
  /* max-width: 300px; */
  margin-top: 0px;
  margin-bottom: 6px;
  min-width: fit-content;
}

.absentBannerShiftSpacebelow {
  /* background-color: black; */
  min-height: 15px;
  min-width: 100%;
}

.shiftRateDropdown {
  color: #496375;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  padding-left: 14px;
  text-align: left;
  max-width: 78px;
  line-height: 26px;
  font-weight: 500;
  height: 35px;
  background: url("../../img/general/downArrowGrey.svg") no-repeat right #fff;
  /* background: url('../../img/general/downArrowNavy.svg') no-repeat right #fff; */
  border: 2px solid #dde9f9;
  -webkit-appearance: none;
  background-position-x: 54px;
  margin-top: 4px;
  margin-right: 22px;
  cursor: pointer;
}

.shiftRateDropdown:hover {
  border-color: #bac5d3;
}
.knobRed {
  background-color: #ba0000;
}

.txtRed {
  color: #ba0000;
}

.txtGreen {
  color: #6db451;
}

.shiftBrkInput {
  background-color: #fff;
  max-width: 60px;
  line-height: 35px;
  height: 35px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 0.9em;
  font-weight: 500;
  padding-top: 2px;
}

.shiftBrkInput:hover {
  border-color: #bac5d3;
}

.shiftDataTickImg {
  max-width: 28px;
  background-color: #00ccff;
  border: 1px solid #143051;
  border-radius: 5px;
  max-height: 28px;
  margin-right: 20px;
  margin-top: 9px;
  cursor: pointer;
}

.startEndShiftContainer {
  display: flex;
  align-items: center;
  max-width: 190px;
  /* background-color: #00ccff; */
  /* margin-left: 100px; */
  /* padding-top: -10px; */
  margin-top: 6px;
  margin-bottom: 4px;
}

.editShiftTimesUntilTxt {
  font-size: 11px;
  padding-left: 7px;
  padding-right: 9px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}

.shiftTimeInput {
  background-color: #fff;
  min-width: 77px;
  max-width: 77px;

  line-height: 30px;
  padding-top: 0px;
  height: 35px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 16px;
  font-weight: 500;
  /* -webkit-appearance: none; */
  /* -moz-appearance: none; */
  /* appearance: none; */
  cursor: text;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-left:8px; */
}

.shiftTimeInput:hover {
  border-color: #bac5d3;
}

.shiftEditDatePickerContainer {
  background-color: orange;
  /* max-height: 150px; */
  /* min-width: 00px; */
}

.usrDatePickerStartDate {
  /* background-color: pink; */
  /* margin-top: -30px; */
  position: relative;
  z-index: 1;
}

.datepickerShiftTickImg {
  /* margin-top: -120px; */
}

.saveShiftDateBtn {
  margin: 0 auto;
  text-align: center;
  /* background-color: blue; */
  max-width: 100px;
  max-height: 30px;
  line-height: 30px;
  position: relative;
  z-index: 2;
  margin-top: 30px;
  margin-bottom: -35px;
  font-size: 0.9em;
  color: #00ccff;
  margin-top: 4px;
}

.closeShiftDatepickerBtn {
  /* background-color: #8ea7c5; */
  font-size: 0.9em;
  /* color: white; */
  line-height: 25px;
  text-align: center;
  margin: 0 auto;
  border-radius: 15px;
  height: 25px;
  margin-top: 3px;
  /* max-width: 66px; */
}

.topEditShiftRow {
  border-bottom: none;
  height: 35px;
  /* padding-bottom: -10px; */
}

.editShiftCloseDatePickerImg {
  width: 15px;
  margin-right: 12px;
  margin-top: 10px;
}

.centerDatePicker {
  /* background-color: purple; */
  margin: 0 auto;
}

.shiftDatepickerBg {
  /* background-color: #00ccff; */
}

.shiftAlreadyExistsUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.shiftAlreadyExistsModalBox {
  background-color: white;
  min-width: 75vw;
  max-width: 75vw;
  /* min-height: 90vh;
max-height: 90vh; */
  /* height: 80vh; */
  border-radius: 15px;
  /* margin-top: -40px; */
  /* display: flex; */
  /* justify-content: center; */
  align-items: center;
  color: #143051;
  margin-top: -100px;
  text-align: center;
  /* position: fixed;
bottom: 0;
text-align: center; */
  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  animation-name: moveEditTeamUserBoxUp !important;
  animation-duration: 0.1s !important;
}

.shiftAlreadyExsitsModalTxt {
  max-width: 90%;
  /* background-color: yellow; */
  margin: 0 auto;
  margin-top: 20px;
  line-height: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 500;
}

.closeShiftAlreadyExistsModalBtn {
  background-color: #214978;
  border: 1px solid #143051;
  color: white;
  min-height: 32px;
  line-height: 30px;
  max-width: 90px;
  border-radius: 35px;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 20px;
  font-weight: 500;
}

.addShiftAddBtn {
  position: fixed;
  top: calc(100% - 148px);
  right: 45px;
  background-color: #3b597c;
  border: 1px solid #143051;
  color: white;
  border-radius: 5px;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  line-height: 41px;
  font-size: 2.5em;
  z-index: 9999999;
}

.addShiftModalBox {
  background-color: #f4f8fe;
  color: #496375;
  min-width: 300px;
  max-width: 300px;
  border-radius: 10px;
  text-align: left;
  line-height: 45px;
  animation-name: zoomModalIn;
  animation-duration: 0.1s;
  cursor: default;
}

.page0AddShiftModalUp100px {
  /* margin-top: -140px; */
}

.addShiftModalHeader {
  display: flex;
  /* background-color: blue; */
  justify-content: space-between;
  margin-top: 5px;
  /* border-bottom: 1px solid #5b728d; */
}

.closeAddShiftModalImg {
  min-width: 14px;
  max-width: 14px;
  /* padding-right: 66px; */
  margin-left: 20px;
  cursor: pointer;
  margin-right: 20px;
}

.closeAddShiftModalImg:hover {
  opacity: 0.8;
}

.closeAddShiftModalImgRight {
  min-width: 14px;
  margin-right: 20px;
  visibility: hidden;
}

.shiftDurationTopRightOfAddShiftModal {
  min-width: 80px;
  max-width: 80px;
  font-size: 1em;
  /* margin-top: 7px; */
  padding-right: 8px;
  text-align: center;
  margin-top: 1px;
  color: #496375;
  font-weight: 500;
}

.shiftDurationTopRightOfAddShiftModalHidden {
  min-width: 80px;
  max-width: 80px;
  font-size: 0.94em;
  padding-right: 8px;
  text-align: center;
  margin-top: 1px;
  /* color: #c0d1e9; */
  visibility: hidden;
}

.addShiftHeaderTitle {
  font-size: 18px;
  padding-top: 4px;
  /* margin-top: 2px; */
  /* margin-left: 55px; */
  font-weight: 500;
  color: #143051 !important;
}

.addShiftMidContainer {
  /* background-color: white; */
  /* max-width: 90%; */
  max-height: 320px;
  min-height: 320px;
  margin: 0 auto;
  margin-top: 10px;
  text-align: center;
  /* border-top: 2px solid #e6eaee; */
  padding-bottom: 20px;
  border-radius: 6px 6px 0px 0px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
  padding-top: 0px;

  /* min-height: 310px;
  max-height: 310px; */
}

.minHeightKBopen {
  /* min-height: 350px; */
  /* overflow: scroll; */
  /* max-height: 330px; */
  /* margin-bottom: -20px; */
  /* background-color: blue; */
}

.heightenWhenPublicKBisOpen {
  min-height: 100px;
}

.hideBinOnInitialMpwTimelineObj {
  pointer-events: none;
  visibility: hidden;
}

.addShiftInputLabel {
  margin-bottom: -10px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 7px;
  margin-left: 5px;
  color: #214978;
}

.overtimeRateContainer {
  max-height: 100px;
  margin-left: 10px;
  /* background-color: blue; */
}

.addOvertimeRateTitle {
  /* margin-bottom: -10px; */
  font-size: 1em;
  font-weight: 500;
  /* margin-top: -2px; */
  margin-top: 22px;
  margin-bottom: -8px;
  margin-left: -14px;
}

.pullpublicnotelabelupabit {
  margin-top: -12px;
}

.addShiftDateInput {
  border-radius: 5px;
  /* margin-top: -30px; */
  border: none;
  background-color: #fff;
  color: #496375;
  min-height: 35px;
  line-height: 35px;
  font-size: 16px !important;
  padding-left: 14px;
  padding-right: 8px;
  letter-spacing: 1px;
  padding-top: 1px;
  /* text-align: center; */
  font-weight: 500;
  max-width: 174px;
  min-width: 174px;
  background: url("../../img/general/calicongrey.svg") no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 142px;
  border: 2px solid #dde9f9;
  margin-left: 0px;
  margin-top: 4px;
  cursor: text;
  /* -webkit-min-logical-width:150px ; */
  /* text-align: left; */
  /* animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important; */

  /* margin-bottom: 2px; */

  /* margin-top: -10px; */
}

.addShiftDateInput:hover {
  border-color: #bac5d3;
}

.invalidInputDate {
  border: 1px solid #ba0000;
  /* background-color: #cc4d4d; */
  color: #cc4d4d;
}

.startDateIsBeforeUserJoinedWarningTxt {
  font-size: 0.8em;
  line-height: 18px;
  max-width: 80%;
  /* margin: 0 auto; */
  margin-top: 5px;
  margin-bottom: -25px;
  font-weight: 500;
  text-align: left;
  opacity: 0.8;
  /* background-color: yellow; */
  min-height: 20px;
  max-height: 20px;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  /* color: white; */
  -webkit-appearance: none;
}

input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
  /* background-color: blue; */
}

.addShiftTimeContainer {
  display: flex;
  min-width: 98%;
  max-width: 98%;
  justify-content: flex-start;
  /* margin: 0 auto; */
}

.addShiftTimesContainer {
  min-width: 100%;
  /* background-color: pink; */
  margin-left: -4px;
  /* display: flex; */
}

.addShiftTimesStartAndEnd {
  display: flex;
  justify-content: flex-start;
  max-height: 38px;
  font-weight: 500;
}

.addShiftStartTitle {
  margin-left: 3px;
}

.addShiftEndTitle {
  margin-left: 60px;
}
.addShiftStartTimeInput {
  background-color: #fff;
  width: 77px;
  max-width: 77px;
  /* padding-left: 4px; */
  line-height: 30px;
  padding-top: 0px;
  height: 30px;
  border: 1px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 0.9em;
  font-weight: 500;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.addShiftBreakRow {
  margin-top: 12px;
}

/* inputs up and above, when making responsive, for desktop, remove paddning-left and make text-align center */

.addShiftEndTimeInput {
  background-color: #fff;
  width: 77px;
  max-width: 77px;
  /* padding-left: 4px; */
  line-height: 30px;
  padding-top: 0px;
  height: 30px;
  border: 1px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 0.9em;
  font-weight: 500;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.addShiftEndMarginLeft {
  margin-left: 18px;
}

.addShiftDivider {
  min-height: 1px;
  background-color: #e0ebf8;
  margin-top: 14px;
}

.lowerLevelInputlabel {
  margin-top: 2px;
  padding-top: 20px;
}

.fixedDividerAddShift {
  position: relative;
  margin-top: -84px;
  /* top: -200px; */
  margin-bottom: 80px;
}

.addShiftLoadTempBtn {
  font-size: 0.9em;
  color: #fff;
  background-color: #214978;
  border: solid #143051 2px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  margin-right: 12px;
  line-height: 26px;
  border-radius: 5px;
  min-height: 30px;
  /* margin-top: 18px; */
  /* margin-bottom: 8px; */
  margin-left: 84px;
  cursor: pointer;
  min-width: fit-content;
}

.addShiftBreakInput {
  background-color: #fff;
  width: 57px;
  max-width: 77px;
  /* padding-left: 4px; */
  line-height: 30px;
  padding-top: 0px;
  height: 30px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 0.9em;
  font-weight: 500;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.addTilBreakInput {
  background-color: #fff;
  width: 57px;
  max-width: 77px;
  /* padding-left: 4px; */
  line-height: 30px;
  padding-top: 0px;
  height: 30px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 0.9em;
  font-weight: 500;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-left: -26px;
}

.addShiftFooter {
  /* background-color: orange; */
  /* margin-top: 20px; */
  display: flex;
  align-items: center;
  min-width: 90%;
  max-width: 90%;
  padding-top: 2px;
  padding-bottom: 5px;
  justify-content: space-between;
  align-items: center;
  /* border-top: 2px solid #143051; */
  margin: -4px auto;
  max-height: 50px;
  margin-bottom: 8px;
}

.addShiftTooLateEarlyTxt {
  color: #bbcada;
  line-height: 14px;
  font-size: 12px;
  margin-left: 6px;
  font-weight: 500;
  border-left: 2px solid #bbcada;
  padding-left: 11px;
  padding-right: 20px;
}

.addShiftFooterWithNoTopBorder {
  /* background-color: orange; */
  /* margin-top: 20px; */
  display: flex;
  align-items: center;
  min-width: 80%;
  max-width: 80%;
  padding-top: 4px;
  padding-bottom: 7px;
  justify-content: space-between;
  align-items: center;
  /* border-top: 2px solid #143051; */
  margin: 0 auto;
}

.cancelAddShiftTxt {
  font-size: 16px;
  font-weight: 500;
  margin-top: 3px;
  /* background-color: pink; */
  min-width: 42px;
  margin-right: 8px;
  margin-left: 10px;
  cursor: pointer;
  color: #859dbb;
}

.cancelAddShiftTxt:hover {
  color: #516b8b;
}

.submitAddShiftOnUser {
  min-height: 35px;
  /* line-height: 31px; */
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: 63px;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #0bf;
  border: 1px solid #0af;
}

.submitAddShiftOnUser:hover {
  background-color: #0af;
}
.addBtnColours {
  background-color: #07f;
  border: #0469de 1px solid;
}

.fadedSubmit {
  opacity: 0.4;
  pointer-events: none;
}

.breakRateContainerAddShift {
  display: flex;
  max-width: 230px;
  margin: 0 auto;
  justify-content: space-around;
}

.addShiftRateDropdown {
  color: #496375;
  border-radius: 5px;
  font-size: 1em;
  font-weight: 500;
  padding-left: 14px;
  text-align: left;
  max-width: 78px;
  line-height: 26px;
  font-weight: 500;
  height: 30px;
  background: url("../../img/general/downArrowGrey.svg") no-repeat right #fff;
  /* background: url('../../img/general/downArrowNavy.svg') no-repeat right #fff; */
  border: 1px solid #dde9f9;
  -webkit-appearance: none;
  background-position-x: 54px;
  margin-top: 6px;
  cursor: pointer;
  /* margin-right: 12px; */
}

.addShiftRateContainer {
  /* background-color: blue; */
  max-height: 70px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 12px;
}

.rateLabelAddShift {
  margin-top: -7px;
  margin-bottom: -12px;
  margin-left: 3px;
}

.clearTxtAddShift {
  opacity: 0.5;
  margin-left: 10px;
}

.shiftMenuImg {
  max-width: 25px;
  margin-top: -65px;
}

.shiftTeamDropdown {
  max-width: 277px;
  min-width: 80%;
  background: url("../../img/general/downArrowWhite.svg") no-repeat right
    #687d97;
  -webkit-appearance: none;
  background-position-x: 92%;
  border-radius: 100px;
  color: white;
  padding-left: 14px;
  font-size: 1em;
  text-align: left;
  min-height: 35px;
  line-height: 33px;
  border: 1px solid #36495f;
  font-weight: 500;
  /* font-size: */
}

.addShiftNoteTextBox {
  /* margin: 0 auto; */
  min-width: 90%;
  min-height: 140px;
  resize: none;
  padding: 5px;
  border: 1px solid #36495f;
  border-radius: 5px;
  background-color: #859dbb;
  color: white;
  margin-top: 10px;
  font-weight: 500;
  font-size: 1.1em;
  overflow: scroll;
  letter-spacing: 0px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.addNoteToAddShiftBtn {
  background-color: #6e7f94;
  border-radius: 25px;
  font-size: 1em;
  max-width: 114px;
  height: 35px;
  line-height: 31px;
  margin: 0 auto;
  margin-top: 20px;
  color: white;
  font-weight: 500;
  border: 1px solid #36495f;
}

.addShiftAddTagsContainer {
  display: flex;
  /* background-color: orange; */
}

.mgrPublicNoteSpacer {
  /* background-color: red; */
  min-height: 18px;
  min-width: 30px;
}

.shiftTemplateUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.shiftTemplateModalBox {
  background-color: #f4f9ff;
  min-width: 300px;
  max-width: 300px;
  border-radius: 10px;
  align-items: center;
  color: #143051;
  /* position: fixed;
  top: 30%;
  left: 10%; */
  text-align: center;

  margin: 0 auto;
  cursor: default;
}

.shiftTemplateModalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 1px solid #c0d1e9; */
  /* background-color: #f4f9ff; */
  min-height: 54px;
}

.closeShiftTemplateModalImage {
  max-width: 14px;
  /* padding-right: 6px; */
  min-width: 14px;
  margin-left: 20px;
  cursor: pointer;
  /* padding-ir */
}

.closeShiftTemplateModalImage:hover {
  opacity: 0.8;
}

.emptyShiftTemplateShiftModalHeaderSpaceRight {
  max-width: 12px;
  min-width: 12px;
  margin-right: 15px;
}

.shiftTemplateHeaderTitleTxt {
  font-size: 18px;
  /* margin-top: 4px; */
  font-weight: 500;
}

.shiftTemplateModalContentContainer {
  background-color: #fff;
  max-height: 300px;
  overflow-y: scroll;
  padding-top: 20px;
  padding-bottom: 15px;
  max-width: 86%;
  margin: 0 auto;
  margin-bottom: 20px;
  border: 1px solid #e6eaee;
  border-top: 2px solid #e6eaee;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left:5px;
}

.shiftTemplateUnitTxt {
  line-height: 2.5em;
  font-weight: 500;
  font-size: 16px;
  color: #496375;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  background-color: white;
  max-width: 75%;
  margin: 6px auto;
  cursor: pointer;
  text-align: left;
  min-width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 240px !important;
  min-width: 240px !important;

}

.shiftTemplateUnitTxt:hover {
  border-color: #bac5d3;
  background-color: #f4f9ff;
}

.shiftTemplateSpan {
  font-weight: 500;
  font-size: 0.7em;
}

.fadeAndNoPointer {
  opacity: 0.5;
  pointer-events: none;
}

.greenSubmitBtnBg {
  background-color: #0077ff;
  color: white;
}

.closeMgrNoteBtn {
  background-color: white;
  display: flex;
  justify-content: space-around;
  max-height: 28px;
  line-height: 26px;
  border-radius: 25px;
  margin: 0 auto;
  max-width: 116px;
  margin-top: 25px;
  font-weight: 500;
  border: 1px solid #143051;
}

.addShiftInputLabelActive {
  font-size: 0.9em;
  color: #496375;
  padding-left: 6px;
}

.greyCloseBtnAddShiftNote {
  max-width: 10px;
  margin-right: 8px;
}

.addShiftNotesDivider {
  min-height: 1px;
  min-width: 100px;
  background-color: #5b728d;
  margin-top: 30px;
}

.abovePublicSpacer {
  min-height: 20px;
}

.addShiftPageKeyContainer {
  /* background-color: black; */
  margin-top: 12px;
  min-height: 10px;
  min-width: 100px;
}

.addShiftPageKeyBox {
  /* background-color: blue; */
  min-height: 10px;
  max-width: 20px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.addShiftKey {
  min-height: 5px;
  min-width: 5px;
  max-width: 5px;
  max-height: 5px;
  background-color: #859dbb;
  border-radius: 100%;
}

.addShiftKeySel {
  min-height: 7px;
  min-width: 7px;
  max-width: 7px;
  max-height: 7px;
  background-color: #143051;
  border-radius: 100%;
}

.hourlyRateContainer {
  display: flex;
  /* background-color: orange; */
  align-items: center;
}

.notEditingHourlyRate {
  /* pointer-events: none;
  background-color: white;
  color: #143051;
  border: 1px solid #c0d1e9; */
}

.displayedHourlyRateNonEdit {
  display: flex;
}

.saveHourlyRateTickImg {
  width: 28px;
  margin-left: 10px;
  border: 1px solid #143051;
  border-radius: 8px;
  background-color: #8ea7c5;
}

.hourlyRateVal {
  font-size: 1.1em;
  /* margin-bottom: -2px; */
  margin-right: 16px;
  font-weight: 400;
  color: #8997a8;
  margin-top: 4px;
  padding-bottom: 2px;
}

.editHourlyRatePencil {
  /* margin-top: 1px; */
}

.pullHourlyRateToZeroImg {
  margin-right: 10px;
  width: 22px;
  opacity: 0.5;
}

.powerBtnForHourlyRateImg {
  margin-right: 15px;
  width: 22px;
  opacity: 0.5;
}

.hourlyRateTitleAndHistoryBtnContainer {
  /* background-color: orange; */
  display: flex;
  max-width: 100px;
  align-items: center;
  justify-content: space-between;
  margin-left: 3%;
}

.hourlyRateHistoryImg {
  margin-top: 6px;
}

.hourlyRateTimelineRow {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
  cursor: pointer;
}

.mpwTimelineUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.hourlyRateTimelineContainer {
  background-color: #fff;
  /* border: 1px solid #eaf3fd; */
  display: flex;
  flex-direction: column;
  min-width: 340px;
  max-width: 340px;
  /* max-height: 80vh; */
  margin: 0 auto;
  padding-bottom: 5px;
  padding-top: 15px;
  border-radius: 10px;
  cursor: default;
  animation-name: zoomModalIn;
  animation-duration: 0.1s;
  /* border-bottom: 1px solid #e6eaee; */
  /* margin-bottom: 20px; */
}

.timelineObjDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid #c0d1e9 2px;
  background-color: #deedff;
  border-radius: 10px;
  min-height: 180px;
  /* margin-top: 15px; */
  max-width: 90%;
  margin: 15px auto;
  /* border: 1px solid black; */
}

.selectedTimelineObjDiv {
  background-color: #c1d1e6;
}

.timelineObjDiv:last-of-type {
  /* background-color: yellow; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: solid #fff 0px; */
}

.timelineObjLeft {
  /* background-color: red; */
  min-width: fit-content;
  display: block;
  text-align: left;
  /* text-indent: 18px; */
  font-size: 0.9em;
  min-width: 185px;
  min-height: 40px;
  align-items: center;
  padding-top: 16px;
  margin-left: 10px;
}

.timelineObjEdit {
  /* background-color: lime; */
  /* padding-right: 5%; */
  margin-top: 20px;
  min-width: 60px;
  text-align: center;
  display: flex;
  margin-left: 17px;
  padding-bottom: 20px;
}

.timelineObjMiddle {
  margin-top: 20px;
  margin-right: 10px;
}

.timelineObjToTxt {
  font-size: 0.85em;
  /* padding-left: 15px; */
  /* padding-top: 8px; */
  padding-bottom: 5px;
  color: #8ea7c5;
  background-color: #8ea7c5;
  background-color: #36495f;
  color: white;
  font-weight: 500;
  max-width: 52px;
  text-align: center;
  padding-left: 10px;
  padding-right: 12px;
  padding-top: 6px;
  border-radius: 10px;
  line-height: 14px;
  margin-left: 5%;
  margin-bottom: 10px;
  /* padding-right: 12px; */
}

.pushDown12px {
  margin-top: 12px;
}

.timelineObjStartTxt {
  margin-left: 8px;
  letter-spacing: 2px;
  /* margin-top: 22px; */
  font-weight: 500;
  color: #6e7f94;
  padding-left: 8px;
  /* margin-top: 3px; */
}

.fromuntilspacer {
  background-color: #bfdcf0;
  max-height: 2px;
  margin-bottom: 12px;
  margin-top: 12px;
}

.timelineObjEndTxt {
  margin-left: 8px;
  letter-spacing: 2px;
  margin-bottom: 18px;
  font-weight: 500;
  color: #6e7f94;
  padding-left: 8px;
}

.killLeftMargin {
  margin-left: 8%;
  min-width: 100px;
}

.hourlyRateHeaderBar {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  padding-left: 3%;
  padding-right: 3%;
}

.hourlyTimelineHeading {
  text-align: left;
  /* margin-left: 3%; */
  font-size: 0.8em;
  font-weight: 500;
  background-color: #516b8b;
  color: white;
  height: 30px;
  line-height: 32px;
  min-width: 165px;
  text-align: center;
  border-radius: 20px;
  display: flex;
}

.closeHourlyRateHistory {
  max-width: 10px;
  margin-left: 10px;
  margin-right: 12px;
}

.removeTimeline {
  font-size: 14px;
  font-weight: 500;
  color: white;
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  background-color: #3b597c;
  border: solid #214978 1px;
  cursor: pointer;
  color: #fff;
}

.removeTimeline:hover {
  background-color: #214978;
}

.removeTimelineSure {
  font-size: 13px;
  font-weight: 500;
  color: white;
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  border: solid 1px #ba0000;
  background-color: #cc4d4d;
}

.editTimelineObjImg {
  margin-right: 18px;
  max-width: 20px;
  margin-left: -6px;
  min-width: 20px;
}

.saveTimelinePeriodBtnImg {
  max-width: 20px;
  /* margin-right: 13px; */
  min-width: 30px;
  border: 1px solid #496375;
  border-radius: 10px;
  background-color: #00ccff;
  cursor: pointer;
}

.addHourlyRateContainer {
  /* background-color: yellow; */
  display: flex;
  justify-content: flex-end;
  padding-right: 6%;
  margin-top: 5px;
}

.addHourlyRateTimelineObject {
  text-align: left;
  background-color: #0af;
  border: 1px solid #07f;
  height: 35px;
  align-items: center;
  text-indent: 10px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  color: white;
  border-radius: 5px;
  margin-top: 2px;
  font-weight: 500;
  cursor: pointer;
  color: #fff;
  min-width: 73px;
  /* padding-left: 2px; */
  padding-right: 2px;
}

.addHourlyRateTimelineObject:hover {
  background-color: #0fa2eb;
}

.addHourlyRateObjTxtAdd {
  /* margin-top: -3px; */
}

.whitePlusForAddingTimelineObj {
  /* margin-top: -1px; */
  margin-right: 4px;
  margin-left: 4px;
  max-width: 22px;
  /* font-size: 600; */
}

.editPeriodValid {
  background-color: #214978;
}

.originalDateShadowed {
  font-weight: 400;
  margin-left: 24px;
}

.editRateContainer {
  /* background-color: #6e7f94; */
  border-bottom: 2px solid #6e7f94;
  /* color: white; */
  /* text-align: left; */
  /* border-radius: 30px; */
  max-width: fit-content;
  padding-top: 3px;
  padding-bottom: 2px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 500;
  margin-bottom: 3px;
  /* margin-right: 11px; */
}

.borderless {
  border: none;
}

.cancelEditTimelinePeriodHourlyRate {
  max-width: 22px;
  min-width: 22px;

  margin-right: 12px;
  /* margin-left: 3px; */
  margin-top: 3px;
}

.timelineDisclaimerTxt {
  font-size: 0.8em;
  max-width: 90%;
  margin: 0 auto;
  text-align: left;
  margin-top: 15px;
  line-height: 1.5em;
}

.hourlyRateExplainedBtnContainer {
  display: flex;
  justify-content: flex-end;
}

.explainedHourlyRateTimeline {
  background-color: #fff;
  border: solid 1px #e6eaee;
  display: flex;
  /* color: #7495ba; */
  /* color: white; */
  font-size: 16px;
  max-width: 120px;
  min-width: 120px;
  padding-left: 10px;
  text-align: center;
  line-height: 25px;
  height: 26px;
  border-radius: 15px;
}

/* .explainedBookIcon {
  max-width: 15px;
  margin-left: 10px;
} */

/* .hourlyTimelineHeading {
  max-width: 90px;
} */

.addOrRemovePeriodContainer {
  display: flex;
  justify-content: center;
  min-width: 100%;
  /* padding-right: 5%; */
  padding-bottom: 10px;
  /* background-color: black; */
}
.hourlyRateTimeRemoveAllAddBtn {
  display: flex;
  /* background-color: #6e7f94; */
  min-width: 90%;
  max-width: 90%;
  justify-content: space-between;
  /* border: 1px solid #143051; */

  /* margin: 0 auto; */
  /* margin-top: 20px; */
  /* text-align: right; */
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;

  border-radius: 10px;
}

/* make this uber-responsive */
.hourRateTimelineHeaderDivider {
  background-color: #c0d6f0;
  min-height: 1px;
  max-height: 1px;
  margin-top: 17px;
  min-width: 70px;
}

.timelineObjRightSide {
  display: block;
  /* background-color: blue; */
  min-width: 104px;
  margin-right: 10px;

  /* margin-right: 10px; */
}

.schedRowunitMiddle {
  /* background-color: lime; */
  width: 35%;
  max-width: 90px;
  display: flex;
  justify-content: center;
}

.deleteTimelineObjImg {
  max-width: 23px;
  margin-right: 22px;
}

.newObjBg {
  background-color: #c0d1e9;
  border-radius: 10px;
}

.onGoingHourlyRateTxt {
  background-color: #dde9f9;
  max-width: 260px;
  margin: 0 auto;
  font-size: 0.7em;
  text-align: center;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 15px;
  color: #687d97;
  margin-top: 15px;
  font-weight: 400;
  text-align: left;
}

.timelineInstruction {
  font-size: 12px;
  max-width: 92%;
  font-weight: 400;
  padding-left: 25px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: left;
  color: #516b8b;
  line-height: 16px;
}

.x0019kw {
  margin-top: 20px;
}
.hourlyRateHistoryBtnContainer {
  /* display: flex;
    background-color: #516b8b;
    justify-content: space-around;
    margin-left: 14px;
    min-height: 28px;
    max-height: 28px;
    line-height: 30px;
    color: white;
    min-width: 85px;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 0.9em;
    border-radius: 15px; */
  /* background-color: #cbdeeb; */
  color: #143051;
  /* min-height: 37px; */
  display: flex;
  line-height: 18px;
  align-items: center;
  padding: 0px 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  /* text-indent: 3px; */
  font-weight: 500;
  max-height: 30px;
  font-size: 0.9em;
  border-radius: 10px;
  /* border: 1px solid #143051; */
  margin-left: 18px;
  margin-top: 0px;
  background-color: #516b8b;
  border: 1px #143051 solid;
  color: white;
  background-color: white;
  color: #496375;
  border-color: #dde9f9;
  /* /* opacity: 0.6; */
}

.whiteCrossToCloseTimeline {
  margin-left: 7px;
  max-width: 12px;
  /* margin-top: 1px; */
}

/* .hourlyRateHistoryImg {
  max-width: 18px;
  margin-top: 1px;
} */

.editShiftNoteOnAdmin {
  /* margin: 0 auto; */
  /* min-width: 100%; */
  min-height: 130px;
  resize: none;
  padding: 5px;
  border: 1px solid #dde9f9;
  border-radius: 5px;
  background-color: white;
  /* color: #496375; */
  font-size: 1em;
  overflow-y: scroll;
  line-height: 20px;
  font-size: 16px;
  margin-top: 0px;
  max-width: 94%;
  min-width: 94%;
  /* margin-left: 5%; */

  margin-bottom: -15px;
  font-weight: 400;
  color: #496375;

  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;
  /* margin-right: 4px; */
  color: #6a8bb1;
  /* min-height: 35px; */
  padding-left: 5px;
  font-weight: 400;
}

.editShiftNoteOnAdmin::placeholder {
  color: #6a8bb1;
}

.editShiftNoteOnAdmin:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 6px;
  background-color: #fdfeff;
}
.saveShiftTagImg {
  /* margin-right: 12px; */
  border: 1px solid #143051;
  background-color: #00ccff;
  border-radius: 8px;
}

.saveOrClearTagValue {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-right: 7px;
  max-height: 96px;
}

.clearTagValue {
  min-height: 35px;
  line-height: 26px;
  color: white;
  font-weight: 500;
  border-radius: 5px;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: -6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  /* margin-bottom: -2px; */
  cursor: pointer;
  background-color: #cbd7e6;
  border: 1px solid #bbcada;
}

.clearTagValue:hover {
  background-color: #bbcada;
}

.noteRow {
  padding-bottom: 30px;
}
.shiftTagHolder {
  min-width: 88%;
  max-width: 85%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 7px;
  margin-top: -9px;
  /* background-color: #f4f8fe; */
  border-radius: 3px;
  margin-bottom: 8px;
  padding-left: 4px;
  padding-right: 4px;
  /* border: 1px solid #e3e8ee; */
}

.tagUnit {
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: -moz-fit-content;
  max-height: fit-content;
  align-items: center;
  border-radius: 4px;
  color: #496375;
  font-weight: 500;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  color: #4f566b;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  margin-left: 4px;
  margin-top: 5px;
}

.tagUnit:first-child {
  margin-left: 0px;
}

.tagTxt {
  line-height: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-weight: 600;
  color: #143051;
}

.addTagUnit {
  /* background-color: blue; */
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 35px;
  line-height: 28px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #143051;
  margin-top: 5px;
  margin-right: 5px;
  background-color: #0077ff;
  color: white;
  min-height: 40px;
  margin-top: 13px;

  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;
  margin-right: 4px;
  color: #6a8bb1;
  min-height: 35px;
  padding-left: 5px;
  font-weight: 500;
  border: 2px solid #dde9f9;
  background-color: white;
  border-radius: 5px;
}

.pullTagImg {
  max-width: 10px;
  min-width: 10px;
  margin-left: 8px;
  /* margin-top:   3px; */
  cursor: pointer;
}

.pullTagImg:hover {
  opacity: 0.8;
  /* max-width: 12px; */
  /* min-width: 12px; */
}

.addTagIcon {
  min-width: 22px;
  max-width: 22px;
  padding: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.tagInput {
  /* background-color: #f4f8fe; */
  border: none;
  color: #496375;
  /* border-bottom: 1px solid #214978; */
  font-weight: 500;
  font-size: 14px;
  min-height: 30px;
  text-indent: 6px;
  font-family: "timelify";
  /* font-style: italic; */
  /* text-decoration: underline; */
  /* opacity: 0.75; */
  /*  */
  /* text-decoration: underline; */
}

.tagInput::placeholder {
  color: #bbcada;
  font-size: 15px;
}

.addTagImage {
  min-width: 20px;
  margin-right: 17px;
  cursor: pointer;
}

.availableTagsUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.availableTagsModal {
  background-color: #fff;
  min-width: 320px;
  max-width: 320px;
  min-height: 400px;
  max-height: 400px;
  border-radius: 10px;
  align-items: center;
  color: #143051;
  text-align: center;
  /* -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  animation-name: moveEditTeamUserBoxUp !important;
  animation-duration: 0.1s !important;
  cursor: default;
}

.addNoteInlineImg {
  max-width: 12px;
  min-width: 12px;
  margin-left: 5px;
}

.x19387459 {
  max-width: 9px;
  min-width: 9px;
  margin-left: 6px;
}
.availableTagTxt {
  font-size: 1em;
  font-weight: 500;
  line-height: 30px;
  max-width: 96%;
  min-width: 96%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 0 auto;
  cursor: pointer;
  border-bottom: 1px solid #cbdeeb;
  display: flex;
  /* justify-content: center; */
  text-align: left;
  color: #36495f;
  cursor: pointer;
}

.lastAvailTag {
  border-bottom: none;
}

.availableTagTxt:hover {
  background-color: #eaf3fd;
}

.editTagDivFlex {
  display: flex;
  justify-content: space-between;
  cursor: default;
}

.tagMgrDiv {
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
  min-height: 50px;
  align-items: center;
}

.addNewTagBtn {
  font-size: 0.84em;
  background-color: #0cf;
  color: white;
  max-width: 120px;
  min-height: 30px;
  line-height: 28px;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  /* margin-bottom: 20px; */
  /* margin-top: -1px; */
  font-weight: 500;
  border: 1px solid #8ea7c5;
  cursor: pointer;
}

.editTagSugBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 66px;
  padding-left: 9px;
  padding-right: 9px;
  min-height: 35px;
  max-height: 35px;
  background-color: #cbd7e6;
  border: 1px solid #bbcada;
  border-radius: 5px;
  cursor: pointer;
}

.editTagSugBtn:hover {
  background-color: #bbcada;
}

.addTagAddShiftImg {
  margin: 0 auto;
  margin-top: 12px;
  min-width: 25px;
}

.editWorkingPatternButton {
  margin-top: 10px;
  margin-bottom: 20px;
}

.workPatternTitle {
  /* margin-top: -40px; */
  /* background-color: orange; */
}

.editWorkingPatternRight {
  /* background-color: yellow; */
  z-index: 1;
  padding-left: 1px;
  margin-left: 1px;
  /* margin-bottom: 0; */
  /* min-height: 100px; */
  /* margin-top: 70px; */
  /* position: relative;
  z-index: 999999999999; */
  /* padding-bottom: 100px; */
}

/* .fixedPatternDivider {
  min-height: 15px;
  min-width: 100%;
  max-width: 10%;
  border-top: solid 2px #e6eaee;
  border-radius: 15px 15px 0px 0px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: -10px;
} */

.shiftTeamSubBubble {
  /* font-size: 0.9em; */
  /* font-weight: 500; */
  /* margin-right: 18px; */
  /* margin-bottom: 14px; */
  /* margin-top: 4px; */
  /* background-color: #d2e6fd; */
  background-color: #fff;
  border: 1px solid #e6eaee;
  color: #496375;
  /* margin: 0 auto; */
  padding-left: 8px;
  padding-right: 8px;
  min-height: 35px;
  max-height: 35px;

  /* line-height: 27px; */
  font-size: 12px;
  margin-top: 9px;
  margin-bottom: 8px;
  border-radius: 5px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-weight: 500;
  display: flex;
  align-items: center;
  line-height:14px !important;
  /* padding-top: 2px; */
}

.adminEditHoursBtn {
  margin: 0 auto;
}

.shiftBubblesBar {
  display: flex;
  justify-content: flex-end;
  min-width: 100%;
  max-height: 38px;
  min-height: 38px;
  align-items: center;
  margin-bottom: 6px;
  margin-top: -6px;
  /* padding-top: 10px; */
}

.shiftBubblesBarManager {
  display: flex;
  justify-content: flex-end;
  min-width: 100%;
  max-height: 38px;
  min-height: 38px;
  align-items: center;
  margin-bottom: -10px;
  margin-top: 4px;
}

.shiftCostBubble {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  /* margin: 0 auto; */
  padding-left: 8px;
  padding-right: 8px;
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* line-height: 28px; */
  /* font-size: 1em; */
  margin-top: 9px;
  margin-bottom: 8px;
  border-radius: 5px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  /* font-weight: 500; */
  /* padding-top: 1px; */
  margin-left: 10px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  line-height: 13px;
  text-align: right;
}

.bankDivLeftContainer {
  /* background-color: blue; */
  max-width: 80%;
  padding-left: 1%;
  /* padding-top: 66px; */
}

.bankUserDiv {
  min-height: 130px;
  max-height: 130px;
  /* margin-top: -80px; */
  display: flex;
  justify-content: space-around;
  padding: 0;
  border: solid 2px #cbdeeb;
  border-radius: 15px;
  max-width: 92%;
  margin: 0 auto;
  margin-top: 15px;
  padding-top: 7px;
  margin-bottom: 20px;
  /* padding-bottom: 8px; */
}

.bankActive {
  border: solid 1px #143051;
  background-color: #143051;
  color: white;
}

.bankTitle {
  font-size: 1em;
}

.bankExplainer {
  font-size: 0.9em;
  text-align: left;
  padding-left: 5%;
  color: #8ea7c5;
  font-weight: 500;
  margin-top: 8px;
  /* background-color: yellow; */
  max-width: 260px;
}

.accrueBankFaded {
  opacity: 0.5;
}

.bankAccruableHelpBtn {
  background-color: #143051;
  color: white;
  font-weight: 500;
  max-height: 25px;
  margin-top: 7px;
  line-height: 28px;
  border-radius: 18px;
  min-width: 25px;
  max-width: 25px;
  text-align: center;
  margin-left: -15px;
}

.evenThoughTxt {
  line-height: 24px;
  max-width: 70%;
  padding-bottom: 12px;
  padding-top: 6px;
}

.absenceValueContainerIncPartialBtn {
  display: flex;
  padding-left: 1px;
  /* background-color: blue; */
  /* max-width: 160px; */
  /* min-width: 180px; */
}

.partialAbsenceBtnContainer {
  display: flex;
  justify-content: space-around;
  background-color: #e89292;
  color: #fff;
  /* border: 1px solid #cc4d4d; */
  max-width: 155px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 8px;
  align-items: center;
  border-radius: 5px;
  max-height: 35px;
  line-height: 35px;
  margin-top: 3px;
}

.partialHover:hover {
  background-color: #d37a7a;
}

.partialAbsenceBtnContainerActive {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #c15a5a;
  color: #fff;
  border: 1px solid #8d0f0f;
  max-width: 144px;
  margin-right: 12px;
  padding-left: 9px;
  padding-right: 7px;
  border-radius: 5px;
  max-height: 35px;
  line-height: 35px;
  margin-top: 3px;
}

.partialFaded {
  opacity: 0.15;
}

/* .partialFaded:hover {
  opacity: 0.5;
} */

.partialAbsencePlusBtn {
  font-size: 1.8em;
  margin-top: -4px;
}

.partialAbsencePlusBtnActive {
  font-size: 1.8em;
  margin-top: -3px;
  transform: rotate(45deg);
  cursor: pointer;
}

.partialAbsenceBtnTxt {
  margin-right: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
}

.partialAbsenceRow {
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
  /* background-color: blue; */
  min-width: 80%;
  align-items: center;
  margin: 1px auto;
}

.partialTimeValue {
  font-weight: 500;
  color: #143051;
  font-size: 16px;
  margin: 0 auto;
  text-align: center;
  margin-top: -1px;
  /* margin-right: -50px; */
}

.partialTimeEditMode {
  background-color: #fff;
  width: 77px;
  max-width: 77px;
  line-height: 30px;
  padding-top: 2px;
  height: 35px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 16px;
  font-weight: 500;
  margin-top: 0px;
  cursor: text;
}
.partialTimeEditMode:hover {
  border-color: #bac5d3;
}

.accrueExplain {
  /* background-color: #9a74eb; */
  max-width: 44px;
  color: #496375;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 22px;
  font-size: 0.85em;
  border-radius: 5px;
  padding-left: 2px;
  padding-right: 2px;
  font-weight: 500;
  margin-left: 22px;
  margin-bottom: 18px;
  margin-top: 0px;
  border: 1px solid #abbed3;
  cursor: pointer;
}

.accrueExplain:hover {
  background-color: #abbed3;
  color: white;
  /* opacity: 0.8; */
  /* transform: scale(0.94); */
}

.editPartialTimePencil {
  max-width: 13px;
  opacity: 0.75;
  cursor: pointer;
  margin-bottom: 5px !important;
  /* padding-top: 1px; */
  /* margin-top: 1px; */
}

.savePartialTimeBtn {
  max-width: 25px;
  margin-top: 8px;
  border: 1px solid #143051;
  border-radius: 10px;
  background-color: #00ccff;
  cursor: pointer;
}

.absenceRowUnit1 {
  /* background-color: orange; */
  min-width: 130px;
  max-width: 130px;
  max-height: 40px;
}

.absenceRowUnit2 {
  /* background-color: pink; */
  min-width: 80px;
  max-width: 80px;
  margin: 3px auto;
  max-height: 40px;
}

.absenceRowUnit3 {
  /* background-color: yellow; */
  padding-bottom: 21px;
  min-width: 60px;
  max-width: 60px;
  margin: 0 auto;
  max-height: 40px;
  text-align: center;
}

.units2and3 {
  /* background-color: blue; */
  display: flex;
  align-items: flex-end;
  min-width: 150px;
  margin-right: 15px;
  cursor: pointer;
}

.savePartialBtn {
  margin-top: 0px !important;
}

.units2and3Hover:hover {
  background-color: #edf3f9;
  border-radius: 5px;
}

.partialTitle {
  color: #214978;
  padding-left: 8px;
  font-size: 12px;
  font-weight: 500;
}

.partialBottomBorderAdjust {
  border: none;
}

.absenceRowContainer {
  display: block;
  background: #f4f8fe;
  border-left: 1px solid #bbcada;
  margin-left: 20px;
  border-radius: 0 0 5px 5px;
  border-bottom: 1px solid #bbcada;
  border-right: 1px solid #bbcada;
  margin-right: 8px;
  margin-bottom: 10px;
}

.partialAbsenceDurationSplitter {
  min-height: 2px;
  background-color: #8ea7c5;
  min-width: 90%;
  max-width: 90%;
  margin: 4px auto;
}

.invalidPartialInput {
  border: 1px solid #cc4d4d;
}

.disablePartialSaveBtn {
  opacity: 0;
  pointer-events: none;
}

.partialAbsenceDurationBox {
  text-align: center;
  color: #143051;
  font-size: 16px;
  font-weight: 500;
}

.absentDurationRow {
  padding-bottom: 7px;
}

.absenceUnitNameTxt {
  font-weight: 500;
  font-size: 1.1em;
  color: #859dbb;
  margin-right: 18px;
  text-align: right;
  cursor: pointer;
}

.alignMiddlePage0 {
  /* padding-top: 70px; */
  /* min-height: 215px; */
  /* max-height: 215px; */
  /* border-bottom: 2px solid #e6eaee; */
}

.addShiftOnWhichUserDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 252px;
  min-width: 252px;
  line-height: 26px;
  height: 35px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  color: #496375;
  border: 2px solid #e6eaee;
  -webkit-appearance: none;
  background-position-x: 230px;
  display: flex;
  cursor: pointer;
  margin-right: 12px;
  margin-top: 8px;
}

.addShiftOnWhichUserDropdown:hover {
  border-color: #bac5d3;
}

.tagTxtOnShiftsArr {
  font-size: 0.85em;
  margin-top: 2px;
  /* color: #36495f; */
}

.preOpenShift {
  /* background-color: #e0ebf8; */
  min-height: 40px;
  min-width: 85%;
  max-width: 85%;
  animation-name: moveSubBoxIn !important;
  animation-duration: 0.15s !important;
  margin-left: 15px;
  border-radius: 0px 0px 15px 15px;
  background: rgb(88, 112, 150);
  background: linear-gradient(
    0deg,
    rgba(88, 112, 150, 1) 0%,
    rgba(88, 112, 150, 1) 90%,
    rgba(69, 89, 120, 1) 100%
  );
  margin-bottom: 2px;
  display: flex;
  justify-content: space-between;
}

.selectedShiftUnitBorder {
  border: 1px #143051 solid;
}

.openShiftBtn {
  background-color: #0077ff;
  border: 1px solid #143051;
  color: white;
  border-radius: 30px;
  min-height: 33px;
  max-height: 33px;
  line-height: 28px;
  font-size: 0.9em;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 12px;
  cursor: pointer;
  font-weight: 500;
  box-shadow: inset 0px 2px 0px #1964ba;
  margin-top: 3px;
}

.shiftSubBarContentLeft {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-weight: 500;
  margin-left: 12px;
}

.adminUserShiftTimeAndTagsContainer {
  /* background-color: blue; */
  display: flex;
  align-items: center;
}

.adminUserShiftTagContainer {
  /* background-color: yellow; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 7px;
}

.adminUserShiftTag {
  color: #fff;
  font-size: 0.8em;
  text-align: left;
  margin-right: 4px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 15px;
  font-weight: 500;
  background-color: #143051;
  line-height: 17px;
  max-height: 18px;
  /* margin-top: 3px; */
}

.surplusTagsOnAdminUserShifts {
  color: #fff;
  font-size: 0.65em;
  text-align: left;
  margin-left: 0px;
  padding-left: 3px;
  padding-right: 5px;
  border-radius: 15px;
  font-weight: 500;
  background-color: #214978;
  line-height: 17px;
  max-height: 18px;
  /* margin-top: 3px; */
}

.editWPattContainer {
  /* background-color: yellow; */
  display: flex;
  /* min-width: 100%; */
  justify-content: flex-end;
}

.editWPattButtonEdit {
  background-color: #214978;
  color: white;
  max-width: 130px;
  min-width: 130px;
  padding-left: 12px;
  padding-right: 12px;
  border: 1px #143051 solid;
  font-size: 0.86em;
  font-weight: 500;
  border-radius: 25px;
  min-height: 30px;
  line-height: 30px;
  margin-bottom: 24px;
  margin-top: 8px;
  text-align: center;
}

.editWPattButtonSave {
  background-color: #0077ff;
  color: white;
  max-width: 130px;
  min-width: 130px;
  padding-left: 12px;
  padding-right: 12px;
  border: 1px #143051 solid;
  font-size: 0.86em;
  font-weight: 500;
  border-radius: 25px;
  min-height: 30px;
  line-height: 30px;
  margin-bottom: 24px;
  margin-top: 8px;
  text-align: center;
}

.editWPattButtonHighlight {
  background-color: #9a74eb;
  color: white;
  max-width: 130px;
  min-width: 130px;
  padding-left: 12px;
  padding-right: 12px;
  border: 1px #143051 solid;
  font-size: 0.86em;
  font-weight: 500;
  border-radius: 25px;
  min-height: 30px;
  line-height: 30px;
  margin-bottom: 24px;
  margin-top: 8px;
}

.nonEditWPatt {
  pointer-events: none;
  opacity: 0.6;
}

.workPattChangesModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.workPattChangesModalBox {
  background-color: white;
  min-width: 75vw;
  max-width: 75vw;
  /* min-height: 90vh;
max-height: 90vh; */
  /* height: 80vh; */
  border-radius: 15px;
  /* margin-top: -40px; */
  /* display: flex; */
  /* justify-content: center; */
  align-items: center;
  color: #143051;
  margin-top: -100px;
  text-align: center;
  /* position: fixed;
bottom: 0;
text-align: center; */
  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  animation-name: moveEditTeamUserBoxUp !important;
  animation-duration: 0.1s !important;
  font-size: 0.9em;
  font-weight: 400;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  /* padding-bottom: 20px */
}

.updateUserSchedGenBtnContainer {
  /* background-color: yellow; */
}

.updateUserSchedGenBtnContainer {
  /* background-color: blue; */
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.updateUserSchedGenBtnNo {
  font-size: 0.8em;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 2px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 17px;
  padding-right: 17px;
  max-height: 30px;
  line-height: 26px;
  border-radius: 20px;
  min-height: 30px;
}

.updateUserSchedGenBtnYes {
  font-size: 0.8em;
  color: #fff;
  background-color: #0077ff;
  border: solid #214978 2px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 17px;
  padding-right: 17px;
  max-height: 30px;
  line-height: 26px;
  border-radius: 20px;
  min-height: 30px;
  margin-left: 20px;
}

.userAllowancesContainer {
  min-width: 100%;
}

.allowancesSelectContainer {
  /* background-color: #e0ebf8; */
  display: flex;
  justify-content: space-between;
  min-height: 44px;
  align-items: center;
  padding-left: 7px;
  padding-right: 7px;
  /* border-radius: 5px; */
  /* border-bottom: 1px solid #bbcada */
}

.allowanceTypeDropdown {
  color: #fff;
  border-radius: 15px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 188px;
  min-width: 188px;
  line-height: 26px;
  height: 30px;
  background: url(/static/media/downArrowWhite2.887d57ce.svg) no-repeat right
    #214978;
  border: 1px solid #143051;
  -webkit-appearance: none;
  background-position-x: 164px;
  display: flex;
  cursor: pointer;
}

.availableAllowancesYearsDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 80px;
  min-width: 80px;
  line-height: 26px;
  height: 35px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
    #214978;
  border: 1px solid #143051;
  -webkit-appearance: none;
  background-position-x: 56px;
  display: flex;
  cursor: pointer;
  color: #214978;
  background-color: white;
  border: 2px solid #c6d5e4;
}

.availableAllowancesYearsDropdown:hover {
  border-color: #bac5d3;
}
.userAnnLeaveCalcToggleTitle {
  font-weight: 500;
  text-align: left;
  margin-top: 20px;
  color: #143051;
  font-size: 12px;
  line-height: 22px;
  /* margin-left: 8px;
  padding-right: 8px; */
  margin-bottom: 12px;
  padding-left: 15px;
  padding-right: 12px;
}

.selectedYearAllowanceSpan {
  color: #143051;
  font-weight: 500;
}

.annLeaveToggleContainer {
  display: flex;
  justify-content: space-between;
  min-height: 40px;
  align-items: flex-start;
  border-top: 1px solid #ddebf4;

  border-bottom: 1px solid #ddebf4;
  padding-bottom: 14px;
  margin-top: 9px;
  padding-top:15px;
}

.finalToggleContainerBorderBottom {
  border-bottom: 1px solid #ddebf4;
}

.annLeaveToggleContainer:first-of-type {
  border: none;
  margin-top: 10px;
}

.allHoursBoxPaddingTop {
  padding-top: 20px;
  padding-bottom: 20px;
}

.annLeaveToggleLeftTxtBox {
  max-width: 80%;
  text-align: left;
}

.annLeaveToggleTxt1 {
  font-weight: 500;
  font-size: 12px;
  margin-left: 14px;
  color: #143051;
  margin-top:8px;
}

.annLeaveToggleTxt2 {
  font-size: 12px;
  margin-top: 8px;
  margin-left: 18px;
  color: #bbcada;
  line-height: 16px;
  font-weight: 500;
}

.allowanceToggleKnob {
  min-height: 100%;
  /* background-color: blue; */
  margin-top: -62px;
}

.annLeaveOrCustomSelector {
  display: flex;
  min-width: 230px;
  align-items: center;
  justify-content: space-between;
  color: #8ea7c5;
  padding-right: 8px;
}

.annLeaveOrCustomTxt {
  font-size: 0.85em;
  font-weight: 500;
  cursor: pointer;
  min-width: 98px;
  max-width: 98px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.annLeaveOrCustomTxt:hover {
  /* background-color: blue; */
}

.annLeaveOrCustomSelected {
  font-weight: 500;
  color: #fff;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 7px;
  cursor: default;
  background-color: #214978;
  border: 1px solid #143051;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 7px;
  border-radius: 5px;
  min-width: 112px;
  min-height: 35px;
}

.annLeaveOrCustomUnderLineAnnLeave {
  min-width: 60px;
  min-height: 0px;
  background-color: #143051;
  margin-top: 1px;
}

.allowancesTopDiv {
  min-width: 100%;
  min-height: 2px;
  background-color: #ddebf4;
  margin-bottom: 11px;
}

.userAnnLeaveSettingsContainer {
  background-color: #fff;
  border-top: solid 2px #eaf0f5;
  border-radius: 5px 5px 0px 0px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 8px;
  /* background-color: blue; */
  min-height: calc(100% - 51px);
  max-height: calc(100% - 51px);
  /* max-height: 100%;
  min-height: 100%; */
  padding-bottom: 100px;

  overflow-y: scroll;
}

.jamLoadAllowance {
  opacity: 0.4;
  pointer-events: none;
}

.overrideContractedHoursAllowanceZeroVoiderContainer {
  background-color: #eaf3fd;
  max-width: 90%;
  margin: 0 auto;
  padding: 10px;
  border: 1px solid #ddebf4;
  border-radius: 15px;
  /* display: flex;
  justify-content: space-between; */
}

.overrideContractedAllowCalcTxt {
  /* background-color: rgb(160, 160, 43); */
  text-align: left;
  font-size: 0.85em;
  /* max-width: 80%; */
}

.overrideContractedAllowBtnContainer {
  /* background-color: yellow; */
  /* min-width: 100%; */
  display: flex;
  justify-content: space-between;
  /* margin: 0 auto; */
  /* max-width: 190px; */
  /* padding-right: 100px; */
  max-width: 170px;
  margin-top: 15px;
}

.overrideContractedAllowCalcBtn {
  background-color: #0077ff;
  /* min-height: 40px; */
  color: white;
  padding-top: 1px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  border: 1px solid #143051;
  font-weight: 500;
  text-align: center;
}

.overrideContractedAllowCalcBtnCancel {
  /* background-color: #0077ff; */
  color: #7390b1;
  padding-top: 4px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px;
  /* border: 1px solid #143051; */
  font-weight: 500;
  font-size: 0.9em;
}

.selectedMinsAllowanceDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 188px;
  min-width: 188px;
  line-height: 26px;
  height: 35px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
    #214978;
  border: 1px solid #143051;
  -webkit-appearance: none;
  background-position-x: 166px;
  display: flex;
  cursor: pointer;
  color: #496375;
  background-color: white;
  border: 1px solid #dde9f9;
}

.selectedMinsAllowanceDropdown:hover {
  border-color: #bac5d3;
}

.manuallySeAllowContainer {
  display: flex;
  justify-content: flex-start;
  margin-top: 22px;
  padding-left: 6px;

}

.manSetAllowKnob {
  margin-top: 10px;
}

.manuallySetAllowTxt {
  margin-top: 20px;
}

.annLeaveDropdownAndExplainInfoContainer {
  /* background-color: lime; */
  max-width: fit-content;
}

.explainThatAllowBasedOnCurrent {
  font-size: 12px;
  text-align: left;
  margin-top: 8px;
  padding: 4px;
  color: #516b8b;
  font-weight: 400;
  line-height: 16px;
}

.annLeavePercentageDropdownOnUser {
  color: #496375;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left;
  max-width: 68px;
  min-width: 68px;
  line-height: 31px;
  height: 35px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  border: 1px solid #dde9f9;
  -webkit-appearance: none;
  background-position-x: 48px;
  cursor: pointer;
  /* margin-top:6px; */
}

.annLeavePercentageDropdownOnUser:hover {
  border-color: #bac5d3;
}

.userAnnLeaveWeeksPerYearContainer {
  min-width: 120px;
  display: flex;
  justify-content: flex-end;
  padding-right: 8px;
  align-items: flex-end;
  flex-direction: column;
}

.wksPerYrSpan {
  font-size: 12px;
  margin-left: 0px;
  margin-right: 12px;
  font-weight: 400;
  color: #516b8b;
  margin-top: 6px;
}

.loadingBallsUserAllow {
  margin-top: 36px;
}

.fadeUserAllowsOnYearChange {
  opacity: 0.4;
  pointer-events: none;
}

.annLeaveMetaContainer {
  background-color: #f4f8fe;
  max-width: 92%;
  /* margin: 14px auto; */
  /* display: flex; */
  border-radius: 5px;
  border: 1px #e6e6e6 solid;
  margin-top: 42px;
  min-height: 40px;
  padding: 2px;
  cursor: pointer;
  margin-left: 10px;
}

.annLeaveMetaContainer:hover {
  background-color: #f0f4fa;
}

.metaExtended {
  border: 2px solid #e6e6e6;
  padding: 1px;
  border-radius: 5px;
}

.annLeaveAllowanceRemainingContainer {
  background-color: #fff;
  /* max-width: 96%;
  max-width: 260px; */
  /* margin: 0 auto; */
  /* font-size: 0.9em; */
  /* display: flex; */
  border-radius: 5px;
  /* border: 1px #cce3ff solid; */
  margin-top: 8px;
  min-height: 40px;
}
.hourlyRateTimelineUnit {
  min-width: 96%;
  max-width: 96%;
  min-height: 80px;
  background-color: white;
  border: 1px solid #c4d5ea;
  border-radius: 5px;
  margin: 6px auto;
  display: flex;
  justify-content: space-around;
}

.selectedHourlyRateTimelineUnit {
  min-width: 96%;
  max-width: 96%;
  min-height: 140px;
  background-color: white;
  border: 1px solid #c4d5ea;
  border-radius: 5px;
  margin: 6px auto;
  display: flex;
  justify-content: space-around;
}

.hourlyRateTimeinlineUnitContent {
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 13px;
  padding-right: 35px;
}

.hourlyRateTimelineLeftSide {
  /* background-color: yellow; */
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 90%;
}

.hourlyRateTimelineRightSide {
  min-width: 20%;
  /* background-color: yellow; */
  padding-left: 10px;
}

.hourlyRateLeftDivider {
  min-width: 100%;
  min-height: 1px;
  background-color: #c0d6f0;
}

.hourlyRateLeftSideTxt {
  font-size: 14px;
  font-weight: 500;
  color: #214978;
  /* padding-right: 60px; */
  padding-left: 2px;
}

.spanHourlyRateTimelineToSpan {
  color: #8ea7c5;
  margin-left: 4px;
  margin-right: 4px;
}

.editHourlyRateTimelineImg {
  min-width: 25px;
  max-width: 25px;
  margin-left: 8px;
  margin-top: 1px;
  padding-top: 1px;
  cursor: pointer;

  border: 2px solid #e3e8ee;
  padding: 5px;
  border-radius: 5px;
}

.editHourlyRateTimelineImg:hover {
  border: 2px solid #b2bbc7;
}

.hourlyRateTimelineLeftSideSelected {
  /* background-color: yellow; */
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 70%;
  padding-bottom: 10px;
}

.hourlyRateInput {
  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px;
  margin-right: 4px;
  color: #6a8bb1;
  max-width: fit-content;
  max-width: 60px;
  min-height: 35px;
  padding-left: 5px;
  font-weight: 500;
  margin-top: 8px;
  padding-left: 10px;
  font-size: 14px;
}

.hourlyRateInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 3px;
  background-color: #fdfeff;
  padding-left: 8px;
}

.hourlyRateSelectedRow {
  /* background-color: blue; */
  min-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  /* flex-direction: column; */
}

.hourlyRateSelectedRowRateContainer {
  /* background-color: blue; */
  min-height: 40px;
  display: flex;
  /* flex-direction: column; */
  align-items: center;

  /* flex-direction: column; */
}

.hourlyRateFromToTxt {
  color: #143051;
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  margin-left: 8px;
  margin-top: 10px;
  margin-bottom: 4px;
}

.spanHourlyRateTimelineToSpanEdit {
  color: #8ea7c5;
  font-size: 12px;
  font-weight: 400;
  margin-left: 6px;
  /* margin-top: 10px; */
}

/* 
color: #fff;
border-radius: 10px;
font-size: 0.9em;
font-weight: 500;
padding-left: 12px;
text-align: left !important;
max-width: 80px;
min-width: 80px;
line-height: 26px;
height: 30px;
background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
  #214978;
border: 1px solid #143051;
-webkit-appearance: none;
background-position-x: 56px;
display: flex;
cursor: pointer;

color: #496375;
background-color: white;
border: 1px solid #dde9f9; */

.editPeriodDateInput {
  letter-spacing: 1px;
  max-width: 162px;
  min-width: 162px;
  background: url("../../img/general/editUserCal.svg") no-repeat right #f4f8fe;
  -webkit-appearance: none;
  background-position-x: 130px;
  margin-bottom: 8px;
  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px;
  margin-right: 4px;
  color: #6a8bb1;
  min-height: 35px;
  padding-left: 10px;
  font-weight: 500;
  font-size: 14px;
  cursor: text;
  /* margin-left: 6%; */
}

.editPeriodDateInput:focus {
  border: 2px solid #dde9f9;
  padding-left: 8px;
  background-color: #fdfeff;
  background-position-x: 128px;
  /* padding-bottom: 1px; */
}

.selectedHourlyRateContainer {
  /* background-color: yellow; */
  min-height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* min-width: 100%; */
}

.binCircleTimelineImg {
  min-width: 29px;
  max-width: 29px;

  margin-left: 14px;
  margin-top: 20px;
  cursor: pointer;

  border: 2px solid #e3e8ee;
  padding: 5px;
  border-radius: 5px;
}

.binCircleTimelineImg:hover {
  border: 2px solid #b2bbc7;
}

.closeAllOfUserTeamsModalBtn {
  font-size: 0.9em;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 2px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  line-height: 26px;
  border-radius: 10px;
  min-height: 30px;
  max-width: 60px;
  margin: 8px auto;
}

.allowanceInfoIfInitialYearContainer {
  max-width: 96%;
  min-width: 96%;
  margin: 0 auto;
  text-align: left;
  font-size: 0.9em;
  color: #143051;
  /* padding-left: 8px; */
  /* padding-right: 8px; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}


.allowanceRow {
  display: flex;
  /* background-color: blue; */
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ddebf4;
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 100%;
  min-height: 40px;
  max-height: 40px;
  padding-left:10px;
  padding-right:10px;
}

.allowanceRow:last-child {
  /* border-top: none; */
  margin-bottom: 20px;
}

.allowanceRowAccrue {
  display: flex;
  /* background-color: blue; */
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #8ea7c5;
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  padding-right:10px;
}


.allowanceRowAccrue:last-child {
  /* border-top: none; */
  margin-bottom: 20px;
}

.allowanceInfoRowDivider {
  min-height: 1px;
  min-width: 100%;
  background-color: #ddebf4;
  display: none;
}

.extraThickDivider {
  min-height: 1px;
  background-color: #ddebf4;
  margin-top: 2px;
  margin-bottom: 2px;
}

.allowanceInfoKey {
  font-size: 12px;
  color: #143051;
  max-width: 65%;
  font-weight: 500;
}

.allowanceInfoValue {
  /* color: #58bdee; */
  color: #bbcada;
  font-weight: 500;
  text-align: right;
  font-size: 14px;
  min-width: 160px !important;
  /* background: blue; */
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.allowInfoBrackets {
  color: #abbed3;
}

.userAllowCalcTitle {
  font-weight: 400;
  text-align: left;
  padding-left: 15px;
  margin-top: 22px;
  margin-bottom: 12px;
  color: #214978;
  font-size: 12px;
  border-top: 1px solid #ddebf4;
  padding-top: 20px;
}

.xc8383 {
  border-bottom: 1px solid #ddebf4;

}

.showMetaToggle {
  display: flex;
  /* background-color: yellow; */
  justify-content: flex-start;
  max-width: 200px;
  margin-top: 7px;
  margin-left: 12px;
  margin-bottom: 7px;
  cursor: pointer;
}

.showAccrualDataArrowImg {
  min-width: 10px;
}

.dataArrowShowMeta {
  transform: rotate(0deg);
  margin-top: 2px;
}

.dataArrowShowMetaActive {
  transform: rotate(90deg);
}

.accruInfoTxt {
  font-weight: 500;
  color: #143051;
  text-align: left;
  margin-left: 10px;
  font-size: 12px;
  margin-top: 3px;
}

.allowanceAdditionsContainer {
  /* background-color: #f4f8fe; */
  max-width: 200px;
  /* margin: 14px auto; */
  /* display: flex; */
  border-radius: 5px;
  border: 1px #e6eaee solid;
  margin-top: 8px;
  min-height: 40px;
  /* max-height: 35px; */
  padding: 2px;
  cursor: pointer;
  margin-left: 10px;
}



.additionsExtended {
  border: 1px solid #e6eaee;
  background-color: #f0f4fa;
  padding: 2px;
    /* padding: 1px; */
  max-width: 93%;

  border-radius: 5px;
  cursor: default;
margin-bottom:15px;
}

/* .additionsExtended:hover {
  border: 2px solid #e6eaee;
  background-color: #f0f4fa;
} */

.additionalMinsTxt {
  background-color: white;
  border: 1px solid #dde9f9;
  padding: 8px;
  font-size: 12px;
  /* padding-left: 6px; */
  /* padding-right: 6px; */
  max-width: -moz-fit-content;
  max-width: fit-content;
  /* margin: 15px auto; */
  border-radius: 5px;
  margin-left: 15px;
  margin-top: 10px;
  font-weight: 500;
  text-align: left;
  color: #516b8b;
}

.addedMinsSpan {
  font-weight: 500;
  color:#00aaff
}

.additionsBtnsContainer {
  /* background-color: yellow; */
  min-width: 190px;
  margin-top: 10px;
}

.additionsBtnsContainerRow {
  display: flex;
  /* background-color: pink; */
  max-width: 200px;
  justify-content: space-between;
  margin: 0 auto;
}
.additionsBtnsContainerRow:last-child {
  margin-top: 2px;
}

.plus1m,
.minus1m {
  background-color: white;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  min-width: 46px;
  max-width: 46px;
  min-height: 46px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 14px;
  padding-top: 7px;
  padding-bottom: 7px;
  color: #36495f;
  cursor: pointer;
}

.plus1m:hover {
  border-color: #143051;
}
.minus1m {
  background-color: #eaf3fd;
  cursor: pointer;
}

.minus1m:hover {
  border-color: #143051;
}

.plus1m:active {
  transform: scale(0.95);
  border-color: #00ccff;
}

.minus1m:active {
  transform: scale(0.95);
  border-color: #00ccff;
}

.saveCanxBtnContainerAllows {
  /* background-color: green; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  margin-left: 12px;
  padding-left: 8px;
  border-left: 1px solid #c0d6f0;
}

.addOrMinusNumberSpan {
  font-size: 1.1em;
  letter-spacing: 2px;
}

.additionsParent {
  display: flex;
  justify-content: flex-start;
  margin-right: 10px;
  margin-bottom: 17px;
  margin-top: 13px;
  border-left: 2px solid #bbcada;
  padding-left: 15px;
}

.submitAdditionsBtn {
  background-color: #0bf;
  border: 1px solid #0af;
  border-radius: 5px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  padding-left: 8px;
  padding-right: 8px;
  min-width: 55px;
  color: #fff;
  cursor: pointer;
}

.submitAdditionsBtn:hover {
  background-color: #0af;
}

.cancelAdditionsBtn {
  background-color: white;
  border: 1px solid #dde9f9;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30px;
  font-weight: 500;
  font-size: 12px;
  padding-left: 8px;
  padding-right: 8px;
  min-width: 55px;
  margin-top: 5px;
  color: #3b597c;
  cursor: pointer;
}

.cancelAdditionsBtn:hover {
  /* background-color: #f4f8fe; */
}

.fadedSubmitAdditionsBtn {
  opacity: 0.4;
  pointer-events: none;
  /* color: #143051; */
  cursor: default;
}

.manipulatedAddedMinsSpan {
  color: #0077ff;
  font-weight: 500;
}

.thirtySpan {
  letter-spacing: 0px;
  font-size: 1.1em;
}

.thirtyDiv {
  display: flex;
}

.hoursToDaysConversion {
  /* background-color: #e3e8ee;  */
  border-right: 1px solid #8ea7c5;
  color: #496375;
  min-height: 30px;
  max-height: 30px;
  /* line-height: 26px; */
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 0px;
  padding-right: 11px;
  font-weight: 500;
  font-size: 0.9em;
  /* border-radius: 5px; */
  margin-top: 15px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 0 auto; */
  /* margin-top: -20px; */
}

.manipulatedDaysConversion {
  /* background-color: #6d1dd5 !important; */
  /* border: 1px solid #430692; */
  color: #6d1dd5;
  /* line-height: 26px; */
}

.equivalentHrsToDaysTxt {
  font-size: 10px;
  max-width: 160px;
  /* margin: 0 auto; */
  text-align: left;
  margin-left: 12px;
  margin-top: 16px;
  font-weight: 400;
  color: #143051;
}

.basedOnHrsToDaysContainer {
  display: flex;
  justify-content: flex-start;
  /* background-color: yellow; */
  max-width: 290px;
  /* min-height: 90px; */
  margin-top: 0px;
  margin-bottom: 24px;
  align-items: center;
  /* display: none; */
}

.addOrDeductTitle {
  font-weight: 500;
  color: #496375;
  text-align: left;
  padding-left: 15px;
  margin-top: 12px;
}

.joinDateMidTermDiv {
  color: #516b8b;
  font-size: 12px;
  text-align: left;
  display: flex;
  margin-top: 20px;
  padding-right: 70px;
  line-height:16px;
}

.allowjoinDateSpan {
  font-weight: 500;
}

.exclaimJoinDateImg {
  min-width: 25px;
  margin-right: 8px;
  margin-top: -52px;
  max-width: 25px;
  margin-left: 12px;
}

.joinDateMidTermInfoTxt {
  
}

.allowanceLoadingImg {
  max-height: 16px;
  margin-top: 1px;
  margin-bottom: 1px;
}

.daysConvertSpan {
  color: #143051;
  padding-left: 12px;
  /* background-color: yellow; */
  border-left: 1px solid #ddebf4;
  margin-left: 12px;
}

.snapOptionsContainer {
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
}
.snapBtnsParent {
  display: flex;
  margin-top: 6px;
  padding-top: 6px;
  border-top: 1px solid #ddebf4;
  justify-content: flex-end;
  padding-bottom: 3px;
}

.snapDownBtn {
  background-color: #0af;
  border: 1px solid #0e94d6;
  color: #fff;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 5px;
  min-width: 96px;
  justify-content: space-between;
  cursor: pointer;
}

.snapUpBtn {
  background-color: #0af;
  border: 1px solid #0e94d6;
  color: #fff;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 5px;
  margin-left: 6px;
  min-width: 84px;
  justify-content: space-between;
  cursor: pointer;
}
.snapDownBtn:hover {
  background-color: #009cea;
}

.snapUpBtn:hover {
  background-color: #009cea;
}

.snapDownImg {
  transform: rotate(270deg);
  max-width: 12px;
  /* margin-left: 1px; */
  /* margin-right: 2px; */
}

.snapUpImg {
  transform: rotate(90deg);
  max-width: 12px;
}

.totalAllowSpanHighlighted {
  font-weight: 500;
  /* background-color: #58bdee; */
  color: #fff;
  max-width: -moz-fit-content;
  max-width: fit-content;
  opacity: 1;
  padding-right: 0px;
  padding-left: 12px;
  text-align: center;
  /* border-radius: 4px; */
  /* line-height: 35px; */
  padding-top: 6px;
  padding-bottom: 6px;
  margin-left: 5px;
  /* background-color: #0077ff; */
  color: #0077ff;
  /* border: 1px #0af solid; */
  margin-left: 12px;    display: flex;
    align-items: center;

}

.highlightedAllowanceRow {
  /* background-color: #fff;
  border: 1px #d2e6fd solid;
  border-top: none;
  margin-top: -2px;
  padding-left: 4px;
  border-radius: 0px 0px 10px 10px;

  padding-right: 4px; */
}

.mpwTimelineContainer {
  background-color: #f4f9ff;
  /* min-width: 100%; */
  max-width: 100%;
  margin: 0px auto;
  border-top: 2px solid #abbed3;
  border-radius: 12px;
  padding-bottom: 20px;
  border-bottom: 2px solid #abbed3;
  font-size: 1.25em;
  max-width: 96%;
  /* padding-bottom: 12px; */
}

.loadingMpwTimelineHorizBallsImg {
  margin-top: 22px;
  margin-bottom: 18px;
}

.mpwTimelineModalBody {
  background-color: white;
  border-radius: 10px;
  min-width: 340px;
  max-width: 340px;
  cursor: default;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.hourlyRateModalTitle {
  font-size: 18px;
  font-weight: 500;
  margin-top: -4px;
}
.mpwTimelineModalBodyMob {
  min-width: 100vw;
  max-width: 100vw;

  background-color: white;
  border-radius: 15px 15px 0 0;
  position: fixed;
  bottom: 0;
  cursor: default;
}
.mpwTimelineRow {
  /* background-color: yellow; */
  display: flex;
  justify-content: flex-end;
  padding-right: 5%;
  padding-left: 5%;
  margin-top: 18px;
  flex-direction: row-reverse;
}

.viewMpwTimelineBtn {
  background-color: #516b8b;
  /* background-color: #516b8b; */
  border: 1px #592eb6 solid;

  color: white;
  max-width: 160px;
  min-width: 140px;
  /* background-color: #0077ff; */
  display: flex;

  /* min-width: 135px; */
  height: 30px;
  line-height: 29px;
  /* margin-left: 3%; */
  justify-content: space-between;
  border-radius: 10px;
  /* padding-bottom: 2px; */
  border: 1px #143051 solid;
  padding-left: 9px;
  padding-right: 9px;
  /* opacity: 0.8; */
  background-color: white;
  color: #496375;
  border-color: #dde9f9;
}

.activeMpwTimelineBg {
  background-color: #214978;
  opacity: 1;
  border-radius: 10px 10px 0 0;
  border-bottom: none;
  font-size: 1.05em;
  background-color: white;
  color: #496375;
  border-color: #dde9f9;
}

.mpwTimelineBtnTxt {
  font-weight: 500;
  color: #496375;
  font-size: 0.9em;
  margin-top: -1px;
}

.schedHistoryImg {
  max-width: 14px;
  margin-left: 8px;
}

.timelineTopContainer {
  /* display: flex; */
}

.timelineTitle {
  font-weight: 500;
  font-size: 0.7em;
  text-align: left;
  max-width: fit-content;
  max-height: fit-content;

  border-radius: 5px;
  /* margin-left: 5%; */
  margin-top: 12px;
  margin-bottom: 12px;
  /* background-color: #214978; */
  /* border: 1px solid #143051; */
  /* color: white; */
  /* padding: 8px; */
  font-size: 1em;
  max-width: 210px;
  margin-left: 6%;
  color: #496375;
  /* padding-left: 5%; */
}

.currMpwInfoTxt {
  font-weight: 400;
  font-size: 12px;
  text-align: left;
  max-width: fit-content;
  max-height: fit-content;

  border-radius: 5px;
  margin-left: 5%;
  margin-top: 15px;
  margin-bottom: 10px;
  /* background-color: #214978; */
  color: #516b8b;
  /* padding: 12px; */
  padding-left: 4px;
  padding-right: 18px;
  line-height: 16px;
}

.showCreatedDs {
  max-width: 96%;
  margin: 0 auto;
  border-radius: 5px;
  color: #516b8b;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  padding: 6px;
  padding-left: 18px;
  padding-right: 60px;
  margin-bottom: 7px;
  line-height: 16px;
}

.opac4SnapBtns {
  opacity: 0.4;
  pointer-events: none;
}

.resetWarningImg {
  max-width: 20px;
  margin-right: 7px;
}

.showResetInstruction {
  text-align: left;
  font-weight: 500;
  max-width: 177px;
  border-radius: 5px;
  font-size: 12px;
  color: #496375;
  padding: 7px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  border: 1px solid #bbcada;
  background-color: #e6eaee;
  text-align: right;
  margin-top: 5px;
  padding-left: 6px;
}

.firstYouMustInstructionTxt {
  /* margin-left: 8px; */
}

.resetInstructionDiv {
  display: flex;
  justify-content: space-between;
}
.resetInstructionBtnHighlight {
  background-color: #0077ff;
  color: white;
  border-color: #143051;
}

.selectedMpwTimelineObjBorder {
  /* border: 1px solid #143051; */
}

.mpwMinsPerWeekTxt {
  text-align: left;
  margin-left: 6px;
  font-size: 12px;
  line-height: 13px;
  margin-top: 6px;
  font-weight: 500;
  /* color: #214978; */
}

.minsPerWeekMpwSpan {
  font-size: 12px;
  /* margin-left: 6px; */
  text-align: left;
  padding-left: 0px;
  /* padding-right: 20px; */
  /* background-color: yellow; */
  max-width: 100px;
  margin-top: -3px;
  max-height: 10px;
  color: #516b8b;
}

.mpwInputTimelineObj {
  font-size: 0.825em;
}

.flashReset {
  background-color: #00ccff;
}

.loadingAdditionsDiv {
  min-width: 100%;
  padding-left: 35px;
  display: flex;
  justify-content: space-between;
  min-height: 32px !important;
  margin-top: 14px;
  /* margin-bottom:0px !important; */
}

.endDateAfterStartMpwUnit {
  background-color: #cc4d4d;
  max-width: fit-content;
  font-size: 12px;
  font-weight: 500;
  color: white;
  margin-left: 30px;
  margin-top: -6px;
  border-radius: 0 0 5px 5px;
  text-align: left;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 1px;
  padding-bottom: 3px;
  border-top: 1px solid #ba0000;
}

.mpwUnit {
  margin-top: 0px;
}

.mpwUnit:first-child {
  margin-top: 0px;
}

.overlapsMpwUnitTxt {
  background-color: #cc4d4d;
  max-width: fit-content;
  font-size: 12px;
  font-weight: 500;
  color: white;
  margin-left: 30px;
  margin-bottom: -6px;
  border-radius: 5px 5px 0px 0px;
  text-align: left;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 3px;
  padding-bottom: 1px;
  border-bottom: 1px solid #ba0000;
  /* margin-top: 4px; */
}

.redBorderMpwUnit {
  border-color: #ba0000;
  border: 1px solid #ba0000;
  /* margin-top: 13px; */
}

.startAfterEndMpwUnitSpanStart {
  background-color: #cc4d4d;
  color: white;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 5px;
}

.mpwConflictsBtn {
  background-color: #fff;
  color: #ba0000;
  border-radius: 10px;
  /* border: 1px solid #ba0000; */
  font-weight: 500;
  font-size: 0.9em;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  margin-left: 8px;
  opacity: 0.8;
  /* max-height: 30px; */
}

.noBottomBorderRadiusConflicts {
  border-radius: 10px 10px 0 0;
  border-bottom: none;
}

.leftArrowWhiteConflictsImg {
  max-width: 9px;
  margin-right: 6px;
  margin-top: 0px;
}

.opac4LoadingSave {
  opacity: 0.4;
  pointer-events: none;
}

.fromOnwardsMpw {
  font-size: 12px;
  padding: 20px;
  text-align: left;
  margin-top: -7px;
  color: #516b8b;
  font-weight: 400;
  line-height: 16px;
}

.hourlyRateConflictSpan {
  background-color: #cc4d4d;
  border: 1px solid #ba0000;
  border-radius: 7px;
  padding-left: 3px;
  padding-right: 4px;
  margin-left: 6px;
  margin-right: -9px;
  margin-top: 0px;
  color: white;
  font-size: 0.9em;
}

.addOrRemoveMpwPeriodContainer {
  display: flex;
  justify-content: center;
  min-width: 100%;
  /* padding-right: 5%;
  padding-left: 5%; */
  padding-bottom: 4px;
  padding-top: 10px;
  /* background-color: black; */
}

.profPicEditDiv {
  display: flex;
  /* background-color: blue; */
  min-width: 80px;
  justify-content: space-between;
  padding-right: 10px;
  /* max-width: 40%; */
}

.editUserProPicImg {
  max-width: 35px;
  border-radius: 100%;
  /* margin-top: -2px; */
}

.noMaxHeightEditUserRow {
  max-height: 40px;
  min-height: 40px;
  align-items: flex-end;
  border-bottom: none;
  /* padding-bottom: 18px; */
  /* background-color: blue; */
}

.editProfPicFormContainer {
  /* max-width: 60%; */
  text-align: right;
}

.editUserProPicDiv {
  /* display: flex; */
  background-color: #eaf3fd;
  border-radius: 10px;
  padding: 10px;
  min-width: 90%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
  min-height: 50px;
  margin: 12px auto;
  /* margin-bottom: -8px; */
  /* align-items: flex-end; */
}

.submitProPicBtn {
  max-width: 80px;
  margin-top: 8px;
  background-color: #0077ff;
  border: 1px solid #143051;
  /* border: none; */
  border-radius: 10px;
  color: white;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.fadedSubmitImg {
  opacity: 0.6;
  pointer-events: none;
}

.photoTooBig {
  color: #ba0000;
  font-weight: 500;
  font-size: 0.7em;
  padding-right: 5%;
}

.customLeaveTypeSelectBar {
  /* background-color: #f4f9ff; */
  display: flex;
  overflow-x: scroll;
  /* border-radius: px; */
  max-width: 100%;
  min-width: 100%;
  min-height: 42px;
  max-height: 42px;
  border-bottom: 2px #dde9f9 solid;
  padding-bottom: 12px;
  padding-right: 22px;
  padding-left: 4px;
  margin-top: 12px;
  /* justify-content: spa; */
}

.customLeaveTypeBarUnit {
  min-width: fit-content;
  margin-left: 14px;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-right: 1px #859dbb solid; */
  padding-right: 10px;
  font-weight: 500;
  color: #859dbb;
  /* font-weight:  */
}

.customLeaveTypeBarUnit:last-child {
  /* border-right: 0px #859dbb solid; */
}

.customLeaveTypeBarUnit:first-child {
  /* min-width: fit-content; */
  margin-left: 6px;
}

.selectedCustomLT {
  border: 1px solid #143051;
  background-color: #19a5c8;
  color: white;
  border-radius: 5px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: 500;
}

.customsWhiteFadePng {
  margin-top: -42px;
  position: fixed;
  min-width: 35px;
  right: 7%;
}

.contractedBasedOnRevagOrCurrContainer {
  /* background-color: blue; */
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  min-width: 100%;
  padding-right: 8px;
  /* padding: 10px; */
  /* background-color: #214978;
  color: white; */
  /* max-width: 80%; */
  /* min-height: 200px; */
}

.reAvgCurrRow {
  min-width: 100%;
  max-width: 100%;
  display: flex;
  background-color: #f4f9ff;
  border: 1px #cce3ff solid;
  border-top: none;
  padding: 10px;
  color: #19a5c8;
  border-radius: 0 0 5px 5px;
  margin-bottom: 0px;
  padding-top: 14px;
  padding-left: 14px;
  /* padding-bottom: 14px; */
  font-weight: 500;
  font-size: 0.95em;
  justify-content: space-between;
  cursor: pointer;
}

.currAvgSpan {
  color: #143051;
  background-color: #fff;
  min-width: fit-content;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  line-height: 18px;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: center;
  border: 1px solid #e6eaee;
  /* font-size: 0.9em; */
}

.opacCurrAvgRowLine {
  opacity: 0.6;
  /* color: black; */
}

.reAvgCurrRow:hover {
  background-color: #edf3f9;
}

.reAvgCurrRow:first-child {
  padding-bottom: 9px;
  /* margin-bottom: -3px; */
  border-radius: 5px 5px 0 0;
  border-top: 1px #cce3ff solid;
  border-bottom: none;
}

.reAvgCurrRowLeft {
  min-width: 80%;
  max-width: 80%;
  font-size: 0.8em;
  text-align: left;
  display: flex;
  align-items: center;
  /* font-weight: 500; */
  justify-content: space-between;
}

.avgCurrKnob {
  /* margin-left: 40px; */
  /* align-items: center; */
  margin-top: -6px;
  margin-left: 8px;
}

.reAvgCurrRowBox {
  /* display: flex; */
  /* justify-content: space-between; */
  min-width: 90% !important;
  margin-top: 10px;
  /* margin-left: 12px; */
  /* padding: 40px; */
}

.avgCurrDividerContainer {
  background-color: #f4f9ff;
  /* background-color: yellow; */
  border-left: 1px #cce3ff solid;
  border-right: 1px #cce3ff solid;
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: 10px; */
  min-width: 100%;
  /* padding-top: 6px; */
}

.reAvgCurrRowDivider {
  min-height: 1px;
  max-height: 1px;
  min-width: 92%;
  max-width: 92%;
  opacity: 0.1;
  background-color: #143051;
  /* margin-top: 5px; */
  /* max-width: 50%; */
}

.contractedOnlyContainer {
  display: flex;
  padding-top: 6px;
}

.downRightContractedHoursArrowImg {
  max-width: 14px;
  margin-left: 5px;
  margin-top: 6px;
}

.allHoursAllowanceToggle {
  margin-top: -40px;
}

.currAvgKnobOn {
  background-color: #00ccff;
}

.timelineIssuesEditUserSubBox {
  background-color: #ba0000;
  opacity: 0.75;
  background: rgb(136, 2, 2);
  background: linear-gradient(
    180deg,
    rgba(136, 2, 2, 1) 0%,
    rgba(186, 0, 0, 1) 21%,
    rgba(186, 0, 0, 1) 100%
  );
  max-width: 85%;
  color: white;
  margin-left: 15px;
  border-radius: 0 0 5px 5px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 0.9em;
  font-weight: 500;
  margin-bottom: 10px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.selectedTimelineIssues {
  border-radius: 0px;
  opacity: 1;

  /* background-color: #143051; */
  background: rgb(136, 2, 2);
  background: linear-gradient(
    180deg,
    rgba(136, 2, 2, 1) 0%,
    rgba(186, 0, 0, 1) 20%,
    rgba(186, 0, 0, 1) 91%,
    rgba(136, 2, 2, 1) 100%
  );
  margin-bottom: 0px;
}

.schedIssueWarningUnit {
  display: flex;
  font-size: 0.8em;
  align-items: center;
}

.paleRedWarningImg {
  max-width: 16px;
  margin-right: 8px;
}

.invisSchedIssue {
  visibility: hidden;
}

.regenSchedDiv {
  min-width: 100%;
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  padding-right: 6%;
  /* margin-top: 8px; */
}

.regenSchedBtn {
  /* text-align: center; */
  min-width: 180px;
  max-width: 180px;
  min-height: 30px;
  max-height: 30px;
  font-size: 0.9em;
  font-weight: 500;
  /* background-color: blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  border: 1px #143051 solid;
  /* margin-right: 5%; */
  background-color: #0077ff;
  color: white;
  /* animation: regenBlinker 0.5s linear infinite; */
}

@keyframes regenBlinker {
  50% {
    /* opacity: 0.8; */
    /* border-color: #1964ba; */
    background-color: #0044ff;
  }
}

.regenExplain {
  font-size: 0.85em;
  text-align: right;
  color: #496375;
  padding-left: 10%;
  padding-right: 6%;
  margin-top: 18px;
  margin-bottom: 14px;
  opacity: 0.8;
  /* max-width: 80%; */
}

.regenExplain:last-of-type {
  margin-bottom: 30px;
}

.updatingSchedTxt {
  margin-bottom: 20px;
  color: #3b597c;
  font-size: 0.8em;
  font-weight: 400;
  padding-right: 5%;
  /* text-align: right; */
}

.loadingHorizImgGenerating {
  margin-top: 20px;
}

.fixedSchedChangesDiv {
  min-width: 94%;
  /* padding-right: 100px; */
  max-width: 94%;
  margin: 0 auto;
  /* margin-left: */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0px;

  /* padding-right: 5%; */
}

.centerRegen {
  text-align: center;
  /* margin-right: 0px; */
}

.regenBtnSched {
  min-width: 180px;
  max-width: 180px;
  min-height: 30px;
  max-height: 30px;
  font-size: 0.9em;
  font-weight: 500;
  /* background-color: blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  border: 1px #143051 solid;
  /* margin-right: 5%; */
  margin: 0px auto;
  background-color: #0077ff;
  color: white;
  margin-top: 10px;
  /* margin-left: 5px; */
}

.regenExplainSched {
  font-size: 0.85em;
  text-align: center;
  color: #496375;
  padding-left: 10%;
  padding-right: 6%;
  margin-top: 20px;
  margin-bottom: 8px;
  opacity: 0.8;
}

.regenExplainSched2 {
  font-size: 0.85em;
  text-align: center;
  color: #496375;
  padding-left: 10%;
  padding-right: 6%;
  margin-top: 10px;
  margin-bottom: 48px;
  opacity: 0.8;
}

.fixedSchedChangesDiv2 {
  min-width: 94%;
  /* padding-right: 100px; */
  max-width: 94%;
  margin: 0 auto;
  /* margin-left: */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -50px;

  /* padding-right: 5%; */
}

.closeManageShiftModalBtnImg {
  /* background-color: blue; */
  min-width: 35px;
  padding: 10px;
  min-height: 35px;
  margin-top: 3px;
  margin-left: 0px;
  cursor: pointer;
  /* max-width: 35px; */
}

.closeManageShiftModalBtnImg:hover {
  opacity: 0.8;
}
.cursorPointer {
  cursor: pointer !important;
}

.timesItemIsNewBg {
  background-color: #efe7f2;
  padding-bottom: 2px;
  margin-top:-3px;
  border: 1px solid #d6c6dd;
  /* border-bottom: 2px solid #1964ba; */
  border-radius: 7px;
  /* justify-content: space-between; */
  /* min-width: 100%; */
  /* margin-top: 10px; */
  /* max-width: 90%; */
}

/* .whiteUntil {
  color: white;
} */

.displayBlock {
  display: block !important;
}
.publishTickImg {
  margin-right: 7px;
  margin-left: 0px;
  max-width: 16px;
}

.pubImmedBg {
  background-color: white;
  color: #143051;
  border-color: #687d97;

  background-color: #a8cc9a;
  border: 1px #6db451 solid;
  color: white;
}

.pubImmedBg:hover {
  background-color: #94b786;
}

.removeTagImg {
  /* margin-left: 24px; */
  /* margin-top: -2px; */
  /* margin-bottom: -10px; */
  animation-name: zoomModalIn !important;
  animation-duration: 0.2s !important;
  cursor: pointer;
  min-width: 22px;
  max-width: 22px;
  /* margin-left: 15px; */
}

.purpleEditChangeTeamIcon {
  max-width: 12px;
  margin-left: 14px;
  margin-bottom: 1px;
  margin-right: 0px;
  cursor: pointer;
}

.addTagModalHeader {
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  padding: 16px;
}

.addTagModalHeaderTitle {
  font-weight: 500;
  font-size: 1.2em;
}

.phantomModalHeaderUnit {
  /* background-color: black; */
  min-width: 15px;
}

.closeAddTagModalImg {
  cursor: pointer;
  margin-left: 4px;
  margin-right: -4px;
}

.closeAddTagModalImg:hover {
  opacity: 0.8;
}

.generateAvailTagsScrollBox {
  /* background-color: yellow; */
  max-height: 292px;
  min-height: 292px;
  overflow-y: scroll;
  max-width: 90%;
  margin-left: 5%;
  border-bottom: 1px solid #143051;
  border-top: 1px solid #c0d1e9;
  padding-bottom: 20px;
}

.addTagRowItem {
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-width: -moz-fit-content;
  max-width: 96%;
  margin-left: 2%;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #07f;
  border: #0469de 1px solid;
  margin-top: 8px;
}

.addTagRowItem:hover {
  background-color: #0469de;
}

.editUserLoadingModalBox {
  min-width: 200px;
  max-width: 200px;
  min-height: 120px;
  max-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #fff;
}

.editUserHorizLoadingImg {
  max-width: 60px;
  max-height: 20px;
  margin-top: 14px;
  margin-bottom: -14px;
}

.disableEditUserMenu {
  opacity: 0;
  pointer-events: none;
}

.editUserTopProfileSection {
  min-height: 140px;
  /* background-color: yellow; */
}

.editUserProPicIcon {
  min-width: 80px;
  max-width: 80px;
  min-height: 80px;
  max-height: 80px;
  object-fit: cover;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: -5px;
  /* cursor: pointer; */
  border: #e3e8ee solid 1px;
}

.editUserProPicIcon:hover {
  /* border-color: #b2bbc7 */
}

.editUserNameJtDiv {
  margin-top: 20px;
  /* background-color: orange; */
  display: flex;
  justify-content: space-around;
  padding-right: 10px;
  margin-bottom: 20px;
  /* max-width: 90%; */
}

.editUserEditImg {
  /* margin-right: 20px; */
  margin-right: 7px;
  cursor: pointer;
  /* max-width: 26px; */
  /* background-color: blue; */
  min-width: 28px;
  border-radius: 5px;
  border: #e3e8ee solid 2px;
}

.editUserEditImg:hover {
  border-color: #b2bbc7;
}

.editUserProfileDivSideUnitLeft {
  min-width: 0%;
  max-width: 0%;
}

.editUserProfileDivSideUnit {
  min-width: 10px;
  /* background-color: black; */
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.activeToggleJtNameUnit {
  border-left: 1px solid #f4f8fe;
  padding-left: 10px;
  margin-top: -82px;
  display: flex;
  flex-direction: column;
}

.editUserFullName {
  font-weight: 500;
  font-size: 20px;
}

.editUserJobTitle {
  font-size: 16px;
  margin-top: 5px;
  color: #516b8b;
  font-weight: 500;
}

.editUserJoined {
  font-size: 14px;
  margin-top: 8px;
  color: #8ea7c5;
}

.editUserClockedOnDiv {
  display: flex;
  /* background-color: orange; */
  min-width: 90%;
  max-width: 90%;
  justify-content: space-between;
  min-height: 40px;
  align-items: center;
  margin: 0 auto;
  margin-top: 40px;
}

.editUserClockedOnTxt {
  font-weight: 500;
  color: #143051;
}

.editUserTopProfileSplitter {
  min-height: 1px;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
  background-color: #e0ebf8;
  margin-top: 14px;
}

.editUserClockOffBtn {
  background-color: #07f;
  border: #0469de 1px solid;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
  min-width: fit-content;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 5px;
  cursor: pointer;
}

.editUserClockOffBtn:hover {
  background-color: #0469de;
}
.editUserProfileMiddle {
  min-width: 70%;
  max-width: 70%;
  /* margin-left: -10%; */
}

.editUserProfileMiddle2 {
  min-width: 90%;
  max-width: 90%;
  margin-right: -20%;
  margin-left: -20%;
}

.editNameFirstAndLastNameInputsDiv {
  display: flex;
  justify-content: space-around;
  /* background-color: yellow; */
  /* margin-left: -20px; */
}

.editUserFNameInput {
  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;
  margin-right: 4px;
  /* margin-left: -20px; */
  /* max-width: 136px; */
  color: #6a8bb1;
  min-height: 40px;
  padding-left: 8px;
  font-weight: 500;
  font-size: 16px;
  max-width: 115px;
}

.editUserFNameInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 6px;
  background-color: #fdfeff;
}

.editUserLNameInput {
  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;
  margin-left: 4px;
  /* max-width: 136px; */
  color: #6a8bb1;
  min-height: 40px;
  padding-left: 8px;
  font-weight: 500;
  font-size: 16px;
  max-width: 115px;
}

.editUserLNameInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 6px;
  background-color: #fdfeff;
}

.editNameUnit {
  max-width: 50%;
  min-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
}

.editUserFNameLabel {
  margin-left: 5px;
  font-size: 12px;
  color: #516b8b;
  margin-bottom: 2px;
  font-weight: 500;
}

.editJtUnit {
  /* background-color: blue; */
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 20px;
}

.editUserJobTitleLabel {
  margin-left: 5px;
  font-size: 12px;
  color: #516b8b;
  margin-bottom: 2px;
  font-weight: 500;
}

.editJobTitleInput {
  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;
  margin-right: 4px;
  min-width: 100%;
  color: #6a8bb1;
  min-height: 40px;
  padding-left: 8px;
  font-weight: 500;
  font-size: 16px;
}

.editJobTitleInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 6px;
  background-color: #fdfeff;
}

.saveNameAndJtBtn {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  background-color: #0bf;
  border: 1px solid #0af;
  padding-left: 3px;
  padding-right: 3px;
  max-width: 56px;
  min-width: 56px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}
.saveNameAndJtBtn:hover {
  background-color: #0af;
}

.editUserStartDateInput {
  border-radius: 5px 5px 0 0;
  border: none;
  background-color: #fff;
  color: #6a8bb1;
  /* font-size: 14px; */
  min-height: 35px;
  line-height: 35px;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 5px;
  letter-spacing: 1px;
  padding-top: 1px;
  font-weight: 500;
  max-width: 153px;
  min-width: 153px;
  background: url("../../img/general/editUserCal.svg") no-repeat right #f4f8fe;
  -webkit-appearance: none;
  background-position-x: 120px;
  border: none;
  border-bottom: 1px solid #dde9f9;
  margin-left: 0px;
  margin-top: 0px;
}

.editUserStartDateInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 7px;
  background-color: #fdfeff;
  background-position-x: 118px;
}

.invalidEditUserTopDetails {
  cursor: none;
  pointer-events: none;
  opacity: 0.4;
}

.editUserAbsenceRow {
  background-color: #f1f8ff;
  max-width: 90%;
  margin: 20px auto;
  min-height: 50px;
  border-radius: 5px;
  display: flex;
  max-height: 35px !important;
  min-height: 35px !important;
  cursor: pointer;
}

.editUserAbsenceRow:hover {
  background-color: #ecf1f6;
}

.editUserAbsenceYear {
  cursor: pointer;
  font-size: 12px;
  margin-top: 2px;
}

.editUserAbsencePercent {
  cursor: pointer;
}

.editUserAllowBarRow {
  background-color: #f1f8ff;
  max-width: 90%;
  margin: 18px auto;
  min-height: 50px;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  max-height: 35px !important;
  min-height: 35px !important;
  cursor: pointer;
}

.editUserAllowBarRow:hover {
  background-color: #ecf1f6;
}
.absenceScoreBar {
  background-color: #d2e6fd;
  min-height: 35px;
  /* min-width  */
  min-width: 90%;
  max-height: 35px;
  border-radius: 0 5px 5px 0;
  /* cursor: pointer; */
  /* position: fixed; */
  /* z-index: -1; */
}

.absenceScoreBar:hover {
  background-color: #c8ddf6;
}
.absenceBarTxtDiv {
  z-index: 9999;
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  display: flex;
  justify-content: space-between;
  margin-top: -47px;
  padding-left: 10px;
  padding-right: 10px;
  color: #143051;
  font-weight: 500;
  font-size: 14px;
}

.allowBarTxtDiv {
  z-index: 9999;
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  display: flex;
  justify-content: space-between;
  margin-top: -45px;
  padding-left: 10px;
  padding-right: 10px;
  color: #143051;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.score100 {
  min-width: 100%;
  animation-name: bar100 !important;
  animation-duration: 1s !important;
}

.score95 {
  min-width: 95%;
  animation-name: bar95 !important;
  animation-duration: 1s !important;
}

.score90 {
  min-width: 90%;
  animation-name: bar90 !important;
  animation-duration: 1s !important;
}

.score85 {
  min-width: 85%;
  animation-name: bar85 !important;
  animation-duration: 1s !important;
}

.score80 {
  min-width: 80%;
  animation-name: bar80 !important;
  animation-duration: 1s !important;
}

.score75 {
  min-width: 75%;
  animation-name: bar75 !important;
  animation-duration: 1s !important;
}

.score70 {
  min-width: 70%;
  animation-name: bar70 !important;
  animation-duration: 1s !important;
}

.score65 {
  min-width: 65%;
  animation-name: bar65 !important;
  animation-duration: 1s !important;
}

.score60 {
  min-width: 60%;
  animation-name: bar60 !important;
  animation-duration: 1s !important;
}

.score55 {
  min-width: 55%;
  animation-name: bar55 !important;
  animation-duration: 1s !important;
}

.score50 {
  min-width: 50%;
  animation-name: bar50 !important;
  animation-duration: 1s !important;
}

.score45 {
  min-width: 45%;
  animation-name: bar45 !important;
  animation-duration: 1s !important;
}

.score40 {
  min-width: 40%;
  animation-name: bar40 !important;
  animation-duration: 1s !important;
}

.score35 {
  min-width: 35%;
  animation-name: bar35 !important;
  animation-duration: 1s !important;
}

.score30 {
  min-width: 30%;
  animation-name: bar30 !important;
  animation-duration: 1s !important;
}

.score25 {
  min-width: 25%;
  animation-name: bar25 !important;
  animation-duration: 1s !important;
}

.score20 {
  min-width: 20%;
  animation-name: bar20 !important;
  animation-duration: 1s !important;
}

.score15 {
  min-width: 15%;
  animation-name: bar15 !important;
  animation-duration: 1s !important;
}

.score10 {
  min-width: 10%;
  animation-name: bar10 !important;
  animation-duration: 1s !important;
}

.score05 {
  min-width: 5%;
  animation-name: bar05 !important;
  animation-duration: 1s !important;
}

.score0 {
  min-width: 0%;
  animation-name: bar00 !important;
  animation-duration: 1s !important;
}

.editUserRowTitleAndInput {
  min-width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* background-color: #0044ff; */
}

.editUserRowPartOfTeamsRow {
  min-width: 100%;
}

.editUserValDiv {
  display: flex;
  /* background-color: yellow; */
  min-width: 20%;
  min-height: 40px;
  justify-content: center;
  /* padding-right: 25px; */
  align-items: center;
}

.editUserEditValue {
  margin-top: 4px;
  margin-bottom: -1px;
  font-size: 16px;
  color: #58bdee;
  font-weight: 500;
  text-align: left;
  margin-left: 5%;
  min-height: 40px;
  overflow-wrap: break-word;
  padding-top: 6px;
}

.primaryTeamBold {
  margin-top: 4px;
  margin-bottom: -1px;
  font-size: 16px;
  color: #00ccff;
  font-weight: 500;
  text-align: left;
  margin-left: 5%;
  min-height: 40px;
  overflow-wrap: break-word;
  padding-top: 6px;
}

.reqClockinValContainer {
  /* margin-top: 4px; */
  /* margin-bottom: -1px; */
  font-size: 16px;
  color: #8997a8;
  font-weight: 400;
  text-align: left;
  /* margin-left: 5%; */
  margin-right: 14px;
  min-height: 40px;
  overflow-wrap: break-word;
  /* padding-top: 6px; */
}

.editUserValEditIcon {
  cursor: pointer;
  border: #e3e8ee solid 2px;
  padding: 6px;
  border-radius: 5px;
  max-width: 28px;
}

.editUserValEditIcon:hover {
  /* transform: scale(1.12); */
  /* opacity: 0.8; */
  border: #b2bbc7 solid 2px;
  padding: 6px;
  border-radius: 5px;
}
.saveEditUserValBtn {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  background-color: #0bf;
  border: 1px solid #0af;
  padding-left: 12px;
  padding-right: 12px;
  /* max-width: 50px; */
  /* min-width: 50px; */
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}
.saveEditUserValBtn:hover {
  background-color: #0af;
}

.invalidDs {
  opacity: 0.2;
  pointer-events: none;
}

.cancelEditUserValBtn {
  font-size: 14px;
  font-weight: 500;
  padding-left: 7px;
  padding-right: 7px;
  max-width: 64px;
  min-width: 64px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  margin-right: 15px;
  margin-top: -30px;
}
.cancelEditUserValBtn:hover {
  background-color: #bbcada;
}

.editUserEmailValInput {
  color: #6a8bb1;
  min-height: 35px;
  padding-left: 9px;
  font-weight: 500;
  margin-left: 5%;
  min-width: 90%;
  margin-top: 5px;
  margin-bottom: 3px;
  font-size: 15px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 7px;
  background-color: #fdfeff;
}

.editUserEmailValInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 7px;
  background-color: #fdfeff;
}

.partOfTeamsDiv {
  /* background-color: blue; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  /* flex-direction: column; */
  margin-left: 5%;
  /* margin-top: 12px; */
  margin-bottom: 8px;
  /* min-height: 200px; */
}

.editUserTeamUnit {
  min-width: fit-content;
  border-radius: 5px;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 35px;
  min-height: 35px;
  align-items: center;
  border-radius: 5px;
  color: #496375;
  font-weight: 500;
  font-size: 14px;
  background-color: #e3e8ee;
  color: #4f566b;
  /* animation-name: zoomModalIn !important; */
  /* animation-duration: 0.1s !important; */
  margin-right: 8px;
  margin-top: 8px;
}

.ghostMgrOf {
  background-color: #f0f0f0 !important;
}

.editUserTeamPrimaryUnit {
  /* border: solid 2px #496375; */
  /* padding-right: 4px; */
}

.removeTeamUnitCrossImg {
  min-width: 14px;
  margin-left: 8px;
  margin-right: 4px;
  cursor: pointer;
  padding: 2px;
}

.removeTeamUnitCrossImgBlank {
  min-width: 0px;
  visibility: hidden;
  pointer-events: none;
  margin-left: 0px;
  margin-right: 0px;
  cursor: pointer;
  margin-left: -2px;
  /* padding: 2px; */
}

.toggleAddTeamBtn {
  min-height: 30px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #0bf;
  border: 1px solid #0af;
  margin-left: 5%;
  margin-bottom: 18px;
  margin-top: 18px;
  padding-left: 6px;
  padding-right: 6px;
}

.addToOtherTeamBtnTxtPlus {
  font-size: 16px;
  margin-left: 3px;
  margin-top: -2px;
}

.toggleAddTeamBtn:hover {
  opacity: 0.8;
}

.toggleAddTeamDropdown {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 263px;
  min-width: 263px;
  line-height: 26px;
  height: 35px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #516b8b;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 242px;
  margin-left: 5%;
  margin-top: 18px;
  margin-bottom: 18px;
}

.changePrimaryTeamDropdown {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 263px;
  min-width: 263px;
  line-height: 26px;
  height: 35px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #516b8b;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 242px;
  margin-left: 3%;
  margin-top: 14px;
  margin-bottom: 14px;
}

.editUserPTeamExplain {
  color: #516b8b;
  font-size: 12px;
  text-align: left;
  margin-left: 5%;
  margin-bottom: 20px;
  margin-top: 14px;
  padding-right: 20px;
  font-weight: 400;
  line-height: 14px;
  padding-left: 10px;
  border-left: 2px solid #516b8b;
}

.doesNotManageTeamSpan {
  color: #bbcada;
  font-size: 12px;
  text-align: left;
  /* margin-left: 5%; */
  /* margin-bottom: 20px; */
  margin-top: 0px;
  font-weight: 500;
}

.editPTeamIcon {
  /* margin-top: -40px; */
  /* top: 0; */
  /* position: fixed */
}

.noPermissionsEditUserTxt {
  color: #496375;
  /* font-weight: 500; */
}

.noPermissionsCloseBtn {
  font-size: 14px;
  color: #fff;
  background-color: #214978;
  border: solid #143051 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 30px;
  cursor: pointer;
  min-width: 100%;
  margin-top: 30px;
}

.noPermissionsCloseBtn:hover {
  background-color: #143051;
}

.generatePTeamMgrs {
  margin-top: 10px;
  margin-left: 10px;
  line-height: 24px;
  font-weight: 500;
}

.editUserPTeamRow {
  align-items: flex-start;
}

.pTeamMgrBulletSpan {
  min-width: 10px;
  max-width: 10px;
  min-height: 10px;
  max-height: 10px;
  background-color: black;
}

.hourlyRateInputter {
  max-width: 86px;
  min-width: 86px;
}

.hourlyRateInclLoaderDiv {
  display: flex;
  margin-top:-12px
}

.updatingShiftsAniBox {
  font-size: 12px;
  max-width: 180px;
  text-align: left;
  margin-left: 20px;
  margin-top: 8px;
  display: flex;
}

.loadingUpdateShiftsLoaderImg {
  max-width: 40px;
  min-width: 40px;
  max-height: 16px;
  margin-top: 3px;
  margin-left: 12px;
}

.updatingShiftsSpan {
  /* margin-left: 4px; */
  margin-top: 4px;
  opacity: 0.5;
}
.hourlyRateHistoryTimelineBtn {
  /* background-color: blue; */
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: -100px;
  margin-bottom: 60px;
  min-height: 40px;
  align-items: center;
}

.hourlyRateHistoryTimelineBtn2 {
  /* background-color: blue; */
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: -180px;
  margin-bottom: 94px;
  min-height: 40px;
  align-items: center;
}

.hourlyRateTitle {
  min-width: 104px;
}

.salariedRow {
  min-height: 135px;
  max-height: 135px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 16px;
  padding-left: 5%;
  padding-right: 5%;
}

.salariedToggleDiv {
  min-height: 70px;
  max-height: 70px;
  background-color: white;
  /* margin-top: 30px; */
  /* margin-left: 12px; */
  min-width: 112px;
  border: 1px solid #e3e8ee;
  border-radius: 5px;
  max-width: 140px;
  font-size: 16px;
}

.wPattToggleDiv {
  min-height: 105px;
  max-height: 105px;
  background-color: white;
  /* margin-top: 30px; */
  /* margin-left: 12px; */
  min-width: 234px;
  border: 1px solid #e3e8ee;
  border-radius: 5px;
  max-width: 140px;
  font-size: 16px;
}

.allowToggleDiv {
  min-height: 105px;
  /* max-height: 105px; */
  background-color: white;
  /* margin-top: 30px; */
  /* margin-left: 12px; */
  min-width: 264px;
  border: 1px solid #e3e8ee;
  border-radius: 5px;
  max-width: 264px;
  font-size: 16px;
  margin-left: 10px;
  margin-top: 20px;
}

.userHRateHistoryBtn {
  min-width: -moz-fit-content;
  min-width: fit-content;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  /* border: 1px solid #eaf3fd; */
  background-color: #b7c9e4;
  border: 1px solid #99adcc;
  padding-left: 7px;
  padding-right: 6px;
  cursor: pointer;
  min-height: 30px;
  display: flex;
  align-items: center;
  /* margin-right: 6%; */
  color: #fff;
  max-height: 30px;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  /* margin-top: 2px; */
}

.editUserPopUpIcon {
  max-width: 15px;
  margin-left: 4px;
}

.contrhourstimelinepopup {
  max-width: 15px;
  margin-left: 4px;
}

.userHRateHistoryBtn:hover {
  background-color: #afc1db;
}

.editHrRateValDiv {
  margin-top: 15px;
}

.hourlyRateTimelineHeader {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  padding-left: 20px;
  margin-top: 5px;
  padding-right: 40px;
  margin-bottom: 20px;
}

.mpwTimelineHeader {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  padding-left: 25px;
  margin-top: 20px;
  padding-right: 40px;
  margin-bottom: 10px;
}

.hourlyRateTimelineCloseImg {
  cursor: pointer;
}

.hourlyRateTimelineCloseImg:hover {
  opacity: 0.8;
}

.hourlyRateTimelineScrollWrapper {
  background-color: #eaf3fd;
  overflow-y: scroll;
  max-height: 300px;
  /* min-height: 300px; */
  max-width: 90%;
  margin-left: 5%;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #cbd7e6;
  padding-top: 8px;
}

.noTimelineHistoryTxt {
  font-size: 12px;
  padding: 20px;
  text-align: left;
  margin-top: -10px;
  color: #516b8b;
  font-weight: 400;
  line-height: 16px;
}

.noLaterThanYesterdaySpan {
  color: #e89292;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 3px;
  margin-left: 12px;
  margin-top: 0px;
}

.overlapWarnInline {
  color: #e89292;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 3px;
  /* margin-left: 12px; */
  margin-top: 10px;
  font-weight: 500;
  font-size: 12px;
}

.editWorkingPatternDropdown {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 223px;
  min-width: 223px;
  line-height: 26px;
  height: 35px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 202px;
  margin-left: 4%;
  margin-top: 8px;
  margin-bottom: 5px;

  margin-top: 16px;
  margin-bottom: -8px;
}

.editWorkingPatternDropdown:hover {
  border-color: #bac5d3;
}

.workingPatternExplain {
  text-align: left;
  padding-left: 10px;
  font-size: 12px;
  margin-top: 30px;
  border-left: 2px solid #516b8b;
  color: #516b8b;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-weight: 400;
  line-height: 16px;
  max-width: 90%;
  margin-bottom: 30px;
}

.workingPatternExplainMobModal {
  max-width: 80vw;
}

.approveClockEditUserRow {
  /* min-height: 280px; */
  padding-top: 110px;
  padding-bottom: 90px;
}

.gpsZoneEditUserRow {
  /* min-height: 280px; */
  max-height: fit-content;
  padding-top: 30px;
  padding-bottom: 20px;
}

.approveClockEditUserRowDesktop {
  min-height: 260px;
  padding-bottom: 110px;
}

.payClockedRowMob {
  padding-top: 90px;
  min-height: 270px;
  /* padding-bottom: 30px; */
}

.autoApproveClockKnobContainer {
  margin-top: -80px;
}

.desktopAutoApproveClockDiv {
  padding-left: 0px;
}

.autoApproveClockDiv {
  max-width: 80%;
  padding-left: 8px;
}

.gpsZoneClockDiv {
  max-width: 90%;
  padding-left: 8px;
  padding-top: 10px;
}
.autoApproveExplain {
  text-align: left;
  margin-left: 5%;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 14px;
  color: #516b8b;
  min-height: 72px;
  max-width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 16px;
  font-weight: 400;
  

  /* background-color: orange; */
}

.clockGpsZoneExplain {
  text-align: left;
  margin-left: 5%;
  font-size: 14px;
  margin-top: 14px;
  margin-bottom: 14px;
  color: #bbcada;
  min-height: 72px;
  max-width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-weight: 500;
  line-height: 18px;
}

.amendOwnClocksRow {
  text-align: left;
  margin-left: 5%;
  font-size: 12px;
  margin-top: 14px;
  margin-bottom: 14px;
  color: #516b8b;
  min-height: 72px;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 16px;
  font-weight: 400;
}

.managesOwnTeamRow {
  margin-top: 3px;
  color: #8ea7c5;
}

.managesOwnTeamRow:first-child {
  margin-top: 12px;
}

.wPattEditUserRow {
  min-height: fit-content;
  /* max-height: 110px; */
  padding-top: 16px;
  min-width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #ddebf4;
  /* font-size: 1.2em; */
  /* border: none; */
}

.editWPatternSaveContainer {
  margin-left: -5%;
  min-width: 25%;
}

.wPattEditBtn {
  margin-top: -26px;
}

.invisWPattExplain {
  visibility: hidden;
  /* opacity: 0.5    ; */
}

.wPattSubTitle {
  margin-top: 28px;
  color: #214978;
  text-align: left;
  font-size: 16px;
  margin-left: 5%;
  font-weight: 500;
  text-align: left;
}

.fixedSchedEditContainer {
  margin-top: 0px;
}

.fixedSchedDayRow {
  background-color: white;
  padding-left: 5%;
  min-width: 96%;
  /* border-bottom: 1px solid #e6eaee; */
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding-bottom: 10px;
  padding-top: 10px;
  max-width: 96% !important;
  margin: 10px auto;
}

.fixedSchedDayRow:first-child {
  margin-top: 12px;
}

.dayNameAndPowerRow {
  /* background-color: lime; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -6px;
  margin-bottom: -2px;
  min-width: 93%;
}

.editDayFxSchedKnob {
  /* background-color: yellow; */
  margin-bottom: 8px;
}

.fixedSchedDayTitle {
  font-size: 16px;
  font-weight: 500;
  margin-left: 14px;
  margin-top: 6px;
  color: #8997a8;
  display: flex;
}

.dayPowerOn {
  color: #07f;
}

.fadeEditDayFsRow {
  opacity: 0.5;
}

.editDsTimeInput {
  background-color: #fff;
  width: 77px;
  max-width: 77px;
  line-height: 35px;
  padding-top: 1px;
  height: 35px;
  border: 1px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 15px;
  font-weight: 500;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  cursor: text;

  /* border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 3px;
  background-color: #fdfeff; */
}

.editDsTimeInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 3px;
  background-color: #fdfeff;
}

.editDsBrkInput {
  background-color: #fff;
  width: 47px;
  max-width: 47px;
  line-height: 35px;
  padding-top: 1px;
  height: 35px;
  border: 1px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 15px;
  font-weight: 500;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  cursor: text;

  /* border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 3px;
  background-color: #fdfeff; */
}

.editDsBrkInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 3px;
  background-color: #fdfeff;
}
.nonPowerEditDsTimeInput {
  color: #f4f8fe;
}

.selEditDateInputter {
  border: 2px solid #00ccff;
}
.editUserFxDayDataRow {
  display: flex;
}

.editDsDayStartUnit {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 7px;
}

.editDsDayStartUnit:first-child {
  margin-left: 0px;
}

.editDsDayStartTxt {
  margin-left: 4px;
  font-weight: 500;
  margin-bottom: 3px;
  font-size: 12px;
  color: #496375;
}

.editDayDsBrkDiv {
  display: flex;
}

.editDayFsBrkMinsTxt {
  font-weight: 500;
  font-size: 12px;
  margin-top: 8px;
  margin-left: 3px;
}

.fixedScheduledRow {
  background-color: #edf2f9;
  /* margin-top: -50px; */
}

.fixedVariedRow {
  background-color: #edf2f9;
}

.saveDayFSBtn {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  background-color: #0bf;
  border: 1px solid #0af;
  padding-left: 12px;
  padding-right: 12px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border-radius: 5px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5%;
  margin-top: 3px;
  cursor: pointer;
}

.saveDayFSBtn:hover {
  background-color: #0af;
}
.editDayFsTopRowDiv {
  display: flex;
  justify-content: space-between;
}

.editedFixedSchedRowHighlight {
  border: #00ccff solid 1px;
}

.fixedSchedDayDurSpan {
  /* background-color: yellow; */
  margin-left: 22px;
  /* color: #0044ff; */
  /* background-color: #859dbb; color: white; border:1px solid #496375; */
  min-width: fit-content;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #859dbb;
  /* text-align: left; */
  /* display: flex;
  justify-content: flex-start; */
  /* min-height: 100%; */
}

.dayNameEditUserSpan {
  min-width: 80px;
  text-align: left;
  margin-left: 12px;
  margin-top: 3px;
}

.wPattSubDiv {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
}
.wPattTotalHours {
  margin-top: 60px;
  color: #8ea7c5;
  border-radius: 5px;
  background-color: #fff;
  margin-right: 5%;
  font-weight: 500;
  text-align: center;
  padding-left: 8px;
  padding-right: 8px;
  min-height: 100%;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #e0ebf8;
  min-width: -moz-fit-content;
  min-width: fit-content;
  border-right: 2px solid;
}

.desktopwPattTotalHours {
  max-width: fit-content;
  min-width: fit-content;
  padding-right: 7px;
  padding-left: 7px;
  margin-top: 20px;
}

.fixedVarHMnumInput {
  background-color: #fff;
  width: 49px;
  max-width: 49px;
  /* line-height: 30px; */
  /* padding-top: 1px; */
  height: 35px;
  border: 1px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 15px;
  font-weight: 500;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  /* border-bottom: 1px solid #dde9f9; */
  background-color: #f4f8fe;
  cursor: text;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  /* padding-left: 3px; */
  background-color: #fdfeff;
  margin-bottom: 4px;
}

/* .fixedVarHMnumInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 3px;
  background-color: #fdfeff;
} */
.editUserFVContainer {
  background-color: #f4f8fe;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  max-width: 220px;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: flex-start; */
  margin-left: 5%;
  /* justify-content: space-between; */
  min-height: 90px;
  padding: 10px;
  margin-top: 0px;
  margin-bottom: 24px;
}

.editUserFvBox {
  display: flex;
  min-height: 66px;
}

.editUserFVleft {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.editUserFVrow {
  display: flex;
  align-items: center;
}

.saveFVrowBtn {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  background-color: #0bf;
  border: 1px solid #0af;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 5px;
  min-height: 35px;
  margin-left: 30px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  cursor: pointer;
}

.saveFVrowBtn:hover {
  background-color: #0af;
}

.editUserFVHoursMinsTxt {
  margin-top: 6px;
  margin-left: 6px;
  font-weight: 500;
  font-size: 14px;
  min-width: 50px;
  text-align: left;
  color: #214978;
}

.fvPerweekTxt {
  font-size: 12px;
  color: #516b8b;
  text-align: left;
  margin-bottom: 9px !important;
  margin-bottom: 8px;
  font-weight: 400;
}

.editUserAllowInfoBox {
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  min-height: 40px;
  margin-top: 40px;
  margin-bottom: -30px;
  /* background-color: yellow; */
}

.allowInfoTxtString {
  font-size: 14px;
  text-align: left;
  color: #bbcada;
  line-height: 22px;
  font-weight: 500;
  line-height: 18px;
}

.editUserProfileTeamDiv {
  background-color: yellow;
  min-height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5%;
  /* margin-top: -200px; */
  margin-bottom: -40px;
  z-index: 9999999999;
}

.editUserPTeamBracketSpan {
  background-color: #e3e8ee;
  color: #4f566b;
  font-weight: 500;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  font-size: 12px;
  padding-bottom: 1px;
}

.teamInlineCursp {
  cursor: pointer;
}

.checkAllowancesHighlighted {
  /* background-color: #00ccff; */
  /* color: #00ccff; */
  border-radius: 5px;
  /* padding-left: 12px; */
  /* padding-right: 5px; */
  margin-left: -12px;
  /* margin-right: -5px; */
}

.checkAllowancesHighlightedSpanBlob {
  min-width: 12px;
  background-color: #0044ff;
  border-radius: 10px;
  margin-right: 2px;
  margin-left: 6px;
}

.checkAllowancesUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.checkAllowModalBox {
  min-width: 300px;
  max-width: 300px;
  background-color: white;
  min-height: 200px;
  border-radius: 5px;
  cursor: default;
}

.checkAllowTxt {
  min-height: 150px;
  max-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: #496375;
  font-size: 15px;
}

.checkAllowBtnBar {
  min-height: 50px;
  max-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row-reverse;
  padding-right: 2%;
  padding-left: 2%;
  margin-bottom: 2px;
}

.checkAllowBtn {
  min-width: 46%;
  background-color: #0077ff;
  color: white;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
}

.checkAllowBtn:hover {
  opacity: 0.9;
}

.checkAllowBtnExit {
  min-width: 46%;
  background-color: #496375;
  color: white;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
}

.checkAllowBtnExit:hover {
  opacity: 0.9;
}

.mpwTimelineHolderDiv {
  min-height: 30px;
  min-width: 40%;
  display: flex;
  justify-content: flex-end;
  margin-top: -185px;
  margin-bottom: 159px;
}

.mpwTimelineMob {
  margin-top: -164px;
  margin-bottom: 134px;

  margin-top: -212px;
  margin-bottom: 171px;
}

.fixedSchedMobMpwTimelineBtn {
  margin-top: -248px;
  margin-bottom: 215px;
}
.fixedSchedMobMpwTimelineBtnDesktop {
  margin-top: -215px;
  margin-bottom: 181px;
}
.mpwTimelineZero {
  /* margin-top: -125px; */
  margin-bottom: 126px;
}

.mpwTimelineButton {
  min-width: -moz-fit-content;
  min-width: fit-content;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  /* border: 1px solid #eaf3fd; */
  background-color: #0af;
  border: 1px solid #07f;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  min-height: 35px;
  display: flex;
  align-items: center;
  margin-right: 6%;
  color: #fff;
  max-height: 35px;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  margin-top: 12px;
  margin-bottom: 2px;
}

.mpwTimelineButton:hover {
  background-color: #0fa2eb;
}

.workPatternTitle {
  /* background-color: yellow; */
  margin-top: 45px;
}

.zeroWpattEditBtn {
  /* background-color: blue; */
  margin-top: -6px;
}

.lastSavedContractedHoursTxt {
}

.hideRemoveTimelineBtn {
  pointer-events: none;
  opacity: 0.1;
  cursor: default;
}

.hideRemoveTimelineBtn:hover {
  opacity: 0.1;
}

/* .whiteTxtTime {
  color: white;
} */

.x12134 {
  color: #143051;
}

.openUnitTypeDropdown {
  margin-top: 10px;
}

.proPicChangeForm {
  /* background-color: blue; */
  max-width: 90px;
  min-width: 90px;
  margin: -72px auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding-right: 13px;
  margin-bottom: 70px;
  /* padding-left: 5%; */
}

.proPicChangeBtn {
  color: white;
  max-width: 40px;
  opacity: 0;
  /* visibility: hidden; */
  cursor: pointer;
  margin-top: -25px;
  margin-bottom: 5px;
  cursor: pointer;
}

.editProPicPencilIcon {
  min-width: 28px;
  min-height: 28px;
  background-color: white;
  border-radius: 5px;
  border: #e3e8ee solid 2px;
  cursor: pointer !important;
  opacity: 0.8;
}

.editProPicPencilIcon:hover {
  border-color: #b2bbc7;
  opacity: 1;
}

.initialContainer {
  min-width: 80px;
  min-height: 80px;
  max-width: 80px;
  max-height: 80px;
  margin: 20px auto;
  border-radius: 5px;
  /* margin-bottom: -20px; */
}

.profilePicturePlaceholder {
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}

.letter1letter2 {
  display: flex;
  margin-left: 9px;
  justify-content: center;
  align-items: center;
  margin-top: -70px;
  color: white;
  min-width: 60px;
  max-width: 60px;

  font-size: 40px;
  font-weight: 500;
  display: none;
  /* letter-spacing: 170px; */
  /* character-s  */
  /* letter-spacing: 10px; */
}

.pullDown20px {
  margin-bottom: -20px;
}

.clockCardInitials {
  max-width: 40px !important;
  max-height: 40px !important;
  min-width: 40px !important;
  min-height: 40px !important;
}

.removeProPicIcon {
  min-width: 28px;
  min-height: 28px;
  max-width: 28px;
  max-height: 28px;
  background-color: white;
  border-radius: 5px;
  border: #e3e8ee solid 2px;
  cursor: pointer !important;
  padding: 4px;
  margin-bottom: 25px;
  margin-left: -14px;
  margin-right: -16px;
}

.notAdminIsSelf {
  opacity: 0.4;
  cursor: default;
  /* pointer-events: none; */
}

.individualApproveTitle {
  padding-right: 80px;
  line-height: 24px;
  margin-bottom: 10px;
}

.whoCanApproveTitle {
  padding-right: 100px;
}

.whoCanManageUserDiv {
  background-color: blue;
}

.pTeamMgrName {
  color: #516b8b;
  font-size: 12px;
  text-align: left;
}

.whoCanManageUserClockRow {
  /* margin-top: 8px; */
  margin-left: 5%;
  color: #8ea7c5;
  font-weight: 500;
}

.whoCanManageUserClockRow:first-child {
  margin-top: 0px;
}

.finalTeamEditUserRow {
  margin-bottom: 50px;
}

.approveOwnClocksRow {
  min-height: fit-content !important;
  max-height: fit-content !important;
  padding-top: 40px;
  padding-bottom: 20px;
}

.allStaffZoneBg {
  background-color: #d0d9e5;
  cursor: pointer;
}

.invalidFS {
  opacity: 0.3;
  pointer-events: none;
}

.paddingTopWPatt {
  padding-top: 22px !important;
}

.hoverRowColour:hover {
  background-color: #f7f9fb;
  cursor: pointer;
}

.editUserMoreBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  min-width: 92px;
  max-width: 92px;
  max-height: 35px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  border-radius: 5px;
  min-height: 35px;
  cursor: pointer;
  /* min-width: 100%; */
}

.editUserMoreBtn:hover {
  background-color: #214978;
}

.editUserThreeDotsImg {
  max-width: 17px;
  margin-right: 5px;
  margin-left: 2px;
}

.whitecrossEditUserMoreImg {
  max-width: 14px;
  min-width: 14px;
}

.editUserShowMoreUnderlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 0, 0, 0.2);
  min-width: 100vw;
  min-height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 9999999;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.editUserShowMoreModalBox {
  background-color: #f4f8fe;
  min-width: 100vw;
  max-width: 100vw;
  /* max-height: 500px; */
  min-height: 310px;
  max-height: 310px;

  border-radius: 5px 5px 0 0;

  align-items: center;
  color: #143051;
  position: fixed;
  bottom: 0;
  text-align: center; 
  cursor: default;
  padding-left: 20px;
  padding-right: 20px;
  animation-name: moveEditTeamUserBoxUp !important;
  animation-duration: 0.1s !important;
  /* padding-bottom: 20px; */
}

.desktopShowMoreModalBox {
  position: relative;
  min-width: 340px;
  max-width: 340px;
  border-radius: 5px;
  min-height: 200px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.showMoreModalHeader {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 20px;
}

.editUserActionsModalMenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 60%;
  margin-bottom: 10px;
}

.editUserActionsBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 72px;
  padding-left: 13px;
  padding-right: 13px;
  min-height: 35px;
  max-height: 35px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-top: 10px;
}

.editUserActionsBtn:visited {
  color: white;
}
.editUserActionsBtn:hover {
  background-color: #bbcada;
}
.editUserActionSplitter {
  min-height: 1px;
  min-width: 80%;
  max-width: 80%;

  background-color: #214978;
  margin-top: 10px;
}

.editUserMoreFlexBox {
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
}

.editUserMoreFlexRight {
  min-width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.editUserMoreImg {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  padding-left: 9px;
  padding-right: 9px;
  min-height: 50px;
  max-height: 50px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
  max-width: -moz-fit-content;
  max-width: 50px;
  margin-top: 10px;
}

.editUserMoreImg:hover {
  background-color: #bbcada;
}

.editUserMoreFooter {
  /* background-color: yellow; */
  min-width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 10px;
  border-top: 1px solid #bac5d3;
  margin-top: 10px;
}

.editUserMoreCloseBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
}

.editUserMoreCloseBtn:hover {
  background-color: #214978;
}

.messageEditUserActionsColours {
  background-color: #07f;
  border: #0469de 1px solid;
  margin-bottom: 25px;
}

.messageEditUserActionsColours:hover {
  background-color: #0469de;
}

.emailAlreadyExistsSpan {
  /* background-color: #214978; */
  color: #ba0000;
  font-size: 12px;
  margin-left: 8px;
  font-weight: 500;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.reAvgTimelineRow {
  min-width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
}

.reAvgTimelineBtn {
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-size: 12px;
  font-weight: 500;
  border-radius: 5px;
  /* border: 1px solid #eaf3fd; */
  background-color: #b7c9e4;
  border: 1px solid #99adcc;
  padding-left: 5px;
  padding-right: 4px;
  cursor: pointer;
  min-height: 26px;
  display: flex;
  align-items: center;
  /* margin-right: 6%; */
  color: #fff;
  max-height: 30px;
  margin-left: 178px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-top: -34px;
}

.reAvgTimelineBtn:hover {
  background-color: #afc1db;
}

.salariedToggleRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-height: 35px;
  padding-left: 3px;
  /* padding-right: 12px; */
  margin-top: 0px;
  font-weight: 500;
  color: #496375;
  padding-bottom: 2px;
  cursor: pointer;
}

.wPattInfoTxt {
  font-size: 10px;
}

.selectedSalariedToggleRow {
  background-color: #f3f7ff;
}

.editUserRow2WPattDiv {
  display: flex;
  justify-content: space-between;
  /* margin-top:10px; */
}

.editUserRow2WPattDivMob {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
}

.wPattExplainDiv {
  margin-top: 0px;
  margin-left: 5px;
  font-size: 12px;
  line-height: 16px;
  background: #f4f8fe;
  text-align: left;
  max-width: 150px;
  min-width: 150px;

  /* border: 1px solid #bbcada; */
  border-radius: 5px;
  padding: 10px;
  color: #8ea7c5;
  margin-left: 15px;
}
.salariedToggleRow:hover {
  background-color: #edf3f9;
}
.salariedToggleRowFirst {
  /* margin-top: -1px; */
  border-bottom: 1px solid #e3e8ee;
  /* padding-bottom: 2px; */
}
.salariedToggleRow:last-child {
  margin-top: -1px;
  border-top: 1px solid #e3e8ee;
  padding-bottom: 2px;
}
.salariedKnob1 {
  margin-top: -5px;
}
.salariedKnob {
  margin-top: -3px;
}
.salariedToggleTitle {
  color: #bac5d3;
  min-width: 68px;
  text-align: left;
  padding-left: 8px;
  font-size: 14px;
}

.salariedSel {
  color: #143051;
}

.salariedRowTop {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  /* background-color: blue; */
}

.wPattTopRow {
  /* background-color: blue; */
}

.workingPattInlineTxt {
  margin-right: 8px;
}

.salariedRowHeading {
  margin-top: 2px;
  color: #214978;
  text-align: left;
  font-size: 12px;
  margin-left: 5%;
  font-weight: 500;
  margin-top: 22px;
}

.salariedRowLower {
  /* background-color: blue; */
  min-height: 70px;
  min-width: 100%;
  max-height: 70px;
  /* margin-top: 20px; */
  display: flex;
  /* justify-content: space-between; */
  justify-content: flex-start;
}

.salariedInputCol {
  min-width: 110px;
  margin-left: 13px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-left: 1px solid #ddebf4;
  padding-left: 7px;
}

@media only screen and (max-width: 380px) {
  .salariedInputCol {
    min-width: 100px;

    margin-left: 10px;
    padding-left: 0px;
    border: none;
  }
}

.salariedToggleAndInputLeftWrap {
  display: flex;
  /* min-width: 75%; */
}

.hourlyRateValueSaved {
  margin-top: 4px;
  margin-bottom: -1px;
  font-size: 16px;
  color: #143051;
  font-weight: 500;
  text-align: left;
  margin-left: 5%;
  min-height: 40px;
  overflow-wrap: break-word;
  padding-top: 6px;

  /* font-size: 18px; */
  /* color: #8997a8; */
  margin-top: 4px;
  margin-left: 4px;
}

.salariedEditSaveCol {
  min-width: 22%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  /* padding-right:8px; */
  padding-top: 12px;
}

.annualSalarySpan {
  font-size: 12px;
}

.hrSalRow {
  display: flex;
  justify-content: flex-start;
  margin-top: 3px;
  cursor: pointer;
}

.hrSalRow:first-child {
  border-bottom: 1px solid #dde9f9;
  padding-bottom: 3px;
}

.hrlySalTickBox {
  background-color: #6a8bb1;
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  cursor: pointer;
  opacity: 0.8;
  border: 1px solid #516b8b;
}

.hrlySalTickBox:hover {
  opacity: 1;
}
.perHourOrSalaryDiv {
  margin-top: 4px;
  margin-left: 5px;
}

.hrlySalTickBoxImg {
}

.nonWorkingDaysDiv {
  /* background-color: #edf2f9; */
  min-height: 120px;
  border-top: 1px solid #ddebf4;
  padding-top: 25px;
  border-bottom:1px solid #ddebf4;
  margin-bottom:10px;
}

.userNonWorkingDayDiv {
  display: flex;
  justify-content: flex-start;
  /* padding-left: 3%; */
  /* padding-right: 5%; */
  margin-top: 23px;
  /* padding-bottom: 30px; */
  border: 1px solid #cbd7e6;
  border-radius: 5px;
  max-width: 90%;
  min-width: 90%;

  margin-left: 4%;
  margin-bottom: 20px;
}

.userNonWorkingDayUnit {
  min-width: 14.31%;
  max-width: 14.31%;

  border-right: 1px solid #cbd7e6;
  cursor: pointer;
  padding-right: 1px;
  background-color: white;
}

@media only screen and (min-width: 821px) {
  .userNonWorkingDayUnit:hover {
    background-color: #f4f8fe;
  }
}

.userNonWorkingDayUnit:last-child {
  border: none;
}

.userNonWorkDayTitle {
  color: #143051;
  min-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
  background-color: #f4f8fe;
  padding-bottom: 2px;
  font-weight: 500;
  font-size: 12px;
}

.usernonWorkKnob {
  margin-right: 0px;
  border-top: 1px solid #dde9f9;
  display: flex;
  justify-content: center;
  padding-bottom: 12px;
  max-height: 52px;
}

.unselectedHpwAvg {
  color: #7f90a4;
}

.addOrDeductContent {
  padding-left: 10px;
}

.noteShiftTagsDiv {
  display: flex;
  flex-wrap: wrap;
  padding-left: 15px;
  padding-bottom: 8px;
}

.editUserMessageBtn {
  background-color: #0077ff;
  border: 1px solid #0469de;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 8px;
  cursor: pointer;
  font-size: 14px;
}

.editUserMessageBtn:hover {
  background-color: #0469de;
}

.editUserMsgChatImg {
  max-width: 15px;
  margin-right: 6px;
}

.unarchiveImg {
  max-width: 15px;
  margin-left: -1px;
  margin-right: 7px;
}

.editUserWPattLoadingDiv {
  /* background-color: blue; */
  min-height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wPattLoadingThreeDotsImg {
  max-width: 50px;
  margin-bottom: 50px;
}

.savedFv {
  color: #bbcada;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-left: 44px;
  font-size: 12px;
}

.savedFvTickImg {
  margin-left: 7px;
  display: none;
}

.loadingSalGif {
  max-width: 40px;
  margin-top: 17px;
  margin-left: 5px;
}

.dayNameAndPowerRowLeft {
  min-width: 130px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
}

.mobFixedSchedTitle {
  font-size: 15px !important;
}

.nonWorkableDaysExplain {
  text-align: left;
  margin-left: 5%;
  font-size: 12px;
  line-height: 16px;
  margin-top: 22px;
  margin-bottom: 24px;
  color: #516b8b;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-weight: 400;
  padding-right: 20px;
  padding-left: 10px;
  border-left: 2px solid #516b8b;
}

.allowBorderLeftActive {
  border-left: 3px #00aaff solid;
}

.defaultPointer {
  cursor: default;
}

.fixedVarOrZeroMobMpwTimelineBtnDesktop {
  margin-top: -197px !important;
}

.unselAnnLeaveOrCustom {
  border-bottom: 1px solid #f4f8fe;
}
.unselAnnLeaveOrCustom:hover {
  color: #516b8b;
  border-bottom: 1px solid #e3e8ee;
}

.saveFxWidth {
  min-width: 40%;
}

.payClockTimeOnlyTitle {
  margin-top: 40px;
}

.payClockTimeOnlyDiv {
  /* background-color: orange; */
}

.removeUsrBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 68px;
  padding-left: 7px;
  padding-right: 9px;
  min-height: 35px;
  max-height: 35px;
  color: #fff;
  background-color: #859dbb;
  border: #516b8b 1px solid;
  border-radius: 5px;
  cursor: pointer;
}

.removeUsrBtn:hover {
  background-color: #6e86a3;
}

.mobileHourlyRateTimeline {
  min-width: 100vw;
  position: fixed;
  bottom: 0px;
  border-radius: 15px 15px 0 0;
}

.clockTitleSize {
  font-size: 16px !important;
}

.cancelEditNameJt {
  cursor: pointer;
  border: #e3e8ee solid 2px;
  padding: 6px;
  border-radius: 5px;
  max-width: 28px;
  margin-right: 10px;
  /* margin-bottom: 10px; */
  /* margin-left:30px */
}

.cancelEditNameJt:hover {
  border-color: #b2bbc7;
}

.closeEditMyShiftModalTxt {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  max-width: 66px;
  min-width: 66px;
}

.closeEditMyShiftModalTxt:hover {
  background-color: #214978;
}

.editUserClosedDaysTimelineBtnHolder {
  /* background-color: yellow; */
  padding-right: 5%;
  margin-top: -104px;
  margin-bottom: 48px;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
}

.editUserClosedDaysTimelineBtn {
  background-color: #0af;
  color: white;
  cursor: pointer;
  border: 1px solid #07f;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  max-height: 35px;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 15px;
}

.editUserClosedDaysTimelineBtn:hover {
  background-color: #0fa2eb;
}

.removeUserInfoTxt {
  font-weight: 400;
  color: #bbcada;
  font-size: 14px;
  line-height: 16px;
  margin-top: 12px;
}

.alignLeft {
  text-align: left !important;
}

.canxRemoveUsrBtn {
  text-align: center;
  min-width: 50px;
  color: #bbcada;
  font-size: 14px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 80px !important;
  margin: 15px 5px -5px;
  cursor: pointer;
}

.noHistAvail {
  font-weight: 500;
  text-align: center;
  margin-top: 29px;
  color: #bbcada;
  font-size: 14px;
}

.unarchBtn {
  background-color: #0bf;
  border: 1px solid #0af;
}

.unarchBtn:hover {
  background-color: #0af !important;
}

.removeBtnsSize {
  padding-left: 4px !important;
  padding-right: 4px !important;
  width: 32% !important;
}

.removeBtnsSize2 {
  padding-left: 4px !important;
  padding-right: 4px !important;
  width: 32% !important;
  margin-left: -6%;
}

.smallEditUserTitle {
  font-size: 14px;
}

.editUserRow2 {
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 3%;
  border-bottom: 1px solid #ddebf4;
}

.editUserPicDiv {
  min-width: 90px;
  max-width: 90px;
}
.editUserRowScores {
  /* background-color: pink; */
  min-width: calc(100% - 90px);
}

.fontSize14 {
  font-size: 14px !important;
  line-height: 14px;
}

.editUserRow2Left {
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  min-width: fit-content;
}

.editUserRow2Right {
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.editUserRowTitle2 {
  font-size: 12px;
  color: #214978;
  font-weight: 400;
  /* margin-left: 4px; */
  margin-bottom: 2px;
  margin-left: 5px;
}

.x2389292111 {
  margin-top: 18px;
}
.editUserRowValue2 {
  font-weight: 500;
  color: #143051;
  min-height: 35px;
  padding-top: 5px;
  text-align: left;
  margin-left: 5px;
}

.saveEditUserBlock {
  display: flex;
  align-items: center;
  margin-right: 7px;
}

.toggleUserNameInputs {
  display: flex;
}

.editUserValInput {
  color: #6a8bb1;
  min-height: 35px;
  padding-left: 9px;
  font-weight: 500;
  /* margin-left: 5%; */
  min-width: 90%;
  /* margin-top: 5px; */
  /* margin-bottom: 3px; */
  font-size: 15px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 7px;
  background-color: #fdfeff;
}

.editUserValInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 7px;
  background-color: #fdfeff;
}

.editUserNameInputSize {
  min-width: 120px;
  max-width: 120px;
}

.editUserNameInputSize2 {
  min-width: 120px;
  max-width: 120px;
  margin-left: 8px;
}

.editUserValInput::placeholder {
  color: #bbcada;
}

.nameInputsUnder440 {
  display: flex;
  flex-direction: column;
}

.editUserRow2NameUnder440 {
  min-height: 130px;
}

.editUserNameInputSize2Under440 {
  margin-left: 0px;
  margin-top: 5px;
  min-width: 90%;
  max-width: 90%;
}

.editUserNameInputSizeUnder440 {
  min-width: 90%;
  max-width: 90%;
  margin-top: 5px;
}

.editJtSize {
  min-width: 240px;
  max-width: 240px;
}

.editJtSizeUnder440 {
  min-width: 184px;
  max-width: 184px;
}

.partOfTeamsDiv2 {
  margin-left: 0px;
  min-width: 310px;
  max-width: 310px;

  margin-top: 0px;
  margin-bottom: 10px;
  /* background: yellow; */
}

.editUserTeamUnitName {
  min-width: fit-content;
  white-space: nowrap !important;
  text-align: left;
}

.partOfPrim {
  font-size: 10px;
  min-width: 3px;
}

.partOfPrim2 {
  margin-left: 5px;
  margin-right: 3px;
  background: #516b8b;
  color: white;
  border-radius: 3px;
  padding: 3px;
}

.mgrOfInline {
  text-align: left;
  font-size: 12px;
  padding-right: 50px;
  margin-top: 12px;
  margin-bottom: 20px;
  font-weight: 500;
  color: #8ea7c5;
  max-width: 350px;
}

.editUserRow2MgrOf {
  font-size: 12px;
  color: #214978;
  border-left: 2px solid #214978;
  padding-left: 7px;
  margin-top: 10px;
  font-weight: 400;
}

.x0101011aa {
  /* white-space: nowrap !important; */
}

.mgrOfInlineMob {
  max-width: 300px;
}

.editUserRow2LeftUnder440 {
  max-width: 250px !important;
  min-width: 250px !important;
}

.fixedSchedInputTable {
  /* background-color: yellow; */
  min-width: 400px;
  max-width: 400px;

  margin-bottom: 50px;
}
.fixedSchedInputTableMobModal {
  min-width: 83vw;
  max-width: 83vw;
}
.fsTableTitleRow {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  margin-bottom: 4px !important;
  /* opacity: 0.7; */
  /* min-width: 300px; */
  /* background-color: orange; */
}

.fsTitleTxt {
  font-size: 12px;
  font-weight: 400;
  max-width: 20%;
  min-width: 20%;
  text-align: left;
  color: #143051;
  margin-left: 5px;
  /* min-width: 60px; */
}

.fsTitleTxt:first-child {
  min-width: 23%;
  max-width: 23%;
  margin-left: 0px;
}

.fsTitleTxtStart {
  margin-left: 4px;
}

.fsTitleTxtStartMobModal {
  min-width: 24%;
  max-width: 24%;
  margin-left: 0px;
}
.fsTitleTxtEnd {
  margin-left: 2px;
}

.fsTitleTxtEndMobModal {
  min-width: 24%;
  max-width: 24%;
}
.fsTitleTxtEndBreak {
  margin-left: 0px;
}

.fsTitleTxtEndBreakMobModal {
  min-width: 24%;
  max-width: 24%;
}

.fsTitleTxtDayMob {
  min-width: 58px !important;
  max-width: 58px !important;
}

.fsTableDataRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3px;
  border-top: 1px solid #e3e8ee;
  padding-top: 3px;
}

.fsTableRow1 {
  /* border-top:none */
}
.fsTableRow1 {
  /* border:none;
      padding-top:0px; */
}

.fsTableDayTxt {
  max-width: 23%;
  min-width: 23%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}

.fsTableDayTxtMob {
  min-width: 58px !important;
  max-width: 58px !important;
}

.fsTitleTxtEndWorkable {
  margin-left: -6px;
  visibility: hidden;
}
.fsTimeInput {
  color: #6a8bb1;
  min-height: 35px;
  font-weight: 500;
  min-width: 70px;
  display: flex;
  justify-content: center;
  max-width: 70px;
  font-size: 15px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  cursor: text;
  /* padding-left: 7px; */
  background-color: #fdfeff;
  /* -webkit-appearance: none; */
  /* appearance: none; */
}

.fsTitleTxtEndWorkableMobModal {
  min-width: calc(28% - 58px);
  max-width: calc(28% - 58px);
}

.fsTimeInputMobModal {
  min-width: 74px;
  padding-left: 2px;
}

.fsTableTimeInputWrap {
  /* background-color: blue; */
  /* margin-left:-2px; */
  max-width: 20%;
  min-width: 20%;
}

.fsTableTimeInputWrapMobModal {
  max-width: 24%;
  min-width: 24%;
}

.fsBrkInput {
  color: #6a8bb1;
  min-height: 35px;
  font-weight: 500;
  min-width: 62px;
  display: flex;
  max-width: 62px;
  font-size: 15px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  background-color: #fdfeff;
}

.fsDayPower {
  min-width: 17%;
  max-width: 17%;
  display: flex;
  justify-content: flex-start;
  /* background-color: black; */
}

.fsDayPowerMobModal {
  min-width: calc(28% - 58px);
  max-width: calc(28% - 58px);
}

.fsDayPowerImg {
  max-width: 20px;
  background-color: #fff;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding: 3px;
  min-width: 25px;
  min-height: 25px;
  cursor: pointer;
}

.unworkableFs {
  opacity: 0.4;
}

.unworkFsDay {
  opacity: 0.4;
}

.x23o3io311 {
  max-height: calc(100% - 141px);
  min-height: calc(100% - 141px);
}

.saveFsRow {
  min-height: 35px;
  display: flex;
  min-width: 100%;
  justify-content: space-between;
}

.saveFsBtn {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  background-color: #0bf;
  border: 1px solid #0af;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 15px;
  margin-right: 20px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}

.fsHoursQty {
  color: #8ea7c5;
  border-radius: 5px;
  background-color: #fff;
  /* margin-right: 5%; */
  font-weight: 500;
  text-align: center;
  padding-left: 8px;
  padding-right: 8px;
  min-height: 100%;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #e0ebf8;
  max-height: 35px;
  display: flex;
  margin-top: 15px;
  align-items: center;
}

.fsSavedTxt {
  color: #bbcada;
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-right: 33px;
  min-height: 35px;
  margin-top: 15px;
  font-size: 14px;
}

.primInlineTitle {
  font-size: 10px;
  text-align: left;
  color: #7790a5;
  margin-bottom: -2px;
}

.teamMgrColor {
  background-color: #eee9ef;
}

.x893298292 {
  /* margin-top: 25px;
  padding-bottom: 4px !important;
  margin-bottom: 30px; */

      /* margin-top: 25px; */
    /* padding-bottom: 4px !important; */
    /* margin-bottom: 30px; */
    padding-top: 0px;
    max-height: 60px;
}

.tMgrAdminSpan {
  color: white;
  background: #bbcada;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  font-size: 11px;
  margin-left: 4px;
  padding-bottom: 2px;
  padding-top: 2px;
}

.adjAllowInfo {
  text-align: right;
  color: #516b8b;
  font-size: 11px;
  max-width: 132px;
  float: right;
  font-weight: 400;
}
.adjAllowRow {
  display: flex;
  flex-direction: column;
  float: right;
  border: 1px solid #e0ebf8;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 15px;
  background: #fcfcfc;
  padding-left: 10px;
  border-radius: 5px;
}

.adjAllowInfo2 {
  margin-top: 8px;
}

.manAdjSpan {
  color: #214978;
  font-size: 13px;
  line-height: 22px;
  font-weight:500
}

.allHoursRecordTxt {
  color: #516b8b;
  font-size: 12px;
  line-height: 16px;
  border-left: 2px solid #143051;
  padding-left: 20px;
  padding-right: 30px;
  max-width: 80%;
  text-align: left;
  margin-left: 20px;
  margin-top: 20px;
}

.annLeavePercSpanSub {
  font-size: 11px;
  margin-left: 0px;
  margin-right: 12px;
  font-weight: 500;
  margin-top: 3px;
  color: #859dbb;
  border-radius: 5px;
}


.annLeaveMode1Sub {
  min-height:102px;
  border-bottom:1px solid #e3e8ee
}

.annLeaveMode1subHpw {
  font-size: 12px;
  color: #143051;
  font-weight: 500;

}

.x10109192221 {
  border-bottom:1px solid #e3e8ee !important
}

.annLeaveMode1SubRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left:23px;
  color:#bac5d3;
  font-weight: 500;
  font-size:12px;
  min-height:40px;
    border-bottom:1px solid #e3e8ee;
    cursor: pointer;

}

.annLeaveMode1SubRow2 {
  /* border-bottom:2px solid #bbcada */
  border:none
}

.annLeaveMode1SubWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
  font-size: 10px;
  /* color:#8a9db4; */
  color: #8ea7c5;
  font-weight: 400;
}

.subRowDeskto:hover {
  background-color: #edf3f9;
}

.subKnob {
  /* background-color: yellow !important; */
  /* max-width: 14px !important; */
  /* max-height: 14px !important; */
  /* margin-top:-4px !important; */

}

.subKnobOn {
  background-color: #89b6eb !important;
  /* top: 3px !important; */
  /* border-color: #2d619e !important */
}

  .subKnobOff {
    /* background-color: #00ccff !important; */
    /* top: 3px !important; */
    /* left: 3px; */
  }
.knobBgSub {
  /* max-height:14px !important; */
  /* max-width: 23px; */
}

.x2982911112f {
  margin-bottom: 10px;
  margin-top: 8px;}

  .editUserCustomScrollBox {
    display: block;
    max-width: 100%;
  }

  .customArrItem {
    border-bottom: 2px solid #ddebf4;
    /* border-radius: 5px; */
    /* padding-left: 10px; */
    margin-top: 18px;
    /* padding-right: 10px; */
    padding-top: 0px;
    max-width: 98%;
    margin-left: 1%;
  }

  .customArrItem:last-child {
    border-bottom:none
  }

  .customArrTitleRow {
    text-align: left;
    font-weight:500;
    font-size:14px;
  }

  .customItemArr {
    margin-bottom: 10px;
    color: #214978;
    margin-left: 7px;
    margin-top: 10px;
    font-size: 16px;
    /* border-bottom: 1px solid #ddebf4; */
    max-width: -moz-fit-content;
    max-width: fit-content;
    padding-left: 5px;
    padding-left: 8px;
    padding-right: 2px;
    border-left: 3px solid #214978;
  
  }


  .customDaysorHourSelect {
    color: #496375;
    border-radius: 0px;
    margin-top: 5px;
    font-size: 12px;
    font-weight: 500;
    padding-left: 10px;
    text-align: left;
    max-width: 69px;
    min-width: 69px;
    line-height: 28px;
    height: 30px;
    background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
    border-left: 1px solid #dde9f9;
    -webkit-appearance: none;
    background-position-x: 57px;
    cursor: pointer;
    margin-right: 3px;
  }

  .customAllowAmendRow {
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
    align-items: flex-end;
    padding-bottom: 5px;
    margin-top: 8px;
  }

  .customPlus {
    border-radius: 5px;
    max-width: 20px;
    min-width: 20px;
    min-height:20px;
    max-height:20px;
    padding: 5px;
    background: #214978;
    border: 1px solid #143051;
    cursor: pointer;
    margin-left:8px
    
  }


  .customPlusHrMinDiv {
    display: flex;
    align-items: center;
  }



  .customPlusHr {
    border-radius: 5px;
    max-width: 23px;
    min-width: 23px;
    min-height: 20px;
    max-height: 20px;
    padding: 13px;
    background: #214978;
    border: 1px solid #143051;
    cursor: pointer;
    margin-left: 8px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    
  }



  .customPlusHr2 {
    /* margin-left:  8px */
  }

  .customMinusHr2 {
    /* margin-right:  8px */
  }

  .customMinus {
    border-radius: 5px;
    max-width: 20px;
    min-width: 20px;
    min-height:20px;
    max-height:20px;
    padding: 5px;
    background: #6d84a2;
    border: 1px solid #143051;
    cursor: pointer;
    margin-right:8px;
    /* opacity: 0.7; */
    
  }



  .customMinusColour {
    background: #6d84a2;
    border: 1px solid #143051;
  }

  @media only screen and (min-width: 820px) {
.checkListShowMe:hover {
  opacity: 0.8;
}
  
    .allowanceAdditionsContainer:hover {
      background-color: #f0f4fa;
    }
  .customMinusColour:hover {
    background-color:#647994 ;

  }


  .customMinus:hover {
    background-color:#647994 ;
  }

  .customPlusHr:hover {
    background-color:#143051 ;
  }


  .customPlus:hover {
    background-color:#143051 ;
  }

}
  .customUnlimToggle {

    display: flex;
    align-items: center;
    min-height: 26px;
    color: #bbcada;
    margin-left: 0px;
    font-size: 12px;
    margin-top: -11px;
    margin-right: 7px;
    cursor: pointer;

  }

  .customUnlimCheckedTrue {
    margin-top: -3px;

  }

  .customUnlimToggle:hover {
    color:#7f96ae
  }

  .customUnlimCheck {
    max-width: 20px;
    min-width: 20px;
    min-height:20px;
    max-height:20px;
    background-color: white;
    border-radius:3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left:4px;
    border: 1px solid #ddebf4;
    margin-right:4px;
    margin-left:7px;

  }

  .customCheckUnlimImg {
    max-width: 17px;
    padding:2px;

  }

  .customAddPlusDiv {
    display: flex;
    margin-top: 2px;
    margin-bottom: 6px;
    margin-right: 11px;
  }
  .fadeAllowHpw {
    opacity: 0.4;
  }

  .allowsPholsRow {
    font-size: 12px;
    color: #516b8b;
    font-weight: 500;
    margin-right: 3px;
    margin-top: 0px;
    margin-bottom: 1px;
    border-top: 1px solid #ddebf4;
    padding-top: 8px;
    padding-right: 8px;
    padding-left: 10px;  }

    .pHolQtyAllows {
      color: #0077ff
    }

    .amendCustomHoursRow {
      display: flex;
      align-items: center;
      padding-right: 10px;
      margin-top: -2px;
      min-width: 100%;
      border-bottom: 1px solid #ddebf4;
      padding-bottom: 10px;
      justify-content: flex-end;
    }

    .tilSettingsRow {
      /* background-color: blue; */
      flex-direction: column;
    }

    .tilRowInline {
      display: flex;

    }

    .x198292ss {
      max-width: 60% !important;
      min-width: 60% !important;    }


.allowancesTilUserRow {
  display: flex !important;
  flex-direction: row !important;
  padding-right: 30px;
  border-bottom: 1px solid #ddebf4;
  padding-bottom:40px;
  margin-bottom:19px;
  margin-top:25px !important;

}

.x101081812d {
  margin-top:4px;
}

.editUserDataDateRow {
  min-height: 52px;
  /* background-color: #5c7ba9; */
  padding-top: 4px;
  min-width: 100%;
  /* padding-left: 15px; */
  /* padding-right: 15px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 1px solid #ddebf4; */
  padding-bottom: 10px;

}

.editUserDataDateLeft {
  display: flex;
  /* background-color: blue; */
}

.editUserDataDateBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.editUserDataDateBlock2 {
  margin-left:8px
}

.editUserDataDateTitle {
  font-size:10px;
  color:#214978;
  font-weight:500;
  margin-left:7px
}

.editUserDataDateInput {
  color: #6a8bb1;
  min-height: 30px;
  /* padding-left: 9px; */
  font-weight: 500;
  /* margin-left: 5%; */
  /* min-width: 100px; */
  /* margin-top: 5px; */
  /* margin-bottom: 3px; */
  font-size: 12px;
  border: 2px solid #e3e8ee;
  border-radius: 5px;
  padding-left: 5px;
  background-color: #fdfeff;
  margin-top:2px;
  cursor: text;
  max-width: 110px;
}

.editUserDataDrop {
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  padding-left: 8px;
  text-align: left !important;
  max-width: 85px;
  min-width: 85px;
  line-height: 26px;
  height: 30px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #214978;
  border: 2px solid #e3e8ee;
  -webkit-appearance: none;
  background-position-x: 64px;
  display: flex;
  cursor: pointer;
  color: #516b8b;
  background-color: white;
  margin-top:2px;
  /* border: 2px solid #c6d5e4; */
}

@media only screen and (min-width: 820px) {


.editUserDataDateInput:hover {
  border-color:#c5d1e1
}



.editUserDataDrop:hover {
  border-color:#c5d1e1
}

}
.allowancesTopDiv2 {
  /* background-color: ; */
}

.x99281 {
  border-top: 2px solid #ddebf4;
  border-radius:0px;
  background-color: #f4f8fe;
  overflow:hidden;

}

.editUserDataMain {
  background-color: #fff;
  border-top: solid 2px #eaf0f5;
  overflow-y: scroll;
  border-radius: 5px 5px 0px 0px;
  max-height: calc(100% - 151px);
  min-height: calc(100% - 151px);
  padding-bottom: 90px;

}



.editUserDataDateRowTop {
  display: flex;
  flex-direction: column;
  /* align-itemsspace- : flex-start; */
}

.editUserDataDateRowBottom {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  min-height:26px;
}

.dataTypeSelectDiv {
  display: flex;
  align-items: center;
}

.dataTypeTxt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  background: #214978;
  border: 1px solid #143051;
  border-radius: 4px;
  height: 20px;
  line-height: 20px;
  color: white;
  margin-right:4px;
  padding-left: 3px;
  padding-right: 3px;
  cursor: pointer;
}

@media only screen and (min-width: 820px) {
  .dataTypeTxt:hover  {
  background-color: #143051;
  }
}


.dataTypeCheckImg { 
  width: 10px;
  margin-left: 4px;
}

.unselectedDataType {
  opacity: 0.1;
}

.unselDataTypeTxt {
  background-color: #8ea7c5;
  border-color: #758da9;}


@media only screen and (min-width: 820px) {
  .unselDataTypeTxt:hover  {
    background-color: #758da9;
  }

  .dataTabUserShift:hover {
    background-color: #f6faff;
  }
}

.editUserDataBox {
  border: 1px solid #ddebf4;
  /* min-height: 70px; */
  margin-bottom: 7px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  background-color: #f9fbff;
}

.editUserDataBoxRow {
  border-bottom: 1px solid #ddebf4;
  font-size:11px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  padding-left:10px;
  padding-right:10px;
  min-height:19px;
  display: flex;
  align-items: center;
}

.editUserDataBoxRowTitle {
color:#143051
}

.editUserDataBoxRowValue {
margin-left:20px;
color:#516b8b
}

.dataTypeSelectUpper {
  /* background-color: blue; */
  padding-bottom:6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top:-8px; */
}

.dataTabUserShift {
  border-bottom: 1px solid #bbcada;
  width: 94%;
  margin-left: 3%;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
}

.dataTabUserShiftTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dataTabUserShiftBottom {
  min-height: 16px;
  padding-bottom: 4px;
  min-width: 100%;
  padding-left: 76px;
  display: flex;
  align-items: center;
  margin-top: -4px;
}
.dataTabUserShiftBottomReq {
  padding-left:82px;
}


.dataTabBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top:5px;
  padding-bottom:5px;
}

.dataTabBlockTitle {
  font-size:10px;
  font-weight: 400;
  color:#8ea7c5;
}

.dataTabBlockValue {
  color: #214978;
  font-weight: 600;
  color: #516b8b;
  font-size: 10px;
  display: flex;
  align-items: center;
  /* white-space: nowrap; */
  max-width: 62px;
  /* background: blue; */
  flex-wrap: wrap;
  text-align: left;
  line-height: 12px;
  margin-top: 3px;
}

.dataTabBlockValueTimes {
  font-weight: 600;
  color:#516b8b;
  font-size:10px;
  display: flex;
  align-items: center;
  text-align: left;
}

.dataTabUserShiftLeft {
  display: flex;
  align-items: center;
  min-width: 80%;
}

.dataTabUserShiftRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 20%;

}

.textAlignRight {
  text-align: right !important;
  display: flex;
  justify-content: flex-end;
}

.flexEnd2 {
  align-items: flex-end;
}

.dataTabClockIcon {
  width:9px;
  margin-right:4px;
  opacity: 0.75;

}

.dataTabTimesBlock {
  margin-left:20px;
  font-size:10px !important;
  min-width: 86px;

}

.dataTabBlockFirst {
  min-width: 62px;
}

.dataTabDurMins {
  margin-left:30px;
}

.dataTabBlockValueDurMins {
  font-size:10px
}


.tabRow {
  /* min-width: calc(100% - 62px); */
  display: flex;
  align-items: center;
}

.tabLate {
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  border-radius: 3px;
  background-color: #bb5d5d;
  padding-left: 3px;
  padding-right: 3px;
  margin-left:5px;
}

.sameDataPushDown {
  margin-top:15px
}


.tabDays {
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  border-radius: 3px;
  background-color: #516b8b;
  padding-left: 3px;
  padding-right: 3px;
  margin-bottom: 2px;
}

.tabDaysLeave {
  background-color: #68b7df;
}

.dataTabBlockValueTimesTimes {
color:#b0bfcb
}

.downloadCsvImg {
  max-width: 14px;
  margin-left: 5px;
}

.excessAllowRow {
  margin-top:40px;
  border-top:1px solid #ddebf4;
  padding-bottom:30px;
}

.x19834981 {
  margin-top:10px  !important;
}

.editUserOtPayReqsInfoTxt {
  color: #516b8b;
  text-align: left;
  font-size:12px;
  line-height:16px;
  padding-right:20px;
  margin-left:15px;
  margin-bottom:20px;
  margin-top:10px;
}

.x9191k {
  font-weight:500;
  margin-left:15px  !important
}


.askAdminToManageTeam {
  color: #bbcada;
  text-align: left;
  font-weight: 500;
  margin-left: 21px;
  max-width: 180px;
  font-size: 12px;
  margin-top: 15px;
  margin-bottom: 30px;
  border-left: 2px solid #bbcada;
  padding-left: 10px;
}

.askAdminToManageTeamViewTeam {
  color: #bbcada;
  text-align: left;
  font-weight: 500;
  margin-left: 1px;
  max-width: 180px;
  font-size: 12px;
  margin-top: 0px;
}

.nonAdminPteam {
  color: #214978;
  text-align: left;
  font-weight: 500;
  margin-left: 21px;
  max-width: 180px;
  font-size: 17px;
  margin-top: 0px;
  margin-bottom: 10px;
}

.dataTabBlockTeamName {
  color: #bbcada;
  font-size: 8px;
  text-align: left;
  max-width: 60px;
  font-weight: 500;
  margin-top: 2px;}


  .archivedEditUser {
    background-color:#f1f1f1
  }

  .removedProPic {
    filter: grayscale(100%);

  }

  .editUsrArchivedSubTxt {
    font-size: 12px;
    color: #bbcada;
    border-bottom: 1px solid #ddebf4;
    min-height: 50px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    line-height: 50px;
    background: #fafafa;
  }

  .removedUserPaddingLeftRight {
    padding-left:10px;
    padding-right:10px;
  }

  .greyScore {
    background-color: #bbcada;
  }

  .greyScore:hover {
    background-color: #bbcada !important;
  }

  .allowsDailyMinsVal {
    font-size:14px;
    color:#516b8b;
    margin-right:12px;
    margin-top:8px;
    font-weight: 400;
  }

  .contractedHPDrow {
    border-top:1px solid #ddebf4;
    border-bottom:none;
    margin-top:30px;
    min-height:60px
  }

  .avgBase {
    font-size: 9px;
    padding-left: 20px;
    border-right: 2px solid #d9eaff;
    padding-right: 6px;
    margin-top: 5px;
    margin-bottom: 4px;
  }


.colour00ccff {
  color: #00ccff !important
}

.pullUp36px {
  margin-top: -48px !important;
  min-height: 71px !important;}


  .convBtnMob {
    margin-top:20px;
  }

  .allLeaveDashControllerMobClosed {
    margin-top: 70px !important;  }


    .x88392727272 {
margin-top:80px !important    }



.editUserCheckListBtn {
  min-width: 102px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  background-color: #e78df7;
  border: 1px solid #9a7ba0;
  padding-left: 7px;
  padding-right: 6px;
  cursor: pointer;
  min-height: 30px;
  display: flex;
  align-items: center;
  color: #fff;
  max-height: 30px;
  margin-top: 8px;
  margin-right: 10px;
  margin-left: -60px;
}

.editUserCheckListBtnMob {
  min-width: fit-content
}
.checkListActive {
  background: none;
  color: #9a7ba0;
  border: 1px solid #f4f8fe;
  cursor: default;
}


@media only screen and (min-width: 820px) {
  .editUserCheckListBtn:hover {
    background-color: #d07ae0;

  }

  .checkListActive:hover {
    background: none;

  }
}
.todoCheckImg {
  max-width: 14px;
  margin-right: 8px;}


  .userCheckListModal {
    background-color: #f4f8fe;
    min-width: 290px;
    position: fixed;
    right: calc(50vw - 400px);
    border-radius: 10px;
    min-height: fit-content;
    cursor: default;
    padding-bottom: 15px;
  }



  .userCheckListModalMob {
    right: auto;

  }

  .userCheckListModalLarge {
    right: calc(50vw - 450px);

  }
  .userCheckListHeader {
    display: flex;
    align-items: center;
    min-width: 100%;
    justify-content: space-between;
    min-height: 50px;
    padding-left: 16px;
    padding-right: 15px;
  }

  .closeCheckListImg {
    max-width: 14px;
    cursor: pointer;
  }

  .userCheckListTitle {
    color:#143051;
    font-size: 16px;
    font-weight: 500;
  }

  .completeAllTick {
    max-width: 14px;
    margin-left:8px;
  }

  .completeCheckBtnAll {
    max-width: 90%;
    min-width: 90%;
    margin-left:5%;
    background-color: #0bf;
    border: 1px solid #0af;
  }

  .completeCheckBtnAll:hover {
    background-color: #0af;
  }

  .editUserModalWithCheckList {
    margin-left:-300px;
  }

  .checkListItem {
    border: 1px solid #dde9f9;
    background: white;
    max-width: 90%;
    margin-left: 5%;
    border-radius: 5px;
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom:6px;
    cursor: pointer;
  }

  .checkListItemNum {
    font-size: 14px;
    font-weight: 500;
    color: #0af;
    margin-top: -15px;
   }

  .checkListTxtAndShowMe {
    display: flex;
    flex-direction: column;
    /* background: blue; */
    min-width: 170px;
    align-items: flex-start;
  }

  .checkListItemTitle {
    font-size: 14px;
    font-weight: 500;
    color: #214978; 
  }

  .checkListShowMe {
    font-size: 14px;
    font-weight: 500;
    margin-top: 1px;
    color: #859dbb;
  cursor: pointer;
  }

  .checkListDoItem{
    margin-right: 2px;
    border: 1px solid #dde9f9;
    min-width: 35px;
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    }

  .checkInfoTxt {
    color: #859dbb;
    font-size: 13px;
    max-width: 250px;
    text-align: left;
    margin-left: 21px;
    margin-bottom: 16px;
    font-weight: 400;
    margin-top: 3px; }

    .completedCheckListTickImg {
      max-width: 14px;
      cursor: default;
    }

    .strikeThrough {
      text-decoration: line-through;
cursor: default;
    }



    .userCheckListModalUnderlay {
      background:none ;
      display: flex;
      align-items: center;
    }

    .userCheckListModalUnderlayMob {
      background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
  cursor: pointer;
  z-index: 99999999;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
    }


    .showMeRow {
      border: 3px solid #00aaff !important; 
      border-radius: 7px;
      /* margin-top: -5px; */
      background: white;
      padding-left: calc(5% - 3px);
      padding-right: calc(5% - 3px);
      margin-bottom: 5px;
      padding-top: 17px;  }

      .showMeRowFv {
        border-radius: 7px 7px 0 0;
        border-bottom:none !important
      }

      .fvContainer {
/* background-color: blue; */
      }

      .fvContainerShowMe {
        border: 3px solid #00aaff;
        border-radius: 0 0 7px 7px;
        border-top: none;
        margin-top: -5px;
      }

      .editUserAllowsBox {
        padding-bottom: 30px;
        margin-bottom: -29px;      }