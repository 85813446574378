@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600&display=swap");

/* Remove up/down arrows in number box */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

* {
  font-family: "timelify", "Windlesham Pro", "Work Sans", sans-serif;
  /* font-style: "Regular 6"; */
  margin: 0;
  padding: 0;
  overflow: auto;
}

.lifeEasier {
  font-size: 0.8em;
  margin-top: 25px;
  color: #143051;
}

.signUpBackground {
  background: linear-gradient(to right, #e6f1ff, #fdfeff);
  /* min-height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100vw;
  min-height:100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.hideSignIn {
  display: none;
}

.signUpContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 350px;
  min-width: 350px;
  border: 1px solid #dde9f9;
  background: white;
  border-radius: 10px;
  min-height: 450px;
  max-height: 450px;
  /* padding-bottom: 10px; */
}

.signUpLogo {
  max-width: 40px;
  margin-top: 12px;
}

.signUpForm {
  padding: 6% 0px 0% 0px;
  /* margin-top: 16px; */
  color: #143051;
}

/* signup button */
.signinBtn {
  background-color: #00ccff;
  color: white;
  width: 100px;
  height: 35px;
  line-height: 37px;
  margin: auto;
  border-radius: 100px;
  margin-top: -10px;
  cursor: pointer;
  font-weight: 500;
}

/* signin button */
.signinBtn2 {
  background-color: #07f;
  border: #0469de 1px solid;
  color: white;
  width: 89px;
  height: 40px;
  display: flex;
  margin: auto;
  border-radius: 5px;
  margin-top: -35px;
  cursor: pointer;
  align-items: center;
  font-weight: 600;
  justify-content: center;
  margin-left: 20px;
}

.signinBtn2:hover {
  background-color: #0c72e7;
}

.hide {
  opacity: 0.2;
  cursor: none;
  /* pointer-events: none; */
  background-color: #143051;
}

.signinInput {
  width: 90%;
  text-align: center;
  font-size: 16px;
  /* font-family: work sans, sans-serif; */
  color: #143051;
  background-color: #f8fbff;
  border: none;
  border: 1px solid #8ea7c5;
  border-radius: 5px;
  /* margin-top: -10px; */
  min-height: 45px;
  /* font-size: 1.2em; */
  font-weight: 600;
  box-shadow: 0px 2px 4px rgb(221, 228, 231);
  vertical-align: center;
}

#password {
  width: 90%;
  text-align: center;
  font-size: 20px;
  /* font-family: work sans, sans-serif; */
  color: #143051;
  background-color: #f8fbff;
  border: none;
  border: 1px solid #8ea7c5;
  border-radius: 5px;
  /* margin-top: 12px; */
  min-height: 45px;
  /* font-size: 1.2em; */
  font-weight: 600;
  box-shadow: 0px 2px 4px rgb(221, 228, 231);
  vertical-align: center;
}

.xx {
  display: none;
}

input[type="password"]::placeholder,
.signinInput::placeholder {
  font-size: 16px;
  color: #e0ebf8;
  text-align: left;
  text-indent: 10px;

  /* vertical-align: center; */
}
/* 
input:focus {
  width: 80%;
  animation-name: widen;
  animation-duration: 0.1s;
}

@keyframes widen {
  from {
    width: 70%;
  }
  to {
    width: 80%;
  }
} */

input {
  margin-bottom: 0px;
  height: 25px;
  outline: none;
}

.signinFooterContainer {
  position: absolute;
  top: 0;
  display: flex;
  height: 50px;
  min-width: 100vw;
}

.signinFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5%;
      padding-right: 5%;
      min-height:40px;
}

.linkContainer {
  max-width: 90vw;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-family: work sans, sans-serif;
  font-size: 0.85em;
  padding-bottom: 10px;
  color: #143051;
  position: fixed;
  top: 15px;
  left: 0px;
  min-width: 100vw;
  z-index: 1;
}

.backBtnSignUp {
  margin-left: 20px;
  width: 15px;
}

#signuplink {
  background-color: #143051;
  border-radius: 15px;

  height: 25px;
  font-size: 0.9em;
  max-width: 80px;
  line-height: 25px;
  padding: 0 15px 0 15px;
  color: white;
  margin-right: 20px;
}

.page2bg {
  background-color: #143051;
  min-height: 100vh;
  min-width: 100vw;
  position: absolute;
  top: 0;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  z-index: 2;
}

.formFName {
  color: white;
  margin-top: 40px;
  font-weight: bold;
  text-align: left;
  text-indent: 7%;
}

.forgotContainer {
  pointer-events: none;
  display: flex;
  z-index: -100;
  max-width: 90%;
  /* background-color: yellow; */
  justify-content: flex-end;
  margin: auto;
}

.forgotPass {
  margin-top: 18px;
  font-size: 12px;
  font-weight: 500;
  /* width: 60px; */
  text-align: right;
  pointer-events: auto;
}

.firstNameText,
.lastNameText {
  color: white;
  margin-top: 20px;
  padding-bottom: 15px;
  font-weight: 500;
  font-size: 1.2em;

  text-align: left;
  text-indent: 7%;
}

.formCompanyName {
  color: white;
  margin-top: 55px;
  padding-bottom: 15px;
  text-align: left;
  text-indent: 7%;
  font-weight: 500;
  font-size: 1.2em;
}

.formYrStartDate {
  color: white;
  margin-top: 50px;
  text-align: left;
  margin-left: 7%;
  font-weight: 500;
  font-size: 1.2em;
}

input#fName,
input#lName,
input#companyName,
input#formStartDate,
input#teamName {
  width: 90%;
  text-align: left;
  font-size: 0.9em;
  font-family: work sans, sans-serif;
  color: #143051;
  background-color: #f8fbff;
  border: none;
  border: 1px solid #8ea7c5;
  border-radius: 5px;
  margin-top: -10px;
  min-height: 45px;
  font-size: 1.2em;
  font-weight: 400;
  /* box-shadow: 0px 2px 4px rgb(221, 228, 231); */
  vertical-align: center;
  text-indent: 10px;
}

input#fName::placeholder,
input#lName::placeholder,
input#companyName::placeholder,
input#formStartDate::placeholder,
input#teamName::placeholder {
  font-size: 16px;
  color: #5d6f85;
  text-align: left;
  text-indent: 10px;
}

.signUpEmailInput {
  font-size: 1em;
  text-align: left;
  text-indent: 10px;
}

input#password {
  font-size: 1em;
  text-align: left;
  text-indent: 10px;
}

.showPassword {
  /* margin-top: 10px; */
  /* position: fixed; */
  /* left: 7%; */
  max-width: 25px;
  display: flex;
  margin-top: -35px;
  margin-left: 83%;
  fill: yellow;
}

input#daySelect {
  max-width: 45px;
  border-radius: 1px;
  margin-top: 30px;
  text-align: center;
  padding-top: 10px;
  min-height: 35px;
  padding-bottom: 10px;
  font-size: 1.2em;
  border: none;
  border-bottom: 1px solid white;
  background-color: #143051;
  color: white;
}

input#daySelect::placeholder {
  color: white;
}

select#monthSelect {
  border-radius: 15px;
  background-color: #214978;
  min-height: 35px;
  font-size: 1em;
  padding-left: 15px;
  color: white;
  width: 135px;
  font-size: 16px;
  line-height: 1;
  border: 0;
  border-radius: 15px;
  height: 30px;
  background: url("../img/general/downArrowWhite.svg") no-repeat right #214978;
  -webkit-appearance: none;
  background-position-x: 108px;
}

select#daySelectDropdown {
  border-radius: 15px;
  background-color: #214978;
  min-height: 35px;
  font-size: 1em;
  padding-left: 15px;
  color: white;
  width: 75px;
  font-size: 16px;
  line-height: 1;
  border: 0;
  border-radius: 15px;
  height: 30px;
  background: url("../img/general/downArrowWhite.svg") no-repeat right #214978;
  -webkit-appearance: none;
  background-position-x: 52px;
}

#monthLabel {
  margin-left: 20px;
  padding-right: 30px;
}

#startDayLabel {
  padding-right: 30px;
  margin-left: -19px;
}

.startendContainer {
  margin-top: -17px;
  margin-left: -65px;
}

select {
  width: 135px;
  font-size: 16px;
  line-height: 1;
  border: 0;
  border-radius: 5px;
  height: 30px;
  background: url("../img/general/downArrowWhite.svg") no-repeat right #143051;
  -webkit-appearance: none;
  background-position-x: 108px;
}

.page2Text,
.page3Text {
  color: white;
  font-size: 1.2em;
  margin-top: 15px;
}

.page3bg,
.page4bg,
.page5bg,
.page6bg,
.page7bg,
.page8bg {
  background-color: #143051;
  color: white;
  min-height: 100vh;
  min-width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  z-index: 2;
}

.dayDoesNotExist {
  margin-top: 44px;
  font-weight: 300;
  font-style: italic;
}

.hidePage {
  display: none;
}

.nextBtn {
  background-color: #0077ff;
  color: white;
  width: 100px;
  height: 35px;
  line-height: 37px;
  margin: auto;
  border-radius: 100px;
  /* position: absolute;
  top: 218px;
  left: 50%; */
  /* transform: translate(-50%, -50%); */

  /* left: 50%; */
  margin: auto;
  margin-top: 30px;
  cursor: pointer;
  font-weight: 500;
}

.goodToGo {
  margin-top: 100px;
}

.goodToGo2 {
  margin-top: 12px;
}

.nextBtnTeams {
  background-color: #0077ff;
  color: white;
  width: 100px;
  height: 35px;
  line-height: 37px;
  margin: auto;
  border-radius: 100px;
  /* position: absolute;
  top: 218px;
  left: 50%; */
  /* transform: translate(-50%, -50%); */

  /* left: 50%; */
  margin: auto;
  margin-top: 20px;
  cursor: pointer;
  font-weight: 500;
}

.nextBtnInactive {
  background-color: #8b8b8b;
  color: white;
  width: 100px;
  height: 35px;
  line-height: 35px;
  margin: auto;
  border-radius: 100px;
  margin-top: 60px;
  pointer-events: none;
}

.visZero {
  visibility: hidden;
}

/* AUTOFILL STYLES */
/* autofill for email and password */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-text-fill-color: #143051;

  transition: background-color 5000s ease-in-out 0s;
}

input#fName:-webkit-autofill,
input#fName:-webkit-autofill:hover,
input#fName:-webkit-autofill:focus,
textarea#fName:-webkit-autofill,
textarea#fName:-webkit-autofill:hover,
textarea#fName:-webkit-autofill:focus,
select#fName:-webkit-autofill,
select#fName:-webkit-autofill:hover,
select#fName:-webkit-autofill:focus,
input#lName:-webkit-autofill,
input#lName:-webkit-autofill:hover,
input#lName:-webkit-autofill:focus,
textarea#lName:-webkit-autofill,
textarea#lName:-webkit-autofill:hover,
textarea#lName:-webkit-autofill:focus,
select#lName:-webkit-autofill,
select#lName:-webkit-autofill:hover,
select#lName:-webkit-autofill:focus,
input#companyName:-webkit-autofill,
input#companyName:-webkit-autofill:hover,
input#companyName:-webkit-autofill:focus,
textarea#companyName:-webkit-autofill,
textarea#companyName:-webkit-autofill:hover,
textarea#companyName:-webkit-autofill:focus,
select#companyName:-webkit-autofill,
select#companyName:-webkit-autofill:hover,
select#companyName:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #143051 inset;
  -webkit-text-fill-color: white;
  transition: background-color 5000s ease-in-out 0s;
}

.signUpTeamsContainer {
  display: block;
  margin-top: 20px;
}

.signUpTeamsCollection {
  display: flex;
  justify-content: center;
  overflow: auto;
}

.signUpTeamUnit {
  /* background-color: ; */
  margin-top: 10px;
  display: flex;
}

.signUpRemoveTeamImg {
  margin-left: 22px;
  margin-top: 1px;
  cursor: pointer;
}

input#signUpTeamName {
  max-width: 60%;
  min-width: 200px;
  border-radius: 1px;
  margin-top: 30px;
  text-align: center;
  padding-top: 10px;
  min-height: 35px;
  padding-bottom: 15px;
  font-size: 1.2em;
  border: none;
  border-bottom: 1px solid white;
  background-color: #143051;
  color: white;
}

.listTeamNameText {
  font-size: 1.2em;
}

.addTeamBtnSignup {
  cursor: pointer;
  margin-left: 10px;
  width: 25px;
}

.chapterParent {
  width: 100vw;
  display: block;
}

.chapterContainer {
  min-height: 8px;

  width: 100%;
  /* margin-top: 60px; */
  display: flex;
  position: absolute;
  top: 15px;
  right: 0px;
  justify-content: center;
  z-index: 999;
}

.chap {
  width: 6px;
  height: 6px;
  background-color: white;
  opacity: 0.7;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 100%;
  margin-top: 1px;
}

.chapHighlighted {
  background-color: white;
  width: 8px;
  height: 8px;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 100%;
}

.addMoreLater {
  font-size: 1em;
  font-weight: 300;
  /* font-style: italic; */
  margin-top: 6px;
  text-align: left;
  text-indent: 7%;
}

/* .downloadBar {
  min-height: 55px;
  min-width: 100vw;
  background-color: #e0ebf8;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.downloadBarContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #143051;
}

#mobIcon {
  max-width: 17px;
}

.downloadBarText {
  font-size: 0.8em;
  margin-left: 10px;
}
/* 
#logotext {
  max-width: 45px;
  margin-left: 3px;
  margin-top: 40px;
} */

.alternativeIn {
  background-color: #143051;
  max-width: 86px;
  min-height: 30px;
  min-width: 70px;
  line-height: 30px;
  color: white;
  border-radius: 35px;
  font-size: 1em;
  margin: auto;
  margin-top: 10px;
  font-weight: 500;
}

.signupDivider {
  min-height: 1px;
  max-width: 90%;
  min-width: 90%;
  margin: auto;
  background-color: #dadada;
  margin-top: 180px;
}

.signinDivider {
  min-height: 1px;
  max-width: 90%;
  min-width: 90%;
  margin: auto;
  background-color: #e0ebf8;
  /* margin-top: 146px; */
}

.emailExists {
  padding-bottom: 20px;
  font-size: 0.9em;
  color: #ba0000;
  font-weight: 300;
  text-align: left;
  text-indent: 7%;
}

.passwordNotLongEnough {
  padding-bottom: 30px;
  font-size: 0.9em;
  color: #ba0000;
  font-weight: 300;
}

.wrongSignInEmail {
  padding-bottom: 22px;
  font-size: 0.8em;
  text-align: left;
  text-indent: 7%;
  color: #ba0000;
}

.wrongSignInPass {
  font-size: 0.8em;
  text-align: left;
  text-indent: 7%;
  color: #ba0000;
  margin-top: 10px;
}

.whenDidYouStartOptionsContainer {
  /* background-color: orange; */
  margin: 0 auto;
  margin-top: 30px;
  display: flex;
  max-width: 90%;

  justify-content: space-around;
  align-items: center;
}

.whenDidYouStartButton {
  max-width: 130px;
  text-align: center;
  background-color: #5d6f85;
  min-width: 130px;
  font-size: 1.1em;
  padding: 10px;
  border-radius: 15px;
  opacity: 0.8;
}

.whenDidYouStartSelected {
  border: solid 2px white;
  opacity: 1;
}

.startDateValSpan {
  font-weight: 500;
  font-size: 0.9em;
}

.perWeekSpan {
  background-color: #587597;
  padding: 3px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  white-space: nowrap;

  /* line-height: 28px; */
}
.startDateSpanNoBreak {
  white-space: nowrap;
  font-weight: 600;
  color: #00ccff;
}

.initUserMpwHistoryInput {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.mpwInputForNewUserHistory {
  width: 70px;
  margin-top: 10px;
  text-align: center;
  font-size: 0.9em;
  /* font-family: work sans, sans-serif; */
  color: #143051;
  background-color: #f8fbff;
  border: none;
  border: 1px solid #8ea7c5;
  border-radius: 5px;
  /* margin-top: -10px; */
  min-height: 45px;
  font-size: 1.2em;
  font-weight: 400;
  /* box-shadow: 0px 2px 4px rgb(221 228 231); */
  /* vertical-align: center; */
  /* text-indent: 10px; */
}

.initUserHoursMinsContainer {
  display: flex;
  align-items: center;
  min-width: 150px;
}

.hoursMinsLabelOnInitCreate {
  margin-left: 12px;
  color: #dadada;
  padding-top: 28px;
  font-size: 1.1em;
  /* background-color: blue; */
  min-width: 60px;
  text-align: left;
}

.manualDayInput {
  /* background-color: #31547c; */
  /* background-color: #8ea7c5; */
  border-radius: 15px;
  border: solid 1px #ffffff;
  padding-left: 12px;
  color: #31547c;
  /* color: white; */
  margin-top: 26px;
  margin-bottom: 10px;
  font-weight: 500;
  padding-top: 2px;
  background: url("../img/general/downArrowNavy.svg") no-repeat right #ffffff;
  -webkit-appearance: none;
  background-position-x: 108px;

  /* padding-bottom: 2px; */
  /* line-height: 28px; */
}

.signInTitle {
  font-weight: 600;
  color: #143051;
  font-size: 22px;
  text-align: left;
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 10px;
}

.signInInputTitle {
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  margin-left: 28px;
  margin-bottom: 6px;}


  .x20483085 {
    margin-top:20px;
  }

  .signInLogoImg {
    max-width: 51px;
    margin-left: 52px;  }

    .signInPrivacy {
      font-weight: 500;
      font-size: 12px;
      
      color: #bbcada !important;
      padding-bottom: 4px;
      cursor: pointer;
    }

    .swapRed {
      color:#ba0000
    }

    .swapGreen {
      color:#82c26a
    }