.towerContainer {
  position: fixed;
  top:0;
  left:0;
  background-color: #f5f5f5;
  min-height: 100vh;
  max-height: 100vh;
  min-width: 100vw;
}

.none {
  display: none;
}

.jamDivider {
  min-width: 2px;
  background-color: white;
  opacity: 0.8;
  min-height: 100%;
  max-height: 60px;
  margin-top: 8px;
  margin-left: 4px;
  margin-right: 4px;
}

.noPermissionsForTower {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.towerHeaderContainer {
  min-height: 100px;
  background-color: #1a293a;
  color: white;
  display: flex;
  align-items: center;
  font-weight: 500;
  justify-content: space-around;
  border-bottom: 4px #493d62 solid;
  padding-top: 2px;
}

.towerLogoImg {
  max-width: 160px;
}

.towerHeaderSide {
  min-width: 30%;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.towerHelloTxt {
  font-weight: 400;
  font-size: 0.9em;
  opacity: 0.7;
}
.towerSignoutBtn {
  margin-left: 40px;
  background-color: #73a9d0;
  min-height: 30px;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px;
  cursor: pointer;
  border: 4px #4e6580 solid;
}

.towerBody {
  display: flex;
  min-width: 100%;
  min-height: 100vh;
  max-height: 100vh;
}

.towerSidebar {
  background-color: #143051;
  min-width: 140px;
  max-width: 140px;

  min-height: 100%;
}

.towerMenuContainer {
  /* background-color: orange; */
  min-height: 250px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-around;
  text-align: left;
  /* padding-left: 10px; */
}

.towerMenuRow {
  /* background-color: red; */
  /* margin: 0 auto; */
  min-height: 35px;
  align-items: center;
  display: flex;
  max-width: 100%;
  padding-right: 20px;
  cursor: pointer;
  padding-left: 10px;

  /* display: flex; */
  /* justify-content: space-between; */
}

.towerMenuRow:hover {
  background-color: #102743;
}

.towerMenuTxt {
  margin-left: 14px;
  color: #73a9d0;
  font-weight: 500;
  font-size: 0.9em;
  padding-top: 1px;
}
.whiteColTowerMenu {
  color: white;
  animation-name: zoomBtnIn !important;
  animation-duration: 0.1s !important;
}

.towerSelctedMenuAccent {
  background-color: #9ac8ea;
  min-width: 4px;
  max-width: 4px;
  min-height: 20px;
  border-radius: 10px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.towerInvisAccent {
  visibility: hidden;
}

.towerContent {
  min-width: calc(100% - 140px);
  /* background-color: yellow; */
  /* margin-top: 20px; */
  /* min-height: 300px; */
  max-height: 100vh;
}

.towerBodyInnerContainer {
  /* background-color: orange; */
  min-height: 100%;
  max-height: 100vh;
  min-width: 100%;
}

.towerNotePadHeader {
  background-color: #9ac8ea;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 80px;
  padding-right: 80px;
  border-bottom: 4px #95a7b8 solid;
}

.towerNoteHeaderDataContainer {
  /* background-color: yellow; */
  display: flex;
  justify-content: space-between;
  min-width: 200px;
}

.towerAddNoteBtn {
  background-color: #0077ff;
  color: white;
  border: 2px solid #143051;
  border-radius: 20px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 500;
  cursor: pointer;
  font-size: 0.9em;
  min-width: 88px;
  text-align: center;
}

.cancelTowerAddNote {
  background-color: #95a7b8;
}

.towerViewDoneBtn {
  background-color: #58467e;
  color: white;
  border: 2px solid #143051;
  border-radius: 20px;
  min-height: 30px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 500;
  margin-left: 10px;
  cursor: pointer;
  font-size: 0.9em;
}

.showAddNoteContainer {
  min-height: 400px;
  background-color: #1a293a;
  min-width: 100%;
  border-bottom: solid 4px #58467e;
  border-left: solid 4px #58467e;
}

.viewDoneNotesNumberSpan {
  padding-left: 10px;
  padding-right: 6px;
  color: #00ccff;
}

.towerNotesContainer {
  /* background-color: blue; */
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
  /* max-height: 100vh; */
  max-height: calc(100vh - 160px);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: scroll;
  padding-bottom: 50px;
}

.towerNoteUnit {
  background-color: white;
  border: 1px solid rgba(89, 89, 89, 0.267);
  border-bottom: 3px solid rgba(89, 89, 89, 0.267);
  max-width: 300px;
  min-width: 300px;
  max-height: 360px;
  /* min-height: 370px; */
  margin-top: 30px;
  margin-left: 30px;
  border-radius: 15px;
  padding-bottom: 30px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.todoNoteBorder {
  border: 1px solid rgba(78, 128, 255, 0.867);
  border-bottom: 3px solid rgba(78, 128, 255, 0.867);
}

.towetNoteContentTxt {
  /* border-bottom: 1px solid #143051; */
  max-width: 90%;
  margin: 5% auto;
  border-radius: 5px;
  color: #4e6580;
  font-size: 0.9em;
  min-height: fit-content;
  cursor: pointer;
  overflow: scroll;
  max-height: 140px;
  background-color: #f5f5f5;
  padding: 10px;
}

.towerNoteUnitSplitter {
  min-height: 1px;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
  background-color: #d2d5e9;
  margin-bottom: 4px;
}

.towerNoteUnitFooter {
  min-height: 30px;
  /* max-height: 30px; */
  /* background-color: yellow; */
  min-width: 100%;
  margin-bottom: -5%;
  display: flex;
}

.towerNoteUnitHeader {
  display: flex;
  /* background-color: orange; */
  min-width: 100%;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 7px;
}

.towerNoteTs {
  font-size: 0.9em;
  font-weight: 500;
  margin-top: 10px;
  margin-left: 14px;
  color: #4e6580;
  /* opacity: 0.4; */
}

.closeTowerNoteUnitImg {
  max-width: 14px;
  margin-right: 14px;
  margin-top: 10px;
  cursor: pointer;
}

.towerNoteFooterLeft {
  /* background-color: lime; */
  min-width: 50%;
  max-width: 50%;
  font-size: 0.7em;
  text-align: left;
  padding-left: 5%;
  display: flex;
  flex-direction: column;
  min-height: 90px;
  max-height: 90px;
  justify-content: flex-start;
  color: #4e6580;
  padding-top: 10px;
}

.towerNoteFooterRight {
  /* background-color: lime; */
  min-width: 50%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 5%;
  padding-top: 8px;
}

.addTowerUserToNoteBtn {
  background-color: #00ccff;
  color: #143051;
  border: 2px solid #143051;
  border-radius: 20px;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 3px;
  padding-right: 3px;
  font-weight: 500;
  cursor: pointer;
  font-size: 0.7em;
  min-width: 66px;
  max-width: 66px;
  text-align: center;
  margin-right: 6px;
}

.addTowerUserToNoteUnderlay {
  background: rgba(0, 0, 0, 0.05);
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.addTowerUserToNoteModalBox {
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  /* min-height: 500px; */
  min-width: 400px;
  max-width: 80%;
  margin: 10% auto;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  /* max-height: 500px; */
}

.addUserToNoteTowerTitle {
  font-weight: 500;
  color: #143051;
}
.towerUsersToAddGenerateBox {
  /* background-color: lime; */
  padding-top: 40px;
  overflow: scroll;
  max-height: 300px;
}
.towerUserToAdd {
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0077ff;
  border-bottom: 1px #4e6580 solid;
  max-width: 80%;
  margin: 0 auto;
}
.towerUserToAdd:last-child {
  border-bottom: none;
}
.listOfTowerUsersAddedToNoteContainer {
  margin-top: 8px;
  /* background-color: yellow; */
  overflow: scroll;
  max-height: 90px;
  border-top: 1px solid #4e6580;
}

.towerUserAddedToNoteItem {
  display: flex;
  /* background-color: red; */
  min-width: 130px;
  justify-content: space-between;
  padding-right: 10px;
  margin-top: 6px;
  border-bottom: #73a9d0 1px solid;
  padding-bottom: 6px;
  padding-top: 3px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.towerUserAddedToNoteItem:last-child {
  border-bottom: none;
}

.towerUserAddedToNoteItemTxt {
  font-size: 0.8em;
  color: #2e4657;
}

.removeTowerUserFromNoteImg {
  max-width: 12px;
  cursor: pointer;
}

.none {
  display: none;
}

.towerNoteFooterClient {
  margin-top: 8px;
}

.towerNoteFooterClient:hover {
  font-weight: 500;
  cursor: pointer;
  color: #0077ff;
}

.towerNoteInput {
  resize: none;
  overflow: scroll;
  border: none;
  background-color: #f5f5f5;
  border: 1px solid #dfdfdf;
  /* margin-top: 3px; */
  border-radius: 15px;
  padding: 8px;
  min-width: 90%;
  min-height: 140px;
  max-height: 140px;
  /* margin-top: 18px; */
}

.saveNoteContentBtn {
  background-color: #0077ff;
  color: white;
  border: 2px solid #143051;
  border-radius: 20px;
  min-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 500;
  cursor: pointer;
  font-size: 1.1em;
  /* margin-bottom: 10px; */
  min-width: 68px;
  text-align: center;
  max-width: 80px;
  margin-top: -4px;
}

.saveBtnContentNoteDivider {
  min-height: 1px;
  background-color: #e6e6e6;
  min-width: 100%;
  margin-top: 8px;
}

.yourNoteSpan {
  color: #143051;
  margin-right: 8px;
}

.noteTsMadeTimeSpan {
  font-size: 0.9em;
  opacity: 0.6;
  padding-left: 8px;
}

.numberOfNotesTxt {
  background-color: #58467e;
  color: white;
  border: 2px solid #143051;
  border-radius: 20px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 500;
  cursor: pointer;
  font-size: 0.9em;
  min-width: 88px;
  text-align: center;
  display: flex;
}

.numberOfNotesTxtLeft {
  /* background-color: #95a7b8; */
  color: #143051;
  /* border: 2px solid #143051; */
  border-radius: 20px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 500;
  cursor: pointer;
  font-size: 0.9em;
  min-width: 88px;
  text-align: center;
  display: flex;
}

.selectedNumberOfTodoNotes {
  background-color: #143051;
}

.whitecrossFilterToDoImg {
  max-width: 10px;
  margin-right: 8px;
}

.towerNoteTodoContainer {
  display: flex;
  /* background-color: pink; */
  /* padding-bottom: 12px; */
  /* margin-top: 10px; */
  max-width: 73px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.towerTodoTxt {
  font-weight: 500;
  margin-top: 14px;
  color: #143051;
  font-size: 1.2em;
}

.disableRemoveUserFromNote {
  pointer-events: none;
  opacity: 0;
}

.towerClientsParent {
  min-width: 100%;
  background-color: yellow;
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
  overflow: scroll;
}

.towerClientClientsBar {
  background-color: #9ac8ea;
  min-width: 240px;
  border-right: 4px #95a7b8 solid;
  overflow: scroll;
  max-height: calc(100vh - 100px);
}

.towerClientUsersSection {
  background-color: #f5f5f5;
  min-width: calc(100% - 240px);
}

.clientUnit {
  min-width: 220px;
  max-width: 220px;
  min-height: 100px;
  background-color: white;
  border: 2px #143051 solid;
  color: #143051;
  border-radius: 15px;
  margin: 10px auto;
  cursor: pointer;
  font-weight: 500;
  padding: 10px;
  text-align: left;
}

.selectedClient {
  background-color: #143051;
  color: white;
}

.clientID {
  cursor: text;
  -webkit-user-select: all; /* Chrome 49+ */
  -moz-user-select: all; /* Firefox 43+ */
  -ms-user-select: all; /* No support yet */
  user-select: all; /* Likely future */
  font-size: 0.8em;
  margin-top: 3px;
  /* opacity: 0.5; */
  max-width: fit-content;
}

.clientUsersQty {
  font-size: 0.9em;
  margin-top: 8px;
  color: #00ccff;
}

.clientArchivedUsersQty {
  font-size: 0.9em;
  margin-top: 2px;
  opacity: 0.4;
}

.clientCreated {
  font-size: 0.9em;
  margin-top: 7px;
  opacity: 0.6;
}

.totalClientsTxt {
  font-weight: 500;
  color: #143051;
  text-align: left;
  margin-left: 15px;
  margin-top: 8px;
  /* border-bottom: 1px solid #143051; */
}

.totCliSpan {
  color: #0077ff;
}

.towerClientClientDateContainer {
  background-color: #58467e;
  border-bottom: 2px solid #143051;
  min-height: 125px;
  max-width: 100vw;
  overflow-x: scroll;
  display: flex;
  justify-content: flex-start;
  padding: 4px;
  padding-left: 6px;
  padding-right: 6px;
}

.clientBox1 {
  min-width: 200px;
  max-width: 200px;
  text-align: left;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  /* border-right: 2px solid #75659a; */
  background-color: #462e7e;
  border: 2px solid #6f6092;
  padding: 5px;
  border-radius: 5px;
}

.clientBox1Item {
  display: flex;
  min-width: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  justify-content: space-between;
  padding-right: 12px;
  padding-left: 8px;
  min-height: 34px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #95a7b8;
  /* padding-left:  */
}

.cliBox1CompanyName {
  font-size: 0.8em;
}

.editClientDataValue {
  max-width: 14px;
  cursor: pointer;
}

.dexterContentBox {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  align-items: space-between;
  padding-right: 12px;
  padding-left: 5px;
  min-height: 34px;
  /* align-items: center; */
  border-radius: 4px;
  text-align: left;
  margin-top: 3px;
}

.clientBoxTitle {
  text-align: left;
  font-size: 0.7em;
  margin-top: 2px;
  font-weight: 500;
}

.clientBoxVal {
  font-size: 0.6em;
  cursor: text;
  padding-top: 3px;
  -webkit-user-select: all; /* Chrome 49+ */
  -moz-user-select: all; /* Firefox 43+ */
  -ms-user-select: all; /* No support yet */
  user-select: all; /* Likely future */
}

.clientBox2 {
  min-width: 240px;
  max-width: 240px;
  text-align: left;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 6px;
  /* border-right: 2px solid #75659a; */
  background-color: #462e7e;
  border: 2px solid #6f6092;
  padding: 5px;
  border-radius: 5px;
  /* padding-left: 8px; */
  /* background-color: rgba(255, 255, 255, 0.1); */
}

.yearStartTitle {
  color: #58467e;
  max-width: fit-content;
  border-radius: 5px 5px 0 0;
  background-color: white;
  margin-left: 5px;
  font-size: 0.8em;
  font-weight: 500;
  padding-left: 5px;
  padding-right: 5px;
}

.yrStartValSpan {
  font-weight: 500;
}

.towerYearStartDropdown {
  color: #fff;
  border-radius: 25px;
  font-size: 0.9em;
  padding-left: 12px;
  text-align: left;
  max-width: 52px;
  min-width: 52px;
  line-height: 24px;
  height: 28px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
    #7495ba;
  /* background: url(../../img/general/downArrowNavy.svg) no-repeat right #fff; */
  border: 2px solid #143051;
  -webkit-appearance: none;
  background-position-x: 30px;
  font-weight: 500;
  padding-top: 0px;
  margin-left: 5px;
  /* margin: 10px auto; */
}

.editYearStartDrops {
  display: flex;
  font-size: 0.8em;
  align-items: center;
}

.beyondYearStartYes {
  color: #6ac651;
  font-size: 0.7em;
  font-weight: 500;
  margin-left: 8px;
  text-align: right;
  /* margin-top: -50px; */
}

.beyondYearStartNo {
  color: #d0a3a3;
  font-size: 0.7em;
  font-weight: 500;
  margin-left: 8px;
  text-align: right;
}

.towerInvis {
  visibility: hidden;
}

.refPeriodWidth {
  max-width: 100px;
  min-width: 100px;
}

.refPeriodAndWeekStartContainer {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  position: relative;
  z-index: 0;
  margin-top: 10px;
}

.refPeriodWeekStartUnit {
  /* margin-left: */
  /* padding-top: 50px; */
  position: relative;
  z-index: 0;
}

.yearStartTitleBox {
  display: flex;
  justify-content: space-between;
}

.contactBoxTower {
  margin-top: 8px;
}

.accOwnerEmail {
  cursor: text;
  -webkit-user-select: all; /* Chrome 49+ */
  -moz-user-select: all; /* Firefox 43+ */
  -ms-user-select: all; /* No support yet */
  user-select: all; /* Likely future */
}

.clientLoadingSvg {
  margin-top: 40px;
}

.trialBoxFaded {
  opacity: 0.4;
  pointer-events: none;
}

.trialLengthInput {
  max-width: 50px;
}

.clientStatusSpan {
  background-color: #0077ff;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 10px;
  color: white;
  margin-right: 8px;
  font-size: 0.9em;
  -webkit-user-select: none; /* Chrome 49+ */
  -moz-user-select: none; /* Firefox 43+ */
  -ms-user-select: none; /* No support yet */
  user-select: none; /* Likely future */
}

.activeCli {
  background-color: #28a705;
}

.lapsedCli {
  background-color: #ba0000;
}

.trialCli {
  background-color: #4e6580;
}

.trialEndsTxt {
  font-size: 0.55em;
  padding-top: 2px;
  margin-bottom: -2px;
  text-align: right;
}

.towerSettingsBatchContainer {
  background-color: #2e4657;
  color: white;
  min-height: 70px;
  display: flex;
  border-bottom: 3px solid #1a293a;
}

.batchUnit {
  display: flex;
  flex-direction: column;
  background-color: #4e6580;
  border: 2px solid #75879b;
  min-width: 220px;
  max-width: 200px;
  border-radius: 10px;
  margin: 4px;
  font-size: 0.9em;
  cursor: pointer;
  padding-right: 3px;
}

.selectedBatchUnit {
  background-color: #143051;
  border-color: #95a7b8;
}

.batchUnitRow1 {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  /* padding-right: px; */
  margin-top: 10px;
  margin-bottom: -10px;
  font-weight: 500;
}

.batchKnob {
  margin-top: -5px;
  /* margin-left: 8px; */
}

.batchUnitRow2 {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 18px;
  margin-bottom: 7px;
  opacity: 0.8;
}

.batchSecondContainer {
  text-align: left;
  /* background-color: blue; */
  min-height: 300px;
}

.batchUnitDropdowner {
  background-color: #73a9d0;
  border-bottom: 3px solid #75879b;
  min-height: 100px;
  animation-name: heightDown;
  animation-duration: 0.1s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.batchRunningLight {
  background-color: #6ac651;
  border-radius: 10px;
  padding-left: 2px;
  padding-right: 2px;
  margin-right: 8px;
  opacity: 1;
  animation: blinker 0.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.3;
  }
}

.batchRunningLightOff {
  background-color: #75879b;
  border-radius: 10px;
  padding-left: 2px;
  padding-right: 2px;
  margin-right: 8px;
}

.towerUsersContainer {
  max-height: 100%;
  overflow: scroll;
  max-height: calc(100vh - 250px);
  padding-top: 4px;
  min-width: calc(100% - 190px);
  max-width: calc(100% - 190px);
  /* display: flex; */
  /* padding-bottom: 380px; */
}

.towerUserUnit {
  background-color: white;
  min-width: 98%;
  max-width: 98%;
  margin: 4px auto;
  border: 2px #d2d5e9 solid;
  border-radius: 10px;
  min-height: 150px;
  max-height: 150px;
  display: flex;
  align-items: center;
}

.towerNotePadAndUsersContainer {
  display: flex;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  /* disp */
}

.towerClientsNotepadPanelContainer {
  background-color: #d2d5e9;
  min-width: 190px;
  border-right: 4px solid #95a7b8;
  padding: 8px;
}

.clientNotesToDoQty {
  background-color: #0077ff;
  /* border: 1px #00ccff solid; */
  font-size: 0.9em;
  color: #f5f5f5;
  margin-left: 28px;
  border-radius: 15px;
  padding-left: 5px;
  padding-right: 5px;
  min-height: 18px;
  cursor: default;
  -webkit-user-select: none; /* Chrome 49+ */
  -moz-user-select: none; /* Firefox 43+ */
  -ms-user-select: none; /* No support yet */
  user-select: none; /* Likely future */
  /* margin-bottom: px; */
  /* line-height: 12px; */
  /* padding-top: 1px; */
  /* padding-bottom: 1px; */
}

.clientNoteUnit {
  border: 2px #2e4657 solid;
  background-color: #dbd6f0;
  border-radius: 5px;
  margin-top: 12px;
}

.clientNoteBox {
  font-size: 0.8em;
  text-align: left;
  color: #143051;
  border: 1px solid #95a7b8;
  background-color: #f3eefe;
  padding: 3px;
  padding-bottom: 8px;
  max-width: 96%;
  margin: 3px auto;
  border-radius: 3px;
  /* cursor: pointer; */
}

.clientNoteBoxInput {
  font-size: 0.8em;
  text-align: left;
  color: #143051;
  border: 1px solid #95a7b8;
  background-color: white;
  padding: 3px;
  padding-bottom: 8px;
  max-width: 96%;
  min-width: 96%;
  margin: 3px auto;
  border-radius: 3px;
  min-height: 200px;
}

.clientNoteDone {
  /* background-color: orange; */
  display: flex;
  justify-content: space-between;
}

.noteTodoDone {
  min-width: 14px;
  min-height: 14px;
  max-height: 14px;
  max-width: 14px;
  background-color: #95a7b8;
  border-radius: 20px;
  border: 2px solid #75879b;
  margin-top: 3px;
  margin-left: 8px;
  opacity: 0.5;
  margin-bottom: 6px;
  cursor: pointer;
}

.clientToDoIconTxtDone {
  opacity: 0.5;
  font-size: 0.8em;
  color: #143051;
  margin-top: 2px;
  margin-left: 4px;
}

.noteTodoTodo {
  min-width: 14px;
  min-height: 14px;
  max-height: 14px;
  max-width: 14px;
  background-color: #00ccff;
  border-radius: 20px;
  border: 2px solid #0077ff;
  margin-top: 3px;
  margin-left: 8px;
  /* opacity: 0.5; */
  margin-bottom: 6px;
  cursor: pointer;
}

.clientToDoIconTxt {
  /* opacity: 0.5; */
  font-size: 0.8em;
  color: #143051;
  margin-top: 2px;
  margin-left: 4px;
}

.noteBoxClientWrapper {
  display: flex;
  /* background-color: yellow; */
}

.editClientUnitNoteBtnImg {
  max-width: 24px;
  margin-right: 8px;
  margin-top: 2px;
  cursor: pointer;
}

.clientUnitParentUnit {
  display: flex;
}

.selectedUnitClientBobble {
  background-color: #1d3b5e;
  color: #1d3b5e;
  min-width: 10px;
  min-height: 90px;
  max-height: 90px;
  max-width: 10px;
  margin-top: 33px;
  border-left: 1px solid #102743;
  margin-left: -10px;
  /* z-index: 100; */
}

.clientActionButtonBox {
  min-width: 80px;
  max-width: 80px;
  text-align: left;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  /* border-right: 2px solid #75659a; */
  background-color: #462e7e;
  border: 2px solid #6f6092;
  padding: 5px;
  border-radius: 5px;
  margin-right: 5px;
}

.deleteClientButton {
  background-color: #6d4b6d;
  border-radius: 5px;
  font-size: 0.85em;
  font-weight: 500;
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
  border: solid 2px #382a57;
  color: #fff;
  cursor: pointer;
  min-height: 30px;
}

.deleteClientButtonSelected {
  background-color: #ba0000 !important;

  border-radius: 5px;
  font-size: 0.8em;
  font-weight: 500;
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
  border: solid 2px #382a57;
  color: #fff;
  cursor: pointer;
  min-height: 30px;
}

.removeClientLoading {
  pointer-events: none;
  cursor: none;
  opacity: 0.4;
}

.clientCounterBar {
  display: flex;
  justify-content: space-between;
}

.refreshClients {
  background-color: #1d3b5e;
  color: white;
  border: #143051 2px solid;
  border-radius: 10px;
  font-size: 0.8em;
  font-weight: 500;
  margin-top: 5px;
  margin-right: 8px;
  padding-top: 3px;
  padding-bottom: 5px;
  padding-left: 4px;
  padding-right: 4px;
  cursor: pointer;
}

.refreshClients:active {
  transform: scale(0.95);
  opacity: 0.7;
}

.towerUserUnitBox1 {
  border: 2px solid #e6eef5;
  border-radius: 10px;
  max-height: 140px;
  min-height: 140px;
  margin-left: 4px;
  text-align: left;
  min-width: 100px;
  max-width: 100px;
  padding: 4px;
  font-size: 0.9em;
  color: #143051;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: space-between; */
}

.removeUserTower {
  /* background-color: #6d4b6d; */
  border-radius: 5px;
  font-size: 0.85em;
  font-weight: 500;
  text-align: left;
  max-width: 66px;
  padding-left: 3px;
  /* padding-top: 4px;
  padding-bottom: 4px; */
  /* border: solid 2px #382a57; */
  color: #c7a2c7;
  cursor: pointer;
  min-height: 20px;
  max-height: 20px;
  line-height: 20px;
}

.sureRemoveUserTower {
  color: #ba0000;
}

.userNameFilterDiv {
  /* background-color: orange; */
}

.userNameSelctFilter {
  min-width: 100%;
  background-position-x: 140px;
  color: white;
  padding-left: 10px;
  font-size: 0.8em;
  font-weight: 500;
}

.permissionsBox {
  font-weight: 500;
  font-size: 0.8em;
  background-color: #7895b6;
  border: 2px solid #4e6580;
  color: white;
  max-width: fit-content;
  border-radius: 10px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.userCreatedBox {
  font-weight: 500;
  font-size: 0.8em;
  background-color: #73a9d0;
  border: 2px solid #4e6580;
  color: white;
  max-width: fit-content;
  border-radius: 10px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.userCreatedSpan {
  font-size: 0.8em;
}

.towerUserBox1NameTxt {
  font-size: 1em;
  font-weight: 500;
  padding-left: 2px;
}

.towerUserUnitTitle {
  font-size: 0.85em;
  font-weight: 500;
}

.hourlyRateBox {
  min-width: 140px;
  justify-content: flex-start;
  background-color: #eaf1fc;
}

.mpwTimelineBox {
  min-width: 140px;
  justify-content: flex-start;
  background-color: #f5eafc;
}

.towerHourlyRateTimelineDiv {
  /* background-color: blue; */
  max-height: 110px;
  font-size: 0.9em;
  font-weight: 500;
  overflow: scroll;
  border-radius: 5px;
  border: 1px solid #95a7b8;
  margin-top: 4px;
}

.hourlyRateTimelineLeftSection {
  color: #4e6580;
  font-size: 0.9em;
}

.towerHourlyRateObj {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid #d2d5e9;
  /* border-radius: 5px; */
  padding-right: 5px;
  /* margin-top: 2px; */
  padding-left: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: white;
}

.towerHourlyRateObj:first-child {
  border-top: none;
}

.towerUserHourlyRateDiv {
  display: flex;
  justify-content: space-between;
  padding-left: 4px;
  padding-right: 4px;
}

.rateOverlapWarn {
  background-color: #ffb7b7;
}

.towerCurrRate {
  font-size: 0.9em;
  margin-top: -2px;
}

.towerWorkingPatternSpan {
  font-size: 0.7em;
  font-weight: 500;
  background-color: #0077ff;
  color: white;
  border-radius: 3px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 3px;
  padding-right: 3px;
  margin-bottom: 2px;
}

.tHrRate {
  font-size: 0.8em;
  background-color: #7895b6;
  color: white;
  border-radius: 7px;
  padding: 4px;
}

.towerUserSplitter {
  min-height: 3px;
  min-width: 100%;
  background-color: #c4c8e6;
}

.jamBatchUnit {
  max-width: 220px;
  background-color: #1a293a;
  min-width: 200px;
}

.batchUnitDropUnit1 {
  background-color: ;
}

.batchUnitDropDownUnitSplitter {
  min-width: 2px;
  background-color: #143051;
  min-height: 80px;
  max-height: 80px;
  margin-left: 4px;
  margin-right: 4px;
}

.runBatchBtn {
  background-color: #0077ff;
  color: white;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #143051;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 500;
  cursor: pointer;
  font-size: 0.9em;
}

.none {
  display: none;
}

.idleBatchLight {
  background-color: #537949;
  border-radius: 10px;
  padding-left: 2px;
  padding-right: 2px;
  margin-right: 8px;
}

.jamActiveLight {
  background-color: #fff;
  border-radius: 10px;
  padding-left: 2px;
  padding-right: 2px;
  margin-right: 8px;
  opacity: 1;
  animation: blinker 0.5s linear infinite;
}

@keyframes blinkerJam {
  50% {
    opacity: 0.9;
    border-color: #fff;
  }
}
.jamBlueBg {
  background-color: #0077ff;
  border-color: #2a3e55;
  animation: blinkerJam 0.5s linear infinite;
}

.powerMonitorButton {
  min-width: 50px;
  max-width: 50px;
  border-radius: 5px;
  /* display: flex; */
  background-color: #1a293a;
  padding-top: 4px;
  align-items: center;
  justify-content: center;
  max-height: 50px;
  min-height: 50px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 4px;
  cursor: pointer;
}

.powerBtnTxt {
  color: white;
  text-align: center;
  font-size: 0.5em;
  font-weight: 500;
}

.towerPowerBtnImg {
  min-width: 30px;
}
@keyframes blinkerJamPower {
  50% {
    opacity: 0.5;
    background-color: #5a8d64;
  }
}
.activePowerBtnImg {
  background-color: #5a8d64;
  animation: blinkerJamPower 0.5s linear infinite;
}

@keyframes blinkThrottle {
  50% {
    opacity: 0.5;
    background-color: #ba0000;
  }
}
.throttlingIcon {
  background-color: #ba0000;
  animation: blinkThrottle 0.5s linear infinite;

  max-width: 100px;
  display: flex;
  align-items: center;
  font-size: 0.8em;
  font-weight: 500;
  max-height: 50px;
  border-radius: 5px;
  margin-top: 10px;
  margin-left: 3px;
  margin-right: 3px;
}

.fadeRunMidnightBtn {
  opacity: 0.3;
  pointer-events: none;
}
