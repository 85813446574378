@font-face {
  font-family: "timelify";
  font-style: bold;
  font-weight: 600;
  /* src: url("../fonts/open-sans-v13-latin-regular.eot"); IE9 Compat Modes */
  src: local("timelify-bold"), local("timelify-bold"),
    url("../fonts/timelify-bold.otf") format("truetype");
}
@font-face {
  font-family: "timelify";
  /* font-style: medium; */
  font-weight: 500;
  /* src: url("../fonts/open-sans-v13-latin-regular.eot"); IE9 Compat Modes */
  src: local("timelify-medium"), local("timelify-medium"),
    url("../fonts/timelify-medium.otf") format("truetype");
}

@font-face {
  font-family: "timelify";
  font-style: normal;
  font-weight: 400;
  /* src: url("../fonts/open-sans-v13-latin-regular.eot"); IE9 Compat Modes */
  src: local("timelify-normal"), local("timelify-normal"),
    url("../fonts/timelify-normal.otf") format("truetype");
}

@font-face {
  font-family: "timelify";
  /* font-style: light; */
  font-weight: 300;
  /* src: url("../fonts/open-sans-v13-latin-regular.eot"); IE9 Compat Modes */
  src: local("timelify-light"), local("timelify-light"),
    url("../fonts/timelify-light.otf") format("truetype");
}

@font-face {
  font-family: "timelify";
  /* font-style: extralight; */
  font-weight: 200;
  /* src: url("../fonts/open-sans-v13-latin-regular.eot"); IE9 Compat Modes */
  src: local("timelify-extralight"), local("timelify-extralight"),
    url("../fonts/timelify-extralight.otf") format("opentype");
}

a,
a:link,
a:visited,
a:hover,
a:focus,
a:active {
  color: inherit;
  text-decoration: none;
}

body {
  /* overflow: hidden; */

  /* background-attachment: fixed; */
/* position: fixed; */
/* top:0; */
/* left:0; */
  /* disable blue highlight */
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

html, body {
  /* Prevent zooming in and out */
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  touch-action: manipulation; /* Prevent double-tap zoom on mobile devices */
  max-zoom: 100%; /* Prevent zooming on some older mobile browsers */
  -ms-text-size-adjust: 100%; /* IE/Edge specific */
  -ms-content-zooming: none; /* IE/Edge specific */
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  min-width: 100%;
  zoom: 1; /* Prevent zoom on older IE versions */
  transform-origin: 0 0;
  /* overflow:hidden; */
  /* display: none; */
}

.App {
  /* position:fixed;
  top: 0px; left:0px; */
  /* left:100px */
    /* display: none !important; */

  background-color: #ebf0f7;
  display: flex;
  justify-content: center;
  /* background-color: blue; */
  /* mix-blend-mode: overlay; */
}

.wrapper {
  background-color: #ebf0f7;
  /* background-color:orange; */
  max-width: 1140px;
  /* overflow:hidden; */
  /* z-index:200000; */
  /* border-right: 1px solid #e6eaee; */
  /* margin: 0 auto; */

  /* position: fixed;top:0; */
}

@media only screen and (max-width: 1141px) {
  .wrapper {
    border-right: none;
  }
}

@media only screen and (max-width: 820px) {
  .wrapper {
    position: fixed;
    top: 0px;
    left: 0px;
  }
}

/* @media only screen and (max-width: 820px) {
  .App {
    position: fixed;
    top: 0px;
    left: 0px;
  }
} */

@media only screen and (min-width: 2614px) {
  .wrapper {
    border-right: 1px solid #e6eaee;
  }
}

.imgPreloads {
  position: absolute;
  visibility: hidden;
}

.loaderBg {
  background-color: white;
  z-index: 100000000;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
}

.loaderlogo {
  max-width: 70px;
  margin-top: -100px;
}

.hideLoader {
  display: none;
}

/* animations */
@keyframes zoomModalIn {
  from {
    /* bottom: -10%; */
    opacity: 0;
    transform: scale(0.85);
  }
  to {
    /* bottom: 0; */
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes rotateClockWise45 {
  from {
    /* bottom: -10%; */
    /* opacity: 0; */
    /* margin-top: 0px; */
    transform: rotate(0);
  }
  to {
    /* bottom: 0; */
    transform: rotate(45);
    /* margin-top: 1px; */
  }
}

@keyframes zoomAddBtnIn {
  from {
    /* bottom: -10%; */
    /* opacity: 0.35; */
    transform: scale(0.85);
  }
  to {
    /* bottom: 0; */
    /* opacity: 0.35; */
    transform: scale(1);
  }
}

/* animations */
@keyframes zoomBtnIn {
  from {
    /* bottom: -10%; */
    opacity: 1;
    transform: scale(0.85);
  }
  to {
    /* bottom: 0; */
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeModalIn {
  from {
    /* bottom: -10%; */
    opacity: 0;
    /* transform: scale(0.4); */
  }
  to {
    /* bottom: 0; */
    opacity: 1;
    /* transform: scale(1); */
  }
}

@keyframes slideSubBarDown {
  from {
    margin-top: -40px;
    opacity: 0;
    transform: scale(0.4);
    z-index: -1;
  }
  to {
    margin-top: -15px;
    opacity: 1;
    transform: scale(1);
    z-index: -1;
  }
}



@keyframes slideSubBarDownNoZoom {
  from {
    margin-top: -40px;
    opacity: 0;
    /* transform: scale(); */
    z-index: -1;
  }
  to {
    margin-top: -15px;
    opacity: 1;
    /* transform: scale(1); */
    z-index: -1;
  }
}

@keyframes slideDesktopMenuDown {
  from {
    /* top: 150px; */
    max-height: 10px;
    /* opacity: 0; */
    /* transform: scale(); */
    z-index: -1;
  }
  to {
    /* top: 162px; */
    /* max-height: 211px; */

    /* opacity: 1; */
    /* transform: scale(1); */
    z-index: -1;
  }
}

@keyframes slideDesktopMenuDown2 {
  from {
    /* top: 150px; */
    max-height: 10px;
    min-height: 0vh;

    /* opacity: 0; */
    /* transform: scale(); */
    z-index: -1;
  }
  to {
    /* top: 162px; */
    /* max-height: 211px; */
    min-height: 60vh;
    /* opacity: 1; */
    /* transform: scale(1); */
    z-index: -1;
  }
}

@keyframes slideModalUp {
  from {
    margin-top: 100vh;
    /* opacity: 0; */
    /* transform: scale(0.4); */
  }
  to {
    margin-top: 0vh;
    /* opacity: 1; */
    /* transform: scale(1); */
  }
}

@keyframes slideModalRight {
  from {
    margin-left: -200px;
    opacity: 0;
    /* transform: scale(0.4); */
  }
  to {
    margin-left: 0px;
    opacity: 1;
    /* transform: scale(1); */
  }
}

@keyframes slideMoveHere {
  from {
    /* margin-left: -50px; */
    /* max-width: 50px;
    min-width: 50px; */
    z-index: 1;
    opacity: 0.1;
    transform: scale(0.4);
  }
  to {
    z-index: 1;

    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideModalLeft {
  from {
    margin-right: 200px;
    opacity: 0;
    /* transform: scale(0.4); */
  }
  to {
    margin-right: 0px;
    opacity: 1;
    /* transform: scale(1); */
  }
}

@keyframes tipBox {
  from {
    margin-right: -340px;
    opacity: 0;
    /* transform: scale(0.4); */
  }
  to {
    margin-right: 0px;
    opacity: 1;
    /* transform: scale(1); */
  }
}

@keyframes heightDown {
  from {
    max-height: 0px;
    min-height: 0px;
    opacity: 0;

    /* transform: scale(0.4); */
  }
  to {
    /* margin-right: 0px; */
    opacity: 1;
    /* transform: scale(1); */
  }
}

@keyframes costDivDown {
  from {
    max-height: 0px;
    min-height: 0px;
    margin-bottom: 0px;

    /* opacity: 0; */
    /* transform: scale(0.4); */
  }
  to {
    /* margin-right: 0px; */
    /* opacity: 1; */
    margin-bottom: 24px;

    max-height: 120px;
    min-height: fit-content;
    /* transform: scale(1); */
  }
}

@keyframes widthRight {
  from {
    max-width: 0px;
    min-width: 0px;
    opacity: 0;
    /* transform: scale(0.4); */
  }
  to {
    /* margin-right: 0px; */
    /* max-width: fit-content;
    min-width: fit-content; */
    opacity: 1;
    /* transform: scale(1); */
  }
}

@keyframes rotateClockWise180 {
  from {
    transform: rotate(0deg);
  }
  to {
    /* margin-right: 0px; */
    transform: rotate(180deg);
    /* transform: scale(1); */
  }
}

@keyframes rotateAntiClockWise180 {
  from {
    transform: rotate(180deg);
  }
  to {
    /* margin-right: 0px; */
    transform: rotate(0deg);
    /* transform: scale(1); */
  }
}

@keyframes slideMenuFromLeft {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 80vh;
  }
}
@keyframes bar100 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 100%;
    min-width: 100%;
  }
}
@keyframes bar95 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 95%;
    min-width: 95%;
  }
}

@keyframes bar90 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 90%;
    min-width: 90%;
  }
}

@keyframes bar85 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 85%;
    min-width: 85%;
  }
}

@keyframes bar80 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 80%;
    min-width: 80%;
  }
}

@keyframes bar75 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 75%;
    min-width: 75%;
  }
}

@keyframes bar70 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 70%;
    min-width: 70%;
  }
}

@keyframes bar65 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 65%;
    min-width: 65%;
  }
}

@keyframes bar60 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 60%;
    min-width: 60%;
  }
}

@keyframes bar55 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 55%;
    min-width: 55%;
  }
}

@keyframes bar50 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 50%;
    min-width: 50%;
  }
}

@keyframes bar45 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 45%;
    min-width: 45%;
  }
}

@keyframes bar40 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 40%;
    min-width: 40%;
  }
}

@keyframes bar35 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 35%;
    min-width: 35%;
  }
}

@keyframes bar30 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 30%;
    min-width: 30%;
  }
}

@keyframes bar25 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 25%;
    min-width: 25%;
  }
}

@keyframes bar20 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 20%;
    min-width: 20%;
  }
}

@keyframes bar15 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 15%;
    min-width: 15%;
  }
}

@keyframes bar10 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 10%;
    min-width: 10%;
  }
}

@keyframes bar05 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 05%;
    min-width: 05%;
  }
}

@keyframes bar00 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 1%;
    min-width: 1%;
  }
}

* {
  /* scrollbar-width: thin; */
  scrollbar-color: #143051 none;
}


@media only screen and (max-width: 820px) {
  * {
    scrollbar-width: thin;
  }
  
  }

*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

*::-webkit-scrollbar-track {
  /* background: none; */
}

*::-webkit-scrollbar-thumb {
  background-color: #c7daef;
  border-radius: 20px;
  border: 3px solid none;
}

*::-webkit-scrollbar-button {
  width: 5px;
  height: 5px;
}

@keyframes slideDesktopSubMenuDownMyShifts {
  from {
    min-height: 0px;
    max-height: 0px;
  }
  to {
    min-height: 86px;
    max-height: 86px;
  }
}

@keyframes slideDesktopSubMenuDownMyAbsence {
  from {
    min-height: 0px;
    max-height: 0px;
  }
  to {
    min-height: 64px;
    max-height: 64px;
  }
}

@keyframes slideDesktopSubMenuDownFourItems {
  from {
    min-height: 0px;
    max-height: 0px;
  }
  to {
    min-height: 118px;
    max-height: 118px;
  }
}

@keyframes slideDesktopSubMenuDownFiveItems {
  from {
    min-height: 0px;
    max-height: 0px;
  }
  to {
    min-height: 150px;
    max-height: 150px;
  }
}


/* Centralising desktop */
/* @media only screen and (min-width: 1260px) {
  .TopBar {
  left: calc(260px);
  border-left: 1px solid black
  }
}


@media only screen and (min-width: 1300px) {
  .TopBar {
  left: calc(300px);
  border-left: 1px solid black
  }
}




@media only screen and (min-width: 1340px) {
  .TopBar {
  left: calc(340px);
  border-left: 1px solid black
  }
}




@media only screen and (min-width: 1380px) {
  .TopBar {
  left: calc(380px);
  border-left: 1px solid black
  }
}


@media only screen and (min-width: 1420px) {
  .TopBar {
  left: calc(420px);
  border-left: 1px solid black
  }
}


@media only screen and (min-width: 1460px) {
  .TopBar {
  left: calc(460px);
  border-left: 1px solid black
  }
}




@media only screen and (min-width: 1500px) {
  .TopBar {
  left: calc(500px);
  border-left: 1px solid black
  }
}




@media only screen and (min-width: 1540px) {
  .TopBar {
  left: calc(540px);
  border-left: 1px solid black
  }
} */

