.calendarSubBar {
  width: 100%;
  min-height: 45px;
  max-height: 45px;
  display: flex;
  justify-content: space-around;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  font-size: 12px;
  background-color: #496375;
  background-color: #0077ff;
  position: fixed;
  z-index: 101;
  bottom: 40px;
  padding-bottom: 8px;
  font-weight: 500;
  color: #b7c9d6;

  border-top: solid #0469de 2px;

}

.allowTeamSwapsBtnHolder {
  display: flex;
  margin-right: 8px;
  align-items: center;
  border: solid 2px #bbcada;
  background-color: #f4f8fe;
  color: #bbcada;
  font-size: 12px;
  padding-left: 8px;
  padding-right:4px;
  justify-content: space-between;
  border-radius: 5px;
  cursor: pointer;
  text-align: left;
  line-height: 13px;
  font-weight: 500;
}

.allowTeamSwapsBtnHolder:hover {
  border-color: #a6aeb6 !important;
}

.allowTeamSwapsBtnHolder:last-child {
  margin-right: 0px;
}


.purpleBgCalSubbar {
  width: 100%;
  min-height: 45px;
  max-height: 45px;
  display: flex;
  justify-content: space-around;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  font-size: 12px;
  padding-top: 1px;
  position: fixed;
  z-index: 101;
  bottom: 40px;
  padding-bottom: 8px;
  font-weight: 500;
  color: #b7c9d6;
  border-top: solid #2e2a6f 2px;
  background-color: #403a8e;


 
}

.calSubBtn {
  color: #fff;
  font-size: 13px;
  margin-top: 0px;

  opacity: 0.7;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 44px;
  font-weight: 500;
  line-height: 44px;
  display: flex;
  flex-direction: column;
  min-width: 25vw;
  max-width: 25vw;
}

.calSubBtnBlue {
  color: #a3bdff;
  font-size: 13px;
  margin-top: 1px;

  padding-left: 12px;
  padding-right: 12px;
  min-height: 44px;

  line-height: 44px;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  min-width: 25vw;
  max-width: 25vw;

}

.pinkSelectedCalBtn {
  color: white;
}

.calSubBarTriangle {
  max-width: 12px;

  margin: 0 auto;
  margin-top: -10px;
  animation: calSubTriSlideUp 0.09s ease-in-out;

}

.calSubBtnSel {
  color: white;

  opacity: 1;


  
}

@keyframes calSubTriSlideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

.calSubBarItemTxt {
}

.calSubBarItemTxt:active {
  transform: scale(0.96);
}

.absencePage {
  min-height: calc(100% - 188px);
  max-height: calc(100% - 188px);
  background-color: #ebf0f7;
}
.absencePageUpperContainer {
  background-color: #fff;
  min-height: 125px;
  max-height: 125px;
  min-width: 100%;
  max-width: 100%;
  position: relative;
  z-index: 0;
  border-bottom: 1px solid #e6eaee;
}

.userAbsencesContainer {
  max-width: 90%;
  margin-left: 5%;
}

.absenceListContainer {
  background-color: #ebf0f7;
  min-height: calc(100vh - 215px);
  max-height: calc(100vh - 215px);
  overflow: auto;
  padding-top: 8px;
  margin-top: 5px;
  padding-bottom: 68px;
  position: relative;
  z-index: 0;
}

.showFilterAbsenceContainer {
  min-height: calc(100vh - 310px);
  max-height: calc(100vh - 310px);
  border-top: 1px solid #e6eaee;
}

.absenceMgrMonthFilterBar {
  background-color: #ebf0f7;
  min-height: 40px;
  max-height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5%;
}

.mgrAbsenceListContainerNoTopBorder {
  border: none;
}

.myAbsenceUpperInnerContainer {
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  margin: 0 auto;
}
.myAbsenceTitle {
  color: #143051;
  font-weight: 500;
  font-size: 1.5em;
  margin-bottom: 0px;
  text-align: left;
  margin-top: 51px;
  padding-left: 5%;
}

.absenceMenuBar {
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
  color: #c8e8f0;
  font-weight: 500;
  max-width: 90%;
  margin: 0px auto;
  min-height: 40px;
  max-height: 40px;
}

.absenceMenuLeft {
  min-width: 137px;
  max-width: 137px;
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
}

.absenceMenuTeam {
  background-color: #a387df;
  border: 1px #8e76c1 solid;
  display: flex;
  color: #fff;
  min-height: 36px;
  line-height: 34px;
  padding-left: 9px;
  border-radius: 5px;
  text-align: center;
  margin-left: 5px;
  margin-right: 8px;
  min-width: 68px;
  max-height: 35px;
  min-height: 35px;

  font-size: 1em;
}

.jamAbs {
  opacity: 0.3;
  pointer-events: none;
}

.absenceTeamSelectBar {
  display: flex;
  color: #fff;
  align-items: flex-end;
  padding-bottom: 6px;
  min-height: 60px;
  max-width: 95%;
  min-width: 95%;
  overflow: auto;
  white-space: nowrap;
  padding-right: 35px;

  padding-left: 5%;

  margin-top: -23px;
  margin-left: 30px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.absenceTeamSelectBar::-webkit-scrollbar {
  width: 5px !important;
  height: 4px !important;
}

.teamNameAbsence {
  min-width: fit-content;
  display: flex;
  min-height: 26px;
  margin-left: 20px;
  border-bottom: 3px solid white;
  font-size: 14px;
  font-weight: 500;
  color: #8290b6;

  cursor: pointer;
}

.teamNameAbsence:first-of-type {
  margin-left: 0px;
}

.teamNameAbsence:active {
}

.teamNameAbsence:hover {
  color: #4f79ab;
  border-bottom: 3px solid #dde9f9;
}

.absencemenutxt:active {
  transform: scale(0.96);
}

.absenceTeamSelected {
  color: #723fce;
  border-bottom: 3px solid #ddd2ff;
  font-weight: 500;
  font-size: 14px;
  cursor: default;
}

.absenceTeamSelected:hover {
  color: #723fce;
  border-bottom: 3px solid #ddd2ff;
}

.myRotaTeamSelected {
  color: #214978;
  border-bottom: 3px solid #00ccff;
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 5px;
  cursor: default;
}

.myRotaTeamSelected:hover {
  color: #214978;
}

.teamAbsenceFaderImg {
  position: fixed;
  top: 96px;
  right: calc(5% - 1px);
  pointer-events: none;
}

.teamAbsenceTitleContainer {
  display: flex;
  max-height: 30px;
  padding-left: 5%;
  min-width: 200px;

  margin-top: 50px;
}

.teamAbsenceTitle {
  color: #143051;
  font-weight: 500;
  font-size: 1.4em;

  text-align: left;
  margin-left: 15px;
  margin-top: 3px;
}

.returnBackFromTeamAbsenceImg {
  max-width: 13px;
  margin-top: -6px !important;
  cursor: pointer;
}

.returnBackFromTeamAbsenceImg:hover {
  opacity: 0.8;
}

.absenceMgrReturnImg {
  margin-top: 10px;
}
.absenceUserMenuBarTxt {
  font-size: 0.94em;
  min-width: fit-content;
  max-height: 26px;
  min-height: 26px;
  margin-top: 0px;
  color: #143051;
  opacity: 0.8;
  font-weight: 400;
  cursor: pointer;
}

.absenceUserMenuBarTxt:active {
  transform: scale(0.97);
}
.absenceMenuTxt {
  font-size: 14px;
  max-height: 27px;
  cursor: pointer;
  color: #143051;
  font-weight: 400;
  margin-top: 2px;
}

.absenceMenuTxt:hover {
  font-weight: 500;
  color: #4f79ab;
}
.absenceMenuTxtSelected:active {
  transform: scale(0.96);
}

.absenceMenuTxt:active {
  transform: scale(0.96);
}

.absenceStatsBtn {
  min-width: 40px;
  cursor: pointer;
}

.addAbsenceBtn {
  position: fixed;
  top: calc(100% - 188px);
  right: 45px;
  background-color: #0077ff;
  background-color: #9374d6;
  border: 1px solid #4b1caf;

  color: #fff;
  border-radius: 100%;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  line-height: 42px;
  font-size: 2.5em;
  z-index: 1000;
}

.addAbsenceBtn:active {
  transform: scale(0.95);
}

.addAbsenceModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.addAbsenceModalBoxNonAdmin {
  background-color: #f4f8fe;
  color: #143051;
  border-radius: 5px;

  min-width: 340px;
  max-width: 340px;
  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  cursor: auto;
  min-height: 350px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.addAbsenceModalHeaderContainer {
  min-height: 55px;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.closeAddAbsenceImgHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  min-height: 40px;
  margin-left: 10px;
}

.addAbsenceModalHeaderTitle {
  font-size: 1.2em;
  padding-top: 3px;
  font-weight: 500;
}

.addAbsencePageKeyContainer {
  min-height: 10px;

  display: flex;
  justify-content: center;
}

.addAbsencePageKeyHolder {
  min-height: 10px;
  min-width: 34px;
  max-width: 34px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.addAbsencePageKey {
  min-height: 5px;
  min-width: 5px;
  max-width: 5px;
  max-height: 5px;
  background-color: #859dbb;
  border-radius: 100%;
}

.addAbsencePageKeySelected {
  min-height: 7px;
  min-width: 7px;
  max-width: 7px;
  max-height: 7px;
  background-color: #143051;
  border-radius: 100%;
}

.addAbsenceMidContainer {
  background-color: white;

  max-width: 80%;
  min-width: 80%;
  margin: 0 auto;

  margin-top: 10px;
  text-align: center;
  border-top: solid 2px #e6eaee;

  border-radius: 5px 5px 0 0;
  overflow: auto;
  max-height: 260px;
  min-height: 260px;
}

.addAbsenceUserDropdown {
  color: #fff;
  border-radius: 10px;
  font-size: 0.9em;
  padding-left: 12px;
  text-align: left;
  max-width: 200px;
  min-width: 200px;
  line-height: 33px;
  height: 35px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
    #7495ba;

  border: 1px solid #143051;
  -webkit-appearance: none;
  background-position-x: 178px;
  font-weight: 500;
  padding-top: 0px;
  margin: 10px auto;
}

.addAbsenceRowSplitter {
  min-height: 2px;
  background-color: #fff;
  margin-top: 20px;
}

.absenceTyperopdown {
  color: #fff;
  border-radius: 25px;
  font-size: 0.9em;
  padding-left: 12px;
  text-align: left;
  max-width: 200px;
  min-width: 200px;
  line-height: 33px;
  height: 35px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
    #7495ba;
  border: 1px solid #143051;
  -webkit-appearance: none;
  background-position-x: 178px;
  font-weight: 500;
  padding-top: 0px;
  margin: 10px auto;
}

.disableAbsenceElement {
  opacity: 0.4;
}

.addAbsenceRowTitleTxt {
  font-weight: 500;
  color: #496375;
  margin-top: 18px;
  text-align: left;
  margin-left: 10%;
}

.paidIndicatorTxt {
  font-weight: 400;
  color: #859dbb;
  font-size: 0.95em;
  text-align: left;
  margin-left: 14%;
  margin-top: 10px;
  font-size: 0.9em;
  padding-left: 1px;
}

.paidIndicatorContainer {
  min-height: 20px;
}

.addAbsenceModalFooter {
  border-top: 2px #e6eaee solid;
  max-width: 80%;
  margin: 0 auto;

  min-height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addAbsenceFooterUnitSide {
  min-width: 70px;
}

.addAbsenceFooterUnitMiddle {
  min-width: 100px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addAbsenceBackBtn {
  color: #859dbb;
  font-weight: 500;
  cursor: pointer;
  text-align: left;
  padding-left: 4px;
}

.addAbsenceClostBtn {
  color: #859dbb;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  text-align: right;
  padding-right: 4px;
}

.addAbsenceModalNextBtn {
  min-height: 35px;
  max-height: 35px;
  min-width: 80px;
  max-width: 80px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  background-color: #0bf;
  border: 2px solid #0af;
  padding-left: 14px;
  padding-right: 14px;
  cursor: pointer;
}

.addAbsenceShiftsContainer {
  min-width: 90%;
  max-width: 90%;
  margin: 10px auto;

  max-height: 160px;
  border-top: 2px #e0ebf8 solid;
  border-bottom: 2px #e0ebf8 solid;

  overflow: auto;
  padding: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  align-content: flex-start;

  flex-wrap: wrap;
}

.addShiftBtnImg {
  min-width: 40px;
  margin-top: 30px;
}

.addShiftsBtnWhenEmpty {
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  padding-left: 12px;
  text-align: left;
  max-width: 113px;
  min-width: 113px;
  height: 35px;
  -webkit-appearance: none;
  min-height: 35px;
  max-height: 35px;
  margin-top: 10px;
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: 500;
  border: solid 2px #bbcada;
  background-color: #cbd7e6;
  cursor: pointer;
}

.addShiftToAbsenceBtnTxt {
  margin-left: -6px;
  font-size: 1em;
}
.addShiftToAbsencePlus {
  font-size: 22px;
  margin-right: 8px;
  margin-top: -2px;
}

.shiftSelectForAbsenceModalUnderlay {
  background: rgba(0, 0, 0, 0.25);

  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.shiftSelectForAbsenceModalBox {
  background-color: #fff;
  min-height: 500px;
  max-width: 340px;
  min-width: 340px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
}

.shiftSelectorBox {
  background-color: #f4f8fe;
  min-height: 400px;
  max-height: 70vh;
  overflow: auto;
  padding-top: 10px;
  padding-bottom: 30px;
  border-radius: 5px 5px 0 0;
  border-top: 2px solid #e6eaee;
}

.shiftSelectorFooter {
  display: flex;
  max-width: 100%;
  margin: 0 auto;
  min-height: 60px;
  align-items: center;
  justify-content: space-around;
  border-top: 2px solid #e6eaee;
}

.removeSelectingShiftsBtn {
  color: #859dbb;
  font-size: 16px;
  font-weight: 500;
}

.closeSelectingShiftsBtn {
  font-size: 16px;
  color: #859dbb;
  font-weight: 500;
}

.doneSelectingShiftsBtn {
  background-color: #00ccff;
  border: 1px solid #143051;
  border-radius: 25px;
  color: #fff;
  font-weight: 500;
  min-height: 30px;
  line-height: 30px;
  padding-left: 12px;
  padding-right: 12px;
}

.saveSelectingShiftsBtn {
  background-color: #0bf;
  border: 2px solid #0af;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  min-height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 14px;
  padding-right: 14px;
}

.disableSaveBtn {
  opacity: 0.4;
  pointer-events: none;
}

.addShiftToAbsenceYearSelectContainer {
  min-height: 60px;

  display: flex;
  align-items: center;
  justify-content: space-around;
}

.lastYearSelectorHolder {
  display: flex;
  font-weight: 500;
  min-width: 130px;
  color: #214978;
  justify-content: space-around;
  margin-top: 8px;
  margin-left: 18px;

  min-height: 35px;
  line-height: 34px;

  padding-left: 10px;
  border-radius: 5px;
  margin-bottom: 4px;
  margin-top: 4px;

  border: 1px solid #e6eaee;
  background-color: white;
}

.lastYearSelectShiftsTxt {
}

.activeLastYearToggleShiftSelect {
  background-color: #859dbb;
  color: white;
  border: 1px solid #496375;
  cursor: pointer;
  background-color: #0bf;
  border: 2px solid #0af;
}

.knobAddShiftsToAbsenceToggleLastYear {
  margin-top: 2px !important;
  margin-left: 4px;
}

.addShiftsModalTitleTxt {
  font-weight: 500;
  color: #143051;
  font-size: 1.2em;
  margin-top: 5px;
  margin-left: 6px;
}

.shiftToAddUnit {
  min-height: 50px;
  max-height: 50px;
  max-width: 90%;
  margin: 6px auto;

  border-radius: 5px;
  background-color: white;
  border-bottom: 2px solid #e6eaee;
  display: flex;
  background-color: #fff;
}

.selectedShiftToAddToAbsenceBg {
  background-color: #0bf;
  border: 2px solid #0af;
}

.mobAbsenceTitle {
  margin-top: 52px;
}

.absenceMenuBarMob {
  margin-top: 1px;
}

.absenceTeamSelectBarMob {
  margin-top: -24px;
}

.teamAbsenceTitleContainerMob {
  margin-top: 49px;
}
.shiftToAddUnitLeft {
  min-width: 120px;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  padding-left: 15px;
  font-weight: 500;
}
.shiftToSelectDateStripTxt {
  color: #496375;
  font-size: 0.9em;
  min-width: 150px;
}
.shiftToSelectTimesTxt {
  color: #859dbb;
  font-size: 0.8em;
  font-weight: 500;
}

.shiftToAddUnitMiddle {
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whiteTxtForAddShiftToAbsence {
  color: white;
}

.absentIndicatorAddShiftToAbsence {
  background-color: #e3e8ee;

  border-radius: 5px;
  color: #496375;
  font-size: 0.65em;
  font-weight: 500;

  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shiftToAddUnitRight {
  min-width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectedShiftRadioBtnImg {
  max-width: 25px;
}

.shiftAddedUnit {
  display: flex;
  padding-left: 7px;
  padding-right: 7px;
  margin-left: 3px;
  margin-right: 3px;
  background-color: #e3e8ee;
  font-size: 0.9em;
  font-weight: 500;
  color: #496375;
  justify-content: space-around;
  margin-top: 6px;
  border-radius: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  line-height: 22px;
  max-height: 30px;
}
.crossToRemoveShiftFromAddToAbsence {
  max-width: 8px;
  margin-left: 5px;
}

.addMoreShiftsToAbsenceBtn {
  color: #fff;
  border-radius: 5px;
  font-size: 16px;

  text-align: left;
  max-width: 96px;
  min-width: 96px;
  height: 35px;
  -webkit-appearance: none;
  min-height: 35px;
  max-height: 35px;
  margin-top: 10px;
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: 500;
  border: solid 2px #bbcada;
  background-color: #cbd7e6;
  cursor: pointer;
  margin-left: 10%;
}

.absenceDurationTopRightContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: -30px;
  font-weight: 500;
  margin-right: 20px;

  color: #6a98d1;
}

.zeroOpacityAbsenceDuration {
  opacity: 0;
}

.rtwRequiredBtnsContainer {
  display: flex;
  min-height: 50px;
  max-width: 220px;
  margin: 12px auto;
}

.rtwRequiredActive {
  color: #fff;
  border-radius: 5px;
  height: 35px;
  border: 1px solid #0af;
  background-color: #0bf;
  -webkit-appearance: none;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  max-height: 30px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.rtwRequiredInactive {
  color: #b7bec7;
  border-radius: 5px;
  height: 35px;
  border: 1px solid #b7bec7;
  -webkit-appearance: none;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  max-height: 30px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}
.rtwRequiredActive:last-child {
  margin-left: 8px;
}

.rtwRequiredInactive:last-child {
  margin-left: 8px;
}
.addAbsenceNoteBox {
  resize: none;
  padding: 5px;
  border: 1px solid #dde9f9;
  border-radius: 5px;
  background-color: white;
  font-size: 16px;
  overflow: auto;
  line-height: 20px;
  font-weight: 400;
  color: #496375;
  min-width: 80%;
  min-height: 100px;
  margin-top: 10px;
  margin-bottom: -20px;
}

.absenceUnit {
  background-color: white;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  padding-right: 5px;
  justify-content: space-between;
  align-items: center;
  min-height: 72px;
  max-height: 72px;
  margin-top: 8px;

  border: 1px solid #e7ebef;

  border-radius: 5px;
  cursor: pointer;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s;
}

.absenceUnit:active {
  transform: scale(0.96);
}

.absenceUnitBlueDot {
  min-width: 9px;
  min-height: 9px;
  max-width: 9px;
  max-height: 9px;
  background-color: #00ccff;
  border-radius: 20px;
  margin: 0 auto;
  margin-top: 9px;
  margin-right: 9px;
}

.absenceUnitPurpleDot {
  min-width: 9px;
  min-height: 9px;
  max-width: 9px;
  max-height: 9px;
  background-color: #9374d6;
  border-radius: 20px;
  margin: 0 auto;
  margin-top: 9px;
  margin-right: 9px;
}

.absenceUnit1 {
  min-width: 60%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 25px;
}

.absenceUnitTypeName {
  color: #143051;
  font-size: 1.05em;
  font-weight: 500;
}

.absenceDatePeriodString {
  color: #859dbb;
  font-weight: 500;
  font-size: 0.9em;
}

.absenceUnit2 {
  min-width: calc(40% - 21px);
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 20px;
}

.absenceShiftCount {
  color: #143051;
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 3px;
}
.absenceUnitDurationString {
  max-width: fit-content;
  /* padding-left: 8px;
  padding-right: 8px; */
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;
  font-weight: 500;
  font-size: 0.8em;
  padding-top: 0px;
  padding-bottom: 0px;
  color: #859dbb;
  /* margin-right: -4px; */
  margin-top: -5px;
}
.absenceUnit3 {
  min-width: 21px;
  max-width: 21px;
  margin-left: -12px;
  margin-top: -5px;
}
.absenceUserHorizLoader {
  margin-top: 20px;
}

.absenceUnitModalUnderlay {
  background: rgba(0, 0, 0, 0.25);

  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.absenceUnitModalBox {
  background-color: #f4f8fe;
  color: #143051;
  border-radius: 5px;

  min-width: 340px;
  max-width: 340px;

  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  cursor: auto;
  min-height: 350px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.mgrAbsenceUnitModalBoxBorder {
}

.absenceModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 60px;
}

.absenceModalHeaderUnit1 {
  min-width: 60px;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.absenceModalHeaderUnit3 {
  min-width: 60px;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.absenceModalHeaderUnit2 {
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.absenceHeaderTitleTxt {
  font-size: 1.2em;
  font-weight: 500;
}

.absenceModalBody {
  min-height: 100px;
  max-width: 90%;

  display: flex;
  margin: 0 auto;
  justify-content: space-between;
}

.absenceModalBodyLeft {
  background-color: white;
  text-align: left;
  max-width: 74%;
  min-width: 74%;

  padding-right: 3px;

  padding-bottom: 5px;
  min-height: 303px !important;
  max-height: 303px;
  overflow: auto;
  border-top: solid 2px #e6eaee;
  border-right: solid 1px #e6eaee;
  border-left: solid 1px #e6eaee;

  border-radius: 6px 6px 0px 0px;
  -webkit-font-smoothing: antialiased;
}

.absenceModalLeftRow {
  max-width: 86%;
  min-width: 86;
  margin: 0 auto;
  padding-top: 16px;
  padding-bottom: 16px;
}

.maxHeight50 {
  max-height: 50px;
}
.absenceModalLeftRowTitle {
  text-align: left;
  margin-left: 5%;

  font-weight: 500;
  font-size: 12px;
  margin-bottom: 6px;
  color: #859dbb;
  text-transform: uppercase;
}

.absenceModalLeftRowValue {
  text-align: left;
  margin-left: 5%;
  font-weight: 500;
  color: #496375;
  font-size: 1.05em;
  margin-top: 3px;
}

.cursorPointer {
  cursor: pointer !important;
}

.durationSmallerTxtAbsenceSpan {
  font-size: 0.85em;
}

.absenceNoteValue {
  max-height: 120px;
  overflow: auto;
  padding: 10px;
  padding-top: 4px;

  border-radius: 5px;
  margin-left: 0px;

  margin-top: 0px;
}

.noteAbsenceBorder {
  border-top: 1px solid #eef2f7;
  border-bottom: 1px solid #eef2f7;
}
.absenceModelLeftRowSplitter {
  min-height: 1px;
  max-height: 1px;
  min-width: 86%;
  max-width: 86%;
  margin: 0 auto;
  background-color: #bac5d3;
}

.absenceModalBodyRight {
  min-width: 25%;
  max-width: 25%;

  min-height: 290px;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  margin-bottom: -10px;
}

.absenceModalFooter {
  min-height: 50px;
  max-height: 50px;

  max-width: 90%;
  margin: 0px auto;
  border-top: #bac5d3 solid 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.footerLeftContainerAbsenceModal {
  display: flex;
  justify-content: center;

  align-items: center;
  min-height: 50px;
}

.cancelAbsenceBtnHolder {
  display: flex;
  min-height: 28px;
  align-items: center;
  justify-content: center;
  min-height: 30px;
  max-height: 30px;
}

.inactiveCancelHolder {
  background-color: none;
  max-width: 26px;
}

.cancelAbsenceBtnTxt {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 72px;
  padding-left: 9px;
  padding-right: 9px;
  min-height: 30px;
  max-height: 30px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
}

.closeAbsenceModalTxt {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 30px;
}

.closeAbsenceModalTxt:hover {
  background-color: #214978;
}

.rtwBtn {
  background-color: #3b597c;
  border: #214978 1px solid;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  min-width: 60px;
  max-width: 60px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 1px;
  padding-bottom: 1px;
  min-height: 30px;
  max-height: 30px;
  border-radius: 5px;
  line-height: 16px;
  font-weight: 500;
  font-size: 0.9em;
  margin: 0 auto;
}

.rtwBtn:hover {
  background-color: #214978;
}

.rtwBtnWider {
  min-width: 60px;
  max-width: 60px;
  text-align: left;
  font-size: 0.9em;
  background-color: #0bf;
  border: solid 2px #0af;
  color: white;
}

.rtwBtnWider:hover {
  background-color: #0af;
}

.page2RTWBtnBg {
  color: white;
}

.rtwTxtWider {
  margin-left: 4px;
  line-height: 15px;
  color: #fff;
}

.nonActiveRtwTxtWidth {
  min-width: 70px;
  margin-right: 5px;
  color: white;
}

.rtwBtnTxt {
  min-width: 80px;
  padding-right: 5px;
}

.rtwBtnTxtNoPad {
  min-width: 80px;
  padding-right: 0px;
}

.color140351 {
  padding-top: 1px;
  font-size: 0.9em;
}

.noPadding {
  padding: 0px;
}

.rtwDot {
  min-width: 6px;
  min-height: 6px;
  max-width: 6px;
  max-height: 6px;
  background-color: white;
  border-radius: 10px;
}

.rtwCross {
  min-width: 8px;
  margin-right: 0px;
}

.closeWhiteRtwPageImg {
  min-width: 8px;
  max-width: 8px;
}

.rtwDataContainer {
  overflow: auto;
}

.rtwAbsenceModalRow {
  max-width: 86%;
  min-width: 86;
  margin: 0 auto;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #bac5d3;
}

.rtwAbsenceModalRow:last-child {
  border: none;
}

.rtwAbsenceModalQuestion {
  font-weight: 500;
  font-size: 1em;

  text-align: left;
  margin-left: 5%;
  color: #859dbb;
  padding-right: 8px;
  margin-top: 8px;
}

.rtwAbsenceModalAnswer {
  text-align: left;
  margin-left: 5%;
  font-weight: 400;
  color: #496375;
  font-size: 1em;
  margin-top: 10px;
}

.rtwQNum {
  padding-left: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 500;
  font-size: 12px;
  max-width: fit-content;
  border-radius: 5px;
  margin-left: 2.5%;
  margin-bottom: -4px;

  color: #74aff5;
}

.absenceTimelineContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 25px;
  padding-top: 25px;
}

.absTimelineIcon {
  min-width: 60px;

  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: inset -2px 0 0 #1c3f68, inset 0 -2px 0 #1c3f68,
    inset 2px 0 0 #1c3f68, inset 0 2px 0 #1c3f68;
  margin-top: 6px;
  background-color: #ffeeee;
  cursor: pointer;
}

.absTimelineDateNumber {
  font-size: 1.45em;
  padding-top: 3px;
  letter-spacing: 1px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.absIconBlob {
  background-color: white;
  border: 2px solid #3b597c;
  color: #496375;
  font-size: 14px;
  padding-top: 1px;
  padding-bottom: 2px;
  font-weight: 500;
  min-width: 100%;
  border-radius: 5px;
}

.absMiddleTimelineBar {
  min-height: 72px;
  min-width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6px;
  cursor: pointer;
}

.absTimelineBarCircle {
  background-color: #8997a8;
  min-width: 5px;
  max-width: 5px;
  min-height: 5px;
  max-height: 5px;
  border-radius: 10px;
}

.absTimelineBarLine {
  min-height: 61px;
  min-width: 1px;
  margin-top: -1px;
  background-color: #8997ab;
}

.shortenAbsBarline {
  min-height: 20px;
  max-height: 20px;

  min-width: 1px;
  margin-top: -1px;
  background-color: #8997ab;
}

.absTimelineDur {
  background-color: #e89292;
  border: 1px solid #cd7171;
  position: fixed;
  margin-top: 22px;
  border-radius: 5px;
  font-size: 0.8em;
  font-weight: 500;
  color: white;
  padding-top: 3px;
  padding-bottom: 4px;
  min-width: 60px;
  max-width: 80px;
}

.pullAbsTimelineDurUp {
  margin-top: 14px;
}

.absenceShiftPageHeaderContainer {
  display: flex;

  max-width: 82px;
  min-width: 82px;
  justify-content: center;
  max-height: 30px;
  min-height: 30px;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 5px;
  color: #143051;
  padding-left: 3px;
  margin-left: 10px;
  margin-top: 10px;
  border: 1px #e6eaee solid;
  cursor: pointer;
}

.absenceModalShiftsTitleTxt {
  font-weight: 500;
  line-height: 28px;
  padding-right: 3px;
  margin-left: 3px;
  font-size: 1em;
}

.goBackAbsenceModalFromShiftPageImg {
  margin-left: -3px;
  margin-right: 2px;
  border-radius: 5px;
  cursor: pointer;
}

.absenceModalShiftsContainer {
  margin-left: 5%;
  overflow: auto;
  max-height: 240px;
  border-top: 2px solid #eef2f7;
  border-bottom: 2px solid #eef2f7;
  border-radius: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.absShiftGenerateUnit {
  border-bottom: 1px solid #bac5d3;
  background-color: white;
  max-width: 94%;

  margin-bottom: 8px;
  padding-top: 6px;
  padding-bottom: 10px;
  cursor: pointer;
}

.absShiftGenerateUnit:hover {
  background-color: #ebf0f7;
}

.absShiftGenerateUnit:last-child {
  border: none;
}

.absShiftUnitTopTxt {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  font-size: 1em;
  color: #496375;
  font-weight: 500;
}

.absShiftUnitTimeContainer {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 6px;
  padding-top: 3px;
  font-size: 1em;
  font-weight: 500;
  color: #859dbb;
}
.absShiftUnitTimeStart {
  font-weight: 500;
  font-size: 0.78em;
  margin-top: -5px;
}

.absShiftUnitDuration {
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;
  color: #496375;
  font-weight: 500;
  font-size: 0.8em;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: #e3e8ee;
  color: #4f566b;
}

.rtwContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: calc(100vh - 0px);
  background-color: #f4f8fe;
  padding-bottom: 30px;
}
.rtwFormUpperContainer {
  min-height: 80px;
  min-width: 100%;
  max-width: 100%;
  margin: 0 auto;

  font-size: 1.6em;
  color: #0077cc;
  font-weight: 500;
  justify-content: space-between;
  padding-top: 26px;
  text-align: center;
}
.rtwFormMidContainer {
  margin-top: 20px;
}

.closeRTWformImg {
}
.topAbsenceFormSideUnit {
  min-width: 30px;
  min-height: 30px;
  max-height: 30px;

  margin-left: 28px;
  margin-right: 28px;
}

.rtwPageKeyContainer {
  display: flex;
  min-width: 100%;

  justify-content: center;
}
.rtwKey {
  min-height: 8px;
  max-height: 8px;
  min-width: 8px;
  max-width: 8px;
  border-radius: 20px;
  background-color: #acc3e1;
  margin-left: 4px;
  margin-right: 4px;
}

.rtwKeySelected {
  background-color: #143051;
}

.rtwKeyReady {
  background-color: #0077ff;
}

.rtwBtnContainer {
  display: flex;
  justify-content: space-around;
  max-width: 320px;
  margin: 0 auto;

  max-height: 40px;
  align-items: center;
  margin-bottom: 40px;
}

.rtwQNextBtn {
  font-size: 0.9em;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 30px;
  margin-left: 25px;
}

.rtwQNextBtnSubmit {
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: 80px;
  font-size: 1em;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #0bf;
  border: 2px solid #0af;
}
.rtwBackBtn {
  min-height: 30px;
  max-height: 30px;
  min-width: 70px;
  max-width: 70px;
  text-align: center;
  font-weight: 400;
  color: #7495ba;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.rtwBtnEmpty {
  min-width: 70px;
  max-width: 70px;
}

.rtwQuestionContainer {
  min-height: 330px;
  overflow: auto;
  max-height: 330px;
  max-width: 500px;
  margin: 0 auto;
}

.rtwQuestionNumber {
  background-color: #143051;
  color: white;
  font-size: 1em;
  font-weight: 500;
  max-width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 25px;
  line-height: 25px;
  padding-top: 1px;
  border-radius: 5px;
  margin: 0 auto;
  margin-bottom: 24px;
}

.rtwQuestion {
  font-size: 1.2em;
  font-weight: 400;
  color: #143051;
  max-width: 90%;

  text-align: center;
  margin: 0 auto;
  margin-bottom: 24px;
}

.rtwTextarea {
  border: 1px solid #e6eaee;
  border-radius: 5px;
  min-width: 74%;
  min-height: 142px;
  padding: 10px;
  font-size: 1em;
  color: #214978;
  resize: none;
}

.rtwTextarea:focus {
  border: 1px solid #143051;
  border-radius: 5px;
  min-width: 74%;
  min-height: 142px;
  resize: none;
}

.rtwReviewContainer {
  min-height: 330px;
  overflow: auto;
  max-height: 330px;
  max-width: 500px;
  margin: 0 auto;
  background-color: white;
  border-top: 4px solid #eef2f7;
  border-bottom: 4px solid #eef2f7;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: auto;
  margin-bottom: 10px;
}

.rtwReviewQ {
  font-size: 1.1em;
  font-weight: 500;
  text-align: left;
  color: #143051;
  margin-top: 10px;
}

.rtwReviewA {
  color: #859dbb;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 10px;
}

.rtwReviewUnit {
  border-bottom: 1px solid #e6eaee;
  padding-bottom: 10px;
  padding-top: 8px;
}

.rtwReviewUnit:last-child {
  border: none;
}

.absenceFormAbsenceTxt {
  max-height: 35px;

  max-width: fit-content;
  margin: 0 auto;
  font-size: 0.6em;
  color: #143051;
  min-height: 35px;
  line-height: 32px;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 20px;

  margin-top: 10px;
}

.disableRTWContainer {
  opacity: 0.4;
  pointer-events: none;
}

.rtwFormAbsDropdown {
  color: #fff;
  border-radius: 25px;
  font-size: 0.6em;
  padding-left: 12px;
  text-align: left;
  max-width: 2140px;
  min-width: 140px;
  line-height: 33px;
  height: 35px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
    #7495ba;

  border: 1px solid #143051;
  -webkit-appearance: none;
  background-position-x: 119px;
  font-weight: 500;
  padding-top: 0px;
  margin: 10px auto;
}

.absenceDurationStringInlineMgr {
  display: flex;
}

.mgrModalAbsenceName {
  font-size: 0.95em;
  margin-top: 8px;
  color: #496375;
  border: 1px solid #e6eaee;
  border-radius: 5px;
  min-height: 30px;
  max-height: 30px;
  padding-left: 10px;
  font-weight: 500;
  padding-right: 10px;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: fit-content;
  margin: 0px auto;
  margin-bottom: 16px;
}

.editAbsTypeImgPencil {
  max-width: 11px;
  margin-left: 14px;
  margin-top: 0px;
  cursor: pointer;
}

.editAbsTypeDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 163px;
  min-width: 163px;
  line-height: 26px;
  height: 30px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  background-position-x: 92px;
  display: flex;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 140px;
  margin: 0 auto;
  margin-bottom: 16px;
}

.editShiftTeamDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 193px;
  min-width: 193px;
  line-height: 26px;
  height: 35px;
  /* border: 2px solid #e6eaee; */
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 2px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 170px;
}

.cancelAbsenceModalUnderlay {
  background: rgba(0, 0, 0, 0.25);

  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.cancelAbsenceModalBox {
  background-color: #f4f8fe;
  color: #143051;
  border-radius: 5px;
  min-height: 35px;
  max-width: 290px;
  min-width: 290px;
  box-shadow: 0px 0px 3px 3px rgb(0 0 0 / 10%);
  padding: 15px;
  cursor: default;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.removeAbsenceModalHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 7px;
  padding-right: 7px;
  padding-bottom: 10px;
  margin-top: 3px;
  min-width: 100%;
}

.removeAbsenceModalHeaderSideUnit {
  min-width: 15px;
  max-width: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -2px;
}

.removeAbsenceBtnContainer {
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
  padding-bottom: 30px;
}

.markShiftsAbsent {
  max-width: 208px;

  border-radius: 5px;
  font-weight: 500;
  color: #fff;
  border: 1px solid #143051;
  background-color: #0af;
  padding-top: 4px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 1em;
  display: flex;
  justify-content: space-around;
  background-color: #0bf;

  font-size: 14px;
  padding-bottom: 1px;
  cursor: pointer;
  border: 3px solid #0af;
}

.markShiftsAbsentUnsel {
  background-color: rgb(0, 0, 0, 0);

  color: #859dbb;
  border: 1px solid #e0ebf8;
  margin-top: 2px;
  margin-bottom: 2px;
}

.keepShiftsAbsentTxt {
  max-width: 140px;
  text-align: left;
  padding-top: 8px;
  padding-left: 10px;
  padding-bottom: 8px;
}

.unmarkShiftsKnob {
  margin-top: 12px;
}

.removeAbsenceModalFooter {
  display: flex;
  justify-content: space-between;

  font-size: 0.9em;
  min-height: 50px;
  align-items: center;
  max-width: 80%;
  margin: 0 auto;
}

.removeAbsenceCancel {
  min-height: 30px;

  color: #859dbb;
  font-weight: 500;
  line-height: 30px;

  border-radius: 25px;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
  font-size: 16px;
}

.removeAbsenceSubmit {
  min-height: 35px;
  background-color: #0bf;
  border: 2px solid #0af;
  color: white;
  font-weight: 500;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 14px;
  padding-right: 14px;
  cursor: pointer;
  font-size: 16px;
}

.removeAbsenceLoaderImg {
  max-width: 40px;
}

.opac5DisableUnmarkBox {
  opacity: 0.5;
  pointer-events: none;
}

.submitRemoveAbsenceAndLoaderImgContainer {
  min-width: 83px;
  max-width: 83px;
}

.noUserAbsencesContainer {
  display: flex;
  max-width: 160px;
  font-size: 0.9em;
  font-weight: 500;
  color: #acc3e1;
  margin: 30px auto;
  justify-content: space-around;
  align-items: center;
}

.noAbsencesSideBar {
  min-width: 20px;
  min-height: 2px;
  border-radius: 100px;
  background-color: #acc3e1;
}

.darkBlueBgSave {
  cursor: pointer;
  background-color: #07f;
  border: #0469de 2px solid;
}

.formNotRequiredDiv {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #95aaba;
  min-width: 60px;
  max-width: 60px;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 1px;
  padding-bottom: 1px;
  min-height: 36px;
  max-height: 36px;
  border-radius: 5px;
  line-height: 12px;
  font-weight: 500;
  font-size: 0.7em;
  margin: 0 auto;
  opacity: 0.7;
  cursor: pointer;
}

.formNotFilledInDiv {
  background-color: #e3e8ee;

  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #636f7e;
  min-width: 60px;
  max-width: 60px;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 1px;
  padding-bottom: 1px;
  min-height: 36px;
  max-height: 36px;
  border-radius: 5px;
  line-height: 12px;
  font-weight: 500;
  font-size: 0.7em;
  margin: 0 auto;
  cursor: pointer;
}

.formNotFilledInDiv:active {
  transform: scale(0.97);
}

.rtwOptionsModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;

  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.rtwOptionsModalBox {
  background-color: #858e9c;
  background-color: #f4f8fe;
  color: #143051;
  border-radius: 5px;
  min-width: 320px;
  max-width: 320px;
  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  cursor: auto;
  min-height: 210px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.rtwOptionsModalHeaderContainer {
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  align-items: center;
}

.rtwOptionsModalTitle {
  font-size: 1.2em;
  font-weight: 500;
}

.rtwOptionsModalHeaderSideUnit {
  display: flex;
  min-width: 40px;
  margin-left: 20px;
  margin-right: 20px;
}

.rtwOptionsModalBodyContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 140px;
  padding-top: 26px;
}

.closeBtnForRTWOptionsModal {
  cursor: pointer;
}

.rtwOptionsRequiredContainer {
  display: flex;
  background-color: #0bf;
  border: 2px solid #0af;
  margin: 0 auto;
  border-radius: 5px;
  max-width: 150px;
  min-width: 150px;
  justify-content: space-around;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 4px;
  padding-left: 4px;
  color: white;
  margin-bottom: 8px;
  cursor: pointer;
}

.rtwOptionsDisabled {
  background-color: #fff;
  color: #143051;
  border: 1px solid #eef2f7;
}

.requiredRtwTxt {
  text-align: center;
  font-weight: 500;
  line-height: 26px;
  padding-top: 2px;
}

.rtwRequireKnob {
  margin-top: -5px !important;
}
.knobOnRtw {
  background-color: #00ccff;
}

.sendReminderBtn {
  display: flex;

  margin: 0 auto;

  border-radius: 25px;
  max-width: 150px;
  min-width: 150px;

  min-height: 44px;
  justify-content: space-around;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;

  color: white;
  color: #143051;
  font-weight: 400;
  min-height: 70px;
  max-height: 70px;
  cursor: pointer;
}

.sentReminderBtn {
  display: flex;

  margin: 0 auto;

  border-radius: 25px;

  padding-left: 6px;
  padding-right: 6px;
  min-width: 150px;
  line-height: 20px;
  justify-content: space-around;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 35px;
  padding-right: 35px;
  color: #143051;
  font-weight: 400;
  min-height: 70px;
  max-height: 70px;
  font-size: 0.9em;
}

.hideReminderBtn {
  visibility: hidden;
  pointer-events: none;
}

.shiftsOnAbsenceHeader {
  display: flex;
  justify-content: space-between;
}

.addRemoveShiftsOnAbsenceContainer {
  font-size: 0.9em;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 104px;
}

.addRemoveShiftsOnAbsenceTxt {
  color: white;
  max-height: 24px;
  min-height: 24px;

  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 20px;
  cursor: pointer;
  border: solid 1px #0af;
  background-color: #0bf;
}

.addRemoveShiftsOnAbsenceTxt:active {
  transform: scale(0.97);
}

.absenceNoteRowHeaderContainer {
  display: flex;

  justify-content: space-between;
}

.editNoteInAbsenceRowImg {
  max-width: 14px;
  min-width: 14px;

  margin-right: 12px;
  margin-top: -2px;
}

.addNoteInAbsenceRowImg {
  max-width: 30px;
  min-width: 30px;

  margin-right: 12px;
  margin-top: -4px;
}

.editAbsenceNoteModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.editAbsNoteModalBox {
  background-color: #f4f8fe;
  color: #143051;
  border-radius: 5px;
  max-width: 320px;
  min-width: 320px;
  box-shadow: 0px 0px 3px 3px rgb(0 0 0 / 10%);
  padding: 15px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.absenceNoteEditInput {
  margin-top: 6px;
  max-width: 86%;
  min-width: 86%;
  background-color: #fff;
  border: none;

  color: #143051;
  font-size: 1em;
  font-weight: 400;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  height: 30px;
  line-height: 23px;
  min-height: 100px;
  max-height: 100px;
  overflow: auto;
  border: 1px solid #e6eaee;
  border-radius: 5px;

  resize: none;
}

.editAbsenceNoteFooterContainer {
  display: flex;
  min-width: 100%;

  justify-content: space-around;
  align-items: center;
  padding-top: 12px;
}

.editAbsenceNoteFooterUnit {
  min-width: 80px;
  max-width: 80px;
}

.saveEditAbsenceNoteBtn {
  min-height: 35px;

  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0077ff;
  color: #fff;
  font-size: 16px;
  background-color: #0bf;
  border: 2px solid #0af;
  padding-left: 14px;
  padding-right: 14px;
  min-width: 80px;
  max-width: 80px;
  font-size: 16px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
}

.disableSaveAbsenceNoteBtn {
  opacity: 0.5;
  pointer-events: none;
}

.closeEditAbsenceNoteBtn {
  color: #859dbb;
  font-weight: 500;
  font-size: 16px;
}

.navyCrossUnitOnAbsenceEditNote {
}

.removeAbsNoteBtn {
  color: #859dbb;
  font-weight: 500;
  font-size: 16px;
}

.userShiftsPage {
  min-height: 100vh;
  background-color: #ebf0f7 !important;
}

.userShiftsPageUpperContainer {
  background-color: white;
  min-height: 117px;
  max-height: 117px;
  min-width: 100%;
  max-width: 100%;
  border-bottom: 2px solid #e6eaee;
}

.teamUpperHoursColours {
  background-color: #403a8e;
  border-bottom: 1px solid #d1dfee;
}

.userShiftsUpperInnerContainer {
  min-width: 100%;
  max-width: 100%;
  min-height: 78px;
  max-height: 78px;
  padding-left: 5%;

  margin: 2px auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.mobUpper {
  align-items: center;
  min-height: 100px;
  max-height: 100px;
  margin-top: 0px;
}
.mobUpperTeamShifts {
  margin-top: 0px;
}
.userShiftsTitle {
  color: #143051;
  font-weight: 500;
  font-size: 23px;

  text-align: left;
  margin-top: 34px;
  min-width: 130px;
}

.myAvailTopRightBtn {
  color: #758aa2;
  font-weight: 500;
  margin-top: 24px;
  font-size: 0.9em;
  text-align: right;

  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  margin-right: 6%;
  border: 1px solid #758aa2;
}

.myAvailTopRightBtn:hover {
  color: white;
  font-weight: 500;
  margin-top: 33px;
  font-size: 1.1em;
  text-align: right;
  background-color: #1e3c5e;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 20px;
  cursor: pointer;
}

.shiftsMenuBar {
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
  color: #c8e8f0;
  font-weight: 500;
  max-width: 90%;
  margin: -6px auto;
}

.shiftsMenuLeft {
  width: 320px;
  min-width: 344px;
  max-width: 344px;
  display: flex;
  justify-content: space-between;
  margin-top: 11px;
}

.shiftTopMenuItem {
  margin-left: 3px;
  font-size: 16px;
  font-weight: 500;
  color: #c2cce9;
}

.shiftTopMenuItem:first-child {
  margin-left: 0px;
}

.timesheetsItem {
}

.shiftsMenuTeam {
  background-color: #a387df;
  border: 1px #8e76c1 solid;

  display: flex;
  color: #fff;
  min-height: 36px;

  padding-left: 9px;
  padding-right: 2px;
  border-radius: 5px;
  text-align: center;
  margin-left: 9px;
  margin-right: 28px;
  min-width: fit-content !important;

  max-height: 35px;
  min-height: 35px;
  margin-top: 4px;
  font-size: 16px;
  cursor: pointer;
  align-items: center;
}

.myHoursBtn {
  background-color: #1e88e5;
  border: #3677c2 1px solid;
  display: flex;
  color: #fff;
  min-height: 36px;
  padding-left: 9px;
  padding-right: 2px;
  border-radius: 5px;
  text-align: center;
  margin-left: 9px;
  min-width: fit-content !important;
  max-height: 35px;
  min-height: 35px;
  font-size: 16px;
  cursor: pointer;
  align-items: center;
  font-weight: 500;
}

.myHoursBtn:hover {
  background-color: #1f78de;
}

.myReqsBtn {
  background-color: #1e88e5;
  border: #3677c2 2px solid;
  display: flex;
  color: #fff;
  min-height: 36px;

  padding-left: 9px;
  padding-right: 2px;
  border-radius: 5px;
  text-align: center;

  min-width: 104px !important;

  max-height: 35px;
  min-height: 35px;

  font-size: 16px;
  cursor: pointer;
  align-items: center;
}

.myReqsBtn:hover {
  background-color: #1f78de;
}

.myHoursBtnMob {
  max-height: 35px;
  min-height: 35px;
  font-size: 14px !important;
  right: 35px;
    position: fixed;
    top: 61px
  /* padding-bottom:3px; */
}

.myReqsBtnMob {
  max-height: 30px;
  min-height: 30px;
  font-size: 14px !important;
}

.staffHoursBtn {
  background-color: #b49ce7;
  border: 1px solid #a47fd0;
  display: flex;
  color: #fff;
  min-height: 36px;

  padding-left: 9px;
  padding-right: 2px;
  border-radius: 5px;
  text-align: center;
  margin-left: 9px;
  margin-right: 28px;
  margin-right: 108px;

  min-width: fit-content !important;

  max-height: 35px;
  min-height: 35px;
  margin-top: 4px;
  font-size: 16px;
  cursor: pointer;
  align-items: center;
}

.minWidth164 {
  min-width: 164px !important;
}
.staffHoursBtn:hover {
  background-color: #a58fd4;
}
/* @media only screen and (min-width: 820px) and (max-width: 1140px) { */

@media only screen and (max-width: 1140px) and  (min-width: 1000px) {
  .staffHoursBtn {
margin-right:45px
  }
}

@media only screen and (max-width: 999px) and  (min-width: 521px) {
  .staffHoursBtn {
margin-right:35px
  }
}

@media only screen and (max-width: 520px) {
  .staffHoursBtn {
    position: fixed;
    top: 11px;
    right: -85px;
  }



  .myAllowsWhiteBtnMob {
    border: 1px solid #fcfdff;
    background-color: #fff;

    top: 40px !important;
    right: 105px !important;
  }



  .x010842081 {
    top:25px;
  }
}

.teamHrsBtn {
  color: white;
  font-weight: 500;
  margin-right: 12px;
  font-size: 14px;
  /* margin-top:1px; */
}

.myHoursTxtBtn {
  margin-top: -1px;
  margin-left: 2px;
}

.teamHoursTxtBtn {
  padding-right: 7px;
  margin-top: -1px;
  min-width: fit-content !important;
}

.shiftsMenuTeam:hover {
  background-color: #8e76c1;
}

.statsBtn {
  background-color: #07f;
  border: #0469de 1px solid;
  display: flex;
  color: #fff;
  min-height: 36px;
  line-height: 34px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  align-items: center;

  min-width: 80px;
  max-height: 35px;
  min-height: 35px;
  margin-top: 4px;
  font-size: 16px;
  cursor: pointer;
}

.statsBtn:hover {
  background-color: #0b74ef;
}

.hoursStatsBtn {
  background-color: #fff;
  border: 1px solid #e6eaee;
  display: flex;
  color: #859dbb;
  min-height: 36px;
  line-height: 34px;
  padding-left: 6px;
  padding-right: 2px;
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-width: 58px;
  max-height: 35px;
  min-height: 35px;
  font-size: 16px;
  cursor: pointer;
  position: fixed;
  right: 150px;
  top: 62px;
}

.hoursStatsBtn:hover {
  border-color: #d7dfe9;
  background-color: #fff;
}

.reqsStatsBtn {
  background-color: #fff;
  border: #fff 1px solid;
  display: flex;
  color: #859dbb;
  min-height: 36px;
  line-height: 34px;
  padding-left: 8px;
  padding-right: 4px;
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  align-items: center;

  min-width: 80px;
  max-height: 35px;
  min-height: 35px;
  font-size: 16px;
  cursor: pointer;
  position: fixed;
  right: 114px;
  top: 62px;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 20%);
}

.reqsStatsBtn:hover {
  border-color: #e3e8ee;
}

.staffReqsBtn {
  min-width: 69px !important;
  margin-top:18px;
}

.staffReqsStatsBtn {
  right: 154px !important;
}

@media only screen and (min-width: 820px) {
  .staffReqsBtn {
    margin-top:23px;
  }

  .teamCostInSh:hover {
    opacity: 0.8;
  }

  .markAbsShiftItem:hover {
    border-color:#bbcada
  }
.markAbsPeriodBtn:hover {
  background-color: #97a1ea;
}

}

@media only screen and (max-width: 520px) {
  .hoursStatsBtn {
    right: 138px;
    top: 29px;
  }

  .reqsStatsBtn {
    right: 103px;
    top: 39px;
  }
}

@media only screen and (max-width: 460px) {
  .hoursStatsBtn {
    min-width: 40px;
    padding-left: 7px;
  }

  .reqsStatsBtn {
    min-width: 40px;
    padding-left: 7px;
  }
}

.teamStatsBtnStyle {
  background-color: #fff;
  color: #859dbb;
  top: 62px;
  right: 145px;
  z-index: 999999;
  min-width: 68px;
  padding-left: 8px;
}

.teamStatsBtnStyle:hover {
  border-color: #d7dfe9 !important;
}

.teamStatsBtnStyleMob {
  min-height: 35px;
  max-height: 35px;
  top: 31px !important;
  font-size: 14px !important;
  padding-left: 6px !important;
  padding-right: 0px !important;
  max-width: 67px !important;
  min-width: 67px !important;
  background-color: #9a74eb !important;
  border: 1px solid #965ade !important;
  color: white;
}

.teamStatsReqsBtnStyleMob {
  min-height: 30px;
  max-height: 30px;
  top: 27px !important;

  padding-left: 5px !important;
  padding-right: 0px !important;
  max-width: 78px !important;
  min-width: 78px !important;
  right: 150px !important;
  z-index: 10;
}

.teamStatsBtnStyle2 {
  top: 62px !important;
  right: 136px !important;
  min-height: 35px !important;
  max-height: 35px !important;
  min-width: 120px !important;
}


@media only screen and (max-width: 1000px) {
  .teamStatsBtnStyle {
    right: 141px;
  }
}
@media only screen and (max-width: 819px) {
  .teamStatsBtnStyle {
    right: 142px;
    top: 61px !important
  }
}

@media only screen and (max-width: 548px) {
  .teamStatsBtnStyle {
    top: 29px !important;
    right: 126px;
  }

  .myHoursBtnMob {
    top: 29px;
    right: 24px;
  }

  .teamStatsBtnStyle2 {
    top: 8px;
    right: 131px;
  }

  .reqsHeaderTeamRightContainer {
    top: 11px !important;
  }
}

@media only screen and (max-width: 420px) {
  .statsBtn {
    min-width: 32px;
    max-width: 32px;
  }
}

.teamImg {
  max-width: 14px;
  margin-left: 0px;
  margin-right: 4px;
  padding-right: 1px;
}

.statsImg {
  max-width: 14px;
  min-width: 14px;
  margin-left: 1px;
  margin-right: 5px;
}

.statsBtnTxt {
  font-weight: 500;
  font-size: 14px;
  min-height: 100%;
  padding-left: 0px;
  cursor: pointer;
  margin-right: 2px;
  color: #8ea7c5;
}

.statsBtnTxtTeam {
  /* color: #9a74eb; */
  font-weight: 500;
}
.absenceMenuRight {
  margin-right: -4px;

  display: flex;
}

.teamDropTitle {
  max-width: 80px;
  position: absolute;
  top: 92px;
  right: 202px;
  font-weight: 500;
  z-index: 999;
  font-size: 14px;
  color: #6a8bb1;
}

.fullTeamDropTitle {
  right: 272px;
}
@media only screen and (max-width: 840px) {
  .teamDropTitle {
    display: none;
  }
}
.teamDropdownContainer {
  min-width: 170px;
  min-height: 30px;
  max-width: 170px;

  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 145px;
  font-weight: 500;
  font-size: 14px;
  padding-left: 10px;
}

.teamDropdownContainer:hover {
  border-color: #d7dfe9;
}

.pushTeamDropDown {
  margin-top: 30px;
}

.pushTeamDropLeft {
  margin-right: 0px;
  margin-top: -6px;
}

.fullTeamDrop {
  min-width: 240px;
  background-position-x: 215px;
}
.pullTeamBtnsUp {
  margin-top: -50px;
}

.absenceTeamBtnTxt {
  font-size: 0.9em;

  padding-right: 3px;
}

.purpleBg {
  background-color: #fff;
}

.hardPurpleBg {
}
.addShiftBtnOnTeamShifts {
  position: fixed;
  top: calc(100% - 188px);
  right: 45px;
  /* background-color: #62248c; */
  /* border: 1px solid #62248c; */
  background-color: #a4afff;
  border: 1px solid #8a98fc;
  color: #fff;
  border-radius: 5px;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  line-height: 42px;
  font-size: 2.5em;
  z-index: 99999;
  cursor: pointer;
  /* opacity: 0.8; */
}

.addShiftBtnOnTeamShifts:hover {
  background-color: #8a98fc;
  /* transform: scale(0.97); */
}

.reqMenuTxtSelected {
  font-weight: 500;
  opacity: 1;
  cursor: default;
  color: #143051;
}

.reqMenuTxtSelected:hover {
  color: #143051;
}

.reqMenuTxtSelected:active {
  transform: scale(1);
}

.addShiftBtnOnTeamShifts:active {
  transform: rotate(90deg);
}

.teamShiftsPage {
  background-color: #ebf0f7;
  min-height: calc(100vh - 219px);
  max-height: calc(100vh - 219px);

  max-width: 100%;
  min-width: 100%;

  z-index: 0;
  position: relative;
  padding-top: 8px;
  overflow: auto;
  padding-bottom: 20px;
  border-right: 1px solid #e0ebf8;
  border-left: 1px solid #e0ebf8;
}

.shiftsTeamBar {
  display: flex;

  min-width: calc(100% - 200px);
  max-width: calc(100% - 200px);
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: none;
  padding-right: 35px;
  min-height: 30px;
  padding-bottom: 1px;
  align-items: center;
  color: white;
  padding-left: 8px;
  margin-top: -7px;
  margin-left: 15px;
}

.myRotaTeamBar {
  margin-top: -7px;
}

.shiftsTeamBarMob {
  margin-top: -22px;
}

.shiftsMenuBarMob {
  margin-top: -24px;
}

.fontSizeMobTeamHoursMenu {
}

.shiftsTeamBar::-webkit-scrollbar {
  width: 5px !important;
  height: 4px !important;
}

.fadedPngOverTeams {
  position: fixed;
  top: 96px;
  right: calc(5% - 1px);
  pointer-events: none;
}

.teamShiftsTitleContainer {
  display: flex;
  max-height: 30px;
  font-size: 28px;
  margin-left: -12px;
  align-items: center;
  margin-bottom: 3px;

  min-width: fit-content;
}

.teamShiftsTitleContainerMob {
  margin-top: 11px;
  margin-left: 5px;
}
.teamAvailAndSwapsBtns {
  display: flex;

  align-items: flex-end;

  justify-content: space-around;

  margin-right: 3%;
}

.rotaPage {
  background-color: #ebf0f7;
  min-width: 100vw;
  min-height: 100vh;
  color: black;

  min-height: calc(100vh - 50px);
  max-height: calc(100vh - 50px);
}

.mobHeightMyRota {
  min-height: calc(100vh - 85px);
  max-height: calc(100vh - 85px);
  border-left: none;
}

.myRotaMainUpper {
  background-color: white;
  min-width: 100%;
  min-height: 125px;
  max-height: 125px;
  border-left: solid 1px white;
  border-bottom: 1px solid #e6eaee;
}

.rotaBody {
  border-left: 1px solid #e6eaee;
  min-height: calc(100vh - 174px);
  max-height: calc(100vh - 174px);
}

.mobRotaBodyHeight {
  min-height: calc(100vh - 210px);
  max-height: calc(100vh - 210px);
  border-left: none;
}

.teamAvailAndSwapsBtnsMob {
  align-items: flex-end;
  flex-direction: column;
}

.teamAvailBtn {
  color: #31537a;

  min-width: fit-content;
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 1em;
  font-weight: 500;
  border-radius: 30px;
  min-height: 26px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 26px;
}

.swapBtnItem {
  display: flex;
  background-color: #fff;

  color: #31537a;
  max-width: 80px;
  padding-left: 12px;
  padding-right: 12px;
  justify-content: space-around;
  border-radius: 5px;
  margin: 0 auto;

  animation-name: zoomModalIn !important;
  animation-duration: 0.125s !important;
  cursor: pointer;
  border: 1px solid #95aaba;
}

.swapBtnItem:hover {
  border-color: #74aff5;
  border-width: 2px;
  background-color: #fafeff;
}

.availBtnItem {
  display: flex;
  background-color: #fff;

  max-width: 117px;

  padding-left: 12px;
  padding-right: 12px;
  justify-content: space-around;
  border-radius: 5px;
  margin: 0 auto;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  cursor: pointer;
  border: 1px solid #95aaba;
}

.availBtnItem:hover {
  border-color: #74aff5;
  border-width: 2px;

  background-color: #fafeff;
}

.teamSwapsBtn {
  color: #1964ba;

  min-width: fit-content;
  text-align: center;
  padding-top: 4px;
  padding-bottom: 3px;
  font-size: 0.9em;
  font-weight: 500;
  border-radius: 5px;
  min-height: 26px;

  line-height: 25px;
}

.closeSwapsImg {
  margin-left: 6px;
  min-width: 9px;
}

.swapsBtnHolder {
  max-width: 80px;
  min-width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 14px;
}

.availBtnHolder {
  max-width: 110px;
  min-width: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 64px;
  right: 5vw;
}

.mobAvailBtn {
  top: 30px;
  right: 5vw;
}

.availSwapsActiveBg {
  color: #fff;
  border: 1px #58467e solid;
  border: 1px #58467e solid;
  background: rgb(0, 119, 255);
  background: linear-gradient(
    0deg,
    rgba(0, 119, 255, 1) 0%,
    rgba(65, 154, 255, 1) 100%
  );
  animation-name: zoomBtnIn !important;
  animation-duration: 0.1s !important;
}

.teamShiftsTitle {
  color: #9576cc;
  font-weight: 500;
  font-size: 28px;

  text-align: left;

  position: fixed;
  top: 47px;
  left: 186px;
  display: flex;
}

.teamShiftsTitleMob {
  left: 18px;
  top: 39px;
}

.myRotaTitle {
  color: #143051;
  font-weight: 500;
  font-size: 28px;

  text-align: left;

  position: fixed;
  top: 46px !important;
  left: 181px;
}

.myRotaTitleMob {
  left: 5%;
  top: 38px !important;
}

.x392841 {
  top: 46px !important;
}

.addTilOrShiftOptionsUnderlay {
  background: rgba(0, 0, 0, 0.7);
  min-height: calc(100% - 58px);
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.addShiftBtnOnTeamShiftsOnOverlay {
  position: fixed;
  top: calc(100% - 188px);
  right: 45px;
  /* background-color: #0077ff; */
  background-color: #9374d6;
  border: 1px solid #6e4eb2;

  color: #fff;
  border-radius: 5px;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  line-height: 44px;
  font-size: 2.5em;
  z-index: 1000;

  overflow: hidden;
  transition-duration: 0.05s;
  transition-property: transform;
  cursor: pointer;
}

.diagPlus {
  transform: rotate(45deg);
  overflow: hidden;
  transition-duration: 0.5s;
  transition-property: transform;
  margin-top: -1px;
  margin-left: 5px;
}

.addTilShiftBtnsContainer {
  display: flex;
  flex-direction: column;
  min-height: 205px;
  min-width: 200px;
  position: fixed;
  top: calc(100% - 348px);
  right: 110px;
  justify-content: space-between;
  align-items: flex-end;
}

.addTilBtn {
  background-color: white;
  min-width: fit-content;
  max-width: fit-content;
  padding-left: 17px;
  padding-right: 17px;

  font-size: 1.1em;
  font-weight: 500;
  color: #214978;
  margin-bottom: 0px;
  /* border: 1px solid #bbcada; */
  min-height: 40px;
  max-height: 40px;
  line-height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.addTilBtn:hover {
  background-color: #f4f8fe;
}

.addTilModalBox {
  background-color: #f4f8fe;
  color: #496375;
  min-width: 340px;
  max-width: 340px;
  border-radius: 5px;
  text-align: left;
  line-height: 45px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  cursor: default;
}

.selectTeamOnAddTilDropdown {
  max-width: 100px;
  background: url("../img/general/downArrowWhite.svg") no-repeat right #687d97;
  -webkit-appearance: none;
  background-position-x: 75px;
  border-radius: 100px;
  color: white;
  padding-left: 14px;
  font-size: 1em;
  text-align: center;
  height: 35px;
  border: 1px solid #36495f;
  font-weight: 500;
  padding-top: 2px;
}

.tilDurationTxt {
  font-size: 1.5em;
  color: #abbed3;
  margin-top: -8px;
}

.thisDaysShiftTitleTxt {
  font-size: 1.07em;
}

.suggestedTimesOnAddTil {
  font-size: 1em;
  font-weight: 400;
  margin-top: -14px;
}

.startInputHolder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 80px;
  margin-top: 10px;
}

.tilSuggestTxt {
  color: #07f;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: 500;
}

.tilSuggestDiv {
  max-height: 24px;
  line-height: 24px;
  font-size: 0.8em;
  margin-top: 6px;
  margin-bottom: -12px;
  margin-left: -4px;
  border: 1px solid #eef2f7;
  background-color: #f4f8fe;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.tilSuggestTxtSpan {
  color: #214978;
  padding-left: 12px;
}

.addShiftTimeLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 90px;
  max-height: 90px;
}

.addShiftTimeRight {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 80px;
  max-height: 80px;
  margin-top: 10px;
  margin-left: 20px;
}

.breakMinsSpanAddOvertime {
  font-size: 0.8em;
}

.showOvertimeRightHeight {
  min-height: 192px;
  max-height: 192px;
}

.addTilDateInput {
  border-radius: 5px;

  border: none;
  background-color: #fff;
  color: #496375;
  min-height: 35px;
  line-height: 35px;
  font-size: 0.9em;
  padding-left: 14px;
  padding-right: 8px;
  letter-spacing: 1px;
  padding-top: 1px;

  font-weight: 500;
  max-width: 174px;
  min-width: 174px;
  background: url("../img/general/calicongrey.svg") no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 142px;
  border: 1px solid #dde9f9;
  margin-left: -5px;
  margin-top: 8px;
}
.overtimeBreakAndRateContainer {
  display: flex;
  min-width: 90%;
  max-width: 80px;
}
.tilDurTopRight {
  padding-top: 1px;
}

.overtimeBrkInput {
  background-color: #fff;
  width: 57px;
  max-width: 77px;

  line-height: 30px;
  padding-top: 0px;
  height: 30px;
  border: 1px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 0.9em;
  font-weight: 500;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-left: -22px;
}

.tilOverlapModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.overlapMsgTxt {
}

.tilOverlapModalBox {
  background-color: #858e9c;
  background-color: #f4f8fe;
  color: #496375;
  border-radius: 5px;
  min-height: 35px;

  min-width: 290px;
  max-width: 290px;

  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  cursor: auto;
  min-height: 150px;
}

.tilOverlapTimesContainer {
  display: flex;
  max-width: 140px;
  justify-content: space-around;
  margin: 24px auto;
  align-items: center;
  padding-top: 3px;
}

.tilOverlapTimeTxt {
  font-weight: 500;
  color: #143051;
  font-size: 1.2em;
}

.tilOverlapTimeTxtTo {
  font-size: 0.9em;
  color: #859dbb;
}

.tilOverlapMessagesContainer {
  max-width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  line-height: 24px;
  padding-bottom: 18px;
  padding-top: 8px;
}

.overlapTilBtnClose {
  background-color: #2e4657;
  max-width: fit-content;
  padding-left: 15px;
  padding-right: 15px;
  color: white;
  min-height: 30px;
  line-height: 28px;
  padding-bottom: 1px;
  border: 1px solid #143051;
  border-radius: 5px;
  margin: 18px auto;
  font-weight: 500;
  margin-bottom: 25px;
  cursor: pointer;
}

.loadExistingFade {
  opacity: 0.4;
  pointer-events: none;
}

.understoodBtnTil {
  background-color: #00ccff;
}

.alignMiddlePage2 {
}

.shiftsBodyContainer {
  background-color: #ebf0f7;

  min-height: calc(100vh - 272px);
  max-height: calc(100vh - 272px);
  overflow: auto;
  position: fixed;
  min-width: 100%;

  padding-top: 8px;
  padding-bottom: 68px;
}

.shiftsBodyContainerDesktop {
  min-width: calc(100vw - 600px);
  max-width: calc(100vw - 600px);

  min-height: calc(100vh - 236px);
  max-height: calc(100vh - 236px);
  border-left: 1px solid #e6eaee;
}

.shiftsContainerNonFullNonMobWithFilt {
  min-width: calc(100vw - 240px);
  max-width: calc(100vw - 240px);

  min-height: calc(100vh - 219px);
  max-height: calc(100vh - 219px);
  border-left: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;

  min-height: calc(100vw - 240px);
  max-height: calc(100vw - 240px);
}

.shiftsContainerNonFullNonMobWithoutFilt {
  min-width: calc(100vw - 241px);
  max-width: calc(100vw - 241px);

  border-left: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;

  min-height: calc(100vw - -151px);
  max-height: calc(100vw - 151px);
}

.shiftsContainerMobOnly {
  min-height: calc(100vh - 309px);
  max-height: calc(100vh - 309px);
  padding-top: 8px !important;
}

.showFilterMobOnlyShiftsContainer {
  min-height: calc(100vh - 351px);
  max-height: calc(100vh - 351px);
  padding-top: 8px !important;
}

.absencePageContainerDesktop {
  max-width: calc(100vw - 240px);
  min-width: calc(100vw - 240px);
  min-height: calc(100vh - 180px);
  max-height: calc(100vh - 180px);
  border-left: 1px solid #e6eaee;
}

.loadingHoriztonalImg {
  max-width: 120px;
  min-width: 120px;

  margin: 30px auto;
}

.tilShiftUnit {
  background-color: white;
  max-width: 90%;
  min-width: 90%;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 72px;
  max-height: 72px;

  border-radius: 5px;
  cursor: pointer;

  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  border: 1px solid #dee5f0;

  padding: 1px;
  padding-left: 0px;
  /* border: none; */
}

.myTilShiftUnit {
  /* margin: 6px auto; */
}

.teamTilShiftUnit {
  margin-top: 18px !important;
}

.tilShiftUnit:hover {
  background-color: #fff;
  border: 1px solid #bbcada;
  /* padding: 0px; */
}

.tilShiftUnit:first-of-type {
  /* margin-top: 14px; */
}
.clockUnit {
  background-color: white;
  max-width: 90%;
  min-width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 72px;
  max-height: 72px;
  margin-top: 15px;

  border-radius: 5px;
  cursor: pointer;
  /* animation-name: zoomModalIn !important; */
  /* animation-duration: 0.1s !important; */
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  border: 1px solid #dee5f0;
  padding: 1px;
}

@media only screen and (min-width: 820px) {

.clockUnit:hover {
  /* border-top: 1px solid #bbcada;
  border-right: 1px solid #bbcada;
  border-bottom: 1px solid #bbcada;
  */
  border-color: #bbcada !important;
  /* padding: 0px; */
  /* padding-left: 1px !important; */
}

}
.mgrClockUnitWrapperOpen {
  min-height: 141px;
  margin-top: 24px;
  /* margin-top: 20px; */
  margin-bottom: -10px;
  /* animation-name: zoomModalIn !important; */
  /* animation-duration: 0.1s !important; */
  /* background: orange; */
  /* transition: transform 0.1s ease-in-out; Adjust the duration as needed */

}

.mgrClockUnitWrapperOpen:first-of-type {
  margin-top: 20px;
}

.mgrClockUnitWrapperClosed {
  min-height: 101px;
  margin-top: 14px;
  /* animation-name: zoomModalIn !important; */
  /* animation-duration: 0.1s !important; */
}
.mgrClockUnitWrapperClosed:first-child {
  margin-top: 0px;
}
.mgrClockUnit {
  background-color: white;
  max-width: 90%;
  min-width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 72px;
  max-height: 72px;
  margin-top: 8px;

  border: 1px solid #e7ebef;

  border-radius: 5px;
  cursor: pointer;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
}

.mgrClockUnitPending:hover {
  /* mitel */
  background-color: #fff;
  border: 2px dashed #214978 !important;
  padding: 0px;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
}

.mgrClockUnitNonPending:hover {
  border-color: #bbcada
}
.noBorderRadiusBottomRight {
  border-radius: 5px 5px 0 5px;
}

.noBorderRadius {
  border-radius: 0px !important;
}

.clockNameAndTeamDiv {
  display: flex;
  justify-content: space-between;

  min-height: 30px;
  margin-bottom: -16px;
}

.mgrClockNameDiv {
  background-color: rgb(255, 255, 255);
  border: 1px solid #e7ebef;
  border-bottom: none;

  max-width: fit-content;
  margin-left: 10%;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 6px;
  padding-right: 9px;
  font-size: 11px;
  font-weight: 500;
  color: #496375;
  border-radius: 5px 5px 0 0;
  font-weight: 500;
line-height:12px;
text-align: left;
}

.clockTeamNameTopRight {
  margin-left: 0px;
  margin-right: 13%;
}

.mgrClockSubBar {
  border: solid 1px rgb(214, 225, 234);
  border-top: none;
  background-color: #fafbfd;
  max-width: 85%;
  margin-left: 10%;
  min-height: 47px;
  max-height: 47px;
  border-radius: 0px 0px 5px 5px;
  /* animation-name: slideClockSubBarDown !important; */
  /* animation-duration: 0.1s !important; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mgrClockSubBarMob {
  max-width: 83%;
  border-radius: 0px 0px 15px 15px;

}

.mgrClockNameShield {
  background-color: white;
  margin-left: 10%;

  min-height: 2px;
  max-height: 2px;
  margin-left: calc(10% + 1px);
  font-size: 14px;
  font-weight: 500;
  max-width: fit-content;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: -73px;
}

.mgrClockNameShieldMob {
  background-color: white;
  margin-left: 10%;

  min-height: 2px;
  max-height: 2px;
  margin-left: calc(10% + 1px);
  font-size: 14px;
  font-weight: 500;
  max-width: fit-content;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: -113px;
}

.mgrClockNameShieldMobSelected {
  background-color: white;
  margin-left: 10%;

  min-height: 2px;
  max-height: 2px;
  margin-left: calc(10% + 1px);
  font-size: 14px;
  font-weight: 500;
  max-width: fit-content;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: -113px;
}

.pullFirstMobClockUnitUp {
  margin-top: 20px;
}

@keyframes slideClockSubBarDown {
  from {
    min-height: 0px;
    max-height: 0px;
  }
  to {
    min-height: 40px;
    max-height: 40px;
  }
}

.desktopMgrClockDefaultCursor {
  cursor: default;
}

.tagsOnShiftExtend {
  min-height: 92px;
  max-height: 92px;
}

.teamShiftTagsContainer {
  display: flex;
  margin-top: 4px;
  min-width: fit-content;
  padding-right: 20px;
  margin-left:9%;
  margin-bottom:7px;
}

.tilShiftUnit:active {
  /* transform: scale(0.98); */
}

.teamShiftTagItem {
  color: #fff;
  font-size: 11px;
  text-align: left;
  margin-right: 2px;
  padding-left: 5px;
  padding-right: 6px;
  min-width: -moz-fit-content;
  min-width: fit-content;
  border-radius: 5px;
  font-weight: 500;
  /* background-color: #143051; */
  /* background-color: rgb(247, 250, 255); */
  background-color: #f4f8fe;
  color: #4f566b;
  padding-top: 0px;
  line-height: 19px;
  max-height: 24px;
  max-height: 23px;
  border: 1px solid #d2d8df;
}

.teamShiftTagsSurplusIndicator {
  color: #fff;
  font-size: 0.7em;
  text-align: left;
  margin-left: 0px;
  min-width: fit-content;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 5px;
  font-weight: 500;
  /* background-color: #f4f8fe; */
  line-height: 17px;
  max-height: 18px;
  margin-top: 3px;
  background-color: #f4f8fe;
  color: #496375;
  border: 1px solid #d2d8df;

  padding-top: 0px;
  line-height: 14px;
}
.tilShiftSection0 {
  min-width: 10%;
  text-align: left;
  display: flex;
  margin-right: -14px;
  padding-left: 10px;
  align-items: center;
  justify-content: center;
}

.unclockedSectionLeft0 {
  min-width: 10%;
  text-align: left;
  display: flex;
  margin-right: -14px;
  padding-left: 17px;
  align-items: center;
  justify-content: center;
}

.myShiftWorkedDot {
  background-color: #a8cc9a;
  min-width: 10px;
  max-width: 10px;
  min-height: 10px;
  max-height: 10px;
  border-radius: 10px;
}

.myShiftUnWorkedDot {
  background-color: #e0ebf8;
  min-width: 6px;
  max-width: 6px;
  min-height: 6px;
  max-height: 6px;
  border-radius: 10px;
  display: none;
}

.clockCardTopRightPlacer {
  min-width: 28%;
  margin-right: 20px;
}

.myShiftWorkedDotAbsent {
  background-color: #e89292;
  min-width: 10px;
  max-width: 10px;
  min-height: 10px;
  max-height: 10px;
  border-radius: 10px;
}

.myShiftWorkedDotAbsentPartial {
  background: linear-gradient(66deg, #e89292 50%, rgba(172, 195, 225, 1) 50%);
  min-width: 10px;
  max-width: 10px;
  min-height: 10px;
  max-height: 10px;
  border-radius: 10px;
}

.myShiftGhostDot {
  background-color: #0077ff;
  min-width: 10px;
  max-width: 10px;
  min-height: 10px;
  max-height: 10px;
  border-radius: 10px;
}

.tilShiftSection1 {
  min-width: 70% !important;
  text-align: left;
  display: flex;
  flex-direction: column;

  padding-left: 7%;
  justify-content: flex-start;
}

.tilShiftSection1Clock {
  min-width: 51% !important;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding-left: 7%;
  justify-content: flex-start;
  /* border-left: 2px dotted #5a9e3f; */
  margin-left: 12px;
}

.clockedOnSection1 {
  min-width: 61% !important;
  text-align: left;
  display: flex;
  flex-direction: column;
  /* padding-left: 7%; */
  justify-content: flex-start;
  /* border-left: 2px dotted #5a9e3f; */
  /* margin-left: 12px; */
}

.declinedClockBar {
  /* border-left: 2px dotted #ba0000; */
}

.pendingClockBar {
  /* border-left: 2px dashed #bbcada; */
}
.pendingClockSection1 {
  /* background-color: blue; */
  margin-left: 6px;
  border: none !important;
}

.pendingClockSection2 {
  padding-right: 22px !important;
}

.tilShiftSection1Unclock {
  text-align: left;
  display: flex;
  flex-direction: column;
  padding-left: 7px;
  justify-content: flex-start;
  border-left: 1px solid #bfccdc;
  margin-left: 18px;
  margin-top:-13px
}

.tilShiftSection1MultiSelected {
  min-width: 54% !important;
  margin-right: -11px;}

.myHoursShiftSecion1 {
  min-width: 60%;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding-left: 7%;
  justify-content: flex-start;
  /* border-left: 2px dotted #5a9e3f; */
  margin-left: 11px;
}

.mobMyHoursShiftSecion1 {
  padding-left: 13px;
  margin-left: 10px;
}

.tagsMobMyHoursShiftsSection1 {
  /* padding-left: 3%; */
  /* margin-left: 3%; */
  min-width: 165px !important;
  /* background: yellow; */
}

.teamClockSection1 {
  min-width: 60%;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  margin-left: 12px;
  justify-content: flex-start;
  /* border-left: 2px dotted #5a9e3f; */
  margin-left: 20px !important;
  padding-left: 10px !important;
}

.leftRedBorder {
  border-left: 2px dotted #ba0000;

}



.desktopUnlinkedTeamClockSection1 {
  margin-left: 5%;
}
.desktopTeamClockSection1 {
  padding-left: 5%;
}

.tilShiftSection2 {
  min-width: calc(40% - 0px);
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 34px;
}

.myHoursShiftSecion2 {
  min-width: calc(85% - 200px);
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 0px;
}

.teamClockSection2 {
  min-width: calc(31% - 0px);
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 22px;
}

.mobTeamClockSection2 {
  padding-right: 18px;
}

.clockSection2 {
  min-width: calc(32% - 0px);
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 13px;
}

.desktopClockSection2 {
  margin-right: -22px !important;
}
.tilShiftSection1team {
  min-width: 166px;
  text-align: left;
  display: flex;
  flex-direction: column;

  padding-left: 5%;

  justify-content: flex-start;
  /* border-left: 2px dotted #214978; */
  margin-left: 10px;
}

.tilShiftSection1teamDesktop {
  margin-left:16px
}

.marginLeft16px {
  margin-left:16px 
}

.tilShiftSection2team {
  min-width: 38%;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 18px;
  /* margin-bottom:10px; */
  /* margin-top:-21px */
}

.tilShiftUnitName {
  color: #143051;
  margin-bottom: 2px;
  font-weight: 600;
  font-size: 14px;
  display: flex;
}
.tilShiftDs {
  color: #143051;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  min-height: 26px;
  line-height: 22px;
  justify-content: space-between;
  min-width: 124px;
  padding-top: 2px;
  margin-top: 0px;
  margin-bottom: -1px;
}

.unclockedDsString {
  color: #8ea7c5;
  display: flex;
  align-items: flex-end;
  font-weight: 500;
  font-size: 12px !important;
  min-height: 35px;
  line-height: 16px;
  padding-bottom: 2px;
  padding-left:2px;
  /* padding-top: 2px; */
  border-bottom: 1px solid #dde9f9;
}

.tilShiftDsTeam {
  color: #95aaba;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  border-top: 1px solid #e0ebf8;
  padding-left: 3px;
  padding-top: 2px;
}

.tilShiftTimes {
  color: #95aaba;
  font-weight: 600;
  font-size: 11px;
  padding-right:5px;
  max-width: fit-content;
  min-width: fit-content;
  margin-top: 2px;
  display: flex;
  align-content: center;
  /* padding-right: 4px; */
}

.tilShiftTimes2 {
  color: #95aaba;
  font-weight: 500;
  font-size: 13px;
  max-width: fit-content;
  min-width: fit-content;
  margin-top: 4px;
  padding-left: 8px;
}

.unclockedShiftTimes {
  color: #8ea7c5;
  font-weight: 500;
  padding-left:2px;
  font-size: 11px;
  max-width: fit-content;
  min-width: fit-content;
  display: flex;
  align-items: center;
  /* margin-top: 4px; */
  /* padding-left: 8px; */
}

.borderLeftTilStrip {
  border-left: 1px solid #e0ebf8;
  color: #95aaba;
  padding-left: 8px;
  margin-left: 10px;
}

.smallerTxtTimeTil {
  font-size: 10px;
  max-width: 82px;
  line-height: 11px;
  font-weight: 500;
  margin-top: 1px;
  min-width: 82px;
  /* background: yellow; */
}

.smallerTxtTimeTilTeamStrip {
  font-size: 12px;
  padding-right: 0px !important;
  /* margin-left: 10px; */
  /* padding-left: 5px !important; */
  margin-top: 1px;
  max-width: 96px !important;
  min-width: 86px !important;
  line-height: 15px;
}

.myShiftsUnitDurationSubTxt {
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content !important;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  align-items: center;
  border-radius: 5px;
  color: #859dbb;
  font-weight: 500;
  font-size: 12px;
  padding-top: 0px;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 9px;
}

.teamClockBreakDurStrip {
  max-width: fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content !important;
  max-width: 110px;
  padding-left: 4px;
  font-weight: 500;
  font-size: 12px;
  padding-top: 0px;
line-height:11px;  display: flex;
  align-items: center;
  justify-content: center;
  color: #9374d6;
  margin-left: 9px;
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
}

.unclockedBreakDurStrip {
  max-width: fit-content;
  min-width: fit-content;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: space-between;
  max-height: 22px;
  min-height: 22px;

  align-items: center;
  border-radius: 5px;
  color: #496375;
  font-weight: 500;
  font-size: 12px;
  background-color: #e3e8ee;
  color: #4f566b;
}

.makeSubTxtSmaller {
  font-size: 0.7em;
  color: #143051;
  color: #0077ff;
}
.tilShiftTimesTeam {
  color: #acc3e1;

  font-size: 0.93em;
  padding-left: 9px;
  margin-left: 9px;
  border-left: 1px solid #bac5d3;
}

.tilShiftDurationTxt {
  font-size: 0.9em;
  margin-top: 0px;
  background-color: #acc3e1;

  color: white;

  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 15px;

  max-height: 24px;
  line-height: 16px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;

  font-weight: 400;
}

.tilShiftDurationTxtTeam {
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #e6eaee;
  color: #fff;
  font-weight: 500;
  font-size: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #214978;

  font-size: 10px;
  font-weight: 500;
  background: #f4f8fe;
  border-radius: 5px;
  border: 1px solid #bbcada;
  color: #516b8b;

}

.absentSubTxtBarShifts {
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #859dbb;
  color: #fff;
  font-weight: 500;
  font-size: 0.8em;
  padding-top: 2px;
  padding-bottom: 2px;
  background: rgb(247, 250, 255);
  border: 1px solid #e4cccc;
  background-color: #f2d8d2;
}

.partialAbsentSubTxtBarShifts {
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;

  color: #496375;
  font-weight: 500;
  font-size: 0.8em;
  padding-top: 2px;
  padding-bottom: 2px;
  background: rgb(247, 250, 255);

  background-color: #2e4657;
  background-color: #e3e8ee;
  color: #4f566b;
  background: linear-gradient(66deg, #e3e8ee 50%, rgb(247, 250, 255) 50%);
}

.partialAbsentSubTxtBarShiftsPaid {
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;

  color: #4f566b;
  font-weight: 500;
  font-size: 0.8em;
  padding-top: 2px;
  padding-bottom: 2px;

  background-color: #e3e8ee;
  background: linear-gradient(66deg, #e3e8ee 50%, rgb(247, 250, 255) 50%);
}

.absentButPaid {
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;

  font-weight: 500;
  font-size: 0.8em;
  padding-top: 2px;
  padding-bottom: 2px;
  background: rgb(247, 250, 255);

  background-color: #e3e8ee;
  color: #4f566b;
}

.tilShiftTypeTxt {
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 3px;
  background: #f4f8fe;
  border-radius: 5px;
  border: 1px solid #bbcada;
  color: #516b8b;
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
  margin-bottom: 6px;
}

.unclockedDurString {
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content !important;
  font-weight: 500;
  padding-top: 3px;
  padding-bottom: 3px;
  /* margin-top: 3px; */
  /* margin-bottom: -3px; */
  margin-right: 8px;
  font-size: 11px;
  text-align: left;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 4px;
  max-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #bbcada;
  /* background-color: #f4f8fe; */
  border: 1px solid #bbcada;
  /* margin-top: -20px; */
}

.activeClockedDurString {
  color: #143051;
  font-size: 14px;
  font-weight: 500;
  /* margin-bottom: 3px; */
  /* animation: Pulsate 1s linear infinite; */
  margin-right: 6px;
}

.tilShiftTypeTxtTeam {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 6px;
  color: #4f566b;
  background-color: #f4f8fe;
  border: 1px solid #e6e6e6;
  padding: 4px;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  display: none;
}

.shiftsPageFilterBarRota {
  min-height: 56px;
  max-height: 56px;
  background-color: #ebf0f7;

  /* min-width: 100%;
  max-width: 100%; */

  padding-top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-right: 4%; */
  position: relative;
  z-index: 8;
  min-width: 100%;
  max-width: 100%;
  /* padding-left: 5%; */
  padding-right: 2%;
  /* border-right: 1px solid #e0ebf8;
  border-bottom: 2px solid #e6eaee; */
}

.shiftsPageFilterBar {
  min-height: 56px;
  max-height: 56px;
  background-color: #ebf0f7;

  /* min-width: 100%;
  max-width: 100%; */

  padding-top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-right: 4%; */
  position: relative;
  z-index: 9;
  min-width: 100%;
  max-width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  /* border-right: 1px solid #e0ebf8;
  border-bottom: 2px solid #e6eaee; */
}

.teamShiftsPageFilterBar {
  min-height: 90px;
  max-height: 90px;
  background-color: #ebf0f7;

  min-width: 90%;
  max-width: 90%;

  padding-top: 0px;

  position: relative;
  z-index: 999;
  border-right: 1px solid #e0ebf8;
  border-bottom: 2px solid #e6eaee;
  margin-bottom: -10px;
  margin-left: 5%;
}

.teamShiftsPageFilterBarUpper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  max-height: 50px;
  border-bottom: 1px solid #e6eaee;
  /* margin-top: 6px; */
  /* max-width: 90%; */
  /* margin-left: 5%; */
}

.upperMobTeamHours_ {
  /* margin-top:3px; */
  /* padding-top:10px; */
  /* padding-left: 5%; */
  /* padding-right: 5%; */
}
.Container {
  /* display: none; */
}
.b221 {
  border-left: 2px solid #e6eaee !important;
}

.teamShiftsPageFilterBarLower {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  max-height: 50px;
  /* padding-top: 4px; */
}

.shiftsPageFilterBarLeft {
  min-width: 44%;
  max-width: 44%;

  min-height: 100%;
  display: flex;
  justify-content: flex-start;
  /* margin-left: 5%; */
  margin-top: 8px;
}

.mobTeamShiftsUpperLeft {
  min-width: 134px;
  max-width: 134px;

  min-height: 100%;
  display: flex;
  justify-content: flex-start;
}

.reverseShiftsIcon {
  max-width: 22px;
  min-width: 22px;
  margin-left: 12px;
  border: 1px solid #e6eaee;
  border-radius: 5px;
  max-height: 23px;
  max-width: 23px;
  min-height: 23px;
  min-width: 23px;
  margin-top: 6px;
  cursor: pointer;
}

.reverseShiftsIcon:hover {
  background-color: #fff;
}
.reversedIcon {
  background-color: #fff;
  border: 1px solid #8ea7c5;
  transform: rotate(180deg);
}

.reversedIcon:hover {
  background-color: #fff;
  opacity: 0.8;
}

.shiftsTilMonthDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 134px;
  min-width: 134px;
  line-height: 26px;
  height: 35px;

  -webkit-appearance: none;
  background-position-x: 64px;
  display: flex;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: #0bf;
  background-color: white;
  color: #143051;
  background: url("../img/general/downArrowGrey.svg") no-repeat right #fff;
  background-position-x: 112px;
}

.shiftsTilMonthDropdown:hover {
  border-color: #d7dfe9;
}

.shiftsTilMonthDropdown2 {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 136px;
  min-width: 136px;
  line-height: 26px;
  height: 35px;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 1px solid #e6eaee;
  color: #fff;
  background: url(/static/media/downArrowWhite2.887d57ce.svg) no-repeat right;
  background-color: #2b679f;
  background-position-x: 112px;
}

.shiftsTilMonthDropdown2:hover {
  border-color: #a9bcd4;
}

.shiftsTilTeamMonthDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 134px;
  min-width: 134px;
  line-height: 26px;
  height: 35px;
  -webkit-appearance: none;
  background-position-x: 66px;
  display: flex;
  cursor: pointer;
  border-color: #2c7eb9;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #143051;
  background: url("../img/general/downArrowGrey.svg") no-repeat right #fff;
  background-position-x: 112px;
}

.shiftsTilTeamMonthDropdown:hover {
  border-color: #d7dfe9;
}

.shiftsPageFilterBarRight {
  min-height: 100%;

  font-size: 14px;
  font-weight: 500;
  display: flex;
  /* margin-right:5%; */
  align-items: center;
  justify-content: flex-end;
  margin-left:20px;
}

.resetFilter {
  margin-top: 5px;
  margin-right: 18px;
  font-weight: 400;
  color: #95aaba;
  cursor: pointer;
  color: #fff;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  margin-top: 9px;
}

.resetFilter:hover {
  background-color: #bbcada;
}

.resetFilterNavy {
  margin-top: 9px;
  margin-right: 18px;
  font-weight: 500;
  color: #8ea7c5;
  cursor: pointer;
  font-size: 12px;
}

.resetFilterNavy:hover {
  opacity: 0.8;
}

.shiftTilFilterHolder {
  min-width: 100%;

  display: flex;
  justify-content: flex-end;
  margin-top: -0px;
  /* margin-bottom:-4px; */
  background-color: #ebf0f7 !important;
  /* max-height:35px; */
}

.shiftTilFilterHolder2 {
  min-width: 100%;

  display: flex;
  justify-content: flex-end;
  margin-top: -4px;
  /* margin-bottom:-4px; */
  background-color: #ebf0f7 !important;
  /* max-height:35px; */
}

.shiftTilFilterBtnsContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 14px;

  min-width: 298px;
  padding-right: 5%;
  border-right: 1px solid #e0ebf8;
}

.shfitTilFilterBtnsContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
  max-width: 342px;
  min-width:342px;
  float: right;
  padding-right: 7%;
  border-right: 1px solid #e0ebf8;
}

.shiftTilFilterBtnsContainerMob {
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;
  background-color: #ebf0f7;
  min-width: 328px;
  padding-right: 5%;
  padding-bottom: 5px !important;
  /* border-right: 1px solid #e0ebf8; */
}

.shiftTilFilterBtn {
  font-size: 14px;
  margin-top: 8px;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  padding-left: 13px;
  font-weight: 500;
  padding-right: 13px;
  /* margin-right: 15px; */
  cursor: pointer;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shiftTilFilterBtn:hover {
  background-color: #bbcada;
}

.shiftTilFilterBtn:active {
  transform: scale(0.96);
}

.shiftTilFilterBtnEngaged {
  font-size: 0.95em;
  margin-top: 8px;
  color: #fff;
  border: 1px solid #7049c4;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;

  padding-left: 14px;
  font-weight: 500;
  padding-right: 14px;

  margin-right: 8%;
  cursor: pointer;
  background-color: #9374d6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeFilterImgTilShifts {
  max-width: 9px;
  margin-left: 6px;
  margin-top: 2px;
}

.shiftTilFilterBtnActive {
  font-size: 14px;
  margin-top: 8px;
  color: #fff;

  display: flex;

  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  align-items: center;

  padding-left: 9px;
  padding-right: 10px;
  font-weight: 500;
  /* margin-right: 16px; */

  cursor: pointer;
  background-color: #8ea7c5;
  border: 1px solid #6987a9;
  min-width: fit-content;
}
.shiftTilFilterBtnActive:hover {
  background-color: #7e93ac;
}

.marginRight0 {
  margin-right: 0px !important;
}

.shiftsOrTilFilter {
  cursor: pointer;
  display: flex;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;

  color: #fff;
  border-radius: 5px;
  min-height: 30px;
  line-height: 30px;
  padding-left: 8px;
  padding-right: 6px;
  font-size: 14px;
  font-weight: 500;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  margin-right: 10px;
  min-width: fit-content;
}

.shiftsOrTilFilter:hover {
  background-color: #bbcada;
}

.shiftsOrTilFilter:last-child {
  margin-right: 0px;
}

.shiftsOrTilFilterTeam {
  cursor: pointer;
  display: flex;
  border: 1px solid #8e76c1;
  background-color: #a387df;

  color: #fff;
  border-radius: 5px;
  min-height: 30px;
  line-height: 30px;
  padding-left: 8px;
  padding-right: 6px;
  font-size: 14px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  margin-right: 6px;
  font-weight: 500;
}

.shiftsOrTilFilterTeam:hover {
  background-color: #8e76c1;
}

.shiftsOrTilFilterTeam:last-child {
  margin-right: 0px;
}

.unselectedShiftOrTil {
  /* margin-top:8px; */
}
.unselectedShiftOrTil2 {
  margin-top: 8px;
  background-position-x: 174px !important;
  max-width: 188px !important;
  min-width: 198px !important;

  min-width: 200px;
  min-height: 35px;
  background-color: blue;
  max-width: 200px;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 175px;
  font-weight: 500;
  font-size: 14px;
  padding-left: 10px;
}

.unworkedShiftTilItem {
  /* background-color: #c0d6f0;
  color: #fff; */
}

.shiftTilSelectImg {
  max-width: 12px;
  margin-left: 7px;
  margin-right: 4px;
}

.filterSplitterContainer {
  min-width: 100%;
  background-color: #ebf0f7;
  padding-top: 0px;
  border-right: 1px solid #e0ebf8;
  border-left: 1px solid #e0ebf8;
}

.filterSplitter {
  min-height: 1px;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 10px;
  background-color: #e6eaee;
}

.myShiftModalUnderlay {
  background: rgba(0, 0, 0, 0.25);

  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.tilRateDropdown {
  color: #496375;
  border-radius: 5px;
  font-size: 1em;
  font-weight: 500;
  padding-left: 14px;
  text-align: left;
  max-width: 78px;
  line-height: 26px;
  font-weight: 500;
  height: 30px;
  background: url("../img/general/downArrowGrey.svg") no-repeat right #fff;
  border: 1px solid #dde9f9;
  -webkit-appearance: none;
  background-position-x: 54px;
  margin-bottom: 6px;
  margin-left: 18px;
}

.myShiftModalBox {
  background-color: #ebf0f7;
  color: #143051;
  min-width: 360px;
  max-width: 360px;
  border-radius: 10px;
  padding-top: 20px;

  text-align: left;
  padding-left: 15px;
  padding-right: 15px;

  line-height: 45px;
  margin: 0 auto;
  height: 515px;

  cursor: auto;

  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  border: 1px solid #e7ebef;
}

.myShiftModalBoxDesktop {
  min-height:516px;
  max-height:516px;
}

.mgrShiftModalBoxBorder {
  border: 1px solid #e7ebef;
}

.allShiftsMarkedAbsenceHeaderTxt {
  font-size: 1.2em;
  font-weight: 500;
}

.myShiftModalHeaderContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2px;
  margin-top: -14px;
}

.myShiftModalHeaderContainerOpen {
  margin-top:-9px
}

.closeAbsenceUnitBtnImg:hover {
  opacity: 0.8;
}

.myShiftCloseHolder {
  min-width: 24px;
  max-width: 24px;
  padding-left: 4px;
padding-top:1px;

}

.closeMyShiftModalImg {
  width: 15px;
  cursor: pointer;
}

.closeMyShiftModalImg:hover {
  opacity: 0.8;
}

.myShiftModalHeaderTitleTxt {
  text-align: right;

  min-width: 170px;
  margin-top: -1px;

  font-size: 18px;
  font-weight: 500 !important;
  color: #143051;
  /* margin-top: -2px; */
}

.toilHeaderTxt {
  text-align: right;
  /* display: flex; */
  /* align-items: center; */
  min-width: -moz-fit-content;
  min-width: fit-content;
  /* margin-top: -3px; */
  font-size: 18px;
  font-weight: 500 !important;
  color: #143051;
  /* margin-top: -3px;
  margin-bottom: 3px; */
}

.openShiftModalTitle {
  text-align: right;

  min-width: 170px;
  margin-top: -3px;

  font-size: 1.2em;
  font-weight: 500 !important;
  color: #143051;
  /* margin-top: -2px; */
}

.myShiftModalHeaderRight {
  text-align: right;
  min-width: 70px;
  max-width: 70px;

  font-size: 0.9em;
  font-weight: 500;
  color: #8ea7c5;
}

.myShiftRow {
  border-bottom: 1px solid #e0ebf8;
  display: flex;
  padding-top: 3px;
  justify-content: space-between;

  padding-left: 5%;
  padding-right: 5%;
}

.myShiftSwappedRow {
  border-bottom: 1px solid #e0ebf8;
  display: flex;
  padding-top: 3px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  padding-left: 5%;
  padding-right: 5%;
}

.myShiftRowLeft {
  font-size: 16px;
  font-weight: 500;

  justify-content: space-between;
  color: #143051;

  min-width: 100%;
}

.itemNote {
  line-height: 26px;
  margin-top: 10px;
  font-size: 0.9em;
  padding-right: 8px;
  padding-bottom: 8px;
}
.myShiftRowLeftTitle {
  font-size: 12px;
  margin-bottom: -20px;
  color: #214978;

  /* color: #859cb6; */
  font-weight: 400;
  display: flex;
  max-width: fit-content;
}

.myShiftRowRight {
  min-width: 18px;
  min-height: 10px;
}

.myShiftModalBody {
  border: solid 1px #e6eaee;
  border-top: solid 2px #e6eaee;
  border-bottom: none;
  border-radius: 6px 6px 0px 0px;
  max-width: 100%;
  overflow: auto;
  overflow-x: hidden;
  max-height: 369px;
  min-height: 369px;
  -ms-overflow-style: none;

  background-color: rgb(252, 253, 255);
  background-color: white;
}

.myShiftBreakSpan {
  font-size: 0.8em;
  color: #bbcada;
}

.bankToBePaidSpan {
  font-size: 0.8em;
  color: #bbcada;
  padding-left: 10px;
}

.myShiftTagsContainer {
  display: flex;
  flex-wrap: wrap;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 6px;
}

.itemTag {
  max-width: fit-content;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  min-height: 35px;
  line-height: 16px;
  align-items: center;
  border-radius: 5px;
  margin-top: 8px;
  margin-right: 5px;
  padding-bottom: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #4f566b;
  background-color: #f4f8fe;
  border: 1px solid #e6e6e6;
}

.myShiftAbsentTxtRed {
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #c98888;
  color: #fff;
  font-weight: 500;
  font-size: 0.8em;
  padding-top: 2px;
  padding-bottom: 2px;
  background: #e89292;
  margin-top: 10px;
  margin-bottom: 12px;
}

.myShiftAbsentDurationSpan {
  font-size: 10px;
  color: #f5e5e5;
  margin-top: 2px;
}

.myShiftModalFooter {
  display: flex;
  justify-content: space-between;
  max-height: 50px;
  min-height: 50px;
  border-top: 2px solid #dbe4ee;
  align-items: center;

  min-width: 100%;
}

.mobMyShiftModalFooter {
  padding-top: 3px;
}

.myShiftModalFooterRight {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 6px;
  padding-right: 6px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  max-width: 59px;
  min-width: 59px;
}

.myShiftModalFooterRight:hover {
  background-color: #214978;
}

.myShiftRow:last-child {
  border-bottom: none;
  padding-bottom: 12px;
}

.myShiftrOW:last-of-type {
  border-bottom: none;
}
.myShiftModalFooterLeft {
  display: flex;
  justify-content: space-between;
  min-width: 225px;
}

.myShiftModalFooterLeftMyShift {
  display: flex;
  justify-content: flex-start;
  min-width: 260px;
}

.myShiftModalFooterLeftMyShiftOtheruser {
  display: flex;
  justify-content: flex-start;
  min-width: 244px;
}
.requestShiftEditBtnContainer {
  display: flex;
  min-height: 35px;
  max-height: 35px;
  align-items: center;
  margin-top: 0px;
  border-radius: 5px;
  justify-content: center;
  max-width: 58px;
  cursor: pointer;
  margin-left:5px;
}

.requestShiftHistoryBtnContainer {
  display: flex;
  min-height: 35px;
  max-height: 35px;

  align-items: center;
  margin-top: 0px;
  border-radius: 5px;

  cursor: pointer;
}

.requestShiftHistoryBtnContainerActive {
  display: flex;
  min-height: 35px;
  max-height: 35px;
  /* min-width: fit-content; */
  /* padding-right: 5px; */
  /* max-width: 90px !important; */
  align-items: center;
  margin-top: 0px;
  border-radius: 5px;

  cursor: pointer;

  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  min-width: fit-content !important;
  padding-right: 7px;
}

.requestShiftHistoryBtnContainerActive:hover {
  background-color: #bbcada;
}
.animateInLeft {
  animation-name: slideModalLeft !important;
  animation-duration: 0.025s !important;
}
.requestShiftEditBtnTxt {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  border: solid 1px #0af;
  background-color: #0bf;
  text-align: center;
  min-width: 50px;
  padding-top: 2px;
  padding-left: 4px;
  padding-right: 4px;
  margin-top: -1px;
}

.requestShiftEditBtnTxt:hover {
  background-color: #0af;
}

.claimItemBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  border: solid 1px #0af;
  background-color: #0bf;
  text-align: center;
  min-width: fit-content;
  padding-top: 2px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 5px;
  max-height: 35px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
}

.claimItemBtn:hover {
  background-color: #0af;
}

.requestShiftHistoryBtnTxtInactive {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 68px;
  padding-left: 6px;
  padding-right: 6px;
  min-height: 35px;
  max-height: 35px;
  color: #fff;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
}

.requestShiftHistoryBtnTxtInactive:hover {
  background-color: #bbcada;
}
.requestShiftHistoryBtnTxtActive {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding-left: 3px;
  text-align: center;
  min-width: fit-content;
  padding-top: 0px;

  padding-right: 4%;
  /* margin-top: -1px; */
  margin-left: 6px;
  /* margin-right: 13px; */
}

.closeHistoryShiftImgCross {
  margin-left: 7px;
  margin-right: -3px;
  max-width: 9px;
  min-width: 9px;

  margin-top: 1px;
}

.nameAndDateDropdownsContainer {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  min-width: 100%;

  padding-right: 5%;
  padding-top: 3px;
  margin: 0 auto;
  background-color: #ebf0f7;
  border-right: 1px solid #e0ebf8;
}

.mobShiftsFilterBarActive {
  display: flex;
  justify-content: space-between;

  background-color: #ebf0f7;
  border-right: 1px solid #e0ebf8;
}

.shiftNamesDropdown {
  /* color: #fff; */
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 188px;
  min-width: 188px;

  line-height: 26px;
  min-height: 35px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
    #496375;
  background-color: white;
  color: #143051;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  background-position-x: 164px;
  display: flex;
  cursor: pointer;
  /* margin-right: 8px; */
}
.shiftNamesDropdown:hover {
  border-color: #d7dfe9;
}

.fullButNarrowShiftNamesDropdown {

  max-width: 158px;
  min-width: 158px;
  background-position-x: 134px;

}

.shiftDayDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 78px;
  min-width: 78px;
  line-height: 26px;
  height: 35px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
    #496375;
  background-color: white;
  color: #496375;
  border: 2px solid #e6eaee;
  -webkit-appearance: none;
  background-position-x: 54px;
  display: flex;
  cursor: pointer;
  /* margin-right: 12px; */
  /* animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important; */
}

.shiftDayDropdown:hover {
  border-color: #d7dfe9;
}

.noShiftsTxtContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  font-size: 0.9em;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  line-height: 20px;
  margin-top: 2px !important;
  padding-left: 5%;

}

.noShiftsSideBar {
  min-height: 1px;
  min-width: 30px;
  background-color: #bbcada;
  max-height: 1px;
  display: none;
}

.noShiftsTxt {
  color: #bbcada;
  padding-left: 13px;
  /* padding-right: 13px; */
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-top:10px;
  text-align: left;
  padding-right:60px;
}

.x19873 {
  margin-top:15px;
}


.mobNoShiftsTxt {
  padding-left: 6%;
}


.x1984971 {
  padding-left:5%;
}
.shiftAmendmentRequestsBtn {
  font-size: 12px;
  margin-top: 8px;
  color: #fff;
  background-color: #9a74eb;
  border: 1px solid #965ade;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  line-height: 35px;
  padding-left: 7px;
  font-weight: 500;
  padding-right: 8px;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-left: 3px solid #965ade !important;
}

.shiftAmendmentRequestsBtn:hover {
  background-color: #967ccf;
}

.shiftAmendmentRequestsBtnActive {
  font-size: 14px;
  margin-top: 8px;
  color: #fff;
  display: flex;
  background-color: #4b1caf;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  line-height: 23px;
  padding-left: 12px;
  padding-right: 18px;
  font-weight: 500;
  margin-right: 10px;
  max-width: 102px;
  cursor: pointer;
  opacity: 1;
  align-items: center;
  border: 1px solid #481561;
}

.shiftAmendmentRequestsBtnActive:hover {
  background-color: #442781;
}

.amendmentsNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  min-height: 20px;
  max-height: 20px;
  min-width: fit-content;

  color: white;

  font-size: 12px;

  margin-left: 3px;
  padding-left: 5px;
  border-left: 1px solid white;
}

.mgrModalBgShiftCost {
  border: solid 2px #bbcada;
  background-color: #cbd7e6;
}

.salariedCost {
  border: solid 1px #7992b2 !important;
  background-color: #859dbb !important;

  font-size: 12px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  font-weight: 500 !important;
}

.shiftTeamSubBubbleMgrBg {
  border: 1px #4b1caf solid;
  color: #7249c9;
  cursor: default;
}

.shiftTeamSubBubbleMgrBg:hover {
  border-color: #b2bbc7;
}

.mgrModalTitleColour {
  min-width: 204px;
}

.employeeNameBar {
  margin-left: 24px !important;
}

.knobOnMgrColour {
  background-color: #7249c9;
}

.ghostBgShiftTilItem {
  background-color: #214978;
  opacity: 1;
  /* border-bottom: 1px solid #143051 !important; */
}

.ghostBgShiftTilItem:hover {
  border-color: #143051;
  background-color: #214978 !important;

  padding-bottom: 1px;
}

.changesDot {
  background-color: white;
  min-width: 5px;
  min-height: 5px;
  max-height: 5px;
  max-width: 5px;
  border-radius: 5px;
  margin-top: -12px;
  margin-right: -3px;
}

.changesNoChangesBtn {
  font-size: 14px;
  margin-top: 8px;
  color: #496375;
  border: 2px solid #496375;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  line-height: 26px;
  padding-left: 10px;
  font-weight: 500;
  padding-right: 20px;
  margin-right: 8%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  color: white;
  min-width: 81px;
  
}

.changesNoChangesBtn:hover {
  background-color: #bbcada;
}

.changesNewChangesBtn {
  font-size: 0.95em;
  margin-top: 8px;
  color: #fff;

  background-color: #0077ff;
  border-radius: 25px;
  min-height: 35px;
  max-height: 35px;
  line-height: 26px;
  padding-left: 10px;
  font-weight: 500;
  padding-right: 10px;
  padding-top: 2px;
  margin-right: 8%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.shiftHistoryContainer {
  min-height: 358px;
  max-height: 358px;
  overflow: auto;
  overflow-x: hidden;
}

.shiftHistoryRow {
  padding: 15px;
  line-height: 22px;
  font-size: 0.9em;
  border-bottom: 1px solid #e0ebf8;
}

.shiftHistoryRow:first-child {
  padding: 15px;
  padding-top: 25px;
  line-height: 22px;
  font-size: 0.9em;
  border-bottom: 1px solid #e0ebf8;
}

.shiftHistoryRow:last-child {
  padding: 15px;
  padding-top: 25px;
  line-height: 22px;
  font-size: 0.9em;
  border: none;
  border-bottom: 0px solid #fff !important;
}

.shiftHistoryRowFinal {
  border: none !important;
}

.shiftHistoryMain {
  font-weight: 400;
  color: #496375;
  padding-right: 20px;
  font-size: 12px;
  margin-left: -7px;
  border-left: 2px solid #bbcada;
  padding-left: 10px;
}

.myShiftChangesColour {
  color: #bbcada;
  font-weight: 500;
}

.shiftHistoryTimeContainer {
  display: flex;
  justify-content: flex-end;
  padding-right: 10%;
  padding-top: 8px;
  margin-bottom: -4px;
}

.shiftHistoryTime {
  font-weight: 500;
  background-color: #f4f8fe;
  font-size: 10px;
  color: #8ea7c5;
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
  max-height: 20px;
  border: 1px solid #bbcada;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingHistoryImg {
  margin: 20px auto;
}

.loadingHistoryContainer {
  min-width: 100%;

  display: flex;
  justify-self: center;
}

.noChangesTxt {
  font-size: 14px;
  color: #859dbb;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
  min-width: 100%;
  padding-left: 5%;
  padding-right: 14%;
}

.noChangesContainer {
  min-width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.backAndDeleteShiftContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: fit-content;
  flex-direction: row-reverse;
}

.openShiftFooterLeftContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 200px;
}

.convertTilBtn {
  background-color: #9a74eb;
  color: white;
  height: 28px;
  line-height: 25px;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 100px;
  font-size: 0.88em;
  font-weight: 500;

  border: 1px solid #592eb6;
  text-align: center;
  animation-name: fadeModalIn !important;
  animation-duration: 0.1s !important;
  margin-left: 10px;
  min-width: 76px;
  max-width: 76px;
}

.backFromMgrShift {
  height: 28px;
  border-radius: 100px;
  font-size: 1em;
  font-weight: 500;
  font-size: 0.95em;
  font-weight: 500;
  color: #592eb6;
  text-align: center;

  padding-left: 9px;
  padding-right: 9px;

  display: flex;
  min-height: 28px;
  max-height: 28px;
  background-color: #8997a8;
  border: 1px solid #636f7e;
  align-items: center;
  margin-top: 0px;
  border-radius: 25px;
  justify-content: center;
  cursor: pointer;
  color: white;

  min-width: 64px;
  max-width: 64px;
}

.shiftTools {
  min-height: 28px;
  max-height: 28px;
  min-width: 28px;
  max-width: 28px;
  padding: 4px;
  background-color: #8997a8;
  border: 1px solid #636f7e;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}

.shiftTools:active {
  transform: rotate(40deg);
}

.shiftToolsActive {
  min-height: 28px;
  max-height: 28px;
  min-width: 28px;
  max-width: 28px;
  padding: 4px;
  background-color: #214978;
  border: 1px solid #143051;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}

.shiftToolsActive:active {
  transform: rotate(90deg);
}

.shiftHistoryTitle {
  font-size: 12px;
  color: #214978;
  font-weight: 400;
  margin-top: 12px;
  line-height:18px;
  padding-right:50px;
  padding-bottom:20px;
  padding-top:10px;
}

.reqsToChangeTitle {
  margin-top: 4px !important;
}

.editShiftRequestModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);


}

.editShiftRequestModalBox {
  background-color: #f4f8fe;
  min-height: 300px;
  min-width: 320px;
  max-width: 320px;
  margin: 0 auto;
  border-radius: 10px;
  /* -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */

  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  cursor: default;


}

.editMyShiftModalHeaderContainer {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  padding-left: 6%;
  padding-right: 5%;
  padding-bottom: 20px;
  margin-top: 2px;
  padding-top: 18px;
}

.toilHeader {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  padding-bottom: 20px;
  margin-top: 16px;
  align-items: center;
  padding-right: 24px;
  padding-left: 18px;
}

.marginTop6px {
  margin-top: 6px;
}

.editMyShiftCloseHolder {
  min-width: 54px;
  max-width: 54px;
}

.editMyShiftRow {
  display: flex;
  justify-content: space-between;
  max-width: 90%;
  margin: 0 auto;
  padding-left: 5px;
  padding-right: 5px;
  min-height: 50px;
  align-items: center;
  border-bottom: 1px solid #e0ebf8;
}

.noEditShiftBorder {
  border: none;
  align-items: flex-start;
}

.editMyShiftNoteTitle {
  margin-top: 17px;
}

.editMyShiftRowTitle {
  color: #214978;
  font-weight: 400;
  font-size: 12px;
}

.editMyShiftFooter {
  min-width: 100%;
  min-height: 53px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-left: 4%;
  padding-right: 4%;
}

.editMyShiftFooterSideUnit {
  min-width: 60px;
  color: #143051;
  padding-right: 12px;
}

.requestEditMyShiftBtn {
  background-color: #07f;
  border: #0469de 1px solid;
  color: white;
  min-height: 35px;
  line-height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
  border-radius: 5px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.requestEditMyShiftBtn:hover {
  background-color: #0469de;
}

.closeEditMyShiftModalTxt {
  font-size: 0.9em;
  color: #496375;
  font-weight: 500;
  cursor: pointer;
  /* margin-right: 8px; */
}

.closeToilBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  max-width: 62px;
  min-width: 62px;
}

.closeToilBtn:hover {
  background-color: #214978;
}

.breakMinsSpanEditShift {
  font-size: 0.8em;
}

.EditMyShiftNoteTextBox {
  min-width: 84%;
  min-height: 80px;
  overflow: auto;
  resize: none;
  padding: 5px;
  border-radius: 5px;
  background-color: #fff;
  color: #516b8b;
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  overflow: auto;
  letter-spacing: 0px;
  padding: 10px;
  border: 2px solid #dde9f9;
}

.editMyShiftTypeDropdown {
  color: #fff;
  border-radius: 15px;
  font-size: 1em;
  font-weight: 500;
  padding-left: 14px;
  text-align: left;
  max-width: 148px;
  line-height: 26px;
  font-weight: 500;
  height: 30px;
  background: url("../img/general/downArrowWhite2.svg") no-repeat right #8ea7c5;

  border: 1px solid #496375;
  -webkit-appearance: none;
  background-position-x: 110px;
}

.blueLoaderEditMyShiftImg {
  max-width: 48px;
  /* padding-left: 52px; */
  /* padding-right: 52px; */
}

.submitContainerEditMyShift {
  min-height: 35px;
  max-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-size: 0.9em; */
  /* padding-left: 5px; */
  /* padding-right: 5px; */
}

.fadeSubmitMyShiftChange {
  opacity: 0.5;
  pointer-events: none;
}

.cancelShiftChangeRequestTxtContainer {
  min-width: 80px;
}

.cancelReqTxt {
  font-weight: 500;
  color: #4b1caf;
}

.showChangesBtnActive {
  font-size: 0.95em;
  margin-top: 8px;
  color: #fff;
  background-color: #8ea7c5;
  border: 1px solid #6987a9;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  min-width: 92px;

  padding-left: 9px;
  padding-right: 10px;
  font-weight: 500;
  margin-right: 10px;

  cursor: pointer;
}

.showChangesBtnActive:hover {
  background-color: #7e93ac;
}

.makeFilterInvisAndInactive {
  opacity: 0.2;
  pointer-events: none;
}

.maxW58px {
  max-width: 58px !important;
}

.closeChangesImgTilShifts {
  max-width: 9px;
  margin-left: 6px;
  margin-top: 2px;
}

.myShiftChangesContainer {
  background-color: #ebf0f7;

  min-height: calc(100vh - 270px);
  max-height: calc(100vh - 270px);
  overflow: auto;
  position: fixed;
  min-width: 100%;

  padding-top: 8px;
  padding-bottom: 68px;
}

.shiftChangeRow {
  background-color: #fafeff;
  border-radius: 5px;

  max-width: 90%;
  min-width: 90%;

  margin: 0 auto;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 12px;

  margin-bottom: 12px;
  margin-top: 24px;
border:1px solid #dee5f0;
  background-color: #fff;
  /* cursor: pointer; */
  /* animation-name: zoomModalIn !important; */
  /* animation-duration: 0.1s !important; */
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
}

.shiftChangeRow:hover {
  /* border-color:#bbcada; */
}

.shiftChangeRow:first-of-type {
  margin-top: 14px;
}

.changeRowLeft {
  background-color: white;

  min-height: 90px;
  max-height: 90px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding-left: 14px;
  padding-top: 7px;
  z-index: 100;
  padding-bottom: 7px;
  margin-left: 6px;

  max-width: 130px !important;
  min-width: 130px !important;

  /* border-radius: 5px; */
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */

  padding-left: 5px;
  padding-right:5px;
  margin-top: 10px;
  margin-bottom: 12px;
  cursor: pointer;
  background-color: white;
  border-radius: 4px 5px 5px 4px;
  border: 2px solid #e0ebf8;
}

.changeRowLeft:hover {
  border-color:#CCD8EA
}

.changeRowRight {
  min-height: 90px;
  max-height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  padding-right: 14px;
  padding-top: 7px;
  z-index: 100;
  padding-bottom: 7px;
  margin-right: 6px;
  max-width: 130px !important;
  min-width: 130px !important;
  /* border-radius: 5px; */
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 10px;
  margin-bottom: 12px;
  cursor: pointer;
  background-color: white;
  border-radius: 5px 4px 4px 5px;
  border: 2px solid #e0ebf8;
}

.changeRowRight:hover {
border-color:#CCD8EA;
}

.oldShiftDate {
  font-size: 12px;
  font-weight: 500;
  color: #496375;
  text-align: right;
  padding-bottom: 6px;
  border-bottom: 1px solid #dde9f9;
  min-width: 100%;
  padding-left:7px;
  padding-right:7px;
}

.oldShiftTimes {
  font-size: 12px;
  font-weight: 500;
  color: #8ea7c5;
  margin-top: 2px;
  padding-bottom: 6px;
  padding-top: 1px;
  border-bottom: 1px solid #dde9f9;
  /* min-width: 100%; */
  text-align: right;
  padding-left:7px;
  padding-right:7px;

}

.oldShiftBrk {
  font-size: 12px;
  font-weight: 500;
  color: #acc3e1;
  padding-left:7px;
  padding-right:7px;

}

.shiftDurationTopRight {
  min-height: 80px;
  margin-top: -5px;
  min-width: 33%;
  max-width: 33%;
}

.editShiftDurationTopRightTxt {
  min-width: 90px;
}

.payableShiftDur {
  font-size: 12px;
  margin-top: -31px;
  text-align: right;
  color: #8ea7c5;
  font-weight: 500;
  opacity: 0.5;
}

.tilNewShiftTime {
  font-size: 12px;
  font-weight: 500;
  color: #acc3e1;
  padding-left: 7px;
  padding-right: 7px;
}

.useTilMinsSpan {
  color: #cce3ff;
}

.highlightedChangeElement {
  font-weight: 500;
  color: #0077ff;
  /* padding-left: 6px; */
  line-height: 13px;
  padding-top: 1px;
  text-align: right;
}

.declinedChange {
  opacity: 1;
}

.shiftChangeTitleRow {
  display: flex;
  min-width: 100%;

  padding-left: 3%;
  padding-right: 3%;
  justify-content: space-between;
  align-items: center;
}

.changeRowMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 110px;
  max-height: 110px;
  background-color: #fcfdff;

  padding-left: 3%;
  padding-right: 3%;
  border-top: 1px solid #e3ebf5;
}

.shiftChangeType {
  font-size: 12px;
  padding-top: 0px;
  padding-bottom: 3px;
  color: #516b8b;
  text-align: left;
  line-height: 16px;
  padding-left: 5px;
  padding-right: 14px;
  font-weight: 500;
  color: #516b8b;
  margin-bottom: 3px;
  margin-top: 8px;
}

.changeStatus {
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: center;
  display: none;
  max-height: 24px;
  min-height: 24px;

  align-items: center;
  /* border-radius: 5px; */
  font-weight: 500;
  font-size: 12px;
  padding-top: 0px;
  padding-bottom: 2px;
  margin-top: 0px;
  margin-bottom: 8px;

  color: #bbcada;
  border-right: #bbcada 2px solid;
}

.changeStatusApproved {
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  /* border-radius: 5px; */

  color: #b6daa8;
  border-right: #b6daa8 2px solid;
  font-weight: 500;
  font-size: 12px;
  padding-top: 0px;
  padding-bottom: 2px;

  margin-top: 0px;
  margin-bottom: 8px;
}

.changeStatusDeclined {
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  /* border-radius: 5px; */
  color: #ba0000;
  border-right: #ba0000 2px solid;
  font-weight: 500;
  font-size: 12px;
  padding-top: 0px;
  padding-bottom: 2px;
  margin-top: 2px;
  margin-bottom: 8px;
  opacity: 0.5;
}

.changeStatusInvis {
  visibility: hidden;
  pointer-events: none;
}

.changeTitleRowSubBar {
  display: flex;
  justify-content: space-between;
  padding-left: 3%;
  padding-right: 3%;
  min-height: 30px;
  align-items: center;
}

.shiftChangeMadeDate {
  font-size: 10px;
  color: #bbcada;
  font-weight: 500;
  margin-left: 5px;
  margin-top: -24px;
  /* border-left: 2px solid #bbcada; */
  /* padding-left: 8px; */
  padding-top: 8px;
  display: flex; align-items: center;
}

.shiftTypeSpan {
  /* font-weight: 500; */
  /* color: #90a7c4; */
}

.declinedChangeSpan {
  /* font-weight: 500; */
  /* color: #ba0000; */
}

.approvedChangeSpan {
  /* font-weight: 500; */
  /* color: #6db451; */
}

.changeShiftArrowImg {
  /* opacity: 0.7; */
}

.deleteChangeUnit {
  color: white;
  z-index: -1;

  margin: 0 auto;

  min-height: 50px;
  display: flex;
  max-width: 100%;
  padding-left: 5%;
  padding-right: 3%;
  justify-content: space-between;
  align-items: center;
  padding-top: 0px;
  background-color: #fff;
  cursor: default;
  border-top: 1px solid #e3ebf5;
}

.viewChangeUnitShift {
  background-color: #0077ff;
  border: 1px solid #214978;
  color: white;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 2px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectedShiftChangeRow {
  border-color: #6a8bb1;
}

.deleteChangeUnitLeft {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  margin-left: -4px;

  min-height: 32px;
  color: #bbcada;
}

.oldDurationTitleSpan {
  /* font-weight: 500; */
  padding-right: 6px;
  color: #516b8b;
  font-size: 12px;
}

.deleteAndViewBtns {
  display: flex;
}

.deleteChangeBtn {
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 8px;
  cursor: pointer;
  font-weight: 500;
  border: solid 1px #e0ebf8;
  background-color: #fff;
  color: #bbcada;
}

.deleteChangeBtn:hover {
  background-color: #f4f8fe;
}

.invisDeleteChangeBtn {
  visibility: hidden;
  pointer-events: none;
}

.cancelChangeLoadingImgContainer {
  /* background-color: #859dbb;
  border: 1px solid #143051;
  color: white;
  border-radius: 5px;
  min-height: 26px;
  line-height: 26px;
  font-size: 0.9em;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 8px;
  cursor: pointer;
  font-weight: 500;
  min-width: 71px;
  box-shadow: inset 0px 2px 0px rgb(92, 121, 153);
  opacity: 0.8; */
}

.cancelChangeLoaderImg {
  max-width: 40px;
  max-height: 12px;
  margin-top: 6px;
  margin-bottom: -1px;
}

.requestsBtnEmpty {
  font-size: 14px;
  margin-top: 8px;

  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;

  padding-left: 10px;
  font-weight: 500;
  padding-right: 10px;

  margin-right: 11px;
  cursor: pointer;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.requestsBtnEmpty:hover {
  background-color: #bbcada;
}

.reqOnSpan {
  margin-left: 4px;
  margin-right: 5px;
}

.decidedBySpan {
  font-weight: 500;
  color: #bbcada;
  /* padding-right: 3px; */
}

.closeShiftBtnCrossImg {
  max-width: 9px;
  min-width: 9px;
  margin-left: 4px;
  margin-right: -7px;
}

.shiftRequestsKeyContainer {
  display: flex;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;

  align-items: center;
  padding-top: 8px;

  border-right: 1px solid #e0ebf8;
}

.shiftReqMonthSelectDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 10px;
  text-align: left !important;
  max-width: 83px;
  min-width: 83px;
  line-height: 16px;
  margin-top: 2px;
  height: 35px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
    #496375;
  background-color: white;
  color: #496375;
  border: 2px solid #e6eaee;
  -webkit-appearance: none;
  background-position-x: 61px;
  display: flex;
  cursor: pointer;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}
.shiftReqMonthSelectDropdown:hover {
  border-color: #d7dfe9;
}
.outstandingShiftReqsBtn {
  font-size: 14px;

  color: #fff;
  /* border:1px solid #143051; */

  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  line-height: 22px;

  font-weight: 500;
  /* background-color: #214978; */

  cursor: pointer;
  padding-left: 6px;
  padding-right: 7px;
  margin-top: 4px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  display: flex;
  align-items: center;
  max-width: 69px !important;
  min-width: 69px !important;

  border: 1px solid #1e88e5 !important;
  background-color: #0af !important;
  border-left: 4px solid #1e88e5 !important;
}

.outstandingShiftReqsBtn:hover {
  background-color: #1e88e5 !important;
}

.amendmentsNumberInOutstanding {
  margin-top: 0px;
  margin-left: 4px;
  border: 1px solid #0077ff;
  background-color: #dde9f9;
  line-height: 18px;
  border-radius: 3px;
  padding: 0px;
  min-width: 22px;
  font-size: 12px;
  color: #0077ff;
}

.outstandingShiftReqsBtnActive {
  font-size: 14px;
  color: #fff;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  line-height: 22px;
  font-weight: 500;

  cursor: pointer;
  padding-left: 7px;
  padding-right: 9px;
  margin-top: 4px;
  padding-top: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: fit-content !important;
  max-width: fit-content !important;

  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  border: 1px solid #143051 !important;
  background-color: #214978 !important;
  border-left: 3px solid #143051 !important;
}
.outstandingShiftReqsBtnActive:hover {
  background-color: #143051 !important;
}

.shiftReqsPage {
  background-color: #ebf0f7;
  min-height: calc(100vh - 208px);
}

.whiteCrossCloseOutstandingShiftRequests {
  margin-left: 6px;
  margin-right: 2px;
  max-width: 9px;
}

.fadeMonthSelect {
  opacity: 0.15;
}

.shiftReqsContainer {
  overflow: auto;
  min-height: calc(100vh - 259px);
  max-height: calc(100vh - 259px);
  padding-bottom: 20px;
}

.shiftReqsContainerMob {
  min-height: calc(100vh - 349px);
  max-height: calc(100vh - 349px);
  padding-bottom: 30px;
}

.shiftRequestsContainerSplitterLineWrapper {
  min-width: 100%;
  border-right: 1px solid #e0ebf8;
  min-height: 8px;
}

.shiftRequestsContainerSplitterLine {
  min-height: 1px;

  min-width: 90%;
  max-width: 90%;
  margin: 8px auto;
  margin-bottom: 0px;
  border-bottom: 1px solid #e6eaee;
}

.shiftReqMonthSelectContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shiftReqNamesDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 145px;
  min-width: 145px;
  margin-top: 2px;
  line-height: 16px;
  height: 35px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #496375;
  background-color: white;
  color: #143051;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  background-position-x: 124px;
  display: flex;
  cursor: pointer;
  margin-right: 5px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.teamTsUsersDropper {
  margin-top: 8px;
}

.shiftReqNamesDropdown:hover {
  border-color: #d7dfe9;
}

.decisionSubBar {
  background-color: #687d97;
  border: 1px solid #143051;
  border-top: none;
  background: rgb(88, 112, 150);
  background: linear-gradient(
    0deg,
    rgb(69, 100, 150) 0%,
    rgb(69, 100, 150) 90%,
    rgb(43, 56, 77) 100%
  );
  max-width: 86%;
  min-width: 86%;
  color: white;
  margin: 0 auto;
  display: flex;
  max-height: 50px;
  min-height: 50px;
  padding-top: 5px;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 0px 0px 10px 10px;
  font-size: 0.9em;
  font-weight: 500;
}

.oldChangeUnitDur {
  font-weight: 500;
}

.decisionRequestShiftDiv {
  /* animation-name: slideSubBarDown !important;
  animation-duration: 0.1s !important;
  animation-delay: 0.2s; */
}

.decisionSubBarApproved {
  max-width: 86%;
  min-width: 86%;
  color: #a8cc9a;
  margin: 0 auto;
  display: flex;
  max-height: 40px;
  min-height: 40px;
  padding-top: 18px;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 0px 0px 10px 10px;
  font-size: 0.9em;
  font-weight: 500;

  padding-bottom: 3px;
}

.shiftEditDecidedBy {
  text-align: right;
  color: #5479a3;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.decisionSubBarDeclined {
  max-width: 86%;
  min-width: 86%;
  color: #cc4d4d;
  margin: 0 auto;
  display: flex;
  max-height: 40px;
  min-height: 40px;

  align-items: center;
  justify-content: space-between;
  padding-left: 4px;
  padding-right: 10px;
  border-radius: 0px 0px 10px 10px;
  font-size: 0.9em;
  font-weight: 500;
  margin-top: 14px;
}

.decisionSubRight {
  display: flex;
  min-width: 150px;
  justify-content: space-between;
}

.viewShiftEditSchedule {
  background-color: #859dbb;
  border: 1px solid #acc3e1;
  color: white;
  border-radius: 30px;
  min-height: 30px;
  max-height: 30px;
  line-height: 26px;
  font-size: 0.9em;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-right: 8px;
  cursor: pointer;
  font-weight: 500;
  margin-top: 3px;
  margin-left: 4px;
  box-shadow: inset 0px 2px 0px rgb(92 121 153);
}

/* .approveShiftEdit {
  background-color: #6dba4f;
  border: 1px solid #acc3e1;
  color: white;
  border-radius: 5px;
  min-height: 26px;
  line-height: 25px;
  font-size: 0.9em;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  font-weight: 500;
  box-shadow: inset 0px 2px 0px #5a9e3f;
}

.approveShiftEdit:active {
  transform: scale(0.96);
}

.declineShiftEdit {
  background-color: #cc4d4d;
  border: 1px solid #acc3e1;
  color: white;
  border-radius: 5px;
  min-height: 26px;
  line-height: 25px;
  font-size: 0.9em;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 8px;
  cursor: pointer;
  font-weight: 500;
  box-shadow: inset 0px 2px 0px #ba0000;
}
.declineShiftEdit:active {
  transform: scale(0.96);
} */

.viewShiftEditSchedule:active {
  transform: scale(0.96);
}

.undoSchedEditBtn {
  background-color: #8997a8;
  border: 1px solid #636f7e;
  color: white;
  border-radius: 5px;
  min-height: 26px;

  font-size: 0.9em;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.undoSchedEditBtn:active {
  transform: scale(0.96);
}

.approveLoaderImg {
  max-width: 40px;
  min-width: 40px;
  max-height: 14px;
  margin-top: 8px;
  margin-right: 8px;
}

.compareCloseHolder {
  min-width: 50px;
}

.blankSpaceTopper {
  display: flex;

  max-height: 10px;
}

.proposedShiftChangeModalTitleTxt {
  text-align: center;

  min-width: 140px;

  font-size: 1.2em;
  font-weight: 500 !important;
}

.proposeCompareShiftsBtn {
  background-color: #859dbb;
  border: 1px solid #acc3e1;
  color: white;
  border-radius: 5px;
  min-height: 26px;
  line-height: 25px;
  font-size: 0.9em;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  font-weight: 500;
  box-shadow: inset 0px 2px 0px rgb(92 121 153);
}

.compareParentContainer {
  min-width: 100%;
  max-width: 100%;
  animation-name: fadeModalIn !important;
  animation-duration: 0.1s !important;
}

.narrowAsItTTR {
}

.compareDateRowContainer {
  min-height: 70px;
  min-width: 100%;
  display: flex;
  justify-content: space-between;
}

.compareDateRow1 {
  min-width: 20%;
  max-width: 20%;
  min-height: 50px;
}

.compareDateRow2 {
  min-width: 40%;
  max-width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 70px;
  max-height: 70px;
  font-size: 1.2em;
  color: #496375;
  border-right: 1px solid #e0ebf8;
}

.originalTitleTxt {
  font-weight: 500;
}

.originalDateTxt {
  margin-top: -28px;
  font-size: 0.7em;
  color: #8ea7c5;
}

.compareDateRow3 {
  min-width: 40%;
  max-width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 70px;
  max-height: 70px;
  font-size: 1.2em;
  color: #4b1caf;
}

.proposedTitleTxt {
  font-weight: 500;
}

.proposedDateTxt {
  margin-top: -28px;
  font-size: 0.7em;
  color: #9a74eb;
}

.generateComparesContainer {
  max-height: 250px;
  min-height: 250px;
  border-top: 1px solid #8ea7c5;
  border-bottom: 1px solid #8ea7c5;

  overflow: auto;
}
.compareDateUserRow {
  background-color: white;
  min-height: 70px;
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px #e0ebf8 solid;
}

.compareDateUserRow:nth-child(even) {
}

.compareDateUserRow1 {
  min-width: 20%;
  max-width: 20%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.compareDateUserRow2 {
  min-width: 40%;
  max-width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 70px;
  max-height: 70px;
  font-size: 1.2em;
  color: #496375;
}

.dayOffItem {
  min-width: 40%;
  max-width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 70px;
  max-height: 70px;
  font-size: 0.8em;
  font-weight: 400;
  color: #bac5d3;
  padding-right: 14px;
}

.compareDateUserRow2Three {
  min-width: 40%;
  max-width: 40%;
  display: flex;

  justify-content: space-around;
  align-items: center;
  min-height: 70px;
  max-height: 70px;
  font-size: 1.2em;
  color: #496375;
}

.compareDateUserRow2Four {
  min-width: 40%;
  max-width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-height: 70px;
  max-height: 70px;

  font-size: 1.2em;
  color: #496375;
  overflow: auto;
}

.fourItemRow {
  max-height: 25%;
  min-height: 25%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 8px;
  padding-left: 5px;
}

.fourItemRowProposed {
  max-height: 25%;
  min-height: 25%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 3px;
  padding-left: 3px;
  padding-top: 1px;
  margin-right: 4px;
  background-color: #0077ff;
  border: 1px solid #143051;
  border-radius: 3px;
  font-weight: 500;
  margin-left: 2px;
}

.fourItemRowThis {
  max-height: 25%;
  min-height: 25%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 3px;
  padding-left: 3px;
  padding-top: 1px;
  margin-right: 4px;
  background-color: #e0ebf8;
  border: 1px solid #859dbb;
  border-radius: 3px;
  font-weight: 500;
}

.fourItemTimesString {
  color: #143051;

  font-size: 0.525em;
}

.fourItemBrkString {
  color: #859dbb;

  font-size: 0.45em;
}

.compareDateUserRow2twoUnits {
  min-width: 40%;
  max-width: 40%;
  display: flex;

  justify-content: space-around;
  align-items: center;
  min-height: 70px;
  max-height: 70px;
  font-size: 1.2em;
  color: #496375;

  padding-left: 3px;
  padding-right: 6px;
}

.compareDateUserRow2twoUnitsProposed {
  min-width: 40%;
  max-width: 40%;
  display: flex;

  justify-content: space-around;
  align-items: center;
  min-height: 70px;
  max-height: 70px;
  font-size: 1.2em;
  color: #496375;

  padding-left: 3px;
  padding-right: 6px;
}

.compareSplitter {
  min-width: 1px;
  max-width: 1px;
  min-height: 100%;
  background-color: #bdc9d6;
  margin-left: -1px;
}

.compareSplitterWhenTtr {
  min-width: 1px;
  max-width: 1px;
  min-height: 100%;
  background-color: #fcf6fd;
  margin-left: -1px;
}

.twoOriginalItemsUnit {
  border-right: 1px #e0ebf8 solid;

  max-height: 68px;
  min-height: 68px;
  margin-left: 2px;
  margin-right: 2px;
  min-width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.threeOriginalItemsUnit {
  border-right: 1px #e0ebf8 solid;

  max-height: 68px;
  min-height: 68px;
  margin-left: 2px;
  margin-right: 2px;
  min-width: 30%;
  max-width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-right: 3px;
}

.threeOriginalItemsUnitProposed {
  background-color: #0077ff;

  border-radius: 10px;
  border: 3px solid #143051;
  max-height: 68px;
  min-height: 68px;
  margin-left: 2px;
  margin-right: 2px;
  min-width: 30%;
  max-width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-right: 3px;
}

.threeOriginalItemsUnit:last-of-type {
  border: none;
}

.threeOriginalItemsUnitThis {
  background-color: #fff;
  background-color: #e0ebf8;
  border: 3px solid #859dbb;
  border-radius: 10px;

  max-height: 68px;
  min-height: 68px;
  margin-left: 2px;
  margin-right: 2px;
  min-width: 32%;
  max-width: 32%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.twoOriginalItemsUnitThis {
  background-color: #fff;
  background-color: #e0ebf8;
  border: 3px solid #859dbb;
  border-radius: 10px;

  max-height: 68px;
  min-height: 68px;
  margin-left: 2px;
  margin-right: 2px;
  min-width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.twoOriginalItemsUnitThisProposed {
  background-color: #0077ff;

  border: 3px solid #143051;
  border-radius: 10px;

  max-height: 68px;
  min-height: 68px;
  margin-left: 2px;
  margin-right: 2px;
  min-width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.twoOriginalItemsUnit:last-of-type {
  border: none;
}

.twoOriginalTimeStart {
  font-size: 0.7em;
  font-weight: 500;
  line-height: 20px;
  min-height: 20px;
  max-height: 20px;
  margin-top: 3px;
  text-align: center;
  color: #143051;
}

.twoOriginalTimeEnd {
  font-size: 0.7em;
  font-weight: 500;
  line-height: 20px;
  min-height: 20px;
  max-height: 20px;
  text-align: center;
  margin-top: -4px;
  color: #143051;
}
.twoOriginalDash {
  font-size: 0.8em;
  font-weight: 500;
  line-height: 1px;
  min-height: 1px;
  max-height: 1px;
  text-align: center;
  margin-top: -3px;
  color: #143051;
}

.twoOriginalTimeBrk {
  font-size: 0.6em;
  font-weight: 400;
  line-height: 20px;
  margin-top: -5px;
  min-height: 20px;
  max-height: 20px;
  text-align: center;
  color: #859dbb;
}

.twoOriginalTimeStartThree {
  font-size: 0.6em;
  font-weight: 500;
  line-height: 20px;
  min-height: 20px;
  max-height: 20px;
  margin-top: 3px;
  text-align: center;
  color: #143051;
}

.twoOriginalTimeEndThree {
  font-size: 0.6em;
  font-weight: 500;
  line-height: 20px;
  min-height: 20px;
  max-height: 20px;
  text-align: center;
  margin-top: -4px;
  color: #143051;
}
.twoOriginalDashThree {
  font-size: 0.6em;
  font-weight: 500;
  line-height: 1px;
  min-height: 1px;
  max-height: 1px;
  text-align: center;
  margin-top: -3px;
  color: #143051;
}

.twoOriginalTimeBrkThree {
  font-size: 0.5em;
  font-weight: 400;
  line-height: 20px;
  margin-top: -5px;
  min-height: 20px;
  max-height: 20px;
  text-align: center;
  color: #859dbb;
}
.originalShiftBubble {
  display: flex;
  flex-direction: column;

  font-size: 0.9em;
  max-height: 55px;
  min-height: 55px;

  padding-bottom: 4px;
  padding-left: 4px;
  padding-right: 4px;

  margin-top: 5px;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
}

.originalShiftBubbleThisUser {
  background-color: #e0ebf8;

  display: flex;
  flex-direction: column;

  font-size: 0.9em;
  max-height: 55px;
  min-height: 55px;

  padding-bottom: 4px;
  padding-left: 4px;
  padding-right: 4px;
  border: 3px solid #859dbb;

  border-radius: 10px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
}

.proposedShiftBubbleThisUser {
  background-color: #0077ff;
  display: flex;
  flex-direction: column;

  font-size: 0.9em;
  max-height: 55px;
  min-height: 55px;

  padding-bottom: 4px;
  padding-left: 4px;
  padding-right: 4px;
  border: 3px solid #143051;

  border-radius: 10px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  margin-left: -5px;
}

.originalCompareTimes {
  line-height: 26px;
  font-weight: 500;
  color: #143051;
  min-height: 22px;
  font-size: 0.9em;
}

.originalCompareTimesThisUser {
  line-height: 26px;
  font-weight: 500;
  color: #859dbb;
  min-height: 22px;
  font-size: 0.84em;
}

.proposedCompareTimesThisUser {
  line-height: 26px;
  font-weight: 500;
  color: #fff;
  min-height: 22px;
  font-size: 0.84em;
}

.originalCompareBreak {
  color: #859dbb;
  line-height: 18px;
  min-height: 16px;
  font-size: 0.7em;
  margin-top: -5px;
}

.originalCompareBreakThisUser {
  color: #859dbb;
  line-height: 18px;
  min-height: 16px;
  font-size: 0.7em;
  margin-top: -5px;
}

.proposedCompareBreakThisUser {
  color: #fff;
  line-height: 18px;
  min-height: 16px;
  font-size: 0.7em;
  margin-top: -5px;
}
.compareDateUserRow3 {
  min-width: 40%;
  max-width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 70px;
  max-height: 70px;
  font-size: 1.2em;
  color: #496375;
}

.compareInitials {
  font-weight: 500;
  background-color: #00ccff;
  max-width: 40px;
  max-height: 40px;
  min-height: 40px;
  min-width: 40px;
  border-radius: 50px;
  color: white;
  font-size: 1.1em;
  text-align: center;
  line-height: 40px;
  text-transform: uppercase;
}

.compareInitialsNotThisUser {
  font-weight: 500;
  background-color: #8997a8;
  max-width: 40px;
  max-height: 40px;
  min-height: 40px;
  min-width: 40px;
  border-radius: 50px;
  color: white;
  font-size: 1.1em;
  text-align: center;
  line-height: 40px;
  text-transform: uppercase;
}

.compareModalUnderlay {
  background: rgba(0, 0, 0, 0.45);

  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 100vw;
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.075s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.compareModalBox {
  background-color: #f4f8fe;
  background-color: #fcf6fd;
  color: #143051;
  min-width: 94vw;
  max-width: 94vw;
  border-radius: 15px;
  padding-top: 20px;

  text-align: left;
  padding-left: 5%;
  padding-right: 5%;

  line-height: 45px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  padding-bottom: 20px;
}

.reveralFullName {
  font-size: 0.65em;
  background-color: #acc3e1;
  text-align: center;
  line-height: 12px;
  color: #fff;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 5px;
}



.loadingCompareImgContainer {
  min-height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingCompareImg {
  margin: 0 auto;
}

.takeTilOnShiftBtn {
  display: flex;

  border: 1px solid #636f7e;

  cursor: pointer;
  background-color: #abb9dc;
  max-height: 28px;
  min-height: 28px;
  text-align: center;
  line-height: 23px;
  color: white;
}

.takeTilBtnContainer {
  display: flex;
  min-height: 35px;
  max-height: 35px;
  align-items: center;
  margin-top: 0px;
  border-radius: 5px;
  justify-content: center;
  min-width: fit-content;
  cursor: pointer;
  margin-left:5px;
}

.takeTilBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  min-width: 66px;
  padding-top: 2px;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: -1px;
  color: #fff;
  border: solid 1px #0af;
  background-color: #0bf;
}
.takeTilBtn:hover {
  background-color: #0af;
}
.overtimeHTiLDisable {
  visibility: hidden;
  pointer-events: none;
}

.none {
  display: none;
}

.conflicted {
  color: #cc4d4d;
}

.conflictWarnTxt {
  background-color: #e26666;
  border: 1px #ba0000 solid;
  color: white;
  margin: 0 auto;
  max-width: 65px;
  min-width: 65px;
  font-size: 0.8em;
  text-align: center;
  border-radius: 30px;
  max-height: 22px;
  min-height: 22px;
  line-height: 17px;

  margin-top: 12px;
  font-weight: 500;
}

.takeTilDsTxt {
  color: #8ea7c5;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
}

.whenTilDropdown {
  color: #fff;
  border-radius: 15px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 88px;
  min-width: 88px;
  line-height: 26px;
  height: 30px;
  background-color: #8ea7c5;
  background: url(/static/media/downArrowWhite2.887d57ce.svg) no-repeat right
    #8ea7c5;
  border: 1px solid #496375;
  -webkit-appearance: none;
  background-position-x: 64px;
  display: flex;
  cursor: pointer;
}

.boldTrimSpan {
  font-weight: 500;
  color: #0077ff;
}

.tilChangePreviewHeader {
  display: flex;

  justify-content: space-around;
  min-height: 76px !important;
  align-items: center;
  margin-top: -8px;
  border-bottom: 1px solid #e0ebf8;
  max-width: 90%;
  margin-left: 5%;
  margin-bottom: 10px;
}
.tilChangePreviewHeaderWhole {
  display: flex;

  justify-content: space-around;
  min-height: 60px !important;
  align-items: center;
  margin-top: -8px;
  min-width: 180px;
}
.originalTilShiftTxt {
  text-align: center;
  /* color: rgb(128, 148, 192); */
  font-size: 14px;
  border: 1px solid #dde9f9;
  color: #143051;
  font-weight: 500;
  /* background-color: #b7c9d6; */
  min-width: -moz-fit-content;
  min-width: fit-content;
  /* max-width: 155px; */
  border-radius: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  max-height: 35px;
  align-items: center;
  /* display: flex; */
  /* justify-content: space-between; */
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  line-height: 22px;
}

.wholeOff {
  text-align: center;
  border-left: 2px solid #00aaff;
  font-size: 14px;
  border: 1px solid #dde9f9;
  color: #214978;
  font-weight: 500;
  min-width: -moz-fit-content;
  min-width: fit-content;
  border-radius: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  min-height: 32px;
  max-height: 32px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  border-left: 2px solid #00aaff;
  margin-top: 14px;
}

.tilInvalidTime {
  /* border: #ba0000 2px solid !important */
  color: #ba0000;
}

.shiftChangeArrowTil {
  margin-left: -14px;
  margin-right: -14px;
}

.splitTilShiftPreview {
}

.tilPreviewShiftContainer {
  display: flex;
  min-width: 150px;
  max-width: 150px;
  min-height: 20px;

  justify-content: center;
}

.tilPreviewShiftContainerWhole {
  display: flex;
  min-width: 180px;
  max-width: 180px;
  min-height: 20px;

  justify-content: center;
}

.notSplitTilShiftPreview {
  display: flex;
  justify-content: center;
  min-width: 110px;
  max-width: 110px;
}

.splitPreviewTilShift {
  text-align: center;
  /* color: rgb(128, 148, 192); */
  font-size: 14px;
  border: 1px solid #dde9f9;
  color: #143051;
  font-weight: 500;
  /* background-color: #b7c9d6; */
  min-width: -moz-fit-content;
  min-width: fit-content;
  /* max-width: 155px; */
  border-radius: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  max-height: 35px;
  align-items: center;
  /* display: flex; */
  /* justify-content: space-between; */
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 22px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.toilRowTitle {
  color: #214978;
  font-weight: 400;
  font-size: 12px;
  text-align: left;
}

.firstBarTil {
  display: flex;
  justify-content: space-between;
  max-width: 90%;
  margin: 0 auto;
  padding-left: 5px;
  padding-right: 5px;
  min-height: 2px;
  align-items: center;
  border-bottom: 1px solid #dde9f9;
  margin-top: 8px;
}

.ghostModalBox {
  min-height: 100px;
  max-width: 300px;
  min-width: 300px;
  padding: 20px;
  border-radius: 10px;
  margin: 0 auto;
  padding-top: 25px;
  justify-content: space-between;
  background-color: white;
  color: #143051;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  cursor: default;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.closeGhostModalBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  margin-top: 20px;
}

.closeGhostModalBtn:hover {
  background-color: #214978;
}

.takeTilReqUnit {
  min-width: 90%;
  max-width: 90%;
  min-height: 50px;
  background-color: #cce3ff;
  border-radius: 7px;
  margin-top: 8px;
  margin: 0 auto;
  margin-top: 8px;
}

.shiftChangeTitleTilOrChange {
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;

  color: #496375;
  font-weight: 500;
  font-size: 12px;
  padding-top: 0px;
  padding-bottom: 2px;
  background: rgb(242, 242, 242);
  /* margin-top: 8px; */
  /* margin-left: 12px; */
  /* margin-bottom: 8px; */
}

.highlighedTilTimes {
  font-weight: 500;
  color: #0077ff;

  padding-left: 6px;
  line-height: 13px;
  padding-right: 6px;
}

.smallerTilTwoShifts {
  font-size: 0.65em;
  min-height: 16px;

  max-width: 80px;
  line-height: 11px;
  margin-top: 2px;
  text-align: right;
}

.useTilTxt {
  color: #8ea7c5;
}

.tilTimesStrip {
  display: flex;

  max-height: 16px;
  padding-top: 2px;
  padding-bottom: 2px;
  min-height: 26px;
}

.tilStripTitle {
  font-size: 11px;
  font-weight: 500;
  color: #8ea7c5;
  /* margin-right: 4px; */
  margin-top: 2px;
}

.originalShiftTimeSpanValue {
  color: #496375;
  font-weight: 500;
  margin-left: 4px;
}
.myShiftTimesRowOriginalRow {
  max-height: 28px;
  margin-top: 3px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -4px;
}

.originalShiftTimeSpan {
  color: #fff;
}

.tilStatusBubble {
  color: white;
  font-size: 0.7em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  max-height: 22px;
}

.tilApprovedBg {
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;
  /* border: 1px solid #7fbb67; */
  color: #a8cc9a;
  font-weight: 500;
  font-size: 0.65em;
  padding-top: 2px;
  padding-bottom: 2px;
  /* background: #a8cc9a; */
  margin-top: 4px;
  margin-bottom: 2px;
}

.tilDeclinedBg {
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #c98888;
  color: #fff;
  font-weight: 500;
  font-size: 0.65em;
  padding-top: 2px;
  padding-bottom: 2px;
  background: #e89292;
  margin-top: 2px;
}

.tilRequestedBg {
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;

  color: #496375;
  font-weight: 500;
  font-size: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  background: rgb(247, 250, 255);
  background-color: #f4f8fe;
  color: #4f566b;
  border: 1px solid #bbcada;
  /* border-left: 3px solid #bbcada; */
}

.originalShiftTimeSpan {
  color: #859dbb;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0px;

  padding-top: 5px;
  max-height: 22px;
  border-top: solid 1px #e0ebf8;
}

.youReqTilSpan {
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;

  color: #496375;
  font-weight: 500;
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  background: rgb(247, 250, 255);
  margin-top: 4px;
  background-color: #f4f8fe;
  color: #4f566b;
  border: 1px solid #bbcada;
  text-transform: none;
  /* border-left: 3px solid #bbcada; */
}

.tilMBold {
  font-weight: 500;
  color: #143051;
  padding-left: 5px;
  padding-right: 5px;
}

.myShiftTimesRowFlex {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  padding-right: 2px;
}

.myShiftTimesRow {
  max-width: 130px;
  line-height: 17px;
  /* margin-top: 12px; */
  padding-bottom: 7px;
}

.tilSplitShiftContainer {
  display: flex;
  justify-content: space-between;
  margin-left: 5px;
  font-weight: 500;
  margin-top: 10px;
  max-width: 179px;
}

.x1938197411 {
  margin-top: 12px;
}

.maxWidth172 {
  max-width: 172px;
}

.tilReqTimesRow {
  margin-left: 5px;
  font-weight: 500;
}

.tilABShiftBox {
  min-width: 30px;
  max-width: 30px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -8px;
  margin-left: 2px;
  padding-right: 4px;
}

.tilArow {
  max-width: 24px;
  min-width: 24px;
  display: flex;
  justify-content: center;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #859dbb;
  color: #496375;
  font-weight: 500 !important;
  font-size: 12px;
  background: rgb(247, 250, 255);
  margin-top: 14px;
}

.tilBrow {
  max-width: 24px;
  min-width: 24px;
  display: flex;
  justify-content: center;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #859dbb;
  color: #496375;
  font-weight: 500 !important;
  font-size: 12px;
  background: rgb(247, 250, 255);
  margin-top: 4px;
}

.approved12TilRow {
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #7fbb67;
  text-align: center;
  color: #fff;
  font-weight: 500;

  padding-top: 2px;
  padding-bottom: 2px;
  background: #a8cc9a;
  margin-top: 4px !important;
  margin-bottom: 2px;
}

.approved12TilRow:last-child {
  margin-top: 0px;
}

.declined12TilRow {
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #e6eaee;
  color: #e89292;
  font-weight: 500;
  padding-top: 2px;
  padding-bottom: 2px;
  background: #FFF;
  margin-top: 10px;
  margin-bottom: 8px;
  border-left: 2px solid #e89292
}

.tilTimePreviewWhenRequested {
  min-width: fit-content;
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;
  color: #496375;
  font-weight: 500;
  font-size: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  /* margin-top: 14px; */
  background-color: #f4f8fe;
  color: #4f566b;
  border: 1px solid #bbcada;
  /* border-left: 3px solid #bbcada; */
}

.blankTilSpaceRight {
  /* max-width: 110px;
  min-width: 110px; */
  max-width: 20px;
  min-width: 20px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 20px;

  font-weight: 500;
  font-size: 0.65em;
  padding-top: 2px;
  padding-bottom: 2px;

  margin-top: 10px;
  margin-right: 4px;
}

.tilDeclinedStrip {
  margin-top: -20px;
  font-size: 12px;
  opacity: 0.3;
  margin-bottom: -14px;
  margin-left: 1px;
}

.fadeTilTimes {
  opacity: 0.4;
  line-height: 18px;
}

.maxW24 {
  max-width: 24px;
}

.cancelTilReqContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 50px;
  padding-left: 2px;
}
.removeTil {
  background-color: #fff;
  border: 1px solid #bbcada;
  border-radius: 5px;
  max-height: 28px;
  color: #bbcada;
  font-size: 0.7em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 12px;
  margin-top: 14px;
  margin-right: 6px;
  cursor: pointer;
  padding-top: 2px;
  /* background-color: #f4f8fe; */
  /* border: 1px solid #bbcada; */
  /* color: #859dbb; */
  min-height: 28px;
}


@media only screen and (min-width: 820px) {
  .removeTil:hover {

    border-color: #859dbb;
  }
}


.removeTilSure {
  background-color: #8ea7c5;
  border: 1px solid #6987a9;
  border-radius: 5px;
  max-height: 24px;
  color: #fff;
  font-size: 0.7em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 7px;
  padding-right: 7px;
  margin-bottom: 12px;
  margin-top: 14px;

  cursor: pointer;
}

.cancelTilImg {
  max-width: 10px;
  margin-right: 4px;
  display: none;
}

.declinedTilSpan {
  background-color: #e26666;
}

.tilAccountTxt {
  font-size: 12px;
  margin-top: -6px;
  max-height: 26px;
  margin-bottom: 10px;
  color: #516b8b;
  font-weight: 500;
  border-left: 2px solid #0cf;
  padding-left: 5px;
  line-height: 20px;
}

.shiftEditTilTimeContainer {
  margin-left: 18px;
  display: flex;
  flex-direction: column;

  font-size: 1.15em;
  font-weight: 500;
  color: #143051;
}

.originalShiftTimesTilBar {
  font-size: 12px;
  margin-bottom: -20px;
  color: #214978;
  font-weight: 400;
  margin-left: 5px;
}

.removeMarginB {
  margin-bottom: -14px;
}

.manageTilStatusTitleApproved {
  margin-top: 5px;
}

.editShiftTilMins {
  color: #bbcada;
  font-size: 12px;
  margin-left: 8px;
  font-weight: 500;
  border: 1px solid #bbcada;
  border-radius: 3px;
  padding-left: 4px;
  padding-right: 4px;
}

.tilTitleBar {
  display: flex;
  justify-content: space-between;
  min-width: 210px;
  margin-bottom: -4px;
}

.tilStatusTitleApproved {
  background-color: #a8cc9a;
  border: 1px solid #7fbb67;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  max-height: 20px;
  margin-top: 12px;
  padding-bottom: 0px;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
}

.tilStatusTitleDeclined {
  color: white;
  max-height: 18px;
  background-color: #e89292;
  border: 1px solid #c98888;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  font-size: 0.65em;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  margin-left: 6px;
}

.tilStatusTitlePending {
  background-color: #e3e8ee;

  color: #4f566b;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 18px;

  margin-top: 12px;
  font-size: 0.65em;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  margin-left: 8px;
}

.tilTimesOnNewTimesStrip {
  max-width: 120px;
  line-height: 28px;
  margin-top: 11px;
  margin-bottom: 8px;
}

.gapBetweenOrigAndEditTimes {
  max-height: 25px;
  min-width: 10px;
}

.contradictsTil {
  background-color: #c98888;
  border-color: #ba0000;
}

.takesAccTilSpan {
  color: #bac5d3;
}

.elipsisAndEditBtnsContainer {
  display: flex;

  min-width: 114px;
  justify-content: space-between;
}

.killWidthOfElipsisEditBtnContainer {
  display: flex;
  min-width: 0px;
}

.editShiftIconContainer {
  min-height: 33px;
  max-height: 33px;
  min-width: 33px;
  max-width: 33px;

  background-color: #8997a8;
  border: 1px solid #636f7e;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
}

.editShiftIconContainer:active {
}

.editShiftBtnIcon {
  min-height: 15px;
  max-height: 15px;
  min-width: 15px;
  max-width: 15px;
  padding-bottom: 1px;
}

.editTilInputsContainer {
  margin-top: 7px;
  display: flex;
  align-items: center;
  min-width: 210px;
  justify-content: space-between;
  margin-bottom: 8px;
}

.editTilUntilSpan {
  font-size: 0.8em;
  padding-left: 2px;
  padding-right: 5px;
}

.historyIconMgrShift {
  min-width: 18px;
  max-width: 18px;
  padding-right: 1px;
}

.historyIconMgrShift:active {
  transform: scale(0.95);
}
 .editShiftBtnOnManagerShift {
  background-color: #9374d6;
  border-color: #4b1caf;
}

.tilStartClash {
  /* color: #c98888 !important; */
  margin-left: 5px;
  font-weight: 500;
}

.hightlightEditBtn1 {
  background-color: #a387df;
  border-color: #f4f8fe;
}
.hightlightEditBtn2 {
}

.timesAndTagContainer {
  display: flex;
  margin-top: 2px;
  min-width: fit-content;
  min-width: 144px;
}

.pTagTxt {
  color: #fff;
  font-size: 10px;
  text-align: left;
  /* margin-left: 7px; */
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 5px;
  font-weight: 500;
  background-color: #143051;
  max-height: 18px;
  min-height: 18px;
  margin-top: 3px;
  background-color: rgb(247, 250, 255);
  color: #496375;
  padding-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e3e8ee;
  color: #4f566b;
  background-color: #f4f8fe;
  border: 1px solid #e6e6e6;
  min-width: fit-content;
  margin-left: 10px;
}

.pTagsSplitter {
  min-width: 1px;
  background-color: #e0ebf8;
  min-height: 100%;
  margin-left: 5px;
  margin-right: -4px;
  margin-top: 2px;
}

.mobpTagsSplitter {
  margin-left: 6px;
  margin-right: -6px;
}

.severalTagsTxt {
  color: #fff;
  font-size: 0.7em;
  text-align: left;
  margin-left: 3px;
  padding-left: 1px;
  padding-right: 5px;
  border-radius: 15px;
  font-weight: 500;

  line-height: 17px;
  max-height: 18px;
  margin-top: 5px;

  color: #496375;

  padding-top: 0px;
  line-height: 14px;
}

.teamShiftsDataBubble {
  position: fixed;
  top: calc(100% - 143px);
  right: 40px;

  background: rgb(247, 250, 255);

  color: #496375;
  border-radius: 5px;
  min-width: 50px;
  min-height: 30px;

  max-height: 30px;
  line-height: 45px;
  border: 1px solid #859dbb;

  z-index: 999;
  display: flex;
  align-items: center;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  box-shadow: 2px 2px;
}

.totalDurMinsTxt {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 1em;
  font-weight: 500;
}

.disableAddBtn {
  opacity: 0.1;
  pointer-events: none;
}

.teamShiftUnit {
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  padding-bottom: 3px;
}

.teamShiftNameTab {
  margin-top: -90px;
  margin-bottom: 70px;
  font-size:11px;
}

.tagsAndAbsent {
  margin-bottom: 18px;
}

.noTagsAbsent {
  margin-bottom: 18px;
  min-height:120px !important;
  max-height:120px !important;

}

.subShiftAbsenceContainer {
  display: flex;
  justify-content: flex-end;
  min-width: 100%;
  padding-right: 5%;
}
.subShiftAbsenceHolder {
  background-color: #e89292;
  border: 1px #c98888 solid;
  max-width: 120px;
  color: white;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  min-height: 24px;
  line-height: 24px;
  padding-left: 8px;
  padding-right: 8px;
  position: absolute;
  z-index: 1;
  margin-top: -17px;
  margin-right: 20%;
  margin-right: 24px;
}

.tilDataDiv {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  padding-bottom: 10px;
}

.tilDataLeft {
  min-width: 44%;
  max-width: 44%;
  min-height: 80px;
  max-height: 80px;
  border-bottom: 2px solid rgba(89, 89, 89, 0.267);
  border-radius: 12px;
  background-color: #fff;
}

.tilDataL1 {
  font-size: 0.84em;
  padding-left: 13px;
  max-height: 24px;
  min-height: 24px;
  line-height: 24px;
  font-weight: 500;
  margin-top: 5px;
  color: #496375;
}

.tilDataL2 {
  font-size: 0.84em;
  padding-left: 13px;
  max-height: 24px;
  min-height: 24px;
  line-height: 24px;
  font-weight: 500;
  margin-top: -5px;
  color: #8ea7c5;
}

.tilDataL3 {
  font-size: 0.84em;
  padding-left: 13px;
  max-height: 24px;
  min-height: 24px;
  line-height: 24px;
  font-weight: 500;
  margin-top: -6px;
  color: #acc3e1;
}
.tilDataRight {
  min-width: 44%;
  max-width: 44%;
  min-height: 80px;
  max-height: 80px;
  border-bottom: 2px solid rgba(89, 89, 89, 0.567);
  border-radius: 12px;
  background-color: #07f;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.tilDataR1 {
  font-size: 0.84em;
  padding-right: 13px;
  max-height: 24px;
  min-height: 24px;
  line-height: 24px;
  font-weight: 500;
  margin-top: 5px;
  color: #fff;
}

.tilDataR2 {
  font-size: 0.84em;
  padding-right: 13px;
  max-height: 24px;
  min-height: 24px;
  line-height: 24px;
  font-weight: 500;
  margin-top: -7px;
  color: #cce3ff;
}

.tilDataR3 {
  font-size: 0.65em;
  line-height: 8px;
  padding-top: 3px;
  max-height: 30px;
  min-height: 30px;
  line-height: 12px;
  font-weight: 500;
  margin-top: 0px;
  color: #fff;
  max-width: 80px;
  border-top: 1px solid #143051;
  margin-right: 13px;
}

.ttrDateStringTitle {
  font-weight: 500;
  color: #496375;
  display: flex;
  justify-content: space-between;
  margin-top: -6px;
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 8px;
}

.tilDataDs {
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #859dbb;
  color: #496375;
  font-weight: 500;
  font-size: 0.8em;
  padding-top: 2px;
  padding-bottom: 2px;
  background: rgb(247, 250, 255);
  margin-top: 8px;
}

.tilDataTeamName {
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #859dbb;
  color: #496375;
  font-weight: 500;
  font-size: 0.8em;
  padding-top: 2px;
  padding-bottom: 2px;
  background: rgb(247, 250, 255);
  margin-top: 8px;
}

.tilDataTeamNameOnChangeContainer {
  display: flex;
  justify-content: center;

  padding-bottom: 10px;

  margin-top: -14px;
}

.nameStringOnTTR {
  line-height: 18px;
  margin-bottom: 16px;
  text-align: center;
  max-width: 230px;
  margin: 0 auto;
  margin-bottom: 20px;
  font-size: 0.85em;
  color: #2e4657;
}

.disableCloseBtn {
  opacity: 0.3;
  pointer-events: none;
}

.paidDayOffSubTxt {
  font-size: 10px;
  max-width: 45px;
  color: #8ea7c5;
  line-height: 10px;
  font-weight: 500;
  margin-left: 6px;
  border-right: 2px solid #8ea7c5;
  padding-right: 7px;
  margin-top:1px;
  display: none;
}

.smallTxtForPaidDayOff {
  font-size: 0.8em;
}

.absenceNameDropdownFilter {
  color: #fff;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 213px;
  min-width: 213px;
  line-height: 26px;
  height: 30px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  background-position-x: 92px;
  display: flex;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 192px;
}

.mgrAbsenceYearSelectDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 213px;
  min-width: 213px;
  line-height: 26px;
  height: 30px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  background-position-x: 92px;
  display: flex;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 192px;
  margin-left: 5%;
}

.absencePageFilterBarLeft {
  min-width: 220px;
  max-width: 220px;

  min-height: 100%;
  display: flex;
  justify-content: flex-start;
  margin-left: 5%;
  margin-top: 8px;
}

.absencePageFilterBarRight {
  min-width: 100px;
  max-width: 100px;
  min-height: 100%;

  font-size: 0.9em;
  font-weight: 500;
  display: flex;

  margin-right: 4px;
  align-items: center;
  justify-content: flex-end;
}

.mgrAbsenceContainerOnly {
  margin-top: 0px;
  border-radius: 0px;

  max-width: 90%;
  margin: 0 auto;
}

.desktopAbsenceContainerMgrOnly {
  max-width: calc(100vw - 240px);
  min-width: calc(100vw - 240px);
  padding-left: 5%;
  padding-right: 5%;
}

.absenceFilterDividerContainer {
  background-color: #ebf0f7;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.absenceFilterDivider {
  min-height: 1px;
  min-width: 90%;
  max-width: 90%;

  background-color: #e6eaee;
}

.absenceFilterBtn {
  font-size: 0.95em;
  margin-top: 8px;
  color: #496375;
  border: 1px solid #496375;
  border-radius: 5px;
  min-height: 30px;
  max-height: 30px;
  line-height: 26px;
  padding-left: 10px;
  font-weight: 500;
  padding-right: 10px;
  padding-top: 2px;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.absenceFilterBtnActive {
  font-size: 0.95em;
  margin-top: 8px;
  color: #fff;
  border: 1px solid #143051;
  display: flex;
  background-color: #214978;
  border-radius: 5px;
  min-height: 30px;
  max-height: 30px;
  line-height: 23px;
  padding-left: 12px;
  padding-right: 12px;
  font-weight: 500;

  padding-top: 2px;
  cursor: pointer;
}

.convertModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.convertModalBox {
  background-color: #fff;
  min-height: 300px;
  min-width: 90%;
  max-width: 90%;
  margin: 10% auto;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);

  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.unitTypeSpan {
  color: #859dbb;
}

.unitTypeDropdown {
  color: #496375;
  border-radius: 5px;
  font-size: 1em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 138px;
  min-width: 138px;
  line-height: 26px;
  height: 35px;
  background: url("../img/general/downArrowGrey.svg") no-repeat right #fff;
  border: 2px solid #dde9f9;
  -webkit-appearance: none;
  background-position-x: 114px;
  display: flex;
  cursor: pointer;
  margin-left: 0px;
  margin-top: -8px;
  margin-bottom: 12px;
}

.unitTypeDropdown:hover {
  border-color: #d7dfe9;
}

.shiftsMenuBtnHolder {
  min-width: 50px;
  max-width: 50px;
  text-align: center;
}

.swapsMenuBtnHolder {
  min-width: 60px;
  max-width: 60px;
  margin-right: 4px;
  text-align: center;
}

.availabilityMenuBtnHolder {
  min-width: 97px;
  max-width: 97px;
  text-align: center;
  margin-left: -8px;
}

.submissionsHolder {
  min-width: 119px;
  max-width: 119px;
}

.availabilityBodyContainer {
  background-color: #ebf0f7;
  padding-bottom: 20px;
  min-height: calc(100vh - 210px);
  max-height: calc(100vh - 210px);
  border-right: 1px solid #e0ebf8;
}

.mobChangesContainer {
  min-height: calc(100vh - 263px);
  max-height: calc(100vh - 263px);
  border-right: none;
}

.absenceMenuTxtSelected {
  cursor: pointer;
  font-weight: 500;
  color: #143051;
  padding-top: 1px;
  margin-top: 1px;
  line-height: 20px;

  padding-bottom: 2px;
  opacity: 1;
}

.changesOverflow {
  overflow-y: scroll;
}

.absenceUserBtnHolder {
  min-width: 75px;
  max-width: 75px;
}

.absenceUserStatsBtnHolder {
  min-width: 54px;
  max-width: 54px;
}

.filterNameShiftsDropdown {
  background-color: #ebf0f7;
}

.hasNoAllowTxtSize {
  padding-top: 4px;
  line-height: 22px;
  margin-bottom: 8px;
  margin-top: 5px;
}

.accruedPayTitleDiv {
  display: flex;
  padding-right: 4px;
}

.mgrShiftQuestionIcon {
  margin-left: 22px;
  margin-bottom: 12px;
}

.payAccruedLeaveTxtDiv {
  max-width: 77%;
}

.accruedLeaveAmount {
  font-size: 0.9em;
  padding-left: 24px;
  line-height: 29px;
  color: #496375;
  font-weight: 500;
  margin-top: 0px;
  color: #9374d6;
}

.accruedAmountSpan {
  background-color: #dbcbff;
  font-weight: 400;
  color: #7249c9;
  border: 1px solid #4b1caf;
  border-radius: 5px;
  padding-left: 9px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-weight: 500;
}

.accruedCostSpan {
  opacity: 0.65;
  margin-left: 6px;
}

.payAccruedLeaveTxtInfo {
  font-size: 0.8em;
  padding-left: 24px;
  line-height: 20px;
  color: #496375;
  font-weight: 400;
  opacity: 0.75;
  margin-top: 14px;
  margin-bottom: 14px;
}

.jamPayAcc {
  opacity: 0.5;
  pointer-events: none;
}

.bankCostBubble {
  background-color: #dbcbff;
  color: #7249c9;
  margin-left: -10px;
  padding-left: 8px;
  padding-right: 10px;
}

.bankCostBubbleUser {
  background-color: #d2e6fd;
  border-color: #496375;
  color: #496375;
  margin-left: -10px;
  padding-left: 8px;
  padding-right: 10px;
}

.teamSelectScheduleDropdown {
  color: #fff;
  border-radius: 10px;
  font-size: 0.75em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 208px;
  min-width: 208px;
  line-height: 26px;
  height: 30px;
  background: url(/static/media/downArrowWhite2.887d57ce.svg) no-repeat right
    #5479a3;
  border: 1px solid #143051;
  -webkit-appearance: none;
  background-position-x: 182px;
  display: flex;
  cursor: pointer;
  opacity: 0.7;
}

.schedMenuLeft {
  width: 210px;

  display: flex;
  justify-content: space-between;
  margin-top: 6px;
}

.teamSchedulePage {
  background-color: #ebf0f7;
  min-height: calc(100vh - 258px);
  max-height: calc(100vh - 258px);
  max-width: 100%;
  min-width: 100%;
  z-index: 1;
  position: relative;
  padding-top: 8px;
  padding-bottom: 100px;
}

.mobStandaloneTeamSchedHeight {
  min-height:100vh;
  max-height:100vh;
}

.teamSchedFilterBarLeft {
  min-width: fit-content;
  max-width: fit-content;

  min-height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 5px;
}

.teamSchedFilterBarLeftMob {
  margin-left: 5% !important;
}

.teamSchedFilterBarRight {
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-height: 50px;
  font-size: 0.9em;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  top: 120px;
  right: calc(4.2% - 9px);
  z-index: 8
}

.teamSchedFilterBarRightStandalone {
  top: 2px;
}
.marginTopMinus5 {
  margin-top: -5px;
}
.monthDropdown {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 10px;
  text-align: left !important;
  max-width: 86px;
  min-width: 86px;
  line-height: 26px;
  height: 35px;
  /* border: 1px solid #e6eaee; */
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  /* border-color: #2c7eb9; */
  border: 1px solid #e6eaee;
  /* background-color: #0bf; */
  background-color: white;
  color: #143051;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 62px;
}

.monthDropdown:hover {
  border-color: #d7dfe9;
}

.x92020121113 {
  background-position-x: 122px;
  max-width: 146px;
  min-width: 146px;

}

.monWeeksDropdown {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 10px;
  text-align: left !important;
  max-width: 104px;
  min-width: 104px;
  line-height: 26px;
  height: 35px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;

  border-color: #2c7eb9;
  border: 1px solid #e6eaee;
  /* background-color: #0bf; */
  background-color: white;
  color: #6a8bb1;
  background: url("../img/general/downArrowGrey.svg") no-repeat right #fff;
  background-position-x: 84px;
}
.fullScreenWeekSelector {
  background-position-x: 210px !important;
  max-width: 232px;
  min-width: 232px;
}
.weekSelectorLonger {
  background-position-x: 210px !important;
  max-width: 235px;
  min-width: 235px;
}

.weekSelectorMedium {
  background-position-x: 123px !important;
  max-width: 145px;
  min-width: 145px;
}

.dayViewWeekSelector {
  background-position-x: 113px !important;
  max-width: 135px;
  min-width: 135px;
}

.monWeeksDropdown:hover {
  border-color: #d7dfe9;
}

.loadingSchedFade {
  opacity: 0.25;

  pointer-events: none;
}

.greenSplitter {
  min-height: 3px;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 10px;
  background-color: #6dba4f;
}

.loadingSchedTxt {
  color: #143051;
  margin-top: 50px;
  margin-bottom: 10px;
  font-size: 0.8em;
  animation-name: zoomBtnIn !important;
  animation-duration: 0.075s !important;
}

.loadingSchedGenImg {
  margin-top: 60px;
}

.loadingSwaps {
  margin-top: 95px;
}

.mgrSchedBox {
  min-width: 100%;
  min-height: calc(100vh - 286px);
}

.schedDayBar {
  min-width: 100%;
  max-width: 100%;

  overflow-x: auto;
  min-height: calc(100vh - 286px);
}
.dayBarContainer {
  min-height: 30px;
  min-width: 1180px;
  max-width: 1180px;
}

.dayBar {
  max-height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-width: fit-content;
  padding-right: 20px;
}

.dayBarUnit {
  min-width: 140px;

  max-width: 140px;
  margin-top: 10px;
  font-size: 0.9em;
  font-weight: 500;
  padding-bottom: 3px;
  color: #143051;
  border-right: 1px solid #143051;
}

.dayBarUnit:last-child {
  border: none;
}

.emptyDay {
  min-width: 120px;
  max-width: 120px;
}

.scheduleDataBoxContainer {
  overflow: auto;
  max-height: calc(100vh - 315px);
  min-height: calc(100vh - 315px);
  padding-bottom: 50px;
  position: relative;
}
.scheduleDataBox {
  min-width: 1180px;
  max-width: 1180px;

  overflow: auto;

  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  position: relative;
}

.nameColumn {
  background-color: purple;
  color: white;
  max-width: 120px;
  min-width: 120px;
  min-height: 100%;

  display: flex;
  flex-direction: column;
  position: sticky;
  left: 0;
}

.jobTitleTitle {
  min-height: 55px;
  max-height: 55px;
  color: #214978;
  font-size: 11px;
  line-height: 12px;
  /* padding-right: 6px; */
  padding-top: 1px;
  padding-bottom: 4px;
  max-width: 100%;
  min-width: 100%;
  color: #ae77ff;
  text-align: left;
  font-weight:600
}

.x99100111 {
  min-height: 25px;
  max-height: 25px;
}

.jobTitleA1box {
  background-color: #ecf4ff;
  min-height: 28px;
  min-width: 100%;
  margin-top: -1px;
  border-radius: 5px 0 0 5px;
  max-height: 30px;
  border:1px solid #e3e8ee;
  border-right:none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  
}
.staffCalJobTitleTitle {
  min-height: 36px;
  max-height: 36px;
  display: flex;
  align-items: center;
  max-height: 36px;
  /* border-radius: 8px 0 0 0px; */
  color: #214978;

  font-size: 9px;
  line-height: 10px;
  /* padding-right: 6px; */
  text-align: left;
  font-weight: 500;

  /* padding-top: 6px; */
  /* padding-bottom: 4px; */
  /* max-width: 100%; */
  /* min-width: 100%; */
  padding-right: 12px;
  /* text-transform: uppercase; */
  /* color: #ff6441; */
  color: #b764eb;
  white-space: normal;
  max-width: 120px !important;
  font-weight: 500;
}

.collapsedJobTitle {
  visibility: hidden;
}

.jobTitleContentUnit {
  margin-top: 10px;

  max-height: fit-content;
}

.jobTitleBlanker {
  opacity: 0.5;
  min-height: 25px;
  max-height: 25px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.jobTitleUsersContainer {
  margin-top: 10px;
}

.jtUser {
  color: #fff;
  border-radius: 5px 0 0 5px;

  border-right: none;
  margin-top: 4px;
  margin-bottom: 1px;
  min-height: 60px;
  max-height: 60px;
  text-align: left;
  font-weight: 500;
  font-size: 0.75em;
  display: flex;
  justify-content: space-between;

  line-height: 13px;
  max-width: 100%;
  font-weight: 500;
  cursor: pointer;
}

.font10 {
  font-size: 10px !important;
}

.jtUserDesktop:hover {
  background-color: #dde9f9;
}

.jtUserStandalone {
  cursor:default;
}
.jtUserStandalone:hover {
  background: none;
}

.jtUserWithAvail {
  margin-top: 18px;
  margin-bottom: 4px;
}

.availPresentButOnThisItem {
  min-height: 5px;
  min-width: 1px;
}

.secondaryUserToTheTeam {
  /* background-color: yellow; */
}


.minHeight102 {
  min-height:102px; 
}
.jtUserLeft {
  max-width: 8px;
  min-width: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3px;
  /* font-weight: 500; */
}

.userLightBarOn {
  background-color: #96e578;
  min-width: 3px;
  max-width: 3px;
  min-height: 46px;
  border-radius: 5px;
  margin-left: 2px;
  margin-top: -2px;
}

.lightBarOffOff {
  background-color: #6987a9;
  min-width: 3px;
  max-width: 3px;
  min-height: 46px;
  border-radius: 10px 0 0 10px;
  margin-left: -4px;
  margin-top: -2px;
  visibility: hidden;
}

.lightBarOnNonPrimary {
  min-height: 37px;
  max-height: 37px;
}

.jtUserRight {
  max-width: 78px;
  min-width: 78px;
  display: flex;
  padding-left: 1px;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 10px;
  /* padding-bottom: 8px; */
  color: #143051;
  margin-left: 0px;
  font-weight: 500;
  padding-right: 2px;
  /* min-height:100px !important */
}

.unitSplit {
  min-width: 100%;
  min-height: 1px;
  margin-top: 4px;
  background-color: #95aaba;
  opacity: 0.25;
}

.teamSchedContainer {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: flex-start;

  padding-left: 1%;

  max-height: calc(100vh - 266px);
  min-height: calc(100vh - 266px);
  overflow-y: auto;
  max-width: 100%;
  min-width: 100%;
  z-index: -1
  /* background-color: white; */
}

.teamSchedDesktop {
  max-height: calc(100vh - 203px);
  min-height: calc(100vh - 203px);
  padding-top: 8px !important;
  z-index: -3;
}
.desktopTeamSchedHeight {
  max-height: calc(100vh - 30px);
  min-height: calc(100vh - 30px);
  padding-top: 0px !important;
}

.desktopTeamSchedHeightStandalone {
  max-height: calc(100vh - 0px);
  min-height: calc(100vh -0px);
}
.deskopStandaloneRota {
left: 0px !important;  min-width: 100vw !important;
  max-width: 100vw !important;
  min-height:100vh !important

}

.standaloneDesktopRota {
  max-height: calc(100vh - 56px);
  min-height: calc(100vh - 56px);
}
.standaloneMobRota {
  max-height: calc(100vh - 65px);
  min-height: calc(100vh - 65px);
}

.calendarComponent {
  position: fixed;
  top: 0;
  left: 160px;
  min-width: calc(100vw - 160px);
  max-width: calc(100vw - 160px);
}

.mobCalComponent {
  position: fixed;
  top: 0;
  left: 0px;
  min-width: calc(100vw);
  max-width: calc(100vw);
}

.fixedLeft0 {
  left: 0 !important;
  border-left: none !important;
}
.teamSchedInnerCont {
  display: flex;

  justify-content: flex-start;
  min-width: 100%;
}


@media only screen and (min-width: 820px) {
  .teamSchedInnerCont {
    min-width: 102%;

  }
}

.nameColContainer {
  min-width: 100px;
  max-width: 100px;
}

.nameColContainerMob {
  min-width: 84px;
  max-width: 84px;
}

.w130px {
  min-width: 130px;
  max-width: 130px;
}

.teamSchedSplit {
  min-width: 3px;
  background-color: #bac5d3;
}

.teamSchedContent {
  display: flex;
  overflow-x: auto;
  font-size: 8px;
  padding-right: 30px;
  min-width:calc(100vw - 297px);
}

.dayCol {
  min-width: fit-content;

  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #bbcada;
  padding-bottom: 10px;
}

.dayCol:first-of-type {
  padding-left: 2px;
}

.dayCol:last-of-type {
  border: none;
  border-right: 1px solid #bac5d3;
  /* border-radius: 0 0 10px 0; */
}

.todayDayColBg {
  background-color: #ffffff !important;
  /* border-left: 3px solid #214978 !important; */
  /* border-right: 3px solid #214978 !important; */
}

.yesterdayDayTopUnit {
  border-radius: 0px 5px 0 0;
}

.tomorrowDayTopUnit {
  border-radius: 5px 0px 0 0;
}

.dayOddColBg {
}

.dayClosedBg {
  background-color: #e3ebf5;
}

.yesterdayDayCol {
  border1-right: none;
}

.dayCol:first-of-type {
  border-left: none;
}
.dayTopUnit {
  min-height: 34px;
  max-height: 34px;
  min-width: 100%;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  color: #143051;
  /* background-color: #ebf0f7; */
  color: #0af;

  border-bottom: 3px solid #bbcada;

  font-weight: 500;
}

.dayTopUnitOdd {
}

.a1DayTopUnit {
  /* background-color: #ebf0f7; */
  justify-content: space-between !important; 
  padding-left: 0px !important;
}

.lastDayTopUnit {
}
.dayCost {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 4px;
  padding-right: 3px;
  border-radius: 5px;
  margin-right: 6px;
  font-size: 0.9em;
  color: #6a98d1;
  padding-right: 6px;
  text-align: right;
  font-weight: 400;
  display: none;
}

.dayMinsSpan {
  color: #6a98d1;
  padding-left: 6px;
  border-left: 1px solid #abbcce;
}

.dayCostBgOdd {
}

.todayCostBg {
}

.blankDayTxt {
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;
  color: #4f86a2;
  /* border-left: 2px solid #0af; */
  padding-left: 4px;
  /* /* opacity: 0.15; */
}

.jtUserShiftsBox {
  background-color: white;

  color: #143051;
  border-radius: 4px;
  margin-top: 5px;
  min-height: 54px;
  max-height: 54px;
  text-align: left;
  font-weight: 500;
  font-size: 7px;
  display: flex;
  align-items: center;

  line-height: 15px;
  /* min-width: 142px;
  max-width: 142px; */
  min-width: 148px;
  max-width: fit-content;
  margin-left: 0px;
  cursor: pointer;
  animation-name: zoomBtnIn !important;
  animation-duration: 0.1s !important;
  z-index: 99999;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  border: 1px solid #dde9f9;
  margin-right: 3px;
  padding: 2px;
}

.jtUserShiftsBoxHove {
  padding-bottom: 3px;
  min-width: 148px;
}
.jtUserCostSpan {
  color: #0077ff;
  font-weight: 500;
  min-width: fit-content;
  font-size: 9px;
}

.shiftBoxLeft {
  min-width: 12%;
  min-height: 54px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: -2px;
  margin-right: 1px;
}

.shiftLight {
  min-height: 8px;
  max-height: 8px;
  min-width: 8px;
  max-width: 8px;
  margin-left: 5px;
  border-radius: 20px;
}

.shiftUnitPublicStatusIconImg {
  min-width: 9px;
  max-width: 9px;
  margin-left: 2px;
}

.partialIcon {
  min-width: 53px;
  margin-top: 20px;
  max-width: 53px;
  margin-left: 5px !important;
}

.unpublishedSizing {
  min-width: 14px;
  max-width: 14px;
  margin-left: 5px;
  margin-bottom: -2px;
  margin-top: -2px;
}
.shiftLightGreen {
  background-color: #96e578;
}

.shiftLightRed {
  background-color: #ba0000;
}

.shiftLightBlue {
  background-color: #e3ebf5;
}

.shiftBoxMiddle {
  min-width: 78%;
  min-height: 100%;
  max-height: 100%;
  padding-left: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.shiftBoxRight {
  min-width: 10%;
  min-height: 54px;
  max-height: 54px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: -0;
}

.shiftBoxRightMoveHere {
  max-width: 14px;
  margin-right: -2px;
}

.shiftBoxTypeTxt {
  transform: rotate(270deg);

  opacity: 1;

  min-width: 54px;

  margin-left: -19px;
  text-align: center;
  color: #fff;
  font-size: 10px;
  z-index: -1;
  padding-bottom: 2px;
}
.shiftBoxTypeTxtLeave {
  transform: rotate(270deg);

  border-radius: 0 0px 5px 5px;
  padding-bottom: 2px;

  min-width: 54px;

  margin-left: -19px;
  text-align: center;
  color: white;
  font-size: 10px;
  z-index: -1;
  margin-top: -1px;
  /* border-top: 2px solid #6c8cb3; */
}

.paidTimeOffStrip {
  color: white;
  font-size: 0.9em;
  min-width: 80px !important;
}
.shiftBoxTypeTxtTimeOff {
  transform: rotate(270deg);

  border-radius: 0 0px 5px 5px;
  padding-bottom: 2px;

  min-width: 54px;

  margin-left: -19px;
  text-align: center;
  color: white;
  font-size: 1.45em;
  z-index: -1;
  margin-top: -1px;
}

.shiftBoxTypeTxtOpen {
  transform: rotate(270deg);
  padding-bottom: 2px;
  color: #7fbb67;
  min-width: 51px;
  margin-left: -18px;
  text-align: center;
  font-size: 1.45em;
  z-index: -1;
  margin-top: -1px;
  border-top: 1px solid #e3e8ee !important;
  max-height: 16px;
}

.shiftBoxTypeTxtMoving {
  transform: rotate(270deg);

  padding-bottom: 2px;

  background-color: #143051;
  min-width: 54px;

  margin-left: -19px;
  text-align: center;
  color: white;
  font-size: 1.25em;
  z-index: -1;
  margin-top: -1px;
}

.shiftBoxTypeTxtOvertime {
  transform: rotate(270deg);
  border-radius: 0 0px 5px 5px;

  min-width: 54px;
  margin-left: -20px;
  text-align: center;
  padding-bottom: 1px;
  color: white;
  font-size: 1.3em;
}

.shiftBoxTypeTxtTil {
  transform: rotate(270deg);

  min-width: 54px;
  margin-left: -19px;
  padding-bottom: 3px;
  text-align: center;

  font-size: 1.3em;
}

.shiftBoxMiddleRow1 {
  min-height: 29px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 2px;
  min-width: 89px;
}

.shiftBoxTimeStrip {
  font-size: 2.4em;

  font-weight: 600;
  color: #143051;
  /* padding-bottom: 1px; */

  display: flex;
}

.shiftBoxTimeStripMyRota {
  font-size: 14px !important;

  font-weight: 600;
  color: #143051;
  padding-bottom: 1px;
padding-left:1px;
  display: flex;
}

.openShiftTimeStrip {
  font-size: 12px;
  margin-bottom: 2px;
  color: #516b8b;
}

.openShiftTimeStripMyRota {
  font-size: 13px !important;
  margin-bottom: 2px;
  color: #516b8b;
  margin-left: 4px;
  font-weight: 600;
  color: #143051;
}

.leaveTimeStrip {
  color: white;
  font-size: 14px;
  margin-bottom: -2px;
}

.leaveTimeStripHovered {
  color: white;
  font-size: 2.1em;
  margin-top: -24px;
}
.makeTimesSmaller {
  font-size: 11px;
}

.shiftBoxDurMins {
  font-size: 11px;
  color: #8ea7c5;
  /* margin-left: 1px; */
  font-weight: 600;
  margin-top: 2px;
  /* max-width: fit-content; */
  /* opacity: 0.5; */
  min-width: 80px !important;
}

.bidderQty {
  font-size: 10px;
  color: #8ea7c5;
  /* margin-left: 1px; */
  font-weight: 500;
}

.bidderQtyMyRota {
  font-size: 10px;
  color: #8ea7c5;
  /* margin-left: 1px; */
  font-weight: 500;
  margin-left:4px;
}
.makeTxt9374d6 {
}

.shiftBoxMiddleRow2 {
  display: flex;
  /* opacity: 0.5; */
  min-height: 23px;
  margin-bottom: 4px;
  margin-top: -2px;
}

.openMiddleRow {
  min-height: 22px;
}

.shiftBoxTagConainer {
  display: flex;
}

.shiftMoveIcon {
  min-width: 28px;
  max-width: 28px;
  min-height: 28px;
  max-height: 28px;
  border-radius: 5px;
  padding: 3px;
  margin-top: 4px;
  margin-left: 4px;
  background-color: #becde0;
  border: #abbcce 1px solid;
}

.delIcon {
  background-color: #9374d6;
  border: 1px solid #6e4eb2;
  min-width: 25px;
  max-width: 25px;
  min-height: 25px;
  max-height: 25px;
  border-radius: 5px;
  padding: 2px;
  padding-top: 1px;
  padding-bottom: 3px;
  margin-top: 4px;
  margin-left: 6px;
}

.delIconConfirm {
  background-color: #00aaff;
  border-color: #1964ba;
}

.expandShiftIcon {
  background-color: #0bf;
  border: 1px solid #0af;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 2px;

  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  border-radius: 5px;

  margin-top: 2px;
}
.emptyShiftBox {
  color: #bbcada;

  margin-top: 5px;
  min-height: 65px;
  max-height: 65px;
  text-align: left;
  font-weight: 500;
  font-size: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  line-height: 15px;
  min-width: 100%;
  max-width: 100%;
  margin-left: 3px;
  cursor: pointer;
  margin-top: -1px;
  padding-bottom: 7px;
}

.fadeSchedLoading {
  opacity: 0.6;
  pointer-events: none;
}

.userShiftsCollection {
  display: flex;
  padding-left: 6px;
  padding-right: 0px;
  min-height: 72px;
  align-items: center;
  padding-top: 28px;
  max-height: 92px;
}

.userShiftsCollectionMyRota {
  display: flex;
  padding-left: 5px;
  padding-right: 2px;
  margin-top: 3px;
  margin-bottom: -2px;
  min-height: 72px !important;
  align-items: center;
  max-height: 72px !important;
}

.myRotaJtUserParent {
  min-height: 78px;
  max-height: 78px;
  border-bottom: 2px solid rgb(149, 170, 186, 0.15);
}

.userShiftsCollectionWithAvail {
  min-height: 86px !important;
  /* msc-height: 86px !important; */
  margin-bottom: -8px;
  margin-top: 13px;
  padding-top: 0px !important;
}


.addShiftBox {
  background-color: #9a74eb;
  position: relative;
  min-height: 28px;
  max-height: 28px;
  color: white;
  border-radius: 5px;

  max-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5em;
  padding-bottom: 4px;
  margin-top: 5px;
  margin-left: 3px;

  font-weight: 500;
  border: 1px #7249c9 solid;
  cursor: pointer;
  z-index: 100;
  animation-name: heightDown !important;
  animation-duration: 0.2s !important;
}

.addShiftBoxNoBottomBorder {
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  background-color: #dbcbff;
}

.invisAddShiftBox {
  opacity: 0;
  visibility: hidden;
}

.extendAdd {
  background-color: #dbcbff;
  position: sticky;
  min-height: 120px;

  max-width: 144px;
  border-radius: 0 5px 5px 5px;
  margin-left: 3px;
  margin-top: -2px;
  border: 1px #7249c9 solid;
  z-index: 1;
  animation-name: heightDown !important;
  animation-duration: 0.2s !important;
}

.leftRightMonWeekContainer {
  display: flex;
  opacity: 0.6;
  min-width: 80px;
  max-width: 80px;
  padding-left: 0px;
  padding-bottom: 3px;
  justify-content: space-between;
}

.leftMonWeek {
  max-width: 22px;
  min-width: 22px;
  max-height: 22px;
  min-height: 22px;
  /* border-radius: 5px; */
  cursor: pointer;
  margin-right: 4px;
  margin-top: 2px;
  padding: 4px;
  /* border: 1px solid #bbcada; */
  /* background-color: #cbd7e6; */
  transform: scaleX(-1);
}
.rightMonWeek {
  max-width: 22px;
  min-width: 22px;
  max-height: 22px;
  min-height: 22px;
  /* border-radius: 5px; */
  cursor: pointer;
  margin-left: 4px;
  margin-top: 2px;
  padding: 4px;
}

.rightMonWeekDesktop:hover {
  background: #f5f8fa;
  border: 1px solid #d2e0f2;
  border-radius: 5px;
}

.rightMonWeek:active {
  /* background-color: #cbd7e6; */
}

.leftMonWeek:active {
  /* background-color: #cbd7e6; */
}

.leftMonWeekDesktop:hover {
  background: #f5f8fa;
  border: 1px solid #d2e0f2;
  border-radius: 5px;
}

.extendBtnsContainer {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 0px;
  padding-bottom: 18px;
  padding-left: 8px;
  min-height: 168px;
}

.extendBtnsContainerEmpty {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.extendBtnShift {
  background-color: white;
  color: #305581;
  max-width: fit-content;
  padding-left: 10px;
  padding-right: 10px;

  min-height: 32px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-size: 1.7em;
  font-weight: 500;

  border: 1px solid #cfd9e3;
  animation-name: fadeModalIn !important;

  white-space: nowrap;

  cursor: pointer;
  /* margin-top:5px;
  margin-bottom:5px; */
}

@media only screen and (min-width: 821px) {
  .extendBtnShift:hover {
    background-color: #fdf9ff;
    border-color: #bcafff;
  }
  .extendBtnShift:nth-child(1) {
    animation-duration: 0.25s !important;
  }
  .extendBtnShift:nth-child(2) {
    animation-duration: 0.5s !important;
  }
  .extendBtnShift:nth-child(3) {
    animation-duration: 0.75s !important;
  }

  .extendBtnShift:nth-child(4) {
    animation-duration: 1s !important;
  }
}
.fadeAddShBox {
}

.hideDayCost {
  opacity: 0;
  visibility: hidden;
}

.schedElipsisBtn {
  background-color: #4b1caf;
  color: white;
  border: 1px solid #140351;
  min-height: 30px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 5px;
  font-size: 2em;
  margin-top: 6px;
  cursor: pointer;
}

.generateBtn {
  font-size: 0.95em;
  margin-top: 8px;
  background-color: #0077ff;
  color: white;
  border: 1px solid #140351;

  border-radius: 10px;
  min-height: 30px;
  max-height: 30px;
  line-height: 26px;
  padding-left: 10px;
  font-weight: 500;
  padding-right: 10px;
  padding-top: 2px;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: fit-content;
}

.clearGenBtn {
  background-color: #4b1caf;
}

.genContainer {
}

.publishBtn {
  font-size: 0.95em;
  margin-top: 8px;
  color: #496375;
  border: 1px solid #496375;
  border-radius: 10px;
  min-height: 30px;
  max-height: 30px;
  line-height: 26px;
  max-width: 86px;
  padding-left: 10px;
  font-weight: 500;
  padding-right: 10px;
  padding-top: 2px;
  margin-right: 6px;
  cursor: pointer;
}

.currWeekIcon {
  margin-left: 6px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.5s !important;
  cursor: pointer;
  background-color: rgb(83, 155, 237);
  border: 1px solid #2c7eb9;
  max-width: 25px;
  min-width: 25px;
  max-height: 25px;
  min-height: 25px;
  border-radius: 5px;
  margin-top: 2px;
  padding:3px;
  border: 1px solid #bbcada;
  background-color: #cbd7e6;
}
@media only screen and (max-width: 400px) {
  .currWeekIcon {
    display: none;
    max-width: 20px;
    min-width: 20px;
    margin-left: 10px;
    animation-name: zoomModalIn !important;
    animation-duration: 0.5s !important;
    cursor: pointer;
  }
}

.horizAddShiftBtn {
  position: relative;
  min-height: 24px;
  max-height: 24px;
  color: #516b8b;
  border-radius: 5px;

  max-width: 30px;
  min-width: 30px;
  margin-top: -4px;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  padding-bottom: 3px;

  font-weight: 500;

  cursor: pointer;
  z-index: 90;
  margin-top: -24px;
  animation-name: zoomAddBtnIn !important;
  animation-duration: 0.6s !important;
}

.horizAddShiftBtn:hover {
}

.fadedHorizAdd {
  opacity: 0.25;
}

.fadedHorizAddOpen {
  opacity: 0.55;

  transform: scale(0.85);
}

.force1opac {
  opacity: 0.8 !important;
  color: white !important;
}

.horizAddBtnActive {
  border: 1px solid #140351;

  border-radius: 5px;
  padding-left: 1px;

  background-color: #214978;
  margin-left: 6px;
  margin-right: 8px;
}
.horizAddBtnActive:hover {
  background-color: #143051;
}

.horizAddBtnActiveGreen {
  border-radius: 5px;
  padding-left: 1px;
  margin-top: 13px;
}

.emptyShiftAddBtn {
  background: none;

  border: none;
  font-size: 2em;
  margin-top: -15px;
  color: #516b8b;
  margin-right: 20px;
  opacity: 0.1;
}

.emptyShiftAddBtn:hover {
  transform: scale(1.2);
  opacity: 0.8;
}

.selectCursorEmptyShifts {
  cursor: pointer;
}

.costAndHoursP {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 6px;
  margin-top: -5px;
  font-weight: 400;
  font-size: 0.92em;
  min-height: 30px;
  max-width: 100%;
}

.costAndHoursPOnNonPrimary {
  margin-top: -7px;
}

.addShiftInlineBox {
  min-width: fit-content;
  min-height: 162px;

  border-radius: 5px;

  margin-top: 5px;
  margin-right: 6px;
  margin-left: -8px;
  z-index: 100;
  animation-name: widthRight !important;
  animation-duration: 0.15s !important;
}

.rotatePlusTxt {
  transform: rotate(45deg);
  margin-top: 0px;
  color: white;
  margin-left: 2px;
  animation-name: rotateClockWise45 !important;
  animation-duration: 0.15s !important;
  line-height: 18px;
}

.userShiftsBgAddBtnActive {
  padding-bottom: 5px;
  border-radius: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.extendUserForAddShiftBox {
  min-height: 93px;

  min-width: 10px;
  animation-name: heightDown !important;
  animation-duration: 0.25s !important;
}

.aallaa {
  min-height: 88px !important;
}

.collapse {
  color: #143051;
  font-size: 0.4em;
  cursor: pointer;
}

.collapsedNameCol {
  max-width: 40px;
  min-width: 40px;
}

.collapseNameDiv {
  min-height: 100%;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  color: #143051;
}

.collapseIcon {
  cursor: pointer;
  min-width: 24px;
  margin-left: 16px;
  min-width: 24px;
  cursor: pointer;
  background-color: #cbd7e6;
  border-radius: 0px 5px 5px 0px;
  border-right: 1px solid #bbcada;
  border-top: 1px solid #bbcada;
  border-bottom: 1px solid #bbcada;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.reverseCollapseIcon {
  min-width: 24px;
  cursor: pointer;
  background-color: #cbd7e6;
  border-radius: 5px 0 0 5px;
  border-left: 1px solid #bbcada;
  border-top: 1px solid #bbcada;
  border-bottom: 1px solid #bbcada;
}

.reverseCollapseIconUserMob {
  /* margin-left: 0px; */
}

.reverseCollapseIconUser {
  /* margin-left: 106px; */
}

.currMonWeekBgDrop {
  border-color: #2c7eb9;
  border: 1px solid #e6eaee;
  /* background-color: #0bf; */
  background-color: white;
  color: #00aaff;
  background: url("../img/general/downArrowGrey.svg") no-repeat right #fff;
  background-position-x: 84px;
}

.addShiftInEmptyBox {
  font-size: 0.5em;
  min-height: 150px;
  max-height: 150px;
  align-items: center;
  align-items: space-between;
}

.emptyBoxAddBtnsContainer {
  min-height: 160px;

  align-items: space-between;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.emptyShiftsExtendBoxExtended {
  min-height: 167px;
  align-items: center;
  align-items: space-between;
}

.emptyExtendBtnsContainer {
  padding-right: 20px;
  min-height: 160px;
  align-items: flex-end;
  margin-top: -8px;
}

.emptyAddShiftCont {
  display: flex;
  max-height: 33px;
}

.emptBtnActive {
  border: 1px solid #143051;

  border-radius: 5px;
  padding-left: 1px;
  margin-top: 2px;
  padding-top: 2px;
  margin-right:6px;

  background-color: #214978;
}

.emptBtnActive:hover {
  background-color: #143051;
}

.shiftBoxUpper {
  background-color: blue;
  display: flex;
}

.shiftBoxLower {
  z-index: 999;
  margin-top: -7px;

  padding-left: 10px;

  display: flex;
  min-height: 18px;
  max-width: 90%;
  /* cursor: pointer; */
  margin-left: 20px;
}

.shTagInline {
  font-size: 1.35em;
  text-align: left;
  margin-right: 2px;
  padding-left: 6px;
  padding-right: 6px;
  min-width: -moz-fit-content;
  min-width: fit-content;
  border-radius: 4px;
  font-weight: 500;

  background-color: rgb(247, 250, 255);
  color: #fff;
  border: 1px solid #859dbb;
  padding-top: 0px;

  display: flex;
  align-items: center;
  max-height: 18px;
  z-index: 0;
  opacity: 1;
  margin-top: 0px;

  background-color: #c798c4;
  border: 1px solid #893c84;
}

.shiftActionsDiv {
  min-height: 40px;

  display: flex;
  justify-content: space-around;
  padding-bottom: 20px;
  margin-top: -21px;
  z-index: 9999;
  padding-left: 10px;
  margin-bottom: -38px;
  padding-right: 15px;
  opacity: 1 !important;
}

.shiftActionsIcon {
  max-width: 30px;
  cursor: pointer;
  animation-name: zoomBtnIn !important;
  animation-duration: 0.1s !important;
  z-index: 9999999;
  position: relative;
}

.shiftActionsIcon:hover {
  transform: scale(0.95);
}

.shiftActionsIcon:active {
  transform: scale(0.92);
}

.shiftActionsIcon:last-child {
  max-width: 24px;
  margin-top: 4px;
  opacity: 1;
  z-index: 9999;
}

.makeDeleteBigger {
  max-width: 28px;
  min-width: 28px;
}
.hideShiftBoxLower {
  visibility: hidden;
  opacity: 0;
}

.pullUpTimeStrip {
  margin-bottom: 12px;
}

.pullUpTimeStripHoveredLeave {
  margin-bottom: 11px;
}

.todayDayTopUnit {
  color: #143051;
}

.leaveShiftBox {
  background-color: #7788d8;
  color: white;
  border: 1px solid #6680a2;
  min-width: 133px !important;
  max-width: 133px !important;

}

.leaveShiftBoxHovered {
  /* background-color: #; */
  background-color: #6274c5;
  color: white;
  /* border: none; */
  min-width: 133px !important;
  max-width: 133px !important;

  padding-left: 2px;
  padding-right: 2px;
  border: 1px solid #6680a2;
  padding-top:3px
}

.absenceLeaveShiftBox {
  background-color: #7e8fa3;
  color: white;
  border: 1px solid #525f6f !important;
}

.myAbsRotaBorder {
  border: 2px solid #525f6f !important;

}
.firstHalfOffBg {
  background: #88afdc;
  background: linear-gradient( 315deg, #88afdc 50%, #b2c1d8 50%, #b2c1d8 97% );
}

.firstHalfOffBg:hover {
  /* background: rgb(33, 73, 120);
  background: linear-gradient(
    315deg,
    rgb(10 40 76) 50%,
    rgba(100, 115, 177, 1) 50%,
    rgb(73 83 123) 97%
  ); */
}

.lastHalfOffBg {
  background: #88afdc;
  background: linear-gradient( 135deg, #88afdc 51%, #b2c1d8 51%, #b2c1d8 97% )
}

.lastHalfOffBg:hover {
  /* background: rgb(33, 73, 120);

  background: linear-gradient(
    135deg,
    rgb(10 40 76) 51%,
    rgba(100, 115, 177, 1) 51%,
    rgb(73 83 123) 97%
  ); */
}

.firstHalfOffBgHovered {
  background: #88afdc;
  background: linear-gradient( 315deg, #6e9bcf 50%, #b2c1d8 50%, #b2c1d8 97% );
}

.lastHalfOffBgHovered {
  background: #88afdc;
  background: linear-gradient( 135deg, #6e9bcf 51%, #b2c1d8 51%, #b2c1d8 97% );
}

.leaveDurMins {
  color: #a8cfff;
  font-size: 10px;
  margin-top: 2px;
}

.leaveTypeNameRow {
  text-overflow: ellipsis;
  max-height: 30px;
  margin-bottom: -14px;
  color: #fff;
  font-size: 10px;
  z-index: 1000;
  margin-top: 14px;
  line-height: 12px;
  margin-bottom: -19px;
}

.longLeaveTypeName {
  text-overflow: ellipsis;
  max-height: 30px;
  margin-bottom: -17px;
  color: #fff;
  font-size: 9px;
  z-index: 1000;
  margin-top: 12px;
  line-height: 9px;
  max-width: 80px;
  padding-left: 24px;
}

.leaveTypeNameRowHovered {
  max-height: 30px;

  color: #fff;
  font-size: 10px;
  z-index: 1000;
  margin-top: 4px;
  margin-bottom: -4px;
  line-height: 9px;
  text-overflow: ellipsis;
  /* margin-top: 12px; */
  /* line-height: 9px; */
}

.hoveredLTnameLong {
  font-size: 9px;
  margin-top: 2px;
  margin-bottom: -4px;
  line-height: 9px;
}

.placeholderImgForMove {
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}

.DayString {
  min-width: fit-content;
  white-space: nowrap;
}

.hideFirstDayString {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

/* lolling */
.absenceFirstHalfOffBgHovered {
  background: rgb(33, 73, 120);
  background:linear-gradient(    315deg,    #525f6f 50%,    #b2c1d8 50%,    #b2c1d8 97%  );

  border: 1px solid #525f6f !important;
  padding-left:2px;
  padding-top:3px;
}

.absenceFirstHalfOffBg {
  
  background: rgb(126 143 163);
  background: linear-gradient(
    315deg,
    rgb(126 143 163) 50%,
    #b2c1d8 50%,
    #b2c1d8 97%
  );
}

.absenceLastHalfOffBg {
  background: rgb(126 143 163);
  background: linear-gradient( 135deg, rgb(126 143 163) 51%, #b2c1d8 51%, #b2c1d8 97% );
  border: 1px solid #525f6f !important;
  padding-left:2px;
  padding-right:2px;
}

.absenceLastHalfOffBgHovered {
  /* background: rgb(126 143 163); */
  background: linear-gradient( 135deg, #69788a 51%, #b2c1d8 51%, #b2c1d8 97% );
  border: 1px solid #525f6f !important;
  padding-left:2px;
}

/* #6978aa */
.saveBtnTxt {
  background-color: #07f;
  max-height: 35px;
  min-height: 35px;
  line-height: 26px;
  color: white;
  font-weight: 500;
  border: 1px solid #0469de;
  border-radius: 5px;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;

  cursor: pointer;
  min-width: 60px;
}
.saveBtnTxt:hover {
  background-color: #0469de;
}

.saveNewShiftBtn {
  background-color: #1e88e5;
  max-height: 35px;
  min-height: 35px;
  color: white;
  font-weight: 500;
  border: 1px solid #0469de;
  border-radius: 5px;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  margin-bottom: -2px;
  cursor: pointer;
  min-width: 60px;
}
.saveNewShiftBtn:hover {
  background-color: #1f78de;
}
.deleteSureTxt {
  display: flex;
  min-height: 25px;
  margin-top: -15px;
  margin-bottom: -20px;
}

.doNotShowTimeStrip {
  visibility: hidden;
  opacity: 0;
}

.schedToolsBtn {
  font-size: 14px;
  margin-top: 8px;
  /* background-color: #656095; */
  color: white;
  /* border: 1px solid #595672; */
  background-color: #a58fd4;
  border: 1px solid #a47fd0;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  line-height: 20px;
  padding-left: 8px;
  font-weight: 500;
  padding-right: 10px;
  /* padding-top: 2px; */

  cursor: pointer;
  display: flex;
  align-items: center;

  justify-content: space-around;

  min-width: fit-content;
  min-width: fit-content;
}



.toolsDownImg {
  max-width: 9px;
  margin-left: 7px;
}
.schedToolsBtnOpen {
  font-size: 0.85em;
  margin-top: 9px;
  background-color: #696682;
  color: white;
  border: 1px solid #696682;
  position: absolute;

  border-radius: 5px 5px 0 0;
  min-height: 35px;
  max-height: 35px;
  line-height: 26px;
  padding-left: 8px;
  font-weight: 500;
  padding-right: 10px;
  padding-top: 1px;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 90px;
  max-width: 90px;
  right: 4%;
  top: 2px;
  /* font-size: 0.85em; */
}

.toolsUnderlay {
  background-color: rgb(43, 56, 77, 0.15);
  position: absolute;
  top: 116px;

  min-width: 100vw;
  min-height: 100vh;
  z-index: 999999;
  cursor: pointer;
  /* -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%); */

  /* -webkit-backdrop-filter: blur(5px); */
  /* backdrop-filter: blur(2px); */
  /* background-color: rgb(122 122 122 / 50%); */
}

.limBtnFade {
  opacity: 0.5;
  /* background-color: #bdadc4 !important; */
  /* border-color:#9b8ba2  !important */
}
.actionsDropper {
  background-color: #fff;
  border: 1px solid #696682;
  position: fixed;
  min-height: 0px;
  min-height: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  min-width: 154px;
  top: 158px;
  right: 4%;
  z-index: 9999;
  border-radius: 10px 0 10px 10px;
  animation: slideOpenMobActionsTools 0.25s forwards;
}



@keyframes slideOpenMobActionsTools {
  0% {
    min-height: 0;
    max-height: 0;
  }
  100% {
    min-height: 442px; /* Adjust this value to control the final height of the rectangle */
    max-height: 442px; /* Adjust this value to control the final height of the rectangle */
  }
}

.actionsDropperPublish {
  background-color: #fff;
  border: 1px solid #696682;
  position: absolute;
  min-height: 0;
  max-height: 0;
  padding-top: 0px;
  padding-bottom: 0px;

  min-width: 154px;
  top: 45px;
  right: calc(4.2% + 152px);
  z-index: 999;
  margin-right: 343px;
  border-radius: 5px 0 5px 5px;
  animation: slideOpenPublishTools 0.25s forwards;
}

@keyframes slideOpenPublishTools {
  0% {
    min-height: 0;
    max-height: 0;
  }
  100% {
    min-height: 150px; /* Adjust this value to control the final height of the rectangle */
    max-height: 150px; /* Adjust this value to control the final height of the rectangle */
  }
}

.fadeNone {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

.limitsDropperDesktop {
  background-color: #fff;
  border: 1px solid #696682;
  position: absolute;
  min-height: 0;
  max-height: 0;
  padding-top: 0px;
  padding-bottom: 0px;

  min-width: 154px;
  top: 45px;
  right: 4.2%;
  z-index: 999;
  margin-right: 376px;
  border-radius: 5px 0 5px 5px;
  animation: slideOpenLimitsTools 0.25s forwards;
}

@keyframes slideOpenLimitsTools {
  0% {
    min-height: 0;
    max-height: 0;
  }
  100% {
    min-height: 76px; /* Adjust this value to control the final height of the rectangle */
    max-height: 76px; /* Adjust this value to control the final height of the rectangle */
  }
}

.copyDropperDesktop {
  background-color: #fff;
  border: 1px solid #696682;
  position: absolute;
  min-height: 0px;
  max-height: 0px;
  padding-top: 0px;
  padding-bottom: 0px;

  min-width: 154px;
  top: 45px;
  right: 4.2%;
  min-height: 0px;
  max-height: 0px;
  z-index: 999;
  margin-right: 258px;
  border-radius: 5px 0 5px 5px;
  animation: slideOpenCopyTools 0.25s forwards;
}

@keyframes slideOpenCopyTools {
  0% {
    min-height: 0;
    max-height: 0;
  }
  100% {
    min-height: 184px; /* Adjust this value to control the final height of the rectangle */
    max-height: 184px; /* Adjust this value to control the final height of the rectangle */
  }
}

.toolsDropperDesktop {
  background-color: #fff;
  border: 1px solid #696682;
  position: absolute;
  /* min-height: fit-content;
  max-height: fit-content; */
  /* min-height: 511px !important;
  max-height: 511px !important; */
  padding-top: 0px;
  padding-bottom: 0px;

  min-width: 154px;
  /* min-height: 0px;
  max-height: 0px; */
  top: 45px;
  right: 4.2%;
  z-index: 999;
  margin-right: 158px;
  border-radius: 5px 0 5px 5px;
  animation: slideOpen 0.2s forwards;
}
@keyframes slideOpen {
  0% {
    min-height: 0;
    max-height: 0;
  }
  100% {
    min-height: 470px; /* Adjust this value to control the final height of the rectangle */
    max-height: 470px; /* Adjust this value to control the final height of the rectangle */
  }
}

.schedActionItem {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  min-height: 40px;
  min-width: 100%;
  padding-right: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #143051;
  border-bottom: 1px solid #cce3ff;
  cursor: pointer;
}

.schedActionItem:last-child {
  border: none;
}

.schedActionItem:hover {
  background-color: #f4f8fe;
  color: #897cf6;
  /* transform: scale(1.025); */
}

.closeToolsImg {
  max-width: 8px;
  margin-left: 6px;
}

.creatingShiftFader {
  pointer-events: none;
  opacity: 0.3;
}

.publishedStatusPublished {
  font-size: 0.74em;
  margin-top: 8px;
  background-color: #7fbb67;
  color: #5a9e3f;
  color: white;
  border: 1px solid #5a9e3f;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  line-height: 26px;
  padding-left: 2px;
  font-weight: 500;
  padding-right: 2px;
  padding-top: 1px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-right: 10px;
  text-align: center;
  max-width: 60px;
  line-height: 10px;
  cursor: default;
}

.publishedStatusUnpublished {
  font-size: 0.74em;
  margin-top: 8px;
  background-color: #7495ba;
  color: #496375;
  color: white;
  border: 1px solid #496375;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  line-height: 26px;
  padding-left: 2px;
  font-weight: 500;
  padding-right: 2px;
  padding-top: 1px;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;

  margin-right: 10px;
  text-align: center;
  max-width: 60px;
  line-height: 10px;
  cursor: default;
}

@media only screen and (min-width: 620px) {
}

.copyToolsBtn {
  border-radius: 5px;
  color: #fff;
  min-width: 111px;
  min-height: 30px;
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
  align-items: center;
  margin-right: 7px;
}

.schedToolsSideBtnClose {
  background-color: #656095;
  max-width: 28px;
  position: fixed;
  top: 128px;
  right: 4.3%;
  margin-right: 334px;
  border-radius: 0 5px 0px 0;
  border: 1px solid #696682;
  border-bottom: none;
  min-width: 28px;
  min-height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2px;
  padding-top: 2px;
}

.schedToolsSideBtnCloseLimits {
  background-color: #656095;
  max-width: 28px;
  position: fixed;
  top: 128px;
  right: 4.3%;
  margin-right: 216px;
  border-radius: 0 5px 0px 0;
  border: 1px solid #0af;
  border-bottom: none;
  min-width: 28px;
  min-height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2px;
  padding-top: 2px;

  border-color: #696682;
}

.schedToolsSideBtnCloseCopy {
  background-color: #656095;
  max-width: 28px;
  position: fixed;
  top: 128px;
  right: 4.3%;
  margin-right: 98px;
  border-radius: 0 5px 0px 0;
  border: 1px solid #696682;
  border-bottom: none;
  min-width: 28px;
  min-height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2px;
  padding-top: 2px;
}

.activeToolsCogImg {
  max-width: 13px;
  margin-left: 0px;
  margin-top: 0px;
  margin-right: 7px;
}

.inactiveCogImg {
  margin-left: 0px;
  margin-right: 8px;
  margin-top: 1px;
}

.inactiveCogImgMob {
  margin-left: 0px;
  margin-right: 8px;
  margin-top: -1px;
}

.desktopToolsTxtActive {
  font-weight: 500;
  font-size: 0.85em;
  margin-left: -9px;
  color: white;
}

.closeToolsImgDesktopTools {
  min-width: 10px;
  max-width: 10px;
  margin-top: -1px;
  margin-right: 5px;
  margin-left: -6px;
}

.closeToolsImgDesktop {
  min-width: 10px;
  max-width: 10px;
}

.toolsToolsMobOnlyBtn {
  background-color: #8f85eb;
  border: 1px solid #756dba;
  border-radius: 5px;
  color: #fff;
  min-width: 94px;
  max-width: 94px;
  min-height: 30px;
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
  align-items: center;
  margin-right: 7px;
}

.toolsToolsMobOnlyBtn:hover {
  background-color: #8d85d2 !important;
}

.pubToolsBtn {
  min-width: 116px;
}

.extendPubBtn {
  min-width: 132px;
}

.toolsCopy {
  max-width: 10px;
  margin-right: 7px;
  margin-top: 2px;
}

.toolsLimit {
  max-width: 13px;
  margin-right: 6px;
  margin-top: 2px;
}

.whitePowerLim {
  max-width: 13px;
  min-width: 13px;
  margin-right: 7px;
  padding: 1px;

}
.toolsPublish {
  max-width: 13px;
  margin-right: 8px;
  margin-left: 2px;
  margin-top: 2px;
}
.toolsCog {
  max-width: 13px;
  margin-right: 7px;
  margin-top: 2px;
}
.desktopToolsBtnTxt {
  background-color: #8f85eb;
  min-width: 80px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding-bottom: 1px;
  cursor: pointer;
  border: 1px solid #756dba;
  border-radius: 5px 0 0 5px
}

.desktopToolsBtnTxtToolsOnly {
  min-width: 66px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding-bottom: 1px;
  cursor: pointer;
  /* border: 1px solid #696682; */
  border-radius: 5px 0 0 5px;
  border-right: none;
  padding-left: 7px;
  /* background-color: #8f85eb; */
  /* border: 1px solid #756dba; */
}

.desktopToolsBtnTxtToolsonly {
  border-right: none;
}

.desktopToolsBtnTxtToolsonly:hover {
}

.toolsPublishTxt {
  min-width: 85px;
}
.extendPubTxt {
  min-width: 126px;
  padding-right: 3px;
  margin-right:1px;
}
.desktopToolsBtnTxt:hover {
  background-color: #8d85d2;
}
.toolsDownArrContainer {
  display: flex;
  /* background-color: #656095; */
  align-items: center;
  justify-content: center;
  min-width: 28px;
  min-height: 35px;
  margin-left: 2px;
  cursor: pointer;
  /* border: 1px solid #595672; */
  background-color: #8f85eb;
  border: 1px solid #756dba;
}

.toolsDownArrContainerToolsOnly {
  display: flex;

  align-items: center;
  justify-content: center;
  min-width: 28px;
  min-height: 30px;

  cursor: pointer;
  /* border: 1px solid #696682; */
  border-radius: 0 5px 5px 0;
  border-left: none;
  margin-right: 6px;
  /* background-color: #8f85eb; */
  /* border: 1px solid #756dba; */
}

.toolsDownArrContainer:hover {
  background-color: #8d85d2;
}

.toolsDownArrContainerWithoutMarginL {
  margin-left: 0px;
  border-left: 0px solid #0af;
}

.toolsDownArrContainerWithoutMarginL:hover {
  background-color: none;
  border-color: #05f;
}
.desktopTooslBtnDownImg {
  max-width: 10px;
  min-width: 10px;
}

.fadePublishBtn {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}

.shCostSpan {
  margin-left: 8px;
  /* padding-left: 5px; */
  /* padding-right: 5px; */
  color: #cce3ff;

  color: #143051;

  padding-bottom: 1px;
  font-weight: 600;
  font-size: 11px;
  border-radius: 10px;
}

.salariedCostSpan {
  margin-left: 5px;
  padding-left: 1px;
  padding-right: 2px;

  color: #bac5d3;
  padding-bottom: 1px;
  font-weight: 600;
  font-size: 10px;
  border-radius: 2px;
}

.salariedCostSpanLeave {
  color: #a8cfff;
  font-size: 10px;
}

.openShiftsNameDiv {
  min-height: 50px;
  max-height: 50px;
  /* margin-bottom: -9px; */
  font-size: 0.9em;
  font-weight: 500;
  color: #143051;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border-right:3px solid #dbe8f7;
  border-left:1px solid #dbe8f7
}

.openShiftsNameDiv2 {
  min-height: 50px;
  max-height: 50px;
  margin-bottom: -9px;
  font-size: 10px;
  font-weight: 500;
  color: #143051;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  margin-right: 8px;
}
.openShiftsArrayDiv {
  min-height: 50px;
  max-height: 50px;
  /* background-color: #fff; */
/* border-bottom:1px solid #e3e8ee; */
  /* margin-bottom: -10px; */
  display: flex;
  padding-left: 5px;
  padding-right: 13px;
}

.collapseOpenTxt {
  font-size: 0.55em;
  transform: rotate(-25deg);
  margin-left: 7px;
}

.openShiftUnit {
  background-color: #fff;
  max-height: 40px;
  min-height: 40px;
  margin-left:2px;
  /* border-left:5px solid #00aaff; */
  max-width: 135px !important;
  min-width: 135px !important;
  border: 1px dashed #bbcada !important;
  box-shadow: none !important;
}
.myOpenShiftUnit {
  background-color: #fff;
  max-height: 40px;
  min-height: 40px;
  border-left: 5px solid #00aaff;
  max-width: 135px !important;
  min-width: 135px !important;
  border: 2px dashed #bbcada !important;
  box-shadow: none !important;
}
.myOpenShiftUnit:hover {
  background-color: #fff;
  max-height: 40px;
  min-height: 40px;
  border-left: 5px solid #00aaff;
  max-width: 135px !important;
  min-width: 135px !important;
  border: 2px dashed #214978 !important;
  box-shadow: none !important;
  padding-left: 2px !important;
  /* padding-right:5px !important */
}
.openShiftUnit:hover {
  /* border:2px solid #143051;
  padding-left:2px !important;
  border-left:5px solid #143051 !important; */
  /* margin-left:2px !important; */
  max-width: 135px !important;
  min-width: 135px !important;
  border-color: #214978 !important;
}

.onShiftBoxRight {
  max-width: 14px;
  margin-left: 4px !important;
}

.onShiftBoxRightMy {
  max-width: 14px;
  margin-left: 16px !important;
}

.greenHorizBtn {
  opacity: 0.5;
  margin-top: 13px;
}

.zeroOpensHeight {
  max-height: 30px;
  min-height: 30px;
}

.noShiftsOpenSpanTxt {
  opacity: 0.5;
  display: flex;
  align-items: center;
}

.openShiftsToTheRight {
}

.addOpenShiftEmpty {
  min-height: 40px;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3em;
  padding-bottom: 5px;
  font-weight: 500;
  color: #9374d6;
  opacity: 0.5;
  cursor: pointer;
  margin-left: 4px;
  border-radius:6px;
  margin-bottom:5px;
  margin-top:5px;
  /* padding-top:5px; */
}

.addOpenShiftEmptyNil {
  min-height: 20px;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3em;
  padding-bottom: 5px;
  font-weight: 500;
  color: #9374d6;
  opacity: 0.5;
  cursor: pointer;
  margin-left: 4px;
  border-radius:6px;
  margin-bottom:5px;
  margin-top:5px;
}



.addShiftPlusTxt {
  cursor: pointer;
  font-size: 28px;
  /* margin-left:5px; */
}



@media only screen and (min-width: 820px) {
  .addOpenShiftEmpty:hover {
    background-color: #eeebf3;
    border-bottom: 1px solid #e3ebf5;
    padding-top: 1px;
  }
  .addOpenShiftEmptyNil:hover {
    background-color: #eeebf3;
    border-bottom: 1px solid #e3ebf5;
    padding-top: 1px;
  }
}


.userHoursP {
  min-height: 16px;
  text-align: right;
  display: flex;
  align-items: center;
  font-size: 9px;
  color: #9dabbe;
  border-radius: 3px;
  font-weight: 500;
}

.userHoursPMob {
  margin-right: 7px;
}

.userHoursPMyRota {
  min-height: 16px;
  text-align: right;
  display: flex;
  align-items: center;
  padding-left: 0px;
  padding-right: 8px;
  color: #8ea7c5;
  border-radius: 3px;
  font-weight: 500;
  font-size: 9px;
}

.smallerIcon {
  max-width: 26px;
}

.claimantsTitle {
  min-width: 100%;
  margin-left: 3%;
}

.selectClaimantTitle {
  background-color: #496375;
  border: 1px solid #143051;
  color: white;
  max-width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px 5px 5px 0;
  max-height: 30px;
  display: flex;

  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: -10px;
  position: relative;
  z-index: 999;
  font-size: 16px;
}
.claimantsDiv {
  max-width: 90%;
  background-color: #f4f9ff;
  min-height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 0.9em;
  font-weight: 400;
  margin-bottom: 20px;
  border: 1px solid #bfccdc;
  border-radius: 0 5px 5px 5px;
  position: relative;
  z-index: 1;
}

.userClaimantsDiv {
  max-width: 100%;
  background-color: #f4f9ff;
  min-height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 0.9em;
  font-weight: 400;
  margin-bottom: 20px;
  border: 1px solid #bfccdc;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  margin-top: 16px;
}

.claimantUnit {
  border-bottom: 1px solid #dde1e6;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.claimantUnit:first-child {
  padding-top: 10px;
}
.claimantUnit:last-child {
  border: none;
}

.userClaimantUser {
  border-bottom: 1px solid #dde1e6;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.userClaimantUser:last-child {
  border: none;
}

.giveClaimantBtn {
  background-color: #07f;
  color: white;
  border: #0469de solid 1px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  border-radius: 5px;
  padding-right: 10px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 2px;
}

.giveClaimantBtn:active {
  transform: scale(0.94);
}

.claimUserTopDiv {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cancelClaim {
  font-size: 12px;
  background: #214978;
  border: 1px solid #143051;
  color: white;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  max-height: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: 500;
  margin-left: 82px !important;
  cursor: pointer;
}

.cancelClaim:hover {
  background-color: #143051;
}
.giveClaimantBtn:hover {
  background-color: #0469de;
}
.claimantTxtLeft {
  max-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 10px;
  padding-left: 4px;
  padding-bottom: 10px;
}

.minW100 {
  min-width: 100%;
}

.claimantName {
  font-size: 0.9em;
  max-height: 26px;
  display: flex;
  align-items: center;
  font-weight: 500;
  min-width: 88px;
}

.claimantTsSpan {
  font-size: 12px;
  font-weight: 500;
  max-height: 26px;
  display: flex;
  align-items: center;
  color: #bac5d3;
  line-height: 16px;
}

.usersHoursThisWeek {
  font-size: 0.75em;
  font-weight: 400;
  color: #8ea7c5;
  max-height: 26px;
  display: flex;
  align-items: center;
  margin-top: -2px;
}


.hoveredDayColBorderLandR {
}

.addShiftHereBox {
  background-color: #00bbff;

  border: 1px solid #5479a3;
  color: #143501;
  border-radius: 5px;
  max-height: 48px;
  min-height: 48px;
  margin-top: 8px;
  margin-left: 10px;
  padding-top: 6px;

  padding-left: 8px;
  padding-right: 18px;

  display: flex;

  align-items: flex-start;
  justify-content: center;
  font-size: 1.5em;
  font-weight: 500;
  animation-name: slideMoveHere !important;
  animation-duration: 0.2s !important;
  cursor: pointer;
}

.addShiftHereBox:hover {
  background-color: #0cf;
  transform: scale(1.025);
}

.MoveShiftHereTitleTxt {
  color: #fff;
  font-size: 1.2em;
}

.moveShiftHereTimes {
  color: #3b597c;
  font-size: 1em;
  margin-top: 0px;
}

.moveHereTxtContainer {
  text-align: left;
}
.moveHereArrImg {
  max-width: 40px;
  margin-top: -4px;
}

.movingShiftBg {
  background-color: #fff;

  border: 1px dashed #143051;
  border-radius: 5px;
}

.allOtherShiftBgsWhenMoveActive {
  opacity: 0.4 !important;
}

.moveActiveRowBg {
}

.selectedDayUserBgWhenActiveMove {
  background-color: #d2d9e2;
}

.hoveredMoveHereCell {
  border-radius: 0px;
  background-color: #f3f8ff;
}

.hoveredMoveHereCellNone {
  -webkit-box-shadow: inset 0px 0px 0px 0px #f00;
  -moz-box-shadow: inset 0px 0px 0px 0px #f00;
  box-shadow: inset 0px 0px 0px 0px #f00;
  border-radius: 0px;
  background-color: #8ea7c5;
}

.plusShiftUnit {
  color: #2c7eb9;
  font-size: 4em;
  font-weight: 500;
  min-width: 20px;
  min-height: 30px;
  max-height: 30px;
  max-width: 20px;
  margin-left: 9px;
  margin-right: 9px;
  line-height: 30px;

  border-radius: 5px;
  margin-top: -24px;
}

.moveShiftBoxHereBox {
  border: 1px dashed #143051;
  margin-top: -24px;
}
.dupelicateShiftBoxHereBox {
  border: 1px dashed #a387df;
  margin-top: -22px;
  min-width: 143px;
  padding-right:0px !important
}

.pointerOverCell {
  cursor: pointer;
}

.emptyShiftsMoveHere {
  font-size: 0.42em;
  margin-left: -100px;
  margin-right: 0px;
  margin-top: -4px;
}

.moveHereTxt {
  font-size: 11px;
  color: #5479a3;
  color: #07f;
  margin-left: 0px;
  font-weight: 500;
  margin-top: 2px;
  min-width: 122px;
}

.x298329842 {
  min-width: 8px !important;
  max-width: 8px !important;
  /* background-color:orange !important */
}

.duplicateRightBg {
  background-color: #a387df;
  margin-right: -22px;
}

.cancelMoveOrDupeBtn {
  max-width: 25px;
  margin-left: 10px;
  margin-right: 0px;
  background-color: #140351;
  min-width: 25px;
  padding: 7px;
  border-radius: 5px;
  background-color: #214978;
  border: 1px solid #140351;
  max-height: 25px;
  margin-top: -22px;
}

.cancelMoveOrDupeBtn:hover {
  opacity: 0.8;
}

.cancelMoveOrDupeBtnEmpty {
  max-width: 25px;
  margin-left: 10px;
  margin-right: -80px;
  min-width: 25px;
  max-height: 25px;
  padding: 7px;
  border-radius: 5px;
  background-color: #214978;
  border: 1px solid #140351;
}

.cancelMoveOrDupeBtnEmpty:hover {
  opacity: 0.8;
}

.moveShiftHereDottedIcon {
  max-width: 24px;
  margin-left: -16px;
}



.moveShiftHereDottedIconInline {
  max-width: 24px;
  margin-left: 10px;
  margin-right: -20px;
  margin-top: -21px;
}



.blueLoadingMoveImg {
  max-width: 50px;
  min-width: 50px;
  max-height: 18px;
  margin-left: -10px;
  margin-top: -2px;
}

.blueLoadingMoveImgInline {
  max-width: 50px;
  min-width: 50px;
  max-height: 18px;
  margin-top: -22px;
  margin-left: 10px;
  margin-right: 10px;
}

.makeCanBtnInvis {
  visibility: hidden;
  pointer-events: none;
}

.pasteNotAvail {
  opacity: 0.5;
  pointer-events: none;
}

.copiedWeekTxt {
  pointer-events: none;
  opacity: 0.5;
}

.jtUserSchedLimitDiv {
  min-width: 128px;
  display: flex;
  margin-left: 8px;
  padding-left: 2px;
  min-height: 32px;
  max-height: 32px;
  padding-right: 2px;
  margin-top:3px;
  justify-content: flex-end;
}
.schedLimitDataTxtCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.totAllowedOff {
  color: #4b1caf;
  font-size: 1.22em;
  display: none;
}

.totAllowedOffActual {
  color: #214978;
  font-size: 12px;
  font-weight: 500;
  margin-top: -1px;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 1px;
  min-width: -moz-fit-content;
  min-width: fit-content;
  white-space: nowrap;
}

.userTotAllOffActual {
  color: #fff;
  font-size: 1.6em;
  font-weight: 500;
  margin-top: -1px;
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 3px;
  min-width: -moz-fit-content;
  min-width: fit-content;
  white-space: nowrap;
  background-color: #51971f;
  border-radius: 3px;
  padding-top: 2px;
  border: 1px solid #143051;
}

.schedLimitIcon {
  max-width: 20px;
  margin-left: 6px;
  margin-right: 4px;
  cursor: pointer;
}

.schedLimitIcon:active {
  transform: scale(0.94);
}

.schedLimitIcon:last-child {
  margin-right: 6px;
  margin-left: 3px;
}

.secondaryUserDiv {
  font-size: 0.7em;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 0px 0 0 5px;
  color: #214978;
  margin-top: -13px;
  margin-bottom: -4px;
  padding-bottom: 1px;
  padding-right: 6px;
  z-index: 99999999;
  border-top: 1px solid #bac5d3;
  margin-left: 5px;
  padding-top: 2px;
  visibility: hidden;
}

.loadingOpensUnit {
  min-height: 50px;
  max-height: 50px;
}
.limitsDropper {
  background-color: #fff;
  border: 1px solid #696682;
  position: absolute;
  min-height: fit-content;
  max-height: fit-content;
  padding-top: 0px;
  padding-bottom: 0px;

  min-width: 154px;
  top: 45px;
  right: 4%;
  z-index: 999;
  border-radius: 10px 0 10px 10px;
  animation-name: heightDown !important;
  animation-duration: 0.1s !important;
}

.limitTitle {
  font-size: 10px;
  font-weight: 500;
  min-height: 100%;
  display: flex;
  align-items: center;
  padding-right: 6px;
  color: #859dbb;
}

.limitOffSpan {
  font-size: 0.8em;
  opacity: 0.7;
}

.limitExceededCurrOff {
}

.limitOKMaxOff {
  border-radius: 3px;
  padding-left: 6px;
  padding-right: 6px;
  color: #cb5858 !important;
  border: 1px solid #cb5858;
  background-color: #fff2f2;
  min-height: 20px;
  padding-top: 4px;
  margin-top:-3px;
}

.makeOpenTitle {
  margin-left: 18px;
  font-size: 1.15em;
  line-height: 28px;
  font-weight: 500;
  color: #143051;
  margin-top: 12px;
  max-width: fit-content;
}

.assignTitle {
  margin-left: 23px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  color: #143051;
  margin-top: 12px;
  margin-bottom: 8px;
}

.makeOpenBtn {
  font-size: 14px;
  color: #fff;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 21px;

  border-radius: 5px;
  min-height: 35px;
  margin-top: 18px;
  margin-bottom: 8px;
  cursor: pointer;
  min-width: fit-content;
}

.makeOpenBtn:hover {
  background-color: #bbcada;
}

.viewChBtn {
  font-size: 14px;
  color: #fff;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  font-weight: 500;
  cursor: pointer;
  padding-left: 13px;
  padding-right: 13px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 21px;

  border-radius: 5px;
  min-height: 35px;
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  min-width: fit-content;
}

.viewChBtn:hover {
  background-color: #bbcada;
}

.pViewChBtn {
  background-color: #9a74eb;
  border: 1px solid #965ade;
  padding-left: 10px;
  padding-right: 10px;
}

.pViewChBtn:hover {
  background-color: #965ade;
}
.showChBackBtn {
  font-size: 14px;
  color: #fff;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 5px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 21px;

  border-radius: 5px;
  min-height: 35px;
  margin-top: 18px;
  margin-bottom: 3px;
  cursor: pointer;
  max-width: 70px;
}

.showChBackBtn:hover {
  background-color: #bbcada;
}

.backChangesImg {
  max-width: 12px;
  margin-right: 6px;
  margin-left: -5px;
}


.amendsGoImg {
  max-width: 10px;
  margin-left: 4px;
  margin-right: 0px;
  transform: rotate(180deg);

}

.outstandingChReqsSpan {
  border-left: 1px solid white;
  max-height: 20px;
  line-height: 20px;
  margin-left: 8px;
  padding-left: 7px;
  padding-right: 2px;
}

.addTagTxtBtn {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 2px;
  padding-right: 2px;
  max-height: 30px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  margin-top: 17px;
  margin-bottom: 8px;
  color: #496375;
  border: #e3e8ee solid 2px;
  background-color: #fff;
  min-width: 87px;
}

.addTagTxtBtn:active {
}

.addTagTxtBtn:hover {
  border-color: #c5d1e1;
}

.makeOpenTxtDiv {
  max-width: 60%;
}

.addShftCostRow {
  display: flex;
  justify-content: space-between;
  min-width: 100px;
}
.addShiftCostTxt {
  margin-top: 7px;
}

.makeOpenExplainTxt {
  color: #516b8b;
  font-weight: 400;
  font-size: 12px;
  margin-left: 23px;
  line-height: 16px;
  /* margin-top: 5px; */
  margin-bottom: 13px;
  margin-top: 6px;
}

.assignOpenDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 150px;
  min-width: 150px;
  line-height: 26px;
  height: 35px;
  background: url(/static/media/downArrowWhite2.887d57ce.svg) no-repeat right
    #4b1caf;
  border: 1px solid #9374d6;
  -webkit-appearance: none;
  background-position-x: 124px;
  display: flex;
  cursor: pointer;
  background-color: rgb(83, 155, 237);
  border-color: #2c7eb9;
  margin-top: 14px;
  margin-right: 15px;
}

.chooseClaimantSpan {
  font-weight: 400;
  margin-left: 10px;
  font-size: 14px;
}

.limitRowInitialCol {
  /* background-color: #e3e8ee; */
  /* color: white; */
  min-height: 30px;
  max-height: 30px;
  border-right: 3px solid #dbe8f7;
    margin-bottom: 0px;
  border-radius: 5px 0 0 5px;
  /* border-top: 1px solid #bbcada;
  border-bottom: 1px solid #bbcada;
  border-left: 1px solid #bbcada; */
  font-weight: 500;
  padding-right: 8px;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
}

.limitTabPowerIcon {
  max-width: 22px;
  /* min-width: 24px; */
  margin-left: 3px;
  cursor: pointer;

  padding: 4px;
  border: 1px solid #30305f;
  border-radius: 5px;
  min-width: 22px;
  background-color: #7a7ac1;
}

.limitTabPowerIconOff {
  max-width: 24px;
  /* min-width: 24px; */

  margin-left: 3px;
  cursor: pointer;

  padding: 4px;
  border: 1px solid #96a6b9;
  border-radius: 5px;
  min-width: 21px;
  background: none;
  background-color: #bbcada;
}
.limitTabPowerIconOff:hover {
  background-color: #cbc7df;
}
.limitRowDayDataDiv {
    /* background-color: #e3e8ee; */
    /* color: white; */
    min-height: 30px;
    max-height: 30px;
    /* margin-top: 10px; */
    margin-bottom: 0px;
    border-bottom: 1px solid #f2f5f8;
    border-top:1px solid #e3e8ee;

    /* border-bottom: 1px solid #bbcada; */
    padding-right: 5px;
    padding-left: 3px;
}

.collapseLimitTitle {
  font-size: 0.8em;
  padding-left: 6px;
}
.limitTitleAndPower {
  display: flex;
  max-width: 88px;
cursor: pointer;
  align-items: center;
  min-height: 100%;
  padding-left: 5px;
  margin-right:-3px;
}
.limitDayTitle {
  font-size: 10px;
  padding-right: 8px;
  margin-top: 2px;
color: #859cb6;
  font-weight: 500;
}

.limitByAllTxt {
  padding-right: 5px;
  font-size: 10px;
  margin-left: 10px;
  margin-top: 2px;
  font-weight: 500;
  color:#214978
}

.dayLimitPowerIcon {
  max-width: 24px;

  margin-top: 1px;
  cursor: pointer;
}

.dayLimitPowerDiv {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  /* flex-direction: row-reverse; */
}

.jobTitleOrDayDiv {
  display: flex;

  align-items: center;
  justify-content: space-between;

  font-size: 1.2em;
}

.limitKnob {
  margin-top: -6px;
}

.selectTeamOrJtLimitDropdown {
  color: #214978;
  border-radius: 3px;
  font-size: 10px;
  padding-left: 7px;
  text-align: left;
  max-width: 70px;
  min-width: 70px;
  line-height: 21px;
  height: 24px;
  /* background: url(/static/media/downArrowWhiteTeamSched.358e1815.svg) no-repeat right #7792b0; */
  background: url("../img/general/limDownArr.svg") no-repeat right #fff;
  /* url("../img/general/downArrowGrey.svg") */
  /* border: 1px solid #516b8b; */
  -webkit-appearance: none;
  background-position-x: 42px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 2px;
  text-align: right;
  padding-right: 25px;
  padding-bottom: 1px;
}

.fadeWhilstMoving {
  opacity: 0.3;
  pointer-events: none;
}
.blueLoadingMoveImg {
  opacity: 0.5;
}
.fadeLimitDiv {
  background-color: #e3e8ee;
  border-top: #bbcada 1px solid;
  border-bottom: #bbcada 1px solid
}

.teamLimitInitialBlank {
  min-height: 30px;
  max-height: 30px;
  margin-bottom: -3px;
  /* border-bottom: 2px solid #bac5d3; */
}

.teamLimitEditDiv {
  /* border-bottom: 1px solid #bbcada; */
  min-height: 30px;
  max-height: 30px;
  margin-bottom: -3px;

  color: #143051;

  padding-bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.limitSettingsIcon {
  max-width: 22px !important;
  min-width: 22px !important;
  margin-left: 4px;
  cursor: pointer;
}

.limitTabTitle {
  margin-left: -3px;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
}

.limitTabTitleMob {
  font-size: 10px;
  text-align: left;
  line-height: 11px;
}

.notApplicTeamLimitEditDiv {
  background: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-top: none;
  min-height: 30px;
  max-height: 30px;
  margin-bottom: -3px;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;

  /* padding-top: 1px; */
  color: #bfccdc;
  font-weight: 500;
padding-bottom:4px;
  /* border-top:solid 1px #f2f5f8 */
}

.disableLimit {
  visibility: hidden;
}

.dayLimContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5em;
  min-height: 30px;
  max-height: 30px;
  min-width: 100%;
  border-top:solid 1px #f2f5f8;
  padding-bottom:3px;
}

.dayLimLeft {
  display: flex;
  margin-left: 5px;
  align-items: center;
  min-height: 100%;
  cursor: pointer;
  margin-left:9px
}

/* .dayLimLeft:hover {
  padding-top: 3px;
  padding-bottom: 2px;
  border-bottom: 1px solid #bbcada;
} */


.dayLimCurrOffTxt {
  font-weight: 500;
  font-size: 8px;
  opacity: 0.7;
  padding-right: 3px;
}
.dayLimCurrOffValue {
  margin-left: 1px;
  font-weight: 500;
  font-size: 1em;
}

.dayLimRight {
  display: flex;
  align-items: center;
}

.dayLimLimitTxt {
  font-weight: 500;
  margin-right: -2px;
  opacity: 0.7;
  font-size: 10px;
  display: none;
}

.dayLimPlus {
  max-width: 20px;
  margin-right: 6px;
  margin-left: 6px;
  cursor: pointer;

  border-radius: 3px;
  border: 1px solid #e0ebf8;
  background-color: #f7faff;
}

.dayLimPlus:hover {
  background-color: #fff;
}

.dayLimPlus:active {
  transform: scale(0.94);
  background-color: #cbd7e6;
  opacity: 0.7;
}

.dayLimLimitValue {
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  min-width: 9px;
  justify-content: center;
}

.dayLimitOffSpan {
  font-size: 0.8em;
}

.limitExceedDayRed {
  background-color: #f07b7b;
  /* border-color: #460f0f; */
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  border: 1px #e56767 solid;
  border-radius: 3px;
  margin-bottom: 1px;
  color: #fff;
  margin-left: 5px;
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
  margin-right: -2px;
}

.limLoad {
  pointer-events: none;
  opacity: 0.5;
}

.bulkEditLimitUnderlay {
  background: rgba(0, 0, 0, 0.25);
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  /* pointer-events: none; */
  background-attachment: fixed;

  overflow: hidden;

  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(2px);
  background-color: rgb(122 122 122 / 30%);
}

.bulkEditLimitModalBox {
  /* background-color: #fff;
  color: #143051;
  border-radius: 5px; */

  min-width: 350px;
  max-width: 350px;
  /* -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* cursor: auto; */

  /* animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important; */


  background-color: #f4f8fe;
  color: #143051;
  border-radius: 10px;
  /* min-width: 380px; */
  /* max-width: 380px; */
  /* max-height: 610px; */
  /* box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  cursor: auto;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.availModalBox {
  background-color: #f4f8fe;
  color: #143051;
  border-radius: 10px;

  min-width: 380px;
  max-width: 380px;
  max-height: 610px;
  /* -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  cursor: auto;

  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.timesheetModalBox {
  max-height: 98vh !important;
}

.availModalBoxMob {
  border-radius: 5px 5px 0 0;
  min-width: 100vw;
  max-width: 100vw;
  max-height: 610px;
  position: fixed;
  bottom: 0;
  animation-name: moveEditTeamUserBoxUp !important;
  animation-duration: 0.1s !important;
}

.x13892843 {
  min-width: 100vw !important;
  max-width: 100vw !important;
}

.templateSavedModal {
  background-color: #fff;
  color: #143051;
  border-radius: 10px;

  min-width: 270px;
  max-width: 270px;
  padding: 14px;
  /* -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  cursor: auto;

  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
}

.tempSavedTxt {
  font-weight: 500;
}

.tempSavedCloseBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  margin-top: 20px;
}

.tempSavedCloseBtn:hover {
  background-color: #214978;
}

.tempSavedCloseBtnPublish {
  font-size: 14px;
  color: #fff;
  background-color: #0bf;
  border: solid #0af 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  margin-top: 20px;
  margin-right: 10px;
}

.tempSavedCloseBtnPublish:hover {
  background-color: #0af;
}
.saveTemplateWideModalBox {
  min-width: 700px;
  max-width: 700px;
}

.saveTemplateModalUnit {
  min-width: 300px;
}

.loadTemplatemodalUnit {
  min-width: 300px;
  max-width: 300px;
  min-height: 248px;
}

.loadTemplatemodalUnitMob {
  min-width: 100%;
  max-width: 100%;
}

.bulkEditLimitModalHeader {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
}

.bulkEditLimitModalHeaderTitle {
  font-weight: 500;
  color: #143051;
  font-size: 18px;
}

.bulkEditCloseBlank {
  pointer-events: none;
  visibility: hidden;
}

.bulkEditingTeamTxt {
  margin-top: 15px;
  min-height: 52px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dde9f9;
  min-width: 100%;
  font-weight: 400;
  font-size: 12px;
  color: #516b8b;
  line-height: 16px;
  text-align: left;
  padding-right: 24px;
}

.availTxtInfo {
  margin-top: -3px;
  min-height: 24px;
  padding-bottom: 10px;
  font-size: 12px;
  line-height: 16px;
  color: #516b8b;
  padding-right: 50px;
  font-weight: 400;
  padding-left: 5px;
}

.saveTempTxtInstruction {
  min-height: 40px;
  /* padding-bottom: 8px; */
  /* border-bottom: 2px solid #143051; */
  font-size: 12px;
  color:#516b8b;
  padding-top: 10px;
  padding-bottom: 15px;
  padding-right:20px;
  /* padding-right: 40px !important; */
}

.bulkEditTextDiv {
  margin-top: 16px;
  text-align: left;
  /* padding-left: 5%; */
  /* padding-right: 5%; */
  font-size: 0.9em;
  display: flex;
  justify-content: space-between;
  max-width: 90%;
  margin-left: 5%;
  border-bottom: 1px solid #bac5d3;
  /* margin-bottom: 20px; */
}

.histClosedDaysTimeline 
.loadTempDivWithMutliWeek {
  margin-top: 16px;
  text-align: left;
  /* padding-left: 5%; */
  /* padding-right: 5%; */
  font-size: 0.9em;
  display: flex;
  justify-content: space-between;
}

.bulkEditTeamNameSpan {
  /* font-weight: 500; */
  /* color: #496375; */
  white-space: nowrap;

}

.bulkLimStartEndDateContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  align-items: flex-start;

  padding-top: 15px;
  min-width: 90%;
  max-width: 90%;

  padding-left: 5%;
}

.availBoxContainerWide {
  display: flex;
  flex-direction: row;
  justify-content: stretch;

  background-color: orange;

  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
}
.availBoxContainerMob {
  border-radius: 6px 6px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  align-items: stretch;
  max-height: 436px;
  padding-top: 16px;
  padding-bottom: 10px;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
}

.timesheetBoxContainerWide {
  display: flex;
  flex-direction: row;
  justify-content: stretch;

  background-color: orange;

  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
}
.timesheetBoxContainerMob {
  border-radius: 6px 6px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  align-items: stretch;
  max-height: 436px;
  /* padding-top: 24px; */
  padding-bottom: 10px;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
}
.availUnit1 {
  background-color: #f4f8fe;
  min-height: 96px;
  min-width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.availUnit1MobOnly {
  background-color: #f4f8fe;
  align-items: flex-end;
}

.availUnit2 {
  min-height: 350px;
  min-width: 70%;
  max-height: 192px;
  overflow-y: auto;
  border-top: 4px solid #cbd7e6;
  border-radius: 6px 6px 0 0;
  padding-top: 4px;
  padding-bottom: 20px;
}

.availActiveBorder {
  border-top: 4px solid #96e578;
  border-radius: 6px 6px 0 0;
}

.bulkStartDateTitle {
  text-align: left;
  padding-left: 10px;
  font-size: 12px;

  margin-bottom: 4px;
  font-weight: 500;
  color: #214978;
}

.bulkStartContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  padding-left: 5px;
}
.bulkStartContainer:last-child {
  margin-bottom: 48px;
}

.bulkStartWarn {
  color: #859dbb;
  font-size: 12px;
  font-weight: 500;
  margin-left: 0px;
  min-height: 30px;
  display: flex;
  align-items: center;
  margin-top: 22px;
  text-align: left;
  max-width: 100px;
}

.makeBulkEndDsMaxTs {
  background-color: #fff;
  border-radius: 5px;
  min-height: 34px;
  color: #859dbb;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
  margin-top: 20px;
  margin-right: 12px;
  font-size: 14px;
  border: 2px solid #dde9f9;
}

.makeBulkEndDsMaxTs:hover {
  border-color: #bbcada;
}

.bulkModalFooter {
  min-width: 90%;
  max-width: 90%;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 0.9em;
  margin: 0 auto;
  border-top: 1px solid #bac5d3;
  margin-top: 10px;
  font-weight: 500;
}

.desktopBulkModalFooter {
  min-width: 95% !important;
  max-width: 95% !important;
  margin-top: 30px;
}

.availModalFooter {
  min-width: 92%;
  max-width: 92%;
  font-size: 14px;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 4%;

  border-top: 1px solid #bac5d3;

  font-weight: 500;
}

.cancelBulkModalBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
}

.cancelBulkModalBtn:hover {
  background-color: #214978;
}

.saveBulkModalBtn {
  color: white;
  background-color: #07f;
  border: #0469de 1px solid;
  border-radius: 5px;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 60px;
  justify-content: center;
  font-weight: 500;
}

.saveBulkModalBtn:hover {
  background-color: #036ce4;
}

.sureCloseAvailNo {
  color: white;
  background-color: #07f;
  border: #0469de 1px solid;
  border-radius: 5px;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.sureCloseAvailNo:hover {
  background-color: #036ce4;
}
.sureCloseAvailYes {
  background-color: #3b597c;
  color: white;
  border: 1px solid #214978;
  border-radius: 5px;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 30px;
  font-size: 14px;
  font-weight: 500;
  min-width: 56px;
  justify-content: center;
  cursor: pointer;
}

.sureCloseAvailYes:hover {
  background-color: #214978;
}
.navyBgNextBtnBulk {
  border-color: #0af;
  background-color: #0bf;
}

.navyBgNextBtnBulk:hover {
  background-color: #0af;
}
.fadeBulkSaveBtn {
  opacity: 0.3;
  pointer-events: none;
}

.bulkTickDiv {
  margin-top: 20px;
  min-width: calc(100% - 174px);
  display: flex;
  padding-left: 10px;
}
.bulkTick {
  max-width: 24px;
}
.bulkPageDiv {
  max-width: calc(100% - 50px);
  margin: 0 auto;
}

.saveTempPageMob {
  max-width: calc(100% - 35px);
  margin: 0 auto;
  margin-top:-5px;
  font-size:14px;
  /* background:pink */
}

.saveTempPageWide {
  max-width: calc(100% - 50px);
  margin: 0px auto;
  display: flex;
  justify-content: space-around;
}
.flexReverse {
  flex-direction: row-reverse;
}
.bulkLimitByDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
}

.bulkLimitByTitle {
  font-size: 12px;
  font-weight: 500;
  margin-left: 4px;
  margin-top: 2px;
  color: #143051;
}

.selectDateRangeDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 26px;
  margin-top: 6px;
}

.selectDateRange {
  font-size: 12px;
  font-weight: 500;
  margin-left: 0px;
  margin-top: 8px;
  color: #214978;
  /* border-left: 2px solid #214978; */
  padding-left: 7px;
}

.selectDateRangeMgrAvail {
  border-left:none;
}

.selectDateRangeTeam {
  margin-left: 14px;
  margin-right:10px;
  margin-top: 1px;
  color: #143051;
}

.selectTeamOrJtLimitDropdownBulk {
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  padding-left: 10px;

  text-align: left;
  max-width: 130px;
  min-width: 130px;
  line-height: 21px;
  margin-left: 8px;

  height: 35px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;

  border: 1px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  -webkit-appearance: none;
  background-position-x: 106px;
  font-weight: 500;
  cursor: pointer;
  padding-top: 1px;
}

.bulkButtons {
  min-width: 100%;
}
.teamDataInputDiv {
  display: flex;
  max-height: 194px;
  min-height:194px;
  max-width: 300px;
  margin-top: 8px;
  justify-content: space-between;
  border: 1px solid #cce3ff;
  border-radius: 10px;
  margin-bottom: 4px;

}

.fadeBulkPlusMinus {
  opacity: 0.3;
  pointer-events: none;
}

.bulkDayPowerIcon {
  cursor: pointer;
  max-width: 28px;
}

.bulkDayPowerDivider {
  min-height: 1px;
  background-color: #cbd7e6;
  min-width: 100%;
  margin-bottom: 8px;
  margin-top: 3px;
}

.bulkDayCol {
  max-width: 42px;
  min-width: 42px;

  border-right: 1px solid #cbd7e6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding-top: 10px;
  padding-bottom: 5px;
}

.bulkDayCol:last-child {
  border-right: none;
}

.bulkDayPlusIcon {
  max-width: 28px;
  cursor: pointer;
}

.bulkDayPlusIcon:active {
  transform: scale(0.96);
}

.bulkDayTitle {
  font-size: 0.8em;
  font-weight: 500;
  margin-bottom: 8px;
}

.bulkDayVal {
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  margin-bottom: 4px;
}

.offTxtBelowVal {
  font-weight: 800;
  font-size: 0.65em;
  margin-top: -6px;
  margin-bottom: 7px;
  color: #859dbb;
}

.allowTxtAboveVal {
  font-weight: 500;
  font-size: 0.65em;
  margin-top: 4px;
  margin-bottom: -4px;
  color: #5479a3;
}

.bulkLimitEditModalKeyDiv {
  min-width: 100%;
  min-height: 8px;
  margin-top: 20px;
  margin-bottom: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bulkKeyContainer {
  min-width: 16px;
  min-height: 100%;

  display: flex;
  justify-content: space-between;
}

.bulkKeyOn {
  min-width: 6px;
  max-height: 6px;
  min-height: 6px;
  max-width: 6px;
  background-color: #143051;
  border-radius: 10px;
}

.bulkKeyOff {
  min-width: 6px;
  max-height: 6px;
  min-height: 6px;
  max-width: 6px;
  background-color: #8ea7c5;
  border-radius: 10px;
}

.offVisZero {
  visibility: hidden;
}

.jtDataInputDiv {
  min-width: 100%;
  max-width: 100%;
  margin-top: 10px;
}

.loadingBulkDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  min-height: 100px;
  font-weight: 500;
  color: #07f;
  padding-bottom: 60px;
}
.jtSelectBulkMenu {
  display: flex;
  align-items: center;
  max-width: 100%;
  min-width: 100%;
  overflow-x: auto;
  margin-top: 12px;
border: 1px solid #e0ebf8;
  background-color: #f4f8fe;
  border-radius: 5px;
  padding: 3px;
  padding-right: 5px;
}
.bulkJtMenuItem {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  text-overflow: ellipsis;
  max-width: 100px;
  min-width: 100px;
  max-height: fit-content;

  border-right: 1px solid #acc3e1;
  color: #5479a3;
  cursor: pointer;
  min-height: 54px;

  padding-left: 10px;
  padding-right: 10px;
}

.bulkJtMenuItem:first-child {
  margin-left: 0px;
}

.bulkJtMenuItem:last-child {
  border-right: none;
}

.selectedBulkJt {
  background-color: #214978;
  border: 2px solid #143051;
  color: white;
  cursor: default;
  border-radius: 5px;
  padding: 6px;
  padding-right: 10px;
  padding-left: 10px;
}

.noBorderRightJt {
  border: none;
}

.noBorderRight {
  border-right: none !important;
}

.limitByJtInstruct {

  text-align: left;
  margin-left: 4px;

  font-weight: 400;
  font-size: 12px;
  color: #516b8b;
  line-height: 16px;
}

.jtEditDivContainer {
  display: flex;
  max-height: 220px;
  max-width: 300px;
  margin-top: 16px;
  justify-content: space-between;
  border: 1px solid #cce3ff;
  border-radius: 10px;
}

.jtEdited {
  color: #0af;
}

.invisFooter {
  visibility: hidden;
  pointer-events: none;
}

.closeBulkEditLimitModalIcon {
  padding: 7px;
  margin-left: -5px;
  margin-top: -4px;
  cursor: pointer;
}

.closeBulkEditLimitModalIcon:hover {
  opacity: 0.8;
}

.templateDataSpan {
  font-weight: 500;
}

.templateNameInput {
  max-width: 100%;
  min-width: 100%;
  resize: none;
  font-weight: 500;
  border: 1px solid white;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #6a8bb1;
  min-height: 40px;
  padding-left: 8px;
  font-size: 15px;
  padding-top: 10px;
  max-height: 40px;
  background-color: white;
}

.templateNameInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 7px;
  background-color: #fdfeff;
  margin-bottom: 0px;
  padding-top: 9px !important;
}
.nameTemplateTxt {
  margin-top: 20px;
  min-height: 48px;
  margin-bottom: -20px;
  text-align: left;
  font-weight: 500;
}

.nameTemplateTxt:last-of-type {
  margin-top: 8px;
}

.templateTableDiv {
  margin-top: 6px;
  /* max-width: 350px; */
}
.templateTableTopRow {
  display: flex;
  align-items: center;
  min-height: 26px;
}

.templateTableTopRow:first-child {
  border-bottom: 2px solid #bfccdc;
  padding-bottom: 5px;
}

.templateTopRowCellTitle {
  font-size: 0.8em;
  font-weight: 500;

  min-width: 21%;
  max-width: 21%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 14px;

  color: #0077cc;
}

.templateTopRowCellTitle:nth-child(3) {
  font-size: 0.75em;
  margin-top: 1px;
}

.templateTopRowCellTitle:first-child {
  justify-content: flex-start;
  padding-left: 4px;
  min-width: 16%;
  max-width: 16%;
  transform: rotate(0deg);
  color: #143051;
}

.templateTopRowCell {
  font-size: 0.8em;
  font-weight: 400;
  min-width: 21%;
  max-width: 21%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 14px;
}

.templateTableTopRow:nth-child(even) {
  background-color: #d2e6fd;
}

.templateTopRowCell:first-child {
  font-weight: 500;
  min-width: 16%;
  max-width: 16%;
  justify-content: flex-start;
  padding-left: 4px;
  border-right: 2px solid #bfccdc;
  min-height: 26px;
}

.templateTableTopRow:last-child {
  border-radius: 0 0 5px 0;
}

.templateIncludeRow {
  display: flex;
  align-items: center;
  min-height: 40px;
  margin-bottom: -6px;
}

.includeCell {
  min-width: 16%;
  max-width: 16%;
  font-size: 12px;
  font-weight: 400;
  color:#516b8b
}

.includeKnobContainer {
  min-width: 21%;
  max-width: 21%;

  border-right: 1px solid #bfccdc;
  display:flex;
  justify-content: center;
    align-items: center;
}

.includeKnobContainer:last-child {
  border-right: none;
}
.templateKnob {
  margin-left: 6px;
  margin-top: 2px;
  margin-bottom: 10px;
  cursor: pointer;
}

.fadeTempRow {
  opacity: 0.25;
}

.descriptionBoxInWideSaveTempModal {
  min-height: 104px;
}

.saveTempMobWideDivider {
  min-width: 2px;
  min-height: 92%;
  max-height: 92%;
  margin-top: 3%;
  background-color: #143051;
  margin-left: 4px;
  margin-right: 4px;
}

.saveTempMobDivider {
  min-width: 100%;
  min-height: 2px;
  max-width: 100%;
  background-color: #143051;
  margin-top: 10px;
  margin-bottom: -8px;
}

.loadingSaveTemp {
  pointer-events: none;
}

.selectTemplateTxt {
  /* margin-top: 10px; */
  min-height: 48px;
  margin-bottom: -10px;
  text-align: left;
  font-weight: 500;
  padding-left: 2px;
}

.selectTemplateTxtMob {
  margin-bottom: -16px;
  margin-top: 5px;
  /* margin-left:-5px; */
}

.whatThisTempLooksLikeTxt {
  margin-top: 6px;

  text-align: left;
  font-weight: 500;
  padding-left: 2px;
}

.templateSelectBox {
  max-height: 200px;
  min-height: 200px;
  overflow-y: auto;
  border: 1px solid #bfccdc;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fcfdfe;
  padding-bottom: 10px;
}

.templateSelectBoxMob {
  /* min-width: 500px !important; */
}

.mobileMaxHeightTemplateBox {
  max-height: 26vh !important;
  min-height: 26vh !important;
}

.templateOption {
  background-color: white;
  margin-top: 6px;
  max-width: 96%;
  min-width: 96%;
  min-height: 50px;
  border-radius: 5px;
  border: 1px solid #e6eaee;
  /* border-bottom: 2px solid rgba(89, 89, 89, 0.267); */

  cursor: pointer;
  animation-name: zoomModalIn !important;
  animation-duration: 0.06s !important;
  display: flex;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 1px;
}

.templateOption:hover {
  border: 1px solid #d7dfe9;
  /* padding-left: 11px;
  padding-bottom: 1px;
  padding-right: 0px; */
}

.templateOptionLeft {
  min-width: 75%;
  max-width: 75%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 0.9em;
  font-weight: 500;
}

.templateOptionRight {
  min-width: 25%;
  max-width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  max-height: 100%;
}

.removeTemplateImg {
  max-width: 26px;
  max-height: 26px;
  background-color: white;
  border: 2px solid #e6eaee;
  padding: 4px;
  border-radius: 5px;
}

.deleteSelectedBorderBin {
  border-color: #143051;
}

.templateUnitDescription {
  font-weight: 400;
  text-align: left;
  line-height: 12px;

  font-size: 0.82em;
  opacity: 0.8;
}

.tempArrowImgDownWide {
  max-width: 12px;
  margin-right: 12px;
  transform: rotate(90deg);
}

.tempArrowImgDownMob {
  max-width: 12px;
  margin-right: 10px;
}

.selectedTemplateUnit {
  border: solid #140351 2px;
  background-color: #07f;
  color: #fff;
  padding-right: 1px;
}

.selectedTemplateUnit:hover {
  border: solid #140351 2px;
  background-color: #1b81f5;
  color: #fff;
  padding-left: 12px;
  padding-right: 1px;
  padding-top: 1px;
}
.removeTemplateButton {
  background-color: #e89292;
  border: 2px #b56161 solid;
  color: white;
  border-radius: 5px;
  font-size: 0.8em;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  min-height: 30px;

  display: flex;
  align-items: center;
}

.tempLookLikeDayRow {
  display: flex;
  min-height: 22px;
  font-size: 0.9em;
  font-weight: 500;
  align-items: flex-end;
  padding-bottom: 1px;
  margin-top: 20px;
  margin-bottom: 6px;
}

.tempLookLikeA1 {
  min-width: 20%;
  max-width: 20%;
  min-height: 20px;
}
.tempLookLikeDayCell {
  min-width: 11.1%;
  max-width: 11.1%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectTemplateFirstTxt {
  margin-top: 130px;
  color: #516b8b;
  font-weight: 400;
  font-size: 12px;
}

.templateUserRow {
  min-height: 70px;

  min-width: 100%;
  max-width: 100%;
  display: flex;

  border-bottom: 2px solid #e0ebf8;
}

.templateUserRow2items {
  min-height: 120px;
}

.templateUserRow3items {
  min-height: 180px;
}

.templateUserRow4items {
  min-height: 230px;
}

.templateUserRow5items {
  min-height: 290px;
}

.templateUserRow6items {
  min-height: 350px;
}

.templateUserRow7items {
  min-height: 410px;
}

.templateUserRow8items {
  min-height: 470px;
}

.templateUserRow9items {
  min-height: 530px;
}

.templateUserRow10items {
  min-height: 590px;
}

.templateUserRow11items {
  min-height: 650px;
}

.templateUserRow:last-child {
  border: none;
}

.templateNameCell {
  min-width: 20%;
  max-width: 20%;
  font-size: 0.65em;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 5px;
  padding-top: 19px;
}

.templateUserDateCell {
  min-width: 11.43%;
  max-width: 11.43%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding-top: 4px;
  padding-bottom: 4px;
}

.templateItem {
  max-height: 52px;
  min-height: 52px;
  min-width: 94%;
  font-size: 0.6em;
  background-color: white;
  border: 1.5px solid #cbd7e6;
  border-radius: 5px;
  margin-right: 2px;
  padding-top: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2px;
}

.templateStart {
  font-weight: 500;
  line-height: 10px;
}

.templateEnd {
  font-weight: 500;
  line-height: 10px;
}

.tempTimeSmaller {
  font-size: 0.76em;
}
.templateType {
  margin-top: 1px;
  color: #07f;
  font-size: 0.9em;
  opacity: 0.5;
  font-weight: 500;
}

.templateBetweenLine {
  opacity: 0.5;
}

.dudTemplateItem {
  max-height: 54px;
  min-height: 54px;
  min-width: 94%;
  min-width: 94%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #6987a9;
}

.tilTypeStripInLoadTemplate {
  color: #4b1caf;
}

.templateOpensRow {
  display: flex;
  min-width: 100%;
  max-width: 100%;
  border-bottom: 2px solid #c6d6e9;

  background-color: #e0ebf8;
}
.opensRow1 {
  min-height: 60px;
}

.opensRow2 {
  min-height: 116px;
}

.opensRow3 {
  min-height: 172px;
}
.opensRow4 {
  min-height: 228px;
}
.opensRow5 {
  min-height: 284px;
}
.opensRow6 {
  min-height: 340px;
}
.opensRow7 {
  min-height: 396px;
}
.opensRow8 {
  min-height: 452px;
}
.opensRow9 {
  min-height: 507px;
}

.previewOpenCell {
  min-width: 11.43%;
  max-width: 11.43%;
}

.a1previewOpensRowCell {
  min-height: 100%;
  min-width: 20%;
  max-width: 20%;
}

.templateOpenTxt {
  color: #6dba4f;
  font-weight: 500;
  margin-top: 1px;
}

.templateOpenShiftsTitle {
  font-weight: 500;
  text-align: left;
  font-size: 0.7em;
  margin-top: 14px;
  padding-left: 5px;
}

.tempLoadedNotLimitsTxt {
  margin-top: 20px;
  font-size: 0.9em;
}

.limitsNoteSpan {
  font-weight: 500;
}

.noSavedTempsTxt {
  margin-top: 16px;
  font-weight: 500;
  color: #acc3e1;
  font-size: 12px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  line-height: 18px;
}

.clickHereToSaveTempInsteadSpan {
  color: #143041;
  font-weight: 500;
  cursor: pointer;
}

.saveTempFooterRightDiv {
  min-width: 225px;
  display: flex;
  justify-content: space-between;
}

.replaceExistingBtn {
  min-height: 30px;
  display: flex;
  align-items: center;
  padding-bottom: 1px;
  padding-left: 2px;
}

.fadeGenBtn {
  pointer-events: none;
  opacity: 0.5;
}
.replaceTickImg {
  margin-top: 2px;
  margin-right: 7px;
  margin-left: -3px;
  max-width: 18px;
}

.splitFromToday {
  font-weight: 500;
  font-size: 0.7em;
  margin-top: -2px;
  color: #19a5c8;
}

.availRow {
  min-height: 17px;
  max-height: 17px;
  /* margin-top: 4px; */
  margin-bottom: -5px;
  padding-bottom: 0px;
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  justify-content: flex-start;
  padding-left: 15px;
  color: #143051;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
  margin-top: 0px;
  margin-bottom: -12px;
  /* border-left: 2px solid #143051; */
  border-radius: 0;
}

.availRow:hover {
  /* color: #8ea7c5; */
}
.assignRow {
  min-height: 110px;
}
.availSpan {
  /* color: #36495f; */
  margin-left: 5px;
  min-width: fit-content;
  white-space: nowrap;
  font-size: 1.05em;
  padding-right: 5px;
}

.availSpan:hover {
  /* color: #214978 */
}

.selectDateRangeDivAvail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-top: 6px;
  min-width: 100%;
}

.teamSchedFilterBarLeftAvail {
  min-height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 5px;
}

.fadeSelMonWeekBtn {
  opacity: 0.3;
  pointer-events: none;
}

.availTable {
  min-width: 100%;
  min-height: 200px;

  font-size: 0.8em;
}

.availModalRow {
  display: flex;
  min-height: 80px;
  max-height: 80px;
  min-width: 100%;
  padding-left: 14px;
  border-bottom: 1px solid #e6eaee;
  align-items: center;
  justify-content: space-between;
  padding-right: 14px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 1.3em;
  background-color: white;
}

.availModalRow:last-child {
  border: none;
}

.extendAvailRow {
  min-height: 100px;
}
.availDay {
  font-size: 14px;
  color: #214978;
  font-weight: 500;
}

.availRowBtnsContainer {
  display: flex;
  justify-content: space-between;
  min-width: 60px;
}
.availPower {
  max-width: 30px;
  min-width: 30px;
  background-color: #96e578;
  border: 1px solid #88d869;
  border-radius: 5px;
  margin-left: -4px;
  cursor: pointer;
}
.availPower:hover {
  background-color: #88d869;
}

.availAdd {
  max-width: 25px;
  min-width: 25px;
}

.availData {
  display: flex;
  min-width: 170px;
  justify-content: space-between;
  align-items: center;
}

.availDataParent {
  min-height: 76px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.availStart {
  background-color: #fff;
  width: 74px;
  max-width: 74px;
  line-height: 30px;
  padding-top: 0px;
  height: 35px;
  border: 1px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 14px;
  font-weight: 500;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: text;
  /* padding-left:4px; */
}

.availStart:hover {
  border-color: #d7dfe9;
}

.availUntil {
  font-weight: 500;
  font-size: 0.8em;
  color: #214978;
  cursor: pointer;
}

.availPowerOff {
  background-color: #bbcada;
  border-color: #8ea7c5;
}

.availPowerOff:hover {
  background-color: #8ea7c5;
}

.availRowOff {
  opacity: 0.6;
  background-color: #f4f8fe;
  cursor: pointer;
}

.availRowOff:hover {
  background-color: #edf3f9;
}
.availSelectRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 320px;
  min-width: 320px;
  min-height: 40px;

  padding-bottom: 10px;
  padding-left: 6px;
  padding-right: 4px;
  padding-top: 4px;
}

.addAvailSelectRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 270px;
  min-width: 270px;
  min-height: 40px;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-right: 8px;
}

.timesheetSelectorRow {
  max-width: 310px;
  min-width: 310px;
  margin-bottom: -10px;
}

.addAvailSelectRow:last-child {
  border: none;
  margin-bottom: 16px;

  padding-bottom: 10px;
  margin-top: 3px;
}

.timesheetSelectorRow:last-child {
  border: none;
  /* margin-bottom: 1px; */
  max-width: 310px;
  min-width: 310px;
  padding-bottom: 0px;
  margin-top: -5px;
}
.availTeamsDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 163px;
  min-width: 163px;
  line-height: 26px;
  height: 35px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  background-position-x: 92px;
  display: flex;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 140px;
  margin-right: 10px;
  margin-top: 1px;
}

.timesheetTeamSelect {
  max-width: 203px;
  min-width: 203px;
  background-position-x: 176px;
}
.mgrAvailSelectorBar {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height: 88px;
}

.selectorBarDynamic2 {
      min-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 44px;
}

@media only screen and (min-width: 800px) {
  .selectorBarDynamic {
    min-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 44px;
  }

  .availSelectRowSelectedWeek {
    margin-top: -6px !important;
    max-width: 285px;
    min-width: 285px;
  }

  .availSelectRowTeam {
    margin-top: -10px !important;
    padding-right: 0px !important;
  }


}

.selectDateRangeSelectWeek2 {
  margin-left: 16px;

}

.availTeamsDropdown:hover {
  border-color: #d7dfe9;
}

.availSaveAndDeleteContainer {
  display: flex;
  justify-content: flex-start;
  /* flex-direction: row-reverse; */
  /* max-width: 140px; */
  /* min-width: 140px; */
}

.deleteAvailBtn {
  color: white;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
  /* margin-left: 10px; */
  /* min-width: 70px; */
  justify-content: center;
  font-weight: 500;
}

.deleteAvailBtn:hover {
  background-color: #bbcada;
}

.saveAvailBtn {
  margin-right: 10px;
}

.sureCloseAvailModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.sureCloseAvailModalBox {
  background-color: #f4f8fe;
  color: #143051;
  border-radius: 5px;
  min-height: 35px;
  min-width: 300px;
  max-width: 300px;
  padding: 15px;
  padding-top: 30px;
  box-shadow: 0px 0px 3px 3px rgb(0 0 0 / 10%);
  cursor: auto;
  min-height: 150px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #143051;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
}

.sureCloseAvailBtnContainer {
  display: flex;
  min-width: fit-content;
  margin: 0 auto;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 8px;
}

.availSavedBtn {
  max-width: 50.67px;
  min-width: 50.67px;
  padding-top: 8px;
  color: #6dba4f;
  font-weight: 500;
  /* margin-right: 11px; */
  margin-left: 9px;
  border-left: 2px solid #6dba4f;
  max-height: 22px;
  margin-top: 6px;
  /* padding-left: 5px; */
  padding-right: 0px;
  line-height: 6px;
  padding-left: 8px;
}

.rateAndCostContainer {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
}

.addShiftCostIndicative {
  margin-right: 30px;
  font-weight: 500;
  color: #abb9dc;
}

.invisCostAddShift {
  visibility: hidden;
  pointer-events: none;
}

.ghostShiftUnitTeamSched {
  background-color: #143051;
  color: white;
  border-radius: 5px 5px 5px 5px;
  max-width: 130px !important;
  min-width: 130px !important;}

.specDayRow {
  min-height: 20px;
  max-height: 20px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  padding-left: 10px;
  padding-bottom: 2px;
  color: #5479a3;
  /* background-color: #ebf0f7; */
  border-bottom: 1px solid #e3e8ee;
  border-top: 1px solid #e3e8ee;

}

.specDayRowStream {
  /* margin-left:2px; */
}

.specDayRowStreamA1 {
  margin-left:0px;
  border-left:#dbe8f7 1px solid;
  border-right:#dbe8f7 3px solid;
  border-top:none;
  border-bottom:none;
}

.activeSpecDayRow {
  background-color: #dfb6e3;
  color: #fff;
  font-weight: 500;
  display: flex;
  /* justify-content: space-between; */
  justify-content: flex-start;
  margin-left: 2px;
  /* border-bottom: 1px solid #d59fd9; */
  border: 1px solid #d59fd9;
  border-radius: 3px;
}

.activeSpecDayRowMon {
  margin-left:0px
}



.schedLimPowerOn {
  background-color: #7fbb67;
  max-height: 21px;
  min-height: 21px;
  min-width: 21px;
  max-width: 21px;
  border-radius: 3px;
  border: 1px solid #5a9e3f;
  margin-top: 4px;
}



.schedLimPlus {
  max-height: 21px;
  min-height: 21px;
  min-width: 21px;
  max-width: 21px;
  border-radius: 3px;

  margin-top: 4px;
}

.schedLimPlus:active {
  opacity: 1;
}



@media only screen and (min-width: 820px) {

  .schedLimPowerOn:hover {
    opacity: 0.8;
    transform: scale(0.98);
  }

  .dayLimitPowerOff:hover {
    opacity: 0.8;
    /* transform: scale(0.98); */
  }

  .schedLimPlus:hover {
    opacity: 1;
    background-color: white;
    border:1px solid #ececec
  }
  .dayLimitPowerOn:hover {
    opacity: 0.8;
    /* transform: scale(0.98); */
  }
}
.schedLimPowerOff {
  background-color: #bfd1e6;
  max-height: 21px;
  min-height: 21px;
  min-width: 21px;
  max-width: 21px;
  border-radius: 3px;
  border: 1px solid #98b8dc;
  margin-top: 4px;
}

.schedLimitPowerOff {
  background-color: #6a8bb1;
  max-height: 21px;
  min-height: 21px;
  min-width: 21px;
  max-width: 21px;
  border-radius: 4px;
}

.dayLimitPowerOn {
  background-color: #7fbb67;
  margin-top: 2px;
  max-height: 21px;
  min-height: 21px;
  min-width: 21px;
  max-width: 21px;
  border-radius: 4px;
  border: 1px solid #2a501b;
  cursor: pointer;
}



.dayLimitPowerOff {
  background-color: #6a8bb1;
  max-height: 21px;
  min-height: 21px;
  min-width: 21px;
  max-width: 21px;
  border-radius: 4px;
  border: 1px solid #143051;
  cursor: pointer;
  margin-top: 4px;
}



.exSmallDurStrip {
  font-size: 11px;
}

.appendingShTagInline {
  margin-left: 3px;
}

.voidRowIfNew {
  opacity: 0.2;
  pointer-events: none;
}

.tilPushTickDown {
  margin-top: 31px;
}

.highlightedTilRow1 {
  border-top: 2px solid #00ccff;
  border-left: 2px solid #00ccff;
  border-right: 2px solid #00ccff;
  border-radius: 5px 5px 0 0;
  border-bottom: none;
  margin-top: 3px;
  padding-left: 10px;
  background-color: #f4f8fe;
  padding-right: 14px;
  padding-left: 14px;
}

.highlightedTilRow2 {
  border-bottom: 2px solid #00ccff;
  border-left: 2px solid #00ccff;
  border-right: 2px solid #00ccff;
  border-radius: 0px 0px 5px 5px;
  margin-bottom: 8px;
  padding-left: 10px;
  background-color: #f4f8fe;
  padding-right: 14px;
  padding-left: 14px;
}

.tilTrimTimeInput {
  /* border: 2px solid #00ccff !important; */
  cursor: pointer;
}

.tilTrimTimeInput:hover {
  border-color: #00aaff !important;
}

.tipBox {
  background-color: #214978;
  display: flex;
  align-items: center;
  position: fixed;
  max-width: 320px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 5px 0 0 5px;
  right: 0px;
  bottom: 150px;
  z-index: 9999999;
  border: 2px solid #143051;
  border-right: none;
  animation-name: tipBox !important;
  animation-duration: 0.75s !important;
}

.schedLimitMinus {
}

.tipBoxClose {
  min-width: 12px;
  cursor: pointer;
}

.tipBoxTxt {
  color: white;
  text-align: left;
  font-weight: 500;
  font-size: 0.94em;
  margin-left: 15px;
  line-height: 18px;
  font-size: 14px;
}

.tipBoxTipSpan {
  font-weight: 500;
  color: #00ccff;
}

.tipBoxBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}

.dontShowTipBtn {
  color: #fff;
  border-radius: 5px;
  font-size: 0.75em;
  margin-left: 15px;
  cursor: pointer;
  margin-top: 4px;
  opacity: 0.7;
}

.dontShowTipBtn:hover {
  opacity: 1;
}

.noCursor {
  cursor: default;
  opacity: 0.3;
}

.showingChangesFor {
  text-align: left;
  color: #acc3e1;
  margin-top: 7px;
  margin-bottom: 6px;
  font-size: 14px;
  padding-left: 54px;
}

.availPlusAddBtn {
  max-width: 30px;
  margin-right: 1px;
  margin-left: 6px;
  cursor: pointer;
  border: 1px solid #cbd7e6;
  border-radius: 5px;
  opacity: 0.5;
  padding: 3px;
}

.availPlusAddBtn:hover {
  border-color: #d7dfe9;
}

.absenceMonthSelectDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 150px;
  min-width: 150px;
  line-height: 26px;
  height: 30px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 129px;
}

.noAbsTxt {
  font-size: 0.9em;
  color: #496375;
  font-weight: 500;
}

.noAbsDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 190px;
  margin: 15px auto;
}

.noAbsSideline {
  min-height: 2px;
  background-color: #496375;
  min-width: 40px;
  opacity: 0.5;
}

.closeRemoveAbsenceModal {
  cursor: pointer;
}

.noteBtnAbsence {
  font-size: 0.82em;
  color: #fff;
  border: solid 1px #0af;
  background-color: #0bf;
  font-weight: 500;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
  max-height: 24px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 24px;
  cursor: pointer;
}

.hideAbsenceContent {
  visibility: hidden;
}

.loadingAbsenceHorizImg {
  margin-top: 8px;
}

.durationAbsenceRow {
  background-color: #fff;
  cursor: pointer;
}

.durationAbsenceRow:hover {
  background-color: #f4f8fe;
}

.addUserToTeamSchedBtn {
  min-width: 90%;
  font-size: 0.94em;
  max-width: 90%;
  margin-left: 5%;
  margin-top: 5px;
  background-color: #0bf;
  min-height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 5px;
  font-weight: 500;
  border: 2px solid #0af;
  cursor: pointer;
  margin-bottom: 10px;
}

.addUserToTeamSchedBtn:hover {
  background-color: #0af;
}

.loadedFromTeamSchedTxt {
  text-align: left;
  line-height: 18px;
  font-size: 0.8em;
  font-weight: 400;
  padding-right: 20px;
  margin-top: 10px;
}

.loadedFromTeamSchedTxtBoldSpan {
  font-weight: 500;
}

.disableBulkLimitBtn {
  opacity: 0.5;
  pointer-events: none;
}

.fadeSched {
  opacity: 0.7;
  pointer-events: none;
}

.availActiveBanner {
  background-color: #96e578;
  border: 1px solid #88d869;
  color: white;
  max-width: fit-content;
  max-height: 28px;
  min-height: 28px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -18px;
  margin-top: -6px;
  margin-left: 0px;
  padding-left: 12px;
  padding-right: 12px;
  z-index: 999;
  border-radius: 5px 5px 5px 5px;
}

.notYetSubmittedAvailBanner {
  background-color: #cbd7e6;
  border: 1px solid #bbcada;

  color: white;
  max-width: fit-content;
  max-height: 28px;
  min-height: 28px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -18px;
  margin-top: -6px;
  margin-left: 0px;
  padding-left: 12px;
  padding-right: 12px;
  z-index: 999;
  border-radius: 5px;
}

.leaveFirstLastHalfTxt {
  font-size: 1.5em;
  opacity: 1;
  color: #fff;
  margin-top: 2px;
}

.removeTilFromShiftBtn {
  cursor: pointer;
  max-width: 20px;
  /* max-height: 20px; */
  margin-left: 5px;
  margin-top: -7px;
  cursor: pointer;
  max-width: 30px;
  margin-left: 30px;
  margin-top: -92px;
  margin-bottom: 40px;
  border: #e3e8ee solid 2px;
  border-radius: 5px;
  padding: 5px;
}

.removeTilFromShiftBtnSure {
  padding-top: 3px;
}

.removeTilFromShiftBtn:hover {
  border: #b2bbc7 solid 2px;
}

.tilBtnsOnShiftDiv {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-around;
}

.addAbsenceSelectUserDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 208px;
  min-width: 208px;
  line-height: 26px;
  height: 30px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  color: #496375;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  background-position-x: 184px;
  display: flex;
  cursor: pointer;
  margin-left: 10%;
  margin-top: 10px;
}

.considerLTLTxt {
  text-align: left;
  font-size: 14px;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 8px;
  color: #859dbb;
  font-weight: 400;
}

.ltlSpan {
  font-weight: 500;
  color: #214978;
  cursor: pointer;
}

.bulkPlusMinus {
  background-color: #00ccff;
  min-width: 26px;
  max-width: 26px;
  padding: 6px;
  border: 1px solid #0af;
  border-radius: 5px;
  cursor: pointer;
}

.bulkPlusMinus:hover {
  background-color: #00aaff;
}

.bulkPlusMinus:active {
  opacity: 0.8;
}
.bulkDayPowerIcon {
  min-width: 26px;
  min-height: 26px;
  max-width: 26px;
  max-height: 26px;
  padding: 4px;
  background-color: #3b597c;
  border: 1px solid #214978;
  border-radius: 5px;
  cursor: pointer;
}

.bulkDayPowerIcon:hover {
  background-color: #214978;
}

.fadeBulkPower {
  opacity: 0.2;
}

.makeCursP {
  cursor: pointer;
}

.noPermissionsEditUser {
  font-weight: 500;
}

.noPermissionsEditUserModalBox {
  background-color: white;
  min-width: 300px;
  min-height: 120px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.limitColourBg {
}

.limitColourPlusBtn {
  opacity: 1;
}

.limitColourDayTxtWhite {
}

.TopBar {
  position: fixed;
  top: 0;

  background-color: #ebf0f7;
  color: white;
  min-height: 30px;
  max-height: 30px;
  z-index: 1;

  border-bottom: 2px solid #e6eaee;
  color: black;
  min-width: calc(100vw - 240px);
  max-width: calc(100vw - 240px);
  border-left: 2px solid #e6eaee;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 30px;
}

.unclockedTab {
  background-color: #36495f;
  border: 1px #143051 solid;
  color: white;
  display: flex;
  align-items: center;
  max-height: 24px;
  min-height: 24px;
  min-width: fit-content;
  max-width: fit-content;
  padding-left: 6px;
  padding-right: 5px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 6px;
}

.unclockedTab:first-of-type {
  margin-left: -27px;
}

.unclockedTab:hover {
  background-color: #143051;
}

.unclockedQty {
  background-color: #a387df;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 6px;
  font-size: 12px;
  padding-top: 1px;
  padding-bottom: 1px;
  font-weight: 500;
}

.allTilOff {
  font-size: 14px;
}

.shiftTimesStrip {
  font-size: 16px;
  line-height: 28px;
  min-width: 112px;
}

.wholeTilTxt {
  font-size: 15px !important;
  line-height: 18px !important;
  margin-top: 16px;
}

.notAvailableTxtBlock {
  background-color: #5479a3;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 16px;
  border-radius: 3px;
  border: 1px solid #496375;
  margin-top: 3px;
  min-height: 17px;
  margin-bottom: -15px;
}

.notAvailableTxtBlock:hover {
  background-color: #3e5d7f;
}

.notAvailSpan {
  color: white;
}

.dayDataBox {
  min-height: 60px;
  background-color: #e3e8ee;
  border-top: 2px solid #143051;
  min-width: 100%;
  max-width: 100%;
  color: white;
  margin-bottom: -9px;
  font-size: 14px;
  padding-bottom: 8px;
  font-size: 12px;
}

.dayDataLeftAndRightFlex {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  margin-top: 14px;
}

.lastDayDataBox {
  border-radius: 0 0 8px 0;
}

.dayDataDayStrip {
  text-align: left;
  font-size: 12px;
  margin-left: 4px;
  margin-top: 4px;
  margin-bottom: 5px;
  opacity: 1;
  min-width: 100px;
  font-weight: 500;
  color: #859dbb;
  padding-left: 5px;
  border-bottom: 1px solid #BBCADA;
  padding-bottom: 13px;
  display: flex;
  align-items: center;
  padding-top: 10px;
  height: 20px;
}
.dayColCostHoursTitle {
  font-weight: 500;
  /* margin-top: 12px; */
  opacity: 0.5;
  /* margin-bottom: 3px; */
  font-size: 10px;
}

.dayColCostHoursValue {
  font-weight: 500;
  font-size: 14px;
  margin-top: 1px;
}

.dayDataBoxLeft {
  text-align: left;
  padding-left: 10px;
}

.dayDataBoxRight {
  text-align: right;
  padding-right: 10px;
}

.teamShiftTeamSelectDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 193px;
  min-width: 193px;
  line-height: 26px;
  height: 30px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 170px;
  margin-left: -18px;
  margin-top: 4px;
}

.teamRotaTeamSelectDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 193px;
  min-width: 193px;
  line-height: 26px;
  height: 35px;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 2px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 170px;
  margin-left: 17px;
  margin-top: -27px;
}

.teamRotaTeamSelectDropdown:hover {
  border-color: #d7dee5;
}
.clockOnsupperCont {
  padding-top: 2px;
}

.clockOnsContainer {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  min-height: calc(100vh - 207px);
  max-height: calc(100vh - 207px);
  overflow-y: scroll;
  padding-bottom: 30px;
  padding-top: 7px;
  border-left: 2px solid #e0ebf8;
  margin-top: -8px;
  background: #ebf0f7 !important;
}

.tsContainer {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  min-height: calc(100vh - 204px);
  max-height: calc(100vh - 204px);
  overflow-y: scroll;
  padding-bottom: 40px;
  /* padding-top: 10px; */
  border-left: 2px solid #e0ebf8;
  border-right: 1px solid #e0ebf8;
  /* margin-top: -8px; */
  background: #ebf0f7 !important;

  /* margin-top: -8px; */
  background: #ebf0f7 !important;
  padding-top:15px;
}

.mobTsContainer {
  min-height: calc(100vh - 263px);
  max-height: calc(100vh - 263px);
}

.tsContainerStaffMob {
  min-height: calc(100vh - 269px);
  max-height: calc(100vh - 269px);
}
.clockOnsContainerWithFilt {
  min-height: calc(100vh - 240px);
  max-height: calc(100vh - 240px);
}

.mobClockOnsContainer {
  min-height: calc(100vh - 255px);
  max-height: calc(100vh - 255px);
}

.myHoursContainer {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  min-height: calc(100vh - 204px);
  max-height: calc(100vh - 204px);
  overflow-y: scroll;
  padding-bottom: 20px;
  padding-top: 14px;
  border-right: 1px solid #e0ebf8;
  background-color: #ebf0f7;

}
.mobMyHoursContainer {
  min-height: calc(100vh - 263px) !important;
  max-height: calc(100vh - 263px) !important;
  /* background-color: yellow; */
}

.showFilterMobMyHoursContainer {
  min-height: calc(100vh - 307px) !important;
  max-height: calc(100vh - 307px) !important;
}

.showFilterDesktopMyHoursContainer {
  min-height: calc(100vh - 258px) !important;
  max-height: calc(100vh - 258px) !important;
}
.showFilterClockHeight {
  min-height: calc(100vh - 299px);
  max-height: calc(100vh - 299px);
}

.showFilterClockHeightDesktop {
  min-height: calc(100vh - 252px);
  max-height: calc(100vh - 252px);
}

.clockOnsContainerMobFiltActive {
  min-height: calc(100vh - 346px);
  max-height: calc(100vh - 346px);
  padding-bottom: 25px;
}

.clockOnsContainerMobNoUnlinked {
  min-height: calc(100vh - 246px);
  max-height: calc(100vh - 246px);
  padding-bottom: 45px;
}

.mobUnlinkedExistContainer {
  min-height: calc(100vh - 291px);
  max-height: calc(100vh - 291px);
  padding-bottom: 45px;
}

.hideViewChange {
  pointer-events: none;
  opacity: 0.3;
}

.loadChangeLoaderImg {
  max-width: 60px;
  max-height: 13px;
  margin-top: 8px;
  margin-right: 20px;
}

.shiftEditTitleTxt {
  font-size: 12px;
  color: #214978;
  font-weight: 400;
  margin-left: 5px;
}

.shiftEditValue {
  margin-top: -26px;
  color: #143051;
  font-weight: 500;
  font-size: 18px;
  margin-left: 5px;
}

.shiftDateValue {
  margin-top: -20px;
  color: #143051;
  font-weight: 500;
  font-size: 18px;
  margin-left: 5px !important;
}

.shiftTypeLeftBox {
  margin-left: 18px;
  max-width: 75%;
  min-width: 60%;

  margin-right: -18px;
}

.saveBtnPushedDown {
  margin-top: 22px;
}

.editShiftDateInputBox {
  font-size: 16px !important;
}

.shiftDateEditDiv {
  margin-top: -9px;
  margin-bottom: 6px;
}

.editBreakMove {
  margin-left: 5px !important;
  margin-top: -20px !important;
  padding-top: 0px;
}

.noTagsTxt {
  margin-top: -20px;
}

.noNoteVal {
  margin-top: -20px;
  margin-left: 5px !important;
}

.pushSaveTimesBtnDown {
  margin-top: 24px;
}

.shiftEditBrkInputMove {
  margin-top: -14px;
  margin-left: 0px !important;
  margin-bottom: 4px;
}

.pushSaveBrkBtnDown {
  margin-top: 24px;
}

.openTypeBox {
  margin-top: -18px;
  margin-left: 0px !important;
}
.openTypeSpan {
  color: #143051;
  margin-left: 5px;
  font-weight: 500;
}

.openUnitTypeDropdown {
  margin-top: -9px !important;
  margin-bottom: 8px;
}

.saveOpenTypeBtn {
  margin-top: 22px;
}

.margin5pxLeft {
  margin-left: 5px;
  font-weight: 500;
}

.openDateString {
  margin-top: -17px;
  margin-left: 5px !important;
}

.openDateInput {
  margin-bottom: 12px !important;
}

.dateOpenTxtTitle {
  margin-bottom: -10px;
}

.saveAndSpecContainer {
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: flex-end; */
  /* justify-content: ; */
}

.openDateSaveBtn {
  background-color: #12b0ff;
  max-height: 30px;
  line-height: 26px;
  color: white;
  font-weight: 500;
  border: 1px solid #0066bf;
  border-radius: 5px;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  cursor: pointer;
  max-width: 60px;
  min-width: 60px;
  margin-left: 43px;
}

.openDateSaveBtn:hover {
  background-color: #2aa6e5;
}

.openDateStripInput {
  margin-top: -17px !important;
  margin-left: 0px !important;
}

.openTimesStrip {
  margin-top: -17px !important;
  margin-left: 7px !important;
  font-weight: 500;
}

.unavilTxt {
  font-size: 12px;
  margin-left: 30px;
  margin-right: 30px;
  font-weight: 500;
  color: #859cb6;
}

.pubCorrection1 {
  margin-right: 217px;
}

.pubCorrection2 {
  margin-right: 99px;
}

.pubCorrection3 {
  margin-right: 225px;
}

.pubCorrection4 {
  margin-right: 108px;
}

.desktopMaster {
  display: flex;
  min-width: 100vw;
  background-color: #ebf0f7;
}

.desktopPurpBg {
  background-color: #403a8e;
}

.desktopWhiteBg {
  background-color: #fff;
}

.desktopMasterMain {
  min-width: calc(100vw - 610px);
  max-width: calc(100vw - 610px);
}



.desktopMasterMainWallChart {
  min-width: calc(100vw - 300px);
  max-width: calc(100vw - 300px);
  background-color: lime !important;
  height: calc(100vh - 210px);

  overflow: auto;
}

@media only screen and (min-width: 820px) {
  .desktopMasterMainWallChart {
    position: fixed;
    top: 125px;
    left: 242px;
    height: calc(100vh - 125px);
  }
}

@media only screen and (min-width: 820px) and (max-width: 1140px) {
  .desktopMasterMainWallChart {
    min-width: calc(100vw - 242px);
    max-width: calc(100vw - 242px);
    background-color: blue !important;
  }
}

@media only screen and (min-width: 1141px) {
  .desktopMasterMainWallChart {
    min-width: calc(100vw - 575px);
    max-width: calc(100vw - 575px);
    background-color: pink !important;
    left: 26%;
  }
}

.mob100Witdth {
  min-width: 100vw  !important;
  max-width: 100vw  !important;

}


.mob100WitdthPlus2 {
  min-width: calc(100vw + 1px) !important;
  max-width:calc(100vw + 1px) !important;
}

.midContentWidth {
  min-width: calc(100vw - 241px);
}
.desktopMasterSecondary {
  background-color: #ebf0f7;
  min-width: calc(100vw - 662px);
  max-width: calc(100vw - 662px);
}

.desktopDataBox {
  background-color: #214978;
  border-radius: 5px;

  min-width: 300px;
  max-width: 300px;
  margin-left: 23px;
  margin-top: 20px;

  color: #143051;
  padding-bottom: 20px;
  border: 1px solid #143051;
  /* box-shadow: 0px 0px 2px rgb(0 0 0 / 20%); */
  animation-name: moveEditTeamUserBoxUp !important;
  animation-duration: 0.2s !important;
}

@media only screen and (max-width: 550px) {
  .desktopDataBox {
    min-width: 100vw !important;
    max-width: 100vw !important;
    position: fixed;
    bottom: 0;
    border-radius: 20px 20px 0 0 !important;

  }
}

.desktopDataBoxNoMargin {
  margin-left: 0px;
  margin-top: 0px;
  padding-bottom:0px ;
  border-radius:10px;
}
@media only screen and (min-width: 1140px) {
  .desktopDataBox {
    min-width: 300px;
    max-width: 300px;
  }

  .desktopMasterMain {
    min-width: 470px;
    max-width: 470px;
  }
}

.x2948758723 {
  margin-top:-1px
}



.desktopDataBoxHeader {
  font-weight: 500;
  min-width: 100%;
  padding-left: 15px;
  min-height: 40px;
  text-align: left;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 23px;
  align-items: center;
  color: white;
}

.dataBoxHeading {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.dataBoxHeading2 {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.mgrHoursDataBoxHeading {
  margin-top: -1px;
  min-width: 120px;
}

.dataBoxYTDbtn {
     /* background-color: #fff; */
     min-height: 35px;
     display: flex;
     align-items: center;
     justify-content: center;
     min-width: -moz-fit-content;
     min-width: fit-content;
     padding-left: 12px;
     padding-right: 12px;
     border: 1px solid #749dd1;
     border-radius: 5px;
     font-size: 14px;
     margin-right: 14px;
     cursor: pointer;
     padding-bottom: 1px;
     color: #fff;
     font-weight: 500;
     /* background-color: #2b679f; */
}

.dataBoxYTDbtn:hover {
  border-color: #a9bcd4;
}
.ytdTri {
  font-size: 18px;
  margin-left: 6px;
  margin-top: -1px;
}

.hoursTri {
  font-size: 20px;
  margin-left: 4px;
  margin-top: 1px;
  padding-top: 3px;
  margin-right: 5px;
}

.desktopDataRow {
  border-bottom: 1px solid #7d9ec8;
  display: flex;
  justify-content: space-between;
  padding-left:7px;
  padding-right: 15px;
  min-height: 40px;
  align-items: center;
  max-width: 90%;
  margin-left: 5%;
  font-size: 14px;
}

.mgrDesktopDataRow {
  border-bottom: 1px solid #cdacf9;
}

.desktopDataTitle {
  font-weight: 500;
  color: #f0cbfc;
  min-width: 60px !important;
  text-align: left;
  
}

.customRemainingTxt {
  min-width: 62px;
}

.dataBoxBorderLeft {
  /* background-color: #fcfbff; */
  /* border-left: 3px solid #e3e8ee; */
}
.desktopDataValue {
  opacity: 0.8;
  font-weight: 500;
  text-align: right;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 12px;
  /* color: #859dbb */
}

.desktopRowThickBorder {
  /* border-radius: 5px; */
  padding-left: 5px;
  padding-right: 7px;
  margin-top: -6px;
  /* border:2px solid #dde9f9; */
}

.emptyDesktopDataRow {
  min-height: 10px;
}

.boldDataVal {
  font-weight: 500;
}

.statsUnderlay {
  background: rgba(0, 0, 0, 0.35);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.075s;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.hoursStatsModalBox {
  min-height: fit-content;
  max-width: 340px;
  min-width: 340px;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.showMyToggler {
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;

  margin-top: 8px;
}

.x198349811 {
  margin-top:2px
}

.closeStatsModalCrossImg {
  cursor: pointer;
  min-width: 15px;
  margin-left: 13px;
  cursor: pointer;
  margin-top: 7px;
  margin-bottom: 3px;
}

.closeStatsModalCrossImg:hover {
  opacity: 0.8;
}

.shiftsMenuTeamMyHours {
  margin-left: 8px !important;
  padding-right: 10px;

  background-color: #07f;
  border: #0469de 1px solid;
}

.shiftsMenuTeamMyHours:hover {
  background-color: #0469de;
}

.mobStatsBtn {
  min-width: fit-content;
  padding-left: 12px;
  padding-right: 10px;
  font-size: 15px;
}

.pushMyHoursTxtUp {
  font-size: 15px;
}

.narrowTitleShifts {
  max-width: 100px;
  min-width: 100px;
}

.teamHoursMobTxt {
  margin-top: 36px;
}

.teamItemSelected {
  color: #fff;
}

.teamHoursTitle {
  color: #fff;
  font-size: 23px;
}
.clockonsTitle {
  font-size: 20px;
}

@media only screen and (max-width: 400px) {
  .timesheetsTitle {
    font-size: 18px;
    margin-top: 39px;
  }
}

.teamStatsBtnHours {
  background-color: #9374d6;
  border-color: #8e76c1;
}

.teamStatsBtnHours:hover {
  background-color: #8e76c1;
}

.mobMyShiftModalBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 516px !important;
  min-height: 516px !important;
  border-radius: 5px 5px 0 0 !important;
}

.mobManagerShiftModalBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 90vh !important;
  min-height: 90vh !important;

  border-radius: 5px 5px 0 0 !important;
}

.mobManagerOpenShiftModalBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 609px !important;
  min-height: 609px !important;

  border-radius: 5px 5px 0 0 !important;
}

.newOpenShiftModalBoxHeights {
  max-height: 609px !important;
  min-height: 609px !important;
}

.clockItemMobModalBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 90vh !important;
  min-height: 90vh !important;
  border-radius: 5px 5px 0 0 !important;
  padding-left: 5% !important;
  padding-right: 5% !important;
}

.mobClockFooter {
  padding-bottom: 0px;
}

.mobClockModalBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 518px !important;
  min-height: 518px !important;
  border-radius: 5px 5px 0 0 !important;
}

.mobClockModalBody {
  min-height: calc(90vh - 162px) !important;
  max-height: calc(90vh - 162px) !important;
}

.smallerWhiteBoxMobBeforeClockedOn {
  min-height: 164px !important;
  max-height: 164px !important;
}

.mobMyShiftModalBody {
  /* min-height: calc(82vh - 150px) !important;
  max-height: calc(82vh - 150px) !important; */
  min-height: 366px  !important;
  max-height: 366px  !important;

}

.minWidth33 {
  min-width: 33%  !important;
  max-width: 33%  !important;

}

.mobMyShiftModalBodyOpen {
  min-height: 371px !important;
    max-height: 371px !important;
}

.mobMyShiftModalBody2 {
  /* min-height: calc(82vh - 150px) !important;
  max-height: calc(82vh - 150px) !important; */
  min-height: 366px  !important;
  max-height: 366px  !important;

}


.mobAbsenceUnitModalBody {
  min-height: calc(90vh - 113px) !important;
  max-height: calc(90vh - 113px) !important;
}

.mobAbsenceUnitModalBodyMgr {
  min-height: calc(90vh - 156px) !important;
  max-height: calc(90vh - 156px) !important;
}

.mobManagerShiftModalBody {
  min-height: calc(90vh - 160px) !important;
  max-height: calc(90vh - 160px) !important;
}

.zoomIn {
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}


.move5pxR {
  margin-left: 24px !important;
}

.mobNotYetClockedModalBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 318px !important;
  min-height: 318px !important;
  border-radius: 5px 5px 0 0 !important;
}

.mobClockOnButNoBreak {
  min-height: 208px !important;
  max-height: 208px !important;
}

.mobClockOnButNoBreakBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 316px !important;
  min-height: 316px !important;
  border-radius: 5px 5px 0 0 !important;
}

.mobClockOnWithBreak1StartOnly {
  min-height: 294px !important;
  max-height: 294px !important;
}

.mobClockOnWithBreak1StartOnlyBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 402px !important;
  min-height: 402px !important;
  border-radius: 5px 5px 0 0 !important;
}

.myClockRow {
  border-bottom: 1px solid #e0ebf8;
  display: flex;
  padding-top: 3px;
  justify-content: flex-start;
  padding-left: 5%;
  padding-right: 5%;
  min-height: 80px;
  min-width: 100%;
}

.clockedRowSched {
  margin-top: 16px;
}

.clockedRow {
  background-color: #f4f8fe;
  min-width: 100%;
  font-weight: 500;
}

.clockTimesLeft {
  font-weight: 500;
  color: #496375;
  font-size: 18px;
  min-width: 35%;
  max-width: 35%;
}

.clockTimesRight {
  font-weight: 500;
  text-align: left;
  color: #496375;
  font-size: 18px;
  border-left: 1px solid #e0ebf8;
  min-height: 100%;
  margin-top: -3px;
  padding-top: 3px;
  padding-left: 20px;
  margin-left: 30px;
}

.clockStartEndTimeSpan {
  font-size: 18px;
}

.clockBreakBetweenSpan {
  font-size: 0px;
  margin-right: 14px;
  color: #859cb6;
  padding-left: 0px;
  padding-right: 0px;
  font-weight: 300;
  border-right: 1px solid #e0ebf8;
  margin-left: -7px;
}

.mobScheduledNotClockOnBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 354px !important;
  min-height: 354px !important;
  border-radius: 5px 5px 0 0 !important;
}

.mobClockOnBodyScheduledWithoutClockOn {
  min-height: 200px !important;
  max-height: 200px !important;
}

.clockedOnButNoBreak1MobScheduledBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 402px !important;
  min-height: 402px !important;
  border-radius: 5px 5px 0 0 !important;
}

.clockedOnButNoBreak1MobScheduledBody {
  min-height: 294px !important;
  max-height: 294px !important;
}

.clockedOnButBreak1MobScheduledBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 4px !important;
  min-height: 488px !important;
  border-radius: 5px 5px 0 0 !important;
}

.clockedOnButBreak1MobScheduledBody {
  min-height: 380px !important;
  max-height: 380px !important;
}

.clockedOnWithFullBreakNoSchedBody {
  min-height: 369px !important;
  max-height: 369px !important;
}

.clockedOnButBreak1EndMobScheduledBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 562px !important;
  min-height: 562px !important;
  border-radius: 5px 5px 0 0 !important;
}

.clockedOnButBreak1EndMobScheduledBody {
  min-height: 454px !important;
  max-height: 454px !important;
}

.hoveredUserRowToday:hover {
  /* background-color: #f4f8fe; */
}



.viewClockItemBtn {
  font-size: 0.9em;
  color: white;
  background-color: #214978;
  border: solid #143051 1px;
  font-weight: 500;
  padding-left: 7px;
  padding-right: 8px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 30px;
  margin-top: 18px;
  margin-bottom: 8px;
  cursor: pointer;
  min-width: -moz-fit-content;
  min-width: 95px;
  max-width: 95px;
  margin-top: 6px;
  margin-right: 8px;
  border-left: 3px solid #143051;
}

.viewClockItemBtn:hover {
  background-color: #143051;
}

.clockCardBtnAndApproveStatus {
  margin-right: 12px;
  min-width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 5px;
}

.clockAppStatusNotYetApproved {
  max-height: 24px;
  line-height: 23px;
  color: #00ccff;
  font-size: 10px;
  font-weight: 500;
  /* border-radius: 5px; */
  padding-right: 5px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: 82px;
  position: relative;
  z-index: 999999;
  text-align: right;
  border-right: solid #00ccff 2px;
  margin-right: 8px;
}

.clockAppStatusApproved {
  max-height: 24px;
  line-height: 23px;
  color: #6db451;
  font-size: 10px;
  font-weight: 500;
  /* border-radius: 5px; */
  padding-right: 5px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: 82px;
  position: relative;
  z-index: 999999;
  text-align: right;
  border-right: solid #6db451 2px;
  margin-right: 8px;
}

.clockAppStatusDeclined {
  max-height: 24px;
  line-height: 23px;
  color: #e26666;
  font-size: 10px;
  font-weight: 500;
  /* border-radius: 5px; */
  padding-right: 5px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: 82px;
  position: relative;
  z-index: 9999;
  text-align: right;
  border-right: solid #e26666 2px;
  margin-right: 8px;
}

.clocksSchedSubLeft {
  /* display: flex; */
  /* flex-direction: row; */
  /* align-items: center; */
  /* justify-content: flex-start; */
  margin-left: 8px;
  font-size: 14px;
  min-width: fit-content;
}
.clockSchedTitle {
  font-weight: 400;
    color: #758aa2;
    margin-left: 3px;
    font-size: 10px;
    display: flex;
    align-items: center;
    text-align: left;
    min-width: 110px !important;
    margin-bottom: 2px;
}

.clockSchedValue {
  font-weight: 500;
  color: #214978;
  margin-left: 4px;
  text-align: left;
  font-size:12px;
}

.clockBrkSpan {
  color: #5479a3;
  border-radius: 5px;
  font-size: 11px;
  padding-right: 2px;
}

.clocksSchedSubRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  min-width: 140px;

  min-height: 35px;
}

.flexEnd {
  justify-content: flex-end !important;
}

.approveClock {
  min-height: 30px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: 50px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 8px;
  background-color: #82c26a;
  color: white;
  border: 1px solid #72af5b;
}

.clockIsApproved {
  min-height: 16px;
  max-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6db451;
  font-size: 12px;
  text-align: center;
  /* border-radius: 5px; */
  font-weight: 500;
  margin-right: 14px;
  border-right: 2px solid #6db451;
  padding-right: 6px;
}

.clockIsDeclined {
  min-height: 16px;
  max-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e89292;
  font-size: 12px;
  text-align: center;
  /* border-radius: 5px; */
  font-weight: 500;
  margin-right: 14px;
  border-right: 2px solid #e89292;
  padding-right: 6px;
}

.approveClock:hover {
  background-color: #77b75f;
}

.openClock {
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: 50px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 1em;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #0af;
  border: solid #1e88e5 1px;
  margin-right: 8px;
  display: none;
}

.openClock:hover {
  background-color: #3b98ea;
}

.unlinkedClockImg {
  max-width: 23px;
  margin-right: 4px;
  margin-top: -3px;
}

.noShiftSchedImg {
  max-width: 18px;
  min-width: 18px;
  margin-right: 9px;
  margin-top: -3px;
  padding-top: 4px;
  margin-left: 5px;
}
.font12px {
  font-size: 12px !important;
}

.clocksFilterBar {
  min-height: 60px;
}

.clockCardProfilePicImg {
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  object-fit: cover;
  border-radius: 100%;
  margin-left: 30px;
  border: 1px solid #c6b5eb;
  cursor: pointer;
}

.clockCardProfilePicImg:hover {
  border: 2px solid #143051;
}

.clockCardHeader {
  max-height: 50px;
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-top: -12px;
}

.noExistJtActions {
  pointer-events: none;
  visibility: hidden;
}

.fadeClockedUnderOver {
  visibility: hidden;
}

.clocksAwaitingTitles {
  text-align: left;
  padding-left: 7%;
  font-weight: 500;
  color: #bbcada;
  font-size: 14px;
  margin-top: 16px;
  min-height: 30px;
  margin-bottom: -4px;
}

.outsideOfMyMonthSpan {
  font-size: 16px;
  margin-left: 2px;
  /* color: #bbcada; */
}

.awaitingClockDivider {
  background-color: #214978;
  min-width: 80%;
  min-height: 4px;
  max-width: 80%;
  margin-left: 10%;
  margin-top: 30px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.unlinkedExlaim {
  font-weight: 900;
  font-size: 24px;

  color: #ba0000;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 20px;
}

.unlinkedFiltBtnCorrection {
  margin-top: 8px;
  max-height: 35px;
  min-height: 35px;

  line-height: 25px;
  margin-right: 10px;
  align-items: center;
  min-width: fit-content !important;
  border-left: 3px solid #965ade !important;
}

.teamClockOnsFilterBarRight {
  /* min-width: 204px; */
  max-width: 204px;
  min-height: 100%;
  /* margin-right: 5%; */

  font-size: 0.9em;
  font-weight: 500;
  display: flex;

  align-items: center;
  justify-content: flex-end;
}

.narrowerFilterLeft {
  min-width: 30%;
  max-width: 30%;
}

.unlinkedQtySpan {
  border-left: 1px solid #9374d6;

  max-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 6px;
  padding-right: 3px;
  margin-left: 7px;
  min-width: 16px;
}

.invalidClockCardBtn {
  font-size: 10px;
  color: #bbcada;
  max-width: 90px;
  line-height: 12px;
  text-align: right;
  margin-right: 14px;
  font-weight: 500;
  margin-top: 4px;
}

.managerShiftClockBreakSpan {
  font-size: 12px;
  min-height: 26px;
  max-height: 26px;
  line-height: 16px;
  display: flex;
  align-items: flex-start;
  margin-top: -8px;
  margin-left: 5px;
  color: #bbcada;
}

.managerShiftProPicImg {
  max-width: 40px;
  max-height: 40px;
  min-width: 40px;
  min-height: 40px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #cbd7e6;
  margin-left: 5%;

  margin-right: -12px;
  cursor: pointer;
}

.managerShiftProPicImg:hover {
  border: 2px solid #cbd7e6;
}

.editShiftRowEmployeeNameAndPic {
  border-bottom: 1px solid #e0ebf8;
  display: flex;
  padding-top: 3px;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 100%;
  padding-top: 20px;
}

.managerShiftInitialsPlaceholder {
  max-width: 40px;
  max-height: 40px;
  min-width: 40px;
  min-height: 40px;
  object-fit: cover;
  border-radius: 5px;
  border: 2px solid #140351;
  margin-left: 5%;
  /* margin-top: 15px; */
  margin-right: -12px;
  cursor: pointer;
  background-color: #214978;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  padding-top: 2px;
}

.managerShiftInitialsPlaceholder:hover {
  background-color: #140351;
}

.greenBg {
  background-color: #82c26a;
}

.hoursBorderRight {
  min-width: 2px;
  background-color: #214978;
  max-height: 40px;
  margin-left: 12px;
  margin-right: 6px;
  margin-top: 2px;
}

.purpleHoursDivider {
  background-color: #fff;
  margin-right: 6px;
}

.jtUserProfilePicImg {
  max-width: 36px;
  min-width: 36px;
  max-height: 54px;
  min-height: 54px;
  object-fit: cover;
  border-radius: 5px;
  margin-top: 0px;
  margin-left: -14px;
  margin-right: 3px;
  z-index: 0;
}

.myRotaProPic {
  max-height: 58px !important;
  min-height: 58px !important;
  margin-top: -1px;
  /* border-radius:0px !important */
}

.secondaryProfPicImg {
  /* max-height: 48px;
  min-height: 48px;
  border-radius: 5px 5px 0 0; */
}

.desktopUserIsWorkingNowLight {
  border-left: 3px solid #96e578;
  border-radius: 3px 5px 5px 3px;
  margin-left: -15px;
}

.x01931131 {
  margin-left: -20px;
}
.pushLimBtnRight {
  margin-right: -24px;
  padding: 4px;
  border: 1px solid #30305f;
  border-radius: 5px;
  max-width: 21px;
  background-color: #7a7ac1;
}

.revIconToTheRight {
}
.greyBg {
  background-color: #516b8b !important;
}

.declinedClockDot {
  background-color: #e89292 !important;
  min-width: 10px;
  max-width: 10px;
  min-height: 10px;
  max-height: 10px;
  border-radius: 10px;
}

@media only screen and (max-width: 460px) {
  .statsBtnTxt {
  }

  .statsBtnTxtTeam {
    margin-right: 5px;
    display: inline;
  }

  .statsImg {
    max-width: 15px;
    margin-left: 4px;
    margin-right: 8px;
    margin-top: 0px;
  }

  .statsBtn {
    padding-left: 8px;
    padding-right: 4px;

    min-width: 130px;
  }
}

.hoursAndCostContainer {
  display: flex;
  justify-content: flex-end;
  min-width: 140px;
}

.hoursCostItem {
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;

  align-items: center;
  border-radius: 5px;
  border: 1px solid #e6eaee;
  color: #fff;
  font-weight: 500;
  font-size: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #fff !important;
  margin-left: 9px;
  background-color: #c6b5eb;
  /* margin-top: 3px; */
  /* margin-bottom: -3px; */
  border: #ac99d4 solid 1px;
}

.isFutureTeamHoursCost {
}

.clockCardsMenuItem {
  margin-left: 0px !important;
  margin-right: 5px !important;
}

.unlinkedSection0 {
  margin-left: -24px;
}

.clockSection0 {
  min-width: 0px;
  max-width: 0px;
}

.leftBorderGhost {
  border-left: 5px solid #cce3ff !important;
}

.partialAbsentBlockWorked {
  background-color: #a8cc9a;
  min-width: 5px;
  min-height: 72px;
  margin-right: -46px;
}

.whiteBg {
  background-color: white !important;
}

.partialAbsentBlockUnWorked {
  background-color: #cce3ff;
  min-width: 5px;
  min-height: 72px;
  margin-right: -46px;
}

.partAbsDivPlaceholder {
  background-color: rgb(255, 255, 255, 0);
  min-width: 5px;
  min-height: 72px;
  margin-right: -46px;
}

.noLeaveReqP {
  font-size: 10px;
  min-width: 52px;
  padding-right: 0px;
}

.partialAbsentCorrectionMob {
  margin-right: -35px;
}

.partialAbsentCorrectionMobWorked {
  margin-right: -15px;
}

.ghostAbsDivPlaceholderColour {
  background-color: #deedff;
}

.partialAbsentShiftDurString {
  max-width: fit-content;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 3px;

  font-weight: 500;
  /* font-size: 14px; */
  padding-top: 2px;
  padding-bottom: 2px;
  background: rgb(247, 250, 255);

  color: #ba0000;
  background: linear-gradient(66deg, #f5e5e5 50%, rgb(247, 250, 255) 50%);
  border: 1px solid #dcc7c7;

  font-size: 12px;
  font-weight: 500;
  margin-bottom: 3px;
  /* background: #f4f8fe; */
  border-radius: 5px;
  /* border: 1px solid #bbcada; */
  /* color: #bbcada; */
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
  margin-bottom: 6px;
}

.fullyAbsentShiftDurString {
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 3px;
  color: #496375;
  font-weight: 500;
  /* font-size: 14px; */
  padding-top: 2px;
  padding-bottom: 2px;
  background: rgb(247, 250, 255);
  background-color: #e3e8ee;
  color: #4f566b;
  background-color: #f5e5e5;
  color: #ba0000;
  border: 1px solid #dcc7c7;
  margin-bottom: 8px;

  font-size: 12px;
  font-weight: 500;
  margin-bottom: 3px;
  /* background: #f4f8fe; */
  border-radius: 5px;
  /* border: 1px solid #bbcada; */
  /* color: #bbcada; */
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
  margin-bottom: 6px;
}

.hoursBorderRightTeam {
  min-width: 2px;
  background-color: #fff;
  max-height: 40px;
  margin-left: 12px;
  margin-right: 6px;
  margin-top: 2px;
}

.teamHeaderColours {
  background-color: #403a8e !important;
  border-bottom: 1px solid #403a8e !important;
}

.myRotaColours {
  background-color: #fff !important;
  /* border-bottom: 1px solid #d1dfee !important; */
}

.colourWhite {
  color: white !important;
}

.colourWhite:hover {
  color: white !important;
}
.publishedShiftLight {
  color: #bbcada !important;
  border-top: 1px solid #e3e8ee !important;
  margin-left: -18px;
  font-weight: 500;
  max-height: 18px;
  margin-right: -4px;
  padding-top: 1px;
}

.whiteGoImg {
  max-width: 10px;
  margin-right: 6px;
  margin-left: -6px;
  margin-top: 1px;
}

.whiteGoImgMyHours {
  max-width: 10px;
  margin-left: 5px;
  margin-right: -2px;
}

.teamSchedLoadingBallsImg {
  margin-top: 35px;
}

.invalidAddShiftBtn {
  opacity: 0.4;
  pointer-events: none;
  cursor: default;
}

.addDateAndDayStringContainer {
  display: flex;
}

.addShiftDayString {
  margin-left: 7px;
  font-weight: 500;
  font-size: 14px;

  color: #bbcada;
  padding-left: 6px;
  padding-right: 6px;
  max-height: 26px;
  display: flex;
  align-items: center;
  margin-top: 9px;
  border-radius: 5px;
}

.saveTimesLoadTemplateBtnAndSaveContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.saveTimesNewItemBtn {
  max-width: 50px;
}

.loadTimesTemplateBtn {
  background-color: #67adff;
  border: solid 1px #106ad0;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
  margin-right: 12px;
  margin-top: 2px;
  margin-bottom: -1px;
}

.loadTimesTemplateBtn:hover {
  background-color: #5c9fee;
}

.unlinkedClockCardsExplain {
  font-size: 12px;
  text-align: left;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 26px;
  color: #bbcada;
  min-height: 180px;
  font-weight: 500;
  border-left: 2px solid #bbcada;
  margin-left: 5%;
  margin-top: 20px;
  line-height: 17px;
}

.unlinkedClockCardsExplainMob {
  padding-left: 25px;
  min-height: 190px;
  margin-bottom: 0px;
}

.teamSchedMgrAvailBtn {
  background-color: blue;
  background-color: #9a74eb;
  border: 2px solid #9471bf;
  border-radius: 5px;
  color: white;
  min-height: 34px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
  position: fixed;
  top: 60px;
  right: 162px;
}

.teamSchedMgrAvailBtnMob {
  background-color: #9a74eb;
  border-color: #9471bf;
  color: #fff;
  top: 68px;
  right: 20px;
}

.teamSchedMgrAvailBtn:hover {
  background-color: #9471bf;
}

.teamSchedMgrAvailBtnMob:hover {
  background-color: #8e76c1;
}

.userSchedMgrAvailBtn {
  border: 1px solid #e6eaee;
  background-color: #fff;
  border-radius: 5px;
  min-height: 34px;
  color: #859dbb;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
  position: fixed;
  top: 62px;
  right: 144px;
  font-size: 14px;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
}

.nonMgrAvailBtn {
  right: 40px !important;
}

.nonMgrAvailBtnMob {
  right: 22px !important;
  top: 31px !important;}

  @media only screen and (min-width: 401px) and (max-width: 819px) {
    .nonMgrAvailBtnMob {
      right: 20px !important;
      top: 65px !important;
    }
  
  }
 
.userSchedMgrAvailBtnMob {
  border: 1px solid #e6eaee;
  background-color: #fff;
min-height:35px !important;
top: 61px;
right: 136px;
}

.myAllowsWhiteBtn {
  border: 1px solid #e6eaee;
  background-color: #fff;
  border-radius: 5px;
  min-height: 34px;
  color: #143051;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
  /* position: fixed;
  top: 62px;
  right: 110px; */
  font-size: 14px;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  /* margin-left:5%; */
  margin-top: 8px;
}

.minWidth140 {
  min-width: 140px !important;
}
.myAllowsWhiteBtn:hover {
  border-color: #dce2e9;
  background-color: #fbfdff;
}

.myAbsencePerfWhiteBtn {
  border: 1px solid #e6eaee;
  background-color: #fff;
  border-radius: 5px;
  min-height: 34px;
  color: #143051;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  font-weight: 500;
  font-size: 14px;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  /* margin-left: 4%; */
  /* margin-top: -1px; */
}
.myAbsencePerfWhiteBtn:hover {
  border-color: #d7dfe9;
}

.myAllowsWhiteBtnMob {
  border: 1px solid #e6eaee;
  background-color: #fff;
  box-shadow: none;
  /* top: 62px;
  right: 110px; */
}

@media only screen and (max-width: 414px) {
  .userSchedMgrAvailBtnMob {
    top: 73px;
    right: 20px;
  }

  .staffRotaBtnMob {
    top: 30px !important;
    right: 20px !important;
  }
}

.userSchedMgrAvailBtn:hover {
  border-color: #d7dfe9;
}

.userSchedMgrAvailBtnMob:hover {
  border-color: #d7dfe9;
}

.staffRotaBtn {
  background-color: #b49ce7;
  border: 1px solid #a47fd0;
  border-radius: 5px;
  color: white;
  min-height: 35px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* padding-top:2px; */
  padding-left: 10px;
  padding-right: 6px;
  font-weight: 500;
  position: fixed;
  /* line-height:18px !important; */
  top: 61px;
  right: 40px;
  font-size: 14px;
  /* line-height:13px; */
}

.staffRotaBtnMob {
  background-color: #b49ce7;
  border-color: #9471bf;
  color: #fff;
  top: 61px;
  right: 35px;
}

.staffRotaBtn:hover {
  background-color: #a58fd4;
}

.staffRotaBtnBtnMob:hover {
  background-color: #9a74eb;
}

.staffRotaWhiteGoImg {
  max-width: 10px;
  margin-left: 7px;
  margin-right: 1px;
}

.availTeamDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 228px;
  min-width: 228px;
  line-height: 32px;
  height: 35px;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 205px;
  margin-right: 10px;
  margin-top: 2px;
}

.availTeamDropdown:hover {
  border-color: #d7dfe9;
}

.mgrAvailModalBody {
  background-color: #f4f8fe;

  border-radius: 6px 6px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
  max-height: calc(90vh - 300px);

  min-width: 94%;
  max-width: 94%;
  margin: 0 auto;
  margin-top: 10px;
}

.mgrAvailModalBodyMob {
  background-color: #f4f8fe;

  border-radius: 6px 6px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
  max-height: calc(90vh - 105px);
  min-height: calc(90vh - 105px);
  padding-top: 10px;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 10px;
}

.teamAvailLeftRightBtn {
  margin-top: 9px;
}

.mgrAvailFooter {
  display: flex;
  justify-content: space-between;
  min-width: 94%;
  max-width: 94%;
  margin-left: 3%;
  align-items: center;
  min-height: 50px;
  border-top: solid 1px #bac5d3;
}

.closeMgrAvailReqBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  max-width: 62px;
  min-width: 62px;
}

.closeMgrAvailReqBtn:hover {
  background-color: #214978;
}

.mgrAvailUserDiv {
  border-top: solid 2px #e6eaee;
  border-radius: 6px 6px 0 0;
  background-color: #fbfdff;
  overflow-y: scroll;
}

.mgrAvailUserDivMob {
  min-height: calc(90vh - 200px);
}

.x283874821 {
  min-height: calc(90vh - 390px);
}


.mgrAvailUserRow {
  min-width: 100%;
  max-width: 100%;
  border-top: solid 1px #bac5d3;
  padding-top: 5px;
  padding-bottom: 5px;
}

.mgrAvailUserRow:first-of-type {
  border: none;
}

.mgrAvailUserRowTop {
  min-width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  align-items: flex-end;
}

.mgrAvailUserRowTopLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 15px;
}

.mgrAvailUserRowProfPic {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
}

.mgrAvailUserRowProfPic:hover {
  opacity: 0.8;
}

.mgrAvailUserRowNameTxt {
  font-weight: 500;
  color: #214978;
  font-size: 18px;
  margin-left: 10px;
  margin-top: 12px;
  cursor: pointer;
}

.mgrAvailUserRowTopRight {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.mgrAvailUserRowTopRightViewAvailBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 72px;
  padding-left: 9px;
  padding-right: 9px;
  min-height: 35px;
  max-height: 35px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 20px;
}

.mgrAvailUserRowTopRightViewAvailBtn:hover {
  background-color: #bbcada;
}

.mgrAvailUserRowBottom {
  min-width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
  margin-bottom: 15px;
}

.mgrAvailUserRowBottomSubmittedBtn {
  border-radius: 5px;

  color: #74aff5;

  font-size: 14px;
  padding-left: 2px;
  padding-right: 9px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.mgrAvailUserRowTimes {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.mgrAvailUserRowTimeStrip {
  border: solid 1px #dae4f1;

  color: #8ea7c5;
  background-color: #fff;
  color: #7fbb67;
  min-height: 20px;
  display: flex;
  align-items: center;
  border-radius: 5px;

  font-size: 14px;
  font-weight: 500;

  padding: 6px;
}

.mgrUnavailUserRowTimeStrip {
  border-radius: 5px;

  font-size: 14px;
  padding-left: 2px;

  font-weight: 500;
  display: flex;
  align-items: center;
  margin-left: 15px;
  color: #e26666;
}

.mgrAvailRequestedStrip {
  color: #8ea7c5;
  font-size: 14px;
  font-weight: 400;
  padding-left: 15px;
  padding-right: 6px;
  margin-top: -3px;
  min-height: 23px;
}

.mgrAvailUserTimesAmperSand {
  color: #74aff5;
  font-size: 12px;
  font-weight: 500;
  padding-left: 4px;
  padding-right: 4px;
}

.requestAvailBtn {
  border-radius: 5px;
  background-color: #9a74eb;
  color: white;
  border: 1px solid #7651c6;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-left: 10px;
  min-height: 35px;
  cursor: pointer;
  margin-top: 4px;
}

.reqAvailBenIcon {
  max-width: 12px;
  /* margin-right: 5px; */
}

.requestAvailBtn:hover {
  background-color: #7651c6;
}

.requestAllAvailBtn {
  border-radius: 5px;
  background-color: #8ea7c5;
  border: 1px solid #6987a9;
  color: white;
  font-size: 14px;
  padding-left: 9px;
  padding-right: 9px;
  font-weight: 500;
  display: flex;
  align-items: center;

  min-height: 30px;
  cursor: pointer;
}
.reqAvailAllOptionsDiv {
  display: flex;
}

.whiteCrossReqAvailAllImg {
  min-height: 30px;
  min-width: 30px;
  border-radius: 5px;
  background-color: #8ea7c5;
  border: 1px solid #6987a9;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.reqAvailWeekDayBtn {
  min-height: 30px;
  min-width: 30px;
  border-radius: 5px;
  background-color: #0bf;
  color: white;
  border: 1px solid #0af;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  font-weight: 500;
  font-size: 14px;
  padding-left: 6px;
  padding-right: 6px;
}

.availRequestedStrip {
  font-size: 14px;
}

.topAvailSelectRow {
}

.teamAvailDayDiv {
  min-height: 100%;
  display: flex;
  justify-content: flex-start;
  margin-right: 10px;
}

.rerequestAvailBtn {
  cursor: pointer;
  max-width: 25px;
  margin-bottom: -7px;
}

.rerequestAvailBtn:hover {
  opacity: 0.8;
}

.mobMgrAvailModalBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 90vh !important;
  min-height: 90vh !important;
  border-radius: 5px 5px 0 0 !important;
}

.mgrAvailFooterViewModeDiv {
  display: flex;
  min-width: 70px;
}
.mgrAvailDayModeBtn {
  font-size: 14px;
  font-weight: 500;
  color: #859dbb;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 72px;
  padding-left: 8px;
  padding-right: 9px;
  min-height: 35px;
  max-height: 35px;
  /* background-color: #8ea7c5; */
  border: 1px solid #d7dfe9;
  border-radius: 5px;
  cursor: pointer;
  margin-left:8px;
}

.availMonthViewPopupImg {
  max-width: 15px;
  margin-left:4px;
  /* margin-right:5px; */
  /* border:1px solid #bbcada; */
  /* padding:2px; */
  /* min-height:16px; */
  /* border-radius: 3px; */
}

.mgrAvailDayModeBtn:hover {
  border-color: #bbcada;
}

.dayMonWeekSelectedMode {
  /* background: none;
  color: #bbcada;
  border: none;
  border-bottom: 2px solid #e7ebf1;
  border-radius: 0;
  cursor: default; */
  /* pointer-events: none; */
  /* display: none; */
}

.addAvailLoadingImg {
  min-height: 10px;
}

.usersMonWeekAvailContainer {
  display: flex;
  min-height: 100%;
}

.monWeekAvailUserRow {
  border-right: solid 1px #bac5d3;
  border-top: solid 1px #bac5d3;
  padding-top: 10px;

  min-width: 120px !important;
  max-height: 90px;
  min-height: 90px;
}

.monWeekAvailUserRow:first-of-type {
  border-top: none;
}

.monWeekAvailUserProPicImg {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  object-fit: cover;
  border-radius: 5px;

  cursor: pointer;
  margin-left: 4px;
}

.monWeekAvailUserProPicImg:hover {
  opacity: 0.8;
}

.monWeekAvailUserRowImgAndReqBtnContainer {
  display: flex;
  font-size: 14px;
  align-items: center;
  min-height: 32px;
}

.monWeekAvailNameContainer {
  text-align: left;
  font-weight: 500;
  color: #214978;
  font-size: 12px;
  margin-top: 6px;
  margin-left: 4px;
}

.monWeekAvailRowRequestBtn {
  border-radius: 3px;
  background-color: #a58fd4;
  color: white;
  border: 1px solid #a47fd0;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 7px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-left: 4px;
  min-height: 30px;
  cursor: pointer;
  margin-top: 0px;
  display: flex;
  align-items: center;
}

.monWeekAvailRowRequestBtn2 {
  border-radius: 3px;
  background-color: #a58fd4;
  color: white;
  border: 1px solid #a47fd0;
  font-size: 12px;
  /* padding-left: 5px; */
  /* padding-right: 7px; */
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-left: 4px;
  min-height: 26px;
  cursor: pointer;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 26px;
}

.monWeekAvailRowRequestBtn:hover {
  border-color: #a47fd0;
}

.monWeekAvailRowRequestBtn2:hover {
  border-color: #a47fd0;
}

.availMsgIcon {
  min-width: 26px;
  max-width: 26px;
  background-color: #07f;
  border: #0469de 1px solid;
  max-height: 26px;
  border-radius: 3px;
  margin-left: 4px;
  padding: 5px;
  cursor: pointer;
}

.availMsgIcon:hover {
  border-color: #036ce4;
}

.monWeekAvailRowRequestBtn:hover {
  background-color: #a47fd0;
}

.usersMonWeeksDataCol {
  min-width: calc(100% - 120px);
  max-width: calc(100% - 120px);

  min-height: 100%;
  display: flex;
  overflow-x: scroll;
}

.teamAvailDsCol {
  min-width: 130px;
  min-width: 130px;

  border-right: 1px solid #e6eaee;
}

.teamAvailDsCol:last-child {
  border: none;
}

@media only screen and (min-width: 500px) and (max-width: 1100px) {
  .mobileMgrAvailModalBoxMediaQ {
    max-width: 90vw;
    min-width: 90vw;
  }
}

@media only screen and (min-width: 1171px) {
  .mobileMgrAvailModalBoxMediaQ {
    max-width: 1150px !important;
    min-width: 1150px !important;
  }
}

.dayViewAvailModalBox {
  margin-top: -2px !important;
}

.mobAddAvailSelectorDiv {
}

.availSpanFormatting {
  color: #49b63e;
  padding: 5px;
  border-radius: 3px;
  max-height: 17px;
  display: flex;
  align-items: center;
  padding-left: 0px;
  margin-left: 5px;
}
.availSpanFormatting:hover {
}

.availSelectRowTeamDay {
}

.teamAvailNameRowBlankTitle {
  min-height: 40px;
  min-width: 100%;
}

.availUserColDayCell {
  color: #214978;
  font-size: 18px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.monWeekAvailUserRow:nth-child(odd) {
  background-color: #f4f8fe;
}

.mgrAvailNameDayRow {
  min-height: 90px;
  max-height: 90px;

  border-top: solid 1px #bac5d3;
}

.mgrAvailNameDayRow:first-child {
  border-top: none;
}

.mgrAvailNameDayRow:nth-child(odd) {
  background-color: #f4f8fe;
}

.mgrAvailRowDsCell {
  color: #516b8b;
  text-align: left;
  font-weight: 500;
  /* padding-left: 9px; */
  /* padding-top: 5px; */
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  font-size: 12px;
  /* border-left: 3px solid #0af; */
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-left: 7px;
  min-height: 24px;
}

.availAddShiftBtn {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  padding-left: 4px;
  padding-right: 4px;
  min-height: 24px;
  max-height: 24px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
  padding-bottom: 3px;
  margin-right: 14px;
}

.availAddShiftBtn:hover {
  background-color: #bbcada;
}

.mgrAvailUserInlineTimes1Row {
  border-radius: 3px;
  /* max-width: 112px; */
  min-width: -moz-fit-content;
  min-width: fit-content;
  font-weight: 500;
  /* margin-left: 3px; */
  /* margin: 0 auto; */
  margin-top: 2px;
  font-size: 12px;
  /* padding-left: 5px; */
  padding-right: 5px;
  max-height: 33px;
  color: #49b63e;
  text-align: left;
  /* margin-left: -10px; */
}

.mgrAvailUserInlineTimes2Row {
  /* background-color: #7fbb67; */
  /* color: white; */
  /* border: 1px solid #5a9e3f; */
  border-radius: 3px;
  /* margin-left: -12px; */
  min-width: fit-content;
  font-weight: 500;
  /* margin-left: 3px; */
  /* margin: 0 auto; */
  font-size: 12px;
  /* padding-left: 5px; */
  padding-right: 5px;
  max-height: 20px;
  /* margin-top: 2px; */
  color: #49b63e;
  text-align: left;
}

.x248149 {
  font-size: 10px;
  line-height: 8px;
  margin-bottom: -2px;
  opacity: 0.5;
  font-weight:400
}

.mgrAvailUserInlineTimesAmper {
  color: #cbdaec;
  font-weight: 500;
  font-size: 10px;
  margin-top: -2px;
  margin-bottom: -8px;
  margin-left: -15px;
  display: none;
}

.mgrAvailCellBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 51px;
  min-width: 100%;
  padding-left: 8px;
}

.mgrAvailUserCellUnavailable {
  color: #94a7bf;
  border-radius: 0px;
  max-width: 106px;
  font-weight: 500;
  /* margin-left: 3px; */
  font-size: 12px;
  /* padding-left: 8px; */
  /* padding-right: 5px; */
  max-height: 20px;
  margin-top: 7px;
  /* margin-left: -14px; */
  border-left: 0px solid #ba0000;

}

.highlightAvailUser {
  border-bottom: 2px solid #143051;
}

.selectedUserAvailRowInline {
  border-bottom: 2px solid #143051;
}

.closedAvailDayCol {
  background-color: #e4eef9 !important;
}

.weekViewUserRequested {
  text-align: left;
  color: #8ea7c5;
  font-size: 12px;
  font-weight: 500;
  line-height: 9px;
  padding-left: 8px;
  max-height: 32px;
  padding-bottom: 2px;
}

.rerequestWeekViewAvailImg {
  margin-top: -2px;
  padding-top: 2px;
  max-width: 20px;
}

.availWeekMsgBtn {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 66px;
  max-width: 66px;
  padding-left: 1px;
  padding-right: 1px;
  min-height: 24px;
  max-height: 24px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 3px;
  cursor: pointer;
}

.availWeekMsgBtn:hover {
  background-color: #bbcada;
}

.vhIssue {
  padding-bottom: 130px !important;
}

.shiftUserInfoTriangle {
  min-width: 35px;
  max-width: 35px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35px;
  max-height: 35px;

  border-radius: 5px;
  padding: 9px;
  border: 2px solid #e3e8ee;
  margin-right: 18px;
}

.shiftUserInfoTriangle:hover {
  border: 2px solid #b2bbc7;
}
.shiftExpandInfoImg {
  max-width: 15px;
}

.expandGreyCloseShiftInfo {
  padding: 1px;
}

.shiftStaffInfoRowTop {
  display: flex;
  justify-content: flex-end;
}

.shiftDataTableHolder {
  display: flex;
  justify-content: flex-end;
}

.shiftDataTable {
  background-color: #f4f8fe;
  min-width: 90%;
  max-width: 90%;
  margin-right: 5%;
  padding: 10px;
  border-radius: 0 0 5px 5px;
  border: 1px solid #e6eaee;
  border-top: none;
  border-bottom: 2px solid #e6eaee;
}

.shiftDataTableRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 45px;
  padding-left: 10px;
}

.shiftDataTableRowTop1pxBorder {
  border-top: 1px solid #e0ebf8;
}

.shiftDataTableTitle {
  font-weight: 500;
  font-size: 14px;
  color: #496375;
}

.shiftDataTableValue {
  font-weight: 500;
  color: #8ea7c5;
  min-width: 85px;
  text-align: right;
  font-size: 14px;
  padding-right: 5px;
}

.monthHoursDataTitle {
  font-weight: 500;
  color: #0af;
}

.shiftDataRowIndent {
  padding-left: 30px;
}

.shiftDataBorderTopThick {
  border-top: 1px solid #859dbb;
  border-bottom: none;
}

.shiftDataSubIcon {
  max-width: 12px;
  margin-right: 9px;

  margin-bottom: 2px;
}

.teamHoursMyDropdown {
  background-color: #1468a4;
  max-height: 35px;
  min-height: 35px;

  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 137px;
  padding-left: 10px;
  padding-right: 10px;
  border: 2px solid #fff;
  color: #6a8bb1;
  font-weight: 500;

  /* background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff; */
  background: url(/static/media/downArrowWhite2.887d57ce.svg) no-repeat right
    #8a65f0;
  background-position-x: 110px;
  border-radius: 5px;
  font-size: 14px;
  margin-right: 14px;
  cursor: pointer;
  padding-bottom: 1px;
}
.teamHoursMyDropdown:hover {
  background-color: #2c7eb9;
}

.desktopTeamHoursDataBoxHeader {
  font-weight: 500;
  min-width: 100%;
  padding-left: 15px;
  min-height: 40px;
  text-align: left;
  margin-top: 17px;
  display: flex;
  justify-content: space-between;
}

.selectedTeamColours {
  border: 1px solid #794add;
  color: #fff;
  background: url(/static/media/downArrowWhite2.887d57ce.svg) no-repeat right
    #8a65f0;
  background-position-x: 215px;
}

.selectedTeamColours:hover {
  border-color: #6d43c6;
}

.teamHoursDataBoxDesktopScroll {
  padding-bottom: 2px !important;
  cursor: default !important;
}

.allStaffTeamHoursDivModalDesktop {
  max-height: calc(100vh - 280px) !important;
  padding-top: 10px;
  /* border-top: 1px solid #e6eaee; */
  /* background-color: #fcfdff; */
}

.expandedNamePayableHoursDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-radius: 0 0 5px 5px;
  background-color: #b997f1;
  min-height: fit-content;
  max-height: fit-content;
  animation-name: heightDown !important;
  animation-duration: 0.2s !important;
  padding-bottom: 5px;
  padding-right: 6px;
  border: 2px solid #c2a9fa !important;
  border-top: none !important;
}

.payableHoursUserItemContainer {
  max-width: 90%;
  margin-left: 5%;
  border-radius: 2px;
  margin-top: 8px;
  box-shadow: 0px 0px 2px rgb(0 0 0 / 20%);
}

.mgrTeamHoursDataItem {
  min-height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px;
  padding-left: 13px;
  cursor: pointer;
  background-color: white;
  border-radius: 3px;
  border: #fff 1px solid !important;
  /* box-shadow: 0px 0px 2px rgb(0 0 0 / 20%); */
  background-color: #a387df !important;
  border: 1px solid #c2a9fa !important;
  /* /* padding-right: 15px; */
}

.dataItemBorderRight {
  border-radius: 5px 5px 0 0;
  background-color: #b997f1 !important;
  border-bottom: none !important;
}

.mgrTeamHoursDataItem:hover {
  border: #fff 1px solid !important;
  padding: 0px;
  padding-left: 13px;
  padding-right: 1px;
}

.payableDataBoxTeamName {
  opacity: 0.8;
  font-size: 12px;
  margin-top: 2px;
  color: #f4f8fe;
  font-weight: 500;
}

.payableExpandedRow {
  min-width: 230px;
  max-width: 230px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-right: 9px;
  min-height: 30px;
  align-items: center;
  padding-left: 14px;
  padding-right: 6px;
  /* border-radius: 5px; */
  margin-top: 2px;
  /* border: 1px solid #f4f8fe; */
  /* border-left: 3px solid #e3e8ee; */
  margin-top: 8px;
  border-bottom:1px solid #ebbdff
}
.payableExpandedRow:first-child {
  /* box-shadow: 0px 0px 2px rgb(0 0 0 / 20%);
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 7px;
  background-color: #fcfbff;
  margin-top: 8px; */
}
.payablePurpleBg {
  /* box-shadow: 0px 0px 2px rgb(0 0 0 / 20%); */
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 7px;
  /* background-color: #fcfbff; */
  /* border-left: 3px solid #e3e8ee; */

  border: 1px solid #7e60af;
  background: #d2b8fd;
  border-left: 3px solid #7e60af;
}

.payableExpandedRow:last-child {
  margin-bottom: 15px;
}

.payableExpandedTitle {
  font-weight: 500;
  color: #735997;
  margin-left: 3px;
}

.payableExpandedTitle2 {
  font-weight: 500;
  color: #fff;
  font-size: 12px;
}

.payableExpandedTitle3 {
  font-weight: 500;
  color: #735997;
  font-size: 13px;
  margin-left: 4px;
}

.payableExpandedValue {
  font-weight: 500;

  color: #fff;
  font-size: 12px;
}

.noHoverExpandable:hover {
    /* background-color: #fff !important; */
    cursor: default;
    border: none;
    padding: 0px;
    padding-left: 12px;
    padding-right: 0px;
    border: 2px solid #c2a9fa !important;
    border-bottom: 0px solid white !important;
    padding-bottom: 1px;
}

.staffAbsDataBoxSplitter {
  min-height: 1px;
  min-width: 94%;
  max-width: 94%;
  margin-right: 1%;
  background-color: #fff;
  margin-bottom: -4px;
  margin-top: 4px;
  opacity: 0.5;
}

.noBorderBottom {
  border-bottom: none !important;
}

.payableUserBtns {
  display: flex;

  min-width: fit-content;
  justify-content: space-between;
  align-items: center;
  min-height: 30px;
  max-height: 30px;
  font-size: 13px;
  margin-right: 5px;
}

.payableUserHours {
  color: #fff;
  border-radius: 5px;
  font-weight: 500;

  padding-left: 6px;
  padding-right: 6px;
  min-height: 26px;
  align-items: center;
  display: flex;
  justify-content: center;
  /* margin-right: 8px; */
  cursor: pointer;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  margin-left: 5px;
  border: 1px solid white;
  background-color: #d264ff;
  border: 1px solid #a058be;
}

.payableUserHours:hover {
  /* color: #fff; */
  /* border: 1px solid #bbcada; */
  background-color: #ca5ef6;
}

.teamStatsColours {
  background-color: #a387df;
  border: 1px #8e76c1;
}

.teamStatsColours:hover {
  background-color: #8e76c1;
}
.paddingTop20Px {
  padding-top: 20px;
}

.modalTeamHoursDataBox {
  /* min-height: calc(100vh - 70px); */
  /* max-height: calc(100vh - 70px); */
  min-width: 100% !important;
  max-width: 100% !important;
  margin-left: 0px !important;
}

.mobHoursBarHeight {
  min-height: 50px !important;
}

.txtFontSize15px {
  font-size: 15px !important;
}

.nonMgrStatsHoursBtnCorrection {
  margin-right: 36px !important;
}

.shiftsHeaderContentContainer {
  min-width: 100vw;
  max-width: 100vw;
  display: flex;
  overflow: visible;

  align-items: center;
  justify-content: space-between;
  min-height: 94px;
  margin-top: 30px;
  padding-left: 2%;
  min-height: 94px;
}

.x1038181 {
  margin-top: 80px
}
.shiftsHeaderLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 24px;
  min-width: 330px;
  overflow: visible;
  margin-left: 10px;
}

.mobTeamShiftsHeaderLeft {
  margin-top: 14px;
}

.mobTeamReqsHeaderLeft {
  margin-top: 18px;
}

.shiftHeaderLeftTitle {
  color: #143051;
  font-weight: 500;
  font-size: 28px;
  text-align: left;
  display: flex;
  align-items: flex-end;
  margin-bottom: 2px;
}

.myRequestsHeaderLeftTitle {
  color: #143051;
  font-weight: 500;
  font-size: 28px;
  text-align: left;
  display: flex;
  align-items: flex-end;
  margin-bottom: 0px;
  margin-top: -7px !important;
}

.myRequestsHeaderLeftTitleMob {
  margin-bottom: -8px;
  margin-top: -3px;
}

.myRequestsHeaderLeftTitleMob2 {
  margin-bottom: -10px;
  margin-top: -1px !important;
}
.myRequestsHeaderLeftTitleMob3 {
  margin-bottom: -11px;
  margin-top: -7px !important;
}

.shiftHeaderMenuRow {
  min-width: 224px;
  max-width: 224px;

  min-height: 35px;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  color: #8290b6;
  margin-left: 1px;
  margin-bottom: 1px;
}

.staffShiftHeaderMenuRow {
  margin-top: -5px;
}

.shiftHeaderMenuRowMob2 {
  margin-top: 3px;
}

.myHoursMenuRow {
  min-height: 41px !important;
  margin-top: 6px;
  margin-bottom: 0px;}

.shiftHeaderMenuRowMob {
  margin-top: -6px !important;
  margin-bottom: -1px !important;}

.shiftHeaderMenuRowTeam {
  min-width: 224px;
  max-width: 224px;
  margin-top: -4px;
  margin-bottom: -2px;
}

.teamStaffHoursTitleMob {
  margin-top: 3px;
}

.staffHoursTeamMob {
  margin-top: 3px;
}
.reqsHeaderMenuRow {
  min-width: 198px;
  max-width: 198px;

  min-height: 35px;
  margin-top: -2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  color: #8290b6;
  margin-left: 1px;
}

.absenceHeaderMenuRow {
  min-width: 353px;
  max-width: 353px;
  min-height: 35px;
  margin-top: -2px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  color: #8290b6;
  margin-left: 1px;
}

.absenceHeaderMenuRowStaff {
  margin-top: -2px;
  margin-bottom: 2px;
  min-width: 385px;
  max-width: 385px;
}

.x000110101 {
  min-width: 335px;
  max-width: 335px;
}

.calendarHeaderMenuRow {
  min-width: 303px;
  max-width: 303px;
  min-height: 35px;
  margin-top: -2px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  color: #8290b6;
  margin-left: 1px;
}

.reqsHeaderMenuRowMob {
  margin-top: 9px;
}

.absenceHeaderMenuMob {
  margin-top: 6px;
  max-width: 289px !important;
  min-width: 289px !important;
}

.calendarHeaderMenuRowMob {
  margin-top: 6px;
  max-width: 299px !important;
  min-width: 299px !important;
}

.reqsHeaderMenuRowTeam {
}

.staffReqsTitleDynamic {
  margin-top: -9px;
}

.shiftHeaderMenuItem {
  color: #8290b6;
  cursor: pointer;
  min-height: 26px;
  font-size: 14px;
}

.shiftHeaderMenuItem:hover {
  color: #859dbb;
  /* border-bottom: 3px solid #dde9f9; */
}

.shiftHeaderMenuItemSelect {
  color: #143051 !important;
  cursor: default;
  /* border-bottom: 3px solid #00ccff; */
}

.shiftHeaderMenuItemSelect:hover {
  color: #143051 !important;
  /* border-bottom: 3px solid #00ccff; */
}

.staffColour {
  color: #9576cc;
}

.staffColour:hover {
  color: #9576cc;
}

.shifHeaderMenuSplitter {
  min-width: 2px;
  background-color: #214978;
  min-height: 30px;
  display: none;
}

.shifHeaderMenuSplitterTeam {
  min-width: 2px;
  background-color: #723fce;
  min-height: 30px;
  display: none;
}

.mobTeamHoursSplitterTeam {
  background-color: white;
}

@media only screen and (min-width: 820px) {
  .shiftsHeaderContentContainer {
    min-width: calc(100vw - 240px);
    max-width: calc(100vw - 240px);
  }
}

@media only screen and (min-width: 1140px) {
  .shiftsHeaderContentContainer {
    min-width: 900px;
    max-width: 900px;
  }

  .x9349714 {
margin-top:-21px  !important  }

  .TopBar {
    min-width: 900px;
    max-width: 900px;
  }

  .x29498284 {
    margin-top:18px;

  }
}

.followingStaffTempTitle {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size:14px;
}

.userNotInTeamItem {
  min-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  min-height: 24px;
  align-items: center;
  border-radius: 5px;
  color: #496375;
  font-weight: 500;
  font-size: 14px;
  background-color: #e3e8ee;
  color: #4f566b;
  margin-top: 5px;
}

.teamSchedTopBarSize {
  min-width: calc(100vw - 240px);
  max-width: calc(100vw - 240px);
  border-right: none;
}

.teamSchedTopBarSizeRota {
  min-width: calc(100vw - 160px);
  max-width: calc(100vw - 160px);
  border-right: none;
}

.shiftHeaderTeamRightContainer {
  overflow: visible;
  min-height: 73px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  min-width: 220px;
  max-width: 220px;
  margin-right: 108px;
  margin-top: 43px;
}

.reqsHeaderTeamRightContainer {
  overflow: visible;
  min-height: 77px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  min-width: 220px;
  max-width: 220px;

  position: fixed;
  top: 61px;
  right: 28px;
}
.shiftHeaderTeamRightContainerMobReqs {
  overflow: visible;
  min-height: 35px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  min-width: 220px;
  max-width: 220px;

  margin-right: 18px;
  margin-top: -1px;
}


@media only screen and (max-width: 1139px) {
  .shiftHeaderTeamRightContainer {
    position: fixed;
    /* top: 28px; */
    right: -61px;
  }

  
}

@media only screen and (max-width: 1000px) {
  .shiftHeaderTeamRightContainer {
    position: fixed;
    /* top: 28px; */
    right: -71px;
  }

  
}


@media only screen and (max-width: 819px) {
  .shiftHeaderTeamRightContainer {
    position: fixed;
    top: 28px;
    right: 3px;
  }

  .shiftHeaderTeamRightContainerMobReqs {
    position: fixed;
    top: 28px;
    right: 3px;
  }
}

@media only screen and (max-width: 548px) {
  .shiftHeaderTeamRightContainer {
    position: fixed;
    top: 8px;
    right: 3px;
    min-height: 77px;
  }

  .shiftHeaderTeamRightContainerMobReqs {
    position: fixed;
    top: 8px;
    right: 3px;
    min-height: 70px;
  }
}

.shiftHeaderTeamRightContainerMob {
  min-height: 0px;
  max-height: 0px;
  top: -17px;
  right: -87px;
  /* background: blue; */
}

.shiftsHeaderTeamDropdown {
  min-width: 200px;
  min-height: 35px;
  background-color: blue;
  max-width: 200px;

  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #143051;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 175px;
  font-weight: 500;
  font-size: 14px;
  padding-left: 10px;
}

.shiftsHeaderTeamDropdown:hover {
  border-color: #d7dfe9;
}

.shiftsHeaderTeamDropdownMob {
  background-position-x: 145px;
  min-width: 170px;
  min-height: 35px;
  max-height: 35px;
}

.shiftsHeaderTeamDropdownMob2 {
  background-position-x: 145px;
  min-width: 170px;
  min-height: 35px;
  max-height: 35px;
  margin-left:8px;
}

.noHover:hover {
  color: #8290b6;
}

.teamSelectedDrop {
  color: white;
  background: url("../img/general/downArrowWhite.svg") no-repeat right #a387df;
  /* background-position-x: 174px; */

  background-position-x: 174px;
  background-color: #7950cf !important;
  border: 1px solid #56369c !important;
}

.teamSelectedDropReqs {
  color: white;
  background: url("../img/general/downArrowWhite.svg") no-repeat right #a387df;
  background-position-x: 213px;

  background-color: #4b1caf !important;
  border-color: #4c2d91 !important;
}

.desktopTeamRotaTitleBlock {
  margin-left: -30px;
}

.myShiftClockOnOffFlex {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid #e0ebf8;
  padding-left: 10px;
  border-radius: 5px 5px 0 0;
  padding-right: 15px;
  margin-top: 13px;
  padding-left: 14px;
  background-color: #f4f8fe;
}

.myShiftClockOnOffFlex:last-child {
  margin-bottom: 10px;
  margin-top: -1px;
  border-radius: 0px 0px 5px 5px;
}
.txtAlignRight {
  text-align: right;
}

.myShiftClockedDivRowValue {
  font-size: 16px;
  margin-top: -6px;
}

.myShiftClockedDivRowTitle {
  color: #214978;
  max-height: 17px;
  line-height: 18px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-size: 12px;
  margin-top: 13px;
  font-weight: 400;
}

.viewClockOnMyShiftBtn {
  background-color: #fff;
  color: #859dbb;
  font-size: 14px;
  padding: 8px;
  border-radius: 5px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: #e3e8ee solid 2px;
  cursor: pointer;
  margin-top: 20px;
}

.viewClockOnMyShiftBtn:hover {
  border-color: #b2bbc7;
}

.clockCardMyShiftPopUp {
  max-width: 17px;
  margin-left: 6px;
}

.breakTeamsPushDown {
  margin-top: -8px;
  margin-left: 44px;
}

.blueColours {
  background-color: #1e88e5 !important;
  border: #0469de 1px solid !important;
}

.blueColoursNoHover2 {
  background-color: #1e88e5 !important;
  border: #0469de 1px solid !important;
}

.blueColours:hover {
  background-color: #1f78de !important;
}

.purpColours {
  background-color: #9a74eb !important;
  border: 1px solid #965ade !important;
}

.purpColours:hover {
  background-color: #967ccf !important;
}

.teamRotaAvailCorrection {
}

.availPopupImg {
  max-width: 15px;
  margin-left: 5px;
}

.cursorDefault {
  cursor: default !important;
}

.jtUserContractedHours {
  max-width: 90%;
  margin-left: 10%;
  border-radius: 0 0 0 5px;
  border-top: 1px #e6eaee solid;

  margin-top: 5px;
  margin-bottom: 1px;
  min-height: 22px;
  max-height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}

.jtUserContractedHoursMob {
  display: flex;
  flex-direction: column;
  padding-left: 2px;

  padding-bottom: 3px;
  border-bottom: 1px solid #dde9f9;
  border-radius: 0px;
}

.jtUserContractedHours:hover {
  background-color: #f4f8fe;
}

.contractedWithAvail {
  margin-top: -8px;
  margin-bottom: 0px;
}

.jtUserContractedSecondaryOnly {
  min-height: 17px;
  margin-top: 4px !important;
}

.contractedWithAvailSecondaryOnly {
  margin-top: -16px !important;
  margin-bottom: 4px !important;
  min-height: 25px !important;
  max-height: 25px !important;
}

.jtUserContractedTitle {
  font-weight: 500;
  font-size: 8px;
  color: #8ea7c5;
  padding-top: 8px;
}

.jtUserContractedTitleMob {
  font-size: 8px;
  text-align: left;
  min-width: 100%;

  opacity: 0.25;
  margin-top: -1px;
  color: #143051;
  padding-top: 2px !important;
}

.jtUserContractedValue {
  font-weight: 500;
  font-size: 10px;
  color: #143051;
  padding-top: 8px;
}

.jtUserContractedValueMob {
  padding-top: 0px;
  min-width: 100%;
  text-align: left;
  margin-top: 0px;
  color: #bbcada;
  font-size: 8px;
  color: #143051;
  opacity: 0.25;
}

.contractedGreen {
  color: #66aa4b;
}

.zeroHoursContracted {
  background-color: #fff !important;
  padding-left: 2px;
  padding-right: 2px;
}

.nwDayTxt {
  color: #bbcada;
  padding: 5px;
  border-radius: 1px;
  max-height: 17px;
  display: flex;
  align-items: center;
  cursor: pointer;
  /* padding-left: 5px; */
  margin-left: -2px;
  /* border-left: 2px solid #cbd7e6; */
  /* display: none; */

}

.nwDayTxt:hover {
  /* color: #214978; */
}

.minH186 {
  min-height: 185px !important;
}

.highlightRed {
  color: #ba0000 !important;
}

.minH188px {
  min-height: 188px !important;
}

.shiftNotesTitle {
  margin-left: 7px !important;
}

.closeShiftNotesWhiteCrossImg {
  margin-left: 6px;
}

.notesBtnShift {
  min-width: 81px !important;
}

.notesBtnShift2 {
  min-width: 89px !important;
}
.noteBtnSelected {
  background-color: #214978 !important;
  border-color: #143051 !important;
  min-width: 76px !important;
  margin-right: 5px;
}

.noteBtnSelected:hover {
  background-color: #143051 !important;
}

.shiftNotesTitleTxt {
  font-size: 12px;
  color: #214978;
  font-weight: 500;
  margin-left: 25px;
}

.shiftNoteHeaderBox {
  margin-top: 15px;
}
.shiftNoteHeaderTxt {
  margin-left: 20px;
  font-weight: 500;
}

.shiftNoteHeaderTxt2 {
  margin-left: 20px;
  font-weight: 500;
  max-height: 32px;
  margin-top: -21px;
  color: #bbcada;
  margin-bottom: 7px;
  font-size: 14px;
}

.notesTagUnit {
  background-color: white !important;
  border: 1px solid #e3e8ee !important;
}

.shiftNotesTitleAndAdd {
  display: flex;
  justify-content: space-between;
  max-height: 70px;
  margin-top: 20px;
  border-top: solid 1px #e3e8ee;
  padding-top: 13px;
}

.shiftNotesAddNoteBtn {
  background-color: #00bbff;
  border: 1px solid #0af;
  min-height: 35px;
  border-radius: 5px;
  min-width: 78px;
  color: white;
  font-weight: 500;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  margin-right: 20px;
  padding-right: 10px;
  padding-left: 3px;
}

.shiftNotesAddNoteBtn:hover {
  background-color: #0af;
}

.toggleAddNote {
  background-color: #214978;
  border-color: #143051;
  min-width: 70px;
}
.toggleAddNote:hover {
  background-color: #143051;
}

.shiftAddNoteDiv {
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
  max-width: 90%;
  margin-left: 5%;
  max-height: 100px;
}

.shiftAddNoteTextArea {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 6px;
  background-color: #fdfeff;
  resize: none;
  min-width: 74%;
  max-width: 74%;
  min-height: 90px;
  padding: 5px;
  padding-left: 10px;
  color: #516b8b;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 60px;
}

.shiftAddNoteSaveBtn {
  background-color: #07f;
  border: #0469de 1px solid;
  min-height: 35px;
  border-radius: 5px;
  min-width: 68px;
  color: white;
  font-weight: 500;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  /* margin-top:10px; */
}
.shiftAddNoteSaveBtn:hover {
  background-color: #0469de;
}

.shiftAddNoteSaveBtnCanx {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  min-height: 35px;
  border-radius: 5px;
  min-width: 68px;
  color: white;
  font-weight: 500;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;

  margin-top: 6px;
}

.shiftAddNoteSaveBtnCanx:hover {
  background-color: #bbcada;
}

.shiftNotesObj {
  background-color: #fbfdff;
  border: 1px solid #e6eaee;
  max-width: 90%;
  margin-left: 5%;
  border-radius: 5px;
  margin-top: 20px;
  padding: 15px;
}

.shiftNotesObj:first-child {
  margin-top: 10px;
}
.noteObjNameAndDs {
  min-width: 100%;
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin-top: -15px;
}

.noteObjName {
  font-weight: 500;
  color: #214978;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.noteObjFsDs {
  font-weight: 500;
  color: #bbcada;
  font-size: 9px;
  margin-bottom: 2px;
}

.dayColContent {
  min-width: 100%;
}
.noteObjNote {
  font-weight: 500;
  line-height: 22px;
  padding-right: 12px;
  font-size: 15px;
  color: #8ea7c5;
}

.notesHolder {
  border-left: 2px solid #143051;
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.notesActive {
  background-color: #8ea7c5 !important;
  border-color: #748ca6 !important;
}

.deleteNoteRow {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: -6px;
}

.binNoteImg {
  cursor: pointer;
  min-width: 28px;
  max-width: 28px;
  min-height: 28px;
  max-height: 28px;
  border-radius: 5px;
  border: #e3e8ee solid 2px;
  padding: 5px;
  background-color: white;
  margin-top:12px;
}

.binNoteImg:hover {
  border-color: #b2bbc7;
}

.noBorderBottom {
  border-bottom: none !important;
}

.noShiftNotes {
  font-weight: 500;
  margin-left: 20px;
  color: #cbd7e6;
  margin-top: 10px;
}

.noComments {
  font-weight: 500;
  margin-left: 20px;
  color: #cbd7e6;
  margin-top: 10px;
  text-align: left;
  padding-bottom:15px;
}

.myRotaBottomBar {
  background-color: #143051;
  min-height: 20px;
  min-width: 100%;
}

.noBottomPadding {
  padding-bottom: 0px !important;
}

.userRotaAvailColours {
  background-color: #cedae8;
  border-top: 1px solid #b2bfcf;
  border-bottom: 1px solid #b2bfcf;
}

.limitRowInitialColUser {
  border-left: 1px solid #b2bfcf;
  margin-top: 9px;
color:white !important
}

.userRotaAvailColoursLast {
  border-radius: 0 5px 5px 0;
  border-right: 1px solid #b2bfcf;
}
.myRotaShiftBox {
  padding-right: 4px !important;
}
.myRotaShiftBoxDesktop:hover {
  background-color: #f7fafe;
  /* border: 2px solid #214978; */
  /* padding: 0px; */
  /* padding-right: 2px !important; */
}

.myRotaShiftBoxStandalone {
  cursor: default;
}
.myRotaShiftBoxStandalone:hover {
  
  background-color: white;
}
.claimedOpen {
  /* box-shadow: none; */
  border: 2px solid #e6eaee;
  /* padding: 0px; */
  border-left: 5px solid #143051;
}

.claimedOpen:hover {
  background-color: #f4f8fe;
  border: 2px solid #214978;
  padding: 0px;
  padding-right: 3px !important;
}

.leaveShiftBoxMyRota {
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  border: 1px solid #735ebd;
}

.leaveShiftBoxMyRota:hover {
  /* background-color: #143051; */
  /* border: 2px solid #143051;
  padding: 0px;
  padding-right: 0px;
  padding-left: 1px;
  padding-right: 1px; */
}

.leaveShiftBoxMyRotaStandalone {
  box-shadow: none  !important;
  border: 1px solid #3d344e;
  cursor: default;
}
.leaveShiftBoxMyRotaStandalone:hover {
  border: 1px solid #3d344e;
  padding: 0px;
  padding-right: 0px;
  padding-left: 2px;
  padding-right: 2px;

}

.myRotaAllowSwapsBtn {
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  min-height: 35px;
  max-height: 35px;
  color: #859dbb;
  align-items: center;
  padding-left: 10px;
  padding-right: 8px;
  justify-content: space-between;
  font-size: 12px;
  padding-right: 11px;
  max-width: 142px;
  text-align: left;
  line-height: 11px;
}

.myRotaAllowSwapsBtn:hover {
  /* background-color: #bbcada; */
  border-color: #a6aeb6 !important;
}

.allowSwapsKnobMyRota {
  margin-top: 1px !important;
  margin-left: -3px;
  margin-right: 27px;
}

.allowSwapsKnobBg {
  background-color: #eaf3ff !important;
  border: 1px solid #ced9e5;
}

.allowSwapsKnobOn {
  background-color: #0af !important;
}

.allowSwapsMyRotaBtnActive {
  background-color: white !important;
  color: #859dbb !important;
  border-color: #e6eaee !important;
  /* margin-right:20px  */
}

.x198398121 {
  margin-right:10px
}

.allowSwapsMyRotaBtnActive:hover {
  background-color: white !important;
  border-color: #d7dfe9 !important;
}

.pendingShiftStartBlob {
  min-height: 7px;
  min-width: 7px;
  border-radius: 10px;
  max-height: 7px;
  max-width: 7px;
  margin-left: 4px;
  background: #dde9f9;
}

.hideUserSchedLimits {
  min-height: 1px;
  max-height: 1px;
}

.limityUserRotaLimByTxt {
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  margin-top: 5px;
  padding-left: 6px;
  padding-right: 6px;
  color:white
}

.paddingRight10px {
  padding-right: 10px !important;
}

.limitBtJtDownRightImg {
  max-width: 10px;
  margin-left: 6px;
  margin-bottom: -3px;
}

.headerTitleSize {
  /* font-size: 33px;
  top: 40px;
  font-weight: 500;
  color: #bbcada !important;
  color: #214978 !important; */

  font-size: 23px;
  top: 40px;
  font-weight: 600;
  color: #bbcada !important;
  color: #143052 !important;
}

.headerTitleColourMgr {
  color: #7f49bd !important;
}

.hoursSizer {
  margin-top: -24px;
  margin-bottom: -1px;
}

.seeUserNonWorkingDaysModal {
  background-color: #f4f8fe;
  min-width: 300px;
  min-height: 120px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  max-width: 300px;
  padding-bottom: 15px;
  padding-top: 9px;
  cursor: default;
}

.seeUserModalMob {
  min-width: 100vw !important;
  max-width: 100vw !important;
  position: fixed;
  bottom: 0;
  border-radius: 5px 5px 0 0;
  animation-name: moveEditTeamUserBoxUp !important;
  animation-duration: 0.1s !important;
}

.seeUserNameTitle {
  font-weight: 500;
  color: #143051;
  /* margin-bottom: 18px; */
  font-size: 14px;
}

.seeUserRow {
  display: flex;
  justify-content: space-between;
  min-height: 33px;
  color: #143051;
  border-top: 1px solid #e6eaee;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 500;
  padding-left: 6px;
}

.seeUserRow:first-of-type {
  margin-top: 22px;
}
.seeUserSchedTable {
  min-width: 100%;
  max-width: 100%;
  max-height: 70vh;
  overflow: auto;
  padding-right: 5px;
  margin-bottom: -22px;
}
.nwDayClosedSpan {
  border-radius: 5px;
  background-color: #e3e8ee;
  color: #4f566b;
  font-weight: 500;
  font-size: 12px;
  padding-left: 3px;
  padding-right: 4px;
  min-height: 18px;
  line-height: 18px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-left: 8px;
}
.seeUserRowTitle {
  font-size: 16px;
  display: flex;
  align-items: center;
}
.seeUserRowValue {
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #bac5d3;
}

.userSchedPopUpFooter {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
  border-top: 1px solid #bac5d3;
  align-items: center;
  margin-bottom: -14px;
  min-height: 50px;
}

.messageUserFromRotaBtn {
  font-size: 14px;
  color: #fff;
  background-color: #07f;
  border: #0469de 1px solid;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 8px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  cursor: pointer;
}

.messageUserFromRotaBtn:hover {
  background-color: #0469de;
}

.closeSeeUserIDBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
}

.closeSeeUserIDBtn:hover {
  background-color: #214978;
}

.seeUserIDTeamsDiv {
  display: flex;
  min-width: 100%;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: -8px;
  margin-bottom: 18px;
}

.seeUserIDTeamsDiv:first-of-type {
  border-bottom: 1px solid #e6eaee;
  padding-bottom: 12px;
}

.seeUserIDTeam {
  min-width: -moz-fit-content;
  min-width: fit-content;
  border-radius: 5px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  padding-top:2px;
  padding-bottom:2px;
  display: flex;
  justify-content: space-between;
  /* max-height: 30px; */
  min-height: 30px;
  align-items: center;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #4f566b;
  margin-right: 8px;
  margin-top: 8px;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  text-align: left;

}

.teamShiftsHeaderHover:hover {
  color: #994fc3;
}

.userSchedTeamsTitle {
  text-align: left;
  color: #214978;
  font-size: 12px;
  font-weight: 500;

  margin-bottom: 12px;
}

.paidTimeOffMyShift {
  color: #0077ff;
}

.myRotaMyRow {
  background: #ffffff;
  border-top: 2px solid #bbcada;
  border-bottom: 2px solid #bbcada;
  padding-top:5px;
  display: flex;
  justify-content: flex-start;
}

.myRotaMyJtUser {
  /* padding-left: 4px;

  border-radius: 5px 0 0 5px; */
}

.myShiftAllowSwapsRow {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  padding-top: 7px;
}

.myShiftAllowSwapsRowHover {
  cursor: pointer;
}

.myShiftAllowSwapsRowHover:hover {
  background-color: #edf3f9;
}

.myShiftAllowSwapsTitle {
  font-size: 18px;
  font-weight: 500;
  color: #143051;
  margin-left: 4px;
}

.myShiftTitleAndAdd {
  border: none;
  margin-top: 10px;
}

.shiftSwapButton {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  padding-left: 6px;
  padding-right: 9px;
  min-height: 35px;
  max-height: 35px;
  color: #fff;
  background-color: #8300ff;
  border: 1px solid #6412b3;
  background-color: #9a74eb;
  border: 1px solid #965ade;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 9px !important;
}

.shiftSwapButton:hover {
  background-color: #6412b3;
  background-color: #8970c0;
}

.myShiftMessageBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 68px;
  padding-left: 7px;
  padding-right: 9px;
  min-height: 35px;
  max-height: 35px;
  color: #fff;
  background-color: #07f;
  border: #0469de 1px solid;
  border-radius: 5px;
  cursor: pointer;
}

.myShiftMessageBtn:hover {
  background-color: #0469de;
}

.myShiftHeaderParent {
  display: flex;
  justify-content: space-between;
  margin-top: -10px;
  padding-top: 10px;
}

.myShiftHeaderLeft {
  min-width: 231px;
}

.openShiftHeaderLeft {
  min-width: 100%;
  margin-top: -9px;
  padding-top: 5px;
}

@media only screen and (min-width: 280px) and (max-width: 349px) {
  .myShiftHeaderLeft {
    min-width: 70%;
  }
}
@media only screen and (min-width: 350px) and (max-width: 359px) {
  .myShiftHeaderLeft {
    min-width: 70%;
  }
}
@media only screen and (min-width: 360px) and (max-width: 379px) {
  .myShiftHeaderLeft {
    min-width: 72%;
  }
}
@media only screen and (min-width: 380px) and (max-width: 399px) {
  .myShiftHeaderLeft {
    min-width: 74%;
  }
}
@media only screen and (min-width: 400px) and (max-width: 419px) {
  .myShiftHeaderLeft {
    min-width: 74%;
  }
}
@media only screen and (min-width: 420px) and (max-width: 449px) {
  .myShiftHeaderLeft {
    min-width: 75%;
  }
}

@media only screen and (min-width: 440px) and (max-width: 459px) {
  .myShiftHeaderLeft {
    min-width: 77%;
  }
}

@media only screen and (min-width: 460px) and (max-width: 499px) {
  .myShiftHeaderLeft {
    min-width: 80%;
  }
}

.myShiftHeaderRight {
  min-width: 80px;
  max-width: 80px;
  margin-top: -5px;
  max-height: 80px !important;
  min-height: 80px !important;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.myShiftProfPicImg {
  min-width: 73px;
  max-width: 73px;
  max-height: 73px;
  min-height: 73px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 2px;

  cursor: pointer;
}

.myShiftProfPicImg:hover {
  border-color: #8ea7c5;
}

.myShiftBlankProfPicImg {
  max-width: 113px;
  min-width: 113px;
  max-height: 93px;
  min-height: 93px;
  margin-top: -19px;
  margin-left: -22px;
  cursor: pointer;
}

.myShiftProfPicBlankDiv {
  background-color: #516b8b;
  max-width: 73px;
  min-width: 73px;
  max-height: 73px;
  min-height: 73px;
  border-radius: 5px;
  border: 2px solid #413051;
}

.myShiftProfPicBlankDiv:hover {
  border-color: #214978;
}

.myShiftBlankInitialsHolder {
  margin-top: -79px;
  min-width: 71px;
  text-align: center;
  color: white;
  font-weight: 500;
  font-size: 40px;
  opacity: 0.8;
  cursor: pointer;
}

.swapModalMiddle {
  display: flex;
  justify-content: space-between;
  max-width: 90%;
  margin-left: 5%;
  min-height: 120px;
  margin-top: 10px;
  border-top: 1px solid #cbd7e6;
  padding-top: 20px;
}

.swapModalMiddleLeft {
  min-width: 145px;
  max-width: 145px;
  min-height: 188px;
  max-height: 188px;
}

.swapModalMiddleRight {
  min-width: 145px;
  max-width: 145px;
  margin-bottom: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.swapModalFooter {
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  border-top: 1px #e3e8ee solid;
  flex-direction: row-reverse;
}

.swapModalSwapMiddleMiddleArrow {
  max-width: 30px;
}

.swapModalMiddleLeftTitle {
  font-weight: 500;
  text-align: left;
  margin-left: 6px;
  color: #0af;
  font-size:14px;
}

.wantShiftDiv {
  background-color: white;
  border: 1px solid #e6eaee;
  border-radius: 5px;
  margin-top: 10px;
  min-height: 81px;
  text-align: left;
  padding-left: 10px;
  padding-top: 6px;
  padding-bottom: 3px;
  max-width: 145px;
  font-size: 16px;
}

.offeredShiftDiv {
  background-color: white;
  border: 2px solid #07f;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  min-height: 81px;
  text-align: right;
  padding-right: 10px;
  padding-top: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 145px;
  padding-bottom: 3px;
}

.wantShiftType {
  font-weight: 500;
  color: #214978;
  border-bottom: 1px solid #bbcada;
  max-width: fit-content;
  padding-right: 8px;
  padding-left: 3px;
  padding-bottom: 2px;
}

.wantShiftDs {
  font-weight: 500;
  color: #516b8b;
  padding-left: 4px;
  padding-top: 3px;
  border-bottom: 1px solid #bbcada;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-right: 6px;
  padding-bottom: 3px;
  font-size: 14px;
}

.wantTimeStrip {
  font-weight: 500;
  color: #8ea7c5;
  padding-top: 2px;
  padding-left: 4px;
  padding-right: 6px;
  font-size: 13px;
  padding-bottom: 3px;

  border-bottom: 1px solid #bbcada;
  max-width: fit-content;
}

.wantShiftDurationDiv {
  background-color: #ffffff;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
  border-radius: 0 0 5px 5px;
  margin-left: 10px;
  font-size: 13px;
  padding-bottom: 3px;
  padding-top: 3px;
  margin-bottom: 10px;
  color: #bbcada;
  border: 1px solid #e6eaee;
  border-top: none;
}

.offeredShiftDurationDiv {
  background-color: #ffffff;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
  border-radius: 0 0 5px 5px;
  margin-right: 10px;
  font-size: 13px;
  padding-bottom: 3px;
  padding-top: 3px;
  margin-bottom: 10px;
  color: #bbcada;
  border: 1px solid #e6eaee;
  border-top: none;
  margin-top: -5px;
  margin-bottom: 5px;
}

.selectShiftBox {
  border: 2px dashed #bac5d3;
  border-radius: 5px;
  padding: 10px;
  color: #496375;
  min-height: 80px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
  font-weight: 500;
  cursor: pointer;
  min-width: 140px;
}

.selectShiftBox:hover {
  color: #140351;
  border-color: #143051;
}

.swapModalMiddleLeftRight {
  font-weight: 500;
  text-align: right;
  margin-bottom: 6px;
  color: #0af;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 6px;
  font-size:14px;
}

.offerNoShiftCheckBoxDiv {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 90px;
  max-width: 90px;
  padding-left: 7px;
  padding-right: 7px;
  min-height: 35px;
  max-height: 35px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 50px;
  margin-top: 10px;
}

.offerNoShiftCheckBoxDiv:hover {
  background-color: #bbcada;
}

.offerNothingCheckBoxImg {
  max-width: 16px;
}

.requestSwapBtnSubmit {
  font-size: 14px;
  color: #fff;
  background-color: #0bf;
  border: 1px solid #0af;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
}

.requestSwapBtnSubmit:hover {
  background-color: #0af;
}

.disableReqSwapBtn {
  pointer-events: none;
  opacity: 0;
  cursor: default;
}

.offerNothingShiftBox {
  opacity: 0.4;
}

.selectShiftScrollBox {
  min-height: 316px;
  max-height: 316px;
  overflow-y: scroll;
  border: 1px solid #e6eaee;
  border-radius: 5px;
  margin-top: 5px;

  min-width: 143px;
  padding-bottom: 10px;
}

.selectMiddleArrow {
  margin-top: -210px;
}

.selectMiddleArrow2 {
  margin-top: -41px;
}

.selectShiftItem {
  background-color: white;
  border: 1px solid #e6eaee;
  border-radius: 4px 5px 5px 4px;
  text-align: left;
  background-color: white;
  cursor: pointer;
  max-width: 96%;
  min-width: 96%;
  margin-left: 2%;
  margin-top: 10px;
  padding: 1px;
  font-size: 14px;
  opacity: 0.8;
  border-left: 2px solid #143051;
}

.selectShiftItem:first-child {
  margin-top: 5px;
}

.selectShiftItem:hover {
  border: #214978 1px solid;
  border-left: 2px solid #143051;

  padding: 1px;
  opacity: 1;
}

.selectShiftItemDs {
  font-weight: 500;
  background-color: #214978;
  color: white;
  margin-left: 8px;
  margin-top: 6px;
  border: 1px solid #143051;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border-radius: 5px;
  padding-bottom: 3px;
  padding-top: 3px;
  padding-right: 5px;
  padding-left: 5px;
  margin-bottom: 9px;
}

.selectShiftTimesRow {
  font-weight: 500;
  color: #516b8b;
  margin-left: 8px;
  margin-top: 5px;
  border-bottom: 1px solid #bbcada;
  max-width: -moz-fit-content;
  max-width: fit-content;
  max-width: fit-content;
  padding-right: 8px;
  padding-left: 3px;
  padding-bottom: 3px;
}

.selectShiftBrkRow {
  font-weight: 500;
  color: #8ea7c5;
  margin-left: 8px;
  margin-top: 5px;
  border-bottom: 1px solid #bbcada;
  max-width: -moz-fit-content;
  max-width: fit-content;
  max-width: fit-content;
  padding-right: 8px;
  padding-left: 3px;
  padding-bottom: 3px;
}

.selectShiftDurMinsRow {
  font-weight: 500;
  color: #bbcada;
  margin-left: 8px;
  margin-top: 5px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  max-width: fit-content;
  padding-right: 8px;
  padding-left: 3px;
  padding-bottom: 6px;
}

.selectShiftTypeRow {
  min-width: 100%;
  font-weight: 500;
  text-align: left;
  padding-left: 10px;
  margin-top: 6px;
  margin-bottom: 5px;
  color: #bac5d3;
  border-bottom: 1px solid #bbcada;
}

.selectShiftTag {
  background-color: #f4f8fe;
  border: 1px solid #e6e6e6;
  color: #516b8b;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 5px;
  font-weight: 500;
  min-height: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 8px;
  margin-top: 4px;
  font-size: 10px;
}

.selectShiftTagsRow {
  margin-top: 4px;
  margin-bottom: 6px;
}

.fullOpac {
  opacity: 1 !important;
}

/* .hoverCommentActive:hover {
  opacity: 0.8 !important;
} */
.selectedShiftFlexEnd {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.offeredShiftDivCloseAndTypeDiv {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  padding-left: 11px;
}

.closeOfferedShiftImg {
  max-width: 14px;
  cursor: pointer;
  opacity: 0.2;
}

.closeOfferedShiftImg:hover {
  opacity: 1;
}

.swapBtnImg {
  max-width: 12px;
  margin-left: 3px;
  margin-right: 5px;
}

.myShiftMsgImg {
  max-width: 12px;
  margin-left: 2px;
  margin-right: 6px;
}

.addNotePlusSign {
  font-size: 20px;
  font-weight: 500;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: -4px;
}

.allowSwapsTickCrossImg {
  max-width: 12px;
  margin-left: 6px;
  margin-right: 2px;
}

.allowSwapsBtnTxtModal {
  font-weight: 500;
  padding-left: 6px;
  padding-right: 5px;
  font-size:11px
}

.limitExceededUserScheduleTeam {
  border-radius: 3px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 3px auto;
  min-height: 20px;
  line-height: 20px;
  padding-left: 6px;
  padding-right: 6px;
  color: #cb5858;
  border: 1px solid #cb5858;
  background-color: #fff2f2;
  font-size: 12px;
  font-weight: 500;
}

.mySwapsMyRow {
  display: flex;
  justify-content: space-between;
  min-width: fit-content;
}

.mySwapsMySelector {
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 93px;
  min-width: 93px;
  line-height: 26px;
  height: 35px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  background-position-x: 92px;
  display: flex;
  cursor: pointer;
  border-color: #2c7eb9;
  border: 1px solid #e6eaee;
  background-color: #0bf;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 72px;
}

.mySwapsMySelector:hover {
  border-color: #d7dfe9;
}

.mySwapsContainer {
  min-width: 100%;
  border-top: 1px solid #dde9f9;
  padding-top: 0px;
  min-height: 200px;
  color: #143051;
  overflow-y: scroll;
  max-height: 450px;
  margin-bottom: 1px;
  padding-bottom: 30px;

  border: 1px solid #bfccdc;
  border-radius: 5px 5px 0 0;
  background-color: #f4f8fe;
}

.mySwapsModal {
  background-color: #fff;
  min-width: 510px;
  min-height: 120px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  animation-name: zoomModalIn;
  animation-duration: 0.1s;
  max-width: 300px;
  padding-bottom: 15px;
  padding-top: 15px;
  cursor: default;
}

.mySwapsHeader {
  font-weight: 500;
  color: #143051;
  margin-bottom: 19px;
  font-size: 18px;
  min-width: 97%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
}

.upcomingSwapsBtn {
  cursor: pointer;
  display: flex;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;

  color: #fff;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  align-items: center;
  padding-left: 8px;
  padding-right: 6px;
  font-size: 14px;
  font-weight: 500;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  margin-right: 10px;
}

.unselectedUpcomingSwapsBg {
  background-color: #dfe9f6;
}

.upcomingSwapsMyFade {
  opacity: 0.5;
}

.upcomingSwapsCheckBoxImg {
  max-width: 12px;
  margin-left: 7px;
  margin-right: 3px;
}

.mobteamSelectedDrop {
  background-color: #bd97e2 !important;
  background-position-x: 144px !important;
  border-color: #8e5fbc !important;
  color: white !important;
}

.x108314 {
  background-position-x: 144px !important;

}

.myHoursTitle {
  margin-top: -22px !important;
  margin-bottom: -11px;
}

.myHoursTitleMob {
  margin-top: -5px !important;
  min-height: 34px !important;
  /* margin-bottom: -2p  x; */
}

.myLeaveBtn2 {
}

.openShiftsTriSpan {
  margin-left: 6px;
  font-size: 8px;
  margin-top: 2px;
  max-width: 4px;
}

.openDiv1 {
  display: flex;
  align-items: center;
  margin-right: -39px;
  font-size: 12px;
  font-weight: 500;
  opacity: 0.5;
}



.openDiv1mobCollapse {
  display: flex;
  align-items: center;
  margin-right: 5px;
}



.myJtUserShiftBox {
  /* border: #143051 2px solid !important; */
  /* box-shadow: 0px 0px 0px rgb(0 0 0 / 0%) !important; */
  /* padding-right: 4px !important; */
}


.activeNoteBtnColours {
  background-color: #fff !important;
  border: solid 1px #bbcada !important;
  color: #143051 !important;
}

.x1313322:hover {
  background-color: #f4f8fe !important;
}

.staffHoursBtnDynamic {
  max-height: 35px !important;
  min-height: 35px !important;
  position: fixed !important;
  top: 43px;
  right: 0px !important;
}

.teamStatsReqsBtnStyleMob {
  max-height: 31px !important;
  min-height: 31px !important;
  position: fixed !important;
  top: 49px !important;
  right: 20px !important;
  background-color: #9a74eb !important;
  border: 1px solid #965ade !important;
  color: white !important;
  min-width: 120px !important;
  max-width: 120px !important;
}

.myHoursDataBoxTitle {
  font-weight: 500;
  margin-right: 10px;
  color: #fff;
  font-size: 18px;
  margin-top: 4px;
}

.teamSchedContainerMob {
  padding-left: 0px !important;
  max-height: calc(100vh - 258px);
    min-height: calc(100vh - 258px);
}
.teamCalContainerMob {
  padding-left: 0px !important;
  max-height: calc(100vh - 258px);
    min-height: calc(100vh - 258px);
    margin-top:-8px;
}

.mobJobTitleTitle {
  margin-left: 8px;
}
.limitRowInitialColMob {
  max-width: 90%;
  margin-left: 10%;
}

.openDiv1Mob {
  font-size: 10px;
  margin-left: -31px;
}

.opens33 {
  font-size: 9px;
  max-width: 48px;
  text-align: left;
}

.opens44 {
  font-size: 8px;
  margin-top: 4px;
  margin-left: 0px;
}
.myRotaTag {
  background-color: #dfe7f0;
  border-color: #bbcada;
  color: #516b8b;
}
.shTagInlineMe {
  /* border: 2px solid #143051; */
}

.ghostShiftUnitTeamSched:hover {
  background-color: #214978 !important;
}

.paddingR8px {
  padding-right: 8px !important;
}

.marginRight12px {
  margin-right: 12px;
}

.lightBlue {
  color: #00ccff;
}

.rotaSwapsQty {
  background-color: #516b8b;
  color: white;
  font-weight: 500;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 3px;
  margin-left: 4px;
  font-size: 12px;
  border: 1px solid #405671;
  min-width: fit-content;
  max-height: 18px;
  display: flex;
  align-items: center;
  min-height:20px;
}

.rotaSwapsQty:first-of-type {
  margin-left: 6px;
}

.swapUnit {
  background-color: #fff;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 20%);
  margin-top: 14px;
  max-width: 96%;
  margin-left: 2%;
  border-radius: 5px;
  min-height: 70px;
  padding: 1px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-left: 5px solid #dde9f9;
}

.swapUnit:first-of-type {
  margin-top: 15px;
}

.teamSwapUnit {
  border-left: 5px solid #a387df;
  padding-bottom: 35px;
}

.swapUnitLeft {
  min-width: 39%;
  padding-left: 3%;
  padding-right: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 3%;
  justify-content: flex-start;
}

.swapUnitLeftTxt {
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  min-width: fit-content;
  margin-top: 5px;
  margin-left: 3px;
  padding-right: 5px;
  padding-bottom: 5px;
}

.swapUnitRightTxt {
  font-weight: 500;
  font-size: 16px;
  text-align: right;
  min-width: fit-content;
  margin-top: 5px;
  margin-left: 3px;
  padding-right: 5px;
  margin-top: 35px;
  padding-bottom: 5px;
}

.swap500 {
  font-weight: 500;
}

.swapUnitMiddle {
  min-width: 22%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  margin-top: 120px;
  border-left: 2px solid #f4f8fe;
  border-right: 2px solid #f4f8fe;
}

.teamSwapUnitMiddle {
  min-width: 22%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  margin-top: 98px;
  border-left: 2px solid #f4f8fe;
  border-right: 2px solid #f4f8fe;
}

.inReturnForArrowImg {
  max-width: 18px;
  opacity: 0.3;
}

.swapUnitRight {
  min-width: 39%;
  padding-left: 4px;
  padding-right: 3%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height: 100%;
  justify-content: flex-start;
  padding-top: 3%;
}
.mySwapsModalFooter {
  min-width: 100%;
  display: flex;
  justify-content: space-between;

  align-items: center;
  margin-bottom: -14px;
  min-height: 50px;
}

.myShiftWhenDsStrip {
  margin-top: 3px;
}

.swappedTitleItem {
  border-radius: 3px;
  color: #143051;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 7px;
  padding-right: 10px;
  font-weight: 500;
  max-height: 35px;
  min-height: 35px;
  font-size: 16px;
  margin-top: 12px;
  border-bottom: 1px solid #e6eaee;
  display: flex;
  justify-content: space-between;
}

.navySwapImg {
  max-width: 13px;
  margin-right: 7px;
}

.swappedWith {
  font-size: 14px;
  line-height: 23px;
  margin-top: 14px;
  margin-left: 5px;
  color: #0af;
  font-weight: 500;
  margin-bottom: 16px;
}

.myShiftSwappedItem {
  background-color: white;
  max-width: calc(100% - 7px);
  margin-left: 3px;
  border-radius: 3px;
  margin-bottom: 14px;

  margin-top: 18px;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 20%);
  display: flex;
  justify-content: space-between;
}

.myShiftSwappedItemLeft {
  min-width: 70%;
  display: flex;
  border-right: 1px solid #e0ebf8;
}

.myShiftSwappedItemRight {
  min-width: 30%;
  display: flex;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 4px;
  padding-right: 4px;
}

.myShiftSwappedItemHalf {
  min-width: 100%;
}

.myShiftSwappedItemHalf2 {
  min-width: 40%;
}

.myShiftSwappedItemLeftDs {
  color: #143051;
  border-bottom: 1px solid #e0ebf8;
  min-height: 30px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-weight: 500;
}

.noShiftOfferedTxt {
  color: #143051;

  min-height: 30px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  min-height: 40px;
}

.myShiftSwappedItemLeftTimes {
  color: #143051;
  border-bottom: 1px solid #e0ebf8;
  min-height: 30px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.myShiftSwappedItemLeftBrk {
  color: #859dbb;

  min-height: 30px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  min-width: 100%;
  justify-content: space-between;
  padding-right: 12px;
  font-weight: 500;
}

.swappedWithString {
  padding-right: 30px;
  font-weight: 500;
  line-height: 18px;
  font-size:12px;
}
.myShiftSwapEditBtnImg {
  cursor: pointer;
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  border-radius: 5px;
  padding: 8px;
  border: #e3e8ee solid 2px;
}

.myShiftSwapEditBtnImg:hover {
  border-color: #b2bbc7;
}

.swapInline {
  max-width: 12px;
  margin-top: -13px;
  margin-bottom: 4px;
  opacity: 0.4;
}

.unpubFade {
  color: #c0d6f0;
}

.inbOutbSwapReqImg {
  max-width: 14px;
  margin-bottom: 4px;
}

.swapUnitWantDs {
  font-weight: 500;
  color: #516b8b;
  font-size: 14px;
  margin-top: 6px;
  border-bottom: 1px solid #dde9f9;
  text-align: left;
  padding-bottom: 8px;
  padding-right: 2px;
}

.swapUnitWantDsSub {
  font-weight: 500;
  color: #8ea7c5;
  font-size: 14px;
  margin-top: 6px;
  border-bottom: 1px solid #dde9f9;
  text-align: left;
  padding-bottom: 8px;
  padding-right: 7px;
}

.swapUnitWantItemDiv {
  max-width: 138px;
  border-radius: 5px;

  border: 2px solid #e3e8ee;
  padding-left: 9px;
  padding-right: 9px;
  margin-top: 10px;
  margin-bottom: 12px;
  cursor: pointer;
  background-color: white;
}

.rightMobSwapUnit {
  margin-top: 12px;
}

.swapUnitWantItemDiv:hover {
  border: 2px solid #d7dfe9;
  /* box-shadow: 0px 0px 3px #143051; */
}

.inboundWantItem {
  border: 2px solid #143051;
}

.inboundWantItem:hover {
  border: 2px solid #143051;
}

.inboundSwapUnit {
  background-color: #fff;
  border-left: 5px solid #0af;
}
.wantShiftTags {
  background-color: #fbfeff;
  padding: 4px;
  border-radius: 0px 0px 5px 5px;
  margin-top: -12px;
  max-width: 84%;
  margin-left: 3%;
  margin-bottom: 8px;
  border: 1px solid #dde9f9;
  border-top: none;
}

.wantShiftTagsList {
  display: flex;
  flex-wrap: wrap;
  padding: 4px;
  margin-top: -4px;
  margin-left: 3%;
}

.wantShiftTagsTitle {
  text-align: left;
  font-weight: 500;
  font-size: 10px;
  margin-left: 2px;
  margin-top: 0px;
  color: #516b8b;
  margin-bottom: 8px;
}

.wantTag {
  font-size: 12px;
  margin-right: 8px;
  color: #859dbb;
  text-align: left;
  line-height: 14px;
  margin-bottom: 8px;
  font-weight: 300;
}

.flipH {
  transform: rotate(180deg);
}

.inReturnForTxt {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  margin-top: 5px;
  color: #143051;
  max-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.font12 {
  font-size: 12px !important;
}

.offerSwapNothingTxt {
  font-weight: 500;
  font-size: 14px;
  text-align: right;
  min-width: -moz-fit-content;
  min-width: fit-content;
  margin-top: 5px;
  margin-left: 3px;
  padding-right: 5px;
  margin-top: 35px;
  color: #bac5d3;
  border: 2px dashed #bac5d3;
  border-radius: 5px;
  padding: 8px;
}

.disableAddNoteBtn {
  background-color: white;
  color: #214978;
  border-color: white;
  pointer-events: none;
  color: #dde9f9;
}

.disableAddNoteBtn:hover {
  background-color: white;
  border-color: white;
}

.swapFName {
  color: #07f;
}

.selectedSwapUnit {
  border: solid 2px #143051 !important;
  padding: 0px !important;
}

.txtAlignRight {
  text-align: right !important;
}

.offerTagsRightAlign {
  min-width: 100% !important;
  text-align: right !important;
}

.swapUnitCtaBar {
  background-color: #f8fbff;
  min-height: 50px;
  max-width: 90%;
  margin-left: 5%;
  border-radius: 0px 0px 5px 5px;
  border: 1px solid #e0ebf8;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
}

.swapUnitParent {
  padding-bottom: 10px;
  margin-top: 30px;
}

.swapUnitParent:first-child {
  margin-top: 0px;
}

.swapUnitParent:last-child {
  border: none;
}

.swapDsMade {
  font-size: 12px;
  color: #bbcada;
  font-weight: 500;
  text-align: left;
}

.swapCtaBtns {
  display: flex;
  justify-content: space-between;
  min-width: 120px;
}

.swapAccept {
  background-color: #00aaff;
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 35px;
  border-radius: 5px;
  border: 1px solid #1e88e5;
  cursor: pointer;
  font-size: 14px;
}

.swapAccept:hover {
  background-color: #1e88e5;
}

.teamSwapAccept {
  background-color: #a387df;
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 35px;
  border-radius: 5px;
  border: 1px solid #967ccf;
  cursor: pointer;
  font-size: 14px;
}

.teamSwapAccept:hover {
  background-color: #967ccf;
}

.swapDecline {
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 35px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
  font-size: 14px;
  border: #dde9f9 1px solid;
}

.swapDecline:hover {
  border-color: #d7dfe9;
}

.staffSwapTeamDropContainer {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.staffSwapTeamSubContainer {
  min-width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;
}

.swapTeamDropdown {
  min-width: 240px;
  min-height: 35px;
  max-width: 240px;

  cursor: pointer;
  border: 2px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 213px;
  font-weight: 500;
  font-size: 14px;
  padding-left: 10px;
  margin-top: 14px;
}

.swapTeamDropdown:hover {
  border-color: #d7dfe9;
}

.noSwapsTxt {
  font-weight: 500;
  margin: 70px auto;
  color: #bbcada;
  font-size: 12px;
  /* padding-right: 50px; */
  /* padding-left: 50px; */
  line-height: 16px;
  max-width: 200px;
  margin-bottom: -30px;
}

.swapTypeSpan {
  color: #c686cc;
}

.teamSwapOfferNothingCross {
  min-width: 20px;
  max-width: 20px;
  margin-top: 25px;
  margin-right: 18px;
  opacity: 0;
}

.requireMgrApp {
  max-width: 180px;
}

.allowSwapsTeamKnob {
  padding-right: 12px;
}

.mobRotaTopLine {
  border-bottom: 1px solid #e7eff7;
  padding-right: 5% !important;
  border-radius: 20px 20px 0 0;
  border-top:2px solid #e6eaee !important;
  min-width: calc(100vw + 4px) !important;
  margin-left: -2px !important;
}

.shiftInlineCommentImg {
  max-width: 10px;
  margin-bottom: -17px;
  margin-top: 7px;
  margin-left: 2px;
  /* opacity: 0.4; */
}

.shiftInlineCommentImgSelfLogAbs {
  margin-top:3px;
}
.inboundSwapCta {
}

.inboundDeclineSwap {
}

.staffSwapInfo {
  font-size: 14px;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 15px;
  font-weight: 500;
  margin-bottom: -16px;
  color: #bbcada;
  line-height: 20px;
}

.closeMySwapsModalImg {
  cursor: pointer;
}
.closeMySwapsModalImg:hover {
  opacity: 0.8;
}
.teamSwapsContainer {
  min-width: 100%;
  margin-top: -12px;
  margin-bottom: 11px;
  font-weight: 500;
}



.selectTeamToChangeSwapSettings {
  color: #bbcada;
  text-align: right;
  font-size: 12px;
  margin-top: 7px;
  margin-right: 1px;
  font-weight: 500;
  padding-right: 10px;
  border-right: 2px solid #e0ebf8;
  min-height: 40px;
  max-width: 160px;
  float: right;
  padding-top: 4px;
}

.usrApprovedSwap {
  max-width: 40%;
  float: right;
  margin-right: 4%;
  text-align: right;
  font-size: 12px;
  margin-top: -78px;
  color: #0af;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.mgrSwapApprovalNotRequired {
  max-width: 40%;
  float: right;
  margin-right: 4%;
  text-align: right;
  font-size: 12px;
  margin-top: -128px;
  color: #bbcada;
  font-weight: 500;
  display: flex;
  align-items: center;
  border-right: 2px solid #bbcada;
  padding-right: 8px;
}

.mgrSwapApprovalNotRequiredNonMob {
  margin-top: -111px;
}
.swapAcceptTickImg {
  max-width: 14px;
  margin-left: 6px;
}

.notYetAccept {
  color: #bbcada;
  margin-top: -74px !important;
  border-right: 2px solid #bbcada;
  padding-right: 8px;
}

.mobNotYetAccept {
  margin-top: -86px !important;
}

.dayCommentIcon {
  max-width: 24px;
  margin-right: 10px;
  /* opacity: 0.2; */
  cursor: pointer;
  padding: 4px;
  min-height: 24px;
  /* border: 1px solid #bac5d3; */
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 7px;
  margin-top: 0px;
  max-height: 24px !important;
  margin-left: 10px;
  padding-bottom: 2px;
  padding-top: 4px;
}

.dayCommentIcon:hover {
  opacity: 0.8;
}

.dayCommentsModal {
  background-color: #ebf0f7;
  min-width: 370px;
  min-height: 120px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  max-width: 300px;
  padding-bottom: 15px;
  padding-top: 15px;
  cursor: default;
}

.dayCommentsModalBody {
  background-color: white;
  min-width: 100%;
  border: solid 1px #e6eaee;
  border-top: solid 2px #e6eaee;
  border-bottom: none;
  border-radius: 6px 6px 0px 0px;
  overflow: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  background-color: white;
  max-height:462px;
}

.dayCommentsHolder {
  /* border-left: 2px solid #143051; */
  margin-left: 15px;
  margin-top: 20px;
  padding-bottom: 20px;
  overflow-y: scroll;
  max-height: 360px;
  padding-bottom: 20px;
  /* margin-bottom: 10px; */
}

.dayCommentObj {
  font-weight: 500;
  line-height: 22px;
  padding-right: 12px;
  font-size: 13px;
  color: #516b8b;
  text-align: left;
  margin-top: 17px;
}

.commentObjNameAndDs {
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom:-5px;
}

.dayCommentsModalFooter {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #bac5d3;
  align-items: center;
  margin-bottom: -14px;
  min-height: 50px;
}

.rotaCommentDiv {
  display: flex;
  align-items: center;
  cursor: pointer;
}



.rotaCommentsQty {
  font-size: 9px;
  /* color: #516b8b; */
  margin-top: 0px;
  margin-left: -9px;
  font-weight: 600;
  margin-right: 6px;
  /* background: blue; */
  border-radius: 3px;
  color: white;
  background: #9a74eb;
  padding: 1px;
  padding-left: 4px;
  padding-right: 4px;
  border: #756dba 1px solid;

}

.dayCommentsTitleTxt {
  font-size: 12px;
  color: #214978;
  font-weight: 500;
  margin-left: 25px;
  padding-top: 18px;
}

.myShiftNoteCommentIcon {
  max-width: 12px;
  margin-right: 5px;
}

.swapMgrApprovedTxt {
  font-weight: 500;
  color: #00aaff;
  font-size: 12px;
  text-align: right;
  display: flex;
}

.declineSwapOnceApproved {
  font-weight: 500;
  color: #bbcada;
  font-size: 12px;
  margin-top: 3px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 5px;
  margin-right: 0px;
  border: 1px solid #e6eaee;
  cursor: pointer;
}

.declineSwapOnceApproved:hover {
  border-color: #bbcada;
}

.swapApprovedAndDeclineDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-right: 2px #bbcada solid;
  padding-right: 8px;
}

.swapApprovedGreenLeftBorder {
  border-left: 5px solid #a8cc9a;
}

.mgrApprovedSwapInfo {
  color: #a8cc9a;
  font-weight: 500;
  text-align: right;
  font-size: 13px;
  margin-bottom: 12px;
  border-right: 2px solid #a8cc9a;
  padding-right: 8px;
}

.awaitingNameDecicionSwap {
  color: #0af;
  font-weight: 500;
  text-align: right;
  font-size: 13px;
  margin-bottom: 14px;
  border-right: 2px solid #0af;
  padding-right: 8px;
}

.swapNoLongerAllowedModalBody {
  min-height: 100px;
  max-width: 300px;
  min-width: 300px;
  padding: 20px;
  border-radius: 5px;
  margin: 0 auto;
  justify-content: space-between;
  background-color: white;
  color: #143051;
  font-size: 16px;
}

.closeSwapsNoLongerBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  margin-top: 20px;
  min-width: 100%;
}

.closeSwapsNoLongerBtn:hover {
  background-color: #214978;
}

.swapUnitLeftUpperMinimum {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 90px;
}

.swapUnitRightUpperMinimum {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height: 90px;
}

.swapUnitTeamLeftUpperMinimum {
  min-height: 68px;
}

.swapUnitTeamRightUpperMinimum {
  min-height: 68px;
}

.outboundAwaitingTxtColour {
  color: #bbcada;
}

.offerNothingMinHeight {
  min-height: 49px;
}

.swapStatusDivider {
  background-color: #e3e8ee;
  min-height: 2px;
  min-width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.declineBtnRight {
  margin-left: 42px;
}

.swapBorderRight {
  border-right: 2px solid #143051;
}

.changesMoveLeft {
  margin-right: 8px !important;
}

.changeRequiresAckBorder {
  border-left: 5px solid #0af !important;
}

.changeApprovedBorder {
  border-left: 5px solid #a8cc9a;
}

.changeDeclinedBorder {
  border-left: 5px solid #ba0000;
}

.acknowledgedTxt {
  color: #bbcada;
  font-weight: 500;
  font-size: 12px;
  margin-top: 0px;
  min-height: 24px;
  display: flex;
  align-items: center;
}

.ackTickImg {
  max-width: 12px;
  margin-left: 7px;
  margin-top: 1px;
}

.changeStatusIconImg {
  max-width: 10px;
  margin-left: 6px;
  margin-top: 0px;
}

.totEarnedSalariedInfo {
  font-weight: 500;
  font-size: 10px;
  text-align: right;
  padding-right: 20px;
  padding-left: 40px;
  margin-top: 8px;
  color: #bbcada;
}

.emptyOutboundQty {
  min-width: 4px;
}

.paddingBottom26px {
  padding-bottom: 33px !important;
}

.paddingBottom82px {
  padding-bottom: 52px !important;
}

.myShiftNotesContainerOtherUser {
  padding-bottom: 13px;
}

.myShiftOtherUserNotesTitle {
  margin-left: 15px;
}

.marginTop0 {
  margin-top: 0px !important;
}

.shiftChangesParent {
  border-right: 1px solid #e0ebf8;
}

.shiftChangesParentMob {
}

.textAlignLeft {
  text-align: left !important;
}

.teamAvailWithShowUser {
  z-index: 9900;
}

.lateMinsSpan {
  font-size: 14px;
  margin-left: 6px;
  color: #bac5d3;
}

.lateInput {
  background-color: #fff;
  width: 77px;
  max-width: 77px;

  line-height: 30px;
  padding-top: 0px;
  height: 35px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 16px;
  font-weight: 500;
  /* animation-name: zoomModalIn !important; */
  /* animation-duration: 0.1s !important; */
  -webkit-appearance: none;
  appearance: none;
  cursor: text;
  margin-left: 3px;
}

.lateInput:hover {
  border-color: #d7dfe9;
}

.lateTitleWhenNotEnabled {
  font-size: 18px;
  font-weight: 500;
  color: #143051;
  margin-left: 5px;
  margin-top: 5px;
  padding-bottom: 3px;
}

.addLateIconPlus {
  margin-top: 8px !important;
}

.unpaidlateBtnDiv {
  cursor: pointer;
  display: flex;
  border: 1px solid #8e76c1;
  background-color: #a387df;
  color: #fff;
  border-radius: 5px;
  min-height: 35px;
  line-height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 6px;
  margin-bottom: 9px;
}

.saveLateAndUnpaidDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 19px;
  justify-content: center;
}

.saveBtnTxtLate {
  background-color: #07f;
  max-height: 35px;
  min-height: 35px;
  line-height: 26px;
  color: white;
  font-weight: 500;
  border: 1px solid #0469de;
  border-radius: 5px;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-width: 60px;
  max-width: 60px;
  margin-top: 6px;
}
.saveBtnTxtLate:hover {
  background-color: #0469de;
}

.unpaidLateCheckBoxImg {
  margin-right: 7px;
  margin-left: 0px;
  max-width: 12px;
}

.unpaidLateOpacity {
  opacity: 0.6;
}

.unpaidLateOpacity:hover {
  opacity: 0.8;
}

.unpaidSaveCancelDiv {
  display: flex;
  align-items: center;
}

.canxLateBtnImg {
  margin-left: 8px;
  cursor: pointer;
  min-width: 35px;
  max-width: 35px;
  min-height: 35px;
  max-height: 35px;
  border-radius: 5px;
  padding: 6px;
  padding-bottom: 8px;
  border: #e3e8ee solid 2px;
  /* border: #e3e8ee solid 2px; */
  margin-top: 6px;
}

.removeLateImg {
  padding: 9px;
}

.paidLateSpan {
  color: #bac5d3;
  font-size: 12px;
}

.openShiftFooterNotesAndClaimDiv {
  display: flex;
  align-items: center;
}

.hasBiddedTxt {
  color: #bbcada;
  font-weight: 500;
  font-size: 12px;
  margin-left: 14px;
}

.availDayDiv {
  text-align: left;

}

.availDayStrip {
  font-size: 12px;
  margin-top: 0px;
  color: #214978;
  font-weight: 500;
}

.publishOutstanding {
  background-color: #7fbb67;
  border-color: #53a747;
  min-width: 105px !important;
  padding-right: 3px;
}

.publishOutstanding:hover {
  background-color: #53a747;
}

.mobDurWithCost {
  margin-right: 5px;
  line-height: 9px;
}

.msgsShownUserSchedUnderlay {
  z-index: 1000;
}

.rotaInsightsBtn {
  background-color: #214978;
  border: 1px solid #143051;
  color: white;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 7px;
  padding-right: 7px;
  font-size: 12px;
  min-height: 28px;
  margin-top: -4px;
  cursor: pointer;
  margin-left: 5px;
  font-weight: 600;
}

.rotaInsightsBtn:hover {
  background-color: #143051;
}

.rotaInsightsBtnMob {
  border-radius: 3px !important;
  margin-left: 5px;
  margin-top: 3px;}


.insightsIconImg {
  margin-right:6px;
  max-width: 11px;
}
/* 


@media only screen and (min-width: 820px) {

  .rotaInsightsBtn {
margin-left:0px !important
  }
  

}

.showVisBtnActive {
  background-color: #504588;
}

.showVisBtnActive:hover {
  background-color: #696682;
}

.insightsIconImg {
  max-width: 10px;
  margin-right: 7px;
  /* margin-left: 1px; */
/* } */

.mobShowVizIcon {
}

.vizBox {
  background-color: #f8f7fa;
  min-height: 178px;
  max-height: 178px;

  min-width: 100%;
  border-top:1px solid #e3e8ee;
  /* border-bottom: 3px solid #bbcada; */
  animation-name: slideVizDown !important;
  animation-duration: 0.1s !important;
}

@keyframes slideVizDown {
  from {
 min-height: 0px;
 max-height: 0px 

  }
  to {
    min-height: 178px;
    max-height: 178px;

  }
}

.vizContent {
  min-height: 100%;
  max-width: 180px;
  margin: 14px auto;
  /* display: flex; */
}

.vizDataBox {
  min-width: 100%;
  /* background-color: #9c82e3; */
  min-height: 130px;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;

  border-radius: 0 0 3px 3px;
}

.vizKeyStartEndTime {
  margin-top: 2px;
}

.vizKeyDiv {
  color: #8ea7c5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 4px;
  min-width: 100%;
  font-size: 10px;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  margin-top:-6px
}

.vizKeyDivBullets {
  color: #9f74c4;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2px;
  min-width: 100%;
  font-size: 10px;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  /* margin-top: -20px; */
}

.vizKeyBullet {
  min-width: 3px;
  border-radius: 5px;
  min-height: 6px;
  background-color: #bbcada;
}
.blankVizBox {
  background: #5c638b;
  animation-name: slideVizDown !important;
  animation-duration: 0.1s !important;
  border-radius: 0 0 0 10px;
  padding-top: 5px;
  /* padding-bottom: 5; */
  border-top:none !important;
}

.blankVizBoxMob {
  padding-top: 2px;
}

.vizCol {
  flex: auto;

  max-height: 130px;
  margin-top: 6px;
  display: flex;
  flex-direction: column-reverse;

  margin-left: 2px;
}

.vizItem {
  background-color: #2e386b;
  margin-bottom: 2px;
  border-radius: 2px;
  flex: auto;
  opacity: 0.8;
}

.noVisItem {
  opacity: 0.1;
}

.showVizWhiteCross {
  max-width: 8px !important;
  margin-left: 2px;
  margin-top: 0px;
  transform: rotate(270deg);
  margin-right: 7px;
}

.blankVizRow {
  display: flex;
  color: white;
  font-size: 12px;
  min-height: 17px;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 8px;
  align-items: center;
  font-weight: 500;
}

.blankVizRowMob {
  flex-direction: column;
  align-items: flex-start;
  /* border-bottom: 1px solid #9c82e3; */
}

.blankVizRowMob:last-child {
  border-bottom: none;
}

.blankVizRowTitle {
  font-weight: 500;
  opacity: 1;
  margin-bottom: -8px;
}

.blankVizRowTitleMob {
  font-size: 9px;
  margin-bottom: -1px;
}

.blankVizRowValueMob {
  font-size: 10px;
  margin-bottom: 1px;
}

.resetPasswordPage {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ebf0f7;
  min-height: 100vh;
  max-height: 100vh;

  min-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* overflow-y: auto; */
}

.resetPassLogo {
  max-width: 80px;
  margin-left: 6px;
  margin-bottom: 10px;
}

.resetPassTitle {
  font-weight: 500;
  color: #214978;
  margin-top: 30px;

  max-width: 300px;
  text-align: center;
  margin-bottom: 10px;
  line-height:24px
}

.contactFormTitleTxt {
  font-weight: 500;
  color: #214978;
  max-width: 300px;
  text-align: center;
  margin: 20px auto;
  font-size: 14px;
  line-height: 23px;
}
.resetPassInput {
  border: #fff 1px solid;
  max-width: 280px;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 20%);
  background-color: white;
  border-radius: 5px;
  margin-top: 20px;
  text-align: left;
  min-height: 35px;
  padding-left: 10px;
  font-size: 18px;
  color: #143051;
}

.resetPassInputEmail {
  border: #fff 1px solid;
  max-width: 280px;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 20%);
  background-color: white;
  border-radius: 5px;
  margin-top: 20px;
  text-align: left;
  min-height: 35px;
  padding-left: 10px;
  font-size: 16px;
  min-width: 220px;
  color: #143051;
  font-weight: 500;
}

.contactFormDiv {
  min-height: 100vh;
  /* margin-top: 40px; */
  padding-bottom: 60px;
  padding-top: 20px;
  overflow-y: auto;
  min-width: 100vw;
}

.mobContactFormPaddBottom {
  padding-bottom: 80px !important;
}

.contactFormTitle {
  color: #143051;
  font-weight: 500;
  margin-top: 20px;
}

.emailContactInline {
  color: #fff !important;
  background-color: #214978;
  padding: 2px 6px;
  border-radius: 5px;
  border: 1px solid #143051;
  margin-left: 5px;
}

.contactDone {
  font-weight: 500;
  color: #143051;
}

.emailContactInline:hover {
  background-color: #143051;
}
.contactFormDivider {
  min-height: 2px;
  background-color: #bbcada;
  min-width: 300px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  max-width: 300px;
  margin: 30px auto;
}

.msgtxtarea {
  padding: 10px;
  min-height: 150px !important;
}

.contactFormSub {
  color: #8ea7c5;
  font-weight: 500;
  margin-top: 3px;
  font-size: 10px;
}
.contactFormInput {
  border: #fff 1px solid;
  max-width: 300px;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 20%);
  background-color: white;
  border-radius: 5px;
  margin-top: 10px;
  text-align: left;
  min-height: 35px;
  padding-left: 10px;
  font-size: 16px;
  min-width: 300px;
  color: #143051;
  font-weight: 500;
}
.requestNewPassBtn {
  background-color: #214978;
  min-height: 35px;
  color: white;
  border-radius: 5px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #143051;
  font-weight: 500;
  font-size: 14px;
  max-width: fit-content;
  margin: 20px auto;
}

.requestNewPassBtn:hover {
  background-color: #143051;
  border: 1px solid #143051;
}

.sendContactMsgBtn {
  background-color: #214978;
  min-height: 35px;
  color: white;
  border-radius: 5px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
  border: 1px solid #143051;
  font-weight: 500;
  max-width: fit-content;
  margin: 25px auto;
}

.invalidContactInput {
  border: 2px solid #d9aeb3;
}

.sendContactMsgBtn:hover {
  background-color: #143051;
  border: 1px solid #143051;
}

.emailDoesNotExistTxt {
  color: #ba0000;
  font-size: 11px;
  font-weight: 400;
  margin-top: 7px;
}

.colourNavy {
  color: #143051 !important;
}

.noPaddRight {
  padding-right: 0px !important;
}

.userOpenAcceptFirstTxt {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  margin-top: 9px;
  color: #bac5d3;
  line-height: 16px;
}

.claimedLight {
  background-color: #fff !important;
  border: 2px solid #143051;
}

.noClaimsTxt {
  font-weight: 500;
}

.mondayTodayBorder {
  border-left: none !important;
}

.biddersShiftLight {
  background-color: #7fbb67 !important;
}

.purBor {
  /* border-bottom: 3px solid #ffa8ff; */
}

.purBor:hover {
  /* border-bottom: 3px solid #ffa8ff !important; */
}

.teamSwapReqsQtyItem {
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 6px;
  font-size: 12px;
  padding-top: 1px;
  padding-bottom: 1px;
  color: white;
  border: 1px solid #896ec3;
  background-color: #a387df;
}

.teamSwapsFlex {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.toolsTeamSwapsNumber {
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 6px;
  font-size: 12px;
  padding-top: 1px;
  padding-bottom: 1px;
  color: #ffffff;
  background-color: #7a45ef;
}

.toolsTeamSwapsNumberMob {
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
  font-size: 12px;
  /* padding-top: 1px; */
  /* padding-bottom: 1px; */
  color: white;
  border: 1px solid #5b3a6c;
  background-color: #643cab;
  max-height: 20px;
  display: flex;
  align-items: center;
  margin-top: -2px;
}

.showToolsTeamSwaps {
  margin-left: 9px;
  margin-right: 0px;
  font-weight: 500;
}

.viewClockTimelineBtnSpan {
  color: white;
  border: solid 1px #0684c3;
  background-color: #0af;
  padding: 4px 6px;
  margin-left: 6px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.viewClockTimelineBtnSpan:hover {
  background-color: #0b97de;
}

.viewClockTimelinePopUpImg {
  margin-top: 3px;
  max-width: 12px;
}

.clockTimelineUnderlay {
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.clockTimelineModalBox {
  min-width: 340px;
  min-height: 500px;
  background-color: #f4f8fe;
  border-radius: 10px;
  z-index: 99999999;
  cursor: default;
  color: #143051;
}

.clockTimelineModalBoxMob {
  min-width: 100vw;
  border-radius: 15px 15px 0 0;
  position: fixed;
  bottom: 0;
  padding-left: 5%;
  padding-right: 5%;
  /* padding-bottom:50px; */
}

.clockTimelineModalHeader {
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  /* padding-left: 8px; */
  padding-top: 5px;
  margin-bottom: 8px;
  /* padding-right: 20px; */
}

.clockTimelineHeaderSideUnit {
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeClockTimelineImg {
  min-width: 35px;
  padding: 10px;
  cursor: pointer;
  margin-left: -10px;
}

.closeClockTimelineImg:hover {
  opacity: 0.8;
}

.clockTimelineHeaderTitle {
  font-weight: 500;
  font-size: 16px;
  /* margin-top:10px; */
}

.clockTimelineModalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 0px;
}
.closeClockTimelimeModalBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  max-width: 62px;
  min-width: 62px;
}

.closeClockTimelimeModalBtn:hover {
  background-color: #214978;
}

.clockedItemDataTimeRow {
  width: 94%;
  margin-left: 3%;
  border-radius: 4px;
  min-height: 42px;
  margin-top: 73px;
  /* border: 1px solid #0d2644; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #cbd7e6;
  background-color: #f4f8fe;
  border-left: 7px solid #0077ff;
}

.clockedItemDataTimeRow:first-of-type {
  margin-top: 20px;
}

.clockItemTimelineDivider {
  min-width: 90%;
  min-height: 4px;
  background-color: #143051;
  margin-top: 71px;
  margin-bottom: -32px;
  margin-left: 5%;
  max-width: 90%;
  border-radius: 4px;
}

.clockItemDividerTxtBrk {
  background-color: #5822bb;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-size: 12px;
  font-weight: 500;
  border-radius: 3px;
  padding: 2px 7px;
  border: 1px solid #021226;
  margin: 60px 10px;
  margin-bottom: -40px;
  /* min-width: 140px; */
  max-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brkLeftBordPurp {
  border-left: 9px solid #5822bb !important;
  /* max-width: 81%; */
  /* margin-left: 16%; */
}

.brkLeftBordGreen {
  border-left: 7px solid #88df65 !important;
}

.purpGpsStatusBrk {
  background-color: #5b25bd;
  border: 1px solid #280e5e;
}

.desktopClockTimeLineHeader {
  padding-left: 19px;
  padding-right: 21px;
}

.clockItemGPSscrollBoxDesktop {
  max-width: 90% !important;
  min-width: 90% !important;
}

.clockTimelineModalFooterDesktop {
  max-width: 93% !important;
  min-width: 93% !important;
  margin-left: 5%;
  padding-right: 10px;
}

.usrPayAsterixSalaryInfo {
  font-size: 9px;
  font-weight: 500;
  color: #fff;
  text-align: right;
  max-width: 93%;
  padding-right: 16px;
  margin-top: 8px;
  margin-bottom: 3px;
}

.contactDoneBtnsDiv {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.contactDoneBtn {
  background-color: #214978;
  border: 1px solid #143051;
  border-radius: 5px;
  color: white !important;
  margin-top: 20px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.contactDoneBtn:hover {
  background-color: #143051;
}

.myRotaJtUserMarginTop5px {
  /* background: yellow !important; */
  margin-top: 10px !important;
  /* min-height:66px !important */
}

.areYouSureModalUnderlay {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.areYouSureModalBox {
  width: 300px;
  min-height: 180px;
  background-color: #f4f8fe;
  border-radius: 5px;
  z-index: 99999999;
  cursor: default;
  color: #143051;
  padding: 15px 20px 15px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.areYouSureTxt {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  margin-top: 8px;
}

.areYouSureModalBtnsDiv {
  display: flex;
  justify-content: space-between;
  min-width: 156px;
  margin-top: 20px;
  min-height: 35px;
}

.areYouSureYesBtn {
  color: #fff;
  font-size: 16px;
  background-color: #0bf;
  border: 1px solid #0af;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 5px;
  min-width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 35px;
  font-weight: 500;
  cursor: pointer;
  min-width: 48%;
}

.areYouSureYesBtn:hover {
  background-color: #0af;
}

.areYouSureNoBtn {
  color: #fff;
  font-size: 16px;
  background-color: #214978;
  border: 1px solid #143051;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 5px;
  min-width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 35px;
  font-weight: 500;
  cursor: pointer;
  min-width: 48%;
}

.areYouSureNoBtn:hover {
  background-color: #143051;
}

.tilTakenEditImg {
  margin-top: 0px !important;
}

.approveOrDeclineChangeReqDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 135px;
  flex-direction: row-reverse;
}

.approveShChangeReqBtn {
  background-color: #6dba4f;
  border: 1px solid #5a9e3f;
  min-height: 35px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  padding-bottom: 2px;
}

.approveShChangeReqBtn:hover {
  background-color: #5a9e3f;
}

.declineShChangeReqBtn {
    /* background-color: #fff; */
    border: 1px solid #e6eaee;
    color: #bac5d3;
    min-height: 35px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}

.declineShChangeReqBtn:hover {
  border-color: #d7dfe9;
  color: #8ea7c5;
}

.shiftChangeTitleAndNote {
  min-height: 30px;
  max-height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 3%;
  padding-right: 3%;
  margin-top: 5px;
}

.changeNoteDiv {
  font-size: 12px;
  font-weight: 400;
  color: #0af;
  text-align: left;
  padding-left: calc(3% + 10px);
  padding-top: 5px;
  padding-bottom: 15px;
  line-height: 20px;
  background-color: #fcfdff;
}

.changeNoteTitleSpan {
  color: #143051;
  font-weight: 500;
}

.mobActiveToolsCog {
  margin-left: 0px;
  margin-right: 7px;
  margin-top: -1px;
}

.extendBlueLeftBorder {
  border-left: 5px solid #00ccff;
}

.extendPurpleLeftBorder {
  border-left: 5px solid #9374d6;
}
.extendRedLeftBorder {
  border-left: 5px solid #c95858;
}

.extendGreyLeftBorder {
  border-left: 5px solid #859dbb;
}

.makeCostSpanSmall {
  font-size: 9px;
  margin-left: 2px;
}

.borderLeft3Blue {
  border-left: 5px solid #00ccff;
}

.borderLeft3Blue:hover {
  border-left: 5px solid #00ccff;
  padding-left: 1px;
}
.borderLeft3Purple {
  border-left: 5px solid #9374d6;
}
.borderLeft3Purple:hover {
  border-left: 5px solid #9374d6;
  padding-left: 1px;
}

.borderLeft3Grey {
  border-left: 5px solid #859dbb;
  padding-left: 1px;
}

.borderLeft3Grey:hover {
  border-left: 5px solid #859dbb;
  padding-left: 1px;
}

.customDataRowBottomBorder {
  border-bottom: 2px solid #516b8b;
  padding-bottom: 4px;
  min-height: 40px !important;
}

.absenceLight {
  font-size: 9px;
  opacity: 0.5;
}

.undeciedBorderLeftChange {
  /* border-left: 5px solid #0af; */
  border: dashed 2px #bbcada;
  box-shadow: none;
}

.declinedBorderLeftChange {
  border-left: 5px solid #ba0000;
}

.approvedBorderLeftChange {
  border-left: 5px solid #a8cc9a !important;
}

.noLongerExistsTxt {
  color: #bbcada;
  font-size: 12px;
  margin-top: -12px;
  margin-bottom: 4px;
  margin-left: 10px;
}

.clocksLoadingApprove {
  opacity: 0.5;
  pointer-events: none;
}

.ongoingBorder {
  border-top: 3px solid #00ccff;
}

.minWidth312 {
  min-width: 312px !important;
}

.colourBlue {
  color: #b9e8ff !important;
}
.colourBlueMgr {
  color: #6ba1e4 !important;
}

.lieuColourMgr {
  color: #93b5dd !important;
}

.overColourMgr {
  color: #8aa9b7 !important;
}

.tilShiftRightParent {
  display: flex;
  align-items: center;
  margin-right: -1px;
  /* background-color: orange; */
}

.teamClockLeftParent {
  /* background-color: blue; */
  min-width: 55%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.teamClockRightParent {
  /* background-color: lime; */
  min-width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.leftReqItemPendingBorder {
  border-left: 5px solid #0af !important;
}

.mobClockStatusBringLeft {
  margin-right: 10px;
}

.ongoingTab {
  background-color: #0af;
  color: white;
  border-color: #07f;
  background-color: #f4f8fe;
  border: 2px solid #00ccff;
  color: #00ccff;
  margin-right: 8px;
  animation: pulsate 1s infinite;
}

@keyframes pulsate {
  0% {
    border: 2px solid #00ccff;
  }
  50% {
    border: 2px solid #cdf4fd;
  }
  100% {
    border: 2px solid #00ccff;
  }
}

.absentShiftUnit {
  background-color: white;
  max-width: 90%;
  min-width: 90%;
  margin: 14px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 72px;
  max-height: 72px;
  border-radius: 5px;
  cursor: pointer;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  padding: 1px;
  border: 1px solid #dee5f0
  /* margin-top: 12px; */
}

.formUnitMy {
  background-color: white;
  max-width: 90%;
  min-width: 90%;
  margin: 12px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 72px;
  max-height: 72px;
  border-radius: 5px;
  cursor: pointer;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  padding: 1px;
  border:1px solid #dee5f0;
  /* border: none; */
  margin-top: 20px;
}

.formUnitMy:hover {
  border:1px solid #bbcada;
  padding: 0px;
  padding-left: 1px;
  padding-right: 1px;
}

.absentShiftUnit:first-of-type {
  margin-top: 40px;
}

.formUnitX {
  margin-top: 42px !important;
  margin-bottom: 8px;
}

.absentShiftUnit:hover {
  background-color: #fff;
  border:1px solid #bbcada;
  /* padding: 0px; */
}

.absentShiftUnitLeft {
  /* background-color: orange; */
  min-width: 160px;
  text-align: left;
  font-weight: 500;
  padding-left: 7%;
}

.absentShiftUnitLeft2 {
  /* background-color: orange; */
  /* min-width: 130px; */
  min-width: fit-content;
  text-align: left;
  font-weight: 500;
  padding-left: 6%;
  margin-left: 2px;
}

.absentShiftTongue {
  background-color: #443e77;
  border-color: #143051;
  /*  */
  background-color: #7eb4cf;
  border-color: #6a9fb9;
}

.formTongue {
  background-color: #40468f;
  border-color: #272d70;
}

.absentShiftScheduledSpan {
  color: #bbcada;
  font-weight: 400;
  font-size: 10px;
  /* padding-right: 0px; */
  /* background: #dde9f9; */
  /* border-radius: 4px; */
  /* padding: 2px; */
  margin-bottom: 4px;
  line-height: 16px;
  padding-top: 0px;
  padding-bottom: 6px;
}

.bbcada {
  color: #bbcada;
}
.absenceShiftUnitLeftUpper {
  color: #143051;
  font-size: 14px;
  font-weight: 600;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 6px;
}

.absenceShiftUnitLeftLower {
  color: #95aaba;
  font-weight: 500;
  font-size: 12px;
  max-width: 170px;
    max-width: fit-content;

  margin-top: 1px !important;
  line-height: 13px;
  border-top: 1px solid #e0ebf8;
}

.myAbsentShiftsLowerRight {
  /* background:orange; */
  line-height: 20px;
  color: #95aaba;
  font-weight: 500;
  font-size: 12px !important;
  max-width: 170px;
  min-height: 24px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  /* justify-content: flex-end; */
  margin-bottom: 3px;
  max-height: 32px !important;
}

.myAbsShiftPurp {
  color: #ba0000;
  border-radius: 3px;
  /* padding-left: 4px; */
  /* padding-right: 4px; */
  min-height: 18px;
  /* margin-left: 5px; */
  /* margin-bottom: 3px; */
  display: flex;
  align-items: center;
  /* font-weight: 500; */
  border-top: 1px solid #e0ebf8;
  /* padding-right: 5px; */
  border-radius: 0;
  /* background-color: #c6b5eb; */
  /* border: #ba0000 1px solid; */
  /* background-color: #f07b7b; */
  /* border-color: #460f0f; */
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-height: 20px !important;
  font-size: 10px;
}

.absentShiftLeftParent {
  display: flex;
  min-width: calc(95% - 25px);
  justify-content: space-between;
  /* margin-top: 6px; */
  align-items: center;
}

.absenceShiftUnitRight {
  text-align: right;
  min-height: 44px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* min-width:116px; */
}

.absenceSpanColour {
  color: #bbcada;
  border-radius: 3px;
  /* padding-left: 4px; */
  /* padding-right: 4px; */
  /* font-size: 10px; */
  /* padding-top: 1px; */
  /* padding-bottom: 1px; */
  /* background-color: #8666a3;
  border: 1px solid #644b7a; */
  margin-left: 5px;
  margin-bottom: 3px;
  /* 
  background-color:#c6b5eb;
  border: #ac99d4 1px solid; */

  /* border: #ba0000 1px solid; */
  /* background-color: #f07b7b; */
}

.myAbsencesFiltBar {
  min-width: 90%;
  max-width: 90%;
  margin-left: 5% !important;
  /* background-color: orange; */
  min-height: 100%;
  display: flex;
  justify-content: space-between;
  /* margin-left: 29px; */
  margin-top: 8px;
  /* padding-right: 6%; */
  align-items: center;
}

.colourAbsencePurp {
  color: #8666a3;
  margin-bottom: 1px;
}

.colourAbsenceBlue {
  color: #214978;
  margin-bottom: 14px;
  margin-top: 15px;
}

.myAbsenceExplain {
  color: #143051;
  font-weight: 500;
  color: #bbcada;
  font-size: 14px;
  margin-top: 18px;
}



.x19849171 {
  margin-top:-5px;
  /* background-color: blue !important; */
}

.x184928741 {
  margin-top:0px
}
.x197467268841 {
  margin-top: -13px;
  margin-bottom: 6px;}

.invalidClockShiftTxt {
  font-weight: 500;
}

.unpaidAbsentSpan {
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content !important;

  font-weight: 500;
  padding-top: 2px;
  padding-bottom: 2px;

  font-size: 10px;
  text-align: left;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 3px;

  /* max-height: 26px; */
  /* margin-bottom:2px !important; */
  color: #fff;
  /* background-color: #f4f8fe; */
  /* border: 1px solid #e6e6e6; */
  margin-left: 7px;

  background-color: #c6b5eb;
  /* margin-top: 3px; */
  /* margin-bottom: -3px; */
  border: #ac99d4 solid 1px;
}

.dayOffTongue {
  background-color: #214978;
  border-color: #143051;
}

.lateActive {
  background-color: #f4f8fe;
  border: 1px solid #dde9f9 !important;
  border-radius: 5px !important;
  margin-top: -4px;
}

.lateActiveTitleRed {
  max-width: 42px;
  font-weight: 500;
  max-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  margin-bottom: 15px;
  margin-top: 7px;
  background: white;
  border: 1px solid #dde9f9;
  border-left: 2px solid #ba0000;
  color: #ba0000 !important;
}

.smallZoneName {
  font-size: 9px;
}

.pullCancelTilUpBecauseInPast {
  margin-bottom: -30px;
}

.addShiftLeaveOnlyBox {
  max-height: 330px;
  min-height: 330px;
}

.addShiftLeaveOnlyFooter {
  margin: -100px auto !important;
  /* flex-direction: row-reverse; */
}

.staffAbsenceHeaderPadding {
  padding-top: 2px;
}

.nonMobShiftsContainerWithFilt {
  max-height: calc(100vh - 294px) !important;
  min-height: calc(100vh - 294px) !important;
}

.nonMobShiftsContainerWithoutFilt {
  max-height: calc(100vh - 210px) !important;
  min-height: calc(100vh - 210px) !important;
}

.mgrReqDateStripLong {
  font-size: 11px;
  margin-top: -3px;
  line-height: 15px;
}

.mgrAbsName {
  color: #143051;
  font-size: 12px;
}

.absBox {
  min-height: calc(100vh - 208px);
  max-height: calc(100vh - 208px);
  align-items: flex-start;
  padding-bottom: 40px;
}

.periodsAbsBox {
  padding-top:34px;
}

.absBoxColCal {
  min-height: calc(100vh - 257px);
  max-height: calc(100vh - 257px);
  align-items: flex-start;
}

.absBoxColCalMob {
  min-height: calc(100vh - 314px);
  max-height: calc(100vh - 314px);
  align-items: flex-start;
}

.absBoxMob {
  min-height: calc(100vh - 265px);
  max-height: calc(100vh - 265px);
}

.x10841131 {
  min-height: calc(100vh - 265px);
  max-height: calc(100vh - 265px);
  /* margin-top:11px */
}

.absBoxMobStaff {
  min-height: calc(100vh - 254px);
  max-height: calc(100vh - 254px);
}

.staffAbsFiltBarRight {
  min-width: fit-content;
  display: flex;
  justify-content: space-between;
}

.absenceFiltBtn {
  font-size: 14px;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  padding-left: 14px;
  font-weight: 500;
  padding-right: 14px;
  cursor: pointer;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  /* margin-right:8px; */
}

.absenceFiltBtn:hover {
  background-color: #bbcada;
}

.absFiltBtnActiveColours {
  background-color: #8ea7c5;
  border: 1px solid #6987a9;
  max-width: 80px;
  padding-left: 11px;
  padding-right: 10px;
}

.absFiltBtnActiveColours:hover {
  background-color: #8ea7c5;
  border: 1px solid #6987a9;
}

.closeAbsFiltCrossImg {
  max-width: 9px;
  margin-left: 5px;
  margin-right: -2px;
}

.showFiltBarRightAbs {
  min-width: 208px;
}

.staffAbsenceFiltDiv {
  min-height: 50px;
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  border-bottom: 1px solid #e6eaee;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* padding-bottom: 5px; */
}

.absBoxFilterActive {
  min-height: calc(100vh - 255px);
  max-height: calc(100vh - 255px);
}

.absBoxFilterActiveStaff {
  min-height: calc(100vh - 304px);
  max-height: calc(100vh - 304px);
}

.lateStartSpanMgr {
  color: white;
  opacity: 0.8;
}

.staffAbsFixedRow {
  min-height: 36px;
  padding-left: 5px;
  padding-right: 8px;
}

.sideBoxAbsTypesMap {
  /* background-color: orange; */
  margin-top: 10px;
  /* margin-bottom:10px; */
}

.staffAbsUnpPaFix {
  margin-top: 12px;
  min-height: 35px;
}

.customTitleLongSmall {
  line-height: 16px !important;
  font-size: 14px !important;
}

.noPadLeft {
  padding-left: 0px !important;
}

.noPadRight {
  padding-right: 0px !important;
}

.teamSchedBar {
  padding-right: 4% !important;
  border-bottom: 2px solid #e6eaee;
  /* padding-bottom: 3px; */
}

.borderBottomGrey {
  border-bottom: 2px solid #e6eaee;
}

.shiftNamesDropdownTypeSelStaff {
  margin-left: 15px;
  display: none;
  /* max-width:170px;
  min-width:170px; */

}

.modalStaffAbsDataBox {
  max-width: 330px !important;
  min-width: 330px !important;
  cursor: default !important;
}

@media only screen and (max-width: 550px) {
  .modalStaffAbsDataBox {
    position: fixed;
    bottom: 0;
    min-height: 92vh !important;
    min-width: 100vw !important;
    border-radius: 13px 13px 0 0;
  }
}

@media only screen and (max-width: 550px) {
  .staffAbsDataModalBody {
    min-height: calc(100vh - 179px) !important;
    max-height: calc(100vh - 179px) !important;
  }
}

.staffAbsDataBoxHeaderLeft {
  display: flex;
  justify-content: flex-start;
  min-width: fit-content;
  /* background: blue */
}

.payableTitleAndTeamNameDivAbs {
  /* background-color: blue; */
}

.closeStaffAbsDataImg {
  max-width: 15px;
  margin-right: 16px;
  margin-left: 7px;
  margin-top: -12px;
  cursor: pointer;
}

.closeStaffAbsDataImg:hover {
  opacity: 0.8;
}

.myAbsDataBoxHeaderLeft {
  display: flex;
  justify-content: flex-start;
}

.absPercSideBox {
  margin-top: 12px !important;
}

.includeTypesSaveTempHover {
  /* background-color: yellow; */
  padding-right: 8px;
  padding-bottom: 9px;
  /* border-radius: 5px; */
}

.includeTypesSaveTempHover:hover {
  background-color: #f4f8fe;
}

.resizable {
  overflow: auto;
}
.loadMultiWeeks {
  /* background-color: #516b8b; */
  min-width: 160px;
  margin-bottom: 7px;
  /* display: flex; */
  /* justify-content: flex-end; */
  /* align-items: center; */
}

.loadMultiWeeks {
  color: #bbcada;
  font-size: 12px;
  font-weight: 500;
  /* margin-right: 8px; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-left: #e3e8ee 2px solid;
}

.applyTempPlusMinus {
  min-width: 24px;
  min-height: 24px;
  border: 1px solid #bbcada;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-radius: 5px;
  font-size: 20px;
  line-height: 20px;
  padding-bottom: 2px;
  cursor: pointer;
}

.applyTempPlusMinus:hover {
  border-color: #d7dfe9;
  background-color: #f4f8fe;
}

.applyTempTxt {
  max-width: 80px;
  margin-right: 8px;
  /* float: right; */
}

.applyWeeksContainer {
  display: flex;
  align-items: center;
  margin-top: 3px;
  /* float: right; */
}

.loadWeeksNumberInput {
  border-radius: 5px;
  border: 1px solid #dde9f9;
  font-weight: 500;
  text-align: center;
  background-color: #fff;
  color: #214978;
  min-height: 35px;
  margin-left: 5px;
  margin-right: 5px;
  min-width: 35px;
  pointer-events: none;
}

.dontForgetToPublish {
  color: yellow;
}

.publishTemplatedNow {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.loadingBulkModal {
  background-color: white;
  min-width: 200px;
  border-radius: 5px;
  padding: 20px;
  font-weight: 500;
  color: #143051;
  font-size: 14px;
  cursor: default;
}

.bulkHorizImg {
  max-width: 110px;
  margin-bottom: 8px !important;
}

.explainBookedOffModal {
  min-height: 100px;
  max-width: 300px;
  min-width: 300px;
  padding: 20px;
  border-radius: 5px;
  margin: 0 auto;
  justify-content: space-between;
  background-color: white;
  color: #143051;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
}

.explainBookedTxt {
  /* color:blue */
  line-height: 18px;
}

.explainBookedClose {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  margin-top: 20px;
}

.explainBookedClose:hover {
  background-color: #214978;
}

.limBold {
  font-weight: 500;
}

.clockOnLateTxt {
  font-size: 16px !important;
  padding-bottom: 4px;
  line-height: 47px;
}

.applyLateBtn {
  margin-right: 15px;
  margin-top: 9px;
  cursor: pointer;
  min-width: 68px;
  max-width: 68px;
  min-height: 35px;
  max-height: 35px;
  border-radius: 5px;
  padding: 0px;
  border: #e3e8ee solid 2px;
  background-color: white;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 5px;
  color: #496375;
  border-left: 3px solid #ba0000;
  border-radius: 4px 5px 5px 4px;
}

.applyLateBtn:hover {
  border-color: #b2bbc7;
  border-left: 3px solid #ba0000;
}

.unlinkedSupportBtn {
  color: white !important;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  font-weight: 500;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  margin-left: 4px;
  margin-right: 8px;
}

.unlinkedSupportBtn:hover {
  background-color: #bbcada;
}

.unlinkedPopup {
  max-width: 12px;
}

.statsImgMob {
  margin-left: 1px;
  margin-right: -1px;
}

.teamStatsImgMob {
  margin-right: 2px;
  margin-left: 2px;
}

.loadingStaffHoursData {
  max-width: 100px;
  margin-top: 18px;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(87deg)
    brightness(1019%) contrast(119%);
}

.newFormBtn {
  background-color: #a387df;
  border: 1px solid #896ec3;
  color: white;
  font-weight: 500;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 14px;
  cursor: pointer;
}
.newFormBtn:hover {
  background-color: #896ec3;
}

.whiteAddFormImg {
  max-width: 12px;
  margin-left: 6px;
}

.formUnitParent {
  max-height: 131px !important;
  animation-name: zoomBtnIn !important;
  animation-duration: 0.1s !important;
  margin-bottom: -19px;
}

.myFormUnit {
  max-height: 131px !important;
  margin-bottom: 0px;
}

.firstFormUnitParent {
  margin-top: 24px !important;
}

.firstFormUnitParentMy {
  margin-top: -1px;
}

.formUnitName {
  margin-top: -101px;
  background: white;
  border: 1px solid #e3e8ee;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border-radius: 5px 5px 0 0;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding-left: 13px;
  padding-right: 13px;
  font-size: 14px;
  margin-left: 12%;
  margin-bottom: 111px;
  z-index: 0;
  position: relative;
  border-bottom: none;
  color: #143051;
  float: left;
}

.absPeriodName {
  margin-top: -99px;
  background: white;
  border: 1px solid #e3e8ee;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border-radius: 5px 5px 0 0;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding-left: 5px;
  padding-right: 10px;
  font-size: 14px;
  margin-left: 12%;
  margin-bottom: 71px;
  z-index: 0;
  position: relative;
  border-bottom: none;
  color: #143051;
  float: left;
  font-size: 12px;
}

.formMgrApproved {
  background-color: #c1d2ba;
  border: 1px solid #6db451;
  color: white;
  border-radius: 3px;
  font-size: 10px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 6px;
  margin-top: 6px !important;
  min-height: 20px;
  margin-bottom: 2px;
}

.formTick {
  max-width: 8px;
  margin-left: 3px;
  margin-right: 3px;
}

.displayFlexEnd {
  display: flex;
  justify-content: flex-end;
}

.usrCompleteForm {
  /* background-color: #fff; */
  /* border-color: #bbcada; */
  margin-top: 5px !important;
  /* border-left: 3px solid #6db451; */
  color: #fff;
  min-height: 20px;
}

.formTitleSmaller {
  font-size: 12px !important;
  line-height: 15px;

  margin-bottom: 3px;
}

.notYetFilledIn {
  font-size: 9px;
  margin-top: 5px;
  border-radius: 3px 2px 2px 3px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  color: #674973;
  border: 1px solid #bbcada;
  border-left: 3px solid #674973;
  padding-left: 6px;
  padding-right: 6px;
}

.notYetSignedOff {
  font-size: 9px;
  /* margin-top: 5px; */
  /* border-radius: 3px 2px 2px 3px; */
  max-width: -moz-fit-content;
  max-width: fit-content;
  color: #bbcada;
  /* border: 1px solid #bbcada; */
  /* border-right: 2px solid #bbcada; */
  /* padding-left: 6px; */
  /* padding-right: 6px; */
  min-height: 14px;
  display: flex;
  align-items: center;
  margin-top: 5px;

}

.notYetFilledInMy {
  /* font-size: 9px;
  margin-top: 5px;
  border-radius: 3px 2px 2px 3px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  color: #7cd3ff;
  border: 1px solid #bbcada;
  border-left: 3px solid #7cd3ff;
  padding-left: 6px;
  padding-right: 6px;
  min-height:20px;
  display: flex;
  align-items: center;
  margin-bottom: 4px; */
  display: none;
}

.formCreatedTab {
  margin-top: -18px;
  max-width: fit-content;
  float: right;
  margin-right: 12%;
  background: white;
  border: 1px solid #e3e8ee;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border-radius: 0px 0px 5px 5px;
  min-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 10px;
  border-top: none;
  color: #bbcada;
}

.noAnimation {
  animation-name: none !important;
  animation-duration: 0s !important;
}

.margiTop10 {
  margin-top: 10px !important;
}

.formIconLeft {
  min-width: 22px;
  /* margin-right: 4px; */
  /* background-color: yellow; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-right: 2px dotted #bbcada; */
  padding-right: 10px;
  min-height: 50px;

  max-height: 50px;
  padding-top: 2px;
}

.formIcon {
  max-width: 17px;
  /* margin-top: -8px; */
  margin-left: 8px;
  margin-right: 3px;
}

.lateIcon {
  max-width: 18px;
  margin-top: -2px;
  margin-left: 10px;
}
.absentShiftIcon {
  max-width: 24px;
  margin-top: -4px;
  margin-left: 15px;
  margin-right: 1px;
}

.formLeftWrapper {
  display: flex;
  /* margin-top:-45px; */
}

.font14 {
  font-size: 14px;
  max-width: 160px;
}

.absencePeriodIcon {
  max-width: 12px;
  margin-left: 14px;
  margin-right: -6px;
}

.absencePeriodIconMob {
  max-width: 11px;
  margin-left: 14px;
  margin-right: -6px;
}

.timeHoursImg {
  max-width: 16px;
  min-width: 16px;
  margin-left: 10px;
}




.myHoursLeftWrapper {
  display: flex;
  /* min-width: 202px;
  max-width:202px; */
  min-width: 180px;
  max-width: fit-content;
  min-height: 72px;
  align-items: center;
}

.clockSectionLeftWrapper {
  display: flex;
  min-width: 52%;
  /* background: orange; */
  margin-left: 15px;
}
.clockCardIcon {
  max-width: 15px;
  margin-right: 3px;
  margin-left: -2px;

}

.teamClockCardIcon {
  max-width: 15px;
  margin-left: 2px;
  margin-right: 4px;
}

.formItemsDiv {
  border: 2px solid #e0ebf8;
  max-width: 90%;
  margin-left: 5%;
  border-radius: 5px;
  min-height: 40px;
  /* overflow: auto; */
  /* max-height: 210px; */
  margin-top: 10px;
  padding-bottom: 10px;
  background-color: #f4f8fe;
}

.formItemsDivMyForm {
  max-width: 96%;
  margin-left: 2%;
  border-radius: 5px;
  min-height: 40px;
  /* overflow: auto; */
  /* max-height: 210px; */
  margin-top: 4px;
  padding-bottom: 0px;
}
.noFormItems {
  color: #bbcada;
  font-weight: 500;
  text-align: center;
  font-size: 12px;
  margin-top: 7px;
  margin-bottom: -12px;
  border-left: 3px solid #bbcada;
  border-radius: 0px !important;
  max-width: 260px;
  max-height: 27px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  margin-left: 17px;
}

.formHeaderTitle {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  line-height: 23px;
  max-width: 83%;
  padding-bottom: 3px;
  min-height: 48px;
  display: flex;
  align-items: center;
}

.yetToCompleteForm {
  color: #bbcada;
  font-size: 12px;
  font-weight: 500;
  margin-left: 6px;
  padding-left: 8px;
  border-left: #bbcada 2px solid;
  max-height: 18px;
  display: flex;
  align-items: center;
}

.yetToCompleteFormUsrOnly {
  cursor: pointer;
}

.yetToCompleteFormUsrOnly:hover {
  border-left: #214978 2px solid;
  color: #214978;
}

.rtwQanswerInput {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 7px;
  background-color: #fdfeff;
  resize: none;
  color: #214978;
  padding: 10px;
  font-weight: 500;
  margin-top: 19px;
  min-width: 100%;
  font-size: 16px;
  line-height: 22px;
}

.saveRtwBtn {
  border: solid 1px #0af;
  background-color: #0bf;
  max-height: 35px;
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.saveRtwBtn:hover {
  background-color: #0af;
}

.formNote {
  color: #143051;
  font-size: 18px;
  font-weight: 500;
  margin-left: 7%;
  padding-right: 20px;
  line-height: 20px;
  padding-top: 8px;
}

.respUnit {
  background-color: #ffffff;
  max-width: 100%;
  margin-left: 0%;
  /* border-radius: 5px; */
  border-bottom: 1px solid #dde9f9;
  margin-top: 10px;
  font-weight: 500;
  padding-left: 10%;
  font-size: 14px;
  padding-right: 31px;
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 12px;
}
.respUnit2 {
  padding-bottom: 22px;
  /* max-width: 80%; */
  /* margin-left:10%; */
}

.respUnit:last-child {
  border: none;
}

.respUnit:first-of-type {
  margin-top: 15px;
}
.latesLower {
  margin-top: -1px;
}

.responseA {
  margin-top: 10px;
  font-weight: 500;
  line-height: 18px;
  font-size: 14px;
  color: #143051;
  margin-bottom: 10px;
  border-left: 2px solid #9380c5;
  padding-left: 9px;
}

.formCompletedByYouDiv {
  background-color: #fff;
  border: 1px solid #6db451;
  border-left: 3px solid #6db451;
  align-items: center;
  color: #6db451;
  font-size: 10px;
  max-width: -moz-fit-content;
  max-width: 108px;
  line-height: 11px;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  padding: 3px;
  padding-left: 8px;
  padding-right: 5px;
  border-radius: 5px;
  text-align: left;
}

.formCompByYouTick {
  max-width: 10px;
  margin-right: 5px;
  margin-left: 2px;
}

.rtwFormNotYetApp {
  margin-left: 10px;
  color: #0077ff;
  font-size: 10px;
  /* border-right: 3px solid #0077ff; */
  display: flex;
  align-items: center;
  /* padding-right: 5px; */
  margin-bottom: 2px;
}

.signOffRTWbtn {
  background-color: #1e88e5;
  border: #0469de 1px solid;
  color: white;
  font-weight: 500;
  max-height: 35px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 9px;
  padding-right: 9px;
  cursor: pointer;
  font-size: 14px;
  margin-right:10px;
}

.signOffRTWbtn:hover {
  background-color: #1374e4;
}

.absenceRTWFooterLeft {
  display: flex;
  justify-content: flex-start;
}

.filledInMarginLeft {
  margin-left: 10px;
}

.staffTitleLeftBlob {
  background-color: #ceb9f3;
  color: #ceb9f3;
  border-radius: 5px;
  max-width: 5px !important;
  min-width: 5px !important;
  /* min-height: 10px; */
  margin-right: 10px;
  font-size: 23px !important;
}

.staffAbsLateRight {
  /* max-height: 30px; */
  min-height: 40;
}

.absentShiftLower {
  margin-bottom: 10px !important;
}

.absShiftX {
  line-height:22px;
  margin-top: 0px;
}

.absRightShift {
  margin-top: 2px;
}
.latesLower2 {
  margin-bottom: 5px;
}

.shiftsHeaderMob2 {
  margin-top: 5px !important;
}

.shiftsHeaderMob3 {
  margin-top: 11px !important;
}

.mobMyAccHeader {
  margin-top: 12px !important;
}

.unkDurStr {
  font-size: 10px;
  padding-top: 7px;
  padding-bottom: 2px;
}

.formMinsStr {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  padding-bottom: 2px;
  color: #214978;
  /* margin-top: -1px; */


  /* font-size: 10px; */
  font-weight: 500;
  background: #f4f8fe;
  border-radius: 5px;
  border: 1px solid #bbcada;
  color: #516b8b;
  font-size:12px;
  padding-left:6px;
  padding-right:6px;
}

.x1974101 {
    margin-top: -31px;
    margin-bottom:8px;

}

.lateStartStr {
  font-weight: 500;
  margin-left: 3px;
  font-size: 12px !important;
  color: #ba0000;
  line-height: 18px;
  min-height: 16px;
  border-top: 1px solid #e0ebf8;
  border-radius: 0;
  margin-top: 2px;
}
.x9739171 {
  margin-bottom: -25px;
  margin-top: 20px;
}

.minHeight24 {
  min-height: 24px !important;
}

.formKey {
  background-color: #fff;
  border: 1px solid #e3e8ee;
  border-radius: 5px;
  min-height: 44px;
  max-height: 44px;
  max-width: 100%;
  min-width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 8px;
  margin-top: -5px;
  padding-left: 2px;
  padding-right: 2px;

  /* float: right; */
}

.responseQ {
  color: #bbcada;
  font-weight: 500;
  font-size:12px;
  line-height: 18px;
}

/* .mgrFormKey {
  border-color: #e3e8ee
} */

.formTabSel {
  background-color: #9380c5;
  min-height: 3px;
  min-width: 85px;
  border-radius: 10px;
  margin-top: -10px;
}

.formTabTitle {
  margin-top:-9px;
  max-height:44px;
  display: flex;
  align-items: center;
}

.formTabTitleSel {
  color:#143051
}

.formKeyTab {
  background-color: #143051;
  border-radius: 5px;
  /* border: 1px solid #e6eaee; */
  /* border-top: 2px solid #0bf;
  border-left: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee; */
flex-direction: column;

  /* border-bottom:none; */
  display: flex;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
  min-width: calc(50% - 4px);
  margin-top: 4px;
  max-height: 34px !important;
  min-height: 34px !important;
  color: white;
}

.formKeyTab:last-child {
  /* margin-left:10px; */
}

.formKeyTabUnselected {
  background: none;
  border: none;
  cursor: pointer;
  color: #bbcada;
}

.formKeyTabUnselected:hover {
  color: #8ea7c5;
}

.issuedByForm {
  margin-top: 18px;
  margin-bottom: 10px;
  color: #bbcada;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.formPageQty {
  background-color: #bbcada;
  color: white;
  max-height: 20px;
  display: flex;
  align-items: center;
  font-weight: 500;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 4px;
  margin-left: 5px;
  /* margin-right: -12px; */
}

.formPageQtyFilledIn {
  background-color: #9380c5;
}

.addLeaveDatesTitlePage2 {
  border-radius: 5px 0px 0px 0px !important;
}

.purpleFormTab {
  /* border-top: 2px solid #ac99d4 */
}

.staffHoursDataBoxHeaderLeft {
  display: flex;
  justify-content: flex-start;
}

.closeStaffDataBoxImg {
  cursor: pointer;
  min-width: 15px;
  max-width: 15px;
  margin-left: 3px;
  cursor: pointer;
  margin-top: -16px;
  margin-right: 16px;
}

.closeStaffDataBoxImg:hover {
  opacity: 0.8;
}

.formModalFooterRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.deleteFormBtn {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  color: white;
  font-weight: 500;
  font-size: 14px;
  max-height: 35px;
  max-width: 35px;
  min-width: 35px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.deleteFormBtn:hover {
  background-color: #bbcada;
}

.sureModalBtnsContainer {
  background-color: blue;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.staffLateName {
  margin-top: 3px;
  margin-bottom: 0px;
  font-size: 16px;
}

.staffAbsentShiftsName {
  font-size: 14px !important;
  margin-top: 3px;
  margin-bottom: 2px;
  line-height:22px;
}

.respHighlight {
  border: 2px solid #00ccff !important;
  color: #214978 !important;
}

.respHighlight:hover {
  border: 2px solid #143051 !important;
  color: #143051 !important;
}
.staffAbsMobShoulder {
  /* background-color: blue; */
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  min-height: 50px;
  max-height: 50px;
}

.staffAbsMobShoulderRow {
  border-bottom: 1px solid #e0ebf8;
  height: 50px;
  max-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formUnitY {
  margin-top: 13px !important;
}

.formYrAndCreateDiv {
  /* background-color: blue; */
  display: flex;
  min-width: fit-content;
}

.createFormInline {
  margin-left: 10px;
  min-width: 102px;
}

.myAbsPerInline {
  margin-right: 15px;
}

.desktopClockCardsShoulder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  max-width: 100%;
  /* background-color: orange; */
}

.staffClocksMobShoulder {
  /* background-color: blue; */
  min-width: 100%;
  max-width: 100%;
  /* margin-left: 5%; */
  min-height: 130px;
  max-height: 130px;
  padding-top: 11px;
  margin-top: 8px;
}

.staffClocksMobShoulderRow {
  border-bottom: 1px solid #e0ebf8;
  height: 50px;
  max-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 29px;
}
.userHoursShoulderBar {
  min-width: calc(100vw + 4px) !important;
  margin-left: -2px !important;
  border-radius: 20px 20px 0 0 !important;
  border-top: 2px solid #e6eaee !important;
  border-left: 2px solid #e6eaee !important;
  border-right: 2px solid #e6eaee !important;
  padding-left: 5%;
  padding-right: 5%;
  min-height: 100px !important;
  max-height: 100px !important;
  /* min-height:112px !important; */
}

.staffClocksMobShoulderRow2 {
  border-bottom: 1px solid #e0ebf8;
  height: 50px;
  max-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1px;
  padding-bottom: 8px;
}

.staffClocksMobShoulderRowUnlinked {
  margin-top: 1px;
}

.userHoursShoulderBar {
  /* min-height:110px !important;
  max-height: 110px !important; */
  /* margin-bottom:-9px;
  padding-top:10px;
  margin-top:5px */
}

.userHoursShoulderBar2 {
  min-width: calc(100vw + 4px) !important;
  margin-left: -2px !important;
  border-radius: 20px 20px 0 0 !important;
  border-top: 2px solid #e6eaee !important;
  border-left: 2px solid #e6eaee !important;
  border-right: 2px solid #e6eaee !important;
  padding-left: 5%;
  padding-right: 5%;
  min-height: 50px !important;
  max-height: 50px !important;
}

.marginTopMinus6 {
  margin-top: -6px !important;
}

.marginTop16 {
  margin-top: 16px;
}

.minHeight106px {
  min-height: 106px !important;
  max-height: 106px !important;
}

.marginTop6 {
  margin-top: 6px !important;
}

.marginTop3 {
  margin-top: 3px !important;
}

.lightbluebg {
  background-color: #ebf0f7 !important;
}

.allStaffTeamClocksMobDrop {
  margin-top: 5px;
}

.requestAvailFromWholeTeamBtn {
  background-color: blue;
}

.reqTeamAvailBtnTxt {
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 35px;
  font-weight: 500;
  border-radius: 5px;
}

.absentRowActive {
  border: 2px solid #143051 !important;
  border-radius: 5px;
  background: #214978;
}

.absentRowActive:hover {
  background-color: #143051 !important;
}

.toilNoteTitle {
  margin-top: 20px;
}

.toilFooter {
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  border-top: 1px solid #e0ebf8;
  max-width: 92%;
  margin-left: 4%;
}

.paddingLeft2 {
  padding-left: 2% !important;
}

.availTilBrackets {
  font-weight: 500;
  font-size: 10px;
  color: #bbcada;
  /* text-align: left; */
  /* margin-top:-28px */
}

.availToilTitleDiv {
  /* background-color: blue; */
  min-height: 36px;
  text-align: left;
  padding-top: 12px;
}

.marginTopMinus28 {
  margin-top: -28px !important;
}

.desktopHeaderPanelRota {
  margin-left: 20px !important;
  min-width: 132px !important;
  max-width: 132px !important;
}

.desktopAddBtnRota {
  /* background-color: yellow; */
  max-width: 38px !important;
  min-width: 38px !important;
}

.tilInvalidTimeInput {
  border: #ba0000 2px solid !important;
}

.tilInvalidTimeInput:hover {
  border: #ba0000 2px solid !important;
}

.x191 {
  /* margin-top: 11px; */
  margin-right: 34px;
  line-height: 27px;
  margin-left:5px;
  font-size:14px;
}

.x19198314 {
  margin-top: 0px;
  line-height: 28px;
  min-width: 100px;
}

.tilFooterLeft {
  display: flex;
  justify-content: flex-start;
}

.removeTilFooterBtn {
  border: 1px solid #bbcada;
  background-color: #cbd7e6;
  cursor: pointer;
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  font-weight: 500;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 14px;
}

.removeTilFooterBtn:hover {
  background-color: #bbcada;
}

.myShiftTimeStrip {
  margin-left: 0px !important;
}

.changesArrRow {
  flex-direction: column;
}

.changesArrDiv {
  min-width: fit-content;

  max-width: fit-content;
  margin-bottom: 10px;
}

.fontSize10 {
  font-size: 10px !important;
  color: #8997a8 !important;
  /* opacity: 0.5; */
}

.fontSize9 {
  font-size: 9px !important;
}
.marginLeft0 {
  margin-left: 0px !important;
}

.rotaCoffee {
  max-width: 12px;
  margin-right: 4px;
  margin-top: 2px;
  margin-bottom: -2px;
  opacity: 0.5;
}

.rotaCoffeeTeam {
  max-width: 11px;
  margin-right: 2px;
  margin-top: 4px;
  margin-bottom: -2px;
  opacity: 0.5;
  margin-left: -6px;
}
.rotaClock {
  max-width: 10px;
  opacity: 0.5;
  margin-right: 3px;
  margin-top: 2px;
  margin-bottom: -2px;
}

.rotaClockTeam {
  max-width: 10px;
  opacity: 0.5;
  margin-right: 2px;
  margin-top: 2px;
  margin-bottom: -2px;
}

.costOverlay {
  background-color: white;
  border: 1px solid #6c96c8;
  border-radius: 3px 3px 0 0;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-left: 10%;
  min-height: 15px;
  margin-top: -70px;
  margin-bottom: 68px;
  position: relative;
  color: #516b8b;
  font-weight: 500;
  font-size: 10px;
  padding-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  /* border-left: 3px solid #6c96c8; */
  border-bottom: none;
  border-color: #dde9f9;

}

.costOverlayLeave {
  background-color: #7788d8;
  color: #bfc7ef;
  border: 1px solid #6680a2;
  border-bottom:none;
}

.costOverlayAbs {
  background-color: #9aadc4;
  color: #e2e6f7;
  border: 1px solid #6680a2;
  border-bottom: none;
  }


.selfLogAbsIndicate {
  background-color: white;
  border: 1px solid #6c96c8;
  border-radius: 3px 3px 0 0;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-left: 52px;
  min-height: 15px;
  margin-top: -83px;
  margin-bottom: 70px;
  position: relative;
  color: #143051;
  font-weight: 500;
  font-size: 10px;
  padding-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  /* border-left: 3px solid #6c96c8; */
  border-bottom: none;
  border-color: #dde9f9;
  color:#ba0000;
  font-size:8px
  /* display:none */
}

.selfLogAbsIndicateOtherTeam {
  margin-left:8px
}

.teamHoursDsStrip {
  /* min-width: 46px !important */
}

.opacFull {
  opacity: 1;
}

.rotaClockLeaveOnly {
  margin-right: 5px;
}

.myRotaCoffee {
  margin-left: 3px;
  margin-right: 3px;
}

.noBorderLeft {
  border-left: none !important;
}

.changeItemsDiv {
  /* background-color: yellow; */
  max-width: 60%;
}

.changeItemRow {
  font-weight: 500;
  line-height: 16px;
  color: #bbcada;
  border-left: 3px solid #00ccff;
  padding-left: 8px;
  margin-bottom: 12px;
  margin-top: 12px;
  font-size: 12px;
  margin-left: 12px;
}

.changeUnitTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: blue; */
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #214978;
  /* opacity: 0.7; */
}

.chAppBorder {
  border-color: #a8cc9a;
}
.chDecBorder {
  border-color: #ba0000;
}

.changeApprovedStat {
  color: #a8cc9a;
  font-size: 12px;
}

.changeDeclinedStat {
  color: #ba0000;
  font-size: 12px;
}

.changePendingStat {
  display: flex;
  align-items: center;
  color: #bbcada;
  font-size: 12px;
  margin-left:9px;
}

.changeReqDs {
  font-size: 10px;
  font-weight: 400;
  color: #bbcada;
  margin-left: 10px;
  margin-top: -26px;
}

.x1113 {
  margin-top: -16px;
  margin-bottom: -9px;
}

.cancelReqInMyShiftImg {
  max-width: 24px;
  border: 1px solid #bbcada;
  border-radius: 4px;
  padding: 5px;
  margin-top: 0px;
  opacity: 0.5;
  margin-left: 5px;
  cursor: pointer;
}

.cancelReqInMyShiftImg:hover {
  opacity: 1;
}

.changeShiftDurMinsDiff {
  color: #bbcada;
  font-size: 12px;
  font-weight: 500;
  line-height: 27px;
  padding-left: 12px;
  margin-top: 4px;
  margin-bottom: 13px;
  border-left: #143052 3px solid;
  margin-left: 12px;
}

.changeDurSpanMins {
  /* background-color: #bedaf8;
    color: white;
    border-radius: 3px; */
  padding-left: 4px;
  /* padding-right: 3px; */
}

.clockOnSpan {
  color: #516b8b;
  font-size: 8px;
  font-weight: 400;
  margin-bottom: 1px;
}

.appOrDecChReqDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: fit-content;
  padding-bottom: 1px;
}

.appChReqBtn {
  background-color: #5ca54091;
  border: 1px solid #5a9e3f75;
  color: #ffffff;
  /* background: white; */
  border-radius: 5px;
  min-height: 28px;
  max-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
  line-height: 12px;
  margin-left: 6px;
  cursor: pointer;
}

.appChReqBtn:hover {
  background-color: #3f702c75;
}

.decChReqBtn {
  /* background-color: #fff; */
  border: 1px solid #e6eaee;
  color: #bac5d3;
  border-radius: 5px;
  min-height: 28px;
  max-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;
}

.decChReqBtn:hover {
  background-color: #ecf4fd;
}

.loadingAppOrDecShiftChangeImg {
  max-width: 36px;
}

.chNote {
  color: #bbcada;
  border-top: 1px solid #bbcada;
  max-width: 90%;
  margin-left: 5%;
  font-weight: 500;
  line-height: 16px;
  padding-top: 13px;
  padding-bottom: 13px;
  font-size: 12px;
}

.chNoteSpanTitle {
  color: #143051;
}

.shiftUnitOutstandingChanges {
  color: white;
  padding-left: 0px;
  padding-right: 0px;
  font-weight: 500;
  border-radius: 4px 2px 2px 2px;
  font-size: 10px;
  padding-bottom: 2px;
  padding-top: 1px;
  border: 1px solid #965ade;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-height: 17px;
  max-width: 175px !important;
  margin-right: -156px;
  min-width: 156px !important;
  margin-top: -54px;
  border-left: 3px solid #965ade;
  z-index: 99;
}

.shiftUnitOutstandingChanges2 {
  color: #fff;
  padding-left: 0px;
  padding-right: 0px;
  font-weight: 500;
  border-radius: 4px 2px 2px 2px;
  font-size: 10px;
  padding-bottom: 2px;
  padding-top: 1px;
  border: 1px solid #965ade;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-height: 17px;
  max-width: 135px !important;
  margin-right: -92px;
  min-width: 92px !important;
  margin-top: -54px;
  border-left: 3px solid #965ade;
  z-index: 999999;
  background: #c2a9fa;
}

.pushSec1Down {
  margin-top: 6px;
}

.myOutstandingColours {
  background-color: #0bf;
  border-color: #0af;
}

.calDateStrip {
  font-weight: 500;
  color: #bbcada;
  font-size: 12px;
}

.monthCalUnit {
  /* background-color: blue; */
  max-width: 90%;
  margin-left: 5%;
  margin-bottom: 70px;
  margin-top: 10px;
}

.midCalHolder {
  display: flex;
  flex-wrap: wrap;
}

.fullCalHolder {
  display: flex;
  flex-wrap: wrap;
}

.midCalUnit {
  max-width: 42%;
  flex-basis: 42%;
  margin-left: 6%;
  margin-bottom: 40px;
}

.fullCalUnit {
  max-width: 40%;
  flex-basis: 40%;
  margin-left: 7%;
  margin-bottom: 40px;
  /* border: 1px solid #dde9f9; */
  /* border-radius:3px; */
  /* padding:7px; */
  /* background-color: white; */
}
/* 
   .fullCalUnit:nth-child(even) {
    border-left: 1px solid #bbcada;
    background-color: lime;
  
   }

   .midCalUnit:nth-child(even) {
    border-left: 1px solid #bbcada
   } */

.monthCalUnitHeader {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  /* margin-top:30px; */
  /* color:; */
}

.monthCalUnitHeaderTitle {
  color: #bbcada;
  font-weight: 500;
  font-size: 14px;
}
.font20 {
  font-size: 20px;
}

.monthCalDayLetters {
  display: flex;
  justify-content: space-around;
  min-width: 100%;
  max-width: 100%;
  min-height: 40px;
  align-items: center;
  margin-top: 20px;
}

.monthCalDayLtr {
  /* background-color: pink; */
  width: 14.2857142857%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #8ea7c5;
  font-size: 14px;
}
.snake {
  display: flex;
  flex-wrap: wrap;
}
.snakeItem {
  /* background-color: yellow; */
  flex-basis: 14.2857142857%;
  min-height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #516b8b;
  cursor: pointer;
  font-size: 14px;
  /* padding:5px; */
  /* max-width:90%; */
}

.snakeItem:hover {
  background-color: #e0ebf8;
  font-weight: 500;
  border-radius: 3px;
}
.nonMobSnakItem {
  min-height: 30px;
  font-size: 14px;
}

.snakeItemLeave {
  background-color: #00ccff;
  color: white;
  font-weight: 500;
  min-width: 96%;
  min-height: 96%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font: size 1px;px; */
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #00b3df
}

.snakeItemLeaveMob {
  max-height: 44px !important;
  min-height: 44px !important;
}

.snakeItemLeave:hover {
  background-color: #85dafc;
}

.snakeAbsenceColours {
  background-color: #516b8b;
  border: 1px solid #315075
}
.snakeAbsenceColours:hover {
  background-color: #607999;
}

.snakeFirstHalfOff {
  background: linear-gradient(66deg, #00ccff 50%, #cbd7e6 50%) !important;
}

.snakeLastHalfOff {
  background: linear-gradient(246deg, #00ccff 50%, #cbd7e6 50%) !important;
}

.snakeFirstHalfOffAbs {
  background: linear-gradient(66deg, #516b8b 50%, #cbd7e6 50%) !important;
}

.snakeLastHalfOffAbs {
  background: linear-gradient(246deg, #516b8b 50%, #cbd7e6 50%) !important;
}

.snakeLastHalfOffAbsHovered {
  background: linear-gradient(246deg, #6484aa 50%, #cbd7e6 50%) !important;
}

.snakeFirstHalfOffAbsHovered {
  background: linear-gradient(66deg, #6484aa 50%, #cbd7e6 50%) !important;
}

.snakeFirstHalfOff:hover {
  background: linear-gradient(66deg, #85dafc 50%, #cbd7e6 50%);
}

.snakeLastHalfOff:hover {
  background: linear-gradient(246deg, #85dafc 50%, #cbd7e6 50%);
}

.snakeFirstHalfOffAbs:hover {
  background: linear-gradient(66deg, #607999 50%, #cbd7e6 50%);
}

.snakeLastHalfOffAbs:hover {
  background: linear-gradient(246deg, #607999 50%, #cbd7e6 50%);
}

.snakeLastHalfOffHovered {
  background: linear-gradient(246deg, #85dafc 50%, #cbd7e6 50%) !important;
}

.snakeFirstHalfOffHovered {
  background: linear-gradient(66deg, #85dafc 50%, #cbd7e6 50%) !important;
}

.pHolNameSnakeItem {
  color: #9bc4f4;
  min-width: 96%;
  min-height: 96%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: 1px solid #9bc4f4;
  font-weight: 500;
  cursor: pointer;
}

.pHolNameSnakeItem:hover {
  background-color: #e0ebf8;
}

.specDayNameSnakeItem {
  color: #8ea7c5;
  min-width: 96%;
  min-height: 96%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: 1px solid #8ea7c5;
  font-weight: 500;
  cursor: pointer;
}

.nullSnake {
  cursor: default;
}

.nullSnake:hover {
  background: none;
  cursor: default;
}

.currMcal {
  border-left: 3px solid #0077ff;
  padding-left: 9px;
  min-width: calc(43% + 8px);
  margin-left: 30px;
}

.currMcalMob {
  border-left: 3px solid #0077ff;
  padding-left: 9px;
  min-width: calc(43% + 8px);
  margin-left: 13px;
}

.forceCalTxtSmall {
  font-size: 12px !important;
}

.monthCalHeaderTitleCurr {
  padding-left: 0px;
  border: none;
  color: #0077ff;
}

.todayCalSnake {
  padding-top: 1px;
  border: 3px solid #0077ff !important;
  border-radius: 4px;
}

.nonMobLoadingCal {
  align-items: flex-start;
  justify-content: center;
}

.closedCalLtr {
  background-color: #e8eeff;
  border: 1px solid #ebf0f7;
  border-radius: 2px;
}

.closedCalLtrPost:hover {
  background-color: #e8eeff;
  border: 1px solid #ebf0f7;
  border-radius: 2px;
}

.showDayModalBox {
  background-color: #fff;
  color: #143051;
  border-radius: 10px;

  min-width: 340px;
  max-width: 340px;
  /* -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  cursor: auto;
  /* min-height: 350px; */
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.showDayModalHeader {
  width: 100%;
  height: 58px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 7%;
  padding-right: 7%;
}

.closeShowDayModalImg {
  max-width: 15px;
  cursor: pointer;
}

.closeShowDayModalImg:hover {
  opacity: 0.8;
}

.showDayHeaderTitle {
  font-weight: 500;
}

.showDayModalBody {
  background-color: #f4f8fe;
  min-height: 93px;
  width: 90%;
  margin-left: 5%;
  border: solid 1px #e6eaee;
  border-top: solid 2px #e6eaee;
  border-radius: 5px 5px 0 0;
  max-height: 450px;
  overflow-y: auto;
}

.showDayModalFooter {
  display: flex;
  justify-content: space-between;
  min-height: 50px;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
}

.closeShowDayBtnTxt {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
}

.closeShowDayBtnTxt:hover {
  background-color: #214978;
}

.showDayAddLeaveBtn {
  background-color: #0077ff;
  border: 1px solid #0469de;
  color: white;
  font-weight: 500;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  cursor: pointer;
  /* animation-name: zoomBtnIn !important; */
  /* animation-duration: 0.1s !important; */
  /* margin-top: 7px; */
  /* margin-left: 9px; */
}

.showDayAddLeaveBtn:hover {
  background-color: #0469de;
}

.showDayList {
  background-color: #f4f8fe;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: auto;
  max-height: 310px;
  min-height: 90px;
}

.showDayListPHolSDay {
  background-color: #214978;
  border-radius: 5px;
  border: 1px solid #143051;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  border-left: 3px solid #143051;
  margin-top: 10px;
}

.showDayShiftUnit {
  background-color: white;
  border-radius: 5px;
  border: 1px solid #dde9f9;
  color: #859dbb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  border-left: 3px solid #214978;
  margin-top: 10px;
}

.pHolSDayTitle {
  font-weight: 500;
  margin-left: 10px;
  text-align: left;
  line-height: 15px;
  padding-top: 4px;
  max-width: 50%;
  color: #143051;
  min-width: 154px;
  padding-left: 2px;
  font-size: 13px;
}

.lineHeight18 {
  line-height: 17px;
  margin-top: -4px;
}

.showDayPholClosed {
  font-size: 11px;
  margin-right: 10px;
  color: #bbcada;
  line-height: 16px;
  text-align: right;
}

.viewPholGhost {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  padding: 4px;
  padding-left: 7px;
  padding-right: 7px;
  /* margin-top: 6px; */
  margin-right: 10px;
  min-height: 35px;
  display: flex;
  align-items: center;
  min-width: 60px;
  justify-content: center;
}

.viewPholGhost:hover {
  background-color: #bbcada;
}

.pHolSpecDayRightWithGhostDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.pHolSdayClosedSpan {
  /* font-weight:500; */
}

.showGenOfferModal {
  background-color: #f4f8fe;
  position: fixed;
  bottom: 100px;
  right: 80px;
  width: 340px;
  /* min-height: 120px; */
  border-radius: 5px;
  animation-name: sideShowGenIn !important;
  animation-duration: 0.3s !important;
  animation-timing-function: ease-out;
  border-left: 4px solid #e999eb;
  cursor: auto;
}

.showGenOfferModalMob {
  background-color: #f4f8fe;
  position: fixed;
  bottom: 0px;
  width: 100%;
  /* right: 80px; */
  /* width:340px; */
  /* min-height: 120px; */
  border-radius: 5px 5px 0 0;
  animation-name: sideShowGenUp !important;
  animation-duration: 0.2s !important;
  /* zoomies */
  animation-timing-function: ease-out;
  /* border-left: 4px solid #e999eb; */
  cursor: auto;
  border-radius:15px 15px 0 0;
}

.showGenGenBtnColours {
  background-color: #e999eb;
  border-color: #bc77be;
}

.showGenGenBtnColours:hover {
  background-color: #bc77be;
}

.showGenUnderlayFadeIn {
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
}

.showGenBtnDiv {
  display: flex;
  /* min-width: 100%; */
  max-width: 100%;
  padding-left: 14px;
  padding-right: 14px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  -webkit-backdrop-filter: blur(3px);
  /* backdrop-filter: blur(3px); */
  /* background-color: rgb(119 119 119 / 50%); */
}

@keyframes sideShowGenUp {
  from {
    bottom: -300px;
  }
  to {
    bottom: 0px;
  }
}

@keyframes sideShowGenIn {
  from {
    right: -300px;
  }
  to {
    right: 80px;
  }
}

.showGenInfotTxt {
  margin-bottom: 10px;
  text-align: center;
  /* font-size: 12px; */
  line-height: 18px;
  font-weight: 500;
  color: #143051;
  text-align: left;
  font-size: 14px;

  /* margin-top:14px; */
  /* margin-left:14px; */
  padding-right: 14px;
  /* margin-bottom:20px; */
}

.showGenName {
  color: #bbcada;
  font-weight: 500;
  border-left: #00ccff 2px solid;
  max-width: fit-content;
  padding-left: 10px;
  margin-left: 30px;
  margin-bottom: 10px;
  font-size: 14px;
}

.showGenHeader {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 14px;
  padding-right: 14px;
  margin-top: 14px;
  margin-bottom: 10px;
}

.showGenLightning {
  width: 70px;
  margin-top: 4px;
}

.unpubClick {
  cursor: pointer !important;
}

.unpubClick:hover {
  background-color: #6b8fa8;
}

.showDayShiftTimes {
  font-size: 12px;
  color: #bbcada;
}

.tilTxtSize {
  /* max-width: 80%; */
}

.tilUnitShowDay {
  /* border-left:3px solid #bbcada */
}

.showDayNone {
  color: #bbcada;
  font-weight: 500;
  margin-top: 36px;
  font-size: 14px;
}

.showDayTeamName {
  font-weight: 500;
  color: #bbcada;
  font-size: 12px;
  /* opacity: 0.8; */
}

.loadingInbetweenBalls {
  margin: 0 auto;
  position: fixed;
  top: 30%;
  left: 50%;
}

.leaveShowDayUnit {
  border-left: 3px solid #00ccff;
}

.absLeaveShowDayUnit {
  border-left: 3px solid #bbcada;
}

.border143051 {
  border-color: #143051;
}

.halfLeaveShowDay {
  background-color: #e0ebf8;
  color: #214978;
  font-size: 10px;
  border-radius: 3px;
  padding-left: 4px;
  padding-right: 4px;
}

.calTitle {
  margin-top: 10px !important;
}

.myCalPageDataBoxTitle {
  font-weight: 500;
  color: white;
  font-size: 16px;
  /* border-left: 3px solid white; */
  padding-left: 10px;
}

.myCalPageDataBoxTitleMob {
  /* font-weight:600; */
  /* color:white; */
  /* font-size:18px; */
  border-left: none;
  padding-left: 0px;
  margin-right: 22px;
}

.selCollHolder {
  margin: 0 auto;
}

.collCalUserDropdown {
  min-width: 220px;
  min-height: 35px;
  background-color: blue;
  max-width: 220px;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #143051;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 195px;
  font-weight: 500;
  font-size: 14px;
  padding-left: 10px;
  line-height: 18px;
}

.collCalUserInfoDiv {
  height: 50px;
  display: flex;
  justify-content: space-between;
  border: 2px solid #e6eaee;
  max-width: 90%;
  margin-left: 5%;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #f5f9ff;
}

.collCalTeamTitle {
  color: #214978;
  font-size: 10px;
  font-weight: 400;
  margin-top: 7px;
  margin-left: 10px;
  text-align: left;
}

.collCalTeamValue {
  font-weight: 500;
  color: #143051;
  font-size: 16px;
  margin-left: 10px;
  text-align: left;
  margin-top: -1px;
  /* max-width: 170px; */
}

.viewCollCalBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 4px;
  color: white;
  font-weight: 500;
  font-size: 12px;
  min-height: 24px;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
  /* margin-top: 10px; */
  /* margin-right: 12px; */
}

.viewCollCalBtn:hover {
  background-color: #bbcada;
}

.wklySchedPopUp {
  max-width: 12px;
  margin-left: 5px;
}

.collCalWorkingToday {
  min-width: 100px;
  cursor: pointer;
  min-width: fit-content;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 500;
  color: #859dbb;
  padding-left: 4px;
  padding-right: 4px;
  margin-top: -7px;
  margin-bottom: -1px;
}

.collCalWorkingToday:hover {
  background-color: #e0ebf8;
  border-radius: 3px;
}

.workBetTodaySpan {
  padding-left: 5px;
  color: #0077ff;
}

.colCalInfoRight {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  margin-right: 12px;
}

.checkerDiv {
  height: 35px;
  display: flex;
  justify-content: space-between;
  border-left: 2px solid #143051;
  max-width: 90%;
  margin-left: 5%;
  margin-top: 15px;
  margin-bottom: 15px;
  align-items: center;
}

.checkDivTitle {
  font-weight: 400;
  color: #516b8b;
  text-align: left;
  line-height: 18px;
  /* padding: 12px; */
  font-size: 12px;
  padding-left: 10px;
  line-height: 16px;
}

.myShPendingChLeftBorder {
  border-left: 3px solid #00ccff;
}

.pendingMgrShChangeItem {
  border-left: 3px solid #965ade;
}

.alignStartx {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap !important;
  min-height: calc(100vh - 155px);
}

.leaveLimInfoTxt {
  color: #516b8b;
  font-size: 12px;
  font-weight: 400;
  padding-left: 6%;
  padding-right: 5%;
  text-align: left;
  line-height: 16px;
  margin-top: 10px;
}

.yourPTeamIs {
  color: #0af;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6%;
  padding-right: 5%;
  text-align: left;
  margin-top: 0px;
}

.highlightSelCollDrop {
  border-color: #0cf;
}

.leaveCheckParent {
  border-top: 1px solid #bbcada;
  min-width: 90%;
  margin-left: 5%;
  margin-top: 20px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 90%;
}
.leaveCheckFlexUnit {
}

.leaveCheckStartTitle {
  font-size: 12px;
  font-weight: 500;
  color: #516b8b;
  text-align: left;
  margin-bottom: 10px;
  margin-left: 8px;
}

.leaveCheckEndTitle {
  font-size: 12px;
  font-weight: 500;
  color: #516b8b;
  text-align: right;
  margin-bottom: 10px;
  margin-right: 8px;
}

.invalidLimitDates {
  pointer-events: none;
  opacity: 0.4;
}

.checkLimitBtn {
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 78px;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #0bf;
  border: 1px solid #0af;
  /* margin-left: 5%; */
  /* margin-top: 13px; */
}

.checkLimitBtn:hover {
  background-color: #0af;
}
.limitMagniImg {
  max-width: 12px;
  margin-left: 6px;
}

.loadinglimBlueLoader {
  max-width: 50px;
  margin-top: 10px;
  margin-left: 6%;
}

.limitCheckDateInput {
  border-radius: 5px;
  border: 1px solid #e6eaee;
  background-color: #fff;
  color: #496375;
  min-height: 35px;
  font-weight: 500;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 8px;
  padding-top: 0px;
  letter-spacing: 1px;
  max-width: 157px;
  min-width: 157px;
  background: url(/static/media/calicongrey.9c7e1aba.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 127px;
  cursor: text;
}

.checkLimsArrDiv {
  min-width: 100% !important;
}

.checkParent {
  /* background-color: blue; */
  /* overflow: auto; */
  /* min-height: calc(100vh - 150px);  */
}

.checkBtnAndResultDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 90%;
  margin-left: 5%;
  margin-top: 16px;
}

.checkExceedBtn {
  font-weight: 500;
  background-color: #e3e8ee;
  color: #4f566b;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  border: 1px solid #bbcada;
  font-size: 14px;
  border-left: #ba0000 3px solid;
  cursor: pointer;
}

.checkExceedBtn:hover {
  background-color: #d7dee5;
}

.exceedsLimitsPopUp3 {
  max-width: 16px;
  margin-left: 4px;
}

.pHolDataRowTitle {
  margin-left: -6px;
}

.exceedsNoLimits {
  font-size: 14px;
  font-weight: 500;
  color: #516b8b;
  display: flex;
  align-items: center;
  /* border-right: 3px solid #6db451; */
  /* padding-right: 12px; */
}

.noExceedTickImg {
  max-width: 20px;
  margin-left: 10px;
}

.colour00aaff {
  color: #00aaff !important;
}

.staffCalJTcolumn {
  border-right: 3px solid #bac5d3;
  max-width: 120px;
  padding-bottom: 16px;
}

.staffCalUsrBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* margin-top: 8px; */
  height: 38px;
  /* border-radius: 4px 0 0 4px; */
  padding-left: 2px;
  cursor: pointer;
  border-bottom: 1px solid #dde9f9;
}

.staffCalUsrBox:hover {
  background-color: #dde9f9;
}
.staffCalProPic {
  min-width: 30px;
  max-width: 30px;

  height: 30px;
  object-fit: cover;
  border-radius: 3px;
  /* border:1px solid #214978 */
}

.staffCalUsrName {
  font-size: 12px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: left;
  font-weight: 500;
  color: #143051;
  line-height: 13px;
}

.staffCalJtDivider {
  min-height: 2px;
  min-width: 100%;
  background-color: #95aaba;
  opacity: 0.15;
  margin-top: 10px;
}

.staffCalJtDivider2 {
  min-height: 2px;
  min-width: 100%;
  background-color: #95aaba;
  opacity: 0.15;
  margin-top: 10px;
}
.staffCalBody {
  min-width: calc(100% - 133px);
  max-width: calc(100% - 133px);
  padding-bottom: 8px;
  overflow-x: auto;
  padding-right: 20px;
  padding-top: 5px;
}

.staffCalUsrSnakeDiv {
  display: flex;
  min-height: 38px;
  /* padding-top:7px; */
  align-items: center;
  padding-left: 10px;
  border-top: 1px solid #dde9f9;
  /* background-color: yellow; */
  /* border-top:1px solid red; */
}

.staffCalUsrSnakeDivDayStrip {
  display: flex;
  min-height: 36px;
  align-items: center;
  padding-left: 10px;
}

.staffCalTeamSnakeBlock {
  min-width: 40px;
  color: #516b8b;
  font-weight: 500;
  font-size: 14px;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: -2px; */
  cursor: pointer;
  /* border-radius:3px; */
}

.staffCalTeamSnakeBlock:hover {
  /* background-color: #e8eef3; */
}

.staffCalJtItem {
  min-width: fit-content;
}

.paddingLeft10 {
  padding-left: 6px;
  padding-right: 10px;
}

.paddingLeft4 {
  padding-left: 4px;
}

.staffCalDayLtrItem {
  /* text-transform: uppercase; */
  cursor: default;
  color: #a1b7cf;
  cursor: pointer;

}

@media only screen and (min-width: 820px) {

.staffCalDayLtrItem:hover {
  background-color: #fff !important;
}

.closedStaffCalBlockBg:hover {
  background-color: #d7dae4;
}

.isMgrClosedStaffCalBlockBg:hover {
  background-color: #e8eeff;
}
.closedHoverer:hover {
  background-color: #d5ddf2;
}
}

.closedStaffCalBlockBg {
  background-color: #d7dae4;
  border-radius: 0px;
}


.closedStaffCalBlockBgPhol {
  background-color: #e3e8f7;
  border-radius: 0px;
  cursor: default;
}

.closedHoverer {
  cursor: pointer;
}


.hoveredUserCalBg {
  background-color: #fff;
}
.pHolsRowA1 {
  min-height: 40.5px;
  max-height: 40.5px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  background-color: #504588;
    color: white;
    /* min-height: 30px; */
    /* max-height: 30px; */
    /* margin-top: 9px; */
    /* margin-bottom: 0px; */
    border-radius: 5px 0 0 5px;
    border-top: 2px solid #143051;
    border-bottom: 2px solid #143051;
    border-left: 2px solid #143051;
    display:flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding-left: 10px;
    padding-right: 8px;
  
}

.limEnableNoComms {
min-height:66.5px;
max-height:66.5px;

}
.pHolsRowA1LimitEnabled {
  /* background-color: yellow; */
  min-height:40.5px !important;
  max-height:40.5px !important

}
.pHolStaffCalRow {
  display: flex;
  min-height: 10px;
  align-items: center;
  margin-left: 10px;
  background: #e5e9f0;
  padding-top: 2px;
  padding-bottom: 2px;
}

.phSnakeItem {
  min-width: 40px;
  font-size: 8px;
  font-weight: 500;
  color: #8ea7c5;
  min-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid #8ea7c5;
  border-radius: 3px;
  cursor: pointer;
}

.phSnakeItem:hover {
  background-color: #f4f8fe;
}

.noItemsSnakeBlock {
  /* cursor: default; */
  /* cursor: copy; */
  /* pointer-events: none; */
}


.reqExistsSnakeBlock {
  background-color: #00ccff !important;
  color: white;
  border-top: 1px solid #00b9fb;
  border-bottom: 1px solid #00b9fb;
}

.hoveredStaffCalLeave {
  background-color: #85dafc !important;
}

.reqExistsSnakeBlockAbsence {
  background-color: #516b8b !important;
  border-top: 1px solid #435b78 !important;
  border-bottom: 1px solid #435b78 !important;

}

.hoveredStaffCalAbsence {
  background-color: #6484aa !important;
}

.firstOfSeq {
  border-radius: 5px 0 0 5px;
  border-left: 1px solid #00b9fb;
}


.lastOfSeq {
  border-radius: 0px 5px 5px 0px;
  border-right: 1px solid #00b9fb;
}

.soloSeq {
  border-radius: 5px;
  border: 1px solid #00b9fb
}

.soloSeqAbs {
  border-radius: 5px;
  border: 1px solid #435b78
}



.firstOfSeqAbs {
  border-left: 1px solid #435b78;

}

.lastOfSeqAbs {
  border-right: 1px solid #435b78;

}

.todayStaffCalSnake {
  background-color: #eef8ff;
  /* border-left: 2px solid #dde9f9; */
  /* border-right: 2px solid #dde9f9; */
}

.todayStaffCalSnake:hover {
  /* background-color: white  !important; */
}
.staffCalTypeDropdown {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 10px;
  text-align: left !important;
  max-width: 230px;
  min-width: 230px;
  line-height: 26px;
  height: 35px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border-color: #2c7eb9;
  border: 1px solid #e6eaee;
  /* background-color: #0bf; */
  background-color: white;
  color: #143051;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 204px;
}

.staffCalTypeDropdown:hover {
  border-color: #d7dfe9;
}

.staffCalKeyDiv {
  display: flex;
  justify-items: space-between;
  align-items: center;
  margin-right: 20px;
}

.staffCalKeyItem {
  display: flex;
  justify-items: flex-start;
  align-items: center;
  color: #bbcada;
  min-height: 22px;
  padding-left: 6px;
  padding-right: 6px;
  cursor: pointer;
}

.staffCalKeyItem:hover {
  background-color: #ecf0f3;
  min-height: 22px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 3px;
  color: #214978;
}

.staffCalKeyItem:nth-child(2) {
  margin-left: 16px;
}
.keyItemBlobAbsence {
  min-width: 8px;
  min-height: 8px;
  background-color: #516b8b;
  border-radius: 2px;
}

.keyItemBlobLeave {
  min-width: 8px;
  min-height: 8px;
  background-color: #00ccff;
  border-radius: 2px;
}

.keyItemTxt {
  font-weight: 500;
  font-size: 12px;
  margin-left: 6px;
}

.colour516b8b {
  color: #516b8b !important;
}

.marginLeft5px {
  margin-left: 5px;
}

.staffCalCommentIcon {
  max-width: 9px;
  margin-right: 5px;
  margin-top: 1px;
}

.teamCalHolder {
  display: flex;
  /* background-color: blue; */
  min-width: 100%;
  max-width: 100%;
}

.marginLeft2pc {
  margin-left: 2% !important;
  min-width: 100%;
}

.absBoxTeamCal {
  min-height: calc(100vh - 192px);
  max-height: calc(100vh - 192px);
  align-items: flex-start;
  border-top: 2px solid #e6eaee;
  margin-top: 4px;
  padding-top: 0px;
}

.calCommDiv {
  display: flex;
  font-size: 11px;
  line-height: 12px;
}

.colour0077ff {
  color: #0077ff !important;
}

.meUserTeamCal {
  background-color: #ffffff;
  border-bottom: 1px solid #c0e8ff;
  border-top: 1px solid #c0e8ff;
  border-left: 1px solid #c0e8ff;
  /* border-top:1px solid #143051; */
  /* border-bottom:1px solid #143051; */
}

.meUserTeamCal:hover {
  background-color: #f4f9ff !important;
}

.meTeamCalRow {
  background-color: #ffffff !important;
  border-bottom: 1px solid #c0e8ff;
  border-top: 1px solid #c0e8ff;
  border-right: 1px solid #c0e8ff;
  border-radius: 0 4px 4px 0;
}

.name-s {
  font-size: 10px !important;
  /* margin-left: -5px; */
  margin-right: 5px;
}



.staffCalDesktopTitle {
  margin-top: 6px;
}

.desktopStaffCalTeamsMenu {
  margin-top: -7px;
}

.c22 {
  margin: 35px auto;
}

.mobTeamSelectLeft {
  position: fixed;
  z-index: 999;
  /* left:17px; */
  top: 103px;
}

.viewTeamDayRow {
  /* background-color: blue; */
  max-height: 40px;
  min-width: fit-content;
  display: flex;
  align-content: center;
  padding-left: 10px;
  display: none;
}

.viewTeamDivItem {
  
    max-height: 22px;
    min-height:22px;
    
    margin-left: 4px;
    margin-right: 4px;
    min-width: 32px;
    max-width: 32px;
    background-color: #214978;
    border: 1px solid #143051;
    border-radius: 3px;
    margin-bottom: 4px;
    cursor: pointer;
    line-height: 9px;
    padding-top: 1px;
    padding-bottom: 1px;
    color: white;
    font-weight: 500;
    font-size: 9px;
    display: flex;
    align-items: center;
    padding-left:3px;
    padding-right:3px;
}

.viewTeamDivItem:hover {
  background-color: #143051;
}

.viewTeamDayPopUpImg {
  max-width: 12px;
}

.viewTeamDayUserRow {
  /* background-color:orange */
}

.ssss {
  /* background-color: orange !important; */
}

.staffCalBreakTeams {
  margin-top: 0px !important;
  margin-left: -1px;}

  .staffCalBreakTeamsRota {
    margin-top:-6px;
  }
.viewTeamJTrow {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  /* background-color: blue; */
  border-bottom: 2px solid #143051;
}

.viewTeamJTrow:last-child {
  border-bottom: none;
}

.viewTeamDayUserRowJt {
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 30px;
  color: #b764eb;
  margin-bottom: 8px;
  margin-top: 4px;
}

.viewTeamJtUserRow {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
  border-bottom: 1px solid #bbcada;
  padding-top: 4px;
  padding-bottom: 10px;
  padding-left: 4px;
  /* justify-content: space-between; */
}

.viewTeamJtLeftUser {
  display: flex;
  align-items: center;
  min-width: 130px;
  max-width: 130px;
}

.viewTeamDayProPicImg {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 3px;
}

.viewTeamUserName {
  color: #143051;
  font-weight: 500;
  font-size: 12px;
  text-align: left;
  line-height: 16px;
  margin-left: 4px;
}

.viewTeamInitials {
  color: white;
  background-color: #214978;
  border: 1px solid #143051;
  border-radius: 3px;
  min-width: 30px;
  max-width: 30px;
  font-size: 12px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  cursor: pointer;
  padding-bottom: 1px;
}

.viewTeamInitials:hover {
  background-color: #143051;
}

.viewTeamJtRightUserItems {
  border-left: 1px solid #e0ebf8;
  margin-left: 4px;
  padding-left: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: flex-start; */
  /* display: flex; */
  /* align-items: center; */
}

.viewTeamItemShift {
  background-color: #fff;
  border: 1px solid #bbcada;
  border-radius: 4px;
  color: #143051;
  font-weight: 500;
  text-align: left;
  font-size: 12px;
  padding: 5px;
  padding-right: 20px;
  padding-left: 7px;
  cursor: pointer;
  margin-top: 4px;
  /* padding-bottom:1px; */
  /* padding-top:4px; */
}

.viewTeamItemShift:hover {
  border: #214978 2px solid;
  padding: 4px;
  padding-right: 19px;
  padding-left: 6px;
}

.viewTeamShiftReq {
  background-color: #0077ff;
  color: white;
  border: #214978 2px solid;
  padding: 4px;
  padding-right: 19px;
  padding-left: 6px;
}

.viewTeamShiftReq:hover {
  border: #214978 2px solid;
  padding: 4px;
  padding-right: 19px;
  padding-left: 6px;
  background-color: #0077cc;
}

.disableOpenReq {
  cursor: default;
  pointer-events: none;
}

.disableOpenReq:hover {
  background-color: #fff;
  border: 1px solid #bbcada;
  border-radius: 4px;
  color: #214978;
  font-weight: 500;
  text-align: left;
  font-size: 12px;
  padding: 5px;
  padding-right: 20px;
  padding-left: 7px;
  cursor: pointer;
  margin-top: 4px;
}

.viewTeamRotaClock {
  max-width: 10px;
  margin-right: 4px;
  opacity: 0.5;
}

.viewTeamRotaBreak {
  max-width: 10px;
  margin-right: 4px;
  opacity: 0.5;
}

.viewTeamItemRowParent {
  margin-top: 4px;
}

.viewTeamItemRow {
  display: flex;
  align-items: center;
  font-size: 10px;
  margin-bottom: 1px;
}

.viewTeamItemTitle {
  font-weight: 500;
  padding-left: 4px;
  border-left: 2px solid #143051;
}

.viewTeamNil {
  color: #bbcada;
  font-weight: 500;
  margin-left: 9px;
  font-size: 12px;
  text-align: left;
  line-height: 30px;
}

.whiteBorderLeft {
  border-left: 2px solid white;
  padding-left: 4px;
}

.myShiftChangeArrUnit {
  margin-bottom: 8px;
  background-color: #f4f8fe;
  border-radius: 5px;
  border: 1px solid #e6eaee !important;
}

.pointerUpcItem {
  cursor: pointer;
}

.pointerUpcItem:hover {
  border-color: #456c9b !important;
}

/* 
.noScroll {

} */

.staffAbsHeader {
  margin-top: -9px;
  min-height: 35px;
}

.desktopStaffAbsHeader {
  margin-top: 7px;
}

.myAbsBtnTxt {
  margin-top: -1px;
}

.myHoursStatsImgOnly {
  margin-right: 5px;
}

.absTeamHeaderDropdown {
  margin-top: 3px;
}

.ndStRdSpan {
  font-size: 7px;
  margin-top: -5px;
}

.ndStRdSpan2 {
  font-size: 10px;
  margin-right: 3px;
}

.ndStRdSpan3 {
  font-size: 10px;
  margin-right: 3px;
  margin-top:-7px;
}
.todayWhiteBorder {
  border: 2px solid white !important;
}

.todayWhiteBorder:hover {
  border: 2px solid #90a7bf !important;
}

.offerClockOn {
  color: #143051;
  font-weight: 500;
  font-size: 18px;
  margin-top: 8px;
  margin-bottom: 4px;
  /* line-height:22px; */
}

.clockOnNowBtn {
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: 78px;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #07f;
  border: #0469de 1px solid;
  font-weight: 500;
  margin-top: 10px;
}

.clockOnNowBtn:hover {
  background-color: #036ce4;
  margin-top: 10px;
}

.marginBottom4 {
  margin-bottom: 4px !important;
}

.timesheetStartDateInput {
  border-color: #e6eaee !important;
  margin-right: 10px !important;
  color: #6a8bb1 !important;
  font-size: 16px !important;
  background-position-x: 126px !important;
  min-width: 161px !important;
  max-width: 161px !important;
}
.timesheetStartDateInput:hover {
  border-color: #d7dfe9 !important;
}

.marginTopMinus12 {
  margin-top: -12px !important;
}

.timesheetDateDay {
  font-size: 12px;
  font-weight: 500;
  color: #bbcada;
  margin-right: 40px;
  margin-top: 10px;
  margin-bottom: -16px;
  display: flex;
}

.tsDayLine {
  max-width: 6px;
  margin-left: 3px;
  margin-top: 6px;
}

.flexCenter {
  justify-content: center !important;
}

.flexCenter2 {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.understoodBtn {
  min-width: 100% !important;
  color: white !important;
}

.editTimeSpecificBtn {
  color: white;
  font-weight: 500;
  font-size: 12px;
  margin-left: 18px;
  margin-top: -1px;
  cursor: pointer;
  background-color: #d8c3e2;
  max-height: 24px;
  max-width: 114px;
  /* margin-top: 8px; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 6px;
  padding-right: 2px;
  border-radius: 4px;
  margin-bottom: 7px;
  border: 1px solid #d8c3e2;
  min-width: 114px;
  min-height:26px;
  /* color:blue; */
  line-height:10px
  /* font-size:49px */
}

.editTimeSpecificBtn:hover {
  background-color: #e6c8f4;
}

.specTimesModeImg {
  max-width: 18px;
  margin-left: 2px;
}

.timePickerDropper {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 72px;
  min-width: 72px;
  line-height: 26px;
  height: 35px;
  /* border: 2px solid #e6eaee; */
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 2px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 52px;
}

.timePickerDropper:hover {
  border-color: #d7dfe9;
}

.timesheetBody {
  /* background-color: yellow; */
  /* border-top: 4px solid #96e578; */
  border-top: 4px solid #bbcada;

  border-radius: 6px 6px 0 0;
  max-width: 90%;
  margin-left: 5%;
  max-height: calc(100vh - 384px) !important;
  overflow-y: auto;
}

.viewTimesheetBody {
  /* background-color: yellow; */
  /* border-top: 4px solid #96e578; */
  /* border-top: 4px solid #bbcada; */
  border-top: 1px solid #143051;
  /* border-radius: 6px 6px 0 0; */
  max-width: 90%;
  margin-left: 5%;
  max-height: calc(100vh - 404px) !important;
  overflow-y: auto;
}

.timesheetDsItem {
  /* background-color:blue; */
  cursor: pointer;
  min-height: 70px;
  padding-bottom: 20px;
  border-bottom: 2px solid #cbd7e6;
}

.timesheetDsItem2 {
  min-height: 70px;
  padding-bottom: 20px;
  border: 2px solid #214978;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 10px;
  background-color: #f8f7ff;
}
.timesheetDsItem:hover {
  background-color: #edf3f9;
}

.timesheetDsItem2User {
  background-color: #f4f8fe;

}

.timesheetDsItem2User:hover {
  background-color: #f4f8fe;

}

.timesheetDsItem2:last-child {
  /* border-bottom: none !important; */
  margin-bottom: 10px;
}

.timesheetActiveDs {
  background-color: white;
  cursor: default;
  min-height: 70px;
}

.timesheetActiveDs:hover {
  background-color: white;
}

.tsPower {
  max-width: 28px;
  min-width: 28px;
  background-color: #96e578;
  border: 1px solid #88d869;
  border-radius: 5px;
  /* margin-left: -4px; */
  cursor: pointer;
}
.tsPower:hover {
  background-color: #88d869;
}

.tsPowerOff {
  background-color: #bbcada;
  border-color: #8ea7c5;
}

.tsPowerOff:hover {
  background-color: #8ea7c5;
}

.tsItemHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  margin-top: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
}

.tsItemTitle {
  color: #214978;
  font-weight: 500;
  font-size: 14px;
  border-left: 2px solid #214978;
  padding-left: 10px;
  margin-bottom: -4px;
  min-height: 24px;
  padding-top: 0px;
}

.tsClocksTitle {
  font-weight: 400;
  color: #bbcada;
  font-size: 12px;
  text-align: left;
  margin-top: 15px;
  margin-left: 12px;
}

.tsClockItem {
  min-height: 50px;
  background-color: #f4f8fe;
  max-width: 78%;
  max-width: 90%;
  margin-left: 12px;
  margin-top: 3px;
  margin-bottom: 12px;

  text-align: left;
  padding-left: 6px;
  font-weight: 400;
  font-size: 12px;
  color: #bbcada;
  border-left: 2px solid #e6eaee;
  padding-left: 9px;
  padding-top: 6px;
  border: 2px solid #e6eaee;
  border-radius: 5px;
  padding-bottom: 4px;
  padding-right: 10px;
}
.tsSchedItem {

}

.tsClocks {
}

.tsClockOnVal {
  font-size: 14px;
  font-weight: 500;
  margin-top: 2px;
}

.clockAlreadyRecords {
  text-align: left;
  font-size: 11px;
  color: #bbcada;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-weight: 500;
  margin-left: 12px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-left: 2px solid #00aaff !important;
  padding-left: 8px;
}

.inactiveTsItemTitle {
  color: #bbcada;
}

.decClock {
  color: #e89292 !important;
}

.appClock {
  color: #a8cc9a !important;
}

.otherTeamShiftBox {
  max-width: 114px !important;
  min-width: 114px !important;
  padding-left: 8px !important;
  background: none !important;
  box-shadow: none !important;
  opacity: 1;
  border: 1px #8ea7c5 dotted !important;
  cursor: default;
  pointer-events: none;

  border-spacing: 4px !important;
}

.otherTeam {
  color: #8ea7c5 !important;
  line-height: 10px;
  margin-top: 3px;
  min-width: fit-content;
}
.otherTeamTimes {
  color: #8ea7c5 !important;
}

.myCalDropAndRightBtnContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 136px;
}

.outstandingWithTagsHeight {
  /* background:pink;   */
  min-height: 91px !important;
}

.tagsAndOutstandingCh {
  margin-top: -74px;
}

.unlinkedBorder {
  border: 2px #ba0000 dashed !important;
}

.genMyCal {
  min-width: 90%;
  margin-left: 5%;
  border-radius: 6px;
  padding-top: 15px;

}

.myCalItem {

  min-height: 50px;
  display: flex;
}

.myCalItemDs {
  background-color: white;
  min-width: 60px;
  min-height: 100%;
  font-weight: 500;
  border-bottom: 1px solid #e0ebf8;
  padding-top: 10px;
}

.myCalItemDsFirst {
  border-radius: 6px 0 0 0;
}

.myCalItemDsDay {
  color: #bbcada;
  font-size: 12px;
}

.myCalItemDsNum {
  color: #bbcada;
  margin-bottom: 5px;
  font-size: 24px;
}

.myCalItemList {
  border-left: 1px solid #e0ebf8;
  border-bottom: 1px solid #e0ebf8;
  min-width: calc(100% - 60px);
  padding-bottom: 10px;
}

.myCalItemAvailRow {
  background-color: white;
  min-width: 91%;
  max-width: 91%;
  margin-left: 3%;
  border-radius: 5px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  border: 1px solid #e9f1fa;
}
@media only screen and (min-width: 820px) {
  .noItemsSnakeBlock:hover {
    background-color: #d7eaff;
  }
  
  .myJtUserShiftBox:hover {
    padding-left: 2px !important;
    padding-right: 2px !important;
    background-color: #6274c5;
  }
  .myJtUserShiftBoxLeave:hover {
    background: #f4f8fe;
    padding-right:4px !important
  }
.myCalItemAvailRow:hover {
  border-color: #bbcada;
}

.unavailItemCal:hover {
  background-color: #f2e8e8;
}

.availItemCal:hover {
  background-color: #dcf1d3;
}
.noAvailSubmitted:hover {
  background-color: #f2f5fb;
}

}
.specPHolRow {
  background-color: #7fb0e4;
  color: white;
  text-align: left;
  justify-content: flex-start;
  padding-left: 10px;
}
.specPHolRow:hover {
  box-shadow: none !important;
  border-color:#e9f1fa !important
}

.unavailItemCal {
  background-color: #f9efef;
  color: #ba0000;
  min-width: 98%;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}




.availItemCal {
  background-color: #e1fad7;
  min-width: 98%;
  border-radius: 3px;
  color: #5a9e3f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}


.noAvailSubmitted {
  background-color: #f4f8fe;
  min-width: 98%;
  border-radius: 3px;
  color: #bbcada;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;
}
.myCalAvailItemImg {
  max-width: 10px;
  margin-left: 5px;
}

.availItemIllus {
  display: flex;

  justify-content: space-between;
  font-size: 10px;
  font-weight: 500;
}

.myCalUnvailTxt {
  margin-left: 8px;
  text-align: left;
  max-width: 140px;
  line-height: 9px;
}

.availItemTimeStrip {
  font-weight: 500;
  font-size: 10px;
  text-align: right;
  margin-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.availItemTimeStripRow {

}

.myCalDsClosed {
  opacity: 0.5;
}

.greyScale {
  -webkit-filter: grayscale(100%); /* Safari and Chrome */
  filter: grayscale(100%);
  opacity: 0.5;
}

.greyScaleHalf {
  -webkit-filter: grayscale(100%); /* Safari and Chrome */
  filter: grayscale(50%);
  opacity:1;
}

.marginTop7px {
  margin-top: 7px !important;
}

.x88 {
  flex-direction: column;
}

.removeFlex {
  display: block !important;
  min-height: 100px;
  max-width: 380px !important;
  min-width: 300px;
  float: right;

}

.showFilterCalBoxDesktop {
  min-height: calc(100vh - 307px) !important;
  max-height: calc(100vh - 307px) !important;
}

.showFilterCalBoxMob {
  min-height: calc(100vh - 366px) !important;
  max-height: calc(100vh - 366px) !important;
}

.availBtn {
  border: 1px solid #364c66 !important;
  background-color: #516b8b !important;
}

.borderRightNone {
  border-right: none !important;
}

.filtSplitContainer {
  background-color: blue;
}

.availFiltInactive {
  background-color: #516b8b;
  min-width: 7px;
  min-height: 7px;
  border-radius: 2px;
  margin-top: -20px;
  margin-right: -10px;
  margin-left: 2px;
}

.myCalShiftInner {
  display: flex;
  min-width: 98%;
  justify-content: space-between;
  align-items: center;
}
.availItemShiftLeft {
  /* background-color: blue; */
  display: flex;
  align-items: center;
}

.availItemShiftRight {
  margin-right: 10px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.myCalColourLightGreen {
  background-color: #bcdabb;
  min-width: 4px;
  border-radius: 5px;
  min-height: 35px;
  margin-right: 0px;
  margin-left: 5px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.myCalShiftTitle {
  font-weight: 500;
  color: #143051;
  font-size: 14px;
  text-align: left;
  padding-left: 4px;
  line-height:22px
  /* text-transform: capitalize; */
}
.myCalShiftTypeAndTags {
  margin-left: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 10px;
}

.calItemTagsContainer {
  display: flex;
  margin-top: -3px;
  padding-left: 8px;
}

.x197378124 {
  padding-left:0px
}
.marginLeft5 {
  margin-left: 5px !important;
}

.itemBoxShadow {
  cursor: pointer;
}

.myCalItemAvailRow:hover {

}

.availItemShiftTimesTop {
  color: #214978;
  font-size: 12px;
  font-weight: 500;
  text-align: right;
  margin-top: 2px;
  max-width: 104px;
  line-height: 13px;
}

.availItemShiftTimesLower {
  color: #bbcada;
  font-size: 11px;
  font-weight: 500;
  text-align: right;
  margin-top: 0px;
}

.myCalItemNote {
  max-width: 13px;
  margin-left: 3px;
  margin-bottom: -2px;
}

.orangeBg {
  background-color: #bbcada !important;
}

.myCalOpensArr {
  border-bottom: 1px solid #e0ebf8;
  padding-bottom: 10px;
  min-width: 90%;
  max-width: 90%;
  margin-left: 10%;
}

.myCalOpensAvailBtn {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
  max-width: 94%;
  margin-left: 3%;
  margin-top: 10px;
  border-radius: 5px;
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid #e2ebf5;
  cursor: pointer;
  color: #143051;
}

.openDownToggle {
  max-width: 8px;
  margin-right: 4px;
}

.rotateDownArr {
  transform: rotate(90deg);
}

.myCalOpenDiv {
  display: flex;
  align-items: center;
}

.myCalOpenLight {
  background-color: #bbcada;
  min-width: 4px;
  min-height: 20px;
  border-radius: 5px;
  margin-right: 8px;
}
.claimedLight2 {
  background-color: #0af !important;
}
.openClaimed {
  background-color: #00ccff;
  border: 1px solid #0af;
  color: #fff;
  font-weight: 500;
  font-size: 10px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 3px;
}

.marginLeftMinus5 {
  margin-left: -5px;
  margin-top: 2px;
  font-size: 10px;
}

.myCalLeaveInner {
  display: flex;
  min-width: 98%;
  justify-content: space-between;
  align-items: center;
  background-color: #e2f5ff;
  border-radius: 3px;
}

.annLeavePlaneIcon {
  max-width: 18px;
  margin-left: 8px;
  margin-right: -2px;
}

.myCalReqFirstHalfOff {
  background: rgb(226, 245, 255);
  background: linear-gradient(
    110deg,
    rgba(226, 245, 255, 1) 50%,
    rgba(255, 255, 255, 1) 50%
  );
}

.myCalReqLastHalfOff {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    250deg,
    rgba(226, 245, 255, 1) 50%,
    rgba(255, 255, 255, 1) 50%
  );
}

.absInnerFull {
  background-color: #d5dee3;
}

.myCalReqFirstHalfOffAbs {
  background: rgb(226, 245, 255);
  background: linear-gradient(
    110deg,
    rgba(213, 222, 227, 1) 50%,
    rgba(255, 255, 255, 1) 50%
  );
}

.myCalReqLastHalfOffAbs {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    250deg,
    rgba(213, 222, 227, 1) 50%,
    rgba(255, 255, 255, 1) 50%
  );
}

.pendingCalItem {
  border: 2px dashed #bbcada;
}
.pendingCalItem:hover {
  border: 2px dashed #8f9dab
}
.addTimesheetItemAfterClock {
  background-color: #07f;
  border: #0469de 2px solid;
  border-radius: 5px;
  max-width: fit-content;
  min-width: fit-content;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 3%;
  margin-bottom: 20px;
  cursor: pointer;
  margin-top: 14px;
  padding-left: 8px;
  padding-right: 8px;
}

.addTimesheetItemAfterClock:hover {
  background-color: #0469de;
}

.addIconTs {
  max-width: 10px;
  margin-left: 4px;
}

.addHrsbtn {
  margin-top: 14px;
}

.tsAddInputDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 17px;

  min-width: 100%;
}

.tsAddInputRow {
  display: flex;
  padding-left: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.tsAddInputCol {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 6px;
}

.tsAddInputTitle {
  font-size: 12px;
  font-weight: 500;
  color: #143051;
  margin-left: 2px;
  margin-bottom: 5px;
}

.tsAddInputTime {
  background-color: #fff;
  width: 77px;
  max-width: 77px;
  line-height: 30px;
  padding-top: 0px;
  height: 35px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 16px;
  font-weight: 500;
  cursor: text;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tsBrkInput {
  background-color: #fff;
  max-width: 60px;
  line-height: 35px;
  height: 35px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 0.9em;
  font-weight: 500;
  padding-top: 2px;
}

.tsAddInputUnpaidSpan {
  color: #bbcada;
  font-size: 10px;
}

.tsItemHeaderSection {
  font-weight: 500;
  display: flex;
  align-items: center;
  color: #bbcada;
  font-size: 14px;
}

.tsAddInlineAddBtn {
  background-color: #07f;
  border: #0469de 1px solid;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 30px;
  border-radius: 5px;
  margin-left: 5px;
}

.tsAddInlineAddBtn:hover {
  background-color: #0469de;
}

.addMoreHrsImg {
  max-width: 15px;
}

.hrsBin {
  max-width: 12px;
}
.binColours {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  padding: 4px;
}

.binColours:hover {
  background-color: #bbcada;
}
.flexTsItems {
  display: flex;
  justify-content: space-between;
  min-width: 76px;
  flex-direction: row;
  margin-left: 5px;
}

.withinTxt {
  color: #ba0000 !important;
}

.colourAuto {
  color: inherit;
}

.saveAndSubmitContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.submitTsBtn {
  color: white;
  background-color: #07f;
  border: #0469de 1px solid;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 60px;
  justify-content: center;
  font-weight: 500;
  margin-right: 10px;
}

.submitTsBtn:hover {
  background-color: #0469de;
}
.saveTsBtn {
  color: white;
  background-color: #0bf;
  border: 1px solid #0af;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 60px;
  justify-content: center;
  font-weight: 500;
  margin-right: 10px;
  margin-left:10px;
}

.saveTsBtn:hover {
  background-color: #0af;
}

.tsClockOnTitle {
  margin-bottom: 5px;
  border-bottom: 1px solid #e1e2e3;
  padding-bottom: 4px;
  margin-right: 10px;
  font-size: 10px;
  min-width: 17px;
  min-height: 30px;
  padding-right: 16px;
  margin-top: 3px;
  max-width: 57px;
}

.tsRateTitle {
  min-height: 15px;
margin-left:6px;
}

.tsRateVal {
  color: #143051;
  font-size: 14px;
  font-weight: 500;
  margin-left: 6px;
  margin-bottom: 5px;}
/* .tsAddInputMetaTitle {
    font-weight:600;
    font-size:12px; 
    color: #bbcada;
  } */

.tsAddInputMetaType {
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  padding-left: 5px;
  text-align: left !important;
  max-width: 83px;
  min-width: 83px;
  line-height: 22px;
  height: 35px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 2px solid #eef2fa;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 63px;
  margin-top: 5px;
}

.tsAddInputMetaNote {
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  padding-left: 5px;
  text-align: left !important;
  max-width: 138px;
  min-width: 138px;
  line-height: 22px;
  height: 35px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  display: flex;
  /* cursor: pointer; */
  border: 2px solid #eef2fa;
  background-color: white;
  color: #6a8bb1;
  margin-top: 5px;
  margin-left: 6px;
  cursor: text;
}

.tsAddInputMetaNote::placeholder {
  color: #bbcada;
  font-size: 12px;
}

.metaRow {
  margin-bottom: 10px;
}

.x9101 {
  margin-top: 3px;
  margin-left: -9px;
}

.firstTsAddBtn {
  /* margin-top: 5px; */
  /* margin-left: 10px; */
}

.absColBorder {
  /* border-left: 2px dotted #bbcada; */
}

.mobAbsenceCol2 {
  padding-left: 6px !important;
}

.colourBBCADA {
  color: #bbcada !important;
}

.nonPrimProPic {
  /* opacity: 0.4; */
  /* filter: grayscale(100%); */
  /* display:none; */
}

.nonPrimLeaveShiftBox {
  background-color: #496375 !important;
}

.nonPrimLeaveShiftBoxFirstHalfOff {
  background: linear-gradient(
    315deg,
    #496375 50%,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.25) 97%
  );
}

.nonPrimLeaveShiftBoxFirstHalfOff:hover {
  background: linear-gradient(
    315deg,
    #496375 50%,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.25) 97%
  );
}

.nonPrimLeaveShiftBoxLastHalfOff {
  background: linear-gradient(
    135deg,
    #496375 51%,
    rgba(255, 255, 255, 0.25) 51%,
    rgba(255, 255, 255, 0.25) 97%
  );
}

.nonPrimLeaveShiftBoxLastHalfOff:hover {
  background: linear-gradient(
    135deg,
    #496375 51%,
    rgba(255, 255, 255, 0.25) 51%,
    rgba(255, 255, 255, 0.25) 97%
  );
}

.noPrimsJt {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #cbd7e6; */
  color: #bac5d3;
  /* border: 1px solid #bac5d3; */
  max-height: 24px;
  margin-top: 4px;
  border-radius: 3px;
  font-size: 10px;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 500;
  margin-right: 5px;
  cursor: pointer;
}

.noPrimsJt:hover {
  color: #748cab;
}

.x9988 {
  margin-bottom: -1px !important;
  /* background-color: green; */
}

.x99881 {
  margin-bottom: 12px !important;
}

.colourOrange {
  color: #ff6441;
}

.vizSubMins {
  font-size: 10px;
  color: #fff;
  margin-top: -7px;
  border-bottom: 1px solid #fff;
  min-width: 100%;
  text-align: right;
  opacity: 0.5;
  /* margin-bottom:-5px; */
}

.vizSubMinsMob {
  text-align: left;
  margin-bottom: 5px;
  padding-bottom: 0px;
  margin-top: -1px;
  min-height: 16px;
  color: pink;
}

.vizBoxMob {
  min-height: 235px !important;
  padding-top: 20px;
  padding-bottom: 0px;
}

.myRotaDayViewBtn {
  background-color: #516b8b;
  border: 1px solid #496375;
  cursor: pointer;
  color: white;
  font-size: 12px;
margin-left:7px;
  min-width: 66px;
  padding-left: 5px;
  padding-right: 5px;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.myRotaDayViewBtnMob {
  min-width: 46px;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 8px;
}

.myRotaDayViewBtn:hover {
  background-color: #496375;
}

.dayViewCalImg {
  max-width: 10px;
  margin-right: 5px;
  /* margin-left:-1px; */
}

.myRotaPrimTeamTitle {
  text-align: left;
  color: #bbcada;
  margin-top: 9px;
  font-size: 8px;
}

.x001 {
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 10px;
  margin-top: 16px;
}

.padTop5 {
  padding-top: 5px !important;
}

.padTop3 {
  padding-top: 3px !important;
}

.pTeamNameUsr {
  /* color:red !important; */
  line-height: 10px !important;
  min-height: 30px !important;
}

.x8899 {
  margin-top: 8px;
}

.X19831 {
  background: none !important;
  border: none !important;
  padding-top: 2px;
  color: white;
}

.mobModalShoulder {
  border-radius: 20px 20px 0 0 !important;
  animation-name: moveEditTeamUserBoxUp !important;
  animation-duration: 0.1s !important;
  bottom: 0px;
  position: fixed;
  left: 0px;
  min-width: 100vw !important;
}

.timePickerDropsInput {
  margin-top: 13px;
}

.doesNotManagePTeamBoxSize {
  /* min-height: 130px;
  max-height: 130px;
  margin-top: 4px; */

  /* min-height: 156px;
  max-height: 156px;
  margin-top: -6px; */

  min-height: 150px;
  max-height: 150px;
  margin-top: -8px;
}

.extendBtnsContainerDoesNotManagePTeam {
  min-height: 128px;

  /* min-height: 156px;
  max-height: 156px; */
  min-height: 150px;
  max-height: 150px;
}

.width87 {
  min-width: 87px !important;
  max-width: 87px !important;
}

.noOpenNotes {
  color: #bbcada;
  font-size: 12px;
  margin-left: 20px;
  font-weight: 500;
  max-height: 50px;
  margin-top: -10px;
  margin-bottom: -6px;
}

.openInlineTags {
  /* background-color: yellow; */
  display: flex;
  margin-left: 16px;
  margin-top: -12px;
}

.openUnitParent {
  /* background-color: blue; */
}

.tsStripWithOpenTags {
  margin-bottom: -2px !important;
  font-size:12px;
}

.openTagsClaimersTxt {
  /* background:orange; */
  margin-bottom: 18px;
  font-size: 9px !important;
}

.tsUnitImg {
  max-width: 15px;
  min-width: 15px;
  margin-left: 10px;
}

.tsUnitImgMob {
  max-width: 15px;
  min-width: 15px;
  margin-left: 13px;
  margin-right: 10px;
}

.tsUnitLeft {
  /* border-right: 2px dotted #5a9e3f; */
  min-height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 42px;
  padding-right: 10px;
}

.tsUnitLeftNonSubmitted {
  border-right: none;
  min-height: 72px;
}

.tsUnitMiddle {
  font-weight: 500;
  text-align: left;
  min-width: calc(100% - 70px);
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
}

.tsUnitMiddleMob {
  padding-left: 8px;
  margin-left:18px
}

.tsUnitFromToSection {
  margin-right: 12px;
  /* border-left:2px solid #bbcada; */
  /* padding-left:10px; */
}

.tsUnitDsTitle,
.tsOptionTitle {
  color: #859dbb;
  font-size: 10px;
  margin-bottom: -1px;
  margin-top: 1px;
}
.x1341 {
  /* margin-left:-2px; */
  padding-left: 10px;
  border-left: 1px solid #e0ebf8;
}

.tsUnitDsValue {
  color: #214978;
  display: flex;
  font-size:14px;
  font-weight: 600;
}

.tsUnitDsValueMob {
  font-size: 16px;
}

@media only screen and (max-width: 404px) {
  .tsUnitDsValueMob {
    font-size: 14px;
  }
}

.tsUnitMidLeft {
  /* display: flex; */
  /* align-items:center */
}

.tsUnitMidRight {
  display: flex;

  align-items: center;
}

.tsOptionsContainer {
  margin-top: 20px;
  overflow-y: auto;
  max-height: 420px;
  max-width: 90%;
  margin-left: 5%;
}

.tsOptionBox {
  background-color: white;
  border: 1px solid #e6eaee;
  border-radius: 5px;
  max-width: 100%;
  /* margin-left:5%; */
  margin-bottom: 10px;
  cursor: pointer;
  text-align: left;
  display: flex;
  justify-content: space-between;
  min-height: 54px;
}

.tsOptionBox:hover {
  border-color: #d7dfe9;
}

.tsOptionTeam {
  font-weight: 500;
  color: #143051;
  /* margin-left:20px; */
}

.activeTsIcon {
  max-width: 16px;
  margin-left: 20px;
}

.tsOptionsUnitRight {
  min-width: calc(100% - 50px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}

.tsOptionValue {
  font-weight: 500;
  font-size: 14px;
  color: #214978;
  margin-top:3px;
}

.tsRightIcon {
  max-width: 12px;
  margin-left: 3px;
}

.addTsBtnLaunch {
  max-width: 150px;
  color: white;
  display: flex;
  align-items: center;
  background-color: #07f;
  border: #0469de 1px solid;
  font-size: 14px;
  border-radius: 5px;
  justify-content: space-around;
  min-height: 35px;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  float: left;
  margin-left: 5%;
  margin-top: 6px;
  margin-bottom: 12px;
  cursor: pointer;
}

.addTsBtnLaunch:hover {
  background-color: #036ce4;
}
.newTsAddIconWhite {
  max-width: 12px;
  margin-left: 5px;
}

.deleteTsBtn {
  margin-right: 10px;
  margin-left:10px
}

.positiveBtn {
  background-color: #0bf;
  border: 1px solid #0af;
}

.positiveBtn:hover {
  background-color: #0af;
}

.tsSubmittedTs {
  color: #bbcada;
  text-align: left;
  line-height: 12px;
  /* max-width: 70px; */
  font-size: 12px;
  max-height: 30px;
  font-weight: 500;
  /* padding-left:10px; */
  margin-top:3px;
  /* padding-top: 4px; */
}

.tsSubmittedTsTitleSpan {
  font-size: 10px;
}

.loadOtherTs {
  line-height: 12px;
}

.cantSeeYourTeam {
  color: #859dbb;
  font-size: 12px;
  font-weight: 500;
  /* min-height: 24px; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 4px;
  margin-bottom: 12px;
  margin-right: 20px;
}

.cantSeeYourTeam:hover {
  color: #516b8b;
}

.paddingTop0x {
  padding-top: 0px !important;
}

.x82181 {
  padding-top: 0px !important;
  margin-bottom: 30px !important;
}

.x193981 {
  margin-top: -10px !important;
  margin-bottom: -10px;
}

.tsFromTilSection {
  display: flex;
  border-bottom: 1px solid #e0ebf8;
  padding-bottom: 4px;
  /* margin-top: -3px; */
}

.tsUnitTeamName {
  color: #00aaff;
  font-size: 12px;
  margin-top: 3px;
  display: flex;
  align-items: center;
}

.submittedTsBadge {
  color: #bbcada;
  font-size: 12px;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  /* background-color: #0af; */
  margin-left: 12px;
  margin-top: 1px;
  min-height: 18px;
  padding-top: 2px;
  /* background: #dde9f9; */
}

.submittedTsBadge {
  color: #bbcada;
  font-size: 12px;
  /* border-radius: 3px; */
  /* padding-left: 5px; */
  /* padding-right: 5px; */
  /* background-color: #0af; */
  /* margin-left: 12px; */
  /* margin-top: 1px; */
  min-height: 18px;
  /* padding-top: 2px; */
  /* background: #dde9f9; */
}
.notYetSub {
  padding-left: 0px;
  padding-right: 0px;
  font-size: 10px;
  margin-right: 9px;
  padding-left: 9px;
  padding-right: 0px !important;
  padding-top: 2px !important;
  border-left: 1px solid #c3d6e3;
  border-radius: 0px;
  /* border: 1px solid #bbcada; */
  color: #bbcada;
}

.notYetSubMgr {

  font-size: 10px;
  margin-top: 1px;
  border-radius: 0px;
  color: #bbcada;
  border:none !important
}

.x28291 {
  /* background-color: blue; */
  margin-top: 1px !important;
  max-height: 30px;
  /* margin-bottom:-20px !important */
}

.x982765 {
  /* background-color: yellow; */
  min-height: 30px;
  margin-top: -10px;
  margin-bottom: -8px;
  align-items: center;
  /* max-width:240px; */
}

.timesheetPeriodDates {
  color: #bbcada;
  font-weight: 500;
}

.tsBadMob {
  /* max-width: 66px; */
  /* text-align: center; */
  line-height: 10px;
  padding-top: 5px !important;
  /* padding-bottom: 2px; */
  font-size: 10px;
  padding-left: 6px;
  /* padding-right: 2px; */
  /* margin-top: -2px;
    }

    .smallSubmitBadgeSpan {
      font-size:7px;
      /* opacity: 0.7; */
}

.clockOnOffTimesFlex {
  display: flex;
  color: #143051;
  font-weight: 500;
  font-size: 11px;
  align-items: center;
  justify-content: space-between;
  min-width: 140px;
  border-top: 1px solid #e0ebf8;
  padding-top: 3px;
}

.clockOnValTxt {
  margin-top: -2px;
  color: #0af;
  font-weight: 600;
}

.tilShiftDateAndNth {
  display: flex;
  min-width: fit-content;
}

.tilShiftDateAndNthSmaller {
  font-size:12px;
  white-space: nowrap;

}

.clockOnOffSection {
  padding-left: 2px;
}

.clockOnOffSection2 {
  border-left: #e0ebf8 1px solid;
  padding-left: 7px;
  margin-left: 5px;
}

.clockOnOffSection3 {
  border-left: #e0ebf8 1px solid;
  padding-left: 7px;
  margin-left: 5px;
  padding-right: 4px;
  min-width: 47px;
}

.teamNameColour {
  color: #0af !important;
  font-size: 12px;
  line-height:12px;
  max-width: 100px;
}

.hoursUnitRotaClockImg {
  max-width: 10px;
  margin-right: 4px;
  opacity: 0.5;
}

.hoursUnitRotaClockImgCoffee {
  max-width: 10px;
  margin-right: 3px;
  /* margin-left:12px; */
  opacity: 0.5;
}

.myHoursUnitTeamName {
  font-weight: 500;
  color: #0af;
  font-size: 12px;
  line-height: 12px;
  max-width: 100px;
  display:flex;
  align-items: center;
}

.myHoursUnitTeamNameMob {
  font-size:10px;
}

.unsubmittedTsBlob {
  max-width: 8px;
  min-width: 8px;
  max-height: 8px;
  min-height: 8px;
  background-color: #00aaff;
  border-radius: 2px;
  margin-top: -50px;
  margin-right: -13px;
}

.clockAwaitBlob {
  margin-right: -43px;
  margin-left: 6px;
}

.annLeaveBlob {
  margin-right: -13px;
  margin-left: 6px;
}

.pendingTsSplitter {
  background-color: #bbcada;
  min-width: 80%;
  min-height: 1px;
  max-width: 80%;
  margin-left: 10%;
  margin-top: 30px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.notYetSubmitTitleArr {
  margin-bottom: -8px;
}

.loadingTimesheetImg {
  max-width: 110px;
  margin-bottom: 0px !important;
  padding-top: 30px;
  padding-bottom: 30px;
}

.tsUpToTodaySpan {
  color: #bbcada;
  font-size: 12px;
  margin-left: 5px;
  margin-right: 6px;
  /* margin-top: 1px; */
}

.calDayCommentImg {
  max-width: 14px;
  margin-top: 2px;
  margin-right: 4px;
}

.commentsCal {
  font-size: 12px;
  color: #bbcada;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1px;
  margin-left: 4px;
  margin-top: -8px;
  min-height: 30px;
  cursor: pointer;
}

.commentsCal:hover {
  transform: scale(0.94);
}

.dayCommentListDiv {
  border-radius: 5px;
  margin-bottom: 7px;
  cursor: pointer;
  border: 1px solid #dde9f9;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  padding: 5px;
  padding-left:15px;
  padding-right:15px;
}

.dayCommentListDiv:hover {
  /* border-color: #214978; */
  border: 1px solid #bbcada;
  /* background-color: #f4f7f9; */
}

.dayCommentListBtn {
  text-align: left;
  font-weight: 500;
  color: #0af;
  font-size: 14px;
  margin-top:3px;
}

.dayCommentListPreview {
  text-align: left;
  font-weight: 500;
  color: #214978;
  font-size: 14px;
  margin-top:4px;
  margin-bottom:4px;
}

.comFn {
  color: #496375;
  font-weight: 500;
  font-size: 10px;
}

.subHeadSplit {
  min-width: 1px;
  min-height: 14px;
  max-height: 14px;

  background-color: #e0ebf8;
  margin-top: -6px;
}

.inlineTeamSplit {
  margin-left: 9px;
  margin-right: -9px;
}

.timesheetDataHeader {
  border-radius: 5px;
  background: #214978;
  color: white;
  border: 1px solid #143051;
  padding: 5px;
  margin-top: 15px;
  font-size: 14px;
}

.timesheetDataHeaderMgr {
  border: 1px solid #7f63bb !important;
  background-color: #9374d6;
}

.tsDataHeaderRow {
  display: flex;
  justify-content: space-between;
  min-height: 30px;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.tsDataHeaderTitle {
  font-weight: 500;
  opacity: 0.7;
  min-width: fit-content;
}

.tsDataHeaderValue {
  font-weight: 500;
  text-align: right;
  line-height:14px;
  /* margin-top:4px */
}

.tsDataHeaderRowSplitter {
  min-height: 1px;
  min-width: 94%;
  margin-left: 3%;
  background-color: white;
  opacity: 0.25;
  max-width: 94%;
}

.tsSchedParent {
  display: flex;
  padding-left: 6px;
}

.tsSchedClockType {
  font-weight: 500;
  border-left: #bbcada 2px solid;
  padding-left: 5px;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  margin-top:8px;
  margin-bottom:12px;
}

.minWidthFitContent {
  min-width: fit-content !important;
}

.tsEditItemTypeDropdown {
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  padding-left: 9px;
  text-align: left !important;
  max-width: 99px;
  min-width: 99px;
  line-height: 18px;
  height: 30px;
  -webkit-appearance: none;
  background-position-x: 64px;
  display: flex;
  cursor: pointer;
  border: 2px solid #dde9f9;
  background-color: #0bf;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 80px;
  margin-left: 8px;
}

.paddingRight27px {
  padding-right: 27px;
}

.fontSize7 {
  font-size: 7px !important;
}

.noPaddingRight {
  padding-right: 0px !important;
}

.toilAllowRow {
  margin-top: -6px;
}

.sufficientTil {
  color: #6dba4f;
}
.insufficientTil {
  color: #f07b7b;
  margin-top:6px;
  margin-left:4px;
  /* margin-right:13px; */
}
.sufficientTilTick {
  margin-right: 4px;
  padding-right: 7px;
  width: 18px;
}

.insufficientTilTick {
  margin-right: 4px;
  padding-right: 7px;
  margin-top: 2px;
  max-width: 17px;
  margin-left: 2px;
}

.colorRed {
  color: #f07b7b !important;
}

.colorRed2 {
  color: #ba0000 !important;
}
.mobModalTeamModal {
  min-height: fit-content !important;
  max-height: fit-content !important;
  padding-bottom: 15px;
}

.specDayClosedSpan {
  margin-left: 3px;
  opacity: 1;
  margin-right: 4px;
  border-left: 1px solid #eed5f6;
  padding-left: 5px;
}

.bidQtyUserIcon {
  max-width: 15px;
  margin-left: 12px;
  margin-right: 5px;
}

.bidUsrQtySpan {
  color: #0af;
  font-size: 16px;
  /* padding-bottom:4px; */
}

.font16 {
  font-size: 16px;
}

.ba0000 {
  color: #ba0000;
}

.unlinkedExplainBtn {
  background-color: #ba0000;
  color: white;
  min-width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  max-height: 15px;
  margin-left: 5px;
  font-size: 11px;
  /* padding: 4px; */
}

.cursorP {
  cursor: pointer !important;
}

.x2817346 {
  line-height: 28px;
}

.unlinkedPopUpLink {
  color: #00aaff !important;
  cursor: pointer;
  margin-left: 4px;
}

.unlinkedPopUpLink:hover {
  color: #00bbff !important;
}

.x9813114 {
  margin-bottom: -12px;
}

.editTagSugImg {
  max-width: 13px;
}

.editTagBtn {
  min-width: 40px !important;
}

.addTagToMulti {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  font-size: 12px;
  color: white;
  font-weight: 500;
  text-align: left;
  max-width: 100px;
  display: flex;
  opacity: 0.8;
  cursor: pointer;
  border-radius: 5px;
  max-height: 35px;
  min-height: 35px;
  min-width: fit-content;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 12px;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

.addTagToMulti:hover {
  opacity: 1;
  background-color: #bbcada;
}

.addTagsToAllActive {
  opacity: 1;
}

.addTagToAllImg {
  max-width: 12px;
  margin-right: 8px;
  margin-left: 2px;
}

.tagMgrLeft {
  display: flex;
  /* min-width:fit; */
}

.shiftTilMyCalTitle {
  /* padding-left:10px; */
  text-align: left;
}

.paddingLeft10 {
  padding-left: 10px;
}

.fontSize12 {
  font-size: 12px !important;
}

.staffFormsContainer {
  /* padding-top: 31px; */
}

.upcomingCommentImg {
  max-width: 13px;
  margin-bottom: -2px;
  margin-left: 3px;
  opacity: 0.76;
}

.ghostColourLight {
  background-color: #875eff;
}
.viewTsBorder {
  border-top: 1px solid #bac5d3;
  border-radius: 0px;
}

.marginTop10 {
  margin-top: 10px !important;
}

.noUnlinkedMobClocksShoulderHeight {
  min-height: 50px !important;
  max-height: 50px !important;
  padding-top: 50px;
}

.weight600 {
  font-weight: 500;
}

.toilNewOldTimesBar {
  display: flex;
  justify-content: space-around;
  /* padding-left: 35px; */
  /* padding-right: 25px; */
  min-width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: #0077ff;
  margin-top: 8px;
  margin-bottom: 10px;
}

.toilNewOldTitle {
  min-width: 109px;
  text-align: left;
  /* border-top:2px solid #e0ebf8; */
  padding-left: 28px;
  padding-top: 6px;
}

.toilNewOldTitle2 {
  min-width: 139px;
  text-align: right;
  /* border-top:2px solid #e0ebf8; */
  padding-right: 26px;
  padding-top: 6px;
}

.x198313 {
  border-top: 1px solid #e0ebf8;
  padding-top: 2px;
}

.x3891974 {
  border-left: 1px solid #e0ebf8;
  padding-left: 5px;
  /* margin-left: 5px; */
  align-items: center;
}

.borderRadius10 {
  border-radius: 10px 5px 5px 10px !important
}

.calCommentsRow {
  min-height: 26px;
  /* background-color: yellow; */
  max-height: 26px;

  display: flex;
  align-items: center;
  min-width: fit-content;
  margin-left: 10px;
}

.calLimitsRow {
  min-height: 108px;
  background-color: #504588;
  max-height: 66px;

  display: flex;
  align-items: center;
  min-width: fit-content;
  margin-left: 10px;
  border-top:2px solid #143051;
  border-bottom:2px solid #143051;
  border-right:2px solid #143051;
  border-radius: 0 5px 5px 0;
}
.calCom {
  max-width: 40px;
  /* border-left:1px solid black; */
  min-width: 40px;
  max-height: 26px;
  /* background-color: lime; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.calCommentIcon {
  max-width: 15px;
}

.commentFade {
  opacity: 0.05;
  cursor: pointer;
}

.commentFade:hover {
  opacity: 0.25;
}

.commentDivActive {
  cursor: pointer;
}

.commentDivActive:hover {
  opacity: 0.8;
}

.commentsExistA1 {
  margin-top: 5px;
  margin-bottom:-4px;
}

.commentsExistA1limitEnabled {
min-height:107.5px;
margin-bottom:36px;
}

.nonWorkingBorderLeft {
  border-left: none;
  padding-left: 0;
}

.reqAndMsgDiv {
  display: flex;
}

.x39142318 {
  font-size: 10px;
}

.x139819841 {
  max-width: 170px;
}

.x13984191 {
  /* background: none; */
  /* backdrop-filter: none; */
  /* background: rgb(0, 0, 0, 0.1); */

    -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.toilInstructInfo {
  color: #516b8b;
  font-weight: 400;
  font-size: 12px;
  text-align: left;
  margin-left: calc(5% + 5px);
  padding-right: 5%;
  line-height: 16px;
}

.x923111831 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.marginBottomMinus20 {
  margin-bottom: -20px !important;
}

.filterSchedBtn {
  border-radius: 5px;
  padding: 5px;
  min-width: 25px;
  min-height: 25px;
  max-height: 25px;
  max-width: 25px;
  margin-top: 7px;
  border: 1px solid #d6e1ed;
  margin-right: 10px;
  cursor: pointer;
}

.filterSchedBtn:hover {
  border-color: #bac5d3;
}

.filterSchedBtnActive {
  padding-right: 6px;
  border: 1px solid #aaa1c3;
  background-color: #fff;
}

.filterSchedBtnActive:hover {
  border: 1px solid #aaa1c3;
}

.filterSchedBtnActiveTags {
  background: #656095;
  border: 0px solid #4d4391;
}

.filterSchedBtnActiveTags:hover {
  background-color: #4d4391;
  border-color: #4d4391;
}

.filtTagsTitle {
  font-size: 11px;
  font-weight: 500;
  color: #826197;
  margin-top: 1px;
  background: #ede2ff;
  max-width: 76px;
  border-radius: 0 0 3px 3px;
  margin: 0px auto;
  padding-bottom: 1px;
  border: 1px solid #bca7d0;
  border-top: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.closeFiltTags {
  max-width: 7px;
  /* opacity: 0.8; */
  margin-left: 4px;
}
.filtTagsFlex {
  display: flex;
  /* background-color: #143051; */
  color: white;
  justify-content: center;
  align-items: center;
  min-height: 30px;
  min-width: calc(100vw - 160px);
  max-width: calc(100vw - 160px);
  float: right;
  overflow-x: scroll;
  padding-left: 50px;
  padding-right: 50px;
}

.filtTagItem {
  background-color: white;
  color: #9c71cc;
  font-weight: 500;
  font-size: 14px;
  border-radius: 5px;
  margin-right: 3px;
  margin-left: 3px;
  min-height: 26px;
  margin-top: 2px;
  border: 1px solid #b4a8de;
  display: flex;
  align-items: center;
  padding-left: 9px;
  padding-right: 9px;
  cursor: pointer;
  min-width: fit-content;
}

.filtTagItemUnsel {
  background: none;
  border-color: #ebd9ff;
  color: white;
  opacity: 0.7;
}

.filtTagItemUnsel:hover {
  background-color: #ebd9ff;
  opacity: 0.9;
}

.filtTagsModal {
  min-width: 300px;
  background-color: #fff;
  /* min-height: 300px; */
  border-radius: 10px;
  /* border: 1px solid #bbcada; */
  margin-bottom: 20%;
  cursor: default;
  /* border: 1px solid #e7ebef; */
  /* box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1) */
}

.filtTagsModalMob {
  margin-bottom: 0%;

  /* min-width: 100vw;
  margin-bottom:0px !important;
  bottom: 0  !important;
  left: 0;
  display: fixed; */
}

.filtTagsUnderlay {
  background: rgba(0, 0, 0, 0.25);
  height: 100vh;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(1px);
  background-color: rgb(119 119 119 / 30%);
}

.tagsFiltScrollBox {
  background-color: #f4f8fe;
  max-width: 92%;
  margin-left: 4%;
  min-height: 189px;
  border: 1px solid #e0ebf8;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  margin-bottom: -10px;
  max-height: 50vh !important;
  overflow-y: auto;
}

.tagFiltItem {
  display: flex;
  align-items: center;
  border: 1px solid #e0ebf8;
  border-radius: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
  /* max-width: calc(100% - 6px); */
  margin-left: 3px;
  min-height: 40px;
  padding-left: 10px;
  font-weight: 500;
  color: #143051;
  cursor: pointer;
  background-color: white;
  text-align: left;
  max-width: 300px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 8px;
}

.tagFiltItem:hover {
  border-color: #bbcada;
}

.showAllTags {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 78px;
  padding-left: 9px;
  padding-right: 9px;
  min-height: 35px;
  max-height: 35px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
}

.showAllTags:hover {
  background-color: #bbcada;
}

.x134214253 {
  margin-right: 10px;
}

.myTilShiftUnitParent {
  /* min-height: fit-content; */
  /* background-color: red; */
  min-height: 86px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.myCalOpensArr {
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;  
  /* animation-duration: 2s !important; */
}


.myTilShiftUnitParent:first-of-type {
  /* margin-top: 14px; */
}
.myShiftUnitTagsRow {
  /* background-color:yellow; */
  /* margin: -7px auto; */
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 90%;
  min-width: 90%;
  margin: -8px auto;
  padding-left: 50px;
  /* display: none; */
}

.myShiftUnitTagsRowMobOnly {
  /* margin-left:74px; */
  z-index: 1;
  position: relative;
}

.myTilShiftUnitWithTags {
  min-height: 90px;
}

.usrShiftTagsContainer {
  display: flex;
  /* margin-top: -3px; */
  min-width: 302px;
  max-width: 302px;
  z-index: 99999;
  /* background-color: lime; */
  /* margin-top: 4px; */
}

.ghostCostOverlay {
  background-color: #143051;
  color: white;
}

.ghostCostOverlayHovered {
  background-color: #214078;
  color: white;
}

.spaceBetween {
  flex-direction: row;
  justify-content: space-between  !important;
}

.addAbsItemCheckBox {
  min-width: 8%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #bbcada;
  max-width: 20px;
  max-height: 20px;
  min-height: 20px;
  min-width: 20px;
  border-radius: 5px;
  margin-top: 12px;
  margin-left: 7px;
}

.addAbsItemCheckBoxImg {
  width: 10px;
}

.accountTeamPopUpImg {
  max-width: 12px;
  margin-left: 5px;
}

.accUserIcon {
  max-width: 8px;
  margin-right: 6px;
}

.accTeamIcon {
  max-width: 18px;
  margin-right: 6px;
}

.calNoItemsTxt {
  background-color: #ffffff;
  max-width: 92%;
  margin-left: 4%;
  border-radius: 3px;
  /* margin-top: 3px; */
  color: #bbcada;
  font-weight: 500;
  font-size: 12px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 6px;
  padding-left: 13px;
}

.rankBtn {
  font-size: 14px;
  /* margin-top: 8px; */
  color: #496375;
  border: 2px solid #496375;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  line-height: 26px;
  padding-left: 10px;
  font-weight: 500;
  padding-right: 8px;
  /* margin-right: 8%; */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  color: white;
  margin-left: 10px;
}

.whiteRankBtn {
  background-color: #b49ce7;
  border: 1px solid #a47fd0;
  color: #fff;
}

.whiteRankBtn:hover {
  /* border-color: #dce2e9; */
  background-color: #a58fd4 !important;
}

.rankPopUpImg {
  max-width: 14px;
  margin-left: 5px;
}
.rankBtn:hover {
  background-color: #bbcada;
}

.myAbsTopLeft {
  display: flex;
  align-items: center;
}

.x038141 {
  min-width: 100%;
  justify-content: space-between;
}

.tsInlineTags {
  /* background-color: blue; */
  margin-top: 8px;
  margin-bottom: 5px;
  border-top: 1px solid #e1e2e3;
  padding-top: 8px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 2px;
}

.tsInlineTagsAddHours {
  margin-bottom: 35px;
  max-width: 90%;
  min-width: 90%;
  border-radius: 5px;
  margin-left: 5%;
  border: 1px solid #e1e2e3;
  padding-bottom: 4px;
  /* padding-left:6px; */
  padding-right: 6px;
  margin-left: 4%;
  margin-top: 0px;
  border: none;
}

.tsInlineTagItem {
  /* display: flex;
  min-height: 35px;
  margin-right: 4px;
  border: 1px solid #e6eaee;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 4px;
  border-radius: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 12px;
  text-align: left; */


  color: #fff;
    font-size: 11px;
    text-align: left;
    margin-right: 2px;
    padding-left: 5px;
    padding-right: 6px;
    min-width: -moz-fit-content;
    min-width: fit-content;
    border-radius: 5px;
    font-weight: 500;
    /* background-color: #143051; */
    /* background-color: rgb(247, 250, 255); */
    background-color: #f4f8fe;
    color: #4f566b;
    padding-top: 0px;
    line-height: 21px;
    max-height: 24px;
    max-height: 23px;
    border: 1px solid #d2d8df;
    display: flex;
    align-items: center;
  /* background-color: white; */
}

.activeTsTagsInline {
  background-color: white;
  border: 1px solid #bbcada;
  color: #214978;
}

.tagItemAddHours {
  font-size: 12px;
  min-height: 30px;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 500;
  align-items: center;
  display: flex;
}

.clockAvailTagsModal {
  background-color: #fff;
  min-width: 320px;
  max-width: 320px;
  min-height: 290px;
  max-height: 290px;
  border-radius: 6px;
  align-items: center;
  color: #143051;
  text-align: center;
  animation-name: moveEditTeamUserBoxUp !important;
  animation-duration: 0.1s !important;
  cursor: default;
}

.clockAvailTagsScrollBox {
  /* background-color: yellow; */
  max-height: 182px;
  min-height: 182px;
  overflow-y: scroll;
  max-width: 90%;
  margin-left: 5%;
  border-bottom: 1px solid #143051;
  border-top: 1px solid #c0d1e9;
  padding-bottom: 20px;
}

.clockAvailTagsDiv {
  display: flex;
  flex-wrap: wrap;
}

.appliedTagsQty {
  margin-left: 5px;
  margin-right: 2px;
}

.noClockTagsTxt {
  font-size: 14px;
  color: #bbcada;
}

.newlyAddedClockTag {
  border-left: 4px solid #00ccff;
}

.x248241 {
  /* background-color: blue; */
  /* max-height:30px; */
  border: 2px solid #eef2fa;
  line-height: 20px;
  font-size: 12px;
  padding-left: 10px;
  max-width: 98px;
  min-width: 98px;
  background-position-x: 76px;
  margin-left: 12px;
  /* margin-top:-27px; */
}

.removeTagFromTsImg {
  max-width: 10px;
  margin-left: 6px;
  min-width: 10px;
  cursor: pointer;
}

.removeTagFromTsImg:hover {
  opacity: 0.8;
}

.addTagAndAddRowDiv {
  display: flex;
  align-items: center;
  min-width: 100%;
  justify-content: space-between;
  padding-right: 5%;
  margin-top: -52px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0ebf8;
}

.x23972871 {
  margin-top: -30px;
}

.x38194 {
  /* background-color: yellow; */
  margin-left: -80px;
  font-size: 12px;
  color: #bbcada;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  font-weight: 500;
}

.x19382 {
  margin-left: -20px !important;
  font-size: 10px;
  margin-right: -10px;
}

.finalTsItemsFlex {
  background: blue;
  justify-content: flex-end;
}

.tsAddInputDsTitle {
  color: #bbcada;
  font-weight: 500;
  font-size: 12px;
  margin-left: 14px;
  margin-bottom: 10px;
}

.addTagInlineSh {
  max-width: 12px;
  margin-left: 5px;
  margin-top: -1px;
}

.approveClockTickImg {
  max-width: 12px;
  min-width: 12px;
  margin-left: 5px;
  margin-right: -2px;
}

.x34981 {
  max-width: 18px;
  min-width: 18px;
  margin-right: -4px;
  margin-left: 2px;
}

.viewTsNoItems {
  color: #859dbb;
  font-size: 14px;
  font-weight: 500;
  margin-top: 0px;
  margin-bottom: -4px;
  text-align: left;
  border-left: 2px solid #bbcada;
  padding-left: 10px;
  margin-left: 22px;
}

.recordViewTsBox {
  /* background-color: #00ccff !important; */
}

.editingEntryEditDiv {
  max-width: 46px;
  min-width: 46px;
  min-height: 58px;
}

.viewTsEditTimeimg {
  max-width: 26px;
  min-width: 26px;
  border: #e3e8ee solid 2px;
  cursor: pointer;
  max-height: 26px;
  min-height: 26px;
  padding: 6px;
  margin-top: 16px;
  border-radius: 5px;
}

.viewTsEditTimeimg:hover {
  border-color: #b2bbc7;
}

.saveEditingEntryBtn {
  max-width: 46px;
  min-width: 46px;
  border: #0af solid 1px;
  cursor: pointer;
  max-height: 26px;
  min-height: 26px;
  /* padding: 6px; */
  background-color: #0bf;
  margin-bottom: 5px;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 4px;
  padding-right: 4px;
  font-weight: 500;
}

.saveEditingEntryBtn:hover {
  background-color: #0af;
}

.greyColoursTsBtn {
  background-color: #cbd7e6;
  padding: 4px;
  border-color: #bbcada;
  font-size:10px;
  margin-top:10px;
}

.greyColoursTsBtn:hover {
  background-color: #bbcada !important;
}

.loadingTsHorizBalls {
  max-width: 80px;
}

.viewTsItemNote {
  font-weight: 500;
  color: #143051;
  padding-left: 2px;
  max-width: 100%;
  min-width: 100%;
  padding-top: 7px;
  margin-left: 1px;
  margin-bottom: 10px;
  border-top: 1px solid #e1e2e3;
  margin-top: 5px;
  min-height: 30px;
  margin-top: 0px;
  font-size:14px;
}

.x398721 {
  background-color: #ecf4ff;
}

.x972421 {
  cursor: pointer;
}

.x972421:hover {
  border-color: #bbcada;
}

.x308491 {
  margin-left: 11px !important;
  margin-right: -1px;
  min-width: 17px;
}

.noAvailClockTags {
  font-size: 14px;
  display: flex;
  align-items: center;
  /* line-height:20px; */
}

.x31414x13 {
  margin-left: 10px !important;
}

.myShiftEditBtnImg {
  max-width: 10px;
  margin-left: 5px;
}

.x139714 {
  padding-left: 9px;
  padding-right: 9px;
  min-width: 88px;
}

.x310852 {
  margin-right: 7px;
}

.mgrAvailReqInfo {
  font-size: 8px;
  margin-left: 4px;
  margin-right: 0px;
  text-align: left;
  max-width: 44px;
  color: #214978;
  margin-top: 0px;
  line-height: 9px;
}

.x9247925 {
  opacity: 0.35;
  -webkit-filter: grayscale(100%); /* Safari and Chrome */
  filter: grayscale(100%);
}

.x9247925:hover {
  opacity: 0.55;
  filter: grayscale(40%);
  -webkit-filter: grayscale(40%); /* Safari and Chrome */


}

.x249213572 {
  /* background-color: blue; */
  min-height: calc(100vh - 322px);
  max-height: calc(100vh - 322px);
}

.x2082982 {
  background-position-x: 174px !important;
  max-width: 196px !important;
  min-width: 196px !important;
  margin-right: 11px
}

.x108429811 {
  margin-right: 24px

}

.x3413567 {
  color: #214978;
}

.x394722 {
  font-size: 12px;
  margin-left: 5px;
  margin-right: 5px;
}

.x2456788743 {
  display: flex;
  align-items: center;
}

.x981452 {
  color: #9374d6;
}

.x9879741 {
  margin-bottom: -46px;
}

.animateZoomIn {
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.x08282 {
  color: #00ccff;
}

.addNoteDayCommentContainer {
  /* margin-top: -10px; */
  margin-left: 7px;
}

.shiftAddNoteTextArea::placeholder {
  font-size: 14px;
  color: #bbcada;
}

.likedUsersSection {
  /* min-width: 80px; */
  text-align: left;
  /* cursor: pointer; */
}

.ackTitle {
  font-size: 10px;
  font-weight: 500;
  color: #bbcada;
  margin-top: 6px;
  margin-bottom: 4px;
  max-height: 12px;
  display: flex;
  align-items: center;
}

.likedArrMapDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding-left: 2px;
}

.likedArrMapDiv:hover {
  opacity: 0.8;
}

.ackUsrIcon {
  max-width: 22px;
  max-height: 22px;
  min-width: 22px;
  min-height: 22px;
  border-radius: 3px;
  margin-right: 2px;
  object-fit: cover;
  /* border: 1px solid #214978; */
}

.ackUsrExcess {
  color: #bbcada;
  font-weight: 500;
  margin-left: 3px;
  font-size: 11px;
}

.ackUsrInitials {
  background-color: #214978;
  border: 1px solid #143051;
  max-width: 22px;
  max-height: 22px;
  min-width: 22px;
  min-height: 22px;
  border-radius: 5px;
  margin-right: 2px;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: white;
  font-weight: 500;
}

.showLikedNamesCom {
  display: flex;
  align-items: flex-start;
  padding-left:1px
}

.ackArrClose {
  max-width: 17px;
  margin-left: 2px;
  margin-top: 6px;
  margin-right: 5px;
  padding-left: 6px;
  padding-right: 5px;
  padding-top: 6px;
  padding-bottom: 6px;
  transform: rotate(270deg);
  border: 1px solid #e1eaf5;
  border-radius: 4px;
  cursor: pointer;
}

.ackArrClose:hover {
  opacity: 0.8;
}

.ackNamesList {
  margin-top: 9px;
  margin-left: 2px;
}

.ackNameItem {
  font-size: 12px;
  font-weight: 500;
  color: #bbcada;
  margin-bottom: 3px;
  max-height: 16px;
  /* background: yellow; */
  display: flex;
  align-items: center;
}

.x18429814581 {
  background: none;
  border: none;
  margin-top: 16px;
}

.Acknowledged {
  font-weight: 500;
  font-size: 9px;
}

.showAckExpandIcon {
  max-width: 20px;
  cursor: pointer;
  padding-left: 4px;
  padding-right: 4px;
  cursor: pointer;
  padding-top: 2px;
  padding-bottom: 2px;
  border: 1px solid #e1eaf5;
  border-radius: 4px;
  margin-left: 3px;
}

.showAckExpandIcon:hover {
  opacity: 0.8;
}

.dayCommentsFrameWithinScroll {
  border-left: 2px solid #143051;
}

.x98472515 {
  font-size: 10px;
  margin-left: 4px;
}

.x5362 {
  margin-left: 2px;
}

.inlineDayUnitImg {
  max-width: 15px;
  /* margin-top: 2px; */
  /* margin-bottom: -4px; */
  margin-right: 5px;
}

.showDayImgTitleWrap {
  display: flex;
  align-items: center;
  margin-bottom: -14px;
  margin-left: -2px;
}

.loadingDayCommentBalls {
  max-width: 80px;
  margin-top: 30px;
  margin-bottom: -100px;
}

.dayCommentComProfPic {
  max-width: 26px;
  min-width: 26px;
  min-height: 26px;
  max-height: 26px;
  border-radius: 3px;
  margin-right: 6px;
}

.dayCommInits {
  max-width: 26px;
  min-width: 26px;
  min-height: 26px;
  max-height: 26px;
  border-radius: 3px;
  margin-right: 6px;
  background-color: #214978;
  border: 1px solid #143051;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: white;
  font-size: 12px;
}

.myShiftClockStatus {
  font-weight: 500;
  font-size: 11px;
  text-align: right;
  padding-right: 10px;
  border-right: 2px solid #bbcada;
  color: #bbcada;
  max-height: 16px;
  line-height: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 2px;
}

.ccAPPR {
  color: #82c26a;
  border-right: none;
  padding-right: 2px;
}

.ccDECL {
  color: #ba0000;
  border-right: none;
  padding-right: 2px;
}

.editClockDecisionBtn {
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  /* padding-left: 10px; */
  /* padding-right: 10px; */
  /* display: flex; */
  min-height: 35px;
  max-height: 35px;
  min-width: 35px;
  max-width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* line-height: 26px; */
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  cursor: pointer;
  color: white;
  /* min-width: -moz-fit-content;
        min-width: fit-content;
        max-width: -moz-fit-content;
        max-width: fit-content; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.editClockDecisionBtn:hover {
  background-color: #bbcada;
}

.editClockDecImg {
  max-width: 24px;
}
.width38px {
  min-width: 38px !important;
  max-width: 38px !important;
}

.clockWhiteBin {
  max-width: 16px;
  min-width: 16px;
}

.backEditDecision {
  min-width: 35px;
  min-height: 35px;
  max-width: 35px;
  max-height: 35px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  margin-right: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.backEditDecision:hover {
  background-color: #bbcada;
}

.backeditdecisionImg {
  max-width: 19px;
}

.mgrTsDecideBtnsContainer {
  display: flex;
  align-items: center;
}
.x1084 {
  /* margin-right:8px */
  max-width: 68px;
  margin-top: -3px;
}

.x38713 {
  max-width: 40px;
}

.tsEditBrkFlexDiv {
  display: flex;
  align-items: center;
  margin-top: -3px;
}

.x1381314 {
  font-size: 14px;
  margin-left: 2px;
  font-weight: 500;
  color: #214978;
}

.x819741 {
  margin-top: -7px;
}

.x18489181 {
  margin-top: 16px;
}

.x20831981 {
  /* background: yellow; */
  flex-direction: column;
  align-items: flex-end;
  min-height: 34px !important;
  line-height: 10px;
  margin-top: -16px;
}

.mgrAbsencePeriodParent {
  -webkit-animation-name: zoomModalIn !important;
  animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
  animation-duration: 0.1s !important;
}
.mgrAbsencePeriodParentFirst {
  /* margin-top:20px !important */
}

.x131415 {
  padding: 0px;
  margin-bottom: -13px;
  margin-top: 19px;
  font-size: 10px !important;
  /* border-bottom: 1px solid #e0ebf8; */
}

.x248925 {
  margin-top: 6px;
}

.x1849781 {
  margin-top: -3px;
  margin-bottom: -1px;
}

.x131141 {
  margin-top: -105px;
}

.x0984014 {
  margin-bottom: -21px;
}

.firsMgrAbsShift {
  /* margin-top: -18px; */
}

.x1848918 {
  /* margin-top:7px; */
  /* font-size:12px  !important; */
}

.x18918491 {
  font-size: 12px;
}

.x140719741 {
  margin-top: -1px;
  margin-bottom: 17px;
}

.x19749711 {
  min-height: 50px !important;
}

.removeTsTagBtnImg {
  max-width: 12px;
  padding: 2px;
  margin-left: 5px;
  cursor: pointer;
}

.removeTsTagBtnImg:hover {
  opacity: 0.8;
}


.editinTsTsBorder {
 border:2px solid #dde9f9
}

.noTsTagstxt {
  color: #bbcada;
  margin-left: 6px;
  margin-top: 5px;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 10px;
  border-top: 1px solid #e1e2e3;
  padding-top: 7px;
}

  .noTagsTsWrap {
    display: flex;
    align-items: center;
  }
  .addTagToTsItemDropdown {
    color: #fff;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 500;
    padding-left: 7px;
    text-align: left !important;
    max-width: 80px;
    min-width: 80px;
    line-height: 20px;
    height: 35px;
    -webkit-appearance: none;
    /* background-position-x: 64px; */
    display: flex;
    cursor: pointer;
    border: 2px solid #dde9f9;
    /* background-color: #0bf; */
    background-color: white;
    color: #6a8bb1;
    background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
    background-position-x: 62px;
    margin-top: 5px;
    margin-left: 2px;
    margin-bottom:8px;
  }

  .addTagToTsItemDropdown:hover {
    border-color: #d7dfe9
  }

  .addingTsTagDiv{
    display: flex;
    align-items: center;
  }

  .tsCreateTagInput {
    min-height:35px;
    border-radius:5px;
    border: 2px solid #dde9f9;
    font-size:16px;
    margin-top:4px;
    margin-left:3px;
    color: #214978;
    font-weight:500;
    padding-left:4px;
    max-width:160px;
    padding-left:8px;
  }

  /* .tsCreateTagInput */

  .addTagTsAddBtn {
    background-color: #07f;
    border: #0469de 1px solid;
    color: white;
    font-weight: 500;
    font-size: 14px;
    border-radius: 5px;
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 9px;
    padding-right: 9px;
    margin-top: 1px;
    min-width: 54px;
    margin-left: 5px;
    cursor: pointer;
  }

  .addTagTsAddBtn:hover {
    background-color: #036ce4;
  }

  .x1411415 {
    min-height:fit-content;
    max-height:fit-content;
    margin-top:4px;
  }

  .x019481 {
    margin-right: -2px;
  }

  .makeTilWholeBtn {
    min-height: 35px;
    max-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    /* min-width: 96px; */
    font-size: 14px;
    text-align: center;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
    background-color: #0bf;
    border: 1px solid #0af;
    padding-left: 7px;
    padding-right: 7px;
    
}

    .makeTilWholeBtn:hover {
      background-color: #0af;
    }

    .x108401840 {
      margin-top:6px;
    }


    .x1084018402 {
      margin-top:2px;
    }

    .x0193011 {
      line-height: 12px;
      margin-bottom: -7px;    }


      .staffAbsLateStartSpan {
        margin-left: 5px;
        border-left: 1px solid #e0ebf8;
        padding-left: 5px;
      }


      .x4082 {
        margin-top: 0px;
        margin-bottom: 10px;
        /* border-bottom: 2px solid #bbcada; */
        border-radius: 7px;
        /* border-right: 2px solid #bbcada; */
        max-width: 92%;
        /* border-top: 2px solid #bbcada; */
        background: #5a497f;
        color: white;
        /* margin-bottom: 40px !important; */
        font-size: 13px;
        animation-name: zoomModalIn !important;
        animation-duration: 0.25s !important;
        animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55) !important;
        margin-bottom: 25px;
        border: 1px solid #2c2144;
        max-height: -moz-fit-content;
        max-height: fit-content;
        min-height: -moz-fit-content;
        min-height: fit-content;
padding-bottom: 20px;
      }

      /* @keyframes slideInstructDown {
        from {
          max-height: 0;
          overflow: hidden;
        }
        to {
          max-height: 1000px; 
          overflow: hidden;
        }
      } */

      .x091011 {
        right: calc(50vw - 80px)
      }

      .hideInfoTxtClose {
        max-width: 40px;
        /* float: right; */
        /* margin-right: 30px; */
        /* margin-left: -60px; */
        cursor: pointer;
        padding:5px;
        /* margin-top: 10px; */
       
        }

     

        .hideInfoTxtClose:hover {
          opacity: 0.8;
        }

        .x082 {
          margin-top:-5px;
        }
.showInstructWrap {
  float: right;
  margin-right: 21px;
  margin-left: -170px;
  /* border: 1px solid #cec8d5; */
  color: #fff;
  display: flex;
  align-items: center;
  min-width: 35px;
  margin-top: -5px;
  border-radius: 4px;
  min-height: 29px;
  font-weight: 500;
  padding-left: 5px;
  font-size: 12px;
  /* background-color: #dbdaf4; */
  max-height: 35px;
  min-height: 35px;
  padding-right: 5px;
  font-size: 14px;
      
}
        .showInstructBtn {
          float: right;
          /* margin-right: 26px; */
          /* margin-left: -163px; */
          cursor: pointer;
          border: 1px solid #cec8d5;
          color: #fff;
          display: flex;
          align-items: center;
          min-width: 32px;
          /* margin-top: -5px; */
          border-radius: 4px;
          min-height: 29px;
          font-weight: 500;
          padding-left: 5px;
          font-size: 12px;
          background-color: #dbdaf4;
          max-height: 25px;
          min-height: 25px;
          padding-right: 5px;
          /* font-size: 14px; */
    
  
  }

  .periodsInstructWrap {
    margin-top:-24px;
  }


  .showInstructBtn:hover {
    background-color: #d2d1ec;
  }

  .showInstructDownWhiteImg {
    max-width: 10px;
    margin-left: 5px; }

    .whatAreTheseTitle {
      margin-bottom: 0px;
      color: #a388ec;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      align-items: center; }

      .showInstructHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 103%;
        margin-bottom: 8px;
      }

      .whatAreTheseQimg {
        /* background: #A388EC; */
        min-width: 16px;
        max-width: 16px;
        max-height: 16px;
        min-height: 16px;
        border-radius: 3px;
        padding: 2px;
        opacity: 1;
        /* border: 1px solid #7b51e9; */
        }

        .showInstructTitle {
          /* margin-left: 4px; */
          margin-top: 1px;
          color: white;
          opacity: 0.5;
          /* border-left: 2px solid white; */
          /* padding-left: 8px; */
        }

        .x131134 {
          margin-right:8px;
          min-height:35px;
        }


.filtImg {
  max-width: 15px;
  margin-left: -3px;
  margin-right: 0px;}

  .x19831 {
    margin-left:-6px;
    margin-right:-6px;
  }

  .absShUnpaid {
    /* border-radius: 5px; */
    border-left: 1px solid #e0ebf8;
    color: #bbcada;
    /* padding: 2px; */
    padding-left: 4px;
    /* padding-right: 4px; */
    font-size:10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    padding-left:6px;
    padding-right:3px;
    margin-top:2px;
  }

  .lateArrStart {
    color: #ba0000;
    font-weight: 600;
    font-size: 11px;
    margin-top: 2px;
    margin-left: 5px;
   }

.lateArrImg {
  max-width: 12px;
  margin-top:2px;
}

.x13141423 {
  margin-top:-2px !important;
}

.x1972141 {
  padding-left:0px
}

.teamHoursRotaClock {
  max-width: 10px;
  margin-right: 4px;
  opacity: 0.5;}

  .x08917471 {
    margin-bottom: 80px;
    margin-top: -90px;  }

    .x9749725 {
      margin-top:4px;
    }

    .x19749111 {
      margin-top:-15px;
    }

    .x1084971971 {
      margin-top: -13px;
      /* margin-left: 9%;    */
     }

      .x1974971 {
        margin-top: -108px;
        /* background-color: orange !important; */
        margin-bottom: 80px;
      }

      .x29749711 {
        margin-top: -103px;
        margin-bottom: 59px;      }

        .x08141 {
          border-top: 1px solid #c7e1ff
        }

  .x0841 {
    margin-left:calc(10% + 85px);
  }

  .x104811 {
    margin-top: -109px;
    margin-bottom: 78px;  }

    .x1794911 {
      margin-top:-13px;
    }

    .x2084971 {
      background:#214978;
      border-color:#143051;
      color:white
    }

    .x131453678 {
      margin-top:9px;
      line-height:12px;
    }

    .firstTeamShiftUnit {
      margin-top:21px;
    }


.x0193081041 {
  padding-top:0px;
  border-top:none;
}

.redMgrMins {
  background-color: #f5e5e5;
  color: #ba0000;
  border: 1px solid #dcc7c7;
}

.redMgrMinsHalf {
  color: #ba0000;
  background: linear-gradient(66deg, #f5e5e5 50%, rgb(247, 250, 255) 50%);
  border: 1px solid #dcc7c7;
}


.x2081371 {
  /* background-color: blue; */
  margin-top:15px;  
  /* margin-bottom:-10px */
}

.x08249 {
  margin-top:-3px;
}

.tsClockItemChild {
  max-width: 76px;
  margin-right:5px;
}

.x10813111 {
  font-weight: 500;
  color: #214978;
  margin-right: 5px;
}

.tsYourNote {
  font-weight: 500;
  color: #214978;
  margin-left: 2px;
  margin-top: -3px;
  border-top: 1px solid #e1e2e3;
  padding-top: 5px;
  padding-bottom: 4px;
}
.mgrTsNoteInput {
  background-color: #fff;
  width: 100%;
  max-width: 100%;
  line-height: 30px;
  padding-top: 0px;
  height: 35px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  text-align: left;
  color: #496375;
  font-size: 16px;
  font-weight: 500;
  /* -webkit-appearance: none; */
  /* -moz-appearance: none; */
  /* appearance: none; */
  cursor: text;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: flex-start; */
  padding-left: 8px;
  margin-bottom:4px
}

.x198131 {
  color: #ffff;
  background: #6412b3;
  border-radius: 3px;
  padding-left: 4px;
  padding-right: 4px;
  min-height: 25px;
  line-height: 21px;
  padding-top: 2px;
  padding-bottom: 2px;
}

  .x2849242 {
    color: #143051;
    font-weight: 400;
    border-radius: 3px;
    padding-left: 1px;
    padding-right: 3px;
    min-height: 25px;
    line-height: 21px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 10px;
  }

  .editingTsRecord {
    border-color: #00ccff;
    border-radius: 8px;
  }

  .x10830184 {
    background-color: #748dab;
    color: white;
    max-width: fit-content;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 6px;
    padding: 7px;
    padding-left: 13px;
    padding-right: 3px;
    cursor: pointer;
    border:1px solid #496375
  }

  .x10830184:hover {
    background-color: #6c86a6;
  }

  .x208420 {
    border: #e1e2e3 1px solid;
    /* color: white; */
    max-width: -moz-fit-content;
    max-width: fit-content;
    border-radius: 5px 5px 0 0;
    margin-top: 10px;
    margin-bottom: -24px;
    padding: 7px;
    padding-left: 13px;
    padding-right: 1px;
    cursor: pointer;
    padding-bottom: 20px;
    margin-left: 0px; }

    .x208420:hover {
      background-color: #eaf0f8;
    }

 

    .showRecordedBtnDiv {
      background-color: #143051;
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 90%;
      border-radius: 5px;
      margin-left: 5%;
      font-size: 14px;
      text-align: left;
      padding-right: 4%;
      padding-left: 4%;
      cursor: pointer;
      padding-bottom: 4px;
      padding-top: 3px;
      margin-top: -6px;
      margin-bottom: 5px;
    }
    .showRecordedBtnDiv:hover {
      background-color: #112944;
    }

    .showRecLeft {

    }

    .showRecordOnlyBtnTxt {
      color:white;
      font-weight:600;
      margin-top:2px;
    }
    .showRecordOnlyBtnTxtSub {
      color: white;
      font-weight: 400;
      opacity: 0.8;
      font-size: 10px;
      line-height: 12px;
      margin-top: 0px;
      max-width: 190px;
    }
    .showRecRight {

    }

    .recordOnlyKnob {
margin-top:-7px !important
    }

    .tsClockStat {
      font-size: 10px;
      font-weight: 500;
      color: #9cb194;
      max-width: -moz-fit-content;
      max-width: fit-content;
      /* border-radius: 5px; */
      min-height: 16px;
      padding-left: 7px;
      padding-right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      /* background-color: #a8cc9a; */
      border-left: 2px solid #bbcada;
 
  
    }

    .x18318341 {
      margin-top: 5px;
      margin-bottom:4px;
      margin-left:3px
/* margin-bottom:-20px; */
    }
    

    .pendClockStat {

    color: #214978;

    min-height: 20px;
    margin-right: 20px;
    padding-left: 5px;
    }

    .declinedClockStat {
      /* background-color: #e89292; */
      color: #ba0000;
      border-left: 2px solid #ba0000;
    }

    .x2984291 {
      color: white;
    background-color: #2c5580;
    border-color: #143051;
    }

    .tsApprovalStatusDiv {
      display: flex;
      align-items: center;
      margin-top: 0px;
      margin-left: 9px;
      max-height: 22px;
      margin-bottom: 2px;
  
    }

    .approveTsClockBtn {
      color: white;
      background-color: #a8cc9a;
      border: 1px solid #9cb194;
      cursor: pointer;
      font-weight:500;
      min-height:20px;
      border-radius:5px;
      margin-left:5px;
      padding-left:4px;
      padding-right:4px;
      padding-top:2px;
      font-size:11px;

    }

    .approveTsClockBtn:hover {
      background-color: #9cb194;
    }

    .x197492 {
      background-color: #e89292;
      border: 1px solid #c57a7a;
      color:white
    }

    .x197492:hover {
      background-color: #c57a7a;

    }

    .x081981 {
      /* font-weight: 500;
      border: 2px solid #143051;
      border-radius: 5px;
      padding: 7px;
      margin-bottom: -20px;
      background: #214978; */
      color: #ba0000;
      margin-bottom:-10px;
    }

    .declinedTsHeaderColours {
      border: 1px solid #ba0000 !important;
      background-color: #c96565;
    }

    .approvedTsHeaderColours {
      border: 1px solid #3fa55d !important;
      background-color: #65c982;
    }

    .declinedTsBorder {
      border-left: 2px dotted #ba0000
    }

    .approvedTsBorder {
      border-left: 2px dotted #5a9e3f
    }

    .x091131 {
      color:#ba0000;
      border-left: 2px solid #ba0000;
      padding-bottom:3px;
    }

    .x140814 {
      margin-top: -3px;
      /* margin-left: -10px;   */
      }

      .approvedTsTxt {
        color: #82c26a
      }

      .x984292 {
        margin-top: 4px;
        margin-left: 0px;
        max-height: 22px;
            }


            .meJwtUser {
              border-top: 2px solid #bbcada;
              border-bottom: 2px solid #bbcada;
              border-left: 2px solid #bbcada;
              margin-left: 1px;
              padding-right: 2px;
              background: white;
              min-height: 71px;
              padding-left: 5px;
              margin-top: 3px !important;
              padding-top: 3px;
              margin-bottom:-3px
          }

          .x20910841 {
            min-height:63px  !important;
          }



          @media only screen and (max-width: 520px) {
     
          
            .staffRotaBtnMob {
              top: 30px !important;
              right: 22px !important;
            }

            .userSchedMgrAvailBtnMob {
           
              top: 30px;
              right: 125px;
          }

          .staffHoursBtnDynamic {
            top: 11px !important;
            right:-85px !important
          }
          }

          .meJwtUserMob {
            padding-left:0px;
          }

          .tsSuff {
            font-size: 10px;
            /* margin-bottom:12px; */
            margin-top:-2px;
            margin-right:3px;
          }

          .x1839181 {
            /* background: yellow; */
            margin-top:-7px;
          }

          .x109842082 {
margin-top: 6px;
          }


.staffAbsFilterBarRight {
  display:flex;
  align-items: center;
}

.absPerfIcon {
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  padding-left: 9px;
  font-weight: 500;
  padding-right: 9px;
  cursor: pointer;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  max-width: 33px;
  margin-right:8px;
}

.myAbsBtn {
  margin-top:14px;
}

.x10983180 {
  margin-top:24px;
}

.x983184 {
  min-height:150px;
}

.x1038198 {
  margin-left:0px;
}


.x10893011 {
  margin-bottom:4px;
  min-width: 190px;
  max-width: 190px;
  background-position-x: 164px;
}

.paddingLeft5pc {
  padding-left:5% !important
}

.x10924014 {
  margin-left:2px;
}

.wideTitle {
  margin-left:3px;
}

.x2038282 {
  min-width: 150px;
  max-width: 150px;
  background-position-x: 124px;
}


.x1941971 {
margin-top:-110px;
}

.x183103 {
  color: #214978
}

.colorBlue {
  color: #0077ff !important;
  border-color: #0077ff;
font-weight:600
}

  .tsGhostTitle {
    color: #bbcada;
    font-size: 12px !important;
    font-weight: 500;
    text-align: left;
    margin-left: -6px;
    margin-bottom: 4px;
    margin-top: 1px;  }

    .x208492 {
      font-size:12px
    }

    .x13141413 {
      font-weight: 500;
      text-align: left;
      margin-left: 6.5%;
      margin-top:8px;
    }

    .myAvailMonthViewUnderlay {
      
    
        background-color: rgb(122 122 122 / 10%);
      
    }

    .x29489292 {
      background-color: rgb(122 122 122 / 10%);

    }


    .availMonthViewModal {
      background-color: #f4f8fe;
      color: #143051;
      border-radius: 10px;
    
      min-width: 340px;
      max-width: 340px;
    
     /* -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);  */
      cursor: auto;
      min-height:400px;
      animation-name: zoomModalIn;
      animation-duration: 0.1s;
    }

    .availMonthSelBar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 5%;
      padding-right: 6%;
      margin-top: 11px;
      border-top: 1px solid #dde9f9;
      padding-top:10px;
    }

    .availMonthSelArrLeft {
      transform: rotate(180deg);
      min-width: 27px;
      padding: 11px;
      cursor: pointer;
    }

    .availMonthSelArrLeftDesktop:hover {
      /* opacity: 0.8; */
      background-color: #e0ebf8;
      border-radius:5px;
    }

    .availMonthSelArrRight {
      /* transform: rotate(180deg); */
      min-width: 27px;
      padding: 11px;
      cursor: pointer;
    }

    .availMonthSelArrRightDesktop:hover {
      /* opacity: 0.8; */
      background-color: #e0ebf8;
      border-radius:5px;

    }

    .availMonthMonthTitle {
      font-weight: 500;
      color: #214978;
      font-size:14px;
    }

    .monthAvailDayBar {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-weight:500;
      color: #bbcada;
      font-size:12px;
      padding-left:5px;
      padding-right:5px;
      margin-top:14px;
      max-width: 92%;
      margin-left: 4%;
    }

    .monthAvailFooter {
      display: flex;
      justify-content: space-between;
      padding-left:5%;
      padding-right:5%;
      border-top:1px solid #dde9f9;
      min-height:50px;
      align-items: center;
      /* margin-top:30px; */
    }

    .monthAvailSnake {
      /* background-color: orange; */
      min-height:300px;
      /* min-height:fit-content;
      max-height:fit-content; */
    }


    .myAvailWkRow {
    /* border-bottom: 1px solid black; */
    margin-top: 12px;
    min-height: 30px;
    /* background-color: blue; */
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 92%;
    margin-left: 4%;
    cursor: pointer;
    }

    .myAvailWkRow:hover {
      background-color: #dde9f9;
      border-radius:5px;
    }

    .myAvailCalDayItem {
      /* min-width: 14$; */
      font-weight: 500;
      font-size:14px;
      color:#214978;
      display:flex;
      align-items: center;
      justify-content: center;
      min-width: calc(14% - 2.85px);
      flex-direction: column
      /* background-color: grey; */
      
    }

    .monthAvailDayTitle {
      font-weight: 500;
      font-size:14px;
      color:#bbcada;
      display:flex;
      align-items: center;
      justify-content: center;
      min-width: calc(14% - 2.85px);
      /* background-color: grey; */
    }

    .availDayN {
      font-weight: 500;
      font-size:14px;
      color:#bbcada
    }

    .x314562 {
      color: #214978
    }

    .availSubmissionIcon {
      max-width: 9px;
      margin-bottom: 5px;
      margin-top: 3px;    }

      .x18391832 {
        border-radius: 0px 5px 0 0 !important
      }

      .x234223323 {
        position: fixed;
        top: 119px;
        right: 4.12%;
        z-index: 999
      }


      .x3298398 {
        border-radius: 5px 5px 0 0;
      }

      .shiftAmendUnit {
        min-width: 260px;
        background-color: #f4f8fe;
        border: 1px solid #dde9f9;
        border-radius:5px;
        margin-bottom:10px;
      }

      .amendBoldSpan {
        font-weight: 500;
      }

      .shiftAmendType {
        font-size:12px;
        line-height:18px;
        max-width: 80%;
        margin-top:8px;
        margin-left:10px;
        font-weight: 400;
      }

      .shiftAmendBy {
        font-size: 12px;
        max-height: 28px;
        font-weight: 500;
        color: #bbcada;
        margin-left: 10px;
        margin-top: -8px;
            }

            .shiftAmendWhen {
              font-size: 12px;
              max-height: 28px;
              font-weight: 500;
              color: #bbcada;
              margin-left: 10px;
              margin-top: -8px;
              margin-bottom: 4px;
              line-height: 37px
            }

                  .shiftAmendAck {
                    font-size: 12px;
                    max-height: 28px;
                    font-weight: 500;
                    color: #bbcada;
                    margin-left: 10px;
                    margin-top: -5px;
                    /* margin-bottom: 10px; */
                    display: flex;
                    align-items: center;
                       }

                    .acknowledgedAmendTickImg {
                      max-width:12px;
                      opacity: 0.5;
                      margin-right:5px;
                    }


                    .x274831 {
                      min-width: 42px;
                    }

                    .addLeaveModalBody {
                      min-height:540px  !important;
                      max-height:540px  !important

                    }

.shiftInLieuExplain {
  font-size: 12px;
  line-height: 16px;
  max-width: 200px;
  color: #516b8b;
  font-weight: 400;
  margin-left: 23px;
  margin-bottom: 10px;
  margin-top: -5px;
}

.tilLeftX00 {
  margin-left: -18px;
  margin-top: -18px;}

  .marginBottom20px {
    margin-bottom:20px;
  }

  .inLieuMyShiftTxt {
    color: #bbcada;
    font-size:12px;
    line-height:18px;
    margin-bottom:20px;
  }

  .shiftInlieuSpan {
    background-color: #ffffff;
    color: #00ccff;
    max-width: 94px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    /* border-radius: 5px; */
    min-height: 22px;
    margin-bottom: 6px;
    border-left: 2px solid #00ccff;
    font-size: 14px;
    padding-left:8px;
  }

  .declinedTsCol {
    color: #ba0000
  }

  .approvedTsCol {
    color: #a8cc9a
  }

  .x398294822 {
    margin-top: 2px !important;
    min-width: 90px;
    max-width: 90px;
    background-position-x: 68px;
  }

  .x3474782832 {
    min-width: 133px;
    max-width: 133px;
    background-position-x: 110px;
  }

  .partialAbsShiftboxWidth {
    min-width: 149px;
    max-width: 149px;
  }

  .manageClockOption {
    background-color: #b49ce7;
    border: 1px solid #a47fd0;
    min-height: 35px;
    max-height: 35px;
    color: white;
    display: flex;
    align-items: center;
    border-radius: 5px;
    font-weight: 500;
    font-size: 13px;
    padding-left: 10px;
    padding-right: 8px;
    cursor: pointer;
    margin-right: 8px;

  }

  .manageClockOption:hover {
    background-color: #a58fd4;
  }

  .manageClockOptionPen {
    max-width:10px;
    margin-left:6px;
  }

  .x2938 {
    border:none;
    border-bottom: 1px solid #bbcada;
    border-radius:0px;
  }

  .x249842 {
    background-color: #ffffff !important;
    border: 1px solid #f3f3f3;
    border-left: 2px solid #214978;
    border-radius: 0 10px 0px 10px;
    margin-left: 10px;
    border-bottom: 2px solid #214978;    
  }

  .x29834297 {
    background-color: #ffffff !important;
    border: 1px solid #e3e8ee;
    /* border-left: 2px solid #214978; */
    /* border-radius: 0 10px 0px 10px; */
    margin-left: 10px;
    /* border-bottom: 2px solid #214978;     */
  }

  .x2984212 {
    /* font-size:10px; */
  }

  .x92478922 {
    min-height:68px;
    /* margin-top:10px; */
  }

  .x29845922 {
    margin-top:-140px !important
  }

  .x2498293 {
    margin-top:-100px !important
  }

  .x139832 {
    margin-top:110px !important;
    /* margin-bottom:-10px; */
    /* background-color: blue; */
  }

  .x9242323 {
    margin-top:100px !important
  }

  .wholeShiftToilInfo {
    color: #516b8b;
    font-weight: 400;
    text-align: left;
    font-size: 12px;
    max-width: 150px; 
  line-height:16px;
  }
.toilAvailToTake {
  font-size:12px !important;
  opacity: 0.7;
  white-space: nowrap;

}

    .x198319821 {
   min-width: 170px;
   max-width: 170px;
   background-position-x: 147px;
    }

  .zoomClockCardOutUnit {
    transform: scale(0); /* Adjust the scale value for the desired zoom level */

  }

  .clockDueButNotActiveIndicate {
    min-width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;  }

    .didNotClockTxt {
      font-weight: 500;
    padding: 2px;
    font-size: 11px;
    color: #ba0000;
    padding-left: 6px;
    padding-right: 6px;
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-top: -1px;
    border-right: 2px solid #ba0000;
    margin-bottom: 0px;
       }

       .todayAvailItemMark {
        background:#dde9f9;
        border-radius:5px;
        /* border:1px solid #bbcada; */
        /* margin-bottom:-1px; */
       }

       .tsItemUnit {
        margin-bottom:10px;
       }

       .x23256786 {
        margin-left:8px;
        margin-top:-1px;
       }

.x113198391 {
  /* background-color: blue; */
  /* margin-left:12% */
}

.x183989842 {
  /* background-color: blue !important; */
  margin-top:0px;
 
}

.x19374872 {
  min-height: calc(100vh - 203px);
  max-height: calc(100vh - 203px);
  padding-bottom:40px
}

.clockCardsDesktopFiltSplitter {
  min-height:1px;
  min-width:100%;
  background-color: #bbcada;
}

.x8765433 {
  /* background:orange; */
  margin-top:-4px;
}

.x183981982 {
  max-width: 130px !important;
  min-width: 130px !important;}

  .x283219831 {
    font-size:12px;
  }

  /* .font10 {
    
  } */

  .viewApprovedTsItem {

    max-width: fit-content;
    padding-left:8px;
    padding-right:8px;
    color:white;
    font-weight:600;
    border-radius:5px;
    min-height:30px;
    max-height:30px;
    display:flex;
    align-items:center;
    justify-items:center;
    cursor: pointer;
    float:right;
    margin-bottom:8px;
    margin-right:6px;
    margin-left:4px;
    margin-top:10px;
    font-size:12px;
  }

  .viewTsItemPopUp {
    max-width:14px;
    margin-left:5px;
  }

  .x203912312 {
    display: flex;
    justify-content: space-between;
    min-width:100%
  }

  .x3895724 {
    font-size: 12px !important;
    line-height: 16px !important;
    font-weight: 400 !important;
    padding-right: 10px;
    max-width: 240px;
    margin-bottom: 30px !important;
  }

  .x1283983 {
    font-weight:500;
  }

  .x83198193 {
    background-color: #214978
  }

  .otherClockDivContainer {
    border: 2px solid #bbcada;
    display: flex;
    border-radius: 10px;
    max-width: 90%;
    margin-top: 0px;
    margin-left: 15px;
    min-height: 80px;
    justify-content: space-between;
  
  }

  .otherClockRight {
    border-left: 2px dashed #bbcada;
    /* border-radius: 10px 0 0 10px; */
    /* margin-top: -2px; */
    min-height: 80px;
   min-width:46%;
   max-width: 48%;;
    background: #f4f8fe;
    cursor: pointer;
    /* padding-bottom:20px; */
   }

   .otherClockRight:hover {
    background-color: #e5eaf2;
   }

   .otherClockCardRow {
    border-bottom:1px solid #e0ebf8;
    padding-bottom:20px;
   }

   .x08493 {
    color: #bbcada !important
   }

   .subClockStartEndTitle {
    font-size: 10px;
    font-weight: 400;
    margin-left: 8px;
    margin-right: 10px;
    margin-top: -10px;
    min-width: 62px;;
   }

   .subClockStartEndValue {
    font-size: 14px;
    font-weight: 500;
    margin-left: 8px;
    margin-right: 10px;
    margin-top: -28px;
    margin-bottom: -8px;
   }
   .subClockDivider {
    min-width: 90%;
    max-width: 90%;
    margin: 6px auto;
    margin-bottom: -9px;
    min-height:1px;
    background-color: #bbcada;
   }

   .subClockTitle {
    font-weight: 500;
    font-size: 11px;
    /* margin-left: 10px; */
    min-width: 100%;
    /* margin-top: -10px; */
    /* margin-right: 10px; */
    border-bottom: 2px solid #bbcada;
    line-height: 18px;
    min-height: 30px;
    display: flex;
    align-items: center;
    padding-left: 6px;
   }

   .otherClockLeft {
    min-width: 54%;
    /* background: #f4f8fe; */

    /* background-color: blue; */
   }



   .x123893 {
    min-width: 44%;
    cursor: pointer;
        background: #f4f8fe;

   }

   .x123893:hover {
    background-color: #e5eaf2;

   }
.x398142 {
  min-width: 56%;
  cursor: default;
  background-color: #fff !important; 


}

.x398142:hover {
  background-color: #fff !important; 
}
   .ccTitleSpan {
    color: #bbcada;
    margin-right:5px;
    
   }

   .subClockDurStrip {
    background: #fff;
    max-width: fit-content;
    font-weight: 500;
    font-size: 11px;
    border-radius: 5px;
    max-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 4px;
    padding-right: 4px;
    margin-top: -1px;
float:right;
margin-right:6px;
border: 1px solid #bbcada;   }


.oneOfTwoOneClock {
  border-bottom: none  !important;
  padding-bottom:7px
}

.oneOfTwoOneClock:hover {
  padding-bottom:5px

}

.oneOfTwoTwoClock {
  border-top:1px dotted #bbcada !important;
  margin-top: -5px;
  border-radius: 0 0 5px 5px;
}

.oneOfTwoTwoClock:hover {
  border-top:1px dotted #bbcada !important;

}

.x937179343:hover {
  padding-top:0px;
  padding-bottom:6px

}


.x49578274 {
  max-width: 10px;
  min-width: 10px;
  margin-right: 7px;}


  .x3848848482 {
    min-height:181px; 
    margin-bottom:-50px;
  }

  .x485487597252 {
    background-color: #8ea7c5;
    min-width: 19px;
    max-width: 19px;
    min-height: 40px;
    margin: 0 auto;
    float: left;
    margin-left: 100px;
    border-left: 1px solid #859dbb;
    border-right: 1px solid #859dbb;
    display:none
  }

  .subClockStatus {
    font-size:12px;
    font-weight: 500;
    margin-top:-14px;
    margin-bottom:-6px;
    margin-left:8px;
    color: #bbcada
  }

  .ccOffDs {
    color: #bbcada;
    font-size: 10px;
    max-height: 20px;
    line-height: 10px;
    margin-top: -3px;
    margin-bottom: 7px;
    display: flex;
    align-items: center;
    /* margin-right: 5px; */
 }

.ccOffDsBtn {
  max-width: 16px;
  margin-left:3px;
  border:1px solid #bbcada;
  border-radius:3px;
  cursor: pointer;
}

.ccOffDsBtn:hover {
  background-color: #e9f3fe;
}

.ccOffDsBtn:first-of-type {

  margin-left:5px;
}



.absenceLogPopup {
  max-width: 13px;
  margin-left: 3px;

}

.selfLogAbsColours {
  background-color: #e89292;
  border: 1px solid #9e7373;}

  .selfLogAbsColours:hover {
    background-color: #da8787;
  }

  .x13456783212 {
    resize:none;
    min-height:120px  !important;
   padding:10px;
    margin-bottom:20px;
  }

  .x4978284432 {
    background-color:#0077ff !important;
    border:#0469de 1px solid !important
  }
  .x4978284432:hover {
    background-color: #036ce4;
  }

  .x1389498523 {
    color: #143051;
    font-weight: 500;
    font-size: 15px;
    margin-top: 8px;
    margin-bottom: 4px;
    line-height: 19px !important;
    padding-right: 40px; }

    .x3928423 {
      font-size: 12px;
      color: #bbcada;
    }

    .x39284232 {
      font-size: 12px;
    color: #143051;
    font-weight: 400;
    }

    .canxSelfAbsenceBtn {
      border: solid 1px #bbcada;
      background-color: #cbd7e6;
      min-width: 70px;      
    }

    .canxSelfAbsenceBtn:hover {
      background-color: #bbcada;      

    }

    .x48924892875 {
      border: 2px solid #ba0000;
      border-radius: 5px;
      margin-top: -3px;
      background: white;    }

      .selfLogApproval {
        color: #143051;
        font-size: 10px;
        border-left: 2px solid #143051;
        padding-left: 7px;      }

.reportedAbs1 {
  font-size: 12px;
  margin-left: 5px;
  color: #516b8b;
  font-weight: 400;
  border-left: 2px solid #516b8b;
  padding-left: 7px;
  max-height: 20px;
  line-height: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
}

  .reportedAbs2 {
    font-size: 14px;
    margin-left: 5px;
    line-height: 16px;
    color: #214978;
    margin-top: -6px;
    margin-bottom: 14px;
    padding-right: 13px;
    font-weight: 400;
  }

  .x24859382 {
    margin-top:-3px;
    border: 2px solid #ba0000 !important
  }

  .selfLogAbsOptionsDiv {
    border-top: 1px solid #bac5d3;
    max-width: 95%;
    padding-bottom: 13px;  }

  .selfLogAbsOption {
    /* border: 2px solid #8ea7c5; */
    border-radius: 5px;
    /* background: #bbcada; */
    color: white;
    max-width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    max-height: 30px;
    display: flex;
    align-items: center;
    margin-top: 9px;
    background-color: #0af;
    border: 1px solid #0f94d6;
    cursor: pointer;
  }

  .selfLogAbsOption:hover {
    background-color: #05a5f5;
  }

  .x1998423 {
    border: solid 1px #bbcada;
    background-color: #cbd7e6;

  }

  .x1998423:hover {
    background-color: #bbcada;
  }

  .x09897534 {
    font-size: 14px;
    border-left: 2px solid #ba0000;
    max-height: 50px;
    display: flex;
    align-items: center;
    padding-left: 7px;
    margin-top: 10px;
    margin-bottom: -2px;
    line-height: 16px;
    padding-right:40px;
    color: #ba0000;  }

    .selfLogAbsIcon {
      background-color: #ba0000;
      padding: 2px;
      padding-top:4px;
      padding-bottom: 4px;
      max-height: 18px;
      border-radius: 3px;    }


      .x2894895834 {
        min-width: 344px;
        max-width: 344px;
        justify-content: flex-end;
      }

      .reportAbsColMySh {
        color: #fff;
        background: #ff8383;
        border: 1px solid #c02f2f;      }



        .selfLoggAbsColours {
          background-color: #ba0000;
          border-color:#8d0000
        }

        .timeHoursImgAbs {

          background-color: #ba0000;
          border-radius:3px;
          padding:4px;
          border:#8d0000 1px solid

        }


        .x19839842 {
          min-width: 100vw  !important;          max-width: 100vw  !important;

        }

        .deleteShiftBin {
          max-width: 15px;
        }

        .x991199 {
          max-width: 158px;
          min-width: 158px;
          height: 35px;
          background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
          background-position-x: 136px

        }

        .x9098 {
          margin-left:15px !important;
        }

        .x09838444 {
          margin-top:-73px;
        }

        .x9991112293312 {
          min-height:90px
        }

        .x18381812 {
          margin-top:8px
        }

        .shiftChangeFlex {
          display: flex;
          justify-content: space-between;
align-items: center;  
border-top:1px solid #e6eaee;
}

        .oldShChange {
          border:1px solid #bbcada;
          border-radius:5px;
          max-width: 180px;
          color: #143051;
          padding-left:8px;

          
        }

        .oldShChTitle {
          font-size:12px;
          font-weight:500;
          max-height: 30px;
        }

        .oldShChValue {
          font-size:12px;
          font-weight:500;
          max-height: 30px;
        }

        .x0912844 {
          line-height: 23px;
          padding-bottom: 16px;
        }

        .x4264723 {
          padding-bottom: 15px;
          line-height: 24px;        }

  .x874722 {
    line-height: 28px;
    padding-bottom: 9px;  }

    .x11221 {
      box-shadow:0px 0px 0px #fff !important;
      /* border:1px solid #efefef; */
      cursor: default;
      border-radius:5px;
      padding-left:0px;
      padding-right:0px;
      min-width: 120px !important;
      max-width: 100px  !important;
    }

    .x11221:hover {
      /* border: 1px solid #efefef */
    }

    .chMgrTsMade {
      font-size:12px;
      margin-left:5px;
      color:#bbcada;
      
      font-weight: 500;
      line-height:19px;
      margin-bottom:4px;
    }

    .x323425 {
      color:#bbcada
    }

    .x12144 {
      color:#214078;
    }

    .loadingAvail {
      opacity: 0.2;
      pointer-events: none;
    }

    .x9939232 {
      font-size:10px;
    }

    .x88381831 {
padding-right: 20px !important    }


.addTilInfoTxt {
  font-weight: 400;
  font-size: 12px;
  color: #516b8b;
  line-height: 16px;
  text-align: left;
  padding-right: 24px;
}

.addShiftMidContainerTil {
  min-height:400px !important;
  max-height:400px !important;
  
}

.plusDxx {
  color:#bbcada;
  font-size:14px
}

.mobTeamShiftBgColour {
  background-color: #403a8e !important;
  /* border-top: 1px solid #403a8e */

}

.mobMyRotaBgColour {
  background-color: white !important;
}

.noFiltTagsTxt {
  color: #bbcada;
  max-width: 190px;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  margin-top: 14px;
  margin-left: 15px;}

  .mobModalWeekTitle {
margin-left:55px;  }


.x389248754 {
margin-left:17px;}

.x28394293 {
margin-left:52px}

.x29393883423 {
border-left: 2px solid #a8cc9a;
color: #a8cc9a
}

.x2456732 {
  margin-top:4px;
  border-top:1px solid #fff;
  padding-top:4px;
}

.usrUnavail {
  color:#516b8b;
  font-weight:500 !important;
  font-size:12px !important;
  cursor: pointer;
  /* border:1px solid #bbcada; */
  max-width: fit-content;
  float: right;
  border-radius:5px;
  padding-left:5px;
  /* padding-right:5px; */
 
}

.usrUnavail:hover {
  color: #143051;
}

.z882132 {
  padding-right:0px  !important;
}

.chMgrDivCtaDiv {
  display: flex;
  justify-content: flex-start;
  margin-top:10px;
}

.approveShCh {
  min-height:35px;
  max-height:35px;
  display:flex;
  align-items:center;
  justify-content: center;
font-weight:500;
min-width: 100px;
border-radius:5px;
background-color: #a8cc9a;
border: 1px solid #9cb194;
    color:white;
    cursor: pointer;
    font-size:14px;
}

.approveShCh:hover {
  background-color: #9cb194;
}


.declineShCh {
  min-height:35px;
  max-height:35px;
  display:flex;
  align-items:center;
  justify-content: center;
font-weight:500;
min-width: 100px;
border-radius:5px;
background-color: #e89292;
border: 1px solid #9e7373;
    color:white;
    cursor: pointer;
    margin-left:10px;
    font-size:14px;

}

.declineShCh:hover {
  background-color: #da8787;
}





.newOldDurTxt {
  font-size:12px;
  font-weight:500;
  margin-left:5px;
  line-height:24px;
}

.newOldDurValueSpan {
  color:#859dbb;
}

.calLimItem {
  /* background:orange; */
  min-width: 40px;
  max-width: 40px;
  min-height:66px;
  /* border-left:1px solid black; */
  /* border-right:1px solid blue */
}

.staffCalLimTxt {
  color:white;
  font-weight:500;
  font-size:14px
}

.staffCalDisabledIndicate {
  font-size:10px;
  opacity: 0.65;
}

.x29283824122 {
  cursor: pointer;
  padding: 4px;
  border: 1px solid #30305f;
  border-radius: 5px;
  min-width: 22px;
  max-width: 22px;
  background-color: #7a7ac1;
}

.x1738h121 {
  margin-top:4px
}

.calLimItemLimEnabled {
  min-width: 80px;
  max-width: 80px;
  min-height:108px;
  /* border-left:1px solid #bbcada; */
  border-right:1px solid #716994;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.viewTeamDivItemLimEnabled {
  color: white;
  margin-left: 20px;
  margin-right: 20px;
  min-width: 40px;
  max-width: 40px;
  font-size: 9px;
  font-weight: 500;
  line-height: 9px;
  padding-top: 1px;
  padding-bottom: 1px;
  margin-bottom: 3px;

}

.calCommDivLimEnabled {
  min-width: 80px;
  border-right:1px solid #bbcada;

}

.staffCalDayLtrItemLimEnabled {
  /* background:purple; */
  min-width: 80px;
  max-width: 80px;
  border-right:1px solid #bbcada;

}

.staffCalTeamSnakeBlockLimEnabled {
  min-width: 80px;
  max-width: 80px;
  border-right:1px solid #bbcada;
}

.xx372874821 {
  border-left:none;
}

.x183291x29191 {
  margin-top:5px;
  max-width: 24px;}

  .staffCalLimTitle {
    color:white;
    font-size:11px;
    font-weight:500;
    text-align:left;
    margin-top:7px;
    text-align: center;
    /* margin-left:4px; */
  }

  .calLimitPowerImg {
    background-color: #6a8bb1;
    max-height: 26px;
    min-height: 26px;
    min-width: 26px;
    max-width: 2px;
    border-radius: 5px;
    border: 1px solid #143051;
    cursor: pointer;
    opacity: 0.7;
    margin-top: 4px;  }

    .calLimitPowerImg:hover {
      background-color:#668983
    }


  .calLimPowerOn {
    background-color: #7fbb67;
    border: 1px solid #2a501b;

    margin-top: 2px;
    max-height: 21px;
    min-height: 21px;
    min-width: 21px;
    max-width: 21px;
    border-radius: 4px;
    opacity: 1;
    cursor: pointer;  }

    .calLimPowerOn:hover {
      background-color: #7fbb67;
    }

    .x00334422 {
      background-color: #7fbb67;
      border: 1px solid #2a501b;
    }

    .calLimDrop {
      color: #fff;
      border-radius: 3px;
      font-size: 11px;
      padding-left: 4px;
      text-align: left;
      max-width: 48px;
      min-width: 48px;
      line-height: 19px;
      height: 21px;
      /* background: url(/static/media/downArrowWhiteTeamSched.358e1815.svg) no-repeat right #7b7aab; */
      border: 1px solid #143051;
      -webkit-appearance: none;
      font-weight: 500;
      cursor: pointer;
      margin-top: 3px;
      margin-left: 2px;
      background: url(/static/media/downArrowWhite2.887d57ce.svg) no-repeat right #7b7aab;
      background-position-x: 34px;

    }

    .calLimPowerAndDrop {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top:2px;
    }

    .calDayLimInfo {
      font-size: 10px;
    color: white;
    opacity: 0.6;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 3px;
    padding-left: 5px;
    padding-right: 5px;
    }

    .calDayLimInfoJt {
      margin-top:12px;
      max-width:100px;
    }
    .calTeamCurrOff {
      color: white;
      font-weight: 500;
      margin-top: -2px;
      font-size: 12px;    }

      .xi138187 {
        margin-top: 5px;
        border-top: #716994 1px solid;
        padding-top: 1px;      }

        .x9ood23 {
          opacity: 0.2;
          pointer-events: none;
cursor: default;        }

.calTeamCurrOffLimExceeded {
  background-color: #f07b7b;
  /* border-color: #460f0f; */
  padding-left: 6px;
  padding-right: 6px;
  margin-bottom:3px;

  font-size:12px;
  /* padding-top: 2px; */
  padding-bottom: 2px;
  max-height:16px;
  line-height:13px;
  border-radius:5px;
  border: 1px #e56767 solid;}

  .c29838 {
    margin-top:20px;
  }

  .limArrow {
    max-width: 10px;
    opacity: 0.7;
    margin-top: 10px;  }

    .jtLimRow {
      /* background:orange; */
      min-height:30px;
      display: flex;
      max-height:30px;
      padding-left:10px;
    }
    .staffCalJtPushDownJtLimActive {
      /* margin-bottom:30px; */
      min-height:66px;
      display: flex;
      align-items: flex-end;
      padding-bottom:10px;  
      /* margin-top:30px; */
    }

    .jtLimBox {
      /* border-left:1px solid black; */
      border-right:1px solid #bbcada;

      min-width: 80px;
      max-width: 80px;
    }
    .jtLimitPowerIcon {
      background-color: #bfd1e6;
      max-height: 21px;
      min-height: 21px;
      min-width: 21px;
      max-width: 21px;
      border-radius: 3px;
      border: 1px solid #98b8dc;
      cursor: pointer;
    }

    .calJtBox {
      display: flex;
      align-items: center;
      min-width: 100%;
      min-height: 100%;
      justify-content: space-between;
      padding-left: 4px;
      padding-right: 6px;
      /* border-right: 1px solid #bbcada; */
      /* border-left: 1px solid #bbcada; */
  

    }

    .calJtBoxTxt {
      color:#6a98d1;
      font-weight:500;
      font-size:10px;
      margin-left:4px;
    }

    .jtPowerLimOn {
      background-color: #7fbb67;
      border: 1px solid #5a9e3f;
      min-width: 14px;
      max-height: 21px;
      min-height: 21px;
      margin-top: 4px;
      margin-right: 4px;
      }

.calJtBtnsDiv {
  display: flex;
  align-items: center;
  margin-left:0px !important;
  margin-top:-2px;
  /* background-color: white; */
}

.calJtBtnMinus {
  max-width:20px;
  border-radius:4px;
  cursor: pointer;

}

.calJtBtnMinus:hover {
  background-color: white;
  border:1px solid #ececec
}

.calJtBtnsMidTxt {
  font-size: 13px;
  color: #bbcada;
  margin-left: 3px;
  margin-right: 3px;
  font-weight: 500;
}

.jtLimWidth {
  min-width:130px;
  max-width: 130px;
}

.jtLimDsViewWidth {
  margin-left: 45px;

  margin-right: 45px;}

  .offSmall {
    font-size:8px;
    font-weight:400;
    margin-left:-1px
  }

  .navyDivider {
    background-color: #143051 !important;
    opacity: 1 !important;
    min-height:4px;
  }
  .hoveredIndexCal {
    /* background-color: #e7e7f3; */
    /* border-left: #143051 solid 3px !important;
    border-right: #143051 solid 3px  !important;  */

  }

  .closedTxtColour {
    color:#abb0c6
  }

  .closedLimBg {
    background-color: #635e7f;
  }

  .xj1389 {
    margin-left:0px !important;
    margin-right:0px  !important
  }

  .xxx198294 {
    margin-left:-6px !important
  }

  .printRotaBtn {
    margin-right: 8px;
    margin-top: 8px;
    min-height: 35px !important;
    margin-left: -1px !important;
  }

  .printRotaBtn:hover {
    color: #859dbb !important
  }
 

  .dayTitleHeadline {
    margin-right:7px;
  }

  .hh88 {
    /* background-color: black; */
  }

  .hh99 {
    /* min-height:10px */
  }

  .shareRotaPassInput {

    color: #214978;
    min-height: 35px;
margin-top:8px;
    font-weight: 500;
    margin-left: 5%;
    min-width: 150px;
    font-size: 16px;
    border: 2px solid #dde9f9;
    border-radius: 5px;
    padding-left: 7px;
    background-color: #fdfeff;
    margin-bottom:12px;
  }

  .sharePassDiv {
    display: flex;
    align-items: center;
  }

  .showSharePassImg {
    background-color: #8ea7c5;
    border: 1px solid #516b8b;
    border-radius: 5px;
    padding: 5px;
    max-height: 30px;
    margin-top: -5px;
    /* margin-left: 8px; */
    cursor: pointer;
  }

  .showSharePassImg:hover {
    background-color: #859dbb;
  }

  .showPassViewShare {
    background-color: #214978;
    border: 1px solid #143051
  }

  .showPassViewShare:hover {
    background-color: #143051;
  }

  .shareRotaCta {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 68px;
    padding-left: 6px;
    padding-right: 6px;
    min-height: 35px;
    max-height: 35px;
    color: #fff;
    border: solid 1px #bbcada;
    background-color: #cbd7e6;
    border-radius: 5px;
    cursor: pointer;  }

    .shareRotaBack {
      padding-left: 3px;
      padding-right: 3px;
      min-width: 57px;
    }
    .shareRotaCta:hover {
      background-color: #bbcada;
    }

    .marginLeft8 {
      margin-left:8px;
    }

    .sharablelink {
      color: #214978;
      font-size: 13px;
      margin-left: 15px;
      font-weight: 500;
      margin-top: 11px;
      margin-bottom: 20px;
      -webkit-user-select: text;
      user-select: text;
      cursor: text;
      border: 2px solid #bbcada;
      border-radius: 5px;
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 10px;
      padding-right: 10px;
      background: #f4f8fe;  }

    .p183141 {
     color: white;
     font-size: 12px;
     font-weight: 500;
     min-width: 45px;
     display: flex;
     justify-content: center;
     height: 28px;
     margin-top: 0px;
      }

    .copiedLink {
      color: #143051;
      font-size: 12px;
      font-weight: 500;
      margin-top: -8px;
      margin-left: 12px;    }

      .x384829x {
        min-width: 96px;
      }

      .saveRotaPass {

        background-color: #00ccff;
        border-color: #05aaff;
        margin-left: 5px;
        padding: 6px;
      }

        .saveRotaPass:hover {
          background-color: #00a6ff;
        }

        .shareRotaIconsDiv {
          display: flex;
          justify-content: space-between;
          min-width: 212px;
          max-width: 212px;
          margin-left: 16px;
          margin-bottom: 10px;
          margin-top: -10px;
        }

        .shareRotaShareBtn {
          border: solid 1px #0af;
          background-color: #00ccff;
          display: flex;
          align-items: center;
          height: 28px;
          border-radius: 5px;
          font-weight: 500;
          font-size: 12px;
          padding-left: 7px;
          padding-right: 4px;
          cursor: pointer;
          color:white !important
        }

        .shareRotaShareBtn:hover {
          background-color: #00a6ff;
          
        }

        .shareRotaIconImg {
          max-width: 14px;
          margin-left:4px
        }
    
        .emailWhite {
          max-width: 12px;
        }

        .x248928742 {
          margin-left: 8px;
          /* min-width: 76px !important; */
          padding-left: 8px;
          padding-right: 8px;        }

          .shareRotaInfoWarn {
            font-size: 12px;
            color: #859dbb;
            margin-left: 16px;
            margin-top: 10px;
            padding-right: 30px;
            border-left: 2px solid #859dbb;
            padding-left: 10px;
          }

          .rotaTitleStandalone {
            font-weight:600;
            color:#143051;
    

        margin-top: -12px;
        margin-left: 19px;
        font-size: 18px;
          }

          .rotaTitleStandaloneMob {
            margin-left:21px
          }


          .rotaTitleStandaloneMobSmall {
            font-size: 14px;
            line-height: 13px;
            /* max-width: 70px; */
            margin-top: -17px;
            margin-left: 21px;
          }
           


.standaloneRotaStrip {
  position: fixed;
  top: 33px;
  left: 21px;
  font-size: 12px;
  color: #516b8b;
  font-weight: 500;
}

.standaloneLoggedIn {
  height: 35px;
  font-weight: 500;
  padding-left: 10px;
  text-align: right;
  line-height: 18px;
  background: #214978;
  border: 1px solid #143051;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  padding-right: 10px;
  font-size: 14px;
  cursor: pointer;
}

.standaloneLoggedIn:hover {
  background-color: #143051;
}

.frLogoRota {
  max-width: 100px;
  cursor: pointer;
  margin-top:8px;
}
     

.shareLinkBg {
  background-color: #dde9f9;
}

.linkNotActive {
  font-size: 12px;
  color: #496375;
  margin-left: 23px;
  padding-right: 30px;
  margin-bottom: 35px;
  margin-top: 30px;
}

  .linkNotYetActiveIndicate {
    color: #bbcada;
    font-weight: 500;
    font-size: 14px;
    height: 24px;
    margin-left: 14px;
    border-left: 2px solid #bbcada;
    display: flex;
    align-items: center;
    padding-left: 10px;
    margin-top: 5px;  }

    .linkActive {
      color: #bcdabb;
      border: none;
      padding-left: 0px;
      font-size: 12px;    }

      .inactiveRotaLinkHeroImg {
        max-width: 500px;
        margin-top:30vh;
      }

      .inactiveRotaLinkHeroImgMob {
        /* width: 400px !important; */
        width: 75% !important;

      }

      .rotaLinkBrokenTxt {
        font-size:24px;
        font-weight: 500;
        margin-top:30px;
        color:#143051
      }

      .shareGreyImg {
        max-width: 12px;
        margin-left: 5px;      }

        .x3289292d {
          max-width: 14px;
        }

        .x3289292x {
          max-width: 13px;
          margin-left:7px;
        }

        .addLeaveMyRota {font-size:24px;
        color:#bbcada;
        font-weight: 500;
        margin-left:5px;
        margin-top:-8px;
        height:22px;
        display: flex;
        align-items: center;
        justify-content: center;
        width:22px;
        border:1px solid #fff;
        border-radius:5px;
        margin-right:5px;
padding-bottom:3px;
cursor: pointer;
/* background-color: #d1dfee; */

        }

        .addLeaveMyRotaLarge {font-size:30px;
          color:#bbcada;
          font-weight: 500;
          margin-left:5px;
          margin-top:-8px;
          height:42px;
          display: flex;
          align-items: center;
          justify-content: center;
          width:62px;
          border:1px solid #fff;
          border-radius:5px;
  padding-bottom:3px;
  cursor: pointer;
  /* background-color: #d1dfee; */
  
          }

          .addLeaveMyRotaLarge:hover {
            background-color: #f6f9ff;
            border:1px solid #cbd7e6;
          }

        .addLeaveMyRota:hover {
          background-color: #f6f9ff;
          border:1px solid #cbd7e6;
        }

        .reqsBtnSub520 {
          position: fixed;
    top: 74px;
    right: 14px;
        }

        .inactRotaLogo {
          max-width: 19px;
          margin-top:68px;
          margin-bottom:-33px;
          margin-left:64px;
        }
        .inactRotaLogoTxt {
          max-width: 120px;
          /* margin-top:-10px; */
          /* margin-top:2px; */
        }

        .viewDayPopUp {
          max-width: 12px;
          margin-left:3px;
          opacity: 0.8;
        }

        .bulkModalFooterLeft {
          display: flex;
          align-items: center;
        }

        .x24897277101 {
          margin-left: 10px;
          border: solid 1px #bbcada;
          background-color: #cbd7e6;
        }

        .x24897277101:hover {
          background-color: #bbcada;
        }

        .editedJtLimBubble {
          min-width: 4px;
          background-color: #00ccff;
          min-height: 35px;
          border-radius: 3px;
          margin-right: 5px;
        }
        .colorWhite {
          color: white !important;
        }

        .rotaPassInputDiv {
          /* display: flex; */
          /* align-items: center; */
          max-width: 250px;
          margin: 250px auto;
        }

        .rotaPassTitle {
          font-weight: 500;
          color: #214978;
          font-size: 14px;
          margin-bottom: 10px;        }

          .rotaPassInputter {
            color: #214978;
            min-height: 35px;
            /* margin-top: 8px; */
            font-weight: 500;
            /* margin-left: 5%; */
            min-width: 150px;
            font-size: 18px;
            border: 2px solid #dde9f9;
            border-radius: 5px;
            background-color: #fdfeff;
        text-align: center;
          }

        .rotaPassEnter {
          border: solid 1px #0af;
          background-color: #00ccff;
          display: flex;
          align-items: center;
          height: 35px;
          border-radius: 5px;
          font-weight: 500;
          font-size: 14px;
          padding-left: 13px;
          padding-right: 14px;
          cursor: pointer;
          color: white !important;
          cursor: pointer;
          width: fit-content;
          margin: 17px auto;
        }

          .rotaPassEnter:hover {
            background-color: #00a6ff;
          }

          .wrongPassRota {
            color: #bbcada;
            font-size: 14px;
            font-weight: 500;
            margin-top: 10px;          }

            .capsLockTxt {
              color: #bbcada;
              font-weight: 500;
              font-size: 12px;
              margin-top: 8px;
              margin-bottom: -7px;            }


              .x237858728128121:hover {
                background-color:#e7e7e7  !important
              }


              .x10839242782d2:hover {
                background-color:#f7f7fd  !important
              }


.x2831981s1981 {
  background-color: #f4f8fe;
  color: #516b8b;
  line-height: 17px;
  min-height: 17px;
  border: 1px solid #cbd7e6;
  margin-top: 5px;
  border-radius: 3px;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 0px;
  font-weight: 500;

}              

.spanNoBreakLine {
  white-space: nowrap;
}

.myShiftItemMarginRight {
  margin-left:4px;
}

.x9090zz {
  margin-left:-4px
}

.collectionWithoutAvail {
  /* background-color: blue; */
  min-height: 92px !important;
  max-height: 92px !important;
margin-bottom: 9px;
}

.secondaryWithAvail {
  /* background-color: yellow; */
  /* min-height:86px !important;
  max-height:86px !important; */
  /* margin-top:-1px; */
}


.secondaryWithOutAvail {
  min-height: 85px !important;
  max-height: 85px !important;
}

.jtUserWrap {
  /* background-color: pink; */
  /* border-bottom:1px solid black; */
  min-height:100px !important
}

.schedItemHeight {
  /* background-color: pink; */
  /* border-bottom:1px solid black; */
  min-height:100px !important;
  
}

.v7178273 {
  /* background-color: blue; */
  margin-top: 3.5px;

}

.x91dd9 {
  /* background-color: purple !important; */
  margin-top:20px;
}

.x9910ss {
  /* background:yellow; */
  margin-top: -12px !important;
}
.hoveredUserRow {
  /* background-color: yellow !important;
  max-height:100px  !important;
  min-height:100px  !important; */
  /* margin-bottom:20px; */
  /* margin-top:-20px; */
  /* margin-bottom:20px; */

}

.hoveredUserRow:hover {
  /* background-color: #e0ebf8; */
}

.bulkEditLimitModalBoxMob {
  /* min-height:90vh;max-height:90vh; */
}

.templateUnitName {
  font-size:14px;
}

.activeTsInfo {
  font-weight: 500;
  font-size: 12px;
  color: #859dbb;
  line-height: 17px;
  text-align: left;
  margin-left: 24px;
  margin-top: 13px;
  margin-bottom: -7px;
}

.itemNameTabImg {
  max-width: 20px;
  min-height:20px;
  max-height:20px;
  min-width: 20px;
  object-fit: cover;
  border-radius: 3px;
  margin-right: 8px;}

  .aa22 {
    position: fixed;
    color:white;
    top:0;left:0;
    background-color: blue;
    height:20px;
    width:100px;
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modalSwiper {
    min-height: 3px;
    min-width: 50px;
    max-width: 50px;
    background-color: #bbcada;
    margin: 0 auto;
    margin-top: -11px;
    border-radius: 5px;
    margin-bottom: 8px;
  }
  .modalSwiperMakeLeaveReq {
    margin-top: 9px;
    margin-bottom: -12px;
  }

  .mobMenuModalSwiper {
    margin-top:10px;
    background-color: #143051;

  }

  .modalSwiperClockTimeline {

    margin-top: 9px;
    margin-bottom: -12px;
  }
  .modalSwiperActiveClocks {

    margin-top: 9px;
    margin-bottom: -12px;
  }
  .modalSwiperDecline {

    margin-top: -8px;
    margin-bottom: 3px;
  }
  .modalSwiperStaffAllow {

    margin-top: 9px;
    margin-bottom: -12px;
  }
  .modalSwiperAddTags {

    margin-top: 9px;
    margin-bottom: -12px;
  }
  .modalSwiperLogAbs {

    margin-bottom: 1px;
    margin-top: -6px;
  }
  .modalSwiperDaycomm {
    margin-top: 9px;
    margin-bottom: -12px;
  }

  .modalSwiperViewUserDay {

    margin-top: 9px;
    margin-bottom: -12px;
  }



  .modalSwiperAddAvail {
    margin-top: 9px;
    margin-bottom: -14px;
  }

  .modalSwiperAddLeaveModal {
    margin-top: 2px;
    margin-bottom: -9px;
  }

  .slideUp {
    animation-name: moveEditTeamUserBoxUp !important;
    animation-duration: 0.1s !important;
   

  }
  @keyframes moveEditTeamUserBoxUp {
    from {
      bottom: -100%;
      opacity: 0;
    }
    to {
      bottom: 0;
      opacity: 1;
    }
  }

  .modalSwiperAddShiftModal2 {
    margin-top: 7px;
    margin-bottom: -14px;
  }

  .modalSwiperAddUser {
    margin-top: -8px;
    margin-bottom: 1px;
  }

  .modalSwiperBulkLimits {
    margin-top: 8px;
    margin-bottom: -12px;
  }

  .modalSwiperClockCard {
    margin-top: -12px;
    margin-bottom: 8px;
  }

  .modalSwiperClockOn {
    margin-top: 7px;
    margin-bottom: -8px;
  }

  .closeDaysSwiper {
    margin-top: 7px;
    margin-bottom: -11px;
  }

  .modalSwiperCreateForm {
    margin-top: 0px;
    margin-bottom: -8px;
  }

  .modalSwiperCreateTimesheet {
    margin-top: 7px;
    margin-bottom: -11px;
  }

  .modalSwiperDayComments {
    margin-top: -7px;
    margin-bottom: 2px;
  }

  .modalSwiperEditShift {
    margin-top: 9px;
    margin-bottom: -14px;
  }

  .editReqDateHeader {
    /* background-color: blue; */
    min-width:100%
  }

  .modalSwiperEditReqDates {
    margin-top: 9px;
    margin-bottom: -12px;
  }

  .modalSwiperFormModal {
    margin-top: 2px;
    margin-bottom: -8px;
  }

  .modalSwiperLeaveReqModal {
    margin-top: 9px;
    margin-bottom: -14px;
  }

  .modalSwiperManagerShiftModal {
    margin-top: -13px;
    margin-bottom: 11px;
  }

  .modalSwiperMessages {
    margin-top: 9px;
    margin-bottom: -14px;
  }

  .modalSwiperMgrAvail {
    margin-top: 9px;
    margin-bottom: -14px;
  }

  .modalSwiperMySwaps {
    margin-top: -6px;
    margin-bottom: 3px
  }

  .modalSwiperNotifs {
    margin-top: -2px;
    margin-bottom: -1px;
  }


  .modalSwiperOpenMgr {
    margin-top: -12px;
    margin-bottom: 10px;
  }

  .modalSwiperSaveTemplate {
    margin-top: 9px;
    margin-bottom: -14px;
  }

  .modalSwiperSwapModal {
    margin-top: 9px;
    margin-bottom: -14px;
  }

  .modalSwiperTeamSwaps {
    margin-top: -7px;
    margin-bottom: 2px;
  }

  .modalSwiperTilTrim {
    margin-top: 9px;
    margin-bottom: -14px;
  }

  .modalSwiperUnclocked {
    margin-top: 9px;
    margin-bottom: -14px;
  }

  .modalSwiperUserLeave {
    margin-top: 9px;
    margin-bottom: -14px;
  }

  .modalSwiperUserOpenShift {
    margin-top: -11px;
    margin-bottom: 6px;
  }

  .modalSwiperUserWorkPat {
    margin-top: -1px;
    margin-bottom: 2px;
  }

  .modalSwiperViewLeaveType {
    margin-top: -7px;
    margin-bottom: 3px;
  }

  .modalSwiperSpecDay {
    margin-top: -6px;
    margin-bottom: 2px;
  }

  .modalSwiperViewTeamDay {
    margin-top: 1px;
    margin-bottom: 0px;
  }

  .modalSwiperViewTeamModal {
    margin-top: 9px;
    margin-bottom: -14px;
  }

  .modalSwiperViewTeamUser {
    margin-top: 6px;
    margin-bottom: -11px;
  }

  .modalSwiperTeamOnRequest {
    margin-top: 9px;
    margin-bottom: -14px;
  }

  .modalSwiperViewTs {
    margin-top: 9px;
    margin-bottom: -14px;
  }

  .modalSwiperEditUser {
    margin-top: 7px;
    margin-bottom: -12px;
  }

  .modalSwiperShowAccept {
    margin-top: -6px;
    margin-bottom: 0px;
  }

  .modalSwiperShowShare {
    margin-top: -5px;
    margin-bottom: -2px;
  }


  
  .modalSwiperMyAllows {
    margin-top: 9px;
    margin-bottom: -14px;
  }

   
  .modalSwiperMyHoursDataBox {
    margin-top: -41px;
    margin-bottom: 46px;
  }

   
  .xxx {
    margin-top: 9px;
    margin-bottom: -14px;
  }

   
  .xxx {
    margin-top: 9px;
    margin-bottom: -14px;
  }

  .notAvailInlineShift {
    min-height: 54px;
    margin-top: -18px;
    border-radius: 5px;
    background: repeating-linear-gradient( -45deg, #ffffff, #ffffff 4px, #dde9f9 0px, #dde9f9 8px);
    min-width: 120px;
    border: 1px solid #dde9f9;
    margin-right:3px;
    cursor: pointer;
  }

  .notAvailInlineShift:hover {
    background: repeating-linear-gradient( -45deg, #ffffff, #ffffff 4px, #EAF3FF 0px, #EAF3FF 8px);

  }

  .nwStripes {
    background: repeating-linear-gradient( -45deg, #ffffff, #ffffff 4px, #f0f7ff 0px, #f0f7ff 8px);
  }

  .notAvailInlineTxt {
    color: #ba0000;
    font-size: 14px;
    font-weight: 500;
    margin-top: 11px;
    text-align: left;
    margin-left: 11px;
    line-height: 15px;
    max-width: 100px; }

    .notAvailAllDayInlineSub {
      font-size: 12px;
      font-weight: 500;
      color: #814141;
      text-align: left;
      margin-left: 11px;    }

      .nwInlineTxt {
        color:#a1b4cb
      }


      .specTimesOpenMgr {
        margin-top: 0px;
        float: left;
        margin-left: 0px;
      }

      .monochromeWhite {
        filter: grayscale(100%) brightness(1000%);

      }

      .rotaAbsIcon {
        min-width: 21px;
        max-width: 13px;
        margin-left: 1px;
        margin-right: -1px;
      }

      .teamSchedDayData {
        background:#e3e8ee
      }

      .teamSchedDayDataRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 12px;
        color: #516b8b;
        min-height: 35px;
        border-bottom: 1px solid #bbcada;
      }

      .leaveShiftBoxMyRotaAbs {
        background-color: #b7a7bb;
        border: 1px solid #9d79a7
      }

      .leaveShiftBoxMyRotaAbsHovered {
        background-color: #a793ac !important;
        background-color: blue;
      }

      .leaveShiftBoxMyRotaAbsHovered:hover {
        /* background-color: #a793ac !important; */

      }

      .leaveShiftBoxMyRotaAbsMyAbs {
border:2px solid #143051      }

.leaveShiftBoxMyRotaAbsMyAbs:hover {
  background-color: #a293a5;
}

.leaveShiftBoxAbs {
  background-color: #9aadc4  !important;
}

.absenceLeaveShiftBoxHovered {
  /* background-color: #; */
  background-color: #88a2c1;
  border: 1px solid #525f6f !important;
padding-left:2px;
padding-right:2px;
  /* color: white; */
  /* border: none; */
  /* border: 1px solid #525f6f !important; */

}

.absHov {
  background-color: #88a2c1;
padding-top:3px
}

.noUpcChangesTxt {
  margin-top: 15px;
padding-left:0px}

.l129018 {
  font-size: 12px !important;
  max-width: 70%;
  padding-bottom: 20px;
}

.xd3719 {
  display: flex;
  justify-content: space-between;
}

.requireSeenKnob {
  /* align-items: center; */
  margin-top:20px;
  display: flex;}

  .l298424 {
    font-weight: 400 !important;
    font-size: 12px !important;;
  color: #516b8b !important;;
  line-height: 16px !important;;
  text-align: left !important;;
  }

  .doubleTickExampleImg {
    max-width: 40px;
    height: 13px;
    min-width: 69px;
    margin-right: 10px;
    margin-top: 31px;
    margin-bottom: 30px; }

    .reqSeenActive {
      min-width: 4px;
      height: 20px;
      background-color: #82c26a;
      margin-right: -15px;
      margin-left: 4px;
      border-radius: 5px;
    }

    .reqSeenActiveInactive {
      background-color: #e6eaee;
    }

    .x1231311xa {
      display:flex;
      align-items: center;
      flex-direction: row;
    }

    .reqAcceptIcon {
      background-color: #214978;
      border-radius: 5px;
      width: 7px;
      height: 7px;
      margin-left: 12px;
      margin-bottom: 12px;
    }

    .reqAcceptIconMgr {
      background-color: #b77bff;
    }

    .myHourAcceptRow {
      width: 90%;
      margin-left: 5%;
      display: flex;
      margin-top:-10px;
      justify-content: flex-end;
      padding-right: 40px;
      min-height: 30px;  
      z-index: 0;
      position: relative;  }

      .myHourAcceptRowWithTags {
        margin-top:-22px
      }

      .myTilShiftUnitParentWithAccept {
        min-height:104px
      }

      .myHoursAcceptBtn {
        border: solid 1px #126fda;
        background-color: #1e88e5;
        color: white;
        border-radius:5px;
        font-weight:500;
        height:28px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left:7px;
        padding-right:7px;
        font-size:11px;
        cursor: pointer;
      }

      .myHoursAcceptBtn:hover {
        background-color: #126fda;
        
      }

      .acceptShiftWhiteTick {
        max-width: 8px;
        margin-left: 5px;     }

        .acceptedDoubleTickMyHours {
          max-width: 16px;
          padding-right:1px;
          margin-bottom: 29px;
          margin-left: 11px;
          margin-right: -27px;        }

          .acceptedByChangeReq {
            max-width: 16px;
            padding-right: 1px;
            margin-bottom: 23px;
            margin-left: 11px;
            margin-right: -27px;
            margin-top: 4px;
          }

          .myHoursLeftSec {
            /* background-color: blue; */
            min-width: 26px;
          }

          .mgrHoursUsrNotYetAccept {
            font-weight:500
          }

          .mgrHoursUsrNotYetAccept {
            display: flex;
            justify-content: flex-start;
            width: 60%;
            margin-left: 6%;
            padding-left: 6px;
            font-size: 10px;
            color: #bbcada;
            font-weight: 500;
            margin-top: -6px;
            /* border-left: 2px solid #bbcada; */
            margin-bottom: 11px;
          }

          .accHover {
            background-color: #516b8b;
            color: white;
            font-size: 10px;
            font-weight: 500;
            border-radius: 3px;
            padding-left: 5px;
            padding-right: 5px;
            position: absolute;
            margin-top: -64px;
            display:flex;
            align-items: center;
            justify-content: center;
            height:20px;
            z-index: 999999;          }

            .accHoverWithChange {
              margin-top:-57px
            }

            .x3d11 {
              margin-top: -68px;
            }

           

 .xjd98222 {
  margin-top:8px;
  margin-bottom:8px;
 }           


 .myRotaDoubleTickImg {
  max-width: 14px;
  /* margin-right: -3px; */
margin-left:5px; }

  .notYetAccBtn {
    background-color: #214978;
    color:white;
    border:1px solid #143051;
    font-size:10px;
    display: flex;
    align-items: center;
    height:30px;
    border-radius:5px;
    padding-left:6px;
    padding-right:6px;
    margin-top:-6px;
    cursor: pointer;

  }

  .notYetAccBtn:hover {
    background-color: #143051;
  }

  .menuRowWithNotYetAccWidth {
    min-width: 348px;
    max-width: 348px;
  }

  .shiftsToAcceptQty {
    background-color: #0077ff;
    border-radius: 3px;
    padding: 2px;
    margin-left: 4px;
    height: 17px;
    display: flex;
    align-items: center;
    font-size: 10px;
    padding: 4px; 
  }

    .closeYetToAcceptBtn {
      display: flex;
      align-items: center;
      background-color: #8ea7c5;
      border: 1px solid #6987a9;
      font-weight: 500;
      color: white;
      font-size: 12px;
      padding-left: 8px;
      padding-right: 8px;
      height: 35px;
      border-radius: 5px;
      cursor: pointer;
      padding-bottom: 1px;  
      animation-name: zoomModalIn !important;
      animation-duration: 0.125s !important;
    }

    .closeYetToAcceptBtn:hover {
      background-color: #7e93ac;
    }

    .closeYetToAccCrossBtn {
      width: 9px;
      margin-left: 6px;
      margin-top: 1px;
    }

    .x389481912222 {
      margin-top: 7px;
      margin-bottom: 7px;    }

      .x23894292111 {
        font-size: 12px;
        color: #859dbb;
        font-weight: 500;
        /* border-left: 2px solid #82c26a; */
        height: 18px;
        display: flex;
        align-items: center;
        /* padding-left: 8px; */
        margin-top: 14px;
        margin-bottom: 14px;     }

        .x991091808312 {
          background-color: #07f;
          border: #0469de 1px solid;
        }

        .x991091808312:hover {
          background-color: #036ce4
        }

        .x13453232d {
          max-width: 10px;
          margin-left: 3px;        }

.myRotaWhiteTick {
  opacity:0.2;
  min-width: 9px;
  max-width: 9px;
  margin-left: 2px;
}

.notYetAcceptedTeamRotaDiv {
  background-color: #8ea7c5;
  min-width: 4px;
  min-height: 12px;
  max-width: 5px;
  max-height: 5px;
  border-radius: 5px;
}

.declineShBtn {
  min-height: 30px;
  max-height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #bbcada;
  min-width: 60px;
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  border: solid 1px #bbcada;
  /* background-color: #cbd7e6; */
  font-weight: 500;
  margin-top: 7px;
  max-width: 0px;
  margin-left: 18px;
  margin-bottom: 10px;
}

.declineShBtn:hover {
  /* background-color: #bbcada; */
  /* margin-top: 10px; */
}


.x28349829211{
  /* margin-left:-36px !important; */
  min-width: 33%;
  max-width: 33%;
}


.actionsDropperMob {
  top: 160px;
  min-height: 508px;
  min-width: 174px;
  right: calc(4.1% + 0px);
  animation: slideDownMobTools 0.25s forwards;

}

@keyframes slideDownMobTools {
  0% {
    min-height: 0;
    max-height: 0;
  }
  100% {
    min-height: 508px; /* Adjust this value to control the final height of the rectangle */
    max-height: 508px; /* Adjust this value to control the final height of the rectangle */
  }
}

.schedActionItem {
  min-height:36px;
  max-height:36px
}

.x239898292 {
  min-width: 100%;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-top: 0px !important;
  margin-bottom:14px;
}

.viewTeamDayModalHeader {
  min-width: 100%;
  display: flex;
  justify-content: space-around;
  align-items:center
}


.acceptUnit {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  border: 1px solid #dde9f9;
  border-radius: 5px;
  max-width: 96%;
  margin-left: 3%;
  margin-top: 10px;
  background: white;
  padding-left: 4px;
  padding-right: 9px;
  padding-top: 2px;
  padding-bottom: 4px;
}

.acceptUnitInner {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  /* border: 1px solid #dde9f */
}
.acceptUnitName {
  font-weight: 500;
  color: #143051;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 5px;
  margin-left: 5px;
}

.acceptUnitString {
  color:#82c26a;
  font-weight: 500;
  font-size: 12px;
}

.acceptUnitDeclined {
  color:#ba0000
}

.acceptUnitType {
  font-size: 10px;
  text-align: left;
  margin-left: 5px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 5px;
  max-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #516b8b;
  font-weight: 500;
  /* border: 1px solid #bbcada; */
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-left: 14px;
  /* margin-top: 2px; */
  background-color: #f4f8fe;
  border: 1px solid #e6e6e6;
  margin-bottom:5px;
}


.acceptTypeAndStrip {
  display: flex;
  align-items: center;
  margin-bottom:5px;
  margin-left:10px
}

.acceptUnitTimes {
  color: #8ea7c5;
  font-weight: 500;
  margin-left: 7px;
  /* border: 1px solid #f4f8fe; */
  font-size: 12px;
  text-align: left;
  line-height: 12px;
  border-left: 2px solid #82c26a;
  margin-top: 4px;
  /* padding-left:9px; */
  margin-left: 5px;
  /* padding-right: 4px; */
  padding-left:10px;
  min-width: 138px;
  padding-bottom:4px;
}
.declinedAccUnitBorder {
  border-color:#ba0000;
  cursor: pointer;
}

.declinedAccUnitBorder:hover {
  background-color: #dde9f9;
  border-radius: 0 3px 3px 0;

}
.acceptUnitDsMade {
  color: #8EA7C5;
  font-size: 10px;
  margin-top:3px;
  font-weight: 500;}

.acceptUnitProPicImg {
  max-width: 22px;
  border-radius: 3px;
  margin-right: 10px;
  max-height: 22px;
  object-fit: cover;
  min-width: 22px;
  min-height: 22px;
}

.acceptUnitLeft {
  max-width: 62%;
  min-width: 62%;
}

.acceptUnitDsSpan {
  color: #8EA7C5;
  font-weight:500;
  font-size: 12px;}

  .acceptUnitRight {
    margin-right:10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .x209dw022 {
    max-width: 390px !important;
    min-width: 390px !important;  }

    .acceptUnitDeclinedItem {
      border: none;
      background: none;
      border-radius: 0;
      border-bottom: 1px solid #bbcada;
    }

 .acceptDeclineReason {
  color: #859dbb;
  font-weight: 500;
  font-size: 12px;
  text-align: left;
  padding-left: 7px;
  padding-right: 16px;
  padding-bottom: 5px;
  border-left: 2px solid #8ea7c5;
  margin-left: 15px;
  margin-bottom: 4px;
  margin-top: 2px;
  padding-top: 3px;
 }
  
  .declineReasonName {
    color:#143051;
    font-weight:600;
    padding-right:6px;
  }

  .acceptUnitDesktop:hover {
    border-color:#bbcada
  }


  .acceptMsg {
    display: flex;
    align-items: center;
    border: solid 1px #bbcada;
    background-color: #cbd7e6;
    color: white;
    font-weight: 500;
    font-size: 10px;
    min-height: 22px;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 3px;
    margin-top: 5px;
    cursor: pointer;
  }

  .acceptMsg:hover {
    background-color: #bbcada;
  }
  .accMsgImg {
    max-width: 10px;
    margin-left:4px;
  }
 

 .acceptanceDsPopupImg {
  max-width: 12px;
  /* padding-top: 1px; */
  margin-bottom: -2px; }

  .acceptDeclineTeamName {
    color: #0af;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    padding-left: 9px;
    margin-bottom: 8px;
    margin-top: 5px;
    /* border-left: 2px solid #fff; */
    margin-left: 15px; 
  padding-left:10px;
  }



    .mgrShModalAcceptRow {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #516b8b;
      margin-top: -23px;
      margin-left: 5px;
      font-weight: 400;
    }

    .acceptMgrShRowImg {
      max-width: 14px;
      margin-right: 4px;    }

      .notYetAccBlob {
        background-color: #143051;
    width: 3px;
    height: 10px;
    border-radius: 10px;
    margin-right: 6px;

      }

      .forceAcceptBtn {
        border: solid 1px #bbcada;
        background-color: #cbd7e6;        color: white;
        font-size: 10px;
        height: 20px;
        margin-left: 12px;
        display: flex;
        align-items: center;
        padding-left: 5px;
        padding-right: 5px;
        max-width: -moz-fit-content;
        max-width: fit-content;
        border-radius: 5px;
        margin-top: 2px;
        cursor: pointer;
           }
.forceAcceptBtn:hover {
  background-color: #bbcada;
}
        .x23892j3 {
          margin-top:0px;
          height:20px
        }

.notYetAccWithForceRow {
  height:51px;
  margin-top:-7px
}

.rotaQmark {
width:10px}



.x189398311 {
  padding-left:8px;
  font-size: 12px;
  color: #859dbb;
  font-weight: 500;
  border-left: 2px solid #82c26a;
  height: 18px;
}


.xu2321 {
  line-height: 13px;
  margin-top: -7px;
  margin-bottom: -70px;
  padding-bottom: 70px;
  border-left: 2px solid #6eb450;
  padding-top: 5px;
  padding-bottom: 4px;
  padding-left: 9px;
  margin-bottom: 10px;
  max-height: 30px;
  margin-left: 12px;
  max-width: 180px;
  font-size: 10px;
}

.x292093091 {
  margin-top:-16px
}


.addingHeight {
  min-height: 180px !important;
}


.x02920188111 {
  /* background-color: black; */
}

.bbllbb {
  /* background-color: orange; */
  min-height:101px !important;
  max-height:101px !important;

  animation-name: heightDown !important;
    animation-duration: 0.25s !important
}

.x2211gg {
  /* background-color: blue; */
  /* line-height:18px; */
}

.standaloneJtUserName {
  /* background:orange; */
  min-height:12px;
  margin-bottom:-10px;
}


.x1393198197311 {
padding-top:9px;}

.swapModalMiddleMob {
  margin-top:24px
}

.calDsTodayTxt {
  color: #fff;
  font-size: 10px;
  margin-top: -4px;
  margin-bottom: 4px;
  background: #143051;
  border-radius: 5px;
  max-width: fit-content;
  padding: 3px;
  margin: -3px auto;
  padding-left: 6px;
  padding-right: 6px;
  margin-bottom: 4px;}

.calDsTodayTxt:hover {
  opacity: 0.8;
}
  .calRightSecForAccept {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .calAcceptIconImg {
    max-width: 14px;
    margin-right:8px;
    /* margin-left:-2px; */
  }

  .calNotAcceptIconImg {
    max-width: 10px;
    margin-right:8px;
    /* margin-left:-2px; */
  }
/* 
  .unaccCalBlob {
    min-width: 3px;
    background-color: #bbcada;
    min-height: 12px;
  } */

  .myShDoubleTick {
    max-width: 15px;
    margin-right:4px;
  }

  .x2984918184111 {
    background-color: blue;
    margin-top:30px;
  }

  .paddingTop5 {
    padding-top:5px;
  }

  .xg27381 {
    max-width: 14px;
    padding-right: 4px;
    margin-left: 13px;  }

    .dayCommFooter {
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .x61761g11 {
      padding-bottom:10px
    }

    .cx020948 {
      color: #0077ff
    }

    .notifyShChangeBtn {
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 72px;
      padding-left: 3px;
      padding-right: 3px;
      min-height: 35px;
      max-height: 35px;
      border: solid 1px #4b1caf;
      background-color: #a387df;
      border-radius: 5px;
      cursor: pointer;
      min-width: 65px;
      flex-direction: row-reverse;
      margin-right: 0px;
    }

    .notifyShChangeBtn:hover {
      background-color: #9b82ce;
    }

 

    .x9928927492 {
      /* background-color: blue; */
border-left:1px solid #e0ebf8;
    padding-left: 6px;
     margin-left: 4px;
    }

    .headerMenuTopRow {
margin-top:4px;    }

.headerMenuBottomRow {
  background-color: #00ccff;
  min-height: 4px;
  min-width: 0; /* Start with zero width */
  max-width: 96%;
  border-radius: 3px;
  margin: 4px auto;
  animation-name: zoomModalIn;
  animation-duration: 0.1s !important;
}


.purpeMenuBottom {
  background-color: #ffa8ff;
}

.x198389191 {
  background: none;
  min-height: 4px;
  min-width: 0; /* Start with zero width */
  max-width: 96%;
  border-radius: 3px;
  margin: 4px auto;
}

.highlighedChangeID {
  background: white;
  border: 4px solid #0077ff;}

  .teamSchedAllTeams {
    color: #bbcada;
    font-size: 9px;
    /* max-width: 123px; */
    line-height: 9px;
    margin-left: 6px;
    margin-top: 14px;
    font-weight: 500; 
  display: none;
  }

    .teamSchedAllTeamsSplitter {
      background-color: #bbcada;
      min-width: 3px;
      min-height: 6px;
      margin-left: 23px;
      margin-top: 9px;
      max-height: 17px;
      border-radius: 5px;
      display: none;
    }

    .marginRight10 {
      margin-right:10px !important
    }

    .marginRight3 {
      margin-right:3px !important

    }

    .noCustomQtyScrollBoxMob {
      min-height: 0px !important;
      max-height: 0px !important;
    }

    .formModalFooterMain {
      display: flex;
      min-height:50px;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #bac5d3;
      /* flex-direction: row-reverse; */
      /* flex-direction: row-reverse; */
    }


    .formsDisabled {
      max-width: 85%;
    margin-left: 7%;
    text-align: left;
    font-size: 12px;
    line-height:16px;
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 40px;
    color: #516b8b;
    border-left:2px solid #516b8b;
    padding-left:10px
    }

    .x1981d {
      margin-top:45px;
    }

    .x9811jj {
      color:#214978 !important;
      font-size:9px
    }

    .joinedClockCardsSelectDiv {
      min-width: 100px;
      min-height: 20px;
      /* background-color: yellow; */
      z-index: 9999999;
      margin-top: -183px;
      margin-bottom: 150px;
      position: relative;

    }

    .joinedClockSelect {
      min-width: 30px;
      max-width: 30px;
      min-height: 30px;
      max-height: 30px;
      background-color: white;
      margin-left: 25%;
      border: 2px solid #bbcada;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      /* margin-top:-140px; */
    }

    .joinedClockSelect2 {
      margin-left:65%
    }

    .selClockCardArr {
      width: 15px;
    }

    .jtRowSec {
      background-color: #ecf4ff;
      min-height:30px;
      max-height:30px;
      font-size:14px;
            z-index:999999;
            display:flex;
            align-items: center;
            /* border-top: 1px solid #e3e8ee; */
            /* border-bottom: 1px solid #e3e8ee; */
            justify-content: flex-end;
    }

    .jtDataRowTxt {
      font-size: 11px;
      font-weight: 500;
      max-height: 30px;
      margin-left: 5px;
      color: #143051;
      max-width: 140px;
      text-align: left;
      line-height: 11px;
      padding-right: 4px;
    }

    .jtDataRowTxtMob {
      font-size:10px;
      line-height:10px;
      /* background-color: yellow; */
      max-width: 67px;
      margin-left:5px;
      color:#4b1caf;
    }

    .collapgeJt {
      max-width: 10px;
      min-width: 10px;
      /* background: blue; */
      min-height: 30px;
      max-height: 30px;
      cursor: pointer;
      /* margin-top: 7px; */
      margin-left: 7px;
      padding: 0px;
}

    .collapgesJtArr {
      transform: rotate(270deg);
    }


    .collapgesJtHeight {
      min-height:30px;
      max-height:30px;
    }


    .jtSmall {
      font-size:9px;
      line-height:9px;
    }

    .jtSmallMob {
      font-size:8px;
      line-height:8px;
    }

    .mgrAcceptanceContainer {
      padding-right:10px;;
    }

    .accTimeCalIcon {
      max-width: 11px;
      margin-top:4px;
      opacity: 0.4;
      margin-right:2px;
    }

    .accTimeCalIcon1 { 
      margin-top:6px;
      margin-bottom:-2px;
    }

    .shiftTilNameTxtHours {
      /* margin-top:4px !important; */
      background-color: #f4f8fe !important;
      border: 1px solid #e6e6e6 !important;
      color:#516b8b !important
    }



  


    .acceptKeyBox {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      min-width: 110px;
      min-height: 35px;
      border-radius: 5px;
      border: 1px solid #e0ebf8;    }

      @media only screen and (max-width: 560px)  {
        .acceptKeyBox {
      display: none;
        }
      }

      .acceptKeyTxt {
      color:#496375;
        font-weight: 500;
        font-size:11px;
        /* line-height:10px; */
        text-align: left;
      }

      .keyDoubleTick {
        max-width: 18px;
        margin-right:3px;
      }

      .slaExclaim {
        color: #ba0000;
        font-weight: 600;
        max-width: 14px;
        margin-right: 8px;
        font-size: 22px;      }

        .myCalColourLightRed {
          background-color:#b24e4e
        }

        .slaColourLight {
          background-color:#f5e5e5
        }

        .teamIconAccept {
          max-width: 14px;
          margin-right:4px;
        }

        .teamIconTs {
          max-width: 14px;
          margin-right:4px;
          margin-top:2px;
        }

        .teamIconHours {
          max-width: 14px;
          min-width: 14px;
          margin-left:7px;
        }
        .teamIconAbs {
          max-width: 14px;
          margin-left:7px;;
        }

        .calPrimTeamIcon {
          max-width: 15px;
          margin-right:4px;
        }

        .sideBoxAbsTypesMapTitle {
          text-align: left;
          color: white;
          margin-bottom: -11px;
          font-weight: 600;
          opacity: 0.5;   
          min-width: 230px;
          max-width: 230px;
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          margin-right: 9px;
          min-height: 30px;
          align-items: center;
          padding-left: 14px;
          padding-right: 6px;
          margin-top: 8px;
        }


          .marginRight8 {
            margin-right:8px;
          }

          .todayItemCalHeader {
            background-color: #af71cd;
          }

          .tsPending {
            border: 2px dashed #bbcada !important;
          }

        

          @media only screen and (min-width: 820px) {
            .tsPending:hover  {
              border: 2px dashed #214978 !important;
            }
          .rotaCommentDiv:hover {
            opacity: 0.8;
          }
            .addBtnHovered {
              color: #0077ff !important;
              opacity: 1;
            }
            
          
          }

  .reqMakerAlreadyOff {
    color: #859dbb;
    font-weight: 600;
    font-size: 8px;
    margin-bottom: 5px;
    margin-top: 3px;
    min-height: 9px;  }

    .alreadyOffOrClosed {
      color:#bbcada !important
    }

    .unpaidTilDur {
      font-size:11px
    }

    .tsNoLongerAvailInShift {
      color: #bbcada;
      font-size: 12px;
      max-width: 110px;
      line-height: 15px;
      text-align: right;
      margin-right: 15px;
      font-weight: 500;
      border-right: 2px solid #bbcada;
      padding-right: 10px;
    }

    .viewEmpTsPopup {
      margin-top: 0px;
      margin-bottom: -1px;
      margin-left: 2px;    }

      .x991ddz {
        color: #bbcada;
        line-height: 16px;
        font-size: 12px;
        font-weight: 400;
        margin-top: 18px;
        margin-bottom: 20px;
        padding-right: 20px;
        border-left: 1px solid #e0ebf8;
        padding-left: 9px;
        /* margin-left: 5px; */
      }

      .myShOtherUserRow {
        display: flex;
        align-items: flex-start;
        margin-bottom:10px;
      }

      .myShiftOtherUsersBlock {
        margin-top: 21px;
        margin-bottom: 13px;      }

      .otherUsrShImg {
        max-width: 25px;
    min-height: 33px;
    max-height: 33px;
    min-width: 25px;
    object-fit: cover;
    cursor: pointer;
    border-radius: 3px;
    margin-right: 8px;    }

    .otherUsrShNameTop {
      max-height: 21px;
      line-height: 21px;  
    font-size:14px;
    cursor: pointer;
    }

    @media only screen and (min-width: 820px) {
      .otherUsrShNameTop:hover  {
       
        opacity: 0.8;
      }

      .otherUsrShImg:hover {
        opacity: 0.9;
      }
    
    
    }

    .otherUsrShNameLow {
/* background-color: yellow;  */
/* border-bottom:1px solid #bbcada; */
/* padding-bottom:20px; */
margin-bottom:8px;
max-height: fit-content;   }

    .otherUserShItem {
      font-size: 12px;
      font-weight: 500;
      max-height: 15px;
      display: flex;
      align-items: center;
      color: #bbcada;
      margin-top: -3px;
      margin-bottom: 4px;
    }

    .otherUsrClock {
      width: 10px;
      margin-top:1px;
      margin-right: 4px;
      opacity: 0.6;    }


      .collea {
        font-size: 12px;
        margin-bottom: -20px;
        color: #214978;
        /* color: #859cb6; */
        font-weight: 400;
        display: flex;
        max-width: -moz-fit-content;
        max-width: fit-content;
        line-height: 18px;
        margin-top: 20px;
        margin-bottom: 0px;
        padding-left: 6px;
        padding-right: 20px;
      }

      .highlightCommentBtn {
        color:#516b8b;
        font-size: 11px;
        font-weight: 500;
        display:flex;
        align-items: center;
        margin-top:4px;
        cursor: pointer;
      }


@media only screen and (min-width: 820px) {
  .highlightCommentBtn:hover {
  color:#143051
  }


}

      .dayCommentTsAndHighlight {
        display:flex;
        flex-direction: column;
        align-items: flex-end;
      }

      .highglightTick {
        min-width:16px;
        min-height:16px;
        max-width:16px;
        max-height:16px;
        margin-left: 6px;
        padding: 3px;
        border: 1px solid #bbcada;
        border-radius: 3px;
        background: white;
        margin-top:1px;
      }

      .unhighlightedCommTick {
        min-width:16px;
        min-height:16px;
        max-width:16px;
        max-height:16px;
        margin-left: 6px;
        padding: 3px;
        border: 1px solid #cadcef;
        border-radius: 3px;
        margin-top:1px;
      }

      .highlightedNoteObj {
        border: 2px solid #e0ebf8;
        padding: 14px;
        background-color: #e8f3ff;
      }

      .highlightedCommentsA1 {
        border-left: 1px solid #dbe8f7;

        border-right: 3px solid #dbe8f7;
        min-height:40px;
        min-width: 100%;
        /* border-top:none; */
      }

      .highlightedCommBox {
        min-height: 40px;
        min-width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        max-width: 225px;
        padding-left:15px;
        padding-right:15px;
        border-bottom:1px solid #e3e8ee
      }

      .highlightExclaim {
        color:#ba0000 !important;
        font-size:18px !important;
        font-weight:600;
        min-width: 10px;
        line-height: 30px;
      }

      .highlightedBG {
        /* background-color: #fff; */
        font-weight: 500;
        max-height: 40px;
        line-height: 13px;
        color: #516b8b;
        font-size: 12px;   
        cursor: pointer;
        border-bottom:1px solid #e3e8ee
         }

         @media only screen and (min-width: 820px) {
            
         .advancedView:hover {
          background-color: #8d85d2;
         }

          .highlightedBG:hover {
          color:#143051
          }
          .highlightTxtStandalone {
          color:#516b8b
          }
        
        }

         .smallerHighlight {
          font-size:10px;
          line-height:11px;
         }


         .highlightTxt {
          padding-left: 5px;
          padding-right: 5px;

          font-size: 10px;
          line-height: 12px;
         }

     

         .highlightedCommRow {
          display: flex;
          flex-direction: column;
         }

         .highlightComCalRow {
          background-color: #fff;
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          font-weight: 500;
          max-width: 94%;
          margin-left: 3%;
          margin-top: 10px;
          border-radius: 5px;
          padding: 5px;
          padding-left: 8px;
          padding-right: 8px;
          border: 1px solid #e2ebf5;
          cursor: pointer;
          color: #143051;
         }

         .x28181132 {
          margin-top:10px !important;
         }
         .highCalExclaim {
          font-weight:600;
          color:#ba0000;
          font-size:18px;
          margin-top:3px;
         }

         .highlightCalComRowCentre {
          min-width: 90%;
          max-width: 90%;

         }

         .highlightRow1 {
          text-align: left;
 font-size: 10px;
 color:#bbcada
         }

         .highlightRow2 {
          text-align: left;
          font-style: italic;
          color:#07f
         }

         .noCommsComIcon {
          opacity: 0.4;
         }

         .dayComShowTopBorder {
          border-top:1px solid #bbcada
         }

         .cantSelfAppAbs {
          color: #143051;
          line-height: 16px;
          font-size: 12px;
          padding-right: 48px;
          padding-left: 12px;
          border-left: 2px solid #143051;
          margin-left: 5px;
          margin-bottom: 20px;         }

          .x2489ff {
            border-radius:20px 20px 0 0  !important
          }


        

          .x29482974231 {
            font-size:14px;
            line-height:20px;
            margin-bottom:10px;
          }



          .absCompanySpan {
            font-size:10px;
            opacity: 0.7;
          }


          .lowerBetter {
            font-size:10px;
            font-weight: 500;
            opacity: 0.4;
            text-align: right;
          }

          .lowerBetter2 {
            font-size:10px;
            font-weight: 500;
            opacity: 0.4;
            text-align: left;
            margin-left:7px;
          }

          .absLowerBetterDiv {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            color:white
          }
          .absLowerBetterDiv2 {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            color:white
          }

.tsItemDurMins {
  font-weight: 500;
  margin-top: 12px;
  margin-left: 7px;
  margin-bottom: 10px;
  font-weight: 400;
  font-size:10px;
  color: #143051;}

  .recDurTxt {
    color:#143051;
    font-weight: 500;
    font-size:14px;
  }


  .addShiftShoulderBtn {
    border: 1px solid #8a98fc;
    background-color: #a4afff;
    color: #fff;
    cursor: pointer;
    min-width: 35px;
    min-height: 35px;
    border-radius: 5px;
    display: flex;
    font-size: 24px;
    line-height: 24px;
    align-items: center;
    padding-bottom: 2px;
    justify-content: center;
    margin-top: 8px;
    margin-right: 10px;
  }


    .addShiftShoulderBtn:hover {
      background-color: #97a1ea;
    }

    .addShiftTilDrop {
      min-width: 200px;
      min-height:400px;
      background-color:white;
      position: absolute;
      top: 2px;
      left:2px;
    }


    .staffRotaBtnColours {
      background-color: #b49ce7  !important;
      border: 1px solid #a47fd0 !important;
    }

    .staffRotaBtnColours:hover {
      background-color: #a58fd4 !important;
    }

    .lineHeight22px {
      line-height: 22px !important
    }

    .payableHoursMobTitle {
      font-size:14px;
      margin-top:2px;
      /* line-height:14px; */
    }
    .rotaViewBtn {
      max-width: 28px;
      border-radius:3px;
      border:1px solid #756dba;
      margin-top:-2px;
      margin-right: 16px;
      cursor: pointer;
      padding: 3px;
      background: #8d85d2;
    }

    .rotaViewBtn:hover {
opacity: 0.9;    }

    .rotaViewBtnMob {
      margin-right:6px
    }

    .rotaViewBtnMob:hover {
      opacity:1
    }

    .streamLinedRotaDiv {
min-height:calc(100vh - 203px);
max-height:calc(100vh - 203px);

min-width: calc(100vw - 160px);
      max-width: calc(100vw - 160px);
background-color: white;
border-left:2px solid #e6eaee;
padding-top:5px;
overflow:auto;
display: flex;

    }

    .streamLinedRotaDivMob {
      border: none;
      max-height: calc(100vh - 257px);
      min-height: calc(100vh - 257px);
      min-width: 100vw;
      max-width: 100vw;
      padding-top: 9px;
      margin-top: -8px;
    }

.streamJtRow {
  /* background-color: #f0f7ff; */
  /* border-radius:4px; */
  max-width: 100%;
  /* margin-left:1%; */
  /* margin-top:2px; */
  /* margin-bottom:5px; */
  text-align: left;
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  min-height:25px;
  /* background-color: white; */
  /* border-top:2px solid #bbcada */

}


.streamJtJobTitle {
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
  color: #143051;
  /* border-radius: 3px 0 0 3px; */
  background-color: #ecf4ff;
  border-top: 1px solid #e3e8ee;
  border-bottom: 1px solid #e3e8ee;

  min-height: 35px;
  padding-left: 9px;
  cursor: pointer;
  min-width: 141px;
  max-width: 141px;
  /* border-right: 1px solid #dbe8f7; */
  z-index: 99999;
  line-height: 10px;


}

.streamJtJobTitleMob {
  min-width: 104px;
  max-width: 104px;
}
/* .streamJtJobTitlePhontom {
  min-height:35px;
  min-width: 10px;
} */

.streamUserRow {
  /* background-color: blue; */
  min-height:60px;
  max-height:60px;
  /* border-bottom:1px solid #dbe8f7; */
  min-width: 100%;
  max-width: 100%;
}

.borderTopBbcada {
  /* border-top: 1px solid #bbcada */
}

.streamUserNameBlock {
  border-right: 3px solid #dbe8f7;
  border-left: 1px solid #dbe8f7;
  border-bottom:1px solid #dbe8f7;
cursor:pointer;
  max-width: 140px;
  min-height:60px;
  max-height: 60px;
  display:flex;
  align-items: center;
  justify-content: flex-start;
  font-size:12px;
  font-weight: 500;
  color:#516b8b;
  padding-right:5px;
}

.streamUserNameBlockMob {
  max-width: 104px;
  font-size: 10px;
  background-color: white;

}

.streamlinedUserProPicImg {
  max-width: 24px;
  min-width: 24px;
  min-height: 36px;
  max-height: 36px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 7px;
  margin-left: 5px;
}

.streamCollapseJtImg {
  max-width: 10px;
  min-width: 10px;
  margin-right: 5px;
  /* margin-left: 2px; */
}

  .streamCollapseJtImgCollapsed {
    transform: rotate(-90deg);
  }

  .streamDays {
    background:blue;
    max-height:100px;
  }

  .streamJtLeft {
    min-width: 140px;
    max-width: 140px;
    min-height:fit-content;
    /* min-height: fit-content; */
    /* min-height: calc(100vh - 203px);
    max-height: calc(100vh - 203px);   */

  }

  .streamInner {
    display: flex;
    overflow:auto;
    /* max-height:500px; */
    /* min-height:500px; */

  }

  .streamlinedDays {
    background-color: yellow;
    display: flex;

  }

 

  .dayColWidener {
    min-width: 140px;
    max-width: fit-content;
  }

  .dayColWidenerMob {
    min-width: fit-content;
    max-width: fit-content;
  }
  .jtOvers {
    background:blue
  }

  .streamlinedParent {
    position: relative;
    overflow-y: scroll;
    background-color: white;
  }

  .streamlinedNameCol {
    position: relative;
    min-width: 140px;
    max-width: 140px;
  }

.streamlinedDayCol {
border-right:1px solid #dbe8f7;
min-width: fit-content;
margin-left:-2px;

}

.streamlinedDayColMob {
  /* min-width: 104px; */
  min-width: fit-content;

}

.streamlinedA1 {
  min-height:35px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  /* border-right:1px solid #dbe8f7; */
  border-bottom: 3px solid #dbe8f7;
  justify-content: space-between;
}

.streamlinedA1NameCol {
  border-left:1px solid #dbe8f7;
    border-right:3px solid #dbe8f7;
    justify-content: flex-start;

}

.streamliendModeBtn {
  max-width: 12px;
  border-radius: 3px;
  margin-top: 0px;
  background: none;
  border: none;
  padding: 2px;
  margin-right: 0px;
  margin-left: 0px;
  cursor: pointer;
  padding: 3px;
  transform: rotate(180deg);

}

.advancedView {
  display: flex;
  background-color: #8f85eb;
  align-items: center;
  color: white;
  font-weight: 500;
  max-width: 83px;
  border-radius: 3px;
  border: 1px solid #756dba;
  font-size: 10px;
  display: flex;
  align-items: center;
  line-height: 10px;
  /* margin-left: 8px; */
  cursor: pointer;
  min-height: 28px;
  padding-right: 0px;
  margin-top: -4px;
  line-height: 9px;
  text-align: left;
  min-width: 83px;
  margin-right: 9px;
}


.advancedView1 {
  margin-top: 0px;
  min-width: 97px;
  max-width: 97px;
  margin-left:4px;
  /* font-size: 10px; */
  line-height: 8px;
  margin-left:0px;
}

.mobStreamlineImgBtn {
  border-radius: 3px;
  border: 1px solid #756dba;
  background-color: #8f85eb;

  max-width: 28px;
  min-width: 28px;
  max-height:28px;
  min-height:28px;
  margin-top:-4px;
  margin-left:15px;
}

.advancedViewX9 {
  min-width: 95px;
  max-width: 95px;
  font-size: 12px;
  margin-right: -2px;
}

.advancedView1Mob {
  margin-left:5px;
}

.advancedView1Desktop {
  min-width:114px;
  max-width: 114px;
  font-size: 12px;
  /* margin-left:1px; */

}

.streamJtRowMob {
  min-width: 104px;

  max-width: fit-content;

}


.streamlinedShiftsArr {
  /* min-width: 204px; */
  /* background-color: black; */
  
  /* color:blue; */
  min-height:60px;
  display: flex;
  min-width: 100%;
  border-bottom: 1px solid #dbe8f7;
  /* border-left: 1px solid #dbe8f7 */
}

.streamlinedShiftsArrMob {
  border-left: 1px solid #dbe8f7

}

.streamlinedShiftsArrFinal {
  border-bottom:none;
  /* display: flex; */
  /* justify-content: center; */
}
.block11 {
/* border:1px solid black; */
/* margin-left:2px; */
min-width: 60px;
}

.fitWidth {
  min-width: calc(100% - 1px) !important;
  border-right: none;
  cursor: default;
}

.borderLeftStream {
  border-right: 4px solid #dbe8f7;
  border-left: 1px solid #e3e8ee;

  /* border-radius:5px 0 0 5px; */
}
.borderLeftStreamMob {
  border-right: 3px solid #dbe8f7;

}

.streamNameAndHours {
  /* background-color: yellow; */
}

.streamUserHours {
  font-size: 10px;
  color: #859dbb;
  margin-top: 2px;
  font-weight: 400;
}

.streamDayTitle {
  font-weight: 500;
  font-size: 16px;
  color: #143051;
  line-height: 24px;
  display:flex;
  align-items: center;
  margin-left:10px;
  margin-top:1px;
}

.streamDayTitleJt {
  font-weight: 500;
  font-size: 12px;
  color: #bbcada;
  line-height: 24px;
  display:flex;
  align-items: center;
  /* margin-left:10px; */
  margin-top:1px;
}

.streamDayTitleDay {
  font-size:14px;
  padding-left:4px;
  /* line-height: 10px; */

}

.streamlinedNonWorkingDayItem {
  background: repeating-linear-gradient(45deg, #ffffff, #ffffff 4px, /* Adjust the height of stripes as needed */ #f0f0f0 4px, /* Adjust the color of stripes as needed */ #f0f0f0 8px /* Adjust the height of stripes + gap as needed */);
  min-height: 59px;
  max-height: 59px;
  min-width: 100%;
  max-width: 100%;
  /* min-width: 139px;
  max-width: 139px; */
  /* min-width: fit-content; */
  /* max-width: fit-content; */
  color: rgba(255,255,255,0);
  font-weight: 500;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #dbe8f7;
  margin-left: 1px;
  cursor: pointer;

}

.streamlinedNonWorkingDayItemStreaming {
  background: repeating-linear-gradient(45deg, #ffffff, #ffffff 4px, /* Adjust the height of stripes as needed */ #b9d3b9 4px, /* Adjust the color of stripes as needed */ #b9d3b9 8px /* Adjust the height of stripes + gap as needed */);

}

.streamlinedNonWorkingDayItem:hover {
  color:#bbcada
}


.streamlinedNonWorkingDayItemMon {
  min-width: 100%;
  /* max-width: 140px; */
  margin-left:0px;
  /* border-left:none */
}

.borderLeftNone {
  border-left:none !important
}

.streamlinedShiftBox {
  min-width: 134px;
  min-height: 58px;
  max-height: 58px;
  margin-top: 1px;
  margin-left: 4px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  animation-name: zoomModalIn !important;
  animation-duration: 0.2s !important;
cursor: pointer;}

.disabledShiftBox {
  cursor: default;
  pointer-events: none;
}


.streamLineShiftTxtBox {
  margin-left:8px
}

.streamShiftTimes {
  font-size: 12px;
  font-weight: 500;
  color: #516b8b;
  margin-bottom: -4px;
  margin-top: 3px;
  /* margin-right:10px; */
}


.streamlinedShiftBoxLeave {
  background-color: #e9e9ff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 126px;
  margin-left: 0px;
  cursor: pointer;
  max-height: 48px;
  margin-top: 6px;
  margin-left: 6px;
  min-height: 48px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.2s !important;
}



.streamlinedShiftBoxAbsence {
  background-color: #dfe7f0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 126px;
  margin-left: 0px;
  cursor: pointer;
  max-height: 48px;
  margin-top: 6px;
  margin-left: 6px;
  min-height: 48px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.2s !important;
}

.finalItemLeaveAbs {
  margin-right:6px;
}




@media only screen and (min-width: 820px) {
  .streamlinedShiftBox:hover {
  background-color: #f3f8ff;
  }

  .streamlinedShiftBoxLeave:hover {
    background-color: #e4e4f6;
  }
  .streamlinedShiftBoxAbsence:hover {
    background-color: #d8e1eb;
  }


}
.streamlinedShiftBlob {
  min-width: 3px;
  border-radius: 5px;
  min-height: 80%;
  background-color: #00ccff;
  margin-left: 3px;
}

.streamlinedShiftBlobGhost {
  background-color: #0469de;
}

.streamlinedShiftOvertimeBlob {
  background-color: #8f85eb;
}
.streamlinedShiftTilBlob {
  background-color: #ec77f7;
}

.unpubBw {
  -webkit-filter: grayscale(100%); /* Safari and Chrome */
  filter: grayscale(100%);
  opacity: 0.5;
}

.streamlinePlane {
  max-width: 16px;
  margin-left:-7px;
  opacity: 0.8;
}


.streamLeavInfoDiv {
  font-size: 10px;
  font-weight: 500;
  color: #b1abe7;
  margin-left:10px;
  min-width: 64px;
}

.streamLeavInfoDivAbs {
  color:#7a8ca2
}

.streamLeaveInfoUpper {
  
}

.streamLeaveInfoLower {
  font-weight: 400;
  margin-top:-2px;
  font-size:10px;
}

.shiftBoxLowerStream {
  margin-top: 4px;
  display: flex;
  min-height: 18px;
  max-height:18px;
}

.hobbler {
  min-height:18px;
  min-width: 1px;
  max-width: 1px;
  margin-top: 4px;

}

.shTagStream {
  background-color: #f4f8fe;
  border-color: #dde9f9;
  color: #859dbb;
  padding-left: 3px;
  padding-right: 3px;
  min-height: 16px;
  max-height: 16px;
  margin-top: 2px;
}

.streamNoTags {
  font-size:10px;
  color:#bbcada;
  font-weight: 500;
}

.streamNoTagsUnpub {
  color:#7fa7c2
}

.streamOverTilType {
  margin-top: -30px;
  margin-right: -10px;
  margin-left: 10px;
  background-color: #f4f8fe;
  border: #dde9f9 1px solid;
  color: #859dbb;
  font-size: 9px;
  font-weight: 500;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 4px;
  min-height: 15px;
  display: flex;
  align-items: center;}



  .streamPlus {
    font-size:22px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #bbcada;
    cursor: pointer;
  }

  

  @media only screen and (min-width: 820px) {
    .streamPlus {
      color: #fff;
    }
    .streamPlus:hover {
      color: #bbcada;
    }
  } 

  .streamShifts {
    /* min-width: 100%; */
    display: flex;
    align-items: center;
  }

  .streamShiftsAndAvail {
    min-width: none;
  }

  .streamAvail {
    color:red
  }

  .paul {
    min-width: 100%;
  }

  .availTest {
    min-height: 22px;
    max-height: 22px;
    padding-right: 10px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    font-size: 9px;
    border-bottom: 1px solid #f4f8fe;
    color: #859dbb;
    font-weight:500;
    background-color: white;
    /* margin-left:1px; */
    /* background-color: yellow; */
    /* border-right:none !important */
    background-color: white;
    /* border-left: 1px solid #dbe8f7; */
    min-width:140px;
    cursor: pointer;


  }

  .opac05 {
    opacity: 0.5
  }
/* 
@media only screen and (min-width: 820px) {
  .availTest {
  margin-left:1px;
  }



} */

  .availNameBlock {
    min-height:82px
  }

  .nameAvail {
    border-right:3px solid #dbe8f7;
    border-left:1px solid #dbe8f7;

    /* text-align: right; */
    display: flex;
    justify-content: flex-end;
  }


  .nameAvailMob {
    justify-content: flex-start;
    padding-left:44px
  }

  .availBlob {
    background-color: #92d378;
    min-width: 4px;
    min-height: 4px;
    border-radius: 4px;
    margin-right: 6px;
    margin-left: -3px;
  }

  .unavailBlob {
    background-color: #d77d7d;
  }
  

  .todayStreamCol {
    border-left: 3px solid #00aaff
  }

  .whiteBorderBottom {
    border-bottom: 1px solid #e8edf1
  }

.rotaViewOptions {
  background-color: #8f85eb;
  border-color: #756dba;}


  @media only screen and (min-width: 820px) {
    .rotaViewOptions:hover {
background-color: #8d85d2;
    }
  
  
  }

  .teamSchedOptionsBox {
    min-width: 135px;
    max-width: 135px;
    min-height: 60px;
    max-height: 60px;
    border: 1px solid #bbcada;
    /* background-color: blue; */
    position: fixed;
    top: 215px;
    left: 174px;
  }

  .teamSchedViewOption {
    display: flex;
    align-items: center;
  }

  .teamSchedViewOptionTxt {
    font-size: 13px;
    color: #214978;
    font-weight: 500;
    margin-left: 7px;
  }

  .limKnob {
    width: 12px !important;
    height: 12px !important;
    position: relative !important;
    top: 12px !important;
    border-radius: 50px !important;
    border: 1px solid #143051 !important;
    }

  .knobBgLim {
    background-color: #dedce2 !important;
    height: 12px !important;
    width: 22px !important;
    border-radius: 50px !important;
    /* position: fixed; */
    margin: auto !important;
    margin-top: 0px !important;
    margin-left: 5px !important;
  }

  .limKnobHolder {
margin-top:-10px;
    margin-right:0px !important;
    margin-left:5px;
    /* margin-right:8px; */
  }


  .rotaDeleteDropPort {
    min-width: 100px;
    min-height: 100px;
    background-color: #f4f8fe;
    position: fixed;
    bottom: 110px;
    right: 60px;
    z-index: 999999999;
    border: 3px dashed #bbcada;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rotaDelPortBinImg {
    width:40px;
    opacity:0.8
  }

  .dupeUnderlay {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 50%);
    height: 100%;
    min-width: 100vw;
    position: fixed;
    z-index: 9999999;
    top: 0;
    left: 0;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;

    /* -webkit-backdrop-filter: blur(3px); */
    /* backdrop-filter: blur(3px); */
    /* background-color: rgb(119 119 119 / 30%); */
  }

  .mobileDupeBtn {
    background-color: white;
    position: fixed;
    bottom: 85px;
    left: 0px;
    z-index: 999999999;
    min-width: 100vw;
    padding-left:2%;
    padding-right:2%;
    min-height: 60px;
    border-radius: 15px 15px 0 0;
    animation: slideDupeUp 0.45s forwards; /* Apply animation */
display:flex;
align-items: center;
justify-content: space-around;
    /* min-width: 130px;
    min-height: 40px;
    background-color: #403a8e;
    position: fixed;
    top: 190px;
    right: 20px;
    z-index: 999999999;
    border: 2px solid #2a2479;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: white;
    font-size: 16px; */
  }


@keyframes slideDupeUp {
  from {
      bottom: -120px; /* Start off screen */
  }
  to {
      bottom: 85px; /* Slide up to desired position */
  }
}


  .duplicateShMobImg {
    max-width: 18px;
    /* margin-top:1px; */
    margin-left:10px;
  }

  .x32849832 {
    margin-top:-3px;
    max-width: 16px;

  }

  .dupeMobBtn {
    background: #a58ed4;
    max-width: 29%;
    min-width: 29%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border: 1px solid #a47fd0;
    border-radius: 5px;
    font-weight: 600;
    min-height: 35px;
    padding-left: 7px;
    padding-right: 7px;
    font-size: 14px;

  }

  .greyColours {
    background-color: #cbd7e6;
    border-color: #bbcada;
  }

  .streamedSh {
    /* background-color: blue !important; */
    border:2px solid #214978;
    min-width: 132px;
    max-width: 132px;
    margin-right:2px;
    /* background: #f4f4f4  !important */
  }

  .streamMoving {
    background-color:#e8fdf2 !important;
    cursor: pointer;
    padding-right:3px;
    border-left:1px solid #dbe8f7 
  }

  .moveShiftHereDottedIconStream {
    margin-left:0px;
    margin-right:8px;
    margin-left:8px;
    display:none
  }

  .canxMoveShMob {
    position: fixed;
    bottom:110px;
    left: calc(50% - 30px);
    z-index:99999999;
    /* background-color: white; */
    display:flex;
    align-items: center;
    justify-content: center;
    border-radius: 110px;
    padding:18px;
    border:2px solid #bbcada;
    opacity: 0.85;
    min-width: 65px;
    max-width: 65px;
    min-height:65px;
    max-height:65px;
    cursor: pointer;
  }

  @media only screen and (min-width: 820px) {
    .canxMoveShMob:hover {
transform: scale(1.1);  }

  }

  .streamMoving:hover {
    background-color: #d5efe1 !important;
  }

  .canxMovImg {
    width:25px
  }

  .movingStreamShiftBox {
border:2px solid #214978;
background-color: white !important;
animation-name: zoomModalIn !important;
animation-duration: 0.1s !important;
min-width: 132px;
max-width: 132px;
margin-right:8px;
}

.dayLimknob {
  /* background:blue */
  margin-top:-11px;
}

.leaveLimtsA1 {
  /* background-color: blue; */
  min-height:30px;
  max-height:30px;
  border-top:1px solid #e3e8ee
}

.x9283711 {
  margin-left:-7px
}

.disableLimsBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 35px;
  max-height: 35px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;}



  

  .ghostStreamBg {
    background-color: #214978;
    max-height: 28px;
    min-height: 52px;
    margin-top: 4px;
    max-width: 30px;
    min-width: 132px;
    border-radius: 4px;
   }
    

    @media only screen and (min-width: 820px) {
      .disableLimsBtn:hover {
  background-color:#bbcada    }
    
  .ghostStreamBg:hover {
    background-color:#143051    }
    }
  

    .leaveLimsA1Sub {
      min-height:57px; 
      border-left:1px solid #dbe8f7;
      border-right:3px solid #dbe8f7
    }

    .limDisabledTitle {
      min-height: 24px;
      margin-top: 2px;
      max-height: 24px;
      font-weight: 500;
      color: #bbcada;
      font-size: 10px;
      padding-right: 3px;
      padding-top: 6px;

    }

    .openRate {
      background-color: #e5a9db;
      color: white;
      font-size: 10px;
      padding-left: 4px;
      padding-right: 4px;
      font-weight: 600;
      border-radius: 3px;
      max-height: 14px;
      padding-top:1px;
      line-height: 12px;
      margin-top: 1px;
      margin-left: 4px;
    }

    .jtTopperWidthStream {
      min-width: calc(100% + 0px) !important;
    }

    .widerDayColStreamJt {
      min-width: 194px !important;
    }

    .x123456789 {
      border-right:0.5px solid #00aaff
    }

    .jtPowerSwitch {
      /* background:orange; */
      display: flex;
      cursor: pointer;
      align-items: center;
      padding-bottom:4px;
    }

    .knobOnJtLim {
      background-color: #0af !important
      /* ; */
    }

    .x99001 {
      margin-right: 4px !important;
      padding-right: 4px !important;
    }

    .hovDupeImg {
      min-width: 18px;
      max-width: 18px;
      position: relative;
      margin-left: 20px;
      margin-top: 20px;
      opacity: 0.8;
      animation-name: zoomModalIn !important;
      animation-duration: 0.1s !important;
      /* display:none */
    }

    .x3289489d34 {
      margin-right: 6px;
      margin-left:-24px; 
    }

    .hovDupeImgx88 {
      margin-left:8px;
      /* min-width: 21px; */
      /* max-width: 21px; */
    }
    .hovDupeImg:hover {
      /* background-color: white; */
      opacity:1;
      min-width: 23px;
      max-width: 23px; 
      margin-left: 17px;

    }

    .hovDupeImgx88:hover {
      margin-left: 6px;

    }

    .mobMyRotaSched {
      min-height: calc(100vh - 250px);
      max-height: calc(100vh - 250px);    }


      .myRotaSchedContainerDesktop {
        padding-left:18px;
    
      }

      .mobUserRotaSchedHeight {
        min-height: calc(100vh - 250px);
        max-height: calc(100vh - 250px);  
      }

      .desktopMyRotaWidthSched {
        min-width: calc(100vw - 305px);
        max-width: calc(100vw - 305px);
      }

      .mobMyRotaWidthSched {
        /* min-width: calc(100vh - 292px);
        max-width: calc(100vh - 292px); */
      }



      .calClockedOnSpan {
        color:white;
        border-radius:3px;
        padding:2px;
        background-color:#00ccff;
        font-size:11px;
        margin-left:2px;
        padding-left:4px;
        padding-right:4px;
        margin-left:5px;
      }

      .pend {
        background-color: #deedff !important;
        border: 1px solid #8bc3ff;
        border-bottom: 2px solid #8bc3ff !important
      }



      .pendClockName {
        background-color: #deedff !important;
        border:1px solid #dee5f0;
        border-bottom:none !important
      }
      .formSubName {
        background-color: #deedff !important;
        border: 1px solid #dee5f0;
        border-top: none !important;
        color: #859dbb;
      }
      .pendClockSub {
        background-color: #f1f7ff !important;
      }

      @media only screen and (min-width: 820px) {
        
   
       .x00191342323:hover {
        /* border-left:#0077ff  5px solid !important */
      }

       .mgrReqItem:hover {
        border-color:#bbcada !important
       }

       .pend:hover {
        border-color: #5395e3 !important;
      

     }
      }

      .bbcada {
        color:#bbcada !important
      }

      .otherTeamBlob {
        background-color: #bbcada;
      }

      .x3832874827 {
        border-top: 1px solid #c3d6e3 !important
      }

      .x32897478243 {
        border-bottom: 1px solid #c3d6e3 !important
      }

      .staffRotaMasterHrs {
        position: fixed;
        top: 62px;
        right: 314px;
        text-align: right;
        min-width: fit-content;
        font-weight: 500;
        color: #bbcada;
        font-size: 14px;
        border-right: 1px solid #e6eaee;
        display: flex;
        align-items: center;
        padding-right: 10px;
        min-height: 33px;
      }

      .staffRotaMasterHrsMidMob {
        position: fixed;
        top: 61px;
        right: 137px;
        border-right: none;
        opacity: 0.7;
        color: white;
      }

      .staffRotaMasterHrsSmallMob {
        position: fixed;
        top: 70px;
        right: 15px;
        border-right: none;
        opacity: 0.7;
        color: white;
      }


      .absShiftBoxSelect {
        background: white;
    min-width: calc(100% - 20px);
    border: 1px solid #e6eaee;
    border-radius: 5px;
    border-top: solid 2px #e6eaee;
    border-right: 1px solid #e6eaee;
    border-left: 1px solid #e6eaee;
    margin-top: 7px;
    max-height: 100px;
    min-height: 100px;
    overflow-y:auto;
    margin-top:10px;
    padding-bottom:5px;
      }

      .markAbsShiftItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #dee5f0;
        border-radius: 5px;
        margin-top: 6px;
        max-width: 94%;
        margin-left: 3%; 
        min-height:45px;
      max-height:45px   ;
      cursor: pointer;
    padding-left:10px;
    background-color: #f4f8fe;
  padding-right:10px;  }

      .markAbsShType {
        max-height: 16px;
        line-height: 16px;
        font-size: 14px;
        font-weight: 500;
    }      

      .markAbsShTime {
        max-height: 16px;
        line-height: 16px;
        font-size: 14px;
        font-weight: 500;
        color: #143051;
          }

.markAbsShDate {
  max-height: 14px;
  line-height: 14px;
  font-size: 10px;
  font-weight: 500;
  color: #8ea7c5;
  margin-top: 4px;
  margin-bottom: -3px;

}

      .markAbsShRight {
        text-align: right;
        margin-right: 6px;
        min-height: 35px;
      }

      .noAbsShTxt {
        text-align: left;
        color: #bbcada;
        font-weight: 500;
        font-size: 12px;
        margin-left: 14px;      }

.markAbsPeriodBtn {
  font-weight: 500;
  text-align: left;
  font-size: 14px;
  border: 1px solid #8a98fc;
  background-color: #a4afff;
  border-radius: 5px;
  max-width: 190px;
  cursor: pointer;
  max-height: 35px;
  display: flex;
  align-items: center;
  text-align: center;
  padding-left: 7px;
  margin-top: 20px;
  color: white;
  padding-right: 5px;
  margin-left: 20px;
  margin-bottom: 30px;
}

.x38492749272 {
  margin-top: -80px !important;
  padding-left: 25px;
  max-height: 30px;}

  .markAbsPeriodBtnNoSh {
    margin-top:6px;
  }

  .makeAbsRowFlash {
    animation: flashingBackground 1s ease-in-out infinite; /* Change 'body' to your target element */
  }

  @keyframes flashingBackground {
    0% {
      background-color: #FFFFFF; /* Start with white */
    }
    50% {
      background-color: #ece9f9; /* Transition to light greyish-blue */
    }
    100% {
      background-color: #FFFFFF; /* Transition back to white */
    }
  }

  .x248943834834 {
    font-size:10px
  }

  .tilStripWidther{
    max-width: 80px;
    font-size: 12px;
    line-height: 12px;
    margin-top: 4px !important;
    margin-bottom: -2px;}

    .x328929849289822 {
      max-width: 92px;
      font-size: 11px;  }

.editMyShiftOrigianal {
  background-color: white;
  border: 1px solid #e0ebf8;
  border-radius: 5px;
  width: 90%;
  text-align: left;
  margin-left: 5%;
  margin-bottom: 20px;
  font-size:12px;
  font-weight: 500;
  padding:8px 20px 8px 20px;
  color:#143051

}

.originalEditSValRow {
  display:flex;
  justify-content: space-between;
  align-items:center;
  border-top: 1px solid #e0ebf8;
  min-height:30px;

}

.origTitle {
  color:#214978; font-weight:400;

}

.editShArr {
  width: 33px;
  margin-top: -40px;
  margin-bottom: 7px;}


  .streamSchedBottomCostBlock {
    background-color: #214978;
    min-height: 50px;
    color: white;
    font-size:16px;
    border-top: 3px solid #143051;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
  border-right:3px solid #143051;
  font-size:13px;
  }

  .streamSchedBottomTitle {
    font-size: 10px;
    opacity: 0.5;
  }

  .alignRight2 {
    float: right;
  }

  .alignLeft2 {
    float: left
  }

  .teamCostInSh {
    margin-left:6px;
    cursor: pointer;
  }

  .teamCostinclSpan {
    margin-left: 6px;
    color: #bbcada;
    font-size: 10px;
    margin-top: -15px;
    margin-bottom: -18px;
  }

  .shTeamCostArr {
    max-width: 10px;
    margin-bottom: 1px;  }

    .shTeamCostArrActive {
      transform: rotate(-90deg);
    }

    .shCostArr {
      max-width: 225px;
      border-radius: 5px;
      font-size: 12px; 
    margin-bottom:20px;   }

    .shCostArrRow {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #bbcada;
      max-height: 35px;
    }

    .shCostArrTitle {
      font-weight: 400;
    }

    .shCostArrValue {
      color:#143051;
      font-weight: 500;
    }

    .x8382818133 {
      cursor: default;
    }

    .x8382818133:hover {
      opacity: 1;
    }



.fadeEditSh {
  opacity:0.5
}