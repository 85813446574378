.adminBg {
  max-height: 100vh;
  background-color: rgb(251, 251, 255);
  /* background-color: red; */
}

.adminTopContainer {
  background-color: #fff;
  /* background: rgb(20, 48, 81); */
  /* background: linear-gradient(
    0deg,
    rgba(20, 48, 81, 1) 43%,
    rgba(29, 69, 115, 1) 91%
  ); */

  display: flex;
  flex-wrap: wrap;
  min-width: 100vw;
  max-width: 100vw;
  height: 130px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e6eaee;
}

.desktopAdminTopContainerHeight {
  height: 125px;
  border-bottom: 2px solid #e6eaee;
}

.adminTopInnerContainer {
  /* background-color: grey; */
  display: flex;
  justify-content: space-between;
  /* margin-left: 5%; */
  /* width: 90%; */
  /* padding-top: 5px; */
  /* margin-top: 25px; */
  align-items: center;
  text-align: left;
}

.companySettings {
  color: #143051;
  font-weight: 500;
  margin-top: 34px;
  font-size: 28px;
  margin-left: 26px;
}

.statsPie {
  min-width: 30px;
  padding-right: 14px;
}

.adminMenuHolder {
  /* background-color: yellow; */
  overflow: scroll;
  max-width: 95%;
  min-width: 95%;
  margin-left: 5%;
  max-height: 38px;
  margin-top: 18px;
  /* max-width: 100%; */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.adminMenuHolder::-webkit-scrollbar {
  display: none;
}

.adminMenuContainer {
  /* background-color: cyan; */
  display: flex;
  min-width: 980px;
  margin-top: -7px;
}

.adminMenuUnit {
  /* min-width: 60px; */
  text-align: center;
  /* padding-left: 11px;
  padding-right: 11px; */
  margin-left: 8px;
  color: #6a98d1;
  font-weight: 400;
  line-height: 31px;
  padding-top: 10px;
  padding-left: 4px;
  padding-right: 5px;
  font-size: 1.12em;
  cursor: pointer;
  /* background-color: yellow; */
}

.adminMenuUnit:active {
  color: #384a5e;
  color: #9ab9df;
  /* color: #143051; */
  transform: scale(0.97);

  /* font-size: 0.95em; */
  /* margin-top: 1px; */
}

.adminMenuUnitFirst {
  text-align: left;
  margin-left: 3px;
}

.adminTitleSel {
  font-weight: 500;
  color: #143051;
  color: #214978;
  border-bottom: 1px #ccdff1 solid;
}

.whiteGradientOverScrollBar {
  background-image: linear-gradient(
    to left,
    rgba(20, 48, 81, 1),
    rgba(20, 48, 81, 0)
  );
  min-height: 25px;
  min-width: 115px;
  position: absolute;
  top: 95px;
  right: 0;
  pointer-events: none;
  display: none;
  /* background-color: black; */
}

.whiteGradientAdmin {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  top: 130px;
  min-height: 6px;
  max-width: 90vw;
  min-width: 90vw;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(255, 255, 255, 1)
  );
  background-repeat: repeat-x;
  z-index: 3;
  display: none;
}
.AdminInner {
  /* background-color: rgb(251, 251, 255); */
  margin-top: 12px;
  padding-bottom: 40px;
  padding-left: 10px;
  padding-right: 10px;
}

@media screen and (max-width: 819px) {
  .AdminInner {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.AdminRowContainer {
  min-width: 100%;
  padding-left: 20px;
  padding-right: 40px;
  min-height: 44px;
  margin-top: 10px;
  /* background-color: rgb(251, 251, 255); */
  /* border-bottom: 1px solid #ddebf4; */
  /* animation-name: zoomModalIn !important; */
  /* animation-duration: 0.1s !important; */
  padding-bottom: 30px;
  background-color: white;
  border-radius: 10px;
  /* margin-bottom: 100px; */
}

.AdminRowContainer:first-of-type {
  margin-top: 0px;
}

.overtimeNoBorder {
  border: none !important;
  border-bottom: 1px solid white;
}
.overtimeBorderBottom {
  border-bottom: 1px solid #143051;
}

.adminLogo {
  max-width: 75px;
  max-height: 20px;
}

.pHolRateTitle {
  /* background-color: yellow; */
  min-width: 150px;
  text-align: left;
}

.AdminRowContainer:last-of-type {
  border-bottom: 0px solid #fff;
}

.specialDayHistory {
  margin-right: 15px;
  cursor: pointer;
}

/* USERS & TEAMS */
.UTSettingsContainer {
  /* background-color: pink; */
  /* max-height: calc(100% - 228px); */
  overflow: hidden;
  /* width: 90vw; */
  display: block;
  /* position: absolute;
  margin: auto;
  left: 0;
  right: 0; */
}

.utUpperContainer {
  /* background-color: #f5faff; */
  display: flex;
  color: #143051;
  justify-content: space-around;
  align-items: center;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 25px;
}

.utSelected {
  font-weight: 500;
  border-bottom: 1px solid #143051;
}

.utSearchIcon {
  width: 35px;
  position: fixed;
  top: calc(100% - 210px);
  right: 94px;
  z-index: 999;
  opacity: 0.7;
}

.utSearchIconCanx {
  width: 37px;
  position: fixed;
  top: calc(100% - 213px);
  right: 92px;
  z-index: 9999999;
  opacity: 1;
}

.utSearchIconHidden {
  visibility: hidden;
}

.utLowerContainer {
  /* background-color: rgb(251, 251, 255); */
  /* position: fixed;
  left: 0;
  right: 0; */
  /* max-width: 90vw; */
  /* max-width: calc(100vw - 420px); */
  /* padding-left: 5%; */
  /* padding-right: 5%; */
  top: 130px;
  /* margin: auto; */
  /* overflow: scroll; */
  min-height: 100%;
  max-height: calc(100% - 152px);
  min-height: calc(100% - 152px);
  margin-top: 2px;
  /* margin-left: calc(100vw - 240px); */
  /* border-top: 2px #143051 solid; */
  /* background-color: purple; */
}

.utLowerContainerMob {
  max-width: 90vw;
  min-width: 90vw;
  /* background-color: orange; */
  margin-left: 5vw;
  margin-top: 0px;
}

.utLowerContainer::-webkit-scrollbar {
  display: none;
}

/* Admin Users/Teams: Users */
.adminUsersContainer {
  /* background-color: red; */
  display: block;
  margin-top: 0px;
  /* padding-top: 10px; */
  padding-bottom: 30px;
  padding-right: 14px;
  max-height: calc(100vh - 164px);
  overflow-y: scroll;
  max-width: 350px;
  margin-left: 43px;
  /* margin-left: calc(100vw - 240px); */
}

.adminUsersContainerMob {
  display: block;
  margin-top: 0px;
  /* padding-top: 10px; */
  padding-bottom: 30px;
  /* padding-right: 14px; */
  max-height: calc(100vh - 164px);
  overflow-y: scroll;
  max-width: 100%;
  min-width: 100%;
  /* margin-left: 43px; */
  /* background-color: pink; */
}

.adminUserBg {
  /* background-color: orange; */
  min-width: 100%;
}

.archColor {
  opacity: 0.5;
}

.adminUserUnitBox {
  background-color: white;
  min-height: 54px;
  /* margin-top: 7px; */
  /* border-radius: 5px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #143051;
  border: solid 1px #e0e5ed;
  border-bottom: solid 2px #e0e5ed;
  margin-top: 8px;
  /* margin-bottom: 2px; */
  border-radius: 5px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  cursor: pointer;
}

.adminUserUnitBoxSelected {
  font-weight: 500;
  /* background-color: #143051; */
  border-radius: 5px;
  border-bottom: 1px solid #475974;
  z-index: 999;
  /* color: blue; */
  border: 2px solid #143051;
  padding-top: 4px;
  margin-top: 12px;
  cursor: pointer;
  /* margin-bottom: 10px; */
  /* color: white; */

  /* font-size: 1.04em; */
}

.selectedUserBar {
  min-width: 3px;
  max-width: 3px;
  min-height: 30px;

  /* margin-top: -6px; */
  max-height: 30px;
  margin-bottom: 2px;
  /* margin-top: -2px; */
  background-color: #ccdff1;
  color: white;
  border-radius: 100px;
  display: none;
}

.invis {
  visibility: hidden;
}

.nameCombined {
  /* margin-top: 11px; */
  margin-left: 8px;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  color: #496375;
}

.utName {
  display: inline-flex;
  margin-left: 2%;
  font-size: 0.9em;
  text-align: left;
  align-items: center;
  font-size: 1em;
  /* margin-top: -11px; */
}

.utNameSelected {
  margin-top: -1px;
  /* margin-left: 4px; */
}

.utJobTitle {
  margin-right: 4%;
  font-size: 1em;
  text-align: right;
  font-weight: 400;
  font-size: 0.8em;
  margin-top: -1px;
  color: #496375;
  width: 200px;
}

.mobileNone {
  /* REMOVE THIS FOR THE DESKTOP VERSION */
  display: none;
}

.AddNewUserAdmin {
  position: fixed;
  top: calc(100% - 188px);
  right: 45px;
  background-color: #143051;
  color: white;
  border-radius: 100%;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  line-height: 44px;
  font-size: 3em;
}

.none {
  display: none !important;
}

.whiteColor {
  /* color: white; */
}

.openUserBtn {
  font-weight: 500;
}

/* unarchive user modal */
.unarchiveUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999999999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
}

@keyframes underlayFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.unarchiveModalBox {
  background-color: #fff;
  min-width: 90vw;
  max-width: 90vw;
  height: 150px;
  margin-top: -10vh;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #143051;
  font-weight: 500;
  text-align: center;
  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
}

/* .unarchiveContainer {
  
} */

.unarchiveButton {
  background-color: #8ea7c5;
  border: 2px solid #143051;
  min-height: 35px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  border-radius: 30px;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
  font-size: 1.1em;
}

/* search user modal */
.adminUnderlay {
  /* background: rgba(0, 0, 0, 1); */

  background-color: #536f8f;
  /* height: 130px; */
  min-height: 125px;
  max-height: 125px;
  border-radius: 0 0 15px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999999999;
  top: 0px;
  left: 0;
  animation-name: searchBarSlideDown;
  animation-duration: 0.2s;
}

@keyframes searchBarSlideDown {
  from {
    margin-top: -80px;
    opacity: 0;
  }
  to {
    margin-top: 0px;
    opacity: 1;
  }
}

.binoculars {
  max-width: 40px;
  margin-top: -70px;
}

.searchUserModal {
  background-color: white;
  min-height: 55px;
  min-width: 90%;
  max-width: 90%;
  position: absolute;
  top: 52px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.searchUserInput {
  min-height: 40px;
  width: 75%;
  font-size: 1.7em;
  color: #143051;
  text-indent: 3%;
  border: none;
  margin-left: 5%;
  /* border-bottom: 2px solid #143051; */
  font-weight: 500;
  /* color: #143051; */
}

.searchUserInput::placeholder {
  font-size: 0.7em;
  color: #536f8f;
}

.cancelSearchImg {
  width: 28px;
  margin-right: 6%;
  transform: rotate(270deg);
}

.keyboardDown {
  width: 24px;
  margin-left: 5%;
  margin-top: 5px;
}

.whiteCurveL {
  position: absolute;
  top: 106px;
  left: 1px;
  z-index: 999999;
  max-width: 14px;
}

.adminCurverCropper {
  min-width: 100vw;
  position: absolute;
  top: 124px;
  max-height: 23px;
  overflow: hidden;
  padding-top: 5px;
  display: none;
  /* background-color: #f4f8fe; */
}

.adminCurve {
  /* min-width: 100vw;
  position: absolute;
  top: 130px; */
  /* border-radius: 18px; */
  background-color: rgb(251, 251, 255);
  /* min-height: 10px; */
  border-top: 1px solid #e6eaee;
  /* -webkit-box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.39); */
  /* box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.225); */
  /* border-top: 2px solid #214978; */
}

/* sub unit box below the user row */
.adminUserUnitBoxSubBox {
  /* background-color: #e0ebf8; */
  min-height: 40px;
  min-width: 85%;
  max-width: 85%;
  animation-name: moveSubBoxIn !important;
  animation-duration: 0.15s !important;
  margin-left: 15px;
  border-radius: 0px 0px 6px 6px;
  background: rgb(88, 112, 150);
  background: linear-gradient(
    0deg,
    rgba(88, 112, 150, 1) 0%,
    rgba(88, 112, 150, 1) 90%,
    rgba(69, 89, 120, 1) 100%
  );
  margin-bottom: 12px;
}

@keyframes moveSubBoxIn {
  from {
    margin-top: -40px;
    opacity: 0;
  }
  to {
    margin-top: 0px;
    opacity: 1;
  }
}

.userSubBoxContainer {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  color: white;
}

.subBoxAllowance {
  margin-left: 12px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.9em;
  animation-name: zoomModalIn !important;
  animation-duration: 0.2s !important;
}

.userRemainingTxt {
  font-size: 1em;
  margin-left: 6px;
}

.editUser {
  /* background-color: #9a74eb; */
  background-color: #0077ff;
  border: 2px solid #143051;
  color: white;
  border-radius: 5px;
  min-height: 30px;
  line-height: 30px;
  font-size: 0.9em;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 12px;
  cursor: pointer;
  font-weight: 500;

  -moz-box-shadow: inset 0px 2px 0px #1964ba;
  -webkit-box-shadow: inset 0px 2px 0px #1964ba;
  min-width: 62px;
  max-width: 62px;
  box-shadow: inset 0px 2px 0px #1964ba;
}

.editUser:active {
  transform: scale(0.95);
}

.loadingBg {
  background-color: #7495ba;
  box-shadow: inset 0px 2px 0px #475974;
  opacity: 1;
  min-width: 62px;
  max-width: 62px;
}

.nameDropArrow {
  width: 7px;
  transform: rotate(270deg);
  margin-top: 13px;
}

.nameDropArrowSelected {
  width: 7px;
  transform: rotate(0deg);
  margin-top: 2px;
  margin-left: -3px;
  animation-name: rotateDropArrow;
  animation-duration: 0.1s;
}

@keyframes rotateDropArrow {
  from {
    transform: rotate(270deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.adminUserUnitBox:last-of-type {
  border: none;
}

.waitGIF {
  width: 14px;
  /* padding-top: 2px; */
}

.archiveUnitBox {
  /* background-color: grey; */
  /* border-radius: 5px; */
  display: flex;
  justify-content: space-between;
  border-bottom: solid 2px #e0ebf8;
  align-items: center;
  color: #496375;
  height: 55px;
  margin-top: 2px;
}

.archivedName {
  margin-left: 3%;
  text-align: left;
  font-weight: 500;
}

.archJobTitle {
  /* height: 12px;
  line-height: 12px; */
  /* margin-top: 10px; */
  margin-right: 3%;
  font-size: 0.8em;
  font-weight: 500;
  text-align: right;
}

/* ADMIN TEAMS PAGE  - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.teamUnit {
  max-height: 54px;
  min-height: 54px;
  background-color: #fff;
  /* margin-top: 3px; */
  border-radius: 15px 15px 15px 15px;
  color: #496375;
  margin-top: 12px;
  border: 2px solid #143051;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  /* border: none; */
}

.teamUnitUnselected {
  max-height: 54px;
  min-height: 54px;

  background-color: #fff;

  margin-top: 3px;
  color: #143051;
  border: solid 1px #e0e5ed;
  border-bottom: solid 2px #e0e5ed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  cursor: pointer;
  border-radius: 15px;
  margin-top: 8px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.absenceRowMainText {
  text-align: left;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 14px;
  padding-left: 15px;
  font-size: 14px;
}

.zoomInGPSItems {
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  padding-left: 10px;
  padding-right: 10px;
}

.teamUnitTeamName {
  margin-left: 8px;
  min-width: 250px;
  text-align: left;
  font-size: 1.1em;
  color: #496375;
  font-weight: 500;
}

.teamUnitTeamNameSel {
  margin-left: 12px;
  min-width: 250px;
  text-align: left;
  font-size: 1.1em;
}

.teamUnitQty {
  margin-right: 20px;
  background-color: #8ea7c5;
  color: white;
  border-radius: 55px;
  min-width: 26px;
  min-height: 24px;
  line-height: 24px;
  font-size: 0.9em;
  /* padding-top: 2px; */
  padding-top: 2px;
}

.teamUnitQtyUnsel {
  margin-right: 20px;
  background-color: #384a5e;
  cursor: pointer;

  color: white;
  border-radius: 55px;
  min-width: 26px;
  min-height: 24px;
  line-height: 24px;
  font-size: 0.9em;
  padding-top: 2px;
}

.offtodayspan {
  font-size: 0.8em;
}

.adminTeamsContainer {
  /* background-color: red; */
  position: relative;
  z-index: 1;
  display: block;
  /* margin-top: -5px; */
  /* padding-top: 10px; */
}

.selectedBarAndTeamName {
  /* display: flex; */
  display: flex;
  margin-left: 2%;
  align-items: center;

  /* max-height: 50px; */
}

.subBoxOffToday {
  margin-left: 12px;
  color: white;
}

.teamUnitSubBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  min-width: 85%;
  max-width: 85%;
  animation-name: moveSubBoxIn;
  animation-duration: 0.15s;
  margin-left: 5px;
  border-radius: 0px 0px 10px 10px;
  background: rgb(88, 112, 150);

  background: linear-gradient(
    0deg,
    rgba(88, 112, 150, 1) 0%,
    rgba(88, 112, 150, 1) 90%,
    rgba(69, 89, 120, 1) 100%
  );
  margin-left: 15px;
  margin-bottom: 12px;
}

.knobPublicHolsAdmin {
  /* background-color: orange; */
  min-width: 33px;
}

.bottomBorderNavy {
  border-bottom: 1px solid #7495ba;
}

/* .shiftTeamSubBubble {
  /* background-color: #a2bddf;
  background-color: #496375; */
/* color: white; */
/* margin: 0 auto; */
/* padding-left: 10px; */
/* padding-right: 10px; */
/* min-height: 24px; */
/* line-height: 24px; */
/* font-size: 0.75em; */
/* margin-top: -10px; */
/* margin-bottom: 8px; */
/* border-radius: 30px; */
/* max-width: fit-content; */
/* } */
*/ .shiftplaceholdertxt {
  margin-top: 10px;
  font-weight: 500;
  color: #8ea7c5;
}

.redSchedRow {
  background-color: red;
}

.accruedLeaveReportsSpan {
  font-weight: 500;
  color: #143051;
  font-size: 1.05em;
}

.moreDetailsButton {
  background-color: #fff;
  border: solid 1px #e6eaee;
  display: flex;
  /* color: #7495ba; */
  /* color: white; */
  font-size: 16px;
  max-width: 120px;
  min-width: 120px;
  padding-left: 10px;
  text-align: center;
  line-height: 25px;
  height: 26px;
  border-radius: 15px;
  /* margin-top: -24px; */
}

.explainedBookIcon {
  max-width: 15px;
  margin-left: 10px;
}

.addExcessToAllowanceInstead {
  /* background-color: blue; */
  margin-top: 34px;
  min-width: 90vw;
  /* border: 1px solid #a2bddf; */
  border-top: 1px solid #e6eaee;
  /* border-radius: 15px; */
  max-width: 250px;
  min-width: 250px;
  padding: 12px;
  padding-left: 4px;
  display: flex;
  align-items: center;
  color: #496375;
  margin-left: 18px;
}

.addExcessToAllowInsteadText {
  text-align: left;
  font-size: 1.2em;
  /* background-color: blue; */
  max-width: 180px;
  color: #496375;
  /* margin-bottom: 4px; */
}

.addAccruedToAllowKnobContainer {
  margin-top: -7px;
  margin-left: 17px;
  position: relative;
  z-index: 99999999999999;
}

.currencyDropdown {
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  padding-left: 10px;
  text-align: left !important;
  max-width: 214px;
  min-width: 214px;
  line-height: 26px;
  height: 35px;
  /* border: 1px solid #e6eaee; */
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  /* border-color: #2c7eb9; */
  border: 2px solid #dde9f9;
  /* background-color: #0bf; */
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 187px;
}

.currencyDropdown:hover {
  border-color: #bbcada;
}

.adminCurrencySymbolTxt {
  text-align: right;
  margin-right: 5%;
  color: #496375;
  font-weight: 500;
  margin-top: 6px;
  margin-bottom: -8px;
  font-size: 12px;
}

.overtimeTitleContainer {
  background-color: #143051;
  color: #fff;
  line-height: 40px;
  padding-left: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 20px;
  border-radius: 10px 10px 10px 0px;
  max-width: fit-content;
  max-width: 282px;
  margin-top: 20px;
}

.defaultOvertimeTitle {
  /* color: #fff; */
  font-weight: 500;
  font-size: 1.2em;
  text-align: left;
  /* background-color: #143051; */
  /* line-height: 40px;
  padding-left: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 20px;
  border-radius: 15px 25px 25px 0px;
  max-width: fit-content; */
}

.defaultOvertimeDisclaimer {
  text-align: left;
  line-height: 18px;
  padding-bottom: 8px;
  font-size: 0.8em;
  max-width: 80%;
  opacity: 0.8;
}

.overtimeDefaultDownArmShort1 {
  min-width: 8px;
  max-width: 8px;
  position: absolute;
  left: 0px;
  /* top: -10px; */
  margin-top: -21px;
  min-height: 328px;
  background-color: #143051;
  border-radius: 100px;
}

.overtimeDefaultDownArmShort2 {
  min-width: 8px;
  max-width: 8px;
  position: absolute;
  left: 0px;
  /* top: -10px; */
  margin-top: -21px;
  min-height: 140px;
  background-color: #143051;
  border-radius: 100px;
}

.overtimeDefaultDownArmLong1 {
  min-width: 8px;
  max-width: 8px;
  position: absolute;
  left: 0px;
  /* top: -10px; */
  margin-top: -21px;
  min-height: 425px;
  background-color: #143051;
  border-radius: 100px;
}

.overtimeDefaultDownArmLong2 {
  min-width: 8px;
  max-width: 8px;
  position: absolute;
  left: 0px;
  /* top: -10px; */
  margin-top: -21px;
  min-height: 275px;
  background-color: #143051;
  border-radius: 100px;
}

.overtimeRowSubText {
  /* margin-left: 14px; */
  /* min-height: 202px; */
  font-weight: 400;
  /* font-size: 1.2em; */
}

.minHeight202 {
  min-height: 202px;
}

.overtimeSubInstruction {
  text-align: left;
  padding-left: 14px;
  color: #859dbb;
  margin-top: -20px;
  font-size: 0.9em;
  font-weight: 400;
}

.overtimeBg {
  background: rgb(232, 239, 251);
  background: linear-gradient(
    0deg,
    rgba(232, 239, 251, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.overtimeIndividualTableContainer {
  min-width: 100%;
  max-width: 100%;
  padding-bottom: 30px;
}

.indivOTstaffTitle {
  text-align: left;
  background-color: #143051;
  color: white;
  max-width: fit-content;
  padding-left: 15px;
  padding-right: 15px;
  max-height: 42px;
  line-height: 44px;
  border-radius: 10px;
  margin-bottom: 25px;
  margin-top: 15px;
  font-size: 1em;
}

.OTinvidualsMasterContainer {
  border-top: 1px solid #ddebf4;
  margin-top: 8px;
}

.individualOvertimeUserContainer {
  display: flex;
  justify-content: space-between;
  background-color: #f7fbff;
  /* padding-top: 20px; */
  border-radius: 5px;
  margin-top: 12px;
}

.individualOTName {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  padding-left: 10px;
  /* background-color: blue; */
  font-weight: 500;
  font-size: 0.8em;
}

.payableBold {
  color: #143051;
}

.indivLName {
  margin-top: -20px;
}

.evenBgColorOvertimeUser {
  background-color: #ebf2f9;
  border-radius: 5px;
}

.rightSideDataOvertime {
  /* background-color:  orange; */
  display: flex;
  align-items: center;
}

.otSettingTitle {
  /* background-color: orange; */
  max-width: 60px;
  font-size: 0.7em;
  line-height: 14px;
  display: flex;
  font-weight: 400;
  /* align-items: center; */
  text-align: left;
}

.otUnit1 {
  display: flex;
  align-items: center;
}

.otUnit2 {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 8px;
  padding-left: 10px;
  border-left: 1px solid #143051;
}

.otIndivSettingContainer {
  margin-top: -7px;
  margin-left: -10px;
}

.otIndivSettingContainer2 {
  margin-top: -7px;
  margin-left: 1px;
}

.indiStaffOverTimeInstructionsTitle1 {
  text-align: left;
  text-indent: 3%;
  margin-top: 10px;
  background-color: #859dbb;
  color: white;
  max-width: 140px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 30px;
  font-size: 0.9em;
  line-height: 32px;
  max-height: 30px;
  margin-left: 16px;
}

.indiStaffOverTimeInstructionsTitle2 {
  text-align: left;
  text-indent: 3%;
  margin-top: -10px;
  background-color: #859dbb;
  color: white;
  max-width: 158px;
  border-radius: 30px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 30px;
  font-size: 0.9em;
  line-height: 32px;
  margin-top: 20px;
  max-height: 30px;
  margin-left: 16px;
}
.indiStaffOverTimeInstructionsSub {
  text-align: left;
  margin-left: 3%;
  margin-top: 10px;
  font-weight: 400;
  line-height: 22px;
  font-size: 0.8em;
  font-weight: 500;
  color: #8997a8;
  margin-left: 16px;
}

.infoBtnContainer {
  display: flex;
  border: 1px solid #143051;
  border-radius: 10px;
  max-width: 70px;
  padding-left: 10px;
  max-height: 30px;
  line-height: 30px;
  color: white;
  font-size: 0.9em;
  background-color: #859dbb;
  margin-left: 16px;
  min-width: 70px;
}

.infoBtnContainerActive {
  display: flex;
  border: 1px solid #143051;
  border-radius: 30px;
  max-width: 70px;
  padding-left: 10px;
  max-height: 30px;
  margin-left: 16px;
  line-height: 30px;
  min-width: 70px;

  font-size: 0.9em;
  /* background-color: #31537a; */
}
.infoBtnI {
  max-width: 18px;
  margin-left: 5px;
}

.closeInfoBtn {
  max-width: 10px;
  margin-left: 10px;
}

.infoAndExplainedContainerOvertime {
  display: flex;
  /* background-color: blue; */
  align-items: center;
  max-width: 220px;
  justify-content: space-between;
}

.shortPayAccrueBox {
  max-height: 105px;
}

.mediumPayAccrueBox {
  max-height: 240px;
}

.overtimeDefaultDownArmMedium {
  max-height: 100px !important;
}

.overtimeInfoBtn2 {
  margin-left: 0px;
  margin-top: -10px;
}

.overtimeInfo2SideBar {
  min-width: 4px;
  min-height: 275px;
  max-width: 4px;
  border-radius: 10px;
  margin-top: 10px;
  position: absolute;
  background-color: #143051;
}

.overtimePanelUserIsZero {
  display: flex;
  /* background-color: blue; */
  color: #859dbb;
  min-width: 208px;
  justify-content: flex-end;
  padding-right: 1px;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
  /* margin-right: 12px; */
}

.right14px {
  margin-left: 14px;
}

.dataLoader {
  max-width: 34px;
  margin-top: 15px;
}

.loadingShiftOpenBtn {
  opacity: 0.3;
  pointer-events: none;
}
.overtimeRatesContainer {
  /* text-align: center; */
}

.overtimeRateRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: orange; */
  /* min-width: 120px; */
  /* max-width: 190px; */
  min-width: 90%;
  max-width: 90%;
  margin-top: 10px;
  /* margin: 10px auto; */
  margin-left: 5%;
}

.noBorderAtBottom {
  /* border-bottom: 0px solid white; */
  /* border: none; */
}

.ukRequirementSpan {
  font-weight: 400;
  color: #859dbb;
  font-size: 12px;
  line-height: 12px;
}

.weeksBelowRefPeriodDrop {
  margin-top: -10px;
  margin-left: 36px;
  font-size: 12px;
  /* margin-bottom: -10px;} */
}

.invisAllowPreview {
  visibility: hidden;
}

.loadingAllowPreviewImg {
  max-width: 40px;
  margin-left: 16px;
}

.pubHolExclusionTitle {
  text-align: left;
  line-height: 23px;
  min-height: 30px;
  margin-top: 14px;
  margin-left: 14px;
  font-size: 14px;
}
.holExclusionsContainer {
  max-width: 62%;
  min-width: 62%;
  /* background-color: orange; */
  text-align: left;
  margin-bottom: 40px;
  margin-top: 40px;
}

.exclusionUnit {
  display: flex !important;
  min-height: 40px;
  /* max-height: 40px; */
  align-items: center;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid white;
}

.exclusionUnit:nth-child(even) {
  background-color: #ebf2f9;
  border: 1px solid #ebf2f9;
}

.exclusionUnit:hover {
  border: 1px solid #e7eef5;
}

.exclusionTitle {
  text-align: left !important;
  font-size: 0.8em;
  line-height: 18px;
  font-weight: 400;
}

.excluKnobIgnoreContainer {
  display: flex;
  justify-content: space-between;
  min-width: 80px;
  max-width: 80px;
}

.exclusionKnob {
  margin-top: -5px;
}

.ignoreExcluActive {
  font-size: 0.7em;
  color: #0077ff;
}

.ignoreExcluInactive {
  font-size: 0.6em;
  color: #859dbb;
  padding-top: 1px;
  margin-bottom: -1px;
}

.overtimeRowSubText {
  max-width: 93%;
  min-width: 93%;
}

.payAccruedOvertimeKnob {
}

.overtimeAdminKnob {
  margin-right: 29px;
}
.knobAdjustmentForSelfGenSched1 {
  margin-right: 16px;
}

.knobAdjustmentForSelfGenSched2 {
  margin-right: 52px;
}

.dailyRatesDataContainer {
  margin-top: 22px;
  padding-bottom: 40px;
}

.dailyPayRateRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 60%;
  margin-top: 10px;
  margin-right: 5%;
}

.dailyPayRateDropdown {
  max-width: 80px;
  min-width: 80px;
  max-height: 40px;
  min-height: 35px;
  background-color: #fff;
  font-size: 14px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 54px;
  border-radius: 5px;
  text-align: left;
  color: #496375;
  /* margin-top: 20px; */
  padding-left: 12px;
  border: 2px solid #dde9f9;
  font-weight: 500;
  cursor: pointer;
}

.dailyPayRateTxt {
  margin-right: 15px;
  font-size: 14px;
  font-weight: 500;
  color: #214978;
}

.scheduleRowSubText {
  max-width: 100%;
}

.scheduleDailyRatesLeftBox {
  background-color: orange;
  max-width: 40%;
}

.dailyRatesWrapper {
  background-color: pink;
}

.genSchedDaysQtyInput {
  max-width: 75px;
  min-width: 75px;
  max-height: 40px;
  min-height: 35px;
  padding-top: 2px;
  background-color: #fff;
  font-size: 0.9em;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 50px;
  border-radius: 10px;
  text-align: left;
  color: #496375;
  margin-top: 20px;
  margin-right: 5%;
  font-weight: 500;
  /* padding-left: 10px; */
  /* margin-right: 40px; */
  padding-left: 14px;
  /* font-size: 0.9em; */
  /* text-align: right; */
  /* height: 40px; */
  border: 2px solid #dde9f9;
  /* margin-right: 10px; */
  /* margin-right: 5%; */
}

.monthResetDropdown {
  max-width: 80px;
  min-width: 80px;
  max-height: 40px;
  min-height: 35px;
  background-color: #fff;
  font-size: 14px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 54px;
  border-radius: 5px;
  text-align: left;
  color: #496375;
  margin-top: 20px;
  padding-left: 12px;
  border: 2px solid #dde9f9;
  font-weight: 500;
  cursor: pointer;
}

.monthResetDropdown:hover {
  border-color: #bbcada;
}

.clockOnPeriodDropdown {
  max-width: 105px;
  min-width: 105px;
  max-height: 40px;
  min-height: 35px;
  background-color: #fff;
  font-size: 14px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 82px;
  border-radius: 5px;
  text-align: left;
  color: #496375;
  margin-top: 20px;
  padding-left: 14px;
  border: 2px solid #dde9f9;
  font-weight: 500;
  cursor: pointer;
}

.clockOnPeriodDropdown:hover {
  border-color: #bbcada;
}
.weeksTxtSchedGen {
  margin-top: -14px;
  font-size: 0.9em;
  margin-left: 20px;
  margin-bottom: -10px;
}

.genSchedQtyRowBit {
  max-width: 212px;
  /* margin-left: 15px; */
}

.noBottomBorder {
  border-bottom: none;
}

.knobContainerCompanyClosed {
  /* background-color: orange; */
  /* margin-top: -4px; */
  margin-left: 12px;
  padding-bottom: 8px;
}

.closedDayTxt {
  font-size: 14px;
  color: #143051;
  margin-left: 12px;
  min-width: 50px;
  font-weight: 500;
  cursor: pointer;
}

.dailyRateRowSplitter {
  min-height: 1px;
  max-height: 1px;
  min-width: 100%;
  margin-top: 10px;
  background-color: #e6eaee;
}

.narrowerSub {
}

.monthResetDropdownHolder {
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  /* min-width: 80px; */
}

.clockOnRefSubTxt {
  /* max-width: 80px; */
  font-weight: 400;
}

.openClosedTxtContainer {
  max-width: 66px;
  min-width: 66px;
}

.specialDaysContainer {
  display: flex;
  margin-top: -23px;
  padding-bottom: 40px;
  padding-left: 15px;
  flex-direction: column;
  align-items: flex-end;
}

.specialDaysUpper {
  min-width: 100%;
  display: flex;
  justify-content: flex-end;

  position: relative;
  z-index: 999;
}

.specialDaysHolder {
  /* background-color: lime; */
  padding-top: 25px;
  margin-top: -40px;
}

.specialDaysAddBtn {
  background-color: #a387df;
  border: 1px solid #8e76c1;
  color: #fff;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 9px;
  padding-right: 9px;
  cursor: pointer;
  /* margin-left: 10px; */
  margin-right: 5%;
  margin-top: -14px;
}

.specialDaysAddBtn:hover {
  background-color: #997ed4;
}

.addZoneParent {
  min-width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
  margin-top: 14px;
}
.addGPSBtn {
  font-size: 14px;
  color: #fff;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 6px;
  max-height: 35px;
  border-radius: 5px;
  min-height: 35px;
  max-width: 103px;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.addCustomBtn {
  max-width: fit-content;
  /* background-color: #a387df;
  border: 1px solid #8e76c1; */
  font-size: 12px;
  min-height: 32px;
  max-height: 32px;
}
.addCustomBtn:hover {
  /* background-color: #997ed4 !important; */
}

.addZonePlus {
  max-width: 12px;
  margin-left: 4px;
}

.closedDaysHistoryBtn {
  font-size: 14px;
  color: #fff;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  line-height: 26px;
  border-radius: 5px;
  min-height: 30px;
  margin-right: 5%;
  max-width: 80px;
  text-align: center;
  margin-top: 18px;
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.closedDaysHistoryPopUp {
  max-width: 14px;
  margin-left: 4px;
}

.closedDaysHistoryBtn:hover {
  background-color: #bbcada;
}

.specialDaysLower {
  /* background-color: rgb(245, 245, 245); */
  border-radius: 10px 0px 0px 10px;
  /* margin-top: 10px; */
  display: flex;
  justify-content: flex-end;
  padding-bottom: 15px;
  /* padding-top: 5px; */
  margin-top: 25px;
  padding-right: 10px;
  margin-bottom: 40px;
  margin-right: 5%;
  border-right: solid 2px #698396;
}

.gpsList {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 15px;
  margin-top: 25px;
  margin-right: 5%;
}
.specialDayUnitsContainer {
  width: 240px;
}

.specialDayUnit {
  /* background-color: #fff; */
  height: 40px;
  line-height: 40px;
  color: #496375;
  border-radius: 6px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  /* border: 2px solid #5f7792; */
  cursor: pointer;
  border: solid 2px #dee5ed;

  /* animation-name: zoomModalIn !important; */
  /* animation-duration: 0.1s !important; */
}


.specialDayDate {
  margin-left: 13px;
  /* background-color: yellow; */
  min-width: 120px;
  font-size: 14px;
  margin-top: -2px;
  text-align: left;
  font-weight: 500;
  line-height: 18px;
  min-height: 38px;
  display: flex;
  align-items: center;
}

.specialDayRate {
  color: #bbcada;
  font-weight: 500;
  font-size: 11px;
  text-align: left;
  margin-left: 13px;
  margin-top: -6px;
  border-top: 1px solid #bbcada;
  line-height: 23px;
}

.specialDaysTrash {
  margin-right: 12px;
  max-width: 12px;
  cursor: pointer;
}

.companyClosed {
  width: 11px;
  margin-top: 4px;
}

.shut {
  font-size: 10px;
  font-weight: 500;
  margin-top: -10px;
  /* width: 50px; */
  /* padding-right: -4px; */
  /* padding-left: -4px; */
}

.trashInvis {
  opacity: 0;
  pointer-events: none;
}

.lighterBgSpecialDay {
  background-color: #fff;
  height: 40px;
  line-height: 40px;
  color: #496375;
  border-radius: 7px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
  font-weight: 500;
  /* border: 2px solid #5f7792; */
  opacity: 0.5;
  border: solid 2px #dee5ed;
  cursor: pointer;
}

/* MODAL */
.specialDayModalUnderlay {
  /* background: rgba(0, 0, 0, 0.25); */
  /* max-height: calc(100% - 58px); */
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.none {
  display: none;
}

.specialDayModalBox {
  background-color: #fff;
  border-radius: 5px;
  min-height: 35px;
  max-width: 338px;
  min-width: 338px;

  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
}

.specialDayModalText {
  padding: 10px 20px 25px;
  /* color: #496375; */
  color: #496375;
}

.specialDayModalClose {
  font-size: 0.9em;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 2px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 30px;
  line-height: 25px;
  border-radius: 5px;
  min-height: 30px;
  max-width: 70px;
  margin: 22px auto;
  margin-bottom: 0px;
}

.addSpecialDayModalBox {
  /* background-color: #e0ebf8; */
  background-color: white;
  max-width: 338px;
  margin: 0 auto;
  border-radius: 5px;
  overflow: hidden;
  /* height: 366px; */

  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  cursor: default;
}

.gpsModalBox {
  /* background-color: #e0ebf8; */
  background-color: white;
  max-width: 338px;
  margin: 0 auto;
  border-radius: 5px;
  overflow: hidden;
  height: 392px;

  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  cursor: default;
}

.addSpecialDayModalBoxExpanded {
  /* max-height: 300px;
  min-height: 300px; */
  overflow: scroll;
  /* margin-top: 5vh; */
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  border-radius: 10px;
}

.addSpecialDayModalBoxExpanded::-webkit-scrollbar {
  display: none;
}

.addSpecialDayContentBox {
  padding: 15px 20px 0px;
  color: #143051;
  background-color: white;
  /* padding-bottom: 25px; */
  min-width: 338px;
}

.specialDayModalHeader {
  display: flex;
  justify-content: space-between;
}

.addSpecialDayTitle {
  font-weight: 400;
  margin-top: 2px;
  margin: 0 auto;
  padding-right: 12px;
}

.specialDayModalMiddle {
  /* background-color: cyan; */
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  /* height: 120px; */
}

.addGpsMiddle {
  /* background-color: cyan; */
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  /* height: 120px; */
}

.specialDayModalFooter {
  /* background-color: pink; */
  margin-top: 2px;
  /* height: 65px; */
}

.dateBubbles {
  display: flex;
  justify-content: space-around;
  /* padding-left: 30px;
  padding-right: 30px; */
  /* background-color: blue; */
}

.bubblesBox {
  /* max-width: 100%; */
  /* min-width: 100%; */
  /* display: flex; */
  /* justify-content: center; */
  /* background-color: orange; */
}
.specialDateBubble {
  background-color: #8ea7c5;
  border: 1px solid #143051;

  color: white;
  min-height: 35px;
  line-height: 35px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 30px;
  text-align: center;
  margin: 0 auto;
}

.bubbleL {
  margin-right: 10px;
  min-width: 40px;
}

.bubbleR {
  margin-left: 10px;
}

.payClosed {
  text-align: right;
  line-height: 20px;
  /* margin-top: -2px; */
}

/* add spcecial day footer */
.specialFooterContainer {
  /* background-color: pink; */
  margin-top: 15px;
  justify-content: space-around;
  padding-bottom: 22px;
  border-bottom: 2px solid #dbe4ee;
}

.rateAndClosedContainer {
  display: flex;
  justify-content: space-between;
}

.rateBox {
  display: flex;
  /* margin-top: -10px; */
  min-height: 40px;
  text-align: center;
  align-items: center;
  max-width: 150px;
  min-width: 150px;
  /* margin: auto 0; */
  font-size: 14px;
  color: #143051;
  border: 2px #dde9f9 solid;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
}

.rateBox:hover {
  border-color: #bbcada;
}

.absencePaidAndRateContainer {
  display: flex;
  justify-content: space-between;
  max-width: 90%;
  margin: 20px auto;
}

.rateBoxAbsence {
  display: flex;
  margin-top: 20px;
  min-height: 40px;
  text-align: center;
  align-items: center;
  max-width: 150px;
  min-width: 150px;
  margin: auto 0;
  font-size: 0.9em;
  color: #496375;
  /* background-color: orange; */
  border: 2px #dde9f9 solid;
  border-radius: 10px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
}

.closedRateBox {
  max-width: 120px;
  min-width: 120px;
}

.absenceRateBox {
  max-width: 105px;
  min-width: 105px;
}

.closeComp {
  margin: 0px auto;
  margin-left: 4px;
}

.closedPay {
  margin: 0 auto;
  margin-left: 4px;
}

.rateTitile {
  font-weight: 500;
  /* margin-right: 0px; */
  line-height: 33px;
  margin-top: 0px;
}

.rateTitileClosed {
  font-weight: 500;
  /* margin-right: 15px; */
  /* margin-left: 15px; */
  line-height: 33px;
  /* margin-top: -2px; */
}

.rateContainerClosed {
  font-weight: 500;
  /* margin-right: 15px; */
  margin-left: 15px;
  /* line-height: 35px; */
  margin-top: -12px;
}

/* name container */
.specialDayNameContainer {
  /* background-color: yellow; */
  width: 100%;
  display: flex;
  min-height: 35px;
  margin-top: 30px;
}

.specialDayNameButtonToOpenInputter {
  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;
  margin-right: 4px;
  color: #6a8bb1;
  min-height: 40px;
  padding-left: 5px;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 12px;
  font-size: 16px;
}

.specialDayNameButtonToOpenInputter:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 6px;
  background-color: #fdfeff;
}

.specialDayNameButtonToOpenInputter::placeholder {
  color: #c0d6f0;
}

/* submit btn */
.submitAddSpecialDay {
  font-size: 14px;
  color: #fff;
  background-color: #0bf;
  border: 1px solid #0af;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  max-width: 62px;
  min-width: 62px;
}

.fadeSubmitAddSpecialDay {
  opacity: 0.5;
  pointer-events: none;
}

.specialAddClose {
  width: 15px;
  transform: rotate(270deg);
  margin-top: 4px;
  margin-left: 2px;
  cursor: pointer;
}

.specialAddClose:hover {
  opacity: 0.8;
}

.specialDayExplain {
  font-size: 12px;
  line-height: 12px;
  text-align: left;
  margin-top: 0px;
  color: #8997a8;
  line-height: 1.6em;
  font-weight: 400;
  margin-top: 40px;
  /* margin-left: 10px; */
}

.repeatSpecialDay {
  margin-left: 5px;
}

.waitBg {
  opacity: 0.25;
}

.specDayModalTxtTitle {
  font-weight: 500;
  margin-top: 10px;
  font-size: 0.9em;
  margin-left: 3%;
}

.specialDayAddHeaderTitle {
  font-size: 18px;
  font-weight: 500;
  color: #143051;
  /* margin-left: 80px; */
  margin-top: 4px;
}

.addSpecialDayInfoTxt {
  color: #143051;
  text-align: left;
  font-size: 12px;
  padding-left: 10px;
}

.specDayModalTxt {
  font-weight: 400;
  margin-top: 10px;
  font-size: 0.9em;
  margin-right: 3%;
  color: #8997a8;
}

.specDayInfoContainer {
  min-width: 85%;
  display: block;
  /* background-color: yellow; */
  padding-top: -10px;
  text-align: left;
}

.specDayInfoRow {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #8ea7c5;
  align-items: center;
  height: 50px;
  font-size: 1.1em;
}

.payStaffKnob {
  margin-left: 30px;
}

.publicHolsDropDown {
  color: #5d6f85;
  font-weight: 500;
  border-radius: 5px;
  margin-top: -30px;
  background-color: white !important;
  min-height: 35px;
  font-size: 1em;
  /* padding-right: 40px; */
  min-width: 154px;
  /* margin-right: -15px; */
  font-size: 14px;
  text-align: left !important;
  line-height: 30px;
  border: 0;
  height: 30px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
    #214978;
  -webkit-appearance: none;
  background-position-x: 130px;
  border: 2px solid #dde9f9;
  margin-right: 0px;
  padding-left: 11px;
  /* margin-bottom: 13px; */
  cursor: pointer;
}

.rateDropDownShortened {
  max-width: 80px;
  min-width: 80px;
  background-position-x: 57px;
}

/* select.publicHolsDropDown {
  width: 135px;
  text-align-last: left;
} */

select {
  outline: none;
}

.pHolRegionRow {
  margin-top: 11px !important;
  padding-left: 15px;
  margin-bottom: -12px;
}

.pHolRegTitleTxt {
  margin-top: 2px;
  margin-bottom: -2px;
  font-size: 14px;
}
.specialDaysRateDropDown {
  max-width: 95px;
  min-width: 95px;
  max-height: 40px;
  min-height: 35px;
  background-color: #fff;
  font-size: 1em;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 60px;
  border-radius: 10px;
  text-align: right;
  color: #496375;
  margin-top: 0px;
  /* padding-left: 14px; */
  padding-right: 46px;
  margin-right: -24px;
  /* border: 2px solid #dde9f9; */
  font-weight: 500;
  /* cursor: pointer; */
}
.settingTitleWithQuery {
  margin-left: 15px;
  font-size: 14px;
}
.settingsBg {
  /* background-color: yellow; */
  max-height: 100vh;
  /* background-color: black; */
}

.profileTopContainer {
  background-color: #f4f8fe;
  /* background: rgb(20, 48, 81); */
  /* background: linear-gradient(
    0deg,
    rgba(20, 48, 81, 1) 43%,
    rgba(29, 69, 115, 1) 91%
  ); */
  display: flex;
  flex-wrap: wrap;
  min-width: 100vw;
  max-width: 100vw;
  height: 160px;
}

.topBarBgBehindCurveCorrected {
  background-color: #f4f8fe;
  min-height: 25px;
  min-width: 100vw;
  position: absolute;
  top: 160px;
  z-index: 0;
}

.profileTopInnerContainer {
  /* background-color: grey; */
  display: flex;
  margin-left: 5%;
  margin-top: 20px;
  align-items: center;
  text-align: left;
}

.profilePic {
  object-fit: cover;
  max-width: 40px;
  max-height: 40px;
  min-height: 40px;
  border-radius: 10px;
}

.nameAndTitleContainer {
  /* background-color: cyan; */
  margin-left: 10px;
  padding-bottom: 3px;
}

.profileFName {
  font-size: 24px;
  font-weight: 500;
  color: #143051;
}

.profileJobTitle {
  color: #859dbb;
  font-size: 1.1em;
  margin-top: 0px;
  font-weight: 500;
  /* line-height: 1em; */
}

.settingsOrAllowanceContainer {
  display: flex;
  font-size: 1.1em;
  width: 100%;
  margin-left: 7%;
}

.submenuSettings {
  margin-left: 0px;
  color: #143051;
  font-weight: 400;
  margin-top: 8px;
  cursor: pointer;
}

.submenuAllow {
  font-weight: 400;
  margin-top: 8px;
  color: #143051;
  cursor: pointer;
}

/* .submenuAllow::after {
  display: block;
  content: attr(title);
  font-weight: bold;
  height: 1px;
  color: transparent;
  overflow: hidden;
  visibility: hidden;
} */

.accountSettingMenuItemHolderAllowances {
  /* background-color: black; */
  min-width: 100px;
  max-width: 100px;
  display: flex;
  justify-content: center;
}
.accountSettingMenuItemHolderSettings {
  /* background-color: black; */
  min-width: 86px;
  max-width: 86px;
  display: flex;
  justify-content: center;
}
.accountSettingMenuItemHolderAbsence {
  /* background-color: black; */
  min-width: 84px;
  max-width: 84px;
  display: flex;
  justify-content: center;
}

.submenuSelected {
  font-weight: 500;
  border-bottom: 1px solid #143051;
  border-bottom: 2px #6987a9 solid;

  height: 29px;
  color: #143051;
  margin-bottom: -9px;
}

.SettingsContainer {
  /* mgr and admin size */
  background-color: rgb(253, 253, 255);
  max-height: calc(100% - 236px);
  overflow: scroll;
  width: 90vw;
  display: block;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  /* border-top: 1px #859dbb solid; */
  min-width: 100vw;
  padding-left: 5vw;
  padding-right: 5vw;
  /* border-radius: 20px 20px 0 0; */
  z-index: 1;
  border-top: 1px solid #e6eaee;
}

.whiteGradientAdmin {
  /* border-top: 1px solid #214978; */
  display: none;
}
.adminPageBackground {
  min-height: 100vw;
  min-width: calc(100vw - 662px);
  background-color: rgb(251, 251, 255);
}
/* Admin content container (sets height) */
.AdminSettingsContainer {
  background-color: #ebf0f7;

  overflow-y: scroll;
  padding-left: 1%;
  padding-right: 1%;
  display: block;
  min-height: calc(100vh - 147px);
  max-height: calc(100vh - 147px);
  min-width: 420px;
  max-width: 580px;
  z-index: 0;
}

.gpsAdminRow {
  border-radius: 10px;
  background-color: white;
  padding-bottom: 15px;
  margin-top: 10px;
  padding-left: 20px;
}
.gpsAdminRowMob {
  min-width: 94%;
  max-width: 94%;
  margin-left: 3.5%;
  padding-left:10px;
}

.AdminSettingsContainer::-webkit-scrollbar {
  /* display: none; */
  /* width: 5px !important;
  height: 4px !important; */
}

.AdminSettingsContainerMob {
  /* max-width: 100vw !important; */
  /* min-width: 100vw; */
  min-height: calc(100vh - 172px);
  max-height: calc(100vh - 172px);
  min-width: calc(100vw + 4px) !important;
  max-width: calc(100vw + 4px) !important;
  margin-left: -2px;
  border-radius: 20px 20px 0 0;
  border-top: 2px solid #143051;
  border-left: 2px solid #143051;
  border-right: 2px solid #143051;
  /* padding-top: 10px; */
  /* background-color: blue; */
}

.x3213456765 {
  /* border:none; */
  border-top: none;
  border-radius: 0;
}

.AdminSettingsContainerWoNav {
  background-color: rgb(251, 251, 255);
  max-height: calc(100% - 228px);
  overflow: scroll;
  width: 90vw;
  display: block;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
}

.SettingsContainerWithoutAccNav {
  /* background-color: yellow; */
  max-height: calc(100% - 218px);
  overflow: scroll;
  width: 90vw;
  display: block;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  min-width: 100vw;
  padding-left: 5vw;
  padding-right: 5vw;
  border-radius: 20px 20px 0 0;
  z-index: 1;
  background-color: white;
}

.SettingsContainer::-webkit-scrollbar {
  /* display: none; */
}

.AdminSettingsContainer::-webkit-scrollbar {
  /* display: none;  */
}

.SettingsInner {
  /* background-color: red; */
  margin-top: 10px;
}

/* SETTINGS ROW  */
.settingsRowContainer {
  min-width: 100%;
  min-height: 44px;
  /* background-color: lime; */
  border-bottom: 1px solid #ddebf4;
  font-size: 1em;
}

.AllowancesRowContainer {
  min-width: 100%;
  min-height: 44px;
  /* background-color: lime; */
  border-bottom: 1px solid #ddebf4;
}

/* last allowance container */
.AllowancesRowContainer:last-child {
  min-width: 100%;
  min-height: 44px;
  /* background-color: lime; */
  border-bottom: 0px solid #ddebf4;
}

.settingsRowContainer:last-of-type {
  border-bottom: 0px solid #fff;
}

.settingsRow {
  display: flex;
  /* max-width: 95%; */
  /* margin: auto; */
  margin-top: 14px;
  justify-content: space-between;
  color: #496375;
  color: #143051;
  /* background-color: cyan; */
  min-height: 44px;
  line-height: 44px;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  align-items: flex-start;
}

.settingValue {
  font-weight: 400;
  color: #516b8b;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: right;
  line-height: 18px;
  padding-left: 15px;
  margin-top: 9px;
}

.logoutText {
  background-color: #143051;
  /* margin: auto; */
  margin-top: 15px;
  color: white;
  border-radius: 30px;
  max-width: 90px;
  min-height: 35px;
  line-height: 34px;
  font-size: 14px;
  /* display: flex; */
  text-align: center;
  font-size: 1em;
  font-weight: 500;
}

.whiteGradient {
  position: fixed;
  left: 0;
  top: 160px;
  min-height: 6px;
  min-width: 100%;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(255, 255, 255, 1)
  );
  background-repeat: repeat-x;
  opacity: 0;
}

.rowWithSubText {
  /* background-color: yellow; */
  max-width: 85%;
  min-width: 85%;
}

.rowWithSubTextGPS {
  /* background-color: yellow; */
  max-width: 100%;
  min-width: 100%;
}

.narrowRowWithSubText {
  max-width: 60%;
  min-width: 60%;
}

.lookBackOnExplainerTxt {
  max-width: 60% !important;
  min-width: 60%;
  /* background-color: blue; */
}

.x92929948211 {
  display: none;
}
.allowYrSelectContainer {
  background-color: white;
  position: fixed;
  height: 50px;
  width: 80%;
}

/* knob */
/* switches for cog team request settings modal */
.knobContainer {
  width: 34px;
  /* min-height: 44px; */
  /* line-height: 44px; */
  /* background-color: black; */
  margin-top: 6px;
  cursor: pointer;
}

.tallerRowKnobContainer {
  /* background-color: black; */
  min-height: 20px;
  margin-top: 17px;
}
.knobBg {
  background-color: #dedce2;
  height: 18px;
  width: 27px;
  border-radius: 50px;
  /* position: fixed; */
  margin: auto;
  margin-top: -11px;
  margin-left: 5px;
}

.switchKnob {
  width: 18px;
  height: 18px;
  position: relative;
  top: 7px;
  border-radius: 50px;
  border: 1px solid #143051;
}

.knobOn {
  margin-left: 15px;
  background-color: #0077ff;
  transition: margin 0.075s ease-in-out;
}

.knobOnMgr {
  margin-left: 15px;
  background-color: #9d57e7;
  transition: margin 0.075s ease-in-out;
}

.knobOff {
  margin-left: 3px;
  background-color: #fff;
  border: 1px solid #9da6ac;
  transition: margin 0.075s ease-in-out;
}

.feedExportBtn {
  background-color: #496375;
  width: 75px;
  color: white;
  line-height: 25px;
  height: 25px;
  border-radius: 30px;
  font-size: 0.8em;
  opacity: 0.6;
}

.permissionsQuery {
  max-width: 15px;
  position: relative;
  z-index: 0;
  margin-left: 10px;
}

/* Allowances: the allowance span */
.allowanceText {
  color: #19a5c8;
  font-weight: 400;
}

.allowMeasureColourTurq {
  color: #19a5c8;
  font-weight: 400;
}

.allowanceNbr {
  font-weight: 400;
}

.allowancesInner {
  margin-top: 55px;
}

.allowancesResetInfoContainer {
  text-align: left;
  margin-top: -1px;
  text-indent: 2.5%;
  color: #143051;
  font-weight: 400;
  background-color: white;
  padding-top: 33px;
}

.resetDateAllowances {
  color: #496375;
  font-weight: 300;
  margin-left: 7px;
  font-size: 1.1em;
}

.allowsCont {
  /* background-color: yellow; */
  width: 73%;
}

.allowancesResetDivider {
  background-color: #ddebf4;
  height: 1px;
}

.resetAllowsTxt {
  margin-top: 6px;
}

.allowanceYrContainer {
  display: flex;
  justify-content: flex-start;
  color: #496375;
  font-weight: 400;
  margin-top: 15px;
  padding-bottom: 5px;
  margin-left: calc(2.5% - 5px);
  /* max-width: 90%; */
  /* display: none; */
}

.allowanceYrTxt {
  margin-left: 5px;
  line-height: 25px;
  width: 60px;
}

.allowSel {
  font-weight: 500;
  background-color: #8ea7c5;
  color: white;
  margin-left: 5px;
  width: 60px;
  line-height: 25px;
  border-radius: 15px;
}

.none {
  display: none;
}

.removeLogoImg {
  margin-left: 17px;
  min-width: 18px;
  margin-right: 6px;
}
.jammedSubBoxOvertime {
  display: flex;
  display: flex;
}

.overtimeJammedTxt {
  color: #496375;
  text-align: right;
  background-color: #e2e7ed;
  font-size: 0.8em;
  font-weight: 500;
  line-height: 16px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 0 0 10px 10px;
  max-width: 90%;
  margin: 0 auto;
  border-top: 1px #8ea7c5 solid;
}

.fadedTogglesOvertime {
  opacity: 0.5;
  pointer-events: none;
}

.loadingUserOpenImg {
  max-width: 30px;
  /* padding-left: 18px;
  padding-right: 18px; */
}

.none {
  display: none;
}

.adminsInfoContainer {
  /* background-color: #e0ebf8; */
  /* border-bottom: solid 1px #e0ebf8; */
  max-width: calc(100vw - 420px);
  margin: 0 auto;
  margin-top: 28px;
  /* height: 80px; */
  /* padding: 20px; */
  font-size: 1em;
  color: #496375;
  text-align: left;
  /* border-radius: 15px; */
  position: relative;
  /* text-align: left; */
  z-index: 2;
  margin-left: 40px;
  padding-right: 20px;
}

.adminsInfoContainerMob {
  margin-left: 40px;
  max-width: 90vw;
}

.companySettingsSpan {
  font-weight: 500;
}

.adminsDataContainer {
  max-width: calc(90vw - 40px);
  margin-left: 40px;
  overflow: scroll;
  min-height: calc(100vh - 113px);
  max-height: calc(100vh - 113px);
  margin-top: 20px;
  border-top: #d8dee6 solid 1px;
  padding-right: 10px;

  padding-top: 20px;
  padding-bottom: 230px;
  z-index: 999;
  /* background-color: yellow; */
}

.adminsDataContainerDesktop {
  max-width: 340px !important;
  min-height: calc(100vh - 180px);
  max-height: calc(100vh - 180px);
  margin-top: 20px;
  border-top: #d8dee6 solid 1px;
  /* background-color: orange; */
  padding-bottom: 60px;
  padding-right: 10px;
}

.adminUserRow {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color:#143051;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 10px;
  height: 40px;
  /* background-color: white; */
  border: 2px solid #dde9f9;
  border-radius: 5px;
  font-weight: 500;
  padding-left:10px;
}

.adminUserRow:first-of-type {
  margin-top: 0px;
}

.removeAdminBinImg {
  margin-right: 10px;
  min-width: 20px;
  cursor: pointer;
  padding: 5px;
}

.none {
  display: none;
}

.addNewAdminButton {
  color: white;
  border-radius: 5px;
  min-width: 50px;
  min-height: 36px;
  max-width: fit-content;
  max-height: 36px;
  display: flex;
  font-weight: 500;
  align-items: center;
  line-height: 30px;
  font-size: 1em;
  margin: 12px auto;
  padding: 10px;
  background-color: #07f;
  border: #0469de 2px solid;
  cursor: pointer;
}

.addNewAdminButton:active {
  transform: scale(0.96);
}

.addAdminUsersModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
}

.addAdminUsersModalBox {
  background-color: #143051;
  color: white;
  min-width: 90vw;
  border-radius: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;
  padding-left: 25px;
  line-height: 45px;
}

.usersToAddAsAdminContainer {
  width: calc(100% - 25px);
  border-top: 1px rgb(255, 255, 255, 0.5) solid;
  padding-top: 10px;
  height: 80px;
  overflow: scroll;
  max-height: 65vh;
  min-height: 65vh;
  font-size: 1.1em;
}

.youColourAdmin {
  background-color: #214978;
  border: 2px solid #143051;
  color: white;
}

.fadeAdmins {
  opacity: 0.8;
  pointer-events: none;
}

.editTeamUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
}

.editTeamBox {
  background-color: #f4f8fe;
  /* background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(200, 200, 200, 1) 0%,
      rgba(255, 255, 255, 1) 9%,
      rgba(255, 255, 255, 1) 100%
    ); */
  min-width: 100vw;
  max-width: 100vw;
  min-height: 90vh;
  max-height: 90vh;
  height: 80vh;
  /* z-index: 999999222229999992222299999922222; */
  border-radius: 15px 15px 0 0;
  /* margin-top: -40px; */
  /* display: flex; */
  /* justify-content: center; */
  align-items: center;
  color: #143051;
  position: fixed;
  bottom: 0;
  /* z-index: 11; */
  /* z-index: 999999999999999999999999999999999999999999999999999999 !important; */
  text-align: center;
  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  animation-name: moveEditTeamUserBoxUp !important;
  animation-duration: 0.1s !important;
}

.specDayEditDateInput {
  border-radius: 5px;
  border: none;
  background-color: #fff;
  color: #496375;
  min-height: 35px;
  line-height: 35px;
  font-size: 16px !important;
  padding-left: 14px;
  padding-right: 8px;
  letter-spacing: 1px;
  padding-top: 1px;
  font-weight: 500;
  max-width: 174px;
  min-width: 174px;
  background: url(/static/media/calicongrey.9c7e1aba.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 142px;
  border: 2px solid #dde9f9;
  margin-left: 0px;
  margin-top: 10px;
  cursor: text;
  /* margin-bottom:-8px; */
}

.specDayEditDateInput:hover {
  border-color: #bbcada;
}

.editTeamModalHeader {
  /* background-color: yellow; */
  display: flex;
  justify-content: space-around;
  min-width: 100%;
  max-width: 100%;
  text-align: left;
  min-height: 30px;
  margin-top: 5px;
  padding-bottom: 10px;
}

.closeEditTeamImg {
  width: 15px;
  /* margin-top: 2px; */
  margin-left: -30px;
}

.leftTeam {
  margin-left: 50%;
  margin-top: 16px;
}

.editTeamNameTitle {
  margin-top: 13px;
  /* margin-left: -10px; */
  font-weight: 500;
  font-size: 1.3em;
  min-width: 76%;
  max-width: 76%;
  display: flex;
  justify-content: center;
}

.headerSideHolderTeam {
  min-width: 12%;
  max-width: 12%;
}

.editTeamNameIcon {
  /* margin-left: 12px; */
  width: 17px;
  margin-top: 16px;
  margin-left: 2px;
}

.none {
  display: none;
}

.editTeamNameInput {
  margin-top: 13px;
  /* background-color: pink; */
  font-weight: 500;
  font-size: 1.2em;
  min-width: 76%;
  max-width: 76%;
  display: flex;
  justify-content: center;
}

.saveTeamNameBtn {
  width: 24px;
  margin-top: 14px;
}

.teamNameInput {
  height: 28px;
  line-height: 28px;
  margin-top: 5px;
  text-align: center;
  color: #143051;
  font-size: 1em;
  border: none;
  padding-bottom: 12px;
  border-bottom: solid 2px #3b597c;
  font-weight: 500;
}

.teamNameInput::placeholder {
  text-align: center;
}

/* middle of edit team admin modal */
.teamAdminMiddleContainer {
  /* background-color: cyan; */
  height: calc(100% - 52px);
  min-width: 300px;
  position: relative;
  display: flex;
}

.teamMiddleLeftCol {
  /* background-color: yellow; */
  min-width: 70%;
}

.teamMiddleRightCol {
  /* background-color: orange; */
  min-width: 30%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.panelContainer {
  /* background-color: red; */
  min-height: calc(100%);
  min-width: 60px;
  /* padding-bottom: -20px; */
}

.teamKeyContainer {
  /* background-color: pink; */
  display: block;
  justify-content: center;
  min-height: 40px;
  margin-bottom: 30px;
  position: fixed;
  bottom: 0;
  animation-name: movePanelUp !important;
  animation-duration: 0.1s !important;
}

@keyframes movePanelUp {
  from {
    bottom: -100%;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

.teamKeyRow {
  display: flex;
  margin-top: 16px;
  justify-content: center;
  /* background-color: blue; */
  max-width: 50px;
  min-width: 60px;
  max-width: 60px;
}

.lastTeamKeyRow {
  margin-top: 14px;
  padding-bottom: 30px;
}

.teamKeyImgIcon {
  min-width: 30%;
  max-width: 30%;
  margin-left: 8px;
}

.teamKeyValue {
  /* background-color: white; */
  min-width: 70%;
  max-width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;
  font-weight: 500;
}

.temKeySplitter {
  height: 1px;
  margin-top: 14px;
  width: 100%;
  background-color: #8997a8;
}

.addUserToTeamBtn {
  background-color: #3b597c;
  border: 2px solid #143051;
  font-size: 3em;
  color: white;
  width: 60px;
  height: 60px;
  line-height: 50px;
  border-radius: 100%;
  text-align: center;
  /* padding-bottom: 10px; */
}

.deleteTeamContainer {
  margin-left: 25px;
  margin-top: 15px;
}

.deleteTeamIconImg {
  width: 38px;
  margin-top: 15px;
}

.delTeamSure {
  width: 38px;
  margin-top: 15px;
  opacity: 1;
}

.delTeam {
  width: 38px;
  margin-top: 15px;
  opacity: 0.6;
  border-radius: 25px;
}

.teamUsersArrayContainer {
  background-color: white;
  padding-top: 15px;
  overflow: scroll;
  height: calc(100% - 35px);
  margin-top: 10px;
  padding-left: 0px;
  font-size: 1.1em;
  border: 2px #8ea7c5 solid;
  border-radius: 10px;
  margin-left: 15px;
  /* f4f8fe */
}

.teamUsersArrayContainer::-webkit-scrollbar {
  /* display: none; */
}

.arrayUserRow {
  background-color: white;
  max-width: 90%;
  display: flex;
  justify-content: space-between;
  padding-left: 6px;
  padding-right: 6px;
  margin: 0 auto;
  height: 50px;
  line-height: 50px;
  text-align: left;
  border-bottom: 1px solid #8997a8;
}

.borderLessUserInTeam {
  border: none !important;
}

.mgrUserDivider {
  border-bottom: 2px solid black;
}

.arrayUserRow:last-of-type {
  border: none;
}

.userFontColor {
  color: #19a5c8;
  color: #00ccff;
  color: #143051;
  font-weight: 500;
}

.mgrFontColor {
  color: #7d6d9f;
  color: #9a74eb;
  font-weight: 500;
}

/* specific user modal */
.specificUserUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
}

.specificUserModalBox {
  background-color: #c0d6f0;
  background-color: white;
  border-radius: 15px;
  min-width: 90vw;
  min-height: 105px;
  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.05);
}

.specificModalHeader {
  display: flex;
  justify-content: space-between;
  height: 45px;
}

.closeSpecific {
  margin-left: 17px;
  width: 12px;
}

.specificNameTitle {
  margin-top: 14px;
  font-weight: 500;
}

.specificMiddleContainer {
  /* background-color: orange; */
  max-width: 90%;
  margin: 0 auto;
  min-height: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.removeFromTeam {
  background-color: #818181;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: white;
  border-radius: 25px;
  margin-top: -8px;
  font-size: 0.9em;
  font-weight: 500;
}

.mgrToggler {
  /* background-color: yellow; */
  min-width: 45%;
  display: flex;
  justify-content: space-around;
}

.mgrToggler:last-of-type {
  margin-top: 8px;
}

.pinkKnob {
  background-color: #9a74eb;
}

.blueKnob {
  background-color: #00ccff;
}

.mgrUserTogglerContainer {
  display: block;
  /* margin-left: -20px; */
  background-color: #8997a8;
  background: linear-gradient(
    0deg,
    rgb(103, 128, 169) 0%,
    rgba(88, 112, 150, 1) 90%,
    rgba(69, 89, 120, 1) 100%
  );
  color: white;
  margin-top: 0px;
  padding-bottom: 8px;
  padding-top: 10px;
  max-width: 200px;
  margin-left: 15px;
  border-radius: 0 0 15px 15px;
  font-size: 0.9em;
  border: 2px solid #214978;
  border-top: none;
  max-width: 80%;
  margin-left: 6%;
}

.selfApproveKnob {
  margin-top: 0px !important;
}

.mgrTxt {
  /* margin-right: 12px; */
  font-size: 0.9em;
  line-height: 1.2em;
  margin-top: 10px;
  font-weight: 500;
  opacity: 0.8;
  max-width: 35%;
  min-width: 35%;
  text-align: left;
}

.selfApproveTxt {
  font-size: 0.9em;
  line-height: 1.2em;
  margin-top: 10px;
  font-weight: 500;
  opacity: 0.8;
  max-width: 50%;
  min-width: 50%;
  text-align: left;
  margin-right: -15%;
}

.addUserToTeamModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
}

.userListToAddToTeamBox {
  background-color: #143051;
  color: white;
  min-width: 90vw;
  border-radius: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;
  padding-left: 25px;
  line-height: 45px;
  /* max-height: 80vh; */
  /* height: 80vh; */
}

.addUserToTeamHeaderContainer {
  display: flex;
  justify-content: space-between;
  /* background-color: orange; */
  padding-bottom: 10px;
  margin-top: -10px;
}
.addUserToTeamTitle {
  text-align: center;
  margin-left: -20px;
  font-weight: 500;
}

.closeWhiteAddUserToTeamImg {
  min-width: 15px;
  max-width: 15px;
}

.userDropdown {
  /* background-color: red; */
  width: calc(100% - 25px);
  border-top: 1px rgb(255, 255, 255, 0.5) solid;
  padding-top: 10px;
  height: 80px;
  overflow: scroll;
  max-height: 65vh;
  min-height: 65vh;
  font-size: 1.1em;
}

.addNewTeamBtn {
  position: fixed;
  top: calc(100% - 188px);
  right: 45px;
  background-color: #3b597c;
  border: 2px solid #143051;
  color: white;
  border-radius: 100%;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  line-height: 42px;
  font-size: 2.5em;
  z-index: 99999;
}

/* modal for mgrs who are not a user */
.MgrNotUserKeyModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
}

.mgrNotUserModalBox {
  background-color: white;
  border-radius: 15px;
  max-width: 80vw;
  position: relative;
  /* z-index: 999999999999; */
}

.mgrNotUserHeading {
  color: #143051;
  font-size: 1.1em;
  max-width: 80%;
  margin: 0 auto;
  padding-top: 20px;
  line-height: 22px;
  padding-bottom: 20px;
  font-weight: 500;
}

.mgrsNotUsersContainer {
  padding-bottom: 30px;
}

.mgrNotUserRow {
  line-height: 35px;
  color: #7d6d9f;
}

.closeMgrsNotUsers {
  font-size: 0.8em;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 2px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  line-height: 26px;
  border-radius: 20px;
  min-height: 30px;
  max-width: 60px;
  margin: 0 auto;
  margin-top: -10px;
  margin-bottom: 20px;
}

/* teamless modal */
.teamlessUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
}

.teamlessModalBox {
  background-color: white;
  border-radius: 15px;
  max-width: 80vw;
  min-width: 80vw;
}

.teamlessUserTxtRow {
  line-height: 35px;
  color: #8997a8;
  font-weight: 500;
  font-size: 1.2em;
}

.mgrKnobContainer {
  margin-top: -10px;
  padding-top: 4px;
  /* padding-bottom: 2px; */
  /* background-color: black; */
}

.knobBgMgr {
  background-color: #c0d6f0;
}

.knobBgMgrOff {
  background-color: #c0d6f0;
}

.selectedUserInTeam {
  position: relative;
  margin-left: 5px;
  /* z-index: -1; */
  animation-name: slideSubBarDownNoZoom !important;
  animation-duration: 0.04s !important;
}

.selectedUserInTeamRow {
  background-color: #143051;
  padding-left: 18px;
  border-radius: 0 0 10px 10px;
  border: none;
  /* color: white; */
}

.whiteNameColourTxt {
  color: white;
}

/* modal for mgrs who are not a user */
.addedUserTeamStatusModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
}

.addedUserTeamStatusModalBox {
  background-color: white;
  border-radius: 15px;
  max-width: 80vw;
  min-width: 80vw;
  min-height: 170px;
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  color: #143051;
}

.userTeamStatusContent {
  /* background-color: pink; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 80%;
  margin: 10px auto;
  padding-bottom: 14px;
  /* margin-bottom: 10px; */
}

.makeTeamNameBold {
  font-weight: 500;
}

.addedUserTeamStatusTxt {
  margin-top: 28px;
}

.adminUsersPathSpan {
  font-weight: 500;
  font-style: italic;
  color: #58467e;
}

.closeTeamStatusModalBtnContainer {
  /* max-width: 80px; */
  margin: 0 auto;
  margin-top: 25px;
  display: flex;
  justify-content: space-around;
  max-width: 200px;
}

.addedUserTeamArrItem {
  font-weight: 500;
}

.addedUserTeamArrItemInBetween {
  font-weight: 400;
  opacity: 0.8;
}

.generateTeamNamesSpans {
  /* background-color: yellow; */
  /* display: flex; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  width: 100%;
  margin-top: 4px;
}

.yesChangePTeamBtn {
  font-size: 0.8em;
  color: #fff;
  background-color: #0077ff;
  border: solid #143051 2px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  line-height: 26px;
  border-radius: 20px;
  min-height: 30px;
  min-width: 70px;
}

.noChangePTeamBtn {
  font-size: 0.8em;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 2px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  line-height: 26px;
  border-radius: 20px;
  min-height: 30px;
  min-width: 70px;
}

.paddingBottomForYesNoTeamStatusModal {
  padding-bottom: 30px;
  padding-top: 10px;
}

.dsInputForUpdateShiftsFwd {
  margin-top: 25px;
}

.updateDontUpdateBtnCont {
  /* background-color: yellow; */
  min-width: 220px;
}

.disableUpdateBtn {
  opacity: 0.4;
  /* pointer-events: none; */
}

.transferShiftsToTeamDropdown {
  max-width: 277px;
  min-width: 80%;
  background: url("../img/general/downArrowWhite.svg") no-repeat right #687d97;
  -webkit-appearance: none;
  background-position-x: 92%;
  border-radius: 100px;
  color: white;
  padding-left: 14px;
  font-size: 1em;
  text-align: left;
  min-height: 35px;
  line-height: 30px;
  border: 2px solid #36495f;
  font-weight: 500;
  margin-top: 30px;
}

.transferFromTxt {
  margin-top: 15px;
  margin-bottom: -30px;
  font-weight: 500;
}

.teamStatusDivider {
  min-height: 1px;
  max-height: 1px;
  background-color: #143051;
  min-width: 80%;
  max-width: 80%;
  margin: 0 auto;
  margin-bottom: -10px;
  margin-top: 25px;
}

.disablePointerEvents {
  pointer-events: none;
}

.absenceAdminContainer {
  min-height: 100%;
  position: relative;
  /* background-color: black; */
  z-index: 99999;
  /* overflow: scroll; */
  /* width: 90vw; */
  /* display: block; */
  /* position: absolute; */
  /* margin: auto; */
  /* left: 0; */
  /* right: 0; */
}
.innerAbsence {
  /* background-color: lime; */
  height: 100%;
}

.overtimeContentContainer {
  /* background-color: pink; */
  max-width: 88%;
  min-width: 88%;
}

.overtimeContentContainerSelfLog {
  max-width: 88%;
  /* background-color: orange; */
}

.overtimeIndividualTableContainer .absenceRowMainText {
  text-align: left;
  /* font-size: 2em; */
  /* background-color: blue; */
  line-height: 24px;
  padding-bottom: 14px;
  max-width: 80%;
  /* margin-bottom: 4px; */
}

.absenceRowSubText {
  /* font-size: 0.85em; */
  font-size: 12px;
  text-align: left;
  /* margin-bottom: -0px; */
  margin-top: 0px;
  padding-left: 15px;
  color: #516b8b;
  /* background-color: blue; */
  /* max-width: 90%;
  min-width: 90%; */
  line-height: 16px;
  /* line-height: 20px; */
  font-weight: 400;
  /* max-width: 40%; */
}

.absenceTypesContainer {
  /* background-color: yellow; */
  height: auto;
  display: block;
}

.absenceAdminRow {
  /* background-color: yellow; */
  /* padding-bottom: 14px; */
}

.absenceKnobContainer {
  /* background-color: black; */
  margin-right: 12px;

  /* padding-right: 31px; */
}

.textLeft {
  text-align: left;
}

.absenceTypesHolder {
  padding-top: 16px;
  padding-bottom: 20px;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 4px;
}

.absenceTypeUnit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  max-width: 310px;
  min-width: 310px;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 5px;
  height: 40px;
  background-color: #f7fbff;
  color: #214978;
  border: 2px solid #dde9f9;
  margin-top: 10px;
  /* box-shadow: 0px 5px 4px -2px rgb(0 0 0 / 10%); */
}

.absenceTypeUnit:first-of-type {
  margin-top: 0px;
}

.nonRemoveable {
  background-color: #214978;
  color: white;
  border-color: #143051;
}

.absenceTypeName {
  /* background-color: orange; */
  line-height: 35px;
  font-size: 0.95em;
  min-width: 70%;
  text-align: left;
  text-indent: 14px;
}

.absenceTypePaid {
  /* background-color: lime; */
  min-width: 88px;
  margin-left: -50px;
  /* padding-right: 5px; */
  /* margin-left: -22px; */
  font-size: 0.95em;
  /* font-weight: 500; */
  text-align: right;
}

.absenceTypeBinImg {
  max-width: 18px;
  margin-right: 14px;
  cursor: pointer;
}

.addAbsenceTypeBtn {
  background-color: #0077ff;
  color: white;
  border-radius: 5px;
  min-width: 50px;
  min-height: 30px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  max-height: 30px;
  display: flex;
  align-items: center;
  line-height: 30px;
  font-size: 0.9em;
  margin: 12px auto;
  margin-left: 6px;
  padding: 8px;
  border: 2px solid #143051;
  cursor: pointer;
  font-weight: 500;
}
.addAbsenceTypeBtn:active {
  transform: scale(0.98);
}

/* add absence type modal */
.addAbsenceTypeModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999999999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
}

@keyframes underlayFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.addAbsenceModalBox {
  background-color: #fff;
  min-width: 338px;
  max-width: 338px;
  height: 230px;
  margin-top: -10vh;
  border-radius: 15px;
  display: block;
  /* justify-content: center; */
  /* align-items: flex-start; */
  padding-top: 14px;
  color: white;
  text-align: center;
  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
}

.addAbsenceModalHeader {
  /* background-color: lime; */
  min-width: 100%;
  height: 20px;
  display: flex;
  justify-content: space-between;
}

.closeAddAbsence {
  max-width: 12px;
  /* background-color: orange; */
  margin-left: 18px;
}

.addAbsenceTitle {
  margin-left: 6px;
  line-height: 14px;
  padding-top: 6px;
  font-size: 1.1em;
  font-weight: 500;
  color: #143051;
}

.addAbsenceBodyContainer {
  /* background-color: red; */
  max-width: 90%;
  margin: 0 auto;
  margin-top: 15px;
}

.absenceName {
  text-align: left;
  font-size: 1.2em;
  height: 24px;
  /* background-color: orange; */
}

.absenceNameInput {
  margin-top: 0px;
  width: 86%;
  background-color: #fff;
  border: none;
  border-bottom: solid 1px #143051;
  color: #143051;
  font-size: 1.1em;
  font-weight: 500;
  padding-bottom: 10px;
  padding-top: 2px;
  text-indent: 3%;
  height: 30px;
  line-height: 43px;
}

.absenceQuestionInput {
  margin-top: 20px;
  width: 86%;
  background-color: #fff;
  border: none;
  border-bottom: solid 1px #143051;
  color: #143051;
  font-size: 1.1em;
  font-weight: 500;
  padding-bottom: 10px;
  padding-top: 2px;
  text-indent: 3%;
  height: 30px;
  line-height: 43px;
}

.submitAbsenceType {
  background-color: #8997a8;
  width: 60px;
  min-height: 26px;
  line-height: 26px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 30px;
}

.addAbsenceTypeModalFooter {
  display: flex;
  min-width: 100%;
  margin-top: 16px;
  /* background-color: orange; */
  justify-content: space-around;
}

.addAbsenceTypeBack {
  line-height: 26px;
  font-size: 0.9em;
  margin-top: 2px;
  margin-left: -8px;
  /* margin: 0 auto; */
}

.paidContainer {
  min-height: 68px;
  max-height: 68px;
  /* background-color: pink; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.unpaidPaidHolder {
  /* background-color: lime; */
  min-width: 200px;
  display: flex;
  justify-content: space-around;
}

.paidTxt {
  margin-top: 1px;
  /* background-color: red; */
  min-width: 60px;
  max-width: 60px;
  font-size: 1.1em;
  font-weight: 500;
  color: white;
}

.paidTxtL {
  text-align: right;
  margin-right: 0px;
  min-width: 66px;
  max-width: 66px;
}

.paidTxtR {
  text-align: left;
  margin-left: 0px;
  min-width: 66px;
  max-width: 66px;
}

.paidUnselected {
  opacity: 0.7;
  font-weight: 400;
  font-size: 0.9em;
  margin-top: 3px;
  color: white;
}

.submitAbsenceTypeSave {
  font-size: 0.9em;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 2px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  line-height: 25px;
  border-radius: 10px;
  min-height: 30px;
  max-width: 64px;
  margin: 0px auto;
}

.redDeleteAbsence {
  background-color: #ba0000;
  color: white;
  border: none;
}

.cancelDelAbsenceType {
  position: relative;
  margin-right: 50px;
  width: 20px;
  display: none;
  /* margin-left: 10px; */
}

.addAbsenceAddBtnFooterHolder {
  /* background-color: yellow; */
  min-width: 300px;
  display: flex;
  /* justify-content: flex-start; */
}

.paidKnob {
  margin-top: -5px;
}

.trashRightAbit {
  margin-right: 12px;
}

.rtwQContainer {
  /* background-color: lime; */
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  /* min-height: 100px; */
}

.rtwQUnit {
  height: fit-content;
  line-height: 40px;
  color: #496375;
  border-radius: 6px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  /* border: 2px solid #5f7792; */
  align-items: center;
  border: solid 2px #dee5ed;
  padding-left: 13px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.rtwQUnit:first-of-type {
  margin-top: 0px;
}

.rtwQtxt {
  font-size: 12px;
  text-align: left;
  margin-right: 10px;
  max-width: 210px;
  line-height: 18px;
}

.removeQuestionImg {
  margin-right: 9px;
  min-width: 18px;
  cursor: pointer;
  padding: 4px;
  max-width: 16px;
}

.removeQuestionImg:hover {
  opacity: 0.75;
}

.questionsTitle {
  color: #496375;
  padding-bottom: 10px;
  text-align: right;
  margin-top: 10px;
  padding-right: 10px;
}

.questionsWrapper {
  float: right;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  align-items: flex-end;
}

.addQuestionBtn {
  margin-right: 0px;
  min-width: 118px;
  font-size: 13px;
  padding-left: 9px;
  margin-top: 15px;
}

.annLeaveBox {
  min-width: 94%;
  padding-left: 20px;
  padding-right: 40px;
  min-height: 44px;
  padding-bottom: 30px;
  background-color: white;
  border-radius: 10px;
  max-width: 94%;
  margin-left: 3.5%;
}

.annLeaveBoxTIL {
  margin-top: 18px;
  padding-bottom: 15px;
  background-color: white;

  /* animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important; */
}

.annLeaveHeader {
  display: flex;
  justify-content: space-between;
  min-width: 105%;
}

.annLeaveTitle {
  margin-left: 14px;
  margin-top: 27px;
  font-size: 14px;
  color: #496375;
  color: #143051;
  text-align: left;
  font-weight: 500;
}

.minimiseAnnLeaveImgOpen {
  margin-right: 9px;
  margin-top: 0px;
  max-width: 9px;
  margin-top: -2px;
}

.minimiseAnnLeaveImgClosed {
  margin-right: 25px;
  margin-top: 0px;
  transform: rotate(90deg);
}

.minimiseCustomLeaveImgClosed {
  margin-right: 25px;
  margin-top: 17px;
  transform: rotate(90deg);
}

.minimiseCustomLeaveImgOpen {
  margin-right: 22px;
  margin-top: 17px;
}

.annLeaveRow {
  /* background-color: yellow; */
  max-width: 94%;
  margin: 0 auto;
  /* margin-top: 15px; */
  color: #143051;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddebf4;
}
.annLeaveRowBlock {
  display: block;
}

.annLeaveBoxDesktop {
  min-width: 100% !important;
  margin-left: 0px;
}

.annLeaveRow:last-of-type {
  border-bottom: none;
}

.annLeaveRowUpper {
  /* background-color: lime; */
  border-bottom: 1px solid #ddebf4;
  /* min-height: 40px; */
  display: flex;
  margin-top: -4px;
  padding-bottom: 10px;
  color: #496375;
  justify-content: space-between;
}

.leaveReqbold {
  font-weight: 500;
  color: #496375;
  /* background-color: orange; */
  min-width: 85px;
}

.annLeaveUpperTxt {
  margin-left: 10px;
  display: flex;
  font-size: 14px;
  color: #496375;
}

.queryLeaveTypesImg {
  min-width: 22px;
  margin-left: 16px;
}

.annLeaveRowLower {
  margin-top: 12px;
  font-size: 16px;
  display: flex;
  font-weight: 500;
  flex-direction: column;
  align-items: flex-start;
}
.annLeaveLowerTxt1 {
  line-height: 30px;
  padding-right: 7px;
}
.annLeaveLowerTxt2 {
  line-height: 17px;
  padding-left: 2px;
  font-weight: 500;
  font-size: 12px;
  color: #496375;
  margin-top: 3px;
}

.annLeaveLowerTxt3 {
  line-height: 17px;
  padding-left: 2px;
  font-weight: 500;
  font-size: 14px;
  color: #496375;
  margin-top: 6px;
}
.minsPerHourDropdown {
  max-width: 75px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 50px;
  border-radius: 10px;
  color: #5d6f85;
  padding-left: 14px;
  text-align: left;
  height: 30px;
  /* background-color: #98a1ac; */
  border: 2px solid #dde9f9;
  font-size: 1.1em;
  font-weight: 500;
}

.minsPerHourDropdownWider {
  max-width: 75px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 55px;
  border-radius: 100px;
  color: #5d6f85;
  padding-left: 14px;
  text-align: center;
  height: 30px;
}

.daysOrHoursCustomLeavDropdown {
  max-width: 90px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 65px;
  border-radius: 10px;
  color: #5d6f85;
  padding-left: 14px;
  text-align: left;
  height: 30px;
  /* background-color: #98a1ac; */
  border: 2px solid #dde9f9;
  font-size: 1.1em;
  font-weight: 500;
}

.minsPerHourDropdownWidth {
  color: #5d6f85;
  font-weight: 500;
  border-radius: 5px;
  background-color: white !important;
  min-height: 35px;
  font-size: 1em;
  min-width: 77px;
  max-width: 77px;
  font-size: 16px;
  text-align: left !important;
  line-height: 30px;
  border: 0;
  height: 30px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
    #214978;
  -webkit-appearance: none;
  background-position-x: 50px;
  border: 2px solid #dde9f9;
  margin-right: 0px;
  padding-left: 14px;
  cursor: pointer;
}

.tilWkDrop {
  background-position-x: 95px;
  min-width: 118px;
  max-width: 118px;
  padding-left: 9px;
  font-size: 14px;
  margin-top: 30px;
}

@media only screen and (min-width: 960px) {
  .tilWkDrop {
    margin-left:60px
  }
}


.allowanceInput {
  max-width: 50px;
  /* background: url('../img/general/downArrowWhite.svg') no-repeat right #214978; */
  -webkit-appearance: none;
  background-position-x: 37px;
  border-radius: 100px;
  color: white;
  padding-left: 14px;
  text-align: center;
  height: 30px;
}

.typeOfUserLoyaltyHeading1 {
  margin-top: 20px;
  font-size: 0.8em;
  text-align: left;
  margin-left: 15px;
  color: #fff;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #93a7b6;
  padding-left: 6px;
  border-radius: 5px;
  max-width: 120px;
}

.typeOfUserLoyaltyHeading2 {
  margin-top: 40px;
  font-size: 0.8em;
  text-align: left;
  margin-left: 15px;
  color: #fff;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #93a7b6;
  padding-left: 6px;
  border-radius: 5px;
  max-width: 128px;
}

.annLeaveLoyaltyTextLeftAbit {
  margin-left: 30px;
}

.leaveReqLimit {
  text-align: right;
}

.adminTilKnob {
  margin-right: 18px;
  margin-top: 12px;
}

.leaveTypeDivider {
  min-height: 1px;
  background-color: #8997a8;
  border-radius: 100px;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.tilExplainer {
  font-size: 14px;
  color: #8997a8;
  text-align: left;
  margin-left: 13px;
  margin-top: 13px;
  max-width: 70%;
  font-weight: 400;
}

.customLeaveBox {
  background-color: #fff;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  cursor: pointer;
}

.editCustomLeaveNameImg {
  width: 14px;
  margin-left: 12px;
}

.customLeaveTopRightIconsContainer {
  /* background-color: lime; */
  display: flex;
  min-width: 70px;
  justify-content: space-between;
}

.customLeaveTitle {
  display: flex;
  align-items: center;
}

.customLeaveMinimiserHolder {
  /* background-color: blue; */
  margin-top: 4px;
  /* padding-top: -4px; */
}

.minimiseAnnLeaveImgOpenCustomLeave {
  margin-top: 17px;
}

.greyPowerUnpublishCustomLeaveImg {
  width: 16px;
  margin-top: 14px;
  /* background-color: white; */
  /* padding-top: 4px; */
  /* padding-bottom: -4px; */
  margin-right: 14px;
}

.customLeaveRowLower {
  /* margin-top: 20px; */
  justify-content: space-between;
}

.customRowLower {
  margin-top: 0px;
}

.customLeaveRateContainer {
  /* background-color: yellow; */
  display: flex;
  margin-right: 8px;
  margin-top: 10px;
}
.customLeaveRateTxt {
  line-height: 30px;
  margin-right: 8px;
}

.paidToggleContainer {
  display: flex;
  height: 30px;
  line-height: 30px;
  margin-top: 10px;

  background-color: white;
  /* border: 1px solid #516b8b; */
}

.paidToggleContainerTxtSelected {
  background-color: #0077ff;
  color: white;
  font-weight: 500;
  padding-left: 13px;
  padding-right: 13px;
  border: 2px solid #143051;
  border-radius: 10px;
  z-index: 1;
  font-size: 1.1em;
  line-height: 26px;
  /* animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important; */
}

.paidToggleContainerTxt {
  background-color: #ddebf4;
  padding-left: 26px;
  padding-right: 10px;
  margin-left: -20px;
  max-height: 26px;
  line-height: 22px;
  margin-top: 2px;
  color: #606c7a;
  border-radius: 10px;
  font-size: 0.95em;
  color: #516b8b;
  border: solid 2px #516b8b;
  font-weight: 500;
}

.unpaidToggleContainerTxtSelected {
  background-color: #72869e;
  color: white;
  font-weight: 500;
  padding-left: 15px;
  padding-right: 15px;
  border: 2px solid #516b8b;
  border-radius: 10px;
  z-index: 1;
  font-size: 1.1em;
  line-height: 26px;
  /* animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important; */
}

.unpaidToggleContainerTxt {
  background-color: #ddebf4;
  padding-left: 14px;
  color: #214978;
  padding-right: 24px;
  margin-right: -18px;
  max-height: 26px;
  line-height: 24px;
  margin-top: 2px;
  border-radius: 10px;
  font-size: 0.9em;

  color: #214978;
  border: solid 2px #516b8b;
}

.customLeaveRateDropdown {
  max-width: 80px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 54px;
  border-radius: 10px;
  color: #5d6f85;
  padding-left: 14px;
  text-align: left;
  height: 30px;
  /* background-color: #98a1ac; */
  border: 2px solid #dde9f9;
  font-size: 1.1em;
  font-weight: 500;
}

.removeCustomLeaveTypeContainer {
  /* background-color: pink; */
  display: flex;
  margin-top: 4px;
  justify-content: flex-end;
}

.removeCustomLeaveTypeBtn {
  margin-right: 10px;
  margin-top: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  font-weight: 500;
  background-color: #8ea7c5;
  border: 2px solid #475974;
  color: white;
  font-size: 0.9em;
  min-width: 84px;
  max-width: 84px;
}

.removeCustomLeaveTypeBtnSure {
  margin-right: 10px;
  margin-top: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  font-weight: 500;
  background-color: #0077ff;
  border: 2px solid #143051;
  color: white;
  font-size: 0.9em;
  min-width: 84px;
  max-width: 84px;
}

.hello {
  text-align: right;
  position: relative;
  right: 0px;
}

.defaultCalcKnob {
  /* background-color: orange; */
  margin-top: -4px;
  margin-right: 11px;
}

.defCalcTxt {
  /* background-color: red; */
  min-width: 160px;
  font-weight: 400;
  text-align: left;
}

.annLeaveUpperTxtSpaceBetween {
  display: flex;
  /* background-color: yellow; */
  min-width: 96%;
  justify-content: space-between;
  margin-left: 10px;
  font-size: 1em;
  margin-top: 0px;
  line-height: 1.5em;
}

.defCalcQcombined {
  /* background-color: pink; */
  min-width: 200px;
  display: flex;
}

.queryDefCalc {
  margin-left: 9px;
}

.heightWhenDefaultCalcEnabled {
  /* background-color: purple; */
  max-height: 50px;
  border: none;
  /* margin-top: -20px; */
}

/* edit custom leave type name modal */
.editCustomLeaveTypeNameUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;

  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
}

.editCustomLeaveTypeNameBox {
  background-color: #fff;
  min-width: 90vw;
  max-width: 90vw;
  height: 180px;
  margin-top: -10vh;
  border-radius: 15px;
  display: block;
  /* justify-content: center; */
  /* align-items: flex-start; */
  padding-top: 14px;
  color: white;
  text-align: center;
  border: 2px solid #dae1eb;
  -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.saveBtnForEditNameLeaveType {
  margin-top: 8px;
}

.enabledOrDisabledLeaveTypes {
  /* background-color: orange; */
  line-height: 20px;
  margin-top: 20px;
  color: #a0b9da;
  display: flex;
  font-weight: 500;
  justify-content: flex-end;
  /* padding-left: 4px; */
}

.enabledLeaveTypes {
  /* margin-left: 5%; */
  margin-right: 15px;
}

.disabledLeaveTypes {
  /* margin-right: 5%; */
}

.selectedLeaveTypes {
  font-weight: 500;
  color: #143051;
}

.customLeaveTypesTitle {
  font-size: 1.1em;
  /* margin-top: 20px; */
  color: white;
  /* max-width: 90%; */
  opacity: 0.7;
  margin: 0 auto;
  border-radius: 10px;
  line-height: 50px;
  background-color: #143051;
  margin-top: 10px;
}

.unpublishedBg {
  background-color: #e8e8e9;
}

.darkBottomBorder {
  border-bottom: 1px solid #aaaeb3;
}

.reduceOpac {
  opacity: 0.5;
}

.paidRowContainer {
  display: flex;
  /* background-color: lime; */
  min-width: 100%;
  justify-content: space-between;
}

.paidTypeBoxL {
  /* margin-left: -10px; */
}

.leaveTypePaidTitle {
  text-align: left;
  margin-bottom: -4px;
  margin-left: 9px;
  color: #516b8b;
  font-size: 1.2em;
  padding-bottom: 4px;
  font-weight: 400;
}

.leaveTypeRate {
  text-align: right;
  margin-right: 22px;
}

.heightWhenIncrementEnabled {
  /* background-color: black; */
  max-height: 66px;
}

.addLeaveTypeBtn {
  background-color: #0077ff;
  color: white;
  border-radius: 5px;
  min-width: 50px;
  min-height: 30px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  max-height: 30px;
  display: flex;
  align-items: center;
  line-height: 30px;
  font-size: 0.9em;
  margin: 12px auto;
  padding: 8px;
  border: 2px solid #143051;
}

.aaa {
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.enabledSpan {
  color: #516b8b;
  min-width: 45px;
  font-size: 0.7em;
  border-radius: 30px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.customLeaveTypesContainer {
  min-height: 300px;
  margin-bottom: 50px;
}

.annLeaveIllustrationBox {
  margin-top: -14px;
  /* border: 2px solid #ddebf4; */
  /* padding: 10px; */
  border-radius: 5px;
  /* padding-top: 0px; */
  /* max-width: 248px; */
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.annleaveIllustrationTable {
  /* background-color: yellow; */
  min-width: 160px;
  max-width: 190px;
  /* max-width: 70%; */
  /* margin-left: 10px; */
  /* margin-top: 20px; */
  /* padding-right: 3px; */
}

.illusRow {
  display: flex;
  max-width: 190px;
  min-width: 160px;
  border-bottom:1px solid #bbcada
  /* padding-right: 8px; */
  /* background-color: blue; */
}

.illusTitleHeader {
  font-weight: 500;
  font-size: 10px;
  text-align: left;
  min-width: 48%;
  background-color: #8ea7c5;
  color: white;
  border-radius: 5px;
  line-height: 13px;
  max-width: 49%;
  margin-left: 2px;
  display: flex;
  /* padding-left: 12px; */
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 4px;
  padding-right: 4px;
}

.plusPholsTitle {
  color:#516b8b
}

.illusTitleHeader:first-child {
  border-radius: 5px 0 0 0px;
}

.illusTitleHeader:last-child {
  border-radius: 0px 5px 0px 0px;
}

/* todo: media query illusTitleHeader above for iphone5 */

.illusRowHighlighted {
  background-color: #f4f9ff;
  /* max-width: 97%;
  min-width: 97%; */
}

.annLeaveVisualiserContainer {
  display: flex;
  justify-content: space-between;
  margin-top: -4px;
  cursor: pointer;
}
.topIllusRow {
  min-width: 100%;
  border:none;
  max-width: 100%;
}

.illusTitle,
.illusContent {
  min-width: 50%;
  color: #496375;
  font-size: 11px;
  margin-top: 6px;
  margin-bottom: 6px;
  text-align: center;
}

.customAllowanceTxt {
  padding-top: 3px;
  padding-left: 0px;
  /* color: #143051; */
  text-indent: 14px;
  /* font-size: 0.9em; */
  font-weight: 400;
}

.noneLeaveType {
  display: none;
}

.pullCustomAllowanceUpAbit {
  margin-top: 0px;
}

.noBorder {
  border: none !important;
}

.disableCustomLeaveContent {
  opacity: 0.3;
  pointer-events: none;
}

.customLeaveDaysOrHoursDiv {
  /* display: flex; */
  /* justify-content: space-between; */
  max-width: 90%;
  margin: 0 auto;
  margin-top: -8px;
  font-size: 0.7em;
  padding-right: 5px;
  /* margin-right: 10px; */
}

.customLeavesTitle {
  margin-left: 20px;
  margin-top: 17px;
  font-size: 1.1em;
  color: #496375;
  text-align: left;
  font-weight: 500;
}

.absenceKnob {
  margin-right: 3px;
}

.regenDayRatesContainer {
  display: flex;
  justify-content: space-between;
  /* background-color: yellow; */
  border: 1px solid #bbcada;
  border-radius: 5px;
  background-color: #f7fbff;
  font-size: 14px;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  align-items: center;
  margin-bottom: 20px;
  max-width: 330px;
  margin-right: 20px;
  /* min-width: 440px; */
}

.updateRateShiftsTxt {
  max-width: 70%;
  font-size: 12px;
  text-align: left;
  font-weight: 400;
  color: #143051;
  line-height: 16px;
  max-width: 60%;
}

.rateDaySpanBold {
  font-weight: 500;
}

.regenRateBtn {
  color: white;
  min-height: 30px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.regenRateBtn:hover {
  background-color: #bbcada;
}
.regenUpdateParent {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.loadingRegenDay {
  font-size: 14px;
  font-weight: 500;
  color: #07f;
  margin-top: 14px;
  margin-bottom: 7px;
  margin-right: 5%;
}

.closedDaysTimelineMainBody {
  max-width: 90%;
  margin: 0 auto;
  min-height: 400px;
  max-height: 400px;
  overflow: scroll;
  padding-bottom: 20px;
  border-radius: 5px 5px 0px 0px;
  background-color: white;
  border-top: solid 2px #e6eaee;
  border-bottom: solid 2px #e6eaee;
}
.historicalClosedDaysTxt {

  padding-bottom: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 6px;
  color: #516b8b;
}

.historyDayUnit {
  display: flex;
  min-width: 100%;
  min-height: 60px;
  max-height: 60px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c0d6f0;
}

.historyDayTitle {
  font-size: 14px;
  margin-left: 25px;
  line-height: 22px;
  color: #214978;
  font-weight: 500;
  min-width: 125px;
  min-height: 22px;
  text-align: left;
}

.historyDataTxtStatus {
  margin-right: 12px;
  font-size: 14px;
  color: #00aaff;
  font-weight: 500;
  text-align: center;
  min-width: 52px;
}

.historyDataTxtStatusClosed {
  color: #bbcada;
}

.knobContainerHistoryDay {
  margin-top: 0px;
  cursor: pointer;
  border: 1px solid #e6eaee;
  padding-bottom: 8px;
  padding-right: 37px !important;
  border-radius: 5px;
  min-width: 35px;
  max-width: 35px;
  max-height: 35px !important;
  min-height: 35px !important;
}

.historySunBorder {
  border-bottom: 2px solid #7495ba;
}

.historyDayUnit:last-child {
  border: none;
}

.historyDayUnitMain {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  max-height: fit-content;
  min-width: 170px;
}

.historyDayUnitpublicHol {
  color: #0cf;
  font-size: 10px;
  text-align: left;
  margin-left: 25px;
  max-width: 150px;
  line-height: 11px;
  text-align: left;
  /* min-height:50px; */
  /* margin-top: -14px; */
}

.closedDaysHistoryModalHeader {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-top: 19px;
  padding-right: 20px;
}

.selfApproveTitleTxt {
  /* max-width: 220px; */
  margin-left: 0px !important;
  /* margin-bottom: 10px; */
}

.selfApproveRow {
  min-height: 160px !important;
  max-height: 160px !important;
  padding-bottom: 15px;
}

.selfApproveTxtParent {
  /* background-color: yellow; */
  max-width: 70%;
  margin-left: 5%;
}

.selfApproveExplain {
  font-size: 14px;
  text-align: left;
  margin-top: 10px;
  color: #8997a8;
  /* margin-left: 5%; */
}

.selfApproveKnob {
  margin-right: 6% !important;
}

.adminWrapper {
  display: flex;
}

@keyframes expandWidth {
  0% { min-width: 0; width: 0; }
  100% { min-width: 160px; width: 160px; }
}
.adminDesktopMenu {
  background-color: #143051;
  min-height: calc(100vh - 145px);
  max-height: calc(100vh - 145px);
  min-width: 0;
  width: 0;
  padding-top: 20px;
  z-index: 100;
  border-left: 2px solid #143051;
  animation: expandWidth 0.5s ease forwards; /* Apply animation */
}


.adminMenuUnitDesktop {
  min-width: 100%;
  min-height: 50px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  color: #a5b5cd;
  padding-left: 24px;
  cursor: pointer;
  font-size: 14px;
  max-height: 40px;
  font-weight: 500;
}

.adminMenuUnitDesktop:hover {
  background-color: #1e3958;
}

.adminTitleDesktopSel {
  font-weight: 500;
  background-color: #214978;
  cursor: default;
  color: white;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.adminTitleDesktopSel:hover {
  cursor: default;
  background-color: #214978;
}

.coordLinkTxt {
  font-weight: 500;
  color: #496375;
}

.coordLinkTxt:hover {
  color: #214978;
}

.longLatInput {
  border-radius: 5px;
  border: 1px solid #dde9f9;
  background-color: #fff;
  color: #496375;
  min-height: 35px;
  font-size: 0.95em;
  font-weight: 500;
  padding-left: 12px;
  padding-right: 8px;
  padding-top: 0px;
  letter-spacing: 1px;
  max-width: 45%;
  min-width: 45%;
  -webkit-appearance: none;
  margin-bottom: 8px;
  margin-top: 8px;
}

.longLatInput:last-child {
  margin-left: 10px;
}

.longLatInput::placeholder {
  color: #c0d6f0;
}

.longLatInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 11px;
  background-color: #fdfeff;
}

.latLongTitlesDiv {
  display: flex;
  min-width: 100%;
  /* background-color: blue; */
}

.longLatTitle {
  min-width: 50%;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  margin-top: 18px;
  padding-left: 2px;
  color: #859dbb;
  text-transform: uppercase;
  /* margin-bottom: px; */
}

.longLatTitle:last-child {
  padding-left: 0px;
  margin-left: -4px;
}

.radiusExplain {
  font-size: 12px;
  color: #859dbb;
  text-align: left;
  margin-left: 16px;
  margin-top: 8px;
}

.radiusInput {
  border-radius: 5px;
  border: 1px solid #dde9f9;
  background-color: #fff;
  color: #496375;
  min-height: 35px;
  font-size: 0.95em;
  font-weight: 500;
  padding-left: 12px;
  padding-right: 8px;
  padding-top: 0px;
  letter-spacing: 1px;
  max-width: 70px;
  min-width: 70px;
  /* background: url(/static/media/calicongrey.9c7e1aba.svg) no-repeat right #fff; */
  -webkit-appearance: none;
  /* background-position-x: 130px; */
  margin-bottom: 8px;
  margin-top: 8px;
}

.radiusInput::placeholder {
  color: #c0d6f0;
}

.radiusInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 11px;
  background-color: #fdfeff;
}
.radiusTitle {
  min-width: 80%;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  margin-top: 18px;
  padding-left: 2px;
  color: #859dbb;
  text-transform: uppercase;
}

.radiusDiv {
  display: flex;
  min-width: 100%;
  border-top: 1px solid #ddebf4;
  margin-top: 10px;
}

.gpsModalFooter {
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
  min-height: 46px;
  align-items: center;
  border-top: 1px solid #ddebf4;
  margin-top: 16px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
}

.saveGpsModalBtn {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  background-color: #0bf;
  border: 2px solid #0af;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 5px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.saveGpsModalBtn:hover {
  background-color: #0af;
}

.closeGpsModalBtn {
  color: #859dbb;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  margin-right: 14px;
}

.closeGpsModalBtn:hover {
  color: #758aa2;
}
.invalidGPS {
  opacity: 0.5;
  pointer-events: none;
}

.latLongInvalidInput {
  border: #e0b7b7 2px solid !important;
}

.gpsZoneTitle {
  margin-left: 5px;
  font-size: 12px;
  color: #859dbb;
  text-transform: uppercase;
  margin-bottom: 2px;
  text-align: left;
  font-weight: 500;
  margin-top: 16px;
}

.gpsInfoTxt {
  color: #214978;
  text-align: left;
  font-size: 14px;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 30px;
  /* padding-left: 18px; */
}

.viewZoneLongLat {
  /* border-radius: 5px; */
  /* border: 1px solid #dde9f9; */
  /* background-color: #fff; */
  color: #496375;
  min-height: 35px;
  font-size: 18px;
  font-weight: 500;
  /* padding-left: 12px; */
  /* padding-right: 8px; */
  /* padding-top: 0px; */
  /* letter-spacing: 1px; */
  max-width: 50%;
  min-width: 50%;
  text-align: left;
  /* background: url(/static/media/calicongrey.9c7e1aba.svg) no-repeat right #fff; */
  -webkit-appearance: none;
  /* background-position-x: 130px; */
  /* margin-bottom: 8px; */
  /* margin-top: 8px; */
  padding-left: 2px;
  margin-top: 5px;
}

.editZoneLongLatInput {
  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;
  margin-right: 4px;
  color: #6a8bb1;
  min-height: 35px;
  padding-left: 8px;
  font-weight: 500;
  max-width: 40%;
  min-width: 40%;
  margin-left: 8%;
  margin-top: 5px;
}

.editZoneLongLatInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 6px;
  background-color: #fdfeff;
}

.editZoneLongLatInput:first-child {
  margin-left: 0px;
}

.viewZoneRadius {
  /* border-radius: 5px; */
  /* border: 1px solid #dde9f9; */
  /* background-color: #fff; */
  color: #496375;
  min-height: 35px;
  font-size: 18px;
  font-weight: 500;
  /* padding-left: 12px; */
  /* padding-right: 8px; */
  /* padding-top: 0px; */
  /* letter-spacing: 1px; */
  max-width: 38%;
  min-width: 38%;
  text-align: left;
  /* background: url(/static/media/calicongrey.9c7e1aba.svg) no-repeat right #fff; */
  -webkit-appearance: none;
  /* background-position-x: 130px; */
  /* margin-bottom: 8px; */
  /* margin-top: 8px; */
  padding-left: 2px;
  margin-top: 5px;
}

.viewRadiusTxt {
  border-radius: 5px;
  border: 1px solid #dde9f9;
  background-color: #fff;
  color: #496375;
  min-height: 35px;
  font-size: 0.95em;
  font-weight: 500;
  padding-left: 12px;
  padding-right: 8px;
  padding-top: 0px;
  letter-spacing: 1px;
  max-width: 70px;
  min-width: 70px;
  /* background: url(/static/media/calicongrey.9c7e1aba.svg) no-repeat right #fff; */
  -webkit-appearance: none;
  /* background-position-x: 130px; */
  margin-bottom: 8px;
  margin-top: 8px;
}

.viewGpsModalBox {
  /* background-color: #e0ebf8; */
  background-color: white;
  max-width: 338px;
  margin: 0 auto;
  border-radius: 5px;
  overflow: hidden;
  min-height: 292px;

  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  cursor: default;
}
.viewZoneModalBox {
  padding: 15px 20px 20px;
  padding-bottom: 10px;
  color: #143051;
  /* background-color: yellow; */
  min-width: 338px;
  /* max-height: 400px !important; */
}

.viewZoneModalHeader {
  display: flex;
  min-width: 100%;
  /* background-color: blue; */
  justify-content: space-between;
}

.viewModalZoneTitle {
  font-size: 1.2em;
  font-weight: 500;
  color: #143051;
  /* margin-left: 80px; */
  margin-top: 3px;
  text-align: center;
  min-height: 35px;
  padding-top: 6px;
}

.closeViewGpsModalIcon {
  width: 15px;
  margin-top: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.viewZoneModalTopRightBlank {
  min-width: 15px;
}

.longLatTitleView {
  min-width: 50%;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  margin-top: 18px;
  padding-left: 2px;
  color: #859dbb;
  text-transform: uppercase;
  /* margin-bottom: px; */
}

.viewRadiusTitle {
  min-width: 50%;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  margin-top: 18px;
  padding-left: 2px;
  color: #859dbb;
  display: flex;
  align-items: flex-end;
  font-size: 12px;
  text-transform: uppercase;
}

.viewGpsStaffTitle {
  min-width: 50%;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  margin-top: 18px;
  /* padding-left: 2px; */
  color: #143051;
  margin-left: -31px;
  /* background-color: blue; */
  min-width: 170px;
  min-height: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 16px;
  /* margin-t */
}

.addStaffToGps {
  color: #fff;
  border: solid 1px #0af;
  background-color: #0bf;
  font-size: 14px;
  border-radius: 5px;
  padding-left: 6px;
  padding-right: 6px;
  min-height: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.viewZonePeople {
  /* background-color: purple; */
  border-top: solid 1px #ddebf4;
  padding-top: 5px;
  min-height: 180px;
  max-height: 180px;
  min-width: 170px;
  margin-top: 10px;
  overflow-y: scroll;
  padding-bottom: 10px;
  /* margin-right: 15px; */
  /* display: none; */
}

.viewGpsModalFooter {
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
  min-height: 40px;
  align-items: center;
  border-top: 1px solid #ddebf4;
  margin-top: 0px;
  /* padding-left: 10px;
  padding-right: 10px; */
  padding-top: 8px;
}

.gpsStaffUnit {
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 30px;
  min-height: 30px;
  align-items: center;
  border-radius: 5px;
  color: #496375;
  font-weight: 500;
  font-size: 14px;
  background-color: #e3e8ee;
  color: #4f566b;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
  margin-right: 8px;
  margin-top: 8px;
  font-size: 14px;
  min-width: 170px;
  max-width: 170px;
  margin-left: 2px;
}
.gpsStaffUnit:hover {
  background-color: #cdd9e8;
}
.gpsStaffUnit:first-child {
  margin-top: 0px;
}

.removeGpsStaffImg {
  min-width: 10px;
  cursor: pointer;
}

.addStaffToGpsDropdown {
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 180px;
  min-width: 180px;
  line-height: 26px;
  height: 30px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #496375;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 154px;
  margin-left: -35px;
  margin-top: 18px;
  /* margin-bottom: -6px !important; */
  /* margin- */
}

.gpsZoneDeleteCrossImg {
  margin-right: 8px;
  max-width: 24px;
  padding-left: 7px;
  padding-right: 7px;
  cursor: pointer;
  /* background-color: blue; */
}

.gpsZoneDeleteCrossImg:hover {
  opacity: 0.6;
  /* transform: scale(1.1); */
}

.deleteGpsBtn {
  font-weight: 500;
  font-size: 14px;
  font-weight: 500;
  color: #592eb6;
  text-align: center;
  padding-left: 9px;
  padding-right: 9px;
  display: flex;
  min-height: 30px;
  max-height: 30px;
  background-color: #214978;
  border: 1px solid #143051;
  align-items: center;
  margin-top: 0px;
  border-radius: 5px;
  justify-content: center;
  cursor: pointer;
  color: white;
  /* margin-left: 10px; */
  min-width: 70px;
  max-width: 70px;
}

.deleteGpsBtn:hover {
  background-color: #143051;
}

.unsureDeleteGps {
  font-weight: 500;
  font-size: 14px;
  font-weight: 500;
  color: #592eb6;
  text-align: center;
  padding-left: 9px;
  padding-right: 9px;
  display: flex;
  min-height: 30px;
  max-height: 30px;
  /* line-height: 26px; */
  background-color: #07f;
  border: 1px solid #0469de;
  align-items: center;
  margin-top: 0px;
  border-radius: 5px;
  /* padding-top: 2px; */
  justify-content: center;
  cursor: pointer;
  color: white;
  /* margin-left: 10px; */
  min-width: 70px;
  max-width: 70px;
}

.unsureDeleteGps:hover {
  background-color: #0469de;
}

.viewGpsDeleteAndEditBtnContainer {
  display: flex;
  justify-content: space-between;
  max-width: 132px;
  min-width: 132px;
}

.editGpsBtn {
  font-weight: 500;
  font-size: 14px;
  font-weight: 500;
  color: #592eb6;
  text-align: center;
  padding-left: 9px;
  padding-right: 9px;
  display: flex;
  min-height: 30px;
  max-height: 30px;
  background-color: #8ea7c5;
  border: 1px solid #6987a9;
  align-items: center;
  margin-top: 0px;
  border-radius: 5px;
  justify-content: center;
  cursor: pointer;
  color: white;
  /* margin-left: 10px; */
  min-width: 54px;
  max-width: 54px;
}

.editGpsBtn:hover {
  background-color: #6987a9;
}

.saveEditGpsBtn {
  font-weight: 500;
  font-size: 14px;
  font-weight: 500;
  color: #592eb6;
  text-align: center;
  padding-left: 9px;
  padding-right: 9px;
  display: flex;
  min-height: 30px;
  max-height: 30px;
  background-color: #0bf;
  border: 1px solid #0af;
  align-items: center;
  margin-top: 0px;
  border-radius: 5px;
  justify-content: center;
  cursor: pointer;
  color: white;
  /* margin-left: 10px; */
  min-width: 54px;
  max-width: 54px;
}

.saveEditGpsBtn:hover {
  background-color: #0af;
}

.editRadiusInput {
  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;
  color: #6a8bb1;
  min-height: 35px;
  padding-left: 8px;
  font-weight: 500;
  max-width: calc(38% - 25px);
  min-width: calc(38% - 25px);
  margin-right: 25px;
  margin-top: 5px;
}

.editRadiusInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 6px;
  background-color: #fdfeff;
}

.editGpsTitleInput {
  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;
  margin-right: 4px;
  color: #6a8bb1;
  min-height: 35px;
  padding-left: 8px;
  font-weight: 500;
  max-width: 220px;
  min-width: 220px;
  margin-left: 30px;
  margin-top: 3px;
}

.editGpsTitleInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 6px;
  background-color: #fdfeff;
}

.gpsAllStaffBtnSelected {
  cursor: pointer;
  display: flex;
  border: 1px solid #9374d6;
  background-color: #4b1caf;
  color: #fff;
  border-radius: 5px;
  min-height: 30px;
  line-height: 30px;
  padding-left: 8px;
  padding-right: 6px;
  font-size: 14px;
  margin-right: 6px;
  max-width: 93px;
  font-weight: 500;
  margin-left: 82px;
  margin-top: 4px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.gpsAllStaffBtn {
  cursor: pointer;
  display: flex;
  border: 1px solid #9374d6;
  background-color: #4b1caf;
  color: #fff;
  border-radius: 5px;
  min-height: 30px;
  line-height: 30px;
  padding-left: 8px;
  padding-right: 6px;
  font-size: 14px;
  margin-right: 6px;
  max-width: 93px;
  opacity: 0.6;
  font-weight: 500;
  margin-left: 82px;
  margin-top: 4px;
  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.gpsAllStaffCheckBoxImg {
  max-width: 16px;
  margin-left: 9px;
}

.fadeAddStaffToGpsDropdown {
  opacity: 0.5;
  pointer-events: none;
}

.gpsStaffNameSpan {
  cursor: pointer;
}

.noStaffAddedToGpsTxt {
  text-align: right;
  max-width: 100px;
  min-width: 100px;
  font-size: 14px;
  margin-top: 40px;
  margin-bottom: 12px;
  color: #859dbb;
  margin-left: 70px;
}

.myAccountHeader {
  background-color: white;
  min-height: 87px;
  max-height: 87px;
  min-width: 100%;
  max-width: 100%;
  /* padding-left: 27px; */
  border-bottom: solid 2px #e6eaee;
  padding-top: 7px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.myAccountMenuRow {
  min-width: 246px;
  max-width: 246px;
  margin-top: 1px;
}

.myAccountMenuRowMob {
  min-width: 352px;
  max-width: 352px;
  margin-top: 6px;
}

.dashMenuRow {
  min-width: 375px;
  max-width: 375px;
  margin-top: 1px;
}

.dashMenuRowMob {
  min-width: 305px;
  max-width: 305px;
  margin-top:6px;
}

@media only screen and (min-width: 820px) {

.toilDashHelpbtn:hover {
  background-color: #bbcada;
}
  .deleteToilConvBtn:hover {
    background-color: #bbcada;
  }
  .dashAllowProPic:hover {
    opacity: 0.8;
  }
  .dashUserRow:hover {
    background-color: #f6f9ff !important;
  }
  .allLeaveDashController:hover {
    border-color: #8ea7c5 !important
  }

  .enableTilRowBtn:hover {
    border-color: #b2bbc7 !important

  }
  .specialDayUnit:hover {
    border-color: #bbcada  }
  .myAccountTitle {
    position: fixed;
    top: 30px;
    /* left: 280px; */
    /* background-color: yellow; */
    z-index: 9999;
  }

  .avoidWeekend:hover {
    border-color:#bbcada
  }
  .myAccountMenuBar {
    position: fixed;
    top: 80px;
    /* left: 278px; */
    z-index: 9999;
  }
  .addGPSBtn:hover {
    background-color: #bbcada;
  }

  .generatePayDaysBtn:hover {
    background-color: #0469de;
  }



  .dashAddUserBtn:hover {
    background-color: #dde5f3;
  }
}

.myAccountTitle {
  color: #bbcada;
  font-weight: 500;
  font-size: 33px;
  text-align: left;
  margin-top: 10px;
  min-width: 130px;
  margin-left: 22px;
}

.myAccountTitleMob {
  margin-top: 14px;
  margin-bottom: -3px;
}

.myAccountMenuBar {
  display: flex;
  min-width: 139px;
  justify-content: space-between;
  margin-top: 3px;
  margin-left: 24px;
  font-weight: 500;
  align-items: center;
}

.myAccBtnHolder {
  min-height: 27px;
  color: #c2cce9;
  cursor: pointer;
  padding-left: 3px;
  padding-right: 3px;
  margin-top: -3px;
}

.myAccBtnHolder:hover {
  color: #4f79ab;
}

.settingsAccHolder {
}

.myAccountMenuBtn {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accountMenuBtnSelected {
  border-bottom: 1px #e3e8ee solid;
  color: #143051;
}

.accountMenuBtnSelected:hover {
  color: #143051 !important;
  cursor: default !important;
}

.accountBody {
  min-height: calc(100vh - 155px);
  background-color: #ebf0f7;
  border-left: 1px solid #e6eaee;
  /* overflow-y: scroll; */
}

.accountAllowancesDiv {
  min-height: calc(100vh - 155px);
  /* border-right: 1px solid #e0ebf8; */
}

.accountSettingsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 110px;
  padding-left: 12px;
  padding-top: 10px;
  /* flex-direction: column; */
  border-bottom: 1px #e3e8ee solid;
  max-width: 90%;
  margin-left: 5%;
}

.accountSettingsTitle {
  color: #143051;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.accountSettingsValue {
  /* color: #8997a8; */
  margin-top: 4px;
  font-weight: 400;
  color: #516b8b;
  font-size: 14px;
}

.accountSettingsRowLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.accountSettingsEditImg {
  margin-right: 18px;
  cursor: pointer;
}

.accountSettingsEditImg:hover {
  opacity: 0.8;
}

.accountSettingsInfo {
  font-size: 14px;
  text-align: left;
  /* margin-bottom: 0px; */
  margin-top: 11px;
  padding-right: 50px;
  color: #516b8b;
  /* background-color: blue; */
  /* line-height: 12px; */
  line-height: 20px;
  font-weight: 400;
}

.allowSwapsKnob {
  margin-right: 4px;
  margin-top: -10px;
  min-width: 40px;
}

.accountSettingsContainer {
  overflow-y: scroll;
  min-height: calc(100vh - 147px);
  max-height: calc(100vh - 147px);
  padding-bottom: 40px;
  border-right: 1px solid #e0ebf8;
}

.accountSettingsContainerMob {
  /* background-color: orange; */

  min-height: calc(100vh - 204px);
  max-height: calc(100vh - 204px);
  overflow-y: scroll;
}

.accountSettingsContainerMobPermissions3 {
  min-height: calc(100vh - 165px);
  max-height: calc(100vh - 165px);
}

.accountSettingsContainerMob::-webkit-scrollbar {
  /* display: none; */
  /* width: 5px !important;
  height: 4px !important; */
}

.accountSettingsContainerMob::-webkit-scrollbar {
  /* width: 5px;
  height: 8px; */
}

.accountSettingsContainerMob::-webkit-scrollbar-button {
  /* width: 5px;
  height: 5px; */
}
.ccountSettingsContainerMob::-webkit-scrollbar-thumb {
  background-color: #c7daef;
  border-radius: 20px;
  border: 3px solid none;
}

.accountSettingsTeamArray {
  /* background-color: blue; */
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  min-width: 90%;
  margin-bottom: 8px;
  margin-top: 10px;
}

.pTeamNameDiv {
  /* background-color: blue; */
  /* display: flex; */
  /* justify-content: flex-start; */
  /* flex-wrap: wrap; */
  min-width: 90%;
  margin-bottom: 8px;
}

.yourMgrsArr {
  margin-bottom: 15px;
  margin-top: -4px;
}

.accountSettingsTeamItem {
  min-width: -moz-fit-content;
    min-width: fit-content;
    border-radius: 5px;
    max-width: -moz-fit-content;
    max-width: fit-content;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    justify-content: space-between;
    /* max-height: 30px; */
    min-height: 30px;
    align-items: center;
    border-radius: 5px;
    color: #496375;
    font-weight: 500;
    font-size: 14px;
    background-color: #e3e8ee;
    color: #4f566b;
    animation-name: zoomModalIn !important;
    animation-duration: 0.1s !important;
    margin-right: 8px;
    margin-top: 8px;
    background-color: #fff;
    border: #e6e6e6 solid 1px !important;
    cursor: pointer;
    text-align: left;
    padding-top: 4px;
    padding-bottom: 4px;
}

.accountSettingsTeamItem:hover {
  border-color: #c5ced8 !important;
}

.accountProfilePTeamName {
  min-width: fit-content;
  border-radius: 5px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 26px;
  min-height: 26px;
  align-items: center;
  border-radius: 5px;
  color: #496375;
  font-weight: 500;
  font-size: 12px;
  /* background-color: #e3e8ee; */
  color: #4f566b;
  /* animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important; */
  /* margin-right: 8px; */
  margin-top: 8px;
  border: 1px solid #e6e6e6;
  background-color: white;
  cursor: pointer;
}

.accountProfilePTeamName:hover {
  border-color: #c5ced8 !important;
}

.accountTeamsLeft {
  min-width: 100%;
}

.yourManagersExplain {
  margin-top: 8px;
  margin-bottom: 11px;
  /* max-width: 60%; */
}

.tallerSettingsRowLeft {
  min-height: 120px;
  padding-top: 30px;
}

.notifSettingsDiv {
  /* background-color: blue; */
  min-width: 300px;
  max-width: 300px;
  margin-left: 23px;
  margin-top: 20px;
  border-radius: 5px;
  border: solid #051b35 2px;
  background-color: #143051;
  overflow-y: scroll;
  max-height: calc(100vh - 200px);
}

.notifSettingsEnableDiv {
  display: flex;
  justify-content: flex-start;
  max-width: 280px;
  margin-left: 8px;
  margin-top: -17px;
  margin-bottom: 10px;
}

.notifEnableItem {
  background-color: #294566;
  border: 2px solid #294566;
  min-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-size: 13px;
  font-weight: 500;
  padding-left: 8px;
  padding-right: 8px;
  min-height: 35px;
  border-radius: 5px;
  cursor: pointer;
}

.notifEnableItem:last-child {
  margin-left: 8px;
}

.notifEnableItemDisabled {
  background-color: #3b5472;
  border: 2px solid #335174;
}

.notifEnableItemDisabled:hover {
  /* background-color: #294566; */
  /* border: 2px solid #335174; */
}

.notifCheckBoxImg {
  max-width: 20px;
  margin-left: 5px;
}

.notifUpper {
  display: flex;
  /* background-color: yellow; */
  min-height: 50px;
  align-items: center;
  justify-content: space-between;
}
.notifSettingsRow {
  /* display: flex; */
  /* background-color: yellow; */
  min-height: 50px;
  /* align-items: center; */
  /* justify-content: space-between; */
  max-width: 98%;
  margin-left: 3%;
  color: white;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 8px;
  /* cursor: pointer; */
  border-bottom: 1px #627a97 solid;
  border-left: 2px solid #00ccff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notifExplainRow {
  /* border-bottom: 1px #627a97 solid; */
  color: white;
  max-width: 200px;
  font-size: 12px;
  text-align: left;
  padding-left: 0px;
  padding-right: 10px;
  opacity: 0.5;
  margin-top: 5px;
  padding-bottom: 10px;
}

/* .notifSettingsRow:hover {
  background-color: #486382;
} */

.notifSettingsRowMob:hover {
  background: none;
}

.notifSettingsRow:last-child {
  border: none;
}

.notifSettingsKnob {
  margin-top: -5px;
}

.notifLeft {
  min-width: 140px;
  /* background-color: grey; */
}

.notifRight {
  display: flex;
  min-width: 84px;
}

.notifBtnCol {
  /* background-color: blue; */
  min-width: 40px;
  max-width: 40px;
  margin-left: 2px;
  margin-right: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.announcementHolder {
  margin-right: -2px;
  padding-right: 5px;
  padding-left: 6px;
  min-width: 40px;
  /* margin-left: -2px; */
}

.notifsIsMgrTitle {
  color: #00ccff;
  font-weight: 500;
  text-align: left;
  padding-left: 15px;
  margin-top: 24px;
  /* opacity: 0.3; */
  margin-bottom: 10px;
}

.notifsIsMgrTitleMgr {
  color: #de82f7;
  font-weight: 500;
  text-align: left;
  padding-left: 15px;
  margin-top: 30px;
  opacity: 1;
  margin-bottom: 10px;
}

.notifSettingsScrollBox {
  border-top: 1px solid #496382;
}

.notifTypeKnob {
  margin-top: -8px;
  margin-left: 5px;
}

@media only screen and (min-height: 693px) {
  .userNotifSettingHeightDynamic {
    /* max-height: 432px !important;
    min-height: 432px !important; */
  }
}

.yourManagersTitle {
  margin-top: 12px;
}

.accountSettingsProfileRow {
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  background-color: white;
  border-radius: 5px;
  border: 1px #e3e8ee solid;
  max-height: 120px;
  min-height: 120px;
  display: flex;
}

.profileRowLeft {
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: orange; */
}

.profileRowProPicImg {
  max-width: 100px;
  min-width: 100px;
  min-height: 100px;
  max-height: 100px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
}

.profileRowRight {
  min-width: calc(100% - 120px);
  /* background-color: orange; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  padding-left: 4px;
}

.rightRowLower {
  margin-top: 2px;
}

.profileRightRowTitle {
  color: #143051;
  font-size: 16px;
  font-weight: 500;
  margin-top: 12px;
  /* opacity: 0.5; */
}

.profileRightRowValue {
  color: #516b8b;
  margin-top: 1px;
  font-size: 12px;
  font-weight: 500;
}

.notifSettingsMob {
  /* background-color: yellow; */
  min-width: auto;
  max-width: calc(100%);
  margin-top:-10px;
  padding-top:20px;
  margin-left:0px;
  border-radius: 0 0 5px 5px;
  padding-left:10px;
  /* overflow: hidden; */
}

.userNotifMob {
  /* overflow-y: none !important */
}

.mgrNotifMob {
  min-height: fit-content;
  max-height: fit-content;
}

.noScrollNotifs {
  /* overflow: none;
  min-height: 1000px; */
}

.primaryTeamNameTitleProfile {
  font-size: 10px;
  color: #00aaff;
  margin-top: 2px;
  border-left: 1px solid #00aaff;
  padding-left: 5px;
  display: none;
}

.yearlyTotalsDiv {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  align-items: center;
}

.yearTotalsYearDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 94px;
  min-width: 94px;
  line-height: 26px;
  height: 35px;
  background: url(/static/media/downArrowWhite2.887d57ce.svg) no-repeat right
    rgb(83, 155, 237);
  border: 1px solid #2c7eb9;
  -webkit-appearance: none;
  background-position-x: 64px;
  display: flex;
  cursor: pointer;
  border-color: #2c7eb9;

  border: 2px solid #e6eaee;
  /* background-color: #0bf; */
  background-color: white;
  color: #6a8bb1;
  background: url("../img/general/downArrowGrey.svg") no-repeat right #fff;
  background-position-x: 72px;
  margin-right: 20px;
}

.yearTotalsYearDropdown:hover {
  border-color: #d7dfe9;
}

.accountHoursDataBox {
  min-width: 100%;
  margin-top: -50px;
  /* border-right: 2px solid #bbcada; */
  padding-top: 70px;
}

.profileDataRow {
  display: flex;
  min-width: 100%;
  /* background-color: orange; */
  min-height: 50px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #e6eaee;
  padding-left: 10px;
  padding-right: 10px;
}

.profileDataRowTitle {
  font-weight: 500;
  color: #214978;
  font-size: 12px;
  text-align: left;
  max-width: 70%;
}

.profileDataRowValue {
  font-weight: 500;
  color: #496375;
  font-size: 12px;
  margin-right: 15px;
}

.allTheHoursExplain {
  max-width: 100%;
  margin-bottom: 0px;
  margin-top: 20px;
}

.hoursIndent {
  margin-left: 20px;
  color: #496375;
  font-weight: 500;
  opacity: 0.8;
}

.thickBorderTop {
  border-top: solid 2px #bac5d3;
}

.thickBorderBottom {
  border-bottom: solid 1px #bac5d3;
}

.noBorderBottom {
  border-bottom: none;
}

.profileNamesLoaderDiv {
  display: flex;
  min-width: 170px;
  justify-content: space-between;
}

.profileLoading {
  min-height: 20px;
  max-height: 20px;
  max-width: 80px;
  min-width: 80px;
  background-color: #e2ebf5;
  border-radius: 5px;
}

.profileJTloading {
  min-height: 14px;
  max-height: 14px;
  margin-top: 6px;
  max-width: 80px;
  min-width: 80px;
  background-color: #c2cfdc;
  border-radius: 5px;
}

.profileJT2loading {
  min-height: 14px;
  max-height: 14px;
  margin-top: 8px;
  max-width: 60px;
  min-width: 60px;
  background-color: #a9b6c2;
  border-radius: 5px;
  /* opacity: 0.5; */
}

.installTimelifyBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border: #e3e8ee 1px solid;
  border-radius: 5px;
  min-width: 90px;
  padding: 10px;
  font-size: 16px;
  color: #496375;
  font-weight: 500;
  cursor: pointer;
}
.installTimelifyBtn:hover {
  border-color: #bac5d3;
}

.installAppBtnImg {
  max-width: 18px;
}

.allowanceBtnTxtProfile {
  font-weight: 500;
  margin-left: 6px;
  margin-right: 6px;
  /* font-size: 14px; */
}

.profileAllowancesBtn {
  background-color: #fff;
  border: #fff 1px solid;
  display: flex;
  color: #859dbb;
  min-height: 36px;
  align-items: center;
  /* line-height: 34px; */
  padding-left: 2px;
  padding-right: 8px;
  border-radius: 3px;
  text-align: center;
  margin-left: 9px;
  margin-right: 28px;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  max-height: 30px;
  min-height: 30px;
  margin-top: 4px;
  font-size: 12px;
  cursor: pointer;
  position: fixed;
  top: 34px;
  right: -4px;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 20%);
}

.profileAllowancesBtn:hover {
  border-color: #e3e8ee;
}

.supportModalBtn {
  background-color: #fff;
  border: #fff 1px solid;
  display: flex;
  color: #859dbb;
  min-height: 36px;
  align-items: center;
  /* line-height: 34px; */
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 3px;
  text-align: center;
  margin-left: 9px;
  margin-right: 28px;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  max-height: 30px;
  min-height: 30px;
  margin-top: 4px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  position: fixed;
  top: 78px;
  right: -4px;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 20%);
}

.supportModalBtn:hover {
  border-color: #e3e8ee;
}

.profileStatsRotate {
  transform: rotate(90deg);
}

.allowanceStatsImg {
  margin-left: 4px;
}

.profileAdminBtn {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  display: flex;
  color: #fff;
  min-height: 36px;
  align-items: center;
  /* line-height: 34px; */
  padding-left: 9px;
  padding-right: 2px;
  border-radius: 5px;
  text-align: center;
  margin-left: 9px;
  margin-right: 28px;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  max-height: 35px;
  min-height: 35px;
  margin-top: 4px;
  font-size: 16px;
  cursor: pointer;
  position: fixed;
  top: 43px;
  right: -4px;
}

.profileAdminBtn:hover {
  background-color: #bbcada;
}

.pushProAllowsBtnDown {
  top: 75px;
}

.signoutBtn {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  display: flex;
  color: #fff;
  align-items: center;
  /* line-height: 34px; */
  padding-left: 9px;
  padding-right: 2px;
  border-radius: 5px;
  text-align: center;
  margin-left: 9px;
  margin-right: 40px;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  max-height: 35px;
  min-height: 35px;
  /* margin-top: 4px; */
  font-size: 14px;
  cursor: pointer;
  max-width: fit-content;
}

.signoutBtn:hover {
  background-color: #bbcada;
}

.signoutRow {
  display: flex;
  justify-content: flex-end;
  min-width: 100%;
  margin-top: -19px;
  padding-right: 8px;
}

.signoutIcon {
  max-width: 10px;
}

.ignoreInstallBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 72px;
  padding-left: 9px;
  padding-right: 9px;
  min-height: 35px;
  max-height: 35px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
}

.ignoreInstallBtn:hover {
  background-color: #bbcada;
}

.installCheckBox {
  max-width: 12px;
  margin-left: 8px;
}

.midBreakAllowBtn {
  margin-top: 28px;
}

.notifSettingsRowTitle {
  font-weight: 500;
  max-width: 200px;
  text-align: left;
  margin-top: 20px;
}

.salaryInfoAndHistoryBtnDiv {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  margin-top: 2px;
}

.myHourlyRateTimelineBtn {
  font-size: 14px;
  font-weight: 500;
  background-color: #d0d8e3;
  border: 1px solid #bbcada;
  color: white;
  display: flex;
  justify-content: space-around;
  padding-left: 8px;
  padding-right: 8px;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  max-height: 35px;
  min-height: 35px;
}
.myHourlyRateTimelineBtn:hover {
  background-color: #c5cedb;
}

.mySalaryTimelineDownBtn {
  max-width: 10px;
  margin-left: 6px;
  margin-top: 2px;
  transform: rotate(0deg);
}

.hideHrRateTimelineRotation {
  transform: rotate(90deg);
}

.mySalaryTimelineDiv {
  border-right: 2px solid #143051;
  min-width: 100%;
  margin-top: -10px;
  margin-bottom: 14px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.mySalTimelineObj {
  border: 1px solid #bac5d3;
  max-width: 280px;
  min-width: 280px;
  margin-bottom: 10px;
  border-radius: 5px;
  background: white;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-right: 10px;
}

.mySalTimelineObj:last-of-type {
  margin-bottom: 0px;
}

.mySalTimelineRow {
  display: flex;
  min-width: 90%;
  justify-content: space-between;
  align-items: center;
  margin-left: 4%;
  max-width: 92%;
  padding-top: 0px;
  padding-bottom: 0px;
  font-weight: 500;
}

.mySaltTimeTitleAndValue {
  text-align: left;
}

.textRight {
  text-align: right;
}

.mySalTimelineTitle {
  color: #214978;
  font-size: 10px;
}

.mySalTimelineVal2 {
  color: #58bdee;
  margin-top: 4px;
  font-size: 14px;
}

.mySalTimelineVal {
  color: #143051;
  font-weight: 500;
  margin-top: 4px;
  font-size: 14px;
}

.secondMySalTimelineRow {
  margin-top: 7px;
  border-top: 1px solid #e3e8ee;
  padding-top: 8px;
}
.accountFixedSched {
  min-width: 100%;
}

.accFSrow {
  display: flex;
  min-width: 240px;
  justify-content: space-between;
  min-height: 60px;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  border-bottom: 1px #e3e8ee solid;
}

.accFSrow:last-child {
  border: none;
  margin-bottom: 20px;
}

.accFSrowTitle {
  color: #496375;
  font-weight: 500;
  font-size: 14px;
}

.accFsRowValue,
.accFsRowNonWorkingDay {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  color: #8997a8;
  font-weight: 500;
  font-size: 14px;
}

.accFsRowNonWorkingDay {
  opacity: 0.5;
}

.accFsRowValueLower {
  /* font-weight:400; */
  margin-top: 4px;
  font-size: 12px;
}

.whatsappModalBox {
  background-color: #f4f8fe;
  border-radius: 5px;
  max-width: 340px;
  max-height: 200px;

  padding: 20px;
  cursor: default;

  animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
}

.whatsappIconImg {
  cursor: pointer;
}

.whatsappIconDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border: solid 1px #e6eaee;
  border-top: solid 2px #e6eaee;
  border-bottom: solid 2px #e6eaee;
  border-radius: 6px;
  padding: 4px;
}

.whatsappBoxHeader {
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  color: #143051;
  font-size: 18px;
  margin-bottom: 16px;
}

.closeWhatsappModalImg {
  max-width: 15px;
  cursor: pointer;
}

.closeWhatsappModalImg:hover {
  opacity: 0.8;
  /* padding: 1px; */
}

.addToContactsWarnTxt {
  /* max-width:210px; */
  font-size: 12px;
  text-align: left;
  max-width: 280px;
  color: #bbcada;
  padding-left: 20px;
  line-height: 16px;
  padding-bottom: 20px;
  /* padding-right:180px; */
}

.emailSpamBoldTitle {
  color: #00ccff;
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  max-width: 270px;
  padding-left: 20px;
  line-height: 16px;
  padding-bottom: 6px;
  margin-top: 18px;
}

.emailSpamEmailSpan {
  color: white;
  font-weight: 500;
}

.profileInitsBlank {
  max-width: 100px;
  min-width: 100px;
  min-height: 100px;
  max-width: 100px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
}

.profileImgProPicPlaceholder {
  min-width: 100%;
  max-width: 100%;
}

.profImgInits {
  font-weight: 500;
  color: white;
  margin-top: -82px;
  font-size: 48px;
  /* opacity: 0.6; */
}

.editmyProPic {
  background-color: white;
  margin-top: -64px;
  margin-left: -31px;
  margin-right: 5px;
  min-width: 25px;
  max-width: 25px;
  min-height: 25px;
  max-height: 25px;
  border-radius: 5px;
  border: #e3e8ee solid 2px;
  cursor: pointer;
}
.editmyProPic:hover {
  border-color: #b2bbc7;
}

.editProPicUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  animation-name: underlayFadeIn;
  animation-duration: 0.1s;
  cursor: pointer;
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.editProPicModalBox {
  background-color: #f4f8fe;
  min-width: 300px;
  max-width: 300px;
  /* height: 280px; */
  margin-top: -10vh;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: default;
  color: #143051;
  font-weight: 500;
  text-align: center;
    animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important;
 
}

.editProPicModalContentBox {
  background-color: #fff;
  max-width: 90%;
  min-width: 90%;
  /* padding-top: 40px; */
  border-radius: 5px 5px 0 0;
  border-top: solid 2px #e6eaee;
  border-right: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
  border-bottom: 1px solid #bac5d3;
  /* min-height:120px; */
  /* margin-bottom: 40px; */
}

.editProPicModalHeader {
  min-width: 90%;
  margin-left: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  padding-right: 55px;
  font-weight: 500;
}

.editProPicModalFooter {
  min-width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
}

.closeEditProPicModalIcon {
  max-width: 15px;
  cursor: pointer;
}

.closeEditProPicModalIcon:hover {
  opacity: 0.8;
}

.closeProPicModalBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
}

.removeProPicBtn {
  font-size: 14px;
  color: #fff;
  background-color: #cbd7e6;
  border: solid #bbcada 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
}

.proPicChangeBtnMyAcc {
  max-width: 220px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.waitForProPic {
  margin-top: 26px;
  margin-bottom: 26px;
  font-size: 14px;
  color: #bbcada;
  font-weight: 500;
}

.accSupportIcon {
  max-width: 6px;
  margin-right: 3px;
  margin-left: 7px;
}

.accAllowsBtn {
  max-width: 12px;
}

.companySettings {
  margin-top: 50px;
}

.compSettingsMob {
  font-size: 20px;
  margin-top: 20px;
  margin-left: 20px;
  max-width: 240px;
  text-align: left;
  line-height: 22px;
  min-height: 50px;
  padding-right: 62px;
  display: flex;
  align-items: flex-end;
  color: white;
  position: fixed;
  top: 5px;
  right: -40px;
}

.adminBurger2 {
  max-width: 12px;
  margin-right: 2px;
  margin-left: 11px;
}

.adminBurger {
  max-width: 12px;
  margin-right: 11px;
  margin-left: 2px;
}

.mobAdminMenuDiv {
  display: flex;
  justify-content: space-between;
  min-width: fit-content;
  max-width: fit-content;
  border: 2px solid #e6eaee;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
  padding-right: 8px;
  padding-left: 8px;
  position: fixed;
  top: 45px;
  left: 14px;
  max-height: 35px;
  min-height: 35px;
  cursor: pointer;
  background-color: white;
  flex-direction: row-reverse;
}

.mobAdminMenuDivActive {
  border-bottom: 2px solid white;
  border-radius: 5px 5px 0 0;
}


.mobAdminMenuDivActiveDash {
border-bottom: 1px solid #e0ebf8;
}

.mobAdminMenuDiv:hover {
  /* border-color: #bac5d3 */
}

.mobAdminPageTitle {
  max-width: fit-content;
  color: #143051;
  font-weight: 560;
  font-size: 14px;
}

.mobAdminOptions {
  min-height: 401px;
  min-width: 200px;
  background-color: white;
  max-width: 200px;
  position: fixed;
  top: 80px;
  left: 14px;
  z-index: 99999;
  border-radius: 0px 10px 5px 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  animation-name: cropDown;
  animation-duration: 0.2s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

.dashMenuDropper {
  border-radius: 10px 0px 5px 5px;
  top: 161px;
  right: calc(4vw + 2px);
  left: auto;
  min-height: 420px;
  z-index:9999999;
  min-width: 150px;
  animation-name: cropDown2;
  animation-duration: 0.2s;
}

@keyframes cropDown {
  from {
    min-height: 0px;
    max-height: 0; /* Start with max-height at 0 */
  }
  to {
    max-height: 401px; /* End with max-height at 200px */
    min-height: 401px;
  }
}

@keyframes cropDown2 {
  from {
    min-height: 0px;

    max-height: 0; /* Start with max-height at 0 */
  }
  to {
    max-height: 420px; /* End with max-height at 200px */
    min-height: 420px;
  }
}

.mobAdminOption {
  color: #214978;
  font-weight: 500;
  text-align: left;
  min-height: 44px;
  /* padding-right: 20px; */
  align-items: flex-start;
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  border-top: 1px solid #e0ebf8 !important;
  flex-direction: column;
  padding-left: 20px;
}

.mobDashMenuOption {
  text-align: right;
  align-items: flex-end;
  padding-right: 15px;
  padding-left: 0px !important;
  min-height:35px;
}

.mobAdminOptionSelected {
  color: #143051;
  font-weight: 500;
  border-left: 4px solid #0077ff;
  padding-left: 18px;
}

.mobAdminOptionSelectedDash {
  color: #143051;
  font-weight: 500;
  border-right: 4px solid #0077ff;
  padding-left: 18px;
  /* padding-bottom:5px; */
  min-height:35px !important;
  max-height:35px  !important
}

.bankHolMenuSub {
  font-size: 10px;
  opacity: 0.4;
}

.mobAdminOptionInclBankHol {
  font-size: 10px;
  margin-top: -2px;
  opacity: 0.8;
  margin: left 1px;
}

.generalNoBorder {
  border-top: none !important;
  min-height: 45px;
  padding-top: 5px;
  margin-top: -4px;
}

.detailsNoBorder {
  border-top: none !important;
  min-height: 32px;
  /* padding-top: 1px; */
  margin-top: -4px;
}

.closedDaysTimelineModalBoxMob {
  /* background-color:blue !important; */
  position: fixed;
  bottom: 0;
  border-radius: 10px 10px 0 0;
}

.closedDaysTimelineFooter {
  background-position-x: blue;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
}

.teamsYouManageMargin {
  margin-top: 12px;
}

.adminEditBtnImg {
  min-width: 28px;
  border-radius: 5px;
  border: #e3e8ee solid 2px;
  cursor: pointer;
  margin-left: 15px;
  background-color: #fff;
}

.adminEditBtnImg:hover {
  border-color: #b2bbc7;
}

.settingRowTitle {
  min-width: fit-content;
  margin-left: 15px;
  font-size: 14px;
  line-height: 17px;
  margin-top: 12px;
}

.editSettingToggledDiv {
  display: flex;
  align-items: center;
}

.mobSettingsToggler {
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  min-height: 77px;
}

.saveSettBtn {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  background-color: #0bf;
  border: 1px solid #0af;
  padding-left: 3px;
  padding-right: 3px;
  max-width: 56px;
  min-width: 56px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  max-height: 35px;
  margin-left: 15px;
}

.saveSettBtn:hover {
  background-color: #0af;
}

.saveSettBtnCanx {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  min-width: 66px;
  max-width: 66px;
}

.saveSettBtnCanx:hover {
  background-color: #bbcada;
}

.settingInputBox {
  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;
  color: #6a8bb1;
  min-height: 35px;
  padding-left: 9px;
  font-weight: 500;
  margin-left: 5%;
  min-width: 150px;
  font-size: 16px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 7px;
  background-color: #fdfeff;
}

.settingInputBoxMob {
  min-width: 190px;
  max-width: 190px;
}

@media only screen and (min-width: 930px) {
  .settingInputBox {
    min-width: 250px !important;
  }

  .editSettingToggledDiv2 {
    min-width: 320px !important;
    max-width: 320px !important;
    padding-right: 322px;
  }
}

.lonLatTitles {
  font-size: 12px;
  line-height: 16px;
  color: #143051;
  font-weight: 400;
  margin-left: 23px;
  padding-top: 18px;
  padding-right: 20px;
}

.gpsZoneRow {
  /* max-height: 80px; */
  border-top: solid 1px #e3e8ee;
  text-align: left;
  min-height: fit-content;
}

.lonLatVal {
  font-weight: 500;
  font-size: 18px;
  color: #143051;
  margin-left: 23px;
  margin-top: 8px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  max-width: 86%;
  align-items: center;
  line-height: 20px;
}

.smallZoneName2 {
  font-size: 14px;
}

.viewGpsZoneMapBtn {
  font-size: 14px;
  color: #fff !important;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  min-width: fit-content;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
}

.viewGpsZoneMapBtn:hover {
  background-color: #bbcada;
}

.mapImg {
  max-width: 14px;
  margin-left: 7px;
  opacity: 0.7;
}

.lonLatInputDiv {
  display: flex;
  align-items: center;
  margin-top: 6px;
  margin-bottom: 10px;
}

.latLonInput {
  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;
  color: #6a8bb1;
  min-height: 35px;
  padding-left: 9px;
  font-weight: 500;
  margin-left: 5%;
  min-width: 197px !important;
  max-width: 197px !important;

  font-size: 16px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 7px;
  background-color: #fdfeff;
}

.addUserTextInput {
  border: none;
  /* border-bottom: 2px solid #dde9f9; */
  /* background-color: #f4f8fe; */
  /* border-radius: 5px 5px 0 0; */
  color: #143051;
  min-height: 35px;
  padding-left: 9px;
  font-weight: 500;
  margin-left: 5%;
  min-width: 90% !important;
  font-size: 16px;
  padding-left: 7px;
  /* background-color: #f4f8fe; */
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 7px;
  background-color: #fdfeff;
}

.addUserNameInputs {
  min-width: 60% !important;
}

.addUserTextInput:focus {
  border: 2px solid #0af;
  border-radius: 5px;
}

.disableLonLat {
  opacity: 0.4;
  pointer-events: none;
}

.viewMapsAndSaveDiv {
  display: flex;
}

.saveGpsZoneBtn {
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  background-color: #0bf;
  border: 1px solid #0af;
  margin-right: 8px;
}

.saveGpsZoneBtn:hover {
  background-color: #0af;
}

.maxViewZoneModalWidth {
  max-width: 330px !important;
  min-width: 330px !important;
}

.adminPanelInfo {
  margin-left: 26px;
  font-size: 12px;
  text-align: left;
  color: #bbcada;
  font-weight: 400;
  max-width: 390px;
  line-height: 15px;
}

.adminBoxWidther {
  min-width: calc(100vw - 453px);
  max-width: calc(100vw - 453px);
}

@media screen and (min-width: 1100px) {
  .adminBoxWidther {
    min-width: 612px;
    max-width: 612px;
    /* background-color: lime; */
  }
}

@media screen and (min-width: 1800px) {
  .adminBoxWidther {
    min-width: 582px;
    max-width: 582px;
    /* background-color: lime; */
    margin-left: -20px;
  }
}

@media screen and (min-width: 1800px) {
  .adminBoxWidther {
    min-width: 582px;
    max-width: 552px;
    /* background-color: lime; */
    margin-left: 10px;
  }
}

.adminBoxWidtherMob {
  min-width: 98vw;
  max-width: 98vw;
}

.loadingUsersAdmin {
  margin-top: 40px;
}

.adminUserItem {
  background-color: white;
  min-width: 96%;
  max-width: 96%;
  margin-top: 10px;
  border: #e6e6e6 solid 1px;
  border-radius: 5px;
  min-height: 50px;
  margin-bottom: 7px;
  cursor: pointer;
  padding-bottom: 5px;
  /* box-shadow: 0px 0px 2px rgb(0 0 0 / 20%); */
  padding: 10px;
  margin-left: 2px;
}

@media screen and (min-width: 1026px) {
  .adminUserItem {
    max-width: 520px !important;
    min-width: 520px !important;
    margin-left: 10px;
  }
}

.teamAdminItem {
  min-width: 99%;
  max-width: 99%;
  margin-bottom: 13px !important;
  padding: 10px;
}

.adminUserItem:hover {
  border: #bbcada 1px solid;
  box-shadow: none;
  /* padding: 0px; */
  /* padding: 9px; */
}

.adminUserItemMob {
  min-width: 96%;
  max-width: 96%;
  margin-left: 2%;
  border-color: #e6e6e6;
  margin-bottom: 10px;
  border-radius: 10px;
}

.minWidth100vw {
  min-width: 100vw;
  max-width: 100vw;
}

.adminUserItemTop {
  min-width: 90%;
  display: flex;
  align-items: center;
  max-width: 96%;
  /* margin-left: 2%; */
  /* margin-top: 2%; */
}

.adminUserProfPic {
  max-width: 50px;
  min-width: 50px;
  max-height: 60px;
  min-height: 60px;
  object-fit: cover;
  border-radius: 5px;
  border: 2px solid #e6e6e6;
  /* margin-bottom: 2%; */
}

.adminUserProfPicMob {
  max-width: 50px;
  min-width: 50px;
  max-height: 60px;
  min-height: 60px;
  border: 1px solid #e6e6e6;
}

.adminUserInits {
  max-width: 50px;
  min-width: 50px;
  max-height: 60px;
  min-height: 60px;
  border: 2px solid #e6e6e6;
  border-radius: 5px;
}

.adminInitsPlaceholderImg {
  max-width: 50px;
  min-width: 50px;
  max-height: 60px;
  min-height: 60px;
  object-fit: cover;
  margin-left: -2px;
  padding-bottom: 8px;
  background: #516b8b;
  padding-top: 8px;
}

.adminUserInit {
  margin-top: -39px;
  font-weight: 500;
  font-size: 21px;
  color: white;
  opacity: 0.5;
  text-transform: uppercase;
}

.adminUserTopNameAndJt {
  text-align: left;
  font-weight: 500;
  color: #143051;
  margin-left: 8px;
  border-left: 2px solid #859dbb;
  padding-left: 9px;
  /* margin-top: -9px; */
}

.adminUserName {
  font-size: 14px;
  text-align: left;
  line-height: 18px;
  /* margin-left: 15px; */
}

.paddingLeft0 {
  padding-left: 0px !important;
}

.adminUserJt {
  font-size: 12px;
  color: #516b8b;
  font-weight: 500;
  margin-top: 1px;
}

.adminUserJTinfo {
  font-weight: 500;
  text-align: left;
  margin-left: 3%;
  font-size: 10px;
  color: #8ea7c5;
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
  line-height: 23px;
  margin-top: -2px;
}

.adminUserPrimTeamSpan {
  color: #859dbb;
  margin-left: 6px;
  background: #f4f8fe;
  border: 1px solid #dde9f9;
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 4px;
  opacity: 0.5;
  display: flex;
  align-items: center;
  max-height: 18px;
  margin-top: 3px;
  font-weight: 500;
}

.adminUserInlineDivider {
  min-width: 93%;
  min-height: 1px;
  background-color: #e6e6e6;
  margin-top: 3px;
  margin-bottom: 10px;
  max-width: 93%;
  margin-left: 3%;
}

.adminUsersFilterImg {
  position: absolute;
  bottom: 80px;
  right: 75px;
  background-color: #a387df;
  border: 1px solid #8e76c1;
  color: #fff;
  border-radius: 5px;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  line-height: 42px;
  font-size: 2.5em;
  z-index: 1000;
  cursor: pointer;
  opacity: 0.8;
  padding: 12px;
}

.adminUsersFilterImg:hover {
  background-color: #8e76c1;
  /* transform: scale(0.97); */
}

.adminUserAddBtn {
  position: absolute;
  bottom: 80px;
  right: 175px;
  background-color: #d287df;
  border: 1px solid #af76c1;
  color: #fff;
  border-radius: 5px;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  line-height: 42px;
  font-size: 2.5em;
  z-index: 1000;
  cursor: pointer;
  opacity: 0.8;
  padding: 12px;
}

.adminUserAddBtn:hover {
  background-color: #b888c7;
  /* transform: scale(0.97); */
}

.addminDisabledUsrsBtn {
  position: absolute;
  bottom: 80px;
  right: 226px;
  background-color: #8b5395;
  border: 1px solid #613f67;
  color: #fff;
  border-radius: 5px;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  line-height: 42px;
  font-size: 2.5em;
  z-index: 1000;
  cursor: pointer;
  opacity: 0.8;
  padding: 7px;
}

.addminDisabledUsrsBtn:hover {
  background-color: #764e7c;
}

.addminDisabledUsrsBtnMob {
  position: fixed;
  bottom: 298px;
  right: 30px;
  min-width: 50px;
  min-height: 50px;
  padding: 13px;
}

.addUserImgMob {
  position: fixed;
  bottom: 239px;
  right: 30px;
  min-width: 50px;
  min-height: 50px;
  padding: 16px;
}
.sortImg {
  right: 125px;
}

.usersSortBox {
  min-width: fit-content;
  min-height: 286px;
  max-height: 286px;

  /* background-color: #f9f6ff; */
  position: absolute;
  bottom: 80px;
  right: 125px;
  /* border: 1px solid #e6e0f3; */
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 7px;
  padding-top: 1px;
  background-color: #e6def6;
  border: 1px solid #d1cbdf;
  animation-name: slideSortBoxUp !important;
  animation-duration: 0.1s !important;
}

@keyframes slideSortBoxUp {
  from {
    min-height: 40px;
    max-height: 40px;
  }
  to {
    min-height: 286px;
    max-height: 286px;
  }
}

.flexRow {
  flex-direction: row !important;
  align-items: center;
  justify-content: flex-start;
}

.employeeCount {
  margin-left: 9px;
  opacity: 0.5;
  border-left: 1px solid white;
  padding-left: 8px;
}

.sortBoxTitle {
  font-weight: 500;
  color: #af99da;
  text-align: left;
  margin-left: 0px;
  margin-top: 9px;
  margin-right: 3px;
  font-size: 14px;
  margin-bottom: 0px;
  /* border-right: 2px solid #af99da; */
  /* padding-right: 7px; */
}

.sortBy {
  background-color: white;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  min-height: 35px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #9287a7;
  border: 2px solid #dcd8ee;
  border-radius: 5px;
  font-size: 14px;
  margin-left: 8px;
  cursor: pointer;
}

.sortBy:hover {
  border-color: #d1bedd;
}

.closeSort {
  width: 20px;
  margin-top: 11px;
  cursor: pointer;
  margin-right: 4px;
  padding: 2px;
}

.closeSort:hover {
  opacity: 0.8;
  transform: scale(0.97);
}

.preloadCloseSort {
  visibility: hidden;
  max-width: 0px;
  max-height: 0px;
  position: fixed;
  top: 0;
  left: 0;
}

.sortBySel {
  background: #caacff;
  color: white;
  border-color: #957eb5;
}

.sortBySel:hover {
  border-color: #6f5496;
}

.filterImgMob {
  position: fixed;
  bottom: 120px;
  right: 30px;
  min-width: 50px;
  min-height: 50px;
  padding: 12px;
}

.filterImgMobSort {
  position: fixed;
  bottom: 180px;
  right: 30px;
  min-width: 50px;
  min-height: 50px;
  padding: 12px;
}

.usersSortBoxMob {
  position: fixed;
  bottom: 183px;
  right: 30px;
}

.sortByInline {
  max-width: 14px;
  margin-left: 4px;
}

.adminMenuUnderlay {
  min-width: 100vw;
  min-height: 100vh;
  border-radius:15px;
  background-color: rgb(0 0 0 / 15%);
  position: fixed;
  z-index: 99;
}

.adminMenuUnderlayDash {
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgb(0 0 0 / 4%);
  position: fixed;
  z-index: 99;
  top: 0; left: 0
}

.findUserToggler {
  background-color: #e6def6;
  border: 1px solid #d1cbdf;
  color: white;
  position: absolute;
  bottom: 80px;
  right: 75px;
  min-height: 40px;
  min-width: 300px;
  max-width: 300px;

  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  animation-name: slideTogglerIn !important;
  animation-duration: 0.1s !important;
}

@keyframes slideTogglerIn {
  from {
    min-width: 0px;
    max-width: 0px;

    /* bottom: -10%; */
    /* opacity: 1; */
    /* transform: scale(0.85); */
  }
  to {
    min-width: 300px;
    max-width: 300px;

    /* bottom: 0; */
    /* opacity: 1; */
    /* transform: scale(1); */
  }
}

.findUserTogglerMob {
  /* right:8px; */
  /* top: 94px; */
  border-radius: 5px 15px 0 0;
  min-height: 50px;
  max-height: 50px;
  /* min-width: calc(100% - 16px); */
}

.closeFindToggleImg {
  width: 18px;
  margin-right: 10px;
  cursor: pointer;
  padding: 2px;
}

.closeFindToggleImg:hover {
  opacity: 0.75;
}

.findValueInput {
  background-color: #ffffff;
  border-radius: 4px;
  margin-left: 4px;
  min-height: 32px;
  min-width: 157px;
  font-size: 13px;
  padding-left: 8px;
  color: #143051;
  font-weight: 500;
  border: 1px solid #50405e;
}

.findValueInputMob {
  min-height: 40px;
  min-width: 257px;
}

.findValueInput::placeholder {
  color: #ab9bcd;
  /* font-size:14px; */
}

.adminUserJoin {
  font-weight: 400;
  font-size: 10px;
  color: #bbcada;
}

.adminUserAnnLeaveValspan {
  margin-left: 6px;
  color: #0af;
  font-weight: 500;
}

.adminUserDayMins {
  color: #859dbb;
  margin-left: 6px;
  background: #f4f8fe;
  border: 1px solid #dde9f9;
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 4px;
  /* opacity: 0.5; */
  display: flex;
  align-items: center;
  max-height: 18px;
  margin-top: 2px;
  font-weight: 500;
}

.addUserMobMmodal {
  min-width: 100vw !important;
  max-width: 100vw !important;
  position: fixed;
  bottom: 0;
  left: 0;
  animation-name: moveEditTeamUserBoxUp !important;
  animation-duration: 0.1s !important;
}

.addUserDate {
  padding-right: 8px;

  background: url(/static/media/calicongrey.9c7e1aba.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 130px;

  cursor: text;

  background-color: #f4f8fe;
  border-radius: 3px;
  margin-left: 5%;
  min-height: 35px;
  min-width: 167px;
  font-size: 16px;
  padding-left: 8px;
  color: #6a8bb1;
  font-weight: 500;
  border: none;
  border-bottom: 2px solid #dde9f9;
  border-left: #f4f8fe 2px solid;
  border-right: #f4f8fe 2px solid;
  border-top: #f4f8fe 2px solid;

  margin-bottom: 5px;
  margin-top: 4px;
}

.addUserDate:focus {
  border: 2px solid #0af;
  border-radius: 5px;
}

.opac05 {
  color: #b1c8e7;
}

.addUserTeamDropdown {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 140px;
  min-width: 140px;
  line-height: 26px;
  height: 35px;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 2px solid #dde9f9;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 116px;
  margin-left: 5%;
  margin-top: 4px;
  margin-bottom: 18px;
}

.addUserTeamDropdownHover:hover {
  border-color: #0af;
}

.pTeamDrop {
  background-position-x: 215px;
  max-width: 240px;
  min-width: 240px;
}

.adminAddUserExplainer {
  color: #bbcada;
  margin-left: 25px;
  font-size: 12px;
  padding-right: 20px;
  margin-top: 6px;
}

.invalidAddUser {
  pointer-events: none;
  opacity: 0.2;
}

.invalidAddUserEmailInput {
  border: 2px solid #cfa8a8;
  border-radius: 5px;
}

.addUserHistNumInput {
  max-width: 50px !important;
  min-width: 50px !important;
}

.historyMinsAddUserDiv {
  margin-left: 10px;
  margin-top: 24px;
  margin-bottom: 20px;
}
.addUserHistHoursTxt {
  font-weight: 500;
  font-size: 14px;
  margin-left: 6px;
  color: #859dbb;
  margin-top: 14px;
}



.adminAddUserLargerTitle {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.addUserScroll {
  max-height: 550px !important;
  overflow-y: auto;
  max-height: 457px !important;
  min-height: 457px !important;
}

.adminAddUserSubmitColours {
  background-color: #07f;
  border: #0469de 1px solid;
}

.adminAddUserSubmitColours:hover {
  background-color: #036ce4;
}

.addUserBackImg {
  max-width: 15px;
  margin-left: 5px;
}

.addUserBackBtn {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  min-width: 35px;
  min-height: 35px;
  border-radius: 5px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.addUserBackBtnDesktop:hover {
  background-color: #bbcada;
}

.addUserPageInfo {
  color: #214978;
  margin-left: 15px;
  font-size: 14px;
  padding-right: 20px;
  text-align: left;
  margin-top: 22px;
  margin-bottom: 16px;
  font-weight: 400;
  /* border-left: 2px solid #00ccff; */
  /* padding-left: 10px; */
}



.adminAddUserAddToOtherTeam {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  font-weight: 500;
  font-size: 12px;
  color: white;
  min-height: 25px;
  max-height: 25px;
  border-radius: 5px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 27px;
}

.adminAddUserAddToOtherTeam:hover {
  background-color: #bbcada;
}

.archivedAdminUserItem {
  background-color: #fff;
}

.archivedAdminUserItem:hover {
  /* border-color: #214978; */
  /* opacity: 0.95; */
}

/* .showDisableGoBack { */
/* min-width: 50px; */
/* padding-bottom: 20px; */
/* padding: 12px !important;} */

.adminInitsRemovedHeight {
  max-height: 40px !important;
  min-height: 40px !important;
  max-width: 40px !important;
  min-width: 40px !important;
}

.adminInitsRemovedHeightImg {
  max-height: 40px !important;
  min-height: 40px !important;
  max-width: 40px !important;
  min-width: 40px !important;
  margin-left: -2px;
  /* margin-top: -2px; */
}

.removedInits {
  margin-top: -38px;
}

.adminUsersMobDivider {
  min-height: 1px;
  background-color: #bbcada;
  min-width: 100%;
  margin-top: -10px;
}

.pullArchNameUp {
  margin-top: -3px;
}

.disabledQty {
  color: #e2c3fe;
  position: absolute;
  bottom: 82px;
  right: 230px;
  font-weight: 500;
  z-index: 99999;
  font-size: 12px;
  text-align: right;
  cursor: pointer;
}

.disabledQtyMob {
  bottom: 219px;
  right: 36px;
}

.grayscale {
  filter: grayscale(100%);
  padding-top: 2px;
}

.adminTeamUnit {
  text-align: left;
  font-weight: 500;
  color: #143051;
  margin-left: 8px;
  /* border-left: 2px solid #859dbb; */
  /* padding-left: 9px; */
}

.adminTeamUsersArr {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  align-items: space-between;
  margin-top: 12px;
}

.adminTeamUsr {
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 3px;
  font-weight: 500;
  margin-bottom: 5px;
  margin-right: 5px;
  font-size: 12px;
  border: 1px solid #e0eefe;
  color: #b4bdd8;
}

.adminTeamsUsrTitle {
  font-size: 14px;
  color: #859dbb;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 0px;
  margin-left: 3px;
  display: flex;
  align-items: center;
}

.adminTeamUsrTitleAndUsers {
  /* border-left: 2px solid #bbcada; */
  /* padding-left: 5px; */
  margin-top: 6px;
  margin-bottom: 13px;
}

.adminTeamName {
  margin-left: 2px;
  margin-top: 4px;
  font-size: 18px;
  border-left: 3px solid #143051;
  padding-left: 8px;
}

.teamMgrUsrIcon {
  max-width: 15px;
  margin-right: 4px;
}

.teamNameOver20 {
  font-size: 16px !important;
}

.teamNameOver26 {
  font-size: 14px !important;
}

.editTeamNameImg {
  cursor: pointer;
  min-width: 28px;
  border-radius: 5px;
  border: #e3e8ee solid 2px;
  margin-left: 8px;
}

.editTeamNameImgCanx {
  cursor: pointer;
  border: #e3e8ee solid 2px;
  padding: 6px;
  border-radius: 5px;
  max-width: 28px;
  margin-left: 8px;
}

.adminTeamNameTitle {
  margin-top: 0px;
  font-size: 18px;
  font-weight: 500;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.saveAdminTeamName {
  margin-right: 0px !important;
  margin-left: 8px;
}

.adminTeamNameInput {
  color: #6a8bb1;
  min-height: 40px;
  padding-left: 9px;
  font-weight: 500;
  margin-left: 5%;
  /* min-width: 320px; */
  /* margin-top: 5px; */
  /* margin-bottom: 3px; */
  font-size: 16px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 7px;
  background-color: #fdfeff;
  /* margin-bottom:10px; */
  /* margin-top:10px; */
}

.addAdminTeamNameInput {
  margin-bottom: 10px;
  margin-top: 10px;
  min-width: 300px;
}

.adminTeamModalBody {
  background-color: white;
}

.adminTeamStat {
  background-color: #214978;
  color: white;
  min-height: 60px;
  min-width: 60px;
  max-width: 60px;
  max-height: 60px;
  border-radius: 5px;
  border: 2px solid #143051;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
}

.adminTeamStatMgrs {
  background-color: #a387df;
  border-color: #58467e;
  margin-bottom: 8px;
  margin-top: 20px;
}

.adminTeamStatNum {
  font-weight: 500;
  font-size: 28px;
  line-height: 28px;
}

.adminTeamStatVal {
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  margin-top: 2px;
}

.adminTeamRowTitle {
  font-size: 16px;
  color: #214978;
  font-weight: 500;
  display: flex;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-left: 14px;
}

.adminTeamUsrRow {
  margin-left: 13px;
  margin-top: 8px;
  margin-bottom: 40px;
}

.teamAdminUsrItem {
  border-top: 1px solid #bbcada;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: calc(100% - 13px);
  min-height: 50px;
  font-size: 14px;
  font-weight: 500;
  color: #8ea7c5;
  padding-bottom: 6px;
  padding-top: 6px;
}

.removeUsrFromTeamImg {
  cursor: pointer;
  max-width: 12px;
  opacity: 0.3;
}

.removeUsrFromTeamImg:hover {
  opacity: 0.5;
}

.adminTeamMgrTxt {
  border-left: 2px solid #a387df;
  padding-left: 7px;
  padding-right: 8px;
  cursor: pointer;
  color: #214978;
  /* font-weight:600 */
}

.adminTeamMgrTxtHover:hover {
  color: #6f85a2;
}

.adminTeamUsrTxt {
  border-left: 2px solid #00ccff;
  padding-left: 7px;
  padding-right: 8px;
  cursor: pointer;
  color: #214978;
}

.adminTeamUsrTxt:hover {
  /* color: #6f85a2; */
}

.adminTeamNameInputoTeamDiv {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}
.adminTeamAddUsrDropdown {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 10px;
  text-align: left !important;
  max-width: 180px;
  min-width: 180px;
  line-height: 26px;
  height: 35px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border-color: #2c7eb9;
  border: 2px solid #7f59cf;
  background-color: white;
  color: #214978;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 156px;
  margin-left: 12px;
  margin-top: 10px;
  /* float:right; */
}

.addUsrToTeamBorder {
  border-color: #0077ff;
}

.teamsUsrCount {
  margin-left: 7px;
  font-size: 11px;
  color: #859dbb;
  font-weight: 400;
  padding-left: 8px;
  border-left: 1px solid #bbcada;
  /* border: solid 1px #bbcada;
  background-color: #cbd7e6;
  padding-left:4px;
  padding-right:4px;
  border-radius:4px;
  font-size:12px;
color: white; */
}

.teamAdminLeft {
  background-color: white;
  text-align: left;
  max-width: 75%;
  min-width: 75%;
  padding-right: 3px;
  padding-top: 15px;
  min-height: 410px;
  max-height: 410px;
  overflow-y: auto;
  border-top: solid 2px #e6eaee;
  border-right: solid 1px #e6eaee;
  border-left: solid 1px #e6eaee;
  padding-bottom: 30px;
  border-radius: 5px 5px 0px 0px;
  -webkit-font-smoothing: antialiased;
  /* margin-bottom: -8px; */
  margin-left: -2px;
}

.teamAdminLeftMob {
  min-height: 490px;
  max-height: 490px;
}

.adminTeamTitleBar {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.addMgrTeam {
  background-color: #0077ff;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 4px;
  border: 1px solid #0469de;
  font-weight: 500;
  font-size: 12px;
  padding: 2px;
  min-height: 28px;
  padding-left: 7px;
  padding-right: 7px;
  margin-right: 10px;
  cursor: pointer;
}

.addMgrTeamMgr {
  background-color: #9b6cff;
  border: 1px #7f59cf solid;
}

.addMgrTeam:hover {
  background-color: #036ce4;
}

.addMgrTeamMgr:hover {
  background-color: #7f59cf !important;
}

.addMgrWhiteAddImg {
  max-width: 10px;
  margin-left: 5px;
}

.canxAddToTeam {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
}

.canxAddToTeam:hover {
  background-color: #bbcada !important;
}

.noUsersTeamTxt {
  font-weight: 500;
  text-align: left;
  font-size: 12px;
  color: #bbcada;
  margin-top: 10px;
  margin-left: 2px;
  margin-bottom: 40px;
}

.numberOfMgrsWhoAreNotMbrs {
  margin-bottom: 8px;
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  padding-bottom: 4px;
  line-height: 12px;
  padding-top: 5px;
  background-color: #ffffff;
  border-color: #859dbb;
  color: #859dbb;
  cursor: pointer;
}

.nonMbrHover:hover {
  border-color: #496375;
}

.mgrsNonMbrsLine {
  background-color: #685086;
  min-width: 4px;
  min-height: 6px;
  margin-left: 15px;
  margin-top: -8px;
}

.adminTeamFilterKnob {
  /* background-color: blue !important; */
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 20px;
  padding-bottom: 12px;
  padding-left: 5px;
  margin-top: 8px;
  margin-bottom: 2px;
}

.filterMgrNotUsr {
  background-color: #8200ff;
  border-color: #58467e;
  color: white !important;
}

.adminTeamRowTitleBorder {
  padding-right: 70px;
  padding-left: 8px;
  border-left: 5px solid #a387df;
  color: #6c4daf;
  font-size: 14px;
  line-height: 14px;
}

.adminTeamSelfApproveTxt {
  max-width: fit-content;
  margin-left: 0px;
  border-left: 2px solid #a387df;
  padding: 2px;

  padding-left: 8px;
  font-size: 11px;
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 2px;
  color: #0af;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-right: 4px;
  /* background-color: pink; */
  min-height: 23px;
}

.adminTeamSelfApproveTxtNonMob:hover {
  background-color: #f2f8ff;
  border-radius: 0 3px 3px 0;
}
.selfAppHover:hover {
  /* color: #07f; */
}

.allowSelfApproveTick {
  background-color: #fff;
  border: 1px solid #dde9f9;
  border-radius: 2px;
  padding: 2px;
  max-width: 15px;
  max-height: 16px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-right: 5px;
}

.selfAppInActive {
  color: #bbcada;
}

.selfAppInActiveHover:hover {
  /* color: #859dbb */
}

.stillMgrSub {
  font-size: 14px;
  color: #516b8b;
  line-height: 18px;
  margin-top: -30px;
  padding-right: 20px;
  padding-left: 10px;
  font-weight: 400;
}

.adminTeamFooter {
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  display: flex;
  justify-content: space-between;
  /* border-top: 1px solid #bac5d3; */
  align-items: center;
  margin-bottom: -14px;
  min-height: 50px;
}

.teamsAddBtn {
  bottom: 80px;
  right: 75px;
}

.teamsAddBtnMob {
  bottom: 120px;
  right: 30px;
}

.accountSettingsProfileRowMob {
  border-radius: 14px;
}

.profileRowProPicImgMob {
  border-radius: 10px 5px 5px 10px;
}

.progessierSubBtn {
  background-color: #294566;
  border: 2px solid #294566;
  min-width: -moz-fit-content;
  min-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-size: 13px;
  font-weight: 500;
  /* padding-left: 8px; */
  /* padding-right: 8px; */
  min-height: 35px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-left: 110px;
}

.enablePushDiv {
  min-width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 11px;
}

.noNotifsSize {
  min-height: 110px;
  max-height: 110px;
}

.myAccTitleDesktop {
  margin-top: -15px !important;
  margin-bottom: -6px;
}

.adminHeaderHeightNonMob {
  min-height: 117px;
}

.x8281 {
  margin-top: 0px;
}

.lastUsrNotifsRow {
  border-radius: 0px 0px 0px 10px;
  border-bottom: 2px solid #00ccff;
}

.lastMgrNotifsRow {
  border-radius: 0px 0px 0px 10px;
  border-left: 2px solid #de82f7;
}

.mgrNotifRow {
  border-left: 2px solid #de82f7;
}
.lastMgrNotifsRow {
  border-radius: 0px 0px 0px 10px;
  border-bottom: 2px solid #de82f7;
}

.adminTeamHeaderSettingsRow {
  /* background-color: yellow; */
  min-height: 100px;
  border-bottom: 1px solid #ddebf4;
  min-width: 100%;
  padding-left: 2%;
  padding-right: 2%;
}

.teamAdminSettingsRowPadding {
  padding-left: 5%;
  padding-right: 5%;
}

.x1974274 {
  background-color: white !important;
  max-width: 97% !important;
  min-width: 97% !important;
  margin-left: 0%;
  margin-top: 12px !important;
}

.teamsAdminHeight {
  min-height: calc(100vh - 187px) !important;
  max-height: calc(100vh - 187px) !important;
}

.viewTeamUserProfPic {
  max-width: 24px;
  max-height: 36px;
  min-width: 24px;
  min-height: 36px;
  object-fit: cover;
  border-radius: 3px;
  margin-top: 2px;
  margin-right: 6px;
}

.proPicPlaceholderViewTeam {
  max-width: 24px;
  max-height: 36px;
  min-width: 24px;
  min-height: 36px;
  object-fit: cover;
  border-radius: 3px;
  margin-top: 2px;
  background-color: #214978;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  font-size:11px
}

.x13981971 {
  display: flex;
  align-items: flex-start;
}

.editTeamPenIcon {
  max-width: 22px;
  margin-left: 4px;
  margin-right: -7px;
}

.x1344222 {
  padding-left: 10px;
  background-color: #b49ce7 !important;
  border: 1px solid #a47fd0 !important;
}

.x1344222:hover {
  background-color: #a58fd4 !important;
}

.notifTitleBtnTxt {
  font-size: 10px;
  font-weight: 500;
}

.pushEmailRadio {
  max-width: 32px;
  min-width: 32px;
  max-height: 32px;
  min-height: 32px;
  background-color: white;
  border-radius: 3px;
  font-size: flex;
  align-items: center;
  padding-top: 7px;
  margin-top: 8px;
  cursor: pointer;
  justify-content: center;
  opacity: 0.5;
}

.pushEmailTickImg {
  max-width: 12px;

}

.activeNotifImg {
  opacity: 1;
}

.viewTeamUsrJt {
  color: #bbcada;
  font-size: 10px;
  margin-left: 10px;
}

.viewTeamUsrJt2 {
  color: #516b8b;
  font-size: 10px;
  margin-left: 40px;
  margin-top: -21px;
  margin-bottom: 9px;
}

.x53263 {
  margin-left: 0px;
  margin-bottom: -1px;
}

.myAllowsHeaderLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* margin-top:-8px; */
  margin-left: 4px;
  /* background-color: blue; */
  min-width: calc(100% - 120px);
  min-height: 50px;
  padding-top: 10px;
  margin-top: -20px;
}

.x13211231 {
  margin-top: -4px !important;
}

.x23982983 {
  margin-top: 1px;
}

.hideProfile {
  overflow: hidden !important;
  visibility: hidden;
}

.loadingProfileBallsImg {
  max-width: 120px;
  margin-top: 30px;
}

.primaryAccTeam {
  background-color: #fff;
  color: #496375;
  border: 2px solid #214978 !important;
}

.primaryAccTeam:hover {
  border: 2px solid #143051 !important;
}

.isPrimSpan {
  font-weight: 500;
  font-size: 12px;
  color: #0af;
  border-right: 2px solid #00aaff;
  padding-right: 5px;
}

.x13812214 {
  flex-direction: column;
  align-items: flex-end;
}

.x1212131 {
  border: none;
  padding: 0px;
  color: #bbcada;
  margin-top: -2px;
  margin-bottom: -4px;
}

.teamSwapSettings {
  background-color: white;
  border: 2px solid #bbcada;
  border-radius: 5px;
  min-height: 30px;
  min-width: 60px;
  margin-left: 15px;
  max-width: 60px;
  margin-top: 10px;
  display: flex;
  cursor: pointer;
}

.teamSwapSettings:hover {
  border-color: #a4b3c3;
}

.swapSettingsBtn {
  max-width: 13px;
  margin-left: 1px;
  margin-right: 3px;
}

.swapSettTxt {
  font-size: 11px;
  font-weight: 500;
  text-align: left;
  line-height: 10px;
  margin-top: 8px;
  color: #bac5d3;
  margin-left: 5px;
}

.x139812 {
  max-width: 10px;
  margin-left: 3px;
  opacity: 0.2;
}

.teamSwapRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  /* border-bottom: 1px solid #bbcada; */
  padding-bottom: 10px;
  padding-top: 10px;
  max-width: 90%;
  margin-left: 5%;
  cursor: pointer;
}

.teamSwapRowTop {
  margin-top: 10px;
  border-bottom: 1px solid #bbcada;
}

.teamSwapRowDesktop:hover {
  background-color: #edf3f9;
  cursor: pointer;
}

.teamSwapRowTitle {
  max-width: 130px;
  min-width: 130px;

  font-size: 14px;

  margin-left: 9px;
}

/* fdfaff */
.clickShiftRowAdmin {
  cursor: pointer;
}

.clickShiftRowAdmin:hover {
  background-color: #fdfaff;
}

.mobileAdminRow {
  padding-left: 6px;
  padding-right: 20px;
  min-width: 94%;
  max-width: 94%;
  margin-left: 3.5%;
}

.mobileAdminRow:hover {
  background-color: #fff !important;
}

.adminRowNoSubTxt {
  padding-bottom: 15px;
}

.knobContainerSettings {
  margin-top: 10px !important;
  margin-left: 12px;
}

.pinSpan {
  font-weight: 500;
}

.CompanyClosed {
  margin-top: -7px !important;
}

.readMoreHere {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  color: white !important;
  border-radius: 5px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: 500;
  /* margin-top: 10px; */
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #cbd7e6;    */
  cursor: pointer;
  min-height: 30px;
  float: left;
}

.readMoreHere:hover {
  background-color: #bbcada;
}

.adminUserKeyBar {
  border-right: 1px solid #143051;
  background-color: #143051;
  min-height: 40px;
  min-width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
}

.adminUserKeyBarMob {
  /* border-radius: 15px 15px 0 0; */
  /* min-width: 100%; */
  background-color: #ebf0f7;
  min-width: calc(100vw + 4px) !important;
  max-width: calc(100vw + 4px) !important;
  margin-left: -2px;
  border-radius: 20px 20px 0 0;
  border-top: 2px solid #e6eaee;
  border-left: 2px solid #e6eaee;
  border-right: 2px solid #e6eaee;
  min-height: 50px;
  padding-left:10px;
}
.adminUsersParent {
  /* background-color: #bbcada; */
  /* border-right:1px solid black */
}

.adminUsersBoxHeight {
  min-height: calc(100vh - 187px);
  max-height: calc(100vh - 187px);
}

.adminUsersBoxHeightMob {
  min-height: calc(100vh - 222px);
  max-height: calc(100vh - 222px);
  border-radius: 0;
  border-top: none;
}

.adminAddUsrBtn {
  background-color: #fff;
  border: 1px solid #143051;
  color: #214978;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 8px;
  cursor: pointer;

}

.adminAddUsrBtnMob {
  border-radius: 10px 4px 4px 4px;
  font-size: 13px;
  background-color: white;
  background: none;
  color: #504588;
  border-color: #504588;
}

.adminAddUsrBtnMob:hover {
  background: none !important;
}

.adminUsrSearchBtn {
  border-radius: 4px;
  margin-left: 5px;
  background-color: #a799cb;
  border: 1px solid #8e76c1;
  color: white;
}

.adminUsrSearchBtn:hover {
  background-color: #a799cb !important;
}

.adminAddUsrBtn:hover {
  background-color: #e0ebf8;
}

.adminUsrSearchBtn:hover {
  background-color: #9d8dc6;
}
.addNewEmpIcon {
  max-width: 12px;
  margin-left: 4px;
  margin-right: 2px;
}

@media screen and (min-width: 820px) {
  .addNewEmpIcon {
    max-width: 20px;
    margin-left: 0px;
    margin-right: -4px;
  }

  .tilWkDrop:hover {
    border-color:#bbcada
  }
}
.addNewEmpIcon2 {
  max-width: 11px;
  margin-left: 4px;
  margin-right: 2px;
}

.showUsersTopBorder {
  border-top: 1px solid #bbcada;
}

.archiveAdminUsersBtn {
  float: right;
}

.adminUserKeyLeft {
  display: flex;
}
.adminUserKeyRight {
  display: flex;
}

.usersArchiveRadius {
  border: solid 1px #5e5573;
  color:white;
  background-color: #a17db0;
}

.usersArchiveRadiusMob {
  border-radius: 4px 10px 4px 4px;
  border: solid 1px #504588;
  background: none;
  color: #504588;
}

.usersArchiveRadius:hover {
  background-color: #93779f;
}

.usersArchiveRadiusMob:hover {
  background: none;
}
.x23089472 {
  border-radius: 10px 4px 4px 4px;
  margin-left: 10px;
}

.x239894 {
  max-width: 8px;
  margin-left: 7px;
}

.x293847872 {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
}

.x293847872:hover {
  background-color: #bbcada;
}

.x1383747 {
  min-height: calc(100vh - 222px);
  max-height: calc(100vh - 222px);
}

.adminTeamSettingsContainer {
  min-height: 100px;
  background-color: white;
  padding-left: 10px;
}

.x183973741 {
  padding-bottom: 20px;
  cursor: pointer;
}

.x1314213433 {
  padding-bottom: 20px;
  cursor: pointer;
}

.x1314213433:hover {
  background-color: #fdfaff !important;
  /* background-color: blue !important; */
  border-radius: 5px;
}

.mgrCanEditKnobContainer {
  margin-top: 10px;
}

.historySpecDaysBtn {
  font-size: 13px;
  color: #fff;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  line-height: 26px;
  border-radius: 5px;
  min-height: 30px;
  margin-right: 5%;
  max-width: 100px;
  text-align: center;
  margin-top: 18px;
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.historySpecDaysBtnDesktop:hover {
  background-color: #bbcada;
}

.historicalCheckBoxInstallImg {
  max-width: 13px;
  margin-left: 6px;
}

.addSpecDayFooter {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}
.closeAddSpecDayBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 6px;
  padding-right: 6px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  max-width: 59px;
  min-width: 59px;
}

.specDayEditKnob {
  margin-top: -18px;
}

.x249898233 {
  min-height: 50px;
  margin-bottom: 2px;
  margin-top: -5px;
  padding-right: 6px;
}

.x298492812 {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  margin-right: 0px;
  margin-top: 5px;
  background-position-x: 60px;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  padding-left: 12px;
  padding-right: 0px;
  min-width: 81px;
  max-width: 81px;
  margin-top:50px;
  margin-right:25px;
  /* margin-left: 167px; */

}

.x298492812:hover {
  border-color: #bbcada;
}

.x240893823 {
  margin-top: -20px;
  margin-right: 30px;}

.x248984234 {
  margin-top: -10px;
}

.specDayDsEdit {
  border-radius: 5px;
  border: none;
  background-color: #fff;
  color: #496375;
  min-height: 35px;
  line-height: 35px;
  font-size: 16px !important;
  padding-left: 14px;
  padding-right: 8px;
  letter-spacing: 1px;
  padding-top: 1px;
  font-weight: 500;
  max-width: 174px;
  min-width: 174px;
  background: url(/static/media/calicongrey.9c7e1aba.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 142px;
  border: 2px solid #dde9f9;
  margin-left: 15px;
  cursor: text;
  margin-right: 23px;
}

.annLeaveSubTxt {
  color: #8997a8;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  max-width: 210px;
  margin-top: 20px;
}

.mobIllusTable {
  margin-top: 50px;
}

.mobAnnLeavSelector {
  margin-top: 27px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.x198348927133 {
  margin-top: -30px;
}

.x289492894892 {
  margin-top: 5px;
}

.mobAnnLeaveContainer {
  margin-top: -27px;
}

.x2989423 {
  margin-top: 20px;
  /* background-color: blue; */
}

.x2484882 {
  font-size: 12px;
  margin-top: 2px;
}

.customLeaveTypeUnit {
  min-height: 62px;
}

.customLeaveTypeSubTxt {
  margin-top: -7px;
  text-align: left;
  padding-left: 4px;
  border-top: 2px solid #dde9f9;
  max-height: 30px;
  line-height: 23px;
  margin-left: 9px;
  font-size: 11px;
  min-width: 160px;
  color: #859dbb;
  font-weight: 500;
}

.addTypePlusImg {
  margin-right: 4px;
  margin-left: 5px;
  max-width: 10px;
}

.showRemovedTypes {
  font-size: 12px;
  color: #fff;
  background-color: #a387df;
  border: 1px solid #8e76c1;
  font-weight: 500;
  cursor: pointer;
  padding-left: 11px;
  padding-right: 11px;
  max-height: 35px;
  border-radius: 5px;
  min-height: 35px;
  max-width: fit-content;
  display: flex;
  align-items: center;
  margin-right: 30px;
  float: right;
  margin-bottom: 10px;
  margin-top: 45px;
}

.showRemovedTypes:hover {
  background-color: #997ed4 !important;
}

.showRemovedTypesDivider {
  min-width: 100%;
  min-height: 2px;
  background-color: #214978;
  margin-top: 10px;
}

.removedTypeTxt {
  border: 2px solid #dde9f9;
  max-height: 20px;
  margin-top: 22px;
  border-radius: 3px;
  margin-right: 14px;
  line-height: 16px;
  font-size: 10px;
  padding-left: 3px;
  padding-right: 5px;
  font-weight: 500;
  background: white;
  color: #516b8b;
}

.customAllowFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
  min-height: 35px;
  margin-bottom: 10px;
}

.customAllowInputParent {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.customAllowInputter {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  margin-right: 0px;
  margin-top: 4px;
  background-position-x: 60px;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  padding-left: 12px;
  padding-right: 0px;
  min-width: 55px;
  max-width: 55px;
  min-height: 35px;
  margin-left: 5px;
  margin-right: 5px;
  /* margin-bottom:-8px */
}

.customAllowInputter:hover {
  border-color: #bbcada;
}

.customAddDeductImg {
  max-width: 28px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: -5px;
  margin-right: 4px;
}

.customAddDeductImg:hover {
  border-color: #bbcada;
}
.x1118383 {
  opacity: 0.4;
  pointer-events: none;
  border-color: #dde9f9;
}

.x289389389 {
  border-color: #214978;
}

.x2892989 {
  margin-left: 0px;
  margin-top: 0px;
  margin-right: 3px;
}

.x88292 {
  opacity: 0;
  pointer-events: none;
}

.unlim {
  color: #c7d5e9;
  font-weight: 500;
  margin-left: 23px;
  min-height: 39px;
  padding-top: 13px;
  font-size: 14px;
  min-height: 66px;
}

.customAllowWithDaysOrHours {
  /* background-color: lightblue; */
}

.daysOrHoursDiv {
  display: flex;
  align-items: center;
  margin-left: 21px;
  margin-top: 6px;
}

.daysOrHoursTxt {
  font-size: 12px;
  font-weight: 500;
  color: #143051;
  border: 2px solid #dde9f9;
  display: flex;
  align-items: center;
  border-radius: 3px;
  padding: 1px;
  padding-left: 3px;
  padding-right: 2px;
  cursor: pointer;
}

.daysOrHoursTxt:hover {
  border-color: #bbcada;
}

.daysorHoursCheckbox {
  max-width: 12px;
  margin-left: 3px;
  border-left: 1px solid #dde9f9;
  padding-left: 3px;
  margin-right: 2px;
}

.x2389923 {
  margin-left: 6px;
}

.x298489243 {
  color: #bbcada;
}

.showLeaveTypeVal {
  font-weight: 500;
  color: #143051;
  margin-top: 40px;
}

.x2894982 {
  font-weight: 500;
  color: #143051;
  margin-left: 22px;
  margin-top: 8px;
  margin-bottom: 15px;
}

.x095985858 {
  position: fixed;
  bottom: 80px;
  right: 38px;
}

.x383838383121 {
  /* background-color: orange; */
  position: fixed;
  bottom: 202px;
  right: 38px;
}

.delayShow {
  animation-name: delayShowAnimate !important;
  animation-duration: 0.1s !important;
}

@keyframes delayShowAnimate {
  from {
    bottom: 0px;
  }

  to {
    bottom: 202px;
  }
}

.x328923121 {
  bottom: 202px;
}

.x298484 {
  min-height: 57px;
}

.x2383821232 {
  min-height: 37px;
}

.x3838383 {
  color: #8ea7c5;
}

.maxHeight480 {
  max-height: 480px;
}

.x3838383243 {
  font-weight: 400;
  font-size: 12px;
  color: #516b8b;
  line-height: 16px;
  margin-top: 10px;
  max-width: 100%;
  margin-bottom: 20px;
  padding-right: 0px;
}

.x9389183812 {
  /* background-color: blue; */
  min-width: 60px;
  margin-left: 10px;
  margin-top: -58px;
}

.x38383813121 {
  margin-top: -17px;
}

.x283829812 {
  margin-top: -1px;
}

.x283891389182 {
  margin-top: -39px;
}

.selfLogPaid {
  border-top: 1px solid #bbcada;
  margin-top: 28px;
  display: flex;
  justify-content: space-between;
  padding-top: 18px;
  font-weight: 500;
  color: #496375;
  font-size: 14px;
  padding-left: 15px;
  /* background: blue; */
  /* min-width: calc(100% + 30px); */
  /* margin-left: -15px; */
  padding-bottom: 19px;
  padding-right: 15px;
  margin-bottom: -16px;
  text-align: left;
}

.selfLogPaid:hover {
  border-radius: 0 0 5px 5px;
  background-color: #f2edf9;
}

.x1384289824 {
  margin-top: -7px !important;
}

.x283819123 {
  font-size: 12px;
}

.x8492892381 {
  min-height: 140px;
}

.paddingBottom0 {
  padding-bottom: 0px;
}

.zoomInSlow {
  animation-name: zoomTypeIn !important;
  animation-duration: 0.3s !important;
}

@keyframes zoomTypeIn {
  from {
    /* bottom: -10%; */
    /* opacity: 0; */
    transform: scale(0.55);
  }
  to {
    /* bottom: 0; */
    /* opacity: 1; */
    transform: scale(1);
  }
}

.latLonInput::placeholder {
  color: #bbcada;
}

.addRtwInput {
  color: #6a8bb1;
  min-height: 85px;
  margin-top: 14px;
  font-weight: 500;
  min-width: 227px !important;
  max-width: 227px !important;
  font-size: 16px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  resize: none;
  background-color: #fdfeff;
  padding: 10px;
}

.addRtwInput::placeholder {
  color: #bbcada;
}

.addQdiv {
  border-top: 2px solid #214978;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.addQBtn {
  max-width: fit-content;
  margin-right: 0px;
  margin-top: 10px;
}

.bg58467e {
  background-color: #214978 !important;
}

.adminOtRatesDiv {
  font-size: 12px;
  margin-left: 15px;
}

.x389181 {
  /* background:blue; */
  min-width: 100%;
}

.x13i121 {
  /* background-color: yellow; */
  padding-right: 15px;
}

.x132432222 {
  min-width: 117%;
}

.x2398298322 {
  margin-top: -4px;
  font-size: 12px;
}

.x121441:hover {
  color: #214978;
}

.x1398748713 {
  margin-top: 15px;
  margin-left: 10px;
}

.x2312221 {
  padding-left: 9px;
}

.x21345678542 {
  min-width: 220px;
  max-width: 220px;
  margin-right: 12px;
  border-color: #214978;
  background-position-x: 194px;
}

.pHolsLeaveDiv {
  /* border-radius: 5px; */
  /* border: 1px solid #bbcada; */
  font-size: 12px;
  padding: 5px;
  max-width: 190px;
  margin-top: 15px;
  padding-top: 10px;
  /* padding-left: 10px; */
  /* padding-right: 10px; */
  text-align: left;
}

.pHolYrSelDiv {
  /* border-top: 1px solid #bbcada; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 6px;
  padding-bottom: 0px;
  padding-top: 6px;
  font-weight: 400;
}

.pHolYr {
  cursor: pointer;
  border-bottom: 2px solid #fff;
  margin-left: 4px;
  margin-right: 4px;
  min-height:20px
}

.selPHolYr {
  font-weight: 600;
  border-bottom: 2px solid #bbcada;
  color:#143051
}

.x283819834 {
  color: #1e88e5;
  border-radius: 3px;
  padding-left: 2px;
  padding-right: 2px;
  font-weight: 500;
  font-size:14px;
  min-width: 20px !important;
}

.pHolIn {
  font-weight: 600;
  margin-top: -8px;
  margin-right: 6px;
}

.pHolLeaveSettsBtn {
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #514588;
  margin-top: 4px;
  margin-right: 0px;
  border:1px solid #bbcada;
  border-radius: 4px;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  /* opacity: 0.8; */
  margin-top: 35px;
  margin-bottom: 0px;
  min-height:35px;
  /* margin-bottom:-10px; */
}

.pHolsLeaveDivMob {
  padding-top: 5px;
  font-size: 12px;
  padding-bottom: 5px;
  color: #496375;
  border: none;
  /* padding-right:10px; */
  border-right:2px solid #bbcada;
  /* border-top: 1px solid #bbcada; */
  /* border-bottom: 1px solid #bbcada; */
  border-radius: 0;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 15px;
  margin-top:20px;
  /* margin-bottom:8px; */
}

.pHolLeaveSettsBtn:hover {
  /* border:2px solid #bbcada; */
  opacity: 1;
}

.purCogPH {
  max-width: 12px;
  margin-left: 5px;
  margin-top: 1px;
}

.showDisabledTxt {
  color: #516b8b;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 15px;
  /* padding-right: 210px; */
  padding-left: 17px;
  text-align: left;
  margin-bottom: 16px;
  max-width:90%
}

.showDisabledTxtMob {
  padding-right: 40px;
  padding-bottom: 10px;
  margin-top: 0px;
}

.x2384898488523 {
  background-color: #3d5667;
  pointer-events: none;
}

.notifPurBorder {
  border-left: 2px solid #de82f7 !important;
}

.height60 {
  height: 60px !important;
}

.addUserNum {
  font-weight: 500;
  font-size: 14px;
  margin-left: 23px;
  margin-top: 15px;
  margin-bottom: -11px;
  color: #0077ff;
}

.x2398911991 {
  font-size: 11px;
  color: #bbcada;
  font-weight: 500;
  margin-left: 23px;
  padding-top: 7px;
  padding-right: 20px;
  margin-bottom: -12px;
}


.x2329829119912 {
  font-size: 11px;
  color: #bbcada;
  font-weight: 500;
  margin-left: 23px;
  padding-top: 7px;
  padding-right: 20px;
  margin-bottom: 8px;
}

.myAccHeaderCorrector {
  margin-left: 3px;
}

.signoutLoading {
  background-color: #fff !important;
  border-color: #bbcada !important;
  color: #bbcada !important;
  padding-right: 10px;
}

.editEmailTxtInput {
  border: none;
  /* border-bottom: 2px solid #dde9f9; */
  /* background-color: #f4f8fe; */
  /* border-radius: 5px 5px 0 0; */
  color: #143051;
  min-height: 35px;
  padding-left: 9px;
  font-weight: 500;
  /* margin-left: 5%; */
  min-width: 90% !important;
  font-size: 14px;
  padding-left: 7px;
  /* background-color: #f4f8fe; */
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 7px;
  background-color: #fdfeff;
  min-width: 220px !important;
  margin-top: 10px;
  /* margin-left:-2px; */
}

.editEmailTxtInput:focus {
  border: 2px solid #0af;
  border-radius: 5px;
}

.saveEmailAccBtn {
  font-size: 14px;
  color: #fff;
  background-color: #0bf;
  border: 1px solid #0af;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  max-width: 62px;
  min-width: 62px;
}

.saveEmailAccBtn:hover {
  background-color: #07b0ed;
}

.x28392479211 {
  pointer-events: none;
  opacity: 0.4;
}
.unverifEmailTxt {
  color: #bbcada;
  font-size: 12px;
  padding-left: 10px;
  /* padding-bottom:10px; */
}

.unverifEmailTxt:hover {
  color: #143051;
  cursor: pointer;
}

.x928392741 {
  pointer-events: none;
  cursor: default;
}

.unverifEmailTxt:hover {
  color: #bbcada;
}

.overtimeExample {
  border: 1px solid #e6e6e6;
  padding: 5px;
  border-radius: 10px;
  padding-top: 12px;
}

.overtimeExampleTxt {
  font-weight: 500;
  color: #143051;
  font-size: 14px;
  margin-left: 10px;

  margin-bottom: 10px;
}

.exampleRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #859dbb;
  /* padding-top:5px; */
  padding-bottom: 5px;
  /* border-top: 1px solid #e6e6e6; */
  border-bottom: 1px solid #e6e6e6;
  padding-right: 10px;
  margin-top: 5px;
}

.exampleRow2 {
  border-bottom: 2px solid #214978;
  margin-top: 5px;
  margin-bottom: 10px;
  padding-bottom: 16px;
}

.exampleNameTitle {
  max-width: 40%;
  margin-left: 10px;
  color: #143051;
}

.exampleMainVal {
  color: #0077ff;
  font-weight: 500;
}

.updateOtReqsBtn {
  font-size: 14px;
  line-height: 14px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  background-color: #07f;
  border: #0469de 1px solid;
  color: white;
  border-radius: 5px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  margin-left: 2%;
  margin-bottom: 19px;
}

@media screen and (min-width: 820px) {
  .updateOtReqsBtn:hover {
    background-color: #036ce4;
  }
  .updatedReqItem:hover {
    background-color: #f4f8fe;
  }
}

.updatereqsImg {
  max-width: 17px;
  margin-left: 6px;
}

.updatedReqsBox {
  border-top: 1px solid #bbcada;
  min-height: 360px;
  max-height: 360px;
  overflow-y: scroll;
  border-bottom: 1px solid #bbcada;
}

.updatedReqItem {
  border-top: 1px solid #bbcada;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  color: #516b8b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.updatedReqItem:first-child {
  border-top: none;
}

.updatedReqNameStrip {
  font-size: 10px;
}

.updatedReqNameStrip {
  line-height: 14px;
  max-width: 90px;
}

.otEachUsrDisclaim {
  color: #143051;
  padding-left: 10px;
  border-left: 2px solid #143051;
  line-height: 16px;
  font-size: 12px;
  margin-top: 20px;
  margin-left: 15px;
  margin-bottom: 18px;
}

.dashboardLeftDiv {
  min-height: calc(100vh - 140px);
  border-right: 1px solid #cbd7e6;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow-y: auto;
  min-width: 220px;
  max-width: 220px;
  max-height: calc(100vh - 140px);
}

.dashboardLeftDivMid {
  min-width: 160px;
  max-width: 160px;
}

.dashboardMasterMain {
  display: flex;

}

.dashMasterMob {
  border-top: 2px solid #e6eaee;
  border-left: 2px solid #e6eaee;
  border-right: 2px solid #e6eaee;
  background-color: #ebf0f7;
  min-width: calc(100vw + 4px);
  margin-left: -2px;
  border-radius: 20px 20px 0 0;

}

.dashUserRow {
  background: white;
  min-height: 40px;
  display: flex;
  padding-left: 15px;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  color: #516b8b;
  text-align: left;
  width: 82%;
  border-bottom: 3px solid #ebf0f7;
  cursor: pointer;
}



@media only screen and (min-width: 820px) {
  .dashUserRow:hover {
    color: #7b429e;
  }
  
}

.dashUserRowFirst {
  border-radius: 10px 10px 0 0;
}
.dashUserRowLast {
  border-radius: 0px 0px 10px 10px;
}

.dashRowSingle {
  border-radius: 5px;
}

.selectedUserDash {
  background-color: #ebddef;
  color: #d534d7;
}

@media only screen and (min-width: 820px) {
  .selectedUserDash:hover {
    color: #d534d7;
  }
}

.dashboardRightDiv {
  /* background-color: blue; */
  min-width: 574px;
  max-width: 574px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 16px;
  /* padding-left:3px; */
}

@media only screen and (max-width: 1034px) {
  .dashboardRightDiv {
    /* background-color: yellow; */
    min-width: calc(100vw - 460px);
    max-width: calc(100vw - 460px);
  }
}

.dashboardRightDivMid {
  min-width: calc(100vw - 400px);
  max-width: calc(100vw - 400px);
}

.dashSubPage {
  background-color: white;
  max-height: calc(100% - 25px);
  min-height: calc(100% - 25px);
  max-width: 93%;
  min-width: 93%;

  /* max-height: 96%; */
  margin-top: 5px;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
}

.unselUsrSubPage {
  background-color: #f9fafd;
}

.selectColl {
  color: #516b8b;
  font-weight: 500;
  margin: 0 auto;
  font-size: 12px;
  margin-top: 90px;
}

.userDashLeft {
  min-width: 120px;
  border-right: 3px solid #ebf0f7;
  min-height: calc(100vh - 181px);
  max-height: calc(100vh - 181px);
  background-color: #ebf0f7;
  overflow-y: auto
}

.userDashLeftBtn {
  background: white;
  min-height: 40px;
  display: flex;
  padding-left: 10px;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  color: #516b8b;
  text-align: left;
  /* width: 90%; */
  border-bottom: 3px solid #ebf0f7;
  cursor: pointer;
}

@media only screen and (min-width: 820px) {
  .userDashLeftBtn:hover {
    color: #7b429e !important;
    background-color: #f6f9ff;
  }

 .userDashFileItem:hover {
  background-color: #f6f9ff !important;
  border-radius:5px;
 }
}

.userDashBtnSel {
  background-color: #ebddef;
  color: #d534d7;
}

.userDashBtnSel:hover {
  color: #d534d7 !important;
  background-color: #ebddef;
cursor: default;
}

.lateStartsDash {
  border-radius: 0 0 0 10px;
}

.dashHistoryArr {
  /* min-height: calc(100vh - 300px); */
  max-height: calc(100vh - 300px);
  overflow: auto;
  padding-right: 3px;

}

.userDashRight {
  min-width: calc(94% - 103px);
  /* margin-top: 2%; */
  margin-left: 2%;
  /* overflow-y: auto; */
  max-height: calc(100vh - 181px);
  padding-right: 10px;
  padding-bottom:15px;
  /* position: fixed;
  top:0;
  left:0;
  min-width: 100vw;
  min-height:100vh;
  background-color: lime; */
}

.userDashRightTeams {
    overflow-y: auto;
    padding-top:10px
}
.userDashDetailsTop {
  display: flex;
  justify-content: space-between;
  background: #f4f6fa;
  border-radius: 5px;
  min-width: 100%;
  align-items: flex-start;
  padding-top: 5px;
  margin-top:10px;
}

.avgMpwDashRow {
  display: flex;
  justify-content: space-between;
  background: #f4f6fa;
  border-radius: 5px;
  min-width: 100%;
  align-items: flex-start;
  padding-top: 11px;
  margin-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  min-height:55px;
}



.userDashProPicImg {
  min-width: 80px;
  min-height: 80px;
  max-width: 80px;
  max-height: 80px;
  object-fit: cover;
  border-radius: 5px;
  border: 2px solid #143051;
}

.userDashProPicImgMid {
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
}

.userDashDetailsLeft {
  min-width: 70%;
  padding-bottom: 10px;
  /* padding-top:10px; */
}

.userDashDetailsLeftRow {
  border-bottom: 1px solid #dde9f9;
  margin-left: 15px;
  text-align: left;
  padding-bottom: 5px;
  padding-top: 5px;
}

.userDashDetailsLeftRowTitle {
  font-size: 11px;
  color: #214978;
  text-align: left;
}

.userDashDetailsLeftRowValue {
  font-weight: 500;
  font-size: 13px;
  color: #143051;
  margin-top: 2px;
  max-width: 210px;
  /* line-height:17px; */
  text-align: left;
}

.userDashDetailsRight {
  margin-right: 15px;
  margin-top: 0px;
}

.editProfileBtnDash {
  display: flex;
  align-items: center;
  background-color: #b49ce7;
  border: 1px solid #a47fd0;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
  min-height: 35px;
  font-weight: 500;
  max-width: 80px;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;
  margin-top: 5px;
  min-width: 60px;
  
}

.editProfileBtnDashMid {
  font-size: 12px;
  min-height: 30px;
}

@media only screen and (min-width: 820px) {
  .editProfileBtnDash:hover {
    background-color: #a58fd4 !important;
  }
}

.editProfilePenIcon {
  width: 10px;
  margin-left: 7px
}

.editProfilePenIconMid {
  width: 9px;
  margin-left: 4px;
}

.dashEmail {
  font-size: 12px;
  cursor: pointer;
}


.avgMpdHistBtn {


  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  min-height: 35px;
  max-height: 35px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
}

  @media only screen and (min-width: 820px) {
    .avgMpdHistBtn:hover {
      border-color: #bbcada !important;
    }
  }

  .avgHistTriangle {
    width: 7px;
    margin-left: 8px;
    margin-right: 2px;
  }

  .avgHistTriangleActive {
    transform: rotate(-90deg);
  }



  .dashExcessArrItem {
    background-color: #fbfbfb;
    width: 255px;
    margin-top: 6px;
    border-radius: 5px;
    min-height: 40px;
    float: right;
    border: 1px solid #d3dae1;
   }

   .maxWidth60pc {
    max-width: 70%;
   }

   .dashExcRow {
 display: flex;
 align-items: center;
 justify-content: space-between;
 /* padding-left:5px;
 padding-right:5px; */
 border-bottom:1px solid #dde9f9;
 max-width: 90%;
 margin-left:5%;
 min-height: 30px;
   }

   .dashExcTitle {
    color: #516b8b;
    font-size: 11px;
    font-weight: 400;
    max-width: 50%;
    text-align: left;
    padding-top: 8px;
    padding-bottom: 8px;
   }

   .dashExcValue {
    font-weight: 500;
    font-size: 11px;
    color: #516b8b;
   }

   /* .x19101133 {
    opacity: 0.6;
   } */


   .x10910 {
    display: flex;
    flex-direction: column;
   }

   .x1910911 {
    min-width: 100%;
    margin-left: 0px;   }

    .x920842 {
      /* background-color: blue; */
      min-width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .dashPayExcess {
      color: #bbcada;
      font-size: 10px;
      max-width: 130px;
      text-align: right;
      margin-top: -3px;    }

      .x392842911 {
        margin-top:6px;
        /* display: flex; */
        /* justify-content: space-between; */
      }

      .x139849111 {
                display: flex;
        justify-content: space-between;
      }

      .worthPlaneImg {
     width:28px;
      }

      .dashUserLoadingimg {
        width:100px;
        margin:30px auto
      }


      .dashTeamItem {
        display: flex;
        /* justify-content: space-between; */
        /* align-items: f; */
        flex-direction: column;
        background: #f4f6fa;
        border-radius: 5px;
        min-width: 100%;
        align-items: flex-start;
        padding-top: 5px;
        margin-bottom:10px;
        padding-bottom:15px;
      }

      .dashAdmin {
        background-color: #bbcada;
        color: white;
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 5px;
        font-size: 11px;
        min-height: 18px;
        max-width: fit-content;
        display: flex;
        align-items: center;
        font-weight: 500;
        margin-top: 2px;
          }

  .teamDashBtn {
    max-width: 74px;
    min-width: 74px;  }


    .dasthTeamItemUpper {
      display: flex;
      justify-content: space-between;
      min-width: 100%;
      align-items: flex-start;
    }
    
    .dashTeamUserSection {
      min-width: 100%;
      display: flex;
      justify-content: flex-start;
      padding-left:15px;
      /* padding-right:15px; */
    }

    .dashTeamMgrs2 {
      margin-left:15px
    }

    .dashTeamMgrTitle {
      font-size: 11px;
      color: #214978;
      text-align: left;
      border-bottom: 1px solid #dde9f9;
      font-weight: 500;
      line-height: 20px;
      }

      .dashTeamPerson {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #516b8b;
        font-size: 10px;
        margin-top: 8px;
        padding-left: 3px;
        text-align: left;
        cursor: pointer;
        line-height: 12px;

      }

      .dashMgrIcon {
        width: 10px;
        margin-right:3px;
        padding-left:2px;
        padding-right:2px;
      }


      .selectedDashTeamMgr {
        background-color: #d1baf3;
        max-width: fit-content;
        border-radius: 3px;
        color: white;
        padding-right: 5px;
        min-height: 20px;
        padding-left: 3px;
        margin-left: 0px;      }


      .selectedDashTeamUsr {
        background-color: #a1bbf1;
        max-width: fit-content;
        border-radius: 3px;
        color: white;
        padding-right: 5px;
        min-height: 20px;
        padding-left: 3px;
        margin-left: 0px;      }

        .dashManageTeamsBtn {
          color: #516b8b;
          font-size: 12px;
          font-weight: 500;
          text-align: right;
          margin-right: 15px;
          cursor: pointer;
          max-width: fit-content;
          float: right;
          border: 1px solid #bbcada;
          border-radius: 5px;
          min-height: 35px;
          display: flex;
          align-items: center;
          padding-left: 10px;
          padding-right: 10px;
          ;
        }

        @media only screen and (min-width: 820px) {
          .dashManageTeamsBtn:hover {
background-color: #f9fbfc;          }
        
        }


        .x99j {
          margin-top:10px;
          padding-right:2px;
        }

        .x13vf {
          margin-top: 10px;
          margin-right: 30px;
          margin-bottom: 32px;
        }

        .dashYrSelectorDropdown {
          border-radius: 5px;
          font-size: 14px;
          font-weight: 500;
          padding-left: 10px;
          text-align: left !important;
          max-width: 69px;
          min-width: 69px;
          line-height: 26px;
          height: 35px;
          /* border: 1px solid #e6eaee; */
          -webkit-appearance: none;
          display: flex;
          cursor: pointer;
          /* border-color: #2c7eb9; */
          border: 2px solid #dde9f9;
          /* background-color: #0bf; */
          background-color: white;
          color: #6a8bb1;
          background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
          background-position-x: 48px;
        }



        .dashDocsUserSelect {
          border-radius: 5px;
          font-size: 14px;
          font-weight: 500;
          padding-left: 10px;
          text-align: left !important;
          max-width: 229px;
          min-width: 229px;
          line-height: 26px;
          height: 35px;
          /* border: 1px solid #e6eaee; */
          -webkit-appearance: none;
          display: flex;
          cursor: pointer;
          /* border-color: #2c7eb9; */
          border: 2px solid #dde9f9;
          /* background-color: #0bf; */
          background-color: white;
          color: #6a8bb1;
          background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
          background-position-x: 208px;
          margin-right:12px
        }

        .dashDocsUserSelectMini {
          background-position-x: 171px;
          max-width: 192px;
          min-width: 192px;
          font-size: 11px;
        }
        .dashMonthSel {
          background-position-x: 85px;
    max-width: 109px;
    min-width: 109px;
        }

        .dashAllowPercentSpan {
          color:#8ea7c5;
          /* padding-left:12px; */
        }

        .noLongerAvailAllowDash {
          font-size:11px
        }

        .allowsLoadDash {
          margin-top: 0px;
          margin-bottom: -7px;        }


          .redRemaining {
            color:#ba0000
          }



          .dashTabHours {
            border-radius: 5px 5px 0px 0px;
            overflow: auto;
            padding-top: 10px;
            padding-bottom: 15px;
            max-height: calc(100vh - 268px);
          }

          .dashTabHoursAbsInstances {
            max-height: calc(100vh - 283px);
            padding-bottom: 20px;
          }

          .dashTabHoursTimesheets {
            max-height: calc(100vh - 263px);

          }

          .dashTabHoursWithBottomBar {
            min-height:calc(100vh - 300px);
            max-height:calc(100vh - 300px);
          }

          .dashTabHoursTotals {
            border-top:1px solid #143051;
            min-height:30px;
            max-height:30px;
            min-width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }

          .noDashHours {
            color: #bbcada;
            font-weight: 500;
            font-size: 12px;
            margin-top: 30px;          }

            .adminBlob {
              background-color: #d9e3f2;
              min-width: 4px;
              min-height: 20px;
              border-radius: 10px;
              margin-right: 7px;
              margin-left: -11px
            }


            .dashHoursTopRight {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              margin-top:-5px;
            }
            .openDataTabOnUser {
              color: #bbcada;
    font-size: 11px;
    font-weight: 500;
    margin-top: 2px;
    margin-right: 4px;
    cursor: pointer;
            }


            .x1093901111a {
              /* margin-top:4px */
            }

            .x18191x {
              margin-top:-7px
            }
.marginTop20 {
  margin-top:20px
}

.dashAbsMenuSel {
  max-height: 30px;
  padding-top: 10px;
}

.absPage1Height {
  padding-top:0px;
  padding-bottom:0px;
}

.dashAbsMenuDiv {
  display: flex;
  align-items: center;
  min-width: 142px;
  justify-content: space-between;
  margin-left: 10px;
  margin-bottom: -10px;
  margin-top: -2px;
}

.dashAbsMenuDiv2 {
  margin-top:-3px;
}

.dashAbsInfo {
  font-size: 12px;
  font-weight: 500;
  color: #516b8b;
  padding-bottom:5px;
  cursor: pointer;
}

.dashAbsInfo:hover {
  color:#214978
}

.dashAbsInfoSel {
  color: #143051;
  border-bottom: 2px solid #143051;
  padding-bottom: 3px;}

  .x910930112 {
    margin-top: 6px;
    margin-bottom: 4px;
  }

  .dashItem {
    border-bottom: 1px solid #bbcada;
    width: 98%;
    margin-left: 1%;
    padding-left: 7px;
    padding-right: 7px;
    cursor: pointer;
  }


  .remainingColour {
    color:#0077ff
  }

  .x190390111 {
    font-size: 12px;
    min-height: 30px;
    padding-left: 8px;
    padding-right: 8px;
    max-width: 118px;  }


    .noExcHist {
      color: #bbcada;
      font-size: 12px;
      font-weight: 500;
      margin-top: 8px;    }

    .loadingDashAbsImg {
      width:100px;
      margin: 20px auto
    }



.x23894398423:hover {
  background-color: white !important;
}

.dashTsApproveImg {
  min-width: 12px;
  max-width: 12px;
  margin-left: 6px;
}

.noWrap {
  flex-wrap: nowrap !important;
}

.backgroundf6f7f9 {
  background-color: #f6f7f9 !important;
}

.x3298298421 {
  opacity: 0.5;
}

.tsDashKey {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 160px;
}

.tsDashKeyTitle {
  font-weight: 500;
  font-size: 10px;
  color: #8ea7c5;
  margin-bottom: 3px;
  padding-left: 2px;
}

.g89492 {
  margin-top:-6px
}

.dashTabHoursTotTitle {
  font-weight: 500;
  font-size: 10px;
  color: #8ea7c5;
  margin-left: 70px;
  margin-right: 16px;
  display: flex;
  min-width: fit-content;
  align-items: flex-end;
}

.dashTabHoursTotValue {
  color: #516b8b;
  padding-left: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.tilProfileInfoSpan {
  font-size:10px; 
  color:#859dbb;
}

.X329892422 {
padding-top:20px;

padding-bottom:20px;
}


.x1933819981313 {
  color:#0077ff
}

.dashClockCardBreakIcon {
  opacity: 0.8;
}

.dashClockTimeStrip {
  color:#b1c2d4
}

.x29484298422 {
  margin-bottom: -37px;
  margin-top: 8px;}

  .dashFormsContainer {
    /* background-color: blue; */
    min-height:calc(100vh - 246px);
    max-height:calc(100vh - 246px);

  }

  .notYetCompDashForm {
    min-width: 8px;
    min-height: 8px;
    background-color: #bbcada;
    border-radius: 10px;
    margin-left: 4px;
  }

  .x317931781 {
    /* margin-bottom: -13px; */
  min-width: 154px;
  }

    .completedFormDashTickImg {
      width:10px;
      margin-left:4px;
    }


.absenceDashDataBoard {
  /* background-color: blue; */

  /* overflow-y:auto */
}

.x398428924892 {
  min-height: calc(100vh - 267px);
  max-height: calc(100vh - 267px);
  overflow-y: auto;
  padding-bottom: 20px;
  margin-top: 10px;

}

.adminTeamItem {
  min-width: 96%;
  max-width: 96%;
  border:#e6e6e6 solid 1px;
  margin-top:12px;
  margin-left: 10px}

  .adminTeamItemMob {
    border-radius:10px
  }

 

  .teamEditAdminBtn {
    min-width: 90px;
  }

  @media screen and (min-width: 854px) and (max-width: 1025px) {
    .adminTeamItem {
      min-width: 94%;
      max-width: 94%;
      margin-left: 10px;
    }
  }

@media screen and (min-width: 1026px) {
  .adminTeamItem {
    max-width: 520px !important;
    min-width: 520px !important;
    margin-left: 10px;
  }
}

.variationExplain {
  font-size: 12px;
  color: #516b8b;
  margin-top: 10px;}

  .lateDashMenu {
    margin-bottom: 0px  !important;
    min-width:90px;
  }

  .colorBa0000 {
    color:#ba0000 
  }

  .docsLoadingDash {
    margin-top: -10px;
    margin-bottom: 1px;
 }

    .dashDocsSizeMb {
      color:#bbcada;
      font-weight: 500;
      /* margin-top:2px; */
      /* margin-bottom:3px; */
      font-size:12px;
      line-height:12px;
      max-width: 130px;
      margin-left:10px;
    }

    .dashAllowsScroll {
      max-height: calc(100vh - 268px);
      min-height: calc(100vh - 268px);
    padding-bottom:15px;
      overflow: auto;  
    
    }

    .docSashLeftRow {
      /* background-color: red; */
      display: flex;
      justify-content: space-between;
      min-width: 100%;
      min-height:55px;
      margin-left:0%;
      align-items: center;
    }


    .uploadDocDashBtn {
      background-color: #0bf;
      border: 1px solid #0af;
      height: 35px;
      font-weight: 500;
      /* line-height: 34px; */
      font-size: 14px;
      padding-left: 9px;
      padding-right: 9px;
      border-radius: 5px;
      /* font-size: 0.9em; */
      /* margin-top: 0px; */
      /* padding-bottom: 1px; */
      display: flex;
      align-items: center;
      justify-content: center;
      /* margin-right: 7px; */
      /* margin-left: 2px; */
      /* max-width: 62px; */
      min-width: fit-content;
      cursor: pointer;
      color:white
    }
    


 
    .x2989248423 {
      padding-right:10px;
    }

    .scrollDashRight {
      overflow: auto;
    }

    .mobDash {
      /* background-color: blue; */
      max-height: calc(100vh - 255px);
    }

    .max25mb {
      font-weight: 500;
      color: #ba0000;
      font-size: 13px;
      margin-left: 4px;
    }

    .dashUserDocsGrid {
      display: grid;
    grid-template-columns: repeat(auto-fill, minmax(117px, 1fr));
    grid-auto-rows: auto;
    grid-auto-flow: row;
    align-content: start; /* Align items to the top */
    min-height: calc(100vh - 246px);
    overflow-y: auto;
    max-height: calc(100vh - 246px);
    padding-bottom:10px;
    

    }
    .dashUserDocsList {
      /* display: flex;
      flex-direction: column; */
    }

    .userDashFileItem {
      min-width: 125px;
      min-height: 130px;
      max-width: 125px;
      max-height: 60px;
      /* border-bottom: 1px solid #e6eaee; */
      /* border-radius: 5px; */
      margin-top: 6px;
      padding-bottom: 10px;
      /* cursor: pointer; */
    }

    .listItemLeft {
      display: flex;
      align-items: center;
      min-width: 60%;
    }

    .userDashFileItemList {
      min-width: 100%;
      min-height: 35px;
      max-height:35px !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      /* max-width: 125px; */
      /* max-height: 60px; */
      border-bottom: 1px solid #e6eaee;
      /* border-radius: 5px; */
      /* margin-top: 6px; */
      /* padding-bottom: 10px; */
    }

    .draggable:active {
      /* Set a transparent background */
      background-color: transparent;
      
      /* Remove the box shadow */
      box-shadow: none;
    
      /* Optionally, you can adjust other properties as needed */
      border: none; /* Remove any border */
      outline: none; /* Remove the outline */
    }

    @media screen and (min-width: 820px) {
      .uploadDocDashBtn:hover {
        background-color: #0af;

      }

      .saveFolderName:hover {
        background-color: #0469de !important;
      }


      .newUserFolder:hover {
        border-color: #bbcada;

      }
      .dashFileIconBtn:hover {
        background-color: #5292de;
        border-color: #3574bf;
        color: white;      }

      .fileIconDelete:hover {
        background-color: white;
        border:1px solid #bac5d3;
        color:#7f91a4
      }


      .editFiltImg:hover {
        background-color: white;
        border:1px solid #bac5d3;
      }
    }


    .dashUserFileIcon {
      max-width: 47px;
      margin-top: 16px;
      opacity: 0.8;
      cursor: pointer;
    }

  
    .dashUserFileName {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 10px;
      padding-left: 6px;
      padding-right: 6px;
      color: #516b8b
       }

       .fileBytes {
        color:#bbcada
       }

       .dashFileIconDiv {
        min-width: 100%;
    min-height: 27px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left:2px;
    padding-right:2px;
       }

       .dashFileIconBtn {
        background-color: #e7f7ff;
        border: 1px solid #c5e4f3;
        border-radius: 3px;
        min-height: 25px;
        width: 47%;
        color: #78ccf4;
        font-weight: 500;
        font-size: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
       }




       .fileIconDelete {
        color: #bbcada;
        background: none;
        border-color: #e6eaee;       }

        .sureDeleteFilenameSpan {
          font-size: 12px;
          line-height:14px;
          color: #bbcada;
          padding-left: 9px;
          padding-right: 9px;
               }


          .newUserFolder {
            background-color: #fff;
            border: 1px solid #e6eaee;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 6px;
            padding-right: 6px;
            border-radius: 5px;
cursor: pointer;            padding-top: 5px;
            padding-bottom: 5px;
            margin-left:8px;
            max-width:37px;
          }



          .newFileNewFolderWrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .newFolderPopIn {
            animation-name: zoomModalIn !important;
            animation-duration: 0.2s !important;
            border-color: #aadbfc;
            border-width: 2px;
          }

          .folderItem {
            /* min-height:60px; */
            border:none
          }

          .folderNameInput {
            color: #6a8bb1;
            min-height: 28px;
            font-weight: 500;
            width: 95%;
            font-size: 11px;
            border: 2px solid #dde9f9;
            border-radius: 3px;
            padding-left: 5px;
            background-color: #fdfeff;
            margin-bottom:-3px;
          }

          .folderNameInput::placeholder {
            color:#bbcada
          }

          .saveFolderName {
            background-color: #07f;
            border: #0469de 1px solid;
            color:white
          }


.editFolterNameImg {
  width: 21px;
  cursor: pointer;
  padding: 3px;
  margin-bottom: -3px;
  border: 1px solid #fff;
  border-radius: 3px;
  position: relative;
  top: -111px;
  margin-left: 86px;
  transform: rotate(90deg);
}



.editFolterNameImg:hover {
  border:1px solid #bbcada
}

.privateFileIcon {
  position: relative;
  top: -109px;
  margin-left: 51px;
  width: 19px;
  z-index: 999;

}

.editFolterNameImgPriv {
  top: -134px;
  margin-left: 94px;}

/* .editFolterNameImgList {
  width: 16px;
  cursor: pointer;
  margin-left: 3px;
  padding: 3px;
  border: 1px solid #e3e8ee;
  border-radius: 3px;
} */

.noEllipsis {
  text-overflow: clip;

}

.folderImg {
  margin-bottom: -4px;
  opacity: 0.7;
  margin-top:20px;
  max-width: 67px;
}

.folderImgHovered {
  opacity: 1;
}

.deleteFolderBinImg {
  /* background-color: blue; */
  width:14px;
  /* opacity: 0.6; */
}

.folderDeleteBtn {
  margin-top: 22px;
  margin-left: 98px;
  margin-bottom: -55px;
  max-width: 23px;
  min-width: 23px;
  min-height: 23px;
  max-height: 23px;
  cursor: pointer;
  padding: 3px;
  border: 1px solid #e3e8ee;
  border-radius: 3px;
}

.folderIconNonRename {
  margin-top: 23px;
  cursor: pointer;
  margin-bottom: 4px;}

  .totDocsDash {
    margin-top:-9px;
  }

  .folderIconRename {
    max-width: 62px;
  }

  .backFolderItem {
    background-color: white;
    color: #516b8b;
    /* max-width: 30px; */
    /* font-size: 12px; */
    /* font-weight: 500; */
    /* border: 2px solid #e6eaee; */
    /* border-radius: 5px; */
    /* padding: 6px; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* min-width: 30px; */
    cursor: pointer;
    /* height: 30px; */
    /* min-width: 125px;
    min-height: 120px;
    max-width: 125px;
    max-height: 60px; */

    min-width: 125px;
    min-height: 120px;
    max-width: 125px;
    max-height: 60px;
    border: 1px solid #e6eaee;
    border-radius: 5px;
    margin-top: 6px;
    opacity:1
    /* padding:40px; */
  }

  .folderBack  {
    width:33px;
  }

  .backFolderName {
    font-size:12px;
    font-weight: 500;
    margin-top:18px;
    color:#bbcada
  }

  @media screen and (min-width: 820px) {


    .editFolderRow:hover {
      background-color: #f4f8fe;
    }

    .noHovFold:hover {
      background-color: white !important;
      cursor: default;
    }
    .delFolBtn:hover {
      color: #516b8b
    }

.backFolderItem {
  opacity: 0.8;
}
    .backFolderItem:hover {
      border-color:#bbcada;
      opacity: 1;
    }

  .folderBack:hover {
    border-color: #bbcada
  }


  .editFileImg:hover {
    background-color: white;
    border: 1px solid #bac5d3;
    color: #7f91a4;
  }

}


.dashMenuFilesLeft {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filesize {
  white-space: nowrap;
  overflow: hidden;
  color:#bbcada;
  text-overflow: ellipsis;
  font-size: 10px;
  padding-left: 6px;
  padding-right: 6px;}


  .listFolderImg {
    max-width: 24px;
    margin-top:0px;
    margin-left:10px;
    margin-top:4px;
  }
 

  .listItem {
    border-bottom:1px solid #e6eaee
  }

  .listViewFileName {
    font-size:12px;
  }

  .editFolderRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-bottom: 1px solid #bbcada; */
    padding-left: 10px;
    padding-right: 10px;
    min-height: 50px;
    color: #214978;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    border-radius:5px;
    text-align: left;
 }

 .editFolderNameInput {
  color: #6a8bb1;
  min-height: 35px;
  padding-left: 9px;
  font-weight: 500;
  /* margin-left: 5%; */
  min-width: 96%;
  /* margin-top: 5px; */
  /* margin-bottom: 3px; */
  font-size: 15px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 7px;
  margin-bottom:10px;
  background-color: #fdfeff;
 }

 .editFolderRowTitle {
  font-size: 12px;
  color: #143051;
  font-weight: 400;
  text-align: left;
  margin-left: 10px;
  margin-bottom: 4px;
  margin-top:25px;
 }

 .delFolBtn {
  color: #bbcada;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  text-align: right;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 0px;
 }

 .editFileImg {
  color: #bbcada;
  background: none;
  border: 1px solid #e6eaee;
    border-radius: 3px;
  height: 25px;
  width: 25px;
  padding:7px;
  /* color: #78ccf4; */
  /* font-weight: 500; */
  /* font-size: 9px; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left:-28px;
 }

 .fileNameBox {
  margin-top: 6px;
  padding-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
 }

 .fileNameInline {
  min-height:fit-content;
  word-break: break-all;
  overflow-wrap: break-word;
  max-width: 80px;
 }


 .opac04 {
  opacity: 0.4  !important;
 }


 
.dashUserPayRollTop {
  display: flex;
  justify-content: space-between;
  background: #f4f6fa;
  border-radius: 5px;
  min-width: 100%;
  align-items: flex-start;
  /* padding-top: 11px; */
  margin-top: 10px;
  padding-left: 10px;
  padding-right:10px;
  /* padding-bottom: 10px; */
  min-height: 55px;

}

.dashPayRollRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 28px;
  /* border-bottom: 1px solid #e0ebf8; */
  max-width: 96%;
  margin-left: 2%;
  background-color: #f6f4ff;
  border-radius: 5px;
  margin-top:6px;
  margin-bottom:5px
}


.dashPayRollRowSub {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height:40px;
  border-bottom:1px solid #e0ebf8;
  max-width: 96%;
  margin-left:2%;
  max-height: 24px;
  min-height: 24px;
}

.dashPayTitle {
  font-weight: 500;
  color: #143051;
  font-size: 12px;
  padding-left: 6px;
  border-left: 2px solid #f0dfff;
  margin-left: 8px;
  text-align: left;
}

.dashPayTitle2 {
  margin-left: 3px;
  border: none;
  font-size: 14px;

}

.payRollDashDivider {
  min-width: 96%;
  max-width: 96%;
  margin-left:2%;
  min-height:2px;
  background-color: #143051;
  display: none;
  
}

.totUnderline {
  /* border-bottom:2px solid #b3d5ff */
}
.dashPayTitleSub {
  font-weight: 500;
  color: #516b8b;
  font-size: 11px;
  padding-left: 8px;
  border-left: 2px solid #00ccff;
  margin-left: 7px;
  text-align: left;
  line-height:13px;
}

.dashPayValue {
  font-weight: 400;
  color:#99b8d9;
  font-size:11px;
  padding-right:5px;
  min-width: 70px;
  max-width: 70px;
  text-align: right;
  /* background-color: blue; */
}

.dashPayValueCost {
  font-weight: 400;
  color:#99b8d9;
  font-size:11px;
  /* padding-right:5px; */
  min-width: 70px;
  /* max-width: 70px; */
  text-align: right;
  /* background-color: blue; */
}


.dashPayValueWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right:5px;
  /* min-width:  */
}
.makeBold {
  font-weight: 500 !important
}

.dashUserPayRollSheet {
  /* background-color: blue; */
  min-height:calc(100vh - 250px);
  max-height:calc(100vh - 250px);
  overflow-y: auto;
  padding-bottom:30px;
  padding-top:20px;
  padding-left:10px;
  padding-right:10px;
}

.x199ss {
  color: #bbcada;
  padding-left: 4px;
  padding-right: 4px;}

  .dashPayValueRate {
    font-size: 11px;
    color: #a0bddb;
  }

.x8881122 {
  /* border-top:1px solid #143051 */
}

.makeBoldPayRollNum {
  font-weight:500;
  font-size:12px;
  color:#143051
}

.payRollLieuRow {
  background-color: #f2f6f7;
  min-height: 40px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: none;
}



  .lieuPayRowExplain {
    font-size: 9px;
    color: #859dbb;  }

    .totalPayableDashRow {
      background-color: #f4f8fe;
      border: 1px solid #bbcada;
      padding-right: 12px;
      margin-bottom:20px;
      
        }

.totPayDashPayTxt {
  font-size: 14px;
}

.payRollDashHeader {
  color: #214978;
  font-size: 10px;
  font-weight: 500;
}

.exportDashPayRollBtn {
  max-width: 139px;
  float: right;
  margin-right: 2%;
  margin-top: 19px;
  font-size: 13px;
}


.totPaidHoursDashRow {
  background-color:#e6f2ff;
  min-height: 35px;
}

.saveEmailCanx {
  font-size:12px;
  font-weight: 500;
  color:#bbcada;
  margin-top:10px;
cursor: pointer;}

.saveEmailWrap {
  margin-top: 18px;
}

.trackedInfo {

  color: #516b8b;
  font-size: 10px;
  text-align: left;
  padding-left: 10px;
  padding-right: 50px;
  margin-top: -12px;
  margin-bottom: 21px;
  border-left: 2px solid #bbcada;
  margin-left: 15px;
}

.netSal {
  background-color: #214978;
  border-color: #143051;}

  .invalidPayRollDateInput {
    border-color:#ba0000 !important
  }

  .dashAddUserBtn {
    background-color: #e3e9f3;
    border: 2px dashed #bac5d3;
    color: #516b8b;
    border-radius: 7px;
    font-weight: 500;
    font-size: 13px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 17px;
    padding-right: 9px;
    cursor: pointer;
    min-width: 82%;
    margin-bottom: 8px;
    }

    .dashAddUserBtnMob {
      margin-top:8px;
      min-width: 130px;
    }

    .dashAddEmpPlus {
      font-size: 21px;
      font-weight: 600;
      margin-right: 7px;
      margin-top: -4px;
      margin-left: -3px;
    }

    .payDayDrop {
      min-width: 100px;
      max-width: 100px;
      background-position-x: 75px; 
       }

      .weeksPayDayDrop {
        min-width: 50px;
        max-width: 50px;
        font-size: 14px;
        background-position-x: 30px;
        margin-top: 0px;
        padding-left: 14px;
        margin-left:8px;
        margin-right:8px;
      }

      .payDayWeekDayDrop {
        min-width: 129px;
        max-width: 129px;
        font-size: 14px;
        background-position-x: 105px;
        margin-top: 0px;
        padding-left: 14px;
        margin-left:8px;
      }

      .monthPayDayDrop {
        min-width: 77px;
        max-width: 77px;
        font-size: 14px;
        background-position-x: 53px;
        margin-top: 0px;
        padding-left: 14px;
        margin-left: 8px;
      }

      .everyNumDrop {
        min-width: 57px;
        max-width: 57px;
        font-size: 14px;
        background-position-x: 40px;
        margin-top: 0px;
        padding-left: 13px;
        margin-left: 8px;
      }

      .onTheDrop {
        min-width: 77px;
        max-width: 77px;
        font-size: 14px;
        background-position-x: 53px;
        margin-top: 0px;
        padding-left: 14px;
        margin-left: 8px;
      }

      .sameDiffDrop {
        min-width: 141px;
        max-width: 141px;
        font-size: 14px;
        background-position-x: 117px;
        margin-top: 0px;
        padding-left: 14px;
        margin-left: 8px;
      }

      .beforeAfterDrop {
        min-width: 91px;
        max-width: 91px;
        font-size: 14px;
        background-position-x: 67px;
        margin-top: 0px;
        padding-left: 14px;
        margin-left: 3px;
      }


.payDaySelectorParent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* border-bottom: 1px solid #dde9f9; */
  /* padding-bottom: 20px; */
}

.payDaySubSelectDiv {
  display: flex;
  align-items: center;
  margin-top:13px;
}

.payDaySubTxt {
  font-size:12px;
  line-height: 12px;
}


.mobpayDayleft {
  min-width: 40%;
  max-width: 40%;
}

.generatePayDaysBtn {
  font-size: 14px;
  color: #fff;
  border: solid 1px #0469de;
  background-color: #0077ff;
  font-weight: 500;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 8px;
  max-height: 35px;
  border-radius: 5px;
  min-height: 35px;
  min-width: fit-content;
  display: flex;
  align-items: center;
  margin-top: 20px;

}

.genPayDayImg {
  max-width: 23px;
  margin-left: 2px;
}


.genpayDayInfo {
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  color: #bbcada;
  text-align: right;
  margin-top: 10px;
  margin-right: 0px;
  border-right: 1px solid #bbcada;
  padding-right: 10px;
  padding-left: 20px;}


  .noPays {
    font-size: 11px;
    border: 2px dashed #e6eaee;
    max-height: 35px;
    line-height: 30px;
    min-width: fit-content;
    margin-top: 20px;
    border-radius: 5px;
    padding-left: 10px;
    min-height: 35px;
    padding-right: 10px;
    color: #516b8b;
   
  }

  .avoidWeekend {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #dde9f8;
    border-radius: 5px;
    max-height: 32px;
    font-size: 12px;
    padding-left: 6px;
    color: #516b8b;
    margin-top:15px;
    background-color: #f4f8fe;
    cursor: pointer;
  }

  .avoidCheck {
    background-color: #dde9f9;
    max-width: 15px;
    max-height: 15px;
    border-radius: 3px;
    display: flex;
    margin-right: 7px;
    margin-left: 6px;  }

  .avoidWeekendCheckImg {
    max-width: 16px;
    padding: 3px;
    }

    .nearestTxt {
      max-width: 89px;
      line-height: 10px;
      font-size: 10px;
  
     }

.payDaysMap {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #bbcada;
  min-width: 190px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;}


.payDayUnit {
  height: 38px;
      line-height: 38px;
      color: #496375;
      border-radius: 5px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 500;
      min-width: 190px;
      border: solid 2px #dee5ed;  max-width: 120px;


}

.payDayTxt {
  margin-left: 10px;
  font-size: 13px;
  margin-top: -2px;
  text-align: left;
  font-weight: 500;
  line-height: 18px;
  min-height: 38px;
  display: flex;
  align-items: center;
}

.editPayDay {
  margin-right: 6px;
  max-width: 24px;
  padding-left: 7px;
  padding-right: 7px;
  cursor: pointer;
}


.showPayDaysBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #bbcada;
  border-radius: 5px;
  max-height: 32px;
  font-size: 12px;
  padding-left: 8px;
  color: #516b8b;
  margin-top: 15px;
  background-color: #f5f5f5;
  cursor: pointer;
  padding-right: 8px;

}

.payDayArrImg {
  width: 11px;
  margin-left: 5px;
  margin-top: 2px;}

.showPayDaysActive {
  transform: rotate(-90deg);
  margin-top: -1px;}


  .editPayDayDate {
    border: none;
    background-color: #fff;
    color: #496375;
    min-height: 31px;
    line-height: 30px;
    font-size: 12px !important;
    padding-left: 14px;
    padding-right: 8px;
    letter-spacing: 1px;
    padding-top: 0px;
    /* text-align: center; */
    font-weight: 500;
    max-width: 130px;
    min-width: 130px;
    background: url(/static/media/calicongrey.9c7e1aba.svg) no-repeat right #fff;
    -webkit-appearance: none;
    background-position-x: -500px;
    border-right: 2px solid #dde9f9;
    margin-left: 0px;
    margin-top: 2px;
    cursor: text;

  }

  .savePayDay {
    border: solid 1px #0469de;
    background-color: #0077ff;
    border-radius: 5px;
    max-height: 22px;
    margin-top: 6px;
    max-width: 22px;
    min-width: 22px;
    min-height: 22px;
    padding: 6px;
    margin-left:5px;
  }

  .savePayDayBtns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -5px;
  }

  .binPayDay {
    border: 1px solid #bbcada;
    border-radius: 5px;
    max-height: 22px;
    margin-top: 6px;
    max-width: 22px;
    min-width: 22px;
    min-height: 22px;
    padding: 5px;
    margin-left: 4px;
    margin-right: 0px;
  }

  .payDayLeftWidth {
    min-width: 40%;
    max-width: 40%;
  }

  .addPayDayBtn {
    max-width: 113px; 
margin-right: 0px;
   
        margin-top: 10px;

    

  }



.editDashPencil {
  max-width: 10px;
  min-width: 10px;
  margin-left: 6px;
  margin-top: -1px;
  opacity: 1;
  margin-right: 11px;
}

.convertImg {
  max-width: 21px;
  margin-left: 6px;
  margin-top: -1px;
  opacity: 1;
  margin-right: 9px;
}
  .mobDashContent {
    min-width: 92vw;
    min-height: 50px;
    margin-left: 4vw;
    border-bottom: 1px solid #e6eaee;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: orange; */
    padding-top:0px;
  }



  .mobDashUserSelectDropDown {
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    padding-left: 12px;
    text-align: left !important;
    max-width: 180px;
    min-width: 180px;
    line-height: 26px;
    height: 35px;
    -webkit-appearance: none;
    display: flex;
    cursor: pointer;
    border: 1px solid #e6eaee;
    background-color: white;
    color: #143051;
    background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right white;
    background-position-x: 155px;
  }

  .mobDashMenu {
    top: 126px;
    right: 4%;
    left: auto;
    padding-right:10px;
    z-index: 99999;
    min-height:35px;
    flex-direction: row;
  }

  .dashSubPageMob {
    position: fixed;
    top: 164px;
    max-height: calc(100% - 255px);
    min-height: calc(100% - 255px);
    min-width: 100vw;
    margin-left: 0px;
    max-width: 100vw;
    left: 0;
    border-radius: 0; 
  background-color: #ebf0f7 !important;
  /* overflow: auto */
  }



  .dashAllowancesContainer {
    min-width: 579px;
    max-width: 579px;
    min-height: calc(100vh - 147px);
    max-height: calc(100vh - 147px);
border-right:1px solid #cbd7e6;
  }




  /* mobile dynamic: */
  @media only screen and (max-width: 820px) {

    .editProPicModalBox {
      border-radius:10px 10px 0 0;
      min-width: 100vw;
      position: fixed;
      bottom:0;
      animation-name: moveEditTeamUserBoxUp !important;
      animation-duration: 0.1s !important;
    }
.userDashDetailsTop {
  background-color: white;
  min-width: 95% !important;
  max-width: 95%;
  margin-left: 2%;
}

.avgMpwDashRow {
  background-color: white;
  min-width: 95% !important;
  max-width: 95%;
  margin-left: 2%;
}

.avgMpwDashRowSal {
  min-width: 93% !important;
  max-width: 94%;
  margin-left: 4%;
}

.x10910 {
  margin-left: 3%;
  min-width: 92% !important;
  max-width: 92% !important;
}

.x183981 {
  min-width: 94% !important;
  max-width: 94% !important;}

.dashAllowsScroll {
  /* background-color: blue; */
  min-width: 100vw;
  margin-left: 0px;
}

.dashTeamItem {
  background-color: white;
  min-width: 94% !important;
  max-width: 94% !important;
  margin-left: 3%;
}

.userDashRightTeams {
  min-width: 100% !important;
  max-width: 100% !important;
  padding-bottom: 50px;
  margin-left: 0px;
  max-height: calc(100vh - 255px);
  padding-top:10px;
  overflow: auto;
  padding-top:15px;

}

.userDashRightAllows {
  /* min-width: 98% !important;
  max-width: 98% !important;
  padding-bottom: 50px;
  margin-left: 2%; */
  min-width: 100vw;
  max-width: 100vw;
  margin-left:0px;
  /* padding-left:5px; */

}


.dashAllowsScroll {
  max-height: calc(100vh - 330px);
  min-height: calc(100vh - 330px);


}
.userDashRightSal {
  min-width: 100% !important;
  max-width: 100% !important;
  padding-bottom: 50px;
  margin-left: 0px;
  max-height: calc(100vh - 255px);

}
.dashUserPayRollTop   {
  background-color: white;
  max-width: 92vw;
  min-width: 92vw;
  margin-left:4vw;

  /* padding-left:2%; */
  /* padding-right:2%; */
}

.dashUserPayRollSheet {
  /* background-color: white; */
}

.absrowMob {
  margin-left: 4%;
  min-width: 94% !important;
  max-width: 94% !important;
  margin-top:0px;
}

  .absenceDashDataBoard {
    margin-left: 4%;
    min-width: 94% !important;
    max-width: 94% !important;
    /* min-height: calc(100vh - 350px); */
    max-height: calc(100vh - 350px);
    /* margin-bottom:20px; */
  }

  .dashRightMob100w {
    min-width: 100vw;
    margin-left: 0px;  }


    .mobDashLateHeader {
      margin-left: 4%;
      min-width: 94% !important;
      max-width: 94% !important;    }

      .dashHistoryArr {
        margin-top: 20px;
        /* min-height: calc(100vh - 400px); */
        max-height: calc(100vh - 400px);
        padding-right: 10px;
      }

    .x992c {
      /* background-color: blue; */
      padding-left:5px;
    }

    .absenceDashDataBoard {
      overflow: auto;
    }

    .x139849111 {
      margin-top:0px;
    }

    .x1910911 {
      min-height:48px
    }



    .dashFormsContainer {
      min-height: calc(100vh - 325px);
      max-height: calc(100vh - 325px);
      min-width: 100vw;
      padding-left:3%;
      padding-right:4%
    }

    .dashTabHoursAbsInstances {
      min-height: calc(100vh - 362px);
      max-height: calc(100vh - 362px);
      min-width: 100vw;
      padding-left:3%;
      padding-right:4%
    }

    .dashTabHours {
      min-height: calc(100vh - 320px);
      max-height: calc(100vh - 320px);
      min-width: 100vw;
      padding-left:3%;
      padding-right:4%
    }

    .editFolterNameImg {
      background-color: white;
      border:1px solid white
    }

    .editFolterNameImg:hover {
      border:1px solid white
    }

    .dashUserDocsList {
      min-width: 100vw;
      margin-top:10px;
      min-height: calc(100vh - 330px);
      max-height: calc(100vh - 330px);
      padding-left:3%;
      padding-right:3%;
    }

    .userPayRollDashComponent {
      /* background-color: yellow; */
      min-width: 100vw;
      max-width: 100vw;
      margin-left:0px;
    }

    .dashUserPayRollSheet {
      min-width: 100vw;
      max-width: 100vw;
      margin-left:0px;
      margin-top:20px;
      padding-top:0px;
      min-height:calc(100vh - 344px);
      max-height:calc(100vh - 344px);

    }

    .userDashRightPayRoll {
      /* background-color: blue; */
      min-width: 100vw;
      margin-left:0px;
    }

    .dashPayValue {
      color:#214978
    }
    .dashPayValueRate {
      color:#214978
    }
    .dashPayValueCost {
      color:#214978
    }

    .payRollLieuRow {
      background-color: white;
    }

    .docsTopBar {
      min-width: 94vw !important;
      
    }


    .hoursDashTopBar {
      margin-left: 2vw;
      min-width: 92vw !important;
      max-width: 92vw !important;
     }

     .dashTabHoursTimesheets {
      max-width: 100vw;
      margin-left: 0px;
      position: fixed;
      left: 0;
      top: 248px;
      min-height: calc(100vh - 333px);
      max-height: calc(100vh - 333px);
     }

     .dashAllowancesContainer {
      min-width: 100vw;
      max-width: 100vw;
      border:none;
      min-height: calc(100vh - 204px);
      max-height: calc(100vh - 204px);
     }

     .allowAdminBtn:hover {
      background-color:#cbd7e6 !important
     }

     .dashAllowsUsersBox {
      min-height: calc(100vh - 254px) !important;
      padding-bottom:20px;
      max-height: calc(100vh - 254px) !important;  }
  }
/* end of mobile dynamic */
  

  .noOverflow {
    overflow: hidden !important;
  }

  .paddingBottom20 {
    padding-bottom: 20px !important
  }



  .dashAllowsTopBar {
    border-bottom:1px solid #cbd7e6;
    min-width: 92%;
    max-width: 92%;
    margin-left:4%;
    min-height:50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .allowAdminBtn {
    border: solid 1px #bbcada;
    background-color: #cbd7e6;
    display: flex;
    color: #fff !important;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    text-align: center;
    min-width: 35px !important;
    max-height: 35px;
    min-height: 35px;
    font-size: 14px;
    cursor: pointer;
    font-weight: 500 !important;
    max-width: -moz-fit-content;
    max-width: fit-content;
  }

  .allowancesAdminCogImg {
    max-width: 14px;
  }

  .allowAdminBtn:hover {
    background-color: #bbcada;
  }

  .dashAllowsUsersBox {
    padding-top:15px;
overflow:auto;
    min-width: 100%;
    min-height: calc(100vh - 197px);
    max-height: calc(100vh - 197px);  }


    .dashAllowRow {
      background-color: white;
      min-width: 90%;
      max-width: 90%;
      margin-left:5%;
      border-radius:5px;
      margin-bottom:8px;
      min-height:60px;
      padding-top:4px;
      /* display: flex; */
      /* align-items: center; */
      /* justify-content: space-between; */
      cursor: pointer;
      border:1px solid #e0ebf8;
    }

    .dashAllowRowSelected {
      min-height:120px
    }

    .dashAllowRow:hover {
      border:1px solid #cfdae6;
    
    }

    @media only screen and (max-width: 820px) {
.dashAllowRow:hover {
  border:1px solid #e0ebf8;

}


    }


    .dashAllowProPic {
      border-radius:5px;
      min-width: 40px;
      max-width: 40px;
      max-height:50px;
      min-height: 50px;
      object-fit: cover;
      margin-left:5px;

    }

    .dashAllowUserInfo {
      display: flex;
      align-items: center;
    }

    .dashAllowUserName {
      font-weight: 500;
      color:#143051;
      margin-left:15px;
      font-size:13px;
      text-align: left;
      line-height:16px;
      max-width: 150px;  
      min-width: 150px;  

      padding-right:20px;
      cursor: pointer;
    }

    .dashAllowUserNameMob {
      font-size: 12px;
      max-width: 100px;  
      min-width: 100px;  

    }

      .dashallowArrImg {
        max-width: 31px;
        margin-right: 10px;
        padding: 10px;
      }

      .dashAllowArrSel {
        transform: rotate(-90deg);
      }

      .dashAllowDataSec {
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* padding-left:15px; */
        min-width: 150px;
      }
/* harry */
.dashAllowItem {
  min-width: 90px;
  max-width: 90px;
  font-weight: 500;
  text-align: left;
}
.dashAllowItemTot {
  margin-right:14px;
  border-right:1px solid #bbcada
}


@media only screen and (min-width: 820px) {
  .dashAllowItem {
    min-width: 86px;
    max-width: 86px;
    font-weight: 500;
    text-align: left;
  }
  .dashAllowItemTot {
    margin-right:30px;
    border-right:1px solid #bbcada
  }
  
  
  }

.dashAllowItemTitle {
  font-size: 10px;
  color:#143051;
  font-weight: 400;
}

.dashAllowItemValue {
  font-size: 13px;
  margin-top:2px;
  color:#214978
}



.dashAllowMain {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
}

.dashAllowsUserTable {
  /* background-color: yellow; */
  max-width: 80%;
  margin-left:15%;
  margin-top:10px;
  margin-bottom:20px;
}

@media only screen and (max-width: 820px) {
.dashAllowsUserTable {
  max-width: 90%;
  margin-left:5%
}

}
.dashAllowTableRow {
  /* background-color: blue; */
  display: flex;
  align-items: center;
  border-bottom:1px solid #bbcada;  
    color: #143051;
  font-weight: 500;
  font-size:14px;
}
.dashAllowsUserTable {
  /* border:1px solid #bbcada */
  cursor: default;
}

.dashAllowTableRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom:1px solid #bbcada;
  padding-bottom:8px;
  padding-top:8px;
  padding-left:10px;
  padding-right: 10px;
}

.dashAllowTableTitle {
  font-size: 12px;
  max-width: 100px;
  min-width: 100px;
  text-align: left;
  line-height: 12px;

}

.dashAllowTableItem {
  text-align: left;
}

.dashAllowTableItemTitle {
  font-size: 9px;
  font-weight: 400;
}

.dashAllowTableItemValue {
  color:#214978;
  font-size:12px;
}

.dashAllowTableItem {
  min-width: 70px;
  max-width: 70px;
  /* background-color: yellow; */
}

.dashAllowRightSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 210px;
  max-width: 210px;
}

.colourGreen {color:#82c26a !important}


.annLeaveDashMainRow {
  /* border-bottom:2px solid #214978; */
  padding-bottom:12px;
  border-bottom:none;
  /* margin-bottom:4px; */
}

.allLeaveDashController {
  min-height: 35px;
  max-height: 35px;
  border: 1px solid #bbcada;
  margin-bottom: 13px;
  border-radius: 5px;
  background: #f9fcff;
  margin-top: 10px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-left: 35%;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right:20px;
}

.dashAddDeductTitle {
  font-weight: 500;
  font-size: 12px;
  max-width: 160px;
  text-align: left;
  line-height: 12px;
  margin-left: 10px;
  color: #214978;
  display: flex;
  align-items: center;
  margin-right: 2px;

}


.yourEarningsDiv {
  display: flex;
  flex-direction: column;
  border: 1px solid #e3e8ee;
  border-radius: 5px;
  background: white;
  padding: 10px;
  margin-top: 10px;
  color: #143051;
  font-size: 12px;
  min-width: 100%;
  margin-bottom:20px;

}

.earnRow {
  border-bottom: 1px solid #e3e8ee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 30px;
  padding-left: 5px;
  padding-right: 5px;
}

.earnTitle {
  font-weight: 500;
}

.earnValue {
  font-weight: 500;
}

.disabledActivate {
  opacity: 0.1;
}

.editUserInactivated {
  border: 2px solid #ba0000;
  border-radius: 5px;
  max-width: 90%;
  margin-left: 5%;
  margin-top: 10px;
  color: #ba0000;
  font-weight: 500;
  padding:10px;
  font-size:14px;
  text-align: left;
}

.activateSideBox {
  /* background-color: blue; */
  min-width: 35vw;
  max-width: 35vw;
/* padding-left:10%; */
  /* border-right:1px solid #bbcada; */
  /* min-width: 100px; */
  /* margin-right:60px; */
  min-height:300px;
  /* padding-right:80px */
}

.resetPassPageDesktop {
  flex-direction: row-reverse;

}

.resetpassMain {
  flex-direction: column;
    min-width: 35vw;
  max-width: 35vw;
  /* justify-content: center; */
  display: flex;
  align-items: center;
}

.paddingRight10pc {
  /* padding-right: 10%; */
}

.activateImg {
  min-width: 250px;
  max-width: 250px;
}

.mobPassMain {
  min-width: 90vw;
  max-width: 90vw;
}

.flexGpsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.avgTool {
  font-weight: 600;
  color: #143051;
  cursor: pointer;
}


.dashAllowEditBtnHolder {
  min-width: 100%;
  display: flex;
  justify-content: flex-end;
  /* padding-right: 5%; */
  border-bottom: 1px solid #e0ebf8;
  /* margin-top: 8px; */
  padding-bottom: 10px;
}


.toilBal {
  text-align: right;
  margin-right:10px;
}


.toilWeeksLimitTxt {
  text-align: left;
  /* border-left: 1px solid #cbd7e6; */
  padding-left: 5px;
}
.toilWeeksLimTitle {
  font-weight: 500;
  color:#bbcada;
  font-size:10px;
}

.toilWeeksLimValue {
  font-weight: 500;
  font-size:12px;
  color:#143051
}

.dashTilList {
  max-width: 300px;
  min-width: 300px;
  margin-left: calc(100% - 300px);
  padding-top: 20px;
  background: #f4f8fe;
  padding-left: 10px;
  padding-right: 10px;
  /* border-radius: 0 0 10px 10px; */
  border-radius:10px;
  border: 1px solid #e0ebf8;
  /* border-top: none; */
}

.dashTilListItem {
  background-color: white;
  border:1px solid #dee5f0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom:10px;
  padding-top:10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.x248924894 {
  background-color: #e7f1ff;
}


@media only screen and (min-width: 820px) {


.dashTilListItem:hover {
  border-color:#bbcada
}
.x248924894:hover {
  border-color:#dee5f0
}
}

.dashTilListItemType {
  font-weight: 500;
  font-size:10px;
  color:#bbcada;
  text-align: right;
  max-width: 148px;
}

.dashTilListItemRight {

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color:#143051;
  margin-right:10px;

}

.dashTilListItemLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color:#143051;
  margin-left:10px;
}

.dashTilListItemDs {
  font-size:12px;
  font-weight: 500;
}

.dashTilListItemTimeStrip {
  font-size: 12px;
  color: #82c26a;
  font-weight: 500;
  text-align: left;
  margin-top: 3px;
  min-width: 126px;
}


  .dashTilListItemDurMins {
    font-weight: 500;
    font-size: 14px;
    color: #82c26a;
}



.convertSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: calc(5% + 5px);
  padding-right: calc(5% + 5px);
  margin-top: 12px;
}

.convertOptionTitle {
  font-weight: 500;
  color:#143051;
  font-size: 12px;
  margin-left:8px;
}

.convertOptionBar {
  display: flex;
align-items: center;
justify-content: space-between;
}

.convertOption {
  background-color: white;
}

.convertKey {
  margin-top:7px;
}

.convertKeyTitle {
  font-size: 11px;
  margin-bottom: 13px;
  margin-top: 3px;
}

.convertToilAnnLeaveDaysSub {
  font-size: 12px;
  color: #bac5d3;}

  .fromToToilTitle {
    text-align: left;
    max-width: 130px;  }

    .toiConvertNewBalance{
      color: #0077ff;
      font-weight: 500;
      font-size: 14px;
      margin-right: 26px;  }


      .toilconvertPreviewDaySpan {
        font-size: 12px;
        color: #bac5d3;
        font-weight: 500;
        margin-right: 24px;
        margin-top: 2px;
        min-width: 77px;
        text-align: center;
          }


.toilConvertInputDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top:5px;
}

.toilConvRowMid {
  min-width: 90%;
  border-left: 2px solid #00ccff;
  border-right: 2px solid #00ccff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 90%;
  margin-left: 5%;
}
.convertArrImg {
  width: 20px;
  margin-right: 64px;
  margin-top: 10px;}




.toilConvertInputDivBtns {
  display: flex;
  align-items: flex-end;
}

    .tilConvMins {
      font-size: 10px;
      font-weight: 500;
      color: #bbcada;
      margin-left: 3px;
      margin-bottom: 10px;    }

      .toilHeaderTxtSmaller {
        font-size:14px;
        min-height:22px;
        max-height:22px;
        line-height:24px;
      }

      .disabled {
        opacity: 0.3;
        /* pointer-events: none; */
      }

      .x238929842122 {
        margin-bottom:10px;
      }

      .itemconvAddedBy {
        font-size:10px;
        margin-top:2px;
      }

      .convArrinline {
        max-width: 10px;
        margin-bottom: 0px;
        margin-right: 4px;
     }

        .minWith76 {
          min-width: 76px;
        }

  


.enableTilRowBtn {
  min-height: 35px;
  border: 1px solid #bbcada;
  margin-bottom: 13px;
  border-radius: 5px;
  background: #fff;
  margin-top: 8px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  /* margin-left: 35%; */
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  padding-left: 9px;
  font-size: 12px;
  font-weight: 500;
  color: #214978;
  border-radius: 5px;
  border: #e3e8ee solid 2px;
}


.enableTilRowDiv {
  min-width: 100%;
  display: flex;
  justify-content: flex-end;
}

.tilRowKnob {
  margin-top: -7px;
  margin-right: 10px;
margin-left:10px
}

.dashTilType {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 8px;
  border-left: 2px solid #bbcada;
  text-align: left;
  padding-left: 6px;}

  .padRight62 {
    padding-right:62px;

  }

  .dashTilRowTable {
    /* display: flex; */
    /* justify-content: space-between; */
    /* min-width: 100%; */
  }

  .dashProfDashBtn {
    margin-top: 3px;
    min-height: 28px;
    max-height: 28px;
    font-size: 12px;
    max-width: 64px;
    padding-left: 1px;
    margin-left: 12px;
    padding-right: 0px;}

    .dashNameProfSectionSel {
      margin-top:10px
    }
    .dashNameProfSection {
      /* margin-top:-20px; */
      /* padding-top:20px; */
    }

    .dashAllowProPicSel {
      margin-top:-16px;
    }


  .toilDashEditProf {
    min-height: 35px;
    max-height: 35px;
    min-width: 81px;
    margin-top: 8px;
    /* margin-left: -10px; */
    /* margin-right: 21px; */
  }

  .deleteToilConvBtn {
    border: solid 1px #bbcada;
    background-color: #cbd7e6;    color: white;
    font-weight: 500;
    font-size: 12px;
    margin-top: 8px;
    border-radius: 5px;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
  }


  .userAllowToilConvRow {
    max-width: 90%;
    margin-top: 10px;
    margin-left: 15px;
  }

  .userAllowsConversionsArr {
    border-left: 2px solid #bbcada;
    margin-top: 10px;
    margin-bottom: 10px;  }


    .additionalMinsTxtMobLieu {
max-width:85%    }


.padRight20 {
  padding-right:25px;
}

.toilHelpBtns {
  display: flex;
  align-items: center;
}

.toilDashHelpbtn {
  border: solid 1px #bbcada;
  min-height: 35px;
  background-color: #cbd7e6;
  min-width: 66px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 9px;
  padding-right: 9px;
  font-weight: 500;
  color: white !important;
  font-size: 14px;
  border-radius: 5px;
  margin-right: 7px;
}

.toilPopup {
  max-width: 14px;
  margin-left: 2px;
}

.markAbsShLeft {
  /* background-color: blue; */
}

.markAbsShType1 {
  font-size: 10px;
  opacity: 0.5;
  max-height: 14px;
  line-height: 13px;
  text-align: left;
  font-weight: 500;
}

.weekklyEarningsDrop {
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  padding-left: 8px;
  text-align: left !important;
  max-width: 69px;
  min-width: 70px;
  line-height: 26px;
  height: 30px;
  border: 1px solid #9374d6;
  -webkit-appearance: none;
  background-position-x: 66px;
  display: flex;
  cursor: pointer;
  border-color: #2c7eb9;
  border: 1px solid #e6eaee;
  /* background-color: #0bf; */
  background-color: white;
  color: #143051;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 60px;
  margin-bottom: 0px;
  border: none;
}

.noFolders {
  color:#bbcada;
  font-size:10px
}

.moveToFolderDrop {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 10px;
  text-align: left !important;
  max-width: 149px;
  min-width: 149px;
  line-height: 26px;
  height: 35px;
  /* border: 1px solid #e6eaee; */
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  /* border-color: #2c7eb9; */
  border: 2px solid #dde9f9;
  /* background-color: #0bf; */
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 128px;
}

.myDocsTitle {
  margin-right:8%;
}

.myDocsModalBox {
  background-color: #214978;
  border-color:#143051
}

.x4289489231 {
  background-color: #143051;
}
.x4289489231:hover {
  background-color: #143051;
  opacity: 0.8;
}

.myDocsIsAdminTxt {
  color: white;
  font-size: 12px;
  font-weight: 400;
  padding-right: 15%;
  text-align: left;
  margin-bottom: 15px;
  padding-left: 8%;
}

.uploadDisclaim {
  font-size: 10px;
  max-width: 85%;
  text-align: left;
  margin-left: 8%;
  margin-bottom: 15px;
  color: #516b8b;
  font-weight: 400;
}

.myDocsBox {
  background-color: #456a98;
  min-height: calc(100vh - 400px);
  max-height: calc(100vh - 400px);

  max-width: 94%;
  margin-left: 3%;
  border:1px solid #7d9ec8;
  border-radius:5px;
  margin-top:10px;
  overflow-y:auto
}

.myDocsFooter {
  min-height: 40px;
  /* border-top: #ffffff1c solid 2px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-left: 5%; */
  /* padding-right: 5%; */
  max-width: 90%;
  margin-left: 3%;
  margin-bottom: 7px;
  margin-top: -3px;
  padding-left:4%
}


.myDocsFolderRow {
  background-color: #567cab;
  border-bottom: 1px solid #919ec4;
  min-height: 50px;
display: flex;
align-items: center;
justify-content: space-between;
color:white;
font-weight: 500;
font-size: 14px;
cursor: pointer;
}

.myDocsFolderRowActive {
  border-bottom:1px solid #567cab
}


@media only screen and (min-width: 820px) {
.myDocsFolderRow:hover {
  background-color: #6692ca;
}

}


.myDocsFolderRowLeft {
  display: flex;
  align-items: center;
  text-align: left;
}
.myDocsFolderRowRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right:15px;
}
.myDocsFolderImg {
  max-width: 22px;
  margin-left:10px;
  margin-right:10px;
}

.myDocsFolderQty {
  font-size: 10px;
  opacity: 0.5;
  /* margin-right:10px; */
}

.myDocsWhiteTriImg {
  max-width: 10px;
  margin-left:10px;
}

.myDocsWhiteTriImgActive {
  transform: rotate(-90deg);
}

.myDocsFileName {
  font-size:10px;
  max-width: 180px;
}

.myDocsFolderOpen {
  /* background-color: blue; */
  /* border-bottom:1px solid #919ec4; */

  margin-bottom:4px;
}

.myDocsFolderRowIndent {
  max-width: 95%;
  margin-left:5%;
  border-left:3px solid #919ec4;

}

.myDocsFolderRowIndentFinal {
  border-bottom:3px solid #919ec4;

}

.noFilesInSub {
  text-align: left;
  color:white;
  margin-left:10%;
  font-size:12px;
  font-weight: 500;
  margin-top:8px;
  margin-bottom:8px;
  opacity: 0.5;
}

.firstMyDocsFile {
  margin-top:10px
}

.myFileDs {
  font-size:10px;
  color:#143051
}

.docFileType {
  font-size:10px;
  color:#143051
}

.docFileRight {
  text-align: right;
}

.x238942223 {
  color:#bbcada
}

.x32453234332 {
  color:#516b8b;
  max-width: 90%;
  margin-left:5%
}

.x318924982423 {
  font-size: 10px  !important;
  color:#bbcada  !important
}

.convArr {

}

.jukl {
  max-width: 10px;
  margin-left: 5px;
  margin-right: -1px;

}

.myUplFolder {
  margin-bottom:10px;
}

.dashTilKey {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom:15px
}

.dashTilKeyTitle {
  font-weight: 500;
  font-size:12px;
  color:#516b8b;
  padding-bottom:8px;
  cursor: pointer;
}

@media only screen and (min-width: 820px) {

  .addUserBulkRow:hover {
    background-color: #bbcada;
  }
.dashTilKeyTitle:hover {
  color:#214978;
  border-bottom: 2px solid #d8e9ff;
  padding-bottom:6px
}

.dashTilKeyTitleActive:hover {
  color: #143051;  
  border-bottom:2px solid #143051

}
.addMultiUserBtn:hover {
  background-color: #a58fd4;
}

}
.dashTilKeyTitleActive {
  font-weight: 500;
  color:#143051;
  padding-bottom:6px;
  border-bottom:2px solid #143051
}

.tilListNil {
  color:#bbcada;
  font-size:12px;
  text-align: left;
  font-weight: 500;
  max-width: 90%;
  margin-left:5%;
  margin-top:20px;
  margin-bottom:20px;
  border-left: 2px solid #bbcada;
  padding-left: 8px;
}

.addMultiUserBtn {
  background-color: #b49ce7;
  border: 1px solid #a47fd0;
  display: flex;
  color: #fff;
  /* min-height: 36px; */
  padding-left: 11px;
  padding-right: 11px;
  border-radius: 5px;
  text-align: center;
  /* margin-left: 9px; */
  /* margin-right: 28px; */
  /* margin-right: 108px; */
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  max-height: 35px;
  min-height: 35px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  align-items: center;
}

.bulkAddUserModal {
min-width: 800px !important;
}

.bulkAddUsersBox {
  min-width: 100%;
  min-height: 210px;
  border: solid 1px #e6eaee;
  border-top: solid 2px #e6eaee;
  border-bottom: none;
  border-radius: 6px 6px 0px 0px;
  overflow: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  background-color: #f4f8fe;
  max-height:600px;
  overflow-y: auto;
}

.multiUserRow {
     /* display: flex; */
    /* align-items: center; */
    /* justify-content: space-between; */
    padding-left: 10px;
    padding-right: 15px;
    border: 4px solid #214978;
    min-height: 100px;
    background: white;
    margin-top: 4px;
    border-radius: 10px;


}

.multiUserRowFNameBlock {
  /* background-color: blue; */
  margin-right:8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
}

.multiRowUserTitle {
  font-size: 12px;
  line-height: 22px;
  color: #143051;
  font-weight: 500;
  margin-left: 3px;
  padding-top: 8px;
  padding-right: 20px;
  text-align: left;
}

.multiNameInputter {
  margin-left: 0px;
  min-width: 130px;
  max-width: 130px;
  margin-top:2px;
}

.multiEmailInputter {
  min-width: 272px !important;
  max-width: 272px !important;
}


.invalidMultiEmailInput {
  border-color:#ebf1f9
}

.addUserPre {
  min-height: 0px !important;
  max-height: 260px !important;
  overflow:hidden !important
}

.multiRowLeft {
  display:flex;

}

.multiRowRight {
  display:flex;
justify-content: flex-end;
/* margin-right:20px; */
}
.multiUserRowDateBlock {
  margin-top:0px;
}
.multiUserStartDate {
  margin-top:2px;
  margin-bottom:0px;
  margin-left:0px;
  background-color: #fdfeff;
 border:2px solid #dde9f9;
  border-radius:5px;
  color:#143051
  /* max-height:166px; */
  /* min-height:166px */
  /* margin-right: 3px; */
}

.x384298821 {
  margin-left:15px;
}

.multiUserRow1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
}

.multiUserRow2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid #dde9f9;
  min-width: 100%;
  margin-top: 10px;
}

.multiUserRow3 {
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  border-top:1px solid #dde9f9;
  margin-left:-18px
}

.addUserBulkRow {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  max-width: 120px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 10px;
  /* margin-right: 35px; */
  margin-bottom: 15px;
}



.partOfTeamsDivBulk {
  /* background-color: blue; */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  /* flex-direction: column; */
  /* margin-left: 5%; */
  margin-top: -6px;
  /* margin-bottom: 8px; */
  /* min-height: 200px; */
}


.bulkTeamSel {
  /* background-color: yellow; */
  margin-left:0px;
  margin-top:10px
}

.marginTop2 {
  margin-top:2px;
}

.multiTeamBlock {
max-width:270px;
}

.bulkAddTeamFirst {
  font-size:12px;
  font-weight: 500;
  color:#bbcada;
  margin-top:12px;
  padding-left: 8px;
  border-left:2px solid #bbcada
}

.multiUserRow3Block {
  max-width: 98%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #dde9f9;
}

.row3BlockLeft {
  width: 80%;
  margin-left: 10px;}

.row3BlockRight {
  width: 14%;
  align-items: center !important;
  margin-top: 33px;}


  .removeMultiUser {
    background-color: #516b8b;
    color: white;
    border: 1px solid #214978;
    border-radius: 5px;
    font-weight: 500;
    font-size: 12px;
    min-height: 28px;
    display: flex;
    align-items: center;
    padding-left: 7px;
    padding-right: 7px;
    margin-top: 10px;
    cursor:pointer
  }

  .removeMultiUser:hover {
    background-color: #486f9e;
  }

  .primBulkDrop {
    margin-top:2px
  }

  .multiJtInput {
    max-width: 180px;
  }

  .color00aaff {
    color:#00aaff
  }

  .addUserBulkRowNil {
    margin-top:80px
  }

  .addUserSingleImg {
    max-width: 15px;
    margin-left: -4px;
    margin-right: 6px;  }

    .addUserSingleImgSingle {
      margin-left: -7px;
      margin-right: 2px;  
    }

    .borderRed {
      border-color:#ba0000
    }

    .multiMetric {
      margin-top: 7px;
      font-size: 12px;
      line-height: 13px;    }


      .newUserBlob {
        min-width: 12px;
        min-height: 57px;
        color: #0af;
        border-radius: 5px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 13px;
      }

