@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #0077ff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.leaveReqUpperContainer {
  max-width: 92vw;
  min-width: 92vw;
  display: block;
  /* position: absolute; */
  margin-left: auto;
  margin-right: auto;
  /* background-color: black;
  opacity: 0.3; */
}

.teamGradientOut {
  height: 35px;
  width: 31;
  z-index: 1;
  position: absolute;
  right: 13px;
  top: 75px;
}

h1 {
  font-family: 'Work Sans';
  color: #143051;
  font-weight: 500;
  font-size: 1.5em;
  margin-top: 45px;
  margin-left: 4px;
}

.txtMyRequests {
  display: flex;
}

.txtTeamRequests {
  display: none;
}

.submenu {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  font-family: 'Work Sans';
  color: #143051;
  padding-left: 4px;
  padding-right: 4px;
  /* background-color: black; */
  min-height: 44px;
}

.upcoming,
.past,
.declined {
  color: #516b8b;
  font-weight: 300;
}

.sel {
  color: #143051;
  font-weight: 400;
}

.team {
  color: #fff;
  min-height: 40px;
  font-weight: 400;
  background-color: #9a74eb;
  border-radius: 25px;
  padding: 8px 12px;
  margin-top: -5px;
}

.teamHidden {
  visibility: hidden;
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #4b1caf;
  position: relative;
  right: -40px;
  margin-top: -20px;
}

/* .backRequests {
  min-width: 80px;
  min-height: 20px;
  display: inline-flex;
  position: relative;
  margin-top: 92px;
  margin-left: -5px;
} */

.teamsXbar {
  display: block;
  width: 100%;
  /* justify-content: space-around; */
  align-items: center;
  font-family: 'Work Sans';
  color: #143051;
  position: relative;
  top: 14px;
  padding: 0px 4px;
  /* background-color: black; */
  min-height: 44px;
  overflow: scroll;

  white-space: nowrap;
}

.teamsXbar::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent;
}

.teamsInnerContainer {
  max-width: auto;
  display: inline-flex;
  justify-content: space-between;
  margin-top: 0px;
  float: left;
  font-size: 0.9em;
}

.teamName {
  margin-left: 0px;
  min-width: auto;
  color: white;
  opacity: 0.4;
}

.teamSel {
  color: white;
  border-bottom: 1px solid #b091f5;
  padding-bottom: 3px;
  opacity: 1;
  font-weight: 400;
}

.teamMenuHolder {
  padding-right: 20px;
  padding-bottom: 3px;
}

teamMenuHolder:nth-last-child(1) {
  padding-right: -20px;
}

.backGroup {
  display: inline-flex;
  margin-top: 38px;
  padding-bottom: 12px;
  float: left;
}

.TeamRequests {
  color: rgb(255, 255, 255);
  font-family: 'Work Sans';
  font-size: 1.3em;
  font-weight: 400;
  margin-left: 9%;
  z-index: 0;
  float: left;
  opacity: 1;
  margin-top: 34px;
}

.txtTeamReqs {
  display: flex;
  min-width: 200px;
  color: #fff;
  font-weight: 400;
  float: right;
  opacity: 0.4;
  position: absolute;
  top: -32px;
  right: 0%;
}

.iconsM {
  position: absolute;
  top: -25px;
  right: 0%;
  width: 100px;
}

.checklist {
  margin-top: 58px;
  max-width: 28px;
  margin-left: -20px;
  opacity: 0.6;
}

.cog {
  max-width: 25px;
  position: relative;
  margin-left: 20px;
  opacity: 0.6;
  padding-bottom: 2px;
  cursor: pointer;
}

.inactive {
  opacity: 0.1;
  pointer-events: none;
}

@media only screen and (max-device-width: 373px) {
  .TeamRequests {
    font-size: 1.15em;
    padding-top: 1px;
    margin-left: 22px;
  }
}

.iconSelected {
  opacity: 1;
  z-index: 9999999009999999999;
}

.purpDivider {
  width: 100%;
  height: 3px;
  background: #143051;
  background: linear-gradient(to top, #8b7baa, #9a74eb);
  position: absolute;
  top: 111px;
}

.returnBack {
  min-width: 17px;
  margin-left: 10px;
}

.loadBlank {
  width: 100vw;
  height: 100vh;
  background-color: white;
  position: absolute;
  top: -58px;
  z-index: 99999999999999;
}

.cornerRounded1 {
  max-width: 12px;
  position: absolute;
  top: 114px;
  left: 0;
}

.cornerRounded2 {
  max-width: 12px;
  position: absolute;
  top: 114px;
  right: 0;
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

/* Remove up/down arrows in number box */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
  -webkit-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
}

* {
  font-family: "timelify", "Windlesham Pro", "Work Sans", sans-serif;
  /* font-style: "Regular 6"; */
  margin: 0;
  padding: 0;
  overflow: auto;
}

.lifeEasier {
  font-size: 0.8em;
  margin-top: 25px;
  color: #143051;
}

.signUpBackground {
  background: linear-gradient(to right, #e6f1ff, #fdfeff);
  /* min-height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100vw;
  min-height:100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.hideSignIn {
  display: none;
}

.signUpContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 350px;
  min-width: 350px;
  border: 1px solid #dde9f9;
  background: white;
  border-radius: 10px;
  min-height: 450px;
  max-height: 450px;
  /* padding-bottom: 10px; */
}

.signUpLogo {
  max-width: 40px;
  margin-top: 12px;
}

.signUpForm {
  padding: 6% 0px 0% 0px;
  /* margin-top: 16px; */
  color: #143051;
}

/* signup button */
.signinBtn {
  background-color: #00ccff;
  color: white;
  width: 100px;
  height: 35px;
  line-height: 37px;
  margin: auto;
  border-radius: 100px;
  margin-top: -10px;
  cursor: pointer;
  font-weight: 500;
}

/* signin button */
.signinBtn2 {
  background-color: #07f;
  border: #0469de 1px solid;
  color: white;
  width: 89px;
  height: 40px;
  display: flex;
  margin: auto;
  border-radius: 5px;
  margin-top: -35px;
  cursor: pointer;
  align-items: center;
  font-weight: 600;
  justify-content: center;
  margin-left: 20px;
}

.signinBtn2:hover {
  background-color: #0c72e7;
}

.hide {
  opacity: 0.2;
  cursor: none;
  /* pointer-events: none; */
  background-color: #143051;
}

.signinInput {
  width: 90%;
  text-align: center;
  font-size: 16px;
  /* font-family: work sans, sans-serif; */
  color: #143051;
  background-color: #f8fbff;
  border: none;
  border: 1px solid #8ea7c5;
  border-radius: 5px;
  /* margin-top: -10px; */
  min-height: 45px;
  /* font-size: 1.2em; */
  font-weight: 600;
  box-shadow: 0px 2px 4px rgb(221, 228, 231);
  vertical-align: center;
}

#password {
  width: 90%;
  text-align: center;
  font-size: 20px;
  /* font-family: work sans, sans-serif; */
  color: #143051;
  background-color: #f8fbff;
  border: none;
  border: 1px solid #8ea7c5;
  border-radius: 5px;
  /* margin-top: 12px; */
  min-height: 45px;
  /* font-size: 1.2em; */
  font-weight: 600;
  box-shadow: 0px 2px 4px rgb(221, 228, 231);
  vertical-align: center;
}

.xx {
  display: none;
}

input[type="password"]::-webkit-input-placeholder, .signinInput::-webkit-input-placeholder {
  font-size: 16px;
  color: #e0ebf8;
  text-align: left;
  text-indent: 10px;

  /* vertical-align: center; */
}

input[type="password"]:-ms-input-placeholder, .signinInput:-ms-input-placeholder {
  font-size: 16px;
  color: #e0ebf8;
  text-align: left;
  text-indent: 10px;

  /* vertical-align: center; */
}

input[type="password"]::placeholder,
.signinInput::placeholder {
  font-size: 16px;
  color: #e0ebf8;
  text-align: left;
  text-indent: 10px;

  /* vertical-align: center; */
}
/* 
input:focus {
  width: 80%;
  animation-name: widen;
  animation-duration: 0.1s;
}

@keyframes widen {
  from {
    width: 70%;
  }
  to {
    width: 80%;
  }
} */

input {
  margin-bottom: 0px;
  height: 25px;
  outline: none;
}

.signinFooterContainer {
  position: absolute;
  top: 0;
  display: flex;
  height: 50px;
  min-width: 100vw;
}

.signinFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5%;
      padding-right: 5%;
      min-height:40px;
}

.linkContainer {
  max-width: 90vw;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-family: work sans, sans-serif;
  font-size: 0.85em;
  padding-bottom: 10px;
  color: #143051;
  position: fixed;
  top: 15px;
  left: 0px;
  min-width: 100vw;
  z-index: 1;
}

.backBtnSignUp {
  margin-left: 20px;
  width: 15px;
}

#signuplink {
  background-color: #143051;
  border-radius: 15px;

  height: 25px;
  font-size: 0.9em;
  max-width: 80px;
  line-height: 25px;
  padding: 0 15px 0 15px;
  color: white;
  margin-right: 20px;
}

.page2bg {
  background-color: #143051;
  min-height: 100vh;
  min-width: 100vw;
  position: absolute;
  top: 0;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  z-index: 2;
}

.formFName {
  color: white;
  margin-top: 40px;
  font-weight: bold;
  text-align: left;
  text-indent: 7%;
}

.forgotContainer {
  pointer-events: none;
  display: flex;
  z-index: -100;
  max-width: 90%;
  /* background-color: yellow; */
  justify-content: flex-end;
  margin: auto;
}

.forgotPass {
  margin-top: 18px;
  font-size: 12px;
  font-weight: 500;
  /* width: 60px; */
  text-align: right;
  pointer-events: auto;
}

.firstNameText,
.lastNameText {
  color: white;
  margin-top: 20px;
  padding-bottom: 15px;
  font-weight: 500;
  font-size: 1.2em;

  text-align: left;
  text-indent: 7%;
}

.formCompanyName {
  color: white;
  margin-top: 55px;
  padding-bottom: 15px;
  text-align: left;
  text-indent: 7%;
  font-weight: 500;
  font-size: 1.2em;
}

.formYrStartDate {
  color: white;
  margin-top: 50px;
  text-align: left;
  margin-left: 7%;
  font-weight: 500;
  font-size: 1.2em;
}

input#fName,
input#lName,
input#companyName,
input#formStartDate,
input#teamName {
  width: 90%;
  text-align: left;
  font-size: 0.9em;
  font-family: work sans, sans-serif;
  color: #143051;
  background-color: #f8fbff;
  border: none;
  border: 1px solid #8ea7c5;
  border-radius: 5px;
  margin-top: -10px;
  min-height: 45px;
  font-size: 1.2em;
  font-weight: 400;
  /* box-shadow: 0px 2px 4px rgb(221, 228, 231); */
  vertical-align: center;
  text-indent: 10px;
}

input#fName::-webkit-input-placeholder, input#lName::-webkit-input-placeholder, input#companyName::-webkit-input-placeholder, input#formStartDate::-webkit-input-placeholder, input#teamName::-webkit-input-placeholder {
  font-size: 16px;
  color: #5d6f85;
  text-align: left;
  text-indent: 10px;
}

input#fName:-ms-input-placeholder, input#lName:-ms-input-placeholder, input#companyName:-ms-input-placeholder, input#formStartDate:-ms-input-placeholder, input#teamName:-ms-input-placeholder {
  font-size: 16px;
  color: #5d6f85;
  text-align: left;
  text-indent: 10px;
}

input#fName::placeholder,
input#lName::placeholder,
input#companyName::placeholder,
input#formStartDate::placeholder,
input#teamName::placeholder {
  font-size: 16px;
  color: #5d6f85;
  text-align: left;
  text-indent: 10px;
}

.signUpEmailInput {
  font-size: 1em;
  text-align: left;
  text-indent: 10px;
}

input#password {
  font-size: 1em;
  text-align: left;
  text-indent: 10px;
}

.showPassword {
  /* margin-top: 10px; */
  /* position: fixed; */
  /* left: 7%; */
  max-width: 25px;
  display: flex;
  margin-top: -35px;
  margin-left: 83%;
  fill: yellow;
}

input#daySelect {
  max-width: 45px;
  border-radius: 1px;
  margin-top: 30px;
  text-align: center;
  padding-top: 10px;
  min-height: 35px;
  padding-bottom: 10px;
  font-size: 1.2em;
  border: none;
  border-bottom: 1px solid white;
  background-color: #143051;
  color: white;
}

input#daySelect::-webkit-input-placeholder {
  color: white;
}

input#daySelect:-ms-input-placeholder {
  color: white;
}

input#daySelect::placeholder {
  color: white;
}

select#monthSelect {
  border-radius: 15px;
  background-color: #214978;
  min-height: 35px;
  font-size: 1em;
  padding-left: 15px;
  color: white;
  width: 135px;
  font-size: 16px;
  line-height: 1;
  border: 0;
  border-radius: 15px;
  height: 30px;
  background: url(/static/media/downArrowWhite.97cd93aa.svg) no-repeat right #214978;
  -webkit-appearance: none;
  background-position-x: 108px;
}

select#daySelectDropdown {
  border-radius: 15px;
  background-color: #214978;
  min-height: 35px;
  font-size: 1em;
  padding-left: 15px;
  color: white;
  width: 75px;
  font-size: 16px;
  line-height: 1;
  border: 0;
  border-radius: 15px;
  height: 30px;
  background: url(/static/media/downArrowWhite.97cd93aa.svg) no-repeat right #214978;
  -webkit-appearance: none;
  background-position-x: 52px;
}

#monthLabel {
  margin-left: 20px;
  padding-right: 30px;
}

#startDayLabel {
  padding-right: 30px;
  margin-left: -19px;
}

.startendContainer {
  margin-top: -17px;
  margin-left: -65px;
}

select {
  width: 135px;
  font-size: 16px;
  line-height: 1;
  border: 0;
  border-radius: 5px;
  height: 30px;
  background: url(/static/media/downArrowWhite.97cd93aa.svg) no-repeat right #143051;
  -webkit-appearance: none;
  background-position-x: 108px;
}

.page2Text,
.page3Text {
  color: white;
  font-size: 1.2em;
  margin-top: 15px;
}

.page3bg,
.page4bg,
.page5bg,
.page6bg,
.page7bg,
.page8bg {
  background-color: #143051;
  color: white;
  min-height: 100vh;
  min-width: 100vw;
  position: fixed;
  top: 0;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  z-index: 2;
}

.dayDoesNotExist {
  margin-top: 44px;
  font-weight: 300;
  font-style: italic;
}

.hidePage {
  display: none;
}

.nextBtn {
  background-color: #0077ff;
  color: white;
  width: 100px;
  height: 35px;
  line-height: 37px;
  margin: auto;
  border-radius: 100px;
  /* position: absolute;
  top: 218px;
  left: 50%; */
  /* transform: translate(-50%, -50%); */

  /* left: 50%; */
  margin: auto;
  margin-top: 30px;
  cursor: pointer;
  font-weight: 500;
}

.goodToGo {
  margin-top: 100px;
}

.goodToGo2 {
  margin-top: 12px;
}

.nextBtnTeams {
  background-color: #0077ff;
  color: white;
  width: 100px;
  height: 35px;
  line-height: 37px;
  margin: auto;
  border-radius: 100px;
  /* position: absolute;
  top: 218px;
  left: 50%; */
  /* transform: translate(-50%, -50%); */

  /* left: 50%; */
  margin: auto;
  margin-top: 20px;
  cursor: pointer;
  font-weight: 500;
}

.nextBtnInactive {
  background-color: #8b8b8b;
  color: white;
  width: 100px;
  height: 35px;
  line-height: 35px;
  margin: auto;
  border-radius: 100px;
  margin-top: 60px;
  pointer-events: none;
}

.visZero {
  visibility: hidden;
}

/* AUTOFILL STYLES */
/* autofill for email and password */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-text-fill-color: #143051;

  -webkit-transition: background-color 5000s ease-in-out 0s;

  transition: background-color 5000s ease-in-out 0s;
}

input#fName:-webkit-autofill,
input#fName:-webkit-autofill:hover,
input#fName:-webkit-autofill:focus,
textarea#fName:-webkit-autofill,
textarea#fName:-webkit-autofill:hover,
textarea#fName:-webkit-autofill:focus,
select#fName:-webkit-autofill,
select#fName:-webkit-autofill:hover,
select#fName:-webkit-autofill:focus,
input#lName:-webkit-autofill,
input#lName:-webkit-autofill:hover,
input#lName:-webkit-autofill:focus,
textarea#lName:-webkit-autofill,
textarea#lName:-webkit-autofill:hover,
textarea#lName:-webkit-autofill:focus,
select#lName:-webkit-autofill,
select#lName:-webkit-autofill:hover,
select#lName:-webkit-autofill:focus,
input#companyName:-webkit-autofill,
input#companyName:-webkit-autofill:hover,
input#companyName:-webkit-autofill:focus,
textarea#companyName:-webkit-autofill,
textarea#companyName:-webkit-autofill:hover,
textarea#companyName:-webkit-autofill:focus,
select#companyName:-webkit-autofill,
select#companyName:-webkit-autofill:hover,
select#companyName:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #143051 inset;
  -webkit-text-fill-color: white;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.signUpTeamsContainer {
  display: block;
  margin-top: 20px;
}

.signUpTeamsCollection {
  display: flex;
  justify-content: center;
  overflow: auto;
}

.signUpTeamUnit {
  /* background-color: ; */
  margin-top: 10px;
  display: flex;
}

.signUpRemoveTeamImg {
  margin-left: 22px;
  margin-top: 1px;
  cursor: pointer;
}

input#signUpTeamName {
  max-width: 60%;
  min-width: 200px;
  border-radius: 1px;
  margin-top: 30px;
  text-align: center;
  padding-top: 10px;
  min-height: 35px;
  padding-bottom: 15px;
  font-size: 1.2em;
  border: none;
  border-bottom: 1px solid white;
  background-color: #143051;
  color: white;
}

.listTeamNameText {
  font-size: 1.2em;
}

.addTeamBtnSignup {
  cursor: pointer;
  margin-left: 10px;
  width: 25px;
}

.chapterParent {
  width: 100vw;
  display: block;
}

.chapterContainer {
  min-height: 8px;

  width: 100%;
  /* margin-top: 60px; */
  display: flex;
  position: absolute;
  top: 15px;
  right: 0px;
  justify-content: center;
  z-index: 999;
}

.chap {
  width: 6px;
  height: 6px;
  background-color: white;
  opacity: 0.7;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 100%;
  margin-top: 1px;
}

.chapHighlighted {
  background-color: white;
  width: 8px;
  height: 8px;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 100%;
}

.addMoreLater {
  font-size: 1em;
  font-weight: 300;
  /* font-style: italic; */
  margin-top: 6px;
  text-align: left;
  text-indent: 7%;
}

/* .downloadBar {
  min-height: 55px;
  min-width: 100vw;
  background-color: #e0ebf8;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.downloadBarContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #143051;
}

#mobIcon {
  max-width: 17px;
}

.downloadBarText {
  font-size: 0.8em;
  margin-left: 10px;
}
/* 
#logotext {
  max-width: 45px;
  margin-left: 3px;
  margin-top: 40px;
} */

.alternativeIn {
  background-color: #143051;
  max-width: 86px;
  min-height: 30px;
  min-width: 70px;
  line-height: 30px;
  color: white;
  border-radius: 35px;
  font-size: 1em;
  margin: auto;
  margin-top: 10px;
  font-weight: 500;
}

.signupDivider {
  min-height: 1px;
  max-width: 90%;
  min-width: 90%;
  margin: auto;
  background-color: #dadada;
  margin-top: 180px;
}

.signinDivider {
  min-height: 1px;
  max-width: 90%;
  min-width: 90%;
  margin: auto;
  background-color: #e0ebf8;
  /* margin-top: 146px; */
}

.emailExists {
  padding-bottom: 20px;
  font-size: 0.9em;
  color: #ba0000;
  font-weight: 300;
  text-align: left;
  text-indent: 7%;
}

.passwordNotLongEnough {
  padding-bottom: 30px;
  font-size: 0.9em;
  color: #ba0000;
  font-weight: 300;
}

.wrongSignInEmail {
  padding-bottom: 22px;
  font-size: 0.8em;
  text-align: left;
  text-indent: 7%;
  color: #ba0000;
}

.wrongSignInPass {
  font-size: 0.8em;
  text-align: left;
  text-indent: 7%;
  color: #ba0000;
  margin-top: 10px;
}

.whenDidYouStartOptionsContainer {
  /* background-color: orange; */
  margin: 0 auto;
  margin-top: 30px;
  display: flex;
  max-width: 90%;

  justify-content: space-around;
  align-items: center;
}

.whenDidYouStartButton {
  max-width: 130px;
  text-align: center;
  background-color: #5d6f85;
  min-width: 130px;
  font-size: 1.1em;
  padding: 10px;
  border-radius: 15px;
  opacity: 0.8;
}

.whenDidYouStartSelected {
  border: solid 2px white;
  opacity: 1;
}

.startDateValSpan {
  font-weight: 500;
  font-size: 0.9em;
}

.perWeekSpan {
  background-color: #587597;
  padding: 3px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  white-space: nowrap;

  /* line-height: 28px; */
}
.startDateSpanNoBreak {
  white-space: nowrap;
  font-weight: 600;
  color: #00ccff;
}

.initUserMpwHistoryInput {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.mpwInputForNewUserHistory {
  width: 70px;
  margin-top: 10px;
  text-align: center;
  font-size: 0.9em;
  /* font-family: work sans, sans-serif; */
  color: #143051;
  background-color: #f8fbff;
  border: none;
  border: 1px solid #8ea7c5;
  border-radius: 5px;
  /* margin-top: -10px; */
  min-height: 45px;
  font-size: 1.2em;
  font-weight: 400;
  /* box-shadow: 0px 2px 4px rgb(221 228 231); */
  /* vertical-align: center; */
  /* text-indent: 10px; */
}

.initUserHoursMinsContainer {
  display: flex;
  align-items: center;
  min-width: 150px;
}

.hoursMinsLabelOnInitCreate {
  margin-left: 12px;
  color: #dadada;
  padding-top: 28px;
  font-size: 1.1em;
  /* background-color: blue; */
  min-width: 60px;
  text-align: left;
}

.manualDayInput {
  /* background-color: #31547c; */
  /* background-color: #8ea7c5; */
  border-radius: 15px;
  border: solid 1px #ffffff;
  padding-left: 12px;
  color: #31547c;
  /* color: white; */
  margin-top: 26px;
  margin-bottom: 10px;
  font-weight: 500;
  padding-top: 2px;
  background: url(/static/media/downArrowNavy.f0080f38.svg) no-repeat right #ffffff;
  -webkit-appearance: none;
  background-position-x: 108px;

  /* padding-bottom: 2px; */
  /* line-height: 28px; */
}

.signInTitle {
  font-weight: 600;
  color: #143051;
  font-size: 22px;
  text-align: left;
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 10px;
}

.signInInputTitle {
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  margin-left: 28px;
  margin-bottom: 6px;}


  .x20483085 {
    margin-top:20px;
  }

  .signInLogoImg {
    max-width: 51px;
    margin-left: 52px;  }

    .signInPrivacy {
      font-weight: 500;
      font-size: 12px;
      
      color: #bbcada !important;
      padding-bottom: 4px;
      cursor: pointer;
    }

    .swapRed {
      color:#ba0000
    }

    .swapGreen {
      color:#82c26a
    }
/* html {margin: 0; height: 100%; overflow: hidden} */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  display: block;
  justify-content: center;
  font-size: 16px;
  /* align-items: flex-end; */
  -webkit-user-select: none; /* Safari */ /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  /* position: fixed; */
  /* top: 0; */
  overflow: hidden;
  max-height: 100vh;
  /* position: fixed;
  top: 0;
  left: 0;
  max-width: 100vw;
  min-width: 100vw; */
}

.__web-inspector-hide-shortcut__ {
  display: none;
}

.reqsPageHolder {
  /* wraps the reqs page so no scroll */
  max-height: 100%;
  overflow: hidden;
  /* position: fixed;
  top: 0;
  left: 0; */
  min-width: 100vw;
  max-width: 100vw;
}

.reqsPage {
  /* background-color: grey; */
  /* max-height: 100%; */
  height: 100vh;
  max-height: calc(100% - 188px);
  /* max-height: 400px; */
}

.reqsPageMgr {
  height: 100vh;
  max-height: calc(100% - 188px);
}

.reqsPageUpperContainer {
  min-height: 117px;
  max-height: 117px;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-self: start;

  background-color: #fff;
  border-bottom:2px solid #e6eaee
}

.requestPageParent {
  background-color: #ebf0f7;
  min-width: 100%;
  min-height: 100vh;
}

.reqsPageUpperContainerTeam {
  min-height: 117px;
  max-height: 117px;
  /* padding-top: 10px; */
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-self: start;
  background-color: #403a8e;
  border-bottom: 1px solid #d1dfee;

  /* background-color: #9374d6; */
  /* background: rgb(75, 25, 172);
  background: linear-gradient(
    0deg,
    rgba(75, 25, 172, 1) 29%,
    rgba(98, 63, 173, 1) 98%
  ); */

  /* border-radius: 20px 20px 0 0; */
}

.reqsPageUpperInnerContainer {
  /* background-color: yellow; */
  min-width: 930px;
  max-width: 930px;
  margin-left: 0px;
  padding-left: 40px;
  /* margin: 0 auto; */
  /* max-height: 10px; */
  /* display: flex;
  align-items: flex-end; */
}




.requestsMenuBar {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 8px; */
  /* font-size: 1.025em; */
  color: #143051;
}

.requestsMenuBarDesktop {
  margin-bottom: 7px;
  margin-top: -2px;
  margin-left: -3px;
}




.backFromTeamReqsImg {
  max-width: 13px;
  margin-top: 10px;
  cursor: pointer;
  cursor: pointer;
  z-index: 99999;
  margin-left: 9px;
  margin-bottom: 1px;
}

.staffReqsTitleSpan {
  margin-top: 3px;
  font-size: 23px;
  font-weight: 500;
  margin-left: 16px;
  /* color: #143051 */
}

.staffRequestsTitle {
  color: #143051;
  font-weight: 500;
  font-size: 23px;
  /* margin-top: -4px; */
  text-align: left;
  margin-bottom: 2px;
}

.reqMenuLeft {
  width: 218px;
  display: flex;
  justify-content: space-between;
  color: #516b8b;
  font-weight: 300;
  margin-top: 9px;
}

.reqMenuTxt {
  cursor: pointer;
  color: #143051;
  font-size: 16px;
  /* margin-top: 1px */
}
.reqMenuTxt:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.reqTeamBtn {
  font-weight: 500;
  font-size: 16px;
  padding-left: 0px;
  cursor: pointer;
  /* min-width: fit-content; */
}



.reqMenuRight {
  display: flex;
  background-color: #a387df;
  border: 1px #8e76c1 solid;
  color: white;  min-height: 36px;
  line-height: 34px;
  padding-left: 9px;
  padding-right: -4px;
  border-radius: 5px;
  text-align: center;
  margin-left: 5px;
  margin-right: 8px;
  min-width: 68px;
  max-height: 35px;
  min-height: 35px;
  margin-top: 4px;
  font-weight: 500;
  cursor: pointer;
}

.cursorPointer {
  cursor: pointer;
}

.fadeReqActionDiv {
  opacity: 0.4;
  pointer-events: none;
  cursor: default;
}

.reqMenuRight:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.reqTeamDot {
  background-color: #4b1caf;
  /* background-color: #9a74eb; */
  /* background-color: #00CCff; */
  min-width: 5px;
  min-height: 5px;
  max-width: 5px;
  max-height: 5px;
  margin-top: 10px;
  position: fixed;
  margin-left: -7px;
  margin-top: 2px;
  border-radius: 15px;
  /* left: 18px; */
  /* margin-right: 10px; */
}

.upcomingTxt {
  min-width: 90px;
  max-width: 90px;
  /* font-size: 1.05em; */
  font-weight: 400;
  color: #143051;
  opacity: 0.8;

}

.pastTxt {
  /* background-color: blue; */
  min-width: 38px;
  max-width: 38px;
  /* font-size: 1.05em; */
  font-weight:400;
  color: #143051;
  opacity: 0.8;
}

.declinedTxt {
  /* background-color: lime; */
  min-width: 80px;
  max-width: 80px;
  /* font-size: 1.05em; */
  font-weight: 400;
  color: #143051;
  opacity: 0.8;

}

.reqMenuTxtSelected {
  font-weight: 500;

  color: #143051;
  max-height: 25px;
  border-bottom: 1px #e3e8ee solid;
  padding-bottom:3px;
  margin-top: 0px;
  opacity: 1;

  

}

.teamsSelectBar {
  display: flex;
  color: white;
  /* background-color: yellow; */
  align-items: flex-end;
  padding-bottom: 10px;
  min-height: 38px;
  max-width: 100%;
  overflow: scroll;
  white-space: nowrap;
  min-width: 100%;
  /* padding-: -2px; */
  /* padding-right: 200px; */
  -ms-overflow-style: none; /*IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* margin-left: 30px; */
  margin-top: -2px;
  margin-bottom: 2px;
}

.teamsSelectBarMob{
  margin-top: 8px;
  margin-bottom: -3px;
}
.emptyTeamForSpaceFromRight {
  /* background-color: yellow; */
  min-height: 5px;
  min-width: 70px;
}

.teamsSelectBar::-webkit-scrollbar {
  /* display: none; */
}

.teamReqsTxt {
  /* min-width: 500px; */
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  display: flex;
  color: #6a98d1;
  font-size: 16px;  
  min-height: 26px;
  margin-left: 20px;
  font-weight: 400;
  padding-bottom: 2px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.5s !important;
          animation-duration: 0.5s !important;
  cursor: pointer;
}

.teamReqsTxt:first-of-type {
  margin-left: 0px;
}

.teamReqsTxt:active {
  -webkit-transform: scale(0.96);
          transform: scale(0.96);
}

.teamReqsTxt:hover {
  color: #4f79ab
}

.teamReqSelected {
  color: #214978;
  border-bottom: 1px solid #e3e8ee;
    font-weight: 500;
  font-size: 16px;
  padding-bottom: 2px;
  /* margin-bottom: -1px; */
}

.navyFadeImg {
  position: fixed;
  top: 94px;
  right: calc(5% - 1px);
  pointer-events: none;
}

.requestsContainer {
  background-color: #ebf0f7;
  /* background-color: blue; */
  min-height: calc(100%);
  max-height: calc(100%);
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.mobReqsContainerFullWidth {
  min-width:calc(100vw + 4px);
  margin-left:-2px;
  border-radius:20px 20px 0 0;
  border-top:2px solid #e6eaee;
  border-left:2px solid #e6eaee;
  border-right:2px solid #e6eaee;

}

.mgrHoursShoulderBar {
  min-width:calc(100vw + 4px) !important;
  margin-left:-2px !important;
  border-radius:20px 20px 0 0  !important;
  /* border-top:2px solid #e6eaee !important; */
  border-left:2px solid #e6eaee !important;
  border-right:2px solid #e6eaee !important;
  min-height:108px !important;
  /* max-height:120px !important */
}



.purpleTopBorderTeam {
  /* border-top: 2px solid #7049c4; */
}

.bgColUpcoming {
  background-color: #0077ff;
  background-color: #143051;
  background-color: #fff;
}

.bgColTeam {
  background-color: #4b1caf;
  /* background-color: #9374d6; */
}

.fullHeightReqsBackdrop {
  min-height: 100vh;
  background-color: white;
  /* padding-bottom: 100px; */
}

.requestUnitPending {
  min-height: 72px;
  max-height: 72px;
  min-width: 90%;
  max-width: 90%;
  background-color: white;
  margin: 0 auto;
  /* -moz-box-shadow: 0 0 5px #ccc;
  -webkit-box-shadow: 0 0 5px #ccc;
  box-shadow: 0 0 5px #ccc; */
  border-radius: 5px;
  color: #143051;
  color: #484848;
  margin-top: 10px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  border: 1px solid #e7ebef;
  /* border-bottom: 2px solid #e7ebef; */
}

.requestUnitPending:active {
  /* min-height: 72px;
  max-height: 72px;
  min-width: 90%;
  max-width: 90%;
  background-color: white;
  margin: 0 auto;
  -moz-box-shadow: 0 0 12px #ccc;
  -webkit-box-shadow: 0 0 12px #ccc;
  box-shadow: 0 0 12px #ccc;
  border-radius: 12px;
  color: #143051;
  color: #484848;
  margin-top: 12px;
  opacity: 0.4; */
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}

.requestUnitPending:first-of-type {
  margin-top: 0px;
}

.reqContentContainerPending {
  display: flex;
  /* background-color: yellow; */
  min-height: 72px;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

.reqContentContainerPending:hover {
    background-color: #f1f6fb;
  
}

.requestUnitMgr {
  /* min-height: 100px; */
  /* padding-top: 13px; */
  margin-top: 4px;
  padding-bottom: 3px;
  /* max-width: 440px; */
  /* background-color: grey; */
}

.requestUnit {
  margin-top: 7px;
}

.reqLeftUnitIcon {
  min-width: 50px;
  max-width: 50px;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: black */
}
.pendingLeft {
  /* background-color: lime; */
  min-width: 50%;
  text-align: left;
  /* text-indent: 10%; */
  padding-top: 8px;
  /* padding-left: 11%; */
}

.leaveTypeNameTxt {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: -5px;
  margin-top: -7px;
  color: #496375;
  color: #143051;
  border-bottom: 1px solid #bfccdc;
  padding-bottom:3px;
  
  /* color: #00ccff; */
}

.reqUnitDateRangeTxt {
  font-size: 14px;
  margin-top: 8px;
  color: #95aaba;
  font-weight: 500;
  /* color: #143051; */
}

.pendingRight {
  text-align: right;
  min-width: 37%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* min-height: 40px; */
}

.estimatedTotalDuration {
  font-size: 12px;
  margin-top: -1px;
  background-color: #8ea7c5;
  /* background-color: #00ccff; */
  color: white;
  /* color: #496375; */

  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 15px;
  /* padding-top: 1px; */
  max-height: 24px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  /* margin-right: -6px; */
  font-weight: 400;

  max-width: fit-content;
  min-width: fit-content;
  /*  */
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 20px;
  line-height: 20px;
  align-items: center;
  border-radius: 5px;
  /* border: 1px solid #859dbb; */
  /* color: #496375; */
  font-weight: 500;
  font-size: 0.8em;
  padding-top: 0px;
  padding-bottom: 0px;
  /* background: rgb(247, 250, 255); */
}

.approvedExcess {
  /* background-color: #99c587;
  color: #fff;
  border-color: #6fa05c; */
  /* color: white; */
}

.declinedExcess {
  /* background-color: #9e7a7a; */
}

.contractedTotal {
  margin-right: 4px;
  font-size: 16px;
  font-weight: 500;
  color: #496375;
  padding-bottom: 3px;
  /* color: black; */
  /* color: #647583; */
  color: #143051;
}

/* .moveRightABit {
  margin-left: 12px;
} */

.ofyourAllow {
  margin-top: 7px;
}

.pendingSplitter {
  background-color: #bbcada;
  min-width: 80%;
  min-height: 2px;
  max-width: 80%;
  margin-left: 10%;
  margin-top: 20px;
  margin-bottom: 25px;
  border-radius: 5px;
}

.requestUnitApproved {
  min-height: 72px;
  max-height: 72px;
  min-width: 90%;
  max-width: 90%;
  background-color: rgb(255, 255, 255);
  margin: 0 auto;
  /* border: 1px solid #d9d9d9; */
  border: 1px solid #e7ebef;
  /* border-bottom: 2px solid #e7ebef; */
  /* border: 1px solid rgba(89, 89, 89, 0.267); */

  border-radius: 5px;
  color: #143051;
  color: #484848;
  margin-top: 8px;
  /* min-height: 80px; */
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.requestUnitApproved:first-of-type {
  /* margin-top: -10px; */
}

.showReqs {
  /* padding-top: 110px; */
  padding-bottom: 30px;
  /* background-color: #1a406b; */
  /* margin-top: 2px; */
  /* padding-top: 62px; */
  min-height: calc(100vh - 213px);
  max-height: calc(100vh  - 213px);

      /* min-height: calc(100vh - 221px);
    min-height: calc(calc(var(--vh, 1vh) * 100) - 221px);
    max-height: calc(100vh - 221px);
    max-height: calc(calc(var(--vh, 1vh) * 100) - 221px); */
  overflow-y: scroll;
  overflow-x: hidden;
  border-right: 1px solid #e0ebf8;
padding-top: 15px;
min-width: 100%;
/* sctoll-to */



  /* margin-top: -19px; */
}

.formsShowReqs {
  min-height: calc(100vh - 208px) !important;
  max-height: calc(100vh  - 208px) !important;
}

.formsShowReqsMob {
  min-height: calc(100vh - 265px) !important;
  max-height: calc(100vh  - 265px) !important;
}
.myUpcomingReqsHeight {
  min-height: calc(100vh - 208px);
  max-height: calc(100vh - 208px);
  /* min-height: calc(-webkit-fill-available - 213px);
  max-height: calc(-webkit-fill-available - 213px); */

  /* height: 100vh; Fallback for browsers that do not support Custom Properties */
  /* height: calc(var(--vh, 1vh) * 100   - 213px); */
  /* background-color: yellow; */
}



.paddingTop20 {
  padding-top: 10px;
}

.showReqsWithoutFilterBar {
  /* padding-top: 110px; */
  padding-bottom: 20px;
  /* background-color: #1a406b; */
  /* margin-top: 2px; */
  padding-top: 62px;
  min-height: calc(100vh - 237px);
  max-height: calc(100vh - 237px);
  overflow-y: scroll;
  overflow-x: hidden;
  border-right: 1px solid #e0ebf8;
padding-top: 5px;
min-width: 100%;
  /* margin-top: -19px; */
}

.showReqsTeamUpcoming {
  min-height: calc(100vh - 213px);
  max-height: calc(100vh - 213px);
  /* background-color: blue; */
}


.showFilterTeamShowReqs {
  min-height: calc(100vh - 270px);
  max-height: calc(100vh - 270px);
}

.borderRightGrey {
  border-right: 1px solid #e0ebf8;

}

.killTopPadding {
  padding-top: 20px;
  margin-top: -8px;
}

.pullUpABit {
  /* margin-top: -18px; */
}

.outstandingPullUp {
  margin-top: 22px !important; 
  margin-left: 3px;
  margin-bottom: 10px !important;
}

.awaitingPullUp {
  /* margin-top: -12px !important; */
  margin-bottom: 12px;
}

.paddingTop0 {
  padding-top: 20px;
  margin-top: -8px;
}

.upcomingsSoloHolder {
  margin-top: -6px;
}

.reqContentContainerApproved {
  display: flex;
  min-height: 72px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}


.reqContentContainerApproved:hover {
  background-color: #f1f6fb;

}


.reqContentContainedDeclined:hover {
  background-color: #f1f6fb;

}

.requestUnitApproved:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}

.requestUnitDeclined:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}

.approvedLeft {
  min-width: 15%;
  min-height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.approvedMiddle {
  min-width: 50%;
  text-align: left;
  /* margin-top: -2px; */
  padding-top: 11px;
  min-height: 50px;
  /* background-color: blue; */
}

.approvedRight {
  background-color: pink;
  min-width: 36%;

  text-align: right;
  padding-right: 9.4%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.unitImgIcon {
  max-width: 15px;
}

.requestUnitDeclined {
  min-height: 72px;
  max-height: 72px;
  min-width: 90%;
  max-width: 90%;
  background-color: #fff;
  margin: 0 auto;
  /* border: 1px solid #d9d9d9; */
  border-radius: 5px;
  color: #143051;
  color: #484848;
  margin-top: 8px;
  /* border-bottom: 2px solid #dccaca; */
  border: 1px solid #e7ebef;
  /* border-bottom: 2px solid #e7ebef; */
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;

  /* opacity: 0.5; */
}

.requestUnitDeclined:first-of-type {
  margin-top: -10px;
}

.loadingReqsGifImg {
  max-width: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.reqInfoUnderlay {
  background: rgba(0, 0, 0, 0.25);
  /* max-height: calc(100% - 58px); */
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.reqInfoModalBox {
  background-color: #ebf0f7
;
  color: #143051;
  border-radius: 10px;
  min-height: 584px;
  max-height: 584px;
  min-width: 340px;
  max-width: 340px;
  /* -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  cursor: auto;
  border: 1px solid #e7ebef;
}

.userReqInfoModalBox {
  background-color: #ebf0f7
;
  color: #143051;
  border-radius: 10px;
  min-height: 520px;
  max-height: 520px;
  min-width: 340px;
  max-width: 340px;
  /* -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  cursor: auto;
  border: 1px solid #e7ebef;
}

.mobReqInfoModalBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 15px 15px 0 0;
  max-height: 90vh !important;
  min-height: 90vh !important;

}

.mobUserReqBody {
  min-height: calc(90vh - 110px) !important;
  max-height: calc(90vh - 110px) !important;
}

.mobMgrReqBodyLeft {
  min-height: calc(90vh - 148px) !important;
  max-height: calc(90vh - 148px) !important;
}

.zoomIn {
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.slideUp {
  -webkit-animation-name: moveEditTeamUserBoxUp !important;
          animation-name: moveEditTeamUserBoxUp !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.duplicateReqWarningModalBox {
  background-color: #f4f8fe;
  /* background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(244, 247, 252, 1) 35%
  ); */
  color: #143051;
  border-radius: 5px;
  min-height: 35px;
  position: absolute;
  top: 45%;
  left: 50%;
  /* max-height: 400px; */
  /* overflow: scroll; */
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  min-width: 300px;
  max-width: 300px;
  /* max-width: 290px; */
  /* min-width: 290px; */
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  cursor: auto;
}

.approvedModalBg {
  background-color: #ebf0f7;
  /* 
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(240, 247, 236, 1) 50%
  ); */
}

.declinedUnitOnMgrOnly {
  opacity: 0.4;
}

.declinedModalBg {
  background-color: rgb(245, 245, 245);
  /* background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 244, 244, 1) 50%
  ); */
}

.reqInfoHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: grey; */
  min-height: 40px;
  margin-top: 10px;
  /* border-bottom: 1px solid #e0ebf8;
  border-bottom: 1px solid #143051; */
}

.closeReqInfoModalImg {
  max-width: 15px;
  min-width: 15px;
  margin-left: 20px;
  cursor: pointer;

}

.closeReqInfoModalImg:hover {
  opacity: 0.8;
}

.reqInfoTitleTxt {
  margin-top: 0px;
  font-size: 18px;
  font-weight: 500;
  /* margin-right: 3px */
}

.reqInfoTitleTxtMy {
  margin-top: 0px;
  font-size: 1.2em;
  font-weight: 500;
  margin-right: 24px
}

.submittedReqTxt {
  padding-bottom: 2px;
  text-align: left;
  margin-left: 10%;
  color: #214978;
  font-size: 12px;
  font-weight: 500;
  /* text-transform: uppercase; */
}

.madeReqDaysAgo {
  /* font-weight: 500; */
  padding-bottom: 8px;
  text-align: left;
  /* margin-left: 10%; */
  margin-top: 22px;
}

/* .requestDurationValue {
  text-align: left;
  margin-left: 12%;
  color: #496375;
  font-size: 1em;
} */

.submissionDateTxt {
  font-weight: 400;
  padding-top: 4px;
  max-width: 88%;
  margin-left: 10%;
  color: #516b8b;
  padding-right: 18px;
  font-size: 12px;
  line-height: 16px;
}

.reqInfoFooter {
  min-height: 50px;
  max-height: 50px;
  /* background-color: blue; */
  max-width: 90%;
  margin: 0 auto;
  border-top: solid 1px #e3e8ee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 6px;
  padding-right: 0px;
}

.deleteRequestBtn {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #747474;
  min-height: 35px;
  line-height: 35px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 30px;
}

.defoDeleteRequestBtn {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #516b8b;
  color: white;
  min-height: 35px;
  line-height: 35px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 30px;
  font-size: 0.93em;
}

.closeReqInfo {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 35px;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  min-width: 62px;
  max-width: 62px;
}

.closeReqInfo:hover {
  background-color: #214978;
}

.reqInfoDuplicateContainer {
  /* background-color: #143051; */
  color: #143051;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 10px;
  margin: 0 auto;
  max-width: 80%;
}

.reqInfoTxtContainer {
  font-size: 0.9em;
  text-align: center;
  /* padding-left: 20px; */
}

.reqNotMadeTxt {
  font-weight: 500;
  font-size: 1.4em;
  color: #143051;
}

.reqNotMadeinfoTxt {
  /* padding-right: 10px; */
  padding-top: 20px;
  padding-bottom: 6px;
  font-size: 1em;
  color: #5d6f85;
  font-weight: 400;
}

.hmmEmoji {
  width: 45px;
}

.closeReqNotMadeNoteBtn {
  background-color: #36495f;
  border: 1px solid #143051;
  cursor: pointer;
  color: white;
  min-height: 30px;
  line-height: 28px;
  max-width: 75px;
  border-radius: 5px;
  margin: 0 auto;
  font-size: 0.9em;
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 500;
  /* padding-top: 2px; */
}

.reqOwnerNameTxt {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: -7px;
  /* margin-top: -1px; */
  color: #496375;
  /* color: #143051; */
  /* color: #9374d6; */
}

.mgrReqDurationString {
  margin-top: 10px;
  color: #859dbb;
  font-size: 14px;
  font-weight: 500;
}

.mgrSmallLeaveTypeNameTxt {
  font-size: 16px;
  margin-top: -8px;
  color: #9374d6;
  color: #496375;
  padding-left: 3px;
}

.mgrSmallLeaveDurationTxt {
  font-size: 14px;
  margin-top: 7px;
  color: #859dbb; /* font-size: 0.9em; */
  padding-left: 3px;
  font-weight: 500;
}

.rightMgrReq {
  min-width: 40%;
  padding-top: 0px;
  margin-top: -2px;
  /* margin-right: 2px; */
  /* padding-right: 2px; */
}

.leftMgrReq {
  max-width: 450%;
  min-width: 45%;
  padding-left: 0px;
  margin-left: -14px;
  /* background-color: orange; */
}

.teamReqsHeaderContainer {
  display: flex;
  justify-content: space-between;
  /* min-height: 110px; */
  margin-top: 30px;
  /* padding-top: 60px; */
  /* background-color: pink; */
  margin-bottom: 0px;
}

.teamReqsHeaderContainerMob {
  margin-top: 44px;
  margin-bottom: -3px;
}

.teamReqsHeaderLeft {
  /* background-color: orange; */
  display: flex;
  min-width: 70%;
  align-items: center;
  /* padding-top: 70px; */
  margin-top: 74px;
}

.teamReqsHeaderLeftMob {
  margin-top: 90px;
}

.teamReqsHeaderLeft {
  /* padding-top: 90px; */
  margin-left: -9px
}

.teamReqsHeaderRight {
  /* background-color: black; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 100px;
  max-width: 100px;
  padding-top: 62px;
}

.checklistAllows {
  /* max-width: 30px; */
  opacity: 0.6;
  margin-top: 5px;
  cursor: pointer;
}

.checklistActive {
  opacity: 1;
  margin-top: 5px;
  cursor: pointer;
}

.checklistAllows:active {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.checklistActive:active {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  /* max-width: 5px; */
}

.reqCog {
  max-width: 30px;
  margin-top: -5px;
  margin-right: 10px;
  opacity: 0.6;
  cursor: pointer;
}

.reqCogActive {
  max-width: 30px;
  margin-top: -5px;
  margin-right: 10px;
  opacity: 1;
  cursor: pointer;
}

.reqCogActive:active {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.reqCog:active {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  /* max-width: 5px; */
}

.checklistImgHolder {
  max-width: 30px;
  min-width: 30px;
  margin-top: 5px;
}

.staffReqModalContentContainer {
  /* background-color: lime; */
  display: flex;
  min-height: 120px;
  position: relative;
  /* max-height: 100%; */
  height: 100%;
  /* height: 180px; */
  /* overflow: scroll; */
  max-width: 90%;
  min-width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  padding-top: 4px;
  padding-bottom: 0px;
  /* border-bottom: 1px solid #bac5d3 */
  /* padding-left: 10%; */
}

.staffReqModalContentLeft {
  background-color: white;
  text-align: left;
  max-width: 73%;
  min-width: 73%;
  /* margin-left: -1px; */
  padding-left: 10px;
  padding-right: 3px;
  padding-top: 3px;
  padding-bottom: 10px;
  min-height: 180px;
  max-height: 437px;
  min-height: 437px;
  overflow-y: scroll;
  /* overflow-x: none; */
  border-top: solid 2px #e6eaee;
  border-right: solid 2px #e6eaee;
  border-left: solid 2px #e6eaee;

  border-radius: 6px 6px 0px 0px;
}

.staffReqModalContentLeftScroll {
  background-color: white;
  text-align: left;
  max-width: 40%;
  min-width: 58%;
  margin-left: 10%;
  padding-left: 10px;
  padding-right: 3px;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: space-around; */
  padding-top: 15px;
  padding-bottom: 2px;
  min-height: 180px;
  max-height: 300px;
  overflow: scroll;
  border-top: solid 2px #e6eaee;
  border-right: solid 1px #e6eaee;
  border-left: solid 1px #e6eaee;

  border-radius: 15px 15px 0px 0px;
}

.staffReqModalContentRight {
  /* background-color: yellow; */
  max-width: 26%;
  min-width: 26%;
  max-height: 429px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.staffReqApproveBtn {
  color: #143051;
  padding-left: 14px;
  padding-right: 14px;
  font-weight: 500;
  font-size: 16px;
  padding-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  min-width: 100%;
}

.staffReqApproveBtn:hover {
  background-color: #e7eef8;
}

.shortStaffTxt {
  line-height: 20px;
}

.staffReqDeclineBtn {
  color: #143051;
  padding-left: 14px;
  padding-right: 14px;
  font-weight: 500;
  font-size: 16px;
  padding-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  min-width: 100%;
}

.staffReqDeclineBtn:hover {
  /* color: #213855; */
  background-color: #e7eef8;

}


.sureDeclineBtn {
  min-height: 35px;
  font-size: 1em;
  line-height: 38px;
  padding-left: 14px;
  padding-right: 14px;
  max-width: 95px;
  padding-top: 4px;
  border-radius: 30px;
  font-weight: 500;
  font-size: 1.1em;
  color: #ba0000;

}

.staffReqViewBtn {
  min-height: 35px;
  background-color: #143051;
  line-height: 35px;
  color: white;
  max-width: 95px;
  border-radius: 30px;
}

.mgrRequestModalBottomBar {
  min-height: 1px;
  min-width: 100%;
  max-width: 100%;
  margin: 0 auto;
  background-color: #8298b1;
  display: none;
}

.staffReqFooterContainer {
  display: flex;
  min-width: 100%;
  max-width: 100%;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
  padding-bottom: 12px;
  padding-top: 8px;
  max-height: 50px;
  min-height: 50px;
  border-top: 1px solid #bac5d3;

}

.staffReqFooterContainerMob {
  position: fixed;
  bottom: 2px
}

.reqFooterUnit {
  min-width: 45%;
  min-height: 57px;
  border-right: 1px #8298b1 solid;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reqFooter50pcW {
  min-width: 50%;

}

.reqFooterUnit:last-child {
  border: none;
}

.reqFooterNoBorder {
  border: none;
}

.viewStaffReqImg {
  max-width: 40px;
  min-width: 40px;
  margin: 0 auto;
  margin-top: -28px;
  /* margin-bottom: 18px; */
  /* margin-top: -10px; */
}

.staffReqUnderStaffedBox {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid #d7e1ee;
  padding-bottom: 14px;
  padding-top: 14px;
  min-width: 100%;
}
.staffReqUnderstaffedInfo {
  font-size: 0.9em;
  margin-left: 10px;
  font-weight: 300;
  color: #496375;
}

.staffReqUnderstaffDotGreen {
  min-width: 7px;
  max-width: 7px;
  min-height: 7px;
  max-height: 7px;
  border-radius: 10px;
  background-color: #6db451;
}

.staffReqUnderstaffDotRed {
  min-width: 7px;
  max-width: 7px;
  min-height: 7px;
  max-height: 7px;
  border-radius: 10px;
  background-color: #ba0000;
}

.staffReqSubmittedAgo {
  /* margin-top: 5px; */
  margin-left: 10px;
  padding-top: 16px;
  color: #496375;
  font-size: 1em;
  font-weight: 500;
  padding-bottom: 15px;
}

.declineReqFirstBtn {
  color: #516b8b;
  padding-left: 14px;
  padding-right: 14px;
  max-width: 95px;
  border-radius: 30px;
  font-weight: 500;
  font-size: 1.1em;
  padding-top: 4px;
}

.staffReqNote {
  /* margin-top: 5px; */
  /* margin-left: 10px; */
  padding-left: 10px;
  padding-right: 3px;
  padding-top: 16px;
  color: #496375;
  color: #0077ff;
  font-size: 1em;
  font-weight: 500;
  max-width: 95%;
  padding-bottom: 15px;
  border-top: 1px solid #d7e1ee;
}

.staffReqDeclineReasonTxt {
font-weight:500;
  margin-left: 6px;
  padding-top: 0px;
  color: #8ea7c5;
  font-size: 14px;
  padding-right: 12px;
  padding-bottom: 18px;
}

.editDateBarContainerFlex {
  display: flex;
  /* background-color: yellow; */
  /* max-width: 180px; */
  max-height: 60px;
}

.inlineEditReqDateImg {
  max-width: 12px;
  margin-left: 12px;
  position: relative;
  /* margin-top: -2px; */
  padding-bottom: 1px;
  opacity: 0.6;
  /* z-index: 9999999; */
}
.inlineEditReqDateImg:hover {
  opacity: 1
}


.inlineEditReqDateImg2 {
  max-width: 10px;
  margin-left: 6px;
  position: relative;
  /* margin-top: -2px; */
  margin-bottom: -1px;
  /* opacity: 0.6; */
  margin-right: -3px;
  cursor: pointer;
}
.inlineEditReqDateImg2:hover {
  /* opacity: 1 */
}

.staffReqModalDatesString {
  color: #143051;
  /* background-color: #496375; */
  /* color: white; */
  margin-right: 10px;
  border-bottom: 1px solid #d7e1ee;
  padding-bottom: 14px;
  padding-top: 14px;
  min-width: 96%;
  /* max-width: fit-content; */
  padding-left: 6px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  /* border-radius: 10px 10px 0 0; */
}

.staffReqDuration {
  /* margin-top: 5px; */

  /* margin-left: 10px; */
  /* border-bottom: 1px solid #d7e1ee; */
  padding-bottom: 14px;
  padding-top: 14px;
  min-width: 96%;
  max-width: 96%;
  padding-left: 6px;
  font-weight: 500;
  color: #143051;
  font-size: 16px;
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.staffReqBtn {
  background-color: #a2bddf;
  min-height: 24px;
  line-height: 24px;
  max-width: 48px;
  min-width: 48px;
  color: white;
  border-radius: 40px;
  margin: 0 auto;
  margin-top: 14px;
  font-size: 0.9em;
  font-weight: 500;
}

.declineReasonViewBtn {
  background-color: #a2bddf;
  min-height: 24px;
  line-height: 24px;
  max-width: 48px;
  min-width: 48px;
  color: white;
  border-radius: 40px;
  margin: 0 auto;
  margin-top: 14px;
  font-size: 0.7em;
}

.reqDurationAndDates {
  display: flex;
  justify-content: center;
  max-width: 80%;
  margin: 0 auto;
}

.requestSubHeader {
  display: flex;
  justify-content: space-between;
  max-width: 86%;
  /* margin: 0 auto; */
  align-items: center;
  margin-left: 6%;
  padding-top: 5px;
  margin-bottom: -1px;
}

.leaveTypeSubBubble {
  /* background-color: #d2e6fd; */
  /* background-color: #496375; */
  /* border: 1px solid #496375; */
  border: 1px #e6eaee solid;
  color: #496375;
  /* margin: 0 auto; */
  padding-left: 13px;
  padding-right: 13px;
  min-height: 28px;
  line-height: 27px;
  font-size: 12px;
  /* margin-top: 9px; */
  margin-bottom: 8px;
  border-radius: 5px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-weight: 500;
  padding-top: 0px;
  padding-bottom: 1px;
  background-color: white;
  /* animation-name: zoomModalIn !important;
  animation-duration: 0.4s !important; */
}

.reqNoteBtn {
  background-color: #214978;
  border: solid 1px #143051;
  color: #fff;
  min-width: 60px;
  max-width: 60px;
  min-height: 35px;
  max-height: 35px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* line-height: 31px; */
  font-weight: 500;
  font-size: 14PX;
  margin-bottom: 6px;
  cursor: pointer;
}

.reqNoteBtnMgr {
  background-color: #8f71d1;
  border: #6c4bb5 1px solid;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  min-width: 60px;
  max-width: 60px;
  min-height: 35px;
  max-height: 35px;
  border-radius: 5px;
  line-height: 29px;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 6px;
  cursor: pointer;
}

.reqNoteBtnMgr:hover {
  background-color: #785cb6;
}

.noteNotViewedDot {
  min-width: 4px;
  max-width: 4px;
  min-height: 4px;
  max-height: 4px;
  background-color: #fff;
  border-radius: 100%;
  margin-right: -2px;
  margin-top: -20px;
  margin-left: 48px;
  margin-right: -50px;
}

.noteViewedBg {
  background-color: #fff;
  /* background-image: linear-gradient(to bottom, rgb(245, 250, 255), #d7e8fc); */
  color: #143051;
  border-color: #8298b1;
  color: #214978;
  min-height:35px;
}
.reqNoteBtnNoteImg {
  max-width: 11px;
  margin-right:4px;


}
.viewReqTeamCal {
  background-color: #1f5087;
  border: solid 1px #143051;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  min-width: 60px;
  max-width: 60px;
  min-height: 35px;
  max-height: 35px;
  border-radius: 5px;
  padding-bottom: 1px;
  /* line-height: px; */
  font-weight: 500;
  font-size: 14px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  color: #fff;
cursor:pointer;
  /* margin-bottom: 4px; */
}

.viewReqTeamCal:hover {
  background-color: #bbcada;
}
.approvedDeclinedBubble {
  background-color: #a2bddf;
  color: white;
  /* margin: 0 auto; */
  padding-left: 9px;
  padding-right: 9px;
  min-height: 30px;
  line-height: 27px;
  font-size: 12px;
  /* margin-top: 3px; */
  margin-bottom: 8px;
  border-radius: 5px;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  font-weight: 500;
  padding-top: 0px;
  
 
}

.greenBubble {
  background-color: #a8cc9a;
  border: 1px solid #6db451;

}

.redBubble {
  background: none !important;
  border-radius: 0px !important;
  /* border: 1px solid #e89292; */
border:none !important;
  border-left:2px solid #e89292 !important;
  color: #ba0000 !important;
  padding-right:0px;
}

.navyBubble {
  /* background-color: #214978; */
  /* border: 1px solid #143051; */
  color: #bbcada !important;
  border:2px dashed #bbcada !important;
  background-color: white !important;
  border-radius:5px  !important;
  min-height:30px !important;
  max-height:30px !important;
  line-height:25px !important
}
.declineReasonContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
  min-width: 100%;
  /* padding-left: 10%; */
  margin-bottom: 2px;
  /* min-height: 224px; */
}

.mobDeclineReasonContainer {
  min-height: 212px;
  max-height: 212px;

}

.declineReasonInput {
  margin: 0 auto;
  min-width: 100%;
  min-height: 144px;
  max-height: 144px;
  resize: none;
  /* padding: 10px; */
  border: 1px solid #8997a8;
  border-radius: 5px;
  background-color: white;
  color: #143051;
  overflow: scroll;
  padding: 10px;
  border: 1px solid #dde9f9;
  border-radius: 5px;
  background-color: white;
  font-size: 16px;
  overflow: scroll;
  line-height: 20px;
  font-weight: 500;
  color: #6a8bb1;
  margin-bottom: 19px;
}

.declineReasonInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 6px;
  background-color: #fdfeff;
  padding:9px;
}

.mobDeclinReasonInput {
  min-height: 124px;
  max-height: 124px;
}

.pushDown2px {
  margin-top: 2px !important
}

.declineReasonSpanOptional {
  /* font-style: italic; */
  color: #516b8b;
  font-weight: 400;
  font-size: 0.8em;
}

*,
*:active,
*:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  -webkit-focus-ring-color: rgba(0, 0, 0, 0) !important;
  outline: none !important;
}

.declineReasonTitle {
  min-width: 100%;
  text-align: left;
  padding-bottom: 8px;
  font-weight: 500;
  color: #213855;
  margin-top: 20px;
  margin-left: 8px;
}

.cancelDecline {
  font-size: 1.1em;
  margin-top: 0px;
  color: #496375;
  font-weight: 500;
}

.backFromDeclineEditReqDecision {
  font-size: 1.1em;
  margin-top: 0px;
  color: #496375;
  font-weight: 500;
  padding-top: 4px;
}

.cancelEditDecisionBtnImg {
  margin-top: 1px;
  height: 16px;
  opacity: 0.5;
  margin-left: 10px;
  margin-right: 7%;
}

.reqApprovedBy {
  color: #496375;
  /* margin-left: 10px; */
  /* border-bottom: 1px solid #d7e1ee; */
  padding-bottom: 14px;
  padding-top: 14px;
  min-width: 96%;
  max-width: 96%;
  padding-left: 6px;
}

.approveByTxtName {
  font-size: 18px;
  font-weight: 500;
  color: #143051;
  /* font-size: 1.05em; */
  margin-top: 3px;
}

.approveByTxtTitle {
  font-size: 1.1em;
  padding-bottom: 2px;
  color: #214978;
  font-size: 12px;
  /* text-transform: uppercase; */
  font-weight: 540;
}

.modalReqDecisionImg {
  margin-right: 25px;
  /* margin-top: 3px; */
  min-width: 20px;
  /* visibility: hidden; */
}

.noBorderBottom {
  border-bottom: solid 0px white;
}

.changeDecisionButton {
  font-weight: 500;
  font-size: 14px;
  color: #592eb6;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  /* display: flex; */
  min-height: 35px;
  max-height: 35px;
  /* line-height: 26px; */
  background-color: #0077FF;
  border: 1px solid #636f7e;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  cursor: pointer;
  color: white;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;

  display: flex;
  align-items: center;
  justify-content: center;
}

.changeDecisionButton:hover {
  background-color: #1875df;
}

.editDecBg {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
}

.editDecBg:hover {
  background-color: #bbcada;
}
.disableOverflow {
  overflow: none !important;
}

.emptyFooter {
  opacity: 0;
  pointer-events: none;
  min-height: 20px;
  max-height: 20px;
}

.mgrReqHistoryBtn {
  /* background-color: black; */
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  position: fixed;
  top: calc(100% - 148px);
  border-radius: 100px;
  left: 45px;
  opacity: 0.9;
}

.mgrReqHistoryBtnFade {
  /* background-color: black; */
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  position: fixed;
  top: calc(100% - 148px);
  border-radius: 100px;
  left: 45px;
  opacity: 0.7;
}

.filterReqsBarContainer {
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 7%;
  max-height: 60px;
  margin-top: -10px;
  padding-bottom: 8px;
  /* paddin */
}

.mgrUpcomingOrPastTitle {
  margin-top:22px;
  margin-bottom: -8px;
  padding-left: 10%;
  color: #496375;
  font-weight: 500;
  font-size: 1.3em;
}

.filterBox {
  /* background-color: pink; */
  display: flex;
  padding-left: 6%;
  padding-top: 8px;
  padding-bottom: 3px;
  justify-content: flex-end;
  margin-right: 7%;
  /* margin-top: 10px; */
}

.filterReqsBtn {
  font-size: 0.95em;
  margin-top: 8px;
  color: #496375;
  border: 1px solid #496375;
  border-radius: 5px;
  min-height: 28px;
  line-height: 28px;
  padding-left: 10px;
  font-weight: 500;
  padding-right: 10px;
  padding-top: 2px;
  margin-top: 20px;
  cursor: pointer;
}

.filterReqsBtnActive {
  font-size: 0.95em;
  margin-top: 8px;
  color: #fff;
  border: 1px solid #143051;
  display: flex;
  background-color: #214978;
  border-radius: 5px;
  align-items: center;
  min-height: 28px;
  line-height: 28px;
  padding-left: 12px;
  padding-right: 12px;
  margin-left: -22px;
  font-weight: 500;
  padding-top: 2px;
  margin-top: 20px;
  cursor: pointer;
}

.nameFilterDropdown {
  color: #6a8bb1;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 188px;
  min-width: 188px;
  line-height: 26px;
  height: 30px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
    #fff;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  background-position-x: 164px;
  display: flex;
  cursor: pointer;
  /* margin-right: 12px; */
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  /* padding-right: 27px; */
}

.outstandingPullUpABit {
  margin-top: -10px;
  margin-bottom: -12px;
}

.filterAppDecContainer {
  /* background-color: orange; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-right: 7%;
}

.unitCrossBlueImg {
  min-width: 20px;
  margin-right: 10px;
}

.unitTickBlueImg {
  min-width: 20px;
  margin-right: 12px;
}

.closeFilterImg {
  max-width: 10px;
  margin-left: 8px;
  margin-top: -2px;
}

.upcomingPastBar {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  min-width: 110px;
  /* background-color: yellow; */
  margin-left: 10%;
  margin-top: 26px;
  font-weight: 500;
  font-size: 1.3em;
}

.upcomingPastInactive {
  /* background-color: black; */
  color: #bac5d3;
  cursor: pointer;
  /* margin-bottom: -8px; */
}

.upcomingPastActive {
  /* margin-top: 8px; */
  /* padding-left: 10%; */
  color: #496375;
  font-weight: 500;
  cursor: pointer;
}

.upcomingPastSpacer {
  /* background-color: pink; */
  min-width: 10px;
}

.deleteLoadingOpacLow {
  opacity: 1;
  pointer-events: none;
  margin-top: 22px;
  margin-bottom: 22px;
  min-width: 40px;
  max-width: 40px;
}

.emptyLoaderSpace {
  min-width: 40px;
  max-width: 40px;
  /* min-height: 2px; */
  margin-top: 21px;
  margin-bottom: 20px;
  min-height: 25px;
}

.contractedTitle,
.excessTitle {
  margin-top: 16px;
  padding-bottom: 6px;
  text-align: left;
  margin-left: 10%;
  color: #214978;
  font-size: 12px;
  font-weight: 400;
  /* text-transform: uppercase; */
}

.approvedByTxt {
  margin-top: 16px;
  padding-bottom: 4px;
  text-align: left;
  margin-left: 10%;
  font-weight: 500;
  font-size: 12px;
  color: #859dbb;
  text-transform: uppercase;
}

.mgrReqLeaveEndStartTitle {
  margin-top: 16px;
  font-weight: 500;
  padding-bottom: 4px;
  text-align: left;
  margin-left: 6px;
  color: #214978;
  font-size: 12px;
  font-weight: 500;
}

.pushDownABit {
  margin-top: 2px;
}

.approvedByMarginTop {
  margin-top: 6px !important;
}

.understaffedMarginTop {
  margin-top: 8px !important;
  margin-bottom: -9px;}
.requestDurationInfoSplitter {
  background-color: #e6eaee;
  min-height: 1px;
  min-width: 86%;
  max-width: 86%;
  margin: 0 auto;
  margin-top: 14px;
}

.mgrLeaveStartEndSplitter {
  background-color: #d7e1ee;
  min-height: 1px;
  min-width: 95%;
  max-width: 95%;
  /* margin: 0 auto; */
  margin-top: 14px;
}

.mgrLeaveStartEndSplitter:last-child {
  /* display: none; */
}

.requestDurationValue {
  text-align: left;
  margin-left: 10%;
  font-weight: 500;
  color: #143051;
  font-size: 16px;
  margin-top: 0px;
}

.mgrReqStartEndVal {
  text-align: left;
  margin-left: 6px;
  font-weight: 500;
  color: #143051;
  font-size: 18px;
  margin-top: 4px;
}

.youWillBePaidForContainer {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 20px; */
}

.estimateDiscTxt {
  color: #bbcada;
  background-color: #fff;
  border:1px solid #bbcada;
  padding-left: 4px;
  opacity: 1;
  /* text-transform: uppercase; */
  padding-right: 4px;
  border-radius: 5px;
  /* text-transform: uppercase; */
  margin-left: 5px;
font-size:10px;
padding-bottom:1px;




  /* text-transform: uppercase; */
  /* margin-left: 5%; */
}

.lastSplitter {
  margin-top: 14px;
}

.cancelReqImgContainer {
  background-color: #0bf;
  border: 1px solid #0af;
  min-width: 80px;
  display: flex;
  border-radius: 5px;
  color: white;
  align-items: center;
  margin-left: -5px;
  padding-left: 3px;
  cursor: pointer;
  min-height: 35px;
}

.cancelReqImgContainer:hover {
  background-color: #0af;
}

.cancelReqImgContainerDefault {
  /* background-color: #333; */
  /* background-color: #fff; */
  margin-left: -5px;

  min-width: 80px;
  display: flex;
  /* border-radius: 40px; */
  /* margin-left: 10px; */
  color: white;
  align-items: center;
  justify-content: flex-start;
}

.cancelReqCancelTxt {
  font-size: 0.9em;
  line-height: 24px;
  /* background-color: blue; */
  min-height: 24px;
  padding-right: 8px;
  font-weight: 500;
  /* text-indent: 0px; */
}

.payableTxt {
  font-weight: 500;
  /* padding-bottom: 2px; */
  margin-left: 6px;
  margin-top: 14px;
  min-height: 26px;
  color: #859dbb;
  font-size: 0.92em;
  font-weight: 500;
  display: flex;
  align-items: center;

  

}


.x198419 {
  padding-bottom: 2px; 
    margin-left: 6px;
    margin-top: 14px;
    margin-bottom: -12px;
    color: #214978;
    font-size: 12px;
    font-weight: 400;
}
.allowanceUsedTxt {
  /* padding-bottom: 2px; */
  /* color: #214978; */
  /* margin-left: 5%; */
  margin-left: 6px;
  margin-top: 14px;
  margin-bottom: -12px;
  color: #214978;
  font-size: 12px;
  font-weight: 400;
  /* text-transform: uppercase; */
}

.excessMinsMgrReqVal {
  margin-top: -12px;
}

.mgrRequestExcessMinsConfirmedOrEstimatedSpan {
  color: #a2bddf;
  /* color: white; */
  /* margin-right: 12%; */
  /* font-size: 1.2em; */
  /* margin-top: 2px; */
  /* background-color: #8298b1; */
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0px;
  /* padding-bottom: 4px; */
  border-radius: 5px;
  line-height: 20px;
  margin-left: 8px;
  font-size: 0.9em;
  font-weight: 500;
  /* margin-top: -2px; */
  /* background-color: #143051; */
  /* min-height: 30px;
}
*/
}
.confirmedDiscBgOnly {
  background-color: #a8cc9a; 
  border-color:#87a67a;
  color: #fff;
}

.colorRed {
  /* color: #7e7575; */
}

.colorGreen {
  /* color: #7da36d; */
}

.pendingMgrReqBlueDot {
  /* background-color: #a2bddf; */
  background-color: #9374d6;
  /* background-color: #9a74eb; */
  min-width: 8px;
  max-width: 8px;
  min-height: 8px;
  border-radius: 10px;
  /* margin-right: -22px; */
  /* margin-left: 15px; */
}

.pendingMgrReqBlueDotUserPending {
  /* background-color: #a2bddf; */
  background-color: #143051;
  /* background-color: #9a74eb; */
  background-color: #00ccff;
  min-width: 8px;
  min-height: 8px;
  border-radius: 10px;
  /* margin-right: -22px; */
  /* margin-left: 15px; */
}
.approvedMgrReqBluedot {
  /* background-color: #a2bddf; */
  background-color: #b6daa8;
  /* background-color: #9a74eb; */
  min-width: 8px;
  min-height: 8px;
  border-radius: 10px;
  margin-right: -22px;
  margin-left: 15px;
}

.declinedMgrSmallTxt {
  color: #38084b;
}
.userPendingMainFontColour {
  color: #00ccff;
  color: #0077ff;
}

.editDatesModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  /* max-height: calc(100% - 58px); */
  min-height: 100vh;
  min-width: 100vw;
  max-height: 100vh;
  max-width: 100vw;
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.editDatesModalBox {
  background-color: white;
  color: #143051;
  border-radius: 5px;
  min-height: 35px;
  position: absolute;
  /* display: block; */
  min-height: 100px;
  /* top: 50%;
  left: 50%; */
  /* max-height: 320px; */
  /* overflow: scroll; */
  /* -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
  max-width: 350px;
  min-width: 350px;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
    -webkit-animation-name: zoomModalIn !important;
            animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  cursor: default;
}

.editDatesModalBoxMob {
  position: fixed;
  bottom: 0;
  border-radius: 5px 5px 0 0;
  min-width: 100vw;
  max-width: 100vw;
}

.editDateModalTitle {
  font-weight: 500;
  margin-top: 15px;
  font-size: 1.2em;
  margin-bottom: 26px;
}

.editDateStartTitle {
  margin-top: 20px;
  margin-bottom: 3px;
  font-size: 1em;
  color: #496375;
  font-weight: 500;
}
/* 
.editDateModalInput {
  border-radius: 30px;
  border: none;
  background-color: #8ea7c5;
  color: white;
  min-height: 35px;
  font-size: 1em;
  padding-left: 14px;
  padding-right: 8px;
  padding-top: 3px;
  letter-spacing: 1px;
  max-width: 174px;
  min-width: 174px;
  background: url("../img/general/calicon.svg") no-repeat right #8ea7c5;
  -webkit-appearance: none;
  background-position-x: 142px;
  border: 1px solid #36495f;
  font-weight: 500;
} */

.editDateModalInput {
  border-radius: 5px;
  border: 1px solid #dde9f9;
  background-color: #fff;
  color: #496375;
  min-height: 35px;
  font-size: 0.95em;
  font-weight: 500;
  padding-left: 12px;
  padding-right: 8px;
  padding-top: 0px;
  letter-spacing: 1px;
  max-width: 162px;
  min-width: 162px;
  background: url(/static/media/calicongrey.9c7e1aba.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 130px;
  margin-bottom: 8px;
  margin-top: 8px;
  cursor: text;
}
.editDatesModalFooterContainer {
  /* background-color: orange; */
  display: flex;
  font-weight: 500;
  justify-content: space-around;
  align-items: center;
  min-height: 60px;
  margin-top: 20px;
}

.cancelEditDateTxt {
  color: #143051;
  font-size: 16px;
  font-weight: 400;
  font-size: 14px !important;
  margin-right: 12px;
  cursor: pointer;
}

.cancelEditDateTxt:hover {
opacity: 0.8;
}

.submitEditDateBtn {
  font-size: 16px;
  color: #fff;
  background-color: #07f;
  border: #0469de 2px solid;
  font-weight: 500;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* line-height: 25px; */
  border-radius: 5px;
  min-height: 35px;
}

.submitEditDateBtn:hover {
  background-color: #0469de;
}

.overlappingEditDateTxt {
  font-size: 14px;
  max-width: 162px;
  margin: 0 auto;
  margin-top: 18px;
  color: #6a8bb1;
  font-weight: 500;
  margin-bottom: -0px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #dde9f9;  
  padding-bottom: 2px;
  padding-top: 2px;

  padding-left: 10px;
  padding-right: 0px;
  min-height: 38px;
  display: flex;
  justify-content: space-around;
}

.loadingChangingDatesImg {
  max-width: 40px;
}

.reqLoadingImg {
  max-width: 40px;
  min-width: 40px;
  margin-top: 6px;
}

.blackCornerBg {
  background-color: black;
  min-height: 20px;
  min-width: 100%;
  position: absolute;
  /* z-index: ; */
  display: none;
}

.pullUp15px {
  margin-top: -15px;
}

.reqTimelineDiv {
  /* background-color: blue; */
  min-width: 80px;
  min-height: 220px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: -12px; */
}

.timelineIcon {
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  border-radius: 5px;
  /* border: 1px solid #143051; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: inset -2px 0 0 #1c3f68, inset 0 -2px 0 #1c3f68,
    inset 2px 0 0 #1c3f68, inset 0 2px 0 #1c3f68;
  margin-top: 6px;

  /* animation-name: zoomModalIn !important;
  animation-duration: 0.4s !important; */
}

.mgrTimelineIcon {
  background: #effaff;
}

.timelineIconGreenBg {
  background-color: #f6fef3;
}

.timelineIconRegBg {
  background-color: #ffeeee;
}

.timelineIconAbsenceBg {
  background-color: #ecf7ff;
}
.pushDownIfSingle {
  margin-top: 40px;
}

.iconCalDateNumber {
  font-size: 1.45em;
  padding-top: 2px;
  letter-spacing: 1px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.halfDayIconAppend {
  font-size: 0.45em;
  margin-left: 3px;
  margin-top: 2px;
}

.iconBlob {
  background-color: white;
  border: 2px solid #3b597c;

  color: #143051;
  font-size: 0.96em;
  padding-top: 2px;
  padding-bottom: 4px;
  font-weight: 500;
  min-width: 100%;
  border-radius: 5px;
}

.middleTimelineBar {
  /* background-color: orange; */
  min-height: 72px;
  min-width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6px;
}

.timelineBarCircle {
  background-color: #8997a8;
  min-width: 5px;
  max-width: 5px;
  min-height: 5px;
  max-height: 5px;
  border-radius: 10px;
}

.timelineBarLine {
  min-height: 61px;
  min-width: 1px;
  margin-top: -1px;
  background-color: #8997ab;
}

.timelineDur {
  background-color: #fff;
  border: 2px solid #1c3f68;
  position: fixed;
  margin-top: 21px;
  border-radius: 5px;
  font-size: 0.8em;
  font-weight: 500;
  color: #1c3f68;
  padding-top: 4px;
  /* padding-bottom: 2px; */
  padding-bottom: 5px;
  min-width: 60px;
  max-width: 80px;
  /* animation-name: zoomModalIn !important;
  animation-duration: 0.4s !important; */
}

.approvedTimelineDurColour {
  background-color: #a8cc9a;
  border: 2px solid #6db451;
  color:white
}

.declinedTimelineDurColour {
  background-color: #e89292;
  border: 2px solid #ba0000;
  color:white

}

.absenceTimelineDurColour {
  background-color: #214978;
  border: 2px solid #143051;
  color:white
}


.startHalfButtonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
  /* background-color: #0077ff; */
  max-width: 162px;
  margin: 0 auto;
  margin-top: 8px;
  padding-bottom: 6px;
  padding-top: 2px;
  padding-right: 8px;
  padding-left: 8px;
  border-radius: 5px;
  border: 1px solid #dde9f9;
  color: #496375;
  cursor: pointer;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.startHalfButtonContainer:hover {
  background-color: #edf3f9;
}

.selectedHalfWayBg {
  background-color: #3b597c;
  border: 2px solid #214978;
  color: white;
  margin-top:6px;
  margin-bottom: 6px;
}

.selectedHalfWayBg:hover {
  background-color: #214978;
}

.startHalfButtonTxt {
  max-width: 130px;
  text-align: left;
  font-size: 0.8em;
  margin-top: 3px;
  margin-right: -20px;
  font-weight: 500;
}

.regenSchedContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 14px; */
  /* background-color: #0077ff; */
  max-width: 200px;
  margin: 0px auto;
  padding-right: 10px;
  padding-left: 10px;
  /* margin-top: 30px; */
  padding-bottom: 6px;
  padding-top: 2px;
  border-radius: 5px;
  border: 1px solid #dde9f9;
  color: #496375;
  cursor: pointer;
}

.regenSchedContainer:hover {
  background-color: #ecf0f5;
}

.selectedRegenSchedContainer {
  background-color: #0bf;
  color: #FFF;
  border: 2px solid #0af;
  margin-top: -2px;
  /* margin-bottom: -1px */


}

.selectedRegenSchedContainer:hover {
  background-color: #0af;
}

.regenSchedBtnTxt {
  /* max-width: 130px; */
  text-align: left;
  font-size: 0.8em;
  margin-top: 3px;
  margin-right: 10px;
  font-weight: 500;
  /* color: white; */
}

.startEndEditDividerBar {
  min-height: 1px;
  max-height: 1px;
  min-width: 80%;
  max-width: 80%;
  margin: 15px auto;
  margin-bottom: 6px;

  background-color: #dde9f9;
}

.userReqBody {
  display: flex;
  margin-bottom: -14px;
  justify-content: space-around;
  /* margin-top: 8px; */
  min-height: 390px;
  max-width: calc(100% - 32px);
  min-width: calc(100% - 32px);
  /* margin-left: 6px; */
  margin: 8px auto;
  margin-left: 15px;
  margin-bottom: 0px;
  border-bottom: solid 1px #bac5d3;
}

.userReqLeftContent {    background-color: white;
  text-align: left;
  max-width: 75%;
  min-width: 75%;
  padding-right: 3px;
  padding-top: 15px;
  min-height: 410px;
  max-height: 410px;
  overflow-y: auto;
  border-top: solid 2px #e6eaee;
  border-right: solid 1px #e6eaee;
  border-left: solid 1px #e6eaee;
  padding-bottom: 10px;
  border-radius: 5px 5px 0px 0px;
  -webkit-font-smoothing: antialiased;
  /* margin-bottom: -8px; */
  margin-left: 4px;
  padding-bottom:30px  
}

.mobUserReqLeftContent {
  min-height: calc(90vh - 110px);
  max-height: calc(90vh - 110px);
}

.userReqRightContent {
  /* background-color: yellow; */
}

.payableContainer {
  display: flex;
}

.invisibleHeightOf15px {
  min-height: 15px;
}
.invisLeftContentOfReq {
  visibility: hidden;
}
.showHalfDayInfo {
  background-color: #a2bddf;
  color: white;
  font-weight: 500;
  font-size: 11px;
  /* min-width: 100%; */
  border-radius: 5px;
  padding-top: 3px;
  padding-bottom: 4px;
  margin-top: 6px;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px #859ebd solid;
}

.bottomCurvedDeclined {
  /* border-radius: 5px; */
  /* border: solid 1px #9e7a7a; */
  /* margin-bottom: 0px; */
  /* padding-bottom: 0px; */
  /* border-bottom: solid 3px #9e7a7a; */
  /* border-top: solid 3px #9e7a7a; */
}

.bottomCurvedApproved {
  /* border-radius: 5px; */
  /* border: solid 1px #b6daa8; */
  /* border-top: solid 3px #b6daa8; */
  /* border-bottom: solid 2px #b6daa8; */
  /* margin-bottom: 3px; */
  /* padding-bottom: 0px; */
  /* max-height: 300px; */
  /* background-color: black; */
}

.halfDayInfoHook {
  min-width: 2px;
  background-color: #4d6987;
  min-height: 12px;
  margin-bottom: -10px;
}

.noReasonDeclinedLeftBox {
  /* max-height: 210px; */
  /* margin-bottom: -20px; */
}

.pushDownDeclineReasonPlaceholder {
  margin-top: 14px;
  color: #acc3e1;
}

.changeExcessIcon {
  max-width: 40px;
  /* margin-left: 9%; */
  transition-duration: 0.1s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.changeExcessIcon:active {
  /* transform: scale(0.92); */
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
}

.mgrReqestFooterHolder {
  display: flex;
  max-width: 90%;
  /* background-color: blue; */
  margin: 0px auto;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
      border-top: solid 1px #bac5d3;
  /* min-height: 57px;
  max-height: 57px; */

  /* margin-left: 10%; */
}

.mgrReqestFooterHolderMob {
  margin-bottom:80px !important;
}

.excessMgrLeftContentBox {
  /* background-color: yellow; */
  min-height: 281px;
  -webkit-animation-name: slideModalRight !important;
          animation-name: slideModalRight !important;
  -webkit-animation-duration: 0.08s !important;
          animation-duration: 0.08s !important;
  padding-bottom: 20px;
  /* margin-left: -100px; */
}

.excessHeaderContainer {
  display: flex;
  background-color: white;
  max-width: 196px;
  justify-content: space-between;
  max-height: 30px;
  min-height: 30px;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 5px;
  color: #143051;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px #e6eaee solid;
margin-top:8px}

.returnBackImgAccruedLeaveOnReqImg {
  /* margin-top: -16px; */
  max-width: 16px;
  cursor: pointer;
  /* margin-left: -12px; */
}

.returnBackImgAccruedLeaveOnReqImg:hover {
opacity: 0.5;}
.excessMgrTxtHeader {
  font-weight: 500;
  font-size: 12px;
  margin-top: -1px;
  /* margin-bottom: 12px; */
  margin-left: -1px;
}

.excessRow {
  max-width: 92%;
}

.excessRowHeader {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 9px;
}
.excessRowTitle {
  font-weight: 500;
  color: #496375;
  margin-left: 5px;
  /* background-color: yellow; */
  font-size: 0.95em;
}

.excessRowKnob {
  margin: 0;
  min-width: 35px;
  margin-top: -18px;
  padding-bottom: 15px;
  margin-left: 0px;
}

.excessRowSubTxt {
  margin-top: -4px;
  font-size: 0.85em;
  padding-left: 0px;
  color: #859dbb;
  font-weight: 400;
  min-height: 40px;
margin-left:12px;}

.excessRowSplitter {
  min-width: 90%;
  margin: 0 auto;
  margin-left: 5px;
  margin-top: 10px;
  margin-bottom: 6px;
  max-width: 90%;
  min-height: 1px;
  max-height: 1px;
  background-color: #496375;
}

.disableCog {
  visibility: hidden;
  pointer-events: none;
}

.accruedLeaveDurationPreview {
  font-size: 0.9em;
  margin-top: 0px;
  line-height: 22px;
  margin-bottom: 6px;
  /* margin-left: 12px; */
}

.accruedMinsSpan {
  /* background-color: #496375; */
  color: #a2bddf;
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: 400;
  padding-left: 0px;
  padding-right: 7px;
  margin-left: 3px;
  border-radius: 15px;
}

.accruedMinsTxt {
  /* font-size: 1; */
  /* text-align: center; */
  /* background-color: blue; */
  /* margin-left: 12px; */
  font-weight: 500;
  color: #496375;
  margin-left: 9px;
}

.nonExcessContent {
  /* background-color: pink; */
  min-height: 281px;
}

.previousPayExcessFade {
  opacity: 0.4;
}

.previousPayExcessModalUnderlay {
  background: rgba(0, 0, 0, 0.3);
  /* max-height: calc(100% - 58px); */
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999999;
  top: 0;
  left: 0;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.previousPayExcessModalBox {
  background-color: #fff;
  /* background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(244, 247, 252, 1) 35%
    ); */
  color: #143051;
  border-radius: 5px;
  min-height: 35px;
  position: absolute;
  top: 45%;
  left: 50%;
  /* max-height: 400px; */
  /* overflow: scroll; */
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width: 310px;
  min-width: 310px;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  cursor: default;
}

.excessWarningTxt {
  font-weight: 400;
  color: #496375;
  max-width: 80%;
  margin: 0 auto;
  margin-top: 25px;
  font-size: 16px;
}

.excessOverrideBtnContainer {
  /* background: #0077ff; */
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  margin-top: 25px;
  margin-bottom: 25px;
  font-weight: 500;
  font-size: 1em;
  min-height: 40px;
  align-items: center;
  padding-left: 10px;
  max-width: 230px;
  
}

.overridePayExcessBtn {
  background-color: #0077cc;
  color: white;
  border: 1px solid #2066b6;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  padding-bottom: 2px;
  font-weight: 500;
}

.overridePayExcessBtn:hover {
  background-color: #2066b6;
}
.cancelOverrideButton {
  background-color: #214978;
  color: white;
  border: 1px solid #143051;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  padding-bottom: 2px;
  font-weight: 500;
}

.cancelOverrideButton:hover {
  background-color: #143051;
}

.editReqDatesWarningIcon {
  min-width: 24px;
  max-width: 24px;
}

.editReqDatesWarningTxt {
  text-align: left;
  padding-left: 12px;
  padding-right: 12px;
  line-height: 14px;
  font-size: 12px;
  margin-top: 2px;
  font-weight: 400;
}

.excessMgrTxtHeaderSplitter {
  min-height: 1px;
  min-width: 90%;
  max-width: 90%;
  margin-left: 6px;
  /* margin-top: 8px; */
  /* margin-bottom: 10px; */
  /* margin-top: 3px; */
  /* background-color: #85a5cc; */
}

.accruedLeaveReqBtnContainer {
  /* background-color: yellow; */
  margin-left: 6%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 120px;
  cursor: pointer;
}

.accruedLeaveReqTxtBanner {
  /* background-color: #85a5cc; */
  max-height: 36px;
  min-height: 36px;
  line-height: 14px;
  padding-left: 12px;
  margin-left: -10px;
  position: relative;
  /* padding-right: 10px; */
  font-size: 0.8em;
  font-weight: 400;
  color: #94a3ad;
  z-index: 2;
  margin-top: 8px;
  max-width: 120px;
  text-align: left;
}

.navyAccruedBg {
  /* background-color: #9a74eb; */
  color: #143051;
}

.mgrPendingReqsTxt {
  color: #4b1caf;
  color: #9374d6;
}

.blueBgReq {
  /* background-color: #00ccff; */
}

.whiteTxt {
  /* color: white; */
}

.fadedReqNoteBtn {
  opacity: 0.6;
}

.reqNotePlusBtnCharacter {
  font-size: 1.3em;
  /* line-height: 1px; */
  /* margin-top: 2px; */
}

.reqNoteUnderlay {
  background: rgba(0, 0, 0, 0.25);
  /* max-height: calc(100% - 58px); */
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.reqNoteModalBox {
  background-color: #f4f8fe;
  color: #143051;
  border-radius: 10px;
  min-height: 35px;
  /* max-height: 400px; */
  /* overflow: scroll; */
  min-width: 290px;
  max-width: 290px;
  cursor: default;
  /* max-width: 290px; */
  /* min-width: 290px; */
  /* box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.reqNoteBodyTxt {
  font-size: 14px;
  font-weight: 500;
  margin: 0 auto;
  max-width: 80%;
  margin-top: 35px;
  color: #516b8b;
  line-height: 16px;
}
.closeReqNoteModalBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* line-height: 26px; */
  border-radius: 5px;
  min-height: 35px;
  max-width: 65px;
  margin: 0px auto;
  margin-top: 25px;
  margin-bottom: 20px;
}

.closeReqNoteModalBtn:hover {
  background-color: #214978;
}

.navyBg {
  background-color: #fff;
  max-width: 280px !important;
  /* color: #143051; */
}

.addNoteToReqUnderlay {
  background-color: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  min-width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.addNoteToReqModalBox {
  background-color: #f4f8fe;
  /* background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(244, 247, 252, 1) 35%
  ); */
  color: #143051;
  border-radius: 15px;
  min-height: 35px;
  /* position: absolute;
  top: 45%;
  left: 50%; */
  /* max-height: 400px; */
  /* overflow: scroll; */
  /* -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
  min-width: 300px;
  max-width: 300px;
  /* max-width: 290px; */
  /* min-width: 290px; */
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  cursor: auto;

  -webkit-animation-name: zoomModalIn !important;

          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.addNoteQuestionTxt {
  font-weight: 500;
  padding: 20px;
  padding-top: 28px;
  color: #143051;
  font-size: 1em;
}

.addNoteOptionBtnContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 200px;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 30px;
}

.addNoteYes {
  background-color: #0077ff;
  border: 1px solid #145051;
  color: #ffffff;
  min-width: 70px;
  min-height: 30px;
  line-height: 29px;
  font-size: 1em;
  font-weight: 500;
  border-radius: 10px;
}

.addNoteNo {
  /* background-color: #ffeeee; */
  border: 1px solid #a8c0da;
  /* color: white; */
  min-width: 70px;
  min-height: 30px;
  line-height: 29px;
  font-size: 1em;
  font-weight: 500;
  border-radius: 10px;
}

.addNoteInput {
  /* background-color:  */
  border: 1px solid #143051;
  border-radius: 10px;
  min-width: 80%;
  min-height: 100px;
  margin-bottom: 15px;
  font-size: 1em;
  padding: 10px;
  font-weight: 400;
}

.saveNoteBtnContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  max-width: 220px;
  margin: 0 auto;
  /* margin-right: 10px; */
  /* margin-top: 10px; */
  margin-bottom: 20px;
}

.backAddNote {
  min-width: 57px;
  min-height: 35px;
  line-height: 34px;
  font-size: 0.9em;
  font-weight: 500;
  /* margin-left: -20px; */
}

.submitAddNote {
  background-color: #0077ff;
  border: 1px solid #145051;
  color: white;
  min-width: 70px;
  min-height: 30px;
  line-height: 29px;
  font-size: 1em;
  font-weight: 500;
  border-radius: 10px;
}

.disableSaveNoteBtn {
  opacity: 0.5;
  pointer-events: none;
}

.reqCostBubbleContainer {
  min-height: 24px;
  max-height: 24px;
  min-width: 90%;
  max-width: 90%;

  /* margin: 0 auto; */

  /* color: #143051; */
  /* color: #484848; */
  margin-top: -12px;
  display: flex;
  justify-content: flex-end;
}

.reqCostBubble {
  background-color: #e3e8ee;
  /* border: 1px solid #143051; */
  min-height: 24px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-right: 0px;
  font-size: 0.8em;
  font-weight: 500;
  color: #4f566b;
  z-index: 100;

  /* margin-bottom: 5px; */
  /* position: fixed; */
}

.greenCostBubble {
  /* background-color: #b6daa8;
  border: 1px solid #6db451; */
}

.redCostBubble {
  /* background-color: #dccaca;
  border: 1px solid #b88383; */
  display: none;
}

.reqRightBarDivider {
  min-height: 2px;
  background-color: #d9e4f2;
  min-width: 60px;
  max-width: 60px;
  margin-top: 5px;
  margin-bottom:10px;
}

.viewReqTeamUnderlay {
  background: rgba(0, 0, 0, 0.25);
  /* max-height: calc(100% - 58px); */
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);

}

.viewReqTeamModalBox {
  background-color: #f4f8fe;
  color: #143051;
  border-radius: 10px;
  min-height: 35px;
  /* max-height: 50%; */
  max-width: 90vw;
cursor:default;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  /* box-shadow: 0px 0px 3px 3px rgb(0 0 0 / 10%); */
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  min-width:340px !important
}

.viewReqTeamModalBoxMob {
  background-color: #f4f8fe;
  color: #143051;
  border-radius: 5px 5px 0 0;
  min-height: 35px;
  min-width: 100vw;
  max-width: 100vw;
cursor:default;
position:fixed;

bottom:0px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  box-shadow: 0px 0px 3px 3px rgb(0 0 0 / 10%);
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}
@media only screen and (min-width: 800px) {
  /* .viewReqTeamModalBox {
    max-width: 760px;
    min-width: 760px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;

  } */
}

@media only screen and (max-width: 799px) {
  /* .viewReqTeamModalBox {
    max-width: 360px;
    min-width: 360px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;

  } */
}

.loadingReqCalTeamPreview {
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 20px;
}

.reqTeamCalContainer {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: flex-start;
  /* overflow-y: scroll; */
  padding-left: 4px;
  padding-right: 4px;
  max-height: 420px;
  overflow-y: scroll;

  max-width: 100%;
  border-bottom: solid 2px #e6eaee;
  border-top: solid 2px #e6eaee;
  border-radius: 5px;
  background-color: white;
  padding-top: 10px;
  padding-bottom: 6px;
  
}

.reqTeamCalHolder {
  display: flex;
  /* min-height: fit-content; */
  /* min-height: 800px; */
  /* padding-top: 100px; */
  /* overflow-y: scroll; */
  justify-content: space-between;
  /* max-height: 420px; */
  /* overflow-y: scroll; */
}

.reqTeamCalLeft {
  /* background-color: yellow; */
  min-width: 80px;
  max-width: 80px;
  margin-left: 10px;
}
.reqTeamCalName {
  min-width: 100%;
  max-width: 100%;
  min-height: 64px;
  max-height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 2px;
  font-weight: 500;
  border-top: #e6eaee solid 2px;
  /* text-transform: uppercase; */
  font-size: 12px;
  text-align: left;
  padding-left: 6px;
  cursor: pointer;
  
}

.reqTeamCalName:hover {
  background-color: #ebf0f7;
}

.reqTeamCalNameTwoItems {
  min-height: 80px;
  max-height: 80px;
}

.reqTeamCalNameThreeItems {
  min-height: 124px;
  max-height: 124px;
}

.reqTeamCalNameFourItems {
  min-height: 160px;
  max-height: 160px;
}

.reqTeamCalNameFiveItems {
  min-height: 200px;
  max-height: 200px;
}

/* .reqTeamCalName:first-of-type {
  border-top: none;
} */
.invisibleOffBoxPlaceholder {
  visibility: hidden;
  font-size: 1.4em;
  /* color: #c8e8f0; */
  font-weight: 500;
  /* background-color: #143051; */
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.reqTeamCalRight {
  min-width: calc(100% - 90px);
  max-width: calc(100% - 90px);
  display: flex;

  overflow: scroll;

  font-size: 8px;
  /* padding-right: 62px; */
  padding-bottom: 10px;
}

/* .reqTeamCalRight::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 17px;
}

.reqTeamCalRight:-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 1);
  box-shadow: 0 0 1px rgba(255, 255, 255, 1);
} */

.reqTeamDayCol {
  /* background-color: blue; */
  min-width: 100px;
  /* max-width: 100px; */
  margin-left: 3px;
}

.reqTeamDayCol:nth-child(odd) {
  background-color: #f7fbff;
  /* border-right: 1px solid #d5e3ee; */
  /* border-left: 1px solid #d5e3ee; */
}

.colBorder {
  /* border-right: 1px solid #d5e3ee; */
  /* border-left: 1px solid #d5e3ee; */
}

.reqTeamShiftsContainer {
  display: flex;
  justify-content: space-around;
  padding-left: 5px;
  padding-right: 5px;

}

.reqTeamDayLimitContainer {
  /* min-width:300px;
  background-color: yellow; */
}
.reqTeamDayColUnit {
  border-top: #e6eaee solid 2px;
  /* max-height: 60px; */
  min-height: 64px;
  /* min-width: 100%;
  max-width: 100%; */
  /* min-width: 100px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 6px;
  padding-top: 6px;
/* min-width: 300px */
/* overflow-y: scroll; */
  /* background-color: pink; */
}

.thisUserColUnit {
  border-bottom: 2px solid #a2bddf;
  background-color: #2066b6;
}

.vv1void {
  /* background-color: grey; */
  min-height: 30px;
  max-height: 30px;
}

.vv1void2 {
  /* background-color: grey; */
  min-height: 20px;
  max-height: 20px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.reqTeamCalDayBarContainer {
  display: flex;
  max-height: 60px;
}

.topBarVoidHolder {
  min-width: 40px;
  max-width: 40px;
  background-color: black;
}

.reqTeamDayTitle {
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8em;
  font-weight: 500;
  padding-top: 4px;
  background-color: #fff;
  border-right: 1px solid white;
  border-radius: 5px 5px 0 0;
}

.limitBox1 {
  min-height: 20px;
  max-height: 20px;
  background-color: #2066b6;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #143051;
  border-radius: 3px;
  margin-top: 2px;
  margin-bottom:2px;
}

.selectedReqTeamCalInitials {
  background-color: #0077ff;
  color: white;
  border-radius: 6px 0 0 6px;
}

.selectedReqTeamCalInitials:hover {
  background-color: #3488e8;
}

.reqTeamLowerDay {
  min-height: 20px;
  max-height: 20px;
  background-color: #e6eaee;
  border-right: 1px solid white;
  border-radius: 0px 0px 5px 5px;
  border-top: 1px #a2bddf solid;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4em;
  font-weight: 500;
  margin-top: 12px;
}

.reqTeamCalNameLastVoid {
  min-height: 30px;
  max-height: 20px;
  min-width: 10px;
}

.reqTeamShifts {
  border: 1px solid #e6eaee;
  background-color: white;
  border-radius: 5px;
  min-height: 30px;
  max-height: 30px;
  min-width: 84px;
  line-height: 27px;
  font-size: 14px;
  padding-left: 9px;
  padding-right: 9px;
  font-weight: 500;
  margin-top: 4px;
  /* margin-left: 3px; */
  margin-right: 3px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.reqTeamShifts:hover {
  border-color: #bac5d3
}
.cursPointerMgr {
  cursor: pointer;
}


.withinLimitBg {
  background-color: #a8cc9a;
  border: 1px solid #6db451;
}

.exceedsLimitBg {
  background-color: #e04040;
  border: 1px solid #ba0000;
  background-color: #e89292;
  border: 1px solid #ba0000;
  /* border-radius: 3px; */
  margin-top: 2px;
  margin-bottom: 2px;
}

.limitReachedBg { 
  background-color: #6a98d1;
}

.reqTeamOffBox {
  font-size: 12px;
  color: #c8e8f0;
  font-weight: 500;
  /* background-color: #143051; */
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-top: -6px;
  margin-bottom: -2px;
}

.reqTeamOffBox:hover {
  opacity: 1;
}

.leaveDurReqCalSpan {
  color: #fff;
  font-weight: 500;
}

.cursPointerMgr:hover {
  opacity: 0.8;
}

.noShiftsFullHalfOff {
  font-size: 1.6em;
  padding-right: 12px;
  padding-left: 12px;
  max-width: 80px;
}

.notWorkingToday {
  font-size: 1.6em;
  color: #bbcada;
  font-weight: 500;
}

.clickedInitialsNameShow {
  font-size: 0.6em;
}

.viewNameModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  /* max-height: calc(100% - 58px); */
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.viewNameModalBox {
  background-color: #f4f8fe;
  color: #143051;
  border-radius: 5px;
  min-height: 35px;
  min-width: 280px;
  /* max-width: 80%; */
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  cursor: auto;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  padding-top: 20px;
}

.closeNameModalBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  /* padding-left: 9px;
  padding-right: 9px; */
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 30px;
  min-width: 90%;
  max-width: 90%;
  margin-top: 20px;
  margin-left: 5%;
  margin-bottom: 15px
}

.viewTeamCalNameTxtItem {
  font-weight: 500;
  font-size: 1em;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.italicJobTitle {
  color: #214978;
  font-weight: 500;
  font-size: 14px;
}
.dsOnNameBarName {
  position: relative;
  background-color: #d5e3ee;
  max-height: 18px;
  min-height: 18px;
  line-height: 18px;
  /* border-right: #7493ba solid 1px; */
  background: rgb(254, 251, 255);
  background: linear-gradient(
    90deg,
    rgba(254, 251, 255, 1) 13%,
    rgba(213, 227, 238, 1) 100%
  );
}
.dsOnNameBar {
  position: relative;
  background-color: #d5e3ee;
  max-height: 18px;
  min-height: 18px;
  line-height: 18px;

  /* min-width: 10px; */
  /* margin-top: -9px; */
}

.barDsViewTeamCalOnReqSpan {
  font-size: 1.2em;
  color: #8ea7c5;
  /* color: #8ea7c5; */
  font-weight: 500;
  /* font-style: italic; */
}

.halfDayBgColour {
  background-color: #516b8b;
}

.colWithSplitter {
  display: flex;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.viewTeamCalSplitter {
  background-color: #d5e3ee;
  min-width: 1px;
  min-height: 1px;
  margin-left: 3px;
}

.viewTeamCalSplitterInitial {
  background-color: #d5e3ee;
  min-width: 2px;
  min-height: 1px;
  margin-left: 3px;
}

.reqTeamCalHeader {
  /* background-color: yellow; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-top: 10px; */
  /* padding-left: 10px; */
  /* padding-bottom: 20px; */
  min-width: 100%;
  min-height: 60px;
}

.teamCalModalHeaderTxt {
  /* max-width: 70%; */
  font-weight: 500;
  font-size: 18px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  /* margin-top: 14px; */
  /* min-height: 60px; */
}

.closeTeamCalModalImg {
  max-width: 15px;
  min-width: 15px;
  /* margin-left: 2px; */
  margin-top: 0px;
  cursor: pointer;
}
.closeTeamCalModalImg:hover {
  opacity: 0.8;
}
.limitStatus {
  background-color: #a8cc9a;
  border: 1px solid #6db451;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  color: white;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 22px;
  line-height: 21px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: -10px;
  margin-bottom: 10px;
}

.limitStatusExceeded {
  /* background-color: #e89292; */
  /* border: 1px solid #ba0000; */
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  color: #ba0000;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 22px;
  line-height: 21px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: -10px;
  margin-bottom: 10px;
}

.limitTxtBelow {
  margin-top: 4px;
  font-size: 1.4em;
  color: #36495f;
}

.reqTeamLoadingLong {
  margin-top: -7px;
  margin-bottom: 20px;
  font-weight: 500;
  color: #2066b6;
  /* font-style: italic; */
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.reqsEndingMoreThan60DaysAgoTxt {
  max-width: 80%;
  margin: 0 auto;
  color: #94a3ad;
  font-size: 14px;
  margin-top: 20px;
  /* margin-bottom: 120px */
}

.blueLoadingReqs {
  margin-top: 35px;
}

.makeDsTxtOnReqSmaller {
  font-size: 0.95em;
}

.requestsJamTxt {
  background-color: #9a74eb;
  border: 1px solid #4b1caf;
  color: white;
  text-align: left;
  padding: 20px;
  max-width: 90%;
  margin: 0 auto;
  border-radius: 10px;
  margin-bottom: 20px;
}

.jammedUsrNameSpan {
  margin-top: 10px;
}

.editReqBtnImg {
  margin-left: 10px;
  border-radius: 5px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  cursor: pointer;
  min-height: 35px;
  min-width: 35px;
}


.editReqBtnImg:active {
  -webkit-transform: scale(0.96);
          transform: scale(0.96);
}

.cancelReqBtnImg {
  border-radius: 5px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  cursor: pointer;
  min-height: 35px;
  min-width: 35px;
}

.cancelReqBtnImg:hover {
  background-color: #bbcada;

}

.overlapWarningModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  /* max-height: calc(100% - 58px); */
  height: 100%;
  min-width: 100%;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.overlapWarningModalBox {
  background-color: #f4f8fe;
  color: #143051;
  border-radius: 5px;
  min-height: 35px;
  min-width: 290px;
  max-width: 290px;
  padding: 20px;
  padding-top: 10px;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  cursor: auto;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  border: 1px solid #8ea7c5;
  font-size: 16px;
}

.overlapModalInfoTxt {
  margin-top: 20px;
}

.okButtonOverlapModal {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 30px;
  /* max-width: fit-content; */
  margin: 30px auto;
  margin-bottom: 0px;
}

.loadingStartEndTimeImg {
  max-width: 40px;
  margin-left: 20px;
  margin-top: 6px;
  margin-bottom: -1px;
}

.editHalfKnobContainer {
  margin-top: -2px !important;
  margin-left: 0px;
}

.fadeEditStartAndEndDateReqContent {
  opacity: 0.6;
  pointer-events: none;
}

.removeReqBtn {
  /* margin-top: 8px; */
  margin-left: 20px;
  margin-right: -100px;
  /* background-color: blue; */
  min-width: 62px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-bottom: 4px; */
  font-size: 1em;
  min-height: 28px;
  max-height: 28px;
  font-weight: 400;
  color: white;
  font-weight: 500;
  font-size: 14px;
  background-color: #cbd7e6;
  border: 1px solid #bbcada;  cursor: pointer;
  margin-top: 14px;
}

.removeReqBtn:hover {
  background-color: #bbcada;
}

.userReqGreenRedBubble {
  /* margin: 0px; */
  margin-left: 7%;
min-height:16px;
max-height:16px;
line-height:15px;
  border:none;
  background-color: #fff;
  color: #6db451;
  border-left: 2px solid #a8cca8;
  border-radius:0px;

  /* margin-left: 50%; */
  /* text-align: right; */
}

.submittedPushDown {
  margin-top: 17px;
  margin-bottom: 2px;
  /* text-transform: uppercase; */
}


.loadingViewTeamOnReqWidth {
  max-width: 200px;
  min-width: 200px;
}



.pendingReqsUserTitle {
  text-align: left;
  padding-left: 7%;
  font-weight: 500;
  color: #bbcada;
  margin-bottom: 14px;
  font-size: 16px;
  margin-top: 12px;
}

.colour143051 {
  color: #bbcada
}

.pushApprovedTitleDown {
  margin-top: 10px;
}

.mgrPastYearSelectDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 78px;
  min-width: 78px;
  line-height: 26px;
  height: 30px;
  background: url(/static/media/downArrowWhite2.887d57ce.svg) no-repeat right
    rgb(83, 155, 237);
  border: 1px solid #2c7eb9;
  -webkit-appearance: none;
  background-position-x: 64px;
  display: flex;
  cursor: pointer;
  border-color: #2c7eb9;
  border: 1px solid #e6eaee;
  background-color: #0bf;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 56px;
  margin-top: 24px;
  margin-left: 5%;
}

.loadedFromTeamViewerBottomBar {
  min-height: 8px;
  background-color: #e7ebef;
}

.defCursor {
  cursor: default;
}

.overtimeHolidayHelpIcon {
  background-color: #a387df;
  border: 2px solid #8e76c1;
  max-width: 22px;
  min-width: 22px;
  border-radius: 3px;
  padding: 3px;
  margin-left: 4px;
  cursor: pointer;
}

.overtimeHolidayHelpIcon:hover {
  background-color: #8e76c1;
}

.excessExplainDiv {
  background-color: #fff;
  color: #6a8bb1;
  font-weight: 300;
  border: 1px solid #e6eaee;
  max-width: 176px;
  font-size: 14px;
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 15px;
  margin-top: -0px;
}

.excessExplainTitle {
  font-weight: 500;
  margin-bottom: 6px;
  color: #a387df
}

.excessExplainBridge {
  background-color: #a387df;
  border-left: 2px solid #8e76c1;
  border-right: 2px solid #8e76c1;
opacity: 0.7;
  min-height: 8px;
  max-height: 8px;
  min-width: 13px;
  max-width: 13px;
  margin-top: -10px;
  margin-left: 152px
}

.limitDisplayInfo {
  min-height: 108px;
  max-height: 108px;
}

.limitDisplayInfoRow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;
  padding-left: 2px;
  padding-top: 2px;
  background-color: #e0ebf8;
  padding-bottom: 2px;
  border: #516b8b 1px solid;
  border-radius: 3px;
}

.limitDisplayInfoRowInline {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 4px;
  padding-bottom: 2px;
  /* max-width:100px; */
min-height:30px;
  border-bottom: 1px solid #6a8bb1;

}

.limitDisplayInlineWrapper {
  display: flex;
  justify-content: space-between;
  min-width: 105px;
  max-width: 105px;
}

.limitDisplayInfoTitle {
  font-weight: 500;
  margin-left: 2px;
  font-size: 10px;
  color: #214978;
  white-space: nowrap;
  display: flex;
  align-items: center;
  min-height: 15px;
  justify-content: center;
  border-radius: 2px;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  padding-left: 3px;
  padding-right: 3px;

}


.limitDisplayInfoTitle2 {
  font-weight: 500;
  /* background-color: #516b8b; */
  margin-left: 2px;
  font-size: 11px;
  /* background-color: #dde9f9; */
  color: #214978;
  white-space: nowrap;
  /* text-transform: uppercase; */
  display: flex;
  align-items: center;
  min-height: 15px;
  justify-content: center;
  border-radius: 2px;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  padding-left: 3px;
  padding-right: 3px;
  /* opacity: 0.5; */

}

.limitDisplayInfoValue {
  margin-left: 5px;
  color: #8ea7c5;
  padding-right: 6px;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 500;

}
.limitDisplayInfoValueCurrMax {
  margin-right: 2px;
  color: #214978;
  padding-right: 3px;
  padding-top: 3px;
  font-size: 12px;
  font-weight: 500;
  /* background: #ffffff; */
  min-width: 26px;
  padding-left: 3px;
  margin-top: 3px;
  max-height: 26px;
  text-align: right;
}

.hideLimitDisplayInfo {
  visibility: hidden;
}

.vv1void3 {
  min-height: 108px;
  max-height: 108px;
}


.noLeaveReqTimelineItem {
  /* background-color: blue; */
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* padding-top: 30px; */
  /* font-size: 12px; */
  text-align: right;
  padding-right: 10px;
  line-height: 24px;

}

.noLeaveReqLightSpan {
  font-size: 14px;
  padding-right: 12px;
  color: #bbcada;
  font-weight: 500;
  line-height: 19px;
  margin-top: 13px;
  padding-bottom: 11px;
}

.mgrLeaveStartAndEndRow {
  min-width: 100%;
  /* background-color: yellow; */
}


.isOwnReqTxt {
  text-align: right;
  max-width: 140px;
  font-size: 0.8em;
  font-weight:400;
  color: #a2bddf;
  margin-right: 7%;
  margin-top: 10px;
  /* background-color: black; */
}

.startHalfSpan {
  background-color: #e3e8ee;
  color: #4f566b;
  font-weight: 500;
  font-size: 12px;
  margin-left: 5px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-top: 6px;

}

.regenSchedOnReq {
  margin-bottom: 20px;
  /* margin-top: 20px; */
}

.regenActiveOnReq {
  margin-bottom: 20px;
  /* margin-top: 19px; */
}

.closeAndViewProfileBox {
  display: flex;
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 12px;

}

.closeVTORuserBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
max-height: 35px;  min-width: 48%;
}

.viewUserVTORuserBtn {
  min-height: 35px;
max-height: 35px;
display: flex;
align-items: center;
justify-content: center;
color: #fff;
min-width: 48%;
font-size: 14px;
text-align: center;
border-radius: 5px;
font-weight: 500;
cursor: pointer;
background-color: #0bf;
border: 1px solid #0af;
}

.editUserIsShownOnVtor {
  /* background-color: yellow !important; */
  z-index: -1
}


.pastDecReqModalFooterApproved {
  min-height: 80px;
  border-top: 1px solid #e3e8ee;
  display: flex;
  max-width: 90%;
  margin-left: 5%;
  justify-content: flex-end;align-items: center;

}

.pastDecReqModalFooterDeclined{
  min-height: 80px;
  border-top: 1px solid #e3e8ee;
  display: flex;
  max-width: 90%;
  margin-left: 5%;
  justify-content: flex-end;align-items: center;
}

.closeDecisedReqBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
margin-top: -30px;
/* margin-right: 15px; */
}

.closeDecisedReqBtn:hover {
  background-color: #214978;
}

.mobDeclinedOrPastBottom {
  /* min-height: 8px; */
}
.removeRed {
  /* bottom: -44px; */
}


.reqsPageHeaderUser {
  /* background-color: #fff; */
  min-width: 100%;
  min-height: 95px;
  display: flex;
  justify-content: space-between;
}

.reqsPageHeaderTeam {
  min-width: 100%;
  min-height: 95px;
  display: flex;
  justify-content: space-between;
  background-color: #534f8c;
  border-bottom: 1px solid #d1dfee;

}

.reqsPageHeaderUserLeft {
  /* background-color: yellow; */
  min-width: 260px;
  margin-left: 40px;
  margin-top: 23px;

}

.reqsPageHeaderUserLeftMob {
  margin-left: 5%;

}


.myRequestsTitle {
  color: #143051;
  font-weight: 500;
  font-size: 23px;
  text-align: left;
}

.myRequestsTitleMob {
  color: #143051;
  font-weight: 500;
  font-size: 23px;
  text-align: left;
  margin-bottom: -3px;
  margin-top: 2px;
  }

  .teamRequestsTitleMob {
    color: #fff;
    font-weight: 500;
    font-size: 23px;
    text-align: left;
    margin-bottom: -3px;
    margin-top: 2px;
    }

    @media only screen and (max-width: 400px) {
      .teamRequestsTitleMob {
       font-size: 20px;
       margin-bottom:4px;
        
      }
  }
  

.myRequestsMenu {
  display: flex;
color: #143051;
font-weight: 400;
font-size: 16px;
margin-top: 4px;
margin-left: 3px;
min-width: -webkit-fit-content;
min-width: -moz-fit-content;
min-width: fit-content;
/* align-items: cneter; */
}

.myRequestsMenuMob {
  display: flex;
color: #143051;
font-weight: 400;
font-size: 16px;
margin-top: 7px;
margin-left: 3px;
}



.myRequestsMenuItem {
  
  margin-left: 9px;
  min-height: 24px;
  cursor: pointer;
  /* opacity: 0.8; */
  /* background-color: blue; */
  min-width: 70px;
  max-width: 70px;
margin-top:5px;
color: #c2cce9;
font-weight: 500;
}

.myRequestsMenuItem:first-of-type {
  margin-left: 0px;
  margin-right: 4px;
  min-width: 77px;
  max-width: 77px;
}

.myRequestsMenuItem:hover {
  /* opacity: 1; */
  color: #4f79ab;
  font-weight: 500;
}

.myRequestsMenuItemMob:hover {
  color: white
}

.myReqestsMenuItemSelected {
  font-weight: 500;
  border-bottom: 1px #e3e8ee solid;
  opacity: 1;
  color: #143051 !important;
  pointer-events: none  !important;
}

.teamReqestsMenuItemSelected {
  font-weight: 500;
  border-bottom: 1px #e3e8ee solid;
  opacity: 1;
  color: #fff;
  pointer-events: none  !important;
}




.reqsPageHeaderUserRight {
  /* background-color: green; */
  min-height: 35px;
  min-width: 240px;
  max-width: 240px;
  position: fixed;
  right: 24px;
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}


@media only screen and (min-width: 1166px) {
    .reqsPageHeaderUserRight {
      /* background-color: green; */
      min-height: 35px;
      min-width: 400px;
      max-width: 400px;

      position: fixed;
      left: 725px;
      display: flex;
      justify-content: flex-end;
    }
}


@media only screen and (max-width: 450px) {
  .reqsPageHeaderUserRight {
    min-height: 35px;
    min-width: 240px;
    max-width: 240px;
    position: fixed;
    right: 24px;
    margin-top: -10px;
    display: flex;
    justify-content: flex-end;
  }
}

.mobReqsPageHeaderCorrection {
  margin-top: 6px;
}


.teamReqsBtn {
  background-color: #a387df;
  border: 1px #8e76c1 solid;

  display: flex;
  color: #fff;
  min-height: 36px;
  line-height: 34px;
  padding-left: 7px;
  padding-right: 2px;
  border-radius: 5px;
  text-align: center;
  /* margin-left: 5px; */
  /* margin-right: 8px; */
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;

  max-height: 35px;
  min-height: 35px;
  /* margin-top: 4px; */
  font-size: 16px;
  cursor: pointer;
  /* padding-bottom: 4px; */
}

.teamReqsBtn:hover {
  background-color: #8e76c1;
}



.reqStatsBtn {
  background-color: #07f;
  border: #0469de 1px solid;
  display: flex;
  color: #fff;
  min-height: 36px;
  line-height: 34px;
  padding-left: 4px;
  padding-right: 0px;
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  align-items: center;
  /* margin-left: 5px; */
  margin-right: 8px;
  min-width: 80px;
  max-height: 35px;
  min-height: 35px;
  /* margin-top: 4px; */
  font-size: 16px;
  cursor: pointer;
  
}

.reqStatsBtn:hover {
  background-color: #0469de;
}

.mobReqStatsBtn {
  /* max-height: 30px;
  min-height: 30px; */
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-left: 12px;
  padding-right: 8px;
  font-size: 15px;

  /* min-width: 40px !important; */
}

.minMaxHeight30 {
  min-height: 30px;
  max-height: 30px;

}

.purpleColourTheme {
  border-color: #8e76c1 !important;
  background-color: #a387df !important;
}

.mobMyReqsBtnTxt {
  margin-top: -3px !important;
  margin-left: -2px;
}

.mobReqsStatsTxt {
  margin-top: 1px;
}
.mobReqsHeaderRight {
  /* background-color: yellow; */
  margin-top: -17px;
}

.reqStatsBtnTxt {
  font-weight: 500;
  /* font-size: 16px; */
  padding-left: 0px;
  cursor: pointer;
  margin-left: 2px;
}

.reqStatsImg {
  max-width: 15px;
  min-width: 15px;

  margin-left: -4px;
  margin-right: 6px;
  margin-top: -2px;
}
@media only screen and (max-width: 420px) {
  .reqStatsBtnTxt {
  display: none;
  }

  .reqStatsImg {
    max-width: 15px;
    margin-left: 1px;
    /* margin-right: 6px; */
    margin-top: -2px;
  }

  .reqStatsBtn {
    /* padding-left: 0px; */
    min-width: 32px;
    max-width: 32px
  }


}



.teamReqBtnTxt {
  /* background-color: blue; */
  min-width: 120px;
  padding-right: 3px;
  margin-top: -1px;
  /* margin-right: 3px; */
}

.myReqBtnTxt {
  /* background-color: blue; */
  min-width: 100px;
  padding-right: 4px;
  margin-top: -1px;

}


.minW101pc {
  /* min-width: 101% */
  padding-left: 0.5%; 
  min-height: calc(100vh - 225px);
  max-height: calc(100vh - 225px);

}

.minW101pc2 {
  /* min-width: 101% */
  padding-left: 0.5%; 
  min-height: calc(100vh - 225px);
  max-height: calc(100vh - 225px);

}


.myReqsBtn {
  background-color: #07f;
  border: #0469de 1px solid;

  display: flex;
  color: #fff;
  min-height: 36px;
  line-height: 34px;
  padding-left: 9px;
  padding-right: 2px;
  border-radius: 5px;
  text-align: center;
  /* margin-left: 5px; */
  /* margin-right: 8px; */
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;

  max-height: 35px;
  min-height: 35px;
  /* margin-top: 4px; */
  font-size: 16px;
  cursor: pointer;
  /* padding-bottom: 4px; */
}

.myReqsBtn:hover {
  background-color: #0469de;
}

.mobMyReqsBtn {
  max-height: 30px;
  min-height: 30px;

}


.teamReqsTeamSelectContainer {
  /* background-color: blue; */
  position: fixed;
  top:86px;
  right: 24px;
  min-width: 284px;
  min-height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (min-width: 1166px) {
  .teamReqsTeamSelectContainer {
    /* background-color: blue; */
    position: fixed;
    top:86px;
    left: 841px;
    right: auto;
    min-width: 284px;
    min-height: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}



.teamReqsTeamTitle {
  max-width: 80px;
  /* position: absolute; */
  /* top: 92px; */
  /* right: 202px; */
  font-weight: 500;
  /* z-index: 999; */
  font-size: 14px;
  color: #6a8bb1;
  margin-right: 8px;
}

@media only screen and (max-width: 870px) {
  .teamReqsTeamTitle {
    display: none;
  }
}


.teamReqTeamsDropdown {
  min-width: 174px;
  min-height: 35px;
  background-color: blue;
  max-width: 174px;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 150px;
  font-weight: 500;
  font-size: 14px;
  padding-left: 10px;

}

.teamReqTeamsDropdown:hover {
  border-color: #bac5d3
}






.teamReqsDropdownMobOnly {
  margin-top: -36px;
}



.reqPageFilterBarMy {
  min-height: 50px;
  max-height: 50px;
  /* background-color: #ebf0f7; */
  /* background-color: black; */
  /* margin-top: -34px; */
  /* min-width: 96%; */
  /* max-width: 96%; */
  padding-top: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* padding-right: 4%; */
  position: relative;
  /* padding-right:5%; */
  z-index: 999;
  /* flex-direction: row-reverse; */
  /* margin-left: 17px; */

}

.rowReverse {
  flex-direction: row-reverse;
}


.reqPageFilterBar {
  min-height: 55px;
      max-height: 55px;
      background-color: #ebf0f7;
      /* margin-top: -34px; */
      min-width: 100%;
      max-width: 100%;
      padding-top: 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      /* padding-right: 4%; */
      position: relative;
      z-index: 999;
      /* padding-right: 2%; */
      padding-left: 5%;
      padding-right:5%;

}

.reqFilterSplitterContainer {
  min-width: 100%;
  background-color: #ebf0f7;
  padding-top: 0px;
  margin-top: -2px;
  /* border-right: 1px solid #e0ebf8; */
}

.reqFilterSplitter {
  min-height: 1px;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 10px;
  background-color: #e6eaee;
  /* margin-bottom: 10px; */
}

.approvedTeamReqsTitle {
  text-align: left;
  padding-left: 7%;
  font-weight: 500;
  color: #bbcada;
  font-size: 14px;
  margin-top: 12px !important;
  min-height: 30px;
  margin-bottom: -4px;
}

.noPastReqs {
  /* background-color: blue; */
  margin-top: 13px !important;
}

.noUpcomingReqs {
    /* background-color: blue; */
    margin-top: 19px !important;
}

.noUpcomingMgrReqs {
  margin-top: 19px !important;

}


.reqsDataBoxYearSelect {
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
max-width: 76px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #e6eaee;
  color: #fff;
  font-weight: 500;
  background-position-x: 55px !important;
    border-radius: 5px;
    background: url(/static/media/downArrowWhite2.887d57ce.svg) no-repeat right;
    font-size: 14px;
  margin-right: 14px;
  cursor: pointer;
  padding-bottom: 1px;
  min-height:35px;
  margin-top:-5px;
  background-color: #2b679f;

}
.reqsDataBoxYearSelect:hover {
  border-color: #bac5d3;
}
.leaveDataMinsSpan {
  /* background-color: #295281; */
  /* padding-top: 2px; */
  /* padding-bottom: 2px; */
  /* border-radius: 3px; */
  /* line-height: 15px; */
  font-size: 12px;
  padding-left: 9px;
  /* padding-right: 6px; */
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height:22px;
  /* background-color: #7f57b9; */
    /* border: 1px solid #eeeeee; */
    color:#fff;
    border-left: 1px solid #ffffff5c;
}

.leaveDataMinsSpanMgr {
  background-color: #513392;
  display: flex;
  align-items: center;
  /* padding-top: 2px; */
  /* padding-bottom: 2px; */
  border-radius: 3px;
  /* line-height: 15px; */
  font-size: 12px;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}



.customMappedItem {
  /* margin-top:10px; */
  /* background-color: rgb(64, 103, 148); */
  /* background-color: #5f98c0; */
  background-color: #19304f;
  /* padding-top: 10px; */
  border-radius: 5px;
  max-width: 90%;
  min-width: 90%;

  margin-right: 5%;
  /* border: 1px solid #dde9f9; */
  /* border-left: 3px solid #143051; */
  padding-top:18px;
  padding-bottom:8px;
  margin-bottom:10px;
  float:right;
  border: 1px solid #143051;
  /* animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important; */
}

.x348772344 {
  padding-bottom: 12px;
  background-color: #4f7ab4;
  border-color: #30517f;

}

.myReqSideboxTop {
  /* border: 1px solid #dde9f9 !important; */
  /* border-left: 3px solid #143051 !important; */
  max-width: 94%;
  border-radius:5px;
  /* padding-top:8px; */
  margin-left:3%;
}
.customMappedItemMgr {
  margin-top:20px;
  /* background-color: rgb(64, 103, 148); */
  /* background-color: #7b59c4; */
  background-color: rgb(255,255,255,0.9);
  padding-top: 10px;
  padding-bottom:4px;
  border-radius: 5px;
  max-width: 90%;
  margin-left: 5%;
  /* border: 1px solid #dde9f9; */
  border: 1px solid #7e60af;
  background: #b997f1;
  border-left: 3px solid #7e60af;

  /* animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important; */
}


.customMappedItem:first-child {
}

.reqsDesktopBoxScroll {
  max-height: calc(100vh - 188px);
  padding-bottom: 0px !important;
  cursor: default !important;

}

.reqsDesktopBoxScrollMgr {
  max-height: calc(100vh - 221px);
  padding-bottom: 12px !important;
  cursor: default !important;

}


.oneCustomsDataBoxSize {
  max-height: 522px !important;
  min-height: 522px !important;
}


.twoCustomsDataBoxSize {
  max-height: 682px !important;
  min-height: 682px !important;
}


.noCustomsDataBoxSizeAbsence {
  max-height: 428px !important;
  min-height: 428px !important;

  /* background-color: pink; */

}


.customDataBoxTitle {
  border: none !important;
  padding-right: 16px !important;
  max-width: 90% !important;
  min-width: 90% !important;
  margin-left: 5% !important;
  border-radius: 5px !important;
  min-height: 50px !important;
  background-color: #284974;
  /* margin-top:10px; */
}

.customDataBoxTitleMgr {
  /* background-color: #a387df !important;
  border: none  !important; */
}

.customLeaveDataMinsSpan {
  background-color: #386190 !important;

}
.reqDataCustomScrollBox {
  overflow-y: scroll;
  max-height: calc(100vh - 442px);
  background-color: #fcfdff;
  border-top:1px solid #e0ebf8;
}
.myAllowsSideBoxCustomsScroll {
  overflow-y: scroll;
  max-height: calc(100vh - 389px);
  /* background-color: #2c7eb9; */
  padding-top:8px;
  /* border-top:1px solid #e0ebf8; */
}
.reqDataCustomScrollBoxAbsence {
  overflow-y: scroll;
  max-height: calc(100vh - 545px);
  padding-top:8px;
  /* border-top:2px solid #e0ebf8; */
  /* background-color: #fcfdff; */
}

.reqDataCustomScrollBoxMgr {
  /* background-color: yellow; */
  overflow-y: scroll;
  max-height: calc(100vh - 530px);
  padding-bottom: 20px;
  /* border-top: 1px solid #e6eaee; */
  /* background-color: #fcfdff; */
  /* padding-bottom: 20px; */
}


.modalCustomScrollBox {
  /* max-height: calc(100vh - 366px);
  min-height: calc(100vh - 366px); */
  min-height:288px;
  max-height:288px;
  /* background-color: #e8e7fb; */
  /* border-radius: 20px 20px 0 0 !important; */
  /* border: 1px solid #7874c0; */
  min-width:  100%;
  /* margin-left: -2px; */
  background-color: #9470ce;

  border-top: 1px solid #6d4298 !important;
  border-bottom: 1px solid #6d4298 !important;

  border-bottom: none;
  margin-top: 20px;
}


.modalCustomScrollBox2 {
  max-height: calc(100vh - 485px);
  min-height: calc(100vh - 485px);



}

.noBorderTopBottom {
  /* border-top: none !important; */
  /* border-bottom:none !important; */
}


.modalCustomScrollBoxMob {
  max-height: calc(100vh - 287px);
  min-height: calc(100vh - 287px);



}
.customLeaveDataTitle {
  text-align: left;
  font-size: 14px  !important;
  max-width: 60%;
  line-height: 15px;
  margin-left:1px;

}

.font12px {
  font-size: 12px !important;
}
.showCustomDataSplitter {
  background-color: white;
  min-height: 2px;
  min-width: 90%;
  max-width: 90%;
  margin-left: calc(5% + 1px);
  z-index: -1;
  /* border-radius: 0 0 2px 0; */
}


.myReqDataSplitter {
  background-color: white;
  min-height: 2px;
  min-width: 90%;
  max-width: 90%;
  margin-left: calc(5% + 1px);
  z-index: -1;
  border-top: 1px solid #e0ebf8
  /* border-radius: 0 0 2px 0; */
}


.showCustomDataSplitterMgr {
  background-color: white;
  min-height: 2px;
  min-width: 90%;
  max-width: 90%;
  margin-left: calc(5% + 1px);
  z-index: -1;
  opacity: 0.8;
  margin-top: -16px;
  /* border-radius: 0 0 2px 0; */
}


.modalShowCustomDataSplitter {
  min-width: 94%;
margin-left: 2%;
  max-width: 94%;
  margin-top:10px;
}

.otherCustomsContainer {
  display: flex;
  justify-content: flex-end;
  min-height: 72px;
  margin-bottom: -17px;
  padding-right: 15px;
  /* border-top: 1px solid white; */
  padding-top: 6px;
  max-width: 95%;
  margin-left: 4%;
/* background-color: #144051; */
}

.flexEnd {
  justify-content: flex-end;
}



.reqsDataBoxShowCustomsBtn {
  /* background-color: #386190; */
    max-height: 30px;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* max-width: 156px;
    min-width: 156px; */
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    /* padding-left: 3px;
    padding-right: 3px; */
    /* padding-left: 2px;
    padding-right:2px; */
    /* border: 2px solid #acc3e1; */
    border: 1px solid #0077aa;

    color: #c0d4e2;
    font-weight: 400;
    border-radius: 5px;
    font-size: 14px;
    margin-right: 14px;
    cursor: pointer;
    /* padding-bottom: 1px; */
}

.reqsDataBoxShowCustomsBtn:hover {
  background-color: #2c7eb9;
  border: 1px solid #e0ebf8;
  padding-left: 1px;
  /* padding-right: 1px; */
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
    color: #fff;

  /* padding-top: 12px; */
}

.reqsDataBoxShowCustomsBtnActive {

  min-width: 144px;
  border-bottom: 2px solid #fff;
  border-color: white; 
  border: none;
  border: 1px solid white;
  background-color: white;
  color: #0077bb;
  border-radius: 5px 5px 0px 0;
  z-index: 2;
  padding-top: 1px;
  font-weight: 500;
  /* padding-left: 1px; */
  font-size: 14px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;

}

.reqsDataBoxShowCustomsBtnActive:hover {
  background-color: #f4f8fe;
  padding-left: 0px;
  color: #0077bb;

  /* opacity: 0.9; */
}

.reqsDataBoxShowCustomsBtnActiveMyReqsOnly {
  margin-top: 9px;
}
.closeOtherLeaveTypesImg {
  max-width: 10px;
  margin-left: 7px;
  margin-right: 10px;
  margin-top: 2px;
}

.closeOtherLeaveTypesImg:hover {
  /* opacity: 0.8; */
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.customRemainingRow {
  /* min-width: 157px; */
  display: flex;
  justify-content: flex-end;
}

.reqStatsUnderlay {
  background: rgba(0, 0, 0, 0.5);
  /* max-height: calc(100% - 58px); */
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.modalReqDataBox {
  min-height: calc(100vh - 87px);
  max-height: calc(100vh - 87px);
  min-width: 90% !important;
  max-width: 90% !important;
  margin-left: 0px !important;
  border-bottom:none !important;
  min-height: -webkit-fit-content !important;
  min-height: -moz-fit-content !important;
  min-height: fit-content !important;
  border-radius:10px !important

}

@media only screen and (min-width: 460px) {
.modalReqDataBox {
  min-width: 390px !important;
  max-width: 390px !important;
}
}

.customQtySpan {
  /* background-color: blue; */
  margin-right: 4px;
  margin-left: 10px;
}

.mgrReqDataBoxDropdown {
  background-color: #1468a4;
    max-height: 35px;
    min-height:35px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 88%;
    min-width: 88%;
    margin-left: 6%;
    padding-left: 10px;
    padding-right: 10px;
    border: 2px solid #fff;
    color: white;
    font-weight: 500;
    background-position-x: 95% !important;
    border-radius: 5px;
    font-size: 14px;
    margin-right: 14px;
    cursor: pointer;
    background: url(/static/media/downArrowWhite2.887d57ce.svg) no-repeat right;
    /* #7495ba; */
    /* padding-bottom: 1px; */
    /* margin-top: -10px; */
    margin-bottom: 5px;
    line-height: 26px;
}
.mgrReqsDataBoxColours {
  border: 1px solid #7f63bb !important;
  background-color: #9374d6 !important;
  cursor: default;
  /* min-height:640px !important */
  /* min-height: fit-content !important; */
  /* max-height: calc(100vh - 200px) !important; */
}

  
.reqDataBoxPurpleBg {
  background-color: #663fa7 !important;
  border: 1px solid #e6eaee !important;
  color: #FFF !important;
  background: url(/static/media/downArrowWhite2.887d57ce.svg) no-repeat right;
}

.reqDataBoxPurpleBg:hover {
  border-color:#ddc6e6 !important}

.mgrReqDataBoxHighlightTitleBar {
  background-color: #a387df !important;
  border-color: #c2a9fa !important;
  margin-top: 15px !important;
  border-radius:5px;
  border-bottom: none !important;
}

.lightPurpleBorderColour {
  border-color: #c2a9fa !important;
  padding-left: 10px !important;
  padding-right: 13px !important;

}

.remainingMgrDataReqTitle {
  padding-left: 10px !important;
  padding-right: 13px !important;
}

.mgrShowCustomsBtn {
  background-color: #9374d6;
  border-color: #9374d6;
  color: white;
  opacity: 0.8;
  margin-top: 2px;
}

.mgrShowCustomsBtn:hover {
  background-color: #9374d6;
  opacity: 1

}

.reqsDataBoxShowCustomsBtnActiveMgr {
  background-color: white;
  border:none;
  color: #4b1caf;   border-radius: 5px 5px 0 0;
  opacity: 0.8;
margin-top: 10px;


}

.reqsDataBoxShowCustomsBtnActiveMgr:hover {
  background-color: white;
  border-radius: 5px 5px 0 0;
  border:none;
  color: #4b1caf;
  padding-left: 0px;
  opacity: 0.8

}

.teamReqsAnnLeaveUsersTitle {
  text-align: left;
  margin-top: 15px;
  margin-left: 25px;
  font-weight: 500;
  opacity: 1;
  color: #bbcada;
  font-size: 14px;
  display: none;
  
}

.mgrReqDataBoxAllStaffDataItem{
 

  min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1px;
    padding-left: 13px;
    cursor: pointer;
    background-color: white;
    border-radius: 4px;
  color:white;
    max-width: 90%;
    margin-left: 5%;
    border-radius: 4px;
    margin-top: 8px;
    box-shadow: 0px 0px 2px rgb(0 0 0 / 20%);
    background-color: #a387df !important;
    border: 1px solid #c2a9fa !important;
  /* padding-right: 15px; */
}

.mgrReqDataBoxAllStaffDataItem:hover {
  border: #e6eaee 1px solid !important; 
  padding: 0px;
  padding-left: 13px;
  padding-right:1px;
  border-radius:4px;
    /* background-color: #a387df !important; */
    /* border: 2px solid #c2a9fa !important;   */
  /* border-radius: 5px; */

}

.mgrReqDataBoxAllStaffDataItem:last-child {
  /* border-bottom: none !important */
}


.allStaffAnnLeaveDiv {
  /* background-color: black; */
  overflow-y:scroll;
  max-height: calc(100vh - 370px);
  margin-top: 10px;
  padding-bottom: 25px;
}

.allStaffAnnLeaveDivModal {
  /* min-height: calc(100vh - 510px) !important; */
  /* max-height: calc(100vh - 235px) !important; */
  /* background-color: #fcfdff; */
    /* border-top: 1px solid #e0ebf8 !important; */
  /* background-color: yellow; */
}


.allStaffAnnLeaveDivModalDesktop {
  /* min-height: calc(100vh - 390px) !important; */
  max-height: calc(100vh - 320px) !important;
  /* border-top: 1px solid #e6eaee; */
  /* background-color: #fcfdff; */
  /* border-top:1px solid #e0ebf8 !important */
}

.mgrReqFullName {
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  color:white;
  line-height:18px;
  /* margin-left: 10px; */
  /* margin-top: 10px; */
}

.mgrReqFullName:hover {
  /* color: #ecf0f5;
  transform: scale(1.05); */
}

.allStaffReqsDataValue {
  font-size: 14px;
  margin-right: 15px;
  font-weight: 500;
  color:#f4f8fe;
  opacity: 0.8;
  text-align: right;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;}

.topWhiteBorder {
  border-top: #ffffff1c solid 2px;
}

.hoveredReqUserContainer {
  min-width: 72px;
  margin-right: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 40px;
  
}

.fixedReqUserContainer {
  min-width: 72px;
  /* margin-right: 5px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  margin-left: 12px;
  margin-top: -22px;
  
}


.viewReqFilterSideBoxImg {
  background-color: #5d2ec2;
  border: 2px solid #4b1caf;
  border-radius: 5px;
  min-width: 34px;
  max-width: 34px;
  min-height: 34px;
  max-height: 34px;
  padding: 2px;
  padding-bottom: 2px;
  padding-top: 2px;
  padding-left: 4px;
  padding-right: 0px;
  cursor: pointer;

}

.viewReqFilterSideBoxImg:hover {
  background-color: #4b1caf;
}

.viewUserOnReqSideBoxImg {
  background-color: #0af;
  border: 2px solid #0562a2;
  border-radius: 5px;
  min-width: 34px;
  max-width: 34px;
  padding: 8px;
  cursor: pointer;

}

.amendAllowBtn {
  background-color: #d264ff;
  border: 1px solid #a058be;
  border-radius: 5px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  /* max-width: 128px; */
  color: white;
  padding: 8px;
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;

  /* float:left */
/* 
  color: #516b8b;
  border-radius: 5px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  min-height: 26px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 8px;
  cursor: pointer;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 20%);
  margin-left: 3px;
  border: 1px solid white; */
  /* background-color: white; */
}

.amendAllowBtn:hover {
  background-color: #ca5ef6;

}

.viewUserOnReqSideBoxImg:hover {
  background-color: #0077cc;
}


.reqsUserIDTab {
  color: #fff;
  background-color: #5d2ec2;
  border: 2px solid #4b1caf;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-radius: 5px;
  padding-left: 4px;
  padding-right: 10px;
  font-size: 14px;
  min-height: 30px;
  cursor: pointer;
}

.reqsUserIDTab:hover {
  background-color: #4b1caf;
}

.reqsUserIDTabMob {
  background-color: #c2cce9;
  color: #fff
}
.reqsUserTabNavyCrossImg {
  max-width: 12px;
  margin-right: 9px;
  margin-left: 4px;
  /* margin-top:1px; */
}

.myReqsBtnCorrectionUserID {
  margin-top:33px
}

.outstandingReqBlob {
  min-width: 12px;
  min-height: 12px;
  max-width: 12px;
  max-height:12px;
  background-color: #00ccff;
}

.typesDropdown {
  margin-top: 8px !important;
  color: #143951 !important;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff !important;
  background-position-x: 137px !important;
  border: 1px solid #e6eaee  !important;
  margin-left:5%  !important
}

.typesDropdown:hover {
  border-color: #d7dfe9 !important
}

.upcomingTeamStaffSelect {
  min-height: 55px;
  max-height: 55px;
  background-color: #ebf0f7;
  /* margin-top: -34px; */
  min-width: 300px;
  max-width: 300px;
  padding-top: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* padding-right: 4%; */
  position: relative;
  /* background-color: black; */
  margin-left: 3px;
  z-index: 999;}

  .teamReqsSelectorBar {
    min-height: 50px;
    max-height: 50px;
    background-color: #ebf0f7;
    /* margin-top: -34px; */
    min-width: 100%;
    max-width: 100%;
    padding-top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-right: 4%; */
    position: relative;
    flex-direction: row-reverse;
    padding-left:1%;
    /* background-color: black; */
    /* margin-left: 3px; */
    z-index: 999;
  }

  .x1378491 {
    padding-left:5%
  }


  .historyOrDeclinedFilterBar {
    justify-content: space-between !important
  }

  .myPreviousReqs {
    padding-top: 12px;
  }

  .mgrReqDataBoxFilterBar {
    display: flex;
    align-items: center;
  }

  .backSelUserIDReqDataImg {
    max-width: 19px;
    min-width: 19px;
    margin-top: -6px;
    margin-left: 21px;
    margin-right: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;

  }

  .backSelUserIDReqDataImg:hover {
    /* transform: scale(0.9); */
    opacity: 0.8;
  }

  .showBackIcon {
    max-width: calc(90% - 40px);
    min-width: calc(90% - 40px);
    margin-left: 5px;
  }


  .otherCustomsContainerOpen {
    margin-top: -10px
  }

  .teamReqsFilterBar {
    min-width: 33% !important;
    max-width: 33% !important;
    /* margin-left: 39px !important; */
  }

  .upcomingFilterBarTeam {
    margin-left: 15px !important
  }



  .userReqCostTxt {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    min-width: -webkit-fit-content !important;
    min-width: -moz-fit-content !important;
    min-width: fit-content !important;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    justify-content: space-between;
    max-height: 24px;
    align-items: center;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    font-size: 0.8em;
    padding-top: 3px;
    padding-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0077bb;
margin-top:-1px;  }

.userReqCostTxtPast {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: -webkit-fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  align-items: center;
  border-radius: 5px;
  font-weight: 500;
  font-size: 0.8em;
  padding-top: 3px;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e3e8ee;
  color: #4f566b;margin-top:-1px;  }

  .mgrReqItem {
    background-color: #fff;
    max-width: 90%;
    min-width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 72px;
    max-height: 72px;
    margin: 0px auto 0;
    /* border: 1px solid #fff; */
    border-radius: 5px;
padding:1px;
    cursor: pointer;
    /* -webkit-animation-name: zoomModalIn!important;
    animation-name: zoomModalIn!important;
    -webkit-animation-duration: .1s!important;
    animation-duration: .1s!important; */
    /* box-shadow: 0px 0px 2px rgb(0 0 0 / 20%); */
    border: 1px solid #dee5f0;
    margin-bottom:10px;


  }


  .leaveReqItem {
    margin-bottom:0px;
    margin-top:10px;
  }
  .x134814 {
    margin-top: 20px;
  }


  .mgrReqItemCol1{
    /* background-color: blue; */
    min-width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 72px;
  }

  .mgrReqApprovedDeclinedIcon {
    min-width: 10px;
    /* margin-top: -37px; */
    /* margin-left: 16px;   */

  }


  .mgrReqItemCol2 {
    width: 95%;
    min-height: 50px;
    /* background-color: lime; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 2px;
    /* border-left: 2px dotted #5a9e3f; */
    max-height: 40px !important;
    margin-left: 16px;
    margin-top:1px;
  }

  .reqColBarDiv {
    min-height:33px;
    max-height:33px;
    min-width: 2px;
    /* background-color: #a8cc9a; */
    margin-left:17px;
    margin-right:-17px;
    border-left: 2px dashed #a8cc9a;
   
  }

  .divPend {
    border-color:#bbcada !important
  }


  .reqColBarAdjust1 {
    margin-left: 12px;
    margin-right: -12px;
  }


  .reqColBarAdjust2 {
    margin-top:8px;
    margin-left: -2px;
    margin-right: -2px;
  }

  .reqColBarAdjust3 {
    margin-left: 12px;
    margin-right: -14px;
    border-left: 2px dashed #143051 !important;
  }

  .reqColBarAdjust4 {
    margin-left: 6px;
    margin-right: -16px;
    margin-top: 10px;
  }

  .reqColBarAdjust5 {
    margin-left: -23px;
    margin-right: -27px;
  }
  .reqColBarAdjust6 {
    margin-left: 12px;
    margin-right: -14px;
    border-left: 2px dashed #bbcada !important;
  }

  .reqColBarAdjust7 {
    margin-left: 18px;
    margin-right: -19px;
  }

  .divDec {
    border-color:#ba0000 !important
  }


  .divApp {
    border-left:2px dashed #91d278 !important
  }


  


  .itemTongue {
    min-width: 19px;
    max-width: 19px;

    background-color: #8666a3;
    border-left: 1px solid #644b7a;
    background-color: #214978;
    border-left: 1px solid #143051;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 1px;
    margin-right:-2px
  }

  .leaveTongue {
    background-color: #214978;
    border-left: 1px solid #143051;

  }

  .leaveTongueDeclined {
    background-color: #b24e4e;
    border-left: 1px solid #971515;

  }

  .shiftTongue {
    background-color: #0af;
    border-left: 1px solid #1e88e5
  }

  .shiftTongueMgr {
    background-color: #2A67B2;
    border-left: 1px solid #37576C
    }

  .clockTongue {
    background-color: #699995;
    border-left: 1px solid #436562;
    /* padding-left:1px; */
  }


  .tsTongue {
    background-color: #749fd2;
    border-left: 1px solid #5c7ea7;
    padding-left: 1px;
  }
  .tilTongue {
    background-color: #bbcada;
    border-left: 1px solid #8ea7c5;
  }

  .tilTongueMgr {
    background-color: #8AA9B7;
    border-left: 1px solid #76a1bd
    }


  .overtimeTongue {
    background-color: #859dbb;
    border-left: 1px solid #496375;
    padding-right:2px;
  }

  .overtimeTongueMgr {
    background-color: #6e4c90;
    border-left: 1px solid #5f3e81;
    /* padding-right:1px; */
    /* margin-left: -1px; */
    }

  .tongueTxt {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    color:white;
    font-size:10px; 
    font-weight:500;
    opacity: 0.7;
    text-transform: uppercase;
    white-space: nowrap;

  }

  .leaveTongueTxt {
    font-size:10px;
    /* margin-left:1px; */
  }

  .shTongueTxt {
    font-size:10px;
    /* margin-left:1px; */
  }
  .hoursTongueMgrTxt {
    margin-left:-2px;
  }

  .clockTongueTxt {
    font-size:9px;
    white-space: nowrap;
min-width: -webkit-fit-content;
min-width: -moz-fit-content;
min-width: fit-content;
  }


  .mgrReqTopRow {
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 3%;
    padding-right: 5%;
  }

  .mgrReqBottomRow {
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 3%;
    padding-right: 5%;
    min-height: 29px;
    margin-bottom: -4px;
  }
  
  .mgrReqName {
    color: #143051;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    margin-top: 1px;
    margin-bottom: 1px;
    max-height: 18.5px;
  }
  @media only screen and (max-width: 820px) {
    .mgrReqName {
      font-size: 16px;}
  }



.editReqBtnImg:hover {
  background-color: #bbcada;

}

@media only screen and (min-width: 820px) {
  .editReqBtnImg:hover {
    background-color: #bbcada;
  
  }
}


  .pendingReqBord {
    /* border-left: 2px dashed #bbcada; */
    /* margin-left:2px; */
  }

  .declinedReqBor {
    border-left: 2px dotted #ba0000
  }

  .mgrReqTypeName {
    color: #143051;
    font-size: 12px;
    font-weight: 500;
    margin-top: -3px;
  }

  .mgrReqDateStrip {
    color: #95aaba;
    font-weight: 600;
    font-size: 12px;
    margin-top: 2px;
    text-align: left;
    min-width: 145px;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;

    margin-right: -20px;
    border-top: 1px solid #e0ebf8;
    padding-top: 4px;

  }

  .mgrReqDateStripLong {
    padding-top: 4px;
    border-top: 1px solid #e0ebf8;
    margin-top: 1px !important;
    margin-bottom: 1px;
    padding-top: 1px;
  }

  @media only screen and (max-width: 415px) {
    .mgrReqDateStripLong {
      font-size: 10px;}
  }
 
  .mgrReqDayLengthSpan {
    /* border-left: 1px solid #e0ebf8; */
    color: #95aaba;
    /* padding-left: 6px; */
    /* margin-left: 4px; */
    font-size: 11px;
  }

  .usrReqDayLengthSpan {
    border-left: 1px solid #e0ebf8;
    color: #95aaba;
    padding-left: 8px;
    margin-left: 10px;
    font-size: 11px;
  }
  .mgrReqDayLengthSpanNewLine {
    border: none;
    padding: 0px;
    margin-left: 0px !important;
    /* opacity: 0.5; */
    border-top: 1px solid #e0ebf8;
    padding-top: 0px;
    margin-top: 0px;
    line-height: 16px;
    font-size:10px
  }


  .leftBorderGreen {
    /* border-left: 5px solid #a8cc9a !important; */

  }



  .leftBorderRed {
    /* border-left: 5px solid #e89292 !important; */

  }

  .mgrReqItemPendingBorder {
    /* border: 2px dashed #bbcada !important;
    box-shadow: none !important; */
    background-color: #deedff !important;
    border-bottom: 2px solid #cce3ff !important;

    background-color: #deedff !important;
    border: 1px solid #8bc3ff !important;
    border-bottom: 2px solid #8bc3ff !important
  }




  .mgrReqItemPendingBorder {
    /* background-color: black !important; */
    border:2px dashed #214978 !important
  }

 

  .mgrReqItemPendingBorderMgr {
    border: 2px dashed #bbcada !important;
    box-shadow: none !important;
  }


  .mgrReqItemPendingBorderMob:hover {
    /* padding-left: 1px;
    padding-right:1px;
    border: 2px dashed #bbcada !important;
    box-shadow: none !important; */
  }


  .formLeftIncompleteBorder {
    /* border-left: 5px solid #0af !important; */
    border: 2px dashed #bbcada !important;
    box-shadow: none !important;

  }

  .formLeftIncompleteBorder:hover {
    /* padding-left: 1px !important; */
    border: 2px dashed #214978 !important;
    box-shadow: none !important;
  }

  .formLeftIncompleteBorderPurple {
    border-left: 5px solid #9374d6 !important;

  }



  .formLeftIncompleteBorderPurple:hover {
    padding-left: 1px !important;
  }


  .formLeftIncompleteBorderBlue {
    border-left: 5px solid #0077ff !important;

  }

  .formLeftIncompleteBorderBlue:hover {
    padding-left: 1px !important;

  }

  .formLeftGreen {
    border-left: 5px solid #6db451 !important;

  }

  .formLeftGreen:hover {
    padding-left: 1px !important;
  }

  .mgrReqItemNonPendHover:hover {
    padding-left:1px;
  }
  .mgrReqCostTxt {
    max-width: -moz-fit-content;
    max-width: -webkit-fit-content;
    max-width: fit-content;
    min-width: -moz-fit-content;
    min-width: -webkit-fit-content !important;
    min-width: fit-content !important;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    justify-content: space-between;
    max-height: 24px;
    /* line-height: 24px; */
    align-items: center;
    border-radius: 4px;
    /* border: 1px solid #859dbb; */
    color: #fff;
    font-weight: 500;
    font-size: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #c6b5eb;
    margin-top: 3px;
    margin-bottom: -3px;
    border: #ac99d4 solid 1px;
    /* margin-left: 9px; */
    /* display:none */
  }


  .declinedCostReqColours {
    background-color: #e3e8ee;
    color: #4f566b;
  }

  

.mgrReqDurAndCostDiv {
  display: flex;
  min-width: 128px;
  justify-content: flex-end;
margin-top:-3px;
}





  .mgrReqDurTxt {
    max-width: -moz-fit-content;
    max-width: -webkit-fit-content;
    max-width: fit-content;
    min-width: -moz-fit-content;
    min-width: -webkit-fit-content !important;
    min-width: fit-content !important;
   
    font-weight: 500;
    padding-top: 3px;
    padding-bottom: 3px;


    margin-top: 3px;
    margin-bottom: -3px;

  margin-right: 8px;




    font-size: 11px;
    text-align: left;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 4px;

    max-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #bbcada;
    /* background-color: #f4f8fe; */
    border: 1px solid #bbcada;



  font-size: 10px;
  font-weight: 500;
  background: #f4f8fe;
  border-radius: 5px;
  border: 1px solid #bbcada;
  color: #516b8b;

  }

 

  .startsInTxt {
    font-size: 10px;
    opacity: 1;
    font-weight: 500;
    margin-top:0px;
    color:#0af;
    text-align: right;
    /* opacity: 0.5; */
    /* display: none; */
  }

  .x19844381 {
    margin-right:8px;
  }

  .startsInTxtAbsence {
    font-size: 10px;
    opacity: 1;
    font-weight: 600;
    margin-top:2px;
    color:#0af;
    text-align: right;
    color:#bbcada;
  }
  .myReqsFilterBar {
    min-width: 88%;
    max-width: 88%;
    /* background-color: orange; */
    min-height: 100%;
    display: flex;
    justify-content: space-between;
    /* margin-left: 30px; */
    margin-top: 8px;
  }

  .myReqsFilterBarFull {
    margin-left:30px;
  }

  .x1038198 {
    margin-left:0px;
  }
  

  .myReqsYearDropdown {
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    padding-left: 9px;
    text-align: left !important;
    max-width: 70px;
    min-width: 70px;
    line-height: 26px;
    height: 35px;
    -webkit-appearance: none;
    display: flex;
    cursor: pointer;
    border-color: #2c7eb9;
    border: 1px solid #e6eaee;
    background-color: white;
    color: #143051;
    background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
    background-position-x: 50px;
  }

  .myReqsYearDropdown:hover {
    border-color: #dce2e9;

  }
  

  .myCalMonthsDropSize {
    background-position-x: 124px;
    max-width: 147px;
    min-width: 147px;
    margin-right: 4px;
  }

  .marginLeft17px {
    margin-left: 17px !important
  }


  .myReqsTypesDropdown {
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    padding-left: 12px;
    text-align: left !important;
    max-width: 220px;
    min-width: 220px;
    line-height: 26px;
    height: 35px;
  
    border: 1px solid #9374d6;
    -webkit-appearance: none;
    background-position-x: 66px;
    display: flex;
    cursor: pointer;
    border-color: #2c7eb9;
    border: 1px solid #e6eaee;
    /* background-color: #0bf; */
    background-color: white;
    color: #143051;
    background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
    background-position-x: 196px;
    /* margin-right:5% !important */
    /* text-overflow: ellipsis; */


  }

   @media only screen and (max-width: 410PX) {
    .myReqsTypesDropdown {
      min-width: 200px !important;
      max-width: 200px !important;
      background-position-x: 176px; 
    }
  }


  .myReqsTypesDropdown:hover {
    border-color: #dce2e9;
  }

  .mobMyReqsFilterbar {
    margin-left: 22px !important;
    min-width:94%;
  }


  .mobMyReqsFilterbarLeavePage {
    margin-left:0px !important;
  }

  .nonUpcomingFiltBarMob {
    margin-left: 5% !important;
    min-width: 90%;  }

  .teamMobFilterBar {
    /* background-color: blue; */
    /* margin-left: -10px */
  }

 



  .noBottomPadding {
    padding-bottom: 0px !important
  }


  .closeReqDataBoxImg {

    cursor: pointer;
    min-width: 15px;
    margin-left: 8px;
    cursor: pointer;
    margin-top: -8px;
    margin-right: -100px;
  }

  .closeReqDataBoxImg:hover {
    opacity: 0.8;
  }

  .font18px {
    font-size: 18px !important
  }

  .font16px {
    font-size: 16px !important
  }


  .approvedByNameTxt {
    /* background-color: yellow; */
    margin-bottom: -20px;
  }

  .usedRemainingIndent {
    margin-left: 7px;
    font-size: 12px !important
  }

  .colourNavy {
    color: #143051;
  }

  .backgroundNavy {
    background-color: #143051 !important;
  }

  .manageExcessOnReqBtn {
    color: #fff;
    border: solid 1px #bbcada;
    background-color: #cbd7e6;
    font-size: 14px;
    padding-left: 7px;
    padding-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: -moz-fit-content;
    max-width: -webkit-fit-content;
    max-width: fit-content;
    border-radius: 5px;
    margin-left: 10px;
    min-height: 29px;
    cursor: pointer;
    padding-bottom: 1px;
}

.manageExcessOnReqBtn:hover {
  background-color: #bbcada;
}

.manageExcessWhiteGoImg {
  max-width: 12px;
  margin-left: 5px;
  margin-right: 2px;
  /* margin-top: 1px; */
}

.expandCostArrImg {
  max-width: 8px;
  margin-left: 5px;
  margin-right: 2px;
  margin-top: 1px;
}

.startEndTimeHolder {
  min-height: 22px;
  max-height: 22px;

}

.requestProfPicImg {
  cursor: pointer;
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
border-radius: 5px;
  object-fit: cover;
  border: 2px solid #143051;
  /* margin-bottom: 12px; */
  margin-top: -4px;

}

.requestProfPicImg:hover {
border-color: #2066b6;}



.reqBlankProfPicHolder {
  cursor: pointer;
  background-color: #516B8B;
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
border-radius: 5px;
  border: 2px solid #143051;
  margin-top:-5px
}

.reqBlankProfPicHolder:hover {
border-color: #2066b6}

.reqBlankProfilePicPlaceholderImg {
  cursor: pointer;
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  object-fit: cover;
padding-right: 4px;
padding-bottom:2px;
}



.reqBlankProPicInitialsHolder {
  color: white;
  display: flex;
  font-size: 30px;
  margin-top: -66px;
  min-height: 60px;
  min-width: 60px;
  max-height: 60px;
  max-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  font-weight:500
}


.overlapsRenewalModalUnderlay {
  background: rgba(0, 0, 0, 0.5);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.overlapsRenewalModalBox {
  min-height: 100px;
  max-width: 300px;
  min-width: 300px;
  padding: 20px;
  border-radius: 10px;
  margin: 0 auto;
  justify-content: space-between;
  background-color: white;
  color: #143051;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  -webkit-animation-name: zoomBtnIn !important;
          animation-name: zoomBtnIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  cursor: default;
}

.overlapsRenTxt {
  margin-bottom: 10px;
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
}

.reqsIndividualNameTitle {
  color: white;
  font-weight: 500;
  font-size: 16px;
  margin-left: 10px;
  margin-top: -4px;}

  .dataBoxMin {
    max-height:280px !important
  }


  .minHeightFitContent {
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content
  }

  .nOutOfDaysSpan {
    color: #143051;
    border-radius: 5px;
    padding-left: 2px;
    /* padding-right: 5px; */
    font-size: 18px;
    /* padding-top: 2px; */
    /* padding-bottom: 2px; */
    font-weight: 500;
    cursor: pointer;
    font-size: 15px;
 }

 .nOutOfDaysSpan:hover {
  opacity: 0.8;
 }

 .colourDarkRed {
  color: #ba0000
 }

    .limitExceededReqTxt {
      font-size: 18px;
      line-height: 18px;
      font-weight: 500;
      color: #496375;
    }

  
    
    .exceededDaysDiv {
      display: flex;
      justify-content: flex-start;
      margin-top: 8px;
      margin-bottom: -7px;
    }


    .limitsDrop {
      cursor: pointer;
    min-width: 22px;
    max-width: 22px;
    max-height: 22px;
    min-height: 22px;
    background-color: #cbd7e6;
    border: 1px solid #bbcada;
    padding: 6px;
    border-radius: 5px;
    margin-left: 10px;
    /* margin-top: -2px; */
    }

    .limitsDrop:hover {
      background-color: #bbcada;
    }

    .reqLimitsArray {
margin-top:14px;   
-webkit-animation-name: costDivDown !important;   
        animation-name: costDivDown !important;
-webkit-animation-duration: 0.12s !important;
        animation-duration: 0.12s !important;
border-left: 1px solid #d7e1ee;
border-bottom: 1px solid #d7e1ee;
max-width:90%;
border-radius: 0px 0px 0px 5px; }

    .reqLimitRow {
      min-height: 30px;
      display: flex;
      flex-direction: column;
      /* align-items: center; */
      justify-content: center;
      color: white;
      font-weight: 500;
      /* border-radius: 5px; */
      /* margin-top: 6px; */
      max-width: 92%;
      border-left: 1px solid #d7e1ee;
      border-bottom: 1px solid #d7e1ee;
      margin-left: 5%;
      min-height: 50px;}

      .limRowFlex {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        max-height: 30px;
        min-height: 30px;
        margin-bottom: -15px;
        border-bottom: 1px solid #e3e8ee;
        padding-bottom: 7px;
        margin-left: 6px;
        max-width: 90%;
        border-left: 1px solid #e3e8ee;
        border-radius: 0 0 0 5px;
        padding-top: 8px;
        margin-top: -7px; 
      padding-left:6px;}

    .firstLimRowFlex {
      margin-top: 7px;
    }

      .limitByJtOrTeamSpan {
        color: #143051;
        font-weight:500;
        font-size:12px;
      }

      .costContractedExcessSpan {
        margin-left:10px;
      }

      .limRowTitleSpan {margin-right:8px;
        font-size: 12px;
        color: #bbcada;
        margin-left: 4px;
        font-weight: 500;}

      



.limArr {
  color: #143051;
  margin-left: 2%;
  font-size: 16px;
  font-weight: 500;
  margin-top: 6px;
}


.limitBtLimRowTxt {
  font-size: 12px;
  margin-left: 0px;
  font-weight: 500;
  border-bottom: 1px solid #e3e8ee;
  max-width: 125px;
  margin-left: 6px;
  border-left: 1px solid #e3e8ee;
  border-radius: 0 0 0 5px;
  padding-left: 10px;
  margin-top: -7px;
  padding-top: 8px;
  padding-bottom: 7px;
  color: #bbcada;
}


.reqLimitsContainer {
  color: #496375;
  /* margin-left: 10px; */
  /* border-bottom: 1px solid #d7e1ee; */
  padding-bottom: 14px;
  padding-top: 20px;
  min-width: 96%;
  max-width: 96%;
  padding-left: 10px;
  background-color: #f4f8fe;
  border-radius: 5px;
  margin-top: 18px;
  padding-bottom: 24px;
  border: 1px solid #e6eaee;
  cursor: pointer;
}


.userReqLimitsContainer {
  color: #496375;
  /* margin-left: 10px; */
  /* border-bottom: 1px solid #d7e1ee; */
  padding-bottom: 14px;
  padding-top: 18px;
  min-width: 196px;
  max-width: 196px;
  padding-left: 10px;
  background-color: #f4f8fe;
  border-radius: 5px;
  margin-top: 18px;
  padding-bottom: 24px;
  border: 1px solid #e6eaee;
  cursor: pointer;
  margin-left: 18px;
}
.userReqLimitsContainer:hover {
  background-color: #fafcff;
}
.reqLimitsContainer:hover {
  background-color: #fafcff;
}

.viewReqTeamCalWarning {
  background-color: #ba0000;
  border: #931414 1px solid;
  min-width: 60px !important;
  max-width: 60px !important;
  padding-left: 3px;
  padding-right: 2px;
  margin-left: 4px;
  font-weight: 500;
  font-size: 12px;

}

.viewReqTeamCalWarning:hover {
  background-color: #a22222;
}

.limitsRedTriangleImg {
  max-width:14px;
}
.reqUnderstaffedTxt {
  color: #143051;
  font-weight: 700;
  font-size: 10px;
  margin-top:3px;
margin-left:4px}

.requestsPageUnderstaffedWarnImg {
  max-width: 13px;
  margin-left:8px;
}



.costBoxNonSelect {
  color: #496375;
  /* margin-left: 10px; */
  /* border-bottom: 1px solid #d7e1ee; */
  padding-bottom: 14px;
  padding-top: 2px;
  min-width: 86%;
  max-width: 86%;
  padding-left: 10px;
  background-color: #f4f8fe;
  border-radius: 5px;
  margin-top: 4px;
  padding-bottom: 4px;
  border: 1px solid #e6eaee;
  /* cursor: default; */
  margin-left: 18px;
  margin-top: 22px}

  .understaffedRed {
    color: #ba0000 !important
  }

  .closeTeamCalModalImgMob {
    margin-left:10px
  }
  .reqTeamCalContainerMobModalHeight {
    max-height: 480px
  }

  .teamBtnContainerUserReq {
    min-height:50px;
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: space-between; */
    /* background-color: blue; */
  }

  .txtFontSize16px {
    font-size: 16px !important
  }

  .reqsMenuTeamBtnSel {
    color:#9576cc  !important;
    /* border-bottom: 3px solid #ffa8ff; */
    /* padding-bottom: 5px */
  }

  .reqsMenuTeamBtnSel:hover {
    color:#9576cc  !important;
    /* border-bottom: 3px solid #ffa8ff !important; */
    /* padding-bottom: 5px */
  }

  .staffReqsTitleDynamic {
    font-size: 28px;
  }

  /* @media only screen and (max-width: 400px) {
    .staffReqsTitleDynamic {
font-size:24px !important;
  
    }
  }



  @media only screen and (max-width: 375px) {
    .staffReqsTitleDynamic {
font-size:22px !important;
  
    }
  }


  @media only screen and (max-width: 343px) {
    .staffReqsTitleDynamic {
font-size:18px !important;
  
    }
  } */

  .reqsTeamHoverBtn:hover {
    color: #9973dd !important;
  }

  .namedReqsBackBtn {
    background-color: #b49ce7;
    border: 1px solid #965ade;
    max-width: 124px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    color: white;
    display: flex;
    align-items: center;
    min-height: 30px;
    margin-top: 2px;
    margin-bottom: 3px;
    justify-content: space-around;
    padding-left: 1px;
    padding-right: 1px;
    cursor: pointer;
}

.namedReqsBackBtnMob {
  max-width: 130px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 2px;
  margin-bottom: 3px;
  color: white;
  display: flex;
  align-items: center;
  min-height: 30px;
  justify-content: space-around;
  padding-left: 1px;
  padding-right: 2px;
  cursor: pointer;
  background-color: #9a74eb;
  border: 1px solid #965ade;
}

.namedReqsBackBtn:hover {
  background-color: #967ccf
}

    .namedReqsBackBtnImg {
      max-width: 12px;
      margin-right: -9px;
      margin-left: -2px;
    }

    .pullStaffHoursBtnUp {
      margin-top: -18px !important;
    }

    .pullStatsBtnUp {
      margin-top: -22px !important
    }



    .incSal {
      background-color: #c6b5eb;
      margin-top: 21px;
      margin-left: 5px;
      color: white;
      max-width: 56px;
      text-align: center;
      border-radius: 3px;
      margin-bottom: -11px;
      font-size: 14px;
      font-weight: 500;
      min-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;  
     }
  


    .incSal2 {
      background-color: #c6b5eb;
      margin-top: 0px;
      margin-left: 0px;
      color: white;
      min-width: 56px;
      text-align: center;
      border-radius: 3px;
      margin-bottom: 0px;
      font-size: 14px;
      font-weight: 500; 
    border: 1px solid #b3a2d7 ;
    min-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;  
  }


    .incSal3{
      background-color: #c6b5eb;
      margin-top: 8px;
      margin-left: 8px;
      color: white;
      max-width: 56px;
      text-align: center;
      border-radius: 3px;
      margin-bottom: 0px;
      font-size: 14px;
      font-weight: 500;
      border: 1px solid #b3a2d7 ;
      min-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;  
    }
    

    .incSal4{
      background-color: #c6b5eb;
      margin-top: 8px;
      margin-left: 8px;
      color: white;
      max-width: -webkit-fit-content;
      max-width: -moz-fit-content;
      max-width: fit-content;
      text-align: left;
      border-radius: 3px;
      margin-bottom: 0px;
      font-size: 13px;
      font-weight: 500;
      border: 1px solid #b3a2d7;
      min-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 7px;
      line-height: 16px;
      padding-top: 4px;
      padding-bottom: 4px;
      padding-right:5px;

    }
    
    

      .marginTop126px_ {
        margin-top: 19px !important
      } 


      .myReqsTitle2 {
margin-top: -18px !important;
   }




/* .pageHeaderMain {
  min-width: calc(100vw - 240px);
  max-width: calc(100vw - 240px);
  background: blue;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.pageHeaderRow1 {
  background-color: orange;
  margin-left:5%;
  min-height:100px;
}

.pageHeaderRow1Title {
  font-size:33px; 
  color: #143051;
  font-weight:500;
}

.pageHeaderRow2 {
  max-height:1px
} */

.reqsPageTeamsSelector {
  margin-top: 8px;
}

.reqsFiltBarLeft {
      /* min-width: 37%; */
    /* max-width: 37%; */
    /* background-color: orange; */
    min-height: 100%;
    display: flex;
    justify-content: flex-start;
    /* margin-left: 8%; */
    margin-top: 8px;
}

.reqSalPlusSpan {
  margin-left:4px;
  font-size:10px
}

.mobReqsAllowancesBtn {
  max-height: 35px !important;
  min-height: 35px !important;
  background-color: #9a74eb !important;
  border: 1px solid #965ade !important;
  color: white !important;
  min-width: 112px !important;
  max-width: 112px !important;
  border-radius: 5px;
  font-weight:600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top:8px;
  cursor: pointer;
  border: 1px solid #e6eaee  !important;
  background-color: white !important;
color: #8ea7c5 !important
  
}


.mobReqsAllowancesBtn:hover {
  border-color: #d7dfe9 !important;
}
.mobReqsAllowancesBtnTxt {
  margin-top:-1px;
  font-size:14px;
  margin-right:3px;
  
  font-weight: 500;
  /* margin-left:-2px; */
}

.mobReqStatsImg {
  max-width: 14px;
  margin-left: 1px;
  margin-right: 0px;
}

.reqPageFilterBarMyUpcomingNonFull {
  /* background-color: orange !important; */
  min-width: 100%;
  max-width:100%;
  padding-left: 5%;
  padding-right: 5%;
  flex-direction: row-reverse;
  justify-content: space-between;}


  .myReqsFilterBarUpcomingNonFull {
    min-width: auto;
    max-width:auto;
  }

  
  .isAbsenceLeaveTypeBorderLeft {
    border-radius: 3px;
    border: 1px solid #cbd7e6;
    border-left: 3px solid #c95858;
  cursor: pointer;  }

    .isLeaveTypeBorderLeft {
      border-radius: 3px;
      border: 1px solid #cbd7e6;
      border-left: 3px solid #9374d6;
    cursor: pointer;  }

    .isLeaveTypeBorderLeft:hover {
      background-color: #edf3f9;
    }

    .mobMyReqsTypesDropFixedPos {
      position: fixed;
      right:30px;
    }

    .reqsPageFilterHolder {
      /* background-color: yellow ; */
      /* max-width:96% */
    }

    .myReqsTypesDropdownMob {
      /* margin-right:5%; */
    }


    .mgrDesktopDataRowBorder {
      border-bottom: 2px solid #516b8b !important;
      padding-bottom:12px;
      margin-top:8px;
      margin-bottom:4px;
      /* min-height:0px; */
    }

    .showingUsrReqsTxt {
      font-weight:600;
      font-size:14px;
      color: #496375;
      margin-top:14px;
    margin-right:20px;
    border: 1px solid #bbcada;
    border-radius:5px;
    padding:5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left:10px;
    padding-right:9px;
    min-height:35px;
    max-height:35px;
    cursor: pointer;
    background-color: white;
    }

    .showingUsrReqsTxt:hover {
      border-color: #496375
    }
    .closeShowNamedReqsImg {
      max-width:10px;
      margin-left:6px;
      margin-right:3px;
    }

    .makeTxtPrimary {
      /* color: #0077ff !important; */
      background: none !important;
      font-size:14px;
      border:none
    }

    .showingNameReqsOnlyTxt {
      /* background-color: blue; */
      margin-left:40px;
      font-size: 16px;
      text-align: left;
      margin-top:29px;
      /* padding-left: 50px; */
      /* padding-top: 20px; */
      /* padding-bottom: 10px; */
      /* padding-right: 26px; */
      color: #bbcada;
      /* min-height: 130px; */
      font-weight: 500;
    }

    .totSalIncludedSize {
      max-width: -webkit-fit-content !important;
      max-width: -moz-fit-content !important;
      max-width: fit-content !important;
      min-width: -webkit-fit-content !important;
      min-width: -moz-fit-content !important;
      min-width: fit-content !important;

      padding-left:6px;
      padding-right:6px
    }

    .totSalIncl {
      margin-left:6px;
      font-size:10px;
    }

    .reqsSideBoxHeader {
      margin-bottom:6px !important;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .amendAllowsImg {
      max-width:10px;
      margin-left:8px;
    }

    .topMgrLeaveInfo {
      border: 1px solid #7e60af;
      background: #b997f1;

      border-radius: 5px;
      max-width: 90%;
      margin-left: 5%;
      margin-top: 10px;
  
    }

    .modalDataBoxHeading {
      font-size:20px !important;
      padding-top:0px !important;
      margin-left:38px; 
      margin-top:-8px !important;
    }

    .firstDataVal {
      margin-top: 14px;
    }

    .modalMyReqsDataBoxTitle {
    /* margin-top: -8px; */
    margin-left: 114px;    }

    .modalMyAbsTitle {
      /* margin-top:-10px; */
      font-size:18px !important;
      /* margin-left:64px; */
      margin-left:115px;
      padding-bottom:9px
    }

    .modalMyAbsNoCustoms {
      max-height:345px;
      min-height:345px;
    }


    .addAbsenceItemsBtn {
      background-color: #0bf;
      border-radius: 5px;
      max-width: -webkit-fit-content;
      max-width: -moz-fit-content;
      max-width: fit-content;
      border: 1px solid #0af;
      max-height: 35px;
      color: white;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      padding-left: 8px;
      padding-right: 8px;
      cursor: pointer;
      margin-left: 7%;
      margin-top: 10px;    }

    .addAbsenceItemsBtn:hover {
      background-color: #0af;
    }

    .closeAddAbsItemsBtn {
      border-radius: 5px;
      max-width: -webkit-fit-content;
      max-width: -moz-fit-content;
      max-width: fit-content;
      border: solid 1px #bbcada;
      background-color: #cbd7e6;
            max-height: 35px;
      color: white;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      padding-left: 12px;
      padding-right:12px;
      cursor: pointer;
      margin-left: 7%;
      margin-top: 10px;
    }

    .closeAddAbsItemsBtn:hover {
      background-color: #bbcada;

    }

    .addAbsenceItemsContainer {
      border: 2px solid #e0ebf8;

    max-width: 90%;
    margin-left: 5%;
    border-radius: 5px;
    min-height: 40px;
    overflow: auto;
    max-height: 210px;
    margin-top: 10px;
    padding-bottom:10px;
    background-color: #f4f8fe;
    }

    


    .addAbsItemUnit {
      display: flex;
      justify-content: space-around;
      min-width: 94%;
      max-width: 94%;
      /* border: 1px solid #e0ebf8; */
      border-radius: 5px;
      max-height: 50px;
      margin-top: 10px;
      margin-left: 10px;
      border: 1px solid rgba(89, 89, 89, 0.267);
      border-bottom: 2px solid rgba(89, 89, 89, 0.267);
      background-color: white;
      cursor: pointer;
    }


    .addAbsItemUnitForm {
      display: flex;
      justify-content: space-around;
      min-width: 94%;
      max-width: 94%;
      /* border: 1px solid #e0ebf8; */
      border-radius: 5px;
      max-height: 50px;
      margin-top: 10px;
      margin-left: 10px;
      border: 1px solid rgba(89, 89, 89, 0.267);
      border-bottom: 2px solid rgba(89, 89, 89, 0.267);
      background-color: white;
      /* cursor: pointer; */
    }


    .addAbsItemUnitForm2 {
      display: flex;
      justify-content: space-around;
      min-width: 94%;
      max-width: 94%;
      /* border: 1px solid #e0ebf8; */
      border-radius: 5px;
      max-height: 50px;
      margin-top: 10px;
      margin-left: 10px;
      border: 1px solid rgba(89, 89, 89, 0.267);
      /* border-bottom: 1px solid rgba(89, 89, 89, 0.267); */
      background-color: white;
      cursor: pointer;
    }

    .addAbsItemUnitForm2:hover {
      border-color: #a7b3c0
    }

    .addAbsItemUnitDesktop:hover {
      border-color: #859dbb;
      background: white;  }

    .addAbsItemLeft {
      /* background-color: purple; */
      text-align:left;
      min-width:50%;
    }


    .addAbsDate {
      max-height: 25px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    margin-top:4px;
    padding-left:13px;

    }

    .addAbsPeriod {
      /* background-color: yellow; */
      max-height:25px;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      margin-top: -9px;
      color: #bbcada;
      padding-left:13px;

    }



    .addAbsDate2 {
      color: #516b8b;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      margin-top: -4px;
      padding-left: 13px;

    }

    .addAbsPeriod2 {
    /* background-color: yellow; */
    /* color: #516b8b; */
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    margin-top: -30px;
    color: #bbcada;
    padding-left: 13px;

    }

    .addAbsItemRight {
      /* background-color: blue; */
      text-align:right;
      min-width:40%;


    }

    .addAbsTitle{
      /* background-color: orange; */
      max-height:25px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 12px;
      font-weight: 500;
      padding-right: 13px;
      margin-top:4px;
    }

    .addAbsName {
      /* background-color: green; */
      max-height:25px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 12px;
      font-weight: 500;
      margin-top:-11px;
color: #bbcada;
      padding-right: 13px;    }

      .addAbsTitle2{
        color:#516b8b;
        max-height:25px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        font-weight: 500;
        padding-right: 13px;
        margin-top:4px;
      }
  
      .addAbsName2 {
        /* background-color: green; */
        max-height:25px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 12px;
        font-weight: 500;
        margin-top:-9px;
  color: #bbcada;
        padding-right: 13px;    }

      .selectedAddAbsItem {
        /* border-color: #143051 !important;
        background: #0077ff;   */
          }

        .selectedAddAbsItem:hover {
          /* border-color: #143051 !important;
          background: #0077bb;    */
        }


        .formPeriod {
  font-weight: 500;
          margin-left: 7%;
          margin-top: -6px;
        }

        .noPaddingBottom {
          padding-bottom:0px !important
        }


.addFormTitleInput {
  min-width: 80%;
  color: #6a8bb1;
  min-height: 40px;
  font-weight: 500;
  font-size: 15px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 8px;
  background-color: #fdfeff;
  max-width: 80%;
  margin-left: 6%;
  margin-top: 6px;
  resize:none
}

.formInfoInput {
  min-height:100px;
  padding-top:10px;
}
.noAbsItemsToAdd {
  color: #bbcada;
  font-weight: 500;
  text-align: left;
  font-size: 14px;
  margin-bottom: -10px;
  padding-left: 16px;
}

.questionsContainer {
  /* border: 2px solid #e0ebf8; */
  max-width: 90%;
  margin-left: 5%;
  /* border-radius: 5px; */
  /* min-height: 40px; */
  /* overflow: auto; */
  /* max-height: 210px; */
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  padding-right:2px
  /* padding-bottom: 10px; */
  /* background-color: #f4f8fe; */
}




.rtwQitem {
  border: 1px solid #bbcada;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  background: #f4f8fe;  
  display: flex;
  align-items: center;
  justify-content: space-between;
}



.rtwQitemToAnswer {
  border: 1px solid #bbcada;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  background: #f4f8fe;  
  border-left:5px solid #214978
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-between; */
}

.removeRtwQimg {
  max-width: 12px;
  min-width: 12px;
  margin-left: 12px;
  margin-right: 5px;
  cursor: pointer;
}

.removeRtwQimg:hover {
  opacity: 0.8;
}

.addRTWqToFormBtn {
  background-color: #a387df;
  border: 1px solid #896ec3;
  color: white;
  font-weight: 500;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  cursor: pointer;
}

.addRTWqToFormBtn:hover {
  background-color: #896ec3;
}

.addQuestionInput {
  min-width: 87%;
  color: #6a8bb1;
  min-height: 50px;
  font-weight: 500;
  font-size: 15px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 8px;
  background-color: #fdfeff;
  max-width: 87%;
  /* margin-left: 6%; */
  margin-top: 6px;
  resize: none;
  padding-top: 12px;
  line-height: 22px;
  overflow: auto;
}

.addQuestionContainer {
  display: flex;
  justify-content: flex-end;
  padding-right: 13%;
  margin-top: 7px;
}

.addQuestCanx {
  font-size: 14px;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  padding-left: 12px;
  font-weight: 500;
  padding-right: 12px;
  cursor: pointer;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;}

  .addQuestCanx:hover {
    background-color: #bbcada;
  }

.addQuestAdd {
  font-size: 14px;
  color: #fff;
  background-color: #0bf;
  border: solid #0af 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  max-width: 60px;
  min-width: 60px;
margin-left:8px;}

  .addQuestAdd:hover {
    background-color:#0af ;
  }


  .createFormSubmitBtn {
    font-size: 14px;
    color: #fff;
    background-color: #1e88e5;
    border: #0469de 1px solid;
    font-weight: 500;
    cursor: pointer;
    padding-left: 9px;
    padding-right: 9px;
    max-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    min-height: 35px;
    max-width: 70px;
    min-width: 70px;
  }
  .createFormSubmitBtn:hover {
    background-color: #1374e4;
  }
  

  .formCreatedModal {
    min-height: 100px;
    max-width: 300px;
    min-width: 300px;
    padding: 20px;
    border-radius: 10px;
    margin: 0 auto;
    justify-content: space-between;
    background-color: white;
    color: #143051;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    -webkit-animation-name: zoomModalIn !important;
            animation-name: zoomModalIn !important;
    -webkit-animation-duration: 0.2s !important;
            animation-duration: 0.2s !important;
    cursor: default;
    font-weight:500
  }

  .formCreatedModalClose {
    font-size: 16px;
    color: #fff;
    background-color: #3b597c;
    border: solid #214978 1px;
    font-weight: 500;
    cursor: pointer;
    padding-left: 12px;
    padding-right: 12px;
    max-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    min-height: 35px;
    margin-top: 20px;
  }

  .formCreatedModalClose:hover {
    background-color: #214978;
  }

  .reqPlaneDiv {
    min-width: 30px;
    margin-right: -10px;
  }

  .reqPlaneIcon {
    max-width: 22px;
    margin-left: 9px;
    margin-top: 4px; }

    .reqPlaneIconMob {
      max-width:19px;
      margin-left: 9px !important;

      margin-right:3px !important;
    }

    .qIndex {
      color: #bbcada
    }

    .rtwQanswerInput {
      background-color: white;
    }

    .toReviewSplitter {
 
      margin-top: 30px;
      margin-bottom: 5px;
      background-color: #bbcada;
      min-width: 80%;
      min-height: 2px;
      max-width: 80%;
      margin-left: 10%;
      border-radius: 5px;
    }

    .pendingReviewFormsTitle {
      font-weight: 500;
      color: #bbcada;
      font-size: 12px;
      text-align: left;
      margin-left: 7%;
      margin-top: 1px;
      /* margin-bottom: 10px; */
    }

    .upcomingMgrReqsSpan {
      /* background-color: yellow; */
      /* margin-left:4px; */
    }


    .addStaffLeaveBtn {
      background-color: #a387df;
      border: 1px solid #896ec3;
      color: white;
      font-weight: 500;
      min-height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 14px;
      cursor: pointer;
      margin-top: 7px;
      /* margin-left: 9px; */
      font-size: 14px;
      min-width: 106px;
      max-height: 34px;
      margin-top: 8px;
    }

    .addStaffLeaveBtn:hover {
      background-color: #a58fd4;
    }

    .filterBarWithAddLeave {
      /* background-color: orange; */
      min-width: 240px;
      justify-content: space-between !important;
      margin-left:0px !important
    }

    .colourPink {
      /* color: #ffd6ef !important */
    }

    .myLeaveDataMinsSpan {

    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 22px;
    color: #fff;
    }

 

    .highlightReqField2 {
      border:2px solid #00ccff !important
    }


.pHolSpecDayRow {
  background-color: #214978;
  border-radius:5px;
  min-height:100px;
  max-width:94%;
  margin-left:3%;
  align-items: center;
  margin-bottom:10px;
}

.pHolDataRow {
border-bottom:1px solid #7d9ec8;
max-width: 92%;
    margin-left: 4%;
display: flex;
color:white;
font-weight:600;
font-size:14px;
min-height:50px;
align-items: center;
padding-left:12px;
justify-content: space-between;
padding-right:5px;
}

.maxHeight50 {
  max-height:50px !important;
  min-height:50px !important;

}

.customScrollBoxWhenOnlyOnePHolRowShown {
  max-height:calc(100vh - 450px)
}

.customScrollBoxWhenBothPHolRowShown {
  max-height:calc(100vh - 550px)

}

.customScrollBoxWhenOnlyOnePHolRowShownModal {
  max-height: calc(100vh - 347px) !important;
  min-height: calc(100vh - 347px) !important;}

.customScrollBoxWhenBothPHolRowShownModal {
  max-height: calc(100vh - 397px) !important;
  min-height: calc(100vh - 397px) !important;
}


.bankPopup {
  max-width:14px;
  margin-left:2px;
  padding:2px;

}



.bankPopUpViewBtn {
  border:1px solid #bbcada;
  border-radius:3px;
  cursor: pointer;
  background-color: #143051;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:2px;
  padding-left:5px;
  font-size:9px;
  margin-left:6px;
}

.bankPopUpViewBtn:hover {
  background-color: #214978;
}

.showPHolsUnderlay {
  position: fixed;
  top:0;
  left:0;
  background-color: rgb(0,0,0,0.25);
  min-width: 100vw;
  min-height:100vh;
  z-index:999;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.pholsShowModalBody {
  padding-left:10px;
  padding-right:10px;
  padding-bottom:10px;
}

.unsurpassedItem {
  background-color: white !important;
  border-color: #bbcada  !important;
  border-left: #00ccff 3px solid !important;
}

.unsurpassedItemTxt {
  color:#214978 !important
}

.allowStatus {
  background-color: #c6b5eb;
  /* margin-top: 21px; */
  /* margin-left: 5px; */
  color: white;
  max-width: 85px;
  text-align: left;
  padding-left:7px;
  padding-right:5px;
  /* text-align: center; */
  border-radius: 4px;
  margin-bottom: -11px;
  font-size: 12px;
  font-weight: 500;
  padding-top:5px;
  padding-bottom:5px;
  /* min-height: 24px; */
  display: flex;
  line-height:12px;
  align-items: center;
  /* margin-top */
  /* justify-content: center;   */
 }



.suffLeaveReq {
  background-color: #a8cc9a;
  border: 1px solid #6db451;
}

.insuffLeaveReq {
  background-color: #b24e4e;
  border-left: 1px solid #971515;
}


.suffDur {
  border-left: 3px solid #6db451;
  max-height:24px;
  margin-top:20px;
  margin-bottom:12px;
  margin-left:6px;
}

.insuffDur {
  border-left: 3px solid #971515;
  max-height:24px;
  margin-top:20px;
  margin-bottom:12px;
  margin-left:6px;
}


.unpaidCostColours {
  background-color: #bbcada !important;
  border-color: #859dbb !important
}

.mgrAllowsFooter {
  min-height: 50px;
  border-top: #ffffff1c solid 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-left: 5%; */
  /* padding-right: 5%; */
  max-width: 90%;
  margin-left: 5%;
}
.myAllowsFooter {
  min-height: 50px;
  border-top: #ffffff1c solid 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-left: 5%; */
  /* padding-right: 5%; */
  max-width: 90%;
  margin-left: 5%;
}


.modalAllowsHeightForFooter {
  max-height: calc(100vh - 339px);
  min-height: calc(100vh - 339px);
}

.modalMgrReqsTitle {
  /* margin-top:-9px; */
  margin-left:10px;
}

.x981971 {
  visibility: hidden;
  min-width: 4px;
  max-width: 4px;
  min-height: 4px;
  max-height: 4px;
  background-color: #fff;
  border-radius: 100%;
  margin-right: -2px;
  margin-top: -20px;
  margin-left: 48px;
  margin-right: -50px;
}

.noAni {
  -webkit-animation-name: none !important;
          animation-name: none !important;
  -webkit-animation-duration: 0.5s !important;
          animation-duration: 0.5s !important;
}


.x917491 {
  /* background-color: red; */
  /* margin-top:2px; */
}


.x131314 {
  margin-top:-50px !important;
}

.x19749171 {
  margin-top: -2px !important;
  margin-bottom: -10px !important;
display: block !important;
}

.closeMgrReqsBtn {
  min-width: 30px;
  padding:8px;
  cursor: pointer;
  /* background-color: blue; */
}


.mgrAllowsHeaderLeft {
  display: flex;
  align-items: center;
  margin-top:-8px
}

.x23141 {
  margin-top:0px
}

.x29842981 {
  opacity: 0.25;
}

.reqCostLoadImg {
  max-width: 40px;
  margin-left:10px;
  margin-top:23px;
  margin-bottom:14px;
}

.x248981 {
  background-color: #ba0000;
  color: white;
  opacity: 0.7;}


  .mgrReqNameTab {
    background-color: rgb(255, 255, 255);
    border: 1px solid #e7ebef;
    border-bottom: none;
    max-width: -moz-fit-content;
    max-width: -webkit-fit-content;
    max-width: fit-content;
    margin-left: 10%;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 6px;
    padding-right: 12px;
    font-size: 12px;
    font-weight: 500;
    color: #496375;
    border-radius: 5px 5px 0 0;
    font-weight: 500;
    margin-top: -91px;
    margin-bottom: 80px;
    font-size: 12px;
  }

  .reqUnitParent {
    padding-top: 10px;
    /* min-height: 120px !important; */
  }

  .x29382983 {
    /* margin-left:40px !important;  */
  }

  .mgrReqsShoulderTopRight {
    display: flex;
    flex-direction: row-reverse;
  }

  .nonFullAddLeaveBtn {
    margin-right:10px;
    min-height: 34px;
    max-height: 34px;

  }


  .mgrPrevScrollHeightMob {
    /* background-color: blue; */
    min-height: calc(100vh - 230px);
    max-height: calc(100vh - 230px);
  }

  .mgrCtaColours {
    background-color: #6a4171 !important; 
    border: 1px solid #522d58 !important;
  }

  .mgrCtaColours:hover {
    background-color: #7c5782 !important;
  }


  .x238312 {
    line-height:16px;
    font-size:12px !important;
    margin-top:12px;
    margin-bottom:12px;
    max-width: 150px;
    color:#143051 !important;
    font-weight: 500;
  }

  .x7187372 {
    display: flex;
    justify-content: space-between;
    min-width: 100%  !important;
    padding-right:24px;
    align-items: center;
  }

  .x98763274 {
    /* background-color: pink !important; */
    margin-top:-3px;
  }


  .x3388242 {
   margin-left:30px  !important;
  }


  .x33883121 {
    margin-left:0px !important
  }

  .x29488845342 {
    margin-right:15px !important
  }



  .mgrReqJt1 {
    color: white;
    opacity: 0.5;
    font-size: 10px;
    /* margin-top: -3px; */
    margin-bottom: 0px;
    line-height: 10px;
  }

    .mgrReqJt2 {
      color: white;
      opacity: 0.3;
      font-size: 10px;  
    margin-top:-3px
    }

    .mgrReqDataName {
      line-height:16px;
      margin-top:4px;
    }
    .colPur{
      color: #fff  !important
    }

    .x37467283 {
margin-left:3px;    }

.x485839823 {
  background-color: #ebe8fa;
}

.txtColPur {
  color:#653fa7 !important
}

.txtColPur2 {
  color:#a68ad6 !important

}
.txtColPur3 {
  color:#9e79dc !important

}

.enableTilKnob {
      padding-bottom: 2px;
      margin-top: -7px !important;
}

.fixedSchedLeaveDiv {
color:#214978;
font-weight:400;
font-size:12px;
}

.x29482983298311 {
  min-width: 90%;
  margin-left: 5%;}



  .x2948911342 {
    font-size:14px !important; 
  }

  .x660o {
    margin-left:6px
  }

  .x7ssd7 {
    max-height: calc(100vh - 450px);
    min-height: calc(100vh - 450px);
    }

    .marginLeftMinus5 {
      margin-left:-5px !important
    }


.noCustomsDataBoxSize {
  max-height: 347px !important;
  min-height: 347px !important;
}

.x182348129d {
  max-height: 101px !important;
  min-height: 101px !important;
}

.x292929dd {
  max-height: 307px !important;
  min-height: 307px !important;
}

.x1839181s {
  max-height: 357px !important;
  min-height: 357px !important;
}

.x8198111333 {
  min-width: 80px !important;
  max-width: 80px  !important;
  background-position-x: 60px !important
}

.lineHeight18 {
  line-height:18px  !important
}

.pendReqDash {
  color: #bbcada;
  font-size: 12px;
  font-weight: 500;
  margin-right: 8px;}


  .reqsDataDaySpan {
    margin-right: 10px;
    color:#b9e8ff
  }

  .regBgToil {
    background-color: #ba0000;
    color: white;
    border-radius: 4px;
    min-height: 30px;
    padding-left: 6px;
    padding-right: 6px;
  }

.calendarSubBar {
  width: 100%;
  min-height: 45px;
  max-height: 45px;
  display: flex;
  justify-content: space-around;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  font-size: 12px;
  background-color: #496375;
  background-color: #0077ff;
  position: fixed;
  z-index: 101;
  bottom: 40px;
  padding-bottom: 8px;
  font-weight: 500;
  color: #b7c9d6;

  border-top: solid #0469de 2px;

}

.allowTeamSwapsBtnHolder {
  display: flex;
  margin-right: 8px;
  align-items: center;
  border: solid 2px #bbcada;
  background-color: #f4f8fe;
  color: #bbcada;
  font-size: 12px;
  padding-left: 8px;
  padding-right:4px;
  justify-content: space-between;
  border-radius: 5px;
  cursor: pointer;
  text-align: left;
  line-height: 13px;
  font-weight: 500;
}

.allowTeamSwapsBtnHolder:hover {
  border-color: #a6aeb6 !important;
}

.allowTeamSwapsBtnHolder:last-child {
  margin-right: 0px;
}


.purpleBgCalSubbar {
  width: 100%;
  min-height: 45px;
  max-height: 45px;
  display: flex;
  justify-content: space-around;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  font-size: 12px;
  padding-top: 1px;
  position: fixed;
  z-index: 101;
  bottom: 40px;
  padding-bottom: 8px;
  font-weight: 500;
  color: #b7c9d6;
  border-top: solid #2e2a6f 2px;
  background-color: #403a8e;


 
}

.calSubBtn {
  color: #fff;
  font-size: 13px;
  margin-top: 0px;

  opacity: 0.7;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 44px;
  font-weight: 500;
  line-height: 44px;
  display: flex;
  flex-direction: column;
  min-width: 25vw;
  max-width: 25vw;
}

.calSubBtnBlue {
  color: #a3bdff;
  font-size: 13px;
  margin-top: 1px;

  padding-left: 12px;
  padding-right: 12px;
  min-height: 44px;

  line-height: 44px;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  min-width: 25vw;
  max-width: 25vw;

}

.pinkSelectedCalBtn {
  color: white;
}

.calSubBarTriangle {
  max-width: 12px;

  margin: 0 auto;
  margin-top: -10px;
  -webkit-animation: calSubTriSlideUp 0.09s ease-in-out;
          animation: calSubTriSlideUp 0.09s ease-in-out;

}

.calSubBtnSel {
  color: white;

  opacity: 1;


  
}

@-webkit-keyframes calSubTriSlideUp {
  from {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes calSubTriSlideUp {
  from {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    opacity: 1;
  }
}

.calSubBarItemTxt {
}

.calSubBarItemTxt:active {
  -webkit-transform: scale(0.96);
          transform: scale(0.96);
}

.absencePage {
  min-height: calc(100% - 188px);
  max-height: calc(100% - 188px);
  background-color: #ebf0f7;
}
.absencePageUpperContainer {
  background-color: #fff;
  min-height: 125px;
  max-height: 125px;
  min-width: 100%;
  max-width: 100%;
  position: relative;
  z-index: 0;
  border-bottom: 1px solid #e6eaee;
}

.userAbsencesContainer {
  max-width: 90%;
  margin-left: 5%;
}

.absenceListContainer {
  background-color: #ebf0f7;
  min-height: calc(100vh - 215px);
  max-height: calc(100vh - 215px);
  overflow: auto;
  padding-top: 8px;
  margin-top: 5px;
  padding-bottom: 68px;
  position: relative;
  z-index: 0;
}

.showFilterAbsenceContainer {
  min-height: calc(100vh - 310px);
  max-height: calc(100vh - 310px);
  border-top: 1px solid #e6eaee;
}

.absenceMgrMonthFilterBar {
  background-color: #ebf0f7;
  min-height: 40px;
  max-height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5%;
}

.mgrAbsenceListContainerNoTopBorder {
  border: none;
}

.myAbsenceUpperInnerContainer {
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  margin: 0 auto;
}
.myAbsenceTitle {
  color: #143051;
  font-weight: 500;
  font-size: 1.5em;
  margin-bottom: 0px;
  text-align: left;
  margin-top: 51px;
  padding-left: 5%;
}

.absenceMenuBar {
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
  color: #c8e8f0;
  font-weight: 500;
  max-width: 90%;
  margin: 0px auto;
  min-height: 40px;
  max-height: 40px;
}

.absenceMenuLeft {
  min-width: 137px;
  max-width: 137px;
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
}

.absenceMenuTeam {
  background-color: #a387df;
  border: 1px #8e76c1 solid;
  display: flex;
  color: #fff;
  min-height: 36px;
  line-height: 34px;
  padding-left: 9px;
  border-radius: 5px;
  text-align: center;
  margin-left: 5px;
  margin-right: 8px;
  min-width: 68px;
  max-height: 35px;
  min-height: 35px;

  font-size: 1em;
}

.jamAbs {
  opacity: 0.3;
  pointer-events: none;
}

.absenceTeamSelectBar {
  display: flex;
  color: #fff;
  align-items: flex-end;
  padding-bottom: 6px;
  min-height: 60px;
  max-width: 95%;
  min-width: 95%;
  overflow: auto;
  white-space: nowrap;
  padding-right: 35px;

  padding-left: 5%;

  margin-top: -23px;
  margin-left: 30px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.absenceTeamSelectBar::-webkit-scrollbar {
  width: 5px !important;
  height: 4px !important;
}

.teamNameAbsence {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  display: flex;
  min-height: 26px;
  margin-left: 20px;
  border-bottom: 3px solid white;
  font-size: 14px;
  font-weight: 500;
  color: #8290b6;

  cursor: pointer;
}

.teamNameAbsence:first-of-type {
  margin-left: 0px;
}

.teamNameAbsence:active {
}

.teamNameAbsence:hover {
  color: #4f79ab;
  border-bottom: 3px solid #dde9f9;
}

.absencemenutxt:active {
  -webkit-transform: scale(0.96);
          transform: scale(0.96);
}

.absenceTeamSelected {
  color: #723fce;
  border-bottom: 3px solid #ddd2ff;
  font-weight: 500;
  font-size: 14px;
  cursor: default;
}

.absenceTeamSelected:hover {
  color: #723fce;
  border-bottom: 3px solid #ddd2ff;
}

.myRotaTeamSelected {
  color: #214978;
  border-bottom: 3px solid #00ccff;
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 5px;
  cursor: default;
}

.myRotaTeamSelected:hover {
  color: #214978;
}

.teamAbsenceFaderImg {
  position: fixed;
  top: 96px;
  right: calc(5% - 1px);
  pointer-events: none;
}

.teamAbsenceTitleContainer {
  display: flex;
  max-height: 30px;
  padding-left: 5%;
  min-width: 200px;

  margin-top: 50px;
}

.teamAbsenceTitle {
  color: #143051;
  font-weight: 500;
  font-size: 1.4em;

  text-align: left;
  margin-left: 15px;
  margin-top: 3px;
}

.returnBackFromTeamAbsenceImg {
  max-width: 13px;
  margin-top: -6px !important;
  cursor: pointer;
}

.returnBackFromTeamAbsenceImg:hover {
  opacity: 0.8;
}

.absenceMgrReturnImg {
  margin-top: 10px;
}
.absenceUserMenuBarTxt {
  font-size: 0.94em;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-height: 26px;
  min-height: 26px;
  margin-top: 0px;
  color: #143051;
  opacity: 0.8;
  font-weight: 400;
  cursor: pointer;
}

.absenceUserMenuBarTxt:active {
  -webkit-transform: scale(0.97);
          transform: scale(0.97);
}
.absenceMenuTxt {
  font-size: 14px;
  max-height: 27px;
  cursor: pointer;
  color: #143051;
  font-weight: 400;
  margin-top: 2px;
}

.absenceMenuTxt:hover {
  font-weight: 500;
  color: #4f79ab;
}
.absenceMenuTxtSelected:active {
  -webkit-transform: scale(0.96);
          transform: scale(0.96);
}

.absenceMenuTxt:active {
  -webkit-transform: scale(0.96);
          transform: scale(0.96);
}

.absenceStatsBtn {
  min-width: 40px;
  cursor: pointer;
}

.addAbsenceBtn {
  position: fixed;
  top: calc(100% - 188px);
  right: 45px;
  background-color: #0077ff;
  background-color: #9374d6;
  border: 1px solid #4b1caf;

  color: #fff;
  border-radius: 100%;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  line-height: 42px;
  font-size: 2.5em;
  z-index: 1000;
}

.addAbsenceBtn:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.addAbsenceModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.addAbsenceModalBoxNonAdmin {
  background-color: #f4f8fe;
  color: #143051;
  border-radius: 5px;

  min-width: 340px;
  max-width: 340px;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  cursor: auto;
  min-height: 350px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.addAbsenceModalHeaderContainer {
  min-height: 55px;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.closeAddAbsenceImgHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  min-height: 40px;
  margin-left: 10px;
}

.addAbsenceModalHeaderTitle {
  font-size: 1.2em;
  padding-top: 3px;
  font-weight: 500;
}

.addAbsencePageKeyContainer {
  min-height: 10px;

  display: flex;
  justify-content: center;
}

.addAbsencePageKeyHolder {
  min-height: 10px;
  min-width: 34px;
  max-width: 34px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.addAbsencePageKey {
  min-height: 5px;
  min-width: 5px;
  max-width: 5px;
  max-height: 5px;
  background-color: #859dbb;
  border-radius: 100%;
}

.addAbsencePageKeySelected {
  min-height: 7px;
  min-width: 7px;
  max-width: 7px;
  max-height: 7px;
  background-color: #143051;
  border-radius: 100%;
}

.addAbsenceMidContainer {
  background-color: white;

  max-width: 80%;
  min-width: 80%;
  margin: 0 auto;

  margin-top: 10px;
  text-align: center;
  border-top: solid 2px #e6eaee;

  border-radius: 5px 5px 0 0;
  overflow: auto;
  max-height: 260px;
  min-height: 260px;
}

.addAbsenceUserDropdown {
  color: #fff;
  border-radius: 10px;
  font-size: 0.9em;
  padding-left: 12px;
  text-align: left;
  max-width: 200px;
  min-width: 200px;
  line-height: 33px;
  height: 35px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
    #7495ba;

  border: 1px solid #143051;
  -webkit-appearance: none;
  background-position-x: 178px;
  font-weight: 500;
  padding-top: 0px;
  margin: 10px auto;
}

.addAbsenceRowSplitter {
  min-height: 2px;
  background-color: #fff;
  margin-top: 20px;
}

.absenceTyperopdown {
  color: #fff;
  border-radius: 25px;
  font-size: 0.9em;
  padding-left: 12px;
  text-align: left;
  max-width: 200px;
  min-width: 200px;
  line-height: 33px;
  height: 35px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
    #7495ba;
  border: 1px solid #143051;
  -webkit-appearance: none;
  background-position-x: 178px;
  font-weight: 500;
  padding-top: 0px;
  margin: 10px auto;
}

.disableAbsenceElement {
  opacity: 0.4;
}

.addAbsenceRowTitleTxt {
  font-weight: 500;
  color: #496375;
  margin-top: 18px;
  text-align: left;
  margin-left: 10%;
}

.paidIndicatorTxt {
  font-weight: 400;
  color: #859dbb;
  font-size: 0.95em;
  text-align: left;
  margin-left: 14%;
  margin-top: 10px;
  font-size: 0.9em;
  padding-left: 1px;
}

.paidIndicatorContainer {
  min-height: 20px;
}

.addAbsenceModalFooter {
  border-top: 2px #e6eaee solid;
  max-width: 80%;
  margin: 0 auto;

  min-height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addAbsenceFooterUnitSide {
  min-width: 70px;
}

.addAbsenceFooterUnitMiddle {
  min-width: 100px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addAbsenceBackBtn {
  color: #859dbb;
  font-weight: 500;
  cursor: pointer;
  text-align: left;
  padding-left: 4px;
}

.addAbsenceClostBtn {
  color: #859dbb;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  text-align: right;
  padding-right: 4px;
}

.addAbsenceModalNextBtn {
  min-height: 35px;
  max-height: 35px;
  min-width: 80px;
  max-width: 80px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  background-color: #0bf;
  border: 2px solid #0af;
  padding-left: 14px;
  padding-right: 14px;
  cursor: pointer;
}

.addAbsenceShiftsContainer {
  min-width: 90%;
  max-width: 90%;
  margin: 10px auto;

  max-height: 160px;
  border-top: 2px #e0ebf8 solid;
  border-bottom: 2px #e0ebf8 solid;

  overflow: auto;
  padding: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  align-content: flex-start;

  flex-wrap: wrap;
}

.addShiftBtnImg {
  min-width: 40px;
  margin-top: 30px;
}

.addShiftsBtnWhenEmpty {
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  padding-left: 12px;
  text-align: left;
  max-width: 113px;
  min-width: 113px;
  height: 35px;
  -webkit-appearance: none;
  min-height: 35px;
  max-height: 35px;
  margin-top: 10px;
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: 500;
  border: solid 2px #bbcada;
  background-color: #cbd7e6;
  cursor: pointer;
}

.addShiftToAbsenceBtnTxt {
  margin-left: -6px;
  font-size: 1em;
}
.addShiftToAbsencePlus {
  font-size: 22px;
  margin-right: 8px;
  margin-top: -2px;
}

.shiftSelectForAbsenceModalUnderlay {
  background: rgba(0, 0, 0, 0.25);

  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.shiftSelectForAbsenceModalBox {
  background-color: #fff;
  min-height: 500px;
  max-width: 340px;
  min-width: 340px;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
}

.shiftSelectorBox {
  background-color: #f4f8fe;
  min-height: 400px;
  max-height: 70vh;
  overflow: auto;
  padding-top: 10px;
  padding-bottom: 30px;
  border-radius: 5px 5px 0 0;
  border-top: 2px solid #e6eaee;
}

.shiftSelectorFooter {
  display: flex;
  max-width: 100%;
  margin: 0 auto;
  min-height: 60px;
  align-items: center;
  justify-content: space-around;
  border-top: 2px solid #e6eaee;
}

.removeSelectingShiftsBtn {
  color: #859dbb;
  font-size: 16px;
  font-weight: 500;
}

.closeSelectingShiftsBtn {
  font-size: 16px;
  color: #859dbb;
  font-weight: 500;
}

.doneSelectingShiftsBtn {
  background-color: #00ccff;
  border: 1px solid #143051;
  border-radius: 25px;
  color: #fff;
  font-weight: 500;
  min-height: 30px;
  line-height: 30px;
  padding-left: 12px;
  padding-right: 12px;
}

.saveSelectingShiftsBtn {
  background-color: #0bf;
  border: 2px solid #0af;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  min-height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 14px;
  padding-right: 14px;
}

.disableSaveBtn {
  opacity: 0.4;
  pointer-events: none;
}

.addShiftToAbsenceYearSelectContainer {
  min-height: 60px;

  display: flex;
  align-items: center;
  justify-content: space-around;
}

.lastYearSelectorHolder {
  display: flex;
  font-weight: 500;
  min-width: 130px;
  color: #214978;
  justify-content: space-around;
  margin-top: 8px;
  margin-left: 18px;

  min-height: 35px;
  line-height: 34px;

  padding-left: 10px;
  border-radius: 5px;
  margin-bottom: 4px;
  margin-top: 4px;

  border: 1px solid #e6eaee;
  background-color: white;
}

.lastYearSelectShiftsTxt {
}

.activeLastYearToggleShiftSelect {
  background-color: #859dbb;
  color: white;
  border: 1px solid #496375;
  cursor: pointer;
  background-color: #0bf;
  border: 2px solid #0af;
}

.knobAddShiftsToAbsenceToggleLastYear {
  margin-top: 2px !important;
  margin-left: 4px;
}

.addShiftsModalTitleTxt {
  font-weight: 500;
  color: #143051;
  font-size: 1.2em;
  margin-top: 5px;
  margin-left: 6px;
}

.shiftToAddUnit {
  min-height: 50px;
  max-height: 50px;
  max-width: 90%;
  margin: 6px auto;

  border-radius: 5px;
  background-color: white;
  border-bottom: 2px solid #e6eaee;
  display: flex;
  background-color: #fff;
}

.selectedShiftToAddToAbsenceBg {
  background-color: #0bf;
  border: 2px solid #0af;
}

.mobAbsenceTitle {
  margin-top: 52px;
}

.absenceMenuBarMob {
  margin-top: 1px;
}

.absenceTeamSelectBarMob {
  margin-top: -24px;
}

.teamAbsenceTitleContainerMob {
  margin-top: 49px;
}
.shiftToAddUnitLeft {
  min-width: 120px;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  padding-left: 15px;
  font-weight: 500;
}
.shiftToSelectDateStripTxt {
  color: #496375;
  font-size: 0.9em;
  min-width: 150px;
}
.shiftToSelectTimesTxt {
  color: #859dbb;
  font-size: 0.8em;
  font-weight: 500;
}

.shiftToAddUnitMiddle {
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whiteTxtForAddShiftToAbsence {
  color: white;
}

.absentIndicatorAddShiftToAbsence {
  background-color: #e3e8ee;

  border-radius: 5px;
  color: #496375;
  font-size: 0.65em;
  font-weight: 500;

  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shiftToAddUnitRight {
  min-width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectedShiftRadioBtnImg {
  max-width: 25px;
}

.shiftAddedUnit {
  display: flex;
  padding-left: 7px;
  padding-right: 7px;
  margin-left: 3px;
  margin-right: 3px;
  background-color: #e3e8ee;
  font-size: 0.9em;
  font-weight: 500;
  color: #496375;
  justify-content: space-around;
  margin-top: 6px;
  border-radius: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  line-height: 22px;
  max-height: 30px;
}
.crossToRemoveShiftFromAddToAbsence {
  max-width: 8px;
  margin-left: 5px;
}

.addMoreShiftsToAbsenceBtn {
  color: #fff;
  border-radius: 5px;
  font-size: 16px;

  text-align: left;
  max-width: 96px;
  min-width: 96px;
  height: 35px;
  -webkit-appearance: none;
  min-height: 35px;
  max-height: 35px;
  margin-top: 10px;
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: 500;
  border: solid 2px #bbcada;
  background-color: #cbd7e6;
  cursor: pointer;
  margin-left: 10%;
}

.absenceDurationTopRightContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: -30px;
  font-weight: 500;
  margin-right: 20px;

  color: #6a98d1;
}

.zeroOpacityAbsenceDuration {
  opacity: 0;
}

.rtwRequiredBtnsContainer {
  display: flex;
  min-height: 50px;
  max-width: 220px;
  margin: 12px auto;
}

.rtwRequiredActive {
  color: #fff;
  border-radius: 5px;
  height: 35px;
  border: 1px solid #0af;
  background-color: #0bf;
  -webkit-appearance: none;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  max-height: 30px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.rtwRequiredInactive {
  color: #b7bec7;
  border-radius: 5px;
  height: 35px;
  border: 1px solid #b7bec7;
  -webkit-appearance: none;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  max-height: 30px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}
.rtwRequiredActive:last-child {
  margin-left: 8px;
}

.rtwRequiredInactive:last-child {
  margin-left: 8px;
}
.addAbsenceNoteBox {
  resize: none;
  padding: 5px;
  border: 1px solid #dde9f9;
  border-radius: 5px;
  background-color: white;
  font-size: 16px;
  overflow: auto;
  line-height: 20px;
  font-weight: 400;
  color: #496375;
  min-width: 80%;
  min-height: 100px;
  margin-top: 10px;
  margin-bottom: -20px;
}

.absenceUnit {
  background-color: white;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  padding-right: 5px;
  justify-content: space-between;
  align-items: center;
  min-height: 72px;
  max-height: 72px;
  margin-top: 8px;

  border: 1px solid #e7ebef;

  border-radius: 5px;
  cursor: pointer;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
}

.absenceUnit:active {
  -webkit-transform: scale(0.96);
          transform: scale(0.96);
}

.absenceUnitBlueDot {
  min-width: 9px;
  min-height: 9px;
  max-width: 9px;
  max-height: 9px;
  background-color: #00ccff;
  border-radius: 20px;
  margin: 0 auto;
  margin-top: 9px;
  margin-right: 9px;
}

.absenceUnitPurpleDot {
  min-width: 9px;
  min-height: 9px;
  max-width: 9px;
  max-height: 9px;
  background-color: #9374d6;
  border-radius: 20px;
  margin: 0 auto;
  margin-top: 9px;
  margin-right: 9px;
}

.absenceUnit1 {
  min-width: 60%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 25px;
}

.absenceUnitTypeName {
  color: #143051;
  font-size: 1.05em;
  font-weight: 500;
}

.absenceDatePeriodString {
  color: #859dbb;
  font-weight: 500;
  font-size: 0.9em;
}

.absenceUnit2 {
  min-width: calc(40% - 21px);
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 20px;
}

.absenceShiftCount {
  color: #143051;
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 3px;
}
.absenceUnitDurationString {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  /* padding-left: 8px;
  padding-right: 8px; */
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;
  font-weight: 500;
  font-size: 0.8em;
  padding-top: 0px;
  padding-bottom: 0px;
  color: #859dbb;
  /* margin-right: -4px; */
  margin-top: -5px;
}
.absenceUnit3 {
  min-width: 21px;
  max-width: 21px;
  margin-left: -12px;
  margin-top: -5px;
}
.absenceUserHorizLoader {
  margin-top: 20px;
}

.absenceUnitModalUnderlay {
  background: rgba(0, 0, 0, 0.25);

  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.absenceUnitModalBox {
  background-color: #f4f8fe;
  color: #143051;
  border-radius: 5px;

  min-width: 340px;
  max-width: 340px;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  cursor: auto;
  min-height: 350px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.mgrAbsenceUnitModalBoxBorder {
}

.absenceModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 60px;
}

.absenceModalHeaderUnit1 {
  min-width: 60px;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.absenceModalHeaderUnit3 {
  min-width: 60px;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.absenceModalHeaderUnit2 {
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.absenceHeaderTitleTxt {
  font-size: 1.2em;
  font-weight: 500;
}

.absenceModalBody {
  min-height: 100px;
  max-width: 90%;

  display: flex;
  margin: 0 auto;
  justify-content: space-between;
}

.absenceModalBodyLeft {
  background-color: white;
  text-align: left;
  max-width: 74%;
  min-width: 74%;

  padding-right: 3px;

  padding-bottom: 5px;
  min-height: 303px !important;
  max-height: 303px;
  overflow: auto;
  border-top: solid 2px #e6eaee;
  border-right: solid 1px #e6eaee;
  border-left: solid 1px #e6eaee;

  border-radius: 6px 6px 0px 0px;
  -webkit-font-smoothing: antialiased;
}

.absenceModalLeftRow {
  max-width: 86%;
  min-width: 86;
  margin: 0 auto;
  padding-top: 16px;
  padding-bottom: 16px;
}

.maxHeight50 {
  max-height: 50px;
}
.absenceModalLeftRowTitle {
  text-align: left;
  margin-left: 5%;

  font-weight: 500;
  font-size: 12px;
  margin-bottom: 6px;
  color: #859dbb;
  text-transform: uppercase;
}

.absenceModalLeftRowValue {
  text-align: left;
  margin-left: 5%;
  font-weight: 500;
  color: #496375;
  font-size: 1.05em;
  margin-top: 3px;
}

.cursorPointer {
  cursor: pointer !important;
}

.durationSmallerTxtAbsenceSpan {
  font-size: 0.85em;
}

.absenceNoteValue {
  max-height: 120px;
  overflow: auto;
  padding: 10px;
  padding-top: 4px;

  border-radius: 5px;
  margin-left: 0px;

  margin-top: 0px;
}

.noteAbsenceBorder {
  border-top: 1px solid #eef2f7;
  border-bottom: 1px solid #eef2f7;
}
.absenceModelLeftRowSplitter {
  min-height: 1px;
  max-height: 1px;
  min-width: 86%;
  max-width: 86%;
  margin: 0 auto;
  background-color: #bac5d3;
}

.absenceModalBodyRight {
  min-width: 25%;
  max-width: 25%;

  min-height: 290px;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  margin-bottom: -10px;
}

.absenceModalFooter {
  min-height: 50px;
  max-height: 50px;

  max-width: 90%;
  margin: 0px auto;
  border-top: #bac5d3 solid 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.footerLeftContainerAbsenceModal {
  display: flex;
  justify-content: center;

  align-items: center;
  min-height: 50px;
}

.cancelAbsenceBtnHolder {
  display: flex;
  min-height: 28px;
  align-items: center;
  justify-content: center;
  min-height: 30px;
  max-height: 30px;
}

.inactiveCancelHolder {
  background-color: none;
  max-width: 26px;
}

.cancelAbsenceBtnTxt {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 72px;
  padding-left: 9px;
  padding-right: 9px;
  min-height: 30px;
  max-height: 30px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
}

.closeAbsenceModalTxt {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 30px;
}

.closeAbsenceModalTxt:hover {
  background-color: #214978;
}

.rtwBtn {
  background-color: #3b597c;
  border: #214978 1px solid;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  min-width: 60px;
  max-width: 60px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 1px;
  padding-bottom: 1px;
  min-height: 30px;
  max-height: 30px;
  border-radius: 5px;
  line-height: 16px;
  font-weight: 500;
  font-size: 0.9em;
  margin: 0 auto;
}

.rtwBtn:hover {
  background-color: #214978;
}

.rtwBtnWider {
  min-width: 60px;
  max-width: 60px;
  text-align: left;
  font-size: 0.9em;
  background-color: #0bf;
  border: solid 2px #0af;
  color: white;
}

.rtwBtnWider:hover {
  background-color: #0af;
}

.page2RTWBtnBg {
  color: white;
}

.rtwTxtWider {
  margin-left: 4px;
  line-height: 15px;
  color: #fff;
}

.nonActiveRtwTxtWidth {
  min-width: 70px;
  margin-right: 5px;
  color: white;
}

.rtwBtnTxt {
  min-width: 80px;
  padding-right: 5px;
}

.rtwBtnTxtNoPad {
  min-width: 80px;
  padding-right: 0px;
}

.color140351 {
  padding-top: 1px;
  font-size: 0.9em;
}

.noPadding {
  padding: 0px;
}

.rtwDot {
  min-width: 6px;
  min-height: 6px;
  max-width: 6px;
  max-height: 6px;
  background-color: white;
  border-radius: 10px;
}

.rtwCross {
  min-width: 8px;
  margin-right: 0px;
}

.closeWhiteRtwPageImg {
  min-width: 8px;
  max-width: 8px;
}

.rtwDataContainer {
  overflow: auto;
}

.rtwAbsenceModalRow {
  max-width: 86%;
  min-width: 86;
  margin: 0 auto;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #bac5d3;
}

.rtwAbsenceModalRow:last-child {
  border: none;
}

.rtwAbsenceModalQuestion {
  font-weight: 500;
  font-size: 1em;

  text-align: left;
  margin-left: 5%;
  color: #859dbb;
  padding-right: 8px;
  margin-top: 8px;
}

.rtwAbsenceModalAnswer {
  text-align: left;
  margin-left: 5%;
  font-weight: 400;
  color: #496375;
  font-size: 1em;
  margin-top: 10px;
}

.rtwQNum {
  padding-left: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 500;
  font-size: 12px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border-radius: 5px;
  margin-left: 2.5%;
  margin-bottom: -4px;

  color: #74aff5;
}

.absenceTimelineContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 25px;
  padding-top: 25px;
}

.absTimelineIcon {
  min-width: 60px;

  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: inset -2px 0 0 #1c3f68, inset 0 -2px 0 #1c3f68,
    inset 2px 0 0 #1c3f68, inset 0 2px 0 #1c3f68;
  margin-top: 6px;
  background-color: #ffeeee;
  cursor: pointer;
}

.absTimelineDateNumber {
  font-size: 1.45em;
  padding-top: 3px;
  letter-spacing: 1px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.absIconBlob {
  background-color: white;
  border: 2px solid #3b597c;
  color: #496375;
  font-size: 14px;
  padding-top: 1px;
  padding-bottom: 2px;
  font-weight: 500;
  min-width: 100%;
  border-radius: 5px;
}

.absMiddleTimelineBar {
  min-height: 72px;
  min-width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6px;
  cursor: pointer;
}

.absTimelineBarCircle {
  background-color: #8997a8;
  min-width: 5px;
  max-width: 5px;
  min-height: 5px;
  max-height: 5px;
  border-radius: 10px;
}

.absTimelineBarLine {
  min-height: 61px;
  min-width: 1px;
  margin-top: -1px;
  background-color: #8997ab;
}

.shortenAbsBarline {
  min-height: 20px;
  max-height: 20px;

  min-width: 1px;
  margin-top: -1px;
  background-color: #8997ab;
}

.absTimelineDur {
  background-color: #e89292;
  border: 1px solid #cd7171;
  position: fixed;
  margin-top: 22px;
  border-radius: 5px;
  font-size: 0.8em;
  font-weight: 500;
  color: white;
  padding-top: 3px;
  padding-bottom: 4px;
  min-width: 60px;
  max-width: 80px;
}

.pullAbsTimelineDurUp {
  margin-top: 14px;
}

.absenceShiftPageHeaderContainer {
  display: flex;

  max-width: 82px;
  min-width: 82px;
  justify-content: center;
  max-height: 30px;
  min-height: 30px;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 5px;
  color: #143051;
  padding-left: 3px;
  margin-left: 10px;
  margin-top: 10px;
  border: 1px #e6eaee solid;
  cursor: pointer;
}

.absenceModalShiftsTitleTxt {
  font-weight: 500;
  line-height: 28px;
  padding-right: 3px;
  margin-left: 3px;
  font-size: 1em;
}

.goBackAbsenceModalFromShiftPageImg {
  margin-left: -3px;
  margin-right: 2px;
  border-radius: 5px;
  cursor: pointer;
}

.absenceModalShiftsContainer {
  margin-left: 5%;
  overflow: auto;
  max-height: 240px;
  border-top: 2px solid #eef2f7;
  border-bottom: 2px solid #eef2f7;
  border-radius: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.absShiftGenerateUnit {
  border-bottom: 1px solid #bac5d3;
  background-color: white;
  max-width: 94%;

  margin-bottom: 8px;
  padding-top: 6px;
  padding-bottom: 10px;
  cursor: pointer;
}

.absShiftGenerateUnit:hover {
  background-color: #ebf0f7;
}

.absShiftGenerateUnit:last-child {
  border: none;
}

.absShiftUnitTopTxt {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  font-size: 1em;
  color: #496375;
  font-weight: 500;
}

.absShiftUnitTimeContainer {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 6px;
  padding-top: 3px;
  font-size: 1em;
  font-weight: 500;
  color: #859dbb;
}
.absShiftUnitTimeStart {
  font-weight: 500;
  font-size: 0.78em;
  margin-top: -5px;
}

.absShiftUnitDuration {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;
  color: #496375;
  font-weight: 500;
  font-size: 0.8em;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: #e3e8ee;
  color: #4f566b;
}

.rtwContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: calc(100vh - 0px);
  background-color: #f4f8fe;
  padding-bottom: 30px;
}
.rtwFormUpperContainer {
  min-height: 80px;
  min-width: 100%;
  max-width: 100%;
  margin: 0 auto;

  font-size: 1.6em;
  color: #0077cc;
  font-weight: 500;
  justify-content: space-between;
  padding-top: 26px;
  text-align: center;
}
.rtwFormMidContainer {
  margin-top: 20px;
}

.closeRTWformImg {
}
.topAbsenceFormSideUnit {
  min-width: 30px;
  min-height: 30px;
  max-height: 30px;

  margin-left: 28px;
  margin-right: 28px;
}

.rtwPageKeyContainer {
  display: flex;
  min-width: 100%;

  justify-content: center;
}
.rtwKey {
  min-height: 8px;
  max-height: 8px;
  min-width: 8px;
  max-width: 8px;
  border-radius: 20px;
  background-color: #acc3e1;
  margin-left: 4px;
  margin-right: 4px;
}

.rtwKeySelected {
  background-color: #143051;
}

.rtwKeyReady {
  background-color: #0077ff;
}

.rtwBtnContainer {
  display: flex;
  justify-content: space-around;
  max-width: 320px;
  margin: 0 auto;

  max-height: 40px;
  align-items: center;
  margin-bottom: 40px;
}

.rtwQNextBtn {
  font-size: 0.9em;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 30px;
  margin-left: 25px;
}

.rtwQNextBtnSubmit {
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: 80px;
  font-size: 1em;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #0bf;
  border: 2px solid #0af;
}
.rtwBackBtn {
  min-height: 30px;
  max-height: 30px;
  min-width: 70px;
  max-width: 70px;
  text-align: center;
  font-weight: 400;
  color: #7495ba;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.rtwBtnEmpty {
  min-width: 70px;
  max-width: 70px;
}

.rtwQuestionContainer {
  min-height: 330px;
  overflow: auto;
  max-height: 330px;
  max-width: 500px;
  margin: 0 auto;
}

.rtwQuestionNumber {
  background-color: #143051;
  color: white;
  font-size: 1em;
  font-weight: 500;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 25px;
  line-height: 25px;
  padding-top: 1px;
  border-radius: 5px;
  margin: 0 auto;
  margin-bottom: 24px;
}

.rtwQuestion {
  font-size: 1.2em;
  font-weight: 400;
  color: #143051;
  max-width: 90%;

  text-align: center;
  margin: 0 auto;
  margin-bottom: 24px;
}

.rtwTextarea {
  border: 1px solid #e6eaee;
  border-radius: 5px;
  min-width: 74%;
  min-height: 142px;
  padding: 10px;
  font-size: 1em;
  color: #214978;
  resize: none;
}

.rtwTextarea:focus {
  border: 1px solid #143051;
  border-radius: 5px;
  min-width: 74%;
  min-height: 142px;
  resize: none;
}

.rtwReviewContainer {
  min-height: 330px;
  overflow: auto;
  max-height: 330px;
  max-width: 500px;
  margin: 0 auto;
  background-color: white;
  border-top: 4px solid #eef2f7;
  border-bottom: 4px solid #eef2f7;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: auto;
  margin-bottom: 10px;
}

.rtwReviewQ {
  font-size: 1.1em;
  font-weight: 500;
  text-align: left;
  color: #143051;
  margin-top: 10px;
}

.rtwReviewA {
  color: #859dbb;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 10px;
}

.rtwReviewUnit {
  border-bottom: 1px solid #e6eaee;
  padding-bottom: 10px;
  padding-top: 8px;
}

.rtwReviewUnit:last-child {
  border: none;
}

.absenceFormAbsenceTxt {
  max-height: 35px;

  max-width: -webkit-fit-content;

  max-width: -moz-fit-content;

  max-width: fit-content;
  margin: 0 auto;
  font-size: 0.6em;
  color: #143051;
  min-height: 35px;
  line-height: 32px;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 20px;

  margin-top: 10px;
}

.disableRTWContainer {
  opacity: 0.4;
  pointer-events: none;
}

.rtwFormAbsDropdown {
  color: #fff;
  border-radius: 25px;
  font-size: 0.6em;
  padding-left: 12px;
  text-align: left;
  max-width: 2140px;
  min-width: 140px;
  line-height: 33px;
  height: 35px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
    #7495ba;

  border: 1px solid #143051;
  -webkit-appearance: none;
  background-position-x: 119px;
  font-weight: 500;
  padding-top: 0px;
  margin: 10px auto;
}

.absenceDurationStringInlineMgr {
  display: flex;
}

.mgrModalAbsenceName {
  font-size: 0.95em;
  margin-top: 8px;
  color: #496375;
  border: 1px solid #e6eaee;
  border-radius: 5px;
  min-height: 30px;
  max-height: 30px;
  padding-left: 10px;
  font-weight: 500;
  padding-right: 10px;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0px auto;
  margin-bottom: 16px;
}

.editAbsTypeImgPencil {
  max-width: 11px;
  margin-left: 14px;
  margin-top: 0px;
  cursor: pointer;
}

.editAbsTypeDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 163px;
  min-width: 163px;
  line-height: 26px;
  height: 30px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  background-position-x: 92px;
  display: flex;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 140px;
  margin: 0 auto;
  margin-bottom: 16px;
}

.editShiftTeamDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 193px;
  min-width: 193px;
  line-height: 26px;
  height: 35px;
  /* border: 2px solid #e6eaee; */
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 2px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 170px;
}

.cancelAbsenceModalUnderlay {
  background: rgba(0, 0, 0, 0.25);

  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.cancelAbsenceModalBox {
  background-color: #f4f8fe;
  color: #143051;
  border-radius: 5px;
  min-height: 35px;
  max-width: 290px;
  min-width: 290px;
  box-shadow: 0px 0px 3px 3px rgb(0 0 0 / 10%);
  padding: 15px;
  cursor: default;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.removeAbsenceModalHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 7px;
  padding-right: 7px;
  padding-bottom: 10px;
  margin-top: 3px;
  min-width: 100%;
}

.removeAbsenceModalHeaderSideUnit {
  min-width: 15px;
  max-width: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -2px;
}

.removeAbsenceBtnContainer {
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
  padding-bottom: 30px;
}

.markShiftsAbsent {
  max-width: 208px;

  border-radius: 5px;
  font-weight: 500;
  color: #fff;
  border: 1px solid #143051;
  background-color: #0af;
  padding-top: 4px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 1em;
  display: flex;
  justify-content: space-around;
  background-color: #0bf;

  font-size: 14px;
  padding-bottom: 1px;
  cursor: pointer;
  border: 3px solid #0af;
}

.markShiftsAbsentUnsel {
  background-color: rgb(0, 0, 0, 0);

  color: #859dbb;
  border: 1px solid #e0ebf8;
  margin-top: 2px;
  margin-bottom: 2px;
}

.keepShiftsAbsentTxt {
  max-width: 140px;
  text-align: left;
  padding-top: 8px;
  padding-left: 10px;
  padding-bottom: 8px;
}

.unmarkShiftsKnob {
  margin-top: 12px;
}

.removeAbsenceModalFooter {
  display: flex;
  justify-content: space-between;

  font-size: 0.9em;
  min-height: 50px;
  align-items: center;
  max-width: 80%;
  margin: 0 auto;
}

.removeAbsenceCancel {
  min-height: 30px;

  color: #859dbb;
  font-weight: 500;
  line-height: 30px;

  border-radius: 25px;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
  font-size: 16px;
}

.removeAbsenceSubmit {
  min-height: 35px;
  background-color: #0bf;
  border: 2px solid #0af;
  color: white;
  font-weight: 500;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 14px;
  padding-right: 14px;
  cursor: pointer;
  font-size: 16px;
}

.removeAbsenceLoaderImg {
  max-width: 40px;
}

.opac5DisableUnmarkBox {
  opacity: 0.5;
  pointer-events: none;
}

.submitRemoveAbsenceAndLoaderImgContainer {
  min-width: 83px;
  max-width: 83px;
}

.noUserAbsencesContainer {
  display: flex;
  max-width: 160px;
  font-size: 0.9em;
  font-weight: 500;
  color: #acc3e1;
  margin: 30px auto;
  justify-content: space-around;
  align-items: center;
}

.noAbsencesSideBar {
  min-width: 20px;
  min-height: 2px;
  border-radius: 100px;
  background-color: #acc3e1;
}

.darkBlueBgSave {
  cursor: pointer;
  background-color: #07f;
  border: #0469de 2px solid;
}

.formNotRequiredDiv {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #95aaba;
  min-width: 60px;
  max-width: 60px;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 1px;
  padding-bottom: 1px;
  min-height: 36px;
  max-height: 36px;
  border-radius: 5px;
  line-height: 12px;
  font-weight: 500;
  font-size: 0.7em;
  margin: 0 auto;
  opacity: 0.7;
  cursor: pointer;
}

.formNotFilledInDiv {
  background-color: #e3e8ee;

  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #636f7e;
  min-width: 60px;
  max-width: 60px;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 1px;
  padding-bottom: 1px;
  min-height: 36px;
  max-height: 36px;
  border-radius: 5px;
  line-height: 12px;
  font-weight: 500;
  font-size: 0.7em;
  margin: 0 auto;
  cursor: pointer;
}

.formNotFilledInDiv:active {
  -webkit-transform: scale(0.97);
          transform: scale(0.97);
}

.rtwOptionsModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;

  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.rtwOptionsModalBox {
  background-color: #858e9c;
  background-color: #f4f8fe;
  color: #143051;
  border-radius: 5px;
  min-width: 320px;
  max-width: 320px;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  cursor: auto;
  min-height: 210px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.rtwOptionsModalHeaderContainer {
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  align-items: center;
}

.rtwOptionsModalTitle {
  font-size: 1.2em;
  font-weight: 500;
}

.rtwOptionsModalHeaderSideUnit {
  display: flex;
  min-width: 40px;
  margin-left: 20px;
  margin-right: 20px;
}

.rtwOptionsModalBodyContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 140px;
  padding-top: 26px;
}

.closeBtnForRTWOptionsModal {
  cursor: pointer;
}

.rtwOptionsRequiredContainer {
  display: flex;
  background-color: #0bf;
  border: 2px solid #0af;
  margin: 0 auto;
  border-radius: 5px;
  max-width: 150px;
  min-width: 150px;
  justify-content: space-around;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 4px;
  padding-left: 4px;
  color: white;
  margin-bottom: 8px;
  cursor: pointer;
}

.rtwOptionsDisabled {
  background-color: #fff;
  color: #143051;
  border: 1px solid #eef2f7;
}

.requiredRtwTxt {
  text-align: center;
  font-weight: 500;
  line-height: 26px;
  padding-top: 2px;
}

.rtwRequireKnob {
  margin-top: -5px !important;
}
.knobOnRtw {
  background-color: #00ccff;
}

.sendReminderBtn {
  display: flex;

  margin: 0 auto;

  border-radius: 25px;
  max-width: 150px;
  min-width: 150px;

  min-height: 44px;
  justify-content: space-around;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;

  color: white;
  color: #143051;
  font-weight: 400;
  min-height: 70px;
  max-height: 70px;
  cursor: pointer;
}

.sentReminderBtn {
  display: flex;

  margin: 0 auto;

  border-radius: 25px;

  padding-left: 6px;
  padding-right: 6px;
  min-width: 150px;
  line-height: 20px;
  justify-content: space-around;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 35px;
  padding-right: 35px;
  color: #143051;
  font-weight: 400;
  min-height: 70px;
  max-height: 70px;
  font-size: 0.9em;
}

.hideReminderBtn {
  visibility: hidden;
  pointer-events: none;
}

.shiftsOnAbsenceHeader {
  display: flex;
  justify-content: space-between;
}

.addRemoveShiftsOnAbsenceContainer {
  font-size: 0.9em;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 104px;
}

.addRemoveShiftsOnAbsenceTxt {
  color: white;
  max-height: 24px;
  min-height: 24px;

  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 20px;
  cursor: pointer;
  border: solid 1px #0af;
  background-color: #0bf;
}

.addRemoveShiftsOnAbsenceTxt:active {
  -webkit-transform: scale(0.97);
          transform: scale(0.97);
}

.absenceNoteRowHeaderContainer {
  display: flex;

  justify-content: space-between;
}

.editNoteInAbsenceRowImg {
  max-width: 14px;
  min-width: 14px;

  margin-right: 12px;
  margin-top: -2px;
}

.addNoteInAbsenceRowImg {
  max-width: 30px;
  min-width: 30px;

  margin-right: 12px;
  margin-top: -4px;
}

.editAbsenceNoteModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.editAbsNoteModalBox {
  background-color: #f4f8fe;
  color: #143051;
  border-radius: 5px;
  max-width: 320px;
  min-width: 320px;
  box-shadow: 0px 0px 3px 3px rgb(0 0 0 / 10%);
  padding: 15px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.absenceNoteEditInput {
  margin-top: 6px;
  max-width: 86%;
  min-width: 86%;
  background-color: #fff;
  border: none;

  color: #143051;
  font-size: 1em;
  font-weight: 400;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  height: 30px;
  line-height: 23px;
  min-height: 100px;
  max-height: 100px;
  overflow: auto;
  border: 1px solid #e6eaee;
  border-radius: 5px;

  resize: none;
}

.editAbsenceNoteFooterContainer {
  display: flex;
  min-width: 100%;

  justify-content: space-around;
  align-items: center;
  padding-top: 12px;
}

.editAbsenceNoteFooterUnit {
  min-width: 80px;
  max-width: 80px;
}

.saveEditAbsenceNoteBtn {
  min-height: 35px;

  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0077ff;
  color: #fff;
  font-size: 16px;
  background-color: #0bf;
  border: 2px solid #0af;
  padding-left: 14px;
  padding-right: 14px;
  min-width: 80px;
  max-width: 80px;
  font-size: 16px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
}

.disableSaveAbsenceNoteBtn {
  opacity: 0.5;
  pointer-events: none;
}

.closeEditAbsenceNoteBtn {
  color: #859dbb;
  font-weight: 500;
  font-size: 16px;
}

.navyCrossUnitOnAbsenceEditNote {
}

.removeAbsNoteBtn {
  color: #859dbb;
  font-weight: 500;
  font-size: 16px;
}

.userShiftsPage {
  min-height: 100vh;
  background-color: #ebf0f7 !important;
}

.userShiftsPageUpperContainer {
  background-color: white;
  min-height: 117px;
  max-height: 117px;
  min-width: 100%;
  max-width: 100%;
  border-bottom: 2px solid #e6eaee;
}

.teamUpperHoursColours {
  background-color: #403a8e;
  border-bottom: 1px solid #d1dfee;
}

.userShiftsUpperInnerContainer {
  min-width: 100%;
  max-width: 100%;
  min-height: 78px;
  max-height: 78px;
  padding-left: 5%;

  margin: 2px auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.mobUpper {
  align-items: center;
  min-height: 100px;
  max-height: 100px;
  margin-top: 0px;
}
.mobUpperTeamShifts {
  margin-top: 0px;
}
.userShiftsTitle {
  color: #143051;
  font-weight: 500;
  font-size: 23px;

  text-align: left;
  margin-top: 34px;
  min-width: 130px;
}

.myAvailTopRightBtn {
  color: #758aa2;
  font-weight: 500;
  margin-top: 24px;
  font-size: 0.9em;
  text-align: right;

  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  margin-right: 6%;
  border: 1px solid #758aa2;
}

.myAvailTopRightBtn:hover {
  color: white;
  font-weight: 500;
  margin-top: 33px;
  font-size: 1.1em;
  text-align: right;
  background-color: #1e3c5e;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 20px;
  cursor: pointer;
}

.shiftsMenuBar {
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
  color: #c8e8f0;
  font-weight: 500;
  max-width: 90%;
  margin: -6px auto;
}

.shiftsMenuLeft {
  width: 320px;
  min-width: 344px;
  max-width: 344px;
  display: flex;
  justify-content: space-between;
  margin-top: 11px;
}

.shiftTopMenuItem {
  margin-left: 3px;
  font-size: 16px;
  font-weight: 500;
  color: #c2cce9;
}

.shiftTopMenuItem:first-child {
  margin-left: 0px;
}

.timesheetsItem {
}

.shiftsMenuTeam {
  background-color: #a387df;
  border: 1px #8e76c1 solid;

  display: flex;
  color: #fff;
  min-height: 36px;

  padding-left: 9px;
  padding-right: 2px;
  border-radius: 5px;
  text-align: center;
  margin-left: 9px;
  margin-right: 28px;
  min-width: -webkit-fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;

  max-height: 35px;
  min-height: 35px;
  margin-top: 4px;
  font-size: 16px;
  cursor: pointer;
  align-items: center;
}

.myHoursBtn {
  background-color: #1e88e5;
  border: #3677c2 1px solid;
  display: flex;
  color: #fff;
  min-height: 36px;
  padding-left: 9px;
  padding-right: 2px;
  border-radius: 5px;
  text-align: center;
  margin-left: 9px;
  min-width: -webkit-fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  max-height: 35px;
  min-height: 35px;
  font-size: 16px;
  cursor: pointer;
  align-items: center;
  font-weight: 500;
}

.myHoursBtn:hover {
  background-color: #1f78de;
}

.myReqsBtn {
  background-color: #1e88e5;
  border: #3677c2 2px solid;
  display: flex;
  color: #fff;
  min-height: 36px;

  padding-left: 9px;
  padding-right: 2px;
  border-radius: 5px;
  text-align: center;

  min-width: 104px !important;

  max-height: 35px;
  min-height: 35px;

  font-size: 16px;
  cursor: pointer;
  align-items: center;
}

.myReqsBtn:hover {
  background-color: #1f78de;
}

.myHoursBtnMob {
  max-height: 35px;
  min-height: 35px;
  font-size: 14px !important;
  right: 35px;
    position: fixed;
    top: 61px
  /* padding-bottom:3px; */
}

.myReqsBtnMob {
  max-height: 30px;
  min-height: 30px;
  font-size: 14px !important;
}

.staffHoursBtn {
  background-color: #b49ce7;
  border: 1px solid #a47fd0;
  display: flex;
  color: #fff;
  min-height: 36px;

  padding-left: 9px;
  padding-right: 2px;
  border-radius: 5px;
  text-align: center;
  margin-left: 9px;
  margin-right: 28px;
  margin-right: 108px;

  min-width: -webkit-fit-content !important;

  min-width: -moz-fit-content !important;

  min-width: fit-content !important;

  max-height: 35px;
  min-height: 35px;
  margin-top: 4px;
  font-size: 16px;
  cursor: pointer;
  align-items: center;
}

.minWidth164 {
  min-width: 164px !important;
}
.staffHoursBtn:hover {
  background-color: #a58fd4;
}
/* @media only screen and (min-width: 820px) and (max-width: 1140px) { */

@media only screen and (max-width: 1140px) and  (min-width: 1000px) {
  .staffHoursBtn {
margin-right:45px
  }
}

@media only screen and (max-width: 999px) and  (min-width: 521px) {
  .staffHoursBtn {
margin-right:35px
  }
}

@media only screen and (max-width: 520px) {
  .staffHoursBtn {
    position: fixed;
    top: 11px;
    right: -85px;
  }



  .myAllowsWhiteBtnMob {
    border: 1px solid #fcfdff;
    background-color: #fff;

    top: 40px !important;
    right: 105px !important;
  }



  .x010842081 {
    top:25px;
  }
}

.teamHrsBtn {
  color: white;
  font-weight: 500;
  margin-right: 12px;
  font-size: 14px;
  /* margin-top:1px; */
}

.myHoursTxtBtn {
  margin-top: -1px;
  margin-left: 2px;
}

.teamHoursTxtBtn {
  padding-right: 7px;
  margin-top: -1px;
  min-width: -webkit-fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
}

.shiftsMenuTeam:hover {
  background-color: #8e76c1;
}

.statsBtn {
  background-color: #07f;
  border: #0469de 1px solid;
  display: flex;
  color: #fff;
  min-height: 36px;
  line-height: 34px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  align-items: center;

  min-width: 80px;
  max-height: 35px;
  min-height: 35px;
  margin-top: 4px;
  font-size: 16px;
  cursor: pointer;
}

.statsBtn:hover {
  background-color: #0b74ef;
}

.hoursStatsBtn {
  background-color: #fff;
  border: 1px solid #e6eaee;
  display: flex;
  color: #859dbb;
  min-height: 36px;
  line-height: 34px;
  padding-left: 6px;
  padding-right: 2px;
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-width: 58px;
  max-height: 35px;
  min-height: 35px;
  font-size: 16px;
  cursor: pointer;
  position: fixed;
  right: 150px;
  top: 62px;
}

.hoursStatsBtn:hover {
  border-color: #d7dfe9;
  background-color: #fff;
}

.reqsStatsBtn {
  background-color: #fff;
  border: #fff 1px solid;
  display: flex;
  color: #859dbb;
  min-height: 36px;
  line-height: 34px;
  padding-left: 8px;
  padding-right: 4px;
  border-radius: 5px;
  text-align: center;
  justify-content: center;
  align-items: center;

  min-width: 80px;
  max-height: 35px;
  min-height: 35px;
  font-size: 16px;
  cursor: pointer;
  position: fixed;
  right: 114px;
  top: 62px;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 20%);
}

.reqsStatsBtn:hover {
  border-color: #e3e8ee;
}

.staffReqsBtn {
  min-width: 69px !important;
  margin-top:18px;
}

.staffReqsStatsBtn {
  right: 154px !important;
}

@media only screen and (min-width: 820px) {
  .staffReqsBtn {
    margin-top:23px;
  }

  .teamCostInSh:hover {
    opacity: 0.8;
  }

  .markAbsShiftItem:hover {
    border-color:#bbcada
  }
.markAbsPeriodBtn:hover {
  background-color: #97a1ea;
}

}

@media only screen and (max-width: 520px) {
  .hoursStatsBtn {
    right: 138px;
    top: 29px;
  }

  .reqsStatsBtn {
    right: 103px;
    top: 39px;
  }
}

@media only screen and (max-width: 460px) {
  .hoursStatsBtn {
    min-width: 40px;
    padding-left: 7px;
  }

  .reqsStatsBtn {
    min-width: 40px;
    padding-left: 7px;
  }
}

.teamStatsBtnStyle {
  background-color: #fff;
  color: #859dbb;
  top: 62px;
  right: 145px;
  z-index: 999999;
  min-width: 68px;
  padding-left: 8px;
}

.teamStatsBtnStyle:hover {
  border-color: #d7dfe9 !important;
}

.teamStatsBtnStyleMob {
  min-height: 35px;
  max-height: 35px;
  top: 31px !important;
  font-size: 14px !important;
  padding-left: 6px !important;
  padding-right: 0px !important;
  max-width: 67px !important;
  min-width: 67px !important;
  background-color: #9a74eb !important;
  border: 1px solid #965ade !important;
  color: white;
}

.teamStatsReqsBtnStyleMob {
  min-height: 30px;
  max-height: 30px;
  top: 27px !important;

  padding-left: 5px !important;
  padding-right: 0px !important;
  max-width: 78px !important;
  min-width: 78px !important;
  right: 150px !important;
  z-index: 10;
}

.teamStatsBtnStyle2 {
  top: 62px !important;
  right: 136px !important;
  min-height: 35px !important;
  max-height: 35px !important;
  min-width: 120px !important;
}


@media only screen and (max-width: 1000px) {
  .teamStatsBtnStyle {
    right: 141px;
  }
}
@media only screen and (max-width: 819px) {
  .teamStatsBtnStyle {
    right: 142px;
    top: 61px !important
  }
}

@media only screen and (max-width: 548px) {
  .teamStatsBtnStyle {
    top: 29px !important;
    right: 126px;
  }

  .myHoursBtnMob {
    top: 29px;
    right: 24px;
  }

  .teamStatsBtnStyle2 {
    top: 8px;
    right: 131px;
  }

  .reqsHeaderTeamRightContainer {
    top: 11px !important;
  }
}

@media only screen and (max-width: 420px) {
  .statsBtn {
    min-width: 32px;
    max-width: 32px;
  }
}

.teamImg {
  max-width: 14px;
  margin-left: 0px;
  margin-right: 4px;
  padding-right: 1px;
}

.statsImg {
  max-width: 14px;
  min-width: 14px;
  margin-left: 1px;
  margin-right: 5px;
}

.statsBtnTxt {
  font-weight: 500;
  font-size: 14px;
  min-height: 100%;
  padding-left: 0px;
  cursor: pointer;
  margin-right: 2px;
  color: #8ea7c5;
}

.statsBtnTxtTeam {
  /* color: #9a74eb; */
  font-weight: 500;
}
.absenceMenuRight {
  margin-right: -4px;

  display: flex;
}

.teamDropTitle {
  max-width: 80px;
  position: absolute;
  top: 92px;
  right: 202px;
  font-weight: 500;
  z-index: 999;
  font-size: 14px;
  color: #6a8bb1;
}

.fullTeamDropTitle {
  right: 272px;
}
@media only screen and (max-width: 840px) {
  .teamDropTitle {
    display: none;
  }
}
.teamDropdownContainer {
  min-width: 170px;
  min-height: 30px;
  max-width: 170px;

  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 145px;
  font-weight: 500;
  font-size: 14px;
  padding-left: 10px;
}

.teamDropdownContainer:hover {
  border-color: #d7dfe9;
}

.pushTeamDropDown {
  margin-top: 30px;
}

.pushTeamDropLeft {
  margin-right: 0px;
  margin-top: -6px;
}

.fullTeamDrop {
  min-width: 240px;
  background-position-x: 215px;
}
.pullTeamBtnsUp {
  margin-top: -50px;
}

.absenceTeamBtnTxt {
  font-size: 0.9em;

  padding-right: 3px;
}

.purpleBg {
  background-color: #fff;
}

.hardPurpleBg {
}
.addShiftBtnOnTeamShifts {
  position: fixed;
  top: calc(100% - 188px);
  right: 45px;
  /* background-color: #62248c; */
  /* border: 1px solid #62248c; */
  background-color: #a4afff;
  border: 1px solid #8a98fc;
  color: #fff;
  border-radius: 5px;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  line-height: 42px;
  font-size: 2.5em;
  z-index: 99999;
  cursor: pointer;
  /* opacity: 0.8; */
}

.addShiftBtnOnTeamShifts:hover {
  background-color: #8a98fc;
  /* transform: scale(0.97); */
}

.reqMenuTxtSelected {
  font-weight: 500;
  opacity: 1;
  cursor: default;
  color: #143051;
}

.reqMenuTxtSelected:hover {
  color: #143051;
}

.reqMenuTxtSelected:active {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.addShiftBtnOnTeamShifts:active {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.teamShiftsPage {
  background-color: #ebf0f7;
  min-height: calc(100vh - 219px);
  max-height: calc(100vh - 219px);

  max-width: 100%;
  min-width: 100%;

  z-index: 0;
  position: relative;
  padding-top: 8px;
  overflow: auto;
  padding-bottom: 20px;
  border-right: 1px solid #e0ebf8;
  border-left: 1px solid #e0ebf8;
}

.shiftsTeamBar {
  display: flex;

  min-width: calc(100% - 200px);
  max-width: calc(100% - 200px);
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: none;
  padding-right: 35px;
  min-height: 30px;
  padding-bottom: 1px;
  align-items: center;
  color: white;
  padding-left: 8px;
  margin-top: -7px;
  margin-left: 15px;
}

.myRotaTeamBar {
  margin-top: -7px;
}

.shiftsTeamBarMob {
  margin-top: -22px;
}

.shiftsMenuBarMob {
  margin-top: -24px;
}

.fontSizeMobTeamHoursMenu {
}

.shiftsTeamBar::-webkit-scrollbar {
  width: 5px !important;
  height: 4px !important;
}

.fadedPngOverTeams {
  position: fixed;
  top: 96px;
  right: calc(5% - 1px);
  pointer-events: none;
}

.teamShiftsTitleContainer {
  display: flex;
  max-height: 30px;
  font-size: 28px;
  margin-left: -12px;
  align-items: center;
  margin-bottom: 3px;

  min-width: -webkit-fit-content;

  min-width: -moz-fit-content;

  min-width: fit-content;
}

.teamShiftsTitleContainerMob {
  margin-top: 11px;
  margin-left: 5px;
}
.teamAvailAndSwapsBtns {
  display: flex;

  align-items: flex-end;

  justify-content: space-around;

  margin-right: 3%;
}

.rotaPage {
  background-color: #ebf0f7;
  min-width: 100vw;
  min-height: 100vh;
  color: black;

  min-height: calc(100vh - 50px);
  max-height: calc(100vh - 50px);
}

.mobHeightMyRota {
  min-height: calc(100vh - 85px);
  max-height: calc(100vh - 85px);
  border-left: none;
}

.myRotaMainUpper {
  background-color: white;
  min-width: 100%;
  min-height: 125px;
  max-height: 125px;
  border-left: solid 1px white;
  border-bottom: 1px solid #e6eaee;
}

.rotaBody {
  border-left: 1px solid #e6eaee;
  min-height: calc(100vh - 174px);
  max-height: calc(100vh - 174px);
}

.mobRotaBodyHeight {
  min-height: calc(100vh - 210px);
  max-height: calc(100vh - 210px);
  border-left: none;
}

.teamAvailAndSwapsBtnsMob {
  align-items: flex-end;
  flex-direction: column;
}

.teamAvailBtn {
  color: #31537a;

  min-width: -webkit-fit-content;

  min-width: -moz-fit-content;

  min-width: fit-content;
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 1em;
  font-weight: 500;
  border-radius: 30px;
  min-height: 26px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 26px;
}

.swapBtnItem {
  display: flex;
  background-color: #fff;

  color: #31537a;
  max-width: 80px;
  padding-left: 12px;
  padding-right: 12px;
  justify-content: space-around;
  border-radius: 5px;
  margin: 0 auto;

  -webkit-animation-name: zoomModalIn !important;

          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.125s !important;
          animation-duration: 0.125s !important;
  cursor: pointer;
  border: 1px solid #95aaba;
}

.swapBtnItem:hover {
  border-color: #74aff5;
  border-width: 2px;
  background-color: #fafeff;
}

.availBtnItem {
  display: flex;
  background-color: #fff;

  max-width: 117px;

  padding-left: 12px;
  padding-right: 12px;
  justify-content: space-around;
  border-radius: 5px;
  margin: 0 auto;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  cursor: pointer;
  border: 1px solid #95aaba;
}

.availBtnItem:hover {
  border-color: #74aff5;
  border-width: 2px;

  background-color: #fafeff;
}

.teamSwapsBtn {
  color: #1964ba;

  min-width: -webkit-fit-content;

  min-width: -moz-fit-content;

  min-width: fit-content;
  text-align: center;
  padding-top: 4px;
  padding-bottom: 3px;
  font-size: 0.9em;
  font-weight: 500;
  border-radius: 5px;
  min-height: 26px;

  line-height: 25px;
}

.closeSwapsImg {
  margin-left: 6px;
  min-width: 9px;
}

.swapsBtnHolder {
  max-width: 80px;
  min-width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 14px;
}

.availBtnHolder {
  max-width: 110px;
  min-width: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 64px;
  right: 5vw;
}

.mobAvailBtn {
  top: 30px;
  right: 5vw;
}

.availSwapsActiveBg {
  color: #fff;
  border: 1px #58467e solid;
  border: 1px #58467e solid;
  background: rgb(0, 119, 255);
  background: linear-gradient(
    0deg,
    rgba(0, 119, 255, 1) 0%,
    rgba(65, 154, 255, 1) 100%
  );
  -webkit-animation-name: zoomBtnIn !important;
          animation-name: zoomBtnIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.teamShiftsTitle {
  color: #9576cc;
  font-weight: 500;
  font-size: 28px;

  text-align: left;

  position: fixed;
  top: 47px;
  left: 186px;
  display: flex;
}

.teamShiftsTitleMob {
  left: 18px;
  top: 39px;
}

.myRotaTitle {
  color: #143051;
  font-weight: 500;
  font-size: 28px;

  text-align: left;

  position: fixed;
  top: 46px !important;
  left: 181px;
}

.myRotaTitleMob {
  left: 5%;
  top: 38px !important;
}

.x392841 {
  top: 46px !important;
}

.addTilOrShiftOptionsUnderlay {
  background: rgba(0, 0, 0, 0.7);
  min-height: calc(100% - 58px);
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.addShiftBtnOnTeamShiftsOnOverlay {
  position: fixed;
  top: calc(100% - 188px);
  right: 45px;
  /* background-color: #0077ff; */
  background-color: #9374d6;
  border: 1px solid #6e4eb2;

  color: #fff;
  border-radius: 5px;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  line-height: 44px;
  font-size: 2.5em;
  z-index: 1000;

  overflow: hidden;
  transition-duration: 0.05s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  cursor: pointer;
}

.diagPlus {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  overflow: hidden;
  transition-duration: 0.5s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  margin-top: -1px;
  margin-left: 5px;
}

.addTilShiftBtnsContainer {
  display: flex;
  flex-direction: column;
  min-height: 205px;
  min-width: 200px;
  position: fixed;
  top: calc(100% - 348px);
  right: 110px;
  justify-content: space-between;
  align-items: flex-end;
}

.addTilBtn {
  background-color: white;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 17px;
  padding-right: 17px;

  font-size: 1.1em;
  font-weight: 500;
  color: #214978;
  margin-bottom: 0px;
  /* border: 1px solid #bbcada; */
  min-height: 40px;
  max-height: 40px;
  line-height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.addTilBtn:hover {
  background-color: #f4f8fe;
}

.addTilModalBox {
  background-color: #f4f8fe;
  color: #496375;
  min-width: 340px;
  max-width: 340px;
  border-radius: 5px;
  text-align: left;
  line-height: 45px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  cursor: default;
}

.selectTeamOnAddTilDropdown {
  max-width: 100px;
  background: url(/static/media/downArrowWhite.97cd93aa.svg) no-repeat right #687d97;
  -webkit-appearance: none;
  background-position-x: 75px;
  border-radius: 100px;
  color: white;
  padding-left: 14px;
  font-size: 1em;
  text-align: center;
  height: 35px;
  border: 1px solid #36495f;
  font-weight: 500;
  padding-top: 2px;
}

.tilDurationTxt {
  font-size: 1.5em;
  color: #abbed3;
  margin-top: -8px;
}

.thisDaysShiftTitleTxt {
  font-size: 1.07em;
}

.suggestedTimesOnAddTil {
  font-size: 1em;
  font-weight: 400;
  margin-top: -14px;
}

.startInputHolder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 80px;
  margin-top: 10px;
}

.tilSuggestTxt {
  color: #07f;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: 500;
}

.tilSuggestDiv {
  max-height: 24px;
  line-height: 24px;
  font-size: 0.8em;
  margin-top: 6px;
  margin-bottom: -12px;
  margin-left: -4px;
  border: 1px solid #eef2f7;
  background-color: #f4f8fe;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.tilSuggestTxtSpan {
  color: #214978;
  padding-left: 12px;
}

.addShiftTimeLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 90px;
  max-height: 90px;
}

.addShiftTimeRight {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 80px;
  max-height: 80px;
  margin-top: 10px;
  margin-left: 20px;
}

.breakMinsSpanAddOvertime {
  font-size: 0.8em;
}

.showOvertimeRightHeight {
  min-height: 192px;
  max-height: 192px;
}

.addTilDateInput {
  border-radius: 5px;

  border: none;
  background-color: #fff;
  color: #496375;
  min-height: 35px;
  line-height: 35px;
  font-size: 0.9em;
  padding-left: 14px;
  padding-right: 8px;
  letter-spacing: 1px;
  padding-top: 1px;

  font-weight: 500;
  max-width: 174px;
  min-width: 174px;
  background: url(/static/media/calicongrey.9c7e1aba.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 142px;
  border: 1px solid #dde9f9;
  margin-left: -5px;
  margin-top: 8px;
}
.overtimeBreakAndRateContainer {
  display: flex;
  min-width: 90%;
  max-width: 80px;
}
.tilDurTopRight {
  padding-top: 1px;
}

.overtimeBrkInput {
  background-color: #fff;
  width: 57px;
  max-width: 77px;

  line-height: 30px;
  padding-top: 0px;
  height: 30px;
  border: 1px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 0.9em;
  font-weight: 500;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  -webkit-appearance: none;
  appearance: none;
  margin-left: -22px;
}

.tilOverlapModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.overlapMsgTxt {
}

.tilOverlapModalBox {
  background-color: #858e9c;
  background-color: #f4f8fe;
  color: #496375;
  border-radius: 5px;
  min-height: 35px;

  min-width: 290px;
  max-width: 290px;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  cursor: auto;
  min-height: 150px;
}

.tilOverlapTimesContainer {
  display: flex;
  max-width: 140px;
  justify-content: space-around;
  margin: 24px auto;
  align-items: center;
  padding-top: 3px;
}

.tilOverlapTimeTxt {
  font-weight: 500;
  color: #143051;
  font-size: 1.2em;
}

.tilOverlapTimeTxtTo {
  font-size: 0.9em;
  color: #859dbb;
}

.tilOverlapMessagesContainer {
  max-width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  line-height: 24px;
  padding-bottom: 18px;
  padding-top: 8px;
}

.overlapTilBtnClose {
  background-color: #2e4657;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 15px;
  padding-right: 15px;
  color: white;
  min-height: 30px;
  line-height: 28px;
  padding-bottom: 1px;
  border: 1px solid #143051;
  border-radius: 5px;
  margin: 18px auto;
  font-weight: 500;
  margin-bottom: 25px;
  cursor: pointer;
}

.loadExistingFade {
  opacity: 0.4;
  pointer-events: none;
}

.understoodBtnTil {
  background-color: #00ccff;
}

.alignMiddlePage2 {
}

.shiftsBodyContainer {
  background-color: #ebf0f7;

  min-height: calc(100vh - 272px);
  max-height: calc(100vh - 272px);
  overflow: auto;
  position: fixed;
  min-width: 100%;

  padding-top: 8px;
  padding-bottom: 68px;
}

.shiftsBodyContainerDesktop {
  min-width: calc(100vw - 600px);
  max-width: calc(100vw - 600px);

  min-height: calc(100vh - 236px);
  max-height: calc(100vh - 236px);
  border-left: 1px solid #e6eaee;
}

.shiftsContainerNonFullNonMobWithFilt {
  min-width: calc(100vw - 240px);
  max-width: calc(100vw - 240px);

  min-height: calc(100vh - 219px);
  max-height: calc(100vh - 219px);
  border-left: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;

  min-height: calc(100vw - 240px);
  max-height: calc(100vw - 240px);
}

.shiftsContainerNonFullNonMobWithoutFilt {
  min-width: calc(100vw - 241px);
  max-width: calc(100vw - 241px);

  border-left: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;

  min-height: calc(100vw - -151px);
  max-height: calc(100vw - 151px);
}

.shiftsContainerMobOnly {
  min-height: calc(100vh - 309px);
  max-height: calc(100vh - 309px);
  padding-top: 8px !important;
}

.showFilterMobOnlyShiftsContainer {
  min-height: calc(100vh - 351px);
  max-height: calc(100vh - 351px);
  padding-top: 8px !important;
}

.absencePageContainerDesktop {
  max-width: calc(100vw - 240px);
  min-width: calc(100vw - 240px);
  min-height: calc(100vh - 180px);
  max-height: calc(100vh - 180px);
  border-left: 1px solid #e6eaee;
}

.loadingHoriztonalImg {
  max-width: 120px;
  min-width: 120px;

  margin: 30px auto;
}

.tilShiftUnit {
  background-color: white;
  max-width: 90%;
  min-width: 90%;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 72px;
  max-height: 72px;

  border-radius: 5px;
  cursor: pointer;

  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  border: 1px solid #dee5f0;

  padding: 1px;
  padding-left: 0px;
  /* border: none; */
}

.myTilShiftUnit {
  /* margin: 6px auto; */
}

.teamTilShiftUnit {
  margin-top: 18px !important;
}

.tilShiftUnit:hover {
  background-color: #fff;
  border: 1px solid #bbcada;
  /* padding: 0px; */
}

.tilShiftUnit:first-of-type {
  /* margin-top: 14px; */
}
.clockUnit {
  background-color: white;
  max-width: 90%;
  min-width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 72px;
  max-height: 72px;
  margin-top: 15px;

  border-radius: 5px;
  cursor: pointer;
  /* animation-name: zoomModalIn !important; */
  /* animation-duration: 0.1s !important; */
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  border: 1px solid #dee5f0;
  padding: 1px;
}

@media only screen and (min-width: 820px) {

.clockUnit:hover {
  /* border-top: 1px solid #bbcada;
  border-right: 1px solid #bbcada;
  border-bottom: 1px solid #bbcada;
  */
  border-color: #bbcada !important;
  /* padding: 0px; */
  /* padding-left: 1px !important; */
}

}
.mgrClockUnitWrapperOpen {
  min-height: 141px;
  margin-top: 24px;
  /* margin-top: 20px; */
  margin-bottom: -10px;
  /* animation-name: zoomModalIn !important; */
  /* animation-duration: 0.1s !important; */
  /* background: orange; */
  /* transition: transform 0.1s ease-in-out; Adjust the duration as needed */

}

.mgrClockUnitWrapperOpen:first-of-type {
  margin-top: 20px;
}

.mgrClockUnitWrapperClosed {
  min-height: 101px;
  margin-top: 14px;
  /* animation-name: zoomModalIn !important; */
  /* animation-duration: 0.1s !important; */
}
.mgrClockUnitWrapperClosed:first-child {
  margin-top: 0px;
}
.mgrClockUnit {
  background-color: white;
  max-width: 90%;
  min-width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 72px;
  max-height: 72px;
  margin-top: 8px;

  border: 1px solid #e7ebef;

  border-radius: 5px;
  cursor: pointer;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
}

.mgrClockUnitPending:hover {
  /* mitel */
  background-color: #fff;
  border: 2px dashed #214978 !important;
  padding: 0px;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
}

.mgrClockUnitNonPending:hover {
  border-color: #bbcada
}
.noBorderRadiusBottomRight {
  border-radius: 5px 5px 0 5px;
}

.noBorderRadius {
  border-radius: 0px !important;
}

.clockNameAndTeamDiv {
  display: flex;
  justify-content: space-between;

  min-height: 30px;
  margin-bottom: -16px;
}

.mgrClockNameDiv {
  background-color: rgb(255, 255, 255);
  border: 1px solid #e7ebef;
  border-bottom: none;

  max-width: -webkit-fit-content;

  max-width: -moz-fit-content;

  max-width: fit-content;
  margin-left: 10%;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 6px;
  padding-right: 9px;
  font-size: 11px;
  font-weight: 500;
  color: #496375;
  border-radius: 5px 5px 0 0;
  font-weight: 500;
line-height:12px;
text-align: left;
}

.clockTeamNameTopRight {
  margin-left: 0px;
  margin-right: 13%;
}

.mgrClockSubBar {
  border: solid 1px rgb(214, 225, 234);
  border-top: none;
  background-color: #fafbfd;
  max-width: 85%;
  margin-left: 10%;
  min-height: 47px;
  max-height: 47px;
  border-radius: 0px 0px 5px 5px;
  /* animation-name: slideClockSubBarDown !important; */
  /* animation-duration: 0.1s !important; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mgrClockSubBarMob {
  max-width: 83%;
  border-radius: 0px 0px 15px 15px;

}

.mgrClockNameShield {
  background-color: white;
  margin-left: 10%;

  min-height: 2px;
  max-height: 2px;
  margin-left: calc(10% + 1px);
  font-size: 14px;
  font-weight: 500;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: -73px;
}

.mgrClockNameShieldMob {
  background-color: white;
  margin-left: 10%;

  min-height: 2px;
  max-height: 2px;
  margin-left: calc(10% + 1px);
  font-size: 14px;
  font-weight: 500;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: -113px;
}

.mgrClockNameShieldMobSelected {
  background-color: white;
  margin-left: 10%;

  min-height: 2px;
  max-height: 2px;
  margin-left: calc(10% + 1px);
  font-size: 14px;
  font-weight: 500;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: -113px;
}

.pullFirstMobClockUnitUp {
  margin-top: 20px;
}

@-webkit-keyframes slideClockSubBarDown {
  from {
    min-height: 0px;
    max-height: 0px;
  }
  to {
    min-height: 40px;
    max-height: 40px;
  }
}

@keyframes slideClockSubBarDown {
  from {
    min-height: 0px;
    max-height: 0px;
  }
  to {
    min-height: 40px;
    max-height: 40px;
  }
}

.desktopMgrClockDefaultCursor {
  cursor: default;
}

.tagsOnShiftExtend {
  min-height: 92px;
  max-height: 92px;
}

.teamShiftTagsContainer {
  display: flex;
  margin-top: 4px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-right: 20px;
  margin-left:9%;
  margin-bottom:7px;
}

.tilShiftUnit:active {
  /* transform: scale(0.98); */
}

.teamShiftTagItem {
  color: #fff;
  font-size: 11px;
  text-align: left;
  margin-right: 2px;
  padding-left: 5px;
  padding-right: 6px;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  border-radius: 5px;
  font-weight: 500;
  /* background-color: #143051; */
  /* background-color: rgb(247, 250, 255); */
  background-color: #f4f8fe;
  color: #4f566b;
  padding-top: 0px;
  line-height: 19px;
  max-height: 24px;
  max-height: 23px;
  border: 1px solid #d2d8df;
}

.teamShiftTagsSurplusIndicator {
  color: #fff;
  font-size: 0.7em;
  text-align: left;
  margin-left: 0px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 5px;
  font-weight: 500;
  /* background-color: #f4f8fe; */
  line-height: 17px;
  max-height: 18px;
  margin-top: 3px;
  background-color: #f4f8fe;
  color: #496375;
  border: 1px solid #d2d8df;

  padding-top: 0px;
  line-height: 14px;
}
.tilShiftSection0 {
  min-width: 10%;
  text-align: left;
  display: flex;
  margin-right: -14px;
  padding-left: 10px;
  align-items: center;
  justify-content: center;
}

.unclockedSectionLeft0 {
  min-width: 10%;
  text-align: left;
  display: flex;
  margin-right: -14px;
  padding-left: 17px;
  align-items: center;
  justify-content: center;
}

.myShiftWorkedDot {
  background-color: #a8cc9a;
  min-width: 10px;
  max-width: 10px;
  min-height: 10px;
  max-height: 10px;
  border-radius: 10px;
}

.myShiftUnWorkedDot {
  background-color: #e0ebf8;
  min-width: 6px;
  max-width: 6px;
  min-height: 6px;
  max-height: 6px;
  border-radius: 10px;
  display: none;
}

.clockCardTopRightPlacer {
  min-width: 28%;
  margin-right: 20px;
}

.myShiftWorkedDotAbsent {
  background-color: #e89292;
  min-width: 10px;
  max-width: 10px;
  min-height: 10px;
  max-height: 10px;
  border-radius: 10px;
}

.myShiftWorkedDotAbsentPartial {
  background: linear-gradient(66deg, #e89292 50%, rgba(172, 195, 225, 1) 50%);
  min-width: 10px;
  max-width: 10px;
  min-height: 10px;
  max-height: 10px;
  border-radius: 10px;
}

.myShiftGhostDot {
  background-color: #0077ff;
  min-width: 10px;
  max-width: 10px;
  min-height: 10px;
  max-height: 10px;
  border-radius: 10px;
}

.tilShiftSection1 {
  min-width: 70% !important;
  text-align: left;
  display: flex;
  flex-direction: column;

  padding-left: 7%;
  justify-content: flex-start;
}

.tilShiftSection1Clock {
  min-width: 51% !important;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding-left: 7%;
  justify-content: flex-start;
  /* border-left: 2px dotted #5a9e3f; */
  margin-left: 12px;
}

.clockedOnSection1 {
  min-width: 61% !important;
  text-align: left;
  display: flex;
  flex-direction: column;
  /* padding-left: 7%; */
  justify-content: flex-start;
  /* border-left: 2px dotted #5a9e3f; */
  /* margin-left: 12px; */
}

.declinedClockBar {
  /* border-left: 2px dotted #ba0000; */
}

.pendingClockBar {
  /* border-left: 2px dashed #bbcada; */
}
.pendingClockSection1 {
  /* background-color: blue; */
  margin-left: 6px;
  border: none !important;
}

.pendingClockSection2 {
  padding-right: 22px !important;
}

.tilShiftSection1Unclock {
  text-align: left;
  display: flex;
  flex-direction: column;
  padding-left: 7px;
  justify-content: flex-start;
  border-left: 1px solid #bfccdc;
  margin-left: 18px;
  margin-top:-13px
}

.tilShiftSection1MultiSelected {
  min-width: 54% !important;
  margin-right: -11px;}

.myHoursShiftSecion1 {
  min-width: 60%;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding-left: 7%;
  justify-content: flex-start;
  /* border-left: 2px dotted #5a9e3f; */
  margin-left: 11px;
}

.mobMyHoursShiftSecion1 {
  padding-left: 13px;
  margin-left: 10px;
}

.tagsMobMyHoursShiftsSection1 {
  /* padding-left: 3%; */
  /* margin-left: 3%; */
  min-width: 165px !important;
  /* background: yellow; */
}

.teamClockSection1 {
  min-width: 60%;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  margin-left: 12px;
  justify-content: flex-start;
  /* border-left: 2px dotted #5a9e3f; */
  margin-left: 20px !important;
  padding-left: 10px !important;
}

.leftRedBorder {
  border-left: 2px dotted #ba0000;

}



.desktopUnlinkedTeamClockSection1 {
  margin-left: 5%;
}
.desktopTeamClockSection1 {
  padding-left: 5%;
}

.tilShiftSection2 {
  min-width: calc(40% - 0px);
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 34px;
}

.myHoursShiftSecion2 {
  min-width: calc(85% - 200px);
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 0px;
}

.teamClockSection2 {
  min-width: calc(31% - 0px);
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 22px;
}

.mobTeamClockSection2 {
  padding-right: 18px;
}

.clockSection2 {
  min-width: calc(32% - 0px);
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 13px;
}

.desktopClockSection2 {
  margin-right: -22px !important;
}
.tilShiftSection1team {
  min-width: 166px;
  text-align: left;
  display: flex;
  flex-direction: column;

  padding-left: 5%;

  justify-content: flex-start;
  /* border-left: 2px dotted #214978; */
  margin-left: 10px;
}

.tilShiftSection1teamDesktop {
  margin-left:16px
}

.marginLeft16px {
  margin-left:16px 
}

.tilShiftSection2team {
  min-width: 38%;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 18px;
  /* margin-bottom:10px; */
  /* margin-top:-21px */
}

.tilShiftUnitName {
  color: #143051;
  margin-bottom: 2px;
  font-weight: 600;
  font-size: 14px;
  display: flex;
}
.tilShiftDs {
  color: #143051;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  min-height: 26px;
  line-height: 22px;
  justify-content: space-between;
  min-width: 124px;
  padding-top: 2px;
  margin-top: 0px;
  margin-bottom: -1px;
}

.unclockedDsString {
  color: #8ea7c5;
  display: flex;
  align-items: flex-end;
  font-weight: 500;
  font-size: 12px !important;
  min-height: 35px;
  line-height: 16px;
  padding-bottom: 2px;
  padding-left:2px;
  /* padding-top: 2px; */
  border-bottom: 1px solid #dde9f9;
}

.tilShiftDsTeam {
  color: #95aaba;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  border-top: 1px solid #e0ebf8;
  padding-left: 3px;
  padding-top: 2px;
}

.tilShiftTimes {
  color: #95aaba;
  font-weight: 600;
  font-size: 11px;
  padding-right:5px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  margin-top: 2px;
  display: flex;
  align-content: center;
  /* padding-right: 4px; */
}

.tilShiftTimes2 {
  color: #95aaba;
  font-weight: 500;
  font-size: 13px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  margin-top: 4px;
  padding-left: 8px;
}

.unclockedShiftTimes {
  color: #8ea7c5;
  font-weight: 500;
  padding-left:2px;
  font-size: 11px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  display: flex;
  align-items: center;
  /* margin-top: 4px; */
  /* padding-left: 8px; */
}

.borderLeftTilStrip {
  border-left: 1px solid #e0ebf8;
  color: #95aaba;
  padding-left: 8px;
  margin-left: 10px;
}

.smallerTxtTimeTil {
  font-size: 10px;
  max-width: 82px;
  line-height: 11px;
  font-weight: 500;
  margin-top: 1px;
  min-width: 82px;
  /* background: yellow; */
}

.smallerTxtTimeTilTeamStrip {
  font-size: 12px;
  padding-right: 0px !important;
  /* margin-left: 10px; */
  /* padding-left: 5px !important; */
  margin-top: 1px;
  max-width: 96px !important;
  min-width: 86px !important;
  line-height: 15px;
}

.myShiftsUnitDurationSubTxt {
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content !important;
  min-width: fit-content !important;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  align-items: center;
  border-radius: 5px;
  color: #859dbb;
  font-weight: 500;
  font-size: 12px;
  padding-top: 0px;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 9px;
}

.teamClockBreakDurStrip {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content !important;
  min-width: fit-content !important;
  max-width: 110px;
  padding-left: 4px;
  font-weight: 500;
  font-size: 12px;
  padding-top: 0px;
line-height:11px;  display: flex;
  align-items: center;
  justify-content: center;
  color: #9374d6;
  margin-left: 9px;
  min-height: -moz-fit-content;
  min-height: -webkit-fit-content;
  min-height: fit-content;
  max-height: -moz-fit-content;
  max-height: -webkit-fit-content;
  max-height: fit-content;
}

.unclockedBreakDurStrip {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: space-between;
  max-height: 22px;
  min-height: 22px;

  align-items: center;
  border-radius: 5px;
  color: #496375;
  font-weight: 500;
  font-size: 12px;
  background-color: #e3e8ee;
  color: #4f566b;
}

.makeSubTxtSmaller {
  font-size: 0.7em;
  color: #143051;
  color: #0077ff;
}
.tilShiftTimesTeam {
  color: #acc3e1;

  font-size: 0.93em;
  padding-left: 9px;
  margin-left: 9px;
  border-left: 1px solid #bac5d3;
}

.tilShiftDurationTxt {
  font-size: 0.9em;
  margin-top: 0px;
  background-color: #acc3e1;

  color: white;

  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 15px;

  max-height: 24px;
  line-height: 16px;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;

  font-weight: 400;
}

.tilShiftDurationTxtTeam {
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #e6eaee;
  color: #fff;
  font-weight: 500;
  font-size: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #214978;

  font-size: 10px;
  font-weight: 500;
  background: #f4f8fe;
  border-radius: 5px;
  border: 1px solid #bbcada;
  color: #516b8b;

}

.absentSubTxtBarShifts {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #859dbb;
  color: #fff;
  font-weight: 500;
  font-size: 0.8em;
  padding-top: 2px;
  padding-bottom: 2px;
  background: rgb(247, 250, 255);
  border: 1px solid #e4cccc;
  background-color: #f2d8d2;
}

.partialAbsentSubTxtBarShifts {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;

  color: #496375;
  font-weight: 500;
  font-size: 0.8em;
  padding-top: 2px;
  padding-bottom: 2px;
  background: rgb(247, 250, 255);

  background-color: #2e4657;
  background-color: #e3e8ee;
  color: #4f566b;
  background: linear-gradient(66deg, #e3e8ee 50%, rgb(247, 250, 255) 50%);
}

.partialAbsentSubTxtBarShiftsPaid {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;

  color: #4f566b;
  font-weight: 500;
  font-size: 0.8em;
  padding-top: 2px;
  padding-bottom: 2px;

  background-color: #e3e8ee;
  background: linear-gradient(66deg, #e3e8ee 50%, rgb(247, 250, 255) 50%);
}

.absentButPaid {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;

  font-weight: 500;
  font-size: 0.8em;
  padding-top: 2px;
  padding-bottom: 2px;
  background: rgb(247, 250, 255);

  background-color: #e3e8ee;
  color: #4f566b;
}

.tilShiftTypeTxt {
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 3px;
  background: #f4f8fe;
  border-radius: 5px;
  border: 1px solid #bbcada;
  color: #516b8b;
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
  margin-bottom: 6px;
}

.unclockedDurString {
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content !important;
  min-width: fit-content !important;
  font-weight: 500;
  padding-top: 3px;
  padding-bottom: 3px;
  /* margin-top: 3px; */
  /* margin-bottom: -3px; */
  margin-right: 8px;
  font-size: 11px;
  text-align: left;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 4px;
  max-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #bbcada;
  /* background-color: #f4f8fe; */
  border: 1px solid #bbcada;
  /* margin-top: -20px; */
}

.activeClockedDurString {
  color: #143051;
  font-size: 14px;
  font-weight: 500;
  /* margin-bottom: 3px; */
  /* animation: Pulsate 1s linear infinite; */
  margin-right: 6px;
}

.tilShiftTypeTxtTeam {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 6px;
  color: #4f566b;
  background-color: #f4f8fe;
  border: 1px solid #e6e6e6;
  padding: 4px;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  display: none;
}

.shiftsPageFilterBarRota {
  min-height: 56px;
  max-height: 56px;
  background-color: #ebf0f7;

  /* min-width: 100%;
  max-width: 100%; */

  padding-top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-right: 4%; */
  position: relative;
  z-index: 8;
  min-width: 100%;
  max-width: 100%;
  /* padding-left: 5%; */
  padding-right: 2%;
  /* border-right: 1px solid #e0ebf8;
  border-bottom: 2px solid #e6eaee; */
}

.shiftsPageFilterBar {
  min-height: 56px;
  max-height: 56px;
  background-color: #ebf0f7;

  /* min-width: 100%;
  max-width: 100%; */

  padding-top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-right: 4%; */
  position: relative;
  z-index: 9;
  min-width: 100%;
  max-width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  /* border-right: 1px solid #e0ebf8;
  border-bottom: 2px solid #e6eaee; */
}

.teamShiftsPageFilterBar {
  min-height: 90px;
  max-height: 90px;
  background-color: #ebf0f7;

  min-width: 90%;
  max-width: 90%;

  padding-top: 0px;

  position: relative;
  z-index: 999;
  border-right: 1px solid #e0ebf8;
  border-bottom: 2px solid #e6eaee;
  margin-bottom: -10px;
  margin-left: 5%;
}

.teamShiftsPageFilterBarUpper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  max-height: 50px;
  border-bottom: 1px solid #e6eaee;
  /* margin-top: 6px; */
  /* max-width: 90%; */
  /* margin-left: 5%; */
}

.upperMobTeamHours_ {
  /* margin-top:3px; */
  /* padding-top:10px; */
  /* padding-left: 5%; */
  /* padding-right: 5%; */
}
.Container {
  /* display: none; */
}
.b221 {
  border-left: 2px solid #e6eaee !important;
}

.teamShiftsPageFilterBarLower {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  max-height: 50px;
  /* padding-top: 4px; */
}

.shiftsPageFilterBarLeft {
  min-width: 44%;
  max-width: 44%;

  min-height: 100%;
  display: flex;
  justify-content: flex-start;
  /* margin-left: 5%; */
  margin-top: 8px;
}

.mobTeamShiftsUpperLeft {
  min-width: 134px;
  max-width: 134px;

  min-height: 100%;
  display: flex;
  justify-content: flex-start;
}

.reverseShiftsIcon {
  max-width: 22px;
  min-width: 22px;
  margin-left: 12px;
  border: 1px solid #e6eaee;
  border-radius: 5px;
  max-height: 23px;
  max-width: 23px;
  min-height: 23px;
  min-width: 23px;
  margin-top: 6px;
  cursor: pointer;
}

.reverseShiftsIcon:hover {
  background-color: #fff;
}
.reversedIcon {
  background-color: #fff;
  border: 1px solid #8ea7c5;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.reversedIcon:hover {
  background-color: #fff;
  opacity: 0.8;
}

.shiftsTilMonthDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 134px;
  min-width: 134px;
  line-height: 26px;
  height: 35px;

  -webkit-appearance: none;
  background-position-x: 64px;
  display: flex;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: #0bf;
  background-color: white;
  color: #143051;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 112px;
}

.shiftsTilMonthDropdown:hover {
  border-color: #d7dfe9;
}

.shiftsTilMonthDropdown2 {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 136px;
  min-width: 136px;
  line-height: 26px;
  height: 35px;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 1px solid #e6eaee;
  color: #fff;
  background: url(/static/media/downArrowWhite2.887d57ce.svg) no-repeat right;
  background-color: #2b679f;
  background-position-x: 112px;
}

.shiftsTilMonthDropdown2:hover {
  border-color: #a9bcd4;
}

.shiftsTilTeamMonthDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 134px;
  min-width: 134px;
  line-height: 26px;
  height: 35px;
  -webkit-appearance: none;
  background-position-x: 66px;
  display: flex;
  cursor: pointer;
  border-color: #2c7eb9;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #143051;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 112px;
}

.shiftsTilTeamMonthDropdown:hover {
  border-color: #d7dfe9;
}

.shiftsPageFilterBarRight {
  min-height: 100%;

  font-size: 14px;
  font-weight: 500;
  display: flex;
  /* margin-right:5%; */
  align-items: center;
  justify-content: flex-end;
  margin-left:20px;
}

.resetFilter {
  margin-top: 5px;
  margin-right: 18px;
  font-weight: 400;
  color: #95aaba;
  cursor: pointer;
  color: #fff;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  margin-top: 9px;
}

.resetFilter:hover {
  background-color: #bbcada;
}

.resetFilterNavy {
  margin-top: 9px;
  margin-right: 18px;
  font-weight: 500;
  color: #8ea7c5;
  cursor: pointer;
  font-size: 12px;
}

.resetFilterNavy:hover {
  opacity: 0.8;
}

.shiftTilFilterHolder {
  min-width: 100%;

  display: flex;
  justify-content: flex-end;
  margin-top: -0px;
  /* margin-bottom:-4px; */
  background-color: #ebf0f7 !important;
  /* max-height:35px; */
}

.shiftTilFilterHolder2 {
  min-width: 100%;

  display: flex;
  justify-content: flex-end;
  margin-top: -4px;
  /* margin-bottom:-4px; */
  background-color: #ebf0f7 !important;
  /* max-height:35px; */
}

.shiftTilFilterBtnsContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 14px;

  min-width: 298px;
  padding-right: 5%;
  border-right: 1px solid #e0ebf8;
}

.shfitTilFilterBtnsContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
  max-width: 342px;
  min-width:342px;
  float: right;
  padding-right: 7%;
  border-right: 1px solid #e0ebf8;
}

.shiftTilFilterBtnsContainerMob {
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;
  background-color: #ebf0f7;
  min-width: 328px;
  padding-right: 5%;
  padding-bottom: 5px !important;
  /* border-right: 1px solid #e0ebf8; */
}

.shiftTilFilterBtn {
  font-size: 14px;
  margin-top: 8px;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  padding-left: 13px;
  font-weight: 500;
  padding-right: 13px;
  /* margin-right: 15px; */
  cursor: pointer;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shiftTilFilterBtn:hover {
  background-color: #bbcada;
}

.shiftTilFilterBtn:active {
  -webkit-transform: scale(0.96);
          transform: scale(0.96);
}

.shiftTilFilterBtnEngaged {
  font-size: 0.95em;
  margin-top: 8px;
  color: #fff;
  border: 1px solid #7049c4;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;

  padding-left: 14px;
  font-weight: 500;
  padding-right: 14px;

  margin-right: 8%;
  cursor: pointer;
  background-color: #9374d6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeFilterImgTilShifts {
  max-width: 9px;
  margin-left: 6px;
  margin-top: 2px;
}

.shiftTilFilterBtnActive {
  font-size: 14px;
  margin-top: 8px;
  color: #fff;

  display: flex;

  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  align-items: center;

  padding-left: 9px;
  padding-right: 10px;
  font-weight: 500;
  /* margin-right: 16px; */

  cursor: pointer;
  background-color: #8ea7c5;
  border: 1px solid #6987a9;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}
.shiftTilFilterBtnActive:hover {
  background-color: #7e93ac;
}

.marginRight0 {
  margin-right: 0px !important;
}

.shiftsOrTilFilter {
  cursor: pointer;
  display: flex;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;

  color: #fff;
  border-radius: 5px;
  min-height: 30px;
  line-height: 30px;
  padding-left: 8px;
  padding-right: 6px;
  font-size: 14px;
  font-weight: 500;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  margin-right: 10px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.shiftsOrTilFilter:hover {
  background-color: #bbcada;
}

.shiftsOrTilFilter:last-child {
  margin-right: 0px;
}

.shiftsOrTilFilterTeam {
  cursor: pointer;
  display: flex;
  border: 1px solid #8e76c1;
  background-color: #a387df;

  color: #fff;
  border-radius: 5px;
  min-height: 30px;
  line-height: 30px;
  padding-left: 8px;
  padding-right: 6px;
  font-size: 14px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  margin-right: 6px;
  font-weight: 500;
}

.shiftsOrTilFilterTeam:hover {
  background-color: #8e76c1;
}

.shiftsOrTilFilterTeam:last-child {
  margin-right: 0px;
}

.unselectedShiftOrTil {
  /* margin-top:8px; */
}
.unselectedShiftOrTil2 {
  margin-top: 8px;
  background-position-x: 174px !important;
  max-width: 188px !important;
  min-width: 198px !important;

  min-width: 200px;
  min-height: 35px;
  background-color: blue;
  max-width: 200px;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 175px;
  font-weight: 500;
  font-size: 14px;
  padding-left: 10px;
}

.unworkedShiftTilItem {
  /* background-color: #c0d6f0;
  color: #fff; */
}

.shiftTilSelectImg {
  max-width: 12px;
  margin-left: 7px;
  margin-right: 4px;
}

.filterSplitterContainer {
  min-width: 100%;
  background-color: #ebf0f7;
  padding-top: 0px;
  border-right: 1px solid #e0ebf8;
  border-left: 1px solid #e0ebf8;
}

.filterSplitter {
  min-height: 1px;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 10px;
  background-color: #e6eaee;
}

.myShiftModalUnderlay {
  background: rgba(0, 0, 0, 0.25);

  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.tilRateDropdown {
  color: #496375;
  border-radius: 5px;
  font-size: 1em;
  font-weight: 500;
  padding-left: 14px;
  text-align: left;
  max-width: 78px;
  line-height: 26px;
  font-weight: 500;
  height: 30px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  border: 1px solid #dde9f9;
  -webkit-appearance: none;
  background-position-x: 54px;
  margin-bottom: 6px;
  margin-left: 18px;
}

.myShiftModalBox {
  background-color: #ebf0f7;
  color: #143051;
  min-width: 360px;
  max-width: 360px;
  border-radius: 10px;
  padding-top: 20px;

  text-align: left;
  padding-left: 15px;
  padding-right: 15px;

  line-height: 45px;
  margin: 0 auto;
  height: 515px;

  cursor: auto;

  -webkit-animation-name: zoomModalIn !important;

          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  border: 1px solid #e7ebef;
}

.myShiftModalBoxDesktop {
  min-height:516px;
  max-height:516px;
}

.mgrShiftModalBoxBorder {
  border: 1px solid #e7ebef;
}

.allShiftsMarkedAbsenceHeaderTxt {
  font-size: 1.2em;
  font-weight: 500;
}

.myShiftModalHeaderContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2px;
  margin-top: -14px;
}

.myShiftModalHeaderContainerOpen {
  margin-top:-9px
}

.closeAbsenceUnitBtnImg:hover {
  opacity: 0.8;
}

.myShiftCloseHolder {
  min-width: 24px;
  max-width: 24px;
  padding-left: 4px;
padding-top:1px;

}

.closeMyShiftModalImg {
  width: 15px;
  cursor: pointer;
}

.closeMyShiftModalImg:hover {
  opacity: 0.8;
}

.myShiftModalHeaderTitleTxt {
  text-align: right;

  min-width: 170px;
  margin-top: -1px;

  font-size: 18px;
  font-weight: 500 !important;
  color: #143051;
  /* margin-top: -2px; */
}

.toilHeaderTxt {
  text-align: right;
  /* display: flex; */
  /* align-items: center; */
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  /* margin-top: -3px; */
  font-size: 18px;
  font-weight: 500 !important;
  color: #143051;
  /* margin-top: -3px;
  margin-bottom: 3px; */
}

.openShiftModalTitle {
  text-align: right;

  min-width: 170px;
  margin-top: -3px;

  font-size: 1.2em;
  font-weight: 500 !important;
  color: #143051;
  /* margin-top: -2px; */
}

.myShiftModalHeaderRight {
  text-align: right;
  min-width: 70px;
  max-width: 70px;

  font-size: 0.9em;
  font-weight: 500;
  color: #8ea7c5;
}

.myShiftRow {
  border-bottom: 1px solid #e0ebf8;
  display: flex;
  padding-top: 3px;
  justify-content: space-between;

  padding-left: 5%;
  padding-right: 5%;
}

.myShiftSwappedRow {
  border-bottom: 1px solid #e0ebf8;
  display: flex;
  padding-top: 3px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  padding-left: 5%;
  padding-right: 5%;
}

.myShiftRowLeft {
  font-size: 16px;
  font-weight: 500;

  justify-content: space-between;
  color: #143051;

  min-width: 100%;
}

.itemNote {
  line-height: 26px;
  margin-top: 10px;
  font-size: 0.9em;
  padding-right: 8px;
  padding-bottom: 8px;
}
.myShiftRowLeftTitle {
  font-size: 12px;
  margin-bottom: -20px;
  color: #214978;

  /* color: #859cb6; */
  font-weight: 400;
  display: flex;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.myShiftRowRight {
  min-width: 18px;
  min-height: 10px;
}

.myShiftModalBody {
  border: solid 1px #e6eaee;
  border-top: solid 2px #e6eaee;
  border-bottom: none;
  border-radius: 6px 6px 0px 0px;
  max-width: 100%;
  overflow: auto;
  overflow-x: hidden;
  max-height: 369px;
  min-height: 369px;
  -ms-overflow-style: none;

  background-color: rgb(252, 253, 255);
  background-color: white;
}

.myShiftBreakSpan {
  font-size: 0.8em;
  color: #bbcada;
}

.bankToBePaidSpan {
  font-size: 0.8em;
  color: #bbcada;
  padding-left: 10px;
}

.myShiftTagsContainer {
  display: flex;
  flex-wrap: wrap;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 6px;
}

.itemTag {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  min-height: 35px;
  line-height: 16px;
  align-items: center;
  border-radius: 5px;
  margin-top: 8px;
  margin-right: 5px;
  padding-bottom: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #4f566b;
  background-color: #f4f8fe;
  border: 1px solid #e6e6e6;
}

.myShiftAbsentTxtRed {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #c98888;
  color: #fff;
  font-weight: 500;
  font-size: 0.8em;
  padding-top: 2px;
  padding-bottom: 2px;
  background: #e89292;
  margin-top: 10px;
  margin-bottom: 12px;
}

.myShiftAbsentDurationSpan {
  font-size: 10px;
  color: #f5e5e5;
  margin-top: 2px;
}

.myShiftModalFooter {
  display: flex;
  justify-content: space-between;
  max-height: 50px;
  min-height: 50px;
  border-top: 2px solid #dbe4ee;
  align-items: center;

  min-width: 100%;
}

.mobMyShiftModalFooter {
  padding-top: 3px;
}

.myShiftModalFooterRight {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 6px;
  padding-right: 6px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  max-width: 59px;
  min-width: 59px;
}

.myShiftModalFooterRight:hover {
  background-color: #214978;
}

.myShiftRow:last-child {
  border-bottom: none;
  padding-bottom: 12px;
}

.myShiftrOW:last-of-type {
  border-bottom: none;
}
.myShiftModalFooterLeft {
  display: flex;
  justify-content: space-between;
  min-width: 225px;
}

.myShiftModalFooterLeftMyShift {
  display: flex;
  justify-content: flex-start;
  min-width: 260px;
}

.myShiftModalFooterLeftMyShiftOtheruser {
  display: flex;
  justify-content: flex-start;
  min-width: 244px;
}
.requestShiftEditBtnContainer {
  display: flex;
  min-height: 35px;
  max-height: 35px;
  align-items: center;
  margin-top: 0px;
  border-radius: 5px;
  justify-content: center;
  max-width: 58px;
  cursor: pointer;
  margin-left:5px;
}

.requestShiftHistoryBtnContainer {
  display: flex;
  min-height: 35px;
  max-height: 35px;

  align-items: center;
  margin-top: 0px;
  border-radius: 5px;

  cursor: pointer;
}

.requestShiftHistoryBtnContainerActive {
  display: flex;
  min-height: 35px;
  max-height: 35px;
  /* min-width: fit-content; */
  /* padding-right: 5px; */
  /* max-width: 90px !important; */
  align-items: center;
  margin-top: 0px;
  border-radius: 5px;

  cursor: pointer;

  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  min-width: -webkit-fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  padding-right: 7px;
}

.requestShiftHistoryBtnContainerActive:hover {
  background-color: #bbcada;
}
.animateInLeft {
  -webkit-animation-name: slideModalLeft !important;
          animation-name: slideModalLeft !important;
  -webkit-animation-duration: 0.025s !important;
          animation-duration: 0.025s !important;
}
.requestShiftEditBtnTxt {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  border: solid 1px #0af;
  background-color: #0bf;
  text-align: center;
  min-width: 50px;
  padding-top: 2px;
  padding-left: 4px;
  padding-right: 4px;
  margin-top: -1px;
}

.requestShiftEditBtnTxt:hover {
  background-color: #0af;
}

.claimItemBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  border: solid 1px #0af;
  background-color: #0bf;
  text-align: center;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-top: 2px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 5px;
  max-height: 35px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
}

.claimItemBtn:hover {
  background-color: #0af;
}

.requestShiftHistoryBtnTxtInactive {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 68px;
  padding-left: 6px;
  padding-right: 6px;
  min-height: 35px;
  max-height: 35px;
  color: #fff;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
}

.requestShiftHistoryBtnTxtInactive:hover {
  background-color: #bbcada;
}
.requestShiftHistoryBtnTxtActive {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding-left: 3px;
  text-align: center;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-top: 0px;

  padding-right: 4%;
  /* margin-top: -1px; */
  margin-left: 6px;
  /* margin-right: 13px; */
}

.closeHistoryShiftImgCross {
  margin-left: 7px;
  margin-right: -3px;
  max-width: 9px;
  min-width: 9px;

  margin-top: 1px;
}

.nameAndDateDropdownsContainer {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  min-width: 100%;

  padding-right: 5%;
  padding-top: 3px;
  margin: 0 auto;
  background-color: #ebf0f7;
  border-right: 1px solid #e0ebf8;
}

.mobShiftsFilterBarActive {
  display: flex;
  justify-content: space-between;

  background-color: #ebf0f7;
  border-right: 1px solid #e0ebf8;
}

.shiftNamesDropdown {
  /* color: #fff; */
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 188px;
  min-width: 188px;

  line-height: 26px;
  min-height: 35px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
    #496375;
  background-color: white;
  color: #143051;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  background-position-x: 164px;
  display: flex;
  cursor: pointer;
  /* margin-right: 8px; */
}
.shiftNamesDropdown:hover {
  border-color: #d7dfe9;
}

.fullButNarrowShiftNamesDropdown {

  max-width: 158px;
  min-width: 158px;
  background-position-x: 134px;

}

.shiftDayDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 78px;
  min-width: 78px;
  line-height: 26px;
  height: 35px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
    #496375;
  background-color: white;
  color: #496375;
  border: 2px solid #e6eaee;
  -webkit-appearance: none;
  background-position-x: 54px;
  display: flex;
  cursor: pointer;
  /* margin-right: 12px; */
  /* animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important; */
}

.shiftDayDropdown:hover {
  border-color: #d7dfe9;
}

.noShiftsTxtContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  font-size: 0.9em;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  line-height: 20px;
  margin-top: 2px !important;
  padding-left: 5%;

}

.noShiftsSideBar {
  min-height: 1px;
  min-width: 30px;
  background-color: #bbcada;
  max-height: 1px;
  display: none;
}

.noShiftsTxt {
  color: #bbcada;
  padding-left: 13px;
  /* padding-right: 13px; */
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-top:10px;
  text-align: left;
  padding-right:60px;
}

.x19873 {
  margin-top:15px;
}


.mobNoShiftsTxt {
  padding-left: 6%;
}


.x1984971 {
  padding-left:5%;
}
.shiftAmendmentRequestsBtn {
  font-size: 12px;
  margin-top: 8px;
  color: #fff;
  background-color: #9a74eb;
  border: 1px solid #965ade;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  line-height: 35px;
  padding-left: 7px;
  font-weight: 500;
  padding-right: 8px;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-left: 3px solid #965ade !important;
}

.shiftAmendmentRequestsBtn:hover {
  background-color: #967ccf;
}

.shiftAmendmentRequestsBtnActive {
  font-size: 14px;
  margin-top: 8px;
  color: #fff;
  display: flex;
  background-color: #4b1caf;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  line-height: 23px;
  padding-left: 12px;
  padding-right: 18px;
  font-weight: 500;
  margin-right: 10px;
  max-width: 102px;
  cursor: pointer;
  opacity: 1;
  align-items: center;
  border: 1px solid #481561;
}

.shiftAmendmentRequestsBtnActive:hover {
  background-color: #442781;
}

.amendmentsNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  min-height: 20px;
  max-height: 20px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;

  color: white;

  font-size: 12px;

  margin-left: 3px;
  padding-left: 5px;
  border-left: 1px solid white;
}

.mgrModalBgShiftCost {
  border: solid 2px #bbcada;
  background-color: #cbd7e6;
}

.salariedCost {
  border: solid 1px #7992b2 !important;
  background-color: #859dbb !important;

  font-size: 12px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  font-weight: 500 !important;
}

.shiftTeamSubBubbleMgrBg {
  border: 1px #4b1caf solid;
  color: #7249c9;
  cursor: default;
}

.shiftTeamSubBubbleMgrBg:hover {
  border-color: #b2bbc7;
}

.mgrModalTitleColour {
  min-width: 204px;
}

.employeeNameBar {
  margin-left: 24px !important;
}

.knobOnMgrColour {
  background-color: #7249c9;
}

.ghostBgShiftTilItem {
  background-color: #214978;
  opacity: 1;
  /* border-bottom: 1px solid #143051 !important; */
}

.ghostBgShiftTilItem:hover {
  border-color: #143051;
  background-color: #214978 !important;

  padding-bottom: 1px;
}

.changesDot {
  background-color: white;
  min-width: 5px;
  min-height: 5px;
  max-height: 5px;
  max-width: 5px;
  border-radius: 5px;
  margin-top: -12px;
  margin-right: -3px;
}

.changesNoChangesBtn {
  font-size: 14px;
  margin-top: 8px;
  color: #496375;
  border: 2px solid #496375;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  line-height: 26px;
  padding-left: 10px;
  font-weight: 500;
  padding-right: 20px;
  margin-right: 8%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  color: white;
  min-width: 81px;
  
}

.changesNoChangesBtn:hover {
  background-color: #bbcada;
}

.changesNewChangesBtn {
  font-size: 0.95em;
  margin-top: 8px;
  color: #fff;

  background-color: #0077ff;
  border-radius: 25px;
  min-height: 35px;
  max-height: 35px;
  line-height: 26px;
  padding-left: 10px;
  font-weight: 500;
  padding-right: 10px;
  padding-top: 2px;
  margin-right: 8%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.shiftHistoryContainer {
  min-height: 358px;
  max-height: 358px;
  overflow: auto;
  overflow-x: hidden;
}

.shiftHistoryRow {
  padding: 15px;
  line-height: 22px;
  font-size: 0.9em;
  border-bottom: 1px solid #e0ebf8;
}

.shiftHistoryRow:first-child {
  padding: 15px;
  padding-top: 25px;
  line-height: 22px;
  font-size: 0.9em;
  border-bottom: 1px solid #e0ebf8;
}

.shiftHistoryRow:last-child {
  padding: 15px;
  padding-top: 25px;
  line-height: 22px;
  font-size: 0.9em;
  border: none;
  border-bottom: 0px solid #fff !important;
}

.shiftHistoryRowFinal {
  border: none !important;
}

.shiftHistoryMain {
  font-weight: 400;
  color: #496375;
  padding-right: 20px;
  font-size: 12px;
  margin-left: -7px;
  border-left: 2px solid #bbcada;
  padding-left: 10px;
}

.myShiftChangesColour {
  color: #bbcada;
  font-weight: 500;
}

.shiftHistoryTimeContainer {
  display: flex;
  justify-content: flex-end;
  padding-right: 10%;
  padding-top: 8px;
  margin-bottom: -4px;
}

.shiftHistoryTime {
  font-weight: 500;
  background-color: #f4f8fe;
  font-size: 10px;
  color: #8ea7c5;
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
  max-height: 20px;
  border: 1px solid #bbcada;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingHistoryImg {
  margin: 20px auto;
}

.loadingHistoryContainer {
  min-width: 100%;

  display: flex;
  justify-self: center;
}

.noChangesTxt {
  font-size: 14px;
  color: #859dbb;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
  min-width: 100%;
  padding-left: 5%;
  padding-right: 14%;
}

.noChangesContainer {
  min-width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.backAndDeleteShiftContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  flex-direction: row-reverse;
}

.openShiftFooterLeftContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 200px;
}

.convertTilBtn {
  background-color: #9a74eb;
  color: white;
  height: 28px;
  line-height: 25px;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 100px;
  font-size: 0.88em;
  font-weight: 500;

  border: 1px solid #592eb6;
  text-align: center;
  -webkit-animation-name: fadeModalIn !important;
          animation-name: fadeModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  margin-left: 10px;
  min-width: 76px;
  max-width: 76px;
}

.backFromMgrShift {
  height: 28px;
  border-radius: 100px;
  font-size: 1em;
  font-weight: 500;
  font-size: 0.95em;
  font-weight: 500;
  color: #592eb6;
  text-align: center;

  padding-left: 9px;
  padding-right: 9px;

  display: flex;
  min-height: 28px;
  max-height: 28px;
  background-color: #8997a8;
  border: 1px solid #636f7e;
  align-items: center;
  margin-top: 0px;
  border-radius: 25px;
  justify-content: center;
  cursor: pointer;
  color: white;

  min-width: 64px;
  max-width: 64px;
}

.shiftTools {
  min-height: 28px;
  max-height: 28px;
  min-width: 28px;
  max-width: 28px;
  padding: 4px;
  background-color: #8997a8;
  border: 1px solid #636f7e;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}

.shiftTools:active {
  -webkit-transform: rotate(40deg);
          transform: rotate(40deg);
}

.shiftToolsActive {
  min-height: 28px;
  max-height: 28px;
  min-width: 28px;
  max-width: 28px;
  padding: 4px;
  background-color: #214978;
  border: 1px solid #143051;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}

.shiftToolsActive:active {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.shiftHistoryTitle {
  font-size: 12px;
  color: #214978;
  font-weight: 400;
  margin-top: 12px;
  line-height:18px;
  padding-right:50px;
  padding-bottom:20px;
  padding-top:10px;
}

.reqsToChangeTitle {
  margin-top: 4px !important;
}

.editShiftRequestModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);


}

.editShiftRequestModalBox {
  background-color: #f4f8fe;
  min-height: 300px;
  min-width: 320px;
  max-width: 320px;
  margin: 0 auto;
  border-radius: 10px;
  /* -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */

  -webkit-animation-name: zoomModalIn !important;

          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  cursor: default;


}

.editMyShiftModalHeaderContainer {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  padding-left: 6%;
  padding-right: 5%;
  padding-bottom: 20px;
  margin-top: 2px;
  padding-top: 18px;
}

.toilHeader {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  padding-bottom: 20px;
  margin-top: 16px;
  align-items: center;
  padding-right: 24px;
  padding-left: 18px;
}

.marginTop6px {
  margin-top: 6px;
}

.editMyShiftCloseHolder {
  min-width: 54px;
  max-width: 54px;
}

.editMyShiftRow {
  display: flex;
  justify-content: space-between;
  max-width: 90%;
  margin: 0 auto;
  padding-left: 5px;
  padding-right: 5px;
  min-height: 50px;
  align-items: center;
  border-bottom: 1px solid #e0ebf8;
}

.noEditShiftBorder {
  border: none;
  align-items: flex-start;
}

.editMyShiftNoteTitle {
  margin-top: 17px;
}

.editMyShiftRowTitle {
  color: #214978;
  font-weight: 400;
  font-size: 12px;
}

.editMyShiftFooter {
  min-width: 100%;
  min-height: 53px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-left: 4%;
  padding-right: 4%;
}

.editMyShiftFooterSideUnit {
  min-width: 60px;
  color: #143051;
  padding-right: 12px;
}

.requestEditMyShiftBtn {
  background-color: #07f;
  border: #0469de 1px solid;
  color: white;
  min-height: 35px;
  line-height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
  border-radius: 5px;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.requestEditMyShiftBtn:hover {
  background-color: #0469de;
}

.closeEditMyShiftModalTxt {
  font-size: 0.9em;
  color: #496375;
  font-weight: 500;
  cursor: pointer;
  /* margin-right: 8px; */
}

.closeToilBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  max-width: 62px;
  min-width: 62px;
}

.closeToilBtn:hover {
  background-color: #214978;
}

.breakMinsSpanEditShift {
  font-size: 0.8em;
}

.EditMyShiftNoteTextBox {
  min-width: 84%;
  min-height: 80px;
  overflow: auto;
  resize: none;
  padding: 5px;
  border-radius: 5px;
  background-color: #fff;
  color: #516b8b;
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  overflow: auto;
  letter-spacing: 0px;
  padding: 10px;
  border: 2px solid #dde9f9;
}

.editMyShiftTypeDropdown {
  color: #fff;
  border-radius: 15px;
  font-size: 1em;
  font-weight: 500;
  padding-left: 14px;
  text-align: left;
  max-width: 148px;
  line-height: 26px;
  font-weight: 500;
  height: 30px;
  background: url(/static/media/downArrowWhite2.887d57ce.svg) no-repeat right #8ea7c5;

  border: 1px solid #496375;
  -webkit-appearance: none;
  background-position-x: 110px;
}

.blueLoaderEditMyShiftImg {
  max-width: 48px;
  /* padding-left: 52px; */
  /* padding-right: 52px; */
}

.submitContainerEditMyShift {
  min-height: 35px;
  max-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-size: 0.9em; */
  /* padding-left: 5px; */
  /* padding-right: 5px; */
}

.fadeSubmitMyShiftChange {
  opacity: 0.5;
  pointer-events: none;
}

.cancelShiftChangeRequestTxtContainer {
  min-width: 80px;
}

.cancelReqTxt {
  font-weight: 500;
  color: #4b1caf;
}

.showChangesBtnActive {
  font-size: 0.95em;
  margin-top: 8px;
  color: #fff;
  background-color: #8ea7c5;
  border: 1px solid #6987a9;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  min-width: 92px;

  padding-left: 9px;
  padding-right: 10px;
  font-weight: 500;
  margin-right: 10px;

  cursor: pointer;
}

.showChangesBtnActive:hover {
  background-color: #7e93ac;
}

.makeFilterInvisAndInactive {
  opacity: 0.2;
  pointer-events: none;
}

.maxW58px {
  max-width: 58px !important;
}

.closeChangesImgTilShifts {
  max-width: 9px;
  margin-left: 6px;
  margin-top: 2px;
}

.myShiftChangesContainer {
  background-color: #ebf0f7;

  min-height: calc(100vh - 270px);
  max-height: calc(100vh - 270px);
  overflow: auto;
  position: fixed;
  min-width: 100%;

  padding-top: 8px;
  padding-bottom: 68px;
}

.shiftChangeRow {
  background-color: #fafeff;
  border-radius: 5px;

  max-width: 90%;
  min-width: 90%;

  margin: 0 auto;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 12px;

  margin-bottom: 12px;
  margin-top: 24px;
border:1px solid #dee5f0;
  background-color: #fff;
  /* cursor: pointer; */
  /* animation-name: zoomModalIn !important; */
  /* animation-duration: 0.1s !important; */
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
}

.shiftChangeRow:hover {
  /* border-color:#bbcada; */
}

.shiftChangeRow:first-of-type {
  margin-top: 14px;
}

.changeRowLeft {
  background-color: white;

  min-height: 90px;
  max-height: 90px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding-left: 14px;
  padding-top: 7px;
  z-index: 100;
  padding-bottom: 7px;
  margin-left: 6px;

  max-width: 130px !important;
  min-width: 130px !important;

  /* border-radius: 5px; */
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */

  padding-left: 5px;
  padding-right:5px;
  margin-top: 10px;
  margin-bottom: 12px;
  cursor: pointer;
  background-color: white;
  border-radius: 4px 5px 5px 4px;
  border: 2px solid #e0ebf8;
}

.changeRowLeft:hover {
  border-color:#CCD8EA
}

.changeRowRight {
  min-height: 90px;
  max-height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  padding-right: 14px;
  padding-top: 7px;
  z-index: 100;
  padding-bottom: 7px;
  margin-right: 6px;
  max-width: 130px !important;
  min-width: 130px !important;
  /* border-radius: 5px; */
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 10px;
  margin-bottom: 12px;
  cursor: pointer;
  background-color: white;
  border-radius: 5px 4px 4px 5px;
  border: 2px solid #e0ebf8;
}

.changeRowRight:hover {
border-color:#CCD8EA;
}

.oldShiftDate {
  font-size: 12px;
  font-weight: 500;
  color: #496375;
  text-align: right;
  padding-bottom: 6px;
  border-bottom: 1px solid #dde9f9;
  min-width: 100%;
  padding-left:7px;
  padding-right:7px;
}

.oldShiftTimes {
  font-size: 12px;
  font-weight: 500;
  color: #8ea7c5;
  margin-top: 2px;
  padding-bottom: 6px;
  padding-top: 1px;
  border-bottom: 1px solid #dde9f9;
  /* min-width: 100%; */
  text-align: right;
  padding-left:7px;
  padding-right:7px;

}

.oldShiftBrk {
  font-size: 12px;
  font-weight: 500;
  color: #acc3e1;
  padding-left:7px;
  padding-right:7px;

}

.shiftDurationTopRight {
  min-height: 80px;
  margin-top: -5px;
  min-width: 33%;
  max-width: 33%;
}

.editShiftDurationTopRightTxt {
  min-width: 90px;
}

.payableShiftDur {
  font-size: 12px;
  margin-top: -31px;
  text-align: right;
  color: #8ea7c5;
  font-weight: 500;
  opacity: 0.5;
}

.tilNewShiftTime {
  font-size: 12px;
  font-weight: 500;
  color: #acc3e1;
  padding-left: 7px;
  padding-right: 7px;
}

.useTilMinsSpan {
  color: #cce3ff;
}

.highlightedChangeElement {
  font-weight: 500;
  color: #0077ff;
  /* padding-left: 6px; */
  line-height: 13px;
  padding-top: 1px;
  text-align: right;
}

.declinedChange {
  opacity: 1;
}

.shiftChangeTitleRow {
  display: flex;
  min-width: 100%;

  padding-left: 3%;
  padding-right: 3%;
  justify-content: space-between;
  align-items: center;
}

.changeRowMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 110px;
  max-height: 110px;
  background-color: #fcfdff;

  padding-left: 3%;
  padding-right: 3%;
  border-top: 1px solid #e3ebf5;
}

.shiftChangeType {
  font-size: 12px;
  padding-top: 0px;
  padding-bottom: 3px;
  color: #516b8b;
  text-align: left;
  line-height: 16px;
  padding-left: 5px;
  padding-right: 14px;
  font-weight: 500;
  color: #516b8b;
  margin-bottom: 3px;
  margin-top: 8px;
}

.changeStatus {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: center;
  display: none;
  max-height: 24px;
  min-height: 24px;

  align-items: center;
  /* border-radius: 5px; */
  font-weight: 500;
  font-size: 12px;
  padding-top: 0px;
  padding-bottom: 2px;
  margin-top: 0px;
  margin-bottom: 8px;

  color: #bbcada;
  border-right: #bbcada 2px solid;
}

.changeStatusApproved {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  /* border-radius: 5px; */

  color: #b6daa8;
  border-right: #b6daa8 2px solid;
  font-weight: 500;
  font-size: 12px;
  padding-top: 0px;
  padding-bottom: 2px;

  margin-top: 0px;
  margin-bottom: 8px;
}

.changeStatusDeclined {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  /* border-radius: 5px; */
  color: #ba0000;
  border-right: #ba0000 2px solid;
  font-weight: 500;
  font-size: 12px;
  padding-top: 0px;
  padding-bottom: 2px;
  margin-top: 2px;
  margin-bottom: 8px;
  opacity: 0.5;
}

.changeStatusInvis {
  visibility: hidden;
  pointer-events: none;
}

.changeTitleRowSubBar {
  display: flex;
  justify-content: space-between;
  padding-left: 3%;
  padding-right: 3%;
  min-height: 30px;
  align-items: center;
}

.shiftChangeMadeDate {
  font-size: 10px;
  color: #bbcada;
  font-weight: 500;
  margin-left: 5px;
  margin-top: -24px;
  /* border-left: 2px solid #bbcada; */
  /* padding-left: 8px; */
  padding-top: 8px;
  display: flex; align-items: center;
}

.shiftTypeSpan {
  /* font-weight: 500; */
  /* color: #90a7c4; */
}

.declinedChangeSpan {
  /* font-weight: 500; */
  /* color: #ba0000; */
}

.approvedChangeSpan {
  /* font-weight: 500; */
  /* color: #6db451; */
}

.changeShiftArrowImg {
  /* opacity: 0.7; */
}

.deleteChangeUnit {
  color: white;
  z-index: -1;

  margin: 0 auto;

  min-height: 50px;
  display: flex;
  max-width: 100%;
  padding-left: 5%;
  padding-right: 3%;
  justify-content: space-between;
  align-items: center;
  padding-top: 0px;
  background-color: #fff;
  cursor: default;
  border-top: 1px solid #e3ebf5;
}

.viewChangeUnitShift {
  background-color: #0077ff;
  border: 1px solid #214978;
  color: white;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 2px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectedShiftChangeRow {
  border-color: #6a8bb1;
}

.deleteChangeUnitLeft {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  margin-left: -4px;

  min-height: 32px;
  color: #bbcada;
}

.oldDurationTitleSpan {
  /* font-weight: 500; */
  padding-right: 6px;
  color: #516b8b;
  font-size: 12px;
}

.deleteAndViewBtns {
  display: flex;
}

.deleteChangeBtn {
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 8px;
  cursor: pointer;
  font-weight: 500;
  border: solid 1px #e0ebf8;
  background-color: #fff;
  color: #bbcada;
}

.deleteChangeBtn:hover {
  background-color: #f4f8fe;
}

.invisDeleteChangeBtn {
  visibility: hidden;
  pointer-events: none;
}

.cancelChangeLoadingImgContainer {
  /* background-color: #859dbb;
  border: 1px solid #143051;
  color: white;
  border-radius: 5px;
  min-height: 26px;
  line-height: 26px;
  font-size: 0.9em;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 8px;
  cursor: pointer;
  font-weight: 500;
  min-width: 71px;
  box-shadow: inset 0px 2px 0px rgb(92, 121, 153);
  opacity: 0.8; */
}

.cancelChangeLoaderImg {
  max-width: 40px;
  max-height: 12px;
  margin-top: 6px;
  margin-bottom: -1px;
}

.requestsBtnEmpty {
  font-size: 14px;
  margin-top: 8px;

  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;

  padding-left: 10px;
  font-weight: 500;
  padding-right: 10px;

  margin-right: 11px;
  cursor: pointer;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.requestsBtnEmpty:hover {
  background-color: #bbcada;
}

.reqOnSpan {
  margin-left: 4px;
  margin-right: 5px;
}

.decidedBySpan {
  font-weight: 500;
  color: #bbcada;
  /* padding-right: 3px; */
}

.closeShiftBtnCrossImg {
  max-width: 9px;
  min-width: 9px;
  margin-left: 4px;
  margin-right: -7px;
}

.shiftRequestsKeyContainer {
  display: flex;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;

  align-items: center;
  padding-top: 8px;

  border-right: 1px solid #e0ebf8;
}

.shiftReqMonthSelectDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 10px;
  text-align: left !important;
  max-width: 83px;
  min-width: 83px;
  line-height: 16px;
  margin-top: 2px;
  height: 35px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
    #496375;
  background-color: white;
  color: #496375;
  border: 2px solid #e6eaee;
  -webkit-appearance: none;
  background-position-x: 61px;
  display: flex;
  cursor: pointer;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}
.shiftReqMonthSelectDropdown:hover {
  border-color: #d7dfe9;
}
.outstandingShiftReqsBtn {
  font-size: 14px;

  color: #fff;
  /* border:1px solid #143051; */

  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  line-height: 22px;

  font-weight: 500;
  /* background-color: #214978; */

  cursor: pointer;
  padding-left: 6px;
  padding-right: 7px;
  margin-top: 4px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  display: flex;
  align-items: center;
  max-width: 69px !important;
  min-width: 69px !important;

  border: 1px solid #1e88e5 !important;
  background-color: #0af !important;
  border-left: 4px solid #1e88e5 !important;
}

.outstandingShiftReqsBtn:hover {
  background-color: #1e88e5 !important;
}

.amendmentsNumberInOutstanding {
  margin-top: 0px;
  margin-left: 4px;
  border: 1px solid #0077ff;
  background-color: #dde9f9;
  line-height: 18px;
  border-radius: 3px;
  padding: 0px;
  min-width: 22px;
  font-size: 12px;
  color: #0077ff;
}

.outstandingShiftReqsBtnActive {
  font-size: 14px;
  color: #fff;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  line-height: 22px;
  font-weight: 500;

  cursor: pointer;
  padding-left: 7px;
  padding-right: 9px;
  margin-top: 4px;
  padding-top: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: -webkit-fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  max-width: -webkit-fit-content !important;
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;

  -webkit-animation-name: zoomModalIn !important;

          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  border: 1px solid #143051 !important;
  background-color: #214978 !important;
  border-left: 3px solid #143051 !important;
}
.outstandingShiftReqsBtnActive:hover {
  background-color: #143051 !important;
}

.shiftReqsPage {
  background-color: #ebf0f7;
  min-height: calc(100vh - 208px);
}

.whiteCrossCloseOutstandingShiftRequests {
  margin-left: 6px;
  margin-right: 2px;
  max-width: 9px;
}

.fadeMonthSelect {
  opacity: 0.15;
}

.shiftReqsContainer {
  overflow: auto;
  min-height: calc(100vh - 259px);
  max-height: calc(100vh - 259px);
  padding-bottom: 20px;
}

.shiftReqsContainerMob {
  min-height: calc(100vh - 349px);
  max-height: calc(100vh - 349px);
  padding-bottom: 30px;
}

.shiftRequestsContainerSplitterLineWrapper {
  min-width: 100%;
  border-right: 1px solid #e0ebf8;
  min-height: 8px;
}

.shiftRequestsContainerSplitterLine {
  min-height: 1px;

  min-width: 90%;
  max-width: 90%;
  margin: 8px auto;
  margin-bottom: 0px;
  border-bottom: 1px solid #e6eaee;
}

.shiftReqMonthSelectContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shiftReqNamesDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 145px;
  min-width: 145px;
  margin-top: 2px;
  line-height: 16px;
  height: 35px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #496375;
  background-color: white;
  color: #143051;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  background-position-x: 124px;
  display: flex;
  cursor: pointer;
  margin-right: 5px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.teamTsUsersDropper {
  margin-top: 8px;
}

.shiftReqNamesDropdown:hover {
  border-color: #d7dfe9;
}

.decisionSubBar {
  background-color: #687d97;
  border: 1px solid #143051;
  border-top: none;
  background: rgb(88, 112, 150);
  background: linear-gradient(
    0deg,
    rgb(69, 100, 150) 0%,
    rgb(69, 100, 150) 90%,
    rgb(43, 56, 77) 100%
  );
  max-width: 86%;
  min-width: 86%;
  color: white;
  margin: 0 auto;
  display: flex;
  max-height: 50px;
  min-height: 50px;
  padding-top: 5px;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 0px 0px 10px 10px;
  font-size: 0.9em;
  font-weight: 500;
}

.oldChangeUnitDur {
  font-weight: 500;
}

.decisionRequestShiftDiv {
  /* animation-name: slideSubBarDown !important;
  animation-duration: 0.1s !important;
  animation-delay: 0.2s; */
}

.decisionSubBarApproved {
  max-width: 86%;
  min-width: 86%;
  color: #a8cc9a;
  margin: 0 auto;
  display: flex;
  max-height: 40px;
  min-height: 40px;
  padding-top: 18px;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 0px 0px 10px 10px;
  font-size: 0.9em;
  font-weight: 500;

  padding-bottom: 3px;
}

.shiftEditDecidedBy {
  text-align: right;
  color: #5479a3;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.decisionSubBarDeclined {
  max-width: 86%;
  min-width: 86%;
  color: #cc4d4d;
  margin: 0 auto;
  display: flex;
  max-height: 40px;
  min-height: 40px;

  align-items: center;
  justify-content: space-between;
  padding-left: 4px;
  padding-right: 10px;
  border-radius: 0px 0px 10px 10px;
  font-size: 0.9em;
  font-weight: 500;
  margin-top: 14px;
}

.decisionSubRight {
  display: flex;
  min-width: 150px;
  justify-content: space-between;
}

.viewShiftEditSchedule {
  background-color: #859dbb;
  border: 1px solid #acc3e1;
  color: white;
  border-radius: 30px;
  min-height: 30px;
  max-height: 30px;
  line-height: 26px;
  font-size: 0.9em;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-right: 8px;
  cursor: pointer;
  font-weight: 500;
  margin-top: 3px;
  margin-left: 4px;
  box-shadow: inset 0px 2px 0px rgb(92 121 153);
}

/* .approveShiftEdit {
  background-color: #6dba4f;
  border: 1px solid #acc3e1;
  color: white;
  border-radius: 5px;
  min-height: 26px;
  line-height: 25px;
  font-size: 0.9em;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  font-weight: 500;
  box-shadow: inset 0px 2px 0px #5a9e3f;
}

.approveShiftEdit:active {
  transform: scale(0.96);
}

.declineShiftEdit {
  background-color: #cc4d4d;
  border: 1px solid #acc3e1;
  color: white;
  border-radius: 5px;
  min-height: 26px;
  line-height: 25px;
  font-size: 0.9em;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 8px;
  cursor: pointer;
  font-weight: 500;
  box-shadow: inset 0px 2px 0px #ba0000;
}
.declineShiftEdit:active {
  transform: scale(0.96);
} */

.viewShiftEditSchedule:active {
  -webkit-transform: scale(0.96);
          transform: scale(0.96);
}

.undoSchedEditBtn {
  background-color: #8997a8;
  border: 1px solid #636f7e;
  color: white;
  border-radius: 5px;
  min-height: 26px;

  font-size: 0.9em;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.undoSchedEditBtn:active {
  -webkit-transform: scale(0.96);
          transform: scale(0.96);
}

.approveLoaderImg {
  max-width: 40px;
  min-width: 40px;
  max-height: 14px;
  margin-top: 8px;
  margin-right: 8px;
}

.compareCloseHolder {
  min-width: 50px;
}

.blankSpaceTopper {
  display: flex;

  max-height: 10px;
}

.proposedShiftChangeModalTitleTxt {
  text-align: center;

  min-width: 140px;

  font-size: 1.2em;
  font-weight: 500 !important;
}

.proposeCompareShiftsBtn {
  background-color: #859dbb;
  border: 1px solid #acc3e1;
  color: white;
  border-radius: 5px;
  min-height: 26px;
  line-height: 25px;
  font-size: 0.9em;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  font-weight: 500;
  box-shadow: inset 0px 2px 0px rgb(92 121 153);
}

.compareParentContainer {
  min-width: 100%;
  max-width: 100%;
  -webkit-animation-name: fadeModalIn !important;
          animation-name: fadeModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.narrowAsItTTR {
}

.compareDateRowContainer {
  min-height: 70px;
  min-width: 100%;
  display: flex;
  justify-content: space-between;
}

.compareDateRow1 {
  min-width: 20%;
  max-width: 20%;
  min-height: 50px;
}

.compareDateRow2 {
  min-width: 40%;
  max-width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 70px;
  max-height: 70px;
  font-size: 1.2em;
  color: #496375;
  border-right: 1px solid #e0ebf8;
}

.originalTitleTxt {
  font-weight: 500;
}

.originalDateTxt {
  margin-top: -28px;
  font-size: 0.7em;
  color: #8ea7c5;
}

.compareDateRow3 {
  min-width: 40%;
  max-width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 70px;
  max-height: 70px;
  font-size: 1.2em;
  color: #4b1caf;
}

.proposedTitleTxt {
  font-weight: 500;
}

.proposedDateTxt {
  margin-top: -28px;
  font-size: 0.7em;
  color: #9a74eb;
}

.generateComparesContainer {
  max-height: 250px;
  min-height: 250px;
  border-top: 1px solid #8ea7c5;
  border-bottom: 1px solid #8ea7c5;

  overflow: auto;
}
.compareDateUserRow {
  background-color: white;
  min-height: 70px;
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px #e0ebf8 solid;
}

.compareDateUserRow:nth-child(even) {
}

.compareDateUserRow1 {
  min-width: 20%;
  max-width: 20%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.compareDateUserRow2 {
  min-width: 40%;
  max-width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 70px;
  max-height: 70px;
  font-size: 1.2em;
  color: #496375;
}

.dayOffItem {
  min-width: 40%;
  max-width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 70px;
  max-height: 70px;
  font-size: 0.8em;
  font-weight: 400;
  color: #bac5d3;
  padding-right: 14px;
}

.compareDateUserRow2Three {
  min-width: 40%;
  max-width: 40%;
  display: flex;

  justify-content: space-around;
  align-items: center;
  min-height: 70px;
  max-height: 70px;
  font-size: 1.2em;
  color: #496375;
}

.compareDateUserRow2Four {
  min-width: 40%;
  max-width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-height: 70px;
  max-height: 70px;

  font-size: 1.2em;
  color: #496375;
  overflow: auto;
}

.fourItemRow {
  max-height: 25%;
  min-height: 25%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 8px;
  padding-left: 5px;
}

.fourItemRowProposed {
  max-height: 25%;
  min-height: 25%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 3px;
  padding-left: 3px;
  padding-top: 1px;
  margin-right: 4px;
  background-color: #0077ff;
  border: 1px solid #143051;
  border-radius: 3px;
  font-weight: 500;
  margin-left: 2px;
}

.fourItemRowThis {
  max-height: 25%;
  min-height: 25%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 3px;
  padding-left: 3px;
  padding-top: 1px;
  margin-right: 4px;
  background-color: #e0ebf8;
  border: 1px solid #859dbb;
  border-radius: 3px;
  font-weight: 500;
}

.fourItemTimesString {
  color: #143051;

  font-size: 0.525em;
}

.fourItemBrkString {
  color: #859dbb;

  font-size: 0.45em;
}

.compareDateUserRow2twoUnits {
  min-width: 40%;
  max-width: 40%;
  display: flex;

  justify-content: space-around;
  align-items: center;
  min-height: 70px;
  max-height: 70px;
  font-size: 1.2em;
  color: #496375;

  padding-left: 3px;
  padding-right: 6px;
}

.compareDateUserRow2twoUnitsProposed {
  min-width: 40%;
  max-width: 40%;
  display: flex;

  justify-content: space-around;
  align-items: center;
  min-height: 70px;
  max-height: 70px;
  font-size: 1.2em;
  color: #496375;

  padding-left: 3px;
  padding-right: 6px;
}

.compareSplitter {
  min-width: 1px;
  max-width: 1px;
  min-height: 100%;
  background-color: #bdc9d6;
  margin-left: -1px;
}

.compareSplitterWhenTtr {
  min-width: 1px;
  max-width: 1px;
  min-height: 100%;
  background-color: #fcf6fd;
  margin-left: -1px;
}

.twoOriginalItemsUnit {
  border-right: 1px #e0ebf8 solid;

  max-height: 68px;
  min-height: 68px;
  margin-left: 2px;
  margin-right: 2px;
  min-width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.threeOriginalItemsUnit {
  border-right: 1px #e0ebf8 solid;

  max-height: 68px;
  min-height: 68px;
  margin-left: 2px;
  margin-right: 2px;
  min-width: 30%;
  max-width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-right: 3px;
}

.threeOriginalItemsUnitProposed {
  background-color: #0077ff;

  border-radius: 10px;
  border: 3px solid #143051;
  max-height: 68px;
  min-height: 68px;
  margin-left: 2px;
  margin-right: 2px;
  min-width: 30%;
  max-width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-right: 3px;
}

.threeOriginalItemsUnit:last-of-type {
  border: none;
}

.threeOriginalItemsUnitThis {
  background-color: #fff;
  background-color: #e0ebf8;
  border: 3px solid #859dbb;
  border-radius: 10px;

  max-height: 68px;
  min-height: 68px;
  margin-left: 2px;
  margin-right: 2px;
  min-width: 32%;
  max-width: 32%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.twoOriginalItemsUnitThis {
  background-color: #fff;
  background-color: #e0ebf8;
  border: 3px solid #859dbb;
  border-radius: 10px;

  max-height: 68px;
  min-height: 68px;
  margin-left: 2px;
  margin-right: 2px;
  min-width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.twoOriginalItemsUnitThisProposed {
  background-color: #0077ff;

  border: 3px solid #143051;
  border-radius: 10px;

  max-height: 68px;
  min-height: 68px;
  margin-left: 2px;
  margin-right: 2px;
  min-width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.twoOriginalItemsUnit:last-of-type {
  border: none;
}

.twoOriginalTimeStart {
  font-size: 0.7em;
  font-weight: 500;
  line-height: 20px;
  min-height: 20px;
  max-height: 20px;
  margin-top: 3px;
  text-align: center;
  color: #143051;
}

.twoOriginalTimeEnd {
  font-size: 0.7em;
  font-weight: 500;
  line-height: 20px;
  min-height: 20px;
  max-height: 20px;
  text-align: center;
  margin-top: -4px;
  color: #143051;
}
.twoOriginalDash {
  font-size: 0.8em;
  font-weight: 500;
  line-height: 1px;
  min-height: 1px;
  max-height: 1px;
  text-align: center;
  margin-top: -3px;
  color: #143051;
}

.twoOriginalTimeBrk {
  font-size: 0.6em;
  font-weight: 400;
  line-height: 20px;
  margin-top: -5px;
  min-height: 20px;
  max-height: 20px;
  text-align: center;
  color: #859dbb;
}

.twoOriginalTimeStartThree {
  font-size: 0.6em;
  font-weight: 500;
  line-height: 20px;
  min-height: 20px;
  max-height: 20px;
  margin-top: 3px;
  text-align: center;
  color: #143051;
}

.twoOriginalTimeEndThree {
  font-size: 0.6em;
  font-weight: 500;
  line-height: 20px;
  min-height: 20px;
  max-height: 20px;
  text-align: center;
  margin-top: -4px;
  color: #143051;
}
.twoOriginalDashThree {
  font-size: 0.6em;
  font-weight: 500;
  line-height: 1px;
  min-height: 1px;
  max-height: 1px;
  text-align: center;
  margin-top: -3px;
  color: #143051;
}

.twoOriginalTimeBrkThree {
  font-size: 0.5em;
  font-weight: 400;
  line-height: 20px;
  margin-top: -5px;
  min-height: 20px;
  max-height: 20px;
  text-align: center;
  color: #859dbb;
}
.originalShiftBubble {
  display: flex;
  flex-direction: column;

  font-size: 0.9em;
  max-height: 55px;
  min-height: 55px;

  padding-bottom: 4px;
  padding-left: 4px;
  padding-right: 4px;

  margin-top: 5px;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
}

.originalShiftBubbleThisUser {
  background-color: #e0ebf8;

  display: flex;
  flex-direction: column;

  font-size: 0.9em;
  max-height: 55px;
  min-height: 55px;

  padding-bottom: 4px;
  padding-left: 4px;
  padding-right: 4px;
  border: 3px solid #859dbb;

  border-radius: 10px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
}

.proposedShiftBubbleThisUser {
  background-color: #0077ff;
  display: flex;
  flex-direction: column;

  font-size: 0.9em;
  max-height: 55px;
  min-height: 55px;

  padding-bottom: 4px;
  padding-left: 4px;
  padding-right: 4px;
  border: 3px solid #143051;

  border-radius: 10px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  margin-left: -5px;
}

.originalCompareTimes {
  line-height: 26px;
  font-weight: 500;
  color: #143051;
  min-height: 22px;
  font-size: 0.9em;
}

.originalCompareTimesThisUser {
  line-height: 26px;
  font-weight: 500;
  color: #859dbb;
  min-height: 22px;
  font-size: 0.84em;
}

.proposedCompareTimesThisUser {
  line-height: 26px;
  font-weight: 500;
  color: #fff;
  min-height: 22px;
  font-size: 0.84em;
}

.originalCompareBreak {
  color: #859dbb;
  line-height: 18px;
  min-height: 16px;
  font-size: 0.7em;
  margin-top: -5px;
}

.originalCompareBreakThisUser {
  color: #859dbb;
  line-height: 18px;
  min-height: 16px;
  font-size: 0.7em;
  margin-top: -5px;
}

.proposedCompareBreakThisUser {
  color: #fff;
  line-height: 18px;
  min-height: 16px;
  font-size: 0.7em;
  margin-top: -5px;
}
.compareDateUserRow3 {
  min-width: 40%;
  max-width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 70px;
  max-height: 70px;
  font-size: 1.2em;
  color: #496375;
}

.compareInitials {
  font-weight: 500;
  background-color: #00ccff;
  max-width: 40px;
  max-height: 40px;
  min-height: 40px;
  min-width: 40px;
  border-radius: 50px;
  color: white;
  font-size: 1.1em;
  text-align: center;
  line-height: 40px;
  text-transform: uppercase;
}

.compareInitialsNotThisUser {
  font-weight: 500;
  background-color: #8997a8;
  max-width: 40px;
  max-height: 40px;
  min-height: 40px;
  min-width: 40px;
  border-radius: 50px;
  color: white;
  font-size: 1.1em;
  text-align: center;
  line-height: 40px;
  text-transform: uppercase;
}

.compareModalUnderlay {
  background: rgba(0, 0, 0, 0.45);

  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 100vw;
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.075s;
          animation-duration: 0.075s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.compareModalBox {
  background-color: #f4f8fe;
  background-color: #fcf6fd;
  color: #143051;
  min-width: 94vw;
  max-width: 94vw;
  border-radius: 15px;
  padding-top: 20px;

  text-align: left;
  padding-left: 5%;
  padding-right: 5%;

  line-height: 45px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  padding-bottom: 20px;
}

.reveralFullName {
  font-size: 0.65em;
  background-color: #acc3e1;
  text-align: center;
  line-height: 12px;
  color: #fff;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 5px;
}



.loadingCompareImgContainer {
  min-height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingCompareImg {
  margin: 0 auto;
}

.takeTilOnShiftBtn {
  display: flex;

  border: 1px solid #636f7e;

  cursor: pointer;
  background-color: #abb9dc;
  max-height: 28px;
  min-height: 28px;
  text-align: center;
  line-height: 23px;
  color: white;
}

.takeTilBtnContainer {
  display: flex;
  min-height: 35px;
  max-height: 35px;
  align-items: center;
  margin-top: 0px;
  border-radius: 5px;
  justify-content: center;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  cursor: pointer;
  margin-left:5px;
}

.takeTilBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  min-width: 66px;
  padding-top: 2px;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: -1px;
  color: #fff;
  border: solid 1px #0af;
  background-color: #0bf;
}
.takeTilBtn:hover {
  background-color: #0af;
}
.overtimeHTiLDisable {
  visibility: hidden;
  pointer-events: none;
}

.none {
  display: none;
}

.conflicted {
  color: #cc4d4d;
}

.conflictWarnTxt {
  background-color: #e26666;
  border: 1px #ba0000 solid;
  color: white;
  margin: 0 auto;
  max-width: 65px;
  min-width: 65px;
  font-size: 0.8em;
  text-align: center;
  border-radius: 30px;
  max-height: 22px;
  min-height: 22px;
  line-height: 17px;

  margin-top: 12px;
  font-weight: 500;
}

.takeTilDsTxt {
  color: #8ea7c5;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
}

.whenTilDropdown {
  color: #fff;
  border-radius: 15px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 88px;
  min-width: 88px;
  line-height: 26px;
  height: 30px;
  background-color: #8ea7c5;
  background: url(/static/media/downArrowWhite2.887d57ce.svg) no-repeat right
    #8ea7c5;
  border: 1px solid #496375;
  -webkit-appearance: none;
  background-position-x: 64px;
  display: flex;
  cursor: pointer;
}

.boldTrimSpan {
  font-weight: 500;
  color: #0077ff;
}

.tilChangePreviewHeader {
  display: flex;

  justify-content: space-around;
  min-height: 76px !important;
  align-items: center;
  margin-top: -8px;
  border-bottom: 1px solid #e0ebf8;
  max-width: 90%;
  margin-left: 5%;
  margin-bottom: 10px;
}
.tilChangePreviewHeaderWhole {
  display: flex;

  justify-content: space-around;
  min-height: 60px !important;
  align-items: center;
  margin-top: -8px;
  min-width: 180px;
}
.originalTilShiftTxt {
  text-align: center;
  /* color: rgb(128, 148, 192); */
  font-size: 14px;
  border: 1px solid #dde9f9;
  color: #143051;
  font-weight: 500;
  /* background-color: #b7c9d6; */
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  /* max-width: 155px; */
  border-radius: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  max-height: 35px;
  align-items: center;
  /* display: flex; */
  /* justify-content: space-between; */
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  line-height: 22px;
}

.wholeOff {
  text-align: center;
  border-left: 2px solid #00aaff;
  font-size: 14px;
  border: 1px solid #dde9f9;
  color: #214978;
  font-weight: 500;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  border-radius: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  min-height: 32px;
  max-height: 32px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  border-left: 2px solid #00aaff;
  margin-top: 14px;
}

.tilInvalidTime {
  /* border: #ba0000 2px solid !important */
  color: #ba0000;
}

.shiftChangeArrowTil {
  margin-left: -14px;
  margin-right: -14px;
}

.splitTilShiftPreview {
}

.tilPreviewShiftContainer {
  display: flex;
  min-width: 150px;
  max-width: 150px;
  min-height: 20px;

  justify-content: center;
}

.tilPreviewShiftContainerWhole {
  display: flex;
  min-width: 180px;
  max-width: 180px;
  min-height: 20px;

  justify-content: center;
}

.notSplitTilShiftPreview {
  display: flex;
  justify-content: center;
  min-width: 110px;
  max-width: 110px;
}

.splitPreviewTilShift {
  text-align: center;
  /* color: rgb(128, 148, 192); */
  font-size: 14px;
  border: 1px solid #dde9f9;
  color: #143051;
  font-weight: 500;
  /* background-color: #b7c9d6; */
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  /* max-width: 155px; */
  border-radius: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  max-height: 35px;
  align-items: center;
  /* display: flex; */
  /* justify-content: space-between; */
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 22px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.toilRowTitle {
  color: #214978;
  font-weight: 400;
  font-size: 12px;
  text-align: left;
}

.firstBarTil {
  display: flex;
  justify-content: space-between;
  max-width: 90%;
  margin: 0 auto;
  padding-left: 5px;
  padding-right: 5px;
  min-height: 2px;
  align-items: center;
  border-bottom: 1px solid #dde9f9;
  margin-top: 8px;
}

.ghostModalBox {
  min-height: 100px;
  max-width: 300px;
  min-width: 300px;
  padding: 20px;
  border-radius: 10px;
  margin: 0 auto;
  padding-top: 25px;
  justify-content: space-between;
  background-color: white;
  color: #143051;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  cursor: default;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.closeGhostModalBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  margin-top: 20px;
}

.closeGhostModalBtn:hover {
  background-color: #214978;
}

.takeTilReqUnit {
  min-width: 90%;
  max-width: 90%;
  min-height: 50px;
  background-color: #cce3ff;
  border-radius: 7px;
  margin-top: 8px;
  margin: 0 auto;
  margin-top: 8px;
}

.shiftChangeTitleTilOrChange {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;

  color: #496375;
  font-weight: 500;
  font-size: 12px;
  padding-top: 0px;
  padding-bottom: 2px;
  background: rgb(242, 242, 242);
  /* margin-top: 8px; */
  /* margin-left: 12px; */
  /* margin-bottom: 8px; */
}

.highlighedTilTimes {
  font-weight: 500;
  color: #0077ff;

  padding-left: 6px;
  line-height: 13px;
  padding-right: 6px;
}

.smallerTilTwoShifts {
  font-size: 0.65em;
  min-height: 16px;

  max-width: 80px;
  line-height: 11px;
  margin-top: 2px;
  text-align: right;
}

.useTilTxt {
  color: #8ea7c5;
}

.tilTimesStrip {
  display: flex;

  max-height: 16px;
  padding-top: 2px;
  padding-bottom: 2px;
  min-height: 26px;
}

.tilStripTitle {
  font-size: 11px;
  font-weight: 500;
  color: #8ea7c5;
  /* margin-right: 4px; */
  margin-top: 2px;
}

.originalShiftTimeSpanValue {
  color: #496375;
  font-weight: 500;
  margin-left: 4px;
}
.myShiftTimesRowOriginalRow {
  max-height: 28px;
  margin-top: 3px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -4px;
}

.originalShiftTimeSpan {
  color: #fff;
}

.tilStatusBubble {
  color: white;
  font-size: 0.7em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  max-height: 22px;
}

.tilApprovedBg {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;
  /* border: 1px solid #7fbb67; */
  color: #a8cc9a;
  font-weight: 500;
  font-size: 0.65em;
  padding-top: 2px;
  padding-bottom: 2px;
  /* background: #a8cc9a; */
  margin-top: 4px;
  margin-bottom: 2px;
}

.tilDeclinedBg {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #c98888;
  color: #fff;
  font-weight: 500;
  font-size: 0.65em;
  padding-top: 2px;
  padding-bottom: 2px;
  background: #e89292;
  margin-top: 2px;
}

.tilRequestedBg {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;

  color: #496375;
  font-weight: 500;
  font-size: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  background: rgb(247, 250, 255);
  background-color: #f4f8fe;
  color: #4f566b;
  border: 1px solid #bbcada;
  /* border-left: 3px solid #bbcada; */
}

.originalShiftTimeSpan {
  color: #859dbb;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0px;

  padding-top: 5px;
  max-height: 22px;
  border-top: solid 1px #e0ebf8;
}

.youReqTilSpan {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;

  color: #496375;
  font-weight: 500;
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  background: rgb(247, 250, 255);
  margin-top: 4px;
  background-color: #f4f8fe;
  color: #4f566b;
  border: 1px solid #bbcada;
  text-transform: none;
  /* border-left: 3px solid #bbcada; */
}

.tilMBold {
  font-weight: 500;
  color: #143051;
  padding-left: 5px;
  padding-right: 5px;
}

.myShiftTimesRowFlex {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  padding-right: 2px;
}

.myShiftTimesRow {
  max-width: 130px;
  line-height: 17px;
  /* margin-top: 12px; */
  padding-bottom: 7px;
}

.tilSplitShiftContainer {
  display: flex;
  justify-content: space-between;
  margin-left: 5px;
  font-weight: 500;
  margin-top: 10px;
  max-width: 179px;
}

.x1938197411 {
  margin-top: 12px;
}

.maxWidth172 {
  max-width: 172px;
}

.tilReqTimesRow {
  margin-left: 5px;
  font-weight: 500;
}

.tilABShiftBox {
  min-width: 30px;
  max-width: 30px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -8px;
  margin-left: 2px;
  padding-right: 4px;
}

.tilArow {
  max-width: 24px;
  min-width: 24px;
  display: flex;
  justify-content: center;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #859dbb;
  color: #496375;
  font-weight: 500 !important;
  font-size: 12px;
  background: rgb(247, 250, 255);
  margin-top: 14px;
}

.tilBrow {
  max-width: 24px;
  min-width: 24px;
  display: flex;
  justify-content: center;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #859dbb;
  color: #496375;
  font-weight: 500 !important;
  font-size: 12px;
  background: rgb(247, 250, 255);
  margin-top: 4px;
}

.approved12TilRow {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #7fbb67;
  text-align: center;
  color: #fff;
  font-weight: 500;

  padding-top: 2px;
  padding-bottom: 2px;
  background: #a8cc9a;
  margin-top: 4px !important;
  margin-bottom: 2px;
}

.approved12TilRow:last-child {
  margin-top: 0px;
}

.declined12TilRow {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #e6eaee;
  color: #e89292;
  font-weight: 500;
  padding-top: 2px;
  padding-bottom: 2px;
  background: #FFF;
  margin-top: 10px;
  margin-bottom: 8px;
  border-left: 2px solid #e89292
}

.tilTimePreviewWhenRequested {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;
  color: #496375;
  font-weight: 500;
  font-size: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  /* margin-top: 14px; */
  background-color: #f4f8fe;
  color: #4f566b;
  border: 1px solid #bbcada;
  /* border-left: 3px solid #bbcada; */
}

.blankTilSpaceRight {
  /* max-width: 110px;
  min-width: 110px; */
  max-width: 20px;
  min-width: 20px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 20px;

  font-weight: 500;
  font-size: 0.65em;
  padding-top: 2px;
  padding-bottom: 2px;

  margin-top: 10px;
  margin-right: 4px;
}

.tilDeclinedStrip {
  margin-top: -20px;
  font-size: 12px;
  opacity: 0.3;
  margin-bottom: -14px;
  margin-left: 1px;
}

.fadeTilTimes {
  opacity: 0.4;
  line-height: 18px;
}

.maxW24 {
  max-width: 24px;
}

.cancelTilReqContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 50px;
  padding-left: 2px;
}
.removeTil {
  background-color: #fff;
  border: 1px solid #bbcada;
  border-radius: 5px;
  max-height: 28px;
  color: #bbcada;
  font-size: 0.7em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 12px;
  margin-top: 14px;
  margin-right: 6px;
  cursor: pointer;
  padding-top: 2px;
  /* background-color: #f4f8fe; */
  /* border: 1px solid #bbcada; */
  /* color: #859dbb; */
  min-height: 28px;
}


@media only screen and (min-width: 820px) {
  .removeTil:hover {

    border-color: #859dbb;
  }
}


.removeTilSure {
  background-color: #8ea7c5;
  border: 1px solid #6987a9;
  border-radius: 5px;
  max-height: 24px;
  color: #fff;
  font-size: 0.7em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 7px;
  padding-right: 7px;
  margin-bottom: 12px;
  margin-top: 14px;

  cursor: pointer;
}

.cancelTilImg {
  max-width: 10px;
  margin-right: 4px;
  display: none;
}

.declinedTilSpan {
  background-color: #e26666;
}

.tilAccountTxt {
  font-size: 12px;
  margin-top: -6px;
  max-height: 26px;
  margin-bottom: 10px;
  color: #516b8b;
  font-weight: 500;
  border-left: 2px solid #0cf;
  padding-left: 5px;
  line-height: 20px;
}

.shiftEditTilTimeContainer {
  margin-left: 18px;
  display: flex;
  flex-direction: column;

  font-size: 1.15em;
  font-weight: 500;
  color: #143051;
}

.originalShiftTimesTilBar {
  font-size: 12px;
  margin-bottom: -20px;
  color: #214978;
  font-weight: 400;
  margin-left: 5px;
}

.removeMarginB {
  margin-bottom: -14px;
}

.manageTilStatusTitleApproved {
  margin-top: 5px;
}

.editShiftTilMins {
  color: #bbcada;
  font-size: 12px;
  margin-left: 8px;
  font-weight: 500;
  border: 1px solid #bbcada;
  border-radius: 3px;
  padding-left: 4px;
  padding-right: 4px;
}

.tilTitleBar {
  display: flex;
  justify-content: space-between;
  min-width: 210px;
  margin-bottom: -4px;
}

.tilStatusTitleApproved {
  background-color: #a8cc9a;
  border: 1px solid #7fbb67;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  max-height: 20px;
  margin-top: 12px;
  padding-bottom: 0px;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
}

.tilStatusTitleDeclined {
  color: white;
  max-height: 18px;
  background-color: #e89292;
  border: 1px solid #c98888;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  font-size: 0.65em;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  margin-left: 6px;
}

.tilStatusTitlePending {
  background-color: #e3e8ee;

  color: #4f566b;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 18px;

  margin-top: 12px;
  font-size: 0.65em;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  margin-left: 8px;
}

.tilTimesOnNewTimesStrip {
  max-width: 120px;
  line-height: 28px;
  margin-top: 11px;
  margin-bottom: 8px;
}

.gapBetweenOrigAndEditTimes {
  max-height: 25px;
  min-width: 10px;
}

.contradictsTil {
  background-color: #c98888;
  border-color: #ba0000;
}

.takesAccTilSpan {
  color: #bac5d3;
}

.elipsisAndEditBtnsContainer {
  display: flex;

  min-width: 114px;
  justify-content: space-between;
}

.killWidthOfElipsisEditBtnContainer {
  display: flex;
  min-width: 0px;
}

.editShiftIconContainer {
  min-height: 33px;
  max-height: 33px;
  min-width: 33px;
  max-width: 33px;

  background-color: #8997a8;
  border: 1px solid #636f7e;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
}

.editShiftIconContainer:active {
}

.editShiftBtnIcon {
  min-height: 15px;
  max-height: 15px;
  min-width: 15px;
  max-width: 15px;
  padding-bottom: 1px;
}

.editTilInputsContainer {
  margin-top: 7px;
  display: flex;
  align-items: center;
  min-width: 210px;
  justify-content: space-between;
  margin-bottom: 8px;
}

.editTilUntilSpan {
  font-size: 0.8em;
  padding-left: 2px;
  padding-right: 5px;
}

.historyIconMgrShift {
  min-width: 18px;
  max-width: 18px;
  padding-right: 1px;
}

.historyIconMgrShift:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}
 .editShiftBtnOnManagerShift {
  background-color: #9374d6;
  border-color: #4b1caf;
}

.tilStartClash {
  /* color: #c98888 !important; */
  margin-left: 5px;
  font-weight: 500;
}

.hightlightEditBtn1 {
  background-color: #a387df;
  border-color: #f4f8fe;
}
.hightlightEditBtn2 {
}

.timesAndTagContainer {
  display: flex;
  margin-top: 2px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  min-width: 144px;
}

.pTagTxt {
  color: #fff;
  font-size: 10px;
  text-align: left;
  /* margin-left: 7px; */
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 5px;
  font-weight: 500;
  background-color: #143051;
  max-height: 18px;
  min-height: 18px;
  margin-top: 3px;
  background-color: rgb(247, 250, 255);
  color: #496375;
  padding-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e3e8ee;
  color: #4f566b;
  background-color: #f4f8fe;
  border: 1px solid #e6e6e6;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  margin-left: 10px;
}

.pTagsSplitter {
  min-width: 1px;
  background-color: #e0ebf8;
  min-height: 100%;
  margin-left: 5px;
  margin-right: -4px;
  margin-top: 2px;
}

.mobpTagsSplitter {
  margin-left: 6px;
  margin-right: -6px;
}

.severalTagsTxt {
  color: #fff;
  font-size: 0.7em;
  text-align: left;
  margin-left: 3px;
  padding-left: 1px;
  padding-right: 5px;
  border-radius: 15px;
  font-weight: 500;

  line-height: 17px;
  max-height: 18px;
  margin-top: 5px;

  color: #496375;

  padding-top: 0px;
  line-height: 14px;
}

.teamShiftsDataBubble {
  position: fixed;
  top: calc(100% - 143px);
  right: 40px;

  background: rgb(247, 250, 255);

  color: #496375;
  border-radius: 5px;
  min-width: 50px;
  min-height: 30px;

  max-height: 30px;
  line-height: 45px;
  border: 1px solid #859dbb;

  z-index: 999;
  display: flex;
  align-items: center;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  box-shadow: 2px 2px;
}

.totalDurMinsTxt {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 1em;
  font-weight: 500;
}

.disableAddBtn {
  opacity: 0.1;
  pointer-events: none;
}

.teamShiftUnit {
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  padding-bottom: 3px;
}

.teamShiftNameTab {
  margin-top: -90px;
  margin-bottom: 70px;
  font-size:11px;
}

.tagsAndAbsent {
  margin-bottom: 18px;
}

.noTagsAbsent {
  margin-bottom: 18px;
  min-height:120px !important;
  max-height:120px !important;

}

.subShiftAbsenceContainer {
  display: flex;
  justify-content: flex-end;
  min-width: 100%;
  padding-right: 5%;
}
.subShiftAbsenceHolder {
  background-color: #e89292;
  border: 1px #c98888 solid;
  max-width: 120px;
  color: white;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  min-height: 24px;
  line-height: 24px;
  padding-left: 8px;
  padding-right: 8px;
  position: absolute;
  z-index: 1;
  margin-top: -17px;
  margin-right: 20%;
  margin-right: 24px;
}

.tilDataDiv {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  padding-bottom: 10px;
}

.tilDataLeft {
  min-width: 44%;
  max-width: 44%;
  min-height: 80px;
  max-height: 80px;
  border-bottom: 2px solid rgba(89, 89, 89, 0.267);
  border-radius: 12px;
  background-color: #fff;
}

.tilDataL1 {
  font-size: 0.84em;
  padding-left: 13px;
  max-height: 24px;
  min-height: 24px;
  line-height: 24px;
  font-weight: 500;
  margin-top: 5px;
  color: #496375;
}

.tilDataL2 {
  font-size: 0.84em;
  padding-left: 13px;
  max-height: 24px;
  min-height: 24px;
  line-height: 24px;
  font-weight: 500;
  margin-top: -5px;
  color: #8ea7c5;
}

.tilDataL3 {
  font-size: 0.84em;
  padding-left: 13px;
  max-height: 24px;
  min-height: 24px;
  line-height: 24px;
  font-weight: 500;
  margin-top: -6px;
  color: #acc3e1;
}
.tilDataRight {
  min-width: 44%;
  max-width: 44%;
  min-height: 80px;
  max-height: 80px;
  border-bottom: 2px solid rgba(89, 89, 89, 0.567);
  border-radius: 12px;
  background-color: #07f;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.tilDataR1 {
  font-size: 0.84em;
  padding-right: 13px;
  max-height: 24px;
  min-height: 24px;
  line-height: 24px;
  font-weight: 500;
  margin-top: 5px;
  color: #fff;
}

.tilDataR2 {
  font-size: 0.84em;
  padding-right: 13px;
  max-height: 24px;
  min-height: 24px;
  line-height: 24px;
  font-weight: 500;
  margin-top: -7px;
  color: #cce3ff;
}

.tilDataR3 {
  font-size: 0.65em;
  line-height: 8px;
  padding-top: 3px;
  max-height: 30px;
  min-height: 30px;
  line-height: 12px;
  font-weight: 500;
  margin-top: 0px;
  color: #fff;
  max-width: 80px;
  border-top: 1px solid #143051;
  margin-right: 13px;
}

.ttrDateStringTitle {
  font-weight: 500;
  color: #496375;
  display: flex;
  justify-content: space-between;
  margin-top: -6px;
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 8px;
}

.tilDataDs {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #859dbb;
  color: #496375;
  font-weight: 500;
  font-size: 0.8em;
  padding-top: 2px;
  padding-bottom: 2px;
  background: rgb(247, 250, 255);
  margin-top: 8px;
}

.tilDataTeamName {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #859dbb;
  color: #496375;
  font-weight: 500;
  font-size: 0.8em;
  padding-top: 2px;
  padding-bottom: 2px;
  background: rgb(247, 250, 255);
  margin-top: 8px;
}

.tilDataTeamNameOnChangeContainer {
  display: flex;
  justify-content: center;

  padding-bottom: 10px;

  margin-top: -14px;
}

.nameStringOnTTR {
  line-height: 18px;
  margin-bottom: 16px;
  text-align: center;
  max-width: 230px;
  margin: 0 auto;
  margin-bottom: 20px;
  font-size: 0.85em;
  color: #2e4657;
}

.disableCloseBtn {
  opacity: 0.3;
  pointer-events: none;
}

.paidDayOffSubTxt {
  font-size: 10px;
  max-width: 45px;
  color: #8ea7c5;
  line-height: 10px;
  font-weight: 500;
  margin-left: 6px;
  border-right: 2px solid #8ea7c5;
  padding-right: 7px;
  margin-top:1px;
  display: none;
}

.smallTxtForPaidDayOff {
  font-size: 0.8em;
}

.absenceNameDropdownFilter {
  color: #fff;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 213px;
  min-width: 213px;
  line-height: 26px;
  height: 30px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  background-position-x: 92px;
  display: flex;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 192px;
}

.mgrAbsenceYearSelectDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 213px;
  min-width: 213px;
  line-height: 26px;
  height: 30px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  background-position-x: 92px;
  display: flex;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 192px;
  margin-left: 5%;
}

.absencePageFilterBarLeft {
  min-width: 220px;
  max-width: 220px;

  min-height: 100%;
  display: flex;
  justify-content: flex-start;
  margin-left: 5%;
  margin-top: 8px;
}

.absencePageFilterBarRight {
  min-width: 100px;
  max-width: 100px;
  min-height: 100%;

  font-size: 0.9em;
  font-weight: 500;
  display: flex;

  margin-right: 4px;
  align-items: center;
  justify-content: flex-end;
}

.mgrAbsenceContainerOnly {
  margin-top: 0px;
  border-radius: 0px;

  max-width: 90%;
  margin: 0 auto;
}

.desktopAbsenceContainerMgrOnly {
  max-width: calc(100vw - 240px);
  min-width: calc(100vw - 240px);
  padding-left: 5%;
  padding-right: 5%;
}

.absenceFilterDividerContainer {
  background-color: #ebf0f7;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.absenceFilterDivider {
  min-height: 1px;
  min-width: 90%;
  max-width: 90%;

  background-color: #e6eaee;
}

.absenceFilterBtn {
  font-size: 0.95em;
  margin-top: 8px;
  color: #496375;
  border: 1px solid #496375;
  border-radius: 5px;
  min-height: 30px;
  max-height: 30px;
  line-height: 26px;
  padding-left: 10px;
  font-weight: 500;
  padding-right: 10px;
  padding-top: 2px;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.absenceFilterBtnActive {
  font-size: 0.95em;
  margin-top: 8px;
  color: #fff;
  border: 1px solid #143051;
  display: flex;
  background-color: #214978;
  border-radius: 5px;
  min-height: 30px;
  max-height: 30px;
  line-height: 23px;
  padding-left: 12px;
  padding-right: 12px;
  font-weight: 500;

  padding-top: 2px;
  cursor: pointer;
}

.convertModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.convertModalBox {
  background-color: #fff;
  min-height: 300px;
  min-width: 90%;
  max-width: 90%;
  margin: 10% auto;
  border-radius: 20px;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);

  -webkit-animation-name: zoomModalIn !important;

          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.unitTypeSpan {
  color: #859dbb;
}

.unitTypeDropdown {
  color: #496375;
  border-radius: 5px;
  font-size: 1em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 138px;
  min-width: 138px;
  line-height: 26px;
  height: 35px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  border: 2px solid #dde9f9;
  -webkit-appearance: none;
  background-position-x: 114px;
  display: flex;
  cursor: pointer;
  margin-left: 0px;
  margin-top: -8px;
  margin-bottom: 12px;
}

.unitTypeDropdown:hover {
  border-color: #d7dfe9;
}

.shiftsMenuBtnHolder {
  min-width: 50px;
  max-width: 50px;
  text-align: center;
}

.swapsMenuBtnHolder {
  min-width: 60px;
  max-width: 60px;
  margin-right: 4px;
  text-align: center;
}

.availabilityMenuBtnHolder {
  min-width: 97px;
  max-width: 97px;
  text-align: center;
  margin-left: -8px;
}

.submissionsHolder {
  min-width: 119px;
  max-width: 119px;
}

.availabilityBodyContainer {
  background-color: #ebf0f7;
  padding-bottom: 20px;
  min-height: calc(100vh - 210px);
  max-height: calc(100vh - 210px);
  border-right: 1px solid #e0ebf8;
}

.mobChangesContainer {
  min-height: calc(100vh - 263px);
  max-height: calc(100vh - 263px);
  border-right: none;
}

.absenceMenuTxtSelected {
  cursor: pointer;
  font-weight: 500;
  color: #143051;
  padding-top: 1px;
  margin-top: 1px;
  line-height: 20px;

  padding-bottom: 2px;
  opacity: 1;
}

.changesOverflow {
  overflow-y: scroll;
}

.absenceUserBtnHolder {
  min-width: 75px;
  max-width: 75px;
}

.absenceUserStatsBtnHolder {
  min-width: 54px;
  max-width: 54px;
}

.filterNameShiftsDropdown {
  background-color: #ebf0f7;
}

.hasNoAllowTxtSize {
  padding-top: 4px;
  line-height: 22px;
  margin-bottom: 8px;
  margin-top: 5px;
}

.accruedPayTitleDiv {
  display: flex;
  padding-right: 4px;
}

.mgrShiftQuestionIcon {
  margin-left: 22px;
  margin-bottom: 12px;
}

.payAccruedLeaveTxtDiv {
  max-width: 77%;
}

.accruedLeaveAmount {
  font-size: 0.9em;
  padding-left: 24px;
  line-height: 29px;
  color: #496375;
  font-weight: 500;
  margin-top: 0px;
  color: #9374d6;
}

.accruedAmountSpan {
  background-color: #dbcbff;
  font-weight: 400;
  color: #7249c9;
  border: 1px solid #4b1caf;
  border-radius: 5px;
  padding-left: 9px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-weight: 500;
}

.accruedCostSpan {
  opacity: 0.65;
  margin-left: 6px;
}

.payAccruedLeaveTxtInfo {
  font-size: 0.8em;
  padding-left: 24px;
  line-height: 20px;
  color: #496375;
  font-weight: 400;
  opacity: 0.75;
  margin-top: 14px;
  margin-bottom: 14px;
}

.jamPayAcc {
  opacity: 0.5;
  pointer-events: none;
}

.bankCostBubble {
  background-color: #dbcbff;
  color: #7249c9;
  margin-left: -10px;
  padding-left: 8px;
  padding-right: 10px;
}

.bankCostBubbleUser {
  background-color: #d2e6fd;
  border-color: #496375;
  color: #496375;
  margin-left: -10px;
  padding-left: 8px;
  padding-right: 10px;
}

.teamSelectScheduleDropdown {
  color: #fff;
  border-radius: 10px;
  font-size: 0.75em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 208px;
  min-width: 208px;
  line-height: 26px;
  height: 30px;
  background: url(/static/media/downArrowWhite2.887d57ce.svg) no-repeat right
    #5479a3;
  border: 1px solid #143051;
  -webkit-appearance: none;
  background-position-x: 182px;
  display: flex;
  cursor: pointer;
  opacity: 0.7;
}

.schedMenuLeft {
  width: 210px;

  display: flex;
  justify-content: space-between;
  margin-top: 6px;
}

.teamSchedulePage {
  background-color: #ebf0f7;
  min-height: calc(100vh - 258px);
  max-height: calc(100vh - 258px);
  max-width: 100%;
  min-width: 100%;
  z-index: 1;
  position: relative;
  padding-top: 8px;
  padding-bottom: 100px;
}

.mobStandaloneTeamSchedHeight {
  min-height:100vh;
  max-height:100vh;
}

.teamSchedFilterBarLeft {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;

  min-height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 5px;
}

.teamSchedFilterBarLeftMob {
  margin-left: 5% !important;
}

.teamSchedFilterBarRight {
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  min-height: 50px;
  font-size: 0.9em;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  top: 120px;
  right: calc(4.2% - 9px);
  z-index: 8
}

.teamSchedFilterBarRightStandalone {
  top: 2px;
}
.marginTopMinus5 {
  margin-top: -5px;
}
.monthDropdown {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 10px;
  text-align: left !important;
  max-width: 86px;
  min-width: 86px;
  line-height: 26px;
  height: 35px;
  /* border: 1px solid #e6eaee; */
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  /* border-color: #2c7eb9; */
  border: 1px solid #e6eaee;
  /* background-color: #0bf; */
  background-color: white;
  color: #143051;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 62px;
}

.monthDropdown:hover {
  border-color: #d7dfe9;
}

.x92020121113 {
  background-position-x: 122px;
  max-width: 146px;
  min-width: 146px;

}

.monWeeksDropdown {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 10px;
  text-align: left !important;
  max-width: 104px;
  min-width: 104px;
  line-height: 26px;
  height: 35px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;

  border-color: #2c7eb9;
  border: 1px solid #e6eaee;
  /* background-color: #0bf; */
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 84px;
}
.fullScreenWeekSelector {
  background-position-x: 210px !important;
  max-width: 232px;
  min-width: 232px;
}
.weekSelectorLonger {
  background-position-x: 210px !important;
  max-width: 235px;
  min-width: 235px;
}

.weekSelectorMedium {
  background-position-x: 123px !important;
  max-width: 145px;
  min-width: 145px;
}

.dayViewWeekSelector {
  background-position-x: 113px !important;
  max-width: 135px;
  min-width: 135px;
}

.monWeeksDropdown:hover {
  border-color: #d7dfe9;
}

.loadingSchedFade {
  opacity: 0.25;

  pointer-events: none;
}

.greenSplitter {
  min-height: 3px;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 10px;
  background-color: #6dba4f;
}

.loadingSchedTxt {
  color: #143051;
  margin-top: 50px;
  margin-bottom: 10px;
  font-size: 0.8em;
  -webkit-animation-name: zoomBtnIn !important;
          animation-name: zoomBtnIn !important;
  -webkit-animation-duration: 0.075s !important;
          animation-duration: 0.075s !important;
}

.loadingSchedGenImg {
  margin-top: 60px;
}

.loadingSwaps {
  margin-top: 95px;
}

.mgrSchedBox {
  min-width: 100%;
  min-height: calc(100vh - 286px);
}

.schedDayBar {
  min-width: 100%;
  max-width: 100%;

  overflow-x: auto;
  min-height: calc(100vh - 286px);
}
.dayBarContainer {
  min-height: 30px;
  min-width: 1180px;
  max-width: 1180px;
}

.dayBar {
  max-height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-width: -webkit-fit-content;

  min-width: -moz-fit-content;

  min-width: fit-content;
  padding-right: 20px;
}

.dayBarUnit {
  min-width: 140px;

  max-width: 140px;
  margin-top: 10px;
  font-size: 0.9em;
  font-weight: 500;
  padding-bottom: 3px;
  color: #143051;
  border-right: 1px solid #143051;
}

.dayBarUnit:last-child {
  border: none;
}

.emptyDay {
  min-width: 120px;
  max-width: 120px;
}

.scheduleDataBoxContainer {
  overflow: auto;
  max-height: calc(100vh - 315px);
  min-height: calc(100vh - 315px);
  padding-bottom: 50px;
  position: relative;
}
.scheduleDataBox {
  min-width: 1180px;
  max-width: 1180px;

  overflow: auto;

  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  position: relative;
}

.nameColumn {
  background-color: purple;
  color: white;
  max-width: 120px;
  min-width: 120px;
  min-height: 100%;

  display: flex;
  flex-direction: column;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}

.jobTitleTitle {
  min-height: 55px;
  max-height: 55px;
  color: #214978;
  font-size: 11px;
  line-height: 12px;
  /* padding-right: 6px; */
  padding-top: 1px;
  padding-bottom: 4px;
  max-width: 100%;
  min-width: 100%;
  color: #ae77ff;
  text-align: left;
  font-weight:600
}

.x99100111 {
  min-height: 25px;
  max-height: 25px;
}

.jobTitleA1box {
  background-color: #ecf4ff;
  min-height: 28px;
  min-width: 100%;
  margin-top: -1px;
  border-radius: 5px 0 0 5px;
  max-height: 30px;
  border:1px solid #e3e8ee;
  border-right:none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  
}
.staffCalJobTitleTitle {
  min-height: 36px;
  max-height: 36px;
  display: flex;
  align-items: center;
  max-height: 36px;
  /* border-radius: 8px 0 0 0px; */
  color: #214978;

  font-size: 9px;
  line-height: 10px;
  /* padding-right: 6px; */
  text-align: left;
  font-weight: 500;

  /* padding-top: 6px; */
  /* padding-bottom: 4px; */
  /* max-width: 100%; */
  /* min-width: 100%; */
  padding-right: 12px;
  /* text-transform: uppercase; */
  /* color: #ff6441; */
  color: #b764eb;
  white-space: normal;
  max-width: 120px !important;
  font-weight: 500;
}

.collapsedJobTitle {
  visibility: hidden;
}

.jobTitleContentUnit {
  margin-top: 10px;

  max-height: -webkit-fit-content;

  max-height: -moz-fit-content;

  max-height: fit-content;
}

.jobTitleBlanker {
  opacity: 0.5;
  min-height: 25px;
  max-height: 25px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.jobTitleUsersContainer {
  margin-top: 10px;
}

.jtUser {
  color: #fff;
  border-radius: 5px 0 0 5px;

  border-right: none;
  margin-top: 4px;
  margin-bottom: 1px;
  min-height: 60px;
  max-height: 60px;
  text-align: left;
  font-weight: 500;
  font-size: 0.75em;
  display: flex;
  justify-content: space-between;

  line-height: 13px;
  max-width: 100%;
  font-weight: 500;
  cursor: pointer;
}

.font10 {
  font-size: 10px !important;
}

.jtUserDesktop:hover {
  background-color: #dde9f9;
}

.jtUserStandalone {
  cursor:default;
}
.jtUserStandalone:hover {
  background: none;
}

.jtUserWithAvail {
  margin-top: 18px;
  margin-bottom: 4px;
}

.availPresentButOnThisItem {
  min-height: 5px;
  min-width: 1px;
}

.secondaryUserToTheTeam {
  /* background-color: yellow; */
}


.minHeight102 {
  min-height:102px; 
}
.jtUserLeft {
  max-width: 8px;
  min-width: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3px;
  /* font-weight: 500; */
}

.userLightBarOn {
  background-color: #96e578;
  min-width: 3px;
  max-width: 3px;
  min-height: 46px;
  border-radius: 5px;
  margin-left: 2px;
  margin-top: -2px;
}

.lightBarOffOff {
  background-color: #6987a9;
  min-width: 3px;
  max-width: 3px;
  min-height: 46px;
  border-radius: 10px 0 0 10px;
  margin-left: -4px;
  margin-top: -2px;
  visibility: hidden;
}

.lightBarOnNonPrimary {
  min-height: 37px;
  max-height: 37px;
}

.jtUserRight {
  max-width: 78px;
  min-width: 78px;
  display: flex;
  padding-left: 1px;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 10px;
  /* padding-bottom: 8px; */
  color: #143051;
  margin-left: 0px;
  font-weight: 500;
  padding-right: 2px;
  /* min-height:100px !important */
}

.unitSplit {
  min-width: 100%;
  min-height: 1px;
  margin-top: 4px;
  background-color: #95aaba;
  opacity: 0.25;
}

.teamSchedContainer {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: flex-start;

  padding-left: 1%;

  max-height: calc(100vh - 266px);
  min-height: calc(100vh - 266px);
  overflow-y: auto;
  max-width: 100%;
  min-width: 100%;
  z-index: -1
  /* background-color: white; */
}

.teamSchedDesktop {
  max-height: calc(100vh - 203px);
  min-height: calc(100vh - 203px);
  padding-top: 8px !important;
  z-index: -3;
}
.desktopTeamSchedHeight {
  max-height: calc(100vh - 30px);
  min-height: calc(100vh - 30px);
  padding-top: 0px !important;
}

.desktopTeamSchedHeightStandalone {
  max-height: calc(100vh - 0px);
  min-height: calc(100vh -0px);
}
.deskopStandaloneRota {
left: 0px !important;  min-width: 100vw !important;
  max-width: 100vw !important;
  min-height:100vh !important

}

.standaloneDesktopRota {
  max-height: calc(100vh - 56px);
  min-height: calc(100vh - 56px);
}
.standaloneMobRota {
  max-height: calc(100vh - 65px);
  min-height: calc(100vh - 65px);
}

.calendarComponent {
  position: fixed;
  top: 0;
  left: 160px;
  min-width: calc(100vw - 160px);
  max-width: calc(100vw - 160px);
}

.mobCalComponent {
  position: fixed;
  top: 0;
  left: 0px;
  min-width: calc(100vw);
  max-width: calc(100vw);
}

.fixedLeft0 {
  left: 0 !important;
  border-left: none !important;
}
.teamSchedInnerCont {
  display: flex;

  justify-content: flex-start;
  min-width: 100%;
}


@media only screen and (min-width: 820px) {
  .teamSchedInnerCont {
    min-width: 102%;

  }
}

.nameColContainer {
  min-width: 100px;
  max-width: 100px;
}

.nameColContainerMob {
  min-width: 84px;
  max-width: 84px;
}

.w130px {
  min-width: 130px;
  max-width: 130px;
}

.teamSchedSplit {
  min-width: 3px;
  background-color: #bac5d3;
}

.teamSchedContent {
  display: flex;
  overflow-x: auto;
  font-size: 8px;
  padding-right: 30px;
  min-width:calc(100vw - 297px);
}

.dayCol {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;

  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #bbcada;
  padding-bottom: 10px;
}

.dayCol:first-of-type {
  padding-left: 2px;
}

.dayCol:last-of-type {
  border: none;
  border-right: 1px solid #bac5d3;
  /* border-radius: 0 0 10px 0; */
}

.todayDayColBg {
  background-color: #ffffff !important;
  /* border-left: 3px solid #214978 !important; */
  /* border-right: 3px solid #214978 !important; */
}

.yesterdayDayTopUnit {
  border-radius: 0px 5px 0 0;
}

.tomorrowDayTopUnit {
  border-radius: 5px 0px 0 0;
}

.dayOddColBg {
}

.dayClosedBg {
  background-color: #e3ebf5;
}

.yesterdayDayCol {
  border1-right: none;
}

.dayCol:first-of-type {
  border-left: none;
}
.dayTopUnit {
  min-height: 34px;
  max-height: 34px;
  min-width: 100%;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  color: #143051;
  /* background-color: #ebf0f7; */
  color: #0af;

  border-bottom: 3px solid #bbcada;

  font-weight: 500;
}

.dayTopUnitOdd {
}

.a1DayTopUnit {
  /* background-color: #ebf0f7; */
  justify-content: space-between !important; 
  padding-left: 0px !important;
}

.lastDayTopUnit {
}
.dayCost {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 4px;
  padding-right: 3px;
  border-radius: 5px;
  margin-right: 6px;
  font-size: 0.9em;
  color: #6a98d1;
  padding-right: 6px;
  text-align: right;
  font-weight: 400;
  display: none;
}

.dayMinsSpan {
  color: #6a98d1;
  padding-left: 6px;
  border-left: 1px solid #abbcce;
}

.dayCostBgOdd {
}

.todayCostBg {
}

.blankDayTxt {
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;
  color: #4f86a2;
  /* border-left: 2px solid #0af; */
  padding-left: 4px;
  /* /* opacity: 0.15; */
}

.jtUserShiftsBox {
  background-color: white;

  color: #143051;
  border-radius: 4px;
  margin-top: 5px;
  min-height: 54px;
  max-height: 54px;
  text-align: left;
  font-weight: 500;
  font-size: 7px;
  display: flex;
  align-items: center;

  line-height: 15px;
  /* min-width: 142px;
  max-width: 142px; */
  min-width: 148px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-left: 0px;
  cursor: pointer;
  -webkit-animation-name: zoomBtnIn !important;
          animation-name: zoomBtnIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  z-index: 99999;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  border: 1px solid #dde9f9;
  margin-right: 3px;
  padding: 2px;
}

.jtUserShiftsBoxHove {
  padding-bottom: 3px;
  min-width: 148px;
}
.jtUserCostSpan {
  color: #0077ff;
  font-weight: 500;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  font-size: 9px;
}

.shiftBoxLeft {
  min-width: 12%;
  min-height: 54px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: -2px;
  margin-right: 1px;
}

.shiftLight {
  min-height: 8px;
  max-height: 8px;
  min-width: 8px;
  max-width: 8px;
  margin-left: 5px;
  border-radius: 20px;
}

.shiftUnitPublicStatusIconImg {
  min-width: 9px;
  max-width: 9px;
  margin-left: 2px;
}

.partialIcon {
  min-width: 53px;
  margin-top: 20px;
  max-width: 53px;
  margin-left: 5px !important;
}

.unpublishedSizing {
  min-width: 14px;
  max-width: 14px;
  margin-left: 5px;
  margin-bottom: -2px;
  margin-top: -2px;
}
.shiftLightGreen {
  background-color: #96e578;
}

.shiftLightRed {
  background-color: #ba0000;
}

.shiftLightBlue {
  background-color: #e3ebf5;
}

.shiftBoxMiddle {
  min-width: 78%;
  min-height: 100%;
  max-height: 100%;
  padding-left: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.shiftBoxRight {
  min-width: 10%;
  min-height: 54px;
  max-height: 54px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: -0;
}

.shiftBoxRightMoveHere {
  max-width: 14px;
  margin-right: -2px;
}

.shiftBoxTypeTxt {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);

  opacity: 1;

  min-width: 54px;

  margin-left: -19px;
  text-align: center;
  color: #fff;
  font-size: 10px;
  z-index: -1;
  padding-bottom: 2px;
}
.shiftBoxTypeTxtLeave {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);

  border-radius: 0 0px 5px 5px;
  padding-bottom: 2px;

  min-width: 54px;

  margin-left: -19px;
  text-align: center;
  color: white;
  font-size: 10px;
  z-index: -1;
  margin-top: -1px;
  /* border-top: 2px solid #6c8cb3; */
}

.paidTimeOffStrip {
  color: white;
  font-size: 0.9em;
  min-width: 80px !important;
}
.shiftBoxTypeTxtTimeOff {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);

  border-radius: 0 0px 5px 5px;
  padding-bottom: 2px;

  min-width: 54px;

  margin-left: -19px;
  text-align: center;
  color: white;
  font-size: 1.45em;
  z-index: -1;
  margin-top: -1px;
}

.shiftBoxTypeTxtOpen {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  padding-bottom: 2px;
  color: #7fbb67;
  min-width: 51px;
  margin-left: -18px;
  text-align: center;
  font-size: 1.45em;
  z-index: -1;
  margin-top: -1px;
  border-top: 1px solid #e3e8ee !important;
  max-height: 16px;
}

.shiftBoxTypeTxtMoving {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);

  padding-bottom: 2px;

  background-color: #143051;
  min-width: 54px;

  margin-left: -19px;
  text-align: center;
  color: white;
  font-size: 1.25em;
  z-index: -1;
  margin-top: -1px;
}

.shiftBoxTypeTxtOvertime {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  border-radius: 0 0px 5px 5px;

  min-width: 54px;
  margin-left: -20px;
  text-align: center;
  padding-bottom: 1px;
  color: white;
  font-size: 1.3em;
}

.shiftBoxTypeTxtTil {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);

  min-width: 54px;
  margin-left: -19px;
  padding-bottom: 3px;
  text-align: center;

  font-size: 1.3em;
}

.shiftBoxMiddleRow1 {
  min-height: 29px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 2px;
  min-width: 89px;
}

.shiftBoxTimeStrip {
  font-size: 2.4em;

  font-weight: 600;
  color: #143051;
  /* padding-bottom: 1px; */

  display: flex;
}

.shiftBoxTimeStripMyRota {
  font-size: 14px !important;

  font-weight: 600;
  color: #143051;
  padding-bottom: 1px;
padding-left:1px;
  display: flex;
}

.openShiftTimeStrip {
  font-size: 12px;
  margin-bottom: 2px;
  color: #516b8b;
}

.openShiftTimeStripMyRota {
  font-size: 13px !important;
  margin-bottom: 2px;
  color: #516b8b;
  margin-left: 4px;
  font-weight: 600;
  color: #143051;
}

.leaveTimeStrip {
  color: white;
  font-size: 14px;
  margin-bottom: -2px;
}

.leaveTimeStripHovered {
  color: white;
  font-size: 2.1em;
  margin-top: -24px;
}
.makeTimesSmaller {
  font-size: 11px;
}

.shiftBoxDurMins {
  font-size: 11px;
  color: #8ea7c5;
  /* margin-left: 1px; */
  font-weight: 600;
  margin-top: 2px;
  /* max-width: fit-content; */
  /* opacity: 0.5; */
  min-width: 80px !important;
}

.bidderQty {
  font-size: 10px;
  color: #8ea7c5;
  /* margin-left: 1px; */
  font-weight: 500;
}

.bidderQtyMyRota {
  font-size: 10px;
  color: #8ea7c5;
  /* margin-left: 1px; */
  font-weight: 500;
  margin-left:4px;
}
.makeTxt9374d6 {
}

.shiftBoxMiddleRow2 {
  display: flex;
  /* opacity: 0.5; */
  min-height: 23px;
  margin-bottom: 4px;
  margin-top: -2px;
}

.openMiddleRow {
  min-height: 22px;
}

.shiftBoxTagConainer {
  display: flex;
}

.shiftMoveIcon {
  min-width: 28px;
  max-width: 28px;
  min-height: 28px;
  max-height: 28px;
  border-radius: 5px;
  padding: 3px;
  margin-top: 4px;
  margin-left: 4px;
  background-color: #becde0;
  border: #abbcce 1px solid;
}

.delIcon {
  background-color: #9374d6;
  border: 1px solid #6e4eb2;
  min-width: 25px;
  max-width: 25px;
  min-height: 25px;
  max-height: 25px;
  border-radius: 5px;
  padding: 2px;
  padding-top: 1px;
  padding-bottom: 3px;
  margin-top: 4px;
  margin-left: 6px;
}

.delIconConfirm {
  background-color: #00aaff;
  border-color: #1964ba;
}

.expandShiftIcon {
  background-color: #0bf;
  border: 1px solid #0af;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 2px;

  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  border-radius: 5px;

  margin-top: 2px;
}
.emptyShiftBox {
  color: #bbcada;

  margin-top: 5px;
  min-height: 65px;
  max-height: 65px;
  text-align: left;
  font-weight: 500;
  font-size: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  line-height: 15px;
  min-width: 100%;
  max-width: 100%;
  margin-left: 3px;
  cursor: pointer;
  margin-top: -1px;
  padding-bottom: 7px;
}

.fadeSchedLoading {
  opacity: 0.6;
  pointer-events: none;
}

.userShiftsCollection {
  display: flex;
  padding-left: 6px;
  padding-right: 0px;
  min-height: 72px;
  align-items: center;
  padding-top: 28px;
  max-height: 92px;
}

.userShiftsCollectionMyRota {
  display: flex;
  padding-left: 5px;
  padding-right: 2px;
  margin-top: 3px;
  margin-bottom: -2px;
  min-height: 72px !important;
  align-items: center;
  max-height: 72px !important;
}

.myRotaJtUserParent {
  min-height: 78px;
  max-height: 78px;
  border-bottom: 2px solid rgb(149, 170, 186, 0.15);
}

.userShiftsCollectionWithAvail {
  min-height: 86px !important;
  /* msc-height: 86px !important; */
  margin-bottom: -8px;
  margin-top: 13px;
  padding-top: 0px !important;
}


.addShiftBox {
  background-color: #9a74eb;
  position: relative;
  min-height: 28px;
  max-height: 28px;
  color: white;
  border-radius: 5px;

  max-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5em;
  padding-bottom: 4px;
  margin-top: 5px;
  margin-left: 3px;

  font-weight: 500;
  border: 1px #7249c9 solid;
  cursor: pointer;
  z-index: 100;
  -webkit-animation-name: heightDown !important;
          animation-name: heightDown !important;
  -webkit-animation-duration: 0.2s !important;
          animation-duration: 0.2s !important;
}

.addShiftBoxNoBottomBorder {
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  background-color: #dbcbff;
}

.invisAddShiftBox {
  opacity: 0;
  visibility: hidden;
}

.extendAdd {
  background-color: #dbcbff;
  position: -webkit-sticky;
  position: sticky;
  min-height: 120px;

  max-width: 144px;
  border-radius: 0 5px 5px 5px;
  margin-left: 3px;
  margin-top: -2px;
  border: 1px #7249c9 solid;
  z-index: 1;
  -webkit-animation-name: heightDown !important;
          animation-name: heightDown !important;
  -webkit-animation-duration: 0.2s !important;
          animation-duration: 0.2s !important;
}

.leftRightMonWeekContainer {
  display: flex;
  opacity: 0.6;
  min-width: 80px;
  max-width: 80px;
  padding-left: 0px;
  padding-bottom: 3px;
  justify-content: space-between;
}

.leftMonWeek {
  max-width: 22px;
  min-width: 22px;
  max-height: 22px;
  min-height: 22px;
  /* border-radius: 5px; */
  cursor: pointer;
  margin-right: 4px;
  margin-top: 2px;
  padding: 4px;
  /* border: 1px solid #bbcada; */
  /* background-color: #cbd7e6; */
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.rightMonWeek {
  max-width: 22px;
  min-width: 22px;
  max-height: 22px;
  min-height: 22px;
  /* border-radius: 5px; */
  cursor: pointer;
  margin-left: 4px;
  margin-top: 2px;
  padding: 4px;
}

.rightMonWeekDesktop:hover {
  background: #f5f8fa;
  border: 1px solid #d2e0f2;
  border-radius: 5px;
}

.rightMonWeek:active {
  /* background-color: #cbd7e6; */
}

.leftMonWeek:active {
  /* background-color: #cbd7e6; */
}

.leftMonWeekDesktop:hover {
  background: #f5f8fa;
  border: 1px solid #d2e0f2;
  border-radius: 5px;
}

.extendBtnsContainer {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 0px;
  padding-bottom: 18px;
  padding-left: 8px;
  min-height: 168px;
}

.extendBtnsContainerEmpty {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.extendBtnShift {
  background-color: white;
  color: #305581;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 10px;
  padding-right: 10px;

  min-height: 32px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-size: 1.7em;
  font-weight: 500;

  border: 1px solid #cfd9e3;
  -webkit-animation-name: fadeModalIn !important;
          animation-name: fadeModalIn !important;

  white-space: nowrap;

  cursor: pointer;
  /* margin-top:5px;
  margin-bottom:5px; */
}

@media only screen and (min-width: 821px) {
  .extendBtnShift:hover {
    background-color: #fdf9ff;
    border-color: #bcafff;
  }
  .extendBtnShift:nth-child(1) {
    -webkit-animation-duration: 0.25s !important;
            animation-duration: 0.25s !important;
  }
  .extendBtnShift:nth-child(2) {
    -webkit-animation-duration: 0.5s !important;
            animation-duration: 0.5s !important;
  }
  .extendBtnShift:nth-child(3) {
    -webkit-animation-duration: 0.75s !important;
            animation-duration: 0.75s !important;
  }

  .extendBtnShift:nth-child(4) {
    -webkit-animation-duration: 1s !important;
            animation-duration: 1s !important;
  }
}
.fadeAddShBox {
}

.hideDayCost {
  opacity: 0;
  visibility: hidden;
}

.schedElipsisBtn {
  background-color: #4b1caf;
  color: white;
  border: 1px solid #140351;
  min-height: 30px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 5px;
  font-size: 2em;
  margin-top: 6px;
  cursor: pointer;
}

.generateBtn {
  font-size: 0.95em;
  margin-top: 8px;
  background-color: #0077ff;
  color: white;
  border: 1px solid #140351;

  border-radius: 10px;
  min-height: 30px;
  max-height: 30px;
  line-height: 26px;
  padding-left: 10px;
  font-weight: 500;
  padding-right: 10px;
  padding-top: 2px;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.clearGenBtn {
  background-color: #4b1caf;
}

.genContainer {
}

.publishBtn {
  font-size: 0.95em;
  margin-top: 8px;
  color: #496375;
  border: 1px solid #496375;
  border-radius: 10px;
  min-height: 30px;
  max-height: 30px;
  line-height: 26px;
  max-width: 86px;
  padding-left: 10px;
  font-weight: 500;
  padding-right: 10px;
  padding-top: 2px;
  margin-right: 6px;
  cursor: pointer;
}

.currWeekIcon {
  margin-left: 6px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.5s !important;
          animation-duration: 0.5s !important;
  cursor: pointer;
  background-color: rgb(83, 155, 237);
  border: 1px solid #2c7eb9;
  max-width: 25px;
  min-width: 25px;
  max-height: 25px;
  min-height: 25px;
  border-radius: 5px;
  margin-top: 2px;
  padding:3px;
  border: 1px solid #bbcada;
  background-color: #cbd7e6;
}
@media only screen and (max-width: 400px) {
  .currWeekIcon {
    display: none;
    max-width: 20px;
    min-width: 20px;
    margin-left: 10px;
    -webkit-animation-name: zoomModalIn !important;
            animation-name: zoomModalIn !important;
    -webkit-animation-duration: 0.5s !important;
            animation-duration: 0.5s !important;
    cursor: pointer;
  }
}

.horizAddShiftBtn {
  position: relative;
  min-height: 24px;
  max-height: 24px;
  color: #516b8b;
  border-radius: 5px;

  max-width: 30px;
  min-width: 30px;
  margin-top: -4px;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  padding-bottom: 3px;

  font-weight: 500;

  cursor: pointer;
  z-index: 90;
  margin-top: -24px;
  -webkit-animation-name: zoomAddBtnIn !important;
          animation-name: zoomAddBtnIn !important;
  -webkit-animation-duration: 0.6s !important;
          animation-duration: 0.6s !important;
}

.horizAddShiftBtn:hover {
}

.fadedHorizAdd {
  opacity: 0.25;
}

.fadedHorizAddOpen {
  opacity: 0.55;

  -webkit-transform: scale(0.85);

          transform: scale(0.85);
}

.force1opac {
  opacity: 0.8 !important;
  color: white !important;
}

.horizAddBtnActive {
  border: 1px solid #140351;

  border-radius: 5px;
  padding-left: 1px;

  background-color: #214978;
  margin-left: 6px;
  margin-right: 8px;
}
.horizAddBtnActive:hover {
  background-color: #143051;
}

.horizAddBtnActiveGreen {
  border-radius: 5px;
  padding-left: 1px;
  margin-top: 13px;
}

.emptyShiftAddBtn {
  background: none;

  border: none;
  font-size: 2em;
  margin-top: -15px;
  color: #516b8b;
  margin-right: 20px;
  opacity: 0.1;
}

.emptyShiftAddBtn:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  opacity: 0.8;
}

.selectCursorEmptyShifts {
  cursor: pointer;
}

.costAndHoursP {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 6px;
  margin-top: -5px;
  font-weight: 400;
  font-size: 0.92em;
  min-height: 30px;
  max-width: 100%;
}

.costAndHoursPOnNonPrimary {
  margin-top: -7px;
}

.addShiftInlineBox {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  min-height: 162px;

  border-radius: 5px;

  margin-top: 5px;
  margin-right: 6px;
  margin-left: -8px;
  z-index: 100;
  -webkit-animation-name: widthRight !important;
          animation-name: widthRight !important;
  -webkit-animation-duration: 0.15s !important;
          animation-duration: 0.15s !important;
}

.rotatePlusTxt {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  margin-top: 0px;
  color: white;
  margin-left: 2px;
  -webkit-animation-name: rotateClockWise45 !important;
          animation-name: rotateClockWise45 !important;
  -webkit-animation-duration: 0.15s !important;
          animation-duration: 0.15s !important;
  line-height: 18px;
}

.userShiftsBgAddBtnActive {
  padding-bottom: 5px;
  border-radius: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.extendUserForAddShiftBox {
  min-height: 93px;

  min-width: 10px;
  -webkit-animation-name: heightDown !important;
          animation-name: heightDown !important;
  -webkit-animation-duration: 0.25s !important;
          animation-duration: 0.25s !important;
}

.aallaa {
  min-height: 88px !important;
}

.collapse {
  color: #143051;
  font-size: 0.4em;
  cursor: pointer;
}

.collapsedNameCol {
  max-width: 40px;
  min-width: 40px;
}

.collapseNameDiv {
  min-height: 100%;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  color: #143051;
}

.collapseIcon {
  cursor: pointer;
  min-width: 24px;
  margin-left: 16px;
  min-width: 24px;
  cursor: pointer;
  background-color: #cbd7e6;
  border-radius: 0px 5px 5px 0px;
  border-right: 1px solid #bbcada;
  border-top: 1px solid #bbcada;
  border-bottom: 1px solid #bbcada;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.reverseCollapseIcon {
  min-width: 24px;
  cursor: pointer;
  background-color: #cbd7e6;
  border-radius: 5px 0 0 5px;
  border-left: 1px solid #bbcada;
  border-top: 1px solid #bbcada;
  border-bottom: 1px solid #bbcada;
}

.reverseCollapseIconUserMob {
  /* margin-left: 0px; */
}

.reverseCollapseIconUser {
  /* margin-left: 106px; */
}

.currMonWeekBgDrop {
  border-color: #2c7eb9;
  border: 1px solid #e6eaee;
  /* background-color: #0bf; */
  background-color: white;
  color: #00aaff;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 84px;
}

.addShiftInEmptyBox {
  font-size: 0.5em;
  min-height: 150px;
  max-height: 150px;
  align-items: center;
  align-items: space-between;
}

.emptyBoxAddBtnsContainer {
  min-height: 160px;

  align-items: space-between;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.emptyShiftsExtendBoxExtended {
  min-height: 167px;
  align-items: center;
  align-items: space-between;
}

.emptyExtendBtnsContainer {
  padding-right: 20px;
  min-height: 160px;
  align-items: flex-end;
  margin-top: -8px;
}

.emptyAddShiftCont {
  display: flex;
  max-height: 33px;
}

.emptBtnActive {
  border: 1px solid #143051;

  border-radius: 5px;
  padding-left: 1px;
  margin-top: 2px;
  padding-top: 2px;
  margin-right:6px;

  background-color: #214978;
}

.emptBtnActive:hover {
  background-color: #143051;
}

.shiftBoxUpper {
  background-color: blue;
  display: flex;
}

.shiftBoxLower {
  z-index: 999;
  margin-top: -7px;

  padding-left: 10px;

  display: flex;
  min-height: 18px;
  max-width: 90%;
  /* cursor: pointer; */
  margin-left: 20px;
}

.shTagInline {
  font-size: 1.35em;
  text-align: left;
  margin-right: 2px;
  padding-left: 6px;
  padding-right: 6px;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  border-radius: 4px;
  font-weight: 500;

  background-color: rgb(247, 250, 255);
  color: #fff;
  border: 1px solid #859dbb;
  padding-top: 0px;

  display: flex;
  align-items: center;
  max-height: 18px;
  z-index: 0;
  opacity: 1;
  margin-top: 0px;

  background-color: #c798c4;
  border: 1px solid #893c84;
}

.shiftActionsDiv {
  min-height: 40px;

  display: flex;
  justify-content: space-around;
  padding-bottom: 20px;
  margin-top: -21px;
  z-index: 9999;
  padding-left: 10px;
  margin-bottom: -38px;
  padding-right: 15px;
  opacity: 1 !important;
}

.shiftActionsIcon {
  max-width: 30px;
  cursor: pointer;
  -webkit-animation-name: zoomBtnIn !important;
          animation-name: zoomBtnIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  z-index: 9999999;
  position: relative;
}

.shiftActionsIcon:hover {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.shiftActionsIcon:active {
  -webkit-transform: scale(0.92);
          transform: scale(0.92);
}

.shiftActionsIcon:last-child {
  max-width: 24px;
  margin-top: 4px;
  opacity: 1;
  z-index: 9999;
}

.makeDeleteBigger {
  max-width: 28px;
  min-width: 28px;
}
.hideShiftBoxLower {
  visibility: hidden;
  opacity: 0;
}

.pullUpTimeStrip {
  margin-bottom: 12px;
}

.pullUpTimeStripHoveredLeave {
  margin-bottom: 11px;
}

.todayDayTopUnit {
  color: #143051;
}

.leaveShiftBox {
  background-color: #7788d8;
  color: white;
  border: 1px solid #6680a2;
  min-width: 133px !important;
  max-width: 133px !important;

}

.leaveShiftBoxHovered {
  /* background-color: #; */
  background-color: #6274c5;
  color: white;
  /* border: none; */
  min-width: 133px !important;
  max-width: 133px !important;

  padding-left: 2px;
  padding-right: 2px;
  border: 1px solid #6680a2;
  padding-top:3px
}

.absenceLeaveShiftBox {
  background-color: #7e8fa3;
  color: white;
  border: 1px solid #525f6f !important;
}

.myAbsRotaBorder {
  border: 2px solid #525f6f !important;

}
.firstHalfOffBg {
  background: #88afdc;
  background: linear-gradient( 315deg, #88afdc 50%, #b2c1d8 50%, #b2c1d8 97% );
}

.firstHalfOffBg:hover {
  /* background: rgb(33, 73, 120);
  background: linear-gradient(
    315deg,
    rgb(10 40 76) 50%,
    rgba(100, 115, 177, 1) 50%,
    rgb(73 83 123) 97%
  ); */
}

.lastHalfOffBg {
  background: #88afdc;
  background: linear-gradient( 135deg, #88afdc 51%, #b2c1d8 51%, #b2c1d8 97% )
}

.lastHalfOffBg:hover {
  /* background: rgb(33, 73, 120);

  background: linear-gradient(
    135deg,
    rgb(10 40 76) 51%,
    rgba(100, 115, 177, 1) 51%,
    rgb(73 83 123) 97%
  ); */
}

.firstHalfOffBgHovered {
  background: #88afdc;
  background: linear-gradient( 315deg, #6e9bcf 50%, #b2c1d8 50%, #b2c1d8 97% );
}

.lastHalfOffBgHovered {
  background: #88afdc;
  background: linear-gradient( 135deg, #6e9bcf 51%, #b2c1d8 51%, #b2c1d8 97% );
}

.leaveDurMins {
  color: #a8cfff;
  font-size: 10px;
  margin-top: 2px;
}

.leaveTypeNameRow {
  text-overflow: ellipsis;
  max-height: 30px;
  margin-bottom: -14px;
  color: #fff;
  font-size: 10px;
  z-index: 1000;
  margin-top: 14px;
  line-height: 12px;
  margin-bottom: -19px;
}

.longLeaveTypeName {
  text-overflow: ellipsis;
  max-height: 30px;
  margin-bottom: -17px;
  color: #fff;
  font-size: 9px;
  z-index: 1000;
  margin-top: 12px;
  line-height: 9px;
  max-width: 80px;
  padding-left: 24px;
}

.leaveTypeNameRowHovered {
  max-height: 30px;

  color: #fff;
  font-size: 10px;
  z-index: 1000;
  margin-top: 4px;
  margin-bottom: -4px;
  line-height: 9px;
  text-overflow: ellipsis;
  /* margin-top: 12px; */
  /* line-height: 9px; */
}

.hoveredLTnameLong {
  font-size: 9px;
  margin-top: 2px;
  margin-bottom: -4px;
  line-height: 9px;
}

.placeholderImgForMove {
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}

.DayString {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  white-space: nowrap;
}

.hideFirstDayString {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

/* lolling */
.absenceFirstHalfOffBgHovered {
  background: rgb(33, 73, 120);
  background:linear-gradient(    315deg,    #525f6f 50%,    #b2c1d8 50%,    #b2c1d8 97%  );

  border: 1px solid #525f6f !important;
  padding-left:2px;
  padding-top:3px;
}

.absenceFirstHalfOffBg {
  
  background: rgb(126 143 163);
  background: linear-gradient(
    315deg,
    rgb(126 143 163) 50%,
    #b2c1d8 50%,
    #b2c1d8 97%
  );
}

.absenceLastHalfOffBg {
  background: rgb(126 143 163);
  background: linear-gradient( 135deg, rgb(126 143 163) 51%, #b2c1d8 51%, #b2c1d8 97% );
  border: 1px solid #525f6f !important;
  padding-left:2px;
  padding-right:2px;
}

.absenceLastHalfOffBgHovered {
  /* background: rgb(126 143 163); */
  background: linear-gradient( 135deg, #69788a 51%, #b2c1d8 51%, #b2c1d8 97% );
  border: 1px solid #525f6f !important;
  padding-left:2px;
}

/* #6978aa */
.saveBtnTxt {
  background-color: #07f;
  max-height: 35px;
  min-height: 35px;
  line-height: 26px;
  color: white;
  font-weight: 500;
  border: 1px solid #0469de;
  border-radius: 5px;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;

  cursor: pointer;
  min-width: 60px;
}
.saveBtnTxt:hover {
  background-color: #0469de;
}

.saveNewShiftBtn {
  background-color: #1e88e5;
  max-height: 35px;
  min-height: 35px;
  color: white;
  font-weight: 500;
  border: 1px solid #0469de;
  border-radius: 5px;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  margin-bottom: -2px;
  cursor: pointer;
  min-width: 60px;
}
.saveNewShiftBtn:hover {
  background-color: #1f78de;
}
.deleteSureTxt {
  display: flex;
  min-height: 25px;
  margin-top: -15px;
  margin-bottom: -20px;
}

.doNotShowTimeStrip {
  visibility: hidden;
  opacity: 0;
}

.schedToolsBtn {
  font-size: 14px;
  margin-top: 8px;
  /* background-color: #656095; */
  color: white;
  /* border: 1px solid #595672; */
  background-color: #a58fd4;
  border: 1px solid #a47fd0;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  line-height: 20px;
  padding-left: 8px;
  font-weight: 500;
  padding-right: 10px;
  /* padding-top: 2px; */

  cursor: pointer;
  display: flex;
  align-items: center;

  justify-content: space-around;

  min-width: -webkit-fit-content;

  min-width: -moz-fit-content;

  min-width: fit-content;
  min-width: fit-content;
}



.toolsDownImg {
  max-width: 9px;
  margin-left: 7px;
}
.schedToolsBtnOpen {
  font-size: 0.85em;
  margin-top: 9px;
  background-color: #696682;
  color: white;
  border: 1px solid #696682;
  position: absolute;

  border-radius: 5px 5px 0 0;
  min-height: 35px;
  max-height: 35px;
  line-height: 26px;
  padding-left: 8px;
  font-weight: 500;
  padding-right: 10px;
  padding-top: 1px;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 90px;
  max-width: 90px;
  right: 4%;
  top: 2px;
  /* font-size: 0.85em; */
}

.toolsUnderlay {
  background-color: rgb(43, 56, 77, 0.15);
  position: absolute;
  top: 116px;

  min-width: 100vw;
  min-height: 100vh;
  z-index: 999999;
  cursor: pointer;
  /* -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%); */

  /* -webkit-backdrop-filter: blur(5px); */
  /* backdrop-filter: blur(2px); */
  /* background-color: rgb(122 122 122 / 50%); */
}

.limBtnFade {
  opacity: 0.5;
  /* background-color: #bdadc4 !important; */
  /* border-color:#9b8ba2  !important */
}
.actionsDropper {
  background-color: #fff;
  border: 1px solid #696682;
  position: fixed;
  min-height: 0px;
  min-height: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  min-width: 154px;
  top: 158px;
  right: 4%;
  z-index: 9999;
  border-radius: 10px 0 10px 10px;
  -webkit-animation: slideOpenMobActionsTools 0.25s forwards;
          animation: slideOpenMobActionsTools 0.25s forwards;
}



@-webkit-keyframes slideOpenMobActionsTools {
  0% {
    min-height: 0;
    max-height: 0;
  }
  100% {
    min-height: 442px; /* Adjust this value to control the final height of the rectangle */
    max-height: 442px; /* Adjust this value to control the final height of the rectangle */
  }
}



@keyframes slideOpenMobActionsTools {
  0% {
    min-height: 0;
    max-height: 0;
  }
  100% {
    min-height: 442px; /* Adjust this value to control the final height of the rectangle */
    max-height: 442px; /* Adjust this value to control the final height of the rectangle */
  }
}

.actionsDropperPublish {
  background-color: #fff;
  border: 1px solid #696682;
  position: absolute;
  min-height: 0;
  max-height: 0;
  padding-top: 0px;
  padding-bottom: 0px;

  min-width: 154px;
  top: 45px;
  right: calc(4.2% + 152px);
  z-index: 999;
  margin-right: 343px;
  border-radius: 5px 0 5px 5px;
  -webkit-animation: slideOpenPublishTools 0.25s forwards;
          animation: slideOpenPublishTools 0.25s forwards;
}

@-webkit-keyframes slideOpenPublishTools {
  0% {
    min-height: 0;
    max-height: 0;
  }
  100% {
    min-height: 150px; /* Adjust this value to control the final height of the rectangle */
    max-height: 150px; /* Adjust this value to control the final height of the rectangle */
  }
}

@keyframes slideOpenPublishTools {
  0% {
    min-height: 0;
    max-height: 0;
  }
  100% {
    min-height: 150px; /* Adjust this value to control the final height of the rectangle */
    max-height: 150px; /* Adjust this value to control the final height of the rectangle */
  }
}

.fadeNone {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

.limitsDropperDesktop {
  background-color: #fff;
  border: 1px solid #696682;
  position: absolute;
  min-height: 0;
  max-height: 0;
  padding-top: 0px;
  padding-bottom: 0px;

  min-width: 154px;
  top: 45px;
  right: 4.2%;
  z-index: 999;
  margin-right: 376px;
  border-radius: 5px 0 5px 5px;
  -webkit-animation: slideOpenLimitsTools 0.25s forwards;
          animation: slideOpenLimitsTools 0.25s forwards;
}

@-webkit-keyframes slideOpenLimitsTools {
  0% {
    min-height: 0;
    max-height: 0;
  }
  100% {
    min-height: 76px; /* Adjust this value to control the final height of the rectangle */
    max-height: 76px; /* Adjust this value to control the final height of the rectangle */
  }
}

@keyframes slideOpenLimitsTools {
  0% {
    min-height: 0;
    max-height: 0;
  }
  100% {
    min-height: 76px; /* Adjust this value to control the final height of the rectangle */
    max-height: 76px; /* Adjust this value to control the final height of the rectangle */
  }
}

.copyDropperDesktop {
  background-color: #fff;
  border: 1px solid #696682;
  position: absolute;
  min-height: 0px;
  max-height: 0px;
  padding-top: 0px;
  padding-bottom: 0px;

  min-width: 154px;
  top: 45px;
  right: 4.2%;
  min-height: 0px;
  max-height: 0px;
  z-index: 999;
  margin-right: 258px;
  border-radius: 5px 0 5px 5px;
  -webkit-animation: slideOpenCopyTools 0.25s forwards;
          animation: slideOpenCopyTools 0.25s forwards;
}

@-webkit-keyframes slideOpenCopyTools {
  0% {
    min-height: 0;
    max-height: 0;
  }
  100% {
    min-height: 184px; /* Adjust this value to control the final height of the rectangle */
    max-height: 184px; /* Adjust this value to control the final height of the rectangle */
  }
}

@keyframes slideOpenCopyTools {
  0% {
    min-height: 0;
    max-height: 0;
  }
  100% {
    min-height: 184px; /* Adjust this value to control the final height of the rectangle */
    max-height: 184px; /* Adjust this value to control the final height of the rectangle */
  }
}

.toolsDropperDesktop {
  background-color: #fff;
  border: 1px solid #696682;
  position: absolute;
  /* min-height: fit-content;
  max-height: fit-content; */
  /* min-height: 511px !important;
  max-height: 511px !important; */
  padding-top: 0px;
  padding-bottom: 0px;

  min-width: 154px;
  /* min-height: 0px;
  max-height: 0px; */
  top: 45px;
  right: 4.2%;
  z-index: 999;
  margin-right: 158px;
  border-radius: 5px 0 5px 5px;
  -webkit-animation: slideOpen 0.2s forwards;
          animation: slideOpen 0.2s forwards;
}
@-webkit-keyframes slideOpen {
  0% {
    min-height: 0;
    max-height: 0;
  }
  100% {
    min-height: 470px; /* Adjust this value to control the final height of the rectangle */
    max-height: 470px; /* Adjust this value to control the final height of the rectangle */
  }
}
@keyframes slideOpen {
  0% {
    min-height: 0;
    max-height: 0;
  }
  100% {
    min-height: 470px; /* Adjust this value to control the final height of the rectangle */
    max-height: 470px; /* Adjust this value to control the final height of the rectangle */
  }
}

.schedActionItem {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  min-height: 40px;
  min-width: 100%;
  padding-right: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #143051;
  border-bottom: 1px solid #cce3ff;
  cursor: pointer;
}

.schedActionItem:last-child {
  border: none;
}

.schedActionItem:hover {
  background-color: #f4f8fe;
  color: #897cf6;
  /* transform: scale(1.025); */
}

.closeToolsImg {
  max-width: 8px;
  margin-left: 6px;
}

.creatingShiftFader {
  pointer-events: none;
  opacity: 0.3;
}

.publishedStatusPublished {
  font-size: 0.74em;
  margin-top: 8px;
  background-color: #7fbb67;
  color: #5a9e3f;
  color: white;
  border: 1px solid #5a9e3f;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  line-height: 26px;
  padding-left: 2px;
  font-weight: 500;
  padding-right: 2px;
  padding-top: 1px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-right: 10px;
  text-align: center;
  max-width: 60px;
  line-height: 10px;
  cursor: default;
}

.publishedStatusUnpublished {
  font-size: 0.74em;
  margin-top: 8px;
  background-color: #7495ba;
  color: #496375;
  color: white;
  border: 1px solid #496375;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  line-height: 26px;
  padding-left: 2px;
  font-weight: 500;
  padding-right: 2px;
  padding-top: 1px;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;

  margin-right: 10px;
  text-align: center;
  max-width: 60px;
  line-height: 10px;
  cursor: default;
}

@media only screen and (min-width: 620px) {
}

.copyToolsBtn {
  border-radius: 5px;
  color: #fff;
  min-width: 111px;
  min-height: 30px;
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
  align-items: center;
  margin-right: 7px;
}

.schedToolsSideBtnClose {
  background-color: #656095;
  max-width: 28px;
  position: fixed;
  top: 128px;
  right: 4.3%;
  margin-right: 334px;
  border-radius: 0 5px 0px 0;
  border: 1px solid #696682;
  border-bottom: none;
  min-width: 28px;
  min-height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2px;
  padding-top: 2px;
}

.schedToolsSideBtnCloseLimits {
  background-color: #656095;
  max-width: 28px;
  position: fixed;
  top: 128px;
  right: 4.3%;
  margin-right: 216px;
  border-radius: 0 5px 0px 0;
  border: 1px solid #0af;
  border-bottom: none;
  min-width: 28px;
  min-height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2px;
  padding-top: 2px;

  border-color: #696682;
}

.schedToolsSideBtnCloseCopy {
  background-color: #656095;
  max-width: 28px;
  position: fixed;
  top: 128px;
  right: 4.3%;
  margin-right: 98px;
  border-radius: 0 5px 0px 0;
  border: 1px solid #696682;
  border-bottom: none;
  min-width: 28px;
  min-height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2px;
  padding-top: 2px;
}

.activeToolsCogImg {
  max-width: 13px;
  margin-left: 0px;
  margin-top: 0px;
  margin-right: 7px;
}

.inactiveCogImg {
  margin-left: 0px;
  margin-right: 8px;
  margin-top: 1px;
}

.inactiveCogImgMob {
  margin-left: 0px;
  margin-right: 8px;
  margin-top: -1px;
}

.desktopToolsTxtActive {
  font-weight: 500;
  font-size: 0.85em;
  margin-left: -9px;
  color: white;
}

.closeToolsImgDesktopTools {
  min-width: 10px;
  max-width: 10px;
  margin-top: -1px;
  margin-right: 5px;
  margin-left: -6px;
}

.closeToolsImgDesktop {
  min-width: 10px;
  max-width: 10px;
}

.toolsToolsMobOnlyBtn {
  background-color: #8f85eb;
  border: 1px solid #756dba;
  border-radius: 5px;
  color: #fff;
  min-width: 94px;
  max-width: 94px;
  min-height: 30px;
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
  align-items: center;
  margin-right: 7px;
}

.toolsToolsMobOnlyBtn:hover {
  background-color: #8d85d2 !important;
}

.pubToolsBtn {
  min-width: 116px;
}

.extendPubBtn {
  min-width: 132px;
}

.toolsCopy {
  max-width: 10px;
  margin-right: 7px;
  margin-top: 2px;
}

.toolsLimit {
  max-width: 13px;
  margin-right: 6px;
  margin-top: 2px;
}

.whitePowerLim {
  max-width: 13px;
  min-width: 13px;
  margin-right: 7px;
  padding: 1px;

}
.toolsPublish {
  max-width: 13px;
  margin-right: 8px;
  margin-left: 2px;
  margin-top: 2px;
}
.toolsCog {
  max-width: 13px;
  margin-right: 7px;
  margin-top: 2px;
}
.desktopToolsBtnTxt {
  background-color: #8f85eb;
  min-width: 80px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding-bottom: 1px;
  cursor: pointer;
  border: 1px solid #756dba;
  border-radius: 5px 0 0 5px
}

.desktopToolsBtnTxtToolsOnly {
  min-width: 66px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding-bottom: 1px;
  cursor: pointer;
  /* border: 1px solid #696682; */
  border-radius: 5px 0 0 5px;
  border-right: none;
  padding-left: 7px;
  /* background-color: #8f85eb; */
  /* border: 1px solid #756dba; */
}

.desktopToolsBtnTxtToolsonly {
  border-right: none;
}

.desktopToolsBtnTxtToolsonly:hover {
}

.toolsPublishTxt {
  min-width: 85px;
}
.extendPubTxt {
  min-width: 126px;
  padding-right: 3px;
  margin-right:1px;
}
.desktopToolsBtnTxt:hover {
  background-color: #8d85d2;
}
.toolsDownArrContainer {
  display: flex;
  /* background-color: #656095; */
  align-items: center;
  justify-content: center;
  min-width: 28px;
  min-height: 35px;
  margin-left: 2px;
  cursor: pointer;
  /* border: 1px solid #595672; */
  background-color: #8f85eb;
  border: 1px solid #756dba;
}

.toolsDownArrContainerToolsOnly {
  display: flex;

  align-items: center;
  justify-content: center;
  min-width: 28px;
  min-height: 30px;

  cursor: pointer;
  /* border: 1px solid #696682; */
  border-radius: 0 5px 5px 0;
  border-left: none;
  margin-right: 6px;
  /* background-color: #8f85eb; */
  /* border: 1px solid #756dba; */
}

.toolsDownArrContainer:hover {
  background-color: #8d85d2;
}

.toolsDownArrContainerWithoutMarginL {
  margin-left: 0px;
  border-left: 0px solid #0af;
}

.toolsDownArrContainerWithoutMarginL:hover {
  background-color: none;
  border-color: #05f;
}
.desktopTooslBtnDownImg {
  max-width: 10px;
  min-width: 10px;
}

.fadePublishBtn {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}

.shCostSpan {
  margin-left: 8px;
  /* padding-left: 5px; */
  /* padding-right: 5px; */
  color: #cce3ff;

  color: #143051;

  padding-bottom: 1px;
  font-weight: 600;
  font-size: 11px;
  border-radius: 10px;
}

.salariedCostSpan {
  margin-left: 5px;
  padding-left: 1px;
  padding-right: 2px;

  color: #bac5d3;
  padding-bottom: 1px;
  font-weight: 600;
  font-size: 10px;
  border-radius: 2px;
}

.salariedCostSpanLeave {
  color: #a8cfff;
  font-size: 10px;
}

.openShiftsNameDiv {
  min-height: 50px;
  max-height: 50px;
  /* margin-bottom: -9px; */
  font-size: 0.9em;
  font-weight: 500;
  color: #143051;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border-right:3px solid #dbe8f7;
  border-left:1px solid #dbe8f7
}

.openShiftsNameDiv2 {
  min-height: 50px;
  max-height: 50px;
  margin-bottom: -9px;
  font-size: 10px;
  font-weight: 500;
  color: #143051;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  margin-right: 8px;
}
.openShiftsArrayDiv {
  min-height: 50px;
  max-height: 50px;
  /* background-color: #fff; */
/* border-bottom:1px solid #e3e8ee; */
  /* margin-bottom: -10px; */
  display: flex;
  padding-left: 5px;
  padding-right: 13px;
}

.collapseOpenTxt {
  font-size: 0.55em;
  -webkit-transform: rotate(-25deg);
          transform: rotate(-25deg);
  margin-left: 7px;
}

.openShiftUnit {
  background-color: #fff;
  max-height: 40px;
  min-height: 40px;
  margin-left:2px;
  /* border-left:5px solid #00aaff; */
  max-width: 135px !important;
  min-width: 135px !important;
  border: 1px dashed #bbcada !important;
  box-shadow: none !important;
}
.myOpenShiftUnit {
  background-color: #fff;
  max-height: 40px;
  min-height: 40px;
  border-left: 5px solid #00aaff;
  max-width: 135px !important;
  min-width: 135px !important;
  border: 2px dashed #bbcada !important;
  box-shadow: none !important;
}
.myOpenShiftUnit:hover {
  background-color: #fff;
  max-height: 40px;
  min-height: 40px;
  border-left: 5px solid #00aaff;
  max-width: 135px !important;
  min-width: 135px !important;
  border: 2px dashed #214978 !important;
  box-shadow: none !important;
  padding-left: 2px !important;
  /* padding-right:5px !important */
}
.openShiftUnit:hover {
  /* border:2px solid #143051;
  padding-left:2px !important;
  border-left:5px solid #143051 !important; */
  /* margin-left:2px !important; */
  max-width: 135px !important;
  min-width: 135px !important;
  border-color: #214978 !important;
}

.onShiftBoxRight {
  max-width: 14px;
  margin-left: 4px !important;
}

.onShiftBoxRightMy {
  max-width: 14px;
  margin-left: 16px !important;
}

.greenHorizBtn {
  opacity: 0.5;
  margin-top: 13px;
}

.zeroOpensHeight {
  max-height: 30px;
  min-height: 30px;
}

.noShiftsOpenSpanTxt {
  opacity: 0.5;
  display: flex;
  align-items: center;
}

.openShiftsToTheRight {
}

.addOpenShiftEmpty {
  min-height: 40px;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3em;
  padding-bottom: 5px;
  font-weight: 500;
  color: #9374d6;
  opacity: 0.5;
  cursor: pointer;
  margin-left: 4px;
  border-radius:6px;
  margin-bottom:5px;
  margin-top:5px;
  /* padding-top:5px; */
}

.addOpenShiftEmptyNil {
  min-height: 20px;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3em;
  padding-bottom: 5px;
  font-weight: 500;
  color: #9374d6;
  opacity: 0.5;
  cursor: pointer;
  margin-left: 4px;
  border-radius:6px;
  margin-bottom:5px;
  margin-top:5px;
}



.addShiftPlusTxt {
  cursor: pointer;
  font-size: 28px;
  /* margin-left:5px; */
}



@media only screen and (min-width: 820px) {
  .addOpenShiftEmpty:hover {
    background-color: #eeebf3;
    border-bottom: 1px solid #e3ebf5;
    padding-top: 1px;
  }
  .addOpenShiftEmptyNil:hover {
    background-color: #eeebf3;
    border-bottom: 1px solid #e3ebf5;
    padding-top: 1px;
  }
}


.userHoursP {
  min-height: 16px;
  text-align: right;
  display: flex;
  align-items: center;
  font-size: 9px;
  color: #9dabbe;
  border-radius: 3px;
  font-weight: 500;
}

.userHoursPMob {
  margin-right: 7px;
}

.userHoursPMyRota {
  min-height: 16px;
  text-align: right;
  display: flex;
  align-items: center;
  padding-left: 0px;
  padding-right: 8px;
  color: #8ea7c5;
  border-radius: 3px;
  font-weight: 500;
  font-size: 9px;
}

.smallerIcon {
  max-width: 26px;
}

.claimantsTitle {
  min-width: 100%;
  margin-left: 3%;
}

.selectClaimantTitle {
  background-color: #496375;
  border: 1px solid #143051;
  color: white;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px 5px 5px 0;
  max-height: 30px;
  display: flex;

  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: -10px;
  position: relative;
  z-index: 999;
  font-size: 16px;
}
.claimantsDiv {
  max-width: 90%;
  background-color: #f4f9ff;
  min-height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 0.9em;
  font-weight: 400;
  margin-bottom: 20px;
  border: 1px solid #bfccdc;
  border-radius: 0 5px 5px 5px;
  position: relative;
  z-index: 1;
}

.userClaimantsDiv {
  max-width: 100%;
  background-color: #f4f9ff;
  min-height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 0.9em;
  font-weight: 400;
  margin-bottom: 20px;
  border: 1px solid #bfccdc;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  margin-top: 16px;
}

.claimantUnit {
  border-bottom: 1px solid #dde1e6;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.claimantUnit:first-child {
  padding-top: 10px;
}
.claimantUnit:last-child {
  border: none;
}

.userClaimantUser {
  border-bottom: 1px solid #dde1e6;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.userClaimantUser:last-child {
  border: none;
}

.giveClaimantBtn {
  background-color: #07f;
  color: white;
  border: #0469de solid 1px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  border-radius: 5px;
  padding-right: 10px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 2px;
}

.giveClaimantBtn:active {
  -webkit-transform: scale(0.94);
          transform: scale(0.94);
}

.claimUserTopDiv {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cancelClaim {
  font-size: 12px;
  background: #214978;
  border: 1px solid #143051;
  color: white;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  max-height: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: 500;
  margin-left: 82px !important;
  cursor: pointer;
}

.cancelClaim:hover {
  background-color: #143051;
}
.giveClaimantBtn:hover {
  background-color: #0469de;
}
.claimantTxtLeft {
  max-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 10px;
  padding-left: 4px;
  padding-bottom: 10px;
}

.minW100 {
  min-width: 100%;
}

.claimantName {
  font-size: 0.9em;
  max-height: 26px;
  display: flex;
  align-items: center;
  font-weight: 500;
  min-width: 88px;
}

.claimantTsSpan {
  font-size: 12px;
  font-weight: 500;
  max-height: 26px;
  display: flex;
  align-items: center;
  color: #bac5d3;
  line-height: 16px;
}

.usersHoursThisWeek {
  font-size: 0.75em;
  font-weight: 400;
  color: #8ea7c5;
  max-height: 26px;
  display: flex;
  align-items: center;
  margin-top: -2px;
}


.hoveredDayColBorderLandR {
}

.addShiftHereBox {
  background-color: #00bbff;

  border: 1px solid #5479a3;
  color: #143501;
  border-radius: 5px;
  max-height: 48px;
  min-height: 48px;
  margin-top: 8px;
  margin-left: 10px;
  padding-top: 6px;

  padding-left: 8px;
  padding-right: 18px;

  display: flex;

  align-items: flex-start;
  justify-content: center;
  font-size: 1.5em;
  font-weight: 500;
  -webkit-animation-name: slideMoveHere !important;
          animation-name: slideMoveHere !important;
  -webkit-animation-duration: 0.2s !important;
          animation-duration: 0.2s !important;
  cursor: pointer;
}

.addShiftHereBox:hover {
  background-color: #0cf;
  -webkit-transform: scale(1.025);
          transform: scale(1.025);
}

.MoveShiftHereTitleTxt {
  color: #fff;
  font-size: 1.2em;
}

.moveShiftHereTimes {
  color: #3b597c;
  font-size: 1em;
  margin-top: 0px;
}

.moveHereTxtContainer {
  text-align: left;
}
.moveHereArrImg {
  max-width: 40px;
  margin-top: -4px;
}

.movingShiftBg {
  background-color: #fff;

  border: 1px dashed #143051;
  border-radius: 5px;
}

.allOtherShiftBgsWhenMoveActive {
  opacity: 0.4 !important;
}

.moveActiveRowBg {
}

.selectedDayUserBgWhenActiveMove {
  background-color: #d2d9e2;
}

.hoveredMoveHereCell {
  border-radius: 0px;
  background-color: #f3f8ff;
}

.hoveredMoveHereCellNone {
  box-shadow: inset 0px 0px 0px 0px #f00;
  border-radius: 0px;
  background-color: #8ea7c5;
}

.plusShiftUnit {
  color: #2c7eb9;
  font-size: 4em;
  font-weight: 500;
  min-width: 20px;
  min-height: 30px;
  max-height: 30px;
  max-width: 20px;
  margin-left: 9px;
  margin-right: 9px;
  line-height: 30px;

  border-radius: 5px;
  margin-top: -24px;
}

.moveShiftBoxHereBox {
  border: 1px dashed #143051;
  margin-top: -24px;
}
.dupelicateShiftBoxHereBox {
  border: 1px dashed #a387df;
  margin-top: -22px;
  min-width: 143px;
  padding-right:0px !important
}

.pointerOverCell {
  cursor: pointer;
}

.emptyShiftsMoveHere {
  font-size: 0.42em;
  margin-left: -100px;
  margin-right: 0px;
  margin-top: -4px;
}

.moveHereTxt {
  font-size: 11px;
  color: #5479a3;
  color: #07f;
  margin-left: 0px;
  font-weight: 500;
  margin-top: 2px;
  min-width: 122px;
}

.x298329842 {
  min-width: 8px !important;
  max-width: 8px !important;
  /* background-color:orange !important */
}

.duplicateRightBg {
  background-color: #a387df;
  margin-right: -22px;
}

.cancelMoveOrDupeBtn {
  max-width: 25px;
  margin-left: 10px;
  margin-right: 0px;
  background-color: #140351;
  min-width: 25px;
  padding: 7px;
  border-radius: 5px;
  background-color: #214978;
  border: 1px solid #140351;
  max-height: 25px;
  margin-top: -22px;
}

.cancelMoveOrDupeBtn:hover {
  opacity: 0.8;
}

.cancelMoveOrDupeBtnEmpty {
  max-width: 25px;
  margin-left: 10px;
  margin-right: -80px;
  min-width: 25px;
  max-height: 25px;
  padding: 7px;
  border-radius: 5px;
  background-color: #214978;
  border: 1px solid #140351;
}

.cancelMoveOrDupeBtnEmpty:hover {
  opacity: 0.8;
}

.moveShiftHereDottedIcon {
  max-width: 24px;
  margin-left: -16px;
}



.moveShiftHereDottedIconInline {
  max-width: 24px;
  margin-left: 10px;
  margin-right: -20px;
  margin-top: -21px;
}



.blueLoadingMoveImg {
  max-width: 50px;
  min-width: 50px;
  max-height: 18px;
  margin-left: -10px;
  margin-top: -2px;
}

.blueLoadingMoveImgInline {
  max-width: 50px;
  min-width: 50px;
  max-height: 18px;
  margin-top: -22px;
  margin-left: 10px;
  margin-right: 10px;
}

.makeCanBtnInvis {
  visibility: hidden;
  pointer-events: none;
}

.pasteNotAvail {
  opacity: 0.5;
  pointer-events: none;
}

.copiedWeekTxt {
  pointer-events: none;
  opacity: 0.5;
}

.jtUserSchedLimitDiv {
  min-width: 128px;
  display: flex;
  margin-left: 8px;
  padding-left: 2px;
  min-height: 32px;
  max-height: 32px;
  padding-right: 2px;
  margin-top:3px;
  justify-content: flex-end;
}
.schedLimitDataTxtCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.totAllowedOff {
  color: #4b1caf;
  font-size: 1.22em;
  display: none;
}

.totAllowedOffActual {
  color: #214978;
  font-size: 12px;
  font-weight: 500;
  margin-top: -1px;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 1px;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  white-space: nowrap;
}

.userTotAllOffActual {
  color: #fff;
  font-size: 1.6em;
  font-weight: 500;
  margin-top: -1px;
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 3px;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  white-space: nowrap;
  background-color: #51971f;
  border-radius: 3px;
  padding-top: 2px;
  border: 1px solid #143051;
}

.schedLimitIcon {
  max-width: 20px;
  margin-left: 6px;
  margin-right: 4px;
  cursor: pointer;
}

.schedLimitIcon:active {
  -webkit-transform: scale(0.94);
          transform: scale(0.94);
}

.schedLimitIcon:last-child {
  margin-right: 6px;
  margin-left: 3px;
}

.secondaryUserDiv {
  font-size: 0.7em;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 0px 0 0 5px;
  color: #214978;
  margin-top: -13px;
  margin-bottom: -4px;
  padding-bottom: 1px;
  padding-right: 6px;
  z-index: 99999999;
  border-top: 1px solid #bac5d3;
  margin-left: 5px;
  padding-top: 2px;
  visibility: hidden;
}

.loadingOpensUnit {
  min-height: 50px;
  max-height: 50px;
}
.limitsDropper {
  background-color: #fff;
  border: 1px solid #696682;
  position: absolute;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  padding-top: 0px;
  padding-bottom: 0px;

  min-width: 154px;
  top: 45px;
  right: 4%;
  z-index: 999;
  border-radius: 10px 0 10px 10px;
  -webkit-animation-name: heightDown !important;
          animation-name: heightDown !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.limitTitle {
  font-size: 10px;
  font-weight: 500;
  min-height: 100%;
  display: flex;
  align-items: center;
  padding-right: 6px;
  color: #859dbb;
}

.limitOffSpan {
  font-size: 0.8em;
  opacity: 0.7;
}

.limitExceededCurrOff {
}

.limitOKMaxOff {
  border-radius: 3px;
  padding-left: 6px;
  padding-right: 6px;
  color: #cb5858 !important;
  border: 1px solid #cb5858;
  background-color: #fff2f2;
  min-height: 20px;
  padding-top: 4px;
  margin-top:-3px;
}

.makeOpenTitle {
  margin-left: 18px;
  font-size: 1.15em;
  line-height: 28px;
  font-weight: 500;
  color: #143051;
  margin-top: 12px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.assignTitle {
  margin-left: 23px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  color: #143051;
  margin-top: 12px;
  margin-bottom: 8px;
}

.makeOpenBtn {
  font-size: 14px;
  color: #fff;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 21px;

  border-radius: 5px;
  min-height: 35px;
  margin-top: 18px;
  margin-bottom: 8px;
  cursor: pointer;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.makeOpenBtn:hover {
  background-color: #bbcada;
}

.viewChBtn {
  font-size: 14px;
  color: #fff;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  font-weight: 500;
  cursor: pointer;
  padding-left: 13px;
  padding-right: 13px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 21px;

  border-radius: 5px;
  min-height: 35px;
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.viewChBtn:hover {
  background-color: #bbcada;
}

.pViewChBtn {
  background-color: #9a74eb;
  border: 1px solid #965ade;
  padding-left: 10px;
  padding-right: 10px;
}

.pViewChBtn:hover {
  background-color: #965ade;
}
.showChBackBtn {
  font-size: 14px;
  color: #fff;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 5px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 21px;

  border-radius: 5px;
  min-height: 35px;
  margin-top: 18px;
  margin-bottom: 3px;
  cursor: pointer;
  max-width: 70px;
}

.showChBackBtn:hover {
  background-color: #bbcada;
}

.backChangesImg {
  max-width: 12px;
  margin-right: 6px;
  margin-left: -5px;
}


.amendsGoImg {
  max-width: 10px;
  margin-left: 4px;
  margin-right: 0px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);

}

.outstandingChReqsSpan {
  border-left: 1px solid white;
  max-height: 20px;
  line-height: 20px;
  margin-left: 8px;
  padding-left: 7px;
  padding-right: 2px;
}

.addTagTxtBtn {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 2px;
  padding-right: 2px;
  max-height: 30px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  margin-top: 17px;
  margin-bottom: 8px;
  color: #496375;
  border: #e3e8ee solid 2px;
  background-color: #fff;
  min-width: 87px;
}

.addTagTxtBtn:active {
}

.addTagTxtBtn:hover {
  border-color: #c5d1e1;
}

.makeOpenTxtDiv {
  max-width: 60%;
}

.addShftCostRow {
  display: flex;
  justify-content: space-between;
  min-width: 100px;
}
.addShiftCostTxt {
  margin-top: 7px;
}

.makeOpenExplainTxt {
  color: #516b8b;
  font-weight: 400;
  font-size: 12px;
  margin-left: 23px;
  line-height: 16px;
  /* margin-top: 5px; */
  margin-bottom: 13px;
  margin-top: 6px;
}

.assignOpenDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 150px;
  min-width: 150px;
  line-height: 26px;
  height: 35px;
  background: url(/static/media/downArrowWhite2.887d57ce.svg) no-repeat right
    #4b1caf;
  border: 1px solid #9374d6;
  -webkit-appearance: none;
  background-position-x: 124px;
  display: flex;
  cursor: pointer;
  background-color: rgb(83, 155, 237);
  border-color: #2c7eb9;
  margin-top: 14px;
  margin-right: 15px;
}

.chooseClaimantSpan {
  font-weight: 400;
  margin-left: 10px;
  font-size: 14px;
}

.limitRowInitialCol {
  /* background-color: #e3e8ee; */
  /* color: white; */
  min-height: 30px;
  max-height: 30px;
  border-right: 3px solid #dbe8f7;
    margin-bottom: 0px;
  border-radius: 5px 0 0 5px;
  /* border-top: 1px solid #bbcada;
  border-bottom: 1px solid #bbcada;
  border-left: 1px solid #bbcada; */
  font-weight: 500;
  padding-right: 8px;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
}

.limitTabPowerIcon {
  max-width: 22px;
  /* min-width: 24px; */
  margin-left: 3px;
  cursor: pointer;

  padding: 4px;
  border: 1px solid #30305f;
  border-radius: 5px;
  min-width: 22px;
  background-color: #7a7ac1;
}

.limitTabPowerIconOff {
  max-width: 24px;
  /* min-width: 24px; */

  margin-left: 3px;
  cursor: pointer;

  padding: 4px;
  border: 1px solid #96a6b9;
  border-radius: 5px;
  min-width: 21px;
  background: none;
  background-color: #bbcada;
}
.limitTabPowerIconOff:hover {
  background-color: #cbc7df;
}
.limitRowDayDataDiv {
    /* background-color: #e3e8ee; */
    /* color: white; */
    min-height: 30px;
    max-height: 30px;
    /* margin-top: 10px; */
    margin-bottom: 0px;
    border-bottom: 1px solid #f2f5f8;
    border-top:1px solid #e3e8ee;

    /* border-bottom: 1px solid #bbcada; */
    padding-right: 5px;
    padding-left: 3px;
}

.collapseLimitTitle {
  font-size: 0.8em;
  padding-left: 6px;
}
.limitTitleAndPower {
  display: flex;
  max-width: 88px;
cursor: pointer;
  align-items: center;
  min-height: 100%;
  padding-left: 5px;
  margin-right:-3px;
}
.limitDayTitle {
  font-size: 10px;
  padding-right: 8px;
  margin-top: 2px;
color: #859cb6;
  font-weight: 500;
}

.limitByAllTxt {
  padding-right: 5px;
  font-size: 10px;
  margin-left: 10px;
  margin-top: 2px;
  font-weight: 500;
  color:#214978
}

.dayLimitPowerIcon {
  max-width: 24px;

  margin-top: 1px;
  cursor: pointer;
}

.dayLimitPowerDiv {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  /* flex-direction: row-reverse; */
}

.jobTitleOrDayDiv {
  display: flex;

  align-items: center;
  justify-content: space-between;

  font-size: 1.2em;
}

.limitKnob {
  margin-top: -6px;
}

.selectTeamOrJtLimitDropdown {
  color: #214978;
  border-radius: 3px;
  font-size: 10px;
  padding-left: 7px;
  text-align: left;
  max-width: 70px;
  min-width: 70px;
  line-height: 21px;
  height: 24px;
  /* background: url(/static/media/downArrowWhiteTeamSched.358e1815.svg) no-repeat right #7792b0; */
  background: url(/static/media/limDownArr.199c335e.svg) no-repeat right #fff;
  /* url("../img/general/downArrowGrey.svg") */
  /* border: 1px solid #516b8b; */
  -webkit-appearance: none;
  background-position-x: 42px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 2px;
  text-align: right;
  padding-right: 25px;
  padding-bottom: 1px;
}

.fadeWhilstMoving {
  opacity: 0.3;
  pointer-events: none;
}
.blueLoadingMoveImg {
  opacity: 0.5;
}
.fadeLimitDiv {
  background-color: #e3e8ee;
  border-top: #bbcada 1px solid;
  border-bottom: #bbcada 1px solid
}

.teamLimitInitialBlank {
  min-height: 30px;
  max-height: 30px;
  margin-bottom: -3px;
  /* border-bottom: 2px solid #bac5d3; */
}

.teamLimitEditDiv {
  /* border-bottom: 1px solid #bbcada; */
  min-height: 30px;
  max-height: 30px;
  margin-bottom: -3px;

  color: #143051;

  padding-bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.limitSettingsIcon {
  max-width: 22px !important;
  min-width: 22px !important;
  margin-left: 4px;
  cursor: pointer;
}

.limitTabTitle {
  margin-left: -3px;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
}

.limitTabTitleMob {
  font-size: 10px;
  text-align: left;
  line-height: 11px;
}

.notApplicTeamLimitEditDiv {
  background: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-top: none;
  min-height: 30px;
  max-height: 30px;
  margin-bottom: -3px;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;

  /* padding-top: 1px; */
  color: #bfccdc;
  font-weight: 500;
padding-bottom:4px;
  /* border-top:solid 1px #f2f5f8 */
}

.disableLimit {
  visibility: hidden;
}

.dayLimContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5em;
  min-height: 30px;
  max-height: 30px;
  min-width: 100%;
  border-top:solid 1px #f2f5f8;
  padding-bottom:3px;
}

.dayLimLeft {
  display: flex;
  margin-left: 5px;
  align-items: center;
  min-height: 100%;
  cursor: pointer;
  margin-left:9px
}

/* .dayLimLeft:hover {
  padding-top: 3px;
  padding-bottom: 2px;
  border-bottom: 1px solid #bbcada;
} */


.dayLimCurrOffTxt {
  font-weight: 500;
  font-size: 8px;
  opacity: 0.7;
  padding-right: 3px;
}
.dayLimCurrOffValue {
  margin-left: 1px;
  font-weight: 500;
  font-size: 1em;
}

.dayLimRight {
  display: flex;
  align-items: center;
}

.dayLimLimitTxt {
  font-weight: 500;
  margin-right: -2px;
  opacity: 0.7;
  font-size: 10px;
  display: none;
}

.dayLimPlus {
  max-width: 20px;
  margin-right: 6px;
  margin-left: 6px;
  cursor: pointer;

  border-radius: 3px;
  border: 1px solid #e0ebf8;
  background-color: #f7faff;
}

.dayLimPlus:hover {
  background-color: #fff;
}

.dayLimPlus:active {
  -webkit-transform: scale(0.94);
          transform: scale(0.94);
  background-color: #cbd7e6;
  opacity: 0.7;
}

.dayLimLimitValue {
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  min-width: 9px;
  justify-content: center;
}

.dayLimitOffSpan {
  font-size: 0.8em;
}

.limitExceedDayRed {
  background-color: #f07b7b;
  /* border-color: #460f0f; */
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  border: 1px #e56767 solid;
  border-radius: 3px;
  margin-bottom: 1px;
  color: #fff;
  margin-left: 5px;
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
  margin-right: -2px;
}

.limLoad {
  pointer-events: none;
  opacity: 0.5;
}

.bulkEditLimitUnderlay {
  background: rgba(0, 0, 0, 0.25);
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  /* pointer-events: none; */
  background-attachment: fixed;

  overflow: hidden;

  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(2px);
  background-color: rgb(122 122 122 / 30%);
}

.bulkEditLimitModalBox {
  /* background-color: #fff;
  color: #143051;
  border-radius: 5px; */

  min-width: 350px;
  max-width: 350px;
  /* -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* cursor: auto; */

  /* animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important; */


  background-color: #f4f8fe;
  color: #143051;
  border-radius: 10px;
  /* min-width: 380px; */
  /* max-width: 380px; */
  /* max-height: 610px; */
  /* box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  cursor: auto;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.availModalBox {
  background-color: #f4f8fe;
  color: #143051;
  border-radius: 10px;

  min-width: 380px;
  max-width: 380px;
  max-height: 610px;
  /* -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  cursor: auto;

  -webkit-animation-name: zoomModalIn !important;

          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.timesheetModalBox {
  max-height: 98vh !important;
}

.availModalBoxMob {
  border-radius: 5px 5px 0 0;
  min-width: 100vw;
  max-width: 100vw;
  max-height: 610px;
  position: fixed;
  bottom: 0;
  -webkit-animation-name: moveEditTeamUserBoxUp !important;
          animation-name: moveEditTeamUserBoxUp !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.x13892843 {
  min-width: 100vw !important;
  max-width: 100vw !important;
}

.templateSavedModal {
  background-color: #fff;
  color: #143051;
  border-radius: 10px;

  min-width: 270px;
  max-width: 270px;
  padding: 14px;
  /* -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  cursor: auto;

  -webkit-animation-name: zoomModalIn !important;

          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
}

.tempSavedTxt {
  font-weight: 500;
}

.tempSavedCloseBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  margin-top: 20px;
}

.tempSavedCloseBtn:hover {
  background-color: #214978;
}

.tempSavedCloseBtnPublish {
  font-size: 14px;
  color: #fff;
  background-color: #0bf;
  border: solid #0af 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  margin-top: 20px;
  margin-right: 10px;
}

.tempSavedCloseBtnPublish:hover {
  background-color: #0af;
}
.saveTemplateWideModalBox {
  min-width: 700px;
  max-width: 700px;
}

.saveTemplateModalUnit {
  min-width: 300px;
}

.loadTemplatemodalUnit {
  min-width: 300px;
  max-width: 300px;
  min-height: 248px;
}

.loadTemplatemodalUnitMob {
  min-width: 100%;
  max-width: 100%;
}

.bulkEditLimitModalHeader {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
}

.bulkEditLimitModalHeaderTitle {
  font-weight: 500;
  color: #143051;
  font-size: 18px;
}

.bulkEditCloseBlank {
  pointer-events: none;
  visibility: hidden;
}

.bulkEditingTeamTxt {
  margin-top: 15px;
  min-height: 52px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dde9f9;
  min-width: 100%;
  font-weight: 400;
  font-size: 12px;
  color: #516b8b;
  line-height: 16px;
  text-align: left;
  padding-right: 24px;
}

.availTxtInfo {
  margin-top: -3px;
  min-height: 24px;
  padding-bottom: 10px;
  font-size: 12px;
  line-height: 16px;
  color: #516b8b;
  padding-right: 50px;
  font-weight: 400;
  padding-left: 5px;
}

.saveTempTxtInstruction {
  min-height: 40px;
  /* padding-bottom: 8px; */
  /* border-bottom: 2px solid #143051; */
  font-size: 12px;
  color:#516b8b;
  padding-top: 10px;
  padding-bottom: 15px;
  padding-right:20px;
  /* padding-right: 40px !important; */
}

.bulkEditTextDiv {
  margin-top: 16px;
  text-align: left;
  /* padding-left: 5%; */
  /* padding-right: 5%; */
  font-size: 0.9em;
  display: flex;
  justify-content: space-between;
  max-width: 90%;
  margin-left: 5%;
  border-bottom: 1px solid #bac5d3;
  /* margin-bottom: 20px; */
}

.histClosedDaysTimeline 
.loadTempDivWithMutliWeek {
  margin-top: 16px;
  text-align: left;
  /* padding-left: 5%; */
  /* padding-right: 5%; */
  font-size: 0.9em;
  display: flex;
  justify-content: space-between;
}

.bulkEditTeamNameSpan {
  /* font-weight: 500; */
  /* color: #496375; */
  white-space: nowrap;

}

.bulkLimStartEndDateContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  align-items: flex-start;

  padding-top: 15px;
  min-width: 90%;
  max-width: 90%;

  padding-left: 5%;
}

.availBoxContainerWide {
  display: flex;
  flex-direction: row;
  justify-content: stretch;

  background-color: orange;

  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
}
.availBoxContainerMob {
  border-radius: 6px 6px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  align-items: stretch;
  max-height: 436px;
  padding-top: 16px;
  padding-bottom: 10px;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
}

.timesheetBoxContainerWide {
  display: flex;
  flex-direction: row;
  justify-content: stretch;

  background-color: orange;

  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
}
.timesheetBoxContainerMob {
  border-radius: 6px 6px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  align-items: stretch;
  max-height: 436px;
  /* padding-top: 24px; */
  padding-bottom: 10px;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
}
.availUnit1 {
  background-color: #f4f8fe;
  min-height: 96px;
  min-width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.availUnit1MobOnly {
  background-color: #f4f8fe;
  align-items: flex-end;
}

.availUnit2 {
  min-height: 350px;
  min-width: 70%;
  max-height: 192px;
  overflow-y: auto;
  border-top: 4px solid #cbd7e6;
  border-radius: 6px 6px 0 0;
  padding-top: 4px;
  padding-bottom: 20px;
}

.availActiveBorder {
  border-top: 4px solid #96e578;
  border-radius: 6px 6px 0 0;
}

.bulkStartDateTitle {
  text-align: left;
  padding-left: 10px;
  font-size: 12px;

  margin-bottom: 4px;
  font-weight: 500;
  color: #214978;
}

.bulkStartContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  padding-left: 5px;
}
.bulkStartContainer:last-child {
  margin-bottom: 48px;
}

.bulkStartWarn {
  color: #859dbb;
  font-size: 12px;
  font-weight: 500;
  margin-left: 0px;
  min-height: 30px;
  display: flex;
  align-items: center;
  margin-top: 22px;
  text-align: left;
  max-width: 100px;
}

.makeBulkEndDsMaxTs {
  background-color: #fff;
  border-radius: 5px;
  min-height: 34px;
  color: #859dbb;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
  margin-top: 20px;
  margin-right: 12px;
  font-size: 14px;
  border: 2px solid #dde9f9;
}

.makeBulkEndDsMaxTs:hover {
  border-color: #bbcada;
}

.bulkModalFooter {
  min-width: 90%;
  max-width: 90%;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 0.9em;
  margin: 0 auto;
  border-top: 1px solid #bac5d3;
  margin-top: 10px;
  font-weight: 500;
}

.desktopBulkModalFooter {
  min-width: 95% !important;
  max-width: 95% !important;
  margin-top: 30px;
}

.availModalFooter {
  min-width: 92%;
  max-width: 92%;
  font-size: 14px;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 4%;

  border-top: 1px solid #bac5d3;

  font-weight: 500;
}

.cancelBulkModalBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
}

.cancelBulkModalBtn:hover {
  background-color: #214978;
}

.saveBulkModalBtn {
  color: white;
  background-color: #07f;
  border: #0469de 1px solid;
  border-radius: 5px;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 60px;
  justify-content: center;
  font-weight: 500;
}

.saveBulkModalBtn:hover {
  background-color: #036ce4;
}

.sureCloseAvailNo {
  color: white;
  background-color: #07f;
  border: #0469de 1px solid;
  border-radius: 5px;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.sureCloseAvailNo:hover {
  background-color: #036ce4;
}
.sureCloseAvailYes {
  background-color: #3b597c;
  color: white;
  border: 1px solid #214978;
  border-radius: 5px;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 30px;
  font-size: 14px;
  font-weight: 500;
  min-width: 56px;
  justify-content: center;
  cursor: pointer;
}

.sureCloseAvailYes:hover {
  background-color: #214978;
}
.navyBgNextBtnBulk {
  border-color: #0af;
  background-color: #0bf;
}

.navyBgNextBtnBulk:hover {
  background-color: #0af;
}
.fadeBulkSaveBtn {
  opacity: 0.3;
  pointer-events: none;
}

.bulkTickDiv {
  margin-top: 20px;
  min-width: calc(100% - 174px);
  display: flex;
  padding-left: 10px;
}
.bulkTick {
  max-width: 24px;
}
.bulkPageDiv {
  max-width: calc(100% - 50px);
  margin: 0 auto;
}

.saveTempPageMob {
  max-width: calc(100% - 35px);
  margin: 0 auto;
  margin-top:-5px;
  font-size:14px;
  /* background:pink */
}

.saveTempPageWide {
  max-width: calc(100% - 50px);
  margin: 0px auto;
  display: flex;
  justify-content: space-around;
}
.flexReverse {
  flex-direction: row-reverse;
}
.bulkLimitByDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
}

.bulkLimitByTitle {
  font-size: 12px;
  font-weight: 500;
  margin-left: 4px;
  margin-top: 2px;
  color: #143051;
}

.selectDateRangeDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 26px;
  margin-top: 6px;
}

.selectDateRange {
  font-size: 12px;
  font-weight: 500;
  margin-left: 0px;
  margin-top: 8px;
  color: #214978;
  /* border-left: 2px solid #214978; */
  padding-left: 7px;
}

.selectDateRangeMgrAvail {
  border-left:none;
}

.selectDateRangeTeam {
  margin-left: 14px;
  margin-right:10px;
  margin-top: 1px;
  color: #143051;
}

.selectTeamOrJtLimitDropdownBulk {
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  padding-left: 10px;

  text-align: left;
  max-width: 130px;
  min-width: 130px;
  line-height: 21px;
  margin-left: 8px;

  height: 35px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;

  border: 1px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  -webkit-appearance: none;
  background-position-x: 106px;
  font-weight: 500;
  cursor: pointer;
  padding-top: 1px;
}

.bulkButtons {
  min-width: 100%;
}
.teamDataInputDiv {
  display: flex;
  max-height: 194px;
  min-height:194px;
  max-width: 300px;
  margin-top: 8px;
  justify-content: space-between;
  border: 1px solid #cce3ff;
  border-radius: 10px;
  margin-bottom: 4px;

}

.fadeBulkPlusMinus {
  opacity: 0.3;
  pointer-events: none;
}

.bulkDayPowerIcon {
  cursor: pointer;
  max-width: 28px;
}

.bulkDayPowerDivider {
  min-height: 1px;
  background-color: #cbd7e6;
  min-width: 100%;
  margin-bottom: 8px;
  margin-top: 3px;
}

.bulkDayCol {
  max-width: 42px;
  min-width: 42px;

  border-right: 1px solid #cbd7e6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding-top: 10px;
  padding-bottom: 5px;
}

.bulkDayCol:last-child {
  border-right: none;
}

.bulkDayPlusIcon {
  max-width: 28px;
  cursor: pointer;
}

.bulkDayPlusIcon:active {
  -webkit-transform: scale(0.96);
          transform: scale(0.96);
}

.bulkDayTitle {
  font-size: 0.8em;
  font-weight: 500;
  margin-bottom: 8px;
}

.bulkDayVal {
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  margin-bottom: 4px;
}

.offTxtBelowVal {
  font-weight: 800;
  font-size: 0.65em;
  margin-top: -6px;
  margin-bottom: 7px;
  color: #859dbb;
}

.allowTxtAboveVal {
  font-weight: 500;
  font-size: 0.65em;
  margin-top: 4px;
  margin-bottom: -4px;
  color: #5479a3;
}

.bulkLimitEditModalKeyDiv {
  min-width: 100%;
  min-height: 8px;
  margin-top: 20px;
  margin-bottom: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bulkKeyContainer {
  min-width: 16px;
  min-height: 100%;

  display: flex;
  justify-content: space-between;
}

.bulkKeyOn {
  min-width: 6px;
  max-height: 6px;
  min-height: 6px;
  max-width: 6px;
  background-color: #143051;
  border-radius: 10px;
}

.bulkKeyOff {
  min-width: 6px;
  max-height: 6px;
  min-height: 6px;
  max-width: 6px;
  background-color: #8ea7c5;
  border-radius: 10px;
}

.offVisZero {
  visibility: hidden;
}

.jtDataInputDiv {
  min-width: 100%;
  max-width: 100%;
  margin-top: 10px;
}

.loadingBulkDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  min-height: 100px;
  font-weight: 500;
  color: #07f;
  padding-bottom: 60px;
}
.jtSelectBulkMenu {
  display: flex;
  align-items: center;
  max-width: 100%;
  min-width: 100%;
  overflow-x: auto;
  margin-top: 12px;
border: 1px solid #e0ebf8;
  background-color: #f4f8fe;
  border-radius: 5px;
  padding: 3px;
  padding-right: 5px;
}
.bulkJtMenuItem {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  text-overflow: ellipsis;
  max-width: 100px;
  min-width: 100px;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;

  border-right: 1px solid #acc3e1;
  color: #5479a3;
  cursor: pointer;
  min-height: 54px;

  padding-left: 10px;
  padding-right: 10px;
}

.bulkJtMenuItem:first-child {
  margin-left: 0px;
}

.bulkJtMenuItem:last-child {
  border-right: none;
}

.selectedBulkJt {
  background-color: #214978;
  border: 2px solid #143051;
  color: white;
  cursor: default;
  border-radius: 5px;
  padding: 6px;
  padding-right: 10px;
  padding-left: 10px;
}

.noBorderRightJt {
  border: none;
}

.noBorderRight {
  border-right: none !important;
}

.limitByJtInstruct {

  text-align: left;
  margin-left: 4px;

  font-weight: 400;
  font-size: 12px;
  color: #516b8b;
  line-height: 16px;
}

.jtEditDivContainer {
  display: flex;
  max-height: 220px;
  max-width: 300px;
  margin-top: 16px;
  justify-content: space-between;
  border: 1px solid #cce3ff;
  border-radius: 10px;
}

.jtEdited {
  color: #0af;
}

.invisFooter {
  visibility: hidden;
  pointer-events: none;
}

.closeBulkEditLimitModalIcon {
  padding: 7px;
  margin-left: -5px;
  margin-top: -4px;
  cursor: pointer;
}

.closeBulkEditLimitModalIcon:hover {
  opacity: 0.8;
}

.templateDataSpan {
  font-weight: 500;
}

.templateNameInput {
  max-width: 100%;
  min-width: 100%;
  resize: none;
  font-weight: 500;
  border: 1px solid white;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #6a8bb1;
  min-height: 40px;
  padding-left: 8px;
  font-size: 15px;
  padding-top: 10px;
  max-height: 40px;
  background-color: white;
}

.templateNameInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 7px;
  background-color: #fdfeff;
  margin-bottom: 0px;
  padding-top: 9px !important;
}
.nameTemplateTxt {
  margin-top: 20px;
  min-height: 48px;
  margin-bottom: -20px;
  text-align: left;
  font-weight: 500;
}

.nameTemplateTxt:last-of-type {
  margin-top: 8px;
}

.templateTableDiv {
  margin-top: 6px;
  /* max-width: 350px; */
}
.templateTableTopRow {
  display: flex;
  align-items: center;
  min-height: 26px;
}

.templateTableTopRow:first-child {
  border-bottom: 2px solid #bfccdc;
  padding-bottom: 5px;
}

.templateTopRowCellTitle {
  font-size: 0.8em;
  font-weight: 500;

  min-width: 21%;
  max-width: 21%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 14px;

  color: #0077cc;
}

.templateTopRowCellTitle:nth-child(3) {
  font-size: 0.75em;
  margin-top: 1px;
}

.templateTopRowCellTitle:first-child {
  justify-content: flex-start;
  padding-left: 4px;
  min-width: 16%;
  max-width: 16%;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  color: #143051;
}

.templateTopRowCell {
  font-size: 0.8em;
  font-weight: 400;
  min-width: 21%;
  max-width: 21%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 14px;
}

.templateTableTopRow:nth-child(even) {
  background-color: #d2e6fd;
}

.templateTopRowCell:first-child {
  font-weight: 500;
  min-width: 16%;
  max-width: 16%;
  justify-content: flex-start;
  padding-left: 4px;
  border-right: 2px solid #bfccdc;
  min-height: 26px;
}

.templateTableTopRow:last-child {
  border-radius: 0 0 5px 0;
}

.templateIncludeRow {
  display: flex;
  align-items: center;
  min-height: 40px;
  margin-bottom: -6px;
}

.includeCell {
  min-width: 16%;
  max-width: 16%;
  font-size: 12px;
  font-weight: 400;
  color:#516b8b
}

.includeKnobContainer {
  min-width: 21%;
  max-width: 21%;

  border-right: 1px solid #bfccdc;
  display:flex;
  justify-content: center;
    align-items: center;
}

.includeKnobContainer:last-child {
  border-right: none;
}
.templateKnob {
  margin-left: 6px;
  margin-top: 2px;
  margin-bottom: 10px;
  cursor: pointer;
}

.fadeTempRow {
  opacity: 0.25;
}

.descriptionBoxInWideSaveTempModal {
  min-height: 104px;
}

.saveTempMobWideDivider {
  min-width: 2px;
  min-height: 92%;
  max-height: 92%;
  margin-top: 3%;
  background-color: #143051;
  margin-left: 4px;
  margin-right: 4px;
}

.saveTempMobDivider {
  min-width: 100%;
  min-height: 2px;
  max-width: 100%;
  background-color: #143051;
  margin-top: 10px;
  margin-bottom: -8px;
}

.loadingSaveTemp {
  pointer-events: none;
}

.selectTemplateTxt {
  /* margin-top: 10px; */
  min-height: 48px;
  margin-bottom: -10px;
  text-align: left;
  font-weight: 500;
  padding-left: 2px;
}

.selectTemplateTxtMob {
  margin-bottom: -16px;
  margin-top: 5px;
  /* margin-left:-5px; */
}

.whatThisTempLooksLikeTxt {
  margin-top: 6px;

  text-align: left;
  font-weight: 500;
  padding-left: 2px;
}

.templateSelectBox {
  max-height: 200px;
  min-height: 200px;
  overflow-y: auto;
  border: 1px solid #bfccdc;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fcfdfe;
  padding-bottom: 10px;
}

.templateSelectBoxMob {
  /* min-width: 500px !important; */
}

.mobileMaxHeightTemplateBox {
  max-height: 26vh !important;
  min-height: 26vh !important;
}

.templateOption {
  background-color: white;
  margin-top: 6px;
  max-width: 96%;
  min-width: 96%;
  min-height: 50px;
  border-radius: 5px;
  border: 1px solid #e6eaee;
  /* border-bottom: 2px solid rgba(89, 89, 89, 0.267); */

  cursor: pointer;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.06s !important;
          animation-duration: 0.06s !important;
  display: flex;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 1px;
}

.templateOption:hover {
  border: 1px solid #d7dfe9;
  /* padding-left: 11px;
  padding-bottom: 1px;
  padding-right: 0px; */
}

.templateOptionLeft {
  min-width: 75%;
  max-width: 75%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 0.9em;
  font-weight: 500;
}

.templateOptionRight {
  min-width: 25%;
  max-width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  max-height: 100%;
}

.removeTemplateImg {
  max-width: 26px;
  max-height: 26px;
  background-color: white;
  border: 2px solid #e6eaee;
  padding: 4px;
  border-radius: 5px;
}

.deleteSelectedBorderBin {
  border-color: #143051;
}

.templateUnitDescription {
  font-weight: 400;
  text-align: left;
  line-height: 12px;

  font-size: 0.82em;
  opacity: 0.8;
}

.tempArrowImgDownWide {
  max-width: 12px;
  margin-right: 12px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.tempArrowImgDownMob {
  max-width: 12px;
  margin-right: 10px;
}

.selectedTemplateUnit {
  border: solid #140351 2px;
  background-color: #07f;
  color: #fff;
  padding-right: 1px;
}

.selectedTemplateUnit:hover {
  border: solid #140351 2px;
  background-color: #1b81f5;
  color: #fff;
  padding-left: 12px;
  padding-right: 1px;
  padding-top: 1px;
}
.removeTemplateButton {
  background-color: #e89292;
  border: 2px #b56161 solid;
  color: white;
  border-radius: 5px;
  font-size: 0.8em;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  min-height: 30px;

  display: flex;
  align-items: center;
}

.tempLookLikeDayRow {
  display: flex;
  min-height: 22px;
  font-size: 0.9em;
  font-weight: 500;
  align-items: flex-end;
  padding-bottom: 1px;
  margin-top: 20px;
  margin-bottom: 6px;
}

.tempLookLikeA1 {
  min-width: 20%;
  max-width: 20%;
  min-height: 20px;
}
.tempLookLikeDayCell {
  min-width: 11.1%;
  max-width: 11.1%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectTemplateFirstTxt {
  margin-top: 130px;
  color: #516b8b;
  font-weight: 400;
  font-size: 12px;
}

.templateUserRow {
  min-height: 70px;

  min-width: 100%;
  max-width: 100%;
  display: flex;

  border-bottom: 2px solid #e0ebf8;
}

.templateUserRow2items {
  min-height: 120px;
}

.templateUserRow3items {
  min-height: 180px;
}

.templateUserRow4items {
  min-height: 230px;
}

.templateUserRow5items {
  min-height: 290px;
}

.templateUserRow6items {
  min-height: 350px;
}

.templateUserRow7items {
  min-height: 410px;
}

.templateUserRow8items {
  min-height: 470px;
}

.templateUserRow9items {
  min-height: 530px;
}

.templateUserRow10items {
  min-height: 590px;
}

.templateUserRow11items {
  min-height: 650px;
}

.templateUserRow:last-child {
  border: none;
}

.templateNameCell {
  min-width: 20%;
  max-width: 20%;
  font-size: 0.65em;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 5px;
  padding-top: 19px;
}

.templateUserDateCell {
  min-width: 11.43%;
  max-width: 11.43%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding-top: 4px;
  padding-bottom: 4px;
}

.templateItem {
  max-height: 52px;
  min-height: 52px;
  min-width: 94%;
  font-size: 0.6em;
  background-color: white;
  border: 1.5px solid #cbd7e6;
  border-radius: 5px;
  margin-right: 2px;
  padding-top: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2px;
}

.templateStart {
  font-weight: 500;
  line-height: 10px;
}

.templateEnd {
  font-weight: 500;
  line-height: 10px;
}

.tempTimeSmaller {
  font-size: 0.76em;
}
.templateType {
  margin-top: 1px;
  color: #07f;
  font-size: 0.9em;
  opacity: 0.5;
  font-weight: 500;
}

.templateBetweenLine {
  opacity: 0.5;
}

.dudTemplateItem {
  max-height: 54px;
  min-height: 54px;
  min-width: 94%;
  min-width: 94%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #6987a9;
}

.tilTypeStripInLoadTemplate {
  color: #4b1caf;
}

.templateOpensRow {
  display: flex;
  min-width: 100%;
  max-width: 100%;
  border-bottom: 2px solid #c6d6e9;

  background-color: #e0ebf8;
}
.opensRow1 {
  min-height: 60px;
}

.opensRow2 {
  min-height: 116px;
}

.opensRow3 {
  min-height: 172px;
}
.opensRow4 {
  min-height: 228px;
}
.opensRow5 {
  min-height: 284px;
}
.opensRow6 {
  min-height: 340px;
}
.opensRow7 {
  min-height: 396px;
}
.opensRow8 {
  min-height: 452px;
}
.opensRow9 {
  min-height: 507px;
}

.previewOpenCell {
  min-width: 11.43%;
  max-width: 11.43%;
}

.a1previewOpensRowCell {
  min-height: 100%;
  min-width: 20%;
  max-width: 20%;
}

.templateOpenTxt {
  color: #6dba4f;
  font-weight: 500;
  margin-top: 1px;
}

.templateOpenShiftsTitle {
  font-weight: 500;
  text-align: left;
  font-size: 0.7em;
  margin-top: 14px;
  padding-left: 5px;
}

.tempLoadedNotLimitsTxt {
  margin-top: 20px;
  font-size: 0.9em;
}

.limitsNoteSpan {
  font-weight: 500;
}

.noSavedTempsTxt {
  margin-top: 16px;
  font-weight: 500;
  color: #acc3e1;
  font-size: 12px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  line-height: 18px;
}

.clickHereToSaveTempInsteadSpan {
  color: #143041;
  font-weight: 500;
  cursor: pointer;
}

.saveTempFooterRightDiv {
  min-width: 225px;
  display: flex;
  justify-content: space-between;
}

.replaceExistingBtn {
  min-height: 30px;
  display: flex;
  align-items: center;
  padding-bottom: 1px;
  padding-left: 2px;
}

.fadeGenBtn {
  pointer-events: none;
  opacity: 0.5;
}
.replaceTickImg {
  margin-top: 2px;
  margin-right: 7px;
  margin-left: -3px;
  max-width: 18px;
}

.splitFromToday {
  font-weight: 500;
  font-size: 0.7em;
  margin-top: -2px;
  color: #19a5c8;
}

.availRow {
  min-height: 17px;
  max-height: 17px;
  /* margin-top: 4px; */
  margin-bottom: -5px;
  padding-bottom: 0px;
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  justify-content: flex-start;
  padding-left: 15px;
  color: #143051;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
  margin-top: 0px;
  margin-bottom: -12px;
  /* border-left: 2px solid #143051; */
  border-radius: 0;
}

.availRow:hover {
  /* color: #8ea7c5; */
}
.assignRow {
  min-height: 110px;
}
.availSpan {
  /* color: #36495f; */
  margin-left: 5px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  white-space: nowrap;
  font-size: 1.05em;
  padding-right: 5px;
}

.availSpan:hover {
  /* color: #214978 */
}

.selectDateRangeDivAvail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-top: 6px;
  min-width: 100%;
}

.teamSchedFilterBarLeftAvail {
  min-height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 5px;
}

.fadeSelMonWeekBtn {
  opacity: 0.3;
  pointer-events: none;
}

.availTable {
  min-width: 100%;
  min-height: 200px;

  font-size: 0.8em;
}

.availModalRow {
  display: flex;
  min-height: 80px;
  max-height: 80px;
  min-width: 100%;
  padding-left: 14px;
  border-bottom: 1px solid #e6eaee;
  align-items: center;
  justify-content: space-between;
  padding-right: 14px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 1.3em;
  background-color: white;
}

.availModalRow:last-child {
  border: none;
}

.extendAvailRow {
  min-height: 100px;
}
.availDay {
  font-size: 14px;
  color: #214978;
  font-weight: 500;
}

.availRowBtnsContainer {
  display: flex;
  justify-content: space-between;
  min-width: 60px;
}
.availPower {
  max-width: 30px;
  min-width: 30px;
  background-color: #96e578;
  border: 1px solid #88d869;
  border-radius: 5px;
  margin-left: -4px;
  cursor: pointer;
}
.availPower:hover {
  background-color: #88d869;
}

.availAdd {
  max-width: 25px;
  min-width: 25px;
}

.availData {
  display: flex;
  min-width: 170px;
  justify-content: space-between;
  align-items: center;
}

.availDataParent {
  min-height: 76px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.availStart {
  background-color: #fff;
  width: 74px;
  max-width: 74px;
  line-height: 30px;
  padding-top: 0px;
  height: 35px;
  border: 1px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 14px;
  font-weight: 500;
  -webkit-appearance: none;
  appearance: none;
  cursor: text;
  /* padding-left:4px; */
}

.availStart:hover {
  border-color: #d7dfe9;
}

.availUntil {
  font-weight: 500;
  font-size: 0.8em;
  color: #214978;
  cursor: pointer;
}

.availPowerOff {
  background-color: #bbcada;
  border-color: #8ea7c5;
}

.availPowerOff:hover {
  background-color: #8ea7c5;
}

.availRowOff {
  opacity: 0.6;
  background-color: #f4f8fe;
  cursor: pointer;
}

.availRowOff:hover {
  background-color: #edf3f9;
}
.availSelectRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 320px;
  min-width: 320px;
  min-height: 40px;

  padding-bottom: 10px;
  padding-left: 6px;
  padding-right: 4px;
  padding-top: 4px;
}

.addAvailSelectRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 270px;
  min-width: 270px;
  min-height: 40px;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-right: 8px;
}

.timesheetSelectorRow {
  max-width: 310px;
  min-width: 310px;
  margin-bottom: -10px;
}

.addAvailSelectRow:last-child {
  border: none;
  margin-bottom: 16px;

  padding-bottom: 10px;
  margin-top: 3px;
}

.timesheetSelectorRow:last-child {
  border: none;
  /* margin-bottom: 1px; */
  max-width: 310px;
  min-width: 310px;
  padding-bottom: 0px;
  margin-top: -5px;
}
.availTeamsDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 163px;
  min-width: 163px;
  line-height: 26px;
  height: 35px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  background-position-x: 92px;
  display: flex;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 140px;
  margin-right: 10px;
  margin-top: 1px;
}

.timesheetTeamSelect {
  max-width: 203px;
  min-width: 203px;
  background-position-x: 176px;
}
.mgrAvailSelectorBar {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height: 88px;
}

.selectorBarDynamic2 {
      min-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 44px;
}

@media only screen and (min-width: 800px) {
  .selectorBarDynamic {
    min-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 44px;
  }

  .availSelectRowSelectedWeek {
    margin-top: -6px !important;
    max-width: 285px;
    min-width: 285px;
  }

  .availSelectRowTeam {
    margin-top: -10px !important;
    padding-right: 0px !important;
  }


}

.selectDateRangeSelectWeek2 {
  margin-left: 16px;

}

.availTeamsDropdown:hover {
  border-color: #d7dfe9;
}

.availSaveAndDeleteContainer {
  display: flex;
  justify-content: flex-start;
  /* flex-direction: row-reverse; */
  /* max-width: 140px; */
  /* min-width: 140px; */
}

.deleteAvailBtn {
  color: white;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
  /* margin-left: 10px; */
  /* min-width: 70px; */
  justify-content: center;
  font-weight: 500;
}

.deleteAvailBtn:hover {
  background-color: #bbcada;
}

.saveAvailBtn {
  margin-right: 10px;
}

.sureCloseAvailModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.sureCloseAvailModalBox {
  background-color: #f4f8fe;
  color: #143051;
  border-radius: 5px;
  min-height: 35px;
  min-width: 300px;
  max-width: 300px;
  padding: 15px;
  padding-top: 30px;
  box-shadow: 0px 0px 3px 3px rgb(0 0 0 / 10%);
  cursor: auto;
  min-height: 150px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #143051;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
}

.sureCloseAvailBtnContainer {
  display: flex;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  margin: 0 auto;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 8px;
}

.availSavedBtn {
  max-width: 50.67px;
  min-width: 50.67px;
  padding-top: 8px;
  color: #6dba4f;
  font-weight: 500;
  /* margin-right: 11px; */
  margin-left: 9px;
  border-left: 2px solid #6dba4f;
  max-height: 22px;
  margin-top: 6px;
  /* padding-left: 5px; */
  padding-right: 0px;
  line-height: 6px;
  padding-left: 8px;
}

.rateAndCostContainer {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
}

.addShiftCostIndicative {
  margin-right: 30px;
  font-weight: 500;
  color: #abb9dc;
}

.invisCostAddShift {
  visibility: hidden;
  pointer-events: none;
}

.ghostShiftUnitTeamSched {
  background-color: #143051;
  color: white;
  border-radius: 5px 5px 5px 5px;
  max-width: 130px !important;
  min-width: 130px !important;}

.specDayRow {
  min-height: 20px;
  max-height: 20px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  padding-left: 10px;
  padding-bottom: 2px;
  color: #5479a3;
  /* background-color: #ebf0f7; */
  border-bottom: 1px solid #e3e8ee;
  border-top: 1px solid #e3e8ee;

}

.specDayRowStream {
  /* margin-left:2px; */
}

.specDayRowStreamA1 {
  margin-left:0px;
  border-left:#dbe8f7 1px solid;
  border-right:#dbe8f7 3px solid;
  border-top:none;
  border-bottom:none;
}

.activeSpecDayRow {
  background-color: #dfb6e3;
  color: #fff;
  font-weight: 500;
  display: flex;
  /* justify-content: space-between; */
  justify-content: flex-start;
  margin-left: 2px;
  /* border-bottom: 1px solid #d59fd9; */
  border: 1px solid #d59fd9;
  border-radius: 3px;
}

.activeSpecDayRowMon {
  margin-left:0px
}



.schedLimPowerOn {
  background-color: #7fbb67;
  max-height: 21px;
  min-height: 21px;
  min-width: 21px;
  max-width: 21px;
  border-radius: 3px;
  border: 1px solid #5a9e3f;
  margin-top: 4px;
}



.schedLimPlus {
  max-height: 21px;
  min-height: 21px;
  min-width: 21px;
  max-width: 21px;
  border-radius: 3px;

  margin-top: 4px;
}

.schedLimPlus:active {
  opacity: 1;
}



@media only screen and (min-width: 820px) {

  .schedLimPowerOn:hover {
    opacity: 0.8;
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
  }

  .dayLimitPowerOff:hover {
    opacity: 0.8;
    /* transform: scale(0.98); */
  }

  .schedLimPlus:hover {
    opacity: 1;
    background-color: white;
    border:1px solid #ececec
  }
  .dayLimitPowerOn:hover {
    opacity: 0.8;
    /* transform: scale(0.98); */
  }
}
.schedLimPowerOff {
  background-color: #bfd1e6;
  max-height: 21px;
  min-height: 21px;
  min-width: 21px;
  max-width: 21px;
  border-radius: 3px;
  border: 1px solid #98b8dc;
  margin-top: 4px;
}

.schedLimitPowerOff {
  background-color: #6a8bb1;
  max-height: 21px;
  min-height: 21px;
  min-width: 21px;
  max-width: 21px;
  border-radius: 4px;
}

.dayLimitPowerOn {
  background-color: #7fbb67;
  margin-top: 2px;
  max-height: 21px;
  min-height: 21px;
  min-width: 21px;
  max-width: 21px;
  border-radius: 4px;
  border: 1px solid #2a501b;
  cursor: pointer;
}



.dayLimitPowerOff {
  background-color: #6a8bb1;
  max-height: 21px;
  min-height: 21px;
  min-width: 21px;
  max-width: 21px;
  border-radius: 4px;
  border: 1px solid #143051;
  cursor: pointer;
  margin-top: 4px;
}



.exSmallDurStrip {
  font-size: 11px;
}

.appendingShTagInline {
  margin-left: 3px;
}

.voidRowIfNew {
  opacity: 0.2;
  pointer-events: none;
}

.tilPushTickDown {
  margin-top: 31px;
}

.highlightedTilRow1 {
  border-top: 2px solid #00ccff;
  border-left: 2px solid #00ccff;
  border-right: 2px solid #00ccff;
  border-radius: 5px 5px 0 0;
  border-bottom: none;
  margin-top: 3px;
  padding-left: 10px;
  background-color: #f4f8fe;
  padding-right: 14px;
  padding-left: 14px;
}

.highlightedTilRow2 {
  border-bottom: 2px solid #00ccff;
  border-left: 2px solid #00ccff;
  border-right: 2px solid #00ccff;
  border-radius: 0px 0px 5px 5px;
  margin-bottom: 8px;
  padding-left: 10px;
  background-color: #f4f8fe;
  padding-right: 14px;
  padding-left: 14px;
}

.tilTrimTimeInput {
  /* border: 2px solid #00ccff !important; */
  cursor: pointer;
}

.tilTrimTimeInput:hover {
  border-color: #00aaff !important;
}

.tipBox {
  background-color: #214978;
  display: flex;
  align-items: center;
  position: fixed;
  max-width: 320px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 5px 0 0 5px;
  right: 0px;
  bottom: 150px;
  z-index: 9999999;
  border: 2px solid #143051;
  border-right: none;
  -webkit-animation-name: tipBox !important;
          animation-name: tipBox !important;
  -webkit-animation-duration: 0.75s !important;
          animation-duration: 0.75s !important;
}

.schedLimitMinus {
}

.tipBoxClose {
  min-width: 12px;
  cursor: pointer;
}

.tipBoxTxt {
  color: white;
  text-align: left;
  font-weight: 500;
  font-size: 0.94em;
  margin-left: 15px;
  line-height: 18px;
  font-size: 14px;
}

.tipBoxTipSpan {
  font-weight: 500;
  color: #00ccff;
}

.tipBoxBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}

.dontShowTipBtn {
  color: #fff;
  border-radius: 5px;
  font-size: 0.75em;
  margin-left: 15px;
  cursor: pointer;
  margin-top: 4px;
  opacity: 0.7;
}

.dontShowTipBtn:hover {
  opacity: 1;
}

.noCursor {
  cursor: default;
  opacity: 0.3;
}

.showingChangesFor {
  text-align: left;
  color: #acc3e1;
  margin-top: 7px;
  margin-bottom: 6px;
  font-size: 14px;
  padding-left: 54px;
}

.availPlusAddBtn {
  max-width: 30px;
  margin-right: 1px;
  margin-left: 6px;
  cursor: pointer;
  border: 1px solid #cbd7e6;
  border-radius: 5px;
  opacity: 0.5;
  padding: 3px;
}

.availPlusAddBtn:hover {
  border-color: #d7dfe9;
}

.absenceMonthSelectDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 150px;
  min-width: 150px;
  line-height: 26px;
  height: 30px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 129px;
}

.noAbsTxt {
  font-size: 0.9em;
  color: #496375;
  font-weight: 500;
}

.noAbsDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 190px;
  margin: 15px auto;
}

.noAbsSideline {
  min-height: 2px;
  background-color: #496375;
  min-width: 40px;
  opacity: 0.5;
}

.closeRemoveAbsenceModal {
  cursor: pointer;
}

.noteBtnAbsence {
  font-size: 0.82em;
  color: #fff;
  border: solid 1px #0af;
  background-color: #0bf;
  font-weight: 500;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
  max-height: 24px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 24px;
  cursor: pointer;
}

.hideAbsenceContent {
  visibility: hidden;
}

.loadingAbsenceHorizImg {
  margin-top: 8px;
}

.durationAbsenceRow {
  background-color: #fff;
  cursor: pointer;
}

.durationAbsenceRow:hover {
  background-color: #f4f8fe;
}

.addUserToTeamSchedBtn {
  min-width: 90%;
  font-size: 0.94em;
  max-width: 90%;
  margin-left: 5%;
  margin-top: 5px;
  background-color: #0bf;
  min-height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 5px;
  font-weight: 500;
  border: 2px solid #0af;
  cursor: pointer;
  margin-bottom: 10px;
}

.addUserToTeamSchedBtn:hover {
  background-color: #0af;
}

.loadedFromTeamSchedTxt {
  text-align: left;
  line-height: 18px;
  font-size: 0.8em;
  font-weight: 400;
  padding-right: 20px;
  margin-top: 10px;
}

.loadedFromTeamSchedTxtBoldSpan {
  font-weight: 500;
}

.disableBulkLimitBtn {
  opacity: 0.5;
  pointer-events: none;
}

.fadeSched {
  opacity: 0.7;
  pointer-events: none;
}

.availActiveBanner {
  background-color: #96e578;
  border: 1px solid #88d869;
  color: white;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  max-height: 28px;
  min-height: 28px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -18px;
  margin-top: -6px;
  margin-left: 0px;
  padding-left: 12px;
  padding-right: 12px;
  z-index: 999;
  border-radius: 5px 5px 5px 5px;
}

.notYetSubmittedAvailBanner {
  background-color: #cbd7e6;
  border: 1px solid #bbcada;

  color: white;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  max-height: 28px;
  min-height: 28px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -18px;
  margin-top: -6px;
  margin-left: 0px;
  padding-left: 12px;
  padding-right: 12px;
  z-index: 999;
  border-radius: 5px;
}

.leaveFirstLastHalfTxt {
  font-size: 1.5em;
  opacity: 1;
  color: #fff;
  margin-top: 2px;
}

.removeTilFromShiftBtn {
  cursor: pointer;
  max-width: 20px;
  /* max-height: 20px; */
  margin-left: 5px;
  margin-top: -7px;
  cursor: pointer;
  max-width: 30px;
  margin-left: 30px;
  margin-top: -92px;
  margin-bottom: 40px;
  border: #e3e8ee solid 2px;
  border-radius: 5px;
  padding: 5px;
}

.removeTilFromShiftBtnSure {
  padding-top: 3px;
}

.removeTilFromShiftBtn:hover {
  border: #b2bbc7 solid 2px;
}

.tilBtnsOnShiftDiv {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-around;
}

.addAbsenceSelectUserDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 208px;
  min-width: 208px;
  line-height: 26px;
  height: 30px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  color: #496375;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  background-position-x: 184px;
  display: flex;
  cursor: pointer;
  margin-left: 10%;
  margin-top: 10px;
}

.considerLTLTxt {
  text-align: left;
  font-size: 14px;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 8px;
  color: #859dbb;
  font-weight: 400;
}

.ltlSpan {
  font-weight: 500;
  color: #214978;
  cursor: pointer;
}

.bulkPlusMinus {
  background-color: #00ccff;
  min-width: 26px;
  max-width: 26px;
  padding: 6px;
  border: 1px solid #0af;
  border-radius: 5px;
  cursor: pointer;
}

.bulkPlusMinus:hover {
  background-color: #00aaff;
}

.bulkPlusMinus:active {
  opacity: 0.8;
}
.bulkDayPowerIcon {
  min-width: 26px;
  min-height: 26px;
  max-width: 26px;
  max-height: 26px;
  padding: 4px;
  background-color: #3b597c;
  border: 1px solid #214978;
  border-radius: 5px;
  cursor: pointer;
}

.bulkDayPowerIcon:hover {
  background-color: #214978;
}

.fadeBulkPower {
  opacity: 0.2;
}

.makeCursP {
  cursor: pointer;
}

.noPermissionsEditUser {
  font-weight: 500;
}

.noPermissionsEditUserModalBox {
  background-color: white;
  min-width: 300px;
  min-height: 120px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.limitColourBg {
}

.limitColourPlusBtn {
  opacity: 1;
}

.limitColourDayTxtWhite {
}

.TopBar {
  position: fixed;
  top: 0;

  background-color: #ebf0f7;
  color: white;
  min-height: 30px;
  max-height: 30px;
  z-index: 1;

  border-bottom: 2px solid #e6eaee;
  color: black;
  min-width: calc(100vw - 240px);
  max-width: calc(100vw - 240px);
  border-left: 2px solid #e6eaee;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 30px;
}

.unclockedTab {
  background-color: #36495f;
  border: 1px #143051 solid;
  color: white;
  display: flex;
  align-items: center;
  max-height: 24px;
  min-height: 24px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 6px;
  padding-right: 5px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 6px;
}

.unclockedTab:first-of-type {
  margin-left: -27px;
}

.unclockedTab:hover {
  background-color: #143051;
}

.unclockedQty {
  background-color: #a387df;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 6px;
  font-size: 12px;
  padding-top: 1px;
  padding-bottom: 1px;
  font-weight: 500;
}

.allTilOff {
  font-size: 14px;
}

.shiftTimesStrip {
  font-size: 16px;
  line-height: 28px;
  min-width: 112px;
}

.wholeTilTxt {
  font-size: 15px !important;
  line-height: 18px !important;
  margin-top: 16px;
}

.notAvailableTxtBlock {
  background-color: #5479a3;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 16px;
  border-radius: 3px;
  border: 1px solid #496375;
  margin-top: 3px;
  min-height: 17px;
  margin-bottom: -15px;
}

.notAvailableTxtBlock:hover {
  background-color: #3e5d7f;
}

.notAvailSpan {
  color: white;
}

.dayDataBox {
  min-height: 60px;
  background-color: #e3e8ee;
  border-top: 2px solid #143051;
  min-width: 100%;
  max-width: 100%;
  color: white;
  margin-bottom: -9px;
  font-size: 14px;
  padding-bottom: 8px;
  font-size: 12px;
}

.dayDataLeftAndRightFlex {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  margin-top: 14px;
}

.lastDayDataBox {
  border-radius: 0 0 8px 0;
}

.dayDataDayStrip {
  text-align: left;
  font-size: 12px;
  margin-left: 4px;
  margin-top: 4px;
  margin-bottom: 5px;
  opacity: 1;
  min-width: 100px;
  font-weight: 500;
  color: #859dbb;
  padding-left: 5px;
  border-bottom: 1px solid #BBCADA;
  padding-bottom: 13px;
  display: flex;
  align-items: center;
  padding-top: 10px;
  height: 20px;
}
.dayColCostHoursTitle {
  font-weight: 500;
  /* margin-top: 12px; */
  opacity: 0.5;
  /* margin-bottom: 3px; */
  font-size: 10px;
}

.dayColCostHoursValue {
  font-weight: 500;
  font-size: 14px;
  margin-top: 1px;
}

.dayDataBoxLeft {
  text-align: left;
  padding-left: 10px;
}

.dayDataBoxRight {
  text-align: right;
  padding-right: 10px;
}

.teamShiftTeamSelectDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 193px;
  min-width: 193px;
  line-height: 26px;
  height: 30px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 170px;
  margin-left: -18px;
  margin-top: 4px;
}

.teamRotaTeamSelectDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 193px;
  min-width: 193px;
  line-height: 26px;
  height: 35px;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 2px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 170px;
  margin-left: 17px;
  margin-top: -27px;
}

.teamRotaTeamSelectDropdown:hover {
  border-color: #d7dee5;
}
.clockOnsupperCont {
  padding-top: 2px;
}

.clockOnsContainer {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  min-height: calc(100vh - 207px);
  max-height: calc(100vh - 207px);
  overflow-y: scroll;
  padding-bottom: 30px;
  padding-top: 7px;
  border-left: 2px solid #e0ebf8;
  margin-top: -8px;
  background: #ebf0f7 !important;
}

.tsContainer {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  min-height: calc(100vh - 204px);
  max-height: calc(100vh - 204px);
  overflow-y: scroll;
  padding-bottom: 40px;
  /* padding-top: 10px; */
  border-left: 2px solid #e0ebf8;
  border-right: 1px solid #e0ebf8;
  /* margin-top: -8px; */
  background: #ebf0f7 !important;

  /* margin-top: -8px; */
  background: #ebf0f7 !important;
  padding-top:15px;
}

.mobTsContainer {
  min-height: calc(100vh - 263px);
  max-height: calc(100vh - 263px);
}

.tsContainerStaffMob {
  min-height: calc(100vh - 269px);
  max-height: calc(100vh - 269px);
}
.clockOnsContainerWithFilt {
  min-height: calc(100vh - 240px);
  max-height: calc(100vh - 240px);
}

.mobClockOnsContainer {
  min-height: calc(100vh - 255px);
  max-height: calc(100vh - 255px);
}

.myHoursContainer {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  min-height: calc(100vh - 204px);
  max-height: calc(100vh - 204px);
  overflow-y: scroll;
  padding-bottom: 20px;
  padding-top: 14px;
  border-right: 1px solid #e0ebf8;
  background-color: #ebf0f7;

}
.mobMyHoursContainer {
  min-height: calc(100vh - 263px) !important;
  max-height: calc(100vh - 263px) !important;
  /* background-color: yellow; */
}

.showFilterMobMyHoursContainer {
  min-height: calc(100vh - 307px) !important;
  max-height: calc(100vh - 307px) !important;
}

.showFilterDesktopMyHoursContainer {
  min-height: calc(100vh - 258px) !important;
  max-height: calc(100vh - 258px) !important;
}
.showFilterClockHeight {
  min-height: calc(100vh - 299px);
  max-height: calc(100vh - 299px);
}

.showFilterClockHeightDesktop {
  min-height: calc(100vh - 252px);
  max-height: calc(100vh - 252px);
}

.clockOnsContainerMobFiltActive {
  min-height: calc(100vh - 346px);
  max-height: calc(100vh - 346px);
  padding-bottom: 25px;
}

.clockOnsContainerMobNoUnlinked {
  min-height: calc(100vh - 246px);
  max-height: calc(100vh - 246px);
  padding-bottom: 45px;
}

.mobUnlinkedExistContainer {
  min-height: calc(100vh - 291px);
  max-height: calc(100vh - 291px);
  padding-bottom: 45px;
}

.hideViewChange {
  pointer-events: none;
  opacity: 0.3;
}

.loadChangeLoaderImg {
  max-width: 60px;
  max-height: 13px;
  margin-top: 8px;
  margin-right: 20px;
}

.shiftEditTitleTxt {
  font-size: 12px;
  color: #214978;
  font-weight: 400;
  margin-left: 5px;
}

.shiftEditValue {
  margin-top: -26px;
  color: #143051;
  font-weight: 500;
  font-size: 18px;
  margin-left: 5px;
}

.shiftDateValue {
  margin-top: -20px;
  color: #143051;
  font-weight: 500;
  font-size: 18px;
  margin-left: 5px !important;
}

.shiftTypeLeftBox {
  margin-left: 18px;
  max-width: 75%;
  min-width: 60%;

  margin-right: -18px;
}

.saveBtnPushedDown {
  margin-top: 22px;
}

.editShiftDateInputBox {
  font-size: 16px !important;
}

.shiftDateEditDiv {
  margin-top: -9px;
  margin-bottom: 6px;
}

.editBreakMove {
  margin-left: 5px !important;
  margin-top: -20px !important;
  padding-top: 0px;
}

.noTagsTxt {
  margin-top: -20px;
}

.noNoteVal {
  margin-top: -20px;
  margin-left: 5px !important;
}

.pushSaveTimesBtnDown {
  margin-top: 24px;
}

.shiftEditBrkInputMove {
  margin-top: -14px;
  margin-left: 0px !important;
  margin-bottom: 4px;
}

.pushSaveBrkBtnDown {
  margin-top: 24px;
}

.openTypeBox {
  margin-top: -18px;
  margin-left: 0px !important;
}
.openTypeSpan {
  color: #143051;
  margin-left: 5px;
  font-weight: 500;
}

.openUnitTypeDropdown {
  margin-top: -9px !important;
  margin-bottom: 8px;
}

.saveOpenTypeBtn {
  margin-top: 22px;
}

.margin5pxLeft {
  margin-left: 5px;
  font-weight: 500;
}

.openDateString {
  margin-top: -17px;
  margin-left: 5px !important;
}

.openDateInput {
  margin-bottom: 12px !important;
}

.dateOpenTxtTitle {
  margin-bottom: -10px;
}

.saveAndSpecContainer {
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: flex-end; */
  /* justify-content: ; */
}

.openDateSaveBtn {
  background-color: #12b0ff;
  max-height: 30px;
  line-height: 26px;
  color: white;
  font-weight: 500;
  border: 1px solid #0066bf;
  border-radius: 5px;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  cursor: pointer;
  max-width: 60px;
  min-width: 60px;
  margin-left: 43px;
}

.openDateSaveBtn:hover {
  background-color: #2aa6e5;
}

.openDateStripInput {
  margin-top: -17px !important;
  margin-left: 0px !important;
}

.openTimesStrip {
  margin-top: -17px !important;
  margin-left: 7px !important;
  font-weight: 500;
}

.unavilTxt {
  font-size: 12px;
  margin-left: 30px;
  margin-right: 30px;
  font-weight: 500;
  color: #859cb6;
}

.pubCorrection1 {
  margin-right: 217px;
}

.pubCorrection2 {
  margin-right: 99px;
}

.pubCorrection3 {
  margin-right: 225px;
}

.pubCorrection4 {
  margin-right: 108px;
}

.desktopMaster {
  display: flex;
  min-width: 100vw;
  background-color: #ebf0f7;
}

.desktopPurpBg {
  background-color: #403a8e;
}

.desktopWhiteBg {
  background-color: #fff;
}

.desktopMasterMain {
  min-width: calc(100vw - 610px);
  max-width: calc(100vw - 610px);
}



.desktopMasterMainWallChart {
  min-width: calc(100vw - 300px);
  max-width: calc(100vw - 300px);
  background-color: lime !important;
  height: calc(100vh - 210px);

  overflow: auto;
}

@media only screen and (min-width: 820px) {
  .desktopMasterMainWallChart {
    position: fixed;
    top: 125px;
    left: 242px;
    height: calc(100vh - 125px);
  }
}

@media only screen and (min-width: 820px) and (max-width: 1140px) {
  .desktopMasterMainWallChart {
    min-width: calc(100vw - 242px);
    max-width: calc(100vw - 242px);
    background-color: blue !important;
  }
}

@media only screen and (min-width: 1141px) {
  .desktopMasterMainWallChart {
    min-width: calc(100vw - 575px);
    max-width: calc(100vw - 575px);
    background-color: pink !important;
    left: 26%;
  }
}

.mob100Witdth {
  min-width: 100vw  !important;
  max-width: 100vw  !important;

}


.mob100WitdthPlus2 {
  min-width: calc(100vw + 1px) !important;
  max-width:calc(100vw + 1px) !important;
}

.midContentWidth {
  min-width: calc(100vw - 241px);
}
.desktopMasterSecondary {
  background-color: #ebf0f7;
  min-width: calc(100vw - 662px);
  max-width: calc(100vw - 662px);
}

.desktopDataBox {
  background-color: #214978;
  border-radius: 5px;

  min-width: 300px;
  max-width: 300px;
  margin-left: 23px;
  margin-top: 20px;

  color: #143051;
  padding-bottom: 20px;
  border: 1px solid #143051;
  /* box-shadow: 0px 0px 2px rgb(0 0 0 / 20%); */
  -webkit-animation-name: moveEditTeamUserBoxUp !important;
          animation-name: moveEditTeamUserBoxUp !important;
  -webkit-animation-duration: 0.2s !important;
          animation-duration: 0.2s !important;
}

@media only screen and (max-width: 550px) {
  .desktopDataBox {
    min-width: 100vw !important;
    max-width: 100vw !important;
    position: fixed;
    bottom: 0;
    border-radius: 20px 20px 0 0 !important;

  }
}

.desktopDataBoxNoMargin {
  margin-left: 0px;
  margin-top: 0px;
  padding-bottom:0px ;
  border-radius:10px;
}
@media only screen and (min-width: 1140px) {
  .desktopDataBox {
    min-width: 300px;
    max-width: 300px;
  }

  .desktopMasterMain {
    min-width: 470px;
    max-width: 470px;
  }
}

.x2948758723 {
  margin-top:-1px
}



.desktopDataBoxHeader {
  font-weight: 500;
  min-width: 100%;
  padding-left: 15px;
  min-height: 40px;
  text-align: left;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 23px;
  align-items: center;
  color: white;
}

.dataBoxHeading {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.dataBoxHeading2 {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.mgrHoursDataBoxHeading {
  margin-top: -1px;
  min-width: 120px;
}

.dataBoxYTDbtn {
     /* background-color: #fff; */
     min-height: 35px;
     display: flex;
     align-items: center;
     justify-content: center;
     min-width: -moz-fit-content;
     min-width: -webkit-fit-content;
     min-width: fit-content;
     padding-left: 12px;
     padding-right: 12px;
     border: 1px solid #749dd1;
     border-radius: 5px;
     font-size: 14px;
     margin-right: 14px;
     cursor: pointer;
     padding-bottom: 1px;
     color: #fff;
     font-weight: 500;
     /* background-color: #2b679f; */
}

.dataBoxYTDbtn:hover {
  border-color: #a9bcd4;
}
.ytdTri {
  font-size: 18px;
  margin-left: 6px;
  margin-top: -1px;
}

.hoursTri {
  font-size: 20px;
  margin-left: 4px;
  margin-top: 1px;
  padding-top: 3px;
  margin-right: 5px;
}

.desktopDataRow {
  border-bottom: 1px solid #7d9ec8;
  display: flex;
  justify-content: space-between;
  padding-left:7px;
  padding-right: 15px;
  min-height: 40px;
  align-items: center;
  max-width: 90%;
  margin-left: 5%;
  font-size: 14px;
}

.mgrDesktopDataRow {
  border-bottom: 1px solid #cdacf9;
}

.desktopDataTitle {
  font-weight: 500;
  color: #f0cbfc;
  min-width: 60px !important;
  text-align: left;
  
}

.customRemainingTxt {
  min-width: 62px;
}

.dataBoxBorderLeft {
  /* background-color: #fcfbff; */
  /* border-left: 3px solid #e3e8ee; */
}
.desktopDataValue {
  opacity: 0.8;
  font-weight: 500;
  text-align: right;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 12px;
  /* color: #859dbb */
}

.desktopRowThickBorder {
  /* border-radius: 5px; */
  padding-left: 5px;
  padding-right: 7px;
  margin-top: -6px;
  /* border:2px solid #dde9f9; */
}

.emptyDesktopDataRow {
  min-height: 10px;
}

.boldDataVal {
  font-weight: 500;
}

.statsUnderlay {
  background: rgba(0, 0, 0, 0.35);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.075s;
          animation-duration: 0.075s;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.hoursStatsModalBox {
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-width: 340px;
  min-width: 340px;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.showMyToggler {
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;

  margin-top: 8px;
}

.x198349811 {
  margin-top:2px
}

.closeStatsModalCrossImg {
  cursor: pointer;
  min-width: 15px;
  margin-left: 13px;
  cursor: pointer;
  margin-top: 7px;
  margin-bottom: 3px;
}

.closeStatsModalCrossImg:hover {
  opacity: 0.8;
}

.shiftsMenuTeamMyHours {
  margin-left: 8px !important;
  padding-right: 10px;

  background-color: #07f;
  border: #0469de 1px solid;
}

.shiftsMenuTeamMyHours:hover {
  background-color: #0469de;
}

.mobStatsBtn {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-left: 12px;
  padding-right: 10px;
  font-size: 15px;
}

.pushMyHoursTxtUp {
  font-size: 15px;
}

.narrowTitleShifts {
  max-width: 100px;
  min-width: 100px;
}

.teamHoursMobTxt {
  margin-top: 36px;
}

.teamItemSelected {
  color: #fff;
}

.teamHoursTitle {
  color: #fff;
  font-size: 23px;
}
.clockonsTitle {
  font-size: 20px;
}

@media only screen and (max-width: 400px) {
  .timesheetsTitle {
    font-size: 18px;
    margin-top: 39px;
  }
}

.teamStatsBtnHours {
  background-color: #9374d6;
  border-color: #8e76c1;
}

.teamStatsBtnHours:hover {
  background-color: #8e76c1;
}

.mobMyShiftModalBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 516px !important;
  min-height: 516px !important;
  border-radius: 5px 5px 0 0 !important;
}

.mobManagerShiftModalBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 90vh !important;
  min-height: 90vh !important;

  border-radius: 5px 5px 0 0 !important;
}

.mobManagerOpenShiftModalBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 609px !important;
  min-height: 609px !important;

  border-radius: 5px 5px 0 0 !important;
}

.newOpenShiftModalBoxHeights {
  max-height: 609px !important;
  min-height: 609px !important;
}

.clockItemMobModalBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 90vh !important;
  min-height: 90vh !important;
  border-radius: 5px 5px 0 0 !important;
  padding-left: 5% !important;
  padding-right: 5% !important;
}

.mobClockFooter {
  padding-bottom: 0px;
}

.mobClockModalBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 518px !important;
  min-height: 518px !important;
  border-radius: 5px 5px 0 0 !important;
}

.mobClockModalBody {
  min-height: calc(90vh - 162px) !important;
  max-height: calc(90vh - 162px) !important;
}

.smallerWhiteBoxMobBeforeClockedOn {
  min-height: 164px !important;
  max-height: 164px !important;
}

.mobMyShiftModalBody {
  /* min-height: calc(82vh - 150px) !important;
  max-height: calc(82vh - 150px) !important; */
  min-height: 366px  !important;
  max-height: 366px  !important;

}

.minWidth33 {
  min-width: 33%  !important;
  max-width: 33%  !important;

}

.mobMyShiftModalBodyOpen {
  min-height: 371px !important;
    max-height: 371px !important;
}

.mobMyShiftModalBody2 {
  /* min-height: calc(82vh - 150px) !important;
  max-height: calc(82vh - 150px) !important; */
  min-height: 366px  !important;
  max-height: 366px  !important;

}


.mobAbsenceUnitModalBody {
  min-height: calc(90vh - 113px) !important;
  max-height: calc(90vh - 113px) !important;
}

.mobAbsenceUnitModalBodyMgr {
  min-height: calc(90vh - 156px) !important;
  max-height: calc(90vh - 156px) !important;
}

.mobManagerShiftModalBody {
  min-height: calc(90vh - 160px) !important;
  max-height: calc(90vh - 160px) !important;
}

.zoomIn {
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}


.move5pxR {
  margin-left: 24px !important;
}

.mobNotYetClockedModalBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 318px !important;
  min-height: 318px !important;
  border-radius: 5px 5px 0 0 !important;
}

.mobClockOnButNoBreak {
  min-height: 208px !important;
  max-height: 208px !important;
}

.mobClockOnButNoBreakBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 316px !important;
  min-height: 316px !important;
  border-radius: 5px 5px 0 0 !important;
}

.mobClockOnWithBreak1StartOnly {
  min-height: 294px !important;
  max-height: 294px !important;
}

.mobClockOnWithBreak1StartOnlyBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 402px !important;
  min-height: 402px !important;
  border-radius: 5px 5px 0 0 !important;
}

.myClockRow {
  border-bottom: 1px solid #e0ebf8;
  display: flex;
  padding-top: 3px;
  justify-content: flex-start;
  padding-left: 5%;
  padding-right: 5%;
  min-height: 80px;
  min-width: 100%;
}

.clockedRowSched {
  margin-top: 16px;
}

.clockedRow {
  background-color: #f4f8fe;
  min-width: 100%;
  font-weight: 500;
}

.clockTimesLeft {
  font-weight: 500;
  color: #496375;
  font-size: 18px;
  min-width: 35%;
  max-width: 35%;
}

.clockTimesRight {
  font-weight: 500;
  text-align: left;
  color: #496375;
  font-size: 18px;
  border-left: 1px solid #e0ebf8;
  min-height: 100%;
  margin-top: -3px;
  padding-top: 3px;
  padding-left: 20px;
  margin-left: 30px;
}

.clockStartEndTimeSpan {
  font-size: 18px;
}

.clockBreakBetweenSpan {
  font-size: 0px;
  margin-right: 14px;
  color: #859cb6;
  padding-left: 0px;
  padding-right: 0px;
  font-weight: 300;
  border-right: 1px solid #e0ebf8;
  margin-left: -7px;
}

.mobScheduledNotClockOnBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 354px !important;
  min-height: 354px !important;
  border-radius: 5px 5px 0 0 !important;
}

.mobClockOnBodyScheduledWithoutClockOn {
  min-height: 200px !important;
  max-height: 200px !important;
}

.clockedOnButNoBreak1MobScheduledBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 402px !important;
  min-height: 402px !important;
  border-radius: 5px 5px 0 0 !important;
}

.clockedOnButNoBreak1MobScheduledBody {
  min-height: 294px !important;
  max-height: 294px !important;
}

.clockedOnButBreak1MobScheduledBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 4px !important;
  min-height: 488px !important;
  border-radius: 5px 5px 0 0 !important;
}

.clockedOnButBreak1MobScheduledBody {
  min-height: 380px !important;
  max-height: 380px !important;
}

.clockedOnWithFullBreakNoSchedBody {
  min-height: 369px !important;
  max-height: 369px !important;
}

.clockedOnButBreak1EndMobScheduledBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 562px !important;
  min-height: 562px !important;
  border-radius: 5px 5px 0 0 !important;
}

.clockedOnButBreak1EndMobScheduledBody {
  min-height: 454px !important;
  max-height: 454px !important;
}

.hoveredUserRowToday:hover {
  /* background-color: #f4f8fe; */
}



.viewClockItemBtn {
  font-size: 0.9em;
  color: white;
  background-color: #214978;
  border: solid #143051 1px;
  font-weight: 500;
  padding-left: 7px;
  padding-right: 8px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 30px;
  margin-top: 18px;
  margin-bottom: 8px;
  cursor: pointer;
  min-width: -moz-fit-content;
  min-width: 95px;
  max-width: 95px;
  margin-top: 6px;
  margin-right: 8px;
  border-left: 3px solid #143051;
}

.viewClockItemBtn:hover {
  background-color: #143051;
}

.clockCardBtnAndApproveStatus {
  margin-right: 12px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 5px;
}

.clockAppStatusNotYetApproved {
  max-height: 24px;
  line-height: 23px;
  color: #00ccff;
  font-size: 10px;
  font-weight: 500;
  /* border-radius: 5px; */
  padding-right: 5px;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  min-width: 82px;
  position: relative;
  z-index: 999999;
  text-align: right;
  border-right: solid #00ccff 2px;
  margin-right: 8px;
}

.clockAppStatusApproved {
  max-height: 24px;
  line-height: 23px;
  color: #6db451;
  font-size: 10px;
  font-weight: 500;
  /* border-radius: 5px; */
  padding-right: 5px;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  min-width: 82px;
  position: relative;
  z-index: 999999;
  text-align: right;
  border-right: solid #6db451 2px;
  margin-right: 8px;
}

.clockAppStatusDeclined {
  max-height: 24px;
  line-height: 23px;
  color: #e26666;
  font-size: 10px;
  font-weight: 500;
  /* border-radius: 5px; */
  padding-right: 5px;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  min-width: 82px;
  position: relative;
  z-index: 9999;
  text-align: right;
  border-right: solid #e26666 2px;
  margin-right: 8px;
}

.clocksSchedSubLeft {
  /* display: flex; */
  /* flex-direction: row; */
  /* align-items: center; */
  /* justify-content: flex-start; */
  margin-left: 8px;
  font-size: 14px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}
.clockSchedTitle {
  font-weight: 400;
    color: #758aa2;
    margin-left: 3px;
    font-size: 10px;
    display: flex;
    align-items: center;
    text-align: left;
    min-width: 110px !important;
    margin-bottom: 2px;
}

.clockSchedValue {
  font-weight: 500;
  color: #214978;
  margin-left: 4px;
  text-align: left;
  font-size:12px;
}

.clockBrkSpan {
  color: #5479a3;
  border-radius: 5px;
  font-size: 11px;
  padding-right: 2px;
}

.clocksSchedSubRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  min-width: 140px;

  min-height: 35px;
}

.flexEnd {
  justify-content: flex-end !important;
}

.approveClock {
  min-height: 30px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: 50px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 8px;
  background-color: #82c26a;
  color: white;
  border: 1px solid #72af5b;
}

.clockIsApproved {
  min-height: 16px;
  max-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6db451;
  font-size: 12px;
  text-align: center;
  /* border-radius: 5px; */
  font-weight: 500;
  margin-right: 14px;
  border-right: 2px solid #6db451;
  padding-right: 6px;
}

.clockIsDeclined {
  min-height: 16px;
  max-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e89292;
  font-size: 12px;
  text-align: center;
  /* border-radius: 5px; */
  font-weight: 500;
  margin-right: 14px;
  border-right: 2px solid #e89292;
  padding-right: 6px;
}

.approveClock:hover {
  background-color: #77b75f;
}

.openClock {
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: 50px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 1em;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #0af;
  border: solid #1e88e5 1px;
  margin-right: 8px;
  display: none;
}

.openClock:hover {
  background-color: #3b98ea;
}

.unlinkedClockImg {
  max-width: 23px;
  margin-right: 4px;
  margin-top: -3px;
}

.noShiftSchedImg {
  max-width: 18px;
  min-width: 18px;
  margin-right: 9px;
  margin-top: -3px;
  padding-top: 4px;
  margin-left: 5px;
}
.font12px {
  font-size: 12px !important;
}

.clocksFilterBar {
  min-height: 60px;
}

.clockCardProfilePicImg {
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  object-fit: cover;
  border-radius: 100%;
  margin-left: 30px;
  border: 1px solid #c6b5eb;
  cursor: pointer;
}

.clockCardProfilePicImg:hover {
  border: 2px solid #143051;
}

.clockCardHeader {
  max-height: 50px;
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-top: -12px;
}

.noExistJtActions {
  pointer-events: none;
  visibility: hidden;
}

.fadeClockedUnderOver {
  visibility: hidden;
}

.clocksAwaitingTitles {
  text-align: left;
  padding-left: 7%;
  font-weight: 500;
  color: #bbcada;
  font-size: 14px;
  margin-top: 16px;
  min-height: 30px;
  margin-bottom: -4px;
}

.outsideOfMyMonthSpan {
  font-size: 16px;
  margin-left: 2px;
  /* color: #bbcada; */
}

.awaitingClockDivider {
  background-color: #214978;
  min-width: 80%;
  min-height: 4px;
  max-width: 80%;
  margin-left: 10%;
  margin-top: 30px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.unlinkedExlaim {
  font-weight: 900;
  font-size: 24px;

  color: #ba0000;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 20px;
}

.unlinkedFiltBtnCorrection {
  margin-top: 8px;
  max-height: 35px;
  min-height: 35px;

  line-height: 25px;
  margin-right: 10px;
  align-items: center;
  min-width: -webkit-fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  border-left: 3px solid #965ade !important;
}

.teamClockOnsFilterBarRight {
  /* min-width: 204px; */
  max-width: 204px;
  min-height: 100%;
  /* margin-right: 5%; */

  font-size: 0.9em;
  font-weight: 500;
  display: flex;

  align-items: center;
  justify-content: flex-end;
}

.narrowerFilterLeft {
  min-width: 30%;
  max-width: 30%;
}

.unlinkedQtySpan {
  border-left: 1px solid #9374d6;

  max-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 6px;
  padding-right: 3px;
  margin-left: 7px;
  min-width: 16px;
}

.invalidClockCardBtn {
  font-size: 10px;
  color: #bbcada;
  max-width: 90px;
  line-height: 12px;
  text-align: right;
  margin-right: 14px;
  font-weight: 500;
  margin-top: 4px;
}

.managerShiftClockBreakSpan {
  font-size: 12px;
  min-height: 26px;
  max-height: 26px;
  line-height: 16px;
  display: flex;
  align-items: flex-start;
  margin-top: -8px;
  margin-left: 5px;
  color: #bbcada;
}

.managerShiftProPicImg {
  max-width: 40px;
  max-height: 40px;
  min-width: 40px;
  min-height: 40px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #cbd7e6;
  margin-left: 5%;

  margin-right: -12px;
  cursor: pointer;
}

.managerShiftProPicImg:hover {
  border: 2px solid #cbd7e6;
}

.editShiftRowEmployeeNameAndPic {
  border-bottom: 1px solid #e0ebf8;
  display: flex;
  padding-top: 3px;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 100%;
  padding-top: 20px;
}

.managerShiftInitialsPlaceholder {
  max-width: 40px;
  max-height: 40px;
  min-width: 40px;
  min-height: 40px;
  object-fit: cover;
  border-radius: 5px;
  border: 2px solid #140351;
  margin-left: 5%;
  /* margin-top: 15px; */
  margin-right: -12px;
  cursor: pointer;
  background-color: #214978;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  padding-top: 2px;
}

.managerShiftInitialsPlaceholder:hover {
  background-color: #140351;
}

.greenBg {
  background-color: #82c26a;
}

.hoursBorderRight {
  min-width: 2px;
  background-color: #214978;
  max-height: 40px;
  margin-left: 12px;
  margin-right: 6px;
  margin-top: 2px;
}

.purpleHoursDivider {
  background-color: #fff;
  margin-right: 6px;
}

.jtUserProfilePicImg {
  max-width: 36px;
  min-width: 36px;
  max-height: 54px;
  min-height: 54px;
  object-fit: cover;
  border-radius: 5px;
  margin-top: 0px;
  margin-left: -14px;
  margin-right: 3px;
  z-index: 0;
}

.myRotaProPic {
  max-height: 58px !important;
  min-height: 58px !important;
  margin-top: -1px;
  /* border-radius:0px !important */
}

.secondaryProfPicImg {
  /* max-height: 48px;
  min-height: 48px;
  border-radius: 5px 5px 0 0; */
}

.desktopUserIsWorkingNowLight {
  border-left: 3px solid #96e578;
  border-radius: 3px 5px 5px 3px;
  margin-left: -15px;
}

.x01931131 {
  margin-left: -20px;
}
.pushLimBtnRight {
  margin-right: -24px;
  padding: 4px;
  border: 1px solid #30305f;
  border-radius: 5px;
  max-width: 21px;
  background-color: #7a7ac1;
}

.revIconToTheRight {
}
.greyBg {
  background-color: #516b8b !important;
}

.declinedClockDot {
  background-color: #e89292 !important;
  min-width: 10px;
  max-width: 10px;
  min-height: 10px;
  max-height: 10px;
  border-radius: 10px;
}

@media only screen and (max-width: 460px) {
  .statsBtnTxt {
  }

  .statsBtnTxtTeam {
    margin-right: 5px;
    display: inline;
  }

  .statsImg {
    max-width: 15px;
    margin-left: 4px;
    margin-right: 8px;
    margin-top: 0px;
  }

  .statsBtn {
    padding-left: 8px;
    padding-right: 4px;

    min-width: 130px;
  }
}

.hoursAndCostContainer {
  display: flex;
  justify-content: flex-end;
  min-width: 140px;
}

.hoursCostItem {
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;

  align-items: center;
  border-radius: 5px;
  border: 1px solid #e6eaee;
  color: #fff;
  font-weight: 500;
  font-size: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #fff !important;
  margin-left: 9px;
  background-color: #c6b5eb;
  /* margin-top: 3px; */
  /* margin-bottom: -3px; */
  border: #ac99d4 solid 1px;
}

.isFutureTeamHoursCost {
}

.clockCardsMenuItem {
  margin-left: 0px !important;
  margin-right: 5px !important;
}

.unlinkedSection0 {
  margin-left: -24px;
}

.clockSection0 {
  min-width: 0px;
  max-width: 0px;
}

.leftBorderGhost {
  border-left: 5px solid #cce3ff !important;
}

.partialAbsentBlockWorked {
  background-color: #a8cc9a;
  min-width: 5px;
  min-height: 72px;
  margin-right: -46px;
}

.whiteBg {
  background-color: white !important;
}

.partialAbsentBlockUnWorked {
  background-color: #cce3ff;
  min-width: 5px;
  min-height: 72px;
  margin-right: -46px;
}

.partAbsDivPlaceholder {
  background-color: rgb(255, 255, 255, 0);
  min-width: 5px;
  min-height: 72px;
  margin-right: -46px;
}

.noLeaveReqP {
  font-size: 10px;
  min-width: 52px;
  padding-right: 0px;
}

.partialAbsentCorrectionMob {
  margin-right: -35px;
}

.partialAbsentCorrectionMobWorked {
  margin-right: -15px;
}

.ghostAbsDivPlaceholderColour {
  background-color: #deedff;
}

.partialAbsentShiftDurString {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 3px;

  font-weight: 500;
  /* font-size: 14px; */
  padding-top: 2px;
  padding-bottom: 2px;
  background: rgb(247, 250, 255);

  color: #ba0000;
  background: linear-gradient(66deg, #f5e5e5 50%, rgb(247, 250, 255) 50%);
  border: 1px solid #dcc7c7;

  font-size: 12px;
  font-weight: 500;
  margin-bottom: 3px;
  /* background: #f4f8fe; */
  border-radius: 5px;
  /* border: 1px solid #bbcada; */
  /* color: #bbcada; */
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
  margin-bottom: 6px;
}

.fullyAbsentShiftDurString {
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 3px;
  color: #496375;
  font-weight: 500;
  /* font-size: 14px; */
  padding-top: 2px;
  padding-bottom: 2px;
  background: rgb(247, 250, 255);
  background-color: #e3e8ee;
  color: #4f566b;
  background-color: #f5e5e5;
  color: #ba0000;
  border: 1px solid #dcc7c7;
  margin-bottom: 8px;

  font-size: 12px;
  font-weight: 500;
  margin-bottom: 3px;
  /* background: #f4f8fe; */
  border-radius: 5px;
  /* border: 1px solid #bbcada; */
  /* color: #bbcada; */
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
  margin-bottom: 6px;
}

.hoursBorderRightTeam {
  min-width: 2px;
  background-color: #fff;
  max-height: 40px;
  margin-left: 12px;
  margin-right: 6px;
  margin-top: 2px;
}

.teamHeaderColours {
  background-color: #403a8e !important;
  border-bottom: 1px solid #403a8e !important;
}

.myRotaColours {
  background-color: #fff !important;
  /* border-bottom: 1px solid #d1dfee !important; */
}

.colourWhite {
  color: white !important;
}

.colourWhite:hover {
  color: white !important;
}
.publishedShiftLight {
  color: #bbcada !important;
  border-top: 1px solid #e3e8ee !important;
  margin-left: -18px;
  font-weight: 500;
  max-height: 18px;
  margin-right: -4px;
  padding-top: 1px;
}

.whiteGoImg {
  max-width: 10px;
  margin-right: 6px;
  margin-left: -6px;
  margin-top: 1px;
}

.whiteGoImgMyHours {
  max-width: 10px;
  margin-left: 5px;
  margin-right: -2px;
}

.teamSchedLoadingBallsImg {
  margin-top: 35px;
}

.invalidAddShiftBtn {
  opacity: 0.4;
  pointer-events: none;
  cursor: default;
}

.addDateAndDayStringContainer {
  display: flex;
}

.addShiftDayString {
  margin-left: 7px;
  font-weight: 500;
  font-size: 14px;

  color: #bbcada;
  padding-left: 6px;
  padding-right: 6px;
  max-height: 26px;
  display: flex;
  align-items: center;
  margin-top: 9px;
  border-radius: 5px;
}

.saveTimesLoadTemplateBtnAndSaveContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.saveTimesNewItemBtn {
  max-width: 50px;
}

.loadTimesTemplateBtn {
  background-color: #67adff;
  border: solid 1px #106ad0;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
  margin-right: 12px;
  margin-top: 2px;
  margin-bottom: -1px;
}

.loadTimesTemplateBtn:hover {
  background-color: #5c9fee;
}

.unlinkedClockCardsExplain {
  font-size: 12px;
  text-align: left;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 26px;
  color: #bbcada;
  min-height: 180px;
  font-weight: 500;
  border-left: 2px solid #bbcada;
  margin-left: 5%;
  margin-top: 20px;
  line-height: 17px;
}

.unlinkedClockCardsExplainMob {
  padding-left: 25px;
  min-height: 190px;
  margin-bottom: 0px;
}

.teamSchedMgrAvailBtn {
  background-color: blue;
  background-color: #9a74eb;
  border: 2px solid #9471bf;
  border-radius: 5px;
  color: white;
  min-height: 34px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
  position: fixed;
  top: 60px;
  right: 162px;
}

.teamSchedMgrAvailBtnMob {
  background-color: #9a74eb;
  border-color: #9471bf;
  color: #fff;
  top: 68px;
  right: 20px;
}

.teamSchedMgrAvailBtn:hover {
  background-color: #9471bf;
}

.teamSchedMgrAvailBtnMob:hover {
  background-color: #8e76c1;
}

.userSchedMgrAvailBtn {
  border: 1px solid #e6eaee;
  background-color: #fff;
  border-radius: 5px;
  min-height: 34px;
  color: #859dbb;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
  position: fixed;
  top: 62px;
  right: 144px;
  font-size: 14px;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
}

.nonMgrAvailBtn {
  right: 40px !important;
}

.nonMgrAvailBtnMob {
  right: 22px !important;
  top: 31px !important;}

  @media only screen and (min-width: 401px) and (max-width: 819px) {
    .nonMgrAvailBtnMob {
      right: 20px !important;
      top: 65px !important;
    }
  
  }
 
.userSchedMgrAvailBtnMob {
  border: 1px solid #e6eaee;
  background-color: #fff;
min-height:35px !important;
top: 61px;
right: 136px;
}

.myAllowsWhiteBtn {
  border: 1px solid #e6eaee;
  background-color: #fff;
  border-radius: 5px;
  min-height: 34px;
  color: #143051;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
  /* position: fixed;
  top: 62px;
  right: 110px; */
  font-size: 14px;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  /* margin-left:5%; */
  margin-top: 8px;
}

.minWidth140 {
  min-width: 140px !important;
}
.myAllowsWhiteBtn:hover {
  border-color: #dce2e9;
  background-color: #fbfdff;
}

.myAbsencePerfWhiteBtn {
  border: 1px solid #e6eaee;
  background-color: #fff;
  border-radius: 5px;
  min-height: 34px;
  color: #143051;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  font-weight: 500;
  font-size: 14px;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  /* margin-left: 4%; */
  /* margin-top: -1px; */
}
.myAbsencePerfWhiteBtn:hover {
  border-color: #d7dfe9;
}

.myAllowsWhiteBtnMob {
  border: 1px solid #e6eaee;
  background-color: #fff;
  box-shadow: none;
  /* top: 62px;
  right: 110px; */
}

@media only screen and (max-width: 414px) {
  .userSchedMgrAvailBtnMob {
    top: 73px;
    right: 20px;
  }

  .staffRotaBtnMob {
    top: 30px !important;
    right: 20px !important;
  }
}

.userSchedMgrAvailBtn:hover {
  border-color: #d7dfe9;
}

.userSchedMgrAvailBtnMob:hover {
  border-color: #d7dfe9;
}

.staffRotaBtn {
  background-color: #b49ce7;
  border: 1px solid #a47fd0;
  border-radius: 5px;
  color: white;
  min-height: 35px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* padding-top:2px; */
  padding-left: 10px;
  padding-right: 6px;
  font-weight: 500;
  position: fixed;
  /* line-height:18px !important; */
  top: 61px;
  right: 40px;
  font-size: 14px;
  /* line-height:13px; */
}

.staffRotaBtnMob {
  background-color: #b49ce7;
  border-color: #9471bf;
  color: #fff;
  top: 61px;
  right: 35px;
}

.staffRotaBtn:hover {
  background-color: #a58fd4;
}

.staffRotaBtnBtnMob:hover {
  background-color: #9a74eb;
}

.staffRotaWhiteGoImg {
  max-width: 10px;
  margin-left: 7px;
  margin-right: 1px;
}

.availTeamDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 228px;
  min-width: 228px;
  line-height: 32px;
  height: 35px;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 205px;
  margin-right: 10px;
  margin-top: 2px;
}

.availTeamDropdown:hover {
  border-color: #d7dfe9;
}

.mgrAvailModalBody {
  background-color: #f4f8fe;

  border-radius: 6px 6px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
  max-height: calc(90vh - 300px);

  min-width: 94%;
  max-width: 94%;
  margin: 0 auto;
  margin-top: 10px;
}

.mgrAvailModalBodyMob {
  background-color: #f4f8fe;

  border-radius: 6px 6px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
  max-height: calc(90vh - 105px);
  min-height: calc(90vh - 105px);
  padding-top: 10px;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 10px;
}

.teamAvailLeftRightBtn {
  margin-top: 9px;
}

.mgrAvailFooter {
  display: flex;
  justify-content: space-between;
  min-width: 94%;
  max-width: 94%;
  margin-left: 3%;
  align-items: center;
  min-height: 50px;
  border-top: solid 1px #bac5d3;
}

.closeMgrAvailReqBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  max-width: 62px;
  min-width: 62px;
}

.closeMgrAvailReqBtn:hover {
  background-color: #214978;
}

.mgrAvailUserDiv {
  border-top: solid 2px #e6eaee;
  border-radius: 6px 6px 0 0;
  background-color: #fbfdff;
  overflow-y: scroll;
}

.mgrAvailUserDivMob {
  min-height: calc(90vh - 200px);
}

.x283874821 {
  min-height: calc(90vh - 390px);
}


.mgrAvailUserRow {
  min-width: 100%;
  max-width: 100%;
  border-top: solid 1px #bac5d3;
  padding-top: 5px;
  padding-bottom: 5px;
}

.mgrAvailUserRow:first-of-type {
  border: none;
}

.mgrAvailUserRowTop {
  min-width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  align-items: flex-end;
}

.mgrAvailUserRowTopLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 15px;
}

.mgrAvailUserRowProfPic {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
}

.mgrAvailUserRowProfPic:hover {
  opacity: 0.8;
}

.mgrAvailUserRowNameTxt {
  font-weight: 500;
  color: #214978;
  font-size: 18px;
  margin-left: 10px;
  margin-top: 12px;
  cursor: pointer;
}

.mgrAvailUserRowTopRight {
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.mgrAvailUserRowTopRightViewAvailBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 72px;
  padding-left: 9px;
  padding-right: 9px;
  min-height: 35px;
  max-height: 35px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 20px;
}

.mgrAvailUserRowTopRightViewAvailBtn:hover {
  background-color: #bbcada;
}

.mgrAvailUserRowBottom {
  min-width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
  margin-bottom: 15px;
}

.mgrAvailUserRowBottomSubmittedBtn {
  border-radius: 5px;

  color: #74aff5;

  font-size: 14px;
  padding-left: 2px;
  padding-right: 9px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.mgrAvailUserRowTimes {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.mgrAvailUserRowTimeStrip {
  border: solid 1px #dae4f1;

  color: #8ea7c5;
  background-color: #fff;
  color: #7fbb67;
  min-height: 20px;
  display: flex;
  align-items: center;
  border-radius: 5px;

  font-size: 14px;
  font-weight: 500;

  padding: 6px;
}

.mgrUnavailUserRowTimeStrip {
  border-radius: 5px;

  font-size: 14px;
  padding-left: 2px;

  font-weight: 500;
  display: flex;
  align-items: center;
  margin-left: 15px;
  color: #e26666;
}

.mgrAvailRequestedStrip {
  color: #8ea7c5;
  font-size: 14px;
  font-weight: 400;
  padding-left: 15px;
  padding-right: 6px;
  margin-top: -3px;
  min-height: 23px;
}

.mgrAvailUserTimesAmperSand {
  color: #74aff5;
  font-size: 12px;
  font-weight: 500;
  padding-left: 4px;
  padding-right: 4px;
}

.requestAvailBtn {
  border-radius: 5px;
  background-color: #9a74eb;
  color: white;
  border: 1px solid #7651c6;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-left: 10px;
  min-height: 35px;
  cursor: pointer;
  margin-top: 4px;
}

.reqAvailBenIcon {
  max-width: 12px;
  /* margin-right: 5px; */
}

.requestAvailBtn:hover {
  background-color: #7651c6;
}

.requestAllAvailBtn {
  border-radius: 5px;
  background-color: #8ea7c5;
  border: 1px solid #6987a9;
  color: white;
  font-size: 14px;
  padding-left: 9px;
  padding-right: 9px;
  font-weight: 500;
  display: flex;
  align-items: center;

  min-height: 30px;
  cursor: pointer;
}
.reqAvailAllOptionsDiv {
  display: flex;
}

.whiteCrossReqAvailAllImg {
  min-height: 30px;
  min-width: 30px;
  border-radius: 5px;
  background-color: #8ea7c5;
  border: 1px solid #6987a9;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.reqAvailWeekDayBtn {
  min-height: 30px;
  min-width: 30px;
  border-radius: 5px;
  background-color: #0bf;
  color: white;
  border: 1px solid #0af;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  font-weight: 500;
  font-size: 14px;
  padding-left: 6px;
  padding-right: 6px;
}

.availRequestedStrip {
  font-size: 14px;
}

.topAvailSelectRow {
}

.teamAvailDayDiv {
  min-height: 100%;
  display: flex;
  justify-content: flex-start;
  margin-right: 10px;
}

.rerequestAvailBtn {
  cursor: pointer;
  max-width: 25px;
  margin-bottom: -7px;
}

.rerequestAvailBtn:hover {
  opacity: 0.8;
}

.mobMgrAvailModalBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 90vh !important;
  min-height: 90vh !important;
  border-radius: 5px 5px 0 0 !important;
}

.mgrAvailFooterViewModeDiv {
  display: flex;
  min-width: 70px;
}
.mgrAvailDayModeBtn {
  font-size: 14px;
  font-weight: 500;
  color: #859dbb;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 72px;
  padding-left: 8px;
  padding-right: 9px;
  min-height: 35px;
  max-height: 35px;
  /* background-color: #8ea7c5; */
  border: 1px solid #d7dfe9;
  border-radius: 5px;
  cursor: pointer;
  margin-left:8px;
}

.availMonthViewPopupImg {
  max-width: 15px;
  margin-left:4px;
  /* margin-right:5px; */
  /* border:1px solid #bbcada; */
  /* padding:2px; */
  /* min-height:16px; */
  /* border-radius: 3px; */
}

.mgrAvailDayModeBtn:hover {
  border-color: #bbcada;
}

.dayMonWeekSelectedMode {
  /* background: none;
  color: #bbcada;
  border: none;
  border-bottom: 2px solid #e7ebf1;
  border-radius: 0;
  cursor: default; */
  /* pointer-events: none; */
  /* display: none; */
}

.addAvailLoadingImg {
  min-height: 10px;
}

.usersMonWeekAvailContainer {
  display: flex;
  min-height: 100%;
}

.monWeekAvailUserRow {
  border-right: solid 1px #bac5d3;
  border-top: solid 1px #bac5d3;
  padding-top: 10px;

  min-width: 120px !important;
  max-height: 90px;
  min-height: 90px;
}

.monWeekAvailUserRow:first-of-type {
  border-top: none;
}

.monWeekAvailUserProPicImg {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  object-fit: cover;
  border-radius: 5px;

  cursor: pointer;
  margin-left: 4px;
}

.monWeekAvailUserProPicImg:hover {
  opacity: 0.8;
}

.monWeekAvailUserRowImgAndReqBtnContainer {
  display: flex;
  font-size: 14px;
  align-items: center;
  min-height: 32px;
}

.monWeekAvailNameContainer {
  text-align: left;
  font-weight: 500;
  color: #214978;
  font-size: 12px;
  margin-top: 6px;
  margin-left: 4px;
}

.monWeekAvailRowRequestBtn {
  border-radius: 3px;
  background-color: #a58fd4;
  color: white;
  border: 1px solid #a47fd0;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 7px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-left: 4px;
  min-height: 30px;
  cursor: pointer;
  margin-top: 0px;
  display: flex;
  align-items: center;
}

.monWeekAvailRowRequestBtn2 {
  border-radius: 3px;
  background-color: #a58fd4;
  color: white;
  border: 1px solid #a47fd0;
  font-size: 12px;
  /* padding-left: 5px; */
  /* padding-right: 7px; */
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-left: 4px;
  min-height: 26px;
  cursor: pointer;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 26px;
}

.monWeekAvailRowRequestBtn:hover {
  border-color: #a47fd0;
}

.monWeekAvailRowRequestBtn2:hover {
  border-color: #a47fd0;
}

.availMsgIcon {
  min-width: 26px;
  max-width: 26px;
  background-color: #07f;
  border: #0469de 1px solid;
  max-height: 26px;
  border-radius: 3px;
  margin-left: 4px;
  padding: 5px;
  cursor: pointer;
}

.availMsgIcon:hover {
  border-color: #036ce4;
}

.monWeekAvailRowRequestBtn:hover {
  background-color: #a47fd0;
}

.usersMonWeeksDataCol {
  min-width: calc(100% - 120px);
  max-width: calc(100% - 120px);

  min-height: 100%;
  display: flex;
  overflow-x: scroll;
}

.teamAvailDsCol {
  min-width: 130px;
  min-width: 130px;

  border-right: 1px solid #e6eaee;
}

.teamAvailDsCol:last-child {
  border: none;
}

@media only screen and (min-width: 500px) and (max-width: 1100px) {
  .mobileMgrAvailModalBoxMediaQ {
    max-width: 90vw;
    min-width: 90vw;
  }
}

@media only screen and (min-width: 1171px) {
  .mobileMgrAvailModalBoxMediaQ {
    max-width: 1150px !important;
    min-width: 1150px !important;
  }
}

.dayViewAvailModalBox {
  margin-top: -2px !important;
}

.mobAddAvailSelectorDiv {
}

.availSpanFormatting {
  color: #49b63e;
  padding: 5px;
  border-radius: 3px;
  max-height: 17px;
  display: flex;
  align-items: center;
  padding-left: 0px;
  margin-left: 5px;
}
.availSpanFormatting:hover {
}

.availSelectRowTeamDay {
}

.teamAvailNameRowBlankTitle {
  min-height: 40px;
  min-width: 100%;
}

.availUserColDayCell {
  color: #214978;
  font-size: 18px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.monWeekAvailUserRow:nth-child(odd) {
  background-color: #f4f8fe;
}

.mgrAvailNameDayRow {
  min-height: 90px;
  max-height: 90px;

  border-top: solid 1px #bac5d3;
}

.mgrAvailNameDayRow:first-child {
  border-top: none;
}

.mgrAvailNameDayRow:nth-child(odd) {
  background-color: #f4f8fe;
}

.mgrAvailRowDsCell {
  color: #516b8b;
  text-align: left;
  font-weight: 500;
  /* padding-left: 9px; */
  /* padding-top: 5px; */
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  font-size: 12px;
  /* border-left: 3px solid #0af; */
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-left: 7px;
  min-height: 24px;
}

.availAddShiftBtn {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  padding-left: 4px;
  padding-right: 4px;
  min-height: 24px;
  max-height: 24px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
  padding-bottom: 3px;
  margin-right: 14px;
}

.availAddShiftBtn:hover {
  background-color: #bbcada;
}

.mgrAvailUserInlineTimes1Row {
  border-radius: 3px;
  /* max-width: 112px; */
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  font-weight: 500;
  /* margin-left: 3px; */
  /* margin: 0 auto; */
  margin-top: 2px;
  font-size: 12px;
  /* padding-left: 5px; */
  padding-right: 5px;
  max-height: 33px;
  color: #49b63e;
  text-align: left;
  /* margin-left: -10px; */
}

.mgrAvailUserInlineTimes2Row {
  /* background-color: #7fbb67; */
  /* color: white; */
  /* border: 1px solid #5a9e3f; */
  border-radius: 3px;
  /* margin-left: -12px; */
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  font-weight: 500;
  /* margin-left: 3px; */
  /* margin: 0 auto; */
  font-size: 12px;
  /* padding-left: 5px; */
  padding-right: 5px;
  max-height: 20px;
  /* margin-top: 2px; */
  color: #49b63e;
  text-align: left;
}

.x248149 {
  font-size: 10px;
  line-height: 8px;
  margin-bottom: -2px;
  opacity: 0.5;
  font-weight:400
}

.mgrAvailUserInlineTimesAmper {
  color: #cbdaec;
  font-weight: 500;
  font-size: 10px;
  margin-top: -2px;
  margin-bottom: -8px;
  margin-left: -15px;
  display: none;
}

.mgrAvailCellBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 51px;
  min-width: 100%;
  padding-left: 8px;
}

.mgrAvailUserCellUnavailable {
  color: #94a7bf;
  border-radius: 0px;
  max-width: 106px;
  font-weight: 500;
  /* margin-left: 3px; */
  font-size: 12px;
  /* padding-left: 8px; */
  /* padding-right: 5px; */
  max-height: 20px;
  margin-top: 7px;
  /* margin-left: -14px; */
  border-left: 0px solid #ba0000;

}

.highlightAvailUser {
  border-bottom: 2px solid #143051;
}

.selectedUserAvailRowInline {
  border-bottom: 2px solid #143051;
}

.closedAvailDayCol {
  background-color: #e4eef9 !important;
}

.weekViewUserRequested {
  text-align: left;
  color: #8ea7c5;
  font-size: 12px;
  font-weight: 500;
  line-height: 9px;
  padding-left: 8px;
  max-height: 32px;
  padding-bottom: 2px;
}

.rerequestWeekViewAvailImg {
  margin-top: -2px;
  padding-top: 2px;
  max-width: 20px;
}

.availWeekMsgBtn {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 66px;
  max-width: 66px;
  padding-left: 1px;
  padding-right: 1px;
  min-height: 24px;
  max-height: 24px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 3px;
  cursor: pointer;
}

.availWeekMsgBtn:hover {
  background-color: #bbcada;
}

.vhIssue {
  padding-bottom: 130px !important;
}

.shiftUserInfoTriangle {
  min-width: 35px;
  max-width: 35px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35px;
  max-height: 35px;

  border-radius: 5px;
  padding: 9px;
  border: 2px solid #e3e8ee;
  margin-right: 18px;
}

.shiftUserInfoTriangle:hover {
  border: 2px solid #b2bbc7;
}
.shiftExpandInfoImg {
  max-width: 15px;
}

.expandGreyCloseShiftInfo {
  padding: 1px;
}

.shiftStaffInfoRowTop {
  display: flex;
  justify-content: flex-end;
}

.shiftDataTableHolder {
  display: flex;
  justify-content: flex-end;
}

.shiftDataTable {
  background-color: #f4f8fe;
  min-width: 90%;
  max-width: 90%;
  margin-right: 5%;
  padding: 10px;
  border-radius: 0 0 5px 5px;
  border: 1px solid #e6eaee;
  border-top: none;
  border-bottom: 2px solid #e6eaee;
}

.shiftDataTableRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 45px;
  padding-left: 10px;
}

.shiftDataTableRowTop1pxBorder {
  border-top: 1px solid #e0ebf8;
}

.shiftDataTableTitle {
  font-weight: 500;
  font-size: 14px;
  color: #496375;
}

.shiftDataTableValue {
  font-weight: 500;
  color: #8ea7c5;
  min-width: 85px;
  text-align: right;
  font-size: 14px;
  padding-right: 5px;
}

.monthHoursDataTitle {
  font-weight: 500;
  color: #0af;
}

.shiftDataRowIndent {
  padding-left: 30px;
}

.shiftDataBorderTopThick {
  border-top: 1px solid #859dbb;
  border-bottom: none;
}

.shiftDataSubIcon {
  max-width: 12px;
  margin-right: 9px;

  margin-bottom: 2px;
}

.teamHoursMyDropdown {
  background-color: #1468a4;
  max-height: 35px;
  min-height: 35px;

  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 137px;
  padding-left: 10px;
  padding-right: 10px;
  border: 2px solid #fff;
  color: #6a8bb1;
  font-weight: 500;

  /* background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff; */
  background: url(/static/media/downArrowWhite2.887d57ce.svg) no-repeat right
    #8a65f0;
  background-position-x: 110px;
  border-radius: 5px;
  font-size: 14px;
  margin-right: 14px;
  cursor: pointer;
  padding-bottom: 1px;
}
.teamHoursMyDropdown:hover {
  background-color: #2c7eb9;
}

.desktopTeamHoursDataBoxHeader {
  font-weight: 500;
  min-width: 100%;
  padding-left: 15px;
  min-height: 40px;
  text-align: left;
  margin-top: 17px;
  display: flex;
  justify-content: space-between;
}

.selectedTeamColours {
  border: 1px solid #794add;
  color: #fff;
  background: url(/static/media/downArrowWhite2.887d57ce.svg) no-repeat right
    #8a65f0;
  background-position-x: 215px;
}

.selectedTeamColours:hover {
  border-color: #6d43c6;
}

.teamHoursDataBoxDesktopScroll {
  padding-bottom: 2px !important;
  cursor: default !important;
}

.allStaffTeamHoursDivModalDesktop {
  max-height: calc(100vh - 280px) !important;
  padding-top: 10px;
  /* border-top: 1px solid #e6eaee; */
  /* background-color: #fcfdff; */
}

.expandedNamePayableHoursDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-radius: 0 0 5px 5px;
  background-color: #b997f1;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  -webkit-animation-name: heightDown !important;
          animation-name: heightDown !important;
  -webkit-animation-duration: 0.2s !important;
          animation-duration: 0.2s !important;
  padding-bottom: 5px;
  padding-right: 6px;
  border: 2px solid #c2a9fa !important;
  border-top: none !important;
}

.payableHoursUserItemContainer {
  max-width: 90%;
  margin-left: 5%;
  border-radius: 2px;
  margin-top: 8px;
  box-shadow: 0px 0px 2px rgb(0 0 0 / 20%);
}

.mgrTeamHoursDataItem {
  min-height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px;
  padding-left: 13px;
  cursor: pointer;
  background-color: white;
  border-radius: 3px;
  border: #fff 1px solid !important;
  /* box-shadow: 0px 0px 2px rgb(0 0 0 / 20%); */
  background-color: #a387df !important;
  border: 1px solid #c2a9fa !important;
  /* /* padding-right: 15px; */
}

.dataItemBorderRight {
  border-radius: 5px 5px 0 0;
  background-color: #b997f1 !important;
  border-bottom: none !important;
}

.mgrTeamHoursDataItem:hover {
  border: #fff 1px solid !important;
  padding: 0px;
  padding-left: 13px;
  padding-right: 1px;
}

.payableDataBoxTeamName {
  opacity: 0.8;
  font-size: 12px;
  margin-top: 2px;
  color: #f4f8fe;
  font-weight: 500;
}

.payableExpandedRow {
  min-width: 230px;
  max-width: 230px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-right: 9px;
  min-height: 30px;
  align-items: center;
  padding-left: 14px;
  padding-right: 6px;
  /* border-radius: 5px; */
  margin-top: 2px;
  /* border: 1px solid #f4f8fe; */
  /* border-left: 3px solid #e3e8ee; */
  margin-top: 8px;
  border-bottom:1px solid #ebbdff
}
.payableExpandedRow:first-child {
  /* box-shadow: 0px 0px 2px rgb(0 0 0 / 20%);
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 7px;
  background-color: #fcfbff;
  margin-top: 8px; */
}
.payablePurpleBg {
  /* box-shadow: 0px 0px 2px rgb(0 0 0 / 20%); */
  border-radius: 5px;
  padding-left: 7px;
  padding-right: 7px;
  /* background-color: #fcfbff; */
  /* border-left: 3px solid #e3e8ee; */

  border: 1px solid #7e60af;
  background: #d2b8fd;
  border-left: 3px solid #7e60af;
}

.payableExpandedRow:last-child {
  margin-bottom: 15px;
}

.payableExpandedTitle {
  font-weight: 500;
  color: #735997;
  margin-left: 3px;
}

.payableExpandedTitle2 {
  font-weight: 500;
  color: #fff;
  font-size: 12px;
}

.payableExpandedTitle3 {
  font-weight: 500;
  color: #735997;
  font-size: 13px;
  margin-left: 4px;
}

.payableExpandedValue {
  font-weight: 500;

  color: #fff;
  font-size: 12px;
}

.noHoverExpandable:hover {
    /* background-color: #fff !important; */
    cursor: default;
    border: none;
    padding: 0px;
    padding-left: 12px;
    padding-right: 0px;
    border: 2px solid #c2a9fa !important;
    border-bottom: 0px solid white !important;
    padding-bottom: 1px;
}

.staffAbsDataBoxSplitter {
  min-height: 1px;
  min-width: 94%;
  max-width: 94%;
  margin-right: 1%;
  background-color: #fff;
  margin-bottom: -4px;
  margin-top: 4px;
  opacity: 0.5;
}

.noBorderBottom {
  border-bottom: none !important;
}

.payableUserBtns {
  display: flex;

  min-width: -webkit-fit-content;

  min-width: -moz-fit-content;

  min-width: fit-content;
  justify-content: space-between;
  align-items: center;
  min-height: 30px;
  max-height: 30px;
  font-size: 13px;
  margin-right: 5px;
}

.payableUserHours {
  color: #fff;
  border-radius: 5px;
  font-weight: 500;

  padding-left: 6px;
  padding-right: 6px;
  min-height: 26px;
  align-items: center;
  display: flex;
  justify-content: center;
  /* margin-right: 8px; */
  cursor: pointer;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  margin-left: 5px;
  border: 1px solid white;
  background-color: #d264ff;
  border: 1px solid #a058be;
}

.payableUserHours:hover {
  /* color: #fff; */
  /* border: 1px solid #bbcada; */
  background-color: #ca5ef6;
}

.teamStatsColours {
  background-color: #a387df;
  border: 1px #8e76c1;
}

.teamStatsColours:hover {
  background-color: #8e76c1;
}
.paddingTop20Px {
  padding-top: 20px;
}

.modalTeamHoursDataBox {
  /* min-height: calc(100vh - 70px); */
  /* max-height: calc(100vh - 70px); */
  min-width: 100% !important;
  max-width: 100% !important;
  margin-left: 0px !important;
}

.mobHoursBarHeight {
  min-height: 50px !important;
}

.txtFontSize15px {
  font-size: 15px !important;
}

.nonMgrStatsHoursBtnCorrection {
  margin-right: 36px !important;
}

.shiftsHeaderContentContainer {
  min-width: 100vw;
  max-width: 100vw;
  display: flex;
  overflow: visible;

  align-items: center;
  justify-content: space-between;
  min-height: 94px;
  margin-top: 30px;
  padding-left: 2%;
  min-height: 94px;
}

.x1038181 {
  margin-top: 80px
}
.shiftsHeaderLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 24px;
  min-width: 330px;
  overflow: visible;
  margin-left: 10px;
}

.mobTeamShiftsHeaderLeft {
  margin-top: 14px;
}

.mobTeamReqsHeaderLeft {
  margin-top: 18px;
}

.shiftHeaderLeftTitle {
  color: #143051;
  font-weight: 500;
  font-size: 28px;
  text-align: left;
  display: flex;
  align-items: flex-end;
  margin-bottom: 2px;
}

.myRequestsHeaderLeftTitle {
  color: #143051;
  font-weight: 500;
  font-size: 28px;
  text-align: left;
  display: flex;
  align-items: flex-end;
  margin-bottom: 0px;
  margin-top: -7px !important;
}

.myRequestsHeaderLeftTitleMob {
  margin-bottom: -8px;
  margin-top: -3px;
}

.myRequestsHeaderLeftTitleMob2 {
  margin-bottom: -10px;
  margin-top: -1px !important;
}
.myRequestsHeaderLeftTitleMob3 {
  margin-bottom: -11px;
  margin-top: -7px !important;
}

.shiftHeaderMenuRow {
  min-width: 224px;
  max-width: 224px;

  min-height: 35px;
  margin-top: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  color: #8290b6;
  margin-left: 1px;
  margin-bottom: 1px;
}

.staffShiftHeaderMenuRow {
  margin-top: -5px;
}

.shiftHeaderMenuRowMob2 {
  margin-top: 3px;
}

.myHoursMenuRow {
  min-height: 41px !important;
  margin-top: 6px;
  margin-bottom: 0px;}

.shiftHeaderMenuRowMob {
  margin-top: -6px !important;
  margin-bottom: -1px !important;}

.shiftHeaderMenuRowTeam {
  min-width: 224px;
  max-width: 224px;
  margin-top: -4px;
  margin-bottom: -2px;
}

.teamStaffHoursTitleMob {
  margin-top: 3px;
}

.staffHoursTeamMob {
  margin-top: 3px;
}
.reqsHeaderMenuRow {
  min-width: 198px;
  max-width: 198px;

  min-height: 35px;
  margin-top: -2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  color: #8290b6;
  margin-left: 1px;
}

.absenceHeaderMenuRow {
  min-width: 353px;
  max-width: 353px;
  min-height: 35px;
  margin-top: -2px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  color: #8290b6;
  margin-left: 1px;
}

.absenceHeaderMenuRowStaff {
  margin-top: -2px;
  margin-bottom: 2px;
  min-width: 385px;
  max-width: 385px;
}

.x000110101 {
  min-width: 335px;
  max-width: 335px;
}

.calendarHeaderMenuRow {
  min-width: 303px;
  max-width: 303px;
  min-height: 35px;
  margin-top: -2px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  color: #8290b6;
  margin-left: 1px;
}

.reqsHeaderMenuRowMob {
  margin-top: 9px;
}

.absenceHeaderMenuMob {
  margin-top: 6px;
  max-width: 289px !important;
  min-width: 289px !important;
}

.calendarHeaderMenuRowMob {
  margin-top: 6px;
  max-width: 299px !important;
  min-width: 299px !important;
}

.reqsHeaderMenuRowTeam {
}

.staffReqsTitleDynamic {
  margin-top: -9px;
}

.shiftHeaderMenuItem {
  color: #8290b6;
  cursor: pointer;
  min-height: 26px;
  font-size: 14px;
}

.shiftHeaderMenuItem:hover {
  color: #859dbb;
  /* border-bottom: 3px solid #dde9f9; */
}

.shiftHeaderMenuItemSelect {
  color: #143051 !important;
  cursor: default;
  /* border-bottom: 3px solid #00ccff; */
}

.shiftHeaderMenuItemSelect:hover {
  color: #143051 !important;
  /* border-bottom: 3px solid #00ccff; */
}

.staffColour {
  color: #9576cc;
}

.staffColour:hover {
  color: #9576cc;
}

.shifHeaderMenuSplitter {
  min-width: 2px;
  background-color: #214978;
  min-height: 30px;
  display: none;
}

.shifHeaderMenuSplitterTeam {
  min-width: 2px;
  background-color: #723fce;
  min-height: 30px;
  display: none;
}

.mobTeamHoursSplitterTeam {
  background-color: white;
}

@media only screen and (min-width: 820px) {
  .shiftsHeaderContentContainer {
    min-width: calc(100vw - 240px);
    max-width: calc(100vw - 240px);
  }
}

@media only screen and (min-width: 1140px) {
  .shiftsHeaderContentContainer {
    min-width: 900px;
    max-width: 900px;
  }

  .x9349714 {
margin-top:-21px  !important  }

  .TopBar {
    min-width: 900px;
    max-width: 900px;
  }

  .x29498284 {
    margin-top:18px;

  }
}

.followingStaffTempTitle {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size:14px;
}

.userNotInTeamItem {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  min-height: 24px;
  align-items: center;
  border-radius: 5px;
  color: #496375;
  font-weight: 500;
  font-size: 14px;
  background-color: #e3e8ee;
  color: #4f566b;
  margin-top: 5px;
}

.teamSchedTopBarSize {
  min-width: calc(100vw - 240px);
  max-width: calc(100vw - 240px);
  border-right: none;
}

.teamSchedTopBarSizeRota {
  min-width: calc(100vw - 160px);
  max-width: calc(100vw - 160px);
  border-right: none;
}

.shiftHeaderTeamRightContainer {
  overflow: visible;
  min-height: 73px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  min-width: 220px;
  max-width: 220px;
  margin-right: 108px;
  margin-top: 43px;
}

.reqsHeaderTeamRightContainer {
  overflow: visible;
  min-height: 77px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  min-width: 220px;
  max-width: 220px;

  position: fixed;
  top: 61px;
  right: 28px;
}
.shiftHeaderTeamRightContainerMobReqs {
  overflow: visible;
  min-height: 35px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  min-width: 220px;
  max-width: 220px;

  margin-right: 18px;
  margin-top: -1px;
}


@media only screen and (max-width: 1139px) {
  .shiftHeaderTeamRightContainer {
    position: fixed;
    /* top: 28px; */
    right: -61px;
  }

  
}

@media only screen and (max-width: 1000px) {
  .shiftHeaderTeamRightContainer {
    position: fixed;
    /* top: 28px; */
    right: -71px;
  }

  
}


@media only screen and (max-width: 819px) {
  .shiftHeaderTeamRightContainer {
    position: fixed;
    top: 28px;
    right: 3px;
  }

  .shiftHeaderTeamRightContainerMobReqs {
    position: fixed;
    top: 28px;
    right: 3px;
  }
}

@media only screen and (max-width: 548px) {
  .shiftHeaderTeamRightContainer {
    position: fixed;
    top: 8px;
    right: 3px;
    min-height: 77px;
  }

  .shiftHeaderTeamRightContainerMobReqs {
    position: fixed;
    top: 8px;
    right: 3px;
    min-height: 70px;
  }
}

.shiftHeaderTeamRightContainerMob {
  min-height: 0px;
  max-height: 0px;
  top: -17px;
  right: -87px;
  /* background: blue; */
}

.shiftsHeaderTeamDropdown {
  min-width: 200px;
  min-height: 35px;
  background-color: blue;
  max-width: 200px;

  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #143051;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 175px;
  font-weight: 500;
  font-size: 14px;
  padding-left: 10px;
}

.shiftsHeaderTeamDropdown:hover {
  border-color: #d7dfe9;
}

.shiftsHeaderTeamDropdownMob {
  background-position-x: 145px;
  min-width: 170px;
  min-height: 35px;
  max-height: 35px;
}

.shiftsHeaderTeamDropdownMob2 {
  background-position-x: 145px;
  min-width: 170px;
  min-height: 35px;
  max-height: 35px;
  margin-left:8px;
}

.noHover:hover {
  color: #8290b6;
}

.teamSelectedDrop {
  color: white;
  background: url(/static/media/downArrowWhite.97cd93aa.svg) no-repeat right #a387df;
  /* background-position-x: 174px; */

  background-position-x: 174px;
  background-color: #7950cf !important;
  border: 1px solid #56369c !important;
}

.teamSelectedDropReqs {
  color: white;
  background: url(/static/media/downArrowWhite.97cd93aa.svg) no-repeat right #a387df;
  background-position-x: 213px;

  background-color: #4b1caf !important;
  border-color: #4c2d91 !important;
}

.desktopTeamRotaTitleBlock {
  margin-left: -30px;
}

.myShiftClockOnOffFlex {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid #e0ebf8;
  padding-left: 10px;
  border-radius: 5px 5px 0 0;
  padding-right: 15px;
  margin-top: 13px;
  padding-left: 14px;
  background-color: #f4f8fe;
}

.myShiftClockOnOffFlex:last-child {
  margin-bottom: 10px;
  margin-top: -1px;
  border-radius: 0px 0px 5px 5px;
}
.txtAlignRight {
  text-align: right;
}

.myShiftClockedDivRowValue {
  font-size: 16px;
  margin-top: -6px;
}

.myShiftClockedDivRowTitle {
  color: #214978;
  max-height: 17px;
  line-height: 18px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-size: 12px;
  margin-top: 13px;
  font-weight: 400;
}

.viewClockOnMyShiftBtn {
  background-color: #fff;
  color: #859dbb;
  font-size: 14px;
  padding: 8px;
  border-radius: 5px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: #e3e8ee solid 2px;
  cursor: pointer;
  margin-top: 20px;
}

.viewClockOnMyShiftBtn:hover {
  border-color: #b2bbc7;
}

.clockCardMyShiftPopUp {
  max-width: 17px;
  margin-left: 6px;
}

.breakTeamsPushDown {
  margin-top: -8px;
  margin-left: 44px;
}

.blueColours {
  background-color: #1e88e5 !important;
  border: #0469de 1px solid !important;
}

.blueColoursNoHover2 {
  background-color: #1e88e5 !important;
  border: #0469de 1px solid !important;
}

.blueColours:hover {
  background-color: #1f78de !important;
}

.purpColours {
  background-color: #9a74eb !important;
  border: 1px solid #965ade !important;
}

.purpColours:hover {
  background-color: #967ccf !important;
}

.teamRotaAvailCorrection {
}

.availPopupImg {
  max-width: 15px;
  margin-left: 5px;
}

.cursorDefault {
  cursor: default !important;
}

.jtUserContractedHours {
  max-width: 90%;
  margin-left: 10%;
  border-radius: 0 0 0 5px;
  border-top: 1px #e6eaee solid;

  margin-top: 5px;
  margin-bottom: 1px;
  min-height: 22px;
  max-height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}

.jtUserContractedHoursMob {
  display: flex;
  flex-direction: column;
  padding-left: 2px;

  padding-bottom: 3px;
  border-bottom: 1px solid #dde9f9;
  border-radius: 0px;
}

.jtUserContractedHours:hover {
  background-color: #f4f8fe;
}

.contractedWithAvail {
  margin-top: -8px;
  margin-bottom: 0px;
}

.jtUserContractedSecondaryOnly {
  min-height: 17px;
  margin-top: 4px !important;
}

.contractedWithAvailSecondaryOnly {
  margin-top: -16px !important;
  margin-bottom: 4px !important;
  min-height: 25px !important;
  max-height: 25px !important;
}

.jtUserContractedTitle {
  font-weight: 500;
  font-size: 8px;
  color: #8ea7c5;
  padding-top: 8px;
}

.jtUserContractedTitleMob {
  font-size: 8px;
  text-align: left;
  min-width: 100%;

  opacity: 0.25;
  margin-top: -1px;
  color: #143051;
  padding-top: 2px !important;
}

.jtUserContractedValue {
  font-weight: 500;
  font-size: 10px;
  color: #143051;
  padding-top: 8px;
}

.jtUserContractedValueMob {
  padding-top: 0px;
  min-width: 100%;
  text-align: left;
  margin-top: 0px;
  color: #bbcada;
  font-size: 8px;
  color: #143051;
  opacity: 0.25;
}

.contractedGreen {
  color: #66aa4b;
}

.zeroHoursContracted {
  background-color: #fff !important;
  padding-left: 2px;
  padding-right: 2px;
}

.nwDayTxt {
  color: #bbcada;
  padding: 5px;
  border-radius: 1px;
  max-height: 17px;
  display: flex;
  align-items: center;
  cursor: pointer;
  /* padding-left: 5px; */
  margin-left: -2px;
  /* border-left: 2px solid #cbd7e6; */
  /* display: none; */

}

.nwDayTxt:hover {
  /* color: #214978; */
}

.minH186 {
  min-height: 185px !important;
}

.highlightRed {
  color: #ba0000 !important;
}

.minH188px {
  min-height: 188px !important;
}

.shiftNotesTitle {
  margin-left: 7px !important;
}

.closeShiftNotesWhiteCrossImg {
  margin-left: 6px;
}

.notesBtnShift {
  min-width: 81px !important;
}

.notesBtnShift2 {
  min-width: 89px !important;
}
.noteBtnSelected {
  background-color: #214978 !important;
  border-color: #143051 !important;
  min-width: 76px !important;
  margin-right: 5px;
}

.noteBtnSelected:hover {
  background-color: #143051 !important;
}

.shiftNotesTitleTxt {
  font-size: 12px;
  color: #214978;
  font-weight: 500;
  margin-left: 25px;
}

.shiftNoteHeaderBox {
  margin-top: 15px;
}
.shiftNoteHeaderTxt {
  margin-left: 20px;
  font-weight: 500;
}

.shiftNoteHeaderTxt2 {
  margin-left: 20px;
  font-weight: 500;
  max-height: 32px;
  margin-top: -21px;
  color: #bbcada;
  margin-bottom: 7px;
  font-size: 14px;
}

.notesTagUnit {
  background-color: white !important;
  border: 1px solid #e3e8ee !important;
}

.shiftNotesTitleAndAdd {
  display: flex;
  justify-content: space-between;
  max-height: 70px;
  margin-top: 20px;
  border-top: solid 1px #e3e8ee;
  padding-top: 13px;
}

.shiftNotesAddNoteBtn {
  background-color: #00bbff;
  border: 1px solid #0af;
  min-height: 35px;
  border-radius: 5px;
  min-width: 78px;
  color: white;
  font-weight: 500;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  margin-right: 20px;
  padding-right: 10px;
  padding-left: 3px;
}

.shiftNotesAddNoteBtn:hover {
  background-color: #0af;
}

.toggleAddNote {
  background-color: #214978;
  border-color: #143051;
  min-width: 70px;
}
.toggleAddNote:hover {
  background-color: #143051;
}

.shiftAddNoteDiv {
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
  max-width: 90%;
  margin-left: 5%;
  max-height: 100px;
}

.shiftAddNoteTextArea {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 6px;
  background-color: #fdfeff;
  resize: none;
  min-width: 74%;
  max-width: 74%;
  min-height: 90px;
  padding: 5px;
  padding-left: 10px;
  color: #516b8b;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 60px;
}

.shiftAddNoteSaveBtn {
  background-color: #07f;
  border: #0469de 1px solid;
  min-height: 35px;
  border-radius: 5px;
  min-width: 68px;
  color: white;
  font-weight: 500;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  /* margin-top:10px; */
}
.shiftAddNoteSaveBtn:hover {
  background-color: #0469de;
}

.shiftAddNoteSaveBtnCanx {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  min-height: 35px;
  border-radius: 5px;
  min-width: 68px;
  color: white;
  font-weight: 500;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;

  margin-top: 6px;
}

.shiftAddNoteSaveBtnCanx:hover {
  background-color: #bbcada;
}

.shiftNotesObj {
  background-color: #fbfdff;
  border: 1px solid #e6eaee;
  max-width: 90%;
  margin-left: 5%;
  border-radius: 5px;
  margin-top: 20px;
  padding: 15px;
}

.shiftNotesObj:first-child {
  margin-top: 10px;
}
.noteObjNameAndDs {
  min-width: 100%;
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin-top: -15px;
}

.noteObjName {
  font-weight: 500;
  color: #214978;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.noteObjFsDs {
  font-weight: 500;
  color: #bbcada;
  font-size: 9px;
  margin-bottom: 2px;
}

.dayColContent {
  min-width: 100%;
}
.noteObjNote {
  font-weight: 500;
  line-height: 22px;
  padding-right: 12px;
  font-size: 15px;
  color: #8ea7c5;
}

.notesHolder {
  border-left: 2px solid #143051;
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.notesActive {
  background-color: #8ea7c5 !important;
  border-color: #748ca6 !important;
}

.deleteNoteRow {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: -6px;
}

.binNoteImg {
  cursor: pointer;
  min-width: 28px;
  max-width: 28px;
  min-height: 28px;
  max-height: 28px;
  border-radius: 5px;
  border: #e3e8ee solid 2px;
  padding: 5px;
  background-color: white;
  margin-top:12px;
}

.binNoteImg:hover {
  border-color: #b2bbc7;
}

.noBorderBottom {
  border-bottom: none !important;
}

.noShiftNotes {
  font-weight: 500;
  margin-left: 20px;
  color: #cbd7e6;
  margin-top: 10px;
}

.noComments {
  font-weight: 500;
  margin-left: 20px;
  color: #cbd7e6;
  margin-top: 10px;
  text-align: left;
  padding-bottom:15px;
}

.myRotaBottomBar {
  background-color: #143051;
  min-height: 20px;
  min-width: 100%;
}

.noBottomPadding {
  padding-bottom: 0px !important;
}

.userRotaAvailColours {
  background-color: #cedae8;
  border-top: 1px solid #b2bfcf;
  border-bottom: 1px solid #b2bfcf;
}

.limitRowInitialColUser {
  border-left: 1px solid #b2bfcf;
  margin-top: 9px;
color:white !important
}

.userRotaAvailColoursLast {
  border-radius: 0 5px 5px 0;
  border-right: 1px solid #b2bfcf;
}
.myRotaShiftBox {
  padding-right: 4px !important;
}
.myRotaShiftBoxDesktop:hover {
  background-color: #f7fafe;
  /* border: 2px solid #214978; */
  /* padding: 0px; */
  /* padding-right: 2px !important; */
}

.myRotaShiftBoxStandalone {
  cursor: default;
}
.myRotaShiftBoxStandalone:hover {
  
  background-color: white;
}
.claimedOpen {
  /* box-shadow: none; */
  border: 2px solid #e6eaee;
  /* padding: 0px; */
  border-left: 5px solid #143051;
}

.claimedOpen:hover {
  background-color: #f4f8fe;
  border: 2px solid #214978;
  padding: 0px;
  padding-right: 3px !important;
}

.leaveShiftBoxMyRota {
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  border: 1px solid #735ebd;
}

.leaveShiftBoxMyRota:hover {
  /* background-color: #143051; */
  /* border: 2px solid #143051;
  padding: 0px;
  padding-right: 0px;
  padding-left: 1px;
  padding-right: 1px; */
}

.leaveShiftBoxMyRotaStandalone {
  box-shadow: none  !important;
  border: 1px solid #3d344e;
  cursor: default;
}
.leaveShiftBoxMyRotaStandalone:hover {
  border: 1px solid #3d344e;
  padding: 0px;
  padding-right: 0px;
  padding-left: 2px;
  padding-right: 2px;

}

.myRotaAllowSwapsBtn {
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  min-height: 35px;
  max-height: 35px;
  color: #859dbb;
  align-items: center;
  padding-left: 10px;
  padding-right: 8px;
  justify-content: space-between;
  font-size: 12px;
  padding-right: 11px;
  max-width: 142px;
  text-align: left;
  line-height: 11px;
}

.myRotaAllowSwapsBtn:hover {
  /* background-color: #bbcada; */
  border-color: #a6aeb6 !important;
}

.allowSwapsKnobMyRota {
  margin-top: 1px !important;
  margin-left: -3px;
  margin-right: 27px;
}

.allowSwapsKnobBg {
  background-color: #eaf3ff !important;
  border: 1px solid #ced9e5;
}

.allowSwapsKnobOn {
  background-color: #0af !important;
}

.allowSwapsMyRotaBtnActive {
  background-color: white !important;
  color: #859dbb !important;
  border-color: #e6eaee !important;
  /* margin-right:20px  */
}

.x198398121 {
  margin-right:10px
}

.allowSwapsMyRotaBtnActive:hover {
  background-color: white !important;
  border-color: #d7dfe9 !important;
}

.pendingShiftStartBlob {
  min-height: 7px;
  min-width: 7px;
  border-radius: 10px;
  max-height: 7px;
  max-width: 7px;
  margin-left: 4px;
  background: #dde9f9;
}

.hideUserSchedLimits {
  min-height: 1px;
  max-height: 1px;
}

.limityUserRotaLimByTxt {
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  margin-top: 5px;
  padding-left: 6px;
  padding-right: 6px;
  color:white
}

.paddingRight10px {
  padding-right: 10px !important;
}

.limitBtJtDownRightImg {
  max-width: 10px;
  margin-left: 6px;
  margin-bottom: -3px;
}

.headerTitleSize {
  /* font-size: 33px;
  top: 40px;
  font-weight: 500;
  color: #bbcada !important;
  color: #214978 !important; */

  font-size: 23px;
  top: 40px;
  font-weight: 600;
  color: #bbcada !important;
  color: #143052 !important;
}

.headerTitleColourMgr {
  color: #7f49bd !important;
}

.hoursSizer {
  margin-top: -24px;
  margin-bottom: -1px;
}

.seeUserNonWorkingDaysModal {
  background-color: #f4f8fe;
  min-width: 300px;
  min-height: 120px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  max-width: 300px;
  padding-bottom: 15px;
  padding-top: 9px;
  cursor: default;
}

.seeUserModalMob {
  min-width: 100vw !important;
  max-width: 100vw !important;
  position: fixed;
  bottom: 0;
  border-radius: 5px 5px 0 0;
  -webkit-animation-name: moveEditTeamUserBoxUp !important;
          animation-name: moveEditTeamUserBoxUp !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.seeUserNameTitle {
  font-weight: 500;
  color: #143051;
  /* margin-bottom: 18px; */
  font-size: 14px;
}

.seeUserRow {
  display: flex;
  justify-content: space-between;
  min-height: 33px;
  color: #143051;
  border-top: 1px solid #e6eaee;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 500;
  padding-left: 6px;
}

.seeUserRow:first-of-type {
  margin-top: 22px;
}
.seeUserSchedTable {
  min-width: 100%;
  max-width: 100%;
  max-height: 70vh;
  overflow: auto;
  padding-right: 5px;
  margin-bottom: -22px;
}
.nwDayClosedSpan {
  border-radius: 5px;
  background-color: #e3e8ee;
  color: #4f566b;
  font-weight: 500;
  font-size: 12px;
  padding-left: 3px;
  padding-right: 4px;
  min-height: 18px;
  line-height: 18px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-left: 8px;
}
.seeUserRowTitle {
  font-size: 16px;
  display: flex;
  align-items: center;
}
.seeUserRowValue {
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #bac5d3;
}

.userSchedPopUpFooter {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
  border-top: 1px solid #bac5d3;
  align-items: center;
  margin-bottom: -14px;
  min-height: 50px;
}

.messageUserFromRotaBtn {
  font-size: 14px;
  color: #fff;
  background-color: #07f;
  border: #0469de 1px solid;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 8px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  cursor: pointer;
}

.messageUserFromRotaBtn:hover {
  background-color: #0469de;
}

.closeSeeUserIDBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
}

.closeSeeUserIDBtn:hover {
  background-color: #214978;
}

.seeUserIDTeamsDiv {
  display: flex;
  min-width: 100%;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: -8px;
  margin-bottom: 18px;
}

.seeUserIDTeamsDiv:first-of-type {
  border-bottom: 1px solid #e6eaee;
  padding-bottom: 12px;
}

.seeUserIDTeam {
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  border-radius: 5px;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  padding-top:2px;
  padding-bottom:2px;
  display: flex;
  justify-content: space-between;
  /* max-height: 30px; */
  min-height: 30px;
  align-items: center;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #4f566b;
  margin-right: 8px;
  margin-top: 8px;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  text-align: left;

}

.teamShiftsHeaderHover:hover {
  color: #994fc3;
}

.userSchedTeamsTitle {
  text-align: left;
  color: #214978;
  font-size: 12px;
  font-weight: 500;

  margin-bottom: 12px;
}

.paidTimeOffMyShift {
  color: #0077ff;
}

.myRotaMyRow {
  background: #ffffff;
  border-top: 2px solid #bbcada;
  border-bottom: 2px solid #bbcada;
  padding-top:5px;
  display: flex;
  justify-content: flex-start;
}

.myRotaMyJtUser {
  /* padding-left: 4px;

  border-radius: 5px 0 0 5px; */
}

.myShiftAllowSwapsRow {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  padding-top: 7px;
}

.myShiftAllowSwapsRowHover {
  cursor: pointer;
}

.myShiftAllowSwapsRowHover:hover {
  background-color: #edf3f9;
}

.myShiftAllowSwapsTitle {
  font-size: 18px;
  font-weight: 500;
  color: #143051;
  margin-left: 4px;
}

.myShiftTitleAndAdd {
  border: none;
  margin-top: 10px;
}

.shiftSwapButton {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  padding-left: 6px;
  padding-right: 9px;
  min-height: 35px;
  max-height: 35px;
  color: #fff;
  background-color: #8300ff;
  border: 1px solid #6412b3;
  background-color: #9a74eb;
  border: 1px solid #965ade;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 9px !important;
}

.shiftSwapButton:hover {
  background-color: #6412b3;
  background-color: #8970c0;
}

.myShiftMessageBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 68px;
  padding-left: 7px;
  padding-right: 9px;
  min-height: 35px;
  max-height: 35px;
  color: #fff;
  background-color: #07f;
  border: #0469de 1px solid;
  border-radius: 5px;
  cursor: pointer;
}

.myShiftMessageBtn:hover {
  background-color: #0469de;
}

.myShiftHeaderParent {
  display: flex;
  justify-content: space-between;
  margin-top: -10px;
  padding-top: 10px;
}

.myShiftHeaderLeft {
  min-width: 231px;
}

.openShiftHeaderLeft {
  min-width: 100%;
  margin-top: -9px;
  padding-top: 5px;
}

@media only screen and (min-width: 280px) and (max-width: 349px) {
  .myShiftHeaderLeft {
    min-width: 70%;
  }
}
@media only screen and (min-width: 350px) and (max-width: 359px) {
  .myShiftHeaderLeft {
    min-width: 70%;
  }
}
@media only screen and (min-width: 360px) and (max-width: 379px) {
  .myShiftHeaderLeft {
    min-width: 72%;
  }
}
@media only screen and (min-width: 380px) and (max-width: 399px) {
  .myShiftHeaderLeft {
    min-width: 74%;
  }
}
@media only screen and (min-width: 400px) and (max-width: 419px) {
  .myShiftHeaderLeft {
    min-width: 74%;
  }
}
@media only screen and (min-width: 420px) and (max-width: 449px) {
  .myShiftHeaderLeft {
    min-width: 75%;
  }
}

@media only screen and (min-width: 440px) and (max-width: 459px) {
  .myShiftHeaderLeft {
    min-width: 77%;
  }
}

@media only screen and (min-width: 460px) and (max-width: 499px) {
  .myShiftHeaderLeft {
    min-width: 80%;
  }
}

.myShiftHeaderRight {
  min-width: 80px;
  max-width: 80px;
  margin-top: -5px;
  max-height: 80px !important;
  min-height: 80px !important;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.myShiftProfPicImg {
  min-width: 73px;
  max-width: 73px;
  max-height: 73px;
  min-height: 73px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 2px;

  cursor: pointer;
}

.myShiftProfPicImg:hover {
  border-color: #8ea7c5;
}

.myShiftBlankProfPicImg {
  max-width: 113px;
  min-width: 113px;
  max-height: 93px;
  min-height: 93px;
  margin-top: -19px;
  margin-left: -22px;
  cursor: pointer;
}

.myShiftProfPicBlankDiv {
  background-color: #516b8b;
  max-width: 73px;
  min-width: 73px;
  max-height: 73px;
  min-height: 73px;
  border-radius: 5px;
  border: 2px solid #413051;
}

.myShiftProfPicBlankDiv:hover {
  border-color: #214978;
}

.myShiftBlankInitialsHolder {
  margin-top: -79px;
  min-width: 71px;
  text-align: center;
  color: white;
  font-weight: 500;
  font-size: 40px;
  opacity: 0.8;
  cursor: pointer;
}

.swapModalMiddle {
  display: flex;
  justify-content: space-between;
  max-width: 90%;
  margin-left: 5%;
  min-height: 120px;
  margin-top: 10px;
  border-top: 1px solid #cbd7e6;
  padding-top: 20px;
}

.swapModalMiddleLeft {
  min-width: 145px;
  max-width: 145px;
  min-height: 188px;
  max-height: 188px;
}

.swapModalMiddleRight {
  min-width: 145px;
  max-width: 145px;
  margin-bottom: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.swapModalFooter {
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  border-top: 1px #e3e8ee solid;
  flex-direction: row-reverse;
}

.swapModalSwapMiddleMiddleArrow {
  max-width: 30px;
}

.swapModalMiddleLeftTitle {
  font-weight: 500;
  text-align: left;
  margin-left: 6px;
  color: #0af;
  font-size:14px;
}

.wantShiftDiv {
  background-color: white;
  border: 1px solid #e6eaee;
  border-radius: 5px;
  margin-top: 10px;
  min-height: 81px;
  text-align: left;
  padding-left: 10px;
  padding-top: 6px;
  padding-bottom: 3px;
  max-width: 145px;
  font-size: 16px;
}

.offeredShiftDiv {
  background-color: white;
  border: 2px solid #07f;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  min-height: 81px;
  text-align: right;
  padding-right: 10px;
  padding-top: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 145px;
  padding-bottom: 3px;
}

.wantShiftType {
  font-weight: 500;
  color: #214978;
  border-bottom: 1px solid #bbcada;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-right: 8px;
  padding-left: 3px;
  padding-bottom: 2px;
}

.wantShiftDs {
  font-weight: 500;
  color: #516b8b;
  padding-left: 4px;
  padding-top: 3px;
  border-bottom: 1px solid #bbcada;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  padding-right: 6px;
  padding-bottom: 3px;
  font-size: 14px;
}

.wantTimeStrip {
  font-weight: 500;
  color: #8ea7c5;
  padding-top: 2px;
  padding-left: 4px;
  padding-right: 6px;
  font-size: 13px;
  padding-bottom: 3px;

  border-bottom: 1px solid #bbcada;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.wantShiftDurationDiv {
  background-color: #ffffff;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
  border-radius: 0 0 5px 5px;
  margin-left: 10px;
  font-size: 13px;
  padding-bottom: 3px;
  padding-top: 3px;
  margin-bottom: 10px;
  color: #bbcada;
  border: 1px solid #e6eaee;
  border-top: none;
}

.offeredShiftDurationDiv {
  background-color: #ffffff;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
  border-radius: 0 0 5px 5px;
  margin-right: 10px;
  font-size: 13px;
  padding-bottom: 3px;
  padding-top: 3px;
  margin-bottom: 10px;
  color: #bbcada;
  border: 1px solid #e6eaee;
  border-top: none;
  margin-top: -5px;
  margin-bottom: 5px;
}

.selectShiftBox {
  border: 2px dashed #bac5d3;
  border-radius: 5px;
  padding: 10px;
  color: #496375;
  min-height: 80px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
  font-weight: 500;
  cursor: pointer;
  min-width: 140px;
}

.selectShiftBox:hover {
  color: #140351;
  border-color: #143051;
}

.swapModalMiddleLeftRight {
  font-weight: 500;
  text-align: right;
  margin-bottom: 6px;
  color: #0af;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 6px;
  font-size:14px;
}

.offerNoShiftCheckBoxDiv {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 90px;
  max-width: 90px;
  padding-left: 7px;
  padding-right: 7px;
  min-height: 35px;
  max-height: 35px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 50px;
  margin-top: 10px;
}

.offerNoShiftCheckBoxDiv:hover {
  background-color: #bbcada;
}

.offerNothingCheckBoxImg {
  max-width: 16px;
}

.requestSwapBtnSubmit {
  font-size: 14px;
  color: #fff;
  background-color: #0bf;
  border: 1px solid #0af;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
}

.requestSwapBtnSubmit:hover {
  background-color: #0af;
}

.disableReqSwapBtn {
  pointer-events: none;
  opacity: 0;
  cursor: default;
}

.offerNothingShiftBox {
  opacity: 0.4;
}

.selectShiftScrollBox {
  min-height: 316px;
  max-height: 316px;
  overflow-y: scroll;
  border: 1px solid #e6eaee;
  border-radius: 5px;
  margin-top: 5px;

  min-width: 143px;
  padding-bottom: 10px;
}

.selectMiddleArrow {
  margin-top: -210px;
}

.selectMiddleArrow2 {
  margin-top: -41px;
}

.selectShiftItem {
  background-color: white;
  border: 1px solid #e6eaee;
  border-radius: 4px 5px 5px 4px;
  text-align: left;
  background-color: white;
  cursor: pointer;
  max-width: 96%;
  min-width: 96%;
  margin-left: 2%;
  margin-top: 10px;
  padding: 1px;
  font-size: 14px;
  opacity: 0.8;
  border-left: 2px solid #143051;
}

.selectShiftItem:first-child {
  margin-top: 5px;
}

.selectShiftItem:hover {
  border: #214978 1px solid;
  border-left: 2px solid #143051;

  padding: 1px;
  opacity: 1;
}

.selectShiftItemDs {
  font-weight: 500;
  background-color: #214978;
  color: white;
  margin-left: 8px;
  margin-top: 6px;
  border: 1px solid #143051;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  border-radius: 5px;
  padding-bottom: 3px;
  padding-top: 3px;
  padding-right: 5px;
  padding-left: 5px;
  margin-bottom: 9px;
}

.selectShiftTimesRow {
  font-weight: 500;
  color: #516b8b;
  margin-left: 8px;
  margin-top: 5px;
  border-bottom: 1px solid #bbcada;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  max-width: fit-content;
  padding-right: 8px;
  padding-left: 3px;
  padding-bottom: 3px;
}

.selectShiftBrkRow {
  font-weight: 500;
  color: #8ea7c5;
  margin-left: 8px;
  margin-top: 5px;
  border-bottom: 1px solid #bbcada;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  max-width: fit-content;
  padding-right: 8px;
  padding-left: 3px;
  padding-bottom: 3px;
}

.selectShiftDurMinsRow {
  font-weight: 500;
  color: #bbcada;
  margin-left: 8px;
  margin-top: 5px;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  max-width: fit-content;
  padding-right: 8px;
  padding-left: 3px;
  padding-bottom: 6px;
}

.selectShiftTypeRow {
  min-width: 100%;
  font-weight: 500;
  text-align: left;
  padding-left: 10px;
  margin-top: 6px;
  margin-bottom: 5px;
  color: #bac5d3;
  border-bottom: 1px solid #bbcada;
}

.selectShiftTag {
  background-color: #f4f8fe;
  border: 1px solid #e6e6e6;
  color: #516b8b;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 5px;
  font-weight: 500;
  min-height: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 8px;
  margin-top: 4px;
  font-size: 10px;
}

.selectShiftTagsRow {
  margin-top: 4px;
  margin-bottom: 6px;
}

.fullOpac {
  opacity: 1 !important;
}

/* .hoverCommentActive:hover {
  opacity: 0.8 !important;
} */
.selectedShiftFlexEnd {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.offeredShiftDivCloseAndTypeDiv {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  padding-left: 11px;
}

.closeOfferedShiftImg {
  max-width: 14px;
  cursor: pointer;
  opacity: 0.2;
}

.closeOfferedShiftImg:hover {
  opacity: 1;
}

.swapBtnImg {
  max-width: 12px;
  margin-left: 3px;
  margin-right: 5px;
}

.myShiftMsgImg {
  max-width: 12px;
  margin-left: 2px;
  margin-right: 6px;
}

.addNotePlusSign {
  font-size: 20px;
  font-weight: 500;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: -4px;
}

.allowSwapsTickCrossImg {
  max-width: 12px;
  margin-left: 6px;
  margin-right: 2px;
}

.allowSwapsBtnTxtModal {
  font-weight: 500;
  padding-left: 6px;
  padding-right: 5px;
  font-size:11px
}

.limitExceededUserScheduleTeam {
  border-radius: 3px;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  margin: 3px auto;
  min-height: 20px;
  line-height: 20px;
  padding-left: 6px;
  padding-right: 6px;
  color: #cb5858;
  border: 1px solid #cb5858;
  background-color: #fff2f2;
  font-size: 12px;
  font-weight: 500;
}

.mySwapsMyRow {
  display: flex;
  justify-content: space-between;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.mySwapsMySelector {
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 93px;
  min-width: 93px;
  line-height: 26px;
  height: 35px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  background-position-x: 92px;
  display: flex;
  cursor: pointer;
  border-color: #2c7eb9;
  border: 1px solid #e6eaee;
  background-color: #0bf;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 72px;
}

.mySwapsMySelector:hover {
  border-color: #d7dfe9;
}

.mySwapsContainer {
  min-width: 100%;
  border-top: 1px solid #dde9f9;
  padding-top: 0px;
  min-height: 200px;
  color: #143051;
  overflow-y: scroll;
  max-height: 450px;
  margin-bottom: 1px;
  padding-bottom: 30px;

  border: 1px solid #bfccdc;
  border-radius: 5px 5px 0 0;
  background-color: #f4f8fe;
}

.mySwapsModal {
  background-color: #fff;
  min-width: 510px;
  min-height: 120px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-animation-name: zoomModalIn;
          animation-name: zoomModalIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  max-width: 300px;
  padding-bottom: 15px;
  padding-top: 15px;
  cursor: default;
}

.mySwapsHeader {
  font-weight: 500;
  color: #143051;
  margin-bottom: 19px;
  font-size: 18px;
  min-width: 97%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
}

.upcomingSwapsBtn {
  cursor: pointer;
  display: flex;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;

  color: #fff;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  align-items: center;
  padding-left: 8px;
  padding-right: 6px;
  font-size: 14px;
  font-weight: 500;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  margin-right: 10px;
}

.unselectedUpcomingSwapsBg {
  background-color: #dfe9f6;
}

.upcomingSwapsMyFade {
  opacity: 0.5;
}

.upcomingSwapsCheckBoxImg {
  max-width: 12px;
  margin-left: 7px;
  margin-right: 3px;
}

.mobteamSelectedDrop {
  background-color: #bd97e2 !important;
  background-position-x: 144px !important;
  border-color: #8e5fbc !important;
  color: white !important;
}

.x108314 {
  background-position-x: 144px !important;

}

.myHoursTitle {
  margin-top: -22px !important;
  margin-bottom: -11px;
}

.myHoursTitleMob {
  margin-top: -5px !important;
  min-height: 34px !important;
  /* margin-bottom: -2p  x; */
}

.myLeaveBtn2 {
}

.openShiftsTriSpan {
  margin-left: 6px;
  font-size: 8px;
  margin-top: 2px;
  max-width: 4px;
}

.openDiv1 {
  display: flex;
  align-items: center;
  margin-right: -39px;
  font-size: 12px;
  font-weight: 500;
  opacity: 0.5;
}



.openDiv1mobCollapse {
  display: flex;
  align-items: center;
  margin-right: 5px;
}



.myJtUserShiftBox {
  /* border: #143051 2px solid !important; */
  /* box-shadow: 0px 0px 0px rgb(0 0 0 / 0%) !important; */
  /* padding-right: 4px !important; */
}


.activeNoteBtnColours {
  background-color: #fff !important;
  border: solid 1px #bbcada !important;
  color: #143051 !important;
}

.x1313322:hover {
  background-color: #f4f8fe !important;
}

.staffHoursBtnDynamic {
  max-height: 35px !important;
  min-height: 35px !important;
  position: fixed !important;
  top: 43px;
  right: 0px !important;
}

.teamStatsReqsBtnStyleMob {
  max-height: 31px !important;
  min-height: 31px !important;
  position: fixed !important;
  top: 49px !important;
  right: 20px !important;
  background-color: #9a74eb !important;
  border: 1px solid #965ade !important;
  color: white !important;
  min-width: 120px !important;
  max-width: 120px !important;
}

.myHoursDataBoxTitle {
  font-weight: 500;
  margin-right: 10px;
  color: #fff;
  font-size: 18px;
  margin-top: 4px;
}

.teamSchedContainerMob {
  padding-left: 0px !important;
  max-height: calc(100vh - 258px);
    min-height: calc(100vh - 258px);
}
.teamCalContainerMob {
  padding-left: 0px !important;
  max-height: calc(100vh - 258px);
    min-height: calc(100vh - 258px);
    margin-top:-8px;
}

.mobJobTitleTitle {
  margin-left: 8px;
}
.limitRowInitialColMob {
  max-width: 90%;
  margin-left: 10%;
}

.openDiv1Mob {
  font-size: 10px;
  margin-left: -31px;
}

.opens33 {
  font-size: 9px;
  max-width: 48px;
  text-align: left;
}

.opens44 {
  font-size: 8px;
  margin-top: 4px;
  margin-left: 0px;
}
.myRotaTag {
  background-color: #dfe7f0;
  border-color: #bbcada;
  color: #516b8b;
}
.shTagInlineMe {
  /* border: 2px solid #143051; */
}

.ghostShiftUnitTeamSched:hover {
  background-color: #214978 !important;
}

.paddingR8px {
  padding-right: 8px !important;
}

.marginRight12px {
  margin-right: 12px;
}

.lightBlue {
  color: #00ccff;
}

.rotaSwapsQty {
  background-color: #516b8b;
  color: white;
  font-weight: 500;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 3px;
  margin-left: 4px;
  font-size: 12px;
  border: 1px solid #405671;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-height: 18px;
  display: flex;
  align-items: center;
  min-height:20px;
}

.rotaSwapsQty:first-of-type {
  margin-left: 6px;
}

.swapUnit {
  background-color: #fff;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 20%);
  margin-top: 14px;
  max-width: 96%;
  margin-left: 2%;
  border-radius: 5px;
  min-height: 70px;
  padding: 1px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-left: 5px solid #dde9f9;
}

.swapUnit:first-of-type {
  margin-top: 15px;
}

.teamSwapUnit {
  border-left: 5px solid #a387df;
  padding-bottom: 35px;
}

.swapUnitLeft {
  min-width: 39%;
  padding-left: 3%;
  padding-right: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 3%;
  justify-content: flex-start;
}

.swapUnitLeftTxt {
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  margin-top: 5px;
  margin-left: 3px;
  padding-right: 5px;
  padding-bottom: 5px;
}

.swapUnitRightTxt {
  font-weight: 500;
  font-size: 16px;
  text-align: right;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  margin-top: 5px;
  margin-left: 3px;
  padding-right: 5px;
  margin-top: 35px;
  padding-bottom: 5px;
}

.swap500 {
  font-weight: 500;
}

.swapUnitMiddle {
  min-width: 22%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  margin-top: 120px;
  border-left: 2px solid #f4f8fe;
  border-right: 2px solid #f4f8fe;
}

.teamSwapUnitMiddle {
  min-width: 22%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  margin-top: 98px;
  border-left: 2px solid #f4f8fe;
  border-right: 2px solid #f4f8fe;
}

.inReturnForArrowImg {
  max-width: 18px;
  opacity: 0.3;
}

.swapUnitRight {
  min-width: 39%;
  padding-left: 4px;
  padding-right: 3%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height: 100%;
  justify-content: flex-start;
  padding-top: 3%;
}
.mySwapsModalFooter {
  min-width: 100%;
  display: flex;
  justify-content: space-between;

  align-items: center;
  margin-bottom: -14px;
  min-height: 50px;
}

.myShiftWhenDsStrip {
  margin-top: 3px;
}

.swappedTitleItem {
  border-radius: 3px;
  color: #143051;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 7px;
  padding-right: 10px;
  font-weight: 500;
  max-height: 35px;
  min-height: 35px;
  font-size: 16px;
  margin-top: 12px;
  border-bottom: 1px solid #e6eaee;
  display: flex;
  justify-content: space-between;
}

.navySwapImg {
  max-width: 13px;
  margin-right: 7px;
}

.swappedWith {
  font-size: 14px;
  line-height: 23px;
  margin-top: 14px;
  margin-left: 5px;
  color: #0af;
  font-weight: 500;
  margin-bottom: 16px;
}

.myShiftSwappedItem {
  background-color: white;
  max-width: calc(100% - 7px);
  margin-left: 3px;
  border-radius: 3px;
  margin-bottom: 14px;

  margin-top: 18px;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 20%);
  display: flex;
  justify-content: space-between;
}

.myShiftSwappedItemLeft {
  min-width: 70%;
  display: flex;
  border-right: 1px solid #e0ebf8;
}

.myShiftSwappedItemRight {
  min-width: 30%;
  display: flex;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 4px;
  padding-right: 4px;
}

.myShiftSwappedItemHalf {
  min-width: 100%;
}

.myShiftSwappedItemHalf2 {
  min-width: 40%;
}

.myShiftSwappedItemLeftDs {
  color: #143051;
  border-bottom: 1px solid #e0ebf8;
  min-height: 30px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-weight: 500;
}

.noShiftOfferedTxt {
  color: #143051;

  min-height: 30px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  min-height: 40px;
}

.myShiftSwappedItemLeftTimes {
  color: #143051;
  border-bottom: 1px solid #e0ebf8;
  min-height: 30px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.myShiftSwappedItemLeftBrk {
  color: #859dbb;

  min-height: 30px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  min-width: 100%;
  justify-content: space-between;
  padding-right: 12px;
  font-weight: 500;
}

.swappedWithString {
  padding-right: 30px;
  font-weight: 500;
  line-height: 18px;
  font-size:12px;
}
.myShiftSwapEditBtnImg {
  cursor: pointer;
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  border-radius: 5px;
  padding: 8px;
  border: #e3e8ee solid 2px;
}

.myShiftSwapEditBtnImg:hover {
  border-color: #b2bbc7;
}

.swapInline {
  max-width: 12px;
  margin-top: -13px;
  margin-bottom: 4px;
  opacity: 0.4;
}

.unpubFade {
  color: #c0d6f0;
}

.inbOutbSwapReqImg {
  max-width: 14px;
  margin-bottom: 4px;
}

.swapUnitWantDs {
  font-weight: 500;
  color: #516b8b;
  font-size: 14px;
  margin-top: 6px;
  border-bottom: 1px solid #dde9f9;
  text-align: left;
  padding-bottom: 8px;
  padding-right: 2px;
}

.swapUnitWantDsSub {
  font-weight: 500;
  color: #8ea7c5;
  font-size: 14px;
  margin-top: 6px;
  border-bottom: 1px solid #dde9f9;
  text-align: left;
  padding-bottom: 8px;
  padding-right: 7px;
}

.swapUnitWantItemDiv {
  max-width: 138px;
  border-radius: 5px;

  border: 2px solid #e3e8ee;
  padding-left: 9px;
  padding-right: 9px;
  margin-top: 10px;
  margin-bottom: 12px;
  cursor: pointer;
  background-color: white;
}

.rightMobSwapUnit {
  margin-top: 12px;
}

.swapUnitWantItemDiv:hover {
  border: 2px solid #d7dfe9;
  /* box-shadow: 0px 0px 3px #143051; */
}

.inboundWantItem {
  border: 2px solid #143051;
}

.inboundWantItem:hover {
  border: 2px solid #143051;
}

.inboundSwapUnit {
  background-color: #fff;
  border-left: 5px solid #0af;
}
.wantShiftTags {
  background-color: #fbfeff;
  padding: 4px;
  border-radius: 0px 0px 5px 5px;
  margin-top: -12px;
  max-width: 84%;
  margin-left: 3%;
  margin-bottom: 8px;
  border: 1px solid #dde9f9;
  border-top: none;
}

.wantShiftTagsList {
  display: flex;
  flex-wrap: wrap;
  padding: 4px;
  margin-top: -4px;
  margin-left: 3%;
}

.wantShiftTagsTitle {
  text-align: left;
  font-weight: 500;
  font-size: 10px;
  margin-left: 2px;
  margin-top: 0px;
  color: #516b8b;
  margin-bottom: 8px;
}

.wantTag {
  font-size: 12px;
  margin-right: 8px;
  color: #859dbb;
  text-align: left;
  line-height: 14px;
  margin-bottom: 8px;
  font-weight: 300;
}

.flipH {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.inReturnForTxt {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  margin-top: 5px;
  color: #143051;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.font12 {
  font-size: 12px !important;
}

.offerSwapNothingTxt {
  font-weight: 500;
  font-size: 14px;
  text-align: right;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  margin-top: 5px;
  margin-left: 3px;
  padding-right: 5px;
  margin-top: 35px;
  color: #bac5d3;
  border: 2px dashed #bac5d3;
  border-radius: 5px;
  padding: 8px;
}

.disableAddNoteBtn {
  background-color: white;
  color: #214978;
  border-color: white;
  pointer-events: none;
  color: #dde9f9;
}

.disableAddNoteBtn:hover {
  background-color: white;
  border-color: white;
}

.swapFName {
  color: #07f;
}

.selectedSwapUnit {
  border: solid 2px #143051 !important;
  padding: 0px !important;
}

.txtAlignRight {
  text-align: right !important;
}

.offerTagsRightAlign {
  min-width: 100% !important;
  text-align: right !important;
}

.swapUnitCtaBar {
  background-color: #f8fbff;
  min-height: 50px;
  max-width: 90%;
  margin-left: 5%;
  border-radius: 0px 0px 5px 5px;
  border: 1px solid #e0ebf8;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
}

.swapUnitParent {
  padding-bottom: 10px;
  margin-top: 30px;
}

.swapUnitParent:first-child {
  margin-top: 0px;
}

.swapUnitParent:last-child {
  border: none;
}

.swapDsMade {
  font-size: 12px;
  color: #bbcada;
  font-weight: 500;
  text-align: left;
}

.swapCtaBtns {
  display: flex;
  justify-content: space-between;
  min-width: 120px;
}

.swapAccept {
  background-color: #00aaff;
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 35px;
  border-radius: 5px;
  border: 1px solid #1e88e5;
  cursor: pointer;
  font-size: 14px;
}

.swapAccept:hover {
  background-color: #1e88e5;
}

.teamSwapAccept {
  background-color: #a387df;
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 35px;
  border-radius: 5px;
  border: 1px solid #967ccf;
  cursor: pointer;
  font-size: 14px;
}

.teamSwapAccept:hover {
  background-color: #967ccf;
}

.swapDecline {
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 35px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
  font-size: 14px;
  border: #dde9f9 1px solid;
}

.swapDecline:hover {
  border-color: #d7dfe9;
}

.staffSwapTeamDropContainer {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.staffSwapTeamSubContainer {
  min-width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;
}

.swapTeamDropdown {
  min-width: 240px;
  min-height: 35px;
  max-width: 240px;

  cursor: pointer;
  border: 2px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 213px;
  font-weight: 500;
  font-size: 14px;
  padding-left: 10px;
  margin-top: 14px;
}

.swapTeamDropdown:hover {
  border-color: #d7dfe9;
}

.noSwapsTxt {
  font-weight: 500;
  margin: 70px auto;
  color: #bbcada;
  font-size: 12px;
  /* padding-right: 50px; */
  /* padding-left: 50px; */
  line-height: 16px;
  max-width: 200px;
  margin-bottom: -30px;
}

.swapTypeSpan {
  color: #c686cc;
}

.teamSwapOfferNothingCross {
  min-width: 20px;
  max-width: 20px;
  margin-top: 25px;
  margin-right: 18px;
  opacity: 0;
}

.requireMgrApp {
  max-width: 180px;
}

.allowSwapsTeamKnob {
  padding-right: 12px;
}

.mobRotaTopLine {
  border-bottom: 1px solid #e7eff7;
  padding-right: 5% !important;
  border-radius: 20px 20px 0 0;
  border-top:2px solid #e6eaee !important;
  min-width: calc(100vw + 4px) !important;
  margin-left: -2px !important;
}

.shiftInlineCommentImg {
  max-width: 10px;
  margin-bottom: -17px;
  margin-top: 7px;
  margin-left: 2px;
  /* opacity: 0.4; */
}

.shiftInlineCommentImgSelfLogAbs {
  margin-top:3px;
}
.inboundSwapCta {
}

.inboundDeclineSwap {
}

.staffSwapInfo {
  font-size: 14px;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 15px;
  font-weight: 500;
  margin-bottom: -16px;
  color: #bbcada;
  line-height: 20px;
}

.closeMySwapsModalImg {
  cursor: pointer;
}
.closeMySwapsModalImg:hover {
  opacity: 0.8;
}
.teamSwapsContainer {
  min-width: 100%;
  margin-top: -12px;
  margin-bottom: 11px;
  font-weight: 500;
}



.selectTeamToChangeSwapSettings {
  color: #bbcada;
  text-align: right;
  font-size: 12px;
  margin-top: 7px;
  margin-right: 1px;
  font-weight: 500;
  padding-right: 10px;
  border-right: 2px solid #e0ebf8;
  min-height: 40px;
  max-width: 160px;
  float: right;
  padding-top: 4px;
}

.usrApprovedSwap {
  max-width: 40%;
  float: right;
  margin-right: 4%;
  text-align: right;
  font-size: 12px;
  margin-top: -78px;
  color: #0af;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.mgrSwapApprovalNotRequired {
  max-width: 40%;
  float: right;
  margin-right: 4%;
  text-align: right;
  font-size: 12px;
  margin-top: -128px;
  color: #bbcada;
  font-weight: 500;
  display: flex;
  align-items: center;
  border-right: 2px solid #bbcada;
  padding-right: 8px;
}

.mgrSwapApprovalNotRequiredNonMob {
  margin-top: -111px;
}
.swapAcceptTickImg {
  max-width: 14px;
  margin-left: 6px;
}

.notYetAccept {
  color: #bbcada;
  margin-top: -74px !important;
  border-right: 2px solid #bbcada;
  padding-right: 8px;
}

.mobNotYetAccept {
  margin-top: -86px !important;
}

.dayCommentIcon {
  max-width: 24px;
  margin-right: 10px;
  /* opacity: 0.2; */
  cursor: pointer;
  padding: 4px;
  min-height: 24px;
  /* border: 1px solid #bac5d3; */
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 7px;
  margin-top: 0px;
  max-height: 24px !important;
  margin-left: 10px;
  padding-bottom: 2px;
  padding-top: 4px;
}

.dayCommentIcon:hover {
  opacity: 0.8;
}

.dayCommentsModal {
  background-color: #ebf0f7;
  min-width: 370px;
  min-height: 120px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  max-width: 300px;
  padding-bottom: 15px;
  padding-top: 15px;
  cursor: default;
}

.dayCommentsModalBody {
  background-color: white;
  min-width: 100%;
  border: solid 1px #e6eaee;
  border-top: solid 2px #e6eaee;
  border-bottom: none;
  border-radius: 6px 6px 0px 0px;
  overflow: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  background-color: white;
  max-height:462px;
}

.dayCommentsHolder {
  /* border-left: 2px solid #143051; */
  margin-left: 15px;
  margin-top: 20px;
  padding-bottom: 20px;
  overflow-y: scroll;
  max-height: 360px;
  padding-bottom: 20px;
  /* margin-bottom: 10px; */
}

.dayCommentObj {
  font-weight: 500;
  line-height: 22px;
  padding-right: 12px;
  font-size: 13px;
  color: #516b8b;
  text-align: left;
  margin-top: 17px;
}

.commentObjNameAndDs {
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom:-5px;
}

.dayCommentsModalFooter {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #bac5d3;
  align-items: center;
  margin-bottom: -14px;
  min-height: 50px;
}

.rotaCommentDiv {
  display: flex;
  align-items: center;
  cursor: pointer;
}



.rotaCommentsQty {
  font-size: 9px;
  /* color: #516b8b; */
  margin-top: 0px;
  margin-left: -9px;
  font-weight: 600;
  margin-right: 6px;
  /* background: blue; */
  border-radius: 3px;
  color: white;
  background: #9a74eb;
  padding: 1px;
  padding-left: 4px;
  padding-right: 4px;
  border: #756dba 1px solid;

}

.dayCommentsTitleTxt {
  font-size: 12px;
  color: #214978;
  font-weight: 500;
  margin-left: 25px;
  padding-top: 18px;
}

.myShiftNoteCommentIcon {
  max-width: 12px;
  margin-right: 5px;
}

.swapMgrApprovedTxt {
  font-weight: 500;
  color: #00aaff;
  font-size: 12px;
  text-align: right;
  display: flex;
}

.declineSwapOnceApproved {
  font-weight: 500;
  color: #bbcada;
  font-size: 12px;
  margin-top: 3px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 5px;
  margin-right: 0px;
  border: 1px solid #e6eaee;
  cursor: pointer;
}

.declineSwapOnceApproved:hover {
  border-color: #bbcada;
}

.swapApprovedAndDeclineDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-right: 2px #bbcada solid;
  padding-right: 8px;
}

.swapApprovedGreenLeftBorder {
  border-left: 5px solid #a8cc9a;
}

.mgrApprovedSwapInfo {
  color: #a8cc9a;
  font-weight: 500;
  text-align: right;
  font-size: 13px;
  margin-bottom: 12px;
  border-right: 2px solid #a8cc9a;
  padding-right: 8px;
}

.awaitingNameDecicionSwap {
  color: #0af;
  font-weight: 500;
  text-align: right;
  font-size: 13px;
  margin-bottom: 14px;
  border-right: 2px solid #0af;
  padding-right: 8px;
}

.swapNoLongerAllowedModalBody {
  min-height: 100px;
  max-width: 300px;
  min-width: 300px;
  padding: 20px;
  border-radius: 5px;
  margin: 0 auto;
  justify-content: space-between;
  background-color: white;
  color: #143051;
  font-size: 16px;
}

.closeSwapsNoLongerBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  margin-top: 20px;
  min-width: 100%;
}

.closeSwapsNoLongerBtn:hover {
  background-color: #214978;
}

.swapUnitLeftUpperMinimum {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 90px;
}

.swapUnitRightUpperMinimum {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-height: 90px;
}

.swapUnitTeamLeftUpperMinimum {
  min-height: 68px;
}

.swapUnitTeamRightUpperMinimum {
  min-height: 68px;
}

.outboundAwaitingTxtColour {
  color: #bbcada;
}

.offerNothingMinHeight {
  min-height: 49px;
}

.swapStatusDivider {
  background-color: #e3e8ee;
  min-height: 2px;
  min-width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.declineBtnRight {
  margin-left: 42px;
}

.swapBorderRight {
  border-right: 2px solid #143051;
}

.changesMoveLeft {
  margin-right: 8px !important;
}

.changeRequiresAckBorder {
  border-left: 5px solid #0af !important;
}

.changeApprovedBorder {
  border-left: 5px solid #a8cc9a;
}

.changeDeclinedBorder {
  border-left: 5px solid #ba0000;
}

.acknowledgedTxt {
  color: #bbcada;
  font-weight: 500;
  font-size: 12px;
  margin-top: 0px;
  min-height: 24px;
  display: flex;
  align-items: center;
}

.ackTickImg {
  max-width: 12px;
  margin-left: 7px;
  margin-top: 1px;
}

.changeStatusIconImg {
  max-width: 10px;
  margin-left: 6px;
  margin-top: 0px;
}

.totEarnedSalariedInfo {
  font-weight: 500;
  font-size: 10px;
  text-align: right;
  padding-right: 20px;
  padding-left: 40px;
  margin-top: 8px;
  color: #bbcada;
}

.emptyOutboundQty {
  min-width: 4px;
}

.paddingBottom26px {
  padding-bottom: 33px !important;
}

.paddingBottom82px {
  padding-bottom: 52px !important;
}

.myShiftNotesContainerOtherUser {
  padding-bottom: 13px;
}

.myShiftOtherUserNotesTitle {
  margin-left: 15px;
}

.marginTop0 {
  margin-top: 0px !important;
}

.shiftChangesParent {
  border-right: 1px solid #e0ebf8;
}

.shiftChangesParentMob {
}

.textAlignLeft {
  text-align: left !important;
}

.teamAvailWithShowUser {
  z-index: 9900;
}

.lateMinsSpan {
  font-size: 14px;
  margin-left: 6px;
  color: #bac5d3;
}

.lateInput {
  background-color: #fff;
  width: 77px;
  max-width: 77px;

  line-height: 30px;
  padding-top: 0px;
  height: 35px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 16px;
  font-weight: 500;
  /* animation-name: zoomModalIn !important; */
  /* animation-duration: 0.1s !important; */
  -webkit-appearance: none;
  appearance: none;
  cursor: text;
  margin-left: 3px;
}

.lateInput:hover {
  border-color: #d7dfe9;
}

.lateTitleWhenNotEnabled {
  font-size: 18px;
  font-weight: 500;
  color: #143051;
  margin-left: 5px;
  margin-top: 5px;
  padding-bottom: 3px;
}

.addLateIconPlus {
  margin-top: 8px !important;
}

.unpaidlateBtnDiv {
  cursor: pointer;
  display: flex;
  border: 1px solid #8e76c1;
  background-color: #a387df;
  color: #fff;
  border-radius: 5px;
  min-height: 35px;
  line-height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 6px;
  margin-bottom: 9px;
}

.saveLateAndUnpaidDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 19px;
  justify-content: center;
}

.saveBtnTxtLate {
  background-color: #07f;
  max-height: 35px;
  min-height: 35px;
  line-height: 26px;
  color: white;
  font-weight: 500;
  border: 1px solid #0469de;
  border-radius: 5px;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-width: 60px;
  max-width: 60px;
  margin-top: 6px;
}
.saveBtnTxtLate:hover {
  background-color: #0469de;
}

.unpaidLateCheckBoxImg {
  margin-right: 7px;
  margin-left: 0px;
  max-width: 12px;
}

.unpaidLateOpacity {
  opacity: 0.6;
}

.unpaidLateOpacity:hover {
  opacity: 0.8;
}

.unpaidSaveCancelDiv {
  display: flex;
  align-items: center;
}

.canxLateBtnImg {
  margin-left: 8px;
  cursor: pointer;
  min-width: 35px;
  max-width: 35px;
  min-height: 35px;
  max-height: 35px;
  border-radius: 5px;
  padding: 6px;
  padding-bottom: 8px;
  border: #e3e8ee solid 2px;
  /* border: #e3e8ee solid 2px; */
  margin-top: 6px;
}

.removeLateImg {
  padding: 9px;
}

.paidLateSpan {
  color: #bac5d3;
  font-size: 12px;
}

.openShiftFooterNotesAndClaimDiv {
  display: flex;
  align-items: center;
}

.hasBiddedTxt {
  color: #bbcada;
  font-weight: 500;
  font-size: 12px;
  margin-left: 14px;
}

.availDayDiv {
  text-align: left;

}

.availDayStrip {
  font-size: 12px;
  margin-top: 0px;
  color: #214978;
  font-weight: 500;
}

.publishOutstanding {
  background-color: #7fbb67;
  border-color: #53a747;
  min-width: 105px !important;
  padding-right: 3px;
}

.publishOutstanding:hover {
  background-color: #53a747;
}

.mobDurWithCost {
  margin-right: 5px;
  line-height: 9px;
}

.msgsShownUserSchedUnderlay {
  z-index: 1000;
}

.rotaInsightsBtn {
  background-color: #214978;
  border: 1px solid #143051;
  color: white;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 7px;
  padding-right: 7px;
  font-size: 12px;
  min-height: 28px;
  margin-top: -4px;
  cursor: pointer;
  margin-left: 5px;
  font-weight: 600;
}

.rotaInsightsBtn:hover {
  background-color: #143051;
}

.rotaInsightsBtnMob {
  border-radius: 3px !important;
  margin-left: 5px;
  margin-top: 3px;}


.insightsIconImg {
  margin-right:6px;
  max-width: 11px;
}
/* 


@media only screen and (min-width: 820px) {

  .rotaInsightsBtn {
margin-left:0px !important
  }
  

}

.showVisBtnActive {
  background-color: #504588;
}

.showVisBtnActive:hover {
  background-color: #696682;
}

.insightsIconImg {
  max-width: 10px;
  margin-right: 7px;
  /* margin-left: 1px; */
/* } */

.mobShowVizIcon {
}

.vizBox {
  background-color: #f8f7fa;
  min-height: 178px;
  max-height: 178px;

  min-width: 100%;
  border-top:1px solid #e3e8ee;
  /* border-bottom: 3px solid #bbcada; */
  -webkit-animation-name: slideVizDown !important;
          animation-name: slideVizDown !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

@-webkit-keyframes slideVizDown {
  from {
 min-height: 0px;
 max-height: 0px 

  }
  to {
    min-height: 178px;
    max-height: 178px;

  }
}

@keyframes slideVizDown {
  from {
 min-height: 0px;
 max-height: 0px 

  }
  to {
    min-height: 178px;
    max-height: 178px;

  }
}

.vizContent {
  min-height: 100%;
  max-width: 180px;
  margin: 14px auto;
  /* display: flex; */
}

.vizDataBox {
  min-width: 100%;
  /* background-color: #9c82e3; */
  min-height: 130px;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;

  border-radius: 0 0 3px 3px;
}

.vizKeyStartEndTime {
  margin-top: 2px;
}

.vizKeyDiv {
  color: #8ea7c5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 4px;
  min-width: 100%;
  font-size: 10px;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  margin-top:-6px
}

.vizKeyDivBullets {
  color: #9f74c4;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2px;
  min-width: 100%;
  font-size: 10px;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  /* margin-top: -20px; */
}

.vizKeyBullet {
  min-width: 3px;
  border-radius: 5px;
  min-height: 6px;
  background-color: #bbcada;
}
.blankVizBox {
  background: #5c638b;
  -webkit-animation-name: slideVizDown !important;
          animation-name: slideVizDown !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  border-radius: 0 0 0 10px;
  padding-top: 5px;
  /* padding-bottom: 5; */
  border-top:none !important;
}

.blankVizBoxMob {
  padding-top: 2px;
}

.vizCol {
  flex: auto;

  max-height: 130px;
  margin-top: 6px;
  display: flex;
  flex-direction: column-reverse;

  margin-left: 2px;
}

.vizItem {
  background-color: #2e386b;
  margin-bottom: 2px;
  border-radius: 2px;
  flex: auto;
  opacity: 0.8;
}

.noVisItem {
  opacity: 0.1;
}

.showVizWhiteCross {
  max-width: 8px !important;
  margin-left: 2px;
  margin-top: 0px;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  margin-right: 7px;
}

.blankVizRow {
  display: flex;
  color: white;
  font-size: 12px;
  min-height: 17px;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 8px;
  align-items: center;
  font-weight: 500;
}

.blankVizRowMob {
  flex-direction: column;
  align-items: flex-start;
  /* border-bottom: 1px solid #9c82e3; */
}

.blankVizRowMob:last-child {
  border-bottom: none;
}

.blankVizRowTitle {
  font-weight: 500;
  opacity: 1;
  margin-bottom: -8px;
}

.blankVizRowTitleMob {
  font-size: 9px;
  margin-bottom: -1px;
}

.blankVizRowValueMob {
  font-size: 10px;
  margin-bottom: 1px;
}

.resetPasswordPage {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ebf0f7;
  min-height: 100vh;
  max-height: 100vh;

  min-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* overflow-y: auto; */
}

.resetPassLogo {
  max-width: 80px;
  margin-left: 6px;
  margin-bottom: 10px;
}

.resetPassTitle {
  font-weight: 500;
  color: #214978;
  margin-top: 30px;

  max-width: 300px;
  text-align: center;
  margin-bottom: 10px;
  line-height:24px
}

.contactFormTitleTxt {
  font-weight: 500;
  color: #214978;
  max-width: 300px;
  text-align: center;
  margin: 20px auto;
  font-size: 14px;
  line-height: 23px;
}
.resetPassInput {
  border: #fff 1px solid;
  max-width: 280px;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 20%);
  background-color: white;
  border-radius: 5px;
  margin-top: 20px;
  text-align: left;
  min-height: 35px;
  padding-left: 10px;
  font-size: 18px;
  color: #143051;
}

.resetPassInputEmail {
  border: #fff 1px solid;
  max-width: 280px;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 20%);
  background-color: white;
  border-radius: 5px;
  margin-top: 20px;
  text-align: left;
  min-height: 35px;
  padding-left: 10px;
  font-size: 16px;
  min-width: 220px;
  color: #143051;
  font-weight: 500;
}

.contactFormDiv {
  min-height: 100vh;
  /* margin-top: 40px; */
  padding-bottom: 60px;
  padding-top: 20px;
  overflow-y: auto;
  min-width: 100vw;
}

.mobContactFormPaddBottom {
  padding-bottom: 80px !important;
}

.contactFormTitle {
  color: #143051;
  font-weight: 500;
  margin-top: 20px;
}

.emailContactInline {
  color: #fff !important;
  background-color: #214978;
  padding: 2px 6px;
  border-radius: 5px;
  border: 1px solid #143051;
  margin-left: 5px;
}

.contactDone {
  font-weight: 500;
  color: #143051;
}

.emailContactInline:hover {
  background-color: #143051;
}
.contactFormDivider {
  min-height: 2px;
  background-color: #bbcada;
  min-width: 300px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  max-width: 300px;
  margin: 30px auto;
}

.msgtxtarea {
  padding: 10px;
  min-height: 150px !important;
}

.contactFormSub {
  color: #8ea7c5;
  font-weight: 500;
  margin-top: 3px;
  font-size: 10px;
}
.contactFormInput {
  border: #fff 1px solid;
  max-width: 300px;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 20%);
  background-color: white;
  border-radius: 5px;
  margin-top: 10px;
  text-align: left;
  min-height: 35px;
  padding-left: 10px;
  font-size: 16px;
  min-width: 300px;
  color: #143051;
  font-weight: 500;
}
.requestNewPassBtn {
  background-color: #214978;
  min-height: 35px;
  color: white;
  border-radius: 5px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #143051;
  font-weight: 500;
  font-size: 14px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 20px auto;
}

.requestNewPassBtn:hover {
  background-color: #143051;
  border: 1px solid #143051;
}

.sendContactMsgBtn {
  background-color: #214978;
  min-height: 35px;
  color: white;
  border-radius: 5px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
  border: 1px solid #143051;
  font-weight: 500;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 25px auto;
}

.invalidContactInput {
  border: 2px solid #d9aeb3;
}

.sendContactMsgBtn:hover {
  background-color: #143051;
  border: 1px solid #143051;
}

.emailDoesNotExistTxt {
  color: #ba0000;
  font-size: 11px;
  font-weight: 400;
  margin-top: 7px;
}

.colourNavy {
  color: #143051 !important;
}

.noPaddRight {
  padding-right: 0px !important;
}

.userOpenAcceptFirstTxt {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  margin-top: 9px;
  color: #bac5d3;
  line-height: 16px;
}

.claimedLight {
  background-color: #fff !important;
  border: 2px solid #143051;
}

.noClaimsTxt {
  font-weight: 500;
}

.mondayTodayBorder {
  border-left: none !important;
}

.biddersShiftLight {
  background-color: #7fbb67 !important;
}

.purBor {
  /* border-bottom: 3px solid #ffa8ff; */
}

.purBor:hover {
  /* border-bottom: 3px solid #ffa8ff !important; */
}

.teamSwapReqsQtyItem {
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 6px;
  font-size: 12px;
  padding-top: 1px;
  padding-bottom: 1px;
  color: white;
  border: 1px solid #896ec3;
  background-color: #a387df;
}

.teamSwapsFlex {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.toolsTeamSwapsNumber {
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 6px;
  font-size: 12px;
  padding-top: 1px;
  padding-bottom: 1px;
  color: #ffffff;
  background-color: #7a45ef;
}

.toolsTeamSwapsNumberMob {
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
  font-size: 12px;
  /* padding-top: 1px; */
  /* padding-bottom: 1px; */
  color: white;
  border: 1px solid #5b3a6c;
  background-color: #643cab;
  max-height: 20px;
  display: flex;
  align-items: center;
  margin-top: -2px;
}

.showToolsTeamSwaps {
  margin-left: 9px;
  margin-right: 0px;
  font-weight: 500;
}

.viewClockTimelineBtnSpan {
  color: white;
  border: solid 1px #0684c3;
  background-color: #0af;
  padding: 4px 6px;
  margin-left: 6px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.viewClockTimelineBtnSpan:hover {
  background-color: #0b97de;
}

.viewClockTimelinePopUpImg {
  margin-top: 3px;
  max-width: 12px;
}

.clockTimelineUnderlay {
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.clockTimelineModalBox {
  min-width: 340px;
  min-height: 500px;
  background-color: #f4f8fe;
  border-radius: 10px;
  z-index: 99999999;
  cursor: default;
  color: #143051;
}

.clockTimelineModalBoxMob {
  min-width: 100vw;
  border-radius: 15px 15px 0 0;
  position: fixed;
  bottom: 0;
  padding-left: 5%;
  padding-right: 5%;
  /* padding-bottom:50px; */
}

.clockTimelineModalHeader {
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  /* padding-left: 8px; */
  padding-top: 5px;
  margin-bottom: 8px;
  /* padding-right: 20px; */
}

.clockTimelineHeaderSideUnit {
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeClockTimelineImg {
  min-width: 35px;
  padding: 10px;
  cursor: pointer;
  margin-left: -10px;
}

.closeClockTimelineImg:hover {
  opacity: 0.8;
}

.clockTimelineHeaderTitle {
  font-weight: 500;
  font-size: 16px;
  /* margin-top:10px; */
}

.clockTimelineModalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 0px;
}
.closeClockTimelimeModalBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  max-width: 62px;
  min-width: 62px;
}

.closeClockTimelimeModalBtn:hover {
  background-color: #214978;
}

.clockedItemDataTimeRow {
  width: 94%;
  margin-left: 3%;
  border-radius: 4px;
  min-height: 42px;
  margin-top: 73px;
  /* border: 1px solid #0d2644; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #cbd7e6;
  background-color: #f4f8fe;
  border-left: 7px solid #0077ff;
}

.clockedItemDataTimeRow:first-of-type {
  margin-top: 20px;
}

.clockItemTimelineDivider {
  min-width: 90%;
  min-height: 4px;
  background-color: #143051;
  margin-top: 71px;
  margin-bottom: -32px;
  margin-left: 5%;
  max-width: 90%;
  border-radius: 4px;
}

.clockItemDividerTxtBrk {
  background-color: #5822bb;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  font-size: 12px;
  font-weight: 500;
  border-radius: 3px;
  padding: 2px 7px;
  border: 1px solid #021226;
  margin: 60px 10px;
  margin-bottom: -40px;
  /* min-width: 140px; */
  max-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brkLeftBordPurp {
  border-left: 9px solid #5822bb !important;
  /* max-width: 81%; */
  /* margin-left: 16%; */
}

.brkLeftBordGreen {
  border-left: 7px solid #88df65 !important;
}

.purpGpsStatusBrk {
  background-color: #5b25bd;
  border: 1px solid #280e5e;
}

.desktopClockTimeLineHeader {
  padding-left: 19px;
  padding-right: 21px;
}

.clockItemGPSscrollBoxDesktop {
  max-width: 90% !important;
  min-width: 90% !important;
}

.clockTimelineModalFooterDesktop {
  max-width: 93% !important;
  min-width: 93% !important;
  margin-left: 5%;
  padding-right: 10px;
}

.usrPayAsterixSalaryInfo {
  font-size: 9px;
  font-weight: 500;
  color: #fff;
  text-align: right;
  max-width: 93%;
  padding-right: 16px;
  margin-top: 8px;
  margin-bottom: 3px;
}

.contactDoneBtnsDiv {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.contactDoneBtn {
  background-color: #214978;
  border: 1px solid #143051;
  border-radius: 5px;
  color: white !important;
  margin-top: 20px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.contactDoneBtn:hover {
  background-color: #143051;
}

.myRotaJtUserMarginTop5px {
  /* background: yellow !important; */
  margin-top: 10px !important;
  /* min-height:66px !important */
}

.areYouSureModalUnderlay {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.areYouSureModalBox {
  width: 300px;
  min-height: 180px;
  background-color: #f4f8fe;
  border-radius: 5px;
  z-index: 99999999;
  cursor: default;
  color: #143051;
  padding: 15px 20px 15px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.areYouSureTxt {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  margin-top: 8px;
}

.areYouSureModalBtnsDiv {
  display: flex;
  justify-content: space-between;
  min-width: 156px;
  margin-top: 20px;
  min-height: 35px;
}

.areYouSureYesBtn {
  color: #fff;
  font-size: 16px;
  background-color: #0bf;
  border: 1px solid #0af;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 5px;
  min-width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 35px;
  font-weight: 500;
  cursor: pointer;
  min-width: 48%;
}

.areYouSureYesBtn:hover {
  background-color: #0af;
}

.areYouSureNoBtn {
  color: #fff;
  font-size: 16px;
  background-color: #214978;
  border: 1px solid #143051;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 5px;
  min-width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 35px;
  font-weight: 500;
  cursor: pointer;
  min-width: 48%;
}

.areYouSureNoBtn:hover {
  background-color: #143051;
}

.tilTakenEditImg {
  margin-top: 0px !important;
}

.approveOrDeclineChangeReqDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 135px;
  flex-direction: row-reverse;
}

.approveShChangeReqBtn {
  background-color: #6dba4f;
  border: 1px solid #5a9e3f;
  min-height: 35px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  padding-bottom: 2px;
}

.approveShChangeReqBtn:hover {
  background-color: #5a9e3f;
}

.declineShChangeReqBtn {
    /* background-color: #fff; */
    border: 1px solid #e6eaee;
    color: #bac5d3;
    min-height: 35px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}

.declineShChangeReqBtn:hover {
  border-color: #d7dfe9;
  color: #8ea7c5;
}

.shiftChangeTitleAndNote {
  min-height: 30px;
  max-height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 3%;
  padding-right: 3%;
  margin-top: 5px;
}

.changeNoteDiv {
  font-size: 12px;
  font-weight: 400;
  color: #0af;
  text-align: left;
  padding-left: calc(3% + 10px);
  padding-top: 5px;
  padding-bottom: 15px;
  line-height: 20px;
  background-color: #fcfdff;
}

.changeNoteTitleSpan {
  color: #143051;
  font-weight: 500;
}

.mobActiveToolsCog {
  margin-left: 0px;
  margin-right: 7px;
  margin-top: -1px;
}

.extendBlueLeftBorder {
  border-left: 5px solid #00ccff;
}

.extendPurpleLeftBorder {
  border-left: 5px solid #9374d6;
}
.extendRedLeftBorder {
  border-left: 5px solid #c95858;
}

.extendGreyLeftBorder {
  border-left: 5px solid #859dbb;
}

.makeCostSpanSmall {
  font-size: 9px;
  margin-left: 2px;
}

.borderLeft3Blue {
  border-left: 5px solid #00ccff;
}

.borderLeft3Blue:hover {
  border-left: 5px solid #00ccff;
  padding-left: 1px;
}
.borderLeft3Purple {
  border-left: 5px solid #9374d6;
}
.borderLeft3Purple:hover {
  border-left: 5px solid #9374d6;
  padding-left: 1px;
}

.borderLeft3Grey {
  border-left: 5px solid #859dbb;
  padding-left: 1px;
}

.borderLeft3Grey:hover {
  border-left: 5px solid #859dbb;
  padding-left: 1px;
}

.customDataRowBottomBorder {
  border-bottom: 2px solid #516b8b;
  padding-bottom: 4px;
  min-height: 40px !important;
}

.absenceLight {
  font-size: 9px;
  opacity: 0.5;
}

.undeciedBorderLeftChange {
  /* border-left: 5px solid #0af; */
  border: dashed 2px #bbcada;
  box-shadow: none;
}

.declinedBorderLeftChange {
  border-left: 5px solid #ba0000;
}

.approvedBorderLeftChange {
  border-left: 5px solid #a8cc9a !important;
}

.noLongerExistsTxt {
  color: #bbcada;
  font-size: 12px;
  margin-top: -12px;
  margin-bottom: 4px;
  margin-left: 10px;
}

.clocksLoadingApprove {
  opacity: 0.5;
  pointer-events: none;
}

.ongoingBorder {
  border-top: 3px solid #00ccff;
}

.minWidth312 {
  min-width: 312px !important;
}

.colourBlue {
  color: #b9e8ff !important;
}
.colourBlueMgr {
  color: #6ba1e4 !important;
}

.lieuColourMgr {
  color: #93b5dd !important;
}

.overColourMgr {
  color: #8aa9b7 !important;
}

.tilShiftRightParent {
  display: flex;
  align-items: center;
  margin-right: -1px;
  /* background-color: orange; */
}

.teamClockLeftParent {
  /* background-color: blue; */
  min-width: 55%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.teamClockRightParent {
  /* background-color: lime; */
  min-width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.leftReqItemPendingBorder {
  border-left: 5px solid #0af !important;
}

.mobClockStatusBringLeft {
  margin-right: 10px;
}

.ongoingTab {
  background-color: #0af;
  color: white;
  border-color: #07f;
  background-color: #f4f8fe;
  border: 2px solid #00ccff;
  color: #00ccff;
  margin-right: 8px;
  -webkit-animation: pulsate 1s infinite;
          animation: pulsate 1s infinite;
}

@-webkit-keyframes pulsate {
  0% {
    border: 2px solid #00ccff;
  }
  50% {
    border: 2px solid #cdf4fd;
  }
  100% {
    border: 2px solid #00ccff;
  }
}

@keyframes pulsate {
  0% {
    border: 2px solid #00ccff;
  }
  50% {
    border: 2px solid #cdf4fd;
  }
  100% {
    border: 2px solid #00ccff;
  }
}

.absentShiftUnit {
  background-color: white;
  max-width: 90%;
  min-width: 90%;
  margin: 14px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 72px;
  max-height: 72px;
  border-radius: 5px;
  cursor: pointer;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  padding: 1px;
  border: 1px solid #dee5f0
  /* margin-top: 12px; */
}

.formUnitMy {
  background-color: white;
  max-width: 90%;
  min-width: 90%;
  margin: 12px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 72px;
  max-height: 72px;
  border-radius: 5px;
  cursor: pointer;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  padding: 1px;
  border:1px solid #dee5f0;
  /* border: none; */
  margin-top: 20px;
}

.formUnitMy:hover {
  border:1px solid #bbcada;
  padding: 0px;
  padding-left: 1px;
  padding-right: 1px;
}

.absentShiftUnit:first-of-type {
  margin-top: 40px;
}

.formUnitX {
  margin-top: 42px !important;
  margin-bottom: 8px;
}

.absentShiftUnit:hover {
  background-color: #fff;
  border:1px solid #bbcada;
  /* padding: 0px; */
}

.absentShiftUnitLeft {
  /* background-color: orange; */
  min-width: 160px;
  text-align: left;
  font-weight: 500;
  padding-left: 7%;
}

.absentShiftUnitLeft2 {
  /* background-color: orange; */
  /* min-width: 130px; */
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  text-align: left;
  font-weight: 500;
  padding-left: 6%;
  margin-left: 2px;
}

.absentShiftTongue {
  background-color: #443e77;
  border-color: #143051;
  /*  */
  background-color: #7eb4cf;
  border-color: #6a9fb9;
}

.formTongue {
  background-color: #40468f;
  border-color: #272d70;
}

.absentShiftScheduledSpan {
  color: #bbcada;
  font-weight: 400;
  font-size: 10px;
  /* padding-right: 0px; */
  /* background: #dde9f9; */
  /* border-radius: 4px; */
  /* padding: 2px; */
  margin-bottom: 4px;
  line-height: 16px;
  padding-top: 0px;
  padding-bottom: 6px;
}

.bbcada {
  color: #bbcada;
}
.absenceShiftUnitLeftUpper {
  color: #143051;
  font-size: 14px;
  font-weight: 600;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 6px;
}

.absenceShiftUnitLeftLower {
  color: #95aaba;
  font-weight: 500;
  font-size: 12px;
  max-width: 170px;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;

  margin-top: 1px !important;
  line-height: 13px;
  border-top: 1px solid #e0ebf8;
}

.myAbsentShiftsLowerRight {
  /* background:orange; */
  line-height: 20px;
  color: #95aaba;
  font-weight: 500;
  font-size: 12px !important;
  max-width: 170px;
  min-height: 24px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  /* justify-content: flex-end; */
  margin-bottom: 3px;
  max-height: 32px !important;
}

.myAbsShiftPurp {
  color: #ba0000;
  border-radius: 3px;
  /* padding-left: 4px; */
  /* padding-right: 4px; */
  min-height: 18px;
  /* margin-left: 5px; */
  /* margin-bottom: 3px; */
  display: flex;
  align-items: center;
  /* font-weight: 500; */
  border-top: 1px solid #e0ebf8;
  /* padding-right: 5px; */
  border-radius: 0;
  /* background-color: #c6b5eb; */
  /* border: #ba0000 1px solid; */
  /* background-color: #f07b7b; */
  /* border-color: #460f0f; */
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  max-height: 20px !important;
  font-size: 10px;
}

.absentShiftLeftParent {
  display: flex;
  min-width: calc(95% - 25px);
  justify-content: space-between;
  /* margin-top: 6px; */
  align-items: center;
}

.absenceShiftUnitRight {
  text-align: right;
  min-height: 44px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* min-width:116px; */
}

.absenceSpanColour {
  color: #bbcada;
  border-radius: 3px;
  /* padding-left: 4px; */
  /* padding-right: 4px; */
  /* font-size: 10px; */
  /* padding-top: 1px; */
  /* padding-bottom: 1px; */
  /* background-color: #8666a3;
  border: 1px solid #644b7a; */
  margin-left: 5px;
  margin-bottom: 3px;
  /* 
  background-color:#c6b5eb;
  border: #ac99d4 1px solid; */

  /* border: #ba0000 1px solid; */
  /* background-color: #f07b7b; */
}

.myAbsencesFiltBar {
  min-width: 90%;
  max-width: 90%;
  margin-left: 5% !important;
  /* background-color: orange; */
  min-height: 100%;
  display: flex;
  justify-content: space-between;
  /* margin-left: 29px; */
  margin-top: 8px;
  /* padding-right: 6%; */
  align-items: center;
}

.colourAbsencePurp {
  color: #8666a3;
  margin-bottom: 1px;
}

.colourAbsenceBlue {
  color: #214978;
  margin-bottom: 14px;
  margin-top: 15px;
}

.myAbsenceExplain {
  color: #143051;
  font-weight: 500;
  color: #bbcada;
  font-size: 14px;
  margin-top: 18px;
}



.x19849171 {
  margin-top:-5px;
  /* background-color: blue !important; */
}

.x184928741 {
  margin-top:0px
}
.x197467268841 {
  margin-top: -13px;
  margin-bottom: 6px;}

.invalidClockShiftTxt {
  font-weight: 500;
}

.unpaidAbsentSpan {
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content !important;
  min-width: fit-content !important;

  font-weight: 500;
  padding-top: 2px;
  padding-bottom: 2px;

  font-size: 10px;
  text-align: left;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 3px;

  /* max-height: 26px; */
  /* margin-bottom:2px !important; */
  color: #fff;
  /* background-color: #f4f8fe; */
  /* border: 1px solid #e6e6e6; */
  margin-left: 7px;

  background-color: #c6b5eb;
  /* margin-top: 3px; */
  /* margin-bottom: -3px; */
  border: #ac99d4 solid 1px;
}

.dayOffTongue {
  background-color: #214978;
  border-color: #143051;
}

.lateActive {
  background-color: #f4f8fe;
  border: 1px solid #dde9f9 !important;
  border-radius: 5px !important;
  margin-top: -4px;
}

.lateActiveTitleRed {
  max-width: 42px;
  font-weight: 500;
  max-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  margin-bottom: 15px;
  margin-top: 7px;
  background: white;
  border: 1px solid #dde9f9;
  border-left: 2px solid #ba0000;
  color: #ba0000 !important;
}

.smallZoneName {
  font-size: 9px;
}

.pullCancelTilUpBecauseInPast {
  margin-bottom: -30px;
}

.addShiftLeaveOnlyBox {
  max-height: 330px;
  min-height: 330px;
}

.addShiftLeaveOnlyFooter {
  margin: -100px auto !important;
  /* flex-direction: row-reverse; */
}

.staffAbsenceHeaderPadding {
  padding-top: 2px;
}

.nonMobShiftsContainerWithFilt {
  max-height: calc(100vh - 294px) !important;
  min-height: calc(100vh - 294px) !important;
}

.nonMobShiftsContainerWithoutFilt {
  max-height: calc(100vh - 210px) !important;
  min-height: calc(100vh - 210px) !important;
}

.mgrReqDateStripLong {
  font-size: 11px;
  margin-top: -3px;
  line-height: 15px;
}

.mgrAbsName {
  color: #143051;
  font-size: 12px;
}

.absBox {
  min-height: calc(100vh - 208px);
  max-height: calc(100vh - 208px);
  align-items: flex-start;
  padding-bottom: 40px;
}

.periodsAbsBox {
  padding-top:34px;
}

.absBoxColCal {
  min-height: calc(100vh - 257px);
  max-height: calc(100vh - 257px);
  align-items: flex-start;
}

.absBoxColCalMob {
  min-height: calc(100vh - 314px);
  max-height: calc(100vh - 314px);
  align-items: flex-start;
}

.absBoxMob {
  min-height: calc(100vh - 265px);
  max-height: calc(100vh - 265px);
}

.x10841131 {
  min-height: calc(100vh - 265px);
  max-height: calc(100vh - 265px);
  /* margin-top:11px */
}

.absBoxMobStaff {
  min-height: calc(100vh - 254px);
  max-height: calc(100vh - 254px);
}

.staffAbsFiltBarRight {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  display: flex;
  justify-content: space-between;
}

.absenceFiltBtn {
  font-size: 14px;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  padding-left: 14px;
  font-weight: 500;
  padding-right: 14px;
  cursor: pointer;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  /* margin-right:8px; */
}

.absenceFiltBtn:hover {
  background-color: #bbcada;
}

.absFiltBtnActiveColours {
  background-color: #8ea7c5;
  border: 1px solid #6987a9;
  max-width: 80px;
  padding-left: 11px;
  padding-right: 10px;
}

.absFiltBtnActiveColours:hover {
  background-color: #8ea7c5;
  border: 1px solid #6987a9;
}

.closeAbsFiltCrossImg {
  max-width: 9px;
  margin-left: 5px;
  margin-right: -2px;
}

.showFiltBarRightAbs {
  min-width: 208px;
}

.staffAbsenceFiltDiv {
  min-height: 50px;
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  border-bottom: 1px solid #e6eaee;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* padding-bottom: 5px; */
}

.absBoxFilterActive {
  min-height: calc(100vh - 255px);
  max-height: calc(100vh - 255px);
}

.absBoxFilterActiveStaff {
  min-height: calc(100vh - 304px);
  max-height: calc(100vh - 304px);
}

.lateStartSpanMgr {
  color: white;
  opacity: 0.8;
}

.staffAbsFixedRow {
  min-height: 36px;
  padding-left: 5px;
  padding-right: 8px;
}

.sideBoxAbsTypesMap {
  /* background-color: orange; */
  margin-top: 10px;
  /* margin-bottom:10px; */
}

.staffAbsUnpPaFix {
  margin-top: 12px;
  min-height: 35px;
}

.customTitleLongSmall {
  line-height: 16px !important;
  font-size: 14px !important;
}

.noPadLeft {
  padding-left: 0px !important;
}

.noPadRight {
  padding-right: 0px !important;
}

.teamSchedBar {
  padding-right: 4% !important;
  border-bottom: 2px solid #e6eaee;
  /* padding-bottom: 3px; */
}

.borderBottomGrey {
  border-bottom: 2px solid #e6eaee;
}

.shiftNamesDropdownTypeSelStaff {
  margin-left: 15px;
  display: none;
  /* max-width:170px;
  min-width:170px; */

}

.modalStaffAbsDataBox {
  max-width: 330px !important;
  min-width: 330px !important;
  cursor: default !important;
}

@media only screen and (max-width: 550px) {
  .modalStaffAbsDataBox {
    position: fixed;
    bottom: 0;
    min-height: 92vh !important;
    min-width: 100vw !important;
    border-radius: 13px 13px 0 0;
  }
}

@media only screen and (max-width: 550px) {
  .staffAbsDataModalBody {
    min-height: calc(100vh - 179px) !important;
    max-height: calc(100vh - 179px) !important;
  }
}

.staffAbsDataBoxHeaderLeft {
  display: flex;
  justify-content: flex-start;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  /* background: blue */
}

.payableTitleAndTeamNameDivAbs {
  /* background-color: blue; */
}

.closeStaffAbsDataImg {
  max-width: 15px;
  margin-right: 16px;
  margin-left: 7px;
  margin-top: -12px;
  cursor: pointer;
}

.closeStaffAbsDataImg:hover {
  opacity: 0.8;
}

.myAbsDataBoxHeaderLeft {
  display: flex;
  justify-content: flex-start;
}

.absPercSideBox {
  margin-top: 12px !important;
}

.includeTypesSaveTempHover {
  /* background-color: yellow; */
  padding-right: 8px;
  padding-bottom: 9px;
  /* border-radius: 5px; */
}

.includeTypesSaveTempHover:hover {
  background-color: #f4f8fe;
}

.resizable {
  overflow: auto;
}
.loadMultiWeeks {
  /* background-color: #516b8b; */
  min-width: 160px;
  margin-bottom: 7px;
  /* display: flex; */
  /* justify-content: flex-end; */
  /* align-items: center; */
}

.loadMultiWeeks {
  color: #bbcada;
  font-size: 12px;
  font-weight: 500;
  /* margin-right: 8px; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-left: #e3e8ee 2px solid;
}

.applyTempPlusMinus {
  min-width: 24px;
  min-height: 24px;
  border: 1px solid #bbcada;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-radius: 5px;
  font-size: 20px;
  line-height: 20px;
  padding-bottom: 2px;
  cursor: pointer;
}

.applyTempPlusMinus:hover {
  border-color: #d7dfe9;
  background-color: #f4f8fe;
}

.applyTempTxt {
  max-width: 80px;
  margin-right: 8px;
  /* float: right; */
}

.applyWeeksContainer {
  display: flex;
  align-items: center;
  margin-top: 3px;
  /* float: right; */
}

.loadWeeksNumberInput {
  border-radius: 5px;
  border: 1px solid #dde9f9;
  font-weight: 500;
  text-align: center;
  background-color: #fff;
  color: #214978;
  min-height: 35px;
  margin-left: 5px;
  margin-right: 5px;
  min-width: 35px;
  pointer-events: none;
}

.dontForgetToPublish {
  color: yellow;
}

.publishTemplatedNow {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.loadingBulkModal {
  background-color: white;
  min-width: 200px;
  border-radius: 5px;
  padding: 20px;
  font-weight: 500;
  color: #143051;
  font-size: 14px;
  cursor: default;
}

.bulkHorizImg {
  max-width: 110px;
  margin-bottom: 8px !important;
}

.explainBookedOffModal {
  min-height: 100px;
  max-width: 300px;
  min-width: 300px;
  padding: 20px;
  border-radius: 5px;
  margin: 0 auto;
  justify-content: space-between;
  background-color: white;
  color: #143051;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
}

.explainBookedTxt {
  /* color:blue */
  line-height: 18px;
}

.explainBookedClose {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  margin-top: 20px;
}

.explainBookedClose:hover {
  background-color: #214978;
}

.limBold {
  font-weight: 500;
}

.clockOnLateTxt {
  font-size: 16px !important;
  padding-bottom: 4px;
  line-height: 47px;
}

.applyLateBtn {
  margin-right: 15px;
  margin-top: 9px;
  cursor: pointer;
  min-width: 68px;
  max-width: 68px;
  min-height: 35px;
  max-height: 35px;
  border-radius: 5px;
  padding: 0px;
  border: #e3e8ee solid 2px;
  background-color: white;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 5px;
  color: #496375;
  border-left: 3px solid #ba0000;
  border-radius: 4px 5px 5px 4px;
}

.applyLateBtn:hover {
  border-color: #b2bbc7;
  border-left: 3px solid #ba0000;
}

.unlinkedSupportBtn {
  color: white !important;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  font-weight: 500;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  margin-left: 4px;
  margin-right: 8px;
}

.unlinkedSupportBtn:hover {
  background-color: #bbcada;
}

.unlinkedPopup {
  max-width: 12px;
}

.statsImgMob {
  margin-left: 1px;
  margin-right: -1px;
}

.teamStatsImgMob {
  margin-right: 2px;
  margin-left: 2px;
}

.loadingStaffHoursData {
  max-width: 100px;
  margin-top: 18px;
  -webkit-filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(87deg)
    brightness(1019%) contrast(119%);
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(87deg)
    brightness(1019%) contrast(119%);
}

.newFormBtn {
  background-color: #a387df;
  border: 1px solid #896ec3;
  color: white;
  font-weight: 500;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 14px;
  cursor: pointer;
}
.newFormBtn:hover {
  background-color: #896ec3;
}

.whiteAddFormImg {
  max-width: 12px;
  margin-left: 6px;
}

.formUnitParent {
  max-height: 131px !important;
  -webkit-animation-name: zoomBtnIn !important;
          animation-name: zoomBtnIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  margin-bottom: -19px;
}

.myFormUnit {
  max-height: 131px !important;
  margin-bottom: 0px;
}

.firstFormUnitParent {
  margin-top: 24px !important;
}

.firstFormUnitParentMy {
  margin-top: -1px;
}

.formUnitName {
  margin-top: -101px;
  background: white;
  border: 1px solid #e3e8ee;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  border-radius: 5px 5px 0 0;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding-left: 13px;
  padding-right: 13px;
  font-size: 14px;
  margin-left: 12%;
  margin-bottom: 111px;
  z-index: 0;
  position: relative;
  border-bottom: none;
  color: #143051;
  float: left;
}

.absPeriodName {
  margin-top: -99px;
  background: white;
  border: 1px solid #e3e8ee;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  border-radius: 5px 5px 0 0;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding-left: 5px;
  padding-right: 10px;
  font-size: 14px;
  margin-left: 12%;
  margin-bottom: 71px;
  z-index: 0;
  position: relative;
  border-bottom: none;
  color: #143051;
  float: left;
  font-size: 12px;
}

.formMgrApproved {
  background-color: #c1d2ba;
  border: 1px solid #6db451;
  color: white;
  border-radius: 3px;
  font-size: 10px;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 6px;
  margin-top: 6px !important;
  min-height: 20px;
  margin-bottom: 2px;
}

.formTick {
  max-width: 8px;
  margin-left: 3px;
  margin-right: 3px;
}

.displayFlexEnd {
  display: flex;
  justify-content: flex-end;
}

.usrCompleteForm {
  /* background-color: #fff; */
  /* border-color: #bbcada; */
  margin-top: 5px !important;
  /* border-left: 3px solid #6db451; */
  color: #fff;
  min-height: 20px;
}

.formTitleSmaller {
  font-size: 12px !important;
  line-height: 15px;

  margin-bottom: 3px;
}

.notYetFilledIn {
  font-size: 9px;
  margin-top: 5px;
  border-radius: 3px 2px 2px 3px;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  color: #674973;
  border: 1px solid #bbcada;
  border-left: 3px solid #674973;
  padding-left: 6px;
  padding-right: 6px;
}

.notYetSignedOff {
  font-size: 9px;
  /* margin-top: 5px; */
  /* border-radius: 3px 2px 2px 3px; */
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  color: #bbcada;
  /* border: 1px solid #bbcada; */
  /* border-right: 2px solid #bbcada; */
  /* padding-left: 6px; */
  /* padding-right: 6px; */
  min-height: 14px;
  display: flex;
  align-items: center;
  margin-top: 5px;

}

.notYetFilledInMy {
  /* font-size: 9px;
  margin-top: 5px;
  border-radius: 3px 2px 2px 3px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  color: #7cd3ff;
  border: 1px solid #bbcada;
  border-left: 3px solid #7cd3ff;
  padding-left: 6px;
  padding-right: 6px;
  min-height:20px;
  display: flex;
  align-items: center;
  margin-bottom: 4px; */
  display: none;
}

.formCreatedTab {
  margin-top: -18px;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  float: right;
  margin-right: 12%;
  background: white;
  border: 1px solid #e3e8ee;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border-radius: 0px 0px 5px 5px;
  min-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 10px;
  border-top: none;
  color: #bbcada;
}

.noAnimation {
  -webkit-animation-name: none !important;
          animation-name: none !important;
  -webkit-animation-duration: 0s !important;
          animation-duration: 0s !important;
}

.margiTop10 {
  margin-top: 10px !important;
}

.formIconLeft {
  min-width: 22px;
  /* margin-right: 4px; */
  /* background-color: yellow; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-right: 2px dotted #bbcada; */
  padding-right: 10px;
  min-height: 50px;

  max-height: 50px;
  padding-top: 2px;
}

.formIcon {
  max-width: 17px;
  /* margin-top: -8px; */
  margin-left: 8px;
  margin-right: 3px;
}

.lateIcon {
  max-width: 18px;
  margin-top: -2px;
  margin-left: 10px;
}
.absentShiftIcon {
  max-width: 24px;
  margin-top: -4px;
  margin-left: 15px;
  margin-right: 1px;
}

.formLeftWrapper {
  display: flex;
  /* margin-top:-45px; */
}

.font14 {
  font-size: 14px;
  max-width: 160px;
}

.absencePeriodIcon {
  max-width: 12px;
  margin-left: 14px;
  margin-right: -6px;
}

.absencePeriodIconMob {
  max-width: 11px;
  margin-left: 14px;
  margin-right: -6px;
}

.timeHoursImg {
  max-width: 16px;
  min-width: 16px;
  margin-left: 10px;
}




.myHoursLeftWrapper {
  display: flex;
  /* min-width: 202px;
  max-width:202px; */
  min-width: 180px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-height: 72px;
  align-items: center;
}

.clockSectionLeftWrapper {
  display: flex;
  min-width: 52%;
  /* background: orange; */
  margin-left: 15px;
}
.clockCardIcon {
  max-width: 15px;
  margin-right: 3px;
  margin-left: -2px;

}

.teamClockCardIcon {
  max-width: 15px;
  margin-left: 2px;
  margin-right: 4px;
}

.formItemsDiv {
  border: 2px solid #e0ebf8;
  max-width: 90%;
  margin-left: 5%;
  border-radius: 5px;
  min-height: 40px;
  /* overflow: auto; */
  /* max-height: 210px; */
  margin-top: 10px;
  padding-bottom: 10px;
  background-color: #f4f8fe;
}

.formItemsDivMyForm {
  max-width: 96%;
  margin-left: 2%;
  border-radius: 5px;
  min-height: 40px;
  /* overflow: auto; */
  /* max-height: 210px; */
  margin-top: 4px;
  padding-bottom: 0px;
}
.noFormItems {
  color: #bbcada;
  font-weight: 500;
  text-align: center;
  font-size: 12px;
  margin-top: 7px;
  margin-bottom: -12px;
  border-left: 3px solid #bbcada;
  border-radius: 0px !important;
  max-width: 260px;
  max-height: 27px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  margin-left: 17px;
}

.formHeaderTitle {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  line-height: 23px;
  max-width: 83%;
  padding-bottom: 3px;
  min-height: 48px;
  display: flex;
  align-items: center;
}

.yetToCompleteForm {
  color: #bbcada;
  font-size: 12px;
  font-weight: 500;
  margin-left: 6px;
  padding-left: 8px;
  border-left: #bbcada 2px solid;
  max-height: 18px;
  display: flex;
  align-items: center;
}

.yetToCompleteFormUsrOnly {
  cursor: pointer;
}

.yetToCompleteFormUsrOnly:hover {
  border-left: #214978 2px solid;
  color: #214978;
}

.rtwQanswerInput {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 7px;
  background-color: #fdfeff;
  resize: none;
  color: #214978;
  padding: 10px;
  font-weight: 500;
  margin-top: 19px;
  min-width: 100%;
  font-size: 16px;
  line-height: 22px;
}

.saveRtwBtn {
  border: solid 1px #0af;
  background-color: #0bf;
  max-height: 35px;
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.saveRtwBtn:hover {
  background-color: #0af;
}

.formNote {
  color: #143051;
  font-size: 18px;
  font-weight: 500;
  margin-left: 7%;
  padding-right: 20px;
  line-height: 20px;
  padding-top: 8px;
}

.respUnit {
  background-color: #ffffff;
  max-width: 100%;
  margin-left: 0%;
  /* border-radius: 5px; */
  border-bottom: 1px solid #dde9f9;
  margin-top: 10px;
  font-weight: 500;
  padding-left: 10%;
  font-size: 14px;
  padding-right: 31px;
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 12px;
}
.respUnit2 {
  padding-bottom: 22px;
  /* max-width: 80%; */
  /* margin-left:10%; */
}

.respUnit:last-child {
  border: none;
}

.respUnit:first-of-type {
  margin-top: 15px;
}
.latesLower {
  margin-top: -1px;
}

.responseA {
  margin-top: 10px;
  font-weight: 500;
  line-height: 18px;
  font-size: 14px;
  color: #143051;
  margin-bottom: 10px;
  border-left: 2px solid #9380c5;
  padding-left: 9px;
}

.formCompletedByYouDiv {
  background-color: #fff;
  border: 1px solid #6db451;
  border-left: 3px solid #6db451;
  align-items: center;
  color: #6db451;
  font-size: 10px;
  max-width: -moz-fit-content;
  max-width: 108px;
  line-height: 11px;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  padding: 3px;
  padding-left: 8px;
  padding-right: 5px;
  border-radius: 5px;
  text-align: left;
}

.formCompByYouTick {
  max-width: 10px;
  margin-right: 5px;
  margin-left: 2px;
}

.rtwFormNotYetApp {
  margin-left: 10px;
  color: #0077ff;
  font-size: 10px;
  /* border-right: 3px solid #0077ff; */
  display: flex;
  align-items: center;
  /* padding-right: 5px; */
  margin-bottom: 2px;
}

.signOffRTWbtn {
  background-color: #1e88e5;
  border: #0469de 1px solid;
  color: white;
  font-weight: 500;
  max-height: 35px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 9px;
  padding-right: 9px;
  cursor: pointer;
  font-size: 14px;
  margin-right:10px;
}

.signOffRTWbtn:hover {
  background-color: #1374e4;
}

.absenceRTWFooterLeft {
  display: flex;
  justify-content: flex-start;
}

.filledInMarginLeft {
  margin-left: 10px;
}

.staffTitleLeftBlob {
  background-color: #ceb9f3;
  color: #ceb9f3;
  border-radius: 5px;
  max-width: 5px !important;
  min-width: 5px !important;
  /* min-height: 10px; */
  margin-right: 10px;
  font-size: 23px !important;
}

.staffAbsLateRight {
  /* max-height: 30px; */
  min-height: 40;
}

.absentShiftLower {
  margin-bottom: 10px !important;
}

.absShiftX {
  line-height:22px;
  margin-top: 0px;
}

.absRightShift {
  margin-top: 2px;
}
.latesLower2 {
  margin-bottom: 5px;
}

.shiftsHeaderMob2 {
  margin-top: 5px !important;
}

.shiftsHeaderMob3 {
  margin-top: 11px !important;
}

.mobMyAccHeader {
  margin-top: 12px !important;
}

.unkDurStr {
  font-size: 10px;
  padding-top: 7px;
  padding-bottom: 2px;
}

.formMinsStr {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  padding-bottom: 2px;
  color: #214978;
  /* margin-top: -1px; */


  /* font-size: 10px; */
  font-weight: 500;
  background: #f4f8fe;
  border-radius: 5px;
  border: 1px solid #bbcada;
  color: #516b8b;
  font-size:12px;
  padding-left:6px;
  padding-right:6px;
}

.x1974101 {
    margin-top: -31px;
    margin-bottom:8px;

}

.lateStartStr {
  font-weight: 500;
  margin-left: 3px;
  font-size: 12px !important;
  color: #ba0000;
  line-height: 18px;
  min-height: 16px;
  border-top: 1px solid #e0ebf8;
  border-radius: 0;
  margin-top: 2px;
}
.x9739171 {
  margin-bottom: -25px;
  margin-top: 20px;
}

.minHeight24 {
  min-height: 24px !important;
}

.formKey {
  background-color: #fff;
  border: 1px solid #e3e8ee;
  border-radius: 5px;
  min-height: 44px;
  max-height: 44px;
  max-width: 100%;
  min-width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 8px;
  margin-top: -5px;
  padding-left: 2px;
  padding-right: 2px;

  /* float: right; */
}

.responseQ {
  color: #bbcada;
  font-weight: 500;
  font-size:12px;
  line-height: 18px;
}

/* .mgrFormKey {
  border-color: #e3e8ee
} */

.formTabSel {
  background-color: #9380c5;
  min-height: 3px;
  min-width: 85px;
  border-radius: 10px;
  margin-top: -10px;
}

.formTabTitle {
  margin-top:-9px;
  max-height:44px;
  display: flex;
  align-items: center;
}

.formTabTitleSel {
  color:#143051
}

.formKeyTab {
  background-color: #143051;
  border-radius: 5px;
  /* border: 1px solid #e6eaee; */
  /* border-top: 2px solid #0bf;
  border-left: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee; */
flex-direction: column;

  /* border-bottom:none; */
  display: flex;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
  min-width: calc(50% - 4px);
  margin-top: 4px;
  max-height: 34px !important;
  min-height: 34px !important;
  color: white;
}

.formKeyTab:last-child {
  /* margin-left:10px; */
}

.formKeyTabUnselected {
  background: none;
  border: none;
  cursor: pointer;
  color: #bbcada;
}

.formKeyTabUnselected:hover {
  color: #8ea7c5;
}

.issuedByForm {
  margin-top: 18px;
  margin-bottom: 10px;
  color: #bbcada;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.formPageQty {
  background-color: #bbcada;
  color: white;
  max-height: 20px;
  display: flex;
  align-items: center;
  font-weight: 500;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 4px;
  margin-left: 5px;
  /* margin-right: -12px; */
}

.formPageQtyFilledIn {
  background-color: #9380c5;
}

.addLeaveDatesTitlePage2 {
  border-radius: 5px 0px 0px 0px !important;
}

.purpleFormTab {
  /* border-top: 2px solid #ac99d4 */
}

.staffHoursDataBoxHeaderLeft {
  display: flex;
  justify-content: flex-start;
}

.closeStaffDataBoxImg {
  cursor: pointer;
  min-width: 15px;
  max-width: 15px;
  margin-left: 3px;
  cursor: pointer;
  margin-top: -16px;
  margin-right: 16px;
}

.closeStaffDataBoxImg:hover {
  opacity: 0.8;
}

.formModalFooterRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.deleteFormBtn {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  color: white;
  font-weight: 500;
  font-size: 14px;
  max-height: 35px;
  max-width: 35px;
  min-width: 35px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.deleteFormBtn:hover {
  background-color: #bbcada;
}

.sureModalBtnsContainer {
  background-color: blue;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.staffLateName {
  margin-top: 3px;
  margin-bottom: 0px;
  font-size: 16px;
}

.staffAbsentShiftsName {
  font-size: 14px !important;
  margin-top: 3px;
  margin-bottom: 2px;
  line-height:22px;
}

.respHighlight {
  border: 2px solid #00ccff !important;
  color: #214978 !important;
}

.respHighlight:hover {
  border: 2px solid #143051 !important;
  color: #143051 !important;
}
.staffAbsMobShoulder {
  /* background-color: blue; */
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  min-height: 50px;
  max-height: 50px;
}

.staffAbsMobShoulderRow {
  border-bottom: 1px solid #e0ebf8;
  height: 50px;
  max-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formUnitY {
  margin-top: 13px !important;
}

.formYrAndCreateDiv {
  /* background-color: blue; */
  display: flex;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.createFormInline {
  margin-left: 10px;
  min-width: 102px;
}

.myAbsPerInline {
  margin-right: 15px;
}

.desktopClockCardsShoulder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  max-width: 100%;
  /* background-color: orange; */
}

.staffClocksMobShoulder {
  /* background-color: blue; */
  min-width: 100%;
  max-width: 100%;
  /* margin-left: 5%; */
  min-height: 130px;
  max-height: 130px;
  padding-top: 11px;
  margin-top: 8px;
}

.staffClocksMobShoulderRow {
  border-bottom: 1px solid #e0ebf8;
  height: 50px;
  max-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 29px;
}
.userHoursShoulderBar {
  min-width: calc(100vw + 4px) !important;
  margin-left: -2px !important;
  border-radius: 20px 20px 0 0 !important;
  border-top: 2px solid #e6eaee !important;
  border-left: 2px solid #e6eaee !important;
  border-right: 2px solid #e6eaee !important;
  padding-left: 5%;
  padding-right: 5%;
  min-height: 100px !important;
  max-height: 100px !important;
  /* min-height:112px !important; */
}

.staffClocksMobShoulderRow2 {
  border-bottom: 1px solid #e0ebf8;
  height: 50px;
  max-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1px;
  padding-bottom: 8px;
}

.staffClocksMobShoulderRowUnlinked {
  margin-top: 1px;
}

.userHoursShoulderBar {
  /* min-height:110px !important;
  max-height: 110px !important; */
  /* margin-bottom:-9px;
  padding-top:10px;
  margin-top:5px */
}

.userHoursShoulderBar2 {
  min-width: calc(100vw + 4px) !important;
  margin-left: -2px !important;
  border-radius: 20px 20px 0 0 !important;
  border-top: 2px solid #e6eaee !important;
  border-left: 2px solid #e6eaee !important;
  border-right: 2px solid #e6eaee !important;
  padding-left: 5%;
  padding-right: 5%;
  min-height: 50px !important;
  max-height: 50px !important;
}

.marginTopMinus6 {
  margin-top: -6px !important;
}

.marginTop16 {
  margin-top: 16px;
}

.minHeight106px {
  min-height: 106px !important;
  max-height: 106px !important;
}

.marginTop6 {
  margin-top: 6px !important;
}

.marginTop3 {
  margin-top: 3px !important;
}

.lightbluebg {
  background-color: #ebf0f7 !important;
}

.allStaffTeamClocksMobDrop {
  margin-top: 5px;
}

.requestAvailFromWholeTeamBtn {
  background-color: blue;
}

.reqTeamAvailBtnTxt {
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 35px;
  font-weight: 500;
  border-radius: 5px;
}

.absentRowActive {
  border: 2px solid #143051 !important;
  border-radius: 5px;
  background: #214978;
}

.absentRowActive:hover {
  background-color: #143051 !important;
}

.toilNoteTitle {
  margin-top: 20px;
}

.toilFooter {
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  border-top: 1px solid #e0ebf8;
  max-width: 92%;
  margin-left: 4%;
}

.paddingLeft2 {
  padding-left: 2% !important;
}

.availTilBrackets {
  font-weight: 500;
  font-size: 10px;
  color: #bbcada;
  /* text-align: left; */
  /* margin-top:-28px */
}

.availToilTitleDiv {
  /* background-color: blue; */
  min-height: 36px;
  text-align: left;
  padding-top: 12px;
}

.marginTopMinus28 {
  margin-top: -28px !important;
}

.desktopHeaderPanelRota {
  margin-left: 20px !important;
  min-width: 132px !important;
  max-width: 132px !important;
}

.desktopAddBtnRota {
  /* background-color: yellow; */
  max-width: 38px !important;
  min-width: 38px !important;
}

.tilInvalidTimeInput {
  border: #ba0000 2px solid !important;
}

.tilInvalidTimeInput:hover {
  border: #ba0000 2px solid !important;
}

.x191 {
  /* margin-top: 11px; */
  margin-right: 34px;
  line-height: 27px;
  margin-left:5px;
  font-size:14px;
}

.x19198314 {
  margin-top: 0px;
  line-height: 28px;
  min-width: 100px;
}

.tilFooterLeft {
  display: flex;
  justify-content: flex-start;
}

.removeTilFooterBtn {
  border: 1px solid #bbcada;
  background-color: #cbd7e6;
  cursor: pointer;
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  font-weight: 500;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 14px;
}

.removeTilFooterBtn:hover {
  background-color: #bbcada;
}

.myShiftTimeStrip {
  margin-left: 0px !important;
}

.changesArrRow {
  flex-direction: column;
}

.changesArrDiv {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;

  max-width: -webkit-fit-content;

  max-width: -moz-fit-content;

  max-width: fit-content;
  margin-bottom: 10px;
}

.fontSize10 {
  font-size: 10px !important;
  color: #8997a8 !important;
  /* opacity: 0.5; */
}

.fontSize9 {
  font-size: 9px !important;
}
.marginLeft0 {
  margin-left: 0px !important;
}

.rotaCoffee {
  max-width: 12px;
  margin-right: 4px;
  margin-top: 2px;
  margin-bottom: -2px;
  opacity: 0.5;
}

.rotaCoffeeTeam {
  max-width: 11px;
  margin-right: 2px;
  margin-top: 4px;
  margin-bottom: -2px;
  opacity: 0.5;
  margin-left: -6px;
}
.rotaClock {
  max-width: 10px;
  opacity: 0.5;
  margin-right: 3px;
  margin-top: 2px;
  margin-bottom: -2px;
}

.rotaClockTeam {
  max-width: 10px;
  opacity: 0.5;
  margin-right: 2px;
  margin-top: 2px;
  margin-bottom: -2px;
}

.costOverlay {
  background-color: white;
  border: 1px solid #6c96c8;
  border-radius: 3px 3px 0 0;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  margin-left: 10%;
  min-height: 15px;
  margin-top: -70px;
  margin-bottom: 68px;
  position: relative;
  color: #516b8b;
  font-weight: 500;
  font-size: 10px;
  padding-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  /* border-left: 3px solid #6c96c8; */
  border-bottom: none;
  border-color: #dde9f9;

}

.costOverlayLeave {
  background-color: #7788d8;
  color: #bfc7ef;
  border: 1px solid #6680a2;
  border-bottom:none;
}

.costOverlayAbs {
  background-color: #9aadc4;
  color: #e2e6f7;
  border: 1px solid #6680a2;
  border-bottom: none;
  }


.selfLogAbsIndicate {
  background-color: white;
  border: 1px solid #6c96c8;
  border-radius: 3px 3px 0 0;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  margin-left: 52px;
  min-height: 15px;
  margin-top: -83px;
  margin-bottom: 70px;
  position: relative;
  color: #143051;
  font-weight: 500;
  font-size: 10px;
  padding-top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  /* border-left: 3px solid #6c96c8; */
  border-bottom: none;
  border-color: #dde9f9;
  color:#ba0000;
  font-size:8px
  /* display:none */
}

.selfLogAbsIndicateOtherTeam {
  margin-left:8px
}

.teamHoursDsStrip {
  /* min-width: 46px !important */
}

.opacFull {
  opacity: 1;
}

.rotaClockLeaveOnly {
  margin-right: 5px;
}

.myRotaCoffee {
  margin-left: 3px;
  margin-right: 3px;
}

.noBorderLeft {
  border-left: none !important;
}

.changeItemsDiv {
  /* background-color: yellow; */
  max-width: 60%;
}

.changeItemRow {
  font-weight: 500;
  line-height: 16px;
  color: #bbcada;
  border-left: 3px solid #00ccff;
  padding-left: 8px;
  margin-bottom: 12px;
  margin-top: 12px;
  font-size: 12px;
  margin-left: 12px;
}

.changeUnitTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: blue; */
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #214978;
  /* opacity: 0.7; */
}

.chAppBorder {
  border-color: #a8cc9a;
}
.chDecBorder {
  border-color: #ba0000;
}

.changeApprovedStat {
  color: #a8cc9a;
  font-size: 12px;
}

.changeDeclinedStat {
  color: #ba0000;
  font-size: 12px;
}

.changePendingStat {
  display: flex;
  align-items: center;
  color: #bbcada;
  font-size: 12px;
  margin-left:9px;
}

.changeReqDs {
  font-size: 10px;
  font-weight: 400;
  color: #bbcada;
  margin-left: 10px;
  margin-top: -26px;
}

.x1113 {
  margin-top: -16px;
  margin-bottom: -9px;
}

.cancelReqInMyShiftImg {
  max-width: 24px;
  border: 1px solid #bbcada;
  border-radius: 4px;
  padding: 5px;
  margin-top: 0px;
  opacity: 0.5;
  margin-left: 5px;
  cursor: pointer;
}

.cancelReqInMyShiftImg:hover {
  opacity: 1;
}

.changeShiftDurMinsDiff {
  color: #bbcada;
  font-size: 12px;
  font-weight: 500;
  line-height: 27px;
  padding-left: 12px;
  margin-top: 4px;
  margin-bottom: 13px;
  border-left: #143052 3px solid;
  margin-left: 12px;
}

.changeDurSpanMins {
  /* background-color: #bedaf8;
    color: white;
    border-radius: 3px; */
  padding-left: 4px;
  /* padding-right: 3px; */
}

.clockOnSpan {
  color: #516b8b;
  font-size: 8px;
  font-weight: 400;
  margin-bottom: 1px;
}

.appOrDecChReqDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  padding-bottom: 1px;
}

.appChReqBtn {
  background-color: #5ca54091;
  border: 1px solid #5a9e3f75;
  color: #ffffff;
  /* background: white; */
  border-radius: 5px;
  min-height: 28px;
  max-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
  line-height: 12px;
  margin-left: 6px;
  cursor: pointer;
}

.appChReqBtn:hover {
  background-color: #3f702c75;
}

.decChReqBtn {
  /* background-color: #fff; */
  border: 1px solid #e6eaee;
  color: #bac5d3;
  border-radius: 5px;
  min-height: 28px;
  max-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;
}

.decChReqBtn:hover {
  background-color: #ecf4fd;
}

.loadingAppOrDecShiftChangeImg {
  max-width: 36px;
}

.chNote {
  color: #bbcada;
  border-top: 1px solid #bbcada;
  max-width: 90%;
  margin-left: 5%;
  font-weight: 500;
  line-height: 16px;
  padding-top: 13px;
  padding-bottom: 13px;
  font-size: 12px;
}

.chNoteSpanTitle {
  color: #143051;
}

.shiftUnitOutstandingChanges {
  color: white;
  padding-left: 0px;
  padding-right: 0px;
  font-weight: 500;
  border-radius: 4px 2px 2px 2px;
  font-size: 10px;
  padding-bottom: 2px;
  padding-top: 1px;
  border: 1px solid #965ade;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  max-height: 17px;
  max-width: 175px !important;
  margin-right: -156px;
  min-width: 156px !important;
  margin-top: -54px;
  border-left: 3px solid #965ade;
  z-index: 99;
}

.shiftUnitOutstandingChanges2 {
  color: #fff;
  padding-left: 0px;
  padding-right: 0px;
  font-weight: 500;
  border-radius: 4px 2px 2px 2px;
  font-size: 10px;
  padding-bottom: 2px;
  padding-top: 1px;
  border: 1px solid #965ade;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  max-height: 17px;
  max-width: 135px !important;
  margin-right: -92px;
  min-width: 92px !important;
  margin-top: -54px;
  border-left: 3px solid #965ade;
  z-index: 999999;
  background: #c2a9fa;
}

.pushSec1Down {
  margin-top: 6px;
}

.myOutstandingColours {
  background-color: #0bf;
  border-color: #0af;
}

.calDateStrip {
  font-weight: 500;
  color: #bbcada;
  font-size: 12px;
}

.monthCalUnit {
  /* background-color: blue; */
  max-width: 90%;
  margin-left: 5%;
  margin-bottom: 70px;
  margin-top: 10px;
}

.midCalHolder {
  display: flex;
  flex-wrap: wrap;
}

.fullCalHolder {
  display: flex;
  flex-wrap: wrap;
}

.midCalUnit {
  max-width: 42%;
  flex-basis: 42%;
  margin-left: 6%;
  margin-bottom: 40px;
}

.fullCalUnit {
  max-width: 40%;
  flex-basis: 40%;
  margin-left: 7%;
  margin-bottom: 40px;
  /* border: 1px solid #dde9f9; */
  /* border-radius:3px; */
  /* padding:7px; */
  /* background-color: white; */
}
/* 
   .fullCalUnit:nth-child(even) {
    border-left: 1px solid #bbcada;
    background-color: lime;
  
   }

   .midCalUnit:nth-child(even) {
    border-left: 1px solid #bbcada
   } */

.monthCalUnitHeader {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  /* margin-top:30px; */
  /* color:; */
}

.monthCalUnitHeaderTitle {
  color: #bbcada;
  font-weight: 500;
  font-size: 14px;
}
.font20 {
  font-size: 20px;
}

.monthCalDayLetters {
  display: flex;
  justify-content: space-around;
  min-width: 100%;
  max-width: 100%;
  min-height: 40px;
  align-items: center;
  margin-top: 20px;
}

.monthCalDayLtr {
  /* background-color: pink; */
  width: 14.2857142857%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #8ea7c5;
  font-size: 14px;
}
.snake {
  display: flex;
  flex-wrap: wrap;
}
.snakeItem {
  /* background-color: yellow; */
  flex-basis: 14.2857142857%;
  min-height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #516b8b;
  cursor: pointer;
  font-size: 14px;
  /* padding:5px; */
  /* max-width:90%; */
}

.snakeItem:hover {
  background-color: #e0ebf8;
  font-weight: 500;
  border-radius: 3px;
}
.nonMobSnakItem {
  min-height: 30px;
  font-size: 14px;
}

.snakeItemLeave {
  background-color: #00ccff;
  color: white;
  font-weight: 500;
  min-width: 96%;
  min-height: 96%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font: size 1px;px; */
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #00b3df
}

.snakeItemLeaveMob {
  max-height: 44px !important;
  min-height: 44px !important;
}

.snakeItemLeave:hover {
  background-color: #85dafc;
}

.snakeAbsenceColours {
  background-color: #516b8b;
  border: 1px solid #315075
}
.snakeAbsenceColours:hover {
  background-color: #607999;
}

.snakeFirstHalfOff {
  background: linear-gradient(66deg, #00ccff 50%, #cbd7e6 50%) !important;
}

.snakeLastHalfOff {
  background: linear-gradient(246deg, #00ccff 50%, #cbd7e6 50%) !important;
}

.snakeFirstHalfOffAbs {
  background: linear-gradient(66deg, #516b8b 50%, #cbd7e6 50%) !important;
}

.snakeLastHalfOffAbs {
  background: linear-gradient(246deg, #516b8b 50%, #cbd7e6 50%) !important;
}

.snakeLastHalfOffAbsHovered {
  background: linear-gradient(246deg, #6484aa 50%, #cbd7e6 50%) !important;
}

.snakeFirstHalfOffAbsHovered {
  background: linear-gradient(66deg, #6484aa 50%, #cbd7e6 50%) !important;
}

.snakeFirstHalfOff:hover {
  background: linear-gradient(66deg, #85dafc 50%, #cbd7e6 50%);
}

.snakeLastHalfOff:hover {
  background: linear-gradient(246deg, #85dafc 50%, #cbd7e6 50%);
}

.snakeFirstHalfOffAbs:hover {
  background: linear-gradient(66deg, #607999 50%, #cbd7e6 50%);
}

.snakeLastHalfOffAbs:hover {
  background: linear-gradient(246deg, #607999 50%, #cbd7e6 50%);
}

.snakeLastHalfOffHovered {
  background: linear-gradient(246deg, #85dafc 50%, #cbd7e6 50%) !important;
}

.snakeFirstHalfOffHovered {
  background: linear-gradient(66deg, #85dafc 50%, #cbd7e6 50%) !important;
}

.pHolNameSnakeItem {
  color: #9bc4f4;
  min-width: 96%;
  min-height: 96%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: 1px solid #9bc4f4;
  font-weight: 500;
  cursor: pointer;
}

.pHolNameSnakeItem:hover {
  background-color: #e0ebf8;
}

.specDayNameSnakeItem {
  color: #8ea7c5;
  min-width: 96%;
  min-height: 96%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: 1px solid #8ea7c5;
  font-weight: 500;
  cursor: pointer;
}

.nullSnake {
  cursor: default;
}

.nullSnake:hover {
  background: none;
  cursor: default;
}

.currMcal {
  border-left: 3px solid #0077ff;
  padding-left: 9px;
  min-width: calc(43% + 8px);
  margin-left: 30px;
}

.currMcalMob {
  border-left: 3px solid #0077ff;
  padding-left: 9px;
  min-width: calc(43% + 8px);
  margin-left: 13px;
}

.forceCalTxtSmall {
  font-size: 12px !important;
}

.monthCalHeaderTitleCurr {
  padding-left: 0px;
  border: none;
  color: #0077ff;
}

.todayCalSnake {
  padding-top: 1px;
  border: 3px solid #0077ff !important;
  border-radius: 4px;
}

.nonMobLoadingCal {
  align-items: flex-start;
  justify-content: center;
}

.closedCalLtr {
  background-color: #e8eeff;
  border: 1px solid #ebf0f7;
  border-radius: 2px;
}

.closedCalLtrPost:hover {
  background-color: #e8eeff;
  border: 1px solid #ebf0f7;
  border-radius: 2px;
}

.showDayModalBox {
  background-color: #fff;
  color: #143051;
  border-radius: 10px;

  min-width: 340px;
  max-width: 340px;
  /* -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  cursor: auto;
  /* min-height: 350px; */
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.showDayModalHeader {
  width: 100%;
  height: 58px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 7%;
  padding-right: 7%;
}

.closeShowDayModalImg {
  max-width: 15px;
  cursor: pointer;
}

.closeShowDayModalImg:hover {
  opacity: 0.8;
}

.showDayHeaderTitle {
  font-weight: 500;
}

.showDayModalBody {
  background-color: #f4f8fe;
  min-height: 93px;
  width: 90%;
  margin-left: 5%;
  border: solid 1px #e6eaee;
  border-top: solid 2px #e6eaee;
  border-radius: 5px 5px 0 0;
  max-height: 450px;
  overflow-y: auto;
}

.showDayModalFooter {
  display: flex;
  justify-content: space-between;
  min-height: 50px;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
}

.closeShowDayBtnTxt {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
}

.closeShowDayBtnTxt:hover {
  background-color: #214978;
}

.showDayAddLeaveBtn {
  background-color: #0077ff;
  border: 1px solid #0469de;
  color: white;
  font-weight: 500;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  cursor: pointer;
  /* animation-name: zoomBtnIn !important; */
  /* animation-duration: 0.1s !important; */
  /* margin-top: 7px; */
  /* margin-left: 9px; */
}

.showDayAddLeaveBtn:hover {
  background-color: #0469de;
}

.showDayList {
  background-color: #f4f8fe;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: auto;
  max-height: 310px;
  min-height: 90px;
}

.showDayListPHolSDay {
  background-color: #214978;
  border-radius: 5px;
  border: 1px solid #143051;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  border-left: 3px solid #143051;
  margin-top: 10px;
}

.showDayShiftUnit {
  background-color: white;
  border-radius: 5px;
  border: 1px solid #dde9f9;
  color: #859dbb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  border-left: 3px solid #214978;
  margin-top: 10px;
}

.pHolSDayTitle {
  font-weight: 500;
  margin-left: 10px;
  text-align: left;
  line-height: 15px;
  padding-top: 4px;
  max-width: 50%;
  color: #143051;
  min-width: 154px;
  padding-left: 2px;
  font-size: 13px;
}

.lineHeight18 {
  line-height: 17px;
  margin-top: -4px;
}

.showDayPholClosed {
  font-size: 11px;
  margin-right: 10px;
  color: #bbcada;
  line-height: 16px;
  text-align: right;
}

.viewPholGhost {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  padding: 4px;
  padding-left: 7px;
  padding-right: 7px;
  /* margin-top: 6px; */
  margin-right: 10px;
  min-height: 35px;
  display: flex;
  align-items: center;
  min-width: 60px;
  justify-content: center;
}

.viewPholGhost:hover {
  background-color: #bbcada;
}

.pHolSpecDayRightWithGhostDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.pHolSdayClosedSpan {
  /* font-weight:500; */
}

.showGenOfferModal {
  background-color: #f4f8fe;
  position: fixed;
  bottom: 100px;
  right: 80px;
  width: 340px;
  /* min-height: 120px; */
  border-radius: 5px;
  -webkit-animation-name: sideShowGenIn !important;
          animation-name: sideShowGenIn !important;
  -webkit-animation-duration: 0.3s !important;
          animation-duration: 0.3s !important;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  border-left: 4px solid #e999eb;
  cursor: auto;
}

.showGenOfferModalMob {
  background-color: #f4f8fe;
  position: fixed;
  bottom: 0px;
  width: 100%;
  /* right: 80px; */
  /* width:340px; */
  /* min-height: 120px; */
  border-radius: 5px 5px 0 0;
  -webkit-animation-name: sideShowGenUp !important;
          animation-name: sideShowGenUp !important;
  -webkit-animation-duration: 0.2s !important;
          animation-duration: 0.2s !important;
  /* zoomies */
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  /* border-left: 4px solid #e999eb; */
  cursor: auto;
  border-radius:15px 15px 0 0;
}

.showGenGenBtnColours {
  background-color: #e999eb;
  border-color: #bc77be;
}

.showGenGenBtnColours:hover {
  background-color: #bc77be;
}

.showGenUnderlayFadeIn {
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
}

.showGenBtnDiv {
  display: flex;
  /* min-width: 100%; */
  max-width: 100%;
  padding-left: 14px;
  padding-right: 14px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  -webkit-backdrop-filter: blur(3px);
  /* backdrop-filter: blur(3px); */
  /* background-color: rgb(119 119 119 / 50%); */
}

@-webkit-keyframes sideShowGenUp {
  from {
    bottom: -300px;
  }
  to {
    bottom: 0px;
  }
}

@keyframes sideShowGenUp {
  from {
    bottom: -300px;
  }
  to {
    bottom: 0px;
  }
}

@-webkit-keyframes sideShowGenIn {
  from {
    right: -300px;
  }
  to {
    right: 80px;
  }
}

@keyframes sideShowGenIn {
  from {
    right: -300px;
  }
  to {
    right: 80px;
  }
}

.showGenInfotTxt {
  margin-bottom: 10px;
  text-align: center;
  /* font-size: 12px; */
  line-height: 18px;
  font-weight: 500;
  color: #143051;
  text-align: left;
  font-size: 14px;

  /* margin-top:14px; */
  /* margin-left:14px; */
  padding-right: 14px;
  /* margin-bottom:20px; */
}

.showGenName {
  color: #bbcada;
  font-weight: 500;
  border-left: #00ccff 2px solid;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 10px;
  margin-left: 30px;
  margin-bottom: 10px;
  font-size: 14px;
}

.showGenHeader {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 14px;
  padding-right: 14px;
  margin-top: 14px;
  margin-bottom: 10px;
}

.showGenLightning {
  width: 70px;
  margin-top: 4px;
}

.unpubClick {
  cursor: pointer !important;
}

.unpubClick:hover {
  background-color: #6b8fa8;
}

.showDayShiftTimes {
  font-size: 12px;
  color: #bbcada;
}

.tilTxtSize {
  /* max-width: 80%; */
}

.tilUnitShowDay {
  /* border-left:3px solid #bbcada */
}

.showDayNone {
  color: #bbcada;
  font-weight: 500;
  margin-top: 36px;
  font-size: 14px;
}

.showDayTeamName {
  font-weight: 500;
  color: #bbcada;
  font-size: 12px;
  /* opacity: 0.8; */
}

.loadingInbetweenBalls {
  margin: 0 auto;
  position: fixed;
  top: 30%;
  left: 50%;
}

.leaveShowDayUnit {
  border-left: 3px solid #00ccff;
}

.absLeaveShowDayUnit {
  border-left: 3px solid #bbcada;
}

.border143051 {
  border-color: #143051;
}

.halfLeaveShowDay {
  background-color: #e0ebf8;
  color: #214978;
  font-size: 10px;
  border-radius: 3px;
  padding-left: 4px;
  padding-right: 4px;
}

.calTitle {
  margin-top: 10px !important;
}

.myCalPageDataBoxTitle {
  font-weight: 500;
  color: white;
  font-size: 16px;
  /* border-left: 3px solid white; */
  padding-left: 10px;
}

.myCalPageDataBoxTitleMob {
  /* font-weight:600; */
  /* color:white; */
  /* font-size:18px; */
  border-left: none;
  padding-left: 0px;
  margin-right: 22px;
}

.selCollHolder {
  margin: 0 auto;
}

.collCalUserDropdown {
  min-width: 220px;
  min-height: 35px;
  background-color: blue;
  max-width: 220px;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #143051;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 195px;
  font-weight: 500;
  font-size: 14px;
  padding-left: 10px;
  line-height: 18px;
}

.collCalUserInfoDiv {
  height: 50px;
  display: flex;
  justify-content: space-between;
  border: 2px solid #e6eaee;
  max-width: 90%;
  margin-left: 5%;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #f5f9ff;
}

.collCalTeamTitle {
  color: #214978;
  font-size: 10px;
  font-weight: 400;
  margin-top: 7px;
  margin-left: 10px;
  text-align: left;
}

.collCalTeamValue {
  font-weight: 500;
  color: #143051;
  font-size: 16px;
  margin-left: 10px;
  text-align: left;
  margin-top: -1px;
  /* max-width: 170px; */
}

.viewCollCalBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 4px;
  color: white;
  font-weight: 500;
  font-size: 12px;
  min-height: 24px;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
  /* margin-top: 10px; */
  /* margin-right: 12px; */
}

.viewCollCalBtn:hover {
  background-color: #bbcada;
}

.wklySchedPopUp {
  max-width: 12px;
  margin-left: 5px;
}

.collCalWorkingToday {
  min-width: 100px;
  cursor: pointer;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 500;
  color: #859dbb;
  padding-left: 4px;
  padding-right: 4px;
  margin-top: -7px;
  margin-bottom: -1px;
}

.collCalWorkingToday:hover {
  background-color: #e0ebf8;
  border-radius: 3px;
}

.workBetTodaySpan {
  padding-left: 5px;
  color: #0077ff;
}

.colCalInfoRight {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  margin-right: 12px;
}

.checkerDiv {
  height: 35px;
  display: flex;
  justify-content: space-between;
  border-left: 2px solid #143051;
  max-width: 90%;
  margin-left: 5%;
  margin-top: 15px;
  margin-bottom: 15px;
  align-items: center;
}

.checkDivTitle {
  font-weight: 400;
  color: #516b8b;
  text-align: left;
  line-height: 18px;
  /* padding: 12px; */
  font-size: 12px;
  padding-left: 10px;
  line-height: 16px;
}

.myShPendingChLeftBorder {
  border-left: 3px solid #00ccff;
}

.pendingMgrShChangeItem {
  border-left: 3px solid #965ade;
}

.alignStartx {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap !important;
  min-height: calc(100vh - 155px);
}

.leaveLimInfoTxt {
  color: #516b8b;
  font-size: 12px;
  font-weight: 400;
  padding-left: 6%;
  padding-right: 5%;
  text-align: left;
  line-height: 16px;
  margin-top: 10px;
}

.yourPTeamIs {
  color: #0af;
  font-size: 14px;
  font-weight: 500;
  padding-left: 6%;
  padding-right: 5%;
  text-align: left;
  margin-top: 0px;
}

.highlightSelCollDrop {
  border-color: #0cf;
}

.leaveCheckParent {
  border-top: 1px solid #bbcada;
  min-width: 90%;
  margin-left: 5%;
  margin-top: 20px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 90%;
}
.leaveCheckFlexUnit {
}

.leaveCheckStartTitle {
  font-size: 12px;
  font-weight: 500;
  color: #516b8b;
  text-align: left;
  margin-bottom: 10px;
  margin-left: 8px;
}

.leaveCheckEndTitle {
  font-size: 12px;
  font-weight: 500;
  color: #516b8b;
  text-align: right;
  margin-bottom: 10px;
  margin-right: 8px;
}

.invalidLimitDates {
  pointer-events: none;
  opacity: 0.4;
}

.checkLimitBtn {
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 78px;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #0bf;
  border: 1px solid #0af;
  /* margin-left: 5%; */
  /* margin-top: 13px; */
}

.checkLimitBtn:hover {
  background-color: #0af;
}
.limitMagniImg {
  max-width: 12px;
  margin-left: 6px;
}

.loadinglimBlueLoader {
  max-width: 50px;
  margin-top: 10px;
  margin-left: 6%;
}

.limitCheckDateInput {
  border-radius: 5px;
  border: 1px solid #e6eaee;
  background-color: #fff;
  color: #496375;
  min-height: 35px;
  font-weight: 500;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 8px;
  padding-top: 0px;
  letter-spacing: 1px;
  max-width: 157px;
  min-width: 157px;
  background: url(/static/media/calicongrey.9c7e1aba.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 127px;
  cursor: text;
}

.checkLimsArrDiv {
  min-width: 100% !important;
}

.checkParent {
  /* background-color: blue; */
  /* overflow: auto; */
  /* min-height: calc(100vh - 150px);  */
}

.checkBtnAndResultDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 90%;
  margin-left: 5%;
  margin-top: 16px;
}

.checkExceedBtn {
  font-weight: 500;
  background-color: #e3e8ee;
  color: #4f566b;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  border: 1px solid #bbcada;
  font-size: 14px;
  border-left: #ba0000 3px solid;
  cursor: pointer;
}

.checkExceedBtn:hover {
  background-color: #d7dee5;
}

.exceedsLimitsPopUp3 {
  max-width: 16px;
  margin-left: 4px;
}

.pHolDataRowTitle {
  margin-left: -6px;
}

.exceedsNoLimits {
  font-size: 14px;
  font-weight: 500;
  color: #516b8b;
  display: flex;
  align-items: center;
  /* border-right: 3px solid #6db451; */
  /* padding-right: 12px; */
}

.noExceedTickImg {
  max-width: 20px;
  margin-left: 10px;
}

.colour00aaff {
  color: #00aaff !important;
}

.staffCalJTcolumn {
  border-right: 3px solid #bac5d3;
  max-width: 120px;
  padding-bottom: 16px;
}

.staffCalUsrBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* margin-top: 8px; */
  height: 38px;
  /* border-radius: 4px 0 0 4px; */
  padding-left: 2px;
  cursor: pointer;
  border-bottom: 1px solid #dde9f9;
}

.staffCalUsrBox:hover {
  background-color: #dde9f9;
}
.staffCalProPic {
  min-width: 30px;
  max-width: 30px;

  height: 30px;
  object-fit: cover;
  border-radius: 3px;
  /* border:1px solid #214978 */
}

.staffCalUsrName {
  font-size: 12px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: left;
  font-weight: 500;
  color: #143051;
  line-height: 13px;
}

.staffCalJtDivider {
  min-height: 2px;
  min-width: 100%;
  background-color: #95aaba;
  opacity: 0.15;
  margin-top: 10px;
}

.staffCalJtDivider2 {
  min-height: 2px;
  min-width: 100%;
  background-color: #95aaba;
  opacity: 0.15;
  margin-top: 10px;
}
.staffCalBody {
  min-width: calc(100% - 133px);
  max-width: calc(100% - 133px);
  padding-bottom: 8px;
  overflow-x: auto;
  padding-right: 20px;
  padding-top: 5px;
}

.staffCalUsrSnakeDiv {
  display: flex;
  min-height: 38px;
  /* padding-top:7px; */
  align-items: center;
  padding-left: 10px;
  border-top: 1px solid #dde9f9;
  /* background-color: yellow; */
  /* border-top:1px solid red; */
}

.staffCalUsrSnakeDivDayStrip {
  display: flex;
  min-height: 36px;
  align-items: center;
  padding-left: 10px;
}

.staffCalTeamSnakeBlock {
  min-width: 40px;
  color: #516b8b;
  font-weight: 500;
  font-size: 14px;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: -2px; */
  cursor: pointer;
  /* border-radius:3px; */
}

.staffCalTeamSnakeBlock:hover {
  /* background-color: #e8eef3; */
}

.staffCalJtItem {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.paddingLeft10 {
  padding-left: 6px;
  padding-right: 10px;
}

.paddingLeft4 {
  padding-left: 4px;
}

.staffCalDayLtrItem {
  /* text-transform: uppercase; */
  cursor: default;
  color: #a1b7cf;
  cursor: pointer;

}

@media only screen and (min-width: 820px) {

.staffCalDayLtrItem:hover {
  background-color: #fff !important;
}

.closedStaffCalBlockBg:hover {
  background-color: #d7dae4;
}

.isMgrClosedStaffCalBlockBg:hover {
  background-color: #e8eeff;
}
.closedHoverer:hover {
  background-color: #d5ddf2;
}
}

.closedStaffCalBlockBg {
  background-color: #d7dae4;
  border-radius: 0px;
}


.closedStaffCalBlockBgPhol {
  background-color: #e3e8f7;
  border-radius: 0px;
  cursor: default;
}

.closedHoverer {
  cursor: pointer;
}


.hoveredUserCalBg {
  background-color: #fff;
}
.pHolsRowA1 {
  min-height: 40.5px;
  max-height: 40.5px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  background-color: #504588;
    color: white;
    /* min-height: 30px; */
    /* max-height: 30px; */
    /* margin-top: 9px; */
    /* margin-bottom: 0px; */
    border-radius: 5px 0 0 5px;
    border-top: 2px solid #143051;
    border-bottom: 2px solid #143051;
    border-left: 2px solid #143051;
    display:flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding-left: 10px;
    padding-right: 8px;
  
}

.limEnableNoComms {
min-height:66.5px;
max-height:66.5px;

}
.pHolsRowA1LimitEnabled {
  /* background-color: yellow; */
  min-height:40.5px !important;
  max-height:40.5px !important

}
.pHolStaffCalRow {
  display: flex;
  min-height: 10px;
  align-items: center;
  margin-left: 10px;
  background: #e5e9f0;
  padding-top: 2px;
  padding-bottom: 2px;
}

.phSnakeItem {
  min-width: 40px;
  font-size: 8px;
  font-weight: 500;
  color: #8ea7c5;
  min-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid #8ea7c5;
  border-radius: 3px;
  cursor: pointer;
}

.phSnakeItem:hover {
  background-color: #f4f8fe;
}

.noItemsSnakeBlock {
  /* cursor: default; */
  /* cursor: copy; */
  /* pointer-events: none; */
}


.reqExistsSnakeBlock {
  background-color: #00ccff !important;
  color: white;
  border-top: 1px solid #00b9fb;
  border-bottom: 1px solid #00b9fb;
}

.hoveredStaffCalLeave {
  background-color: #85dafc !important;
}

.reqExistsSnakeBlockAbsence {
  background-color: #516b8b !important;
  border-top: 1px solid #435b78 !important;
  border-bottom: 1px solid #435b78 !important;

}

.hoveredStaffCalAbsence {
  background-color: #6484aa !important;
}

.firstOfSeq {
  border-radius: 5px 0 0 5px;
  border-left: 1px solid #00b9fb;
}


.lastOfSeq {
  border-radius: 0px 5px 5px 0px;
  border-right: 1px solid #00b9fb;
}

.soloSeq {
  border-radius: 5px;
  border: 1px solid #00b9fb
}

.soloSeqAbs {
  border-radius: 5px;
  border: 1px solid #435b78
}



.firstOfSeqAbs {
  border-left: 1px solid #435b78;

}

.lastOfSeqAbs {
  border-right: 1px solid #435b78;

}

.todayStaffCalSnake {
  background-color: #eef8ff;
  /* border-left: 2px solid #dde9f9; */
  /* border-right: 2px solid #dde9f9; */
}

.todayStaffCalSnake:hover {
  /* background-color: white  !important; */
}
.staffCalTypeDropdown {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 10px;
  text-align: left !important;
  max-width: 230px;
  min-width: 230px;
  line-height: 26px;
  height: 35px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border-color: #2c7eb9;
  border: 1px solid #e6eaee;
  /* background-color: #0bf; */
  background-color: white;
  color: #143051;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 204px;
}

.staffCalTypeDropdown:hover {
  border-color: #d7dfe9;
}

.staffCalKeyDiv {
  display: flex;
  justify-items: space-between;
  align-items: center;
  margin-right: 20px;
}

.staffCalKeyItem {
  display: flex;
  justify-items: flex-start;
  align-items: center;
  color: #bbcada;
  min-height: 22px;
  padding-left: 6px;
  padding-right: 6px;
  cursor: pointer;
}

.staffCalKeyItem:hover {
  background-color: #ecf0f3;
  min-height: 22px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 3px;
  color: #214978;
}

.staffCalKeyItem:nth-child(2) {
  margin-left: 16px;
}
.keyItemBlobAbsence {
  min-width: 8px;
  min-height: 8px;
  background-color: #516b8b;
  border-radius: 2px;
}

.keyItemBlobLeave {
  min-width: 8px;
  min-height: 8px;
  background-color: #00ccff;
  border-radius: 2px;
}

.keyItemTxt {
  font-weight: 500;
  font-size: 12px;
  margin-left: 6px;
}

.colour516b8b {
  color: #516b8b !important;
}

.marginLeft5px {
  margin-left: 5px;
}

.staffCalCommentIcon {
  max-width: 9px;
  margin-right: 5px;
  margin-top: 1px;
}

.teamCalHolder {
  display: flex;
  /* background-color: blue; */
  min-width: 100%;
  max-width: 100%;
}

.marginLeft2pc {
  margin-left: 2% !important;
  min-width: 100%;
}

.absBoxTeamCal {
  min-height: calc(100vh - 192px);
  max-height: calc(100vh - 192px);
  align-items: flex-start;
  border-top: 2px solid #e6eaee;
  margin-top: 4px;
  padding-top: 0px;
}

.calCommDiv {
  display: flex;
  font-size: 11px;
  line-height: 12px;
}

.colour0077ff {
  color: #0077ff !important;
}

.meUserTeamCal {
  background-color: #ffffff;
  border-bottom: 1px solid #c0e8ff;
  border-top: 1px solid #c0e8ff;
  border-left: 1px solid #c0e8ff;
  /* border-top:1px solid #143051; */
  /* border-bottom:1px solid #143051; */
}

.meUserTeamCal:hover {
  background-color: #f4f9ff !important;
}

.meTeamCalRow {
  background-color: #ffffff !important;
  border-bottom: 1px solid #c0e8ff;
  border-top: 1px solid #c0e8ff;
  border-right: 1px solid #c0e8ff;
  border-radius: 0 4px 4px 0;
}

.name-s {
  font-size: 10px !important;
  /* margin-left: -5px; */
  margin-right: 5px;
}



.staffCalDesktopTitle {
  margin-top: 6px;
}

.desktopStaffCalTeamsMenu {
  margin-top: -7px;
}

.c22 {
  margin: 35px auto;
}

.mobTeamSelectLeft {
  position: fixed;
  z-index: 999;
  /* left:17px; */
  top: 103px;
}

.viewTeamDayRow {
  /* background-color: blue; */
  max-height: 40px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  display: flex;
  align-content: center;
  padding-left: 10px;
  display: none;
}

.viewTeamDivItem {
  
    max-height: 22px;
    min-height:22px;
    
    margin-left: 4px;
    margin-right: 4px;
    min-width: 32px;
    max-width: 32px;
    background-color: #214978;
    border: 1px solid #143051;
    border-radius: 3px;
    margin-bottom: 4px;
    cursor: pointer;
    line-height: 9px;
    padding-top: 1px;
    padding-bottom: 1px;
    color: white;
    font-weight: 500;
    font-size: 9px;
    display: flex;
    align-items: center;
    padding-left:3px;
    padding-right:3px;
}

.viewTeamDivItem:hover {
  background-color: #143051;
}

.viewTeamDayPopUpImg {
  max-width: 12px;
}

.viewTeamDayUserRow {
  /* background-color:orange */
}

.ssss {
  /* background-color: orange !important; */
}

.staffCalBreakTeams {
  margin-top: 0px !important;
  margin-left: -1px;}

  .staffCalBreakTeamsRota {
    margin-top:-6px;
  }
.viewTeamJTrow {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  /* background-color: blue; */
  border-bottom: 2px solid #143051;
}

.viewTeamJTrow:last-child {
  border-bottom: none;
}

.viewTeamDayUserRowJt {
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 30px;
  color: #b764eb;
  margin-bottom: 8px;
  margin-top: 4px;
}

.viewTeamJtUserRow {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
  border-bottom: 1px solid #bbcada;
  padding-top: 4px;
  padding-bottom: 10px;
  padding-left: 4px;
  /* justify-content: space-between; */
}

.viewTeamJtLeftUser {
  display: flex;
  align-items: center;
  min-width: 130px;
  max-width: 130px;
}

.viewTeamDayProPicImg {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 3px;
}

.viewTeamUserName {
  color: #143051;
  font-weight: 500;
  font-size: 12px;
  text-align: left;
  line-height: 16px;
  margin-left: 4px;
}

.viewTeamInitials {
  color: white;
  background-color: #214978;
  border: 1px solid #143051;
  border-radius: 3px;
  min-width: 30px;
  max-width: 30px;
  font-size: 12px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  cursor: pointer;
  padding-bottom: 1px;
}

.viewTeamInitials:hover {
  background-color: #143051;
}

.viewTeamJtRightUserItems {
  border-left: 1px solid #e0ebf8;
  margin-left: 4px;
  padding-left: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: flex-start; */
  /* display: flex; */
  /* align-items: center; */
}

.viewTeamItemShift {
  background-color: #fff;
  border: 1px solid #bbcada;
  border-radius: 4px;
  color: #143051;
  font-weight: 500;
  text-align: left;
  font-size: 12px;
  padding: 5px;
  padding-right: 20px;
  padding-left: 7px;
  cursor: pointer;
  margin-top: 4px;
  /* padding-bottom:1px; */
  /* padding-top:4px; */
}

.viewTeamItemShift:hover {
  border: #214978 2px solid;
  padding: 4px;
  padding-right: 19px;
  padding-left: 6px;
}

.viewTeamShiftReq {
  background-color: #0077ff;
  color: white;
  border: #214978 2px solid;
  padding: 4px;
  padding-right: 19px;
  padding-left: 6px;
}

.viewTeamShiftReq:hover {
  border: #214978 2px solid;
  padding: 4px;
  padding-right: 19px;
  padding-left: 6px;
  background-color: #0077cc;
}

.disableOpenReq {
  cursor: default;
  pointer-events: none;
}

.disableOpenReq:hover {
  background-color: #fff;
  border: 1px solid #bbcada;
  border-radius: 4px;
  color: #214978;
  font-weight: 500;
  text-align: left;
  font-size: 12px;
  padding: 5px;
  padding-right: 20px;
  padding-left: 7px;
  cursor: pointer;
  margin-top: 4px;
}

.viewTeamRotaClock {
  max-width: 10px;
  margin-right: 4px;
  opacity: 0.5;
}

.viewTeamRotaBreak {
  max-width: 10px;
  margin-right: 4px;
  opacity: 0.5;
}

.viewTeamItemRowParent {
  margin-top: 4px;
}

.viewTeamItemRow {
  display: flex;
  align-items: center;
  font-size: 10px;
  margin-bottom: 1px;
}

.viewTeamItemTitle {
  font-weight: 500;
  padding-left: 4px;
  border-left: 2px solid #143051;
}

.viewTeamNil {
  color: #bbcada;
  font-weight: 500;
  margin-left: 9px;
  font-size: 12px;
  text-align: left;
  line-height: 30px;
}

.whiteBorderLeft {
  border-left: 2px solid white;
  padding-left: 4px;
}

.myShiftChangeArrUnit {
  margin-bottom: 8px;
  background-color: #f4f8fe;
  border-radius: 5px;
  border: 1px solid #e6eaee !important;
}

.pointerUpcItem {
  cursor: pointer;
}

.pointerUpcItem:hover {
  border-color: #456c9b !important;
}

/* 
.noScroll {

} */

.staffAbsHeader {
  margin-top: -9px;
  min-height: 35px;
}

.desktopStaffAbsHeader {
  margin-top: 7px;
}

.myAbsBtnTxt {
  margin-top: -1px;
}

.myHoursStatsImgOnly {
  margin-right: 5px;
}

.absTeamHeaderDropdown {
  margin-top: 3px;
}

.ndStRdSpan {
  font-size: 7px;
  margin-top: -5px;
}

.ndStRdSpan2 {
  font-size: 10px;
  margin-right: 3px;
}

.ndStRdSpan3 {
  font-size: 10px;
  margin-right: 3px;
  margin-top:-7px;
}
.todayWhiteBorder {
  border: 2px solid white !important;
}

.todayWhiteBorder:hover {
  border: 2px solid #90a7bf !important;
}

.offerClockOn {
  color: #143051;
  font-weight: 500;
  font-size: 18px;
  margin-top: 8px;
  margin-bottom: 4px;
  /* line-height:22px; */
}

.clockOnNowBtn {
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: 78px;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #07f;
  border: #0469de 1px solid;
  font-weight: 500;
  margin-top: 10px;
}

.clockOnNowBtn:hover {
  background-color: #036ce4;
  margin-top: 10px;
}

.marginBottom4 {
  margin-bottom: 4px !important;
}

.timesheetStartDateInput {
  border-color: #e6eaee !important;
  margin-right: 10px !important;
  color: #6a8bb1 !important;
  font-size: 16px !important;
  background-position-x: 126px !important;
  min-width: 161px !important;
  max-width: 161px !important;
}
.timesheetStartDateInput:hover {
  border-color: #d7dfe9 !important;
}

.marginTopMinus12 {
  margin-top: -12px !important;
}

.timesheetDateDay {
  font-size: 12px;
  font-weight: 500;
  color: #bbcada;
  margin-right: 40px;
  margin-top: 10px;
  margin-bottom: -16px;
  display: flex;
}

.tsDayLine {
  max-width: 6px;
  margin-left: 3px;
  margin-top: 6px;
}

.flexCenter {
  justify-content: center !important;
}

.flexCenter2 {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.understoodBtn {
  min-width: 100% !important;
  color: white !important;
}

.editTimeSpecificBtn {
  color: white;
  font-weight: 500;
  font-size: 12px;
  margin-left: 18px;
  margin-top: -1px;
  cursor: pointer;
  background-color: #d8c3e2;
  max-height: 24px;
  max-width: 114px;
  /* margin-top: 8px; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 6px;
  padding-right: 2px;
  border-radius: 4px;
  margin-bottom: 7px;
  border: 1px solid #d8c3e2;
  min-width: 114px;
  min-height:26px;
  /* color:blue; */
  line-height:10px
  /* font-size:49px */
}

.editTimeSpecificBtn:hover {
  background-color: #e6c8f4;
}

.specTimesModeImg {
  max-width: 18px;
  margin-left: 2px;
}

.timePickerDropper {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 72px;
  min-width: 72px;
  line-height: 26px;
  height: 35px;
  /* border: 2px solid #e6eaee; */
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 2px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 52px;
}

.timePickerDropper:hover {
  border-color: #d7dfe9;
}

.timesheetBody {
  /* background-color: yellow; */
  /* border-top: 4px solid #96e578; */
  border-top: 4px solid #bbcada;

  border-radius: 6px 6px 0 0;
  max-width: 90%;
  margin-left: 5%;
  max-height: calc(100vh - 384px) !important;
  overflow-y: auto;
}

.viewTimesheetBody {
  /* background-color: yellow; */
  /* border-top: 4px solid #96e578; */
  /* border-top: 4px solid #bbcada; */
  border-top: 1px solid #143051;
  /* border-radius: 6px 6px 0 0; */
  max-width: 90%;
  margin-left: 5%;
  max-height: calc(100vh - 404px) !important;
  overflow-y: auto;
}

.timesheetDsItem {
  /* background-color:blue; */
  cursor: pointer;
  min-height: 70px;
  padding-bottom: 20px;
  border-bottom: 2px solid #cbd7e6;
}

.timesheetDsItem2 {
  min-height: 70px;
  padding-bottom: 20px;
  border: 2px solid #214978;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 10px;
  background-color: #f8f7ff;
}
.timesheetDsItem:hover {
  background-color: #edf3f9;
}

.timesheetDsItem2User {
  background-color: #f4f8fe;

}

.timesheetDsItem2User:hover {
  background-color: #f4f8fe;

}

.timesheetDsItem2:last-child {
  /* border-bottom: none !important; */
  margin-bottom: 10px;
}

.timesheetActiveDs {
  background-color: white;
  cursor: default;
  min-height: 70px;
}

.timesheetActiveDs:hover {
  background-color: white;
}

.tsPower {
  max-width: 28px;
  min-width: 28px;
  background-color: #96e578;
  border: 1px solid #88d869;
  border-radius: 5px;
  /* margin-left: -4px; */
  cursor: pointer;
}
.tsPower:hover {
  background-color: #88d869;
}

.tsPowerOff {
  background-color: #bbcada;
  border-color: #8ea7c5;
}

.tsPowerOff:hover {
  background-color: #8ea7c5;
}

.tsItemHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  margin-top: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
}

.tsItemTitle {
  color: #214978;
  font-weight: 500;
  font-size: 14px;
  border-left: 2px solid #214978;
  padding-left: 10px;
  margin-bottom: -4px;
  min-height: 24px;
  padding-top: 0px;
}

.tsClocksTitle {
  font-weight: 400;
  color: #bbcada;
  font-size: 12px;
  text-align: left;
  margin-top: 15px;
  margin-left: 12px;
}

.tsClockItem {
  min-height: 50px;
  background-color: #f4f8fe;
  max-width: 78%;
  max-width: 90%;
  margin-left: 12px;
  margin-top: 3px;
  margin-bottom: 12px;

  text-align: left;
  padding-left: 6px;
  font-weight: 400;
  font-size: 12px;
  color: #bbcada;
  border-left: 2px solid #e6eaee;
  padding-left: 9px;
  padding-top: 6px;
  border: 2px solid #e6eaee;
  border-radius: 5px;
  padding-bottom: 4px;
  padding-right: 10px;
}
.tsSchedItem {

}

.tsClocks {
}

.tsClockOnVal {
  font-size: 14px;
  font-weight: 500;
  margin-top: 2px;
}

.clockAlreadyRecords {
  text-align: left;
  font-size: 11px;
  color: #bbcada;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  font-weight: 500;
  margin-left: 12px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-left: 2px solid #00aaff !important;
  padding-left: 8px;
}

.inactiveTsItemTitle {
  color: #bbcada;
}

.decClock {
  color: #e89292 !important;
}

.appClock {
  color: #a8cc9a !important;
}

.otherTeamShiftBox {
  max-width: 114px !important;
  min-width: 114px !important;
  padding-left: 8px !important;
  background: none !important;
  box-shadow: none !important;
  opacity: 1;
  border: 1px #8ea7c5 dotted !important;
  cursor: default;
  pointer-events: none;

  border-spacing: 4px !important;
}

.otherTeam {
  color: #8ea7c5 !important;
  line-height: 10px;
  margin-top: 3px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}
.otherTeamTimes {
  color: #8ea7c5 !important;
}

.myCalDropAndRightBtnContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 136px;
}

.outstandingWithTagsHeight {
  /* background:pink;   */
  min-height: 91px !important;
}

.tagsAndOutstandingCh {
  margin-top: -74px;
}

.unlinkedBorder {
  border: 2px #ba0000 dashed !important;
}

.genMyCal {
  min-width: 90%;
  margin-left: 5%;
  border-radius: 6px;
  padding-top: 15px;

}

.myCalItem {

  min-height: 50px;
  display: flex;
}

.myCalItemDs {
  background-color: white;
  min-width: 60px;
  min-height: 100%;
  font-weight: 500;
  border-bottom: 1px solid #e0ebf8;
  padding-top: 10px;
}

.myCalItemDsFirst {
  border-radius: 6px 0 0 0;
}

.myCalItemDsDay {
  color: #bbcada;
  font-size: 12px;
}

.myCalItemDsNum {
  color: #bbcada;
  margin-bottom: 5px;
  font-size: 24px;
}

.myCalItemList {
  border-left: 1px solid #e0ebf8;
  border-bottom: 1px solid #e0ebf8;
  min-width: calc(100% - 60px);
  padding-bottom: 10px;
}

.myCalItemAvailRow {
  background-color: white;
  min-width: 91%;
  max-width: 91%;
  margin-left: 3%;
  border-radius: 5px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  border: 1px solid #e9f1fa;
}
@media only screen and (min-width: 820px) {
  .noItemsSnakeBlock:hover {
    background-color: #d7eaff;
  }
  
  .myJtUserShiftBox:hover {
    padding-left: 2px !important;
    padding-right: 2px !important;
    background-color: #6274c5;
  }
  .myJtUserShiftBoxLeave:hover {
    background: #f4f8fe;
    padding-right:4px !important
  }
.myCalItemAvailRow:hover {
  border-color: #bbcada;
}

.unavailItemCal:hover {
  background-color: #f2e8e8;
}

.availItemCal:hover {
  background-color: #dcf1d3;
}
.noAvailSubmitted:hover {
  background-color: #f2f5fb;
}

}
.specPHolRow {
  background-color: #7fb0e4;
  color: white;
  text-align: left;
  justify-content: flex-start;
  padding-left: 10px;
}
.specPHolRow:hover {
  box-shadow: none !important;
  border-color:#e9f1fa !important
}

.unavailItemCal {
  background-color: #f9efef;
  color: #ba0000;
  min-width: 98%;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}




.availItemCal {
  background-color: #e1fad7;
  min-width: 98%;
  border-radius: 3px;
  color: #5a9e3f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}


.noAvailSubmitted {
  background-color: #f4f8fe;
  min-width: 98%;
  border-radius: 3px;
  color: #bbcada;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;
}
.myCalAvailItemImg {
  max-width: 10px;
  margin-left: 5px;
}

.availItemIllus {
  display: flex;

  justify-content: space-between;
  font-size: 10px;
  font-weight: 500;
}

.myCalUnvailTxt {
  margin-left: 8px;
  text-align: left;
  max-width: 140px;
  line-height: 9px;
}

.availItemTimeStrip {
  font-weight: 500;
  font-size: 10px;
  text-align: right;
  margin-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.availItemTimeStripRow {

}

.myCalDsClosed {
  opacity: 0.5;
}

.greyScale {
  -webkit-filter: grayscale(100%); /* Safari and Chrome */
  filter: grayscale(100%);
  opacity: 0.5;
}

.greyScaleHalf {
  -webkit-filter: grayscale(100%); /* Safari and Chrome */
  filter: grayscale(50%);
  opacity:1;
}

.marginTop7px {
  margin-top: 7px !important;
}

.x88 {
  flex-direction: column;
}

.removeFlex {
  display: block !important;
  min-height: 100px;
  max-width: 380px !important;
  min-width: 300px;
  float: right;

}

.showFilterCalBoxDesktop {
  min-height: calc(100vh - 307px) !important;
  max-height: calc(100vh - 307px) !important;
}

.showFilterCalBoxMob {
  min-height: calc(100vh - 366px) !important;
  max-height: calc(100vh - 366px) !important;
}

.availBtn {
  border: 1px solid #364c66 !important;
  background-color: #516b8b !important;
}

.borderRightNone {
  border-right: none !important;
}

.filtSplitContainer {
  background-color: blue;
}

.availFiltInactive {
  background-color: #516b8b;
  min-width: 7px;
  min-height: 7px;
  border-radius: 2px;
  margin-top: -20px;
  margin-right: -10px;
  margin-left: 2px;
}

.myCalShiftInner {
  display: flex;
  min-width: 98%;
  justify-content: space-between;
  align-items: center;
}
.availItemShiftLeft {
  /* background-color: blue; */
  display: flex;
  align-items: center;
}

.availItemShiftRight {
  margin-right: 10px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.myCalColourLightGreen {
  background-color: #bcdabb;
  min-width: 4px;
  border-radius: 5px;
  min-height: 35px;
  margin-right: 0px;
  margin-left: 5px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.myCalShiftTitle {
  font-weight: 500;
  color: #143051;
  font-size: 14px;
  text-align: left;
  padding-left: 4px;
  line-height:22px
  /* text-transform: capitalize; */
}
.myCalShiftTypeAndTags {
  margin-left: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 10px;
}

.calItemTagsContainer {
  display: flex;
  margin-top: -3px;
  padding-left: 8px;
}

.x197378124 {
  padding-left:0px
}
.marginLeft5 {
  margin-left: 5px !important;
}

.itemBoxShadow {
  cursor: pointer;
}

.myCalItemAvailRow:hover {

}

.availItemShiftTimesTop {
  color: #214978;
  font-size: 12px;
  font-weight: 500;
  text-align: right;
  margin-top: 2px;
  max-width: 104px;
  line-height: 13px;
}

.availItemShiftTimesLower {
  color: #bbcada;
  font-size: 11px;
  font-weight: 500;
  text-align: right;
  margin-top: 0px;
}

.myCalItemNote {
  max-width: 13px;
  margin-left: 3px;
  margin-bottom: -2px;
}

.orangeBg {
  background-color: #bbcada !important;
}

.myCalOpensArr {
  border-bottom: 1px solid #e0ebf8;
  padding-bottom: 10px;
  min-width: 90%;
  max-width: 90%;
  margin-left: 10%;
}

.myCalOpensAvailBtn {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
  max-width: 94%;
  margin-left: 3%;
  margin-top: 10px;
  border-radius: 5px;
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid #e2ebf5;
  cursor: pointer;
  color: #143051;
}

.openDownToggle {
  max-width: 8px;
  margin-right: 4px;
}

.rotateDownArr {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.myCalOpenDiv {
  display: flex;
  align-items: center;
}

.myCalOpenLight {
  background-color: #bbcada;
  min-width: 4px;
  min-height: 20px;
  border-radius: 5px;
  margin-right: 8px;
}
.claimedLight2 {
  background-color: #0af !important;
}
.openClaimed {
  background-color: #00ccff;
  border: 1px solid #0af;
  color: #fff;
  font-weight: 500;
  font-size: 10px;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 3px;
}

.marginLeftMinus5 {
  margin-left: -5px;
  margin-top: 2px;
  font-size: 10px;
}

.myCalLeaveInner {
  display: flex;
  min-width: 98%;
  justify-content: space-between;
  align-items: center;
  background-color: #e2f5ff;
  border-radius: 3px;
}

.annLeavePlaneIcon {
  max-width: 18px;
  margin-left: 8px;
  margin-right: -2px;
}

.myCalReqFirstHalfOff {
  background: rgb(226, 245, 255);
  background: linear-gradient(
    110deg,
    rgba(226, 245, 255, 1) 50%,
    rgba(255, 255, 255, 1) 50%
  );
}

.myCalReqLastHalfOff {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    250deg,
    rgba(226, 245, 255, 1) 50%,
    rgba(255, 255, 255, 1) 50%
  );
}

.absInnerFull {
  background-color: #d5dee3;
}

.myCalReqFirstHalfOffAbs {
  background: rgb(226, 245, 255);
  background: linear-gradient(
    110deg,
    rgba(213, 222, 227, 1) 50%,
    rgba(255, 255, 255, 1) 50%
  );
}

.myCalReqLastHalfOffAbs {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    250deg,
    rgba(213, 222, 227, 1) 50%,
    rgba(255, 255, 255, 1) 50%
  );
}

.pendingCalItem {
  border: 2px dashed #bbcada;
}
.pendingCalItem:hover {
  border: 2px dashed #8f9dab
}
.addTimesheetItemAfterClock {
  background-color: #07f;
  border: #0469de 2px solid;
  border-radius: 5px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 3%;
  margin-bottom: 20px;
  cursor: pointer;
  margin-top: 14px;
  padding-left: 8px;
  padding-right: 8px;
}

.addTimesheetItemAfterClock:hover {
  background-color: #0469de;
}

.addIconTs {
  max-width: 10px;
  margin-left: 4px;
}

.addHrsbtn {
  margin-top: 14px;
}

.tsAddInputDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 17px;

  min-width: 100%;
}

.tsAddInputRow {
  display: flex;
  padding-left: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.tsAddInputCol {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 6px;
}

.tsAddInputTitle {
  font-size: 12px;
  font-weight: 500;
  color: #143051;
  margin-left: 2px;
  margin-bottom: 5px;
}

.tsAddInputTime {
  background-color: #fff;
  width: 77px;
  max-width: 77px;
  line-height: 30px;
  padding-top: 0px;
  height: 35px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 16px;
  font-weight: 500;
  cursor: text;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tsBrkInput {
  background-color: #fff;
  max-width: 60px;
  line-height: 35px;
  height: 35px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 0.9em;
  font-weight: 500;
  padding-top: 2px;
}

.tsAddInputUnpaidSpan {
  color: #bbcada;
  font-size: 10px;
}

.tsItemHeaderSection {
  font-weight: 500;
  display: flex;
  align-items: center;
  color: #bbcada;
  font-size: 14px;
}

.tsAddInlineAddBtn {
  background-color: #07f;
  border: #0469de 1px solid;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 30px;
  border-radius: 5px;
  margin-left: 5px;
}

.tsAddInlineAddBtn:hover {
  background-color: #0469de;
}

.addMoreHrsImg {
  max-width: 15px;
}

.hrsBin {
  max-width: 12px;
}
.binColours {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  padding: 4px;
}

.binColours:hover {
  background-color: #bbcada;
}
.flexTsItems {
  display: flex;
  justify-content: space-between;
  min-width: 76px;
  flex-direction: row;
  margin-left: 5px;
}

.withinTxt {
  color: #ba0000 !important;
}

.colourAuto {
  color: inherit;
}

.saveAndSubmitContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.submitTsBtn {
  color: white;
  background-color: #07f;
  border: #0469de 1px solid;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 60px;
  justify-content: center;
  font-weight: 500;
  margin-right: 10px;
}

.submitTsBtn:hover {
  background-color: #0469de;
}
.saveTsBtn {
  color: white;
  background-color: #0bf;
  border: 1px solid #0af;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 60px;
  justify-content: center;
  font-weight: 500;
  margin-right: 10px;
  margin-left:10px;
}

.saveTsBtn:hover {
  background-color: #0af;
}

.tsClockOnTitle {
  margin-bottom: 5px;
  border-bottom: 1px solid #e1e2e3;
  padding-bottom: 4px;
  margin-right: 10px;
  font-size: 10px;
  min-width: 17px;
  min-height: 30px;
  padding-right: 16px;
  margin-top: 3px;
  max-width: 57px;
}

.tsRateTitle {
  min-height: 15px;
margin-left:6px;
}

.tsRateVal {
  color: #143051;
  font-size: 14px;
  font-weight: 500;
  margin-left: 6px;
  margin-bottom: 5px;}
/* .tsAddInputMetaTitle {
    font-weight:600;
    font-size:12px; 
    color: #bbcada;
  } */

.tsAddInputMetaType {
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  padding-left: 5px;
  text-align: left !important;
  max-width: 83px;
  min-width: 83px;
  line-height: 22px;
  height: 35px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 2px solid #eef2fa;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 63px;
  margin-top: 5px;
}

.tsAddInputMetaNote {
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  padding-left: 5px;
  text-align: left !important;
  max-width: 138px;
  min-width: 138px;
  line-height: 22px;
  height: 35px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  display: flex;
  /* cursor: pointer; */
  border: 2px solid #eef2fa;
  background-color: white;
  color: #6a8bb1;
  margin-top: 5px;
  margin-left: 6px;
  cursor: text;
}

.tsAddInputMetaNote::-webkit-input-placeholder {
  color: #bbcada;
  font-size: 12px;
}

.tsAddInputMetaNote:-ms-input-placeholder {
  color: #bbcada;
  font-size: 12px;
}

.tsAddInputMetaNote::placeholder {
  color: #bbcada;
  font-size: 12px;
}

.metaRow {
  margin-bottom: 10px;
}

.x9101 {
  margin-top: 3px;
  margin-left: -9px;
}

.firstTsAddBtn {
  /* margin-top: 5px; */
  /* margin-left: 10px; */
}

.absColBorder {
  /* border-left: 2px dotted #bbcada; */
}

.mobAbsenceCol2 {
  padding-left: 6px !important;
}

.colourBBCADA {
  color: #bbcada !important;
}

.nonPrimProPic {
  /* opacity: 0.4; */
  /* filter: grayscale(100%); */
  /* display:none; */
}

.nonPrimLeaveShiftBox {
  background-color: #496375 !important;
}

.nonPrimLeaveShiftBoxFirstHalfOff {
  background: linear-gradient(
    315deg,
    #496375 50%,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.25) 97%
  );
}

.nonPrimLeaveShiftBoxFirstHalfOff:hover {
  background: linear-gradient(
    315deg,
    #496375 50%,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.25) 97%
  );
}

.nonPrimLeaveShiftBoxLastHalfOff {
  background: linear-gradient(
    135deg,
    #496375 51%,
    rgba(255, 255, 255, 0.25) 51%,
    rgba(255, 255, 255, 0.25) 97%
  );
}

.nonPrimLeaveShiftBoxLastHalfOff:hover {
  background: linear-gradient(
    135deg,
    #496375 51%,
    rgba(255, 255, 255, 0.25) 51%,
    rgba(255, 255, 255, 0.25) 97%
  );
}

.noPrimsJt {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #cbd7e6; */
  color: #bac5d3;
  /* border: 1px solid #bac5d3; */
  max-height: 24px;
  margin-top: 4px;
  border-radius: 3px;
  font-size: 10px;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 500;
  margin-right: 5px;
  cursor: pointer;
}

.noPrimsJt:hover {
  color: #748cab;
}

.x9988 {
  margin-bottom: -1px !important;
  /* background-color: green; */
}

.x99881 {
  margin-bottom: 12px !important;
}

.colourOrange {
  color: #ff6441;
}

.vizSubMins {
  font-size: 10px;
  color: #fff;
  margin-top: -7px;
  border-bottom: 1px solid #fff;
  min-width: 100%;
  text-align: right;
  opacity: 0.5;
  /* margin-bottom:-5px; */
}

.vizSubMinsMob {
  text-align: left;
  margin-bottom: 5px;
  padding-bottom: 0px;
  margin-top: -1px;
  min-height: 16px;
  color: pink;
}

.vizBoxMob {
  min-height: 235px !important;
  padding-top: 20px;
  padding-bottom: 0px;
}

.myRotaDayViewBtn {
  background-color: #516b8b;
  border: 1px solid #496375;
  cursor: pointer;
  color: white;
  font-size: 12px;
margin-left:7px;
  min-width: 66px;
  padding-left: 5px;
  padding-right: 5px;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.myRotaDayViewBtnMob {
  min-width: 46px;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 8px;
}

.myRotaDayViewBtn:hover {
  background-color: #496375;
}

.dayViewCalImg {
  max-width: 10px;
  margin-right: 5px;
  /* margin-left:-1px; */
}

.myRotaPrimTeamTitle {
  text-align: left;
  color: #bbcada;
  margin-top: 9px;
  font-size: 8px;
}

.x001 {
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 10px;
  margin-top: 16px;
}

.padTop5 {
  padding-top: 5px !important;
}

.padTop3 {
  padding-top: 3px !important;
}

.pTeamNameUsr {
  /* color:red !important; */
  line-height: 10px !important;
  min-height: 30px !important;
}

.x8899 {
  margin-top: 8px;
}

.X19831 {
  background: none !important;
  border: none !important;
  padding-top: 2px;
  color: white;
}

.mobModalShoulder {
  border-radius: 20px 20px 0 0 !important;
  -webkit-animation-name: moveEditTeamUserBoxUp !important;
          animation-name: moveEditTeamUserBoxUp !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  bottom: 0px;
  position: fixed;
  left: 0px;
  min-width: 100vw !important;
}

.timePickerDropsInput {
  margin-top: 13px;
}

.doesNotManagePTeamBoxSize {
  /* min-height: 130px;
  max-height: 130px;
  margin-top: 4px; */

  /* min-height: 156px;
  max-height: 156px;
  margin-top: -6px; */

  min-height: 150px;
  max-height: 150px;
  margin-top: -8px;
}

.extendBtnsContainerDoesNotManagePTeam {
  min-height: 128px;

  /* min-height: 156px;
  max-height: 156px; */
  min-height: 150px;
  max-height: 150px;
}

.width87 {
  min-width: 87px !important;
  max-width: 87px !important;
}

.noOpenNotes {
  color: #bbcada;
  font-size: 12px;
  margin-left: 20px;
  font-weight: 500;
  max-height: 50px;
  margin-top: -10px;
  margin-bottom: -6px;
}

.openInlineTags {
  /* background-color: yellow; */
  display: flex;
  margin-left: 16px;
  margin-top: -12px;
}

.openUnitParent {
  /* background-color: blue; */
}

.tsStripWithOpenTags {
  margin-bottom: -2px !important;
  font-size:12px;
}

.openTagsClaimersTxt {
  /* background:orange; */
  margin-bottom: 18px;
  font-size: 9px !important;
}

.tsUnitImg {
  max-width: 15px;
  min-width: 15px;
  margin-left: 10px;
}

.tsUnitImgMob {
  max-width: 15px;
  min-width: 15px;
  margin-left: 13px;
  margin-right: 10px;
}

.tsUnitLeft {
  /* border-right: 2px dotted #5a9e3f; */
  min-height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 42px;
  padding-right: 10px;
}

.tsUnitLeftNonSubmitted {
  border-right: none;
  min-height: 72px;
}

.tsUnitMiddle {
  font-weight: 500;
  text-align: left;
  min-width: calc(100% - 70px);
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
}

.tsUnitMiddleMob {
  padding-left: 8px;
  margin-left:18px
}

.tsUnitFromToSection {
  margin-right: 12px;
  /* border-left:2px solid #bbcada; */
  /* padding-left:10px; */
}

.tsUnitDsTitle,
.tsOptionTitle {
  color: #859dbb;
  font-size: 10px;
  margin-bottom: -1px;
  margin-top: 1px;
}
.x1341 {
  /* margin-left:-2px; */
  padding-left: 10px;
  border-left: 1px solid #e0ebf8;
}

.tsUnitDsValue {
  color: #214978;
  display: flex;
  font-size:14px;
  font-weight: 600;
}

.tsUnitDsValueMob {
  font-size: 16px;
}

@media only screen and (max-width: 404px) {
  .tsUnitDsValueMob {
    font-size: 14px;
  }
}

.tsUnitMidLeft {
  /* display: flex; */
  /* align-items:center */
}

.tsUnitMidRight {
  display: flex;

  align-items: center;
}

.tsOptionsContainer {
  margin-top: 20px;
  overflow-y: auto;
  max-height: 420px;
  max-width: 90%;
  margin-left: 5%;
}

.tsOptionBox {
  background-color: white;
  border: 1px solid #e6eaee;
  border-radius: 5px;
  max-width: 100%;
  /* margin-left:5%; */
  margin-bottom: 10px;
  cursor: pointer;
  text-align: left;
  display: flex;
  justify-content: space-between;
  min-height: 54px;
}

.tsOptionBox:hover {
  border-color: #d7dfe9;
}

.tsOptionTeam {
  font-weight: 500;
  color: #143051;
  /* margin-left:20px; */
}

.activeTsIcon {
  max-width: 16px;
  margin-left: 20px;
}

.tsOptionsUnitRight {
  min-width: calc(100% - 50px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}

.tsOptionValue {
  font-weight: 500;
  font-size: 14px;
  color: #214978;
  margin-top:3px;
}

.tsRightIcon {
  max-width: 12px;
  margin-left: 3px;
}

.addTsBtnLaunch {
  max-width: 150px;
  color: white;
  display: flex;
  align-items: center;
  background-color: #07f;
  border: #0469de 1px solid;
  font-size: 14px;
  border-radius: 5px;
  justify-content: space-around;
  min-height: 35px;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  float: left;
  margin-left: 5%;
  margin-top: 6px;
  margin-bottom: 12px;
  cursor: pointer;
}

.addTsBtnLaunch:hover {
  background-color: #036ce4;
}
.newTsAddIconWhite {
  max-width: 12px;
  margin-left: 5px;
}

.deleteTsBtn {
  margin-right: 10px;
  margin-left:10px
}

.positiveBtn {
  background-color: #0bf;
  border: 1px solid #0af;
}

.positiveBtn:hover {
  background-color: #0af;
}

.tsSubmittedTs {
  color: #bbcada;
  text-align: left;
  line-height: 12px;
  /* max-width: 70px; */
  font-size: 12px;
  max-height: 30px;
  font-weight: 500;
  /* padding-left:10px; */
  margin-top:3px;
  /* padding-top: 4px; */
}

.tsSubmittedTsTitleSpan {
  font-size: 10px;
}

.loadOtherTs {
  line-height: 12px;
}

.cantSeeYourTeam {
  color: #859dbb;
  font-size: 12px;
  font-weight: 500;
  /* min-height: 24px; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 4px;
  margin-bottom: 12px;
  margin-right: 20px;
}

.cantSeeYourTeam:hover {
  color: #516b8b;
}

.paddingTop0x {
  padding-top: 0px !important;
}

.x82181 {
  padding-top: 0px !important;
  margin-bottom: 30px !important;
}

.x193981 {
  margin-top: -10px !important;
  margin-bottom: -10px;
}

.tsFromTilSection {
  display: flex;
  border-bottom: 1px solid #e0ebf8;
  padding-bottom: 4px;
  /* margin-top: -3px; */
}

.tsUnitTeamName {
  color: #00aaff;
  font-size: 12px;
  margin-top: 3px;
  display: flex;
  align-items: center;
}

.submittedTsBadge {
  color: #bbcada;
  font-size: 12px;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  /* background-color: #0af; */
  margin-left: 12px;
  margin-top: 1px;
  min-height: 18px;
  padding-top: 2px;
  /* background: #dde9f9; */
}

.submittedTsBadge {
  color: #bbcada;
  font-size: 12px;
  /* border-radius: 3px; */
  /* padding-left: 5px; */
  /* padding-right: 5px; */
  /* background-color: #0af; */
  /* margin-left: 12px; */
  /* margin-top: 1px; */
  min-height: 18px;
  /* padding-top: 2px; */
  /* background: #dde9f9; */
}
.notYetSub {
  padding-left: 0px;
  padding-right: 0px;
  font-size: 10px;
  margin-right: 9px;
  padding-left: 9px;
  padding-right: 0px !important;
  padding-top: 2px !important;
  border-left: 1px solid #c3d6e3;
  border-radius: 0px;
  /* border: 1px solid #bbcada; */
  color: #bbcada;
}

.notYetSubMgr {

  font-size: 10px;
  margin-top: 1px;
  border-radius: 0px;
  color: #bbcada;
  border:none !important
}

.x28291 {
  /* background-color: blue; */
  margin-top: 1px !important;
  max-height: 30px;
  /* margin-bottom:-20px !important */
}

.x982765 {
  /* background-color: yellow; */
  min-height: 30px;
  margin-top: -10px;
  margin-bottom: -8px;
  align-items: center;
  /* max-width:240px; */
}

.timesheetPeriodDates {
  color: #bbcada;
  font-weight: 500;
}

.tsBadMob {
  /* max-width: 66px; */
  /* text-align: center; */
  line-height: 10px;
  padding-top: 5px !important;
  /* padding-bottom: 2px; */
  font-size: 10px;
  padding-left: 6px;
  /* padding-right: 2px; */
  /* margin-top: -2px;
    }

    .smallSubmitBadgeSpan {
      font-size:7px;
      /* opacity: 0.7; */
}

.clockOnOffTimesFlex {
  display: flex;
  color: #143051;
  font-weight: 500;
  font-size: 11px;
  align-items: center;
  justify-content: space-between;
  min-width: 140px;
  border-top: 1px solid #e0ebf8;
  padding-top: 3px;
}

.clockOnValTxt {
  margin-top: -2px;
  color: #0af;
  font-weight: 600;
}

.tilShiftDateAndNth {
  display: flex;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.tilShiftDateAndNthSmaller {
  font-size:12px;
  white-space: nowrap;

}

.clockOnOffSection {
  padding-left: 2px;
}

.clockOnOffSection2 {
  border-left: #e0ebf8 1px solid;
  padding-left: 7px;
  margin-left: 5px;
}

.clockOnOffSection3 {
  border-left: #e0ebf8 1px solid;
  padding-left: 7px;
  margin-left: 5px;
  padding-right: 4px;
  min-width: 47px;
}

.teamNameColour {
  color: #0af !important;
  font-size: 12px;
  line-height:12px;
  max-width: 100px;
}

.hoursUnitRotaClockImg {
  max-width: 10px;
  margin-right: 4px;
  opacity: 0.5;
}

.hoursUnitRotaClockImgCoffee {
  max-width: 10px;
  margin-right: 3px;
  /* margin-left:12px; */
  opacity: 0.5;
}

.myHoursUnitTeamName {
  font-weight: 500;
  color: #0af;
  font-size: 12px;
  line-height: 12px;
  max-width: 100px;
  display:flex;
  align-items: center;
}

.myHoursUnitTeamNameMob {
  font-size:10px;
}

.unsubmittedTsBlob {
  max-width: 8px;
  min-width: 8px;
  max-height: 8px;
  min-height: 8px;
  background-color: #00aaff;
  border-radius: 2px;
  margin-top: -50px;
  margin-right: -13px;
}

.clockAwaitBlob {
  margin-right: -43px;
  margin-left: 6px;
}

.annLeaveBlob {
  margin-right: -13px;
  margin-left: 6px;
}

.pendingTsSplitter {
  background-color: #bbcada;
  min-width: 80%;
  min-height: 1px;
  max-width: 80%;
  margin-left: 10%;
  margin-top: 30px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.notYetSubmitTitleArr {
  margin-bottom: -8px;
}

.loadingTimesheetImg {
  max-width: 110px;
  margin-bottom: 0px !important;
  padding-top: 30px;
  padding-bottom: 30px;
}

.tsUpToTodaySpan {
  color: #bbcada;
  font-size: 12px;
  margin-left: 5px;
  margin-right: 6px;
  /* margin-top: 1px; */
}

.calDayCommentImg {
  max-width: 14px;
  margin-top: 2px;
  margin-right: 4px;
}

.commentsCal {
  font-size: 12px;
  color: #bbcada;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1px;
  margin-left: 4px;
  margin-top: -8px;
  min-height: 30px;
  cursor: pointer;
}

.commentsCal:hover {
  -webkit-transform: scale(0.94);
          transform: scale(0.94);
}

.dayCommentListDiv {
  border-radius: 5px;
  margin-bottom: 7px;
  cursor: pointer;
  border: 1px solid #dde9f9;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  padding: 5px;
  padding-left:15px;
  padding-right:15px;
}

.dayCommentListDiv:hover {
  /* border-color: #214978; */
  border: 1px solid #bbcada;
  /* background-color: #f4f7f9; */
}

.dayCommentListBtn {
  text-align: left;
  font-weight: 500;
  color: #0af;
  font-size: 14px;
  margin-top:3px;
}

.dayCommentListPreview {
  text-align: left;
  font-weight: 500;
  color: #214978;
  font-size: 14px;
  margin-top:4px;
  margin-bottom:4px;
}

.comFn {
  color: #496375;
  font-weight: 500;
  font-size: 10px;
}

.subHeadSplit {
  min-width: 1px;
  min-height: 14px;
  max-height: 14px;

  background-color: #e0ebf8;
  margin-top: -6px;
}

.inlineTeamSplit {
  margin-left: 9px;
  margin-right: -9px;
}

.timesheetDataHeader {
  border-radius: 5px;
  background: #214978;
  color: white;
  border: 1px solid #143051;
  padding: 5px;
  margin-top: 15px;
  font-size: 14px;
}

.timesheetDataHeaderMgr {
  border: 1px solid #7f63bb !important;
  background-color: #9374d6;
}

.tsDataHeaderRow {
  display: flex;
  justify-content: space-between;
  min-height: 30px;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.tsDataHeaderTitle {
  font-weight: 500;
  opacity: 0.7;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.tsDataHeaderValue {
  font-weight: 500;
  text-align: right;
  line-height:14px;
  /* margin-top:4px */
}

.tsDataHeaderRowSplitter {
  min-height: 1px;
  min-width: 94%;
  margin-left: 3%;
  background-color: white;
  opacity: 0.25;
  max-width: 94%;
}

.tsSchedParent {
  display: flex;
  padding-left: 6px;
}

.tsSchedClockType {
  font-weight: 500;
  border-left: #bbcada 2px solid;
  padding-left: 5px;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  margin-top:8px;
  margin-bottom:12px;
}

.minWidthFitContent {
  min-width: -webkit-fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
}

.tsEditItemTypeDropdown {
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  padding-left: 9px;
  text-align: left !important;
  max-width: 99px;
  min-width: 99px;
  line-height: 18px;
  height: 30px;
  -webkit-appearance: none;
  background-position-x: 64px;
  display: flex;
  cursor: pointer;
  border: 2px solid #dde9f9;
  background-color: #0bf;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 80px;
  margin-left: 8px;
}

.paddingRight27px {
  padding-right: 27px;
}

.fontSize7 {
  font-size: 7px !important;
}

.noPaddingRight {
  padding-right: 0px !important;
}

.toilAllowRow {
  margin-top: -6px;
}

.sufficientTil {
  color: #6dba4f;
}
.insufficientTil {
  color: #f07b7b;
  margin-top:6px;
  margin-left:4px;
  /* margin-right:13px; */
}
.sufficientTilTick {
  margin-right: 4px;
  padding-right: 7px;
  width: 18px;
}

.insufficientTilTick {
  margin-right: 4px;
  padding-right: 7px;
  margin-top: 2px;
  max-width: 17px;
  margin-left: 2px;
}

.colorRed {
  color: #f07b7b !important;
}

.colorRed2 {
  color: #ba0000 !important;
}
.mobModalTeamModal {
  min-height: -webkit-fit-content !important;
  min-height: -moz-fit-content !important;
  min-height: fit-content !important;
  max-height: -webkit-fit-content !important;
  max-height: -moz-fit-content !important;
  max-height: fit-content !important;
  padding-bottom: 15px;
}

.specDayClosedSpan {
  margin-left: 3px;
  opacity: 1;
  margin-right: 4px;
  border-left: 1px solid #eed5f6;
  padding-left: 5px;
}

.bidQtyUserIcon {
  max-width: 15px;
  margin-left: 12px;
  margin-right: 5px;
}

.bidUsrQtySpan {
  color: #0af;
  font-size: 16px;
  /* padding-bottom:4px; */
}

.font16 {
  font-size: 16px;
}

.ba0000 {
  color: #ba0000;
}

.unlinkedExplainBtn {
  background-color: #ba0000;
  color: white;
  min-width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  max-height: 15px;
  margin-left: 5px;
  font-size: 11px;
  /* padding: 4px; */
}

.cursorP {
  cursor: pointer !important;
}

.x2817346 {
  line-height: 28px;
}

.unlinkedPopUpLink {
  color: #00aaff !important;
  cursor: pointer;
  margin-left: 4px;
}

.unlinkedPopUpLink:hover {
  color: #00bbff !important;
}

.x9813114 {
  margin-bottom: -12px;
}

.editTagSugImg {
  max-width: 13px;
}

.editTagBtn {
  min-width: 40px !important;
}

.addTagToMulti {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  font-size: 12px;
  color: white;
  font-weight: 500;
  text-align: left;
  max-width: 100px;
  display: flex;
  opacity: 0.8;
  cursor: pointer;
  border-radius: 5px;
  max-height: 35px;
  min-height: 35px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 12px;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

.addTagToMulti:hover {
  opacity: 1;
  background-color: #bbcada;
}

.addTagsToAllActive {
  opacity: 1;
}

.addTagToAllImg {
  max-width: 12px;
  margin-right: 8px;
  margin-left: 2px;
}

.tagMgrLeft {
  display: flex;
  /* min-width:fit; */
}

.shiftTilMyCalTitle {
  /* padding-left:10px; */
  text-align: left;
}

.paddingLeft10 {
  padding-left: 10px;
}

.fontSize12 {
  font-size: 12px !important;
}

.staffFormsContainer {
  /* padding-top: 31px; */
}

.upcomingCommentImg {
  max-width: 13px;
  margin-bottom: -2px;
  margin-left: 3px;
  opacity: 0.76;
}

.ghostColourLight {
  background-color: #875eff;
}
.viewTsBorder {
  border-top: 1px solid #bac5d3;
  border-radius: 0px;
}

.marginTop10 {
  margin-top: 10px !important;
}

.noUnlinkedMobClocksShoulderHeight {
  min-height: 50px !important;
  max-height: 50px !important;
  padding-top: 50px;
}

.weight600 {
  font-weight: 500;
}

.toilNewOldTimesBar {
  display: flex;
  justify-content: space-around;
  /* padding-left: 35px; */
  /* padding-right: 25px; */
  min-width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: #0077ff;
  margin-top: 8px;
  margin-bottom: 10px;
}

.toilNewOldTitle {
  min-width: 109px;
  text-align: left;
  /* border-top:2px solid #e0ebf8; */
  padding-left: 28px;
  padding-top: 6px;
}

.toilNewOldTitle2 {
  min-width: 139px;
  text-align: right;
  /* border-top:2px solid #e0ebf8; */
  padding-right: 26px;
  padding-top: 6px;
}

.x198313 {
  border-top: 1px solid #e0ebf8;
  padding-top: 2px;
}

.x3891974 {
  border-left: 1px solid #e0ebf8;
  padding-left: 5px;
  /* margin-left: 5px; */
  align-items: center;
}

.borderRadius10 {
  border-radius: 10px 5px 5px 10px !important
}

.calCommentsRow {
  min-height: 26px;
  /* background-color: yellow; */
  max-height: 26px;

  display: flex;
  align-items: center;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  margin-left: 10px;
}

.calLimitsRow {
  min-height: 108px;
  background-color: #504588;
  max-height: 66px;

  display: flex;
  align-items: center;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  margin-left: 10px;
  border-top:2px solid #143051;
  border-bottom:2px solid #143051;
  border-right:2px solid #143051;
  border-radius: 0 5px 5px 0;
}
.calCom {
  max-width: 40px;
  /* border-left:1px solid black; */
  min-width: 40px;
  max-height: 26px;
  /* background-color: lime; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.calCommentIcon {
  max-width: 15px;
}

.commentFade {
  opacity: 0.05;
  cursor: pointer;
}

.commentFade:hover {
  opacity: 0.25;
}

.commentDivActive {
  cursor: pointer;
}

.commentDivActive:hover {
  opacity: 0.8;
}

.commentsExistA1 {
  margin-top: 5px;
  margin-bottom:-4px;
}

.commentsExistA1limitEnabled {
min-height:107.5px;
margin-bottom:36px;
}

.nonWorkingBorderLeft {
  border-left: none;
  padding-left: 0;
}

.reqAndMsgDiv {
  display: flex;
}

.x39142318 {
  font-size: 10px;
}

.x139819841 {
  max-width: 170px;
}

.x13984191 {
  /* background: none; */
  /* backdrop-filter: none; */
  /* background: rgb(0, 0, 0, 0.1); */

    -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
}

.toilInstructInfo {
  color: #516b8b;
  font-weight: 400;
  font-size: 12px;
  text-align: left;
  margin-left: calc(5% + 5px);
  padding-right: 5%;
  line-height: 16px;
}

.x923111831 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.marginBottomMinus20 {
  margin-bottom: -20px !important;
}

.filterSchedBtn {
  border-radius: 5px;
  padding: 5px;
  min-width: 25px;
  min-height: 25px;
  max-height: 25px;
  max-width: 25px;
  margin-top: 7px;
  border: 1px solid #d6e1ed;
  margin-right: 10px;
  cursor: pointer;
}

.filterSchedBtn:hover {
  border-color: #bac5d3;
}

.filterSchedBtnActive {
  padding-right: 6px;
  border: 1px solid #aaa1c3;
  background-color: #fff;
}

.filterSchedBtnActive:hover {
  border: 1px solid #aaa1c3;
}

.filterSchedBtnActiveTags {
  background: #656095;
  border: 0px solid #4d4391;
}

.filterSchedBtnActiveTags:hover {
  background-color: #4d4391;
  border-color: #4d4391;
}

.filtTagsTitle {
  font-size: 11px;
  font-weight: 500;
  color: #826197;
  margin-top: 1px;
  background: #ede2ff;
  max-width: 76px;
  border-radius: 0 0 3px 3px;
  margin: 0px auto;
  padding-bottom: 1px;
  border: 1px solid #bca7d0;
  border-top: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.closeFiltTags {
  max-width: 7px;
  /* opacity: 0.8; */
  margin-left: 4px;
}
.filtTagsFlex {
  display: flex;
  /* background-color: #143051; */
  color: white;
  justify-content: center;
  align-items: center;
  min-height: 30px;
  min-width: calc(100vw - 160px);
  max-width: calc(100vw - 160px);
  float: right;
  overflow-x: scroll;
  padding-left: 50px;
  padding-right: 50px;
}

.filtTagItem {
  background-color: white;
  color: #9c71cc;
  font-weight: 500;
  font-size: 14px;
  border-radius: 5px;
  margin-right: 3px;
  margin-left: 3px;
  min-height: 26px;
  margin-top: 2px;
  border: 1px solid #b4a8de;
  display: flex;
  align-items: center;
  padding-left: 9px;
  padding-right: 9px;
  cursor: pointer;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.filtTagItemUnsel {
  background: none;
  border-color: #ebd9ff;
  color: white;
  opacity: 0.7;
}

.filtTagItemUnsel:hover {
  background-color: #ebd9ff;
  opacity: 0.9;
}

.filtTagsModal {
  min-width: 300px;
  background-color: #fff;
  /* min-height: 300px; */
  border-radius: 10px;
  /* border: 1px solid #bbcada; */
  margin-bottom: 20%;
  cursor: default;
  /* border: 1px solid #e7ebef; */
  /* box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1) */
}

.filtTagsModalMob {
  margin-bottom: 0%;

  /* min-width: 100vw;
  margin-bottom:0px !important;
  bottom: 0  !important;
  left: 0;
  display: fixed; */
}

.filtTagsUnderlay {
  background: rgba(0, 0, 0, 0.25);
  height: 100vh;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(1px);
  background-color: rgb(119 119 119 / 30%);
}

.tagsFiltScrollBox {
  background-color: #f4f8fe;
  max-width: 92%;
  margin-left: 4%;
  min-height: 189px;
  border: 1px solid #e0ebf8;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  margin-bottom: -10px;
  max-height: 50vh !important;
  overflow-y: auto;
}

.tagFiltItem {
  display: flex;
  align-items: center;
  border: 1px solid #e0ebf8;
  border-radius: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
  /* max-width: calc(100% - 6px); */
  margin-left: 3px;
  min-height: 40px;
  padding-left: 10px;
  font-weight: 500;
  color: #143051;
  cursor: pointer;
  background-color: white;
  text-align: left;
  max-width: 300px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 8px;
}

.tagFiltItem:hover {
  border-color: #bbcada;
}

.showAllTags {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 78px;
  padding-left: 9px;
  padding-right: 9px;
  min-height: 35px;
  max-height: 35px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
}

.showAllTags:hover {
  background-color: #bbcada;
}

.x134214253 {
  margin-right: 10px;
}

.myTilShiftUnitParent {
  /* min-height: fit-content; */
  /* background-color: red; */
  min-height: 86px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.myCalOpensArr {
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;  
  /* animation-duration: 2s !important; */
}


.myTilShiftUnitParent:first-of-type {
  /* margin-top: 14px; */
}
.myShiftUnitTagsRow {
  /* background-color:yellow; */
  /* margin: -7px auto; */
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 90%;
  min-width: 90%;
  margin: -8px auto;
  padding-left: 50px;
  /* display: none; */
}

.myShiftUnitTagsRowMobOnly {
  /* margin-left:74px; */
  z-index: 1;
  position: relative;
}

.myTilShiftUnitWithTags {
  min-height: 90px;
}

.usrShiftTagsContainer {
  display: flex;
  /* margin-top: -3px; */
  min-width: 302px;
  max-width: 302px;
  z-index: 99999;
  /* background-color: lime; */
  /* margin-top: 4px; */
}

.ghostCostOverlay {
  background-color: #143051;
  color: white;
}

.ghostCostOverlayHovered {
  background-color: #214078;
  color: white;
}

.spaceBetween {
  flex-direction: row;
  justify-content: space-between  !important;
}

.addAbsItemCheckBox {
  min-width: 8%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #bbcada;
  max-width: 20px;
  max-height: 20px;
  min-height: 20px;
  min-width: 20px;
  border-radius: 5px;
  margin-top: 12px;
  margin-left: 7px;
}

.addAbsItemCheckBoxImg {
  width: 10px;
}

.accountTeamPopUpImg {
  max-width: 12px;
  margin-left: 5px;
}

.accUserIcon {
  max-width: 8px;
  margin-right: 6px;
}

.accTeamIcon {
  max-width: 18px;
  margin-right: 6px;
}

.calNoItemsTxt {
  background-color: #ffffff;
  max-width: 92%;
  margin-left: 4%;
  border-radius: 3px;
  /* margin-top: 3px; */
  color: #bbcada;
  font-weight: 500;
  font-size: 12px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 6px;
  padding-left: 13px;
}

.rankBtn {
  font-size: 14px;
  /* margin-top: 8px; */
  color: #496375;
  border: 2px solid #496375;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  line-height: 26px;
  padding-left: 10px;
  font-weight: 500;
  padding-right: 8px;
  /* margin-right: 8%; */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  color: white;
  margin-left: 10px;
}

.whiteRankBtn {
  background-color: #b49ce7;
  border: 1px solid #a47fd0;
  color: #fff;
}

.whiteRankBtn:hover {
  /* border-color: #dce2e9; */
  background-color: #a58fd4 !important;
}

.rankPopUpImg {
  max-width: 14px;
  margin-left: 5px;
}
.rankBtn:hover {
  background-color: #bbcada;
}

.myAbsTopLeft {
  display: flex;
  align-items: center;
}

.x038141 {
  min-width: 100%;
  justify-content: space-between;
}

.tsInlineTags {
  /* background-color: blue; */
  margin-top: 8px;
  margin-bottom: 5px;
  border-top: 1px solid #e1e2e3;
  padding-top: 8px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 2px;
}

.tsInlineTagsAddHours {
  margin-bottom: 35px;
  max-width: 90%;
  min-width: 90%;
  border-radius: 5px;
  margin-left: 5%;
  border: 1px solid #e1e2e3;
  padding-bottom: 4px;
  /* padding-left:6px; */
  padding-right: 6px;
  margin-left: 4%;
  margin-top: 0px;
  border: none;
}

.tsInlineTagItem {
  /* display: flex;
  min-height: 35px;
  margin-right: 4px;
  border: 1px solid #e6eaee;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 4px;
  border-radius: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 12px;
  text-align: left; */


  color: #fff;
    font-size: 11px;
    text-align: left;
    margin-right: 2px;
    padding-left: 5px;
    padding-right: 6px;
    min-width: -moz-fit-content;
    min-width: -webkit-fit-content;
    min-width: fit-content;
    border-radius: 5px;
    font-weight: 500;
    /* background-color: #143051; */
    /* background-color: rgb(247, 250, 255); */
    background-color: #f4f8fe;
    color: #4f566b;
    padding-top: 0px;
    line-height: 21px;
    max-height: 24px;
    max-height: 23px;
    border: 1px solid #d2d8df;
    display: flex;
    align-items: center;
  /* background-color: white; */
}

.activeTsTagsInline {
  background-color: white;
  border: 1px solid #bbcada;
  color: #214978;
}

.tagItemAddHours {
  font-size: 12px;
  min-height: 30px;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 500;
  align-items: center;
  display: flex;
}

.clockAvailTagsModal {
  background-color: #fff;
  min-width: 320px;
  max-width: 320px;
  min-height: 290px;
  max-height: 290px;
  border-radius: 6px;
  align-items: center;
  color: #143051;
  text-align: center;
  -webkit-animation-name: moveEditTeamUserBoxUp !important;
          animation-name: moveEditTeamUserBoxUp !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  cursor: default;
}

.clockAvailTagsScrollBox {
  /* background-color: yellow; */
  max-height: 182px;
  min-height: 182px;
  overflow-y: scroll;
  max-width: 90%;
  margin-left: 5%;
  border-bottom: 1px solid #143051;
  border-top: 1px solid #c0d1e9;
  padding-bottom: 20px;
}

.clockAvailTagsDiv {
  display: flex;
  flex-wrap: wrap;
}

.appliedTagsQty {
  margin-left: 5px;
  margin-right: 2px;
}

.noClockTagsTxt {
  font-size: 14px;
  color: #bbcada;
}

.newlyAddedClockTag {
  border-left: 4px solid #00ccff;
}

.x248241 {
  /* background-color: blue; */
  /* max-height:30px; */
  border: 2px solid #eef2fa;
  line-height: 20px;
  font-size: 12px;
  padding-left: 10px;
  max-width: 98px;
  min-width: 98px;
  background-position-x: 76px;
  margin-left: 12px;
  /* margin-top:-27px; */
}

.removeTagFromTsImg {
  max-width: 10px;
  margin-left: 6px;
  min-width: 10px;
  cursor: pointer;
}

.removeTagFromTsImg:hover {
  opacity: 0.8;
}

.addTagAndAddRowDiv {
  display: flex;
  align-items: center;
  min-width: 100%;
  justify-content: space-between;
  padding-right: 5%;
  margin-top: -52px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0ebf8;
}

.x23972871 {
  margin-top: -30px;
}

.x38194 {
  /* background-color: yellow; */
  margin-left: -80px;
  font-size: 12px;
  color: #bbcada;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  font-weight: 500;
}

.x19382 {
  margin-left: -20px !important;
  font-size: 10px;
  margin-right: -10px;
}

.finalTsItemsFlex {
  background: blue;
  justify-content: flex-end;
}

.tsAddInputDsTitle {
  color: #bbcada;
  font-weight: 500;
  font-size: 12px;
  margin-left: 14px;
  margin-bottom: 10px;
}

.addTagInlineSh {
  max-width: 12px;
  margin-left: 5px;
  margin-top: -1px;
}

.approveClockTickImg {
  max-width: 12px;
  min-width: 12px;
  margin-left: 5px;
  margin-right: -2px;
}

.x34981 {
  max-width: 18px;
  min-width: 18px;
  margin-right: -4px;
  margin-left: 2px;
}

.viewTsNoItems {
  color: #859dbb;
  font-size: 14px;
  font-weight: 500;
  margin-top: 0px;
  margin-bottom: -4px;
  text-align: left;
  border-left: 2px solid #bbcada;
  padding-left: 10px;
  margin-left: 22px;
}

.recordViewTsBox {
  /* background-color: #00ccff !important; */
}

.editingEntryEditDiv {
  max-width: 46px;
  min-width: 46px;
  min-height: 58px;
}

.viewTsEditTimeimg {
  max-width: 26px;
  min-width: 26px;
  border: #e3e8ee solid 2px;
  cursor: pointer;
  max-height: 26px;
  min-height: 26px;
  padding: 6px;
  margin-top: 16px;
  border-radius: 5px;
}

.viewTsEditTimeimg:hover {
  border-color: #b2bbc7;
}

.saveEditingEntryBtn {
  max-width: 46px;
  min-width: 46px;
  border: #0af solid 1px;
  cursor: pointer;
  max-height: 26px;
  min-height: 26px;
  /* padding: 6px; */
  background-color: #0bf;
  margin-bottom: 5px;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 4px;
  padding-right: 4px;
  font-weight: 500;
}

.saveEditingEntryBtn:hover {
  background-color: #0af;
}

.greyColoursTsBtn {
  background-color: #cbd7e6;
  padding: 4px;
  border-color: #bbcada;
  font-size:10px;
  margin-top:10px;
}

.greyColoursTsBtn:hover {
  background-color: #bbcada !important;
}

.loadingTsHorizBalls {
  max-width: 80px;
}

.viewTsItemNote {
  font-weight: 500;
  color: #143051;
  padding-left: 2px;
  max-width: 100%;
  min-width: 100%;
  padding-top: 7px;
  margin-left: 1px;
  margin-bottom: 10px;
  border-top: 1px solid #e1e2e3;
  margin-top: 5px;
  min-height: 30px;
  margin-top: 0px;
  font-size:14px;
}

.x398721 {
  background-color: #ecf4ff;
}

.x972421 {
  cursor: pointer;
}

.x972421:hover {
  border-color: #bbcada;
}

.x308491 {
  margin-left: 11px !important;
  margin-right: -1px;
  min-width: 17px;
}

.noAvailClockTags {
  font-size: 14px;
  display: flex;
  align-items: center;
  /* line-height:20px; */
}

.x31414x13 {
  margin-left: 10px !important;
}

.myShiftEditBtnImg {
  max-width: 10px;
  margin-left: 5px;
}

.x139714 {
  padding-left: 9px;
  padding-right: 9px;
  min-width: 88px;
}

.x310852 {
  margin-right: 7px;
}

.mgrAvailReqInfo {
  font-size: 8px;
  margin-left: 4px;
  margin-right: 0px;
  text-align: left;
  max-width: 44px;
  color: #214978;
  margin-top: 0px;
  line-height: 9px;
}

.x9247925 {
  opacity: 0.35;
  -webkit-filter: grayscale(100%); /* Safari and Chrome */
  filter: grayscale(100%);
}

.x9247925:hover {
  opacity: 0.55;
  filter: grayscale(40%);
  -webkit-filter: grayscale(40%); /* Safari and Chrome */


}

.x249213572 {
  /* background-color: blue; */
  min-height: calc(100vh - 322px);
  max-height: calc(100vh - 322px);
}

.x2082982 {
  background-position-x: 174px !important;
  max-width: 196px !important;
  min-width: 196px !important;
  margin-right: 11px
}

.x108429811 {
  margin-right: 24px

}

.x3413567 {
  color: #214978;
}

.x394722 {
  font-size: 12px;
  margin-left: 5px;
  margin-right: 5px;
}

.x2456788743 {
  display: flex;
  align-items: center;
}

.x981452 {
  color: #9374d6;
}

.x9879741 {
  margin-bottom: -46px;
}

.animateZoomIn {
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.x08282 {
  color: #00ccff;
}

.addNoteDayCommentContainer {
  /* margin-top: -10px; */
  margin-left: 7px;
}

.shiftAddNoteTextArea::-webkit-input-placeholder {
  font-size: 14px;
  color: #bbcada;
}

.shiftAddNoteTextArea:-ms-input-placeholder {
  font-size: 14px;
  color: #bbcada;
}

.shiftAddNoteTextArea::placeholder {
  font-size: 14px;
  color: #bbcada;
}

.likedUsersSection {
  /* min-width: 80px; */
  text-align: left;
  /* cursor: pointer; */
}

.ackTitle {
  font-size: 10px;
  font-weight: 500;
  color: #bbcada;
  margin-top: 6px;
  margin-bottom: 4px;
  max-height: 12px;
  display: flex;
  align-items: center;
}

.likedArrMapDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding-left: 2px;
}

.likedArrMapDiv:hover {
  opacity: 0.8;
}

.ackUsrIcon {
  max-width: 22px;
  max-height: 22px;
  min-width: 22px;
  min-height: 22px;
  border-radius: 3px;
  margin-right: 2px;
  object-fit: cover;
  /* border: 1px solid #214978; */
}

.ackUsrExcess {
  color: #bbcada;
  font-weight: 500;
  margin-left: 3px;
  font-size: 11px;
}

.ackUsrInitials {
  background-color: #214978;
  border: 1px solid #143051;
  max-width: 22px;
  max-height: 22px;
  min-width: 22px;
  min-height: 22px;
  border-radius: 5px;
  margin-right: 2px;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: white;
  font-weight: 500;
}

.showLikedNamesCom {
  display: flex;
  align-items: flex-start;
  padding-left:1px
}

.ackArrClose {
  max-width: 17px;
  margin-left: 2px;
  margin-top: 6px;
  margin-right: 5px;
  padding-left: 6px;
  padding-right: 5px;
  padding-top: 6px;
  padding-bottom: 6px;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  border: 1px solid #e1eaf5;
  border-radius: 4px;
  cursor: pointer;
}

.ackArrClose:hover {
  opacity: 0.8;
}

.ackNamesList {
  margin-top: 9px;
  margin-left: 2px;
}

.ackNameItem {
  font-size: 12px;
  font-weight: 500;
  color: #bbcada;
  margin-bottom: 3px;
  max-height: 16px;
  /* background: yellow; */
  display: flex;
  align-items: center;
}

.x18429814581 {
  background: none;
  border: none;
  margin-top: 16px;
}

.Acknowledged {
  font-weight: 500;
  font-size: 9px;
}

.showAckExpandIcon {
  max-width: 20px;
  cursor: pointer;
  padding-left: 4px;
  padding-right: 4px;
  cursor: pointer;
  padding-top: 2px;
  padding-bottom: 2px;
  border: 1px solid #e1eaf5;
  border-radius: 4px;
  margin-left: 3px;
}

.showAckExpandIcon:hover {
  opacity: 0.8;
}

.dayCommentsFrameWithinScroll {
  border-left: 2px solid #143051;
}

.x98472515 {
  font-size: 10px;
  margin-left: 4px;
}

.x5362 {
  margin-left: 2px;
}

.inlineDayUnitImg {
  max-width: 15px;
  /* margin-top: 2px; */
  /* margin-bottom: -4px; */
  margin-right: 5px;
}

.showDayImgTitleWrap {
  display: flex;
  align-items: center;
  margin-bottom: -14px;
  margin-left: -2px;
}

.loadingDayCommentBalls {
  max-width: 80px;
  margin-top: 30px;
  margin-bottom: -100px;
}

.dayCommentComProfPic {
  max-width: 26px;
  min-width: 26px;
  min-height: 26px;
  max-height: 26px;
  border-radius: 3px;
  margin-right: 6px;
}

.dayCommInits {
  max-width: 26px;
  min-width: 26px;
  min-height: 26px;
  max-height: 26px;
  border-radius: 3px;
  margin-right: 6px;
  background-color: #214978;
  border: 1px solid #143051;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: white;
  font-size: 12px;
}

.myShiftClockStatus {
  font-weight: 500;
  font-size: 11px;
  text-align: right;
  padding-right: 10px;
  border-right: 2px solid #bbcada;
  color: #bbcada;
  max-height: 16px;
  line-height: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 2px;
}

.ccAPPR {
  color: #82c26a;
  border-right: none;
  padding-right: 2px;
}

.ccDECL {
  color: #ba0000;
  border-right: none;
  padding-right: 2px;
}

.editClockDecisionBtn {
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  /* padding-left: 10px; */
  /* padding-right: 10px; */
  /* display: flex; */
  min-height: 35px;
  max-height: 35px;
  min-width: 35px;
  max-width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* line-height: 26px; */
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  cursor: pointer;
  color: white;
  /* min-width: -moz-fit-content;
        min-width: fit-content;
        max-width: -moz-fit-content;
        max-width: fit-content; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.editClockDecisionBtn:hover {
  background-color: #bbcada;
}

.editClockDecImg {
  max-width: 24px;
}
.width38px {
  min-width: 38px !important;
  max-width: 38px !important;
}

.clockWhiteBin {
  max-width: 16px;
  min-width: 16px;
}

.backEditDecision {
  min-width: 35px;
  min-height: 35px;
  max-width: 35px;
  max-height: 35px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  margin-right: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.backEditDecision:hover {
  background-color: #bbcada;
}

.backeditdecisionImg {
  max-width: 19px;
}

.mgrTsDecideBtnsContainer {
  display: flex;
  align-items: center;
}
.x1084 {
  /* margin-right:8px */
  max-width: 68px;
  margin-top: -3px;
}

.x38713 {
  max-width: 40px;
}

.tsEditBrkFlexDiv {
  display: flex;
  align-items: center;
  margin-top: -3px;
}

.x1381314 {
  font-size: 14px;
  margin-left: 2px;
  font-weight: 500;
  color: #214978;
}

.x819741 {
  margin-top: -7px;
}

.x18489181 {
  margin-top: 16px;
}

.x20831981 {
  /* background: yellow; */
  flex-direction: column;
  align-items: flex-end;
  min-height: 34px !important;
  line-height: 10px;
  margin-top: -16px;
}

.mgrAbsencePeriodParent {
  -webkit-animation-name: zoomModalIn !important;
  animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
  animation-duration: 0.1s !important;
}
.mgrAbsencePeriodParentFirst {
  /* margin-top:20px !important */
}

.x131415 {
  padding: 0px;
  margin-bottom: -13px;
  margin-top: 19px;
  font-size: 10px !important;
  /* border-bottom: 1px solid #e0ebf8; */
}

.x248925 {
  margin-top: 6px;
}

.x1849781 {
  margin-top: -3px;
  margin-bottom: -1px;
}

.x131141 {
  margin-top: -105px;
}

.x0984014 {
  margin-bottom: -21px;
}

.firsMgrAbsShift {
  /* margin-top: -18px; */
}

.x1848918 {
  /* margin-top:7px; */
  /* font-size:12px  !important; */
}

.x18918491 {
  font-size: 12px;
}

.x140719741 {
  margin-top: -1px;
  margin-bottom: 17px;
}

.x19749711 {
  min-height: 50px !important;
}

.removeTsTagBtnImg {
  max-width: 12px;
  padding: 2px;
  margin-left: 5px;
  cursor: pointer;
}

.removeTsTagBtnImg:hover {
  opacity: 0.8;
}


.editinTsTsBorder {
 border:2px solid #dde9f9
}

.noTsTagstxt {
  color: #bbcada;
  margin-left: 6px;
  margin-top: 5px;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 10px;
  border-top: 1px solid #e1e2e3;
  padding-top: 7px;
}

  .noTagsTsWrap {
    display: flex;
    align-items: center;
  }
  .addTagToTsItemDropdown {
    color: #fff;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 500;
    padding-left: 7px;
    text-align: left !important;
    max-width: 80px;
    min-width: 80px;
    line-height: 20px;
    height: 35px;
    -webkit-appearance: none;
    /* background-position-x: 64px; */
    display: flex;
    cursor: pointer;
    border: 2px solid #dde9f9;
    /* background-color: #0bf; */
    background-color: white;
    color: #6a8bb1;
    background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
    background-position-x: 62px;
    margin-top: 5px;
    margin-left: 2px;
    margin-bottom:8px;
  }

  .addTagToTsItemDropdown:hover {
    border-color: #d7dfe9
  }

  .addingTsTagDiv{
    display: flex;
    align-items: center;
  }

  .tsCreateTagInput {
    min-height:35px;
    border-radius:5px;
    border: 2px solid #dde9f9;
    font-size:16px;
    margin-top:4px;
    margin-left:3px;
    color: #214978;
    font-weight:500;
    padding-left:4px;
    max-width:160px;
    padding-left:8px;
  }

  /* .tsCreateTagInput */

  .addTagTsAddBtn {
    background-color: #07f;
    border: #0469de 1px solid;
    color: white;
    font-weight: 500;
    font-size: 14px;
    border-radius: 5px;
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 9px;
    padding-right: 9px;
    margin-top: 1px;
    min-width: 54px;
    margin-left: 5px;
    cursor: pointer;
  }

  .addTagTsAddBtn:hover {
    background-color: #036ce4;
  }

  .x1411415 {
    min-height:-webkit-fit-content;
    min-height:-moz-fit-content;
    min-height:fit-content;
    max-height:-webkit-fit-content;
    max-height:-moz-fit-content;
    max-height:fit-content;
    margin-top:4px;
  }

  .x019481 {
    margin-right: -2px;
  }

  .makeTilWholeBtn {
    min-height: 35px;
    max-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    /* min-width: 96px; */
    font-size: 14px;
    text-align: center;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
    background-color: #0bf;
    border: 1px solid #0af;
    padding-left: 7px;
    padding-right: 7px;
    
}

    .makeTilWholeBtn:hover {
      background-color: #0af;
    }

    .x108401840 {
      margin-top:6px;
    }


    .x1084018402 {
      margin-top:2px;
    }

    .x0193011 {
      line-height: 12px;
      margin-bottom: -7px;    }


      .staffAbsLateStartSpan {
        margin-left: 5px;
        border-left: 1px solid #e0ebf8;
        padding-left: 5px;
      }


      .x4082 {
        margin-top: 0px;
        margin-bottom: 10px;
        /* border-bottom: 2px solid #bbcada; */
        border-radius: 7px;
        /* border-right: 2px solid #bbcada; */
        max-width: 92%;
        /* border-top: 2px solid #bbcada; */
        background: #5a497f;
        color: white;
        /* margin-bottom: 40px !important; */
        font-size: 13px;
        -webkit-animation-name: zoomModalIn !important;
                animation-name: zoomModalIn !important;
        -webkit-animation-duration: 0.25s !important;
                animation-duration: 0.25s !important;
        -webkit-animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55) !important;
                animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55) !important;
        margin-bottom: 25px;
        border: 1px solid #2c2144;
        max-height: -moz-fit-content;
        max-height: -webkit-fit-content;
        max-height: fit-content;
        min-height: -moz-fit-content;
        min-height: -webkit-fit-content;
        min-height: fit-content;
padding-bottom: 20px;
      }

      /* @keyframes slideInstructDown {
        from {
          max-height: 0;
          overflow: hidden;
        }
        to {
          max-height: 1000px; 
          overflow: hidden;
        }
      } */

      .x091011 {
        right: calc(50vw - 80px)
      }

      .hideInfoTxtClose {
        max-width: 40px;
        /* float: right; */
        /* margin-right: 30px; */
        /* margin-left: -60px; */
        cursor: pointer;
        padding:5px;
        /* margin-top: 10px; */
       
        }

     

        .hideInfoTxtClose:hover {
          opacity: 0.8;
        }

        .x082 {
          margin-top:-5px;
        }
.showInstructWrap {
  float: right;
  margin-right: 21px;
  margin-left: -170px;
  /* border: 1px solid #cec8d5; */
  color: #fff;
  display: flex;
  align-items: center;
  min-width: 35px;
  margin-top: -5px;
  border-radius: 4px;
  min-height: 29px;
  font-weight: 500;
  padding-left: 5px;
  font-size: 12px;
  /* background-color: #dbdaf4; */
  max-height: 35px;
  min-height: 35px;
  padding-right: 5px;
  font-size: 14px;
      
}
        .showInstructBtn {
          float: right;
          /* margin-right: 26px; */
          /* margin-left: -163px; */
          cursor: pointer;
          border: 1px solid #cec8d5;
          color: #fff;
          display: flex;
          align-items: center;
          min-width: 32px;
          /* margin-top: -5px; */
          border-radius: 4px;
          min-height: 29px;
          font-weight: 500;
          padding-left: 5px;
          font-size: 12px;
          background-color: #dbdaf4;
          max-height: 25px;
          min-height: 25px;
          padding-right: 5px;
          /* font-size: 14px; */
    
  
  }

  .periodsInstructWrap {
    margin-top:-24px;
  }


  .showInstructBtn:hover {
    background-color: #d2d1ec;
  }

  .showInstructDownWhiteImg {
    max-width: 10px;
    margin-left: 5px; }

    .whatAreTheseTitle {
      margin-bottom: 0px;
      color: #a388ec;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      align-items: center; }

      .showInstructHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 103%;
        margin-bottom: 8px;
      }

      .whatAreTheseQimg {
        /* background: #A388EC; */
        min-width: 16px;
        max-width: 16px;
        max-height: 16px;
        min-height: 16px;
        border-radius: 3px;
        padding: 2px;
        opacity: 1;
        /* border: 1px solid #7b51e9; */
        }

        .showInstructTitle {
          /* margin-left: 4px; */
          margin-top: 1px;
          color: white;
          opacity: 0.5;
          /* border-left: 2px solid white; */
          /* padding-left: 8px; */
        }

        .x131134 {
          margin-right:8px;
          min-height:35px;
        }


.filtImg {
  max-width: 15px;
  margin-left: -3px;
  margin-right: 0px;}

  .x19831 {
    margin-left:-6px;
    margin-right:-6px;
  }

  .absShUnpaid {
    /* border-radius: 5px; */
    border-left: 1px solid #e0ebf8;
    color: #bbcada;
    /* padding: 2px; */
    padding-left: 4px;
    /* padding-right: 4px; */
    font-size:10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    padding-left:6px;
    padding-right:3px;
    margin-top:2px;
  }

  .lateArrStart {
    color: #ba0000;
    font-weight: 600;
    font-size: 11px;
    margin-top: 2px;
    margin-left: 5px;
   }

.lateArrImg {
  max-width: 12px;
  margin-top:2px;
}

.x13141423 {
  margin-top:-2px !important;
}

.x1972141 {
  padding-left:0px
}

.teamHoursRotaClock {
  max-width: 10px;
  margin-right: 4px;
  opacity: 0.5;}

  .x08917471 {
    margin-bottom: 80px;
    margin-top: -90px;  }

    .x9749725 {
      margin-top:4px;
    }

    .x19749111 {
      margin-top:-15px;
    }

    .x1084971971 {
      margin-top: -13px;
      /* margin-left: 9%;    */
     }

      .x1974971 {
        margin-top: -108px;
        /* background-color: orange !important; */
        margin-bottom: 80px;
      }

      .x29749711 {
        margin-top: -103px;
        margin-bottom: 59px;      }

        .x08141 {
          border-top: 1px solid #c7e1ff
        }

  .x0841 {
    margin-left:calc(10% + 85px);
  }

  .x104811 {
    margin-top: -109px;
    margin-bottom: 78px;  }

    .x1794911 {
      margin-top:-13px;
    }

    .x2084971 {
      background:#214978;
      border-color:#143051;
      color:white
    }

    .x131453678 {
      margin-top:9px;
      line-height:12px;
    }

    .firstTeamShiftUnit {
      margin-top:21px;
    }


.x0193081041 {
  padding-top:0px;
  border-top:none;
}

.redMgrMins {
  background-color: #f5e5e5;
  color: #ba0000;
  border: 1px solid #dcc7c7;
}

.redMgrMinsHalf {
  color: #ba0000;
  background: linear-gradient(66deg, #f5e5e5 50%, rgb(247, 250, 255) 50%);
  border: 1px solid #dcc7c7;
}


.x2081371 {
  /* background-color: blue; */
  margin-top:15px;  
  /* margin-bottom:-10px */
}

.x08249 {
  margin-top:-3px;
}

.tsClockItemChild {
  max-width: 76px;
  margin-right:5px;
}

.x10813111 {
  font-weight: 500;
  color: #214978;
  margin-right: 5px;
}

.tsYourNote {
  font-weight: 500;
  color: #214978;
  margin-left: 2px;
  margin-top: -3px;
  border-top: 1px solid #e1e2e3;
  padding-top: 5px;
  padding-bottom: 4px;
}
.mgrTsNoteInput {
  background-color: #fff;
  width: 100%;
  max-width: 100%;
  line-height: 30px;
  padding-top: 0px;
  height: 35px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  text-align: left;
  color: #496375;
  font-size: 16px;
  font-weight: 500;
  /* -webkit-appearance: none; */
  /* -moz-appearance: none; */
  /* appearance: none; */
  cursor: text;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: flex-start; */
  padding-left: 8px;
  margin-bottom:4px
}

.x198131 {
  color: #ffff;
  background: #6412b3;
  border-radius: 3px;
  padding-left: 4px;
  padding-right: 4px;
  min-height: 25px;
  line-height: 21px;
  padding-top: 2px;
  padding-bottom: 2px;
}

  .x2849242 {
    color: #143051;
    font-weight: 400;
    border-radius: 3px;
    padding-left: 1px;
    padding-right: 3px;
    min-height: 25px;
    line-height: 21px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 10px;
  }

  .editingTsRecord {
    border-color: #00ccff;
    border-radius: 8px;
  }

  .x10830184 {
    background-color: #748dab;
    color: white;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 6px;
    padding: 7px;
    padding-left: 13px;
    padding-right: 3px;
    cursor: pointer;
    border:1px solid #496375
  }

  .x10830184:hover {
    background-color: #6c86a6;
  }

  .x208420 {
    border: #e1e2e3 1px solid;
    /* color: white; */
    max-width: -moz-fit-content;
    max-width: -webkit-fit-content;
    max-width: fit-content;
    border-radius: 5px 5px 0 0;
    margin-top: 10px;
    margin-bottom: -24px;
    padding: 7px;
    padding-left: 13px;
    padding-right: 1px;
    cursor: pointer;
    padding-bottom: 20px;
    margin-left: 0px; }

    .x208420:hover {
      background-color: #eaf0f8;
    }

 

    .showRecordedBtnDiv {
      background-color: #143051;
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 90%;
      border-radius: 5px;
      margin-left: 5%;
      font-size: 14px;
      text-align: left;
      padding-right: 4%;
      padding-left: 4%;
      cursor: pointer;
      padding-bottom: 4px;
      padding-top: 3px;
      margin-top: -6px;
      margin-bottom: 5px;
    }
    .showRecordedBtnDiv:hover {
      background-color: #112944;
    }

    .showRecLeft {

    }

    .showRecordOnlyBtnTxt {
      color:white;
      font-weight:600;
      margin-top:2px;
    }
    .showRecordOnlyBtnTxtSub {
      color: white;
      font-weight: 400;
      opacity: 0.8;
      font-size: 10px;
      line-height: 12px;
      margin-top: 0px;
      max-width: 190px;
    }
    .showRecRight {

    }

    .recordOnlyKnob {
margin-top:-7px !important
    }

    .tsClockStat {
      font-size: 10px;
      font-weight: 500;
      color: #9cb194;
      max-width: -moz-fit-content;
      max-width: -webkit-fit-content;
      max-width: fit-content;
      /* border-radius: 5px; */
      min-height: 16px;
      padding-left: 7px;
      padding-right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      /* background-color: #a8cc9a; */
      border-left: 2px solid #bbcada;
 
  
    }

    .x18318341 {
      margin-top: 5px;
      margin-bottom:4px;
      margin-left:3px
/* margin-bottom:-20px; */
    }
    

    .pendClockStat {

    color: #214978;

    min-height: 20px;
    margin-right: 20px;
    padding-left: 5px;
    }

    .declinedClockStat {
      /* background-color: #e89292; */
      color: #ba0000;
      border-left: 2px solid #ba0000;
    }

    .x2984291 {
      color: white;
    background-color: #2c5580;
    border-color: #143051;
    }

    .tsApprovalStatusDiv {
      display: flex;
      align-items: center;
      margin-top: 0px;
      margin-left: 9px;
      max-height: 22px;
      margin-bottom: 2px;
  
    }

    .approveTsClockBtn {
      color: white;
      background-color: #a8cc9a;
      border: 1px solid #9cb194;
      cursor: pointer;
      font-weight:500;
      min-height:20px;
      border-radius:5px;
      margin-left:5px;
      padding-left:4px;
      padding-right:4px;
      padding-top:2px;
      font-size:11px;

    }

    .approveTsClockBtn:hover {
      background-color: #9cb194;
    }

    .x197492 {
      background-color: #e89292;
      border: 1px solid #c57a7a;
      color:white
    }

    .x197492:hover {
      background-color: #c57a7a;

    }

    .x081981 {
      /* font-weight: 500;
      border: 2px solid #143051;
      border-radius: 5px;
      padding: 7px;
      margin-bottom: -20px;
      background: #214978; */
      color: #ba0000;
      margin-bottom:-10px;
    }

    .declinedTsHeaderColours {
      border: 1px solid #ba0000 !important;
      background-color: #c96565;
    }

    .approvedTsHeaderColours {
      border: 1px solid #3fa55d !important;
      background-color: #65c982;
    }

    .declinedTsBorder {
      border-left: 2px dotted #ba0000
    }

    .approvedTsBorder {
      border-left: 2px dotted #5a9e3f
    }

    .x091131 {
      color:#ba0000;
      border-left: 2px solid #ba0000;
      padding-bottom:3px;
    }

    .x140814 {
      margin-top: -3px;
      /* margin-left: -10px;   */
      }

      .approvedTsTxt {
        color: #82c26a
      }

      .x984292 {
        margin-top: 4px;
        margin-left: 0px;
        max-height: 22px;
            }


            .meJwtUser {
              border-top: 2px solid #bbcada;
              border-bottom: 2px solid #bbcada;
              border-left: 2px solid #bbcada;
              margin-left: 1px;
              padding-right: 2px;
              background: white;
              min-height: 71px;
              padding-left: 5px;
              margin-top: 3px !important;
              padding-top: 3px;
              margin-bottom:-3px
          }

          .x20910841 {
            min-height:63px  !important;
          }



          @media only screen and (max-width: 520px) {
     
          
            .staffRotaBtnMob {
              top: 30px !important;
              right: 22px !important;
            }

            .userSchedMgrAvailBtnMob {
           
              top: 30px;
              right: 125px;
          }

          .staffHoursBtnDynamic {
            top: 11px !important;
            right:-85px !important
          }
          }

          .meJwtUserMob {
            padding-left:0px;
          }

          .tsSuff {
            font-size: 10px;
            /* margin-bottom:12px; */
            margin-top:-2px;
            margin-right:3px;
          }

          .x1839181 {
            /* background: yellow; */
            margin-top:-7px;
          }

          .x109842082 {
margin-top: 6px;
          }


.staffAbsFilterBarRight {
  display:flex;
  align-items: center;
}

.absPerfIcon {
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  padding-left: 9px;
  font-weight: 500;
  padding-right: 9px;
  cursor: pointer;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  max-width: 33px;
  margin-right:8px;
}

.myAbsBtn {
  margin-top:14px;
}

.x10983180 {
  margin-top:24px;
}

.x983184 {
  min-height:150px;
}

.x1038198 {
  margin-left:0px;
}


.x10893011 {
  margin-bottom:4px;
  min-width: 190px;
  max-width: 190px;
  background-position-x: 164px;
}

.paddingLeft5pc {
  padding-left:5% !important
}

.x10924014 {
  margin-left:2px;
}

.wideTitle {
  margin-left:3px;
}

.x2038282 {
  min-width: 150px;
  max-width: 150px;
  background-position-x: 124px;
}


.x1941971 {
margin-top:-110px;
}

.x183103 {
  color: #214978
}

.colorBlue {
  color: #0077ff !important;
  border-color: #0077ff;
font-weight:600
}

  .tsGhostTitle {
    color: #bbcada;
    font-size: 12px !important;
    font-weight: 500;
    text-align: left;
    margin-left: -6px;
    margin-bottom: 4px;
    margin-top: 1px;  }

    .x208492 {
      font-size:12px
    }

    .x13141413 {
      font-weight: 500;
      text-align: left;
      margin-left: 6.5%;
      margin-top:8px;
    }

    .myAvailMonthViewUnderlay {
      
    
        background-color: rgb(122 122 122 / 10%);
      
    }

    .x29489292 {
      background-color: rgb(122 122 122 / 10%);

    }


    .availMonthViewModal {
      background-color: #f4f8fe;
      color: #143051;
      border-radius: 10px;
    
      min-width: 340px;
      max-width: 340px;
    
     /* -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);  */
      cursor: auto;
      min-height:400px;
      -webkit-animation-name: zoomModalIn;
              animation-name: zoomModalIn;
      -webkit-animation-duration: 0.1s;
              animation-duration: 0.1s;
    }

    .availMonthSelBar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 5%;
      padding-right: 6%;
      margin-top: 11px;
      border-top: 1px solid #dde9f9;
      padding-top:10px;
    }

    .availMonthSelArrLeft {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      min-width: 27px;
      padding: 11px;
      cursor: pointer;
    }

    .availMonthSelArrLeftDesktop:hover {
      /* opacity: 0.8; */
      background-color: #e0ebf8;
      border-radius:5px;
    }

    .availMonthSelArrRight {
      /* transform: rotate(180deg); */
      min-width: 27px;
      padding: 11px;
      cursor: pointer;
    }

    .availMonthSelArrRightDesktop:hover {
      /* opacity: 0.8; */
      background-color: #e0ebf8;
      border-radius:5px;

    }

    .availMonthMonthTitle {
      font-weight: 500;
      color: #214978;
      font-size:14px;
    }

    .monthAvailDayBar {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-weight:500;
      color: #bbcada;
      font-size:12px;
      padding-left:5px;
      padding-right:5px;
      margin-top:14px;
      max-width: 92%;
      margin-left: 4%;
    }

    .monthAvailFooter {
      display: flex;
      justify-content: space-between;
      padding-left:5%;
      padding-right:5%;
      border-top:1px solid #dde9f9;
      min-height:50px;
      align-items: center;
      /* margin-top:30px; */
    }

    .monthAvailSnake {
      /* background-color: orange; */
      min-height:300px;
      /* min-height:fit-content;
      max-height:fit-content; */
    }


    .myAvailWkRow {
    /* border-bottom: 1px solid black; */
    margin-top: 12px;
    min-height: 30px;
    /* background-color: blue; */
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 92%;
    margin-left: 4%;
    cursor: pointer;
    }

    .myAvailWkRow:hover {
      background-color: #dde9f9;
      border-radius:5px;
    }

    .myAvailCalDayItem {
      /* min-width: 14$; */
      font-weight: 500;
      font-size:14px;
      color:#214978;
      display:flex;
      align-items: center;
      justify-content: center;
      min-width: calc(14% - 2.85px);
      flex-direction: column
      /* background-color: grey; */
      
    }

    .monthAvailDayTitle {
      font-weight: 500;
      font-size:14px;
      color:#bbcada;
      display:flex;
      align-items: center;
      justify-content: center;
      min-width: calc(14% - 2.85px);
      /* background-color: grey; */
    }

    .availDayN {
      font-weight: 500;
      font-size:14px;
      color:#bbcada
    }

    .x314562 {
      color: #214978
    }

    .availSubmissionIcon {
      max-width: 9px;
      margin-bottom: 5px;
      margin-top: 3px;    }

      .x18391832 {
        border-radius: 0px 5px 0 0 !important
      }

      .x234223323 {
        position: fixed;
        top: 119px;
        right: 4.12%;
        z-index: 999
      }


      .x3298398 {
        border-radius: 5px 5px 0 0;
      }

      .shiftAmendUnit {
        min-width: 260px;
        background-color: #f4f8fe;
        border: 1px solid #dde9f9;
        border-radius:5px;
        margin-bottom:10px;
      }

      .amendBoldSpan {
        font-weight: 500;
      }

      .shiftAmendType {
        font-size:12px;
        line-height:18px;
        max-width: 80%;
        margin-top:8px;
        margin-left:10px;
        font-weight: 400;
      }

      .shiftAmendBy {
        font-size: 12px;
        max-height: 28px;
        font-weight: 500;
        color: #bbcada;
        margin-left: 10px;
        margin-top: -8px;
            }

            .shiftAmendWhen {
              font-size: 12px;
              max-height: 28px;
              font-weight: 500;
              color: #bbcada;
              margin-left: 10px;
              margin-top: -8px;
              margin-bottom: 4px;
              line-height: 37px
            }

                  .shiftAmendAck {
                    font-size: 12px;
                    max-height: 28px;
                    font-weight: 500;
                    color: #bbcada;
                    margin-left: 10px;
                    margin-top: -5px;
                    /* margin-bottom: 10px; */
                    display: flex;
                    align-items: center;
                       }

                    .acknowledgedAmendTickImg {
                      max-width:12px;
                      opacity: 0.5;
                      margin-right:5px;
                    }


                    .x274831 {
                      min-width: 42px;
                    }

                    .addLeaveModalBody {
                      min-height:540px  !important;
                      max-height:540px  !important

                    }

.shiftInLieuExplain {
  font-size: 12px;
  line-height: 16px;
  max-width: 200px;
  color: #516b8b;
  font-weight: 400;
  margin-left: 23px;
  margin-bottom: 10px;
  margin-top: -5px;
}

.tilLeftX00 {
  margin-left: -18px;
  margin-top: -18px;}

  .marginBottom20px {
    margin-bottom:20px;
  }

  .inLieuMyShiftTxt {
    color: #bbcada;
    font-size:12px;
    line-height:18px;
    margin-bottom:20px;
  }

  .shiftInlieuSpan {
    background-color: #ffffff;
    color: #00ccff;
    max-width: 94px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    /* border-radius: 5px; */
    min-height: 22px;
    margin-bottom: 6px;
    border-left: 2px solid #00ccff;
    font-size: 14px;
    padding-left:8px;
  }

  .declinedTsCol {
    color: #ba0000
  }

  .approvedTsCol {
    color: #a8cc9a
  }

  .x398294822 {
    margin-top: 2px !important;
    min-width: 90px;
    max-width: 90px;
    background-position-x: 68px;
  }

  .x3474782832 {
    min-width: 133px;
    max-width: 133px;
    background-position-x: 110px;
  }

  .partialAbsShiftboxWidth {
    min-width: 149px;
    max-width: 149px;
  }

  .manageClockOption {
    background-color: #b49ce7;
    border: 1px solid #a47fd0;
    min-height: 35px;
    max-height: 35px;
    color: white;
    display: flex;
    align-items: center;
    border-radius: 5px;
    font-weight: 500;
    font-size: 13px;
    padding-left: 10px;
    padding-right: 8px;
    cursor: pointer;
    margin-right: 8px;

  }

  .manageClockOption:hover {
    background-color: #a58fd4;
  }

  .manageClockOptionPen {
    max-width:10px;
    margin-left:6px;
  }

  .x2938 {
    border:none;
    border-bottom: 1px solid #bbcada;
    border-radius:0px;
  }

  .x249842 {
    background-color: #ffffff !important;
    border: 1px solid #f3f3f3;
    border-left: 2px solid #214978;
    border-radius: 0 10px 0px 10px;
    margin-left: 10px;
    border-bottom: 2px solid #214978;    
  }

  .x29834297 {
    background-color: #ffffff !important;
    border: 1px solid #e3e8ee;
    /* border-left: 2px solid #214978; */
    /* border-radius: 0 10px 0px 10px; */
    margin-left: 10px;
    /* border-bottom: 2px solid #214978;     */
  }

  .x2984212 {
    /* font-size:10px; */
  }

  .x92478922 {
    min-height:68px;
    /* margin-top:10px; */
  }

  .x29845922 {
    margin-top:-140px !important
  }

  .x2498293 {
    margin-top:-100px !important
  }

  .x139832 {
    margin-top:110px !important;
    /* margin-bottom:-10px; */
    /* background-color: blue; */
  }

  .x9242323 {
    margin-top:100px !important
  }

  .wholeShiftToilInfo {
    color: #516b8b;
    font-weight: 400;
    text-align: left;
    font-size: 12px;
    max-width: 150px; 
  line-height:16px;
  }
.toilAvailToTake {
  font-size:12px !important;
  opacity: 0.7;
  white-space: nowrap;

}

    .x198319821 {
   min-width: 170px;
   max-width: 170px;
   background-position-x: 147px;
    }

  .zoomClockCardOutUnit {
    -webkit-transform: scale(0);
            transform: scale(0); /* Adjust the scale value for the desired zoom level */

  }

  .clockDueButNotActiveIndicate {
    min-width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;  }

    .didNotClockTxt {
      font-weight: 500;
    padding: 2px;
    font-size: 11px;
    color: #ba0000;
    padding-left: 6px;
    padding-right: 6px;
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-top: -1px;
    border-right: 2px solid #ba0000;
    margin-bottom: 0px;
       }

       .todayAvailItemMark {
        background:#dde9f9;
        border-radius:5px;
        /* border:1px solid #bbcada; */
        /* margin-bottom:-1px; */
       }

       .tsItemUnit {
        margin-bottom:10px;
       }

       .x23256786 {
        margin-left:8px;
        margin-top:-1px;
       }

.x113198391 {
  /* background-color: blue; */
  /* margin-left:12% */
}

.x183989842 {
  /* background-color: blue !important; */
  margin-top:0px;
 
}

.x19374872 {
  min-height: calc(100vh - 203px);
  max-height: calc(100vh - 203px);
  padding-bottom:40px
}

.clockCardsDesktopFiltSplitter {
  min-height:1px;
  min-width:100%;
  background-color: #bbcada;
}

.x8765433 {
  /* background:orange; */
  margin-top:-4px;
}

.x183981982 {
  max-width: 130px !important;
  min-width: 130px !important;}

  .x283219831 {
    font-size:12px;
  }

  /* .font10 {
    
  } */

  .viewApprovedTsItem {

    max-width: -webkit-fit-content;

    max-width: -moz-fit-content;

    max-width: fit-content;
    padding-left:8px;
    padding-right:8px;
    color:white;
    font-weight:600;
    border-radius:5px;
    min-height:30px;
    max-height:30px;
    display:flex;
    align-items:center;
    justify-items:center;
    cursor: pointer;
    float:right;
    margin-bottom:8px;
    margin-right:6px;
    margin-left:4px;
    margin-top:10px;
    font-size:12px;
  }

  .viewTsItemPopUp {
    max-width:14px;
    margin-left:5px;
  }

  .x203912312 {
    display: flex;
    justify-content: space-between;
    min-width:100%
  }

  .x3895724 {
    font-size: 12px !important;
    line-height: 16px !important;
    font-weight: 400 !important;
    padding-right: 10px;
    max-width: 240px;
    margin-bottom: 30px !important;
  }

  .x1283983 {
    font-weight:500;
  }

  .x83198193 {
    background-color: #214978
  }

  .otherClockDivContainer {
    border: 2px solid #bbcada;
    display: flex;
    border-radius: 10px;
    max-width: 90%;
    margin-top: 0px;
    margin-left: 15px;
    min-height: 80px;
    justify-content: space-between;
  
  }

  .otherClockRight {
    border-left: 2px dashed #bbcada;
    /* border-radius: 10px 0 0 10px; */
    /* margin-top: -2px; */
    min-height: 80px;
   min-width:46%;
   max-width: 48%;;
    background: #f4f8fe;
    cursor: pointer;
    /* padding-bottom:20px; */
   }

   .otherClockRight:hover {
    background-color: #e5eaf2;
   }

   .otherClockCardRow {
    border-bottom:1px solid #e0ebf8;
    padding-bottom:20px;
   }

   .x08493 {
    color: #bbcada !important
   }

   .subClockStartEndTitle {
    font-size: 10px;
    font-weight: 400;
    margin-left: 8px;
    margin-right: 10px;
    margin-top: -10px;
    min-width: 62px;;
   }

   .subClockStartEndValue {
    font-size: 14px;
    font-weight: 500;
    margin-left: 8px;
    margin-right: 10px;
    margin-top: -28px;
    margin-bottom: -8px;
   }
   .subClockDivider {
    min-width: 90%;
    max-width: 90%;
    margin: 6px auto;
    margin-bottom: -9px;
    min-height:1px;
    background-color: #bbcada;
   }

   .subClockTitle {
    font-weight: 500;
    font-size: 11px;
    /* margin-left: 10px; */
    min-width: 100%;
    /* margin-top: -10px; */
    /* margin-right: 10px; */
    border-bottom: 2px solid #bbcada;
    line-height: 18px;
    min-height: 30px;
    display: flex;
    align-items: center;
    padding-left: 6px;
   }

   .otherClockLeft {
    min-width: 54%;
    /* background: #f4f8fe; */

    /* background-color: blue; */
   }



   .x123893 {
    min-width: 44%;
    cursor: pointer;
        background: #f4f8fe;

   }

   .x123893:hover {
    background-color: #e5eaf2;

   }
.x398142 {
  min-width: 56%;
  cursor: default;
  background-color: #fff !important; 


}

.x398142:hover {
  background-color: #fff !important; 
}
   .ccTitleSpan {
    color: #bbcada;
    margin-right:5px;
    
   }

   .subClockDurStrip {
    background: #fff;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    font-weight: 500;
    font-size: 11px;
    border-radius: 5px;
    max-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 4px;
    padding-right: 4px;
    margin-top: -1px;
float:right;
margin-right:6px;
border: 1px solid #bbcada;   }


.oneOfTwoOneClock {
  border-bottom: none  !important;
  padding-bottom:7px
}

.oneOfTwoOneClock:hover {
  padding-bottom:5px

}

.oneOfTwoTwoClock {
  border-top:1px dotted #bbcada !important;
  margin-top: -5px;
  border-radius: 0 0 5px 5px;
}

.oneOfTwoTwoClock:hover {
  border-top:1px dotted #bbcada !important;

}

.x937179343:hover {
  padding-top:0px;
  padding-bottom:6px

}


.x49578274 {
  max-width: 10px;
  min-width: 10px;
  margin-right: 7px;}


  .x3848848482 {
    min-height:181px; 
    margin-bottom:-50px;
  }

  .x485487597252 {
    background-color: #8ea7c5;
    min-width: 19px;
    max-width: 19px;
    min-height: 40px;
    margin: 0 auto;
    float: left;
    margin-left: 100px;
    border-left: 1px solid #859dbb;
    border-right: 1px solid #859dbb;
    display:none
  }

  .subClockStatus {
    font-size:12px;
    font-weight: 500;
    margin-top:-14px;
    margin-bottom:-6px;
    margin-left:8px;
    color: #bbcada
  }

  .ccOffDs {
    color: #bbcada;
    font-size: 10px;
    max-height: 20px;
    line-height: 10px;
    margin-top: -3px;
    margin-bottom: 7px;
    display: flex;
    align-items: center;
    /* margin-right: 5px; */
 }

.ccOffDsBtn {
  max-width: 16px;
  margin-left:3px;
  border:1px solid #bbcada;
  border-radius:3px;
  cursor: pointer;
}

.ccOffDsBtn:hover {
  background-color: #e9f3fe;
}

.ccOffDsBtn:first-of-type {

  margin-left:5px;
}



.absenceLogPopup {
  max-width: 13px;
  margin-left: 3px;

}

.selfLogAbsColours {
  background-color: #e89292;
  border: 1px solid #9e7373;}

  .selfLogAbsColours:hover {
    background-color: #da8787;
  }

  .x13456783212 {
    resize:none;
    min-height:120px  !important;
   padding:10px;
    margin-bottom:20px;
  }

  .x4978284432 {
    background-color:#0077ff !important;
    border:#0469de 1px solid !important
  }
  .x4978284432:hover {
    background-color: #036ce4;
  }

  .x1389498523 {
    color: #143051;
    font-weight: 500;
    font-size: 15px;
    margin-top: 8px;
    margin-bottom: 4px;
    line-height: 19px !important;
    padding-right: 40px; }

    .x3928423 {
      font-size: 12px;
      color: #bbcada;
    }

    .x39284232 {
      font-size: 12px;
    color: #143051;
    font-weight: 400;
    }

    .canxSelfAbsenceBtn {
      border: solid 1px #bbcada;
      background-color: #cbd7e6;
      min-width: 70px;      
    }

    .canxSelfAbsenceBtn:hover {
      background-color: #bbcada;      

    }

    .x48924892875 {
      border: 2px solid #ba0000;
      border-radius: 5px;
      margin-top: -3px;
      background: white;    }

      .selfLogApproval {
        color: #143051;
        font-size: 10px;
        border-left: 2px solid #143051;
        padding-left: 7px;      }

.reportedAbs1 {
  font-size: 12px;
  margin-left: 5px;
  color: #516b8b;
  font-weight: 400;
  border-left: 2px solid #516b8b;
  padding-left: 7px;
  max-height: 20px;
  line-height: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
}

  .reportedAbs2 {
    font-size: 14px;
    margin-left: 5px;
    line-height: 16px;
    color: #214978;
    margin-top: -6px;
    margin-bottom: 14px;
    padding-right: 13px;
    font-weight: 400;
  }

  .x24859382 {
    margin-top:-3px;
    border: 2px solid #ba0000 !important
  }

  .selfLogAbsOptionsDiv {
    border-top: 1px solid #bac5d3;
    max-width: 95%;
    padding-bottom: 13px;  }

  .selfLogAbsOption {
    /* border: 2px solid #8ea7c5; */
    border-radius: 5px;
    /* background: #bbcada; */
    color: white;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    max-height: 30px;
    display: flex;
    align-items: center;
    margin-top: 9px;
    background-color: #0af;
    border: 1px solid #0f94d6;
    cursor: pointer;
  }

  .selfLogAbsOption:hover {
    background-color: #05a5f5;
  }

  .x1998423 {
    border: solid 1px #bbcada;
    background-color: #cbd7e6;

  }

  .x1998423:hover {
    background-color: #bbcada;
  }

  .x09897534 {
    font-size: 14px;
    border-left: 2px solid #ba0000;
    max-height: 50px;
    display: flex;
    align-items: center;
    padding-left: 7px;
    margin-top: 10px;
    margin-bottom: -2px;
    line-height: 16px;
    padding-right:40px;
    color: #ba0000;  }

    .selfLogAbsIcon {
      background-color: #ba0000;
      padding: 2px;
      padding-top:4px;
      padding-bottom: 4px;
      max-height: 18px;
      border-radius: 3px;    }


      .x2894895834 {
        min-width: 344px;
        max-width: 344px;
        justify-content: flex-end;
      }

      .reportAbsColMySh {
        color: #fff;
        background: #ff8383;
        border: 1px solid #c02f2f;      }



        .selfLoggAbsColours {
          background-color: #ba0000;
          border-color:#8d0000
        }

        .timeHoursImgAbs {

          background-color: #ba0000;
          border-radius:3px;
          padding:4px;
          border:#8d0000 1px solid

        }


        .x19839842 {
          min-width: 100vw  !important;          max-width: 100vw  !important;

        }

        .deleteShiftBin {
          max-width: 15px;
        }

        .x991199 {
          max-width: 158px;
          min-width: 158px;
          height: 35px;
          background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
          background-position-x: 136px

        }

        .x9098 {
          margin-left:15px !important;
        }

        .x09838444 {
          margin-top:-73px;
        }

        .x9991112293312 {
          min-height:90px
        }

        .x18381812 {
          margin-top:8px
        }

        .shiftChangeFlex {
          display: flex;
          justify-content: space-between;
align-items: center;  
border-top:1px solid #e6eaee;
}

        .oldShChange {
          border:1px solid #bbcada;
          border-radius:5px;
          max-width: 180px;
          color: #143051;
          padding-left:8px;

          
        }

        .oldShChTitle {
          font-size:12px;
          font-weight:500;
          max-height: 30px;
        }

        .oldShChValue {
          font-size:12px;
          font-weight:500;
          max-height: 30px;
        }

        .x0912844 {
          line-height: 23px;
          padding-bottom: 16px;
        }

        .x4264723 {
          padding-bottom: 15px;
          line-height: 24px;        }

  .x874722 {
    line-height: 28px;
    padding-bottom: 9px;  }

    .x11221 {
      box-shadow:0px 0px 0px #fff !important;
      /* border:1px solid #efefef; */
      cursor: default;
      border-radius:5px;
      padding-left:0px;
      padding-right:0px;
      min-width: 120px !important;
      max-width: 100px  !important;
    }

    .x11221:hover {
      /* border: 1px solid #efefef */
    }

    .chMgrTsMade {
      font-size:12px;
      margin-left:5px;
      color:#bbcada;
      
      font-weight: 500;
      line-height:19px;
      margin-bottom:4px;
    }

    .x323425 {
      color:#bbcada
    }

    .x12144 {
      color:#214078;
    }

    .loadingAvail {
      opacity: 0.2;
      pointer-events: none;
    }

    .x9939232 {
      font-size:10px;
    }

    .x88381831 {
padding-right: 20px !important    }


.addTilInfoTxt {
  font-weight: 400;
  font-size: 12px;
  color: #516b8b;
  line-height: 16px;
  text-align: left;
  padding-right: 24px;
}

.addShiftMidContainerTil {
  min-height:400px !important;
  max-height:400px !important;
  
}

.plusDxx {
  color:#bbcada;
  font-size:14px
}

.mobTeamShiftBgColour {
  background-color: #403a8e !important;
  /* border-top: 1px solid #403a8e */

}

.mobMyRotaBgColour {
  background-color: white !important;
}

.noFiltTagsTxt {
  color: #bbcada;
  max-width: 190px;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  margin-top: 14px;
  margin-left: 15px;}

  .mobModalWeekTitle {
margin-left:55px;  }


.x389248754 {
margin-left:17px;}

.x28394293 {
margin-left:52px}

.x29393883423 {
border-left: 2px solid #a8cc9a;
color: #a8cc9a
}

.x2456732 {
  margin-top:4px;
  border-top:1px solid #fff;
  padding-top:4px;
}

.usrUnavail {
  color:#516b8b;
  font-weight:500 !important;
  font-size:12px !important;
  cursor: pointer;
  /* border:1px solid #bbcada; */
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  float: right;
  border-radius:5px;
  padding-left:5px;
  /* padding-right:5px; */
 
}

.usrUnavail:hover {
  color: #143051;
}

.z882132 {
  padding-right:0px  !important;
}

.chMgrDivCtaDiv {
  display: flex;
  justify-content: flex-start;
  margin-top:10px;
}

.approveShCh {
  min-height:35px;
  max-height:35px;
  display:flex;
  align-items:center;
  justify-content: center;
font-weight:500;
min-width: 100px;
border-radius:5px;
background-color: #a8cc9a;
border: 1px solid #9cb194;
    color:white;
    cursor: pointer;
    font-size:14px;
}

.approveShCh:hover {
  background-color: #9cb194;
}


.declineShCh {
  min-height:35px;
  max-height:35px;
  display:flex;
  align-items:center;
  justify-content: center;
font-weight:500;
min-width: 100px;
border-radius:5px;
background-color: #e89292;
border: 1px solid #9e7373;
    color:white;
    cursor: pointer;
    margin-left:10px;
    font-size:14px;

}

.declineShCh:hover {
  background-color: #da8787;
}





.newOldDurTxt {
  font-size:12px;
  font-weight:500;
  margin-left:5px;
  line-height:24px;
}

.newOldDurValueSpan {
  color:#859dbb;
}

.calLimItem {
  /* background:orange; */
  min-width: 40px;
  max-width: 40px;
  min-height:66px;
  /* border-left:1px solid black; */
  /* border-right:1px solid blue */
}

.staffCalLimTxt {
  color:white;
  font-weight:500;
  font-size:14px
}

.staffCalDisabledIndicate {
  font-size:10px;
  opacity: 0.65;
}

.x29283824122 {
  cursor: pointer;
  padding: 4px;
  border: 1px solid #30305f;
  border-radius: 5px;
  min-width: 22px;
  max-width: 22px;
  background-color: #7a7ac1;
}

.x1738h121 {
  margin-top:4px
}

.calLimItemLimEnabled {
  min-width: 80px;
  max-width: 80px;
  min-height:108px;
  /* border-left:1px solid #bbcada; */
  border-right:1px solid #716994;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.viewTeamDivItemLimEnabled {
  color: white;
  margin-left: 20px;
  margin-right: 20px;
  min-width: 40px;
  max-width: 40px;
  font-size: 9px;
  font-weight: 500;
  line-height: 9px;
  padding-top: 1px;
  padding-bottom: 1px;
  margin-bottom: 3px;

}

.calCommDivLimEnabled {
  min-width: 80px;
  border-right:1px solid #bbcada;

}

.staffCalDayLtrItemLimEnabled {
  /* background:purple; */
  min-width: 80px;
  max-width: 80px;
  border-right:1px solid #bbcada;

}

.staffCalTeamSnakeBlockLimEnabled {
  min-width: 80px;
  max-width: 80px;
  border-right:1px solid #bbcada;
}

.xx372874821 {
  border-left:none;
}

.x183291x29191 {
  margin-top:5px;
  max-width: 24px;}

  .staffCalLimTitle {
    color:white;
    font-size:11px;
    font-weight:500;
    text-align:left;
    margin-top:7px;
    text-align: center;
    /* margin-left:4px; */
  }

  .calLimitPowerImg {
    background-color: #6a8bb1;
    max-height: 26px;
    min-height: 26px;
    min-width: 26px;
    max-width: 2px;
    border-radius: 5px;
    border: 1px solid #143051;
    cursor: pointer;
    opacity: 0.7;
    margin-top: 4px;  }

    .calLimitPowerImg:hover {
      background-color:#668983
    }


  .calLimPowerOn {
    background-color: #7fbb67;
    border: 1px solid #2a501b;

    margin-top: 2px;
    max-height: 21px;
    min-height: 21px;
    min-width: 21px;
    max-width: 21px;
    border-radius: 4px;
    opacity: 1;
    cursor: pointer;  }

    .calLimPowerOn:hover {
      background-color: #7fbb67;
    }

    .x00334422 {
      background-color: #7fbb67;
      border: 1px solid #2a501b;
    }

    .calLimDrop {
      color: #fff;
      border-radius: 3px;
      font-size: 11px;
      padding-left: 4px;
      text-align: left;
      max-width: 48px;
      min-width: 48px;
      line-height: 19px;
      height: 21px;
      /* background: url(/static/media/downArrowWhiteTeamSched.358e1815.svg) no-repeat right #7b7aab; */
      border: 1px solid #143051;
      -webkit-appearance: none;
      font-weight: 500;
      cursor: pointer;
      margin-top: 3px;
      margin-left: 2px;
      background: url(/static/media/downArrowWhite2.887d57ce.svg) no-repeat right #7b7aab;
      background-position-x: 34px;

    }

    .calLimPowerAndDrop {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top:2px;
    }

    .calDayLimInfo {
      font-size: 10px;
    color: white;
    opacity: 0.6;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 3px;
    padding-left: 5px;
    padding-right: 5px;
    }

    .calDayLimInfoJt {
      margin-top:12px;
      max-width:100px;
    }
    .calTeamCurrOff {
      color: white;
      font-weight: 500;
      margin-top: -2px;
      font-size: 12px;    }

      .xi138187 {
        margin-top: 5px;
        border-top: #716994 1px solid;
        padding-top: 1px;      }

        .x9ood23 {
          opacity: 0.2;
          pointer-events: none;
cursor: default;        }

.calTeamCurrOffLimExceeded {
  background-color: #f07b7b;
  /* border-color: #460f0f; */
  padding-left: 6px;
  padding-right: 6px;
  margin-bottom:3px;

  font-size:12px;
  /* padding-top: 2px; */
  padding-bottom: 2px;
  max-height:16px;
  line-height:13px;
  border-radius:5px;
  border: 1px #e56767 solid;}

  .c29838 {
    margin-top:20px;
  }

  .limArrow {
    max-width: 10px;
    opacity: 0.7;
    margin-top: 10px;  }

    .jtLimRow {
      /* background:orange; */
      min-height:30px;
      display: flex;
      max-height:30px;
      padding-left:10px;
    }
    .staffCalJtPushDownJtLimActive {
      /* margin-bottom:30px; */
      min-height:66px;
      display: flex;
      align-items: flex-end;
      padding-bottom:10px;  
      /* margin-top:30px; */
    }

    .jtLimBox {
      /* border-left:1px solid black; */
      border-right:1px solid #bbcada;

      min-width: 80px;
      max-width: 80px;
    }
    .jtLimitPowerIcon {
      background-color: #bfd1e6;
      max-height: 21px;
      min-height: 21px;
      min-width: 21px;
      max-width: 21px;
      border-radius: 3px;
      border: 1px solid #98b8dc;
      cursor: pointer;
    }

    .calJtBox {
      display: flex;
      align-items: center;
      min-width: 100%;
      min-height: 100%;
      justify-content: space-between;
      padding-left: 4px;
      padding-right: 6px;
      /* border-right: 1px solid #bbcada; */
      /* border-left: 1px solid #bbcada; */
  

    }

    .calJtBoxTxt {
      color:#6a98d1;
      font-weight:500;
      font-size:10px;
      margin-left:4px;
    }

    .jtPowerLimOn {
      background-color: #7fbb67;
      border: 1px solid #5a9e3f;
      min-width: 14px;
      max-height: 21px;
      min-height: 21px;
      margin-top: 4px;
      margin-right: 4px;
      }

.calJtBtnsDiv {
  display: flex;
  align-items: center;
  margin-left:0px !important;
  margin-top:-2px;
  /* background-color: white; */
}

.calJtBtnMinus {
  max-width:20px;
  border-radius:4px;
  cursor: pointer;

}

.calJtBtnMinus:hover {
  background-color: white;
  border:1px solid #ececec
}

.calJtBtnsMidTxt {
  font-size: 13px;
  color: #bbcada;
  margin-left: 3px;
  margin-right: 3px;
  font-weight: 500;
}

.jtLimWidth {
  min-width:130px;
  max-width: 130px;
}

.jtLimDsViewWidth {
  margin-left: 45px;

  margin-right: 45px;}

  .offSmall {
    font-size:8px;
    font-weight:400;
    margin-left:-1px
  }

  .navyDivider {
    background-color: #143051 !important;
    opacity: 1 !important;
    min-height:4px;
  }
  .hoveredIndexCal {
    /* background-color: #e7e7f3; */
    /* border-left: #143051 solid 3px !important;
    border-right: #143051 solid 3px  !important;  */

  }

  .closedTxtColour {
    color:#abb0c6
  }

  .closedLimBg {
    background-color: #635e7f;
  }

  .xj1389 {
    margin-left:0px !important;
    margin-right:0px  !important
  }

  .xxx198294 {
    margin-left:-6px !important
  }

  .printRotaBtn {
    margin-right: 8px;
    margin-top: 8px;
    min-height: 35px !important;
    margin-left: -1px !important;
  }

  .printRotaBtn:hover {
    color: #859dbb !important
  }
 

  .dayTitleHeadline {
    margin-right:7px;
  }

  .hh88 {
    /* background-color: black; */
  }

  .hh99 {
    /* min-height:10px */
  }

  .shareRotaPassInput {

    color: #214978;
    min-height: 35px;
margin-top:8px;
    font-weight: 500;
    margin-left: 5%;
    min-width: 150px;
    font-size: 16px;
    border: 2px solid #dde9f9;
    border-radius: 5px;
    padding-left: 7px;
    background-color: #fdfeff;
    margin-bottom:12px;
  }

  .sharePassDiv {
    display: flex;
    align-items: center;
  }

  .showSharePassImg {
    background-color: #8ea7c5;
    border: 1px solid #516b8b;
    border-radius: 5px;
    padding: 5px;
    max-height: 30px;
    margin-top: -5px;
    /* margin-left: 8px; */
    cursor: pointer;
  }

  .showSharePassImg:hover {
    background-color: #859dbb;
  }

  .showPassViewShare {
    background-color: #214978;
    border: 1px solid #143051
  }

  .showPassViewShare:hover {
    background-color: #143051;
  }

  .shareRotaCta {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 68px;
    padding-left: 6px;
    padding-right: 6px;
    min-height: 35px;
    max-height: 35px;
    color: #fff;
    border: solid 1px #bbcada;
    background-color: #cbd7e6;
    border-radius: 5px;
    cursor: pointer;  }

    .shareRotaBack {
      padding-left: 3px;
      padding-right: 3px;
      min-width: 57px;
    }
    .shareRotaCta:hover {
      background-color: #bbcada;
    }

    .marginLeft8 {
      margin-left:8px;
    }

    .sharablelink {
      color: #214978;
      font-size: 13px;
      margin-left: 15px;
      font-weight: 500;
      margin-top: 11px;
      margin-bottom: 20px;
      -webkit-user-select: text;
      -ms-user-select: text;
          user-select: text;
      cursor: text;
      border: 2px solid #bbcada;
      border-radius: 5px;
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 10px;
      padding-right: 10px;
      background: #f4f8fe;  }

    .p183141 {
     color: white;
     font-size: 12px;
     font-weight: 500;
     min-width: 45px;
     display: flex;
     justify-content: center;
     height: 28px;
     margin-top: 0px;
      }

    .copiedLink {
      color: #143051;
      font-size: 12px;
      font-weight: 500;
      margin-top: -8px;
      margin-left: 12px;    }

      .x384829x {
        min-width: 96px;
      }

      .saveRotaPass {

        background-color: #00ccff;
        border-color: #05aaff;
        margin-left: 5px;
        padding: 6px;
      }

        .saveRotaPass:hover {
          background-color: #00a6ff;
        }

        .shareRotaIconsDiv {
          display: flex;
          justify-content: space-between;
          min-width: 212px;
          max-width: 212px;
          margin-left: 16px;
          margin-bottom: 10px;
          margin-top: -10px;
        }

        .shareRotaShareBtn {
          border: solid 1px #0af;
          background-color: #00ccff;
          display: flex;
          align-items: center;
          height: 28px;
          border-radius: 5px;
          font-weight: 500;
          font-size: 12px;
          padding-left: 7px;
          padding-right: 4px;
          cursor: pointer;
          color:white !important
        }

        .shareRotaShareBtn:hover {
          background-color: #00a6ff;
          
        }

        .shareRotaIconImg {
          max-width: 14px;
          margin-left:4px
        }
    
        .emailWhite {
          max-width: 12px;
        }

        .x248928742 {
          margin-left: 8px;
          /* min-width: 76px !important; */
          padding-left: 8px;
          padding-right: 8px;        }

          .shareRotaInfoWarn {
            font-size: 12px;
            color: #859dbb;
            margin-left: 16px;
            margin-top: 10px;
            padding-right: 30px;
            border-left: 2px solid #859dbb;
            padding-left: 10px;
          }

          .rotaTitleStandalone {
            font-weight:600;
            color:#143051;
    

        margin-top: -12px;
        margin-left: 19px;
        font-size: 18px;
          }

          .rotaTitleStandaloneMob {
            margin-left:21px
          }


          .rotaTitleStandaloneMobSmall {
            font-size: 14px;
            line-height: 13px;
            /* max-width: 70px; */
            margin-top: -17px;
            margin-left: 21px;
          }
           


.standaloneRotaStrip {
  position: fixed;
  top: 33px;
  left: 21px;
  font-size: 12px;
  color: #516b8b;
  font-weight: 500;
}

.standaloneLoggedIn {
  height: 35px;
  font-weight: 500;
  padding-left: 10px;
  text-align: right;
  line-height: 18px;
  background: #214978;
  border: 1px solid #143051;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  padding-right: 10px;
  font-size: 14px;
  cursor: pointer;
}

.standaloneLoggedIn:hover {
  background-color: #143051;
}

.frLogoRota {
  max-width: 100px;
  cursor: pointer;
  margin-top:8px;
}
     

.shareLinkBg {
  background-color: #dde9f9;
}

.linkNotActive {
  font-size: 12px;
  color: #496375;
  margin-left: 23px;
  padding-right: 30px;
  margin-bottom: 35px;
  margin-top: 30px;
}

  .linkNotYetActiveIndicate {
    color: #bbcada;
    font-weight: 500;
    font-size: 14px;
    height: 24px;
    margin-left: 14px;
    border-left: 2px solid #bbcada;
    display: flex;
    align-items: center;
    padding-left: 10px;
    margin-top: 5px;  }

    .linkActive {
      color: #bcdabb;
      border: none;
      padding-left: 0px;
      font-size: 12px;    }

      .inactiveRotaLinkHeroImg {
        max-width: 500px;
        margin-top:30vh;
      }

      .inactiveRotaLinkHeroImgMob {
        /* width: 400px !important; */
        width: 75% !important;

      }

      .rotaLinkBrokenTxt {
        font-size:24px;
        font-weight: 500;
        margin-top:30px;
        color:#143051
      }

      .shareGreyImg {
        max-width: 12px;
        margin-left: 5px;      }

        .x3289292d {
          max-width: 14px;
        }

        .x3289292x {
          max-width: 13px;
          margin-left:7px;
        }

        .addLeaveMyRota {font-size:24px;
        color:#bbcada;
        font-weight: 500;
        margin-left:5px;
        margin-top:-8px;
        height:22px;
        display: flex;
        align-items: center;
        justify-content: center;
        width:22px;
        border:1px solid #fff;
        border-radius:5px;
        margin-right:5px;
padding-bottom:3px;
cursor: pointer;
/* background-color: #d1dfee; */

        }

        .addLeaveMyRotaLarge {font-size:30px;
          color:#bbcada;
          font-weight: 500;
          margin-left:5px;
          margin-top:-8px;
          height:42px;
          display: flex;
          align-items: center;
          justify-content: center;
          width:62px;
          border:1px solid #fff;
          border-radius:5px;
  padding-bottom:3px;
  cursor: pointer;
  /* background-color: #d1dfee; */
  
          }

          .addLeaveMyRotaLarge:hover {
            background-color: #f6f9ff;
            border:1px solid #cbd7e6;
          }

        .addLeaveMyRota:hover {
          background-color: #f6f9ff;
          border:1px solid #cbd7e6;
        }

        .reqsBtnSub520 {
          position: fixed;
    top: 74px;
    right: 14px;
        }

        .inactRotaLogo {
          max-width: 19px;
          margin-top:68px;
          margin-bottom:-33px;
          margin-left:64px;
        }
        .inactRotaLogoTxt {
          max-width: 120px;
          /* margin-top:-10px; */
          /* margin-top:2px; */
        }

        .viewDayPopUp {
          max-width: 12px;
          margin-left:3px;
          opacity: 0.8;
        }

        .bulkModalFooterLeft {
          display: flex;
          align-items: center;
        }

        .x24897277101 {
          margin-left: 10px;
          border: solid 1px #bbcada;
          background-color: #cbd7e6;
        }

        .x24897277101:hover {
          background-color: #bbcada;
        }

        .editedJtLimBubble {
          min-width: 4px;
          background-color: #00ccff;
          min-height: 35px;
          border-radius: 3px;
          margin-right: 5px;
        }
        .colorWhite {
          color: white !important;
        }

        .rotaPassInputDiv {
          /* display: flex; */
          /* align-items: center; */
          max-width: 250px;
          margin: 250px auto;
        }

        .rotaPassTitle {
          font-weight: 500;
          color: #214978;
          font-size: 14px;
          margin-bottom: 10px;        }

          .rotaPassInputter {
            color: #214978;
            min-height: 35px;
            /* margin-top: 8px; */
            font-weight: 500;
            /* margin-left: 5%; */
            min-width: 150px;
            font-size: 18px;
            border: 2px solid #dde9f9;
            border-radius: 5px;
            background-color: #fdfeff;
        text-align: center;
          }

        .rotaPassEnter {
          border: solid 1px #0af;
          background-color: #00ccff;
          display: flex;
          align-items: center;
          height: 35px;
          border-radius: 5px;
          font-weight: 500;
          font-size: 14px;
          padding-left: 13px;
          padding-right: 14px;
          cursor: pointer;
          color: white !important;
          cursor: pointer;
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;
          margin: 17px auto;
        }

          .rotaPassEnter:hover {
            background-color: #00a6ff;
          }

          .wrongPassRota {
            color: #bbcada;
            font-size: 14px;
            font-weight: 500;
            margin-top: 10px;          }

            .capsLockTxt {
              color: #bbcada;
              font-weight: 500;
              font-size: 12px;
              margin-top: 8px;
              margin-bottom: -7px;            }


              .x237858728128121:hover {
                background-color:#e7e7e7  !important
              }


              .x10839242782d2:hover {
                background-color:#f7f7fd  !important
              }


.x2831981s1981 {
  background-color: #f4f8fe;
  color: #516b8b;
  line-height: 17px;
  min-height: 17px;
  border: 1px solid #cbd7e6;
  margin-top: 5px;
  border-radius: 3px;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 0px;
  font-weight: 500;

}              

.spanNoBreakLine {
  white-space: nowrap;
}

.myShiftItemMarginRight {
  margin-left:4px;
}

.x9090zz {
  margin-left:-4px
}

.collectionWithoutAvail {
  /* background-color: blue; */
  min-height: 92px !important;
  max-height: 92px !important;
margin-bottom: 9px;
}

.secondaryWithAvail {
  /* background-color: yellow; */
  /* min-height:86px !important;
  max-height:86px !important; */
  /* margin-top:-1px; */
}


.secondaryWithOutAvail {
  min-height: 85px !important;
  max-height: 85px !important;
}

.jtUserWrap {
  /* background-color: pink; */
  /* border-bottom:1px solid black; */
  min-height:100px !important
}

.schedItemHeight {
  /* background-color: pink; */
  /* border-bottom:1px solid black; */
  min-height:100px !important;
  
}

.v7178273 {
  /* background-color: blue; */
  margin-top: 3.5px;

}

.x91dd9 {
  /* background-color: purple !important; */
  margin-top:20px;
}

.x9910ss {
  /* background:yellow; */
  margin-top: -12px !important;
}
.hoveredUserRow {
  /* background-color: yellow !important;
  max-height:100px  !important;
  min-height:100px  !important; */
  /* margin-bottom:20px; */
  /* margin-top:-20px; */
  /* margin-bottom:20px; */

}

.hoveredUserRow:hover {
  /* background-color: #e0ebf8; */
}

.bulkEditLimitModalBoxMob {
  /* min-height:90vh;max-height:90vh; */
}

.templateUnitName {
  font-size:14px;
}

.activeTsInfo {
  font-weight: 500;
  font-size: 12px;
  color: #859dbb;
  line-height: 17px;
  text-align: left;
  margin-left: 24px;
  margin-top: 13px;
  margin-bottom: -7px;
}

.itemNameTabImg {
  max-width: 20px;
  min-height:20px;
  max-height:20px;
  min-width: 20px;
  object-fit: cover;
  border-radius: 3px;
  margin-right: 8px;}

  .aa22 {
    position: fixed;
    color:white;
    top:0;left:0;
    background-color: blue;
    height:20px;
    width:100px;
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modalSwiper {
    min-height: 3px;
    min-width: 50px;
    max-width: 50px;
    background-color: #bbcada;
    margin: 0 auto;
    margin-top: -11px;
    border-radius: 5px;
    margin-bottom: 8px;
  }
  .modalSwiperMakeLeaveReq {
    margin-top: 9px;
    margin-bottom: -12px;
  }

  .mobMenuModalSwiper {
    margin-top:10px;
    background-color: #143051;

  }

  .modalSwiperClockTimeline {

    margin-top: 9px;
    margin-bottom: -12px;
  }
  .modalSwiperActiveClocks {

    margin-top: 9px;
    margin-bottom: -12px;
  }
  .modalSwiperDecline {

    margin-top: -8px;
    margin-bottom: 3px;
  }
  .modalSwiperStaffAllow {

    margin-top: 9px;
    margin-bottom: -12px;
  }
  .modalSwiperAddTags {

    margin-top: 9px;
    margin-bottom: -12px;
  }
  .modalSwiperLogAbs {

    margin-bottom: 1px;
    margin-top: -6px;
  }
  .modalSwiperDaycomm {
    margin-top: 9px;
    margin-bottom: -12px;
  }

  .modalSwiperViewUserDay {

    margin-top: 9px;
    margin-bottom: -12px;
  }



  .modalSwiperAddAvail {
    margin-top: 9px;
    margin-bottom: -14px;
  }

  .modalSwiperAddLeaveModal {
    margin-top: 2px;
    margin-bottom: -9px;
  }

  .slideUp {
    -webkit-animation-name: moveEditTeamUserBoxUp !important;
            animation-name: moveEditTeamUserBoxUp !important;
    -webkit-animation-duration: 0.1s !important;
            animation-duration: 0.1s !important;
   

  }
  @-webkit-keyframes moveEditTeamUserBoxUp {
    from {
      bottom: -100%;
      opacity: 0;
    }
    to {
      bottom: 0;
      opacity: 1;
    }
  }
  @keyframes moveEditTeamUserBoxUp {
    from {
      bottom: -100%;
      opacity: 0;
    }
    to {
      bottom: 0;
      opacity: 1;
    }
  }

  .modalSwiperAddShiftModal2 {
    margin-top: 7px;
    margin-bottom: -14px;
  }

  .modalSwiperAddUser {
    margin-top: -8px;
    margin-bottom: 1px;
  }

  .modalSwiperBulkLimits {
    margin-top: 8px;
    margin-bottom: -12px;
  }

  .modalSwiperClockCard {
    margin-top: -12px;
    margin-bottom: 8px;
  }

  .modalSwiperClockOn {
    margin-top: 7px;
    margin-bottom: -8px;
  }

  .closeDaysSwiper {
    margin-top: 7px;
    margin-bottom: -11px;
  }

  .modalSwiperCreateForm {
    margin-top: 0px;
    margin-bottom: -8px;
  }

  .modalSwiperCreateTimesheet {
    margin-top: 7px;
    margin-bottom: -11px;
  }

  .modalSwiperDayComments {
    margin-top: -7px;
    margin-bottom: 2px;
  }

  .modalSwiperEditShift {
    margin-top: 9px;
    margin-bottom: -14px;
  }

  .editReqDateHeader {
    /* background-color: blue; */
    min-width:100%
  }

  .modalSwiperEditReqDates {
    margin-top: 9px;
    margin-bottom: -12px;
  }

  .modalSwiperFormModal {
    margin-top: 2px;
    margin-bottom: -8px;
  }

  .modalSwiperLeaveReqModal {
    margin-top: 9px;
    margin-bottom: -14px;
  }

  .modalSwiperManagerShiftModal {
    margin-top: -13px;
    margin-bottom: 11px;
  }

  .modalSwiperMessages {
    margin-top: 9px;
    margin-bottom: -14px;
  }

  .modalSwiperMgrAvail {
    margin-top: 9px;
    margin-bottom: -14px;
  }

  .modalSwiperMySwaps {
    margin-top: -6px;
    margin-bottom: 3px
  }

  .modalSwiperNotifs {
    margin-top: -2px;
    margin-bottom: -1px;
  }


  .modalSwiperOpenMgr {
    margin-top: -12px;
    margin-bottom: 10px;
  }

  .modalSwiperSaveTemplate {
    margin-top: 9px;
    margin-bottom: -14px;
  }

  .modalSwiperSwapModal {
    margin-top: 9px;
    margin-bottom: -14px;
  }

  .modalSwiperTeamSwaps {
    margin-top: -7px;
    margin-bottom: 2px;
  }

  .modalSwiperTilTrim {
    margin-top: 9px;
    margin-bottom: -14px;
  }

  .modalSwiperUnclocked {
    margin-top: 9px;
    margin-bottom: -14px;
  }

  .modalSwiperUserLeave {
    margin-top: 9px;
    margin-bottom: -14px;
  }

  .modalSwiperUserOpenShift {
    margin-top: -11px;
    margin-bottom: 6px;
  }

  .modalSwiperUserWorkPat {
    margin-top: -1px;
    margin-bottom: 2px;
  }

  .modalSwiperViewLeaveType {
    margin-top: -7px;
    margin-bottom: 3px;
  }

  .modalSwiperSpecDay {
    margin-top: -6px;
    margin-bottom: 2px;
  }

  .modalSwiperViewTeamDay {
    margin-top: 1px;
    margin-bottom: 0px;
  }

  .modalSwiperViewTeamModal {
    margin-top: 9px;
    margin-bottom: -14px;
  }

  .modalSwiperViewTeamUser {
    margin-top: 6px;
    margin-bottom: -11px;
  }

  .modalSwiperTeamOnRequest {
    margin-top: 9px;
    margin-bottom: -14px;
  }

  .modalSwiperViewTs {
    margin-top: 9px;
    margin-bottom: -14px;
  }

  .modalSwiperEditUser {
    margin-top: 7px;
    margin-bottom: -12px;
  }

  .modalSwiperShowAccept {
    margin-top: -6px;
    margin-bottom: 0px;
  }

  .modalSwiperShowShare {
    margin-top: -5px;
    margin-bottom: -2px;
  }


  
  .modalSwiperMyAllows {
    margin-top: 9px;
    margin-bottom: -14px;
  }

   
  .modalSwiperMyHoursDataBox {
    margin-top: -41px;
    margin-bottom: 46px;
  }

   
  .xxx {
    margin-top: 9px;
    margin-bottom: -14px;
  }

   
  .xxx {
    margin-top: 9px;
    margin-bottom: -14px;
  }

  .notAvailInlineShift {
    min-height: 54px;
    margin-top: -18px;
    border-radius: 5px;
    background: repeating-linear-gradient( -45deg, #ffffff, #ffffff 4px, #dde9f9 0px, #dde9f9 8px);
    min-width: 120px;
    border: 1px solid #dde9f9;
    margin-right:3px;
    cursor: pointer;
  }

  .notAvailInlineShift:hover {
    background: repeating-linear-gradient( -45deg, #ffffff, #ffffff 4px, #EAF3FF 0px, #EAF3FF 8px);

  }

  .nwStripes {
    background: repeating-linear-gradient( -45deg, #ffffff, #ffffff 4px, #f0f7ff 0px, #f0f7ff 8px);
  }

  .notAvailInlineTxt {
    color: #ba0000;
    font-size: 14px;
    font-weight: 500;
    margin-top: 11px;
    text-align: left;
    margin-left: 11px;
    line-height: 15px;
    max-width: 100px; }

    .notAvailAllDayInlineSub {
      font-size: 12px;
      font-weight: 500;
      color: #814141;
      text-align: left;
      margin-left: 11px;    }

      .nwInlineTxt {
        color:#a1b4cb
      }


      .specTimesOpenMgr {
        margin-top: 0px;
        float: left;
        margin-left: 0px;
      }

      .monochromeWhite {
        -webkit-filter: grayscale(100%) brightness(1000%);
                filter: grayscale(100%) brightness(1000%);

      }

      .rotaAbsIcon {
        min-width: 21px;
        max-width: 13px;
        margin-left: 1px;
        margin-right: -1px;
      }

      .teamSchedDayData {
        background:#e3e8ee
      }

      .teamSchedDayDataRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 12px;
        color: #516b8b;
        min-height: 35px;
        border-bottom: 1px solid #bbcada;
      }

      .leaveShiftBoxMyRotaAbs {
        background-color: #b7a7bb;
        border: 1px solid #9d79a7
      }

      .leaveShiftBoxMyRotaAbsHovered {
        background-color: #a793ac !important;
        background-color: blue;
      }

      .leaveShiftBoxMyRotaAbsHovered:hover {
        /* background-color: #a793ac !important; */

      }

      .leaveShiftBoxMyRotaAbsMyAbs {
border:2px solid #143051      }

.leaveShiftBoxMyRotaAbsMyAbs:hover {
  background-color: #a293a5;
}

.leaveShiftBoxAbs {
  background-color: #9aadc4  !important;
}

.absenceLeaveShiftBoxHovered {
  /* background-color: #; */
  background-color: #88a2c1;
  border: 1px solid #525f6f !important;
padding-left:2px;
padding-right:2px;
  /* color: white; */
  /* border: none; */
  /* border: 1px solid #525f6f !important; */

}

.absHov {
  background-color: #88a2c1;
padding-top:3px
}

.noUpcChangesTxt {
  margin-top: 15px;
padding-left:0px}

.l129018 {
  font-size: 12px !important;
  max-width: 70%;
  padding-bottom: 20px;
}

.xd3719 {
  display: flex;
  justify-content: space-between;
}

.requireSeenKnob {
  /* align-items: center; */
  margin-top:20px;
  display: flex;}

  .l298424 {
    font-weight: 400 !important;
    font-size: 12px !important;;
  color: #516b8b !important;;
  line-height: 16px !important;;
  text-align: left !important;;
  }

  .doubleTickExampleImg {
    max-width: 40px;
    height: 13px;
    min-width: 69px;
    margin-right: 10px;
    margin-top: 31px;
    margin-bottom: 30px; }

    .reqSeenActive {
      min-width: 4px;
      height: 20px;
      background-color: #82c26a;
      margin-right: -15px;
      margin-left: 4px;
      border-radius: 5px;
    }

    .reqSeenActiveInactive {
      background-color: #e6eaee;
    }

    .x1231311xa {
      display:flex;
      align-items: center;
      flex-direction: row;
    }

    .reqAcceptIcon {
      background-color: #214978;
      border-radius: 5px;
      width: 7px;
      height: 7px;
      margin-left: 12px;
      margin-bottom: 12px;
    }

    .reqAcceptIconMgr {
      background-color: #b77bff;
    }

    .myHourAcceptRow {
      width: 90%;
      margin-left: 5%;
      display: flex;
      margin-top:-10px;
      justify-content: flex-end;
      padding-right: 40px;
      min-height: 30px;  
      z-index: 0;
      position: relative;  }

      .myHourAcceptRowWithTags {
        margin-top:-22px
      }

      .myTilShiftUnitParentWithAccept {
        min-height:104px
      }

      .myHoursAcceptBtn {
        border: solid 1px #126fda;
        background-color: #1e88e5;
        color: white;
        border-radius:5px;
        font-weight:500;
        height:28px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left:7px;
        padding-right:7px;
        font-size:11px;
        cursor: pointer;
      }

      .myHoursAcceptBtn:hover {
        background-color: #126fda;
        
      }

      .acceptShiftWhiteTick {
        max-width: 8px;
        margin-left: 5px;     }

        .acceptedDoubleTickMyHours {
          max-width: 16px;
          padding-right:1px;
          margin-bottom: 29px;
          margin-left: 11px;
          margin-right: -27px;        }

          .acceptedByChangeReq {
            max-width: 16px;
            padding-right: 1px;
            margin-bottom: 23px;
            margin-left: 11px;
            margin-right: -27px;
            margin-top: 4px;
          }

          .myHoursLeftSec {
            /* background-color: blue; */
            min-width: 26px;
          }

          .mgrHoursUsrNotYetAccept {
            font-weight:500
          }

          .mgrHoursUsrNotYetAccept {
            display: flex;
            justify-content: flex-start;
            width: 60%;
            margin-left: 6%;
            padding-left: 6px;
            font-size: 10px;
            color: #bbcada;
            font-weight: 500;
            margin-top: -6px;
            /* border-left: 2px solid #bbcada; */
            margin-bottom: 11px;
          }

          .accHover {
            background-color: #516b8b;
            color: white;
            font-size: 10px;
            font-weight: 500;
            border-radius: 3px;
            padding-left: 5px;
            padding-right: 5px;
            position: absolute;
            margin-top: -64px;
            display:flex;
            align-items: center;
            justify-content: center;
            height:20px;
            z-index: 999999;          }

            .accHoverWithChange {
              margin-top:-57px
            }

            .x3d11 {
              margin-top: -68px;
            }

           

 .xjd98222 {
  margin-top:8px;
  margin-bottom:8px;
 }           


 .myRotaDoubleTickImg {
  max-width: 14px;
  /* margin-right: -3px; */
margin-left:5px; }

  .notYetAccBtn {
    background-color: #214978;
    color:white;
    border:1px solid #143051;
    font-size:10px;
    display: flex;
    align-items: center;
    height:30px;
    border-radius:5px;
    padding-left:6px;
    padding-right:6px;
    margin-top:-6px;
    cursor: pointer;

  }

  .notYetAccBtn:hover {
    background-color: #143051;
  }

  .menuRowWithNotYetAccWidth {
    min-width: 348px;
    max-width: 348px;
  }

  .shiftsToAcceptQty {
    background-color: #0077ff;
    border-radius: 3px;
    padding: 2px;
    margin-left: 4px;
    height: 17px;
    display: flex;
    align-items: center;
    font-size: 10px;
    padding: 4px; 
  }

    .closeYetToAcceptBtn {
      display: flex;
      align-items: center;
      background-color: #8ea7c5;
      border: 1px solid #6987a9;
      font-weight: 500;
      color: white;
      font-size: 12px;
      padding-left: 8px;
      padding-right: 8px;
      height: 35px;
      border-radius: 5px;
      cursor: pointer;
      padding-bottom: 1px;  
      -webkit-animation-name: zoomModalIn !important;  
              animation-name: zoomModalIn !important;
      -webkit-animation-duration: 0.125s !important;
              animation-duration: 0.125s !important;
    }

    .closeYetToAcceptBtn:hover {
      background-color: #7e93ac;
    }

    .closeYetToAccCrossBtn {
      width: 9px;
      margin-left: 6px;
      margin-top: 1px;
    }

    .x389481912222 {
      margin-top: 7px;
      margin-bottom: 7px;    }

      .x23894292111 {
        font-size: 12px;
        color: #859dbb;
        font-weight: 500;
        /* border-left: 2px solid #82c26a; */
        height: 18px;
        display: flex;
        align-items: center;
        /* padding-left: 8px; */
        margin-top: 14px;
        margin-bottom: 14px;     }

        .x991091808312 {
          background-color: #07f;
          border: #0469de 1px solid;
        }

        .x991091808312:hover {
          background-color: #036ce4
        }

        .x13453232d {
          max-width: 10px;
          margin-left: 3px;        }

.myRotaWhiteTick {
  opacity:0.2;
  min-width: 9px;
  max-width: 9px;
  margin-left: 2px;
}

.notYetAcceptedTeamRotaDiv {
  background-color: #8ea7c5;
  min-width: 4px;
  min-height: 12px;
  max-width: 5px;
  max-height: 5px;
  border-radius: 5px;
}

.declineShBtn {
  min-height: 30px;
  max-height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #bbcada;
  min-width: 60px;
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  border: solid 1px #bbcada;
  /* background-color: #cbd7e6; */
  font-weight: 500;
  margin-top: 7px;
  max-width: 0px;
  margin-left: 18px;
  margin-bottom: 10px;
}

.declineShBtn:hover {
  /* background-color: #bbcada; */
  /* margin-top: 10px; */
}


.x28349829211{
  /* margin-left:-36px !important; */
  min-width: 33%;
  max-width: 33%;
}


.actionsDropperMob {
  top: 160px;
  min-height: 508px;
  min-width: 174px;
  right: calc(4.1% + 0px);
  -webkit-animation: slideDownMobTools 0.25s forwards;
          animation: slideDownMobTools 0.25s forwards;

}

@-webkit-keyframes slideDownMobTools {
  0% {
    min-height: 0;
    max-height: 0;
  }
  100% {
    min-height: 508px; /* Adjust this value to control the final height of the rectangle */
    max-height: 508px; /* Adjust this value to control the final height of the rectangle */
  }
}

@keyframes slideDownMobTools {
  0% {
    min-height: 0;
    max-height: 0;
  }
  100% {
    min-height: 508px; /* Adjust this value to control the final height of the rectangle */
    max-height: 508px; /* Adjust this value to control the final height of the rectangle */
  }
}

.schedActionItem {
  min-height:36px;
  max-height:36px
}

.x239898292 {
  min-width: 100%;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-top: 0px !important;
  margin-bottom:14px;
}

.viewTeamDayModalHeader {
  min-width: 100%;
  display: flex;
  justify-content: space-around;
  align-items:center
}


.acceptUnit {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  border: 1px solid #dde9f9;
  border-radius: 5px;
  max-width: 96%;
  margin-left: 3%;
  margin-top: 10px;
  background: white;
  padding-left: 4px;
  padding-right: 9px;
  padding-top: 2px;
  padding-bottom: 4px;
}

.acceptUnitInner {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  /* border: 1px solid #dde9f */
}
.acceptUnitName {
  font-weight: 500;
  color: #143051;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 5px;
  margin-left: 5px;
}

.acceptUnitString {
  color:#82c26a;
  font-weight: 500;
  font-size: 12px;
}

.acceptUnitDeclined {
  color:#ba0000
}

.acceptUnitType {
  font-size: 10px;
  text-align: left;
  margin-left: 5px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 5px;
  max-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #516b8b;
  font-weight: 500;
  /* border: 1px solid #bbcada; */
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  margin-left: 14px;
  /* margin-top: 2px; */
  background-color: #f4f8fe;
  border: 1px solid #e6e6e6;
  margin-bottom:5px;
}


.acceptTypeAndStrip {
  display: flex;
  align-items: center;
  margin-bottom:5px;
  margin-left:10px
}

.acceptUnitTimes {
  color: #8ea7c5;
  font-weight: 500;
  margin-left: 7px;
  /* border: 1px solid #f4f8fe; */
  font-size: 12px;
  text-align: left;
  line-height: 12px;
  border-left: 2px solid #82c26a;
  margin-top: 4px;
  /* padding-left:9px; */
  margin-left: 5px;
  /* padding-right: 4px; */
  padding-left:10px;
  min-width: 138px;
  padding-bottom:4px;
}
.declinedAccUnitBorder {
  border-color:#ba0000;
  cursor: pointer;
}

.declinedAccUnitBorder:hover {
  background-color: #dde9f9;
  border-radius: 0 3px 3px 0;

}
.acceptUnitDsMade {
  color: #8EA7C5;
  font-size: 10px;
  margin-top:3px;
  font-weight: 500;}

.acceptUnitProPicImg {
  max-width: 22px;
  border-radius: 3px;
  margin-right: 10px;
  max-height: 22px;
  object-fit: cover;
  min-width: 22px;
  min-height: 22px;
}

.acceptUnitLeft {
  max-width: 62%;
  min-width: 62%;
}

.acceptUnitDsSpan {
  color: #8EA7C5;
  font-weight:500;
  font-size: 12px;}

  .acceptUnitRight {
    margin-right:10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .x209dw022 {
    max-width: 390px !important;
    min-width: 390px !important;  }

    .acceptUnitDeclinedItem {
      border: none;
      background: none;
      border-radius: 0;
      border-bottom: 1px solid #bbcada;
    }

 .acceptDeclineReason {
  color: #859dbb;
  font-weight: 500;
  font-size: 12px;
  text-align: left;
  padding-left: 7px;
  padding-right: 16px;
  padding-bottom: 5px;
  border-left: 2px solid #8ea7c5;
  margin-left: 15px;
  margin-bottom: 4px;
  margin-top: 2px;
  padding-top: 3px;
 }
  
  .declineReasonName {
    color:#143051;
    font-weight:600;
    padding-right:6px;
  }

  .acceptUnitDesktop:hover {
    border-color:#bbcada
  }


  .acceptMsg {
    display: flex;
    align-items: center;
    border: solid 1px #bbcada;
    background-color: #cbd7e6;
    color: white;
    font-weight: 500;
    font-size: 10px;
    min-height: 22px;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 3px;
    margin-top: 5px;
    cursor: pointer;
  }

  .acceptMsg:hover {
    background-color: #bbcada;
  }
  .accMsgImg {
    max-width: 10px;
    margin-left:4px;
  }
 

 .acceptanceDsPopupImg {
  max-width: 12px;
  /* padding-top: 1px; */
  margin-bottom: -2px; }

  .acceptDeclineTeamName {
    color: #0af;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    padding-left: 9px;
    margin-bottom: 8px;
    margin-top: 5px;
    /* border-left: 2px solid #fff; */
    margin-left: 15px; 
  padding-left:10px;
  }



    .mgrShModalAcceptRow {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #516b8b;
      margin-top: -23px;
      margin-left: 5px;
      font-weight: 400;
    }

    .acceptMgrShRowImg {
      max-width: 14px;
      margin-right: 4px;    }

      .notYetAccBlob {
        background-color: #143051;
    width: 3px;
    height: 10px;
    border-radius: 10px;
    margin-right: 6px;

      }

      .forceAcceptBtn {
        border: solid 1px #bbcada;
        background-color: #cbd7e6;        color: white;
        font-size: 10px;
        height: 20px;
        margin-left: 12px;
        display: flex;
        align-items: center;
        padding-left: 5px;
        padding-right: 5px;
        max-width: -moz-fit-content;
        max-width: -webkit-fit-content;
        max-width: fit-content;
        border-radius: 5px;
        margin-top: 2px;
        cursor: pointer;
           }
.forceAcceptBtn:hover {
  background-color: #bbcada;
}
        .x23892j3 {
          margin-top:0px;
          height:20px
        }

.notYetAccWithForceRow {
  height:51px;
  margin-top:-7px
}

.rotaQmark {
width:10px}



.x189398311 {
  padding-left:8px;
  font-size: 12px;
  color: #859dbb;
  font-weight: 500;
  border-left: 2px solid #82c26a;
  height: 18px;
}


.xu2321 {
  line-height: 13px;
  margin-top: -7px;
  margin-bottom: -70px;
  padding-bottom: 70px;
  border-left: 2px solid #6eb450;
  padding-top: 5px;
  padding-bottom: 4px;
  padding-left: 9px;
  margin-bottom: 10px;
  max-height: 30px;
  margin-left: 12px;
  max-width: 180px;
  font-size: 10px;
}

.x292093091 {
  margin-top:-16px
}


.addingHeight {
  min-height: 180px !important;
}


.x02920188111 {
  /* background-color: black; */
}

.bbllbb {
  /* background-color: orange; */
  min-height:101px !important;
  max-height:101px !important;

  -webkit-animation-name: heightDown !important;

          animation-name: heightDown !important;
    -webkit-animation-duration: 0.25s !important;
            animation-duration: 0.25s !important
}

.x2211gg {
  /* background-color: blue; */
  /* line-height:18px; */
}

.standaloneJtUserName {
  /* background:orange; */
  min-height:12px;
  margin-bottom:-10px;
}


.x1393198197311 {
padding-top:9px;}

.swapModalMiddleMob {
  margin-top:24px
}

.calDsTodayTxt {
  color: #fff;
  font-size: 10px;
  margin-top: -4px;
  margin-bottom: 4px;
  background: #143051;
  border-radius: 5px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 3px;
  margin: -3px auto;
  padding-left: 6px;
  padding-right: 6px;
  margin-bottom: 4px;}

.calDsTodayTxt:hover {
  opacity: 0.8;
}
  .calRightSecForAccept {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .calAcceptIconImg {
    max-width: 14px;
    margin-right:8px;
    /* margin-left:-2px; */
  }

  .calNotAcceptIconImg {
    max-width: 10px;
    margin-right:8px;
    /* margin-left:-2px; */
  }
/* 
  .unaccCalBlob {
    min-width: 3px;
    background-color: #bbcada;
    min-height: 12px;
  } */

  .myShDoubleTick {
    max-width: 15px;
    margin-right:4px;
  }

  .x2984918184111 {
    background-color: blue;
    margin-top:30px;
  }

  .paddingTop5 {
    padding-top:5px;
  }

  .xg27381 {
    max-width: 14px;
    padding-right: 4px;
    margin-left: 13px;  }

    .dayCommFooter {
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .x61761g11 {
      padding-bottom:10px
    }

    .cx020948 {
      color: #0077ff
    }

    .notifyShChangeBtn {
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 72px;
      padding-left: 3px;
      padding-right: 3px;
      min-height: 35px;
      max-height: 35px;
      border: solid 1px #4b1caf;
      background-color: #a387df;
      border-radius: 5px;
      cursor: pointer;
      min-width: 65px;
      flex-direction: row-reverse;
      margin-right: 0px;
    }

    .notifyShChangeBtn:hover {
      background-color: #9b82ce;
    }

 

    .x9928927492 {
      /* background-color: blue; */
border-left:1px solid #e0ebf8;
    padding-left: 6px;
     margin-left: 4px;
    }

    .headerMenuTopRow {
margin-top:4px;    }

.headerMenuBottomRow {
  background-color: #00ccff;
  min-height: 4px;
  min-width: 0; /* Start with zero width */
  max-width: 96%;
  border-radius: 3px;
  margin: 4px auto;
  -webkit-animation-name: zoomModalIn;
          animation-name: zoomModalIn;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}


.purpeMenuBottom {
  background-color: #ffa8ff;
}

.x198389191 {
  background: none;
  min-height: 4px;
  min-width: 0; /* Start with zero width */
  max-width: 96%;
  border-radius: 3px;
  margin: 4px auto;
}

.highlighedChangeID {
  background: white;
  border: 4px solid #0077ff;}

  .teamSchedAllTeams {
    color: #bbcada;
    font-size: 9px;
    /* max-width: 123px; */
    line-height: 9px;
    margin-left: 6px;
    margin-top: 14px;
    font-weight: 500; 
  display: none;
  }

    .teamSchedAllTeamsSplitter {
      background-color: #bbcada;
      min-width: 3px;
      min-height: 6px;
      margin-left: 23px;
      margin-top: 9px;
      max-height: 17px;
      border-radius: 5px;
      display: none;
    }

    .marginRight10 {
      margin-right:10px !important
    }

    .marginRight3 {
      margin-right:3px !important

    }

    .noCustomQtyScrollBoxMob {
      min-height: 0px !important;
      max-height: 0px !important;
    }

    .formModalFooterMain {
      display: flex;
      min-height:50px;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #bac5d3;
      /* flex-direction: row-reverse; */
      /* flex-direction: row-reverse; */
    }


    .formsDisabled {
      max-width: 85%;
    margin-left: 7%;
    text-align: left;
    font-size: 12px;
    line-height:16px;
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 40px;
    color: #516b8b;
    border-left:2px solid #516b8b;
    padding-left:10px
    }

    .x1981d {
      margin-top:45px;
    }

    .x9811jj {
      color:#214978 !important;
      font-size:9px
    }

    .joinedClockCardsSelectDiv {
      min-width: 100px;
      min-height: 20px;
      /* background-color: yellow; */
      z-index: 9999999;
      margin-top: -183px;
      margin-bottom: 150px;
      position: relative;

    }

    .joinedClockSelect {
      min-width: 30px;
      max-width: 30px;
      min-height: 30px;
      max-height: 30px;
      background-color: white;
      margin-left: 25%;
      border: 2px solid #bbcada;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      /* margin-top:-140px; */
    }

    .joinedClockSelect2 {
      margin-left:65%
    }

    .selClockCardArr {
      width: 15px;
    }

    .jtRowSec {
      background-color: #ecf4ff;
      min-height:30px;
      max-height:30px;
      font-size:14px;
            z-index:999999;
            display:flex;
            align-items: center;
            /* border-top: 1px solid #e3e8ee; */
            /* border-bottom: 1px solid #e3e8ee; */
            justify-content: flex-end;
    }

    .jtDataRowTxt {
      font-size: 11px;
      font-weight: 500;
      max-height: 30px;
      margin-left: 5px;
      color: #143051;
      max-width: 140px;
      text-align: left;
      line-height: 11px;
      padding-right: 4px;
    }

    .jtDataRowTxtMob {
      font-size:10px;
      line-height:10px;
      /* background-color: yellow; */
      max-width: 67px;
      margin-left:5px;
      color:#4b1caf;
    }

    .collapgeJt {
      max-width: 10px;
      min-width: 10px;
      /* background: blue; */
      min-height: 30px;
      max-height: 30px;
      cursor: pointer;
      /* margin-top: 7px; */
      margin-left: 7px;
      padding: 0px;
}

    .collapgesJtArr {
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
    }


    .collapgesJtHeight {
      min-height:30px;
      max-height:30px;
    }


    .jtSmall {
      font-size:9px;
      line-height:9px;
    }

    .jtSmallMob {
      font-size:8px;
      line-height:8px;
    }

    .mgrAcceptanceContainer {
      padding-right:10px;;
    }

    .accTimeCalIcon {
      max-width: 11px;
      margin-top:4px;
      opacity: 0.4;
      margin-right:2px;
    }

    .accTimeCalIcon1 { 
      margin-top:6px;
      margin-bottom:-2px;
    }

    .shiftTilNameTxtHours {
      /* margin-top:4px !important; */
      background-color: #f4f8fe !important;
      border: 1px solid #e6e6e6 !important;
      color:#516b8b !important
    }



  


    .acceptKeyBox {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      min-width: 110px;
      min-height: 35px;
      border-radius: 5px;
      border: 1px solid #e0ebf8;    }

      @media only screen and (max-width: 560px)  {
        .acceptKeyBox {
      display: none;
        }
      }

      .acceptKeyTxt {
      color:#496375;
        font-weight: 500;
        font-size:11px;
        /* line-height:10px; */
        text-align: left;
      }

      .keyDoubleTick {
        max-width: 18px;
        margin-right:3px;
      }

      .slaExclaim {
        color: #ba0000;
        font-weight: 600;
        max-width: 14px;
        margin-right: 8px;
        font-size: 22px;      }

        .myCalColourLightRed {
          background-color:#b24e4e
        }

        .slaColourLight {
          background-color:#f5e5e5
        }

        .teamIconAccept {
          max-width: 14px;
          margin-right:4px;
        }

        .teamIconTs {
          max-width: 14px;
          margin-right:4px;
          margin-top:2px;
        }

        .teamIconHours {
          max-width: 14px;
          min-width: 14px;
          margin-left:7px;
        }
        .teamIconAbs {
          max-width: 14px;
          margin-left:7px;;
        }

        .calPrimTeamIcon {
          max-width: 15px;
          margin-right:4px;
        }

        .sideBoxAbsTypesMapTitle {
          text-align: left;
          color: white;
          margin-bottom: -11px;
          font-weight: 600;
          opacity: 0.5;   
          min-width: 230px;
          max-width: 230px;
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          margin-right: 9px;
          min-height: 30px;
          align-items: center;
          padding-left: 14px;
          padding-right: 6px;
          margin-top: 8px;
        }


          .marginRight8 {
            margin-right:8px;
          }

          .todayItemCalHeader {
            background-color: #af71cd;
          }

          .tsPending {
            border: 2px dashed #bbcada !important;
          }

        

          @media only screen and (min-width: 820px) {
            .tsPending:hover  {
              border: 2px dashed #214978 !important;
            }
          .rotaCommentDiv:hover {
            opacity: 0.8;
          }
            .addBtnHovered {
              color: #0077ff !important;
              opacity: 1;
            }
            
          
          }

  .reqMakerAlreadyOff {
    color: #859dbb;
    font-weight: 600;
    font-size: 8px;
    margin-bottom: 5px;
    margin-top: 3px;
    min-height: 9px;  }

    .alreadyOffOrClosed {
      color:#bbcada !important
    }

    .unpaidTilDur {
      font-size:11px
    }

    .tsNoLongerAvailInShift {
      color: #bbcada;
      font-size: 12px;
      max-width: 110px;
      line-height: 15px;
      text-align: right;
      margin-right: 15px;
      font-weight: 500;
      border-right: 2px solid #bbcada;
      padding-right: 10px;
    }

    .viewEmpTsPopup {
      margin-top: 0px;
      margin-bottom: -1px;
      margin-left: 2px;    }

      .x991ddz {
        color: #bbcada;
        line-height: 16px;
        font-size: 12px;
        font-weight: 400;
        margin-top: 18px;
        margin-bottom: 20px;
        padding-right: 20px;
        border-left: 1px solid #e0ebf8;
        padding-left: 9px;
        /* margin-left: 5px; */
      }

      .myShOtherUserRow {
        display: flex;
        align-items: flex-start;
        margin-bottom:10px;
      }

      .myShiftOtherUsersBlock {
        margin-top: 21px;
        margin-bottom: 13px;      }

      .otherUsrShImg {
        max-width: 25px;
    min-height: 33px;
    max-height: 33px;
    min-width: 25px;
    object-fit: cover;
    cursor: pointer;
    border-radius: 3px;
    margin-right: 8px;    }

    .otherUsrShNameTop {
      max-height: 21px;
      line-height: 21px;  
    font-size:14px;
    cursor: pointer;
    }

    @media only screen and (min-width: 820px) {
      .otherUsrShNameTop:hover  {
       
        opacity: 0.8;
      }

      .otherUsrShImg:hover {
        opacity: 0.9;
      }
    
    
    }

    .otherUsrShNameLow {
/* background-color: yellow;  */
/* border-bottom:1px solid #bbcada; */
/* padding-bottom:20px; */
margin-bottom:8px;
max-height: -webkit-fit-content;
max-height: -moz-fit-content;
max-height: fit-content;   }

    .otherUserShItem {
      font-size: 12px;
      font-weight: 500;
      max-height: 15px;
      display: flex;
      align-items: center;
      color: #bbcada;
      margin-top: -3px;
      margin-bottom: 4px;
    }

    .otherUsrClock {
      width: 10px;
      margin-top:1px;
      margin-right: 4px;
      opacity: 0.6;    }


      .collea {
        font-size: 12px;
        margin-bottom: -20px;
        color: #214978;
        /* color: #859cb6; */
        font-weight: 400;
        display: flex;
        max-width: -moz-fit-content;
        max-width: -webkit-fit-content;
        max-width: fit-content;
        line-height: 18px;
        margin-top: 20px;
        margin-bottom: 0px;
        padding-left: 6px;
        padding-right: 20px;
      }

      .highlightCommentBtn {
        color:#516b8b;
        font-size: 11px;
        font-weight: 500;
        display:flex;
        align-items: center;
        margin-top:4px;
        cursor: pointer;
      }


@media only screen and (min-width: 820px) {
  .highlightCommentBtn:hover {
  color:#143051
  }


}

      .dayCommentTsAndHighlight {
        display:flex;
        flex-direction: column;
        align-items: flex-end;
      }

      .highglightTick {
        min-width:16px;
        min-height:16px;
        max-width:16px;
        max-height:16px;
        margin-left: 6px;
        padding: 3px;
        border: 1px solid #bbcada;
        border-radius: 3px;
        background: white;
        margin-top:1px;
      }

      .unhighlightedCommTick {
        min-width:16px;
        min-height:16px;
        max-width:16px;
        max-height:16px;
        margin-left: 6px;
        padding: 3px;
        border: 1px solid #cadcef;
        border-radius: 3px;
        margin-top:1px;
      }

      .highlightedNoteObj {
        border: 2px solid #e0ebf8;
        padding: 14px;
        background-color: #e8f3ff;
      }

      .highlightedCommentsA1 {
        border-left: 1px solid #dbe8f7;

        border-right: 3px solid #dbe8f7;
        min-height:40px;
        min-width: 100%;
        /* border-top:none; */
      }

      .highlightedCommBox {
        min-height: 40px;
        min-width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        max-width: 225px;
        padding-left:15px;
        padding-right:15px;
        border-bottom:1px solid #e3e8ee
      }

      .highlightExclaim {
        color:#ba0000 !important;
        font-size:18px !important;
        font-weight:600;
        min-width: 10px;
        line-height: 30px;
      }

      .highlightedBG {
        /* background-color: #fff; */
        font-weight: 500;
        max-height: 40px;
        line-height: 13px;
        color: #516b8b;
        font-size: 12px;   
        cursor: pointer;
        border-bottom:1px solid #e3e8ee
         }

         @media only screen and (min-width: 820px) {
            
         .advancedView:hover {
          background-color: #8d85d2;
         }

          .highlightedBG:hover {
          color:#143051
          }
          .highlightTxtStandalone {
          color:#516b8b
          }
        
        }

         .smallerHighlight {
          font-size:10px;
          line-height:11px;
         }


         .highlightTxt {
          padding-left: 5px;
          padding-right: 5px;

          font-size: 10px;
          line-height: 12px;
         }

     

         .highlightedCommRow {
          display: flex;
          flex-direction: column;
         }

         .highlightComCalRow {
          background-color: #fff;
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          font-weight: 500;
          max-width: 94%;
          margin-left: 3%;
          margin-top: 10px;
          border-radius: 5px;
          padding: 5px;
          padding-left: 8px;
          padding-right: 8px;
          border: 1px solid #e2ebf5;
          cursor: pointer;
          color: #143051;
         }

         .x28181132 {
          margin-top:10px !important;
         }
         .highCalExclaim {
          font-weight:600;
          color:#ba0000;
          font-size:18px;
          margin-top:3px;
         }

         .highlightCalComRowCentre {
          min-width: 90%;
          max-width: 90%;

         }

         .highlightRow1 {
          text-align: left;
 font-size: 10px;
 color:#bbcada
         }

         .highlightRow2 {
          text-align: left;
          font-style: italic;
          color:#07f
         }

         .noCommsComIcon {
          opacity: 0.4;
         }

         .dayComShowTopBorder {
          border-top:1px solid #bbcada
         }

         .cantSelfAppAbs {
          color: #143051;
          line-height: 16px;
          font-size: 12px;
          padding-right: 48px;
          padding-left: 12px;
          border-left: 2px solid #143051;
          margin-left: 5px;
          margin-bottom: 20px;         }

          .x2489ff {
            border-radius:20px 20px 0 0  !important
          }


        

          .x29482974231 {
            font-size:14px;
            line-height:20px;
            margin-bottom:10px;
          }



          .absCompanySpan {
            font-size:10px;
            opacity: 0.7;
          }


          .lowerBetter {
            font-size:10px;
            font-weight: 500;
            opacity: 0.4;
            text-align: right;
          }

          .lowerBetter2 {
            font-size:10px;
            font-weight: 500;
            opacity: 0.4;
            text-align: left;
            margin-left:7px;
          }

          .absLowerBetterDiv {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            color:white
          }
          .absLowerBetterDiv2 {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            color:white
          }

.tsItemDurMins {
  font-weight: 500;
  margin-top: 12px;
  margin-left: 7px;
  margin-bottom: 10px;
  font-weight: 400;
  font-size:10px;
  color: #143051;}

  .recDurTxt {
    color:#143051;
    font-weight: 500;
    font-size:14px;
  }


  .addShiftShoulderBtn {
    border: 1px solid #8a98fc;
    background-color: #a4afff;
    color: #fff;
    cursor: pointer;
    min-width: 35px;
    min-height: 35px;
    border-radius: 5px;
    display: flex;
    font-size: 24px;
    line-height: 24px;
    align-items: center;
    padding-bottom: 2px;
    justify-content: center;
    margin-top: 8px;
    margin-right: 10px;
  }


    .addShiftShoulderBtn:hover {
      background-color: #97a1ea;
    }

    .addShiftTilDrop {
      min-width: 200px;
      min-height:400px;
      background-color:white;
      position: absolute;
      top: 2px;
      left:2px;
    }


    .staffRotaBtnColours {
      background-color: #b49ce7  !important;
      border: 1px solid #a47fd0 !important;
    }

    .staffRotaBtnColours:hover {
      background-color: #a58fd4 !important;
    }

    .lineHeight22px {
      line-height: 22px !important
    }

    .payableHoursMobTitle {
      font-size:14px;
      margin-top:2px;
      /* line-height:14px; */
    }
    .rotaViewBtn {
      max-width: 28px;
      border-radius:3px;
      border:1px solid #756dba;
      margin-top:-2px;
      margin-right: 16px;
      cursor: pointer;
      padding: 3px;
      background: #8d85d2;
    }

    .rotaViewBtn:hover {
opacity: 0.9;    }

    .rotaViewBtnMob {
      margin-right:6px
    }

    .rotaViewBtnMob:hover {
      opacity:1
    }

    .streamLinedRotaDiv {
min-height:calc(100vh - 203px);
max-height:calc(100vh - 203px);

min-width: calc(100vw - 160px);
      max-width: calc(100vw - 160px);
background-color: white;
border-left:2px solid #e6eaee;
padding-top:5px;
overflow:auto;
display: flex;

    }

    .streamLinedRotaDivMob {
      border: none;
      max-height: calc(100vh - 257px);
      min-height: calc(100vh - 257px);
      min-width: 100vw;
      max-width: 100vw;
      padding-top: 9px;
      margin-top: -8px;
    }

.streamJtRow {
  /* background-color: #f0f7ff; */
  /* border-radius:4px; */
  max-width: 100%;
  /* margin-left:1%; */
  /* margin-top:2px; */
  /* margin-bottom:5px; */
  text-align: left;
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  min-height:25px;
  /* background-color: white; */
  /* border-top:2px solid #bbcada */

}


.streamJtJobTitle {
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
  color: #143051;
  /* border-radius: 3px 0 0 3px; */
  background-color: #ecf4ff;
  border-top: 1px solid #e3e8ee;
  border-bottom: 1px solid #e3e8ee;

  min-height: 35px;
  padding-left: 9px;
  cursor: pointer;
  min-width: 141px;
  max-width: 141px;
  /* border-right: 1px solid #dbe8f7; */
  z-index: 99999;
  line-height: 10px;


}

.streamJtJobTitleMob {
  min-width: 104px;
  max-width: 104px;
}
/* .streamJtJobTitlePhontom {
  min-height:35px;
  min-width: 10px;
} */

.streamUserRow {
  /* background-color: blue; */
  min-height:60px;
  max-height:60px;
  /* border-bottom:1px solid #dbe8f7; */
  min-width: 100%;
  max-width: 100%;
}

.borderTopBbcada {
  /* border-top: 1px solid #bbcada */
}

.streamUserNameBlock {
  border-right: 3px solid #dbe8f7;
  border-left: 1px solid #dbe8f7;
  border-bottom:1px solid #dbe8f7;
cursor:pointer;
  max-width: 140px;
  min-height:60px;
  max-height: 60px;
  display:flex;
  align-items: center;
  justify-content: flex-start;
  font-size:12px;
  font-weight: 500;
  color:#516b8b;
  padding-right:5px;
}

.streamUserNameBlockMob {
  max-width: 104px;
  font-size: 10px;
  background-color: white;

}

.streamlinedUserProPicImg {
  max-width: 24px;
  min-width: 24px;
  min-height: 36px;
  max-height: 36px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 7px;
  margin-left: 5px;
}

.streamCollapseJtImg {
  max-width: 10px;
  min-width: 10px;
  margin-right: 5px;
  /* margin-left: 2px; */
}

  .streamCollapseJtImgCollapsed {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }

  .streamDays {
    background:blue;
    max-height:100px;
  }

  .streamJtLeft {
    min-width: 140px;
    max-width: 140px;
    min-height:-webkit-fit-content;
    min-height:-moz-fit-content;
    min-height:fit-content;
    /* min-height: fit-content; */
    /* min-height: calc(100vh - 203px);
    max-height: calc(100vh - 203px);   */

  }

  .streamInner {
    display: flex;
    overflow:auto;
    /* max-height:500px; */
    /* min-height:500px; */

  }

  .streamlinedDays {
    background-color: yellow;
    display: flex;

  }

 

  .dayColWidener {
    min-width: 140px;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
  }

  .dayColWidenerMob {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  .jtOvers {
    background:blue
  }

  .streamlinedParent {
    position: relative;
    overflow-y: scroll;
    background-color: white;
  }

  .streamlinedNameCol {
    position: relative;
    min-width: 140px;
    max-width: 140px;
  }

.streamlinedDayCol {
border-right:1px solid #dbe8f7;
min-width: -webkit-fit-content;
min-width: -moz-fit-content;
min-width: fit-content;
margin-left:-2px;

}

.streamlinedDayColMob {
  /* min-width: 104px; */
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;

}

.streamlinedA1 {
  min-height:35px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  /* border-right:1px solid #dbe8f7; */
  border-bottom: 3px solid #dbe8f7;
  justify-content: space-between;
}

.streamlinedA1NameCol {
  border-left:1px solid #dbe8f7;
    border-right:3px solid #dbe8f7;
    justify-content: flex-start;

}

.streamliendModeBtn {
  max-width: 12px;
  border-radius: 3px;
  margin-top: 0px;
  background: none;
  border: none;
  padding: 2px;
  margin-right: 0px;
  margin-left: 0px;
  cursor: pointer;
  padding: 3px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);

}

.advancedView {
  display: flex;
  background-color: #8f85eb;
  align-items: center;
  color: white;
  font-weight: 500;
  max-width: 83px;
  border-radius: 3px;
  border: 1px solid #756dba;
  font-size: 10px;
  display: flex;
  align-items: center;
  line-height: 10px;
  /* margin-left: 8px; */
  cursor: pointer;
  min-height: 28px;
  padding-right: 0px;
  margin-top: -4px;
  line-height: 9px;
  text-align: left;
  min-width: 83px;
  margin-right: 9px;
}


.advancedView1 {
  margin-top: 0px;
  min-width: 97px;
  max-width: 97px;
  margin-left:4px;
  /* font-size: 10px; */
  line-height: 8px;
  margin-left:0px;
}

.mobStreamlineImgBtn {
  border-radius: 3px;
  border: 1px solid #756dba;
  background-color: #8f85eb;

  max-width: 28px;
  min-width: 28px;
  max-height:28px;
  min-height:28px;
  margin-top:-4px;
  margin-left:15px;
}

.advancedViewX9 {
  min-width: 95px;
  max-width: 95px;
  font-size: 12px;
  margin-right: -2px;
}

.advancedView1Mob {
  margin-left:5px;
}

.advancedView1Desktop {
  min-width:114px;
  max-width: 114px;
  font-size: 12px;
  /* margin-left:1px; */

}

.streamJtRowMob {
  min-width: 104px;

  max-width: -webkit-fit-content;

  max-width: -moz-fit-content;

  max-width: fit-content;

}


.streamlinedShiftsArr {
  /* min-width: 204px; */
  /* background-color: black; */
  
  /* color:blue; */
  min-height:60px;
  display: flex;
  min-width: 100%;
  border-bottom: 1px solid #dbe8f7;
  /* border-left: 1px solid #dbe8f7 */
}

.streamlinedShiftsArrMob {
  border-left: 1px solid #dbe8f7

}

.streamlinedShiftsArrFinal {
  border-bottom:none;
  /* display: flex; */
  /* justify-content: center; */
}
.block11 {
/* border:1px solid black; */
/* margin-left:2px; */
min-width: 60px;
}

.fitWidth {
  min-width: calc(100% - 1px) !important;
  border-right: none;
  cursor: default;
}

.borderLeftStream {
  border-right: 4px solid #dbe8f7;
  border-left: 1px solid #e3e8ee;

  /* border-radius:5px 0 0 5px; */
}
.borderLeftStreamMob {
  border-right: 3px solid #dbe8f7;

}

.streamNameAndHours {
  /* background-color: yellow; */
}

.streamUserHours {
  font-size: 10px;
  color: #859dbb;
  margin-top: 2px;
  font-weight: 400;
}

.streamDayTitle {
  font-weight: 500;
  font-size: 16px;
  color: #143051;
  line-height: 24px;
  display:flex;
  align-items: center;
  margin-left:10px;
  margin-top:1px;
}

.streamDayTitleJt {
  font-weight: 500;
  font-size: 12px;
  color: #bbcada;
  line-height: 24px;
  display:flex;
  align-items: center;
  /* margin-left:10px; */
  margin-top:1px;
}

.streamDayTitleDay {
  font-size:14px;
  padding-left:4px;
  /* line-height: 10px; */

}

.streamlinedNonWorkingDayItem {
  background: repeating-linear-gradient(45deg, #ffffff, #ffffff 4px, /* Adjust the height of stripes as needed */ #f0f0f0 4px, /* Adjust the color of stripes as needed */ #f0f0f0 8px /* Adjust the height of stripes + gap as needed */);
  min-height: 59px;
  max-height: 59px;
  min-width: 100%;
  max-width: 100%;
  /* min-width: 139px;
  max-width: 139px; */
  /* min-width: fit-content; */
  /* max-width: fit-content; */
  color: rgba(255,255,255,0);
  font-weight: 500;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #dbe8f7;
  margin-left: 1px;
  cursor: pointer;

}

.streamlinedNonWorkingDayItemStreaming {
  background: repeating-linear-gradient(45deg, #ffffff, #ffffff 4px, /* Adjust the height of stripes as needed */ #b9d3b9 4px, /* Adjust the color of stripes as needed */ #b9d3b9 8px /* Adjust the height of stripes + gap as needed */);

}

.streamlinedNonWorkingDayItem:hover {
  color:#bbcada
}


.streamlinedNonWorkingDayItemMon {
  min-width: 100%;
  /* max-width: 140px; */
  margin-left:0px;
  /* border-left:none */
}

.borderLeftNone {
  border-left:none !important
}

.streamlinedShiftBox {
  min-width: 134px;
  min-height: 58px;
  max-height: 58px;
  margin-top: 1px;
  margin-left: 4px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.2s !important;
          animation-duration: 0.2s !important;
cursor: pointer;}

.disabledShiftBox {
  cursor: default;
  pointer-events: none;
}


.streamLineShiftTxtBox {
  margin-left:8px
}

.streamShiftTimes {
  font-size: 12px;
  font-weight: 500;
  color: #516b8b;
  margin-bottom: -4px;
  margin-top: 3px;
  /* margin-right:10px; */
}


.streamlinedShiftBoxLeave {
  background-color: #e9e9ff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 126px;
  margin-left: 0px;
  cursor: pointer;
  max-height: 48px;
  margin-top: 6px;
  margin-left: 6px;
  min-height: 48px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.2s !important;
          animation-duration: 0.2s !important;
}



.streamlinedShiftBoxAbsence {
  background-color: #dfe7f0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 126px;
  margin-left: 0px;
  cursor: pointer;
  max-height: 48px;
  margin-top: 6px;
  margin-left: 6px;
  min-height: 48px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.2s !important;
          animation-duration: 0.2s !important;
}

.finalItemLeaveAbs {
  margin-right:6px;
}




@media only screen and (min-width: 820px) {
  .streamlinedShiftBox:hover {
  background-color: #f3f8ff;
  }

  .streamlinedShiftBoxLeave:hover {
    background-color: #e4e4f6;
  }
  .streamlinedShiftBoxAbsence:hover {
    background-color: #d8e1eb;
  }


}
.streamlinedShiftBlob {
  min-width: 3px;
  border-radius: 5px;
  min-height: 80%;
  background-color: #00ccff;
  margin-left: 3px;
}

.streamlinedShiftBlobGhost {
  background-color: #0469de;
}

.streamlinedShiftOvertimeBlob {
  background-color: #8f85eb;
}
.streamlinedShiftTilBlob {
  background-color: #ec77f7;
}

.unpubBw {
  -webkit-filter: grayscale(100%); /* Safari and Chrome */
  filter: grayscale(100%);
  opacity: 0.5;
}

.streamlinePlane {
  max-width: 16px;
  margin-left:-7px;
  opacity: 0.8;
}


.streamLeavInfoDiv {
  font-size: 10px;
  font-weight: 500;
  color: #b1abe7;
  margin-left:10px;
  min-width: 64px;
}

.streamLeavInfoDivAbs {
  color:#7a8ca2
}

.streamLeaveInfoUpper {
  
}

.streamLeaveInfoLower {
  font-weight: 400;
  margin-top:-2px;
  font-size:10px;
}

.shiftBoxLowerStream {
  margin-top: 4px;
  display: flex;
  min-height: 18px;
  max-height:18px;
}

.hobbler {
  min-height:18px;
  min-width: 1px;
  max-width: 1px;
  margin-top: 4px;

}

.shTagStream {
  background-color: #f4f8fe;
  border-color: #dde9f9;
  color: #859dbb;
  padding-left: 3px;
  padding-right: 3px;
  min-height: 16px;
  max-height: 16px;
  margin-top: 2px;
}

.streamNoTags {
  font-size:10px;
  color:#bbcada;
  font-weight: 500;
}

.streamNoTagsUnpub {
  color:#7fa7c2
}

.streamOverTilType {
  margin-top: -30px;
  margin-right: -10px;
  margin-left: 10px;
  background-color: #f4f8fe;
  border: #dde9f9 1px solid;
  color: #859dbb;
  font-size: 9px;
  font-weight: 500;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 4px;
  min-height: 15px;
  display: flex;
  align-items: center;}



  .streamPlus {
    font-size:22px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #bbcada;
    cursor: pointer;
  }

  

  @media only screen and (min-width: 820px) {
    .streamPlus {
      color: #fff;
    }
    .streamPlus:hover {
      color: #bbcada;
    }
  } 

  .streamShifts {
    /* min-width: 100%; */
    display: flex;
    align-items: center;
  }

  .streamShiftsAndAvail {
    min-width: none;
  }

  .streamAvail {
    color:red
  }

  .paul {
    min-width: 100%;
  }

  .availTest {
    min-height: 22px;
    max-height: 22px;
    padding-right: 10px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    font-size: 9px;
    border-bottom: 1px solid #f4f8fe;
    color: #859dbb;
    font-weight:500;
    background-color: white;
    /* margin-left:1px; */
    /* background-color: yellow; */
    /* border-right:none !important */
    background-color: white;
    /* border-left: 1px solid #dbe8f7; */
    min-width:140px;
    cursor: pointer;


  }

  .opac05 {
    opacity: 0.5
  }
/* 
@media only screen and (min-width: 820px) {
  .availTest {
  margin-left:1px;
  }



} */

  .availNameBlock {
    min-height:82px
  }

  .nameAvail {
    border-right:3px solid #dbe8f7;
    border-left:1px solid #dbe8f7;

    /* text-align: right; */
    display: flex;
    justify-content: flex-end;
  }


  .nameAvailMob {
    justify-content: flex-start;
    padding-left:44px
  }

  .availBlob {
    background-color: #92d378;
    min-width: 4px;
    min-height: 4px;
    border-radius: 4px;
    margin-right: 6px;
    margin-left: -3px;
  }

  .unavailBlob {
    background-color: #d77d7d;
  }
  

  .todayStreamCol {
    border-left: 3px solid #00aaff
  }

  .whiteBorderBottom {
    border-bottom: 1px solid #e8edf1
  }

.rotaViewOptions {
  background-color: #8f85eb;
  border-color: #756dba;}


  @media only screen and (min-width: 820px) {
    .rotaViewOptions:hover {
background-color: #8d85d2;
    }
  
  
  }

  .teamSchedOptionsBox {
    min-width: 135px;
    max-width: 135px;
    min-height: 60px;
    max-height: 60px;
    border: 1px solid #bbcada;
    /* background-color: blue; */
    position: fixed;
    top: 215px;
    left: 174px;
  }

  .teamSchedViewOption {
    display: flex;
    align-items: center;
  }

  .teamSchedViewOptionTxt {
    font-size: 13px;
    color: #214978;
    font-weight: 500;
    margin-left: 7px;
  }

  .limKnob {
    width: 12px !important;
    height: 12px !important;
    position: relative !important;
    top: 12px !important;
    border-radius: 50px !important;
    border: 1px solid #143051 !important;
    }

  .knobBgLim {
    background-color: #dedce2 !important;
    height: 12px !important;
    width: 22px !important;
    border-radius: 50px !important;
    /* position: fixed; */
    margin: auto !important;
    margin-top: 0px !important;
    margin-left: 5px !important;
  }

  .limKnobHolder {
margin-top:-10px;
    margin-right:0px !important;
    margin-left:5px;
    /* margin-right:8px; */
  }


  .rotaDeleteDropPort {
    min-width: 100px;
    min-height: 100px;
    background-color: #f4f8fe;
    position: fixed;
    bottom: 110px;
    right: 60px;
    z-index: 999999999;
    border: 3px dashed #bbcada;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rotaDelPortBinImg {
    width:40px;
    opacity:0.8
  }

  .dupeUnderlay {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 50%);
    height: 100%;
    min-width: 100vw;
    position: fixed;
    z-index: 9999999;
    top: 0;
    left: 0;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;

    /* -webkit-backdrop-filter: blur(3px); */
    /* backdrop-filter: blur(3px); */
    /* background-color: rgb(119 119 119 / 30%); */
  }

  .mobileDupeBtn {
    background-color: white;
    position: fixed;
    bottom: 85px;
    left: 0px;
    z-index: 999999999;
    min-width: 100vw;
    padding-left:2%;
    padding-right:2%;
    min-height: 60px;
    border-radius: 15px 15px 0 0;
    -webkit-animation: slideDupeUp 0.45s forwards;
            animation: slideDupeUp 0.45s forwards; /* Apply animation */
display:flex;
align-items: center;
justify-content: space-around;
    /* min-width: 130px;
    min-height: 40px;
    background-color: #403a8e;
    position: fixed;
    top: 190px;
    right: 20px;
    z-index: 999999999;
    border: 2px solid #2a2479;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: white;
    font-size: 16px; */
  }


@-webkit-keyframes slideDupeUp {
  from {
      bottom: -120px; /* Start off screen */
  }
  to {
      bottom: 85px; /* Slide up to desired position */
  }
}


@keyframes slideDupeUp {
  from {
      bottom: -120px; /* Start off screen */
  }
  to {
      bottom: 85px; /* Slide up to desired position */
  }
}


  .duplicateShMobImg {
    max-width: 18px;
    /* margin-top:1px; */
    margin-left:10px;
  }

  .x32849832 {
    margin-top:-3px;
    max-width: 16px;

  }

  .dupeMobBtn {
    background: #a58ed4;
    max-width: 29%;
    min-width: 29%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border: 1px solid #a47fd0;
    border-radius: 5px;
    font-weight: 600;
    min-height: 35px;
    padding-left: 7px;
    padding-right: 7px;
    font-size: 14px;

  }

  .greyColours {
    background-color: #cbd7e6;
    border-color: #bbcada;
  }

  .streamedSh {
    /* background-color: blue !important; */
    border:2px solid #214978;
    min-width: 132px;
    max-width: 132px;
    margin-right:2px;
    /* background: #f4f4f4  !important */
  }

  .streamMoving {
    background-color:#e8fdf2 !important;
    cursor: pointer;
    padding-right:3px;
    border-left:1px solid #dbe8f7 
  }

  .moveShiftHereDottedIconStream {
    margin-left:0px;
    margin-right:8px;
    margin-left:8px;
    display:none
  }

  .canxMoveShMob {
    position: fixed;
    bottom:110px;
    left: calc(50% - 30px);
    z-index:99999999;
    /* background-color: white; */
    display:flex;
    align-items: center;
    justify-content: center;
    border-radius: 110px;
    padding:18px;
    border:2px solid #bbcada;
    opacity: 0.85;
    min-width: 65px;
    max-width: 65px;
    min-height:65px;
    max-height:65px;
    cursor: pointer;
  }

  @media only screen and (min-width: 820px) {
    .canxMoveShMob:hover {
-webkit-transform: scale(1.1);
        transform: scale(1.1);  }

  }

  .streamMoving:hover {
    background-color: #d5efe1 !important;
  }

  .canxMovImg {
    width:25px
  }

  .movingStreamShiftBox {
border:2px solid #214978;
background-color: white !important;
-webkit-animation-name: zoomModalIn !important;
        animation-name: zoomModalIn !important;
-webkit-animation-duration: 0.1s !important;
        animation-duration: 0.1s !important;
min-width: 132px;
max-width: 132px;
margin-right:8px;
}

.dayLimknob {
  /* background:blue */
  margin-top:-11px;
}

.leaveLimtsA1 {
  /* background-color: blue; */
  min-height:30px;
  max-height:30px;
  border-top:1px solid #e3e8ee
}

.x9283711 {
  margin-left:-7px
}

.disableLimsBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 35px;
  max-height: 35px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;}



  

  .ghostStreamBg {
    background-color: #214978;
    max-height: 28px;
    min-height: 52px;
    margin-top: 4px;
    max-width: 30px;
    min-width: 132px;
    border-radius: 4px;
   }
    

    @media only screen and (min-width: 820px) {
      .disableLimsBtn:hover {
  background-color:#bbcada    }
    
  .ghostStreamBg:hover {
    background-color:#143051    }
    }
  

    .leaveLimsA1Sub {
      min-height:57px; 
      border-left:1px solid #dbe8f7;
      border-right:3px solid #dbe8f7
    }

    .limDisabledTitle {
      min-height: 24px;
      margin-top: 2px;
      max-height: 24px;
      font-weight: 500;
      color: #bbcada;
      font-size: 10px;
      padding-right: 3px;
      padding-top: 6px;

    }

    .openRate {
      background-color: #e5a9db;
      color: white;
      font-size: 10px;
      padding-left: 4px;
      padding-right: 4px;
      font-weight: 600;
      border-radius: 3px;
      max-height: 14px;
      padding-top:1px;
      line-height: 12px;
      margin-top: 1px;
      margin-left: 4px;
    }

    .jtTopperWidthStream {
      min-width: calc(100% + 0px) !important;
    }

    .widerDayColStreamJt {
      min-width: 194px !important;
    }

    .x123456789 {
      border-right:0.5px solid #00aaff
    }

    .jtPowerSwitch {
      /* background:orange; */
      display: flex;
      cursor: pointer;
      align-items: center;
      padding-bottom:4px;
    }

    .knobOnJtLim {
      background-color: #0af !important
      /* ; */
    }

    .x99001 {
      margin-right: 4px !important;
      padding-right: 4px !important;
    }

    .hovDupeImg {
      min-width: 18px;
      max-width: 18px;
      position: relative;
      margin-left: 20px;
      margin-top: 20px;
      opacity: 0.8;
      -webkit-animation-name: zoomModalIn !important;
              animation-name: zoomModalIn !important;
      -webkit-animation-duration: 0.1s !important;
              animation-duration: 0.1s !important;
      /* display:none */
    }

    .x3289489d34 {
      margin-right: 6px;
      margin-left:-24px; 
    }

    .hovDupeImgx88 {
      margin-left:8px;
      /* min-width: 21px; */
      /* max-width: 21px; */
    }
    .hovDupeImg:hover {
      /* background-color: white; */
      opacity:1;
      min-width: 23px;
      max-width: 23px; 
      margin-left: 17px;

    }

    .hovDupeImgx88:hover {
      margin-left: 6px;

    }

    .mobMyRotaSched {
      min-height: calc(100vh - 250px);
      max-height: calc(100vh - 250px);    }


      .myRotaSchedContainerDesktop {
        padding-left:18px;
    
      }

      .mobUserRotaSchedHeight {
        min-height: calc(100vh - 250px);
        max-height: calc(100vh - 250px);  
      }

      .desktopMyRotaWidthSched {
        min-width: calc(100vw - 305px);
        max-width: calc(100vw - 305px);
      }

      .mobMyRotaWidthSched {
        /* min-width: calc(100vh - 292px);
        max-width: calc(100vh - 292px); */
      }



      .calClockedOnSpan {
        color:white;
        border-radius:3px;
        padding:2px;
        background-color:#00ccff;
        font-size:11px;
        margin-left:2px;
        padding-left:4px;
        padding-right:4px;
        margin-left:5px;
      }

      .pend {
        background-color: #deedff !important;
        border: 1px solid #8bc3ff;
        border-bottom: 2px solid #8bc3ff !important
      }



      .pendClockName {
        background-color: #deedff !important;
        border:1px solid #dee5f0;
        border-bottom:none !important
      }
      .formSubName {
        background-color: #deedff !important;
        border: 1px solid #dee5f0;
        border-top: none !important;
        color: #859dbb;
      }
      .pendClockSub {
        background-color: #f1f7ff !important;
      }

      @media only screen and (min-width: 820px) {
        
   
       .x00191342323:hover {
        /* border-left:#0077ff  5px solid !important */
      }

       .mgrReqItem:hover {
        border-color:#bbcada !important
       }

       .pend:hover {
        border-color: #5395e3 !important;
      

     }
      }

      .bbcada {
        color:#bbcada !important
      }

      .otherTeamBlob {
        background-color: #bbcada;
      }

      .x3832874827 {
        border-top: 1px solid #c3d6e3 !important
      }

      .x32897478243 {
        border-bottom: 1px solid #c3d6e3 !important
      }

      .staffRotaMasterHrs {
        position: fixed;
        top: 62px;
        right: 314px;
        text-align: right;
        min-width: -webkit-fit-content;
        min-width: -moz-fit-content;
        min-width: fit-content;
        font-weight: 500;
        color: #bbcada;
        font-size: 14px;
        border-right: 1px solid #e6eaee;
        display: flex;
        align-items: center;
        padding-right: 10px;
        min-height: 33px;
      }

      .staffRotaMasterHrsMidMob {
        position: fixed;
        top: 61px;
        right: 137px;
        border-right: none;
        opacity: 0.7;
        color: white;
      }

      .staffRotaMasterHrsSmallMob {
        position: fixed;
        top: 70px;
        right: 15px;
        border-right: none;
        opacity: 0.7;
        color: white;
      }


      .absShiftBoxSelect {
        background: white;
    min-width: calc(100% - 20px);
    border: 1px solid #e6eaee;
    border-radius: 5px;
    border-top: solid 2px #e6eaee;
    border-right: 1px solid #e6eaee;
    border-left: 1px solid #e6eaee;
    margin-top: 7px;
    max-height: 100px;
    min-height: 100px;
    overflow-y:auto;
    margin-top:10px;
    padding-bottom:5px;
      }

      .markAbsShiftItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #dee5f0;
        border-radius: 5px;
        margin-top: 6px;
        max-width: 94%;
        margin-left: 3%; 
        min-height:45px;
      max-height:45px   ;
      cursor: pointer;
    padding-left:10px;
    background-color: #f4f8fe;
  padding-right:10px;  }

      .markAbsShType {
        max-height: 16px;
        line-height: 16px;
        font-size: 14px;
        font-weight: 500;
    }      

      .markAbsShTime {
        max-height: 16px;
        line-height: 16px;
        font-size: 14px;
        font-weight: 500;
        color: #143051;
          }

.markAbsShDate {
  max-height: 14px;
  line-height: 14px;
  font-size: 10px;
  font-weight: 500;
  color: #8ea7c5;
  margin-top: 4px;
  margin-bottom: -3px;

}

      .markAbsShRight {
        text-align: right;
        margin-right: 6px;
        min-height: 35px;
      }

      .noAbsShTxt {
        text-align: left;
        color: #bbcada;
        font-weight: 500;
        font-size: 12px;
        margin-left: 14px;      }

.markAbsPeriodBtn {
  font-weight: 500;
  text-align: left;
  font-size: 14px;
  border: 1px solid #8a98fc;
  background-color: #a4afff;
  border-radius: 5px;
  max-width: 190px;
  cursor: pointer;
  max-height: 35px;
  display: flex;
  align-items: center;
  text-align: center;
  padding-left: 7px;
  margin-top: 20px;
  color: white;
  padding-right: 5px;
  margin-left: 20px;
  margin-bottom: 30px;
}

.x38492749272 {
  margin-top: -80px !important;
  padding-left: 25px;
  max-height: 30px;}

  .markAbsPeriodBtnNoSh {
    margin-top:6px;
  }

  .makeAbsRowFlash {
    -webkit-animation: flashingBackground 1s ease-in-out infinite;
            animation: flashingBackground 1s ease-in-out infinite; /* Change 'body' to your target element */
  }

  @-webkit-keyframes flashingBackground {
    0% {
      background-color: #FFFFFF; /* Start with white */
    }
    50% {
      background-color: #ece9f9; /* Transition to light greyish-blue */
    }
    100% {
      background-color: #FFFFFF; /* Transition back to white */
    }
  }

  @keyframes flashingBackground {
    0% {
      background-color: #FFFFFF; /* Start with white */
    }
    50% {
      background-color: #ece9f9; /* Transition to light greyish-blue */
    }
    100% {
      background-color: #FFFFFF; /* Transition back to white */
    }
  }

  .x248943834834 {
    font-size:10px
  }

  .tilStripWidther{
    max-width: 80px;
    font-size: 12px;
    line-height: 12px;
    margin-top: 4px !important;
    margin-bottom: -2px;}

    .x328929849289822 {
      max-width: 92px;
      font-size: 11px;  }

.editMyShiftOrigianal {
  background-color: white;
  border: 1px solid #e0ebf8;
  border-radius: 5px;
  width: 90%;
  text-align: left;
  margin-left: 5%;
  margin-bottom: 20px;
  font-size:12px;
  font-weight: 500;
  padding:8px 20px 8px 20px;
  color:#143051

}

.originalEditSValRow {
  display:flex;
  justify-content: space-between;
  align-items:center;
  border-top: 1px solid #e0ebf8;
  min-height:30px;

}

.origTitle {
  color:#214978; font-weight:400;

}

.editShArr {
  width: 33px;
  margin-top: -40px;
  margin-bottom: 7px;}


  .streamSchedBottomCostBlock {
    background-color: #214978;
    min-height: 50px;
    color: white;
    font-size:16px;
    border-top: 3px solid #143051;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
  border-right:3px solid #143051;
  font-size:13px;
  }

  .streamSchedBottomTitle {
    font-size: 10px;
    opacity: 0.5;
  }

  .alignRight2 {
    float: right;
  }

  .alignLeft2 {
    float: left
  }

  .teamCostInSh {
    margin-left:6px;
    cursor: pointer;
  }

  .teamCostinclSpan {
    margin-left: 6px;
    color: #bbcada;
    font-size: 10px;
    margin-top: -15px;
    margin-bottom: -18px;
  }

  .shTeamCostArr {
    max-width: 10px;
    margin-bottom: 1px;  }

    .shTeamCostArrActive {
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
    }

    .shCostArr {
      max-width: 225px;
      border-radius: 5px;
      font-size: 12px; 
    margin-bottom:20px;   }

    .shCostArrRow {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #bbcada;
      max-height: 35px;
    }

    .shCostArrTitle {
      font-weight: 400;
    }

    .shCostArrValue {
      color:#143051;
      font-weight: 500;
    }

    .x8382818133 {
      cursor: default;
    }

    .x8382818133:hover {
      opacity: 1;
    }



.fadeEditSh {
  opacity:0.5
}
.footer-menu {
  height: 45px;
  background-color: #fff;
  /* background-color: #143051; */
  width: 100%;
  min-width: 100%;

  /* max-width: 200px !important; */
  /* background-color: yellow; */
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  /* padding-bottom: 4px; */
  z-index: 999999;
  /* border-radius: 10px 10px 0 0; */
  box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 16%);
  /* border-top: 1px rgb(185, 190, 213) solid; */
  padding-bottom: 1px;
}

.homeFooterMobile {
  background-color: #143051;
  /* background-color: yellow; */
  border-top: none;
  /* margin-bottom: 4px; */
}

.footer-menu-hidden {
  display: none;
}
.footerBtn {
  /* min-width: 28%; */
  /* max-width: 20%; */
  /* background-color: blue; */
  padding-left: 20px;
  padding-right: 20px;
  margin-top: -4px;
  min-height: 52px;
}

.footerBtnToucher:active {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

/* .footerBtn {
  margin: auto;
  text-align: center;
  flex: 1;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: blue;
  max-width: 20%;
  min-width: 20%;
} */

.footerTouchHolder {
  background-color: rgb(209, 209, 212);
  min-height: 52px;
  max-height: 52px;
  min-width: 52px;
  max-width: 52px;
  border-radius: 100%;
  margin-top: -50px;
  margin-left: 8px;
  -webkit-animation-name: zoomToucherIn !important;
          animation-name: zoomToucherIn !important;
  -webkit-animation-duration: 0.5s !important;
          animation-duration: 0.5s !important;
  opacity: 0;
}

.accountToucher {
  margin-left: 3px;
}

.calToucher {
  margin-left: 6px;
}

.homeToucher {
  margin-left: 17px;
}

@-webkit-keyframes zoomToucherIn {
  from {
    /* bottom: -10%; */
    opacity: 0.6;
    -webkit-transform: scale(0.55);
            transform: scale(0.55);
  }
  to {
    /* bottom: 0; */
    opacity: 0.4;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  to {
    opacity: 0;
  }
}

@keyframes zoomToucherIn {
  from {
    /* bottom: -10%; */
    opacity: 0.6;
    -webkit-transform: scale(0.55);
            transform: scale(0.55);
  }
  to {
    /* bottom: 0; */
    opacity: 0.4;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  to {
    opacity: 0;
  }
}
img.f2:active {
  /* width: 24px; */
  display: none;
  margin-top: 16px;
  -webkit-transform: scale(0.92);
          transform: scale(0.92);

  /* z-index: -1; */
}
img.f3:active {
  /* width: 24px; */
  margin-top: 16px;
  -webkit-transform: scale(0.92);
          transform: scale(0.92);
  /* z-index: -1; */
}

/* #footerBtn:active {
  max-width: 10px;
} */

.f1 {
  margin-top: 15px;
  margin-left: 12px;
  min-width: 22px;
  max-width: 22px;
  position: relative;
  z-index: -1;
}

.f2 {
  margin-top: 15px;
  min-width: 23px;
  max-width: 23px;
  position: relative;
  z-index: -1;
}

.f4Container {
  border-radius: 5px;
  max-width: 36px;
  min-width: 36px;
  min-height: 36px;
  max-height: 36px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  background-color: #07f;
  border: #0469de 2px solid;
  cursor: pointer;
}

.f4ContainerActive {
  background-color: #214978;
  border-color: #496375;
}

.f4 {
  /* margin-top: 10px; */
  min-width: 16px;
  max-width: 16px;
  z-index: 100;
  overflow: hidden;
  transition-duration: 0.05s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);

  /* background-color: #0077ff;
  padding: 10px; */
}

.f4:active {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);

  /* min-width: 20px; */
  /* max-width: 20px; */
}

.rotate45degs {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  overflow: hidden;
  transition-duration: 0.05s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.rotate45degs:active {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.f3 {
  margin-top: 15px;
  min-width: 23px;
  max-width: 23px;
}

.cancelShowAddOptions {
  /* margin-top: -1px; */
  min-width: 40px;
  max-width: 40px;
}

.cancelShowAddOptions:active {
  /* margin-top: 1px; */
  min-width: 40px;
}

.f5 {
  margin-top: 15px;
  position: relative;
  z-index: -1;
  margin-right: 12px;
  max-width: 22px;
  min-width: 22px;
}

.addOptionsUnderlay {
  /* background: rgba(0, 0, 0, 0.82); */
  min-height: calc(100% - 58px);
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 70%);
}

.showOptionsBtnsContainer {
  position: fixed;
  bottom: 78px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.jamBtnsContainer {
  opacity: 0.15;
  z-index: -1;
  /* pointer-events: none; */
}

.jamNavbarTxt {
  color: white;
  max-width: 300px;
  border-radius: 20px;
  border: 2px solid white;
  padding: 20px;
  background-color: #143051;
}

.addLeaveOption {
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: orange; */
  min-height: 46px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 18px;
  color: #214978;
  font-weight: 600;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #496375;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.15s !important;
          animation-duration: 0.15s !important;
  -webkit-animation-delay: 0ms;
          animation-delay: 0ms;
  cursor: pointer;
}

.mobNavClockOnBtn {
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: orange; */
  min-height: 46px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 18px;
  color: #214978;
  font-weight: 600;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #496375;
  background-color: #00ccff;
  border: #0af 1px solid;
  color:#fff;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.15s !important;
          animation-duration: 0.15s !important;
  -webkit-animation-delay: 0ms;
          animation-delay: 0ms;
  cursor: pointer;
}

.leaveBubbleSolo {
  color: #fff;
  margin-bottom: 0px;
  /* background-color: #00ccff;
  border: #0af 1px solid; */
  background-color: #d6a4ff;
  border: #b773f0 1px solid;
  cursor: pointer;
}

.reqModalOnTheNavBar {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  z-index: 99999999999;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 30%);
    display: flex;
  align-items: center;
  justify-content: center;
}

.mobBurger {
  /* background-color: #143051; */
  /* border-radius: 0 0 7px 0px;
  position: absolute;
  min-height: 30px;
  min-width: 30px;
  max-width: 30px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 8px;
  left: 12px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 5px;
  padding-top: 5px;
  z-index: 9999;
  min-width: 18px;
  cursor: pointer; */
}

.whiteCrossMobMenu {
  position: absolute;
  top: 8px;
  left: 10px;
  padding-left: 4px;
  padding-right: 8px;
  padding-bottom: 5px;
  padding-top: 3px;
  z-index: 9999;
  min-width: 28px;
}

.mobMenuDiv {
  min-height: 100vh;
  max-height: 100vh;
  background-color: #fafbff;
  min-width: 80vw;
  max-width: 80vw;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation-name: slideMenuFromLeft !important;
          animation-name: slideMenuFromLeft !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  border-right: 2px #3333 solid;
  border-radius: 0px 20px 0 0;
  z-index: 9999;
}

.mobMenuUnderlay {
  min-width: 100vw;
  min-height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.managerMenuList {
  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 60px;
  min-height: 180px;
  /* padding-left: 20px; */
}

.myMenuList {
  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 60px;
  min-height: 135px;
  /* padding-left: 20px; */
}

.manageMenuItem {
  min-height: 45px;
  max-height: 45px;
  display: flex;
  align-items: center;
  font-size: 1.3em;
  font-weight: 500;
  padding-left: 30px;
  min-width: 100%;
  border-bottom: 1px solid #2e4657;
  color: white;
}

.manageMenuItem:last-child {
  border: none;
}

.indicate {
  position: fixed;
  top: 0px;
  background-color: #0af;
  padding-left: 18px;
  padding-right: 18px;
  min-height: 40px;
  min-width: calc(100vw - 10px);
  /* margin-right: 0.5vw; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  left: 5px;
  top: 5px;
  border-radius: 5px;

  /* right: 0%; */
  display: flex;
  justify-content: center;
  /* margin-right: 394px; */
  font-size: 1.25em;
  font-weight: 500;
  /* border-radius: 0 0 8px 8px; */
  padding-bottom: 2px;
  z-index: 999999999 !important;
  border: 1px solid #07f;
  /* goaty */
  /* margin-right: 48vw; */
  /* margin: 0 auto; */

  cursor: pointer;
}

.animateIndicate {
  -webkit-animation-name: heightDown !important;
          animation-name: heightDown !important;
  -webkit-animation-duration: 0.3s !important;
          animation-duration: 0.3s !important;
}

.desktopIndicate {
  left: 0px;
  top: 0px;
  min-width: 100vw;
  border-radius: 0px;
  border-left: none !important;
  border-top: none !important;
  border-right: none !important;


  left: 0px;
  top: 0px;
  min-width: 100vw;
  border-radius: 0px;
  border-left: none !important;
  border-top: none !important;
  border-right: none !important;
  background: white;
  color: #143051;
  font-size: 14px;
  font-weight: 500;
  color: #516b8b;
  border: 1px solid #bbcada;
  border-radius: 5px;
  max-width: 630px;
  min-width: 630px;
  margin-left: calc(50% - 315px);
  margin-top: 10px;
}

.instructIndicate {
  background-color: #fa903d;
  border: 1px solid #ca7837;
}

.approveIndicate {
  background-color: #6dba4f;
  border: 1px solid #5a9e3f;
}

.declineIndicate {
  background-color: #e26666;
  border: 1px solid #ac4848;
}

.blueIndDesktopBlob {
  min-width: 12px;
  min-height:12px;
  max-width: 12px;
  max-height:12px;
  border-radius: 40px;
  margin-right:10px;
  background-color: #00aaff;

}

.greenIndDesktopBlob {
  background-color: #6dba4f;
}
.redIndDesktopBlob {
  background-color: #e26666;
}
.orangeIndDesktopBlob {
  background-color: #fa903d;
}


.indicateTxt {
  display: flex;
  align-items: center;
}
.mobIndicateTxt {
  font-size: 18px;

}

.desktopHeaderHolder {
  min-height: calc(100vh - 30px);
  max-height: calc(100vh - 30px);
  position: fixed;
  top: 0;
  /* left: 0; */
  min-width: 240px;
  max-width: 240px;
  background-color: white;
  z-index: 9;
  /* border-left: 1px solid #e6eaee; */
}

.desktopBottomBar {
  min-height: 30px;
  background-color: white;
  border-top: 1px solid #e6eaee;
  max-height: 50px;
  min-width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.device {
  color: #c2cce9;
  /* background-color: orange; */
  font-size: 12px;
}

.rotaCalPageBottomBarHeight {
  min-height: 10px;
  max-height: 10px;
}

.pageLeft240pxDesktop {
  margin-left: 240px;
  max-width: calc(100vw - 240px) !important;
  min-width: calc(100vw - 240px) !important;
}

.borderLeftGrey {
  border-left: 2px solid #e6eaee !important;
}
.desktopHeaderTopBarLogoAndClockOnDiv {
  min-height: 66px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.headerLogoTimelify {
  max-width: 20px;
  margin-top: 17px;
  margin-bottom: 10px;
  margin-left: 7px;
}
.headerLogoTimelifyTxt {
  max-width: 83px;
  margin-top: 13px;
  margin-bottom: 8px;
  margin-left: 5px;
}

.headerLogoTimelifyRota {
  margin-left: 7px;
}

.headerLogoTimelifyRotaTxt {
  margin-left: -16px;
  max-width: 70px;
}
.desktopHeaderMenuDiv {
  margin-top: 95px;
  overflow-y: scroll;
  /* overflow-x: none; */
  max-height: calc(100vh - 201px);
  padding-top: 20px;
  /* background-color: pink; */
}

.headerMenuIfClockOnNotAllowed {
  max-height: calc(100vh - 209px);
  /* background: blue; */
  border-top: 1px solid #f1f5fa;
  margin-top: 37px;
}
.desktopHeaderMenuBtn {
  /* background-color  : yellow; */
  min-width: 140px;
  /* min-width: 100%; */
  display: flex;
  justify-content: flex-start;
  min-height: 30px;
  max-height: 30px;
  /* border-bottom: 1px solid #f3f6f8; */
  align-items: center;
  font-size: 14px;
  color: #143051;
  font-weight: 500;
  padding-left: 10px;
  cursor: pointer;
}

.desktopHeaderMenuBtnMgr {
  /* min-width: 100%; */
  /* border-bottom: 1px solid #f3f6f8; */
  display: flex;
  justify-content: flex-start;
  min-height: 30px;
  max-height: 30px;
  align-items: center;
  font-size: 16px;
  color: #496375;
  font-weight: 300;
  padding-left: 55px;
  cursor: pointer;
}

.desktopHeaderMenuBtn:hover {
  /* color: #0077ff; */
  /* font-weight: 400; */
  min-height: 30px;
  max-height: 30px;
  /* margin-left: -1px; */
}

.desktopHeaderMenuBtnMgr:hover {
  color: #a387df;
  font-weight: 400;
  min-height: 40px;
  max-height: 40px;
  margin-left: -1px;
}

.desktopHeaderBtnSelected {
  /* min-width: 75%;
  max-width: 75%; */
  /* margin-left: 15%; */
  display: flex;
  justify-content: flex-start;
  min-height: 40px;
  align-items: center;
  font-size: 16px;
  color: #143051;
  padding-left: 20px;
  cursor: pointer;
  font-weight: 500;
  background-color: #0077ff;
  color: white;
  border-top: 1px solid #1270db;
  border-bottom: 1px solid #1270db;
  cursor: default;
  border-radius: 5px;
  margin-top: 10px;
}

.desktopHeaderBtnSelectedWithSub {
  border-radius: 5px 5px 0 5px !important;
}

.desktopHeaderBtnSelectedMgr {
  min-width: 75%;
  max-width: 75%;
  margin-left: 15%;
  display: flex;
  justify-content: flex-start;
  min-height: 40px;
  align-items: center;
  font-size: 16px;
  color: #143051;
  padding-left: 20px;
  cursor: pointer;
  font-weight: 500;
  font-weight: 500;
  background-color: #4b1caf;
  color: white;
  border-top: 1px solid #3e1c88;
  border-bottom: 1px solid #3e1c88;
  cursor: default;
  border-radius: 5px;
}

.desktopHeaderPanel {
  display: flex;
  min-width: 168px;
  max-width: 168px;
  /* margin: 20px auto; */
  margin-top: 0px;
  margin-left: 40px;
  justify-content: space-between;
  margin-bottom: -26px;
}

.desktopNotifbtn {
  font-weight: 500;
  border: 1px solid #e0ebf8;
  border-radius: 5px;
  max-height: 38px;
  min-height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 38px;
  max-width: 38px;
  cursor: pointer;
}

.desktopAddbtn {
  font-weight: 500;
  border: 1px solid #e0ebf8;
  border-radius: 5px;
  max-height: 38px;
  min-height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  max-width: 70px;
  cursor: pointer;
  color: #2d5a8f;
}

.addBtnShield {
  position: absolute;
  top: 66px;
  left: 138px;
  font-weight: 500;
  border: 1px solid #cbd7e6;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;

  border-bottom: 1px solid #f4f8fe;
  max-height: 39px;
  min-height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;
  max-width: 70px;
  cursor: pointer;
  color: #2d5a8f;
  z-index: 999999;
  padding-bottom: 1px;
}

@media only screen and (min-width: 1140px) {
  .addBtnShield {
    left: calc(50vw - 432px);
  }

  .addDesktopBox {
    left: calc(50vw - 432px) !important;
  }
}

.teamRotaAddBtnShield {
  /* background-color: orange; */
  top: 66px;
  left: 113px;
}

.desktopAddbtnSelected {
  background-color: #fff;
  border: #143051 1px #cbd7e6;
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid #fff;
  z-index: 999999;
  color: #214978;
}

.desktopAddbtn:hover {
  background-color: #f4f8fe;
}

.desktopNotifbtn:hover {
  background-color: #f4f8fe;
}

.desktopAddbtnSelected:hover {
  background-color: #214978;
  border: #143051 1px solid;
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid #214978;
  z-index: 999999;
  color: white;
}

.desktopHeaderNotifImg {
  max-width: 20px;
}

.desktopHeaderCalImg {
  max-width: 18px;
}

.desktopHeaderAddImg {
  max-width: 12px;
  margin-top: 1px;
  margin-right: 1px;
}

.desktopNotifBtnBg {
  background-color: #214978;
  border: #143051 1px solid;
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid #214978;
  z-index: 999999;
}

.desktopNotifBtnBg:hover {
  background-color: #214978;
  border: #143051 1px solid;
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid #214978;
  z-index: 999999;
}

.desktopPlusTxt {
  font-size: 14px;
  margin-left: 3px;
}

.desktopNotifBox {
  background-color: #1e3249;
  border: #143951 1px solid;
  border-radius: 5px;
  position: fixed;
  top: 100px;
  left: calc(50vw - 560px);
  z-index: 99999;
  min-width: 375px;
  max-width: 375px;
  min-height: 60vh;
  -webkit-animation-name: slideDesktopMenuDown2 !important;
          animation-name: slideDesktopMenuDown2 !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  cursor: default;
}

.desktopUpcomingBox {
  background-color: #1e3249;
  border: #143051 1px solid;
  border-radius: 5px;
  position: fixed;
  top: 100px;
  left: calc(50vw - 530px);
  z-index: 99999;
  min-width: 375px;
  max-width: 375px;
  max-height: 70vh;
  cursor: default;
  -webkit-animation-name: slideDesktopMenuDown2 !important;
          animation-name: slideDesktopMenuDown2 !important;
  -webkit-animation-duration: 0.25s !important;
          animation-duration: 0.25s !important;
  cursor: default;
  /* overflow-y: auto; */
}

@media only screen and (max-width: 1159px) {
  .desktopNotifBox {
    left: 10px;
  }

  .desktopUpcomingBox {
    left: 62px;
  }
}

.rotaNotifsBoxPosition {
  left: 10px;
}

.rotaUpcomingBox {
  left: 60px;
}

.desktopNotifUnderlay {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 999999;
  /* background-color: transparent; or use opacity: 0; */
  /* pointer-events: none; */
  /* background-color: rgb(0, 0, 0, 0.12); */
  /* cursor: pointer; */
  /* -webkit-backdrop-filter: blur(3px); */
  /* backdrop-filter: blur(3px); */
  /* background-color: rgb(119 119 119 / 50%); */
}

.shadowUnderlay {
  background-color: rgb(119 119 119 / 20%) !important;
}

.notifGhost {
  min-height: 40px;
  min-width: 40px;
  position: fixed;
  top: 130px;
  margin-left: 42px;
  z-index: 111;
  cursor: pointer;
}

.upcomingGhost {
  min-height: 40px;
  min-width: 40px;
  position: fixed;
  top: 124px;
  margin-left: 0px;
  z-index: 111;
  cursor: pointer;
}

.addGhost {
  cursor: pointer;
  min-height: 40px;
  min-width: 70px;
  position: fixed;
  top: 124px;
  margin-left: 96px;
  z-index: 111;
  cursor: pointer;
}

.upcomingGhost2 {
  cursor: pointer;
  min-height: 40px;
  min-width: 40px;
  position: fixed;
  top: 124px;
  left: 88px;
  z-index: 99999;
  cursor: pointer;
}

.notifGhost2 {
  cursor: pointer;
  min-height: 40px;
  min-width: 40px;
  position: fixed;
  top: 124px;
  left: 40px;
  z-index: 99999;
  cursor: pointer;
}

.addDesktopBox {
  background-color: #f4f8fe;
  border: #cbd7e6 1px solid;
  border-radius: 0 5px 5px 5px;
  position: fixed;
  top: 104px;
  left: 138px;
  z-index: 99999;
  min-width: 135px;
  max-width: 135px;
  /* min-height: 170px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 12px;
  cursor: default;
  padding-bottom: 5px;
  -webkit-animation-name: slideDesktopMenuDown !important;
          animation-name: slideDesktopMenuDown !important;
  -webkit-animation-duration: 0.2s !important;
          animation-duration: 0.2s !important;
  max-height: 211px;
}

.teamRotaAddDesktopBox {
  top: 102px !important;
  left: 113px !important;
}

.desktopLeaveReqBtn {
  color: #fff;
  margin-bottom: 0px;
  background-color: #07f;
  border: #0469de 1px solid;
  cursor: pointer;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  min-width: 92%;
  margin-top: 12px;
  font-size:14px;
}

.desktopLeaveReqBtn:hover {
  background-color: #036ce4;
}

.desktopAddBtns {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35px;
  padding-left: 12px;
  padding-right: 12px;
  color: #214978;
  margin-top: 8px;
  font-weight: 500;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #eceff2;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.15s !important;
          animation-duration: 0.15s !important;
  -webkit-animation-delay: 0ms;
          animation-delay: 0ms;
  cursor: pointer;
  font-size:14px;
}

.desktopAddBtns:hover {
  background-color: #f3f6f8;
}

.desktopAddBtns:last-child {
  margin-bottom: 8px;
}

.desktopClockOnDiv {
  min-height: 58px;
  max-height: 58px;
  background-color: #ebf0f7;
  margin-top: 37px;
  border-top: 2px solid #e6eaee;
  border-bottom: 2px solid #e6eaee;
  border-right: 1px solid #ebf0f7;
  min-width: 242px;
  margin-bottom: -30px;
  display: flex;
  justify-content: flex-start;
  padding-right: 10px;
  align-items: center;
  position: fixed;
  top: 10;
  /* left: 0; */
  z-index: 999;
}

.desktopHeaderClockOnBtn {
  font-weight: 500;
  border: 1px solid #e0ebf8;
  border-radius: 5px;
  max-height: 35px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  cursor: pointer;
  color: #2d5a8f;
  margin-left: 7px;
  padding-left: 10px;
  padding-right: 3px;
  background-color: white;
  font-size: 14px;
  margin-right: 5px;
}

.desktopHeaderClockOnBtn:hover {
  background-color: #f4f8fe;
}

.clockOnModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.swapModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 10000000;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}
.clockOnModalBox {
  background-color: #f4f8fe;
  border-radius: 10px;
  min-width: 380px;
  max-width: 380px;

  /* min-height: 400px; */
  cursor: default;
  /* animation-name: zoomModalIn !important; */
  /* animation-duration: 0.1s !important; */
}

@media only screen and (max-width: 375px) {
  .clockOnModalBox {
    min-width: 350px;
    max-width: 350px;
  }

  .clockOnDataTitle {
    display: flex;
    font-size: 14px !important;
  }
}

.swapModalHeader {
  background-color: #f4f8fe;
  border-radius: 10px;
  min-width: 380px;
  /* min-height: 400px; */
  cursor: default;
  /* animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important; */
}

.clockOnModalHeader {
  min-height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
  margin-top: 0px;
  /* background: yellow; */
  margin-bottom: -12px !important;
}


.xx283819812 {
  min-height: 41px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
  margin-top: 0px;
  /* background: yellow; */
  margin-bottom: 1px !important;
  margin-top: -5px !important;

}

  .x2398911d {
    min-height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 14px;
    padding-right: 14px;
    margin-top: 0px;
    padding-top:3px;
    /* background: yellow; */
    margin-bottom: 1px !important;
    margin-top: -10px !important;
  }

.clockOnModalHeaderSideUnit {
  min-width: 30px;
  display: flex;
  justify-content: space-between;
}

.clockTicker {
  min-width: 68px;
  font-family: "Courier New", Courier, monospace;
  /* background-color: green; */
  margin-left: 50px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 32px;
  /* padding: 8px; */
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  /* padding-top: 5px; */
  background-color: #916fdb;
  border: 1px solid #7656bc;
  font-size: 18px;
  line-height: 18px;
  /* margin-top: -6px; */
}

.clockTickerInvis {
  visibility: hidden;
  margin-left: -35px;
}

.clockedOnClockAndTeamDiv {
  display: flex;
  justify-content: flex-start;
  /* background-color: yellow; */
  /* max-width: 100%; */
  /* max-height: 60px; */
  margin-top: -10px;
}

.clockOnModalHeaderTitle {
  font-weight: 500;
  font-size: 18px;
  color: #140351;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  /* padding: left 10px; */
  /* padding-right:10px; */
}

.closeClockOnModalImg {
  cursor: pointer;
  margin-left: 8px;
}
.closeClockOnModalImg:hover {
  opacity: 0.8;
}

.liveClock {
  display: flex;
  font-size: 30px;
  /* margin-bottom:12px; */
  min-width: 104px;
  max-width: 104px;
  /* margin: 20px auto; */
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #0077cc;
  /* border: 2px solid rgb(206, 211, 218); */
  border: 2px solid #214978;
  border-radius: 5px;
  padding-bottom: 2px;
  position: relative;
  z-index: 999;
  margin-left: 40px;
  margin-top: 13px;
  margin-bottom: 16px;
  padding-right: 14px;
}

.liveClockHours,
.liveClockMins,
.liveClockColon {
  /* font-family: "Courier New", Courier, monospace; */
}

.clockOffClockColours {
  background-color: #fff;
  border: 2px solid #d3d9e0;
  color: #496375;
  font-size: 50px;
  min-width: 160px;
  max-width: 160px;
  margin: 20px auto;

  /* border-radius: 5px; */
}

.hideColon {
  opacity: 0.25;
  /* visibility: hidden; */
}

.scheduledClockOnContainer {
  display: flex;
  justify-content: space-between;
  min-height: 50px;
  align-items: center;
  border-bottom: 1px solid #e0ebf8;
  min-width: 100%;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  margin-bottom: 30px;
  margin-top: -16px;
  margin-bottom: 12px;
}

.x2345676543 {
  margin-top: -30px !important;
}

.x76543456 {
  /* margin-top:-40px !important */
}

.schedClockOnLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-right: 1px solid #e0ebf8;
  padding-right: 8px;
  min-width: 37%;
}

.schedClockOnRight {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-left: 1px solid #e0ebf8;
  padding-left: 12px;
  min-width: 37%;
}

.schedClockBreakMiddle {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 26%;
}

.schedClockBreakMiddleTitle {
  font-size: 12px;
  color: #214978;
  margin-bottom: 4px;
  /* padding-left: 3px; */
  max-width: 70px;
  font-weight: 500;
  text-align: center;
}
.schedClockBreakMiddleValue {
  font-weight: 500;
  font-size: 12px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  min-height: 24px;
  align-items: center;
  border-radius: 5px;
  /* color: #496375; */
  font-weight: 500;
  background-color: #f4f8fe;
  color: #4f566b;
  border: 1px solid #bbcada;
}

.schedClockOnLeftTitle {
  font-size: 12px;
  color: #214978;
  margin-bottom: 4px;
  padding-left: 4px;
  padding-right: 8px;
  text-align: left;
  font-weight: 500;
  max-width: 69px;
}

.schedClockOnLeftTitleClockedOn {
  font-size: 10px;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  color: #214978;
  margin-bottom: 2px;
  font-weight: 400;
  text-align: left;
  /* background-color: #7fbb67; */
  /* border: 1px solid #5a9e3f; */
  border-radius: 5px;
  padding-left: 5px;
  /* padding-right: 7px; */
  min-height: 20px;
  display: flex;
  align-items: flex-end;
}

.schedClockOnRightTitle {
  font-size: 12px;
  color: #214978;
  margin-bottom: 4px;
  padding-right: 7px;
  text-align: right;
  max-width: 70px;
  font-weight: 500;
  max-width: 69px;

}

.schedClockOnLeftValue {
  font-weight: 500;
  font-size: 13px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
  justify-content: space-between;
  max-height: 30px;
  min-height: 30px;
  align-items: center;
  border-radius: 5px;
  color: #496375;
  font-weight: 500;
  background-color: #f4f8fe;
  color: #4f566b;
  border: 1px solid #bbcada;
}

.schedClockOnRightValue {
  font-weight: 500;
  font-size: 13px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
  justify-content: space-between;
  max-height: 30px;
  min-height: 30px;
  align-items: center;
  border-radius: 5px;
  color: #496375;
  font-weight: 500;
  background-color: #f4f8fe;
  color: #4f566b;
  border: 1px solid #bbcada;
}

.schedClockSmallTxtL {
  min-height: 24px;
  padding-left: 4px;
  padding-right: 4px;
  text-align: left;
  max-width: 80px;
  font-size: 12px;
}

.schedClockSmallTxtR {
  min-height: 24px;
  padding-left: 4px;
  padding-right: 4px;
  text-align: right;
  max-width: 80px;
  font-size: 12px;

  /* font-size: 12px;
  padding-left: 2px; */
}

.clockOnContentBox {
  background-color: #fff;
  max-width: 90%;
  margin: 0 auto;
  /* min-height: 300px; */
  margin-top: -50px;
  z-index: -1;
  padding-top: 40px;
  border-radius: 5px 5px 0 0;
  border-top: solid 2px #e6eaee;
  border-right: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
  border-bottom: 1px solid #bac5d3;
  min-height: 200px;
  margin-bottom: 40px;
}

.loadingClockBox {
  /* min-height: 80px; */
}

.clockOnActionsDiv {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  padding-right: 5%;
  padding-left: 5%;
  margin-top: -40px;
}

.actionsClockOnBtn {
  min-height: 40px;
  max-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: 110px;
  font-size: 1.1em;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #0bf;
  border: 1px solid #0af;
}

.actionsClockOnBtn:hover {
  background-color: #0af;
}

.closeClockOnDiv {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
}

.closeClockOnDiv:hover {
  background-color: #214978;
}

.undoClockBtn {
  font-size: 14px;
  color: #fff;
  background-color: #cbd7e6;
  border: solid #bbcada 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
}

.undoClockBtn:hover {
  background-color: #bbcada;
}

.clockOnBtnContainer {
  max-width: 90%;
  margin-left: 5%;
  margin-bottom: 15px;
  margin-top: 15px;
}

.clockOffBtnContainer {
  max-width: 90%;
  margin-left: 5%;
  margin-bottom: 0px;
  margin-top: -3px;
  display: flex;
  justify-content: space-between;
}

.clockedActive {
  background-color: #fff;
  color: #07f;
  padding-left: 5px;
  /* padding-right: 25px; */
  margin-top: 0px;
  /* max-width: 50px; */
  text-align: left;
  font-size: 15px;
  line-height: 14px;
  margin-top: -4px;
  font-weight: 500;
}

.hideClockedOff {
  visibility: hidden;
}

.startBreakBtn {
  min-width: 48%;
  background-color: rgb(58, 101, 150);
  border: 1px solid rgb(45, 84, 107);
}

.startBreakBtn:hover {
  background-color: #07b;
  border: 1px solid #07b;
}

.clockOffBtnSmall {
  min-width: 48%;
}

.disableBrkBtn {
  pointer-events: none;
  visibility: hidden;
}

.clockBreakMap {
  display: flex;
  justify-content: space-around;
  /* background-color: yellow; */
  min-width: 100%;
  margin-top: 10px;
}

.breakClockUnit {
  min-width: 93px;
  max-width: 93px;
  min-height: 120px;
  padding-left: 8px;
  padding-bottom: 4px;
  padding-top: 4px;
  /* padding-right: 3px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* max-height: 24px; */
  align-items: flex-start;
  color: #496375;
  font-weight: 500;
  font-size: 14px;
  /* background-color: #e3e8ee; */
  color: #143051;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  border-right: 1px solid #e0ebf8;
}

.breakClockUnitInactive {
  min-width: 88px;
  max-width: 88px;
  visibility: hidden;
}

.smallerBreak1 {
  margin-bottom: 3px;
  max-height: 60px;
  min-height: 60px;
  margin-top: 5px;
}
.breakClockUnit:last-child {
  border: none;
}

.breakClockNum {
  color: #496375;
  margin-bottom: -8px;
  /* margin-top:4px; */
  /* padding: 2px 5px;
  font-size: 12px;
  border-left: 2px solid #bbcada; */

  font-size: 10px !important;
  color: #214978;
  font-weight: 400;
}

.breakClockStartTitle {
  font-size: 10px;
  margin-top: 8px;
  color: #859dbb;
  /* border-top: 1px solid #e0ebf8; */
  min-width: 60px;
  text-align: left;
  padding-top: 5px;
  font-weight: 500;
  /* text-transform: uppercase; */
}

.breakClockStartTitleDuration {
  margin-top: -5px;
  visibility: hidden;
}

.colorLightBlue {
  color: #00ccff !important;
}
.breackClockStartSpan {
  color: #516b8b;
  font-weight: 500;
  font-size: 15px;
  margin-top: 2px;
  /* border-bottom: 1px solid #140351; */
  min-width: 100%;
  text-align: left;
  /* padding-bottom: 5px; */
}

.breakEndUnit {
  text-align: left;
  margin-bottom:10px;
}

.clockTeamDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  padding-left: 17px;
  text-align: left !important;
  max-width: 100%;
  min-width: 100%;
  margin-bottom: 20px;
  line-height: 26px;
  height: 35px;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 2px solid #e6eaee;
  background-color: white;
  color: #143051;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 95%;
}

.clockTeamDropdown:hover {
  border-color: #bac5d3;
}
.clockedOnDataBox {
  min-width: 185px;
  max-width: 185px;
  /* max-height: 52px;
  min-height: 52px; */
  /* background-color: #d7e9fe; */
  border-radius: 5px;
  margin-left: 138px;
  display: flex;
  flex-direction: column;
  margin-top: -10px;
  margin-bottom: -121px;
  padding-left: 17px;
  /* justify-content: space-between; */
}

.clockedOnDataBoxMob {
  padding-left: 10px;
}

@media only screen and (min-width: 457px) and (max-width: 499px) {
  .clockedOnDataBox {
    min-width: calc(100vw - 227px);
    max-width: calc(100vw - 227px);
    margin-left: 152px;
  }

  .clockOnDataTitle {
    display: flex;
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 386px) and (max-width: 456px) {
  .clockedOnDataBox {
    min-width: calc(100vw - 214px);
    max-width: calc(100vw - 214px);
    margin-left: 146px;
  }

  .clockOnDataTitle {
    display: flex;
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 0px) and (max-width: 355px) {
  .clockedOnDataBox {
    min-width: calc(100vw - 206px);
    max-width: calc(100vw - 206px);
    margin-left: 148px;
  }

  .clockOnDataTitle {
    display: flex;
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 356px) and (max-width: 365px) {
  .clockedOnDataBox {
    min-width: calc(100vw - 193px);
    max-width: calc(100vw - 193px);
    margin-left: 142px;
  }
}

@media only screen and (min-width: 366px) and (max-width: 385px) {
  .clockedOnDataBox {
    min-width: calc(100vw - 200px);
    max-width: calc(100vw - 200px);
    margin-left: 138px;
  }
}

.clockTeamName {
  border-bottom: 1px solid #e0ebf8;
  max-height: 36px;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  color: #143051;
}

.clockTeamName:last-child {
  border: none;
}
.clockOnDataValue {
  font-weight: 500;
  color: #516b8b;
  font-size: 15px;
}
.desktopHeaderSubDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  padding-left: 0px;
  background-color: #fafcff;
  max-width: 157px;
  margin-left: 50px;
  padding-bottom: 10px;
  border-radius: 0 0 5px 5px;
  margin-bottom: 15px;
  -webkit-animation-name: slideDesktopSubMenuDownMyShifts;
          animation-name: slideDesktopSubMenuDownMyShifts;
  /* fits three items */
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  min-height: auto;
  min-width: auto;
}

.desktopHeaderDivTwoItems {
  -webkit-animation-name: slideDesktopSubMenuDownMyAbsence;
          animation-name: slideDesktopSubMenuDownMyAbsence;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
}

.desktopHeaderDivFourItems {
  -webkit-animation-name: slideDesktopSubMenuDownFourItems;
          animation-name: slideDesktopSubMenuDownFourItems;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  /* display: none; */
  /* margin-left: -2px; */
}

.desktopHeaderDivFiveItems {
  -webkit-animation-name: slideDesktopSubMenuDownFourItems;
          animation-name: slideDesktopSubMenuDownFourItems;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  /* display: none; */
  /* margin-left: -2px; */
}
.desktopHeaderSubBtn {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #496375 !important;
  cursor: pointer;
  text-align: left;
  min-width: 100%;
  padding-left: 25px;
}
.desktopHeaderSubBtn:hover {
  color: #0077cc !important;
  background-color: #ebf0f7;
  padding-top: 8px;
  margin-top: 2px;
  margin-bottom: -7px;
  min-height: 30px;
  min-width: 100%;
}

.mgrSubBtnHover:hover {
  color: #9a74eb !important; 
  background-color: #ebf0f7;
}

.desktopHeaderSubBtnSelectedMob {
  font-weight: 500;
  color: #4b1caf !important;
  cursor: none;
  pointer-events: none;
  border-left: 2px solid #4b1caf;
  padding-left: 6px;
  margin-left: 17px;
}

.pushDataBoxDown {
  margin-top: 0px;
  margin-left: 140px;
  margin-bottom: -105px;
}

@media only screen and (min-width: 500px) {
  .pushDataBoxDown {
    margin-left: 128px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 499px) {
  .pushDataBoxDown {
    min-width: calc(100vw - 238px) !important;
    max-width: calc(100vw - 238px) !important;
    margin-left: 164px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 479px) {
  .pushDataBoxDown {
    min-width: calc(100vw - 228px) !important;
    max-width: calc(100vw - 228px) !important;
    margin-left: 158px;
  }
}

@media only screen and (min-width: 429px) and (max-width: 449px) {
  .pushDataBoxDown {
    min-width: calc(100vw - 228px) !important;
    max-width: calc(100vw - 228px) !important;
    margin-left: 158px;
  }
}

@media only screen and (min-width: 396px) and (max-width: 428px) {
  .pushDataBoxDown {
    min-width: calc(100vw - 208px) !important;
    max-width: calc(100vw - 208px) !important;
    margin-left: 138px;
  }
}

@media only screen and (min-width: 386px) and (max-width: 395px) {
  .pushDataBoxDown {
    min-width: calc(100vw - 208px) !important;
    max-width: calc(100vw - 208px) !important;
    margin-left: 138px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 385px) {
  .clockUnpaidBreakSpan {
    display: none !important;
  }
}

.pullClockOnUp {
  margin-top: -30px;
  /* min-height: 60px; */
}

.assocItemDataRow {
  border-left: 1px solid #e0ebf8;
  padding-left: 7px;
  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 74px;
  margin-top: 0px;
  padding-bottom: 2px;
  min-width: 33%;
  max-width: 33%;
}

.assocItemDataRow:first-child {
  border: none;
  padding-left: 0px;
  /* margin-top: 8px; */
}
.assocItemTitle {
  font-size: 12px;
  text-align: left;
  min-width: 100%;
  color: #214978;
  font-weight: 400;
  /* opacity: 0.6; */
  /* text-transform: uppercase; */
  margin-top: -6px;
}

.assocSchedRow {
  display: flex;
  text-align: left;
  min-width: 100%;
  /* margin-top: -20px; */
}

.assocDateRow {
  min-width: 33%;
  max-width: 33%;}

.assocTimeRow {
  min-width: 33%;
  max-width: 33%;
}

.assocItemValue {
  margin-top: -24px;
  color: #143051;

  margin-top: -12px;
  color: #143051;
  line-height: 15px;
  font-size: 14px;
  margin-bottom: 8px;
}

.clockedOnOffTitle {
  /* color: #0077ff !important; */
  font-size: 12px !important;
  /* background-color: yellow; */
  /* min-height: 32px; */
  padding-right: 20px;
  /* line-height: 15px; */
  /* margin-top: 8px; */
  /* margin-bottom: 12px; */
  opacity: 1;
}

.clockedOnRowTitle {
  background-color: #f4f8fe;
  min-width: 100%;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
  color: #859cb6;
  padding-left: 15px;
  margin-bottom: -10px;
}

.clockedDataRowBg {
  background-color: #f4f8fe;
  min-width: 100%;
  border-top: 2px solid #e6eaee;
  border-left: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
  border-radius: 5px 5px 0 0;
  padding-right: 0px !important;
  /* min-height: 150px !important; */
}

.clocked1 {
  border-radius: 5px;
  border-bottom: 2px solid #e6eaee !important;
}
.clock3 {
  border-radius: 0 0 0px 0px;
  border-left: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
  background-color: #f9fafc;
}
.clock2 {
  border-radius: 0 0 5px 5px;
  border-bottom: 2px solid #e6eaee !important;
}

.brkClockedSpan {
  /* color: black; */
  opacity: 0.5;
  margin-left: 2px;
  display: none;
}

.earlyLateClockTxt {
  font-size: 14px;
  max-height: 18px;
  /* margin-top: -20px; */
}

.clockedScoreOnOff {
  min-width: 33%;
  max-width: 33%;
  padding-left: 7px;
  font-weight: 500;
  margin-top: -8px;
  font-size: 12px;
  border-left: 1px solid #e0ebf8;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 4px;
  padding-right: 6px;
  /* max-width: 20px; */
}

.clockedScoreOnOff:first-child {
  padding-left: 0px;
  border: none;
}

.offScore90 {
min-width: 33%;
max-width: 33%;}

.clockedScoreBrk {
  min-width: 30%;
  max-width: 30%;
  padding-left: 7px;
  font-weight: 500;
  margin-top: -8px;
  font-size: 12px;
  border-left: 1px solid #e0ebf8;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 4px;
}

.clockedScoreRow {
  max-height: 46px !important;
  min-height: 46px !important;
  background-color: #f4f8fe;
  color: #385f8c;
  font-weight: 400 !important;
  border-left: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
  padding-right: 0px !important;
}
.colourRed {
  /* color: #e89292 !important; */
  color: #ba0000 !important;
}

.colourGreen {
  color: #5a9e3f;
}

.clockPayRow {
  display: flex;
  justify-content: space-between !important;
  min-width: 100% !important;
  padding-bottom: 10px;
}

.myShiftRowLRighTitle {
  color: #858e9c;
  font-weight: 500;
}

.clockPayRowR {
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.clockPayRowVal {
  color: #143051;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-top: 13px;
}

.deleteAndShiftHolder {
  display: flex;
  max-height: 50px;
  align-items: center;
  padding-top: 1px;
}

.viewAssocShiftBtn {
  font-weight: 500;
  font-size: 14px;
  /* font-weight: 500; */
  /* color: #592eb6; */
  text-align: center;
  padding-left: 9px;
  padding-right: 9px;
  display: flex;
  min-height: 35px;
  max-height: 35px;
  /* line-height: 26px; */
  background-color: #8ea7c5;
  border: 1px solid #6987a9;
  align-items: center;
  margin-top: 0px;
  border-radius: 5px;
  /* padding-top: 2px; */
  justify-content: center;
  cursor: pointer;
  color: white;
  /* margin-left: -3px; */
  /* min-width: 70px;
  max-width: 70px; */
}

.font16px {
  /* font-size: 16px !important; */
}

.viewAssocShiftBtn:hover {
  background-color: #6987a9;
}

.invalidClockShiftModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.invalidClockShiftModalBody {
  background-color: white;
  min-width: 320px;
  max-width: 320px;
  /* min-height: 90vh;
max-height: 90vh; */
  /* height: 80vh; */
  border-radius: 15px;
  /* margin-top: -40px; */
  /* display: flex; */
  /* justify-content: center; */
  align-items: center;
  color: #143051;
  margin-top: -100px;
  text-align: center;
  padding: 30px;
  /* position: fixed;
bottom: 0;
text-align: center; */
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -webkit-animation-name: moveEditTeamUserBoxUp !important;
          animation-name: moveEditTeamUserBoxUp !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  cursor: default;
}

.closeInvalidShiftModalBtn {
  font-size: 16px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  margin-top: 35px;
  margin-bottom: -10px;
}

.closeInvalidShiftModalBtn:hover {
  background-color: #214978;
}

.overShortSchedClockedSpan {
  font-size: 12px;
  margin-left: 0px;
  font-weight: 400;
  color: #858e9c;
  /* margin-right: -10px; */
}

.fadeViewAssoc {
  opacity: 0.3;
}

.unlinkedTab {
  cursor: pointer;
}

.unlinkedTab:hover {
  background-color: #5b728d;
}

.blankModalContentBox {
  background-color: #fff;
  max-width: 90%;
  margin: 0 auto;
  margin-top: -50px;
  z-index: -1;
  border-radius: 5px 5px 0 0;
  border-top: solid 2px #e6eaee;
  border-right: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
  border-bottom: 1px solid #bac5d3;
  margin-top: 10px;
}

.blankModalFooter {
  /* background-color: purple; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 50px;
  min-height: 50px;
  padding-left: 5%;
  padding-right: 5%;
}

.mobBlankModalBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 542px !important;
  min-height: 542px !important;
  border-radius: 5px 5px 0 0 !important;
}

.mobUnclockModalBox {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  /* border-radius: 5px 5px 0 0; */
  max-height: 600px !important;
  min-height: 600px !important;
  /* border-radius: 5px 5px 0 0 !important; */
  background-color: white;
  z-index: 9999999 !important;
}

.unclockedContainer {
  overflow: scroll;
  overflow-x: hidden;
  min-height: 450px;
  max-height: 450px;
  padding-bottom: 10px;
}

.activeClocksContainer {
  overflow: scroll;
  overflow-x: hidden;
  min-height: 498px;
  max-height: 498px;
  padding-bottom: 10px;
  padding-right: 5px;
}

.minus140pxForActions {
  min-height: 287px;
  max-height: 287px;
}

.minus140pxForActionsMob {
  min-height: 232px;
  max-height: 232px;
}
.unclocksModalContentBox {
  background-color: #f4f8fe;
  max-width: 90%;
  margin: 0 auto;
  margin-top: -50px;
  z-index: -1;
  border-radius: 5px;
  border-top: solid 2px #143051;
  /* border-right: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee; */
  border-bottom: 2px solid #143051;
  margin-top: 10px;
  border: 1px solid #bfccdc;
}

.whiteBg {
  background-color: white;
}

.unclockedUnit {
  background-color: white;
  max-width: 95%;
  min-width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  min-height: 74px;
  max-height: 74px;
  margin-top: 8px;
  border-radius: 5px;
  cursor: pointer;

  padding-right: 10px;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  border: #d5e2f0 1px solid;
  /* padding-left:5px; */
}

.unclockedUnit:hover {
  border-color: #bbcada
  /* box-shadow: 0px 0px 3px #143051; */
}

.clockedOnUnit {
  background-color: white;
  max-width: 95%;
  min-width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  min-height: 94px;
  max-height: 94px;
  margin-top: 8px;
  border-radius: 5px;
  cursor: pointer;

  padding-right: 10px;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  border: #dde9f9 1px solid;
  padding-left: 8px;
}

.zoomIn {
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.clockedOnUnitX:hover {
  border: #bbcada 1px solid;

  /* border-color: #bac5d3 */
  /* box-shadow: 0px 0px 3px #143051; */
}

.multiSelectUnclocks {
  /* background-color: #214978 !important;
  border-color: #143051 !important; */
  /* margin-right:8px */
}

.multiSelectUnlockRadio {
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  /* background-color: white;
  border: 2px solid #143051; */
  /* border-radius: 100%; */
  margin-right: 5px;
  margin-left: -5px;
}

.unclockTickImg {
  max-width: 20px;
  max-height: 20px;
  min-width: 20px;
  min-height: 20px;
  border: 1px solid #cbd7e6;
  border-radius: 3px;
  padding: 3px;
}

.unselectedUnclockRadio {
  max-width: 20px;
  max-height: 20px;
  min-width: 20px;
  min-height: 20px;
  background-color: white;
  border: 1px solid #cbd7e6;
  border-radius: 3px;
}

.mobUnclocksModalBody {
  /* min-height: calc(90vh - 162px) !important; */
  /* max-height: calc(90vh - 162px) !important; */
}

.actionsUnclockActive {
  background-color: #0bf !important;
  border: 1px solid #0af !important;
  cursor: pointer;
  margin-right: 8px;
}

.unclockUnitBody {
  display: flex;
  justify-content: space-between;

  min-width: 100%;
  max-width: 100%;
  /* padding-right: 8px; */
}

.unclockNameString {
  display: flex;
  justify-content: flex-start;
  /* padding-left: 14px; */
  margin-top: 7px;
  margin-left: 2px;
  color: #143051;
  font-weight: 500;
  font-size: 14px;
  max-height:20px;
}

.unclockSection2 {
  padding-right: 0px !important;
}

.unclockSection1 {
  margin-left: -14px;
  /* min-width: 180px !important; */
  /* min-width: 10px; */
  /* background-color: orange; */
}

.x2312121 {
  margin-left: 6px;
}

.unclockSection1Desktop {
  margin-left: -33px;
  min-width: 150px !important;
}

.unclockSection1DesktopX {
  margin-left: 6px;
  min-width: 150px !important;
}

.unclockItemMenuDiv {
  min-height: 159px;
  /* min-height: fit-content; */
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  background-color: #214978;
  background-color: #214978;

  color: white;
  border-radius: 0 0 5px 5px;
  border-top: solid #143051 2px;
  -webkit-animation-name: slideUnclockMenuDown;
          animation-name: slideUnclockMenuDown;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: -2px;
}

@-webkit-keyframes slideUnclockMenuDown {
  from {
    max-height: 0px;
    min-height: 0px;

    opacity: 0;
    /* transform: scale(0.4); */
    z-index: -1;
  }
  to {
    max-height: 159px;
    min-height: 159px;

    opacity: 1;
    /* transform: scale(1); */
    z-index: -1;
  }
}

@keyframes slideUnclockMenuDown {
  from {
    max-height: 0px;
    min-height: 0px;

    opacity: 0;
    /* transform: scale(0.4); */
    z-index: -1;
  }
  to {
    max-height: 159px;
    min-height: 159px;

    opacity: 1;
    /* transform: scale(1); */
    z-index: -1;
  }
}

.unclockItemMenuDiv2 {
  min-height: 159px;
  /* min-height: fit-content; */
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  background-color: #214978;
  background-color: #214978;

  color: white;
  border-radius: 0 0 5px 5px;
  border-top: solid #143051 2px;
  -webkit-animation-name: slideUnclockMenuDown2;
          animation-name: slideUnclockMenuDown2;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
}

@-webkit-keyframes slideUnclockMenuDown2 {
  from {
    max-height: 99px;
    min-height: 99px;

    /* opacity: 0; */
    /* transform: scale(0.4); */
    z-index: -1;
  }
  to {
    max-height: 159px;
    min-height: 159px;

    /* opacity: 1; */
    /* transform: scale(1); */
    z-index: -1;
  }
}

@keyframes slideUnclockMenuDown2 {
  from {
    max-height: 99px;
    min-height: 99px;

    /* opacity: 0; */
    /* transform: scale(0.4); */
    z-index: -1;
  }
  to {
    max-height: 159px;
    min-height: 159px;

    /* opacity: 1; */
    /* transform: scale(1); */
    z-index: -1;
  }
}

.unclockItemMenuDivNoSched {
  min-height: 99px;
  /* min-height: fit-content; */
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  background-color: #214978;
  background-color: #667c96;
  color: white;
  border-radius: 0 0 5px 5px;
  border: solid #143051 2px;
  -webkit-animation-name: slideUnclockMenuDownNoSched;
          animation-name: slideUnclockMenuDownNoSched;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: -2px;
  border-top: none;
}

@-webkit-keyframes slideUnclockMenuDownNoSched {
  from {
    max-height: 0px;
    min-height: 0px;

    opacity: 0;
    /* transform: scale(0.4); */
    z-index: -1;
  }
  to {
    max-height: 99px;
    min-height: 99px;

    opacity: 1;
    /* transform: scale(1); */
    z-index: -1;
  }
}

@keyframes slideUnclockMenuDownNoSched {
  from {
    max-height: 0px;
    min-height: 0px;

    opacity: 0;
    /* transform: scale(0.4); */
    z-index: -1;
  }
  to {
    max-height: 99px;
    min-height: 99px;

    opacity: 1;
    /* transform: scale(1); */
    z-index: -1;
  }
}

.unclockItemMenuDivSched {
  min-height: 142px;
  /* max-height: 142px; */
  /* min-height: fit-content; */
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  /* background-color: #214978; */
  background-color: #214978;
  /* border-bottom: #143051 1px solid; */
  color: white;
  border-radius: 0 0 5px 5px;
  border-top: solid #143051 2px;
  -webkit-animation-name: slideUnclockMenuDownSched;
          animation-name: slideUnclockMenuDownSched;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  /* margin-bottom: 16px; */
  border-left: #bbcada 1px solid;
  border-right: #bbcada 1px solid;
  border-top: none;
  margin-top: -2px;
}

@-webkit-keyframes slideUnclockMenuDownSched {
  from {
    max-height: 0px;
    min-height: 0px;

    opacity: 0;
    /* transform: scale(0.4); */
    z-index: -1;
  }
  to {
    max-height: 142px;
    min-height: 142px;

    opacity: 1;
    /* transform: scale(1); */
    z-index: -1;
  }
}

@keyframes slideUnclockMenuDownSched {
  from {
    max-height: 0px;
    min-height: 0px;

    opacity: 0;
    /* transform: scale(0.4); */
    z-index: -1;
  }
  to {
    max-height: 142px;
    min-height: 142px;

    opacity: 1;
    /* transform: scale(1); */
    z-index: -1;
  }
}

.unclockMenuL {
  min-width: 70%;
  /* min-height: 20px; */
  /* background-color: yellow; */
}

.unclockMenuLActive {
  min-width: 100%;
  /* background-color: yellow; */
}

.unclockMenuR {
  min-width: 30%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-left: 1px solid white;
}

.viewUnclockItem {
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: 64px;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #0bf;
  border: 1px solid #0af;
}

.viewUnclockItem:hover {
  background-color: #0af;
}

.chatActionItem {
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: 64px;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #a387df;
  border: 1px solid #8e76c1;
  margin-top: 8px;
}

.chatActionItem:hover {
  background-color: #9a82ce;
}

.unclockActionBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 72px;
  padding-left: 9px;
  padding-right: 9px;
  min-height: 40px;
  max-height: 40px;
  border: solid 1px #dde9f9;
  background-color: #bbcada;
  border-radius: 5px;
  cursor: pointer;
  max-width: 90%;
  margin-left: 5%;
  margin-top: 8px;
}

.unclockActionBtnSmallerTxt {
  font-size: 12px;
  min-height: 30px;
  max-height: 30px;
}

.unclockActionBtn:hover {
  background-color: #00ccff;
  color: #fff;
}

.unclockActionBtn:first-child {
  margin-top: 0px;
}

.unclockBtnDivider {
  min-height: 1px;
  background-color: #bbcada;
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  margin-top: 8px;
}

.selectedUnclockUnit {
  border: 1px solid #214978;
  margin-top: 6px;
  padding-bottom: 2px;
  padding-top: 2px;
  margin-bottom: 2px;
}

.selectedUnclockUnit:hover {
  box-shadow: none;
  border: 1px solid #214978;

}

.unclockAbsBtn {
  background-color: #e7f1fb;
  color: #6a8bb1;
  border-left: 2px solid #bbcada;
}

.unclockAbsBtn:hover {
  border: solid 1px #09aed8;
  background-color: #00ccff;
  color: white;
}

.unclockDeleteBtn:hover {
  background-color: #e26666;
  border-color: #ba0000;
}

.unclockMarkWorkedBtn:hover {
  background-color: #6dba4f;
  border-color: #427030;
}

.unclockMultiActionsBox {
  /* background-color: #214978; */
  /* border: 1px solid #bfccdc; */
  min-width: 330px !important;
  min-height: 209px;
  min-width: 70%;
  max-width: 70%;
  margin-left: 5%;
  color: white;
  margin-top: 9px;
  /* border-radius: 5px 5px 0 0; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 88px;
  /* border-top: 1px solid #bfccdc; */
  border-bottom: 1px solid #bfccdc;
}

.desktopMultiActionBox {
  /* background-color: #00ccff; */

  min-height: 154px !important;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-left: 12px;
}

.noBorder:hover {
  border: none;
}

.unclockedSelectedQty {
  color: #143051;
  font-weight: 500;
  margin-left: 20px;
  margin-bottom: 4px;
  margin-top: -8px;
  font-size: 14px;
}

.showActionsWhiteCrossImg {
  margin-right: 5px;
  margin-left: -2px;
}

.paddingLandR15px {
  padding-left: 15px;
  padding-right: 15px;
}

.clockOnNotAllowed {
  min-height: 260px;
  /* background-color: white; */
  /* max-width: 90%; */
  /* margin-left: 5%; */
  /* padding-top: 80px; */
  /* padding-right: 30px;
  padding-left: 30px; */
  color: #214978;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clockOnNotAllowedTxt {
  max-width: 200px;
  margin-top: -20px;
}

.disableUnclockActions {
  pointer-events: none;
  opacity: 0.3;
}

.clockCardDateLeft {
  min-width: 78% !important;
  max-width: 78% !important;
}

.editClockedImg {
  margin-top: 7px;
  margin-right: 12px;
  cursor: pointer;
  min-width: 35px;
  max-width: 35px;
  min-height: 35px;
  max-height: 35px;
  border-radius: 5px;
  padding: 9px;
  border: #e3e8ee solid 2px;
  background-color: white;
}

.editClockedImg:hover {
  border-color: #b2bbc7;
}

.editClockDateImg {
  cursor: pointer;
  margin-top: 28px !important;
  margin-left: 26px !important;
  /* margin-right: 18px;
    margin-top: 20px; */
  cursor: pointer;
  min-width: 35px;
  max-width: 35px;
  min-height: 35px;
  max-height: 35px;
  border-radius: 5px;
  padding: 9px;
  border: #e3e8ee solid 2px;
  font-weight: 500;
}

.editClockDateImg:hover {
  border-color: #b2bbc7;
}

.clockedTitleTxtSolo {
  font-weight: 400;
  font-size: 12px;
  color: #214978;
}

.clockedTimesTitleDiv {
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  margin-bottom: -20px;
}

.savedEditClockedBtn {
  background-color: #07f;
  max-height: 35px;
  line-height: 26px;
  color: white;
  font-weight: 500;
  border: 1px solid #0469de;
  border-radius: 5px;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  /* margin-bottom: -2px; */
  cursor: pointer;
  min-width: 60px;
}

.savedEditClockedBtn:hover {
  background-color: #0469de;
}

.deleteClockBtn {
  margin-right: 8px !important;
  margin-left: 0px !important;
}

.clockedTimeInput {
  background-color: #fff;
  width: 77px;
  max-width: 77px;
  margin-top: -12px;
  /* margin-left: -1px; */
  /* padding-left: 4px; */
  line-height: 30px;
  padding-top: 0px;
  height: 35px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 16px;
  font-weight: 500;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  -webkit-appearance: none;
  appearance: none;
  /* font-size: 16px; */
  cursor: text;
  margin-bottom: 4px;
}

.clockedTimeInput:hover {
  border-color: #bbcada;
}

.invalidClockSave {
  /* pointer-events: none; */
  opacity: 0.1;
}

.invalidClockInput {
  border: #e0b7b7 2px solid !important;
}

.offClock {
  /* background-color: blue; */
  min-width: 90px;
}

.clockBreakFixed {
    min-width: 124px !important;
    max-width: 124px;
    padding-right: 30px;
    font-size: 14px;
    line-height: 18px;
    padding-top: 14px;
    padding-bottom: 10px;
}

.clockBrkDurMins {
  display: flex;
  /* margin-bottom: -5px; */
}

.clockBrkUntilSpan {
  font-size: 12px;
  padding-right: 6px;
  color: #859cb6;
}

.noMinHeight {
  min-height: 0px !important;
}

.overlapBrkClockSpan {
  text-transform: none;
  margin-left: 6px;
  background-color: #cd7171;
  color: white;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 3px;
  max-height: 17px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  font-weight: 500;
  font-size: 10px;
}

.breakClockTitleDiv {
  min-width: 190px;
}

.breakClockTitleDivSmaller {
  min-width: 160px;
}

.removeClockBrkImg {
  cursor: pointer;
  max-width: 22px;
  min-width: 22px;
  margin-right: 7px;
  margin-top: -4px;
  background-color: white;
  padding: 3px;
  border: 1px solid #bbcada;
  max-height: 22px;
  min-height: 22px;
  border-radius: 4px;
  margin-top: 8px;
}

.removeClockBrkImg:hover {
  background-color: #e9f3fe;
}

.addBrkBtn {
  max-width: 30px;
  margin-right: 10px;
  /* margin-bottom:  */
  cursor: pointer;
}

.addBrkRowDiv {
  /* background-color: blue; */
  max-height: 35px;
  margin-bottom: -19px;
  margin-top: -5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.addBrkClockBtn {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  color: white;
  font-weight: 500;
  font-size: 12px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 9px;
  padding-right: 9px;
  border-radius: 5px;
  margin-right: 5px;
  cursor: pointer;
  padding-bottom: 1px;
}

.addBrkClockBtn:hover {
  background-color: #bbcada;
}

.clockBreakTimeStrip {
  font-size: 14px;
}

.pullAddBreakBtnUpDueToSched {
  margin-top: -110px;
  margin-bottom: 74px;
}

.clockedOverUnderSpan {
  font-size: 12px;
  color: #ba0000;
  /* color: white; */
  /* border-radius: 3px; */
  /* padding: 3px; */
  /* padding-left: 5px; */
  /* padding-right: 5px; */
  font-weight: 500;
  /* opacity: 0.8; */
  line-height: 14px;
  max-width: 110px !important;
  margin-top: 6px;
}

.originallyClockedMobFont {
  font-size: 10px;
  text-align: left;
  max-width: 58px;
}

@media only screen and (max-width: 1054px) {
  .createdByDynamic {
    font-size: 10px;
    text-align: left;
    max-width: 58px;
  }
}

.relinkBtn {
  margin-left: 6px;
}

.teamDesktopHeaderSubBg {
  background-color: #fafcff;
}

.approveAndDeclineClockBtns {
  /* background-color: blue; */
  min-width: 166px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.shorterAppDecClockBtnContainer {
  min-width: 90px;
}

.approveClockBtn {
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #a8cc9a;
  border: 1px solid #9cb194;
  margin-right: 10px;
}

.approveClockBtn:hover {
  background-color: #9cb194;
}
.declineClockBtn {
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #e89292;
  border: 1px solid #9e7373;
}

.declineClockBtn:hover {
  background-color: #da8787;
}
.sureDeleteClockBtnColours {
  background-color: #214978 !important;
  border: 1px solid #143051 !important;
}

.declinedClockTab {
  background-color: #e89292 !important;
  border: 1px solid #ba0000 !important;
}

.clockCardNotesArr {
  /* background-color: blue; */
  border-left: 2px solid #143051;
  margin-top: 10px;
  margin-bottom: 20px;
}

.clockCardNoteRow {
  /* background-color: yellow; */
  margin-top: 3px;
  font-size: 14px;
  border-top: 1px solid #cbd7e6;
  padding-bottom: 8px;
}

.clockCardNoteRow:first-child {
  border-top: none;
}

.clockCardNameAndDateDiv {
  display: flex;
  justify-content: space-between;
}

.clockCardNoteName {
  font-weight: 500;
  color: #00ccff;
}

.clockCardNoteTime {
  font-size: 12px;
  color: #859cb6;
}

.clockCardNoteValue {
  line-height: 16px;
  margin-top: -6px;
  min-width: 100%;
  color: #8ea7c5;
}

.clockCardNoteTitleAndAddNoteBtnDiv {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  margin-top: 12px;
}

.addClockNoteBtn {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  margin-top: 17px;
  margin-bottom: 8px;
  color: #496375;
  border: #e3e8ee solid 1px;
  background-color: #fff;
}

.addClockNoteBtn:hover {
  border-color: #bbcada;
}

.addClockNoteBtnActive {
  /* background-color: #fff; */
  /* border: 1px solid #143051; */
}

.addClockNoteBtnActive:hover {
  /* background-color: #143051; */
}

.addClockNoteInputContainer {
  display: flex;
  min-height: 120px;
  margin-top: 20px;
  margin-bottom: 4px;
  /* background-color: orange; */
}

.addClockNoteTextInput {
  min-width: 76%;
  max-width: 76%;
  min-height: 130px;
  resize: none;
  border-radius: 5px;
  background-color: #fff;
  font-size: 1em;
  overflow: scroll;
  line-height: 20px;
  font-size: 16px;
  margin-top: 0;
  font-weight: 400;
  color: #496375;
  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;
  color: #6a8bb1;
  padding: 5px;
  font-weight: 500;
}

.addClockNoteTextInput::-webkit-input-placeholder {
  color: #bbcada;
}

.addClockNoteTextInput:-ms-input-placeholder {
  color: #bbcada;
}

.addClockNoteTextInput::placeholder {
  color: #bbcada;
}

.addClockNoteTextInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 6px;
  background-color: #fdfeff;
}

.clockNoteSaveAndClearBtns {
  min-width: 24%;
  padding-left: 3%;
  padding-right: 3%;
}

.saveClockNoteBtn {
  background-color: #07f;
  max-height: 35px;
  min-height: 35px;

  line-height: 26px;
  color: white;
  font-weight: 500;
  border: 1px solid #0469de;
  border-radius: 5px;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  /* margin-top: 8px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  margin-bottom: -2px;
  cursor: pointer;
  min-width: 60px;
}
.saveClockNoteBtn:hover {
  background-color: #0469de;
}

.clearClockNoteBtn {
  max-height: 35px;
  min-height: 35px;
  line-height: 26px;
  color: white;
  font-weight: 500;
  border-radius: 5px;
  font-size: 0.9em;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  /* margin-bottom: -2px; */
  cursor: pointer;
  background-color: #8ea7c5;
  border: 1px solid #6987a9;
  min-width: 60px;
}

.clearClockNoteBtn:hover {
  background-color: #6987a9;
}

.endIsNextDaySpan {
  /* background-color: #140351; */
  /* color: white; */
  color: #859cb6;
  font-size: 14px;
  padding-left: 0px;
  /* padding-right: 4px; */
  border-radius: 5px;
  padding-right: 7px;
}

.closeAddNoteClockBtn {
  margin-right: 5px;
  max-width: 10px;
}

.clockNoteDiv {
  min-width: 100%;
}

.fadeSaveBtnClockNote {
  opacity: 0.2;
  pointer-events: none;
}

.clockTickImg {
  max-width: 18px;
}

.clockCrossImg {
  max-width: 20px;
  margin-right: -1px;

  margin-left: -1px;
}

.viewAssocClockItemInline {
  margin-top: 10px;
  margin-right: 1px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.indiOnlyUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.indiOnlyModalBox {
  background-color: #f4f8fe;
  border-radius: 5px;
  min-width: 340px;
  /* min-height: 400px; */
  cursor: default;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.indiOnlyStaffList {
  /* background-color: blue; */
  border-top: 1px solid #ddebf4;
  border-bottom: 1px solid #ddebf4;
  min-height: 100px;
  max-height: 220px;
  padding-top: 15px;
  padding-bottom: 15px;
  max-height: 20px;
  overflow-y: scroll;
  margin-top: 20px;
  margin-bottom: 20px;
}

.individualUserManagesNav {
  margin-top: 6px;
  font-size: 14px;
  text-align: left;
  margin-left: 6%;
  color: #859dbb;
}

.individualUserManagesNav:first-child {
  margin-top: 0px;
}

.clockOnSchedTeamNameContainer {
  display: flex;
  justify-content: center;
  max-height: 20px;
  min-height: 20px;
  margin-bottom: -10px;
  min-width: 100%;
  margin-top: -5px;
  margin-bottom: 10px;
  /* padding-right: 5%; */
}

.clockOnSchedTeamNameContainerClockedOn {
  display: flex;
  justify-content: flex-end;
  max-height: 20px;
  min-height: 20px;
  margin-top: -20px;
  margin-bottom: 10px;
  min-width: 100%;
  padding-right: 5%;
}

.clockOnSchedTeamNameTxt {
  text-align: left;
  /* background-color: #e3e8ee; */
  /* color: #4f566b; */
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-weight: 500;
  background-color: #f4f8fe;
  color: #4f566b;
  border: 1px solid #bbcada;
  /* margin-bottom:6px; */
  /* float:left */
}

.clockUnpaidBreakSpan {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #e3e8ee; */
  color: #8da4bc;
  border-left: 1px solid #e1eaf4;
  font-size: 10px;
  /* border-radius: 5px; */
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 6px;
  font-weight: 400;
  /* padding-bottom: 1px; */
}

.clockOnDataTitle {
  display: flex;
  font-size: 10px !important;
  color: #214978;
  font-weight: 400;
}

.unclockedTabMessagesImg {
  max-width: 10px;
  margin-right: 5px;
}

.topBarMsgIcon {
  max-width: 13px;
  margin-right: 4px;
}

.topBarClockedOnIcon {
  max-width: 14px;
  margin-right: 4px;
}

.blueBg {
  -webkit-animation: colorFade 2s infinite;
          animation: colorFade 2s infinite; /* Adjust the duration as needed */
}



@-webkit-keyframes colorFade {
  0% {
    background-color: #0077ff;
  }
  50% {
    background-color: #36495f;
  }
  100% {
    background-color: #0077ff;
  }
}



@keyframes colorFade {
  0% {
    background-color: #0077ff;
  }
  50% {
    background-color: #36495f;
  }
  100% {
    background-color: #0077ff;
  }
}

.mobMsgDiv {
  min-width: 50px;
  max-width: 50px;
  min-height: 46px;
  max-height: 46px;
  background-color: #234f83;
  border: 1px solid #143051;
  position: absolute;
  right: 0px;
  bottom: 30vh;
  border-radius: 5px 0 0 5px;
  border-right: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  cursor: pointer;
  /* animation-name: slideMobSideBtnIn;
  animation-duration: 0.1s; */
}

.mobMsgQty {
  background-color: #07f;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border-radius: 3px 0 0 3px;
  color: white;
  max-height: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 1px;
  padding-bottom: 2px;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  bottom: calc(30vh - 11px);
  z-index: 999;
  /* animation-name: zoomModalIn;
  animation-duration: 0.1s; */
}

.mobMsgIcon {
  margin-left: 0px;
  margin-top: 0px;
  min-width: 24px;
}

.mobNotifDiv {
  min-width: 50px;
  max-width: 50px;
  min-height: 46px;
  max-height: 46px;
  background-color: #234f83;
  border: 1px solid #143051;
  position: absolute;
  right: 0px;
  bottom: calc(30vh + 64px);
  border-radius: 5px 0 0 5px;
  border-right: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  cursor: pointer;
  /* animation-name: slideMobSideBtnIn;
  animation-duration: 0.1s; */
}

@-webkit-keyframes slideMobSideBtnIn {
  from {
    /* margin-left: -50px; */
    /* max-width: 50px;
    min-width: 50px; */
    z-index: 1;
    right: -50px;
    /* opacity: 0.1; */
    /* transform: scale(0.4); */
  }
  to {
    z-index: 1;
    right: 0px;
    /* opacity: 1; */
    /* transform: scale(1); */
  }
}

@keyframes slideMobSideBtnIn {
  from {
    /* margin-left: -50px; */
    /* max-width: 50px;
    min-width: 50px; */
    z-index: 1;
    right: -50px;
    /* opacity: 0.1; */
    /* transform: scale(0.4); */
  }
  to {
    z-index: 1;
    right: 0px;
    /* opacity: 1; */
    /* transform: scale(1); */
  }
}

.mobNotifQty {
  background-color: #07f;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border-radius: 3px 0 0 3px;
  color: white;
  max-height: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 1px;
  padding-bottom: 2px;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  bottom: calc(30vh + 52px);
  z-index: 999;
  /* animation-name: zoomModalIn;
  animation-duration: 0.1s; */
}

.mobNotifIcon {
  margin-left: 0px;
  margin-top: -2px;
  min-width: 24px;
  max-width: 24px;
}

.mobMgrBtnDiv {
  min-width: 50px;
  max-width: 50px;
  min-height: 46px;
  max-height: 46px;
  background-color: #592eb6;
  border: 1px solid #4b1caf;
  position: absolute;
  right: 0px;
  bottom: calc(30vh + 128px);
  border-radius: 5px 0 0 5px;
  border-right: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  cursor: pointer;
  /* animation-name: slideMobSideBtnIn;
  animation-duration: 0.1s; */
}

.mobMgrBtnQty {
  background-color: #a387df;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border-radius: 3px 0 0 3px;
  color: white;
  max-height: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 1px;
  padding-bottom: 2px;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  bottom: calc(30vh + 117px);
  z-index: 999;
  /* animation-name: zoomModalIn;
  animation-duration: 0.1s; */
}

.activeClocksMobQty {
  background-color: #7fbb67;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border-radius: 3px 0 0 3px;
  color: white;
  max-height: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 1px;
  padding-bottom: 2px;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  bottom: calc(30vh + 158px);
  z-index: 999;
  /* animation-name: zoomModalIn;
  animation-duration: 0.1s; */
}

.mobMgrBtnIcon {
  margin-left: 0px;
  margin-top: -2px;
  min-width: 24px;
  max-width: 24px;
}

.mobClockDiv {
  min-width: 50px;
  max-width: 50px;
  min-height: 46px;
  max-height: 46px;
  background-color: #7fbb67;

  border: 1px solid #5a9e3f;
  position: absolute;
  right: 0px;
  bottom: calc(30vh + 192px);
  border-radius: 5px 0 0 5px;
  border-right: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  cursor: pointer;
  /* animation-name: slideMobSideBtnIn;
  animation-duration: 0.1s; */
}

.mobClockIcon {
  margin-left: 0px;
  margin-top: -2px;
  min-width: 24px;
  max-width: 24px;
  -webkit-animation: blinkLiveClockBtn 2s linear infinite;
          animation: blinkLiveClockBtn 2s linear infinite;
}

.pushLiveClockBtnDown {
  bottom: 328px;
}

@-webkit-keyframes blinkLiveClockBtn {
  50% {
    opacity: 0.25;
    /* background-color: #5a8d64; */
  }
}

@keyframes blinkLiveClockBtn {
  50% {
    opacity: 0.25;
    /* background-color: #5a8d64; */
  }
}

.mobCollapseSiteBtnDiv {
  min-width: 36px;
  max-width: 36px;
  min-height: 36px;
  max-height: 36px;
  background-color: #bac5d3;
  border: 1px solid #a8b6ca;
  position: absolute;
  right: 0px;
  bottom: calc(30vh - 60px);
  border-radius: 5px 0 0 5px;
  border-right: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  cursor: pointer;
}

.collapseMobSideBtnsImg {
  margin-left: 0px;
  margin-top: -2px;
  min-width: 24px;
  max-width: 24px;
}

.mobCollapseQty {
  background-color: #6987a9;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border-radius: 3px 0 0 3px;
  color: white;
  max-height: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 1px;
  padding-bottom: 2px;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  bottom: calc(30vh - 74px);
  z-index: 999;
}

.horizRevCollapse {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.clockSideUnderlay {
  background: rgba(0, 0, 0, 0.85);
  min-height: calc(100% - 50px);
  /* min-height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 3999000;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.clockSideBtnsContainer {
  min-width: 200px;
  min-height: 90px;
  /* background-color: blue; */
  position: absolute;
  bottom: calc(30vh + 52px);
  right: 66px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.showClockSideTab {
  min-height: 40px !important;
  padding-left: 11px !important;
  padding-right: 11px !important;
}

.showClockSideTabPushDown {
  margin-top: 14px;
}

.showClockSideTabTxt {
  font-size: 16px;
}

.showClockSideTabQtySpan {
  background-color: #a387df;
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 6px;
  font-size: 16px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.clockSideCloseImg {
  min-width: 16px;
  max-width: 16px;
  position: absolute;
  bottom: calc(30vh + 89px);
  right: 14px;
}

.unclocksExplainer {
  max-width: 340px;
  color: #516b8b;
  line-height: 16px;
  font-size: 12px;
  padding-right: 20px;
  padding-left: 25px;
  text-align: left;
  margin-top: 7px;
  margin-bottom: 0px;
  font-weight: 400;
  padding-bottom: 4px;
}

.clockedOnStripHolder {
  color: #95aaba;
  font-weight: 500;
  margin-top: -2px;
  max-width: 150px;
}

.clockActiveStartDiv {
  display: flex;
  margin-top: 1px;
}

.activeClockAwaitingClockOffSpan {
  /* background-color: #07b; */
  color: #95aaba;
  font-size: 10px;
  /* margin-bottom:10px; */
  /* padding-left: 4px; */
  /* padding-right: 4px; */
  border-radius: 5px 0 0 5px;
  /* min-height: 40px; */
}

.activeClockOnStartSpan {
  color: #95aaba;
  font-size: 10px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 0px 5px 5px 0px;
  font-weight: 500;
}

.unclockUnitNameAndTeamDiv {
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
  padding-right: 6px;
  max-height:26px;
}

.unclockTeamName {
  display: flex;
  justify-content: flex-start;
  /* padding-right: 14px; */
  margin-top: 12px;
  /* margin-bottom: 6px; */
  color: #00aaff;
  font-weight: 500;
  font-size: 12px;
}

.activeClockItemMenuDiv {
  min-height: 159px;
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  background-color: #214978;
  background-color: #214978;

  color: white;
  border-radius: 0 0 5px 5px;
  border-top: solid #143051 2px;
  -webkit-animation-name: slideUnclockMenuDown;
          animation-name: slideUnclockMenuDown;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
}

.unclockNameAndIconDiv {
  display: flex;
max-height:26px}

.unclockedIcon {
  max-width: 16px;
  margin-top: 9px;
  margin-left: 11px;
  margin-right: 1px;
}

.colourOrange {
  color: #dc8744 !important;
}

.clockedOnIcon {
  max-height: 30px;
  margin-top: 11px;
  margin-left: 5px;
  margin-right: 3px;
  -webkit-animation: Pulsate 1s linear infinite;
          animation: Pulsate 1s linear infinite;
  max-width: 30px;
}

.pulsate {
  -webkit-animation: Pulsate 1s linear infinite;
          animation: Pulsate 1s linear infinite;
}
.clockedOnAgoSpan {
  color: #8ea7c5;
  font-size: 12px;
  text-align: left;
  /* margin-left: 10px; */
  /* padding-left: 6px; */
  padding-right: 6px;
  /* border-radius: 5px; */
  /* padding-top: 2px; */
  /* min-height: 30px; */
  /* padding-bottom: 2px; */
  /* background-color: #8ea7c5; */
  /* line-height: 15px; */
  max-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  /* border: 1px solid #7f93ac; */
  /* margin-top: -5px; */
}

.scheduledActiveClockOffset {
  font-size: 10px;
  margin-left: 6px;
  color: #ba0000;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  text-align: left;
  line-height: 10px;
  /* margin-right: -10px; */
}

.onTimeTickImg {
  min-width: 15px;
  max-width: 15px;
  margin-left: 8px;
}

.clockOffAbsentTxtR {
  max-width: 50px;
  text-align: right;
  line-height: 14px;
  font-size: 10px;
}

.clockOffAbsentBtn {
  justify-content: space-between;
  padding-left: 15px;
}

.clockOffAbsentTxtL {
  /* background-color: yellow; */
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.clockOffAbsentUnpaidTxtL {
  margin-left: -8px !important;
  /* background-color: blue; */
}

.activeClockAbsentTitle {
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  padding-left: 18px;
  padding-top: 6px;
}

.activeClockAbsentBtns {
  display: flex;
}

.activeClockAbsentButton {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 72px;
  padding-left: 9px;
  padding-right: 9px;
  min-height: 40px;
  max-height: 40px;
  border: solid 1px #143051;
  background-color: #bbcada;
  border-radius: 5px;
  cursor: pointer;
  max-width: 90%;
  margin-left: 5%;
  margin-top: 5px;
  min-height: 35px;
}

.activeClockAbsentButton:hover {
  background-color: #a1b4c8;
}

.activeClockAbsentButton:last-child {
  margin-left: 4px;
}

.activeClockViewShift {
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: 64px;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #0bf;
  border: 1px solid #0af;
  margin-top: 8px;
}

.activeClockViewShift:hover {
  background-color: #0af;
}

.activeClockGPSBtn {
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: 64px;
  max-width: 64px;

  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer !important;
  background-color: #143051;
  border: 1px solid #142a46;
  margin-top: 8px;
}

.clockedDataIconImg {
  max-width: 18px;
  margin-left: 0px;
  margin-right: 2px;
}

/* .clockedDataIconImg2 { */
/* max-width: 10px;
    margin-left: 4px;
    margin-right: 0px;
  }
   */
.clockGpsSplit {
  margin-left: -2px;
  margin-right: 0px;
  opacity: 0;
}

.activeClockGPSBtn:hover {
  background-color: #142a46;
}

.activeClockStatusDiv {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  /* justify-content: flex-start; */
  font-size: 13px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 26px;
  /* animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important; */
  /* align-items: ; */
}

.activeClockBrkTxt {
  background-color: #0077ff;
  font-weight: 500;
  color: #fff;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 0 0 0px 5px;
  min-height: 26px;
  display: flex;
  align-items: center;
  border-top: 1px solid #0a5bb7;
  border-left: 1px solid #1a62b3;
  border-bottom: 1px solid #1a62b3;
font-size:12px
  /* font-weight: 500; */
}

.activeClockBrkTxtAgo {
  background-color: #0077cc;
  font-weight: 500;
  color: #99d3f1;
  padding-left: 6px;
  border-top: 1px solid #0a5bb7;

  padding-right: 6px;
  border-radius: 0 0px 5px 0px;
  min-height: 26px;
  display: flex;
  align-items: center;
  border-right: 1px solid #1a62b3;
  border-bottom: 1px solid #1a62b3;
  font-size:12px;
  /* font-weight: 500; */
}

.messagesModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.mobMessagesUnderlay {
  background: rgba(0, 0, 0, 0.25);
  /* background-color: #fff; */
  min-height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
  /* padding-bottom: 20px; */
}

.messagesModalBox {
  background-color: #f4f8fe;
  /* background-color: white; */
  border-radius: 10px;
  min-width: 780px;
  max-width: 780px;
  cursor: default;
  /* animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important; */
}

.mobMessagesModalBox {
  /* min-height:80vh; */
  /* position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 602px !important;
  min-height: 602px !important;
  border-radius: 5px 5px 0 0 !important;
  background-color: #f4f8fe;
  z-index: 9999999 !important;
  box-shadow: 0px 0px 3px 3px rgb(0 0 0 / 10%) */
}

.messagesModalBodyDesktop {
  min-height: 300px;
  /* border: 1px solid #bac5d3; */
  max-width: 96%;
  margin-left: 2%;
  margin-top: 10px;
  background-color: white;
  display: flex;
}

.messagesLeft {
  min-width: 40%;
  max-width: 40%;
  min-height: 358px;
  max-height: 358px;
  overflow-y: scroll;
  background-color: #f4f8fe;
  border-right: 1px solid #bac5d3;
  border-top: 1px solid #bac5d3;
  padding-top: 3px;
}

.messagesRight {
  min-width: 60%;
  max-width: 60%;
  border-top: 1px solid #bac5d3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 5px;
  /* background-color: lime; */
}

.chatBoxScrollBox {
  padding-bottom: 20px;
  min-width: 100%;
  min-height: 314px;
  max-height: 314px;
  overflow-y: scroll;
}
.chatBox {
  /* background-color: orange; */
  min-height: 100%;
  /* max-height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.messagesModalFooterDesktop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  border-top: 1px solid #bac5d3;
  max-width: 96%;
  margin-left: 2%;
}

.clockMessagesModalBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
}
.clockMessagesModalBtn:hover {
  color: #fff;
  background-color: #214978;
}

.refreshMsgBtn {
  font-size: 14px;
  color: #fff;
  background-color: #cbd7e6;
  border: solid #bbcada 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
}
.refreshMsgBtn:hover {
  color: #fff;
  background-color: #bbcada;
}

.chatHeadUnit {
  min-height: 66px;
  padding-right: 10px;
  /* background-color: blue; */
  /* margin-top: 4px; */
  border-bottom: 1px solid #bac5d3;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.chatHeadUnit:hover {
  background-color: #e9eef7;
}

.chatHeadProfPicImg {
  max-width: 40px;
  min-width: 40px;
  max-height: 40px;
  min-height: 40px;
  border-radius: 5px;
  object-fit: cover;
}

.chatHeadL {
  min-width: 50px;
}

.chatHeadR {
  min-width: calc(100% - 60px);
  max-width: calc(100% - 60px);

  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding-left: 4px;
}

.chatHeadName {
  font-weight: 500;
  font-size: 16px;
  color: #140351;
}

.chatHeadMsgPreview {
  font-size: 13px;
  color: #859dbb;
  font-weight: 500;
}

.unreadColour {
  color: #0077ff;
  font-weight: 500;
}

.unreadDiv {
  min-width: 8px;
  max-width: 8px;
  min-height: 8px;
  max-height: 8px;
  border-radius: 10px;
  background-color: #0077ff;
  min-height: 100%;
  margin-left: 6px;
  margin-top: -30px;
}

.selectedChatHead {
  background-color: #0077ff;
  /* border-radius: 5px 0 0 5px; */
  border: none;
  padding-bottom: 1px;
}

.selectedChatHead:hover {
  background-color: #0077ff;
}

.messageNoBorder {
  /* border: none; */
  padding-bottom: 1px !important;
}

.colourWhite {
  color: white !important;
}

.chatInputBar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  min-width: 100%;
}
.chatInput {
  border: none;
  border: 2px solid #dde9f9;
  background-color: #fdfeff;
  border-radius: 5px;
  margin-right: 4px;
  color: #143051;
  min-height: 35px;
  /* padding-left: 5px; */
  font-weight: 400;
  resize: none;
  min-width: calc(100% - 55px);
  font-size: 14px;
  padding: 10px;
  max-height: 40px;
  z-index: 999;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  /* padding-left: 6px; */
  background-color: #fdfeff;
  padding-top: 8px;
  padding-left: 8px;
}

.chatInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 6px;
  background-color: #fdfeff;
  padding-top: 8px;
  padding-left: 8px;
}

.chatInput::-webkit-input-placeholder {
  color: #bbcde3;
}

.chatInput:-ms-input-placeholder {
  color: #bbcde3;
}

.chatInput::placeholder {
  color: #bbcde3;
}

.chatSend {
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: 50px;
  font-size: 1em;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #0bf;
  border: 1px solid #0af;
  margin-top: 0px;
}

.chatSend:hover {
  background-color: #0af;
}

.disableSendBtn {
  /* opacity: 0.5; */
  pointer-events: none;
  border: none;
  background-color: white;
  color: #cbd7e6;
}

.chatItemParent {
  min-width: 96vw;
  max-width: 96vw;
  margin-left: 2vw;
}

.chatItemParentDesktop {
  min-width: 430px;
  max-width: 430px;
}

.chatItem {
  /* background-color: blue; */
  min-width: 100%;
  margin-top: 8px;
  display: flex;
  text-align: left;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
}

.chatItemDesktop {
  /* background-color: blue; */
  min-width: 430px;
  max-width: 430px;

  margin-top: 8px;
  display: flex;
  text-align: left;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
}

.flexLeft {
  justify-content: flex-start;
  /* background-color: yellow; */
}

.flexRight {
  justify-content: flex-end;
  /* background-color: orange; */
}

.chatTxtIn {
  max-width: 335px;
  background-color: #e0ebf8;
  color: #516b8b;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 14px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  font-weight: 400;
  border: 1px solid #cddcee;
}

.chatTxtOut {
  max-width: 350px;
  background-color: #0077ff;
  border: 1px solid #0046ff;
  color: white;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 14px;
  margin-right: 5px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  font-weight: 400;
}

.threadProPic {
  object-fit: cover;
  max-width: 30px;
  min-width: 30px;
  max-height: 30px;
  min-height: 30px;
  border-radius: 5px;
  margin-left: 10px;
  margin-top: 0px;
}

.msgsNoPicInitialDiv {
  background-color: #cfd8e2;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  min-width: 30px;
  min-height: 30px;
  border-radius: 5px;
  font-weight: 500;
}
.chatDs {
  font-size: 12px;
  color: #a1b4c8;
  margin-top: 5px;
  /* margin-left: 4px; */
  text-align: left;
  margin-left: 54px;
  margin-bottom: 10px;
}

.chatDsRight {
  font-size: 10px;
  color: #a1b4c8;
  margin-top: 5px;
  /* margin-left: 12px; */
  text-align: right;
  margin-right: 7px;
  margin-bottom: 10px;
}

.disable {
  pointer-events: none;
  opacity: 0.5;
}

.loadAllChatBtn {
  /* background-color: blue; */
  min-width: 100%;
  color: #00ccff;
  font-weight: 500;
  min-height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
}

.loadAllChatBtn:hover {
  background-color: #edf5fa;
}

.searchUserMessages {
  min-height: 40px;
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #bbcde3;
}

.messagesSearchInput {
  border: none;
  font-size: 16px;
  /* border-bottom: 1px solid #dde9f9; */
  background-color: #f4f8fe;
  border-radius: 5px;
  margin-right: 4px;
  color: #6a8bb1;
  min-height: 35px;
  padding-left: 5px;
  font-weight: 500;
  min-width: 250px;
  margin-top: -2px;
}

.messagesSearchInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 3px;
  background-color: #fdfeff;
}

.messagesSearchInput::-webkit-input-placeholder {
  color: #bbcde3;
  font-weight: 500;
  padding-left: 2px;
}

.messagesSearchInput:-ms-input-placeholder {
  color: #bbcde3;
  font-weight: 500;
  padding-left: 2px;
}

.messagesSearchInput::placeholder {
  color: #bbcde3;
  font-weight: 500;
  padding-left: 2px;
}

.messagesMagniImg {
  max-width: 18px;
  min-width: 18px;
  margin-left: 10px;
  margin-top: -2px;
}

.closeMobMessagesBtnImg {
  min-width: 32px;
  max-width: 32px;
  padding: 8px;
  position: fixed;
  bottom: calc(30vh + 3px);
  right: 7px;
}

.refreshMobMsgIcon {
  min-width: 54px;
  max-width: 54px;
  padding: 11px;
  position: fixed;
  bottom: calc(30vh - 63px);
  right: -4px;
  /* margin-left: 4px; */
}

.closeMobMessagesBtnImg2 {
  bottom: calc(30vh + 19px);
  right: 45px;
}

.mobMessagesTitle {
  color: #143051;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  min-width: 160px;
  margin-right: -32px;
  margin-top: 4px;
}

.msgNotifPushBtn {
  /* border: 1px solid #bbcada;  */
  /* background-color: #fff; */
  border-radius: 5px;
  padding-top: 2px;
  padding-bottom: 7px;
  padding-right: 2px;
  padding-left: 6px;
  max-width: 37px;
  margin-left: 9px;

}

.mobChatHeadsDiv {
  min-height: 100px;
  max-height: 100px;
  min-width: 100vw;
  max-width: 100vw;
  overflow-x: scroll;
  /* padding-bottom: 4px; */
  /* margin-left: 5%;
  margin-top: 20px; */
  padding-left: 1vw;
  display: flex;
  border-bottom: 1px solid #bac5d3;
  padding-right: 20px;
  /* background-color: blue; */

  /* background-color: blue; */
}
.mobChatHeadsDiv::-webkit-scrollbar-thumb {
  background-color: #c7daef;
  border-radius: 20px;
}

.mobChatHeadItem {
  /* background-color: pink; */
  min-width: 88px;
  max-width: 88px;
}

.mobChatHeadProfPic {
  border-radius: 5px;
  max-width: 54px;
  min-width: 54px;
  max-height: 50px;
  min-height: 50px;
  object-fit: cover;
  /* border: 1px solid #2d5a8f; */
  margin-top: 4px;
  margin-bottom: -3px;
}

.mobChatHeadNameRow {
  color: #143051;
  font-size: 12px;
  line-height: 12px;
}

.mobChatBox {
  min-height: calc(100vh - 123px);
  max-height: calc(100vh - 123px) !important;

  /* margin-bottom: 10px; */
  min-width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* overflow-y: scroll; */

  /* margin-left:  */
  /* min-width: calc(90vw - 30px); */
}

.mobChatChatsDiv {
  /* background-color: orange; */
  min-height: calc(100vh - 164px);
  margin-bottom: 10px;
  min-width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
}

.mobChatNameHolder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 17px;
  margin-top: 3px;
}

.selectedMobChatHeadItem {
  background-color: #143051;
  border-radius: 5px;
  /* padding-top: 5px; */
  padding-bottom: 5px;
  margin-bottom: 2px;
  /* min-width: 90px; */
  /* margin-left: -5px; */
  /* margin-right: -5px; */

  /* margin-top: -5px; */
}
.selectedMobChatName {
  /* background-color: white;
  color: #0077ff;
  max-width: fit-content;
  text-align: center;
  border-radius: 5px;
  padding-left: 3px;
  padding-right: 3px; */
  font-weight: 500;
  color: #fff;
  margin-left:-5px
}

.selectedMobChatHeadImg {
  border: 2px solid white;
  margin-left:-10px;
  /* margin-top: 4px;
  margin-bottom: -3px;
  margin-left: -3px; */
}
.mobChatThread {
  min-height: calc(100vh - 230px);
  max-height: calc(100vh - 230px);
  overflow-y: scroll;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  padding-right: 8px;
  /* background-color: yellow; */
  min-width: 100%;
  padding-bottom: 10px;
  padding-top: 12px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: flex-end; */
  /* display: flex; */
/* align-items: flex-end; */
  /* border-right: 1px solid #e6eaee /* display: flex; flex-direction: column; justify-content: flex-end; */
  -webkit-animation-name: slideModalUp !important;
          animation-name: slideModalUp !important;
  -webkit-animation-duration: 0.2s !important;
          animation-duration: 0.2s !important;
}
.mobChatThread::-webkit-scrollbar-thumb {
  background-color: #c7daef;
  border-radius: 20px;
}

.mobChatPreviews {
  min-height: calc(100vh - 173px);
  max-height: calc(100vh - 173px);
  overflow-y: scroll;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  min-width: 100%;
  max-width: 82%;
  padding-right: 8px;
  padding-left: 5%;
  /* border-right: 1px solid #bac5d3; */
  padding-bottom: 10px;
}
.mobChatPreviews::-webkit-scrollbar-thumb {
  background-color: #c7daef;
  border-radius: 20px;
}
.mobChatItem {
  background-color: yellow;
  min-width: 80vw;
  max-width: 80vw;
}
.mobChatInputBox {
  min-height: 40px;
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 3px;
}

.mobChatTxtInput {
  min-width: calc(90% - 20px);
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 6px;
  background-color: #fdfeff;
  min-height: 44px;
  font-size: 16px;
  color: #214978;
  padding-left: 10px;
  font-weight: 400;
}

.mobSendChatBtn {
  min-height: 44px;
  max-height: 44px;
  /* margin-top: 2px; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: 50px;
  font-size: 1em;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #0bf;
  border: 2px solid #0af;
}

.mobChatPreviewUnit {
  background-color: rgb(255, 255, 255, 0.03);
  max-height: 60px;
  min-height: 60px;
  margin-top: 8px;
  align-items: center;
  /* border: 1px solid #414a55; */
  border-radius: 5px;
  max-width: 90vw;
  min-width: 90vw;
  display: flex;
  justify-content: space-between;
  background-color: white;
  /* border: rgba(172, 195, 225, 1) 1px solid; */
  border: 1px solid #deedff;
  /* border-bottom: 2px solid #deedff; */
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
}

.mobChatPreviewProfilePicImg {
  max-width: 46px;
  min-width: 46px;
  max-height: 46px;
  min-height: 46px;
  object-fit: cover;
  border-radius: 5px;
  margin-left: 5px;
  margin-top: 3px;
  /* border: 1px solid #2d5a8f; */
}

.mobChatPreviewUnitRight {
  color: #140351;
  text-align: left;
  min-width: calc(100% - 50px);
  padding-left: 10px;
  font-weight: 500;
}

.unreadMobChatNameStrip {
  color: #00ccff;
}
.mobChatPreviewUnitRightPreview {
  font-size: 14px;
  font-weight: 400;
  color: #496375;
}

.unreadMobChatUnit {
  border: 2px solid #143051;
  background-color: #ffffff;
}

.unreadMobChatPicBorder {
  border: 2px solid #143051;
}

.chatHeadMobCrossImg {
  cursor: pointer;
  margin-left: -65px;
  margin-right: 52px;
  margin-bottom: 38px;
}

.mobChatThreadHeader {
  min-height: 50px;
  /* background-color: blue; */
  border-bottom: 1px solid #e6eaee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 20px;
}

.mobChatThreadNameTitle {
  color: #214978;
  margin-left: 10px;
  font-weight: 500;
  font-size: 18px;
  margin-top: 2px;
  text-align: left;
  /* font-size:14px; */
}

.mobChatHeaderThreadLeft {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lastActiveTxtMob {
  color: #516b8b;
  font-weight: 400;
  font-size: 12px;
  /* padding-top: 2px; */
  /* padding-right: 11px; */
  text-align: right;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  line-height: 13px;
}

.lastActiveTxtDesktop {
  color: #8ea7c5;
  font-weight: 400;
  font-size: 12px;
  margin-top: -64px;
  margin-bottom: 38px;
  margin-left: 10px;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 5px;
  border: 1px solid #e0ebf8;
}

.whiteGoBackMsgsImg {
  background-color: #214978;
  border-radius: 5px;
  /* min-width: 30px; */
  min-height: 30px;
  max-height: 30px;
  max-width: 18px;
  margin-left: 4px;
  margin-right: 2px;
  /* border: 1px solid #143051; */
}

.mobMessagesHeader {
  /* background-color: yellow; */
  min-height: 54px;
  min-width: 100vw;
  max-width: 100vw;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
}

.mobMessagesRefresh {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 30px;
  margin-right: 5%;
  margin-top: 5px;
}

.desktopNavBarHeading {
  color: #a8b6ca;
  font-size: 12px;
  text-align: left;
  padding-left: 50px;
  margin-bottom: 7px;
  font-weight: 500;
}

.desktopNavItem {
  display: flex;
  min-width: 75%;
  justify-content: center;
  max-width: 75%;
  margin-left: 14%;
  border-radius: 5px;
  color: #140351;
}

.desktopNavItem:hover {
  background-color: #f5f9ff;
  color: black;
}

.desktopNavIcon {
  max-width: 15px;
  margin-left: 5px;
}

.desktopNavIconRota {
  display: none;
}

.desktopItemSelected {
  background-color: #ebf0f7;
  border-color: white !important;
  border-left: 3px solid #0077ff !important;
  border: 1px solid black;
  /* border:none !important */
}

.desktopItemSelectedMgrBorder {
  border-left: 3px solid #4b1caf !important;
}

.desktopItemSelectedAdminBorder {
  border-left: 3px solid #516b8b !important;
}

.desktopItemSelected:hover {
  background-color: #ebf0f7;
}

.navBtnBlue {
  color: #0077ff;
}

.navBtnPink {
  color: #4b1caf;
}

.navBtnPurple {
  color: #9a74eb;
}

.navBtnLightGrey {
  color: #8ea7c5;
}

.navBtnDarkGrey {
  color: #516b8b;
}

.desktopNavAdmin {
  margin-bottom: 30px;
}

.updateLoading {
  opacity: 0.2;
  pointer-events: none;
}

.clockedOnLightSpan {
  background-color: #82c26a;
  min-width: 4px;
  min-height: 23px;
  max-width: 6px;
  max-height: 6px;
  border-radius: 8px;
  margin-right: 6px;
  margin-left: -11px;
  margin-top: 1px;
  -webkit-animation: blinkLiveClockBtn 1s linear infinite;
          animation: blinkLiveClockBtn 1s linear infinite;
}

.submitDesktopBtn {
  font-weight: 500;
  border: 1px solid #e0ebf8;
  border-radius: 5px;
  max-height: 38px;
  min-height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  cursor: pointer;
  color: #2d5a8f;
  /* margin-left: 40px; */
  padding-left: 8px;
  padding-right: 8px;
  background-color: white;
}

.submitDesktopBtn:hover {
  background-color: #e7f1fb;
}

.deviceInfo {
  background-color: black;
  color: white;
  position: fixed;
  top: 0;
  right: 0;
  min-height: 20px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-left: 4px;
  padding-right: 4px;
  opacity: 0.6;
  border-radius: 0 0 0 5px;
  display: none;
}

.scheduledFixContainer {
  /* background-color: blue; */
  min-height: 110px;
  padding-top: 16px;
}

.installModalContentBox {
  /* background-color: blue; */
  /* min-height: calc(100vh - 70px); */
  /* max-height: calc(100vh - 70px); */
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* display: flex;   */
  /* flex-direction: row; */
}

.installModalContentBoxAndroid {
  min-height: 188px;
}

.dockImg {
  margin-top: 20px;
  max-width: 150px;
  margin-bottom: 10px; /* display: none; */
}

.dockInstructDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 322px;
  margin-bottom: 15px;
  /* background-color: blue; */
  padding-top: 2px;
  padding-bottom: 2px;
  
  /* margin-left: -20px; */
}

.dockInstructTxt {
  font-size: 14px;
  color: #214978;
  font-weight: 400;
  display: flex;
  min-height: 20px;
  align-items: center;
}
.addToHSiosImg {
  max-width: 22px;
  margin-left: 8px;
  margin-right: 8px;
  background: white;
  min-width: 30px;
  padding: 6px;
  max-height: 30px;
  border-radius: 4px;
  border: 1px solid #e7e5ed;
}

.addToHSChromeImg {
  max-width: 22px;
  margin-top: -12px;
}

.chromeJustTap {
  margin-top: -16px;
  margin-left: 1px;
}

.addToHSspan {
  font-weight: 500;
}

.lookForTheLogo {
  max-width: 24px;
  max-height: 24px;
  min-height: 24px;
  min-width: 24px;
  padding: 3px;
}
.inSafari {
  margin-top: 24px;
  margin-bottom: 2px;
  text-align: left;
  min-width: 100%;
  padding-left: 20px;
  font-weight: 500;
  margin-bottom: 13px;
  min-width: 300px;
  /* background-color:   yellow; */
  margin-left: -80px;
}

.chromeInstructWidth {
  max-width: 329px;
  min-width: 329px;
  margin-top: 40px;
  margin-left: 10px;
}

.orAths {
  margin-top: 2px;
  font-size: 12px;
  opacity: 0.6;
}

.installOrATHSdiv {
  min-width: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 50px;
  margin-top: 8px;
}

.autoInstallAndroidBtn {
  font-size: 14px;
  color: #fff;
  border: solid 1px #0af;
  background-color: #0bf;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 30px;
}

.autoInstallAndroidBtn:hover {
  background-color: #0af;
}

.installModalFooter {
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  min-height: 50px;
  /* padding-right: 20px;
  padding-left: 30px; */
  margin-top: 30px;
  border-top: 1px #e3e8ee solid;
  padding-top:40px;
}

.deviceTogglerContainer {
  min-height: 50px;
  max-height: 50px;
  min-width: 120px;
  max-width: 200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
  margin-top: 20px;
}

.mobInstallModal {
  position: fixed;
  /* bottom:  0px; */
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 360px !important;
  min-height: 360px !important;
  border-radius: 5px 5px 0 0 !important;
}

.mobSwapModal {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 15px  15px 0 0  !important;
  max-height: 533px !important;
  min-height: 533px !important;
  /* border-radius: 5px 5px 0 0 !important; */
}

.nonSelectMobSwapModal {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  border-radius: 5px 5px 0 0;
  max-height: 324px !important;
  min-height: 324px !important;
  /* border-radius: 5px 5px 0 0 !important; */
}

.selectedShiftMobSwapModal {
  position: fixed;
  bottom: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  /* border-radius: 15px 15px 0 0; */
  max-height: 348px !important;
  min-height: 348px !important;
  border-radius: 15px 15px 0 0 !important;
}

.deviceToggler {
  font-weight: 500;
  color: #bac5d3;
  min-height: 30px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.deviceToggler:hover {
  color: #143051;
}

.deviceTogglerActive {
  background-color: #214978;
  color: white;
  min-height: 30px;
  border-radius: 5px;
  border: 1px solid #143051;
}

.deviceTogglerActive:hover {
  color: white;
}

.saveClockCardDateBtn {
  background-color: #07f;
  max-height: 30px;
  line-height: 26px;
  color: white;
  font-weight: 500;
  border: 1px solid #0469de;
  border-radius: 5px;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  min-height: 35px;
  /* margin-bottom: -2px; */
  cursor: pointer;
  min-width: 60px;
  margin-top: 20px;
}

.editClockDateInput {
  margin-top: 9px !important;
}
.schedClockDay {
  /* background-color: #8997a8; */
  /* border: 1px #5b728d solid; */
  /* color: white; */
  max-height: 30px;
  display: flex;
  align-items: center;
  /* padding-left: 6px; */
  /* padding-right: 6px; */
  /* border-radius: 5px; */
  margin-top: 12px;
  margin-left: 0px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-bottom: -8px;
  font-size: 14px;
  /* padding-top:1px; */
  color: #143051;
  font-size: 18px;
  /* background-color: #f4f8fe; */
  /* border: 1px solid #e6e6e6; */
}

.onYourMob {
  min-width: 329px;
  text-align: left;
  padding-left: 4px;
  margin-top: 20px;
  margin-bottom: -28px;
  color: #214978;
  font-size: 15px;
  min-height: 30px;
  line-height: 30px;
}

.onYourAndroidSpan {
  border-radius: 5px;
  margin-bottom: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-weight: 500;
  margin-left: 2px;
}

.desktopLeftShield {
  min-width: 11%;
  min-height: calc(100vh - 30px);
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
}

@media only screen and (min-width: 1450px) {
  .desktopLeftShield {
    min-width: 16%;
  }
}

@media only screen and (min-width: 1675px) {
  .desktopLeftShield {
    min-width: 20%;
  }
}

@media only screen and (min-width: 1865px) {
  .desktopLeftShield {
    min-width: 25%;
  }
}

@media only screen and (min-width: 2280px) {
  .desktopLeftShield {
    min-width: 30%;
  }
}

@media only screen and (min-width: 2800px) {
  .desktopLeftShield {
    min-width: 36%;
  }
}

.desktopLeftGreyBar {
  min-width: 100%;
  min-height: 58px;
  background-color: #ebf0f7;
  border-bottom: 2px solid #e6eaee;
  border-top: 2px solid #e6eaee;
  margin-top: 115px;
}

.desktopLeftGreyForNonClockOn {
  background-color: white;
  border-bottom: none;
  border-top: 1px solid #f1f5fa;
}

.desktopRightShield {
  min-height: 89px;
  min-width: 25px;
  background-color: white;
  position: fixed;
  top: 28px;
  right: 0px;
  border-bottom: 2px solid #e6eaee;
  border-top: 2px solid #e6eaee;
}

.desktopRightShieldAdmin {
  min-height: 89px;
}

@media only screen and (max-width: 1140px) {
  .desktopRightShield {
    display: none;
  }
}
@media only screen and (min-width: 1170px) {
  .desktopRightShield {
    min-width: 36px;
  }
}
@media only screen and (min-width: 1205px) {
  .desktopRightShield {
    min-width: 45px;
  }
}

@media only screen and (min-width: 1230px) {
  .desktopRightShield {
    min-width: 60px;
  }
}
@media only screen and (min-width: 1255px) {
  .desktopRightShield {
    min-width: 6vw;
  }
}

@media only screen and (min-width: 1292px) {
  .desktopRightShield {
    min-width: 7vw;
  }
}

@media only screen and (min-width: 1326px) {
  .desktopRightShield {
    min-width: 8vw;
  }
}

@media only screen and (min-width: 1357px) {
  .desktopRightShield {
    min-width: 9vw;
  }
}

@media only screen and (min-width: 1390px) {
  .desktopRightShield {
    min-width: 10vw;
  }
}

@media only screen and (min-width: 1424px) {
  .desktopRightShield {
    min-width: 11vw;
  }
}

@media only screen and (min-width: 1460px) {
  .desktopRightShield {
    min-width: 12vw;
  }
}

@media only screen and (min-width: 1500px) {
  .desktopRightShield {
    min-width: 13vw;
  }
}

@media only screen and (min-width: 1542px) {
  .desktopRightShield {
    min-width: 14vw;
  }
}

@media only screen and (min-width: 1585px) {
  .desktopRightShield {
    min-width: 15vw;
  }
}

@media only screen and (min-width: 1625px) {
  .desktopRightShield {
    min-width: 16vw;
  }
}

@media only screen and (min-width: 1670px) {
  .desktopRightShield {
    min-width: 17vw;
  }
}

@media only screen and (min-width: 1730px) {
  .desktopRightShield {
    min-width: 320px;
  }
}

@media only screen and (min-width: 1780px) {
  .desktopRightShield {
    min-width: 340px;
  }
}

@media only screen and (min-width: 1820px) {
  .desktopRightShield {
    min-width: 360px;
  }


}

@media only screen and (min-width: 1850px) {
  .desktopRightShield {
    min-width: 380px;
  }
}

@media only screen and (min-width: 1890px) {
  .desktopRightShield {
    min-width: 400px;
  }
}

@media only screen and (min-width: 1938px) {
  .desktopRightShield {
    min-width: 420px;
  }
}

@media only screen and (min-width: 1970px) {
  .desktopRightShield {
    min-width: 440px;
  }
}

@media only screen and (min-width: 2019px) {
  .desktopRightShield {
    min-width: 460px;
  }
}

@media only screen and (min-width: 2056px) {
  .desktopRightShield {
    min-width: 480px;
  }
}

@media only screen and (min-width: 2096px) {
  .desktopRightShield {
    min-width: 500px;
  }
}

@media only screen and (min-width: 2134px) {
  .desktopRightShield {
    min-width: 520px;
  }
}

@media only screen and (min-width: 2179px) {
  .desktopRightShield {
    min-width: 540px;
  }
}

@media only screen and (min-width: 2218px) {
  .desktopRightShield {
    min-width: 560px;
  }
}

@media only screen and (min-width: 2259px) {
  .desktopRightShield {
    min-width: 580px;
  }
}

@media only screen and (min-width: 2300px) {
  .desktopRightShield {
    min-width: 600px;
  }
}

@media only screen and (min-width: 2336px) {
  .desktopRightShield {
    min-width: 620px;
  }
}

@media only screen and (min-width: 2378px) {
  .desktopRightShield {
    min-width: 640px;
  }
}

@media only screen and (min-width: 2418px) {
  .desktopRightShield {
    min-width: 660px;
  }
}

@media only screen and (min-width: 2460px) {
  .desktopRightShield {
    min-width: 680px;
  }
}

@media only screen and (min-width: 2497px) {
  .desktopRightShield {
    min-width: 700px;
  }
}

@media only screen and (min-width: 2538px) {
  .desktopRightShield {
    min-width: 720px;
  }
}

@media only screen and (min-width: 2580px) {
  .desktopRightShield {
    min-width: 740px;
  }
}

@media only screen and (min-width: 2614px) {
  .desktopRightShield {
    display: none;
  }
}

.mobSideBtns {
  /* z-index: 999999999999 */
}

.mobMessagesModalBox {
  /* background-color: white;
  max-height:80% !important;
  min-height:80% !important;
  max-width: 100vw !important;
  min-width: 100vw !important;
  position: fixed;
  bottom: 0; left: 0; */

  position: fixed;
  bottom: 0px;
  left: 0;
  min-width: 100vw !important;
  max-width: 100vw !important;
  /* border-radius: 5px 5px 0 0; */

  /* max-height:590px !important;
  min-height:590px !important; */
  /* border-radius: 5px 5px 0 0 !important; */
  background-color: #f4f8fe;
  z-index: 9999999 !important;
  /* box-shadow: 0px 0px 3px 3px rgb(0 0 0 / 10%); */
}

.mobChatMsgFooter {
  /* background-color: orange; */
  /* position: fixed; */
  /* bottom: 0px; */
  min-width: 100%;
  min-height: 50px;
  border-top: 1px solid #bac5d3;
}

.unclockedShiftTilTxt {
  color: #516b8b;
  font-size: 9px;
  text-align: left;
  margin-left: 15px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 3px;
  /* padding-top: 2px; */
  /* min-height: 30px; */
  /* padding-bottom: 2px; */
  background-color: #8ea7c5;
  /* line-height: 15px; */
  max-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f8fe;
  border: 1px solid #e6e6e6;
  /* border: 1px solid #7f93ac; */
  margin-top: 4px;
}

.downMsgModalImg {
  cursor: pointer;
  padding: 5px;
}

.mobChatHeaderThreadRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.refreshMobMsgBtnImg {
  /* background-color: #214978; */
  max-width: 30px;
  min-width: 30px;
  min-height: 30px;
  max-height: 30px;
  border-radius: 5px;
  /* border: 1px solid #143051; */
  cursor: pointer;
  background-color: #0077ff;
  border: 1px solid #0077aa;
}

.refreshMobMsgBtnImg:hover {
  background-color: #0af;
}

.opac06 {
  opacity: 0.6;
}

.msgImgUserSchedImg {
  max-width: 12px;
  margin-right: 7px;
  margin-left: 2px;
}

.mobNavTopLeftDiv {
  display: flex;
  position: fixed;
  top: 0px;
  left: -6px;
}

.mobNavMsgIcon {
  min-width: 36px;
  margin-left: 7px;
  padding: 6px;
  padding-right: 6px;
  margin-right: 3px;
}

.mobNavMsgIcon:first-child {
  min-width: 48px;
  margin-top: 1px;
  margin-right: -3px;
  padding: 13px;
}

.mobMsgQtyBlob {
  background-color: #ef5353;
  border: 1px solid #ba0000;
  color: white;
  font-weight: 500;
  min-width: 17px;
  min-height: 17px;
  border-radius: 3px;
  max-width: 17px;
  max-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  padding-bottom: 1px;
  position: fixed;
  top: 9px;
  left: 69px;
}

.unclockParent {
  padding-bottom: 3px;
}

.navBarQty {
  color: white;
  border: 1px solid #9119ca;
  min-width: 20px;
  margin-left: -38px;
  max-width: 20px;
  border-radius: 4px;
  max-height: 20px;
  min-height: 20px;
  margin-top: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding-bottom: 2px;
  background-color: #a387df;
  font-size: 11px;
  margin-left: -24px;
  border: 1px solid #896ec3;
}

.blueNavBarQty {
  border-color: #116dd6;
  background-color: #07f;
}

.fadeStaffRotaLink {
  opacity: 0.5;
}

.mobHelpIcon {
  min-width: 22px;
  max-width: 22px;
  margin-left: 18px;
  /* padding-top:2px; */
  /* opacity: 0.7; */
}

.unreadMsgName {
  font-weight: 500;
}

.staffRotaNavBtn {
  border-bottom: 1px solid #e6eaee;
  border-top: 1px solid #e6eaee;
  cursor: pointer;
  border-radius: 0px;
  /* background-color: #f7f9ff !important; */
}

.borderRadius5 {
  border-radius: 5px !important;
}

.borderTopWhite {
  border-top: 1px solid white;
}

.borderBottomWhite {
  border-bottom: 1px solid white;
}

.liveClockSecs {
  font-size: 12px;
  margin-top: -24px;
  margin-left: -9px;
  min-width: 34px;
  margin-right: -24px;
  opacity: 0.5;
}

.liveClockSecsInline {
  font-size: 12px;
  margin-top: -17px;
  margin-left: -9px;
  min-width: 34px;
  margin-right: -24px;
}

.statsIconFloat {
  position: fixed;
  top: 13px;
  left: 184px;
  max-width: 24px;
  z-index: 9999;
}
.gpsLoadingClockOnModalImg {
  /* background-color: blue; */
  max-width: 40px;
  max-height: 12px;
  margin-top: 3px;
  margin-left: 3px;
}

.gps1only {
  opacity: 0.5;
  max-height: 20px;
}

.clockGPSBar {
  /* background-color: yellow; */
  display: flex;
}

.gpsIcon {
  max-width: 30px;
}

.gpsLoadedTxt {
  font-weight: 500;
  font-size: 12px;
  margin-left: 8px;
  color: #a8cc9a;
  text-align: right;
  min-height: 17px;
  display: flex;
  align-items: center;
}

.gpsConfDiv {
  text-align: left;
  min-width: 186px
}

.gpsLoadedSubTxt {
  color: #143051;
  font-weight: 500;
  font-size: 12px;
  margin-left: 8px;
  float: right;
}

.gpsLoadedSubTxtAcc {
  color: #143051;
  font-weight: 500;
  font-size: 10px;
  opacity: 0.6;
  margin-left: 4px;
  border-left: 1px solid #bbcada;
  padding-left: 6px;
}

.gpsLoadedSubTxtDist {
  color: #bac5d3;
  font-weight: 500;
  font-size: 10px;
  /* opacity: 0.6; */
  margin-left: 0px;
  /* border-left: 1px solid #143051; */
  padding-left: 6px;
}

.gpsLoading {
  font-weight: 500;
  font-size: 12px;
  color: #0077bb;
  margin-top: 2px;
  margin-left: 8px;
  margin-right: 4px;
  opacity: 1;
  min-width: 186px;
  display: flex;
  justify-content: flex-end;
  -webkit-animation: Pulsate 1s linear infinite;
          animation: Pulsate 1s linear infinite;
}

.deniedFailedTxt {
  font-weight: 500;
  font-size: 12px;
  color: #ba0000;
  margin-top: 2px;
  margin-left: 8px;
  margin-right: 4px;
  opacity: 1;
  /* animation: Pulsate 1s linear infinite; */
  min-height: 20px;
  padding-top: 2px;
}

@-webkit-keyframes Pulsate {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes Pulsate {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.clockOnStatusDiv {
  font-weight: 500;
  text-align: right;
  background-color: #214978;
  border: 1px solid #143051;
  border-radius: 5px;

  padding: 3px 8px 3px 8px;
  color: white;
}

.clockOnStatusTitle {
  font-size: 14px;
  font-weight: 500;
  color: #143051;
  /* opacity: 0.6; */
}

.clockOnStatusValue {
  font-size: 12px;
  margin-top: 2px;
}

.clockStatusBox {
  font-weight: 500;
  text-align: left;
  background-color: #f4f8fe;
  border: 3px solid #cbd7e6;
  border-radius: 5px;
  padding: 3px 8px 3px 8px;
  color: #516b8b;
  margin-top: 8px;
}

.workingStatus {
  background-color: #ade098;
  border: 3px solid #5a9e3f;
  color: #5a914c;
}

.gpsLoadingDisableClockOnBtn {
  opacity: 0.2;
  pointer-events: none;
}

.awaitGPStxt {
  min-height: 40px;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #214978;
  min-width: 100%;
  justify-content: center;
}

.deniedGPSdiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  padding-right: 3px;
  margin-top: 14px;
}

.deniedGPStxt {
  font-weight: 500;
  color: #0af;
  text-align: left;
  max-width: 60%;
  font-size: 12px;
}

.ignoreGPSdenied {
  background-color: #07f;
  border: #0469de 1px solid;
  border-radius: 5px;
  min-height: 35px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
}

.ignoreGPSdenied:hover {
  background-color: #0469de;
}

.gpsIgnored {
  font-weight: 500;
  color: #bbcada;
  margin-left: 10px;
  font-size: 12px;
}

.gpsIgnoredTopRight {
  font-weight: 500;
  color: #bbcada;
  margin-left: 0px;
  font-size: 12px;
}

.topRightGPS {
  text-align: right;
  /* background-color: blue; */
  display: flex;
  max-height: 40px;
  max-width: 200px;
  float: right;
}

.topRightGPSicon {
  max-width: 18px;
  margin-top: 0px;
  margin-right: 2px;
  margin-bottom: -1px;
}

.gpsBreakDiv {
  background-color: yellow;
  min-width: 160px;
  border: 1px solid #143051;
  border-radius: 5px;
  margin-bottom: 15px;
  margin-top: 5px;
}

.clockOnGPSbox {
  background-color: black;
  min-height: 120px;
}

.clockGPSrow {
  flex-direction: column;
  /* background-color: blue; */
  /* max-width:100%; */
}

.clockedRowGps {
  /* display: flex; */
  margin-top: 14px;
  margin-bottom: 8px;
  min-height: 120px;
  border: 1px solid #e0ebf8;
  border-radius: 5px;
  background-color: #f4f8fe;
  /* width: 200px; */
  /* height:200px; */
}

.clockRowGpsRow {
  display: flex;
  min-height: 66px;
  align-items: center;
  min-width: 100%;
  border-bottom: 1px solid #e0ebf8;
  padding-bottom: 5px;
  padding-right: 5px;
}

.clockRowGpsRowLeft {
  /* background-color: blue; */
  width: 73%;
  font-weight: 500;
  color: #143051;
  padding-left: 10px;
}

.clockRowGpsRowRight {
  /* background-color: yellow; */
  width: 27%;
}

.viewMapBtn {
  display: flex;
  font-weight: 500;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  cursor: pointer;
  color: white !important;
  border-radius: 5px;
  font-size: 14px;
  justify-content: center;
  max-width: 76px;
  max-height: 35px;
  align-items: center;
}

.viewMapBtn:hover {
  background-color: #bbcada;
}
.viewInMapsPopUpImg {
  max-width: 18px;
  margin-left: 6px;
}

.clockedOnZoneTxtTitle {
  font-size: 12px;
  line-height: 16px;
  color: #214978;
}

.clockedOnZoneTxt {
  font-size: 12px;
  color: #143051;
  line-height: 14px;
  margin-top: 5px;
  padding-right: 10px;
}

.clockedOnZoneTxtAcc {
  font-size: 10px;
  color: #bbcada;
  line-height: 14px;
  margin-top: 2px;
  padding-right: 10px;
}

.clockGPSRowImgAndTitle {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.gpsRowGpsIcon {
  max-width: 20px;
  margin-right: 4px;
}

.gpsAwayFromSpan {
  opacity: 0.5;
}

.clockedOnZoneTxtTitleUnknown {
  font-size: 12px;
  line-height: 16px;
  color: #214978;
  margin-top: 9px;
  margin-left: 4px;
}

.coordSize {
  font-size: 14px;
}

.xSmallCoords {
  font-size: 9px;
}

.clockedGPSheaderRow {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clockGPSToggleBrksBtn {
  max-height: 30px;
  color: white;
  border: solid 1px #143051;
  background-color: #214978;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 5px;
  cursor: pointer;
  margin-top: 12px;
  /* margin-bottom:-4px; */
}

.clockGPSToggleBrksBtn:hover {
  background-color: #143051;
}

.showBrkGpsCheckboxImg {
  /* max-width:15px */
  max-width: 96%;
  padding: 2px;
  padding-top: 3px;
  /* padding-left:2px; */
  /* padding-bottom:12px; */
  /* margin-top:-12px; */
}
.showBrkCheckBoxDiv {
  border-radius: 3px;
  min-width: 14px;
  max-width: 14px;
  min-height: 14px;
  max-height: 14px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  margin-right: 3px;
  margin-top: -1px;
}

.showBrkOpac {
  opacity: 0.5;
}

.breakGpsRowAni {
  display: flex;
  align-items: center;
  min-width: 100%;
  border-bottom: 1px solid #e0ebf8;
  padding-bottom: 5px;
  padding-right: 5px;
  background-color: white;
  -webkit-animation-name: breakGPSrowAnimation !important;
          animation-name: breakGPSrowAnimation !important;
  -webkit-animation-duration: 0.5s !important;
          animation-duration: 0.5s !important;
}

@-webkit-keyframes breakGPSrowAnimation {
  from {
    height: 0px;
  }
  to {
    height: 70px;
  }
}

@keyframes breakGPSrowAnimation {
  from {
    height: 0px;
  }
  to {
    height: 70px;
  }
}

.awaitingGPSAlreadyClockedOn {
  min-height: 60px;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #214978;
  min-width: 100%;
  justify-content: center;
  padding-top: 0px;
  margin-top: -30px;
}
.x2831421 {
  margin-top: -30px;
}

.x98349724 {
  margin-top: -16px;
}

.x1398471 {
  margin-top: -30px;
}

.x324567 {
  margin-top: -17px;
}
.awaitingGPSAlreadyClockedOnMob {
  padding-top: 10px;
}

.x3719741 {
  margin-top: -33px;
}

.x9173811 {
  margin-top: -30px;
}

.closeGPSsub {
  max-width: 9px;
  margin-left: 6px;
}

.activeClockGPSbox {
  min-height: 142px;
  min-width: 82%;
  max-width: 82%;
  margin-left: 12%;
  background-color: #ffffff;
  color: white;
  border-radius: 0 0 5px 5px;
  border: solid #143051 2px;
  -webkit-animation-name: slideUnclockMenuDownSched;
          animation-name: slideUnclockMenuDownSched;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  /* display: flex; */
  /* padding-top: 8px; */
  padding-bottom: 44px;
  margin-bottom: 16px;
  /* border-left: #bbcada 1px solid; */
  /* border-right: #bbcada 1px solid; */
  border-top: none;
}

.activeClockGPSrow {
  background-color: #6481a6;
  max-width: 88%;
  margin-left: 8%;
  border-radius: 0 0 4px 4px;
  min-height: 40px;
  /* margin-top: -1px; */
  /* border: 1px solid #0d2644; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -28px;
  padding-top: 1px;
}

.acitveClockGPScol2 {
  width: 100%;
  /* background-color:  green; */
  min-height: 40px;
}

.activeClockGPSUpper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #627f9e;
  max-width: 94%;
  margin-left: 2.5%;
  padding-top: 0px;
  font-weight: 500;
  font-size: 13px;
  min-height: 14px;
  max-height: 14px;
  margin-top: 3px;
}
.activeClockGPSLower {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #627f9e; */
  max-width: 95%;
  margin-left: 2.5%;
  padding-bottom: 0px;
  font-weight: 500;
  font-size: 12px;
  min-height: 22px;
  max-height: 22px;
}

.activeClockGPSrowGPSimg {
  max-width: 16px;
  /* margin-top:4px; */
  margin-right: 4px;
}

.gpsAccSmallTxt {
  font-size: 10px;
  opacity: 0.5;
}
.gpsClockedStatus {
  /* background-color: #193752; */
  /* border-radius: 3px; */
  /* padding: 2px 6px; */
  margin-top: 1px;
  max-height: 20px;
  display: flex;
  align-items: center;
  -webkit-user-select: text;
      -ms-user-select: text;
          user-select: text;
  cursor: text;
}

.gpsClockedItemStatus {
  background-color: #0077ff;
  border: 1px solid #0050ab;
  border-radius: 3px;
  padding: 2px 6px;
  margin-top: 1px;
  max-height: 20px;
  display: flex;
  align-items: center;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  -webkit-user-select: text;
      -ms-user-select: text;
          user-select: text;
  cursor: pointer;
}

.gpsClockedItemStatus:hover {
  opacity: 0.8 !important;
}

.clockedDataTimeRow {
  width: 94%;
  margin-left: 3%;
  border-radius: 4px;
  min-height: 62px;
  margin-top: 53px;
  /* border: 1px solid #0d2644; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* align-items: center;
  justify-content: space-between; */
  color: #143051;
  border: 1px solid #bfccdc;
  background-color: #f4f4f4;
  border-left: 7px solid #4578b5;
}

.clockedDataTimeRow:first-of-type {
  margin-top: 20px;
}

.clockedDataTimeRowL {
  width: 50%;
  font-weight: 500;
  /* padding-left: 8px; */
  font-size: 12px;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: flex-start; */
  margin-top: 6px;
  text-align: left;
  margin-left: 12px;
}

.clockedDataTimeRowR {
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  /* background-color: black; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* opacity: 0.7; */
  font-weight: 500;
  /* padding-right: 8px; */
  color: #0af;
  margin-top: 1px;
  font-size: 18px;
  margin-left: 12px;
}

.activeclockGPSUpperLeft {
  opacity: 0.8;
  font-size: 10px;
}

.locRecordedDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activeClockGPSmapBtn {
  background-color: #0af;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 3px;
  border: 1px solid #0373ab;
  cursor: pointer;
  font-size: 11px;
  display: flex;
  max-height: 20px;
  display: flex;
  align-items: center;
}

.gpsMapPopUpIcon {
  max-width: 12px;
  margin-left: 2px;
  opacity: 0.7;
}

.activeClockGPSmapBtn:hover {
  background-color: #0b99e1;
}

.activeclockGPSUpperRight {
  font-size: 10px;
  opacity: 0.4;
  margin-left: 4px;
}

.activeClockNoGPSdiv {
  /* background-color: #6481a6; */
  max-width: 140px;
  float: right;
  margin-right: 20px;
  min-height: 30px;
  max-height: 30px;

  border-radius: 0px 0px 4px 4px;
  /* border: #143051 1px solid; */
  /* border-top: none; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #859dbb;
  border: 1px solid #bfccdc;
  background-color: #f4f4f4;
  border-top: none;

  font-size: 10px;
  padding-right: 9px;
  padding-left: 9px;
}

.clockItemGPSscrollBox {
  min-height: 500px;
  max-height: 500px;
  overflow-y: auto;
  min-width: 100%;
  /* min-width: calc(100% - 20px); */
  color: #143041;
  border-radius: 5px;
  border-top: solid #143051 2px;
  /* animation-name: slideUnclockMenuDownSched; */
  /* animation-duration: 0.1s; */
  /* display: flex; */
  margin-top: 6px;
  padding-bottom: 44px;
  margin-bottom: 10px;

  padding-left: 10px;
  padding-right: 10px;

  background-color: #fff;
  max-width: 90%;
  margin: 0 auto;
  /* min-height: 300px; */
  /* margin-top: -50px; */
  /* z-index: -1; */
  /* padding-top: 40px; */
  border-radius: 5px 5px 0 0;
  border-top: solid 2px #e6eaee;
  border-right: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
  border-bottom: 1px solid #bac5d3;
  padding-top: 10px;
}

.clockActiveDivider {
  min-width: 90%;
  min-height: 1px;
  background-color: #516b8b;
  margin-top: 51px;
  margin-bottom: -30px;
  margin-left: 5%;
  max-width: 90%;
}

.clockActiveDividerTxt {
  background-color: #0af;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-size: 12px;
  font-weight: 500;
  border-radius: 3px;
  padding: 2px 7px;
  border: 1px solid #021226;
  margin: 20px 10px;
  margin-bottom: -40px;
  /* min-width: 140px; */
  max-height: 20px;
  display: flex;
  align-items: center;
}
.clockActDivPart {
  font-weight: 500;
  margin-right: 3px;
  opacity: 0.75;
}

.clockActiveDividerTxtBrk {
  background-color: #5822bb;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  font-size: 12px;
  font-weight: 500;
  border-radius: 3px;
  padding: 2px 7px;
  border: 1px solid #021226;
  margin: 40px 10px;
  margin-bottom: -40px;
  /* min-width: 140px; */
  max-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clockedItemTimelineGPSrowBg {
  background-color: #e0ebf8;
  border: 1px solid #bbcada;
  border-top: none;
  color: #8ea7c5;
}

.colourGreyBlue {
  color: #143051;
}

.clockedItemBorderBottom {
  border-bottom: 1px solid #cbd7e6;
  margin-bottom: 2px !important;
}

.cancelClockCardModalBox {
  min-width: 280px;
  min-height: 180px;
  background-color: #f4f8fe;
  border-radius: 5px;
  z-index: 99999999;
  cursor: default;
  color: #143051;
  max-width: 270px;
  padding: 20px;
}

.cancelClockCardTxt {
  font-weight: 500;
  color: #143051;
  text-align: center;
  font-size: 14px;
}

.cancelClockCardModalBtnsDiv {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  max-width: 170px;
  margin: 20px auto;
  margin-bottom: 0px;
}

.cancelClockCardBtns1 {
  color: white;
  font-weight: 500;
  background-color: #1e88e5;
  border: #0469de 1px solid;
  min-width: 70px;
  border-radius: 5px;
  min-height: 35px;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.cancelClockCardBtns1:hover {
  background-color: #1374e4;
}

.cancelClockCardBtns2 {
  color: white;
  font-weight: 500;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  min-width: 70px;
  border-radius: 5px;
  min-height: 35px;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.cancelClockCardBtns2:hover {
  background-color: #bbcada;
}

.greyColour {
  color: #516b8b !important;
}

.gpsSupportBtnTopRight {
  background-color: #bbcada;
  color: white !important;
  border: 1px solid #bac5d3;
  /* float: right; */
  border-radius: 5px;
  padding: 1px;
  padding-left: 5px;
  padding-right: 4px;
  margin-left: 8px;
}

.gpsSettingsSupportPopup {
  max-width: 9px;
  margin-left: 2px;
}

.gpsSupportBtnTopRight:hover {
  background-color: #bac5d3;
}

.selectedActiveClockUnit {
  border: 2px solid #214978;
  margin-top: 6px;
  /* padding-bottom: 2px; */
  padding-top: 1px;
  margin-bottom: 2px;
  padding-left: 7px;
  padding-right: 9px;
  min-height: 97px;
}

.clockOnDurSub {
  color: #516b8b;
  border-top: 1px solid #bbcada;
  padding-top: 4px;
  margin-top: -5px;
  font-size: 10px;
}

.shoulder {
  min-width: 30px;
  min-height: 30px;
  background-color: #fff;
  position: fixed;
  top: 123px;
  left: 0px;
  z-index: 9999;
}

.shoulderIn {
  border: 2px solid #e6eaee;
  min-width: 30px;
  min-height: 30px;
  border-radius: 25px 0 0 0;
  border-bottom: none;
  border-right: none;
  background-color: #ebf0f7;
}

.rightShoulder {
  left: auto;
  right: 0px;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

.shoulderCenter {
  min-width: calc(100vw - 54px);
  min-height: 2px;
  background-color: #e6eaee;
  /* background-color: blue; */
  position: fixed;
  top: 123px;
  left: 30px;
}

.shoulder {
  position: fixed;
  top: 190px;
  /* min-height:50px; */
  /* min-width: calc(100vw + 4px); */
  width: calc(100vw + 4px);
  max-height: 10px !important;
  margin-left: -2px;
  background-color: none;
  z-index: 1;
  /* background-color: blue; */
}

.rotaSizeFont {
  font-size: 14px !important;
  min-width: 112px !important;
  padding-left: 0px !important;
  margin-right: 5px;
}

.rotaNavItem {
  min-width: 131px;
  max-width: 131px;
  margin-left: 14px;
}

.rotaNavBarHeading {
  padding-left: 24px !important;
  font-size: 10px;
  margin-bottom: 5px;
}

.rotaDesktopHeaderHolder {
  min-width: 160px !important;
  max-width: 160px !important;
}

.rotaDesktopClockOnDiv {
  min-width: 162px !important;
  max-width: 162px !important;
}

.desktopHeaderClockOnBtnRota {
  font-size: 12px;
  max-width: 55px !important;
  min-width: 92px !important;
  padding-right: 8px;
}

.marginLeft10 {
  margin-left: 10px !important;
}
.minusAppToil {
  font-size: 12px;
  max-height: 50px;
  line-height: 14px;
  margin-top: 10px;
  font-weight: 500;
  color: #859dbb;
  border-right: 2px solid #0077ff;
  padding-right: 8px;
  margin-bottom: 10px;
}

.minusAppToilSpan {
  color: #0077ff;
}

.selectAllBtn {
  margin-right: 8px;
}

.getSupportFooterBtn {
  /* background-color: #8dafd7; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8dafd7;
  font-weight: 500;
  /* border-radius: 5px; */
  /* border: 1px solid #6a9ad2; */
  max-height: 24px;
  min-height: 24px;
  font-size: 12px;
  /* padding-left: 5px; */
  /* padding-right: 5px; */
  cursor: pointer;
  margin-right: 8px;
  margin-top: -2px;
  border-bottom: 2px solid #fff;
}

.getSupportFooterBtn:hover {
  color: #6a9ad2;
  border-bottom: 2px solid #6a9ad2;
}

.backContactBtnImg {
  width: 30px;
  position: fixed;
  top: 20px;
  left: 20px;
  /* background-color: blue; */
  cursor: pointer;
  padding-right: 10px;
}

.backContactBtnImg:hover {
  opacity: 0.8;
}

.vhIssue {
  padding-bottom: 150px !important;
}

.mgrReqsVHissueSideBoxHeight {
  min-height: 479px !important;
  max-height: 479px !important;
  /* max-height: calc(100vh - 565px) !important; */
}

.x178141 {
  min-height: 436px !important;
  max-height: 436px !important;
}

.x49829752 {
  min-height: calc(100vh - 248px) !important;
  max-height: calc(100vh - 248px) !important;
}

.cusVh1 {
  max-height: calc(100vh - 620px) !important;
}

.cusVh2 {
  max-height: calc(100vh - 570px) !important;
}

.cusVh3 {
  max-height: calc(100vh - 670px) !important;
  max-height: calc(100vh - 6270px) !important;
  padding-bottom: 200px;
}

.cusVh4 {
  max-height: calc(100vh - 670px) !important;
  max-height: calc(100vh - 6270px) !important;
  padding-bottom: 240px;
}

.vhIssueModalMyAllows {
  /* max-height:800px !important; */
  /* min-height:800px !important; */
  margin-top: -50px !important;
  border-bottom: 1px solid #2b679f !important;
}

.vhIssueEditUser {
  /* background-color: blue !important; */
  min-height: 80vh !important;
  max-height: 80vh !important;
  min-width: 100vw !important;
  max-width: 100vw !important;
  bottom: 80px !important;
}

.viewPortHeight {
  background-color: blue;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
}

.closeUpcomingImg {
  min-width: 25px;
  padding: 5px;
  margin-top: 7px;
  margin-bottom: -7px;
  margin-right: 5px;
  cursor: pointer;
}

.addTeamModalBox {
  background-color: #ebf0f7;
  color: #143051;
  min-width: 340px;
  max-width: 340px;
  border-radius: 10px;
  padding-top: 0px;
  text-align: left;
  padding-left: 0px;
  padding-right: 0px;
  margin: 0 auto;
  max-height: 200px;
  cursor: auto;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  border: 1px solid #e7ebef;
}

.addTeamModalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 50px;
  min-height: 50px;
  max-width: 90%;
  margin-left: 5%;
}

.createTeamBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 35px;
  max-height: 35px;
  background-color: #0bf;
  border: 1px solid #0af;
  border-radius: 5px;
  cursor: pointer;
}

.createTeamBtn:hover {
  background-color: #0af;
}

.installMobBtn {
  background-color: #6c00ff;
  position: fixed;
  /* bottom: 114px;
  right: 40px; */
  bottom: 101px;
  right: 35px;
  max-width: 120px;
  min-width: 120px;
  max-height: 50px;
  display: flex;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #523266;
  /* box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 40%); */
  z-index: 9999;
}

.mobInstallBtnImg {
  max-width: 20px;
  min-width: 20px;
  min-height: 1px;
  margin-right: 3px;
  -webkit-animation: rotate 2s infinite;
          animation: rotate 2s infinite;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  50% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  100% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  50% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  100% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
}

.clockedOnF4Pulsate {
  background-color: #07f;
  border: #0469de 2px solid;
  -webkit-animation: f4pulsate 2s infinite;
          animation: f4pulsate 2s infinite;
}

@-webkit-keyframes f4pulsate {
  0% {
    /* opacity: 1 !important; */
    background-color: #07f;
    border: #0469de 2px solid;
  }
  50% {
    /* opacity: 0.5!important; */
    background-color: #5edea6;
    border: #23b843 2px solid;
  }
  100% {
    /* opacity: 1!important; */
    background-color: #07f;
    border: #0469de 2px solid;
  }
}

@keyframes f4pulsate {
  0% {
    /* opacity: 1 !important; */
    background-color: #07f;
    border: #0469de 2px solid;
  }
  50% {
    /* opacity: 0.5!important; */
    background-color: #5edea6;
    border: #23b843 2px solid;
  }
  100% {
    /* opacity: 1!important; */
    background-color: #07f;
    border: #0469de 2px solid;
  }
}

.workingPulsate {
  -webkit-animation: workingPulstate 2s infinite;
          animation: workingPulstate 2s infinite;
}
@-webkit-keyframes workingPulstate {
  0% {
    color: #5a914c;
  }
  50% {
    color: #8fc681;
  }
  100% {
    color: #5a914c;
  }
}
@keyframes workingPulstate {
  0% {
    color: #5a914c;
  }
  50% {
    color: #8fc681;
  }
  100% {
    color: #5a914c;
  }
}

.breakPulsate {
  -webkit-animation: breakPulsate 2s infinite;
          animation: breakPulsate 2s infinite;
}
@-webkit-keyframes breakPulsate {
  0% {
    color: #859dbb;
  }
  50% {
    color: #0af;
  }
  100% {
    color: #859dbb;
  }
}
@keyframes breakPulsate {
  0% {
    color: #859dbb;
  }
  50% {
    color: #0af;
  }
  100% {
    color: #859dbb;
  }
}

.clockedOnBrkF4Pulsate {
  background-color: #07f;
  border: #0469de 2px solid;
  -webkit-animation: f4pulsateBrk 2s infinite;
          animation: f4pulsateBrk 2s infinite;
}

@-webkit-keyframes f4pulsateBrk {
  0% {
    /* opacity: 1 !important; */
    background-color: #07f;
    border: #0469de 2px solid;
  }
  50% {
    background-color: #00ccff;
    border: #0fa7cc 2px solid;
  }
  100% {
    /* opacity: 1!important; */
    background-color: #07f;
    border: #0469de 2px solid;
  }
}

@keyframes f4pulsateBrk {
  0% {
    /* opacity: 1 !important; */
    background-color: #07f;
    border: #0469de 2px solid;
  }
  50% {
    background-color: #00ccff;
    border: #0fa7cc 2px solid;
  }
  100% {
    /* opacity: 1!important; */
    background-color: #07f;
    border: #0469de 2px solid;
  }
}

.tapToInstallParent {
  color: white;
  font-weight: 500;
  text-align: left;
  margin-top: 0px;
  margin-left: 4px;
  min-height: 50px;
}

.tapToInstall {
  font-size: 12px;
  margin-top: -2px;
  opacity: 0.75;
  /* margin-bottom:-1px; */
}

.tapToInstall2 {
  font-size: 14px;
  margin-top: -2px;
}

.notAppStoreLink {
  color: #859dbb;
  max-width: 290px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  margin-top: 15px;
  border-left: 3px solid #859dbb;
  padding-left: 10px;
}

.chromeInstallModalBox {
  /* background-color: orange !important; */
  min-height: 0px !important;
  padding-bottom: 3px;
  max-width: 170px;
  min-width: 380px !important;
}

.flexCenter {
  justify-content: center !important;
}

.x81811 {
  margin-left: -4px;
  margin-right: 8px;
}

.flexStart {
  justify-content: flex-start !important;
}

.x0873 {
  min-width: 75px;
  text-align: right;}

.sendMsgArrImg {
  max-width: 14px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);}

.mobMsgFooter {
  max-width: 90%;
  margin-left: 5%;
}

.activeClockBannerLeft {
  display: flex;
}

.activeClockBannerRight {
  background-color: #ffffff;
  min-width: 30px;
  min-height: 30px;
  border-radius: 0 0 5px 5px;
  border-bottom: 1px solid #d5e2f0;
  border-left: 1px solid #d5e2f0;
  border-right: 1px solid #d5e2f0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
}

.clockedGpsStatusImg {
  max-width: 20px;
}

.lastClockedDistance {
  font-size: 10px;
  font-weight: 500;
  margin-left: 2px;
  margin-top: 0px;
  color: #516b8b;
  margin-right: 2px;
}

.x183911 {
  /* background-color: yellow; */
  margin-top: 16px;
}

.x88349829 {
  margin-top: 14px;
  /* margin-bottom:4px; */
}

.x3891813 {
  /* background-color: orange; */
  min-height: 40px;
  margin-top: -15px;
}

.installAppImg {
  max-width: 60px;
  position: fixed;
  bottom: 120px;
  right: 35px;
  cursor: pointer;
}

.installCloudImg {
  max-width: 90%;
  margin-top: 10px;
  margin-bottom: 20px;
}

.dockInstructNum {
  font-weight: 500;
  margin-right: 15px;
}

.underlayInstall {
  background: rgba(0, 0, 0, 0.82);
  min-height: calc(100% - 58px);
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;

  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(2px);
  background-color: rgb(187 187 187 / 50%);
}

.addToHomeScreenBubble {
  max-width: 150px;
  margin-left: 8px;
  margin-right: 8px;
  background: white;
  min-width: 30px;
  padding: 3px;
  max-height: 30px;
  border-radius: 4px;
  border: 1px solid #e7e5ed;
  align-items: center;
  display: flex;
}

.addToHomeScreenTxt {
  font-size: 11px;
  font-weight: 500;
  min-width: 110px;
  color: #143051;
}

.addToHomeScreenTxt2 {
  font-size: 11px;
  font-weight: 500;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  color: #143051;
}

.aths {
  max-width: 12px;
  margin-left: 2px;
  margin-right: 2px;
}

.aths2 {
  margin-left: 3px;
  margin-right: 3px;
}

.fontSize16 {
  font-size: 16px !important;
}

.androidBub {
  max-width: 80px;
  padding-left: 5px;
  padding-right: 5px;
}

.x3841941 {
  max-width: 330px;
  min-width: 100vw !important;
  min-height:100vh !important;
  border-radius: 0px;

}

.clockedOnGpsRow {
  font-size: 12px;
  text-align: left;
  max-width: 90%;
  margin-left: 5%;
  opacity: 0.7;
  font-weight: 500;
  border-left: 2px solid #bbcada;
  padding-left: 8px;
  padding-right: 6px;
  color: white;
  /* color: #98aabd */
}

.x2913081 {
  flex-direction: row !important;
}

.teamTagsBubble {
  background-color: #00a9ff !important;
  margin-top: 90px;
  border-color: #0097e4 !important;
  cursor: pointer;
}

.teamTagsBubble:hover {
  background-color: #05a2f3 !important;
}
/* 
        .viewTeamEditTagsPencil {
          max-width: 20px;
          margin-top: -49px;
          margin-bottom: 28px;
          margin-left: 37px;        } */

.defaultCursor {
  cursor: default !important;
}

.tagsGoBack {
  display: flex;
  align-items: center;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  color: white;
  font-weight: 500;
  min-height: 30px;
  border-radius: 5px;
  padding-left: 6px;
  padding-right: 9px;
  font-size: 12px;
  cursor: pointer;
  margin-left: 14px;
  margin-bottom: 0px;
}

.tagsGoBack:hover {
  background-color: #bbcada;
}
.tagsBackImg {
  max-width: 11px;
}

.teamTagIemX {
  border-radius: 5px;
  margin-top: 8px;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  font-weight: 500;
  padding: 4px 9px;
  font-weight: 500;
  font-size: 14px;
  color: #4f566b;
  background-color: #f4f8fe;
  border: 1px solid #e6e6e6;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
}

.removeTeamTagImgX {
  min-width: 19px;
  margin-left: 3px;
  cursor: pointer;
  padding: 5px;
  margin-right: -4px;
}

.removeTeamTagImgX:hover {
  opacity: 0.7;
}

.x234895372 {
  margin-bottom: 6px !important;
  margin-right: 10px !important;
}

.tagsGoImg {
  max-width: 5px;
  margin-top: -46px;
  margin-bottom: 26px;
  margin-left: 47px;
}

.addTagInlineImgTeam {
  max-width: 12px;
  margin-left: 6px;
}

.addTagToTeamBtn {
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  max-width: -moz-fit-content;
  max-width: 100px;
  /* margin-left: 2%; */
  padding-left: 8px;
  padding-right: 8px;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #07f;
  border: #0469de 1px solid;
  margin-top: 20px;
}

.addTagToTeamBtn:hover {
  background-color: #0469de;
}

.clockFooterLeft {
  display: flex;
  align-items: center;
}

.clockTagsBtn {
  /* background-color: blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35px;
  background-color: #8ea7c5;
  border: 1px solid #6987a9;
  color: white;
  font-weight: 500;
  font-size: 14px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  margin-right: 10px;
}

.clockTagsBtn:hover {
  background-color: #849fbe;
}

.clockTagsIcon {
  max-width: 14px;
  margin-right: 4px;
}

.clockTagSelector {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 130px;
  min-width: 130px;
  line-height: 26px;
  height: 35px;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 2px solid #e6eaee;
  background-color: white;
  color: #8ea7c5;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 106px;
  /* margin-top:10px; */
}

.clockTagSelector:hover {
  border-color: #d7dfe9;
}

.x13145361 {
  margin-right: 8px;
}

.noAppliedTags {
  font-size: 14px;
  color: #bbcada;
  font-weight: 500;
  text-align: center;
  min-width: 100%;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;

  /* display: flex; */
}

.moreInfoAvailTagsBtn {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  color: white !important;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 4px auto;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}

.moreInfoAvailTagsBtn:hover {
  background-color: #bbcada;
}

.moreInfoTagsAvailPopupImg {
  max-width: 12px;
  margin-left: 2px;
}

.clockItemTagsContainer {
  display: flex;
  flex-wrap: wrap;
}

.clockCardTagSel {
  margin-top: 10px;
}

.upcomingActiveClocksSplitter {
  min-height: 1px;
  max-height: 1px;
  min-width: 90%;
  background-color: #bbcada;
  max-width: 90%;
  margin-left: 5%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: none;
}

.upcomingActiveClocksTitle {
  text-align: left;
  font-weight: 500;
  color: #143051;
  font-size: 14px;
  /* margin-left: 2%; */
  padding-bottom: 3px !important;
  margin-top: 18px;
  /* border-left: 2px solid #214978; */
  display: flex;
  align-items: center;
  padding-top: 3px;
  padding-left: 7px;
  margin-bottom: 6px;
}

.currClockOnTitle {
  max-width: 340px;
  color: #143051;
  line-height: 18px;
  font-size: 14px;
  padding-right: 20px;
  padding-left: 9px;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 0px;
  font-weight: 500;
  padding-bottom: 8px;
}

.upcomingActiveItem {
  background-color: white;
  max-width: 95%;
  min-width: 95%;
  margin: 0 auto;
  display: flex;
  border:1px solid #d5e2f0;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
  min-height: 64px;
  /* max-height: 64px; */
  margin-top: 8px;
  border-radius: 5px;
  cursor: pointer;
  /* animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important; */
  padding-right: 10px;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  padding-left: 8px;
  margin-bottom: 6px;
}

.marginTop12 {
  margin-top: 12px;
}

.upcomingActiveItem:hover {
  /* box-shadow: 0px 0px 3px #143051; */
  border-color:#bbcada
}

.upcomingActiveItemLeft {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 50%;
  /* background-color: yellow; */
  padding-left: 5px;
}

.upcStartStrip {
  font-size: 12px;
  color: #516b8b;
  font-weight: 400;
  text-align: left;
}

.upcNameTxt {
  color: #143051;
  font-weight: 500;
  font-size: 16px;
}

.dueToStartSpan {
  color: #516b8b;
  font-weight: 600;}

.clockRequiredStatus {
  color: #516b8b;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.upcomingActiveItemRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  min-width: 50%;
  /* background-color: lime; */
  padding-left: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right:2px !important
}

.x9742152 {
  /* opacity: 0.4; */
}

.clockOnReqStopwatchImg {

  max-width: 12px;
  margin-left: 4px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.actClocksTags {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 10px;
}

.actClockTagItem {
  /* background-color: #e3e8ee; */
  border: 1px solid #dce7f3;
  color: #cbd7e6;
  font-size: 12px;
  margin-bottom: 4px;
  margin-left: 4px;
  text-align: right;
  padding: 2px;
  padding-left: 3px;
  padding-right: 5px;
  border-radius: 4px;
  font-weight: 500;
}

.highlightClockActiveItem {
  /* border-left: 2px dashed #143051; */
  border-left: 4px solid #ba0000;

  border-radius: 5px;
}

.highlightClockActiveWorkingNow {
  border-left: 4px dashed #7fbb67;
  border-radius: 0px 5px 5px 0px;
}

.highlightClockActiveWorkingNow2 {
  border-left: 4px solid #7fbb67;
  border-radius: 0px 5px 5px 0px;
}

.highlightClockActiveWorkingNow2:hover{
  border-left: 4px solid #7fbb67 !important;
}

.activeClocksSection {
  background-color: #f4f8fe;
  border: 1px solid #bfccdc;
  min-width: 100% !important;
  border-radius: 5px;
  padding-bottom: 15px;
}

.x10841085 {
  background-color: white;
  border: none;
  border-bottom: 1px solid #bfccdc;
  border-radius: 0;
}

.x081284 {
  margin-top: 4px;
  font-size: 12px;
  color: #516b8b !important;
  margin-bottom: -4px;
  text-align: right;
  font-weight: 500;
}

.msgBackBtnDiv {
  background-color: #214978;
  border: 1px solid #143051;
  color: white;
  background-color: #214978;
  border: 1px solid #143051;
  color: white;
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  padding-right: 8px;
}

.highlightClockActiveItem:hover {
  border-left:4px solid #ba0000
}
.desktopBurgerIcon {
  border: 1px solid #e0ebf8;
  background-color: white;
  padding: 2px;
  min-width: 35px;
  min-height: 35px;
  max-width: 35px;
  max-height: 35px;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  margin-left: 40px;
  /* margin-left:2px; */
}

.desktopBurgerIconRota {
  margin-left: 20px;
}

.x248921 {
  min-width: 70px !important;
  max-width: 76px !important;
  padding-right: 10px;
}

.x1308428411 {
  min-width: 80px !important;
  max-width: 100px !important;
}

.x1308428e411 {
  min-width: 100px !important;
  max-width: 100px !important;
  /* font-size:12px; */
}

.desktopMenuFloater {
  /* border: 1px solid #bbcada; */
  border-radius: 5px;
  position: fixed;
  top: 127px;
  left: calc(50% - 535px);
  min-width: 200px;
  min-height: 654px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 5px;
  padding-bottom: 5px;
  -webkit-animation: slideDesktopActionMenuDown 0.185s forwards;
          animation: slideDesktopActionMenuDown 0.185s forwards;
}

@media only screen and (max-width: 1140px) {
  .desktopMenuFloater {
    left: 35px;
  }
}

.desktopMenuFloaterRota {
  left: 15px;
}

@-webkit-keyframes slideDesktopActionMenuDown {
  0% {
    min-height: 0;
    max-height: 0;
  }
  100% {
    min-height: 654px; /* Adjust this value to control the final height of the rectangle */
    max-height: 654px; /* Adjust this value to control the final height of the rectangle */
  }
}

@keyframes slideDesktopActionMenuDown {
  0% {
    min-height: 0;
    max-height: 0;
  }
  100% {
    min-height: 654px; /* Adjust this value to control the final height of the rectangle */
    max-height: 654px; /* Adjust this value to control the final height of the rectangle */
  }
}

/* @keyframes slideDesktopMenuDown {
    from {  
  
      min-height: 0px;
      max-height: 0px;
    }
    to {
      min-height:550px;
      max-height:550px;
    
    }
  } */

.closeDesktopBurer {
  cursor: pointer;
  max-width: 35px;
  min-width: 35px;
  padding: 11px;
  background-color: #fff;
  border: 1px solid #bbcada;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  margin-top: -634px;
}

.desktopBurgerMenu {
  background-color: #fff;
  min-width: 170px;
  min-height: 601px;
  max-height: 601px;
  border: 1px solid #bbcada;
  border-radius: 0 5px 5px 5px;
  z-index: -1;
  margin-top: 33px;
  overflow: hidden;
}


.mobBurgerMenu {
  /* background-color: #fff; */
  /* min-width: 170px; */
  min-height: 668px;
  max-height: 668px;

  border: 1px solid #bbcada;
  border-radius: 0 5px 5px 5px;
  z-index: -1;
  margin-top: 33px;
  /* min-height:512px;
  max-height:512px; */
  overflow: hidden;
  /* -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */

  /* margin-bottom:-1px */
}

.desktopBurgerTitle {
  color: #bbcada;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  margin-top: 12px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.desktopBurgerItem {
  /* border-left: 2px solid #214978; */
  max-width: 160px;
  display: flex;
  padding-left: 2px;
  padding-right: 4px;
  border-radius: 5px;
  margin-left: 7px;
  text-align: left;
  font-size: 12px;
  margin-top: 5px;
  align-items: center;
  min-height: 24px;
  color: #214978 !important;
  font-weight: 500;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
}

.desktopBurgerItem:hover {
  background-color: #ebf0f7;
}

.desktopBurgerItemBlob {
  min-width: 3px;
  background-color: #0077ff;
  min-height: 20px;
  border-radius: 5px;
  margin-right: 6px;
  margin-left: 3px;
}

.x239814 {
  background-color: #a58fd4;
}

.teamTagsInfoTxt {
  font-size: 12px;
  color: #516b8b;
  font-weight: 400;
  max-width: 90%;
  margin-top: 16px;
  margin-left: 14px;
  line-height: 16px;
}

.x1841984 {
  border-left: 4px solid #00ccff;
}

.showDesktopMenuUnderlay {
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  background-color: rgb(119 119 119 / 27%);
  min-width: 100vw;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.breakPulsateTick {
  border-right: 2px solid #214978;
  padding-right: 5px;
  -webkit-animation: brkClockPulsate 1s infinite;
          animation: brkClockPulsate 1s infinite;
  color: #0077ff;
}

@-webkit-keyframes brkClockPulsate {
  0% {
    border-right: 2px solid #fff;
  }
  50% {
    border-right: 2px solid #214978;
  }
  100% {
    border-right: 2px solid #fff;
  }
}

@keyframes brkClockPulsate {
  0% {
    border-right: 2px solid #fff;
  }
  50% {
    border-right: 2px solid #214978;
  }
  100% {
    border-right: 2px solid #fff;
  }
}

.profAdminNavBar {
  background-color: #214978 !important;
  color: #ebf1f8 !important;
  border-color: #143051 !important;
}

.accBtnSel {
  font-weight: 500;
  color: white;
  cursor: pointer;
}

.innerd {
  height: 100%;
  /* background-color: blue; */
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 2px;
  /* min-width: 25vw; */
}

.accountNavBtn {
  margin-top: 12px;
  font-size: 13px;
  cursor: pointer;
  font-weight: 500;
}

.inactiveAccBtn {
  opacity: 0.7;
}

.selectPoint {
  /* margin-top: 2px; */
  /* margin-top: -6px; */
  /* display: none; */
  margin-bottom: -1px;
}

.invis {
  visibility: hidden;
}

.none {
  display: none;
}

.activeClockOnTitle {
  font-size: 10px;
  font-weight: 500;
  color: #bbcada;
  margin-bottom: -10px;
}

.activeClockedDurMins {
  font-size: 10px;
  font-weight: 500;
  color: #bbcada;
  margin-right: 5px;
}

.invisImg {
  max-width: 0px !important;
  min-width: 0px !important;
  position: fixed;
  top: -100px;
  left: -100px;
  visibility: hidden;
}

.noMsgsYet {
  opacity: 0.6;
}

.awaitingSwapTxt {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  line-height: 24px;
  align-items: center;
  border-radius: 5px;
  color: #496375;
  font-weight: 500;
  font-size: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  background: rgb(247, 250, 255);
  background-color: #f4f8fe;
  color: #4f566b;
  border: 1px solid #bbcada;
  border-left: 3px solid #bbcada;
  margin-top: 10px;
}

.editClockedImgWh {
  max-width: 35px;
  margin-left: 2px;
  /* border: 1px solid white; */
  border-radius: 3px;
  min-width: 22px;
  padding: 6px;
  cursor: pointer;
  margin-top: 2px;
}

.editClockedImgWhDesktop:hover {
  background-color: #e3e8ee;
}

.saveEditClockTimeBtn {
  background-color: #1e88e5 !important;
  border: #0469de 1px solid !important;
  cursor: pointer;
  border-radius: 5px;
  min-height: 32px;
  margin-left: 5px;
  /* margin-top: 3px; */
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.saveEditClockTimeBtn:hover {
  background-color: #1f78de !important;
}

.saveActiveClockEditPen {
  max-width: 15px;
}

.activeClockInputEdit {
  background-color: #fff;
  width: 77px;
  max-width: 77px;
  line-height: 30px;
  padding-top: 0px;
  height: 35px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 16px;
  font-weight: 500;
  /* -webkit-appearance: none; */
  /* -moz-appearance: none; */
  /* appearance: none; */
  cursor: text;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top:2p */
}

.activeGpsLowerLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.clDurSpan {
  border-top: 1px solid #dde9f9;
  color: #214978 !important;
  margin-top: -4px !important;
  padding-top: 5px;
  margin-bottom: 13px;
}

.x389213 {
  border-top: 1px solid #bfccdc;
  padding-top: 10px;
}

.imagesHidden {
  visibility: hidden;
  position: fixed !important;
  top: 0px;
  max-width: 0px;
}

.shiftLoadingBox {
  min-width: 200px;
  /* max-width: 140px; */

  background-color: #ebf0f7;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  /* box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 16%); */
}

.shiftLoadingBoxDesktop {
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.shiftLoadingBoxMob {
  position: fixed;
  bottom: 0px;
  min-width: 100vw;
  border-radius: 15px 15px 0 0;
  min-height: 100px;
  -webkit-animation-name: slideLoaderUp;
          animation-name: slideLoaderUp;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
}

.shiftLoadingBallsImgMob {
  min-width: 160px;
}

@-webkit-keyframes slideLoaderUp {
  from {
    max-height: 0px;
    min-height: 0px;

    opacity: 0;
    /* transform: scale(0.4); */
    z-index: -1;
  }
  to {
    max-height: 100px;
    min-height: 100px;

    opacity: 1;
    /* transform: scale(1); */
    z-index: -1;
  }
}

@keyframes slideLoaderUp {
  from {
    max-height: 0px;
    min-height: 0px;

    opacity: 0;
    /* transform: scale(0.4); */
    z-index: -1;
  }
  to {
    max-height: 100px;
    min-height: 100px;

    opacity: 1;
    /* transform: scale(1); */
    z-index: -1;
  }
}

.x090910923091 {
  background-color: orange;
  color: white;
  max-height: 30px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999999999;
}

.mgrModal {
  background-color: #fdfaff !important;
}

.x198492823 {
  margin-right: 10px;
}

.x239849272 {
  color: #214978;
}

.unreadNotifQty {
  margin-top: -30px;
  font-size: 10px;
  margin-left: 27px;
  color: #fff;
  position: absolute;
  background: red;
  min-width: 17px;
  max-width: 17px;
  min-height: 17px;
  max-height: 17px;
  border-radius: 9px;
  padding-top: 2px;
}

.unreadNotifBtn {
  background-color: #0077ff;
  border-color: #075dbf;
  /* animation: PulsateNotif 1s linear infinite; */
  /* max-width: 30px; */
}
.unreadNotifBtn:hover {
  background-color: #2e8dff;
  border-color: #3d82d3;
}
@-webkit-keyframes PulsateNotif {
  0% {
    background-color: #609ee5;
  }
  50% {
    /* background-color: #95acc5; */
  }
  100% {
    background-color: #609ee5;
  }
}
@keyframes PulsateNotif {
  0% {
    background-color: #609ee5;
  }
  50% {
    /* background-color: #95acc5; */
  }
  100% {
    background-color: #609ee5;
  }
}

.notifItem {
  border: 2px solid #cbd7e6;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-width: 94%;
  border-radius: 5px;
  margin-left: 3%;
  background-color: #143051;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #bedaff;
  padding-right: 5px;
  padding-top:10px;
  padding-bottom:10px;
  border-color:white
}

.notifItem:last-of-type {
  /* border-bottom:none !important */
}
.zoomNotifIn {
  /* animation: zoomInNotif 0.1s ease-in-out; Adjust the duration and timing function as needed */

}

@-webkit-keyframes zoomInNotif {
  from {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes zoomInNotif {
  from {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}


.notifLinkable {
  cursor: pointer;
}


.notifLinkable:hover {
  /* background-color: #244061; */
}

.hoveredNotifHovered {
  background-color: #244061 !important;
}

.notifBold {
  font-weight: 500;
  /* white-space: nowrap; */

}

.notifBoldMsg {
  color:#00ccff
}

.notifImg {
  border: 2px solid #143051;
  max-width: 50px;
  border-radius: 5px;
  margin-left: 4px;
  min-height: 50px;
  object-fit: cover;
  max-height: 50px;
  min-width: 40px;
  max-width: 40px;
}

.notifImgMgr {
  border-color:#decdff
}

.notifTxt {
line-height:17px;
min-width: 100%;
max-width:100%;
/* padding-right:15px; */

}

.notifTxtMob {
  font-size:14px
}

.notifMid {
  text-align: left;
  font-size: 12px;
  padding-left: 10px;
  padding-right: 15px;
  /* min-width: 225px;
  max-width: 225px; */
  /* min-width: 233px; */
  min-width: 70%;
  max-width: 70%;
  min-height:52px;
  display: flex;
  align-items: center;
}

/* .notifMid1 {
  min-width: 255px;
  max-width: 255px;
}

.notifMid2 {
  min-width: 305px;
  max-width: 305px;
} */


.notifStatus {
  font-size: 10px;
  text-align: right;
  /* min-width: 80px;
  max-width: 80px; */
  margin-right: 15px;
  color: #bbcada;
  min-width:38px;
  max-width: 38px;
}

.notifIsRead {
  background: none;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid;
  opacity: 0.8;
  padding-top: 15px;
  padding-left: 0px;
  padding-bottom: 15px;
}

.notifIsRead:last-child {border:none}

.notifIsRead:hover {
  opacity: 1;
}

.notifBlob {
  min-width: 5px;
  min-height: 40px;
  background-color: #8ea5c0;
  border-radius: 5px;
  /* animation-delay: 0.2s; */
  /* animation: notifsFadeIn 0.1s ease-in-out; Adjust the duration and timing function as needed */

}

.notifBlobApproved {
  background-color: #82c26a;
}

.notifBlobDeclined {
  background-color: #f63b3b;
}
.notifHead {
  /* background-color: yellow; */
  margin-top:-4px;
}

.mgrNotifKey {
  height: 44px;
  margin-top: -4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #516b8b;
  max-width: 94%;
  margin-left: 3%;
}

.mgrNotifKeyTitle {
  color: white;
  font-weight: 500;
  font-size: 16px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  margin-top: 0px;
}

.x314565342 {
  margin-top: -7px;
  margin-right: 7px;
  margin-left:-5px;
}

.x992910011 {
  margin-left:-0px;
}

.mgrNotifKeyTitleMob {
  font-size: 18px;
  margin-bottom: 7px;
}

.mgrNotifKeyRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mgrNotifKeyTxt {
  font-size: 12px;
  color: #fff;
  border: solid 1px #45607f;
  background-color: #143051;
  font-weight: 500;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
  max-height: 22px;
  line-height: 22px;
  border-radius: 5px;
  min-height: 22px;
  /* margin-right: 5%; */
  /* max-width: 100px; */
  text-align: center;
  /* margin-top: 18px; */
  /* display: flex; */
  /* align-items: center; */
  margin-left: 4px;
}

.notifKeyAsTxt {
  font-size:8px;
  margin-bottom:-12px;
  margin-top:-4px;
  text-align: left;
  opacity: 0.65;
}

.mgrNotifkeyImg {
  max-width: 12px;
  margin-left: 6px;
  margin-top: -6px;
}

.mgrNotifkeyImgMob {
  /* margin-top:0px */
}

.mobNotifBox {
  background-color: #214978;
  border: #143951 1px solid;
  position: fixed;
  bottom: 0;
  left: 0;
  min-height: 96vh;
  min-width: 100vw;
  max-width: 100vw;
  border-radius: 20px 20px 0 0;
}

.closeNotifImg {
  max-width: 15px;
  margin-top: 2px;
  margin-right: 14px;
  min-width: 16px;
  margin-left: 3px;
}

.mgrNotifKeyMob {
  min-height: 50px;
  align-items: center;
  justify-content: space-between;
  padding-top: 4px;
  padding-right: 3px;
  padding-left: 2px;
  /* padding-bottom:4px */
}

.x39248231 {
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.x98248423 {
  min-width: 37px;
  max-width: 37px;
  min-height: 34px;
  max-height: 34px;
  opacity: 0.2;
  position: fixed;
  top: 66px;
  left: 90px;
  cursor: pointer;
}

.x09482834 {
  left: 68px;
}

.x49849357843 {
  min-width: 68px;
  max-width: 68px;
  min-height: 34px;
  max-height: 34px;
  opacity: 0.2;
  position: fixed;
  top: 66px;
  left: 139px;
  cursor: pointer;
}

.x84746265233 {
  min-width: 38px;
  max-width: 38px;
  left: 114px;
}

.x239829833 {
  min-width: 37px;
  max-width: 37px;
  min-height: 34px;
  max-height: 34px;
  opacity: 0.2;
  position: fixed;
  top: 66px;
  left: 40px;
  cursor: pointer;
}

.x848887423 {
  left: 20px;
  min-height: 38px;
}

.x9375877823 {
  min-width: 38px;
  max-width: 38px;
  min-height: 34px;
  max-height: 34px;
  opacity: 0.2;
  position: fixed;
  top: 66px;
  left: 114px;
  cursor: pointer;
}

.x8478268642323 {
  min-width: 37px;
  max-width: 37px;
  min-height: 37px;
  max-height: 37px;
  opacity: 0.2;
  position: fixed;
  top: 66px;
  left: 40px;
  cursor: pointer;
}

.x848887423 {
  left: 20px;
  min-height: 38px;
}

.x32982874423 {
  min-width: 37px;
  max-width: 37px;
  min-height: 37px;
  max-height: 37px;
  opacity: 0.2;
  position: fixed;
  top: 66px;
  left: 90px;
  cursor: pointer;
  z-index: 999999999;
}

.x94829873233 {
  left: 68px;
}

.x12345 {
  background-color: orange;
  color: white;
  max-height: 30px;
  position: fixed;
  top: 0;
  right: 45px;
  z-index: 9999999999;
}

.x54321 {
  background-color: pink;
  color: white;
  max-height: 30px;
  position: fixed;
  top: 0;
  right: 90px;
  z-index: 9999999999;
}

.nonMobModalClockContentBox {
  padding-bottom: 10px;
}

.minHeight170 {
  min-height: 150px;
}

.mobBreak1FinishedNoSchedStartContentHeight {
  min-height: 477px !important;
  max-height: 477px !important;
}

.notifWrapper {
  /* background-color: orange; */
  min-height: calc(96vh - 102px);
  max-height: calc(96vh - 102px);
padding-bottom:5px;
  overflow-y: auto;
}

.notifFooter {
  min-height: 50px;
  min-width: 94%;
  max-width: 94%;
  /* padding-left:5px; */
  margin-left: 2%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #516b8b;
}

.notifWrapperDesktop {
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-height: calc(100vh - 206px);
}

.x13984927123 {
  background-color: #143051;
  border-color: #061f3c;
}

.x13984927123:hover {
  background-color: #143051;
}
.mobMgrNotifKey {
  min-height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  padding-top:2px;
}

.desktopMgrNotifKey {
  min-height: 30px;
  padding-left: 7px;
  padding-right: 7px;
  font-size: 12px;
}
.notifMobTitleTxt {
  font-size: 22px;
  color: white;
  font-weight: 500;
}

.vhIssueNotifWrapper {
  min-height: 448px;
  max-height: 448px;
}

.vhIssueNotifBox {
  min-height: 550px;
  max-height: 550px;
  /* background-color: yellow; */
  border-radius: 20px;
  bottom: 100px;
}



.x889988 {
  margin-top: 4px;
  margin-bottom: 0px;
}

.x9183812 {
  background-color: #cbd7e6;
  border: 1px solid #bbcada;
  padding-left:15px;
  padding-right:15px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-top:15px;
  margin-left:5%
}


.x9183812:hover {
  background-color: #cbd7e6;
}

.x233245211 {
  margin-left:10px
}

.shActiveDiv {
  border-right: 2px solid #bbcada;
  /* background: white; */
  max-width: 220px;
  /* border-radius: 5px; */
  float: right;
  padding-right: 14px;
  /* padding-left: 10px; */
  max-height: 80px;
  line-height: 24px;
  min-width: 190px;
  margin-right: 14px;
  margin-top: -27px;
  margin-bottom: 10px;
  text-align: right;
  cursor: pointer;
}

.shActiveDivTxt {
  font-size:12px;
  font-weight:500
}

.z728731 {

  font-size: 11px;
  color: #49b63e;
  line-height: 15px;
}


  .x8283242312111 {
margin-top:0px  }

.resolIcon {
  margin-left: 8px;
  margin-right: 8px;
  background: white;
  min-width: 24px;
  max-width: 24px;

  padding: 3px;
  max-height: 30px;
  border-radius: 4px;
  border: 1px solid #e7e5ed;
  align-items: center;
  display: flex;
  color: black;
  font-weight: 500;
  /* padding-left: 5px; */
  max-height: 24px;
  /* padding-top: 4px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.resWarnInfo {
  color: #214978;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  max-width: 90%;
  margin-left: 5%;
  margin-top: 10px;
  margin-bottom: 40px;
  border-left: 2px solid #bbcada;
  padding-left: 14px;
  padding-right: 30px;}

  .x32892984221 {
    margin-top:30px;
  }

  .resInsturctTxt {
    color: #214978;
    /* display: flex; */
    /* align-items: center; */
    font-size: 14px;
    min-width: 80%;
    max-width: 80%; 
    margin-bottom: 7px;
    text-align: left;
    line-height:18px;
  }

  .greyThreeDotsResImg {
    background-color: white;
    border-radius:4px;
    border:1px solid #bbcada;
    padding:4px;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    max-width: 22px;
    margin-bottom:-5px;
    margin-left:5px;
    margin-right:5px;
    min-height:22px

  }

  .noRotate {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  .displayFlex {
    display: flex;
    align-items: center;
  }

  .iosZoomBar {
    border: 2px solid #f2f2f2;
    border-radius: 10px;
    max-width: 240px;
    margin-top: 10px;  }

    .notifSubTxt {
      display: flex;
      /* margin-left:-5px; */
      margin-top:2px;
      font-size:10px;
      font-weight: 500;

    }

    .notifSubTxtBubble {
      border: 1px solid #011934;
      border-radius: 5px;
      padding-left: 4px;
      padding-right: 4px;
      /* margin-right: 5px; */
      /* margin-left: 5px; */
      background-color: #516b8b;
white-space: nowrap;
font-weight:600;
max-height: 20px;
font-size:12px;
    }
    .deleteNotifImg {
      max-width: 24px;
      padding-left:4px;
      padding-right:4px;
      /* background-color: yellow; */
      margin-top:10px;
      margin-bottom:3px;
      cursor: pointer;

    }

   
    .deleteNotifImgDesktop:hover {
      background-color: #2d5a8f;
      border-radius:3px;
      /* border:1px solid #bbcada; */
    }

    .x883811ex {
      min-width: 38px;
      padding-top:5px;
      padding-bottom:5px;
      border-radius:4px;
      /* background-color: yellow; */
      /* padding-left:4px; */
      /* padding-right:2px; */
      margin-top:1px;
margin-right:12px;
margin-left:3px;
padding-right:3px;
padding-left:4px;
padding:10px  !important

    }

    .mobNotifHolder {
      margin-top: -3px;
      margin-left: 0px;    }
    
    .mobNotifIconSep {
      min-width: 42px !important;
      margin-left: 3px !important;
      margin-top: 6px !important;
      margin-bottom: 0px;
      padding: 9px !important;
    }


    .mobUnreadNotifIcon {
      /* font-size: 8px;
      font-weight: 500;
      margin-top: -34px;
      background: #0077ff;
      border-radius: 25px;
      height: 15px;
      width: 15px;
      position: relative;
      z-index: 999;
      margin-left: 22px; */
      /* display: flex; */
      /* align-items: center; */
      /* justify-content: center; */
      /* color: white; */
      /* border: 1px solid #3f6aac; */

      background-color: #ef5353;
      border: 1px solid #ba0000;
      color: white;
      font-weight: 500;
      min-width: 17px;
      min-height: 17px;
      border-radius: 3px;
      max-width: 17px;
      max-height: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      padding-bottom: 1px;
      margin-top:-36px;
      margin-left:23px;
      position: relative;
      z-index:9999
    }


    .xi28381 {
      font-size:6px
    }

    .viewDayBurger {
      display: flex;
      flex-direction: column;
      margin-bottom:1px;
      align-items: center;
      justify-content: center;  }

    .viewDayBurgerLine {
      background-color: white;
      min-height: 2px;
      min-width: 16px;
      max-width: 9px;
      max-height: 2px;
      margin-top: 2px;
      margin-bottom: 2px;
      border-radius: 4px;
    }

    .notificationsBoxDesktop {
      max-height: calc(100vh - 160px) !important   }

      .pushNoteSpan {
        color: #516b8b;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        /* margin-bottom:10px; */
      }

      .sendPushNotifBtnMgs {
        margin: 20px auto;
        padding-right:0px;
      }

      .xb192381 {
        min-width: 100%;
        margin-left: 0px; 
      margin-top:20px
      }


        .xc209233 {
          margin-left:2px;
          background-color: #07f;
        }

        .redBg {
          background-color: #e57676;
        }

        .x238929j {
          max-width: 7px;
        }

      

        .mobHomeBtnQty {
          background-color: #b49ce7;
          color: white;
          position: absolute;
          margin-top: -32px;
          margin-left: 25px;
          border-radius: 3px;
          font-weight: 500;
          font-size: 10px;
          padding: 2px;
          border: 1px solid #a47fd0;
          width: 18px;
          height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .mobHomeBtnQtyFontSmall {
          font-size:8px
        }

.notifTag {
  background-color: #8ea7c5;
  border: 1px solid #214978;
  border-radius: 4px;
  font-size:12px;
  padding: 1px;
  padding-left: 4px;
  padding-right: 4px;}


  .x298497113 {
    margin-top:15px !important
  }

  .x92499101042:hover {
    background-color: #305f96 !important;
  }

  .noNotifs {
    color: white;
    font-weight: 500;
    font-size: 14px;
    margin-top: 30px;
  opacity: 0.4;  }


    .swapNotifImg {
      max-width: 12px;
      min-width: 12px;
      margin-right: 12px;    }

      .notifHowever {
        /* color:#ffb400 */
      }

      .extSuppImg {
        margin-left: 0px;
        max-width: 11px;
        margin-bottom: -2px;
        margin-top: 2px;
         }

       .msgBorderTopScrolled {
        border-top:1px solid #e0ebf8
       }
       .x2f34222 {
        /* bottom: 15px !important; */
    }

 
    .clearNotifs {
      /* background-color: blue; */
      color:white;
      font-size:12px;
      font-weight:500;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: 50px auto;
    /* border:1px solid white; */
    height:24px;
    display: flex;
    align-items: center;
    padding-left:5px;
    padding-right:5px;
    border-radius:5px;
    opacity: 0.5;
    cursor: pointer;
    }

    .clearNotifs:hover {
      opacity: 1;
    }

    .notifBottomBar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top:-20px;
    }

    .x920901ss {
      /* background-color: blue; */
      min-width:30%;
    }

    .loadMoreNotifsHolder {
      min-width: 33%;
    }

    .desktopHeaderSubBtnSelected {
      font-weight: 500;
      color: #07f !important;
      cursor: none;
      pointer-events: none;
      border-left: 2px solid #07f;
      padding-left: 6px;
      margin-left: 17px;
    }
    

    .x298429712s {
      margin-bottom:-12px  !important
    }

    .x298429712sss {
      margin-bottom:-9px  !important

    }

    .x394304023d {
      margin-bottom: -22px !important
    }

    .breakOnToNewLine {
white-space: wrap;    }

.indicateSmall {
  font-size:14px;
  padding-left:10px;
  padding-right:10px;
}

.x32984197411 {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 2px;
  padding-right: 2px;
margin-left:5%;
}
  /* margin: 0 auto;} */


  .notifTxtJsx2 {
    /* background-color: YELLOW; */
    /* min-height:100px; */
  }

  .addShiftNotifJsx {
    display: flex;
    margin-left: 0px;
    margin-top: 8px;
    background: #516b8b;
    border-radius: 5px;
    padding: 0px;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    padding: 3px;
  }

  .addShJsxDate {
    font-size: 10px;
    margin-left:4px;
    margin-right: 4px;  }


    .needToVerifNotifBox {
      background-color: #4784ae;
      border:1px solid #bbcada;
      border-radius:7px;
      width: 90%;
      margin-top:10px;
      margin-left:5%;
      margin-bottom:10px;
      min-height:60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .notifVerifTxt {
      color:white;
      text-align: left;
      font-weight:500;
      font-size:14px;
      /* margin-top:12px; */
      margin-left:10px;
    }

    .notifVerifTxtSub {
      color:white;
      text-align: left;
      font-weight:500;
      font-size:12px;
      opacity: 0.6;
      /* margin-top:8px; */
      margin-left:10px;
    }

    .verifNotifBtn {
      color:white;
      background-color: #214978;
      border:1px solid #143051;
      border-radius:5px;
      padding-left:10px;
      padding-right:10px;

      margin-right:10px;
      font-size:14px;
      font-weight:500;
      min-height:35px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .verifNotifBtn:hover {
      background-color: #143051;
    }

    .notifKeyDiv {
      display: flex;
      color:#81b3ee
    }

    .notifIsMgr {
      /* background-color: #4b3589; */
      color:#e4c5f9 !important;
      border-color:white;
    }

      .notifLinkableMgr:hover {
        /* background-color: #563d9c  !important; */
      }

      .mgrNotifDeleteHover:hover {
        /* background-color: #4b3589  !important; */
        cursor: pointer;

      }

      .asAMgr {
        color:#b39def
      }


      .swapWithTitle {
        font-size:15px;
      }

      .x29849283211 {
        font-size: 14px;
        font-weight: 400;
        padding-left: 3px;
        padding-right: 20px;
        line-height: 16px;
      }



      .x2984298291111 {
        min-width: 117px;
        background-color: #a387df;
        border: 1px solid #5f4d85;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding-left: 5px;
        padding-right: 5px;
      }

      .fontSize12Notif {
        font-size:12px  !important;
        line-height:14px !important
      }
      .aa1010:hover {
        background-color: #9b7fd6;
      }

      .x892 {
        background-color: #6f41cc;
        border: #532aa4 1px solid;
        padding-right:4px;
      }

      .x109100001 {
        width: 10px;
        margin-right:0px;
      }

      .swapsQtySpanNotif {
font-weight:600      }

.x1091091a {
  min-height:74px
}

.swapNotifBlob {
  min-width: 4px;
  min-height: 40px;
  background-color: #cfb8fd;
  border-radius: 5px;
  margin-left: 10px;
}

.histCheck {
  margin-right: -1px;
  margin-left: 8px;
  max-width: 14px;
}

.histSwapBtn {
  padding-left:9px !important;
  padding-right:10px !important
}

.showHistTxt {
  font-size: 12px;
  font-weight: 500;
  color: #bbcada;
  margin-top: 7px;
  margin-bottom: 5px;
  text-align: left;
margin-left:10px;
}

.histSwap {
  border-bottom:1px solid #dde9f9;
  max-width: 96%;
  margin-left:2%;
  padding-bottom:20px;
}
.histSwapTxt {
  color:#516b8b;
  font-size:12px;
  text-align: left;
  max-width: 90%;
  margin-left:10px;
  margin-top:20px;
  border-left:2px solid #bbcada;
  padding-left:10px;
  line-height:16px

}

.histSub {
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  margin-left: 22px;
  margin-top: 5px;}

  .histTeamName {
    color:#bbcada;
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    margin-left: 22px;
    margin-top: 5px;
  }

  .swapHistoryDs {
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    margin-left: 22px;
    margin-top: 5px;
    color: #bbcada
  }

  .mySwapsFooterLeft {
    display: flex;
    justify-content: space-between;
    min-width: 200px;
    /* background-color: lime; */
    min-width: 253px;
  }
  .x10s00 {
    margin-left:0px !important

  }

  .userSwapsBox {
    background-color: #0af;
    border-color: #143051  }

    .x272721 {
      background-color: #8ad7fd;
    }

    .x373718111 {
      background-color: #0077ff;
      border: 1px solid #0046ff;    }
      .x828288111:hover {
        background-color: #0771eb;
      }

      .nonAdminInnerd {
        min-width: 33vw;
      }

      .unReadNotifMgr {
        background-color: #553555;
      }

      .unReadNotifMgr:hover {
        background-color: #5c3a5c !important;
      }

      .installPWAinfo {
        /* font-size: 14px;
        color: #214978;
        text-align: left;
        margin-bottom: 30px;
        border-left:2px solid #214978;
        padding-left:15px;
        max-width: 90%;
        line-height: 19px;
        font-weight: 500;   */
      
        font-size: 14px;
        color: #516b8b;
        text-align: left;
        margin-bottom: 10px;
        border-left: 2px solid #214978;
        padding-left: 15px;
        max-width: 90%;
        margin-top:10px;
        margin-bottom:30px;
        line-height: 18px;
        font-weight: 400;}

        .unclockCal {
          opacity: 0.3;
          max-width: 10px;
          margin-right: 7px;        }

          .unclockCal1{
            margin-bottom:2px;
          }


          .adminTopRight {
            background-color: blue;
            position: fixed;
            top:0;right:0;
            margin-top:2px;
            margin-right:5px;
           
            min-height:24px;
            z-index: 999;

            background-color: #214978;
            border: 1px #143051 solid;
            color: white;
            display: flex;
            align-items: center;
            max-height: 24px;
            min-height: 24px;
            min-width: -moz-fit-content;
            min-width: -webkit-fit-content;
            min-width: fit-content;
            max-width: -moz-fit-content;
            max-width: -webkit-fit-content;
            max-width: fit-content;
            padding-left: 6px;
            padding-right: 6px;
            font-size: 12px;
            font-weight: 500;
            border-radius: 3px;
            cursor: pointer;
            /* margin-left: 6px; */
          }

          .adminTopRight:hover {
            background-color: #143051;
          }

          .adminActionsCog {
            width:11px;
            margin-left:5px;
          }

.adminActionsMenu {
  background-color: #214978;
  position: fixed;
  top: 25px;
  padding-top:5px;
  right: 5px;
  border: 1px solid #143051;
  border-radius: 5px 0 5px 5px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  z-index: 99;
}

.adminTopRightActive {
  border-radius: 5px 5px 0 0;
  border-bottom:1px solid #214978
}


.adminActionsRow {
  min-height:35px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color:white !important;
  font-weight: 500;
  font-size:12px;
  padding-left:20px;
  padding-right:20px;
  cursor: pointer;
}

.adminActionsRow:hover {
  background-color: #143051;
  color:white
}

.adminActionCross {
  padding:1px;
}




.mgrReqItemPendingBorder:hover {
  border-color:#143051 !important
}


.pendClock {
  background-color: white !important;
  border:2px dashed #214978 !important

}

.pendClock:hover {
  border-color:#143051 !important
}

.paddingRight8 {
  padding-right:8px  !important
}

.asMgr {
  margin-top:0px
}



.desktopIndicateUnderlay {
  min-width: 100vw;
  min-height:100px;
  background-color: blue;
  position: fixed;
  top:0; left:0;
  z-index:99999;
opacity: 0.3;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  -webkit-animation: fadeIn 0.5s forwards;
          animation: fadeIn 0.5s forwards;
}
@-webkit-keyframes fadeIn {
  /* Start at fully opaque (black) */
  from {
    opacity: 0;
  }
  /* End at fully transparent */
  to {
    opacity: 0.3;
  }
}
@keyframes fadeIn {
  /* Start at fully opaque (black) */
  from {
    opacity: 0;
  }
  /* End at fully transparent */
  to {
    opacity: 0.3;
  }
}

.finalLineInstallModal {
  margin-top:-19px
}


.multi-widget-ui {
  display: none  !important;
}

.fadedDivsWithNoClick {
  opacity: 0.6;
  pointer-events: none;
}

.reqModalHeader {
  display: flex;
  justify-content: space-between;
  /* background-color: blue; */
  margin: 0 auto;
  max-width: 90%;
  min-width: 90%;
  margin-top: 5px;
  min-height: 50px;
  max-height: 50px;
  align-items: center;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.closeReqModalImg {
  min-width: 30px;
  padding: 7px;
  margin-left: 0px;
  /* background-color: blue; */
  cursor: pointer;
}

.closeReqModalImg:hover {
  opacity: 0.8;
}

.reqLeaveTitle {
  color: #213855;
  font-size: 1.3em;
  font-weight: 500;
  margin-left: -20px;
}

.reqDial {
  width: 25px;
  margin-top: 4px;
  visibility: hidden;
}

.reqScrollableContainer {
  /* background-color: pink; */
  min-width: 340px;
  /* min-width: 100%; */
  /* overflow: scroll; */
  /* overflow-x: visible; */
  /* border-top: 2px #e6eaee solid; */
  /* position: absolute; */
  /* top: 284px; */
  /* padding-top: 8px; */
  /* margin-top: 14px; */
  /* border-radius: 12px 12px 0 0; */
  /* bottom: 60px; */
  max-height: calc(100% - 300px);
  /* padding-bottom: 45px; */
  /* animation-name: fadeModalIn !important;
  animation-duration: 1s !important; */
}

.requestPage1 {
  /* background-color: lime; */
  min-height: 380px;
}

.datePickerContainer {
  /* background-color: orange; */
}

.previewContainer {
  /* background-color: orange; */
  max-width: 90%;
  margin: 0 auto;
  height: 50px;
}

.reqDateRangeTxt {
  /* color: #d9e4f3; */
  color: #07f;
  /* color: white; */
  font-weight: 500;
  font-size: 16px;
}

.reqLikelyTxt {
  color: #143051;
  margin-top: 6px;
  font-size: 14px;
  font-weight: 500;
}

.alreadyOffTxt {
  color: #c1cfe0;
  margin-top: 8px;
  font-weight: 500;
}

.likely {
  font-weight: 400;
  color: #a8cc9a;
}

.unlikely {
  font-weight: 400;
  color: #fb8888;
}

.typeContainer {
  /* background-color: purple; */
  margin-top: -12px;
margin-bottom:-6px;
  justify-content: space-between;
  /* min-height: 44px; */
}

.typeTxt {
  text-align: left;
  /* margin-left: 5%; */
  margin-top: 9px;
  margin-left:5%;
  font-size: 12px;
  /* color: #c0d6f0; */
  color: #143051;
  font-weight: 500;
  /* font-size: 1.2em; */
}

.typeOnlyTxt {
  margin-left:6%;
  display: none;
}

.typesBox {
  display: block;
  max-width: 100vw;
  align-items: center;
  font-family: "Work Sans";
  color: #143051;
  position: relative;
  top: 14px;
  padding: 0 4px;
  min-height: 49px;
  overflow-x: scroll;
  white-space: nowrap;
}

.typesInnerContainer {
  display: inline-flex;
  justify-content: space-between;
  margin-top: -4px;
  padding-top: 4px;
  float: left;
  padding-right: 20px;
  min-height: 49px;
}

.reqTypeItem {
  min-width: auto;
  padding-left: 14px;
  padding-right: 14px;
  color: #213855;
  font-weight: 300;
  font-size: 1em;
  background-color: #fff;
  /* padding-bottom: 1px; */
  margin-left: 8px;
  height: 35px;
  line-height: 30px;
  border-radius: 5px;
  font-weight: 500;
  border: 1px solid #dde9f9;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selTypeItem {
  background-color: #19a5c8;
  background-color: #0077ff;
  font-weight: 500;
  color: white;
  border: 2px solid #214978;
  line-height: 30px;
}

.reqLoadingGifImg {
  max-width: 120px;
  margin-top: 40px;
  margin-bottom: -13px;
}

.reqModalFooterContainer {
    border-top: 1px solid #dde9f9;
    color: white;
    display: flex;
    margin-left: 5%;
    max-width: 90%;
    max-height: 50px;
    min-height: 50px;
    justify-content: space-between;
    align-items: center;
    padding-top:3px;
 
}

.reqPage1Footer {
  padding-top: 8px;
}

.addNoteTxt {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  padding-left: 4px;
  padding-right: 4px;
  min-height: 30px;
  max-height: 30px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 20px;
}

.addNoteTxt:hover {
  background-color: #bbcada;
}

.reqAndCloseBtns {
  display: flex;
  /* background-color: #fff; */
  /* background-color: blue; */
  /* border-radius: 25px 0 0 20px; */
  /* padding-top: 3px;
  padding-bottom: 5px; */
  /* min-width: 50%; */
  justify-content: flex-end;
  align-items: center;
  /* margin-right: 5%; */
  /* min-height: 36px; */
  /* font-size: em; */
  font-weight: 500;
  /* min-width: 180px; */
}

.reqAndCloseBtnsPage2 {
  padding-top: 6px;
}

.reqBtn {
  background-color: #07f;
  border: #0469DD 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  font-weight: 500;
  /* line-height: 33px; */
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 0px;
  max-width: 67px;
  min-width: 75px;
  cursor: pointer;
}

.reqBtn:hover {
  background-color: #0469de;
}

.loadingReqSubmission {
  height: 36px;
  font-weight: 500;
  line-height: 36px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 30px;
  font-size: 0.9em;
  margin-top: 0px;
  max-width: 97px;
  min-width: 97px;
}

.reqLoaderImg {
  min-width: 40px;
  max-width: 40px;
}

.closeReqTxt {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 30px;
  margin-top: 3px;
}

.closeReqTxt:hover {
  background-color: #214978;
}
.datePickerTitleAndAMPMBtnContainer {
  /* background-color: lime; */
  padding-top: 15px;
  padding-bottom: 3px;
  display: flex;
  justify-content: space-between;
}

.reqAmPmBtn {
  margin-right: 5%;
  /* background-color: #2?;/;14978; */
  height: 28px;
  line-height: 28px;
  margin-top: -0px;
  color: white;
  font-size: 0.75em;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 30px;
}

.reqAmPmBtnFaded {
  margin-right: 5%;
  /* background-color: #214978; */
  height: 28px;
  opacity: 0.4;
  pointer-events: none;
  line-height: 28px;
  margin-top: -0px;
  color: white;
  font-size: 0.75em;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 30px;
}

.calHolder {
  /* background-color: yellow; */
  /* min-height: 500px; */
  max-width: 414px;
}

.vanillaDatePickerTable {
  color: white;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
  table-layout: fixed;
  padding-bottom: 24px;
  /* border-collapse: collapse; */
}

#dayNameRowDatePicker {
  height: 40px;
  line-height: 40px;
}

.dayNameTxt {
  font-weight: 500;
  font-size: 1em;
  opacity: 0.6;
}

.vanDPRow {
  /* background-color: black; */
  margin-top: 10px;
}

#table-body {
  color: white;
  /* background-color: blue; */
  table-layout: fixed;
}

.block {
  height: 30px;
  width: 14%;
  border: none;
  /* background-color: yellow; */
  cursor: pointer;
  font-weight: 500;
}

.datePickerMonthYearCaption {
  /* background-color: black; */
  display: flex;
  justify-content: space-between;
}

.dpMmonth {
  text-align: left;
  color: #00ccff;
  /* margin-top: 24px; */
  /* padding-bottom: 4px; */
  /* margin-left: 10%; */
  font-weight: 500;
  font-size: 1.2em;
  margin-top: 18px;
  margin: 18px auto;
}

.typeDropdown {
  max-width: 200px;
  min-width: 200px;
  background: url(/static/media/downArrowWhite.97cd93aa.svg) no-repeat right #516b8b;
  -webkit-appearance: none;
  background-position-x: 175px;
  border-radius: 100px;
  color: white;
  padding-left: 14px;
  font-size: 0.9em;
  /* text-align: right; */
  height: 40px;
  border: 1px solid #143051;
  margin-right: 5%;
}

.weekendBlock {
  background-color: #213855;
}

.reqBtnNext {
  background-color: #0bf;
  border: 1px solid #0af;
  height: 35px;
  font-weight: 500;
  /* line-height: 34px; */
  font-size:14px;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 5px;
  /* font-size: 0.9em; */
  /* margin-top: 0px; */
  /* max-width: 84px;
  min-width: 84px; */
  /* padding-bottom: 1px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-right: 7px; */
  /* margin-left: 2px; */
  max-width: 62px;
  min-width: 62px;
  cursor: pointer;
}

.reqBtnNext:hover {
  background-color: #0af;
}
.reqPageKey {
  /* background-color: yellow; */
  display: flex;
  justify-content: center;
  margin-top: 5px;
  padding-bottom: 5px;
}

.page1Bullet {
  min-height: 4px;
  max-height: 4px;
  max-width: 4px;
  min-width: 4px;
  background-color: #8cabd1;
  max-width: 4px;
  border-radius: 100%;
  margin-right: 3px;
}

.page2Bullet {
  min-height: 4px;
  min-width: 4px;
  background-color: #8cabd1;
  max-width: 4px;
  border-radius: 100%;
  margin-left: 3px;
}

.pageBulletSelected {
  /* background-color: white; */
  background-color: #07f;
  max-width: 4px;
  min-width: 4px;
}

.dpEndPointStart {
  background-color: white;
  color: #143051;
  border-radius: 10px 0px 0px 10px;
}
.startHalf {
  background-color: white;
  background: linear-gradient(45deg, #35557a 50%, #fff 50%);
  color: #143051;
  border-radius: 10px 0px 0px 10px;
}

.startHalfSolo {
  background-color: yellow;
  /* background: linear-gradient(45deg, #35557a 50%, #fff 50%); */
  color: #143051;
  /* border-radius: 10px 10px 10px 10px; */
}

.endHalf {
  background-color: white;
  background: linear-gradient(45deg, #fff 50%, #35557a 50%);
  color: #143051;
  border-radius: 0px 10px 10px 0px;
}

.endHalfDud {
  background-color: white;
  background: linear-gradient(45deg, #35557a 50%, #fff 50%);
  color: #143051;
  border-radius: 0px 10px 10px 0px;
}

.endHalfSolo {
  background-color: yellow;
  /* background: linear-gradient(45deg, #fff 50%, #35557a 50%); */
  color: #143051;
  /* border-radius: 10px 10px 10px 10px; */
}

.dpEndPointEnd {
  background-color: white;
  color: #143051;
  border-radius: 0px 10px 10px 0px;
}

.dpEndPointBoth {
  background-color: white;
  color: #143051;
  border-radius: 10px;
}

.dpBetween {
  background-color: white;
  color: #143051;
}

.dudStart {
  background-color: #516b8b;
  border-radius: 10px 0px 0px 10px;
}

.dudEnd {
  background-color: #516b8b;
  border-radius: 0px 10px 10px 0px;
}

.dudEnd {
  background-color: #516b8b;
  border-radius: 0px 10px 10px 0px;
}

.dudBetween {
  background-color: #516b8b;
  /* border-radius: 10px 0px 0px 10px; */
}

.dudBoth {
  background-color: #516b8b;
  border-radius: 10px;
}
.startHalfStartDud {
  background-color: #516b8b;
  /* background: linear-gradient(45deg, #35557a 50%, #fff 50%); */
  color: orange;
  border-radius: 10px 0px 0px 10px;
}
.startHalfEndDud {
  background-color: #516b8b;
  /* background: linear-gradient(45deg, #fff 50%, #35557a 50%); */
  color: orange;
  border-radius: 10px 0px 0px 10px;
}

.endHalfStartDud {
  background-color: #516b8b;
  /* background: linear-gradient(45deg, #35557a 50%, #fff 50%); */
  color: orange;
  border-radius: 0px 10px 10px 0px;
}
.endHalfEndDud {
  background-color: #516b8b;
  /* background: linear-gradient(45deg, #fff 50%, #35557a 50%); */
  color: orange;
  border-radius: 0px 10px 10px 0px;
}

.betweenHalfStartDud {
  background-color: #516b8b;
  /* background: linear-gradient(45deg, #35557a 50%, #fff 50%); */
  color: orange;
  border-radius: 0px;
}
.betweenHalfEndDud {
  background-color: #516b8b;
  /* background: linear-gradient(45deg, #fff 50%, #35557a 50%); */
  color: orange;
  border-radius: 0px;
}

.bothHalfStartDud {
  background-color: #516b8b;
  /* background: linear-gradient(45deg, #35557a 50%, #fff 50%); */
  color: orange;
  border-radius: 10px;
}
.bothHalfEndDud {
  background-color: #516b8b;
  /* background: linear-gradient(45deg, #fff 50%, #35557a 50%); */
  color: orange;
  border-radius: 10px;
}

.endHalfDud {
  background-color: #516b8b;
  /* background: linear-gradient(45deg, #35557a 50%, #fff 50%); */
  color: orange;
  border-radius: 0px 10px 10px 0px;
}

.selectDatesTxt {
  color: #516b8b;
  font-size: 12px;
  margin-top: 0px;
  font-weight: 400;
}

.reqDurationLoaderImg {
  max-width: 30px;
  margin-top: 12px;
}

.notworkinganywaytxt {
  font-size: 0.9em;
  margin-top: 10px;
  color: white;
}

.unlikelyUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999999999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

@-webkit-keyframes underlayFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes underlayFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.unlikelyModalBox {
  background-color: #496375;
  min-width: 90vw;
  max-width: 90vw;
  /* height: 150px; */
  margin-top: -10vh;
  border-radius: 15px;
  padding-bottom: 15px;
  padding-top: 15px;

  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  color: white;
  text-align: center;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
}

.understaffedTitle {
  /* background-color: yellow; */
  font-size: 1.2em;
  font-weight: 500;
  text-align: center;
  padding-bottom: 20px;
  margin-top: 5px;
}

.understaffedInfo {
  /* background-color: yellow; */
  font-size: 0.9em;
  font-weight: 300;
  text-align: center;
  padding-bottom: 20px;
  line-height: 20px;
  max-width: 90%;
  margin: 0 auto;
}

.understaffOKbtn {
  background-color: #00ccff;
  max-width: 60px;
  margin: 0 auto;
  margin-top: 15px;
  border-radius: 15px;
  min-height: 30px;
  line-height: 30px;
}

.understaffedItemsContainer {
  padding-top: 20px;
  padding-bottom: 20px;
  max-height: 200px;
  overflow: scroll;
  background-color: #355064;
}

.understaffedDayItem {
  /* background-color: grey; */
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  font-size: 0.9em;
  justify-content: space-between;
  border-bottom: 1px solid rgb(85, 118, 128);
  height: 35px;
  align-items: center;
}

.understaffedDayItem:last-of-type {
  /* background-color: grey; */
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  font-size: 0.9em;
  justify-content: space-between;
  border: none;
  height: 35px;
  align-items: center;
}

.understaffItemDate {
  font-weight: 400;
}

.understaffItemTeam {
  font-weight: 300;
}

.understaffDivider {
  min-height: 1px;
  min-width: 100%;
  background-color: white;
}

.submitReqAnywayInfo {
  font-size: 0.9em;
  font-weight: 300;
  text-align: center;
  padding-bottom: 0px;
  line-height: 20px;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 20px;
}

.halfTimeModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999999999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

@keyframes underlayFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.halfTimeModalBox {
  background-color: white;
  min-width: 300px;
  max-width: 300px;
  border-radius: 10px;
  padding-bottom: 15px;
  padding-top: 15px;
  color: #143051;
  text-align: center;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.halfTimeModal {
  font-weight: 500;
  font-size: 16px;
  margin-top: 4px;
  padding-bottom: 7px;
}




.valuee {
  margin-right: 5%;
  padding-top: 1px;
}

.alreadyOff {
  margin-right: 7%;
  margin-top: 4px;
  color: #8ea7c5;
  font-weight: 500;
}

.halfTimeDivider {
  background-color: #8ea7c5;
  min-height: 1px;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 2px;
}

.unselHalf {
  color: #355064;
  line-height: 30px;
  min-height: 30px;
  margin-right: 8px;
  font-weight: 500;
}

.selHalf {
  background-color: #143051;
  color: white;
  border-radius: 15px;
  line-height: 30px;
  min-height: 29px;
  padding-top: 6px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 500;
  margin-right: 8px;
}

.startEndLabel {
  margin-right: 18px;
  font-size: 1em;
  font-weight: 500;
  color: #8cabd1;
}

.startEndLabelForFixedVar {
  margin-right: 10px;
  font-size: 0.83em;
  font-weight: 500;
  /* background-color: black; */
  line-height: 8px;
  height: 10px;
  color: #9a74eb;
}

.halfModalFooter {
  min-height: 35px;
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
  align-items: center;
  max-width: 90%;
  margin-left: 5%;
  margin-bottom: -5px;
}

.cancelHalfModal {
  min-width: 70px;
  font-size: 0.9em;
  font-weight: 500;
  color: #50647c;
}
.saveBtnHalfModal {
  background-color: #0bf;
  border: 1px solid #0af;
  font-size:14px;
  color:white;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 35px;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  min-width: 62px;
  max-width: 62px;
}

.saveBtnHalfModal:hover {
  background-color: #0af;
}

.halfStartTime {
  background: #0077ff;
  font-size: 12px;
  color: white;
  border-radius: 4px;
  padding-left: 3px;
  padding-right: 3px;
}

.noteTitleX {
  font-weight: 500;
  color: #143051;
  font-size: 12px;
  margin-top: 12px;
  text-align: left;
  margin-left: 5%;
}
.noteTitleXval {
  color: #516b8b;
  text-align: left;
  font-size: 14px;
  margin-left: 5%;
  margin-bottom:30px;
  line-height: 18px;}

.noteInputModalBox {
  min-width: 90%;
  position: fixed;
  top: 20%;
  /* background-color: yellow; */
}

.nextDisabled {
  opacity: 0.25;
  /* color: #0cf; */
  /* background-color: white; */

  pointer-events: none;
}

.reqNoteBoxInput {
  background-color: rgb(142, 167, 197, 0.1);
  border: 1px solid #8ea7c5;
  border-radius: 30px;
  min-height: 40px;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 10px;
  color: white;
  line-height: 25px;
  padding-top: 0px;
  padding-left: 5%;
}

.addNoteSelected {
  background-color: white;
  min-height: 32px;
  max-height: 32px;
  max-width: 109px;
  min-width: 109px;
  line-height: 32px;
  display: flex;
  justify-content: space-around;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 30px;
  margin-left: 5%;
  margin-top: 10px;
}

.closeNoteImg {
  max-width: 10px;
  margin-right: 2px;
}

.addNoteTxtBtnSel {
  color: #143051;
  line-height: 32px;
}

.addNoteBtn {
  border: 1px solid #8ea7c5;
  max-width: 96px;
  min-height: 30px;
  line-height: 30px;
  border-radius: 30px;
  padding-left: 12px;
  margin: 0 auto;
  padding-right: 8px;
  margin-top: 22px;
}

.removeNoteBtn {
  border: 1px solid #8ea7c5;
  background-color: #0d2f55;
  max-width: 128px;
  min-height: 30px;
  line-height: 30px;
  border-radius: 30px;
  padding-left: 12px;
  margin: 0 auto;
  padding-right: 8px;
  margin-top: 22px;
}

.noteHeader {
  display: flex;
  justify-content: space-between;
}

.noteBtnsBar {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  min-height: 100px;
  margin-top: 8px;
}

.saveNoteBtn {
  background-color: #00ccff;
  border: none;
  color: white;
  padding-top: 5px;
  text-align: center;
  /* margin-right: 5%; */
  padding-right: 12px;
  padding-left: 12px;
  border-radius: 30px;
  margin-top: 5px;
  max-height: 30px;
}

.closeNoteEditor {
  color: white;
  margin-top: 10px;
  padding-left: 10px;
  max-width: 60px;
}

.closeNoteBlank {
  color: white;
  margin-top: 10px;
  margin-right: 20px;
  opacity: 0;
  max-width: 60px;
}

.noteTxt {
  color: white;
  /* background-color: green; */
  text-align: left;
  margin-top: 14px;
  margin-left: 8%;
  max-width: 84%;
  line-height: 25px;
  max-height: 120px;
  overflow: scroll;
  font-size: 0.9em;
  border: 1px solid #8ea7c5;
  border-radius: 15px;
  padding: 10px;
}

.requestPage1 {
}

.requestPage2 {
  max-height: 442px;
  min-height: 442px;
  /* border: 2px solid #172c43; */
  border-radius: 10px 10px 0 0;
  border-left: 1px solid #e7ebef;
  border-right: 1px solid #e7ebef;
  border-top: 2px solid #e7ebef;
  border-bottom: none;
  background-color: #193555;
  background-color: white;
  max-width: 90%;
  margin: 0px auto;
  /* overflow: scroll; */
  position: relative;
  z-index: 1;
}

.allowanceInfoContainer {
  /* overflow: scroll; */
  border: none;
  margin: 0 auto;
  color: #8ea7c5;
  color: white;
  line-height: 25px;
  padding-top: 18px;
  /* padding-bottom: 0px; */
  padding-left: 8px;
  padding-right: 8px;
  /* border-bottom: 1px solid #8ea7c5; */
  /* border-top: 1px solid #8ea7c5; */
  margin-top: 0px;
  /* background-color: lime; */
  max-height: 445px;
  overflow-y: scroll;
  padding-bottom:30px
  /* margin-bottom: 10px; */
}

.noScroll {
  overflow: hidden !important;
}

.showNoteContainer {
  border: none;
  margin: 0 auto;
  color: #8ea7c5;
  color: #213855;
  line-height: 25px;
  padding-top: 18px;
  /* padding-bottom: 0px; */
  padding-left: 20px;
  padding-right: 20px;
  /* border-bottom: 1px solid #8ea7c5; */
  /* border-top: 1px solid #8ea7c5; */
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding-left: 10px; */
}

.enterNoteTitle {
  font-weight: 500;
  color: #213855;
  margin-top: 10px;
}

.reqNoteTextArea {
  margin-top: 5px;
  max-width: 266px;
  min-width: 266px;
  min-height: 250px;
  border-radius: 5px;
  margin-left: -2px;
  resize: none;
  overflow: scroll;
  background-color: #ebf0f7;
  border-color: #c1cfe0;
  padding: 10px;
  color: #213855;
  font-size: 1em;
}
.avgMpdBoldSpan {
  font-weight: 500;
}

.zeroZMinsExplainer {
  margin-top: 10px !important;
  margin-bottom: 12px !important;
}

.zeroDisplayContainer {
  margin-bottom: 4px !important;
}

.zeroTxtSpacerMakeReq {
  min-height: 9px;
  /* background-color: black; */
  min-width: 20px;
}

.remAlowSpan {
  color: #214978;
  font-size: 1.4em;
  font-weight: 500;
  margin-top: 12px;
  text-align: left;
  margin-left: 20px;
  /* margin-bottom: 4px; */
}

.zMinsSplitter {
  min-width: 90%;
  max-width: 90%;
  min-height: 1px;
  max-height: 1px;
  background-color: #8ea7c5;
  margin: 0px auto;
  margin-top: -20px;
}

.noteUnderlay {
  background: rgb(21, 31, 45);
  background: linear-gradient(
    0deg,
    rgba(21, 31, 45, 1) 0%,
    rgba(20, 48, 81, 1) 100%
  );
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999999999999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

@keyframes underlayFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.alreadyExistsUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999999999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.alreadyExistsModalBox {
  background-color: white;
  max-width: 90%;
  border-radius: 15px;
  color: #143051;
  line-height: 20px;
  padding: 20px;
  padding-top: 25px;
}

.okayBtn {
  height: 35px;
  line-height: 35px;
  background-color: #143051;
  border-radius: 30px;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  margin: 0 auto;
  margin-top: 20px;
  max-width: 80px;
}

.calendarLinkSpan {
  color: #00ccff;
}

.ofyourAllow {
  /* margin-top: 14px; */
}

.zMinsDisplayContainer {
  padding-left: 10px;
  border-left: 3px solid #bbcada;
  margin-top: 7px;
  font-size: 20px;
  color: #143051;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: -20px;
  text-align: left;
  margin-left: 27px;
}

.zMinsExplainTxt {
  font-size: 14px;
  line-height: 18px;
  color: #143051;
  max-width: 90%;
  margin: 0px auto;
  text-align: left;
  font-weight: 400;
  padding-bottom: 18px;
  padding-left: 10px;
}

.likeliHoodTxt {
  border-left: 3px solid #bbcada;
  padding-bottom: 0px;
  padding-left: 13px;
  margin-bottom: 26px;}

.allowanceYrBold {
  font-weight:500
}


.ofYourAllowanceTxt {
  font-size: 14px;
  line-height: 22px;
  color: #143051;
  max-width: 90%;
  margin: 0px auto;
  text-align: left;
  font-weight: 400;
  padding-bottom: 10px;
  padding-left: 10px;
  margin-top:30px;
  margin-bottom:-24px;
}

.zMinsExplainMargin20 {
  margin-top: 20px;
}

.overlappingDsArr {
  margin-top: -20px;
  color: #2066b6;
  padding-left: 6px;
  overflow: scroll;
  margin-left: 15px;

}

.overlappingDayOff {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 24px;
  align-items: center;
  border-radius: 5px;
  color: #496375;
  font-weight: 500;
  font-size: 14px;
  background-color: #e3e8ee;
  color: #4f566b;
  margin-top: 6px;
  margin-left: 10px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.moveIfApprovedTxtDown {
  margin-top: 18px;
}

.fixedVarHalfWayThruTxt {
  font-size: 0.8em;
}

.fixedVarHalfSelectContainer {
  font-size: 0.9em;
  display: flex;
}

.fixedVarHalfSelectTxtLine1 {
  text-align: right;
  /* margin-top: 7px; */
  font-size: 0.88em;
  color: #516b8b;
}

.fixedVarHalfSelectTxtLine2 {
  text-align: right;
  margin-top: -1px;
  font-size: 0.88em;
  color: #516b8b;
}

.makeTxt00ccff {
  color: #00ccff;
}

.halfWayThruTxtBox {
  /* background-color: yellow; */
  display: block;
  margin-top: 1px;
  margin-right: 12px;
}

.halfStartEndKnob {
  margin-top: 0px !important;
  margin-right: 0px;
  margin-left:8px;
}

.calInputCog {
  position: fixed;
  right: 0px;
  bottom: 100px;
  opacity: 0.8;
  max-width: 40px;
}

.prefThumbsContainer {
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  margin-top: 20px;
  max-width: 80%;
  padding-bottom: 20px;
  padding-top: 15px;
}

/* .inputPrefBottomFooter {
  border-top: 1px solid #8ea7c5;
} */

.inputPrefBottomCloseBtn {
  margin-top: 12px;
  font-weight: 500;
}

.requestDateInputField {


  border-radius: 5px;
  border: 1px solid #e6eaee;
  background-color: #fff;
  color: #496375;
  min-height: 35px;
  font-weight: 500;
  font-size:16px;
  padding-left: 12px;
  padding-right: 8px;
  padding-top: 0px;
  letter-spacing: 1px;
  max-width: 169px;
  min-width: 169px;
  background: url(/static/media/calicongrey.9c7e1aba.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 137px;
  cursor: text;
}




.requestDateInputField:hover {
  border-color: #bac5d3;
}

.invalidReqSubmitBtnFade {
  opacity: 0;
  pointer-events: none;
}

.dateInputterInvalid {
  /* border: 2px solid #ba0000; */
  background: url(/static/media/calicongrey.9c7e1aba.svg) no-repeat right #fff;
  border-color: #fb8888;
  -webkit-appearance: none;
  background-position-x: 130px;
}

.dateIsInvalidTxt {
  color: white;
  font-size: 0.8em;
  margin-top: 5px;
  text-indent: 10px;
}

.hideTxt {
  visibility: hidden;
}

.dateReqInputHolder {
  display: flex;
  margin-top: 4px;
  /* background-color: yellow; */
  overflow-x: visible;
  /* min-width: 110%; */
}

.dateInputUnit {
  /* background-color: orange; */
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  overflow-x: visible;
}

.requestDateInputWrapper {
  /* background-color: black; */
  color: white;
  max-width: 96%;
  margin: 0 auto;
  text-align: left;
  overflow-x: visible;
  margin-top: -10px;
}

.startEndTxtForDateInput {
  padding-bottom: 5px;
  text-indent: 14px;
  font-size: 0.9em;
  /* margin-top: 3px; */
}

/* .startAndClearHolder {
  display: flex;
} */

.inputterHalfShiftContainer {
  display: flex;
  font-size: 0.85em;
  align-items: center;
  /* margin-right: 14px; */
  /* background-color: green; */
  min-width: 100px;
  padding-left: 6px;
  /* color: blue; */
}

.halfShiftInactive {
  /* font-size: */
  color: #8cabd1;
  font-weight: 500;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  text-align: center;
  margin-top: -2px;
  cursor: pointer;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 14px;
}

.halfShiftInactive:hover {
  color: #355064;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.halfShiftActive {
  font-weight: 500;
  background-color: #0077ff;
  border: 1px solid #0469de;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 5px;
  min-width: 52px;
  max-width: 52px;
  text-align: center;
  color:white
}

.fadedActive {
  /* background-color: #5d97a5; */
}

/* .halfShiftSpacer {
  background-color: orange;
  min-width: 14px;
} */

.none {
  display: none;
}

.halfWayThruContainerForFixedVarOnly {
  font-size: 0.8em;
  display: flex;
  min-width: 130px;
  /* background-color: yellow; */
  justify-content: flex-end;
  margin-left: -90px;
}

.halfWayThruTxt {
  color: #8ea7c5;
  margin-top: 4px;
  font-weight: 500;
  font-size: 11px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.killPaddingRight {
  margin-right: 6%;
}

.startAndEndVerticalDivider {
  min-width: 10px;
  min-height: 14px;
  /* background-color: blue; */
}

.fadeAndDisable {
  opacity: 0.2;
  pointer-events: none;
}

.clearDateInputsRequest {
  font-size: 0.85em;
  /* margin-left: 5%; */
  margin-top: 25px;
  opacity: 0.2;
  pointer-events: none;
  background-color: #fff;
  color: #859dbb;
  max-width: 52px;
  border: 1px solid #e6eaee;
  text-align: center;
  min-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* line-height: 25px; */
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
}

.clearDateInputsRequest:hover {
  border-color: #bac5d3;
}

.fullOpac {
  opacity: 1;
  pointer-events: auto;
}

.excessMinsDisclaimerTxt {
  font-size: 0.85em;
  line-height: 20px;
  margin-top: -20px;
  color: white;
  background-color: #214978;
  padding: 10px;
  border-radius: 15px;
}

.findoutmoreexcessmins {
  font-weight: 500;
  font-size: 0.9em;
  line-height: 33px;
}

.makeReqNoteTitle {
  font-size: 1.2em;
  margin-bottom: 12px;
}

.requestNoteInput {
  /* background-color: yellow; */
  min-width: 90%;
  margin: 0 auto;
  border: none;
  background-color: #1e4572;
  color: white;
  font-size: 1.1em;
  border-radius: 15px;
  border: 1px solid #34659d;
  padding: 15px;
  min-height: 50px;
}

.highlightHalfDayBookedBlock {
  color: orange;
}

.overlappingReqModalUnderlay {
  background: rgba(0, 0, 0, 0.3);
  /* max-height: calc(100% - 58px); */
  height: 100%;
  min-width: 100vw;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999999;
  top: 0;
  left: 0;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.overlappingReqModalBox {
  background-color: #f4f8fe;
  color: #143051;
  border-radius: 5px;
  min-height: 35px;
  min-width: 320px;
  max-width: 320px;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  cursor: auto;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  /* border: 2px solid #8ea7c5; */
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 14px;
  padding-right: 14px;
  margin-top: -100px;
}

.overlappingTitle {
  font-weight: 500;
  max-width: 90%;
  padding-left: 6%;
  padding-right: 6%;
  padding-bottom: 18px;
  margin: 5px auto;
  margin-bottom: 20px;
  line-height: 1.5em;
  border-bottom: 1px solid #8997a8;
}

.overlappingDs {
  margin: 10px auto;
  /* margin-top: 10px; */
  background-color: #2066b6;
  border: 1px solid #214978;
  color: #fff;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 5px;
  font-size: 0.95em;
  font-weight: 500;
  padding-top: 4px;
  padding-bottom: 4px;
}

.overlappingIssueTxt {
  max-width: 90%;
  margin: 20px auto;
  border-top: 1px solid #8997a8;
  padding-top: 18px;
  padding-left: 6%;
  padding-right: 6%;
  font-size: 0.9em;
  line-height: 1.4em;
  color: #516b8b;
  /* text-align: left;  */
}

.moreInfoOverlapsTxt {
  font-size: 0.9em;
  margin-top: 20px;
  color: #516b8b;
}

.moreInfoFoundHereOverlapsSpan {
  font-weight: 500;
  color: #00ccff;
}

.closeOverlapModal {
  font-size: 0.9em;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 2px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  line-height: 26px;
  border-radius: 5px;
  min-height: 30px;
  max-width: 60px;
  /* margin: 28px auto; */
  /* margin-bottom: 6px; */
  cursor: pointer;
  /* margin-bottom: -10px; */
}

.closeOverlapModalContainer {
  /* background-color: yellow; */
  display: flex;
  max-height: 40px;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 30px;
}

.helpButtonOverlapModal {
  font-weight: 500;
  cursor: pointer;
  color: #3b597c;
  font-size: 0.9em;
}

.overlapsSplitter {
  margin-top: 34px;
  margin-bottom: 40px;
}

.estimateTimeYouWillBeP {
  margin-top: 20px;
}

.reqMakerAllowanceBox {
  background-color: #f4f8fe;
  border: 1px solid #e7ebef;
  color: #143051;
  border-radius: 5px;
  max-width: 90%;
  margin: 0px auto;
  margin-top:70px;
  min-height: 92px;
  padding-top: 5px;

}

.reqMakerAllowRow {
  display: flex;
  max-width: 90%;
  margin: 0 auto;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #081d35;
  padding: 7px;
  padding-left: 0px;
}

.reqMakerAllowRow:first-child {
  border-top: none;
}

.reqMakerLeaveTypeName {
  font-size: 14px;

  border-radius: 5px;

  border: 1px solid #e7ebef;
  background-color: #fff;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  padding-left: 12px;
  padding-right: 12px;
  /* margin: 0 auto; */
  margin-top: 20px;
  margin-bottom: -13px;
  position: relative;
  z-index: 2;
  font-weight: 500;
  color: #496375;
  margin-left: 33px;
  min-height: 30px;
  display: flex;
  align-items: center;
  border-left: 3px solid #e7ebef;
}

.unableToMakeReqSpan {
  color: #fff;
  background-color: #ba0000;
  border-radius: 10px;
  padding: 10px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  display: flex;
  align-items: center;
  /* margin: 0 auto; */
  margin-bottom: -25px;
  /* margin-left: -5px; */
  /* padding-left: 20px; */
  /* padding-right: 20px; */
  /* justify-content: baseline; */
  /* text-align: right; */
}

.cannotOverlapTxt {
  font-size: 0.9em;
  max-width: 90%;
  margin: -20px auto;
  text-align: left;
}

.dailyValueReq {
  opacity: 0.5;
}

.reqMakerAllowRowValue {
  text-align: right;
  font-size: 16px;
  font-weight: 600;
}

.extendAllowanceTxtReq {
  font-size: 14px;
  line-height: 14px;
  min-width: 100px;
  margin-top: 2px;
  margin-bottom: 8px;
  color: #8ea7c5;
  /* min-width: 320px; */
}

.reqConversionSpan {
  color: #8ea7c5;
  font-size: 17px;
  padding-left: 2px;
}
.reqMakerAllowRowTitle {
  text-align: left;
  /* line-height: 16 px; */
  font-size: 12px;
}

.addNoteToReqContainer {
  /* background-color: blue; */
  min-height: 35px;
  max-height: 35px;
  margin-bottom: -30px;
  display: flex;
  justify-content: space-between;
  padding-right: 5%;
}

.addNoteToReqBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 78px;
  padding-left: 7px;
  padding-right: 4px;
  /* min-height: 35px; */
  /* max-height: 35px; */
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
  /* margin-top: 0px; */
  margin-right: 2px;
}

@media only screen and (min-width: 820px)  {
  .addNoteToReqBtn:hover {
    background-color: #bbcada;
  }
}

.addNoteToReqBtnImg {
  width: 9px;
  margin-left: 6px;
  margin-right: 4px;
}




.noteActive {
  min-width: 6px;
  max-width: 6px;
  min-height: 6px;
  max-height: 6px;
  background-color: #0077dd;
  border-radius: 10px;
  margin-right: 2px;
  margin-left: 6px;
}

.closeNoteBtnX {
  max-width: 10px;
  margin-left: 6px;
}

.desktopTypesDropdown {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 280px;
  min-width: 280px;
  line-height: 26px;
  height: 35px;
  -webkit-appearance: none;
  background-position-x: 92px;
  display: flex;
  cursor: pointer;
  margin-left: 20px;
  /* margin-top: 18px; */
  margin: 18px auto;
  border: 1px solid #e6eaee;
  background-color: #0bf;
  background-color: white;
  color: #3b597c;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 255px;
}

.desktopTypesDropdown:hover {
  border-color: #d7dfe9;
}

.reqCostDiv {
  max-width: 80%;
  border: 1px solid #dde9f9;
  border-right: none;
  border-top: none;
  border-radius: 0px 0px 0px 5px;
  margin-bottom: 24px;
  -webkit-animation-name: costDivDown !important;
          animation-name: costDivDown !important;
  -webkit-animation-duration: 0.12s !important;
          animation-duration: 0.12s !important;
  margin-left: 15px;
}

.reqCostDivToggle {
  display: flex;
  justify-content: space-between;
  max-width: 190px;
  padding-right: 40px;
  /* border-bottom: 1px solid #d7e1ee; */
  /* cursor:   pointer; */
}

.mobReqCostDivToggle {
  max-width: 213px;
}

.reqCostDropImg {
  cursor: pointer;
  min-width: 22px;
  max-width: 22px;
  max-height: 22px;
  min-height: 22px;
  background-color: #cbd7e6;
  border: 1px solid #bbcada;
  padding: 6px;
  border-radius: 5px;
  margin-left: 10px;
  margin-top: -2px;
  /* margin-right: 120px; */
}

.reqCostDropImg:hover {
  background-color: #bbcada;
}

.noBorder {
  border: none;
}

.showCostImgInactive {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.costArrRow {
  padding-bottom: 12px;
  padding-left: 12px;
  border-bottom: 1px solid #d7e1ee;
}

.costArrRow:last-of-type {
  border: none;
}

.costArrTitle {
  margin-left: 5px;
  font-size: 10px;
  color: #214978;
  font-weight: 500;
  margin-top: 12px;
  /* text-transform: uppercase; */
  background-color: #fff;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  padding: 4px;
  border-radius: 3px;
  padding-left: 6px;
  padding-right: 6px;
  
  border: 1px solid #e3e8ee;
}

.costArrValue {
  color: #143051;
  margin-left: 9%;
  font-size: 14px;
  font-weight: 500;
  margin-top: 6px;
  line-height:17px
}

.costContractedExcessSpan {
  font-size: 10px;
  margin-left: 1px;
  font-weight: 500;
  color: #bbcada;
}

.whiteGoActive {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.reqMakerLimitsBox {
  /* color: blue; */
  border-bottom: 1px solid #d7e1ee;
  margin-bottom: 20px;
  padding-bottom: 12px;
}

.limRowFlexBox {
  display: flex;
  justify-content: space-between;
  max-width: 90%;
  margin-left: 5%;
  color: black;
  margin-top: -4px;
}

.limRowDs {
  text-align: left;
  padding-left: 10px;
  color: #bbcada;
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 12px;
  margin-top: -4px;
  display: flex;
  align-items: center;
}
.limExceedRedX {
  width:12px;
  margin-right:6px;
}

.reqMakerLimitrRow {
  border: 1px solid #445a74;
  background-color: #516b8b;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 9px;
  border-radius: 5px;
  max-width: 92%;
  margin-left: 4%;
}

.reqMakerLimitrRow:first-of-type {
  margin-top: 20px;
}

.reqMakerLimitrRow:last-of-type {
  margin-bottom: 15px;
}
.limRowLeft {
  text-align: left;
  min-width: 15%;
  max-width: 15%;
}

.limRowMiddle {
  text-align: left;
  min-width: 35%;
  max-width: 35%;

  padding-left: 6px;
  border-left: 1px solid #6c7f96;
  /* min-height: fit-content; */
}

.limRowRight {
  padding-left: 6px;
  text-align: left;
  min-width: 55%;
  max-width: 55%;
  /* padding-bottom: 6px; */
  border-left: 1px solid #6c7f96;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
}

.limRowTitle {
  color: #fff;
  font-size: 10px;
  font-weight: 500;
}

.limRowValue {
  font-size: 12px;
  font-weight: 500;
  margin-top: -4px;
  line-height: 16px;
  margin-top: -3px;
  font-weight: 500;
  color: #b0c4dd;
  min-height: -moz-fit-content;
  min-height: -webkit-fit-content;
  min-height: fit-content;
  /* background-color: blue; */
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  padding-right: 12px;
}

.limRowValueChecker {
  font-size: 12px;
  font-weight: 500;
  margin-top: -4px;
  line-height: 14px;
  margin-top: 0px;
  font-weight: 500;
  color: #b0c4dd;
  min-height: -moz-fit-content;
  min-height: -webkit-fit-content;
  min-height: fit-content;
  /* background-color: blue; */
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  padding-right: 12px;
}

.costBoxContainer {
  color: #496375;
  /* margin-left: 10px; */
  /* border-bottom: 1px solid #d7e1ee; */
  padding-bottom: 14px;
  padding-top: 2px;
  min-width: 96%;
  max-width: 96%;
  padding-left: 10px;
  background-color: #f4f8fe;
  border-radius: 5px;
  margin-top: 4px;
  padding-bottom: 4px;
  border: 1px solid #e6eaee;
  cursor: pointer;
}

.costBoxContainer:hover {
  background-color: #fafcff;
}

.allowBoxContainer {
  margin-top:10px;
  
}

.usrAllowBoxContainer {
  margin-top: 11px;
    max-width: 175px !important;
    min-width: 175px !important;
    margin-left: 19px;
}

.userCostBoxContainer {
  color: #496375;
  /* margin-left: 10px; */
  /* border-bottom: 1px solid #d7e1ee; */
  padding-bottom: 14px;
  padding-top: 2px;
  min-width: 86%;
  max-width: 86%;
  padding-left: 10px;
  background-color: #f4f8fe;
  border-radius: 5px;
  margin-top: 4px;
  padding-bottom: 4px;
  border: 1px solid #e6eaee;
  cursor: pointer;
  margin-left: 18px;
  margin-top: 22px;
}

.userCostBoxContainer:hover {
  background-color: #fafcff;
}

.reqFooterBackUnit {
  min-width: 10%;
  cursor: pointer;
  /* background-color: blue; */
  min-height: 50px;
  border-right: 1px #96acc4 solid;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reqFooterBackUnit:hover {
  background-color: #e7ebef;
}

.reqBackImg {
  max-width: 6px;
}

.payableSplitter {
  margin-top: 0px !important;
}

.toUseSplitter {
  margin-top: 0px !important;
}


.sureDeclinedReqModalMob {
  max-height: 362px !important;
  min-height: 362px !important;
}

.sureDeclinedReqModal {
  max-height: 360px !important;
  min-height: 360px !important;
}

.sureDeclinedWithReganModal {
  /* background-color: blue  !important; */
  max-height: 413px !important;
  min-height: 413px !important;
}

.sureDeclinedWithReganModalMob {
  max-height: 403px !important;
  min-height: 403px !important; 
}

.sureDeclineModalBoxNotYetApprovedWithOutRegen {
  max-height: 323px !important;
  min-height: 323px !important; 
}

.sureDeclineModalBoxNotYetApprovedWithOutRegenMob {
  max-height: 323px !important;
  min-height: 323px !important;
}

.sureDeclinedReasonContainerWithReganModalMob {
  max-height: 253px !important;
  min-height: 253px !important; 
}


.addLeaveModalBox {
  background-color: #f4f8fe;
  color: #143051;
  min-width: 380px;
  max-width: 380px;
  border-radius: 10px;
  padding-top: 7px;
  text-align: left;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 45px;
  cursor: auto;
  border: 1px solid #e7ebef;
  /* -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
}

.addLeaveModalBoxMob {
  min-width:100vw;
  max-width:100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  border-radius: 5px 5px 0 0
}

.addLeaveModalBoxHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* max-width:90%; */
  padding-left:8px;
  padding-right:10px;
  padding-bottom:4px;
}

.addLeaveModalHeaderboxCloseImg {
  cursor: pointer;
}

.addLeaveModalHeaderboxCloseImg:hover {
  opacity: 0.8;
}
.addLeaveModalHeaderBoxTitle {
  font-weight:600;
  font-size:16px;
}

.addLeaveToUserModalFooter {
  display: flex;
  min-height:50px;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #bac5d3;
  flex-direction: row-reverse;
  /* flex-direction: row-reverse; */
}

.flexDirectionRow {
  flex-direction: row-reverse !important;

}
.saveAddLeaveToUserBtn {
  font-size: 14px;
  color: #fff;
  background-color: #0bf;
  border: 1px solid #0af;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  max-width: 62px;
  min-width: 62px;
}
.saveAddLeaveToUserBtn:hover {
  background-color: #0af;
}

.addLeaveDatesRow {
  display: flex;
justify-content: space-between;
/* padding-left:5%;
padding-right:5%; */
flex-direction: column;
border-bottom: 1px solid #e0ebf8;
padding-bottom:20px;
}




.addLeaveDatesTitle {
  font-weight:400;
  font-size:12px;
  color:#214978;
  margin-bottom:-10px;
  margin-left:7%;
  
}

.addLeaveOverlapsOtherReqsTitle {
  font-weight:600;
  font-size:12px;
  color:#143051;
  margin-bottom:-10px;
  margin-left:14px;
}

.addLeaveOverlappingDsArr {
  display: flex;
  flex-wrap: wrap;
  margin-top:-5px;
  margin-bottom:18px;
  padding-left:4px;

}

.overlapReqAddLeaveItem {
  background-color: blue;
  font-size:12px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight:500;
  color:white;
  background-color: #bccada;
  border: 1px solid #bac5d3;
  padding-left:5px;
  padding-right:5px;
  border-radius: 5px;
  margin-left:10px;
  margin-top:5px;
  cursor: pointer;
}

.overlapReqAddLeaveItem:hover {
  background-color: #bac5d3;
}


.addLeaveDateInput {
  border-radius: 5px;
  border: none;
  background-color: #fff;
  color: #496375;
  min-height: 35px;
  line-height: 35px;
  font-size: 16px;
  padding-left: 14px;
  padding-right: 8px;
  letter-spacing: 1px;
  padding-top: 1px;
  font-weight: 500;
  max-width: 174px;
  min-width: 174px;
  background: url(/static/media/calicongrey.9c7e1aba.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 142px;
  border: 2px solid #dde9f9;
  margin-left: 0px;
  margin-top: 4px;
  cursor: text;

}

.addLeaveDateInput:hover {
  border-color: #bac5d3
}

.addLeaveModalLeaveSelectorDropdown {
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  padding-left: 10px;
  text-align: left !important;
  max-width: 254px;
  min-width: 254px;
  line-height: 26px;
  height: 35px;
  /* border:  2px solid #e6eaee; */
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border-color: #2c7eb9;
  border: 2px solid #dde9f9;
  background-color: white;
  color: #496375;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 228px;
  margin-top:7px;
  margin-bottom:10px;
  margin-left:5%;
}
.addLeaveModalLeaveSelectorDropdown:hover {
  border-color: #bac5d3

}

.lastDayDivWithNoEnd {
  min-width:100%;
  display: flex;
  justify-content: flex-start;
  padding-left:5%;
  min-height: 96px;
}

.lastDayL {
min-width:176px;
}


.ongoingBtn {
  cursor: pointer;
  display: flex;
  border: 2px solid #dde9f9;
  /* background-color: #a387df; */
  color: #62548e;
  border-radius: 5px;
  min-height: 35px;
  line-height: 35px;
  padding-left: 9px;
  padding-right: 7px;
  font-size: 12px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  /* margin-left: 18px; */
  font-weight: 500;
  margin-top: 17px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  max-height:30px;
  align-items: center;
}

.ongoingBtn:hover {
  border: 2px solid #bac5d3;
  color: #4b1caf;


  /* background-color: #8e76c1; */
}

.ongoingActive {
  background-color: #9f89d3;
  border-color: #31146f;
  color:white
}

.ongoingActive:hover {
  background-color: #8973bc;
  border-color: #31146f;
  color:white
  /* border-color: #1b054a; */
}

.ongoingInputTxt {
  font-weight: 500;
  color: #bbcada;
  font-size: 12px;
  line-height: 16px;
  /* padding-right: 80px; */
  max-width: 174px;
  min-width: 174px;
  max-height: 35px;
  min-height: 35px;
  margin-top: 6px;
  margin-left: 0px;
  padding-left: 8px;
  border-top: 1px solid #f5f5f5;
  padding-top: 5px;
  margin-bottom: 4px;
}

.addLeaveFirstDayRowFlex {
  display: flex;
  /* border-bottom: 1px solid #e0ebf8; */
  padding-bottom:12px;
  padding-left:5%;

  /* min-width:100%; */

}

.addLeaveFirstDayBorderBottom {
    border-bottom: 1px solid #e0ebf8;

}

.lastDayR {
  margin-top:35px;
  /* background-color: blue; */
  font-size:14px;
  font-weight:600;
  display: flex;
  max-width:110px;
  align-items: center;
  margin-left:20px;
  padding-bottom:10px;
  padding-left:5px;
  padding-right:2px;
  max-height:40px;
  border-radius:5px;
  border: 1px solid white;
  color:#bbcada
}
.lastDayR:hover {
  /* background-color: #f2f7fd; */
  /* border: 1px solid #bbcada */
  color: #90aac7

}

.lastDayRFx {
  margin-top:4px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-left: 20px;
  padding-bottom: 7px;
  max-height: 54px;
  border: 1px solid white;
  min-height:78px;
  color: #bbcada;
}

.addLeaveFixSchedStripFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width:100px;
  margin-top: 21px;
  margin-left:10px;
  max-height:30px
}

.addLeaveHalfStripTxt {
  max-height:50px;
  line-height:15px;
  margin-top:10px;
  font-size:12px;
  cursor: pointer;

}

.addLeaveHalfStripTxtFs {
  cursor: pointer;
  background-color: #fff;
  border-bottom: 2px solid #fff;
  color:#143051;
  /* pointer-events: none; */
  /* cursor: default; */
  /* border-radius: 5px; */
  padding-left:4px;
  padding-right:4px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  max-height:24px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.addLeaveHalfStripTxtFs:hover {
opacity: 0.8;}

.selectedFsHalfStrip {
  background-color: #fff;
  border-bottom: 2px solid #a387df;
  color:#143051;
  pointer-events: none;
  cursor: default;
  /* border-radius: 5px; */
  padding-left:4px;
  padding-right:4px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  max-height:24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectedFsHalfStripNonHalf {
  /* background-color: #cbd7e6;
  border: 1px solid #bac5d3; */
}

.addLeaveHalfStartKnob {
  /* background-color: pink; */
  min-width: 38px;
  max-height:30px;
  margin-bottom:3px;
}

.addLeaveHalfStartActive {
  background-color: #214978;
  border-color: #143051;
  color:white;
}

.addLeaveHalfStartActive:hover {
  background-color: #143051;
  border-color: #143051;
  color:white;

}

.startsBeforeEndsAddLeave {
  border-color: #ba0000
}

.startsBeforeEndsAddLeave:hover {
  border-color: #ba0000
}

.addLeaveOngoingAndDataDiv {
  display: flex;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: 20px;
  min-width: 100%;
}

.addLeaveDataDiv {
  min-width: 92%;
  max-width:92%;
  margin-left:4%;
  margin-top: 10px;
  border-radius: 5px;
  border: 2px solid #214978;
  margin-right: 8px;
  margin-bottom: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #f4f8fe;
  border-left:10px solid #143051

}

.addLeaveDataDivRow {
  height:33px;
  border-bottom: 1px solid #e0ebf8;
  max-width:94%;
  min-width:94%;
  margin-left:3%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left:5px;
  padding-right:5px;
  font-size:14px;
  font-weight:600;
  color: #143051;
}

.addLeaveDataDivRowValue {
  color: #0077ff;
  font-size:14px;
}


.addLeaveReqAllowDataDiv {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
}

.recalcAddLeaveDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right:5%;
  padding-left:5%;
  margin-top:12px;

}

.recalcAddLeaveBtn {
  border: 1px solid #8e76c1;
  background-color: #a387df;
  color:white;
  border-radius:5px;
  display: flex;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-left:4px;
  padding-right:8px;
  font-weight:600;
  max-height:35px;
  align-items: center;
  font-size:14px;
  cursor: pointer;
  margin-bottom:10px;
}

.recalcAddLeaveBtn:hover {
  background-color: #8e76c1;
}

.withOutSpan {
  color: #bbcada;
  font-size:10px;
}

.calcRefreshIconImg {
  max-width:22px;
  margin-right:4px;

}

.desktopAddLeaveModalBody {
  max-height: 572px !important;
}

.formRespBody {
  max-height: 450px !important
}

.addLeaveDataNoLeaveRequired {
  font-weight: 500;
  text-align: center;
}

.startsOrEndsHalf {
  font-weight:600;
  font-size:12px;
  float:right;
  margin-top:-26px;
  margin-right:27px;
  color: #bbcada
}



.firstHalfOffTxtV {
  margin-top: -8px;
  margin-bottom: -16px;
  float: right;
  text-align: right;
  margin-right:27px;

  

}

.ongoingSelectImg {
  max-width: 14px;
  min-width:14px;
  min-height:14px;
    margin-left: 7px;
    margin-right: 4px;
    border: 1px solid #d8d3e3;
    border-radius: 3px;
    background: #e6e2f0;
}

.singleDayBothHalfs {
  font-size: 14px;
  line-height: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 14px;
  padding-bottom: 15px;
  background-color: #de6969;
  color: white;
  border-radius: 5px;
  max-width: 90%;
  margin-left: 5%;
  margin-bottom: 9px;
  margin-top: 7px;
  border: 2px solid #ba0000;
  font-weight:600;
  text-align: center;
  
}

.addLeaveStartEndInvalid {
  text-align: center;
  font-weight: 500;
  color: #143051
}

.redDocsBtn {
  background-color: #ba0000;
  /* color: #fff !important; */
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 5px;
  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-left:5px;
cursor: pointer;
}

.redDocsBtn:hover {
  background-color: #ae0e0e;
}

  .redDocsBtnPopUpImg {
    max-width:9px;
    margin-left:3px;
  }

  .addLeaveModalInfoBtn {
    background-color: #143051;
    color: white !important;
    font-weight: 500;
    min-height: 35px;
    display: flex;
    max-width: -moz-fit-content;
    max-width: -webkit-fit-content;
    max-width: fit-content;
    margin: 0 auto;
    align-items: center;
    padding-left: 11px;
    padding-right: 11px;
    border-radius: 5px;
    font-size: 14px;
    margin-top: 10px;
    float: left;
    margin-right: 7px;
  }

  .addLeaveModalInfoPopUp {
    max-width:12px;
    margin-left:3px;
  }


  .calcSpanModalTxt {
    color: #a387df;
    font-weight:600
  }

  .needToRecalcAddBtn {
    /* opacity: 0.6; */
    color: #bbcada;
    border-color: #bbcada;
    background:none
  }

  .needToRecalcAddBtn:hover {
    background-color: #bbcada;
    color:white
  }

  .overlapWarnIcon {
    margin-right: 5px;
    max-width: 16px;
    margin-bottom: -2px;
    padding-top: 3px;  }

.zeroAllowAddLeaveExplainDiv {
  padding-left:5px;
  min-height:35px;
  padding-top:10px;
  padding-bottom:10px;
  /* background-color: blue; */

}
    .zeroAllowAddLeaveExplainTxt {
      color: #bbcada;
      font-size:10px;
      font-weight:500;
      line-height:12px;
      max-width:192px;
      /* background-color:pink; */
    }

    .zeroAllowAddLeaveExplainInfoBtn {
      background-color: #bbcada;
      border: 1px solid #bac5d3;
      color:white !important;
      font-weight:500;
      display: flex;
      align-items: center;
      font-size:12px;
      max-height:20px;
      padding-left:5px;
      padding-right:5px;
      border-radius:5px;
      cursor: pointer;
    }

    .zeroAllowAddLeaveExplainImg {
      max-width:12px;
      margin-left:3px;
    }

    .zeroAllowAddLeaveExplainInfoBtn:hover {
      background-color: #bac5d3;
    }


    .cancelAddLeaveModalBtn {
      font-size: 14px;
      color: #fff;
      background-color: #3b597c;
      border: solid #214978 1px;
      font-weight: 500;
      cursor: pointer;
      padding-left: 9px;
      padding-right: 9px;
      max-height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      min-height: 35px;
      max-width: 70px;
      min-width: 70px;
      
    }
    .cancelAddLeaveModalBtn:hover {
      background-color: #214978;
    }


    .greenNestedAddLeaveBorderLeft {
      border-left:10px solid #7fbb67
    }

    .redNestedAddLeaveBorderLeft {
      border-left:10px solid #ba0000
    }

    .sufficientImg {
      max-width:12px;
      margin-left:4px;
    }

    .addLeaveFixedSchedDivParent {
      height:22px;
      /* background-color: black; */
    }

    .addLeaveFixedSchedSub {
      color: #bbcada;
      font-size:12px;
      margin-top:-10px;
      /* height:20px; */
    }

    .addLeaveNoteBtn {
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      padding-left: 7px;
      padding-right: 7px;
      max-height: 30px;
      /* margin-right: 20px; */
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      min-height: 35px;
      margin-top: 17px;
      /* margin-bottom: 8px; */
      color: #496375;
      border: #e3e8ee solid 2px;
      background-color: #fff;
      min-width: 78px; 
      cursor: pointer;
       }
       

      .addLeaveNoteBtn:hover {
        border-color: #b2bbc7
      }
      .showAddNoteCloseImg {
        max-width: 9px;
        margin-left:6px;
        margin-right:2px;
      }

      .addLeaveAddNoteDiv {
        max-width:90%;
        margin-left:5%;
        border-top: 1px solid #e0ebf8;
        border-bottom: 1px solid #e0ebf8;
        padding-top:20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }
.addLeaveFirstDayRowFlexLeft {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}
      .addLeaveNoteAddNoteDivLeft {
      width:75%
      }

      .addLeaveNoteAddNoteDivRight {
        /* background-color: orange; */

      }

      .addNoteNoteTextAreaInput {
        border: 2px solid #dde9f9;
        border-radius: 5px;
        padding-left: 6px;
        background-color: #fdfeff;
        resize: none;
        width: 100%;
        min-height: 90px;
        padding: 5px;
        padding-left: 10px;
        color: #516b8b;
        font-size: 16px;
        font-weight: 500;
        color: #496375;
        font-weight: 500;
        /* margin-bottom: 60px; */
      }

      .addNoteNoteTextAreaInput:hover {
        border-color: #bac5d3
      }

      .showAddNoteAddNoteBtn {
        border: solid 1px #bbcada;
        background-color: #cbd7e6;
        min-height: 35px;
        border-radius: 5px;
        min-width: 68px;
        color: white;
        font-weight: 500;
        max-height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        cursor: pointer;
        margin-top: 27px;
      }

      .showAddNoteAddNoteBtn:hover {
        background-color: #bbcada;
      }

      .addLeaveNoteTitle {
        font-weight: 500;
        font-size: 12px;
        line-height:12px;
        margin-bottom:14px;
        color: #143051;
        margin-left:10px;
        /* max-height:20px; */
        /* margin-top:-30px; */
      }

.absenceModalFooterLeft {
  font-weight: 500;
  color: #bbcada;
  margin-top: -2px;
  font-size: 12px;

  margin-left: -5px;
}

.isAbsenceSubHeaderCorrection {
  margin-bottom: -1px;
  /* margin-top: 2px; */
}

.noReqCostSplitter {
  min-height:1px;
  min-width:100%;
  background-color: #d7e1ee;
  min-width:90%;
  margin-right:10px;
}


.areYouSureModalYesBtn {
  font-size: 14px;
  color: #fff !important;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  margin-top: 20px;
  width:48%;

}

.areYouSureModalYesBtn:hover {
  background-color: #214978;
}

.areYouModalNoBtn {
  font-size: 14px;
  color: #8ea7c5;
  background-color: #f4f8fe;
  border: solid #bbcada 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  margin-top: 20px;
  width:48%;
}

.areYouModalNoBtn:hover {
  background-color: #dde9f9;
}

.areYouModalBtnsDiv {
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  align-items: center;
}

.lastDayRparent {
  /* background-color: blue; */
}

.fxLastHalfOffCorrection {
  margin-right:22px;
}


.addLeaveLoadingImg {
  max-width:45px;
  margin-right:8px;
}

.reqOngoingSpan {
  /* background-color: #9e79ab; */
  border: 1px solid #f5f5f5;
  /* border-left: 3px solid #634698; */
  color:#bbcada;
  border-radius:3px;
  font-size:12px;
  font-weight:600;
  min-height:20px;
  padding-left:4px;
  padding-right:4px;
  padding-bottom:1px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
}

.ongoingTxtFormat {
  font-size: 12px;
  padding-right: 20px;
  color: #859dbb;
  display: flex;
  justify-content: space-between;
  min-width: 210px;
  cursor: default;
  line-height: 14px;

}


.ongoingUpToNowSpan {
  color:#bbcada;
  font-weight:400
}

.openedToEndOngoingDateInput {
  border-color: #00ccff
}


.endOngoingNowBtn {
  background-color: #00ccff;
  border: 1px solid #0af;
  color:white;
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left:20px;
  min-height:35px;
  border-radius: 5px;
  font-size:14px;
  cursor: pointer;
}

.endOngoingNowBtn:hover {
  background-color: #0af;
}

.willBeUnder {
  color: #143051;
  font-weight: 700;
  font-size: 11px;
  margin-top: 4px;
  margin-left: 12px;
  min-height: 30px;
  text-align: center;
padding-right:8px;
  margin-bottom: -15px;
}

.soFar {
  margin-top:-2px;
}

.onGoingPullUp {
  margin-top:34px;
}


.reqModalContainer {

  min-height: 660px;
  max-height: 660px;
  min-width: 400px;
  max-width: 400px;

  border-radius: 10px;

  /* box-shadow: 0px 0px 3px 3px rgb(0 0 0 / 10%); */
  cursor: auto;
  -webkit-animation-name: zoomModalIn ;
          animation-name: zoomModalIn ;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  border: 1px solid #e7ebef !important;
  background-color: #f4f8fe!important;

}

.mobReqModalContainer {
  min-width: 100vw !important;
  max-width: 100vw !important;
  position: fixed;
  bottom: 0;
  border-radius: 15px 15px 0 0;
  -webkit-animation-name: moveEditTeamUserBoxUp !important;
          animation-name: moveEditTeamUserBoxUp !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}


.decideReqActionsDiv {
  /* background-color: blue; */
  display: flex;
  align-items: center;
}

.reqDecisionReturnArrImg {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;  max-width:35px;
  max-height:35px;
  min-height:35px;
  min-width:35px;
  padding:7px;
  border-radius:5px;
  cursor: pointer;
  margin-right:8px;
}

.reqDecisionReturnArrImg:hover {
  background-color: #bbcada;
}


.reqRate {
  border: 2px solid #1c3f68;
  min-width: 60px;
  border-radius: 5px;
  min-height: 40px;
  margin-top: -10px;
  background: #f3e6ff;
}

.x2i3i2 {
  background-color: #ebf4ff;
}


.reqRate0 {
  font-size: 9px;
  font-weight: 500;
  margin-top: 3px;
  margin-bottom: -3px;
  /* margin-left: 3px; */
  text-align: center;
}


.reqRate1 {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 2px;
  margin-top: 1px;
  text-align: center;
}


/* .reqRateDivider {
  min-height: 2px;
  background-color: #1c3f68;
  min-width: 60px;
  margin-top: -10px;
  margin-bottom: 20px;
  display: none;
} */
.x28838482 {
  margin-top:10px !important
}

.addLeaveFixedSchedParent {
  /* background-color: blue; */
}

.leaveStartsWhen {
  /* background-color: yellow; */
  margin-top: 0px;
  margin-bottom: -30px;
  font-size: 12px;
  color: #214978;
  padding-left:11px;
  font-weight: 400;}


  .x7733 {
    margin-left: -10px;
  }

  .annLeavePlaneTopRight {
    max-width: 25px;
    margin-left: 3px;
    margin-bottom: -6px;  }



    .sameDataPushDown {
      /* margin-top:8px; */
    }

    .fontSize14Rate {
font-size:14px;
    }

    .x9101pp {
      margin-top:15px
    }

    .beforeTodayReqCal {
      opacity: 0.5;
      pointer-events: none;
    }

    .reqWkRow {
      /* border-bottom: 1px solid black; */
      margin-top: 12px;
      min-height: 30px;
      /* background-color: blue; */
      padding-left: 5px;
      padding-right: 5px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      max-width: 92%;
      margin-left: 4%;
      cursor: pointer;
      }
      @media only screen and (min-width: 820px)  {
        .reqCalItemHover:hover {
          background-color: #e3e8ee;
          border-radius:5px;
        }
      }


   

      .selReqSnakeItem {
        background-color: #143051;
        color:white !important;
        border-radius:5px;
      }

      .selReqSnakeItem:hover {
        background-color: #143051;
      }



      .selReqSnakeItemClosed {
        background-color: #dde9f9;
        color:white !important;
        border-radius:5px;
      }

      .selReqSnakeItemClosed:hover {
        background-color: #dde9f9;
      }

      .reqHalfDiv {
        /* background-color: blue; */
        min-height:50px;
        max-height:50px;
        /* color:black */
      }

.halfReqSelRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 94%;
  margin-left: 3%;
  border-top: 1px solid #dde9f9;
  align-items: center;
  padding-top: 5px;
}

.x991010101111 {
  padding-top:1px;
}

.halfReqTitleTxt {
  font-size: 10px;
  font-weight: 500;
  color: #214978;
  margin-right: 5px;
  margin-left: 10px
}

  .halfReqSelRowBlock {
    display: flex;
    align-items: center;
  }

  .halfReqSelRowBlockInline {
    display: flex;
    align-items: center;
  }

  .closedReqDaySelector {
color:#bbcada  }

.fsReqblock {
  margin-top:5px;
}

.x1981911311 {
  border-left: 1px solid #dde9f9;
  margin-left: 12px;}

  .halfSelStartReqItem {
    background: rgb(255,255,255);
    background: linear-gradient(135deg, rgba(255,255,255,0) 50%, rgba(20,48,81,1) 50%);  
  }

    .halfSelStartReqItem:hover {
      background: rgb(255,255,255);
      background: linear-gradient(135deg, rgba(255,255,255,0) 50%, rgba(20,48,81,1) 50%);  
    }

    .x88s {
      color:#143051
    }

    .x881jj {
      color: #143051 !important;
    }

  .halfSelEndReqItem {
    background: rgb(255,255,255);
    background: linear-gradient(310deg, rgba(255,255,255,0) 50%, rgba(20,48,81,1) 50%);  }


  .halfSelEndReqItem:hover {
    background: rgb(255,255,255);
    background: linear-gradient(310deg, rgba(255,255,255,0) 50%, rgba(20,48,81,1) 50%);  }


    .reqMonthDropMakerDropdown {
      border-radius: 5px;
      font-size: 14px;
      font-weight: 500;
      padding-left: 12px;
      text-align: left !important;
      max-width: 160px;
      min-width: 160px;
      line-height: 26px;
      height: 35px;
      -webkit-appearance: none;
      background-position-x: 92px;
      display: flex;
      cursor: pointer;
      margin-left: 20px;
      /* margin-top: 18px; */
      margin: 0px auto;
      border: 1px solid #e6eaee;
      background-color: #0bf;
      background-color: white;
      color: #3b597c;
      background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right white;
      background-position-x: 135px;
    }
    @media only screen and (min-width: 820px)  {

    .reqMonthDropMakerDropdown:hover {
      border-color:#d7dfe9
    }
  }

  .makeReqFooterLeft {
    display: flex;
    align-items: center;
    margin-top:-6px
  }

  .reqBackBtn {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 60px;
    padding-left: 4px;
    padding-right: 4px;
    min-height: 35px;
    max-height: 35px;
    border: solid 1px #bbcada;
    background-color: #cbd7e6;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 0px;
    margin-left: 10px;
  }

  .reqBackBtn:hover {
    background-color: #bbcada;
  }

  .insufficAllow {
    color: #ba0000;
    margin-top: 40px;
    margin-bottom: -50px;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 14px;
    font-weight: 500;
    margin-left: 27px;
    border-left: 3px solid #ba0000;  }

    .sufficAllow {
      color: #6eb450;
      margin-top: 40px;
      margin-bottom: -50px;
      max-width: -moz-fit-content;
      max-width: -webkit-fit-content;
      max-width: fit-content;
      /* padding-left: 8px; */
      padding-right: 8px;
      font-size: 14px;
      font-weight: 500;
      margin-left: 27px;
      /* border-left: 3px solid #ba0000; */
      display: flex;
  
    align-items: center;
    }

      .sufficTickImg {
        width: 14px;
        margin-left: 7px;
      }






.addReqTextBox {
  min-width: 90%;
  max-width: 90%;
  min-height: 130px;
  resize: none;
  border-radius: 5px;
  margin-top: 8px;
  font-size: 1em;
  overflow: scroll;
  line-height: 20px;
  font-size: 16px;
  margin-top: 9px;
  font-weight: 400;
  color: #496375;
  /* border: none; */
  border: 2px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px;
  color: #6a8bb1;
  padding: 8px;
  font-weight: 500;

    }

    .addReqTextBox:focus {
      border-color:#bbcada
    }

    .addReqTextBox::-webkit-input-placeholder {
      color:#bbcada
    }

    .addReqTextBox:-ms-input-placeholder {
      color:#bbcada
    }

    .addReqTextBox::placeholder {
      color:#bbcada
    }

.clearReqNoteBtn {
  color: #bbcada;
  font-weight: 500;
  font-size: 14px;
  margin-right: 12px;
  cursor: pointer;}

  .clearReqNoteBtn:hover {
    color:#a0b0c1
  }

  .x9900ss {
    background-color: #4f657f !important;
    border-radius:5px;
  }

  .lastHovReqDs {
    background-color: #143051 !important; 
  }

  .x98298131133 {
    /* color:orange */
  }

  .openSnakItem {
    border-radius:7px 0 0 7px;
  }

  .openSnakItem:hover {
    border-radius:7px 0 0 7px;

  }


  .absenceModalMgr {
    background-color: #e5eaf1 !important;
  }


  .tilExtra {
    margin-top:6px
  }
.tilExtraRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-weight: 500;
  font-size: 12px;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 20px;
  margin-bottom: 2px;
  margin-top: 0px;
}

.tilExTitle {
  max-width: 170px;
  text-align: left;
  opacity: 0.5;
  font-size: 10px;
}

.showConvArr {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  border: 1px solid #8ea7c5;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  min-width: 96px;
  min-height: 24px;
  padding-bottom: 1px;
  margin-top:10px;
  margin-right:14px;
  cursor: pointer;
}
@media only screen and (min-width: 820px)  {
  .showConvArr:hover {
    border-color: #7593b6;
  }
}

.convWhiteTri {
  max-width: 6px;
  margin-left:5px;
  margin-top: 2px;

}

.convWhiteTriActive {
  /* transform: rotate(-90deg); */
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  overflow: hidden;
  transition-duration: 0.25s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.convArr {
  margin-top:50px;
}

.addToCalBtn {
  max-height: 35px;
  font-size: 12px;
  max-width: 70px;
  text-align: left;
  padding-left: 8px;
  line-height: 12px;
  font-weight: 500;
  padding-top: 5px;}
.menuMainContainer {
  background-color: #143051;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  max-width: 100vw;
  /* border-radius: 20px 20px 0 0; */
  /* padding-bottom: -60px; */
  background: rgb(20, 48, 81);
  background: linear-gradient(
    321deg,
    rgba(20, 48, 81, 1) 50%,
    rgba(0, 119, 255, 1) 120%
  );
}

.actionTaskBarContainer {
  background-color: #234f83;
  min-height: 50px;
  max-height: 50px;
  display: flex;
  min-width: 100vw;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 58px;
  display: none;
}

.youHaveRequestsToActionTxt {
  color: white;
  font-size: 0.9em;
  font-weight: 500;
}

.addBtnsBg {
  background: rgba(0, 0, 0, 0.7);
  min-width: 100vw;
  min-height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  /* z-index: 999; */
}

.menuAddButtonArea {
  /* background-color: #1a406b; */
  max-height: 150px;
  min-height: 160px;
  width: 280px;
  display: flex;
  justify-content: flex-end;
  padding-right: 25px;
  position: fixed;
  bottom: 78px;
  right: 13px;
  color: white;
}

.menuAddSpaceL {
  /* background-color: orange; */
  display: block;
  /* text-align: right; */
  min-width: 200px;
  /* position: fixed; */
  /* right: 25px; */
}

.menuAddSpaceR {
  /* background-color: yellow; */
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  /* position: fixed; */
  /* right: 25px; */
}

.menuAddAbsenceBtn {
  background-color: #00ccff;
  line-height: 36px;
  height: 34px;
  padding-left: 13px;
  padding-right: 13px;
  border-radius: 30px;
  /* padding-top: 1px; */
  font-weight: 500;
  text-align: center;
  max-width: 105px;
  margin-left: 20px;
  font-size: 1.2em;
}

.menuAddOvertimeBtn {
  background-color: #00ccff;
  line-height: 36px;
  height: 34px;
  max-width: 112px;
  padding-left: 13px;
  padding-right: 13px;
  border-radius: 30px;
  /* padding-top: 1px; */
  font-weight: 500;
  text-align: center;
  margin-top: 13px;
  margin-left: 37px;
  font-size: 1.2em;
}

.menuAddShiftBtn {
  background-color: #00ccff;
  line-height: 36px;
  height: 34px;
  max-width: 76px;
  padding-left: 13px;
  padding-right: 13px;
  border-radius: 30px;
  /* padding-top: 1px; */
  font-weight: 500;
  text-align: center;
  margin-top: 13px;
  margin-left: 62px;
  font-size: 1.2em;
}

.menuAddLeaveBtn {
  background-color: #fff;
  line-height: 34px;
  height: 34px;
  color: #00ccff;
  max-width: 78px;
  padding-left: 13px;
  padding-right: 13px;
  border-radius: 30px;
  padding-top: 1px;
  font-weight: 500;
  text-align: center;
  margin-top: 13px;
  margin-left: 98px;
  font-size: 1.2em;
}

.menuAddPlusBtn {
  min-height: 55px;
  max-height: 55px;
  min-width: 55px;
  max-width: 55px;
  background-color: #234f83;
  color: #fff;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  line-height: 55px;
  font-size: 2.7em;
  border-radius: 100%;
  font-weight: 500;
}

.menuAddPlusBtnInactive {
  min-height: 55px;
  max-height: 55px;
  min-width: 55px;
  max-width: 55px;
  background-color: #fff;
  color: #234f83;
  line-height: 55px;
  font-size: 2.7em;
  border-radius: 100%;
  font-weight: 500;
  position: fixed;
  bottom: 98px;
  right: 38px;
}

.heresYourLeaveTxt {
  color: white;
  text-align: left;
  font-weight: 500;
  margin-top: 25px;
  margin-left: 25px;
  font-size: 1.1em;
}

/*  day user request modal */
.reqModalBackdrop {
  background-color: #143051;
  /* background-color: rgb(21, 31, 45, 0.95); */

  /* background: rgb(21, 31, 45);
  background: linear-gradient(
    0deg,
    rgba(21, 31, 45, 1) 0%,
    rgba(20, 48, 81, 1) 100%
  ); */
  min-height: 100vh;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999999999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.menuBottomContainer {
  background-color: #234f83;
  min-height: 100px;
  display: flex;
  position: absolute;
  min-width: 100%;
  bottom: 58px;
  min-height: 280px;
  border-radius: 35px 35px 0 0;
}

.numberFloated {
  font-size: 3.5em;
  margin-top: -32px;
  z-index: 0;
  position: fixed;
  color: white;
  font-weight: 500;
  margin-left: 28px;
}

.unitFloatedSpan {
  font-size: 0.3em;
  font-weight: 500;
  margin-left: -10px;
}

.menuBottomDataContainer {
  /* background-color: orange; */
  color: white;
  max-height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 44px;
}

.dataContainerUnit {
  padding-left: 10px;
  padding-right: 10px;
}

.dataUnitTitle {
  font-size: 1em;
  font-weight: 500;
  opacity: 0.7;
}

.dataUnitValue {
  margin-top: 5px;
  font-weight: 500;
  font-size: 2em;
  display: flex;
  justify-content: center;
}

.dataUnitValueSpan {
  font-weight: 300;
  font-size: 0.5em;
  margin-left: 2px;
  margin-top: 15px;
}

.dataUnitValueSpanNd {
  font-weight: 300;
  font-size: 0.35em;
  margin-left: 1px;
  margin-top: 5px;
}

.timeInputTrial {
  min-width: 90px;
  /* background-color: orange; */
  max-width: 90px;
  color: #8ea7c5;
  text-align: center;
  /* margin-left: 42px; */
  font-size: 1.2em;
  font-weight: 500;
  padding-bottom: 4px;
}

.menuWhiteOverlayContainer {
  /* background-color: yellow; */
  max-width: 100vw;
  min-width: 100vw;
  min-height: 220px;
  max-height: 240px;
  position: absolute;
  top: 0px;
  opacity: 0.1;
  z-index: -1;
}

.menuWhiteOverlaySquare {
  min-height: 170px;
  max-height: 170px;
  min-width: 100%;
  max-width: 100%;
  background-color: white;
  position: relative;
  z-index: 100;
  /* display: none; */
}

.homeBulge {
  max-width: 100%;
}

.menuTopRow1 {
  /* background-color: green; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-left: 4%; */
  /* padding-right: 4%; */
  margin-top: 20px;
  margin-left: 5%;
}

.editProfileImg {
  margin-top: 13px;
  /* margin-left: 24px; */
  opacity: 0.75;
  min-width: 28px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  cursor: pointer;
}

.menuName {
  color: white;
  font-weight: 500;
  /* font-size: 1.1em; */
  margin-top: -7px;
}

.menuRemaining {
  color: white;
  /* margin-top: 11px; */
  font-size: 1em;
  opacity: 0.75;
  margin-top: 3px;
}

.menuRemainingDays {
  color: white;
  /* margin-top: 11px; */
  font-size: 1em;
  opacity: 0.5;
  margin-top: 3px;
}

.menuRemainingBoldSpan {
  font-weight: 500;
}

.menuShiftTodayTxt {
  color: white;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  opacity: 0.3;
  display: flex;
  /* line-height: 22px; */
  /* min-height: 18px; */
}

.nextLeaveTxt {
  color: white;
  margin-top: 3px;
  font-weight: 300;
  font-size: 14px;
  text-align: left;
  opacity: 0.3;
}

.menuClockOnDiv {
  /* background-color: white; */
  max-width: 96px;
  min-width: 96px;
  border-radius: 5px;
  display: flex;
  min-height: 35px;
  align-items: center;
  font-weight: 500;
  /* color: #516b8b; */
  font-size: 14px;
  justify-content: center;
  padding-right: 0px;
  padding-left: 0px;
  border: 2px solid #516b8b;
  cursor: pointer;
  color: white;
  background-color: #0bf;
  border: 2px solid #0af;
  /* margin-right: -10px; */
  /* margin: 16px auto; */
}

.menuClockOnDiv:active {
  -webkit-transform: scale(0.96);
          transform: scale(0.96);
  opacity: 0.9;
}
.menuClockOnPowerImg {
  margin-left: 6px;
  margin-right: 0px;
  margin-top: -1px;
  max-width: 14px;
}

.upcomingNotificationsContainer {
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
  padding-right: 5%;
  padding-left: 5%;
  font-size: 16px;
  font-weight: 500;
  color: white;
}

.fadedNotifUpcomingTxt {
  opacity: 0.5;
  margin-top: 5px;
}

.upcomingTitle {
  /* text-decoration: underline; */
  display: flex;
  padding-top: 6px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.none {
  display: none;
}

.upcomingUnderling,
.notifUnderling {
  min-height: 3px;
  background-color: #8ea7c5;
  min-width: 100%;
  margin-top: 2px;
  opacity: 0.7;
  border-radius: 10px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.notifCount {
  font-size: 12px;
  background-color: #0077ff;
  border-radius: 5px;
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  margin-top: -4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
}

.notifCountPurple {
  background-color: #a67cff;
}

.menuUpcNotifDivider {
  min-height: 1px;
  background-color: white;
  min-width: 90%;
  max-width: 90%;
  margin: 7px auto;
  opacity: 0.1;
  margin-bottom:0px;
}

.menuProfPicImg {
  max-width: 60px;
  min-width: 60px;
  border-radius: 10px;
  max-height: 70px;
  min-height: 70px;
  object-fit: cover;
  /* margin-top: -5px; */
  border: 3px #8ea7c5 solid;
}

.menuTopRow1SideUnit {
  min-width: 72px;
  /* margin-left: 14px; */
  /* background-color: blue; */
  display: flex;
  justify-content: center;
  margin-right: 20px;
}

.menuTopSectionContainer {
  display: flex;
  /* background-color: orange; */
  justify-content: space-between;
  min-height: 120px;
  align-items: center;
  margin-top: 16px;
}

.menuTopRow2 {
  /* background-color: yellow; */
  min-width: 188px;
  max-height: 180px;
  display: flex;
  flex-direction: column;
  padding-top: 6px;
  justify-content: space-around;
  text-align: left;
  padding-left: 10px;
}

.menuTopDivider {
  background-color: white;
  opacity: 0.4;
  min-height: 1px;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
  margin-bottom: 12px;
}

.topMenuContent {
  /* background-color: blue; */
  min-height: 200px;
  /* max-height: 200px; */
  -webkit-animation-name: fadeModalIn !important;
          animation-name: fadeModalIn !important;
  -webkit-animation-duration: 0.2s !important;
          animation-duration: 0.2s !important;
  /* animation-name: zoomModalIn !important; */
  /* animation-duration: 0.2s !important; */
}

.topMenuContentLoad {
  /* background-color: blue; */
  min-height: 200px;
  /* animation-name: fadeModalIn !important;
  animation-duration: 0.1s !important; */
}

.menuLoadingImg {
  max-width: 50px;
  margin-top: 70px;
}

.shiftTodayRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: blue; */
  padding-left: 7%;
  padding-right: 7%;
  margin-top: -4px;
  min-height: 20px;
}

.menuAllowancesContainer {
  min-height: 400px;
  /* background-color: rgba(255, 255, 255, 0.8); */
  background-color: #dde9f9;
  border-radius: 15px 15px 0 0;
  /* border-top: 2px solid #143051; */
  /* background-color: white; */
  min-height: calc(100vh - 46px);
  max-height: calc(100vh - 186px);
  /* animation-name: heightDown;
  animation-duration: 0.1s; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 9999999;
  position: fixed;
  bottom: 0;
  min-width: 100vw;

  
}

.allowPanelTop {
  /* background-color: yellow; */
  /* background-color: yellow; */
  min-height: 60px;
  padding-top: 20px;
  text-align: left;
  padding-left: 5%;
  padding-right: 5%;
  color: #143051;
  font-weight: 500;
  font-size: 14px;
  line-height:23px;
}

.excessMpdBold {
  font-weight: 500;
  background-color: #2066b6;
  color: white;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  white-space: nowrap
}
.allowPanelMiddle {
  /* background-color: green; */
}

.allowPanelBottom {
  /* background-color: blue; */
  padding-bottom: 20px;
  padding-top: 5px;
  margin-bottom: 8px;
}

.menuAllowancesPullUpIcon {
  max-width: 70px;
  margin-bottom: 14px;
  margin-top: 8px;
  opacity: 0.8;
  cursor: pointer;
}

.nextLeaveRow {
  margin-top: -10px;
  font-weight: 300;
  font-size: 0.9em;
}

.nextShiftAndNextLeaveDiv {
  max-height: 40px;
  margin-top: 4px;
  /* opacity: 0.3; */
  /* padding-bottom: 4px; */
  /* background-color: blue; */
}

.allowPanelMiddle {
  /* background-color: yellow; */
  margin-top: 20px;
}

.excessDataRow {
  /* background-color: orange; */
  min-width: 90%;
  max-width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 5%;
  min-height: 40px;
  color: #143051;
  border-bottom: 1px solid #516b8b;
}

.excessDataRow:last-child {
  border: none;
}

.menuExcessTitle {
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  color: #214978;
}

.menuExcessTitleHistory {
  font-weight: 500;
  max-width: 70%;
  text-align: left;
  font-size: 0.94em;
  margin-top: 12px;
}
.allowPanelTopAndMiddle {
  border-bottom: 1px solid #b5c4d5;
  max-height: 100%;
  overflow-y: scroll;
  z-index: 99;
  padding-bottom:40px;
  margin-top:-21px;
  padding-top:20px;
}
.excessHistoryRowDataDiv {
  /* background-color: purple; */
  margin-top: 20px;
}
.excessHistoryRow {
  /* background-color: yellow; */
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  text-align: left;
  /* min-height: 120px; */
  border-bottom: 1px solid #516b8b;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5%;
}

.excessHistoryRow:last-child {
  border: none;
}

.menuExcessValue {
  color: #0af;
  font-weight: 500;
  font-size: 14px;
}

.excessHistoryRowStart {
  color: #143051;
  font-weight: 500;
  margin-top: 14px;
  margin-bottom: 15px;
  font-size: 16px;
}

.excessHistoryRowTable {
}

.excessHistoryRowTableRow {
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  padding-right: 5%;
  align-items: center;
  min-height: 40px;
  font-size: 0.9em;
  color: #2066b6;
  border-top: 1px solid #8ea7c5;
}

.excessHistoryRowTableRowFirst {
  border: none;
}

.excessHistoryRowTableRowTitle {
  max-width: 70%;
  color: #859dbb;
  font-weight: 500;
  line-height:14px;
}

.excessHistoryDataTitle {
  font-weight: 500;
  text-align: left;
  margin-top: 40px;
  color: #fff;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  background-color: #0077cc;
  margin-left: 8%;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  border: 1px solid #2066b6;
  margin-top: 70px;
  padding-bottom: 1px;
}

.noBorderExcessData {
  border: none;
}

.showProfileStatsBtn {
  background-color: white;
  color: #0077dd;
  background-color: #184479;
  color: #fff;
  font-weight: 500;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  min-height: 30px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #143051;
  cursor: pointer;
  font-size: 14px;
  height: 35px;
}

.hideProfileStatsBtn {
  background-color: #143051;
  color: #fff;
  font-weight: 500;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  /* min-width: 90px; */
  padding-left: 6px;
  padding-right: 6px;
  min-height: 30px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #143051;
  cursor: pointer;
  font-size: 12px;
}

.profileStatsWhiteCross {
  margin-right: 5px;
  margin-top: 1px;
}

.menuMgrNotifsBar {
  /* background-color: rgb(255, 255, 255, 0.05); */
  margin-top: 7px;
  min-height: 35px;
  align-items: center;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;

  display: flex;
  flex-wrap: wrap;
}

.menuUpcomingHolder {
  /* background-color: blue; */
  min-height: calc(100vh - 291px);
  max-height: calc(100vh - 291px);
  min-width: 100vw;
  overflow-y: auto;
  padding-top: 10px;
}

.upcItemParent {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  margin-left: 4%;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.upcItemL {
  background-color: rgba(1, 120, 255, 1);
  min-width: 43px;
  max-width: 43px;
  min-height: 30px;
  border-radius: 10px 0 0 10px;
  z-index: 22;
  display: flex;
  color: white;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 12px;
  padding-left: 7px;
  text-align: left;
}

.upcItemLToday {
  font-size: 11px;
  padding-left: 5px;
}

.upcItemLsmall {
  font-size: 10px;
  line-height: 11px;
  padding-left: 5px;
  text-align: center;
  /* padding-right:1px; */
}
.upcItemR {
  min-width: calc(100% - 33px);
  border-radius: 10px;
  max-width: 90%;
  margin-left: -25px;

  /* margin-left:5%; */
  min-height: 70px;
  background: rgb(1, 120, 255);
  background: linear-gradient(
    90deg,
    rgba(1, 120, 255, 1) 8%,
    rgba(37, 73, 114, 1) 100%
  );
  padding-left: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #243a55;
}

.absentUpcItemL {
  background: rgb(176 86 86) 8%;

}

.absentUpcItemR {
  background: rgb(176 86 86) 8%;
  background: linear-gradient(
    90deg,
    rgb(176 86 86) 8%,
    rgb(139 31 31) 100%
  )}
.tilOvertimeUpcItemR {
  background: rgb(0, 204, 255);
  background: linear-gradient(
    90deg,
    rgba(0, 204, 255, 1) 8%,
    rgba(37, 73, 114, 1) 100%
  );
}

.tilOvertimeUpcItemRBlob {
  background-color: rgb(0, 204, 255);
}

.absenceUpcItemR {
  background: rgb(120, 164, 215);
  background: linear-gradient(
    90deg,
    rgba(120, 164, 215, 1) 8%,
    rgba(37, 73, 114, 1) 100%
  );
}

.absenceUpcItemRBlob {
  background-color: rgb(120, 164, 215);
}

.leaveUpcItemR {
  background: rgb(19, 217, 169);
  background: linear-gradient(
    90deg,
    rgba(19, 217, 169, 1) 8%,
    rgba(37, 71, 112, 1) 100%
  );
}

.leaveUpcItemRBlob {
  background-color: rgb(19, 217, 169);
}

.pHolUpcItemR {
  background: #143041;
  border: 2px dotted #214978;
}

.pHolUpcItemRBlob {
  background-color: #143041;
}

.notificationsBox {
  padding-top: 10px;
  max-height: calc(100vh - 290px);
  overflow-y: auto;
  border-top: 1px solid #223c5c;
  -webkit-animation: notifsFadeIn 0.1s ease-in-out;
          animation: notifsFadeIn 0.1s ease-in-out; /* Adjust the duration and timing function as needed */


}


.upcomingScrollBox {
  padding-top: 10px;
  max-height: calc(100vh - 340px);
  overflow-y: auto;
  border-top: 1px solid #223c5c;
  -webkit-animation: notifsFadeIn 0.1s ease-in-out;
          animation: notifsFadeIn 0.1s ease-in-out; /* Adjust the duration and timing function as needed */

}

.notificationsBoxMob {
  max-height: calc(100vh - 28px);}

@-webkit-keyframes notifsFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes notifsFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.upcItemRightLeft {
  /* background-color: yellow; */
  min-width: 57%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.upcItemRightRight {
  /* background-color: lime; */
  min-width: 33%;
  margin-right: 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}

.upcItemRightRightTop {
  font-weight: 500;
  font-size: 16px;
  color: #a8d1ff;
  white-space: nowrap;

}

.upcItemRightRightBottom {
  font-size: 12px;
  font-weight: 500;
  color: #b7c3d1;
}

.tilTxtSize {
  font-size: 16px;
  line-height: 16px;
}

.upcomingDatesParent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 2px;
}

.upcomingDateToken {
  min-width: 46px;
  min-height: 46px;
  border-radius: 10px;
  /* margin-top:1px; */
  /* border: 1px solid #143051 */
}

.upcTokenTop {
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  border: 3px solid #143051;
  border-radius: 10px 10px 0 0;
  min-height: 36px;
  line-height: 22px;
  padding-top: 1px;
  background-color: #f4f8fe;
  color: #143051;
  display: flex;
  justify-content: center;
}

.upcTokenBottom {
  font-weight: 500;
  font-size: 12px;
  color: #516b8b;
  border: 3px solid #516b8b;
  border-radius: 3px;
  margin-top: -10px;
  background-color: white;
  border-radius: 8px 8px 10px 10px;
  padding-bottom: 1px;
  min-height: 22px;
}

.upcTokenDivider {
  color: white;
  margin-left: 3px;
  margin-right: 3px;
  font-size: 20px;
  font-weight: 500;
}

.upcTokenHalf {
  font-weight: 500;
  font-size: 14px;
  margin-left: 1px;
  opacity: 0.6;
}

.upcLeaveDur {
  font-weight: 500;
  color: white;
  line-height: 14px;
  text-align: left;
  font-size: 12px;
  /* padding-right:8px; */
  margin-left: 8px;
}

.notYetStartedUpcoming {
  font-size: 14px;
  margin-left: 10px;
  line-height: 16px;
}

.desktopUpcomingTitle {
  color: white;
  font-weight: 500;
  text-align: left;
  margin-top: 3px;
  margin-left: 13px;
  font-size: 16px;
  margin-bottom: 8px;
  line-height: 26px;
}

.loadUpcomingMoreBtn {
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
  color: white;
  cursor: pointer;
  border: 1px solid #8b9db0;
  max-width: 100px;
  border-radius: 35px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 20px;
  padding-left:10px;
  padding-right:10px;

}

.loadUpcomingMoreBtn:hover {
  opacity: 0.8;
}

.upcShiftInfo {
  /* background-color: blue; */
  max-height: 50px;
  margin-left: 10px;
}

.upcShiftInfoTop {
  color: white;
  font-weight: 500;
  text-align: left;
  font-size: 14px;
  max-width: 306px;
}

.upcShiftInfoBottom {
  color: #b8d6f9;
  font-weight: 500;
  text-align: left;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-top: 2px;
}

.upcTagsSpan {
  display: flex;
  margin-left: 5px;
  border-left: #b8d6f9 1px solid;
  padding-left: 5px;
  line-height: 11px;
  font-size: 10px;
}

.upcShiftTime {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.pHolSpecDayWidthUpcRight {
  min-width: 50%;
}

.specDayRightLeft {
  /* background-color: yellow; */
  min-width: 00%;
}

.pHolStatus {
  opacity: 0.5;
}

.menuNoProPic {
  max-width: 44px;
  max-height: 44px;
  min-width: 44px;
  min-height: 44px;
}

.menuProPicPlaceholder {
  max-width: 44px;
  max-height: 44px;
  min-width: 44px;
  min-height: 44px;
  border: 2px solid #143051;
  border-radius: 5px;
  /* object-fit: cover; */
}

.menuProPicInits {
  margin-top: -37px;
  opacity: 0.5;
  color: white;
  font-weight: 500;
  /* display: none; */
}
.excessHistoryRowTableRowValue {
  font-weight: 500;
}


.loadingExcessDiv {
  border-bottom: 1px solid #b5c4d5;
  max-height: 100%;
  overflow-y: scroll;
  z-index: 99999999999999;
  min-height: calc(100vh - 186px);
  max-height: calc(100vh - 186px);
}

.excessLoadingMenu {
  min-height:80px;
  max-height:80px;
}

.loadingExcessImg {
  max-width:100px;
  margin-top:33px;
}

.clockOffBtnActive {
  background-color: #1e88e5;
  border: 2px solid #1d71d1;
}

.clockedBrkBtnTxtSize {
min-width:131px;
}

.menuSuff {
  font-size: 8px;
  margin-right: 4px;}



  .x213982 {
    margin-top: -5px;
  }


  .clockRowDs {
    font-weight:600;
    font-size:12px;
    color: #bbcada;
    margin-top:4px;
    margin-left:10px;
    display: flex;
    align-items: center;
  }

  .clockDeductImg {
max-width: 15px;
border:1px solid #dee7f0;
border-radius:3px;
margin-left:4px;
margin-right:4px;
cursor: pointer;

}
@media only screen and (min-width: 820px) {
  .clockDeductImg:hover {
    background-color: #dee7f0;
  }
}



.warnRedMinus {
  border-color: #ba0000 !important;
  background: #c43b3b;}

  .invalidClockNameRow {
    margin-bottom:18px;
  }

.invalidClockOffTime {
  pointer-events: none;
  opacity: 0.3;
}

.x131212 {
  min-width: calc(100% - 15px);
  /* background: blue; */
  display: flex;
  justify-content: center;
  max-width: calc(100% - 15px);
  font-size:16px;
  margin-top:20px;
  margin-bottom:14px;
}

.x0910931 {
  margin-right: 10px;
  margin-left: 10px;
  min-width: 23px;}

  .x3893881 {
    min-width: 90px
  }

  .clockOffInputTime {
    background-color: #fff;
    width: 77px;
    max-width: 77px;
    line-height: 30px;
    padding-top: 0px;
    height: 35px;
    border: 2px solid #dde9f9;
    border-radius: 5px;
    text-align: center;
    color: #496375;
    font-size: 16px;
    font-weight: 500;
    /* -webkit-appearance: none; */
    /* -moz-appearance: none; */
    /* appearance: none; */
    cursor: text;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 3px;
    }

    .x1289347 {
      align-items: center;
      display: flex;
      flex-direction: column;    }

      .mgrMobTopRight {
        min-height: 40px;
        min-width: calc(100vw - 132px);
        /* background-color: blue; */
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: fixed;
        top: 0;
        right: 0;
      }


      .mgrMobTopRightBtn {
        font-size: 10px;
        font-weight: 500;
        color: white;
        background: #214978;
        border: 1px solid #143051;
        border-radius: 5px;
        height: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 5px;
        padding-right: 4px;
        margin-right: 5px;
      }

      .mgrMobTopRightBtnQty {
        background-color: #a387df;
    border-radius: 3px;
    margin-left: 5px;
    padding: 2px;
    font-size: 9px;
    min-width: 15px;
    padding-left: 4px;
    padding-right: 4px;
      }
      



      .menuMobHeaderSec {
        min-height: 200px;
        margin-bottom: -179px;
        z-index: 999;   
      /* background-color: blue;  */
      }
@font-face {
  font-family: "timelify";
  font-style: bold;
  font-weight: 600;
  /* src: url("../fonts/open-sans-v13-latin-regular.eot"); IE9 Compat Modes */
  src: local("timelify-bold"), local("timelify-bold"),
    url(/static/media/timelify-bold.3579c701.otf) format("truetype");
}
@font-face {
  font-family: "timelify";
  /* font-style: medium; */
  font-weight: 500;
  /* src: url("../fonts/open-sans-v13-latin-regular.eot"); IE9 Compat Modes */
  src: local("timelify-medium"), local("timelify-medium"),
    url(/static/media/timelify-medium.4f5e1d6a.otf) format("truetype");
}

@font-face {
  font-family: "timelify";
  font-style: normal;
  font-weight: 400;
  /* src: url("../fonts/open-sans-v13-latin-regular.eot"); IE9 Compat Modes */
  src: local("timelify-normal"), local("timelify-normal"),
    url(/static/media/timelify-normal.79fe5d72.otf) format("truetype");
}

@font-face {
  font-family: "timelify";
  /* font-style: light; */
  font-weight: 300;
  /* src: url("../fonts/open-sans-v13-latin-regular.eot"); IE9 Compat Modes */
  src: local("timelify-light"), local("timelify-light"),
    url(/static/media/timelify-light.38e58d62.otf) format("truetype");
}

@font-face {
  font-family: "timelify";
  /* font-style: extralight; */
  font-weight: 200;
  /* src: url("../fonts/open-sans-v13-latin-regular.eot"); IE9 Compat Modes */
  src: local("timelify-extralight"), local("timelify-extralight"),
    url(/static/media/timelify-extralight.ec51fc65.otf) format("opentype");
}

a,
a:link,
a:visited,
a:hover,
a:focus,
a:active {
  color: inherit;
  text-decoration: none;
}

body {
  /* overflow: hidden; */

  /* background-attachment: fixed; */
/* position: fixed; */
/* top:0; */
/* left:0; */
  /* disable blue highlight */
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

html, body {
  /* Prevent zooming in and out */
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  touch-action: manipulation; /* Prevent double-tap zoom on mobile devices */
  max-zoom: 100%; /* Prevent zooming on some older mobile browsers */
  -ms-text-size-adjust: 100%; /* IE/Edge specific */
  -ms-content-zooming: none; /* IE/Edge specific */
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  min-width: 100%;
  zoom: 1; /* Prevent zoom on older IE versions */
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  /* overflow:hidden; */
  /* display: none; */
}

.App {
  /* position:fixed;
  top: 0px; left:0px; */
  /* left:100px */
    /* display: none !important; */

  background-color: #ebf0f7;
  display: flex;
  justify-content: center;
  /* background-color: blue; */
  /* mix-blend-mode: overlay; */
}

.wrapper {
  background-color: #ebf0f7;
  /* background-color:orange; */
  max-width: 1140px;
  /* overflow:hidden; */
  /* z-index:200000; */
  /* border-right: 1px solid #e6eaee; */
  /* margin: 0 auto; */

  /* position: fixed;top:0; */
}

@media only screen and (max-width: 1141px) {
  .wrapper {
    border-right: none;
  }
}

@media only screen and (max-width: 820px) {
  .wrapper {
    position: fixed;
    top: 0px;
    left: 0px;
  }
}

/* @media only screen and (max-width: 820px) {
  .App {
    position: fixed;
    top: 0px;
    left: 0px;
  }
} */

@media only screen and (min-width: 2614px) {
  .wrapper {
    border-right: 1px solid #e6eaee;
  }
}

.imgPreloads {
  position: absolute;
  visibility: hidden;
}

.loaderBg {
  background-color: white;
  z-index: 100000000;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
}

.loaderlogo {
  max-width: 70px;
  margin-top: -100px;
}

.hideLoader {
  display: none;
}

/* animations */
@-webkit-keyframes zoomModalIn {
  from {
    /* bottom: -10%; */
    opacity: 0;
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
  }
  to {
    /* bottom: 0; */
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes zoomModalIn {
  from {
    /* bottom: -10%; */
    opacity: 0;
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
  }
  to {
    /* bottom: 0; */
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes rotateClockWise45 {
  from {
    /* bottom: -10%; */
    /* opacity: 0; */
    /* margin-top: 0px; */
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    /* bottom: 0; */
    -webkit-transform: rotate(45);
            transform: rotate(45);
    /* margin-top: 1px; */
  }
}

@keyframes rotateClockWise45 {
  from {
    /* bottom: -10%; */
    /* opacity: 0; */
    /* margin-top: 0px; */
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    /* bottom: 0; */
    -webkit-transform: rotate(45);
            transform: rotate(45);
    /* margin-top: 1px; */
  }
}

@-webkit-keyframes zoomAddBtnIn {
  from {
    /* bottom: -10%; */
    /* opacity: 0.35; */
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
  }
  to {
    /* bottom: 0; */
    /* opacity: 0.35; */
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes zoomAddBtnIn {
  from {
    /* bottom: -10%; */
    /* opacity: 0.35; */
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
  }
  to {
    /* bottom: 0; */
    /* opacity: 0.35; */
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/* animations */
@-webkit-keyframes zoomBtnIn {
  from {
    /* bottom: -10%; */
    opacity: 1;
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
  }
  to {
    /* bottom: 0; */
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes zoomBtnIn {
  from {
    /* bottom: -10%; */
    opacity: 1;
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
  }
  to {
    /* bottom: 0; */
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes fadeModalIn {
  from {
    /* bottom: -10%; */
    opacity: 0;
    /* transform: scale(0.4); */
  }
  to {
    /* bottom: 0; */
    opacity: 1;
    /* transform: scale(1); */
  }
}

@keyframes fadeModalIn {
  from {
    /* bottom: -10%; */
    opacity: 0;
    /* transform: scale(0.4); */
  }
  to {
    /* bottom: 0; */
    opacity: 1;
    /* transform: scale(1); */
  }
}

@-webkit-keyframes slideSubBarDown {
  from {
    margin-top: -40px;
    opacity: 0;
    -webkit-transform: scale(0.4);
            transform: scale(0.4);
    z-index: -1;
  }
  to {
    margin-top: -15px;
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    z-index: -1;
  }
}

@keyframes slideSubBarDown {
  from {
    margin-top: -40px;
    opacity: 0;
    -webkit-transform: scale(0.4);
            transform: scale(0.4);
    z-index: -1;
  }
  to {
    margin-top: -15px;
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    z-index: -1;
  }
}



@-webkit-keyframes slideSubBarDownNoZoom {
  from {
    margin-top: -40px;
    opacity: 0;
    /* transform: scale(); */
    z-index: -1;
  }
  to {
    margin-top: -15px;
    opacity: 1;
    /* transform: scale(1); */
    z-index: -1;
  }
}



@keyframes slideSubBarDownNoZoom {
  from {
    margin-top: -40px;
    opacity: 0;
    /* transform: scale(); */
    z-index: -1;
  }
  to {
    margin-top: -15px;
    opacity: 1;
    /* transform: scale(1); */
    z-index: -1;
  }
}

@-webkit-keyframes slideDesktopMenuDown {
  from {
    /* top: 150px; */
    max-height: 10px;
    /* opacity: 0; */
    /* transform: scale(); */
    z-index: -1;
  }
  to {
    /* top: 162px; */
    /* max-height: 211px; */

    /* opacity: 1; */
    /* transform: scale(1); */
    z-index: -1;
  }
}

@keyframes slideDesktopMenuDown {
  from {
    /* top: 150px; */
    max-height: 10px;
    /* opacity: 0; */
    /* transform: scale(); */
    z-index: -1;
  }
  to {
    /* top: 162px; */
    /* max-height: 211px; */

    /* opacity: 1; */
    /* transform: scale(1); */
    z-index: -1;
  }
}

@-webkit-keyframes slideDesktopMenuDown2 {
  from {
    /* top: 150px; */
    max-height: 10px;
    min-height: 0vh;

    /* opacity: 0; */
    /* transform: scale(); */
    z-index: -1;
  }
  to {
    /* top: 162px; */
    /* max-height: 211px; */
    min-height: 60vh;
    /* opacity: 1; */
    /* transform: scale(1); */
    z-index: -1;
  }
}

@keyframes slideDesktopMenuDown2 {
  from {
    /* top: 150px; */
    max-height: 10px;
    min-height: 0vh;

    /* opacity: 0; */
    /* transform: scale(); */
    z-index: -1;
  }
  to {
    /* top: 162px; */
    /* max-height: 211px; */
    min-height: 60vh;
    /* opacity: 1; */
    /* transform: scale(1); */
    z-index: -1;
  }
}

@-webkit-keyframes slideModalUp {
  from {
    margin-top: 100vh;
    /* opacity: 0; */
    /* transform: scale(0.4); */
  }
  to {
    margin-top: 0vh;
    /* opacity: 1; */
    /* transform: scale(1); */
  }
}

@keyframes slideModalUp {
  from {
    margin-top: 100vh;
    /* opacity: 0; */
    /* transform: scale(0.4); */
  }
  to {
    margin-top: 0vh;
    /* opacity: 1; */
    /* transform: scale(1); */
  }
}

@-webkit-keyframes slideModalRight {
  from {
    margin-left: -200px;
    opacity: 0;
    /* transform: scale(0.4); */
  }
  to {
    margin-left: 0px;
    opacity: 1;
    /* transform: scale(1); */
  }
}

@keyframes slideModalRight {
  from {
    margin-left: -200px;
    opacity: 0;
    /* transform: scale(0.4); */
  }
  to {
    margin-left: 0px;
    opacity: 1;
    /* transform: scale(1); */
  }
}

@-webkit-keyframes slideMoveHere {
  from {
    /* margin-left: -50px; */
    /* max-width: 50px;
    min-width: 50px; */
    z-index: 1;
    opacity: 0.1;
    -webkit-transform: scale(0.4);
            transform: scale(0.4);
  }
  to {
    z-index: 1;

    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes slideMoveHere {
  from {
    /* margin-left: -50px; */
    /* max-width: 50px;
    min-width: 50px; */
    z-index: 1;
    opacity: 0.1;
    -webkit-transform: scale(0.4);
            transform: scale(0.4);
  }
  to {
    z-index: 1;

    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes slideModalLeft {
  from {
    margin-right: 200px;
    opacity: 0;
    /* transform: scale(0.4); */
  }
  to {
    margin-right: 0px;
    opacity: 1;
    /* transform: scale(1); */
  }
}

@keyframes slideModalLeft {
  from {
    margin-right: 200px;
    opacity: 0;
    /* transform: scale(0.4); */
  }
  to {
    margin-right: 0px;
    opacity: 1;
    /* transform: scale(1); */
  }
}

@-webkit-keyframes tipBox {
  from {
    margin-right: -340px;
    opacity: 0;
    /* transform: scale(0.4); */
  }
  to {
    margin-right: 0px;
    opacity: 1;
    /* transform: scale(1); */
  }
}

@keyframes tipBox {
  from {
    margin-right: -340px;
    opacity: 0;
    /* transform: scale(0.4); */
  }
  to {
    margin-right: 0px;
    opacity: 1;
    /* transform: scale(1); */
  }
}

@-webkit-keyframes heightDown {
  from {
    max-height: 0px;
    min-height: 0px;
    opacity: 0;

    /* transform: scale(0.4); */
  }
  to {
    /* margin-right: 0px; */
    opacity: 1;
    /* transform: scale(1); */
  }
}

@keyframes heightDown {
  from {
    max-height: 0px;
    min-height: 0px;
    opacity: 0;

    /* transform: scale(0.4); */
  }
  to {
    /* margin-right: 0px; */
    opacity: 1;
    /* transform: scale(1); */
  }
}

@-webkit-keyframes costDivDown {
  from {
    max-height: 0px;
    min-height: 0px;
    margin-bottom: 0px;

    /* opacity: 0; */
    /* transform: scale(0.4); */
  }
  to {
    /* margin-right: 0px; */
    /* opacity: 1; */
    margin-bottom: 24px;

    max-height: 120px;
    min-height: -webkit-fit-content;
    min-height: fit-content;
    /* transform: scale(1); */
  }
}

@keyframes costDivDown {
  from {
    max-height: 0px;
    min-height: 0px;
    margin-bottom: 0px;

    /* opacity: 0; */
    /* transform: scale(0.4); */
  }
  to {
    /* margin-right: 0px; */
    /* opacity: 1; */
    margin-bottom: 24px;

    max-height: 120px;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    /* transform: scale(1); */
  }
}

@-webkit-keyframes widthRight {
  from {
    max-width: 0px;
    min-width: 0px;
    opacity: 0;
    /* transform: scale(0.4); */
  }
  to {
    /* margin-right: 0px; */
    /* max-width: fit-content;
    min-width: fit-content; */
    opacity: 1;
    /* transform: scale(1); */
  }
}

@keyframes widthRight {
  from {
    max-width: 0px;
    min-width: 0px;
    opacity: 0;
    /* transform: scale(0.4); */
  }
  to {
    /* margin-right: 0px; */
    /* max-width: fit-content;
    min-width: fit-content; */
    opacity: 1;
    /* transform: scale(1); */
  }
}

@-webkit-keyframes rotateClockWise180 {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    /* margin-right: 0px; */
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    /* transform: scale(1); */
  }
}

@keyframes rotateClockWise180 {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    /* margin-right: 0px; */
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    /* transform: scale(1); */
  }
}

@-webkit-keyframes rotateAntiClockWise180 {
  from {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  to {
    /* margin-right: 0px; */
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    /* transform: scale(1); */
  }
}

@keyframes rotateAntiClockWise180 {
  from {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  to {
    /* margin-right: 0px; */
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    /* transform: scale(1); */
  }
}

@-webkit-keyframes slideMenuFromLeft {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 80vh;
  }
}

@keyframes slideMenuFromLeft {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 80vh;
  }
}
@-webkit-keyframes bar100 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 100%;
    min-width: 100%;
  }
}
@keyframes bar100 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 100%;
    min-width: 100%;
  }
}
@-webkit-keyframes bar95 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 95%;
    min-width: 95%;
  }
}
@keyframes bar95 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 95%;
    min-width: 95%;
  }
}

@-webkit-keyframes bar90 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 90%;
    min-width: 90%;
  }
}

@keyframes bar90 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 90%;
    min-width: 90%;
  }
}

@-webkit-keyframes bar85 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 85%;
    min-width: 85%;
  }
}

@keyframes bar85 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 85%;
    min-width: 85%;
  }
}

@-webkit-keyframes bar80 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 80%;
    min-width: 80%;
  }
}

@keyframes bar80 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 80%;
    min-width: 80%;
  }
}

@-webkit-keyframes bar75 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 75%;
    min-width: 75%;
  }
}

@keyframes bar75 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 75%;
    min-width: 75%;
  }
}

@-webkit-keyframes bar70 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 70%;
    min-width: 70%;
  }
}

@keyframes bar70 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 70%;
    min-width: 70%;
  }
}

@-webkit-keyframes bar65 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 65%;
    min-width: 65%;
  }
}

@keyframes bar65 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 65%;
    min-width: 65%;
  }
}

@-webkit-keyframes bar60 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 60%;
    min-width: 60%;
  }
}

@keyframes bar60 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 60%;
    min-width: 60%;
  }
}

@-webkit-keyframes bar55 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 55%;
    min-width: 55%;
  }
}

@keyframes bar55 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 55%;
    min-width: 55%;
  }
}

@-webkit-keyframes bar50 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 50%;
    min-width: 50%;
  }
}

@keyframes bar50 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 50%;
    min-width: 50%;
  }
}

@-webkit-keyframes bar45 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 45%;
    min-width: 45%;
  }
}

@keyframes bar45 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 45%;
    min-width: 45%;
  }
}

@-webkit-keyframes bar40 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 40%;
    min-width: 40%;
  }
}

@keyframes bar40 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 40%;
    min-width: 40%;
  }
}

@-webkit-keyframes bar35 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 35%;
    min-width: 35%;
  }
}

@keyframes bar35 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 35%;
    min-width: 35%;
  }
}

@-webkit-keyframes bar30 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 30%;
    min-width: 30%;
  }
}

@keyframes bar30 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 30%;
    min-width: 30%;
  }
}

@-webkit-keyframes bar25 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 25%;
    min-width: 25%;
  }
}

@keyframes bar25 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 25%;
    min-width: 25%;
  }
}

@-webkit-keyframes bar20 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 20%;
    min-width: 20%;
  }
}

@keyframes bar20 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 20%;
    min-width: 20%;
  }
}

@-webkit-keyframes bar15 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 15%;
    min-width: 15%;
  }
}

@keyframes bar15 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 15%;
    min-width: 15%;
  }
}

@-webkit-keyframes bar10 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 10%;
    min-width: 10%;
  }
}

@keyframes bar10 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 10%;
    min-width: 10%;
  }
}

@-webkit-keyframes bar05 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 05%;
    min-width: 05%;
  }
}

@keyframes bar05 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 05%;
    min-width: 05%;
  }
}

@-webkit-keyframes bar00 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 1%;
    min-width: 1%;
  }
}

@keyframes bar00 {
  from {
    max-width: 0px;
    min-width: 0px;
  }
  to {
    /* min-width: 80vh; */
    max-width: 1%;
    min-width: 1%;
  }
}

* {
  /* scrollbar-width: thin; */
  scrollbar-color: #143051 none;
}


@media only screen and (max-width: 820px) {
  * {
    scrollbar-width: thin;
  }
  
  }

*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

*::-webkit-scrollbar-track {
  /* background: none; */
}

*::-webkit-scrollbar-thumb {
  background-color: #c7daef;
  border-radius: 20px;
  border: 3px solid none;
}

*::-webkit-scrollbar-button {
  width: 5px;
  height: 5px;
}

@-webkit-keyframes slideDesktopSubMenuDownMyShifts {
  from {
    min-height: 0px;
    max-height: 0px;
  }
  to {
    min-height: 86px;
    max-height: 86px;
  }
}

@keyframes slideDesktopSubMenuDownMyShifts {
  from {
    min-height: 0px;
    max-height: 0px;
  }
  to {
    min-height: 86px;
    max-height: 86px;
  }
}

@-webkit-keyframes slideDesktopSubMenuDownMyAbsence {
  from {
    min-height: 0px;
    max-height: 0px;
  }
  to {
    min-height: 64px;
    max-height: 64px;
  }
}

@keyframes slideDesktopSubMenuDownMyAbsence {
  from {
    min-height: 0px;
    max-height: 0px;
  }
  to {
    min-height: 64px;
    max-height: 64px;
  }
}

@-webkit-keyframes slideDesktopSubMenuDownFourItems {
  from {
    min-height: 0px;
    max-height: 0px;
  }
  to {
    min-height: 118px;
    max-height: 118px;
  }
}

@keyframes slideDesktopSubMenuDownFourItems {
  from {
    min-height: 0px;
    max-height: 0px;
  }
  to {
    min-height: 118px;
    max-height: 118px;
  }
}

@-webkit-keyframes slideDesktopSubMenuDownFiveItems {
  from {
    min-height: 0px;
    max-height: 0px;
  }
  to {
    min-height: 150px;
    max-height: 150px;
  }
}

@keyframes slideDesktopSubMenuDownFiveItems {
  from {
    min-height: 0px;
    max-height: 0px;
  }
  to {
    min-height: 150px;
    max-height: 150px;
  }
}


/* Centralising desktop */
/* @media only screen and (min-width: 1260px) {
  .TopBar {
  left: calc(260px);
  border-left: 1px solid black
  }
}


@media only screen and (min-width: 1300px) {
  .TopBar {
  left: calc(300px);
  border-left: 1px solid black
  }
}




@media only screen and (min-width: 1340px) {
  .TopBar {
  left: calc(340px);
  border-left: 1px solid black
  }
}




@media only screen and (min-width: 1380px) {
  .TopBar {
  left: calc(380px);
  border-left: 1px solid black
  }
}


@media only screen and (min-width: 1420px) {
  .TopBar {
  left: calc(420px);
  border-left: 1px solid black
  }
}


@media only screen and (min-width: 1460px) {
  .TopBar {
  left: calc(460px);
  border-left: 1px solid black
  }
}




@media only screen and (min-width: 1500px) {
  .TopBar {
  left: calc(500px);
  border-left: 1px solid black
  }
}




@media only screen and (min-width: 1540px) {
  .TopBar {
  left: calc(540px);
  border-left: 1px solid black
  }
} */


.addNewUserModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999999999; 
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.085s;
          animation-duration: 0.085s;
}

@-webkit-keyframes underlayFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes underlayFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.none {
  display: none;
}

.addNewUserModalBox {
  /* background-color: #e0ebf8; */
  background-color: white;
  max-width: 100vw;
  border-radius: 0 0 15px 15px;
  overflow: scroll;
  height: 220px;
  min-height: 220px;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
}

.fullHeight {
  min-height: 90vh;
  max-height: 90vh;
}

.addNewUserContentBox {
  /* padding: 15px 20px 20px; */
  color: #143051;
  overflow: scroll;
  /* padding-bottom: 75px; */
}

.addNewUserModalHeader {
  display: flex;
}

.addNewUserClose {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 15px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  margin-top: 2px;
}

.addNewUserTitle {
  /* margin: 0 auto; */
  position: absolute;
  top: 36px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  text-align: center;
  font-size: 1.4em;
  font-weight: 500;
  color: #143051;
  margin-top: 3px;
}

/* page frames */
.addUserInputFrame {
  /* background-color: lime; */
  min-width: 100vw;
  vertical-align: middle;
  margin: auto 0;
  /* min-height: 220px; */
}

.addPlusUser {
  /* background-color: #143051; */
  min-width: 5px;
  max-width: 55px;
  height: 55px;
  margin: 0 auto;
  color: white;
  /* line-height: 55px; */
  /* font-size: 2.5em; */
  /* border-radius: 100%; */
  margin-top: 12px;
}

.addUserInputContainer {
  /* background-color: lime; */
  padding-top: 5px;
  max-width: 84vw;
  margin: 0 auto;
  min-height: 200px;
  /* max-height: 170px; */
  display: grid;
}

.addUserInputTitle {
  text-align: center;
  padding-bottom: 5px;
  font-size: 1.2em;
  color: #496375;
  font-weight: 500;
  /* margin-top: 14px;
  margin-bottom: -10px; */
}

.addUserInput {
  min-height: 55px;
  font-size: 1.5em;
  border: none;
  text-align: center;
  border-bottom: 1px solid #143051;
  color: #496375;
}

/* .addUserInput:input] {
  min-height: 55px;
  font-size: 1.5em;
  border: none;
  text-align: center;
  border-bottom: 1px solid #143051;
  color: #496375;
} */

.pTeamDropdownContainer {
  min-height: 55px;
  max-height: 55px;
  display: flex;
  /* background-color: yellow; */
  align-items: flex-end;
  justify-content: center;
}

.addUserNextBtn {
  height: 35px;
  background-color: #b2c9e3;
  min-width: 70px;
  max-width: 70px;
  color: white;
  line-height: 37px;
  border-radius: 25px;
  pointer-events: none;
  font-weight: 500;
  /* margin-left: -7px; */
}

.nextBtnReady {
  background-color: #143051;
  pointer-events: auto;
}

.addUserPrimaryTeamDropdown {
  border-radius: 30px;
  background: url(/static/media/downArrowWhite.97cd93aa.svg) no-repeat right #516b8b;
  -webkit-appearance: none;
  background-position-x: 90%;
  color: white;
  min-height: 40px;
  font-size: 1.05em;
  text-indent: 10%;
  min-width: 70%;
  min-width: 70%;
  font-weight: 500;
  padding-top: 2px;
  /* font-size: 1. */
}

.addUserCancelImg {
  min-width: 20px;
  position: fixed;
  top: 162px;
  margin-left: -38px;
}

.backNextContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 90%;
  min-width: 90%;
  margin: 0 auto;
  margin-top: 30px;
}

.addUserBack {
  width: 40px;
  /* margin-left: 15px; */
  min-width: 40px;
  max-width: 40px;
  font-size: 0.9em;
  font-weight: 500;
  color: #8997a8;
}

.addUserBackInvisible {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  /* margin-left: 15px; */
  font-size: 0.9em;
  color: #8997a8;
  pointer-events: none;
  /* visibility: hidden; */
  opacity: 0;
  /* visibility: hidden; */
}

.emptyNextThing {
  width: 40px;
}

.dateInputterForStartDate {
  /* background-color: orange; */
  min-height: 55px;
  max-height: 55px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: -18px;
  margin-bottom: -14px;
}

.hhy {
  max-height: 55px;
  /* background-color: yellow; */
  /* display: none; */
}

.opacity0 {
  opacity: 0;
}

.usrDatePickerStartDate {
  margin-top: 25px;
}

.addUserEmailExists {
  color: #ba0000;
  font-size: 0.8em;
  position: fixed;
  width: 100%;
  top: 127px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.reqClockOutKnobContainer {
  /* background-color: blue; */
  display: flex;
  min-height: 55px;
  justify-content: center;
  padding-top: 23px;
}

.clockOutKnob {
  /* background-color: yellow; */
  margin-top: -4px;
}

.reqClockOutY {
  color: #8ea7c5;
  font-size: 1em;
  margin-left: 15px;
  margin-top: 3px;
  font-weight: 500;
}

.reqClockOutN {
  color: #8ea7c5;
  font-size: 1em;
  margin-right: 15px;
  margin-top: 3px;
  font-weight: 500;
}

.reqClockOutNSelected {
  color: #143051;
}

.reqClockOutYSelected {
  color: #00ccff;
}

.workingPatternOptionsN {
  /* text-align: left; */
  /* font-size: 0.75em; */
  min-width: 110px;
  max-width: 110px;
}

.workingPatternOptionsY {
  /* text-align: center; */
  /* font-size: 0.75em; */
  min-width: 110px;
  max-width: 110px;
  /* padding-left: 15px; */
}

.contractual {
  color: #8ea7c5;
  font-size: 0.9em;
  margin-left: 15px;
  margin-top: 3px;
  text-align: left;
  min-width: 90px;
  max-width: 105px;
  margin-left: -15px;
  margin-right: 8px;
}

.contractualSel {
  color: #00ccff;
}

.casual {
  color: #8ea7c5;
  text-align: center;
  font-size: 0.9em;
  margin-left: 15px;
  margin-top: 3px;
  text-align: left;
  min-width: 70px;
  max-width: 110px;
  margin-left: 25px;
}

.casualSel {
  color: #00ccff;
}

.sameColour {
  background-color: #00ccff;
}

/* casFix */
.casFixContainer {
  /* background-color: yellow; */
  min-height: 55px;
  max-height: 55px;
  /* max-width: 250px;
  min-width: 250px; */

  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.casFixBase {
  /* background-color: orange; */
  border-radius: 30px;
  /* min-width: 220px; */
  min-height: 35px;
  max-height: 35px;
  /* min-width: 250px; */
  background-color: #ddebf4;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  /* padding-top: 3px; */
  /* line-height: 33px; */
  /* border: 1px solid #143051; */
}

.casFixBtn {
  min-width: 125px;
  background-color: white;
  border: 0px solid #143051;
  min-height: 35px;
  line-height: 35px;
  border-radius: 30px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
}

.casFixUnsel {
  min-width: 100px;
  background-color: #ddebf4;
  border: 0px solid #143051;
  min-height: 35px;
  line-height: 35px;
  border-radius: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.casFixSel {
  min-width: 120px;
  background-color: #00ccff;
  position: relative;
  z-index: 99999;
  min-height: 35px;
  line-height: 35px;
  border-radius: 30px;
  color: white;
  /* font-weight: 400; */
  padding-left: 10px;
  padding-right: 10px;
}

.slightlySmaller {
  font-size: 0.9em;
}

.slightlySmallerAgain {
  font-size: 0.8em;
}

/* fixed varied */
.variedHrsContainer {
  background-color: blue;
  display: flex;
  min-height: 55px;
  justify-content: center;
  align-items: center;
}

.varDropdowns {
  /* background-color: yellow; */
  margin-top: 9px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.variedNumberDropdown {
  max-width: 60px;
  min-width: 60px;
  min-height: 35px;
  padding-left: 15px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 30px;
  color: white;
  background: url(/static/media/downArrowWhite.97cd93aa.svg) no-repeat right #516b8b;
  -webkit-appearance: none;
  background-position-x: 80%;
}

.varHrsMins {
  /* background-color: pink; */
  max-height: 10px;
  /* min-height: 20px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* margin-top: 5px; */
  position: absolute;
  top: 124px;
  left: 51%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.varHrs {
  max-width: 60px;
  min-width: 60px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 0.8em;
}

.perWeek {
  margin-top: 5px;
  font-size: 0.9em;
}

.perWeekInvisible {
  margin-top: 5px;
  visibility: hidden;
  /* display: */
}

/* Daily hours inputter */
.schedHrsInputContainer {
  background-color: #f4f9ff;
  overflow: scroll;
  margin-top: 0px;
  max-height: 50vh;
  border-top: 1px solid #143051;

  border-bottom: 1px solid #143051;
}

.schedInputFaded {
  opacity: 0.2;
  pointer-events: none;
}

.schedHrsInputContainer::-webkit-scrollbar {
  display: none;
}

.fadeToggleButton {
  width: 35px;
}

.dayRow {
  padding-bottom: 25px;
  padding-top: 5px;
  border-bottom: 1px solid #8ea7c5;
  margin-top: 3px;
}

.dayRowLast {
  padding-bottom: 25px;
  padding-top: 25px;
  border-bottom: 0px solid #ddebf4;
  margin-top: 3px;
}

.dayPopulated {
  background-color: rgb(219, 241, 219);
  padding-top: 5px;
  padding-bottom: 25px;
  margin-top: 3px;
  border-bottom: 1px solid #8ea7c5;
}

.dayPopulatedLast {
  background-color: rgb(219, 241, 219);
  padding-bottom: 25px;
}

.schedHrsRow {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35px;
}

.schedWkDay {
  padding-right: 25px;
  font-weight: 500;
  font-size: 1.3em;
}

.schedInput {
  max-width: 45px;
  min-width: 45px;
  margin-left: 5px;
  background-color: #8ea7c5;
  border-radius: 30px;
  border: 1px solid #143051;
  min-height: 35px;
  color: white;
  text-align: center;
  font-size: 1em;
}

.startFinDivider {
  /* background-color: cyan; */
  min-width: 20px;
}

.brkRow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 22px;
  margin-left: 9%;
}

.breakTitleTxt {
  min-width: 30px;
  /* max-width: 30px; */
  font-size: 0.85em;
  /* background-color: yellow; */
}

.minsSuffix {
  font-size: 0.8em;
}
.brkInput {
  max-width: 45px;
  min-width: 45px;
  margin-left: 5px;
  background-color: #8ea7c5;
  border-radius: 30px;
  border: 1px solid #143051;
  min-height: 35px;
  color: white;
  text-align: center;
  font-size: 1em;
}

.brkInput::-webkit-input-placeholder {
  color: white;
  font-weight: 300;
  font-size: 0.9em;
}

.brkInput:-ms-input-placeholder {
  color: white;
  font-weight: 300;
  font-size: 0.9em;
}

.brkInput::placeholder {
  color: white;
  font-weight: 300;
  font-size: 0.9em;
}

.schedInput::-webkit-input-placeholder {
  color: white;
  font-weight: 300;
  font-size: 0.9em;
}

.schedInput:-ms-input-placeholder {
  color: white;
  font-weight: 300;
  font-size: 0.9em;
}

.schedInput::placeholder {
  color: white;
  font-weight: 300;
  font-size: 0.9em;
}

.at {
  font-size: 0.8em;
}

.brkInputMins {
  max-width: 45px;
  min-width: 45px;
  margin-left: 5px;
  background-color: #496375;
  border-radius: 30px;
  border: 1px solid #143051;
  min-height: 35px;
  color: white;
  text-align: center;
  font-size: 1em;
}

.brkInputMins::-webkit-input-placeholder {
  color: white;
  font-weight: 300;
  font-size: 0.9em;
}

.brkInputMins:-ms-input-placeholder {
  color: white;
  font-weight: 300;
  font-size: 0.9em;
}

.brkInputMins::placeholder {
  color: white;
  font-weight: 300;
  font-size: 0.9em;
}

.timeInputDiv {
  margin-top: 15px;
}

.timeInputTxt {
  min-width: 90px;
  /* background-color: orange; */
  max-width: 90px;
  color: #8ea7c5;
  text-align: center;
  /* margin-left: 42px; */
  font-size: 0.8em;
  font-weight: 500;
  padding-bottom: 4px;
}

.endInput {
  min-width: 90px;
  max-width: 90px;
  color: #8ea7c5;
  text-align: center;
  margin-left: 7px;
  font-size: 0.8em;
  font-weight: 500;
}

.totalHrsAddUser {
  /* background-color: yellow; */
  min-height: 50px;
  line-height: 50px;
}

.clearHrs {
  font-size: 0.8em;
  text-align: right;
  margin-left: 20px;
  margin-right: -25px;
  min-width: 80px;
  color: #8997a8;
}

.upperSchedContainer {
  /* background-color: orange; */
  min-width: 100%;
  display: flex;
  justify-content: space-between;

  /* min-heigh5px; */
}

.checkboxSide {
  /* background-color: lime; */
  min-width: 60%;
  font-size: 0.7em;
  display: flex;
  align-items: center;
}

.hoursCalcSide {
  /* background-color: cyan; */
  min-width: 40%;
  font-size: 1.2em;
}

.fadeToggleButton {
  width: 28px;
  margin-left: 4px;
}

.fixedVariedSelect {
  text-align: left;
  font-size: 1.2em;
  margin-left: 10px;
  /* background-color: yellow; */
}

.variedNumberInput {
  max-width: 60px;
  min-width: 60px;
  min-height: 35px;
  /* padding-left: 15px; */
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 30px;
  text-align: center;
  font-size: 1.2em;
  color: white;
  background-color: #8ea7c5;
  border: solid 1px #143051;
}

.variedNumberInput::-webkit-input-placeholder {
  color: white;
  text-align: center;
}

.variedNumberInput:-ms-input-placeholder {
  color: white;
  text-align: center;
}

.variedNumberInput::placeholder {
  color: white;
  text-align: center;
}
.userStartDateAddUserInput {
  font-weight: 500;
  padding-top: 3px;
}

.importantStartDateStrap {
  font-size: 0.9em;
  color: #8ea7c5;
  margin-top: 4px;
}

.pullUpABitAddUserTxt {
  margin-top: -4px;
}

.importantStartTxt {
  color: #143051;
}

.dateBolded {
  color: #143051;
  font-weight: 500;
}

.proratedhpwinstructions {
  font-size: 0.9em;
  line-height: 24px;
  margin-top: -10px;
}

.proHPWContainer {
  display: flex;
  justify-content: center;
  margin-top: 22px;
}

.proHPWUnit {
  display: flex;
  align-items: center;
}

.proHPWTxt {
  font-size: 0.8em;
  margin-left: -4px;
}

.noGoNextAddUsrBtn {
  opacity: 0.4;
}

.over59input {
  background-color: red;
}

.react-datepicker {
  font-family: Work Sans, Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  /* background-color: #777; */
  color: #143051;
  border: 1px solid #fff;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #143051;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.944rem;
}

.react-datepicker__day {
  color: #143051;
}

.react-datepicker__day--selected {
  background-color: #c6d3e4;
  color: white;
}

.react-datepicker__day--keyboard-selected {
  background-color: #143051;
  color: #fff;
}

.react-datepicker__day-name {
  color: #8ea7c5;
}

.react-datepicker__header {
  /* background-color: #fff; */
  padding-bottom: 5px;
  border-bottom: 2px solid #143051;
}

.react-datepicker__day--disabled {
  color: #dadada;
}

.react-datepicker__week {
  max-height: 38px;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #143051;
  color: white;
}

.react-datepicker__header {
  background-color: rgb(0, 0, 0, 0);
  max-height: 88px;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #143051;
}

.react-datepicker__day--outside-month {
  color: #8ea7c5;
}

.react-datepicker__month-container {
  margin-left: -6px;
}


.editUserUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  cursor: pointer;
  z-index: 99999999;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.editUserModalBox {
  background-color: #f4f8fe;
  min-width: 100%;
  max-width: 500px;
  min-height: 90vh;
  max-height: 90vh;
  height: 80vh;
  /* border-radius: 6px 6px 0 0; */
  border-radius: 5px 5px 0 0;
  /* margin-top: -40px; */
  /* display: flex; */
  /* justify-content: center; */
  align-items: center;
  color: #143051;
  position: fixed;
  bottom: 0;
  text-align: center;
  /* -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  /* box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  cursor: default;
  /* z-index: 111 !important; */
}

.desktopEditUser {
  min-width: 500px;
  bottom: 5vh;
  border-radius: 10px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.mobEditUser {
  -webkit-animation-name: moveEditTeamUserBoxUp !important;
          animation-name: moveEditTeamUserBoxUp !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  border-radius: 20px 20px 0 0;
}

@-webkit-keyframes moveEditTeamUserBoxUp {
  from {
    bottom: -100%;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes moveEditTeamUserBoxUp {
  from {
    bottom: -100%;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

.editUserModalHeader {
  /* background-color: yellow; */
  display: flex;
  justify-content: space-around;
  min-width: 100%;
  max-width: 100%;
  text-align: left;
  min-height: 47px;
  margin-top: 5px;
  margin-bottom: -5px;
  padding-bottom: 0px;
}

.closeEditUserImg {
  width: 30px;
  padding: 7px;
  margin-top: 10px !important;
  margin-left: 16px !important;
  cursor: pointer;
}

.closeEditUserImgMob {
  margin-left: 18px !important;
}

.closeEditUserImg:hover {
  opacity: 0.8;
}

.left {
  margin-left: 50%;
  margin-top: 16px;
}

.editUserNameTitle {
  margin-top: 13px;
  /* margin-left: -10px; */
  font-weight: 500;
  font-size: 18px;
  min-width: 76%;
  max-width: 76%;
  display: flex;
  justify-content: center;
}

.editUserNameTitleMobModal {
  /* justify-content: flex-end; */
  margin-right: 10px;
  margin-top: 15px;
}

.editUserModalMenu {
  /* background-color: lime; */
  display: flex;
  justify-content: space-around;
  font-size: 1em;
  max-width: 94%;
  margin: 0 auto;
  margin-top: 5px;
  color: #143051;
  font-weight: 300;
}

.editUserMenuItem {
  line-height: 25px;
  font-weight: 500 !important;
  color: #8ea7c5;
  font-size: 14px;
  cursor: pointer;
}

.editUserMenuItemDesktopOnly:hover {
  color: #6f88a6;
}

.editUserMenuItem:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.editUserMenuItemSelected {
  font-weight: 500;
  padding-bottom: 0px;
  border-bottom: solid 3px #ffa8ff;
  color: #143051;
  cursor: default;
  margin-bottom: 3px;
}

.editUserMenuItemSelected:active {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.headerSideHolder {
  min-width: 12%;
  max-width: 12%;
}

/* Main body of the edit user modal */
.editUserMainBodyContainer {
  /* background-color: pink; */
  display: flex;
  position: relative;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;

  margin-top: 6px;
  border-bottom: solid 2px #e6e6e6;
  max-height: calc(100% - 139px);
  min-height: calc(100% - 139px);
  overflow: hidden;
  z-index: 999;
}

.editUserModalFooterContainer {
  /* background-color: yellow; */
  max-width: 90%;
  margin: 0 auto;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editUserModalFooterLeftBox {
  /* background-color: orange; */
  display: flex;
}

.editUserModalFooterRightBox {
  background-color: #214978;
  border: #143051 1px solid;

  display: flex;
  height: 35px;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  /* line-height: 30px; */
  color: white;
  font-size: 14px;
  border-radius: 5px;
  margin-right: 3px;
  padding-left: 13px;
  padding-right: 13px;
  cursor: pointer;
}

.editUserModalFooterRightBox:hover {
  background-color: #143051;
}

.deleteUserBtn {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /* min-width: 72px; */
  padding-left: 13px;
  padding-right: 13px;
  min-height: 35px;
  max-height: 35px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
}
.deleteUserBtn:hover {
  background-color: #bbcada;
}
.sureDelete {
  color: #143051;
  font-weight: 500;
}

.absolutelySure {
  color: #ba0000;
  font-size: 1em;
  /* margin-top: 1px; */
}

/* edit user row */
.DetailsContainer {
  display: block;
  min-width: 100%;
  max-height: calc(100% - 160px);
  min-height: calc(100% - 160px);
  overflow-y: auto;
  border-top: solid 2px #e6eaee;
  border-radius: 5px 5px 0px 0px;

  background-color: white;
  z-index: 100;
}

/* edit user row */
.EditUserTeamsContainer {
  display: block;
  min-width: 100%;
  max-height: calc(100% - 160px);
  min-height: calc(100% - 160px);
  overflow-y: scroll;
  /* -ms-overflow-style: none; IE and Edge */
  /* scrollbar-width: none; Firefox */
  /* padding-bottom: 10px; */
  border-top: solid 2px #e6eaee;
  border-radius: 5px 5px 0px 0px;
  /* padding-top: 10px; */
  /* background-color: rgb(252, 253, 255); */
  background-color: white;
}

.loadingEditUserWhiteSplash {
  display: block;
  min-width: 100%;
  max-height: calc(100% - 160px);
  min-height: calc(100% - 160px);
  overflow: scroll;
  /* -ms-overflow-style: none; IE and Edge */
  /* scrollbar-width: none; Firefox */
  /* padding-bottom: 10px; */
  border-top: solid 2px #e6eaee;
  border-radius: 5px 5px 0px 0px;
  /* padding-top: 10px; */
  /* background-color: rgb(252, 253, 255); */
  background-color: white;
}

.editUserLoadBar1 {
  min-height: 30px;
  max-height: 30px;
  max-width: 80%;
  min-width: 80%;
  background-color: #e2ebf5;
  margin-top: 30px;
  margin-left: 30px;
  border-radius: 5px;
}

.editUserLoadBar2 {
  min-height: 20px;
  max-height: 20px;
  max-width: 40%;
  min-width: 40%;
  background-color: #e2ebf5;
  margin-top: 15px;
  margin-left: 30px;
  border-radius: 5px;
  opacity: 0.8;
}

.editUserLoadBar3 {
  min-height: 20px;
  max-height: 20px;
  max-width: 40%;
  min-width: 40%;
  background-color: #e2ebf5;
  margin-top: 15px;
  margin-left: 30px;
  border-radius: 5px;
  opacity: 0.8;
}
.editUserRow {
  /* background-color: cyan; */
  min-height: 110px;
  max-height: 110px;
  padding-top: 20px;
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  /* padding-bottom: -10px; */
  align-items: center;
  border-bottom: 1px solid #ddebf4;
  font-size: 14px;
}
.firstEditUserRow {
  margin-top: 40px;
}

.editUserTeamRow {
  /* background-color: cyan; */
  min-height: 110px;
  /* max-height: 110px; */
  padding-top: 20px;
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  /* padding-bottom: -10px; */
  align-items: flex-start;
  border-bottom: 1px solid #ddebf4;
  font-size: 1.2em;
}

.editWPatternRowOnly {
  max-height: 180px;
  /* margin-top: -50px; */
}

.knobContainerEditUser {
  margin-top: 0px;
}

.editUserRow:last-of-type {
  border: none;
}

.editUserEditTitle {
  font-weight: 500;
  color: #143051;
  min-height: 35px;
  padding-top: 5px;
  text-align: left;
  margin-left: 5%;
  font-size: 14px;
}

.jobTitle {
  /* background-color: yellow; */
  min-width: 66px;
}

.jobTitleValue {
  max-width: 62vw;
  /* background-color: lime; */
}

.clockInKnob {
  margin-top: -2px;
  /* margin-left: -20px; */
}

.notSetTxt {
  font-size: 1em;
  color: #143051;
  font-weight: 500;
  opacity: 0.3;
}

.editUserReqClockOutKnobContainer {
  /* background-color: yellow; */
  margin-right: 8px;
}

.wPatternContainer {
  font-size: 1em;
  display: flex;
  justify-content: space-around;
  /* background-color: #ddebf4; */
  /* border: solid 2px #143051; */
  box-shadow: inset -2px 0 0 #e2ebf5, inset 0 -2px 0 #e2ebf5,
    inset 2px 0 0 #e2ebf5, inset 0 2px 0 #e2ebf5;

  min-height: 30px;
  border-radius: 10px;
  min-width: 120px;
  padding-left: 1px;
  line-height: 30px;
  text-align: center;
  align-items: center;
  /* padding-left: 15px;
  padding-right: 15px; */
}

.casualTxt {
  margin-right: 10px;
  padding-left: 6px;
  /* margin-top: -1px; */
}

.fixedTxt {
  margin-left: 5px;
  padding-right: 18px;
  font-weight: 500;
  margin-top: 0px;
  /* padding-top: 2px; */
  color: #143051;
}

.wPatSel {
  background-color: #00aaff;
  color: white;
  font-weight: 500;
  padding-left: 20px;
  padding-right: 18px;
  border-radius: 10px;
  margin-left: -1px;
  border: 1px solid #143051;

  /* padding-top: 4px; */
  /* margin-top: -2px; */
}

.fixedSel {
  background-color: #00aaff;
  color: white;
  font-weight: 500;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
  margin-left: 4px;
  border: 1px solid #143051;
  margin-bottom: 0px;
  /* margin-top: -1px; */
}

.casualUnsel {
  margin-left: 10px;
  font-weight: 500;
  color: #143051;
}

.casualExplained {
  width: 93%;
  text-align: left;
  background-color: #8ea7c5;
  color: white;
  margin: 0 auto;
  font-size: 1em;
  font-weight: 500;
  padding-top: 10px;
  margin-top: 15px;
  padding-bottom: 10px;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 5px;
}

.fixedPattContainer {
  /* background-color: #f4f9ff; */
  display: flex;
  margin-top: 0px;
  /* justify-content: space-between; */
}

.editSchedContainer {
  width: 100%;
  /* background-color: lime; */
  max-width: 145px;
  min-height: 45px;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-around; */
  margin: 15px auto;
}

.editSchedButton {
  background-color: #9a74eb;
  height: 35px;
  display: flex;
  line-height: 35px;
  padding-left: 15px;
  padding-right: 10px;
  margin-top: 0px;
  margin-right: 5%;
  color: white;
  border-radius: 35px;
  font-size: 0.9em;
}

.editSchedTxt {
  padding-right: 10px;
}

.editSchedImg {
  width: 11px;
}

.scheduleContainer {
  width: 100%;
  display: block;
  margin-top: 20px;
  background-color: #fdfeff;
  border-top: solid 2px #e6eaee;
  border-radius: 15px 15px 0px 0px;
  border-right: 1px solid #dde1e6;
  border-left: 1px solid #dde1e6;
}

.scheduleDividingBar {
  min-width: 3px;
  max-width: 3px;
  margin-left: 5%;
  background-color: #c0d1e9;
  min-height: 50px;
  visibility: hidden;
  display: block;
}

.fixedScheduledTitle {
  font-size: 0.94em;
  font-weight: 500;
  text-align: center;
  /* margin-left: 5%; */
  margin-top: 14px;
  color: #496375;
  /* background-color: blue; */

  /* position: absolute; */
  /* top: 100px; */
}

.fixedSchedSubDivBar {
  min-width: 90%;
  max-width: 90%;
  min-height: 1px;
  max-height: 1px;
  background-color: #dde1e6;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.fixedCont {
  /* background-color: cyan; */
  /* border-bottom: solid 1px #143051; */
  display: flex;
  width: 100%;
  /* height: 80px; */
  justify-content: space-between;
  align-items: center;
  margin-top: -60px;
  padding-bottom: 0px;
  /* min-height: 120px; */
}

.hpw {
  color: #19a5c8;
  background-color: #7390b1;
  border: 1px solid #214978;
  /* padding-left: 15px; */
  padding-bottom: 20px;
  border-radius: 10px;
  width: 40%;
  max-width: 90px;
  font-size: 1em;
  padding-top: 11px;
  margin-left: 10px;
  text-align: left;
  margin-top: 24px;
  max-height: 58px;
  text-align: center;
  line-height: 0px;
  /* margin-left: -2px; */
}

.fixVarInstruction {
}

.fixedVarInstructionTxt {
  font-size: 0.75em;
  width: 80%;
  max-width: 70px;
  border-radius: 30px;
  color: #fff;
  margin: 0 auto;
  /* padding-top: 10px; */
  padding-bottom: 10px;
  font-weight: 500;
  margin-top: -10px;
  margin-bottom: 20px;
  background-color: #abbed3;
  line-height: 24px;
  max-height: 24px;
}

.noBorder {
  border: none;
}

.schedVarContainer {
  font-size: 1em;
  display: flex;
  justify-content: space-around;
  background-color: white;
  /* border: solid 2px #143051; */
  box-shadow: inset -2px 0 0 #e2ebf5, inset 0 -2px 0 #e2ebf5,
    inset 2px 0 0 #e2ebf5, inset 0 2px 0 #e2ebf5;
  min-height: 30px;
  border-radius: 10px;
  align-items: center;
  /* min-width: 120px; */
  max-width: 200px;
  line-height: 31px;
  text-align: center;
  margin-right: 5%;
  /* padding-left: 15px;
    padding-right: 15px; */
}

.schedVarSched {
  margin-right: 10px;
  /* padding-top: 0px; */
  /* background-color: orange; */
}

.schedVarVar {
  margin-left: 5px;
  padding-right: 10px;
  /* padding-top: 2px; */
  /* background-color: blue; */
  padding-right: 22px;
  font-weight: 500;
}

.schedVarSchedSel {
  background-color: #00aaff;
  border: 1px solid #143051;
  color: white;
  text-align: center;
  font-weight: 500;
  padding-left: 12px;
  padding-right: 14px;
  border-radius: 10px;
  /* margin-left: -1px; */
}

.schedVarVarSel {
  background-color: #00aaff;
  border: 1px solid #143051;
  color: white;
  font-weight: 500;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 10px;
  margin-left: 0px;
}

.schedVarSchedUnsel {
  /* background-color: #0077ff; */
  margin-left: 10px;
  padding-left: 6px;
  font-weight: 500;
  font-size: 1em;
}

.fixedSchedContainer {
  /* background-color: purple; */
  margin-top: 10px;
  max-width: 100%;
}

.fixedVarContainer {
  /* background-color: lime; */
}

.schedRow {
  display: block;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 105px;
  /* padding-bottom: 20px; */
  border-bottom: solid 1px #dde9f9;
}

.schedRow:last-of-type {
  border: none;
}

.titleRow {
  max-height: 22px;
  margin-top: 8px;
}

.hidden {
  visibility: hidden;
}

.clearImg {
  width: 28px;
  margin-right: 12px;
}

.editDayHoursUnit {
  /* background-color: lime; */
  width: 60%;
  display: flex;
  margin-right: 3%;
  justify-content: center;
  justify-content: flex-end;
  align-items: center;
}

.schedRowunit {
  /* background-color: lime; */
  width: 35%;
  max-width: 90px;
  display: flex;
  justify-content: center;
  /* padding-left: 3%; */
}

.timelineHourlyVal {
  /* background-color: lime; */
  width: 100%;
  max-width: 90px;
  /* margin-left: 10px; */
  display: flex;
  flex-direction: column;
  /* text-align: center; */
  align-items: center;
}

.perHourTxt {
  font-size: 0.8em;
  /* margin-right: 14px; */
  padding-top: 0px;
  font-weight: 500;
}

.schedRowunitBreak {
  width: 20%;
  max-width: 90px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
}

.inputRow {
  /* background-color: orange; */
  display: flex;
  max-width: 260px;
  margin: 0 auto;
  /* padding-left: 5%; */
  /* font-size: 1.2em; */
  /* margin-left: 3%; */
}

.inputRowTitle {
  /* background-color: yellow; */
  display: flex;
  max-height: 20px;
  /* padding-left: 5%; */
  max-width: 260px;
  margin: 0 auto;
  font-size: 1.1em;
  align-items: center;
  /* text-align: center; */
}

.schedRowunitDay {
  /* background-color: lime; */
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  /* margin-left: 3%; */
}

.editing {
  background-color: #f4f9ff;
  /* border-left: 2px #143051 solid; */
  /* background-color: blue; */
}

.firstEditingSchedRow {
  border-radius: 20px 20px 0 0;
  border-top: 2px #e6eaee solid;
}
.lastEditingSchedRow {
  border-radius: 0px 0px 20px 20px;
  border-bottom: 2px #e6eaee solid;
}

.schedDay {
  color: #19a5c8;
  font-weight: 500;
  font-size: 1.2em;
  margin: 0 auto;
  /* text-align: center; */
  /* margin-left: %; */
  /* text-align: right; */
  /* background-color: blue; */
}

.editDayHoursImg {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 18px;
}

.saveDayHoursImg {
  width: 28px;
}

.schedTitle {
  color: #143051;

  font-size: 0.7em;
}

.schedTime {
  background-color: #e6eaee;
  color: #496375;
  border: 1px solid #859dbb;
  border-radius: 10px;
  height: 30px;
  font-weight: 500;
  line-height: 27px;
  padding-left: 7px;
  padding-right: 7px;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  font-size: 0.95em;
  /* padding-bottom: 1px; */
}

.editDayBtn {
  background-color: #143051;
  /* height: 20px; */
  line-height: 20px;
  color: white;
  font-size: 0.9em;
  border-radius: 20px;
  width: 45px;
  height: 20px;
}

.schedTimeTitle {
  /* background-color: #e6eaee; */
  color: #143051;
  border-radius: 30px;
  height: 20px;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 20px;
  /* padding-left: 7px; */
  /* padding-right: 7px; */
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-left: 3%;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  -webkit-filter: invert(100%);
          filter: invert(100%);
  margin-right: 6px;
  /* background-color: blue; */
}

.schedTimeInput {
  background-color: #496375;
  color: white;
  border-radius: 10px;
  border: solid 2px #143051;
  height: 30px;
  font-weight: 500;
  line-height: 30px;
  padding-left: 2px;
  padding-right: 0px;
  /* width: 100%; */
  min-width: 76px;
  max-width: 76px;
  margin: 0 auto;
  font-size: 0.94em;
  text-align: center;
}

#monEndInput {
  margin-left: 10px;
}

.schedBrk {
  background-color: #eaf3fd;
  color: #496375;
  border-radius: 10px;
  border: 1px solid #8ea7c5;
  height: 30px;
  width: 80%;
  font-weight: 500;
  font-size: 0.95em;
  text-align: center;
  line-height: 27px;
  padding-left: 7px;
  padding-right: 7px;
}

.schedBrkTitle {
  /* background-color: #c0d1e9; */
  color: #143051;
  border-radius: 30px;
  height: 30px;
  width: 80%;
  font-weight: 500;
  font-size: 0.8em;
  text-align: center;
  line-height: 30px;
  /* padding-left: 7px; */
  /* padding-right: 7px; */
}

.schedBrkInput {
  background-color: #8ea7c5;
  color: white;
  border-radius: 10px;
  height: 30px;
  width: 80%;
  border: none;
  border: solid 2px #143051;
  font-size: 1.1em;
  font-weight: 500;
  text-align: center;
  line-height: 30px;
  padding-left: 7px;
  padding-right: 7px;
}

.fixedSchedInfoContainer {
  /* background-color: yellow; */
  font-size: 0.7em;
  display: flex;
  height: 53px;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  z-index: 99999;
  margin-top: -24px;
  /* padding-top: 30px; */
}

.FixedSchedInfoTxt {
  max-width: 50%;
  margin-right: 0px;
  /* padding-top: 6px; */
  /* margin-top: -20px; */
  text-align: right;
  font-size: 1.2em;
  font-weight: 500;
  color: #496375;
  padding-top: 20px;
  /* min-height: 10px; */
  /* margin-top: 20px; */
  /* position: relative; */
  /* z-index: 9999; */
  /* margin-bottom: 3px; */
}

.curvyArrowImg {
  width: 20px;
  /* margin-top: -40px; */
  margin-right: 3%;
  padding-bottom: 33px;
}

.fixedVarInputContainer {
  /* background-color: yellow; */
  display: flex;
  margin-top: 0px;
  justify-content: center;
}

.fixedVarInputWrapper {
  width: 240px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.saveHoursBtnHolder {
  /* background-color: lime; */
  max-width: 152px;
  margin: 0 auto;
  max-height: 90px;
}

.fixedVarHours {
  height: 35px;
  line-height: 35px;
  color: white;
  border: solid 2px #143051;
  text-align: center;
  font-size: 1.2em;
  font-weight: 500;
  background-color: #496375;
  width: 60px;
  border-radius: 35px;
  margin-right: 0px;
  margin-left: 10px;
}

.fixedVarUnitTxt {
  font-size: 1em;
  margin-left: -10px;
  font-weight: 500;
  color: #496375;
}

.dayOff {
  font-size: 0.8em;
  min-width: 78px;
  max-width: 78px;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 500;
  background-color: #496375;
  color: white;
  height: 22px;
  line-height: 23px;
  border-radius: 15px;
  margin-right: 14px;
}

.dayOffBtnImg {
  width: 28px;
  margin-right: 12px;
}

.editSchedContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding-bottom: 0px;
}

.editSchedBtnContainer {
  background-color: #9a74eb;
  /* background-color: #0077ff; */
  display: flex;
  min-width: 135px;
  height: 30px;
  line-height: 29px;
  margin-left: 3%;
  justify-content: space-around;
  border-radius: 10px;
  /* padding-bottom: 2px; */
  border: 1px #592eb6 solid;
}

.editBgFaded {
  background-color: #d0c5e8;
  border: 1px #9982cb solid;
}

.tapToEditSched {
  font-size: 0.94em;
  height: 30px;
  line-height: 26px;
  margin-left: 6px;
  color: white;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
}

.editSchedIcon {
  width: 10px;
  margin-right: 12px;
}

.crossWhiteIcon {
  width: 16px;
  margin-right: 6px;
}

.saveSchedBtnContainer {
  background-color: #00ccff;
  border: 1px solid #143051;
  display: flex;
  width: 150px;
  text-align: center;
  justify-content: center;
  height: 35px;
  padding-top: 4px;
  font-size: 1.15em;
  justify-content: space-around;
  border-radius: 10px;
  margin-right: 3%;
  padding-left: 3px;
  margin-top: 15px;
  /* margin: -10px auto; */
  margin-bottom: 120px;
}

.tapToSaveSched {
  font-size: 0.9em;
  height: 25px;
  /* font-weight: 500; */
  line-height: 25px;
  text-align: center;
  margin-left: 6px;
  color: white;
  font-weight: 500;
}

.saveSchedIcon {
  width: 12px;
  margin-right: 8px;
  margin-top: -3px;
}

.hideSaveBtn {
  visibility: hidden;
  pointer-events: none;
}

.bottomSaveBtnContainer {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  max-width: 100%;
}

.nonEdit {
  background-color: #e6eaee;
  border: 1px solid #496375;
  color: #496375;
  font-weight: 500;
  font-size: 1.2em;
}

.editFixVar {
  background-color: #9a74eb;
}

.errorMins {
  background-color: #ba0000;
}

.opacity4 {
  opacity: 0.4;
  pointer-events: none;
}

.sureCloseUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-width: 100vw;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.sureCloseEditUserBox {
  min-width: 90vw;
  max-width: 90vw;
  /* height: 300px; */
  background-color: #fff;

  border-radius: 15px;
  /* display: flex;
  justify-content: center; */
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  border: solid 2px #143051;
  margin-top: -130px;
}

.sureCloseEditUserBoxText {
  text-align: center;
  color: #496375;
  padding-top: 30px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 1.2em;
  font-weight: 500;
}

.sureBtnContainer {
  display: flex;
  justify-content: space-around;
  flex-direction: row-reverse;

  max-width: 55%;
  margin: 0 auto;
  margin-top: 20px;
  padding-bottom: 25px;
  font-weight: 500;
  font-size: 1.2em;
}

.sureBtn1 {
  background-color: #00ccff;
  border: 1px solid #143051;
  width: 90px;
  color: white;
  height: 40px;
  line-height: 40px;
  border-radius: 35px;
}

.sureBtn2 {
  /* background-color: orange; */
  width: 70px;
  color: #143051;
  height: 40px;
  line-height: 40px;
}

/* Modal for userAdminSettingInput */
.userAdminSettingInputUnderlay {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999999;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.userAdminSettingInputModal {
  background-color: #fff;
  border: 1px solid #dae1eb;
  height: 160px;
  min-width: 300px;
  max-width: 300px;
  /* max-width: 300px; */
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 80px;
  margin-left: 120px;
  display: block;
  color: #143051;
  justify-content: center;
}

.userAdminSettingInputHeader {
  margin-top: 20px;
  font-weight: 500;
  font-size: 1.1em;
}

.userAdminSettingInputInput {
  margin-top: 26px;
  width: 86%;
  background-color: #fff;
  border: none;
  border-bottom: solid 1px #143051;
  color: #143051;
  font-size: 1.3em;
  font-weight: 500;
  padding-bottom: 10px;
  padding-top: 2px;
  text-indent: 3%;
  height: 30px;
  line-height: 43px;
}

.userAdminSettingInputInput::-webkit-input-placeholder {
  color: #8ea7c5;
}

.userAdminSettingInputInput:-ms-input-placeholder {
  color: #8ea7c5;
}

.userAdminSettingInputInput::placeholder {
  color: #8ea7c5;
}

.userAdminSettingInputFooter {
  display: flex;
  justify-content: space-between;
  /* margin-top: 18px; */
  max-width: 70%;
  margin: 18px auto;
  /* font-size: 0.98em; */
}

.userAdminSettingInputCancel {
  color: #8ea7c5;
  height: 30px;
  line-height: 26px;
  font-size: 1em;
  margin-left: 10%;
  font-weight: 500;
  margin-top: 0px;
}

.userAdminSettingInputSave {
  background-color: #00ccff;
  border: 1px solid #143051;
  color: #214978;
  color: white;
  height: 30px;
  width: 64px;
  font-size: 1.05em;
  border-radius: 10px;
  margin-right: 10%;
  font-weight: 500;
  line-height: 25px;
}

.saveBtnFaded {
  opacity: 0.3;
  color: #143051;
  background-color: white;
  pointer-events: none;
  border: none;
}

.removeEmpNum {
  color: #c0d1e9;
  height: 30px;
  line-height: 26px;
  font-size: 1em;
  font-weight: 500;
  margin-top: 0px;
  display: none;
}

.tickTeam {
  width: 30px;
}

.teamSelecterDropdown {
  color: white;
  width: 250px;
  margin-top: 20px;
  border: solid #143051 2px;
  border-radius: 10px;
  padding-left: 18px;
  background: url(/static/media/downArrowWhite.97cd93aa.svg) no-repeat right
    #0077ff;
  -webkit-appearance: none;
  background-position-x: 224px;
  height: 35px;
  font-weight: 500;
  padding-top: 2px;
  font-size: 1.05em;
  /* margin: 0 auto; */
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
    #214978;
  border: 1px solid #143051;
  -webkit-appearance: none;
  /* background-position-x: 56px; */
  /* display: flex; */
  cursor: pointer;

  color: #496375;
  background-color: white;
  border: 1px solid #dde9f9;
  background-position-x: 224px;
}

.hpwValue {
  font-weight: 500;
  font-size: 1.1em;
  margin-top: 5px;
  line-height: 16px;
  padding-top: 2px;
  color: #fff;

  /* margin-bottom: -3px; */

  /* padding-top: 4px; */
  text-align: center;
  /* background-color: black; */
}

.h {
  font-weight: 500;
  font-size: 0.9em;
}

.perWeek {
  font-weight: 500;
  color: #cbdeeb;
  font-size: 0.8em;
  /* margin-top: -3px; */
  /* margin-bottom: 3px; */
}

.openAllTeams {
  background-color: #cbdeeb;
  color: #143051;
  /* min-height: 47px; */
  line-height: 36px;
  padding: 4px 10px;
  /* text-indent: 3px; */
  padding-top: 3px;
  font-weight: 500;
  /* max-height: 28px; */
  font-size: 0.9em;
  border-radius: 10px;
  border: 1px solid #143051;
  margin-bottom: 8px;
  margin-top: -9px;
  background-color: #516b8b;
  border: 1px #143051 solid;
  color: white;
  background-color: white;
  color: #496375;
  border-color: #dde9f9;
  /* margin-top: px; */
  /* opacity: 0.6; */
}

.noTeamsEditUserTxt {
  pointer-events: none;
}

.allTeamsQty {
  margin-left: 7px;
  background-color: #143051;
  /* width: 20px; */
  border-radius: 10px;
  font-size: 0.9em;
  /* min-width: 30px; */
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 2px;
  color: white;

  /* min-width: 28px; */
}

.showAllTeamsModalUnderlay {
  background: rgba(0, 0, 0, 0.5);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.pTeamTitle {
  min-width: 40%;
}

.allTeamsModalBox {
  background-color: white;
  min-width: 90vw;
  max-width: 90vw;
  /* min-height: 80vh; */
  max-height: 80vh;
  padding-top: 20px;
  /* padding-bottom: 20px; */
  /* height: 80vh; */
  border-radius: 15px;
  margin-top: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #143051;
  text-align: center;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
}

.allTeamsContentContainer {
  /* background-color: #00ccff; */
  max-width: 90%;
  min-width: 90%;
  /* display: flex; */
}

.allTeamsTeamRow {
  display: flex;
  justify-content: space-between;
  min-width: 90%;
  border-bottom: 1px solid #ddebf4;
  /* border-top: 1px solid #ddebf4; */
  padding-top: 14px;
  padding-bottom: 14px;
}

.allTeamsTeamRow:last-of-type {
  border: none;
}

.mgrKey {
  display: flex;
  /* justify-content: space-between; */
  /* max-width: 80%; */
  /* margin: 0 auto; */
  padding-bottom: 20px;
  /* margin-top: 17px; */
  justify-content: center;
}

.allTeamsTeamName {
  margin-top: 2px;
  margin-left: 5%;
  text-align: left;
  max-width: 60%;
  font-weight: 500;
  font-size: 0.9em;
  color: #8997a8;
  /* background-color: grey; */
}

.keyMgrIcon {
  min-width: 14px;
  max-width: 14px;
}

.allTeamsMgr {
  width: 18px;
  margin-right: 20px;
}

.allTeamsRemove {
  min-width: 20px;
  max-width: 20px;
  margin-right: 20px;
}

.allTeamsTitle {
  font-size: 1.2em;
  font-weight: 500;
  padding-bottom: 20px;
}

.mgrKeyTxt {
  font-size: 0.9em;
  text-align: left;
  color: #8997a8;
}

.mgrIconAndRemoveIconContainer {
  /* background-color: yellow; */
  display: flex;
  /* max-width: 30%; */
}

.teamsListContainer {
  overflow: scroll;
  max-height: 240px;
  padding-bottom: 10px;
}

.allTeamsFooterContainer {
  /* background-color: orange; */
  border-top: 2px solid #143051;
  min-height: 50px;
  line-height: 50px;
  font-size: 1em;
  font-weight: 500;
  color: #8ea7c5;
}

.hideMgrIcon {
  visibility: hidden;
}

.noTeamsText {
  /* font-size: 0.9em; */
  padding-top: 20px;
  color: #8ea7c5;
  padding-bottom: 20px;
}

/*  - - -  */
.isMgrNotMbrContainer {
  background-color: #e0ebf8;
  border-radius: 10px;
  width: 100%;
  color: #143051;
  padding: 10px;
  font-size: 0.8em;
  font-weight: 400;
  margin-top: 7px;
  margin-bottom: 18px;
  display: flex;
  /* text-align: left; */
}

.isMgrNotMbrText {
  max-width: 70%;
  margin: 0 auto;
  font-weight: 500;
  text-align: left;
}

.viewMgrNotMbrBtn {
  font-size: 0.9em;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 2px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  line-height: 26px;
  border-radius: 10px;
  min-height: 30px;
  max-width: 70px;
  margin: 2px auto;
  /* margin-bottom: 2px; */
}

.closeColour {
  background-color: #516b8b;
  max-width: 64px;
  min-width: 64px;
  /* padding-right: 3px; */
  /* padding-left: 3px; */
}

.mgrNotMbrData {
  /* margin-top: 18px; */
  /* margin-bottom: 6px; */
  display: block;
  max-width: 90%;
  margin: -18px auto;
  margin-bottom: 20px;
  background-color: #edf2f9;
  border-top: 2px solid #c1d1e6;
  border-radius: 0 0 10px 10px;
}

.mgrNotMbrRow {
  display: flex;
  padding-top: 14px;
  padding-bottom: 14px;
  border-top: 1px solid #8ea7c5;
  /* margin-bottom: 4px; */
  justify-content: space-between;
  max-width: 90%;
  margin: 0 auto;
}

.mgrNotMbrRow:first-of-type {
  display: flex;
  margin-top: 0px;
  /* margin-bottom: 4px; */
  justify-content: space-between;
  border: none;
}

.mgrNotMbrTeam {
  font-size: 0.9em;
  min-width: 75%;
  text-align: left;
  line-height: 22px;
  height: 22px;
  /* background-color: yellow; */
}

.mgrNotMbrMgrIconImg {
  /* min-width: 10%; */
  /* max-width: 10%; */
  /* max-height: 22px; */
  max-width: 16px;
  /* background-color: lime; */
}

.mgrNotMbrDeleteIconImg {
  /* background-color: red; */
  width: 10%;
  max-width: 20px;

  margin-left: 5%;
}

.mgrNotMbrQtyTxt {
  color: #00ccff;
}

/* shifts panel */
.userShiftsContainer {
  /* background-color: blue; */
  min-width: 100%;
}

.shiftRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 70px;
  border-bottom: 2px solid #e0ebf8;
  min-width: 100%;
  font-size: 0.9em;
  /* background-color: orange; */
  background-color: #ddebf4;
  background-color: white;
  border-radius: 10px;
  margin-top: 5px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.shiftRow:last-of-type {
  /* border-bottom: none; */
}

.shiftDateString {
  text-align: left;
  /* margin-left: 5px; */
  /* background-color: pink; */
  font-weight: 500;
  font-size: 1.2em;
  color: #496375;
  min-height: 24px;
  min-width: 110px;
}

.shiftTimesString {
  color: #496375;
  color: #859dbb;
  font-weight: 500;
  text-align: left;
  margin-top: -3px;
  font-size: 1.03em;
  /* margin-right: -3px; */
  /* margin-left: 5px; */
}

.shiftDateTxtSpan {
  /* min-width: 140px;
  max-width: 140px; */
  /* background-color: lime; */
}

.paidDayOff {
  font-size: 0.95em;
}

.shiftTilNameTxt {
  /* color: #fff; */
  font-size: 10px;
  text-align: left;
  margin-left: 5px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 5px;
  /* padding-top: 2px; */
  /* min-height: 30px; */
  /* padding-bottom: 2px; */
  /* background-color: #8ea7c5; */
  /* line-height: 15px; */
  max-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #bbcada;
  font-weight: 400;
  /* background-color: #214978; */
  border: 1px solid #bbcada;
  /* border: 1px solid #7f93ac; */
  /* margin-top: -1px; */
}

.shiftDurationString {
  color: #143051;
  margin-right: 5px;
  font-weight: 300;
  /* background-color: blue; */
  min-width: 70px;
  text-align: right;
}

.shiftsMonthsContainer {
  /* background-color: purple; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.shiftMonthSelectDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left;
  max-width: 88px;
  line-height: 26px;
  height: 30px;
  background: url(/static/media/downArrowWhite2.887d57ce.svg) no-repeat right
    #214978;
  /* background: url('../../img/general/downArrowNavy.svg') no-repeat right #fff; */
  border: 1px solid #143051;
  -webkit-appearance: none;
  background-position-x: 66px;
  /* margin: 0 auto; */
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
    #214978;
  border: 1px solid #143051;
  -webkit-appearance: none;
  /* background-position-x: 56px; */
  display: flex;
  cursor: pointer;

  color: #496375;
  background-color: white;
  border: 1px solid #dde9f9;
  background-position-x: 64px;
}

.monthDurationTxt {
  /* color: #9a74eb; */
  color: #8ea7c5;
  font-weight: 500;
  margin-right: 15px;
  font-size: 1em;
}

.userShiftsHolder {
  /* background-color: #f5f5f5; */
  min-height: calc(100vh - 262px);
  max-height: calc(100vh - 262px);
  max-width: 100%;
  min-width: 100%;
  z-index: 0;
  position: relative;
  padding-top: 8px;
  overflow: scroll;
  padding-bottom: 100px;
  border-top: 1px solid #143051;
}

.adminAbsenceHolder {
  margin-right: 10px !important;
  /* padding-right: 0px; */
}

.adminUnitWidth {
  max-width: 100%;
  min-width: 100%;
}

/* background-color: #f5f5f5;
min-height: calc(100vh - 262px);
max-height: calc(100vh - 262px);
max-width: 100%;
min-width: 100%;
z-index: 0;
position: relative;
padding-top: 8px;
overflow: scroll;
padding-bottom: 100px; */

.shiftRowL {
  /* background-color: yellow; */
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 8%;
  max-width: 8%;
}

.shiftRowM {
  /* background-color: lime; */
  display: block;
  min-width: 60%;
  max-width: 60%;
  padding-left: 1px;
}

.horiztonalBallsUserShifts {
  margin-top: 20px;
}

.shiftRowR {
  /* background-color: orange; */
  display: block;
  min-width: 38%;
  min-width: 38%;
  max-width: 35%;
  /* padding-right: 80px; */
}

.alignRight {
  text-align: right;
  /* background-color: blue; */
  min-width: 110px;
  padding-right: 48px;
}

.shiftWorkedGreen {
  min-width: 9px;
  max-width: 9px;
  min-height: 9px;
  max-height: 9px;
  background-color: #a8cc9a;
  border-radius: 100px;
}

.shiftWorkedBlue {
  min-width: 6px;
  max-width: 6px;
  min-height: 6px;
  max-height: 6px;
  background-color: #e0ebf8;
  border-radius: 100px;
}

.shiftWorkedRed {
  min-width: 9px;
  max-width: 9px;
  min-height: 9px;
  max-height: 9px;
  background-color: #ba0000;
  border-radius: 100px;
}

.shiftWorkedRedAndGreen {
  min-width: 9px;
  max-width: 9px;
  min-height: 9px;
  max-height: 9px;
  background: rgb(186, 0, 0);
  background: linear-gradient(
    66deg,
    rgba(186, 0, 0, 1) 50%,
    rgba(168, 204, 154, 1) 50%
  );
  border-radius: 100px;
}

.shiftWorkedRedAndBlue {
  min-width: 9px;
  max-width: 9px;
  min-height: 9px;
  max-height: 9px;
  background: rgb(186, 0, 0);
  background: linear-gradient(
    66deg,
    rgba(186, 0, 0, 1) 50%,
    rgba(20, 48, 81, 1) 50%
  );
  border-radius: 100px;
}

.unworkedOpac {
  opacity: 0.7;
}

.ghostShiftUnit {
  background-color: #deedff;
  opacity: 1;
  border-bottom: 2px solid #cce3ff;

  /* display: none; */
}

.ghostDotColour {
  background-color: #a8cc9a;
}
/* Edit shift modal */
.editShiftModalUnderlay {
  background: rgba(0, 0, 0, 0.45);
  /* background-color: #fff; */
  /* height: 130px; */
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 100vw;
  position: fixed;
  z-index: 999999998;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.075s;
          animation-duration: 0.075s;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.editShiftModalBox {
  background-color: #f4f8fe;
  color: #143051;
  min-width: 380px;
  max-width: 380px;
  border-radius: 10px;
  padding-top: 20px;
  text-align: left;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 45px;
  cursor: auto;
  /* border: 1px solid #e7ebef; */
  /* -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
}

.addUserToTeamHeaderContainer {
  max-height: 45px;
}
.shiftCloseHolder {
  min-width: 70px;
  max-width: 70px;
  margin-left: -5px;
  min-width: 33%;
  max-width: 33%;
}

.headerTitleColumnIncludingPubStatus {
  display: flex;
  flex-direction: column;
}

.shiftPublishedStatus {
  max-height: 24px;
  line-height: 21px;
  background-color: #a8cc9a;
  border: 1px #6db451 solid;
  color: white;
  font-size: 0.85em;
  font-weight: 500;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  /* margin-top: -7px; */
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-top: 10px;
  margin-bottom: -13px;
  margin-left: 20px;
  position: relative;
  z-index: 9999999;
  /* animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important; */
}

.unpubBg {
  background-color: #8997a8;
  border: 1px #5b728d solid;
}

.addUserToTeamTitle {
  text-align: center;
  /* background-color: black; */
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-left: 14px;
  font-size: 18px;
  font-weight: 500 !important;
}

.shiftCloseHolderRight {
  /* margin-left: -10px; */
  min-width: 90px;
  max-width: 90px;
  margin-left: -35px;
}

.editShiftDurationTopRightTxt {
  text-align: right;
  font-size: 1em;
  font-weight: 500;
  color: #8ea7c5;

  /* margin-right: 13px; */
}

.editShiftModalFooter {
  /* background-color: purple; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 50px;
  min-height: 50px;
  border-top: 1px solid #bac5d3;
}

.deleteShiftBtn {
  font-weight: 500;
  font-size: 14px;
  font-weight: 500;
  color: #592eb6;
  text-align: center;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  min-height: 35px;
  max-height: 35px;
  /* line-height: 26px; */
  background-color: #859dbb;
  border: 1px solid #7c8fa5;
  align-items: center;
  margin-top: 0px;
  border-radius: 5px;
  /* padding-top: 2px; */
  justify-content: center;
  cursor: pointer;
  color: white;
  margin-left: 10px;
  min-width: 68px;
  max-width: 68px;
}

.deleteShiftBtn:hover {
  background-color: #7c8fa5;
}

.notSureDeleteShiftBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 72px;
  padding-left: 9px;
  padding-right: 9px;
  min-height: 35px;
  max-height: 35px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  min-width: 70px;
  flex-direction: row-reverse;
  margin-right: 1px;
}

.notSureDeleteShiftBtn:hover {
  background-color: #bbcada;
}

.pubShiftBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 35px;
  max-height: 35px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
}

.pubShiftBtn:active {
  /* transform: scale(0.997); */
}

.pubShiftBtn:hover {
  background-color: #bbcada;
}

.needToPubColour {
  background-color: #07f;
  border: solid 1px #0469de;
  min-width: 70px;
  /* margin-right: -4px; */
}

.needToPubColour:hover {
  background-color: #0469de;
}
.closeEditShiftBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  max-width: 62px;
  min-width: 62px;
}
.closeEditShiftBtn:hover {
  background-color: #214978;
}

.closeMgrPurpleBtn {
  background-color: #663fa7 !important;
  border: 1px solid #e6eaee !important;
}

.editShiftModalBody {
  /* background-color: yellow; */
  border-top: solid 2px #e6eaee;
  border-right: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;

  border-radius: 5px 5px 0px 0px;
  max-width: 100%;
  /* max-width: 200px; */
  overflow: auto;
  overflow-x: hidden;
  /* max-height: 280px; */
  /* -ms-overflow-style: none; /* IE and Edge */
  /* scrollbar-width: none; Firefox */
  padding-top: 10px;
  background-color: rgb(252, 253, 255);
  background-color: white;
  max-height: 450px;
  min-height: 450px;
  /* min-width: 500px; */
}

/* .editShiftModalBody::-webkit-scrollbar {
  display: none;
} */

@media only screen and (max-width: 480px) {
  .editShiftModalBody {
    /* -ms-overflow-style: none; IE and Edge */
    /* scrollbar-width: none; Firefox */
    /* min-width: 500px; */
  }

  .editShiftModalBody::-webkit-scrollbar {
    /* display: none; */
  }

  .editShiftModalBox {
    min-width: 100vw;
    max-width: 100vw;
    margin-top: -80px;
  }
}

.editShiftRow {
  /* background-color: #fff; */
  border-bottom: 1px solid #e0ebf8;
  display: flex;
  padding-top: 3px;
  justify-content: space-between;
  /* min-height: 55px; */
}

.clickShiftRow {
  cursor: pointer;
}

.clickShiftRow:hover {
  background-color: #edf3f9;
}

.clickShiftRow:active {
  background-color: #f4f8fe;
}

.editShiftRow:last-of-type {
  border-bottom: none;
  padding-bottom: 15px;
}

.shiftEditTitle {
  margin-left: 18px;
  font-size: 18px;

  font-weight: 500;
  color: #143051;
}

.specialNameTitle {
  line-height: 22px;
  margin-top: 12px;
  margin-bottom: 12px;
  padding-right: 17px;
  color: #07f;
}

.shiftNoteRowSpace {
  /* background-color: pink; */
  margin-left: 5px;
  line-height: 20px;
  font-size: 16px;
  margin-top: -6px;
  /* max-width: 70%; */
  padding-bottom: 14px;
  padding-right: 10px;
  color: #496375;
  font-weight: 400;
  /* font-weight: 300; */
}

.editShiftPencil {
  margin-right: 18px;
  margin-top: 20px;
  cursor: pointer;
  min-width: 35px;
  max-width: 35px;
  min-height: 35px;
  max-height: 35px;
  border-radius: 5px;
  padding: 9px;
  border: #e3e8ee solid 2px;
  background-color: white;
}

.editShiftPencil:hover {
  border-color: #c5d1e1;
  /* opacity: 0.8; */
  /* transform: scale(1.1); */
}

.knobEditShift {
  margin-top: 6px;
  margin-right: 15px;
}

.absentBannerShift {
  text-align: center;
  background-color: #ba0000;
  color: white;
  max-height: 25px;
  line-height: 25px;
  font-size: 0.8em;
  margin-top: -10px;
  border-radius: 51px;
  max-width: 60px;
  margin: 0 auto;
  margin-top: -8px;
}

.editShiftInfoWarning {
  font-size: 0.72em;
  line-height: 20px;
  margin-left: 8px;
  /* max-width: 300px; */
  margin-top: 0px;
  margin-bottom: 6px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.absentBannerShiftSpacebelow {
  /* background-color: black; */
  min-height: 15px;
  min-width: 100%;
}

.shiftRateDropdown {
  color: #496375;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  padding-left: 14px;
  text-align: left;
  max-width: 78px;
  line-height: 26px;
  font-weight: 500;
  height: 35px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  /* background: url('../../img/general/downArrowNavy.svg') no-repeat right #fff; */
  border: 2px solid #dde9f9;
  -webkit-appearance: none;
  background-position-x: 54px;
  margin-top: 4px;
  margin-right: 22px;
  cursor: pointer;
}

.shiftRateDropdown:hover {
  border-color: #bac5d3;
}
.knobRed {
  background-color: #ba0000;
}

.txtRed {
  color: #ba0000;
}

.txtGreen {
  color: #6db451;
}

.shiftBrkInput {
  background-color: #fff;
  max-width: 60px;
  line-height: 35px;
  height: 35px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 0.9em;
  font-weight: 500;
  padding-top: 2px;
}

.shiftBrkInput:hover {
  border-color: #bac5d3;
}

.shiftDataTickImg {
  max-width: 28px;
  background-color: #00ccff;
  border: 1px solid #143051;
  border-radius: 5px;
  max-height: 28px;
  margin-right: 20px;
  margin-top: 9px;
  cursor: pointer;
}

.startEndShiftContainer {
  display: flex;
  align-items: center;
  max-width: 190px;
  /* background-color: #00ccff; */
  /* margin-left: 100px; */
  /* padding-top: -10px; */
  margin-top: 6px;
  margin-bottom: 4px;
}

.editShiftTimesUntilTxt {
  font-size: 11px;
  padding-left: 7px;
  padding-right: 9px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}

.shiftTimeInput {
  background-color: #fff;
  min-width: 77px;
  max-width: 77px;

  line-height: 30px;
  padding-top: 0px;
  height: 35px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 16px;
  font-weight: 500;
  /* -webkit-appearance: none; */
  /* -moz-appearance: none; */
  /* appearance: none; */
  cursor: text;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-left:8px; */
}

.shiftTimeInput:hover {
  border-color: #bac5d3;
}

.shiftEditDatePickerContainer {
  background-color: orange;
  /* max-height: 150px; */
  /* min-width: 00px; */
}

.usrDatePickerStartDate {
  /* background-color: pink; */
  /* margin-top: -30px; */
  position: relative;
  z-index: 1;
}

.datepickerShiftTickImg {
  /* margin-top: -120px; */
}

.saveShiftDateBtn {
  margin: 0 auto;
  text-align: center;
  /* background-color: blue; */
  max-width: 100px;
  max-height: 30px;
  line-height: 30px;
  position: relative;
  z-index: 2;
  margin-top: 30px;
  margin-bottom: -35px;
  font-size: 0.9em;
  color: #00ccff;
  margin-top: 4px;
}

.closeShiftDatepickerBtn {
  /* background-color: #8ea7c5; */
  font-size: 0.9em;
  /* color: white; */
  line-height: 25px;
  text-align: center;
  margin: 0 auto;
  border-radius: 15px;
  height: 25px;
  margin-top: 3px;
  /* max-width: 66px; */
}

.topEditShiftRow {
  border-bottom: none;
  height: 35px;
  /* padding-bottom: -10px; */
}

.editShiftCloseDatePickerImg {
  width: 15px;
  margin-right: 12px;
  margin-top: 10px;
}

.centerDatePicker {
  /* background-color: purple; */
  margin: 0 auto;
}

.shiftDatepickerBg {
  /* background-color: #00ccff; */
}

.shiftAlreadyExistsUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.shiftAlreadyExistsModalBox {
  background-color: white;
  min-width: 75vw;
  max-width: 75vw;
  /* min-height: 90vh;
max-height: 90vh; */
  /* height: 80vh; */
  border-radius: 15px;
  /* margin-top: -40px; */
  /* display: flex; */
  /* justify-content: center; */
  align-items: center;
  color: #143051;
  margin-top: -100px;
  text-align: center;
  /* position: fixed;
bottom: 0;
text-align: center; */
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -webkit-animation-name: moveEditTeamUserBoxUp !important;
          animation-name: moveEditTeamUserBoxUp !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.shiftAlreadyExsitsModalTxt {
  max-width: 90%;
  /* background-color: yellow; */
  margin: 0 auto;
  margin-top: 20px;
  line-height: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 500;
}

.closeShiftAlreadyExistsModalBtn {
  background-color: #214978;
  border: 1px solid #143051;
  color: white;
  min-height: 32px;
  line-height: 30px;
  max-width: 90px;
  border-radius: 35px;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 20px;
  font-weight: 500;
}

.addShiftAddBtn {
  position: fixed;
  top: calc(100% - 148px);
  right: 45px;
  background-color: #3b597c;
  border: 1px solid #143051;
  color: white;
  border-radius: 5px;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  line-height: 41px;
  font-size: 2.5em;
  z-index: 9999999;
}

.addShiftModalBox {
  background-color: #f4f8fe;
  color: #496375;
  min-width: 300px;
  max-width: 300px;
  border-radius: 10px;
  text-align: left;
  line-height: 45px;
  -webkit-animation-name: zoomModalIn;
          animation-name: zoomModalIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  cursor: default;
}

.page0AddShiftModalUp100px {
  /* margin-top: -140px; */
}

.addShiftModalHeader {
  display: flex;
  /* background-color: blue; */
  justify-content: space-between;
  margin-top: 5px;
  /* border-bottom: 1px solid #5b728d; */
}

.closeAddShiftModalImg {
  min-width: 14px;
  max-width: 14px;
  /* padding-right: 66px; */
  margin-left: 20px;
  cursor: pointer;
  margin-right: 20px;
}

.closeAddShiftModalImg:hover {
  opacity: 0.8;
}

.closeAddShiftModalImgRight {
  min-width: 14px;
  margin-right: 20px;
  visibility: hidden;
}

.shiftDurationTopRightOfAddShiftModal {
  min-width: 80px;
  max-width: 80px;
  font-size: 1em;
  /* margin-top: 7px; */
  padding-right: 8px;
  text-align: center;
  margin-top: 1px;
  color: #496375;
  font-weight: 500;
}

.shiftDurationTopRightOfAddShiftModalHidden {
  min-width: 80px;
  max-width: 80px;
  font-size: 0.94em;
  padding-right: 8px;
  text-align: center;
  margin-top: 1px;
  /* color: #c0d1e9; */
  visibility: hidden;
}

.addShiftHeaderTitle {
  font-size: 18px;
  padding-top: 4px;
  /* margin-top: 2px; */
  /* margin-left: 55px; */
  font-weight: 500;
  color: #143051 !important;
}

.addShiftMidContainer {
  /* background-color: white; */
  /* max-width: 90%; */
  max-height: 320px;
  min-height: 320px;
  margin: 0 auto;
  margin-top: 10px;
  text-align: center;
  /* border-top: 2px solid #e6eaee; */
  padding-bottom: 20px;
  border-radius: 6px 6px 0px 0px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
  padding-top: 0px;

  /* min-height: 310px;
  max-height: 310px; */
}

.minHeightKBopen {
  /* min-height: 350px; */
  /* overflow: scroll; */
  /* max-height: 330px; */
  /* margin-bottom: -20px; */
  /* background-color: blue; */
}

.heightenWhenPublicKBisOpen {
  min-height: 100px;
}

.hideBinOnInitialMpwTimelineObj {
  pointer-events: none;
  visibility: hidden;
}

.addShiftInputLabel {
  margin-bottom: -10px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 7px;
  margin-left: 5px;
  color: #214978;
}

.overtimeRateContainer {
  max-height: 100px;
  margin-left: 10px;
  /* background-color: blue; */
}

.addOvertimeRateTitle {
  /* margin-bottom: -10px; */
  font-size: 1em;
  font-weight: 500;
  /* margin-top: -2px; */
  margin-top: 22px;
  margin-bottom: -8px;
  margin-left: -14px;
}

.pullpublicnotelabelupabit {
  margin-top: -12px;
}

.addShiftDateInput {
  border-radius: 5px;
  /* margin-top: -30px; */
  border: none;
  background-color: #fff;
  color: #496375;
  min-height: 35px;
  line-height: 35px;
  font-size: 16px !important;
  padding-left: 14px;
  padding-right: 8px;
  letter-spacing: 1px;
  padding-top: 1px;
  /* text-align: center; */
  font-weight: 500;
  max-width: 174px;
  min-width: 174px;
  background: url(/static/media/calicongrey.9c7e1aba.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 142px;
  border: 2px solid #dde9f9;
  margin-left: 0px;
  margin-top: 4px;
  cursor: text;
  /* -webkit-min-logical-width:150px ; */
  /* text-align: left; */
  /* animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important; */

  /* margin-bottom: 2px; */

  /* margin-top: -10px; */
}

.addShiftDateInput:hover {
  border-color: #bac5d3;
}

.invalidInputDate {
  border: 1px solid #ba0000;
  /* background-color: #cc4d4d; */
  color: #cc4d4d;
}

.startDateIsBeforeUserJoinedWarningTxt {
  font-size: 0.8em;
  line-height: 18px;
  max-width: 80%;
  /* margin: 0 auto; */
  margin-top: 5px;
  margin-bottom: -25px;
  font-weight: 500;
  text-align: left;
  opacity: 0.8;
  /* background-color: yellow; */
  min-height: 20px;
  max-height: 20px;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  /* color: white; */
  -webkit-appearance: none;
}

input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
  /* background-color: blue; */
}

.addShiftTimeContainer {
  display: flex;
  min-width: 98%;
  max-width: 98%;
  justify-content: flex-start;
  /* margin: 0 auto; */
}

.addShiftTimesContainer {
  min-width: 100%;
  /* background-color: pink; */
  margin-left: -4px;
  /* display: flex; */
}

.addShiftTimesStartAndEnd {
  display: flex;
  justify-content: flex-start;
  max-height: 38px;
  font-weight: 500;
}

.addShiftStartTitle {
  margin-left: 3px;
}

.addShiftEndTitle {
  margin-left: 60px;
}
.addShiftStartTimeInput {
  background-color: #fff;
  width: 77px;
  max-width: 77px;
  /* padding-left: 4px; */
  line-height: 30px;
  padding-top: 0px;
  height: 30px;
  border: 1px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 0.9em;
  font-weight: 500;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  -webkit-appearance: none;
  appearance: none;
}

.addShiftBreakRow {
  margin-top: 12px;
}

/* inputs up and above, when making responsive, for desktop, remove paddning-left and make text-align center */

.addShiftEndTimeInput {
  background-color: #fff;
  width: 77px;
  max-width: 77px;
  /* padding-left: 4px; */
  line-height: 30px;
  padding-top: 0px;
  height: 30px;
  border: 1px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 0.9em;
  font-weight: 500;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  -webkit-appearance: none;
  appearance: none;
}

.addShiftEndMarginLeft {
  margin-left: 18px;
}

.addShiftDivider {
  min-height: 1px;
  background-color: #e0ebf8;
  margin-top: 14px;
}

.lowerLevelInputlabel {
  margin-top: 2px;
  padding-top: 20px;
}

.fixedDividerAddShift {
  position: relative;
  margin-top: -84px;
  /* top: -200px; */
  margin-bottom: 80px;
}

.addShiftLoadTempBtn {
  font-size: 0.9em;
  color: #fff;
  background-color: #214978;
  border: solid #143051 2px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  margin-right: 12px;
  line-height: 26px;
  border-radius: 5px;
  min-height: 30px;
  /* margin-top: 18px; */
  /* margin-bottom: 8px; */
  margin-left: 84px;
  cursor: pointer;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.addShiftBreakInput {
  background-color: #fff;
  width: 57px;
  max-width: 77px;
  /* padding-left: 4px; */
  line-height: 30px;
  padding-top: 0px;
  height: 30px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 0.9em;
  font-weight: 500;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  -webkit-appearance: none;
  appearance: none;
}

.addTilBreakInput {
  background-color: #fff;
  width: 57px;
  max-width: 77px;
  /* padding-left: 4px; */
  line-height: 30px;
  padding-top: 0px;
  height: 30px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 0.9em;
  font-weight: 500;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  -webkit-appearance: none;
  appearance: none;
  margin-left: -26px;
}

.addShiftFooter {
  /* background-color: orange; */
  /* margin-top: 20px; */
  display: flex;
  align-items: center;
  min-width: 90%;
  max-width: 90%;
  padding-top: 2px;
  padding-bottom: 5px;
  justify-content: space-between;
  align-items: center;
  /* border-top: 2px solid #143051; */
  margin: -4px auto;
  max-height: 50px;
  margin-bottom: 8px;
}

.addShiftTooLateEarlyTxt {
  color: #bbcada;
  line-height: 14px;
  font-size: 12px;
  margin-left: 6px;
  font-weight: 500;
  border-left: 2px solid #bbcada;
  padding-left: 11px;
  padding-right: 20px;
}

.addShiftFooterWithNoTopBorder {
  /* background-color: orange; */
  /* margin-top: 20px; */
  display: flex;
  align-items: center;
  min-width: 80%;
  max-width: 80%;
  padding-top: 4px;
  padding-bottom: 7px;
  justify-content: space-between;
  align-items: center;
  /* border-top: 2px solid #143051; */
  margin: 0 auto;
}

.cancelAddShiftTxt {
  font-size: 16px;
  font-weight: 500;
  margin-top: 3px;
  /* background-color: pink; */
  min-width: 42px;
  margin-right: 8px;
  margin-left: 10px;
  cursor: pointer;
  color: #859dbb;
}

.cancelAddShiftTxt:hover {
  color: #516b8b;
}

.submitAddShiftOnUser {
  min-height: 35px;
  /* line-height: 31px; */
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: 63px;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #0bf;
  border: 1px solid #0af;
}

.submitAddShiftOnUser:hover {
  background-color: #0af;
}
.addBtnColours {
  background-color: #07f;
  border: #0469de 1px solid;
}

.fadedSubmit {
  opacity: 0.4;
  pointer-events: none;
}

.breakRateContainerAddShift {
  display: flex;
  max-width: 230px;
  margin: 0 auto;
  justify-content: space-around;
}

.addShiftRateDropdown {
  color: #496375;
  border-radius: 5px;
  font-size: 1em;
  font-weight: 500;
  padding-left: 14px;
  text-align: left;
  max-width: 78px;
  line-height: 26px;
  font-weight: 500;
  height: 30px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  /* background: url('../../img/general/downArrowNavy.svg') no-repeat right #fff; */
  border: 1px solid #dde9f9;
  -webkit-appearance: none;
  background-position-x: 54px;
  margin-top: 6px;
  cursor: pointer;
  /* margin-right: 12px; */
}

.addShiftRateContainer {
  /* background-color: blue; */
  max-height: 70px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 12px;
}

.rateLabelAddShift {
  margin-top: -7px;
  margin-bottom: -12px;
  margin-left: 3px;
}

.clearTxtAddShift {
  opacity: 0.5;
  margin-left: 10px;
}

.shiftMenuImg {
  max-width: 25px;
  margin-top: -65px;
}

.shiftTeamDropdown {
  max-width: 277px;
  min-width: 80%;
  background: url(/static/media/downArrowWhite.97cd93aa.svg) no-repeat right
    #687d97;
  -webkit-appearance: none;
  background-position-x: 92%;
  border-radius: 100px;
  color: white;
  padding-left: 14px;
  font-size: 1em;
  text-align: left;
  min-height: 35px;
  line-height: 33px;
  border: 1px solid #36495f;
  font-weight: 500;
  /* font-size: */
}

.addShiftNoteTextBox {
  /* margin: 0 auto; */
  min-width: 90%;
  min-height: 140px;
  resize: none;
  padding: 5px;
  border: 1px solid #36495f;
  border-radius: 5px;
  background-color: #859dbb;
  color: white;
  margin-top: 10px;
  font-weight: 500;
  font-size: 1.1em;
  overflow: scroll;
  letter-spacing: 0px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.addNoteToAddShiftBtn {
  background-color: #6e7f94;
  border-radius: 25px;
  font-size: 1em;
  max-width: 114px;
  height: 35px;
  line-height: 31px;
  margin: 0 auto;
  margin-top: 20px;
  color: white;
  font-weight: 500;
  border: 1px solid #36495f;
}

.addShiftAddTagsContainer {
  display: flex;
  /* background-color: orange; */
}

.mgrPublicNoteSpacer {
  /* background-color: red; */
  min-height: 18px;
  min-width: 30px;
}

.shiftTemplateUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.shiftTemplateModalBox {
  background-color: #f4f9ff;
  min-width: 300px;
  max-width: 300px;
  border-radius: 10px;
  align-items: center;
  color: #143051;
  /* position: fixed;
  top: 30%;
  left: 10%; */
  text-align: center;

  margin: 0 auto;
  cursor: default;
}

.shiftTemplateModalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 1px solid #c0d1e9; */
  /* background-color: #f4f9ff; */
  min-height: 54px;
}

.closeShiftTemplateModalImage {
  max-width: 14px;
  /* padding-right: 6px; */
  min-width: 14px;
  margin-left: 20px;
  cursor: pointer;
  /* padding-ir */
}

.closeShiftTemplateModalImage:hover {
  opacity: 0.8;
}

.emptyShiftTemplateShiftModalHeaderSpaceRight {
  max-width: 12px;
  min-width: 12px;
  margin-right: 15px;
}

.shiftTemplateHeaderTitleTxt {
  font-size: 18px;
  /* margin-top: 4px; */
  font-weight: 500;
}

.shiftTemplateModalContentContainer {
  background-color: #fff;
  max-height: 300px;
  overflow-y: scroll;
  padding-top: 20px;
  padding-bottom: 15px;
  max-width: 86%;
  margin: 0 auto;
  margin-bottom: 20px;
  border: 1px solid #e6eaee;
  border-top: 2px solid #e6eaee;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left:5px;
}

.shiftTemplateUnitTxt {
  line-height: 2.5em;
  font-weight: 500;
  font-size: 16px;
  color: #496375;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  background-color: white;
  max-width: 75%;
  margin: 6px auto;
  cursor: pointer;
  text-align: left;
  min-width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 240px !important;
  min-width: 240px !important;

}

.shiftTemplateUnitTxt:hover {
  border-color: #bac5d3;
  background-color: #f4f9ff;
}

.shiftTemplateSpan {
  font-weight: 500;
  font-size: 0.7em;
}

.fadeAndNoPointer {
  opacity: 0.5;
  pointer-events: none;
}

.greenSubmitBtnBg {
  background-color: #0077ff;
  color: white;
}

.closeMgrNoteBtn {
  background-color: white;
  display: flex;
  justify-content: space-around;
  max-height: 28px;
  line-height: 26px;
  border-radius: 25px;
  margin: 0 auto;
  max-width: 116px;
  margin-top: 25px;
  font-weight: 500;
  border: 1px solid #143051;
}

.addShiftInputLabelActive {
  font-size: 0.9em;
  color: #496375;
  padding-left: 6px;
}

.greyCloseBtnAddShiftNote {
  max-width: 10px;
  margin-right: 8px;
}

.addShiftNotesDivider {
  min-height: 1px;
  min-width: 100px;
  background-color: #5b728d;
  margin-top: 30px;
}

.abovePublicSpacer {
  min-height: 20px;
}

.addShiftPageKeyContainer {
  /* background-color: black; */
  margin-top: 12px;
  min-height: 10px;
  min-width: 100px;
}

.addShiftPageKeyBox {
  /* background-color: blue; */
  min-height: 10px;
  max-width: 20px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.addShiftKey {
  min-height: 5px;
  min-width: 5px;
  max-width: 5px;
  max-height: 5px;
  background-color: #859dbb;
  border-radius: 100%;
}

.addShiftKeySel {
  min-height: 7px;
  min-width: 7px;
  max-width: 7px;
  max-height: 7px;
  background-color: #143051;
  border-radius: 100%;
}

.hourlyRateContainer {
  display: flex;
  /* background-color: orange; */
  align-items: center;
}

.notEditingHourlyRate {
  /* pointer-events: none;
  background-color: white;
  color: #143051;
  border: 1px solid #c0d1e9; */
}

.displayedHourlyRateNonEdit {
  display: flex;
}

.saveHourlyRateTickImg {
  width: 28px;
  margin-left: 10px;
  border: 1px solid #143051;
  border-radius: 8px;
  background-color: #8ea7c5;
}

.hourlyRateVal {
  font-size: 1.1em;
  /* margin-bottom: -2px; */
  margin-right: 16px;
  font-weight: 400;
  color: #8997a8;
  margin-top: 4px;
  padding-bottom: 2px;
}

.editHourlyRatePencil {
  /* margin-top: 1px; */
}

.pullHourlyRateToZeroImg {
  margin-right: 10px;
  width: 22px;
  opacity: 0.5;
}

.powerBtnForHourlyRateImg {
  margin-right: 15px;
  width: 22px;
  opacity: 0.5;
}

.hourlyRateTitleAndHistoryBtnContainer {
  /* background-color: orange; */
  display: flex;
  max-width: 100px;
  align-items: center;
  justify-content: space-between;
  margin-left: 3%;
}

.hourlyRateHistoryImg {
  margin-top: 6px;
}

.hourlyRateTimelineRow {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  cursor: pointer;
}

.mpwTimelineUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.hourlyRateTimelineContainer {
  background-color: #fff;
  /* border: 1px solid #eaf3fd; */
  display: flex;
  flex-direction: column;
  min-width: 340px;
  max-width: 340px;
  /* max-height: 80vh; */
  margin: 0 auto;
  padding-bottom: 5px;
  padding-top: 15px;
  border-radius: 10px;
  cursor: default;
  -webkit-animation-name: zoomModalIn;
          animation-name: zoomModalIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  /* border-bottom: 1px solid #e6eaee; */
  /* margin-bottom: 20px; */
}

.timelineObjDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid #c0d1e9 2px;
  background-color: #deedff;
  border-radius: 10px;
  min-height: 180px;
  /* margin-top: 15px; */
  max-width: 90%;
  margin: 15px auto;
  /* border: 1px solid black; */
}

.selectedTimelineObjDiv {
  background-color: #c1d1e6;
}

.timelineObjDiv:last-of-type {
  /* background-color: yellow; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: solid #fff 0px; */
}

.timelineObjLeft {
  /* background-color: red; */
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  display: block;
  text-align: left;
  /* text-indent: 18px; */
  font-size: 0.9em;
  min-width: 185px;
  min-height: 40px;
  align-items: center;
  padding-top: 16px;
  margin-left: 10px;
}

.timelineObjEdit {
  /* background-color: lime; */
  /* padding-right: 5%; */
  margin-top: 20px;
  min-width: 60px;
  text-align: center;
  display: flex;
  margin-left: 17px;
  padding-bottom: 20px;
}

.timelineObjMiddle {
  margin-top: 20px;
  margin-right: 10px;
}

.timelineObjToTxt {
  font-size: 0.85em;
  /* padding-left: 15px; */
  /* padding-top: 8px; */
  padding-bottom: 5px;
  color: #8ea7c5;
  background-color: #8ea7c5;
  background-color: #36495f;
  color: white;
  font-weight: 500;
  max-width: 52px;
  text-align: center;
  padding-left: 10px;
  padding-right: 12px;
  padding-top: 6px;
  border-radius: 10px;
  line-height: 14px;
  margin-left: 5%;
  margin-bottom: 10px;
  /* padding-right: 12px; */
}

.pushDown12px {
  margin-top: 12px;
}

.timelineObjStartTxt {
  margin-left: 8px;
  letter-spacing: 2px;
  /* margin-top: 22px; */
  font-weight: 500;
  color: #6e7f94;
  padding-left: 8px;
  /* margin-top: 3px; */
}

.fromuntilspacer {
  background-color: #bfdcf0;
  max-height: 2px;
  margin-bottom: 12px;
  margin-top: 12px;
}

.timelineObjEndTxt {
  margin-left: 8px;
  letter-spacing: 2px;
  margin-bottom: 18px;
  font-weight: 500;
  color: #6e7f94;
  padding-left: 8px;
}

.killLeftMargin {
  margin-left: 8%;
  min-width: 100px;
}

.hourlyRateHeaderBar {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  padding-left: 3%;
  padding-right: 3%;
}

.hourlyTimelineHeading {
  text-align: left;
  /* margin-left: 3%; */
  font-size: 0.8em;
  font-weight: 500;
  background-color: #516b8b;
  color: white;
  height: 30px;
  line-height: 32px;
  min-width: 165px;
  text-align: center;
  border-radius: 20px;
  display: flex;
}

.closeHourlyRateHistory {
  max-width: 10px;
  margin-left: 10px;
  margin-right: 12px;
}

.removeTimeline {
  font-size: 14px;
  font-weight: 500;
  color: white;
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  background-color: #3b597c;
  border: solid #214978 1px;
  cursor: pointer;
  color: #fff;
}

.removeTimeline:hover {
  background-color: #214978;
}

.removeTimelineSure {
  font-size: 13px;
  font-weight: 500;
  color: white;
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  border: solid 1px #ba0000;
  background-color: #cc4d4d;
}

.editTimelineObjImg {
  margin-right: 18px;
  max-width: 20px;
  margin-left: -6px;
  min-width: 20px;
}

.saveTimelinePeriodBtnImg {
  max-width: 20px;
  /* margin-right: 13px; */
  min-width: 30px;
  border: 1px solid #496375;
  border-radius: 10px;
  background-color: #00ccff;
  cursor: pointer;
}

.addHourlyRateContainer {
  /* background-color: yellow; */
  display: flex;
  justify-content: flex-end;
  padding-right: 6%;
  margin-top: 5px;
}

.addHourlyRateTimelineObject {
  text-align: left;
  background-color: #0af;
  border: 1px solid #07f;
  height: 35px;
  align-items: center;
  text-indent: 10px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  color: white;
  border-radius: 5px;
  margin-top: 2px;
  font-weight: 500;
  cursor: pointer;
  color: #fff;
  min-width: 73px;
  /* padding-left: 2px; */
  padding-right: 2px;
}

.addHourlyRateTimelineObject:hover {
  background-color: #0fa2eb;
}

.addHourlyRateObjTxtAdd {
  /* margin-top: -3px; */
}

.whitePlusForAddingTimelineObj {
  /* margin-top: -1px; */
  margin-right: 4px;
  margin-left: 4px;
  max-width: 22px;
  /* font-size: 600; */
}

.editPeriodValid {
  background-color: #214978;
}

.originalDateShadowed {
  font-weight: 400;
  margin-left: 24px;
}

.editRateContainer {
  /* background-color: #6e7f94; */
  border-bottom: 2px solid #6e7f94;
  /* color: white; */
  /* text-align: left; */
  /* border-radius: 30px; */
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-top: 3px;
  padding-bottom: 2px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 500;
  margin-bottom: 3px;
  /* margin-right: 11px; */
}

.borderless {
  border: none;
}

.cancelEditTimelinePeriodHourlyRate {
  max-width: 22px;
  min-width: 22px;

  margin-right: 12px;
  /* margin-left: 3px; */
  margin-top: 3px;
}

.timelineDisclaimerTxt {
  font-size: 0.8em;
  max-width: 90%;
  margin: 0 auto;
  text-align: left;
  margin-top: 15px;
  line-height: 1.5em;
}

.hourlyRateExplainedBtnContainer {
  display: flex;
  justify-content: flex-end;
}

.explainedHourlyRateTimeline {
  background-color: #fff;
  border: solid 1px #e6eaee;
  display: flex;
  /* color: #7495ba; */
  /* color: white; */
  font-size: 16px;
  max-width: 120px;
  min-width: 120px;
  padding-left: 10px;
  text-align: center;
  line-height: 25px;
  height: 26px;
  border-radius: 15px;
}

/* .explainedBookIcon {
  max-width: 15px;
  margin-left: 10px;
} */

/* .hourlyTimelineHeading {
  max-width: 90px;
} */

.addOrRemovePeriodContainer {
  display: flex;
  justify-content: center;
  min-width: 100%;
  /* padding-right: 5%; */
  padding-bottom: 10px;
  /* background-color: black; */
}
.hourlyRateTimeRemoveAllAddBtn {
  display: flex;
  /* background-color: #6e7f94; */
  min-width: 90%;
  max-width: 90%;
  justify-content: space-between;
  /* border: 1px solid #143051; */

  /* margin: 0 auto; */
  /* margin-top: 20px; */
  /* text-align: right; */
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;

  border-radius: 10px;
}

/* make this uber-responsive */
.hourRateTimelineHeaderDivider {
  background-color: #c0d6f0;
  min-height: 1px;
  max-height: 1px;
  margin-top: 17px;
  min-width: 70px;
}

.timelineObjRightSide {
  display: block;
  /* background-color: blue; */
  min-width: 104px;
  margin-right: 10px;

  /* margin-right: 10px; */
}

.schedRowunitMiddle {
  /* background-color: lime; */
  width: 35%;
  max-width: 90px;
  display: flex;
  justify-content: center;
}

.deleteTimelineObjImg {
  max-width: 23px;
  margin-right: 22px;
}

.newObjBg {
  background-color: #c0d1e9;
  border-radius: 10px;
}

.onGoingHourlyRateTxt {
  background-color: #dde9f9;
  max-width: 260px;
  margin: 0 auto;
  font-size: 0.7em;
  text-align: center;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 15px;
  color: #687d97;
  margin-top: 15px;
  font-weight: 400;
  text-align: left;
}

.timelineInstruction {
  font-size: 12px;
  max-width: 92%;
  font-weight: 400;
  padding-left: 25px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: left;
  color: #516b8b;
  line-height: 16px;
}

.x0019kw {
  margin-top: 20px;
}
.hourlyRateHistoryBtnContainer {
  /* display: flex;
    background-color: #516b8b;
    justify-content: space-around;
    margin-left: 14px;
    min-height: 28px;
    max-height: 28px;
    line-height: 30px;
    color: white;
    min-width: 85px;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 0.9em;
    border-radius: 15px; */
  /* background-color: #cbdeeb; */
  color: #143051;
  /* min-height: 37px; */
  display: flex;
  line-height: 18px;
  align-items: center;
  padding: 0px 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  /* text-indent: 3px; */
  font-weight: 500;
  max-height: 30px;
  font-size: 0.9em;
  border-radius: 10px;
  /* border: 1px solid #143051; */
  margin-left: 18px;
  margin-top: 0px;
  background-color: #516b8b;
  border: 1px #143051 solid;
  color: white;
  background-color: white;
  color: #496375;
  border-color: #dde9f9;
  /* /* opacity: 0.6; */
}

.whiteCrossToCloseTimeline {
  margin-left: 7px;
  max-width: 12px;
  /* margin-top: 1px; */
}

/* .hourlyRateHistoryImg {
  max-width: 18px;
  margin-top: 1px;
} */

.editShiftNoteOnAdmin {
  /* margin: 0 auto; */
  /* min-width: 100%; */
  min-height: 130px;
  resize: none;
  padding: 5px;
  border: 1px solid #dde9f9;
  border-radius: 5px;
  background-color: white;
  /* color: #496375; */
  font-size: 1em;
  overflow-y: scroll;
  line-height: 20px;
  font-size: 16px;
  margin-top: 0px;
  max-width: 94%;
  min-width: 94%;
  /* margin-left: 5%; */

  margin-bottom: -15px;
  font-weight: 400;
  color: #496375;

  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;
  /* margin-right: 4px; */
  color: #6a8bb1;
  /* min-height: 35px; */
  padding-left: 5px;
  font-weight: 400;
}

.editShiftNoteOnAdmin::-webkit-input-placeholder {
  color: #6a8bb1;
}

.editShiftNoteOnAdmin:-ms-input-placeholder {
  color: #6a8bb1;
}

.editShiftNoteOnAdmin::placeholder {
  color: #6a8bb1;
}

.editShiftNoteOnAdmin:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 6px;
  background-color: #fdfeff;
}
.saveShiftTagImg {
  /* margin-right: 12px; */
  border: 1px solid #143051;
  background-color: #00ccff;
  border-radius: 8px;
}

.saveOrClearTagValue {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-right: 7px;
  max-height: 96px;
}

.clearTagValue {
  min-height: 35px;
  line-height: 26px;
  color: white;
  font-weight: 500;
  border-radius: 5px;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: -6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  /* margin-bottom: -2px; */
  cursor: pointer;
  background-color: #cbd7e6;
  border: 1px solid #bbcada;
}

.clearTagValue:hover {
  background-color: #bbcada;
}

.noteRow {
  padding-bottom: 30px;
}
.shiftTagHolder {
  min-width: 88%;
  max-width: 85%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 7px;
  margin-top: -9px;
  /* background-color: #f4f8fe; */
  border-radius: 3px;
  margin-bottom: 8px;
  padding-left: 4px;
  padding-right: 4px;
  /* border: 1px solid #e3e8ee; */
}

.tagUnit {
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: -moz-fit-content;
  max-height: -webkit-fit-content;
  max-height: fit-content;
  align-items: center;
  border-radius: 4px;
  color: #496375;
  font-weight: 500;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  color: #4f566b;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  margin-left: 4px;
  margin-top: 5px;
}

.tagUnit:first-child {
  margin-left: 0px;
}

.tagTxt {
  line-height: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-weight: 600;
  color: #143051;
}

.addTagUnit {
  /* background-color: blue; */
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 35px;
  line-height: 28px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #143051;
  margin-top: 5px;
  margin-right: 5px;
  background-color: #0077ff;
  color: white;
  min-height: 40px;
  margin-top: 13px;

  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;
  margin-right: 4px;
  color: #6a8bb1;
  min-height: 35px;
  padding-left: 5px;
  font-weight: 500;
  border: 2px solid #dde9f9;
  background-color: white;
  border-radius: 5px;
}

.pullTagImg {
  max-width: 10px;
  min-width: 10px;
  margin-left: 8px;
  /* margin-top:   3px; */
  cursor: pointer;
}

.pullTagImg:hover {
  opacity: 0.8;
  /* max-width: 12px; */
  /* min-width: 12px; */
}

.addTagIcon {
  min-width: 22px;
  max-width: 22px;
  padding: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.tagInput {
  /* background-color: #f4f8fe; */
  border: none;
  color: #496375;
  /* border-bottom: 1px solid #214978; */
  font-weight: 500;
  font-size: 14px;
  min-height: 30px;
  text-indent: 6px;
  font-family: "timelify";
  /* font-style: italic; */
  /* text-decoration: underline; */
  /* opacity: 0.75; */
  /*  */
  /* text-decoration: underline; */
}

.tagInput::-webkit-input-placeholder {
  color: #bbcada;
  font-size: 15px;
}

.tagInput:-ms-input-placeholder {
  color: #bbcada;
  font-size: 15px;
}

.tagInput::placeholder {
  color: #bbcada;
  font-size: 15px;
}

.addTagImage {
  min-width: 20px;
  margin-right: 17px;
  cursor: pointer;
}

.availableTagsUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.availableTagsModal {
  background-color: #fff;
  min-width: 320px;
  max-width: 320px;
  min-height: 400px;
  max-height: 400px;
  border-radius: 10px;
  align-items: center;
  color: #143051;
  text-align: center;
  /* -webkit-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1); */
  -webkit-animation-name: moveEditTeamUserBoxUp !important;
          animation-name: moveEditTeamUserBoxUp !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  cursor: default;
}

.addNoteInlineImg {
  max-width: 12px;
  min-width: 12px;
  margin-left: 5px;
}

.x19387459 {
  max-width: 9px;
  min-width: 9px;
  margin-left: 6px;
}
.availableTagTxt {
  font-size: 1em;
  font-weight: 500;
  line-height: 30px;
  max-width: 96%;
  min-width: 96%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 0 auto;
  cursor: pointer;
  border-bottom: 1px solid #cbdeeb;
  display: flex;
  /* justify-content: center; */
  text-align: left;
  color: #36495f;
  cursor: pointer;
}

.lastAvailTag {
  border-bottom: none;
}

.availableTagTxt:hover {
  background-color: #eaf3fd;
}

.editTagDivFlex {
  display: flex;
  justify-content: space-between;
  cursor: default;
}

.tagMgrDiv {
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
  min-height: 50px;
  align-items: center;
}

.addNewTagBtn {
  font-size: 0.84em;
  background-color: #0cf;
  color: white;
  max-width: 120px;
  min-height: 30px;
  line-height: 28px;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  /* margin-bottom: 20px; */
  /* margin-top: -1px; */
  font-weight: 500;
  border: 1px solid #8ea7c5;
  cursor: pointer;
}

.editTagSugBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 66px;
  padding-left: 9px;
  padding-right: 9px;
  min-height: 35px;
  max-height: 35px;
  background-color: #cbd7e6;
  border: 1px solid #bbcada;
  border-radius: 5px;
  cursor: pointer;
}

.editTagSugBtn:hover {
  background-color: #bbcada;
}

.addTagAddShiftImg {
  margin: 0 auto;
  margin-top: 12px;
  min-width: 25px;
}

.editWorkingPatternButton {
  margin-top: 10px;
  margin-bottom: 20px;
}

.workPatternTitle {
  /* margin-top: -40px; */
  /* background-color: orange; */
}

.editWorkingPatternRight {
  /* background-color: yellow; */
  z-index: 1;
  padding-left: 1px;
  margin-left: 1px;
  /* margin-bottom: 0; */
  /* min-height: 100px; */
  /* margin-top: 70px; */
  /* position: relative;
  z-index: 999999999999; */
  /* padding-bottom: 100px; */
}

/* .fixedPatternDivider {
  min-height: 15px;
  min-width: 100%;
  max-width: 10%;
  border-top: solid 2px #e6eaee;
  border-radius: 15px 15px 0px 0px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: -10px;
} */

.shiftTeamSubBubble {
  /* font-size: 0.9em; */
  /* font-weight: 500; */
  /* margin-right: 18px; */
  /* margin-bottom: 14px; */
  /* margin-top: 4px; */
  /* background-color: #d2e6fd; */
  background-color: #fff;
  border: 1px solid #e6eaee;
  color: #496375;
  /* margin: 0 auto; */
  padding-left: 8px;
  padding-right: 8px;
  min-height: 35px;
  max-height: 35px;

  /* line-height: 27px; */
  font-size: 12px;
  margin-top: 9px;
  margin-bottom: 8px;
  border-radius: 5px;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  font-weight: 500;
  display: flex;
  align-items: center;
  line-height:14px !important;
  /* padding-top: 2px; */
}

.adminEditHoursBtn {
  margin: 0 auto;
}

.shiftBubblesBar {
  display: flex;
  justify-content: flex-end;
  min-width: 100%;
  max-height: 38px;
  min-height: 38px;
  align-items: center;
  margin-bottom: 6px;
  margin-top: -6px;
  /* padding-top: 10px; */
}

.shiftBubblesBarManager {
  display: flex;
  justify-content: flex-end;
  min-width: 100%;
  max-height: 38px;
  min-height: 38px;
  align-items: center;
  margin-bottom: -10px;
  margin-top: 4px;
}

.shiftCostBubble {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  /* margin: 0 auto; */
  padding-left: 8px;
  padding-right: 8px;
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* line-height: 28px; */
  /* font-size: 1em; */
  margin-top: 9px;
  margin-bottom: 8px;
  border-radius: 5px;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  /* font-weight: 500; */
  /* padding-top: 1px; */
  margin-left: 10px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  line-height: 13px;
  text-align: right;
}

.bankDivLeftContainer {
  /* background-color: blue; */
  max-width: 80%;
  padding-left: 1%;
  /* padding-top: 66px; */
}

.bankUserDiv {
  min-height: 130px;
  max-height: 130px;
  /* margin-top: -80px; */
  display: flex;
  justify-content: space-around;
  padding: 0;
  border: solid 2px #cbdeeb;
  border-radius: 15px;
  max-width: 92%;
  margin: 0 auto;
  margin-top: 15px;
  padding-top: 7px;
  margin-bottom: 20px;
  /* padding-bottom: 8px; */
}

.bankActive {
  border: solid 1px #143051;
  background-color: #143051;
  color: white;
}

.bankTitle {
  font-size: 1em;
}

.bankExplainer {
  font-size: 0.9em;
  text-align: left;
  padding-left: 5%;
  color: #8ea7c5;
  font-weight: 500;
  margin-top: 8px;
  /* background-color: yellow; */
  max-width: 260px;
}

.accrueBankFaded {
  opacity: 0.5;
}

.bankAccruableHelpBtn {
  background-color: #143051;
  color: white;
  font-weight: 500;
  max-height: 25px;
  margin-top: 7px;
  line-height: 28px;
  border-radius: 18px;
  min-width: 25px;
  max-width: 25px;
  text-align: center;
  margin-left: -15px;
}

.evenThoughTxt {
  line-height: 24px;
  max-width: 70%;
  padding-bottom: 12px;
  padding-top: 6px;
}

.absenceValueContainerIncPartialBtn {
  display: flex;
  padding-left: 1px;
  /* background-color: blue; */
  /* max-width: 160px; */
  /* min-width: 180px; */
}

.partialAbsenceBtnContainer {
  display: flex;
  justify-content: space-around;
  background-color: #e89292;
  color: #fff;
  /* border: 1px solid #cc4d4d; */
  max-width: 155px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 8px;
  align-items: center;
  border-radius: 5px;
  max-height: 35px;
  line-height: 35px;
  margin-top: 3px;
}

.partialHover:hover {
  background-color: #d37a7a;
}

.partialAbsenceBtnContainerActive {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #c15a5a;
  color: #fff;
  border: 1px solid #8d0f0f;
  max-width: 144px;
  margin-right: 12px;
  padding-left: 9px;
  padding-right: 7px;
  border-radius: 5px;
  max-height: 35px;
  line-height: 35px;
  margin-top: 3px;
}

.partialFaded {
  opacity: 0.15;
}

/* .partialFaded:hover {
  opacity: 0.5;
} */

.partialAbsencePlusBtn {
  font-size: 1.8em;
  margin-top: -4px;
}

.partialAbsencePlusBtnActive {
  font-size: 1.8em;
  margin-top: -3px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  cursor: pointer;
}

.partialAbsenceBtnTxt {
  margin-right: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
}

.partialAbsenceRow {
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
  /* background-color: blue; */
  min-width: 80%;
  align-items: center;
  margin: 1px auto;
}

.partialTimeValue {
  font-weight: 500;
  color: #143051;
  font-size: 16px;
  margin: 0 auto;
  text-align: center;
  margin-top: -1px;
  /* margin-right: -50px; */
}

.partialTimeEditMode {
  background-color: #fff;
  width: 77px;
  max-width: 77px;
  line-height: 30px;
  padding-top: 2px;
  height: 35px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 16px;
  font-weight: 500;
  margin-top: 0px;
  cursor: text;
}
.partialTimeEditMode:hover {
  border-color: #bac5d3;
}

.accrueExplain {
  /* background-color: #9a74eb; */
  max-width: 44px;
  color: #496375;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 22px;
  font-size: 0.85em;
  border-radius: 5px;
  padding-left: 2px;
  padding-right: 2px;
  font-weight: 500;
  margin-left: 22px;
  margin-bottom: 18px;
  margin-top: 0px;
  border: 1px solid #abbed3;
  cursor: pointer;
}

.accrueExplain:hover {
  background-color: #abbed3;
  color: white;
  /* opacity: 0.8; */
  /* transform: scale(0.94); */
}

.editPartialTimePencil {
  max-width: 13px;
  opacity: 0.75;
  cursor: pointer;
  margin-bottom: 5px !important;
  /* padding-top: 1px; */
  /* margin-top: 1px; */
}

.savePartialTimeBtn {
  max-width: 25px;
  margin-top: 8px;
  border: 1px solid #143051;
  border-radius: 10px;
  background-color: #00ccff;
  cursor: pointer;
}

.absenceRowUnit1 {
  /* background-color: orange; */
  min-width: 130px;
  max-width: 130px;
  max-height: 40px;
}

.absenceRowUnit2 {
  /* background-color: pink; */
  min-width: 80px;
  max-width: 80px;
  margin: 3px auto;
  max-height: 40px;
}

.absenceRowUnit3 {
  /* background-color: yellow; */
  padding-bottom: 21px;
  min-width: 60px;
  max-width: 60px;
  margin: 0 auto;
  max-height: 40px;
  text-align: center;
}

.units2and3 {
  /* background-color: blue; */
  display: flex;
  align-items: flex-end;
  min-width: 150px;
  margin-right: 15px;
  cursor: pointer;
}

.savePartialBtn {
  margin-top: 0px !important;
}

.units2and3Hover:hover {
  background-color: #edf3f9;
  border-radius: 5px;
}

.partialTitle {
  color: #214978;
  padding-left: 8px;
  font-size: 12px;
  font-weight: 500;
}

.partialBottomBorderAdjust {
  border: none;
}

.absenceRowContainer {
  display: block;
  background: #f4f8fe;
  border-left: 1px solid #bbcada;
  margin-left: 20px;
  border-radius: 0 0 5px 5px;
  border-bottom: 1px solid #bbcada;
  border-right: 1px solid #bbcada;
  margin-right: 8px;
  margin-bottom: 10px;
}

.partialAbsenceDurationSplitter {
  min-height: 2px;
  background-color: #8ea7c5;
  min-width: 90%;
  max-width: 90%;
  margin: 4px auto;
}

.invalidPartialInput {
  border: 1px solid #cc4d4d;
}

.disablePartialSaveBtn {
  opacity: 0;
  pointer-events: none;
}

.partialAbsenceDurationBox {
  text-align: center;
  color: #143051;
  font-size: 16px;
  font-weight: 500;
}

.absentDurationRow {
  padding-bottom: 7px;
}

.absenceUnitNameTxt {
  font-weight: 500;
  font-size: 1.1em;
  color: #859dbb;
  margin-right: 18px;
  text-align: right;
  cursor: pointer;
}

.alignMiddlePage0 {
  /* padding-top: 70px; */
  /* min-height: 215px; */
  /* max-height: 215px; */
  /* border-bottom: 2px solid #e6eaee; */
}

.addShiftOnWhichUserDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 252px;
  min-width: 252px;
  line-height: 26px;
  height: 35px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  color: #496375;
  border: 2px solid #e6eaee;
  -webkit-appearance: none;
  background-position-x: 230px;
  display: flex;
  cursor: pointer;
  margin-right: 12px;
  margin-top: 8px;
}

.addShiftOnWhichUserDropdown:hover {
  border-color: #bac5d3;
}

.tagTxtOnShiftsArr {
  font-size: 0.85em;
  margin-top: 2px;
  /* color: #36495f; */
}

.preOpenShift {
  /* background-color: #e0ebf8; */
  min-height: 40px;
  min-width: 85%;
  max-width: 85%;
  -webkit-animation-name: moveSubBoxIn !important;
          animation-name: moveSubBoxIn !important;
  -webkit-animation-duration: 0.15s !important;
          animation-duration: 0.15s !important;
  margin-left: 15px;
  border-radius: 0px 0px 15px 15px;
  background: rgb(88, 112, 150);
  background: linear-gradient(
    0deg,
    rgba(88, 112, 150, 1) 0%,
    rgba(88, 112, 150, 1) 90%,
    rgba(69, 89, 120, 1) 100%
  );
  margin-bottom: 2px;
  display: flex;
  justify-content: space-between;
}

.selectedShiftUnitBorder {
  border: 1px #143051 solid;
}

.openShiftBtn {
  background-color: #0077ff;
  border: 1px solid #143051;
  color: white;
  border-radius: 30px;
  min-height: 33px;
  max-height: 33px;
  line-height: 28px;
  font-size: 0.9em;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 12px;
  cursor: pointer;
  font-weight: 500;
  box-shadow: inset 0px 2px 0px #1964ba;
  margin-top: 3px;
}

.shiftSubBarContentLeft {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-weight: 500;
  margin-left: 12px;
}

.adminUserShiftTimeAndTagsContainer {
  /* background-color: blue; */
  display: flex;
  align-items: center;
}

.adminUserShiftTagContainer {
  /* background-color: yellow; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 7px;
}

.adminUserShiftTag {
  color: #fff;
  font-size: 0.8em;
  text-align: left;
  margin-right: 4px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 15px;
  font-weight: 500;
  background-color: #143051;
  line-height: 17px;
  max-height: 18px;
  /* margin-top: 3px; */
}

.surplusTagsOnAdminUserShifts {
  color: #fff;
  font-size: 0.65em;
  text-align: left;
  margin-left: 0px;
  padding-left: 3px;
  padding-right: 5px;
  border-radius: 15px;
  font-weight: 500;
  background-color: #214978;
  line-height: 17px;
  max-height: 18px;
  /* margin-top: 3px; */
}

.editWPattContainer {
  /* background-color: yellow; */
  display: flex;
  /* min-width: 100%; */
  justify-content: flex-end;
}

.editWPattButtonEdit {
  background-color: #214978;
  color: white;
  max-width: 130px;
  min-width: 130px;
  padding-left: 12px;
  padding-right: 12px;
  border: 1px #143051 solid;
  font-size: 0.86em;
  font-weight: 500;
  border-radius: 25px;
  min-height: 30px;
  line-height: 30px;
  margin-bottom: 24px;
  margin-top: 8px;
  text-align: center;
}

.editWPattButtonSave {
  background-color: #0077ff;
  color: white;
  max-width: 130px;
  min-width: 130px;
  padding-left: 12px;
  padding-right: 12px;
  border: 1px #143051 solid;
  font-size: 0.86em;
  font-weight: 500;
  border-radius: 25px;
  min-height: 30px;
  line-height: 30px;
  margin-bottom: 24px;
  margin-top: 8px;
  text-align: center;
}

.editWPattButtonHighlight {
  background-color: #9a74eb;
  color: white;
  max-width: 130px;
  min-width: 130px;
  padding-left: 12px;
  padding-right: 12px;
  border: 1px #143051 solid;
  font-size: 0.86em;
  font-weight: 500;
  border-radius: 25px;
  min-height: 30px;
  line-height: 30px;
  margin-bottom: 24px;
  margin-top: 8px;
}

.nonEditWPatt {
  pointer-events: none;
  opacity: 0.6;
}

.workPattChangesModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.workPattChangesModalBox {
  background-color: white;
  min-width: 75vw;
  max-width: 75vw;
  /* min-height: 90vh;
max-height: 90vh; */
  /* height: 80vh; */
  border-radius: 15px;
  /* margin-top: -40px; */
  /* display: flex; */
  /* justify-content: center; */
  align-items: center;
  color: #143051;
  margin-top: -100px;
  text-align: center;
  /* position: fixed;
bottom: 0;
text-align: center; */
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -webkit-animation-name: moveEditTeamUserBoxUp !important;
          animation-name: moveEditTeamUserBoxUp !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  font-size: 0.9em;
  font-weight: 400;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  /* padding-bottom: 20px */
}

.updateUserSchedGenBtnContainer {
  /* background-color: yellow; */
}

.updateUserSchedGenBtnContainer {
  /* background-color: blue; */
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.updateUserSchedGenBtnNo {
  font-size: 0.8em;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 2px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 17px;
  padding-right: 17px;
  max-height: 30px;
  line-height: 26px;
  border-radius: 20px;
  min-height: 30px;
}

.updateUserSchedGenBtnYes {
  font-size: 0.8em;
  color: #fff;
  background-color: #0077ff;
  border: solid #214978 2px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 17px;
  padding-right: 17px;
  max-height: 30px;
  line-height: 26px;
  border-radius: 20px;
  min-height: 30px;
  margin-left: 20px;
}

.userAllowancesContainer {
  min-width: 100%;
}

.allowancesSelectContainer {
  /* background-color: #e0ebf8; */
  display: flex;
  justify-content: space-between;
  min-height: 44px;
  align-items: center;
  padding-left: 7px;
  padding-right: 7px;
  /* border-radius: 5px; */
  /* border-bottom: 1px solid #bbcada */
}

.allowanceTypeDropdown {
  color: #fff;
  border-radius: 15px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 188px;
  min-width: 188px;
  line-height: 26px;
  height: 30px;
  background: url(/static/media/downArrowWhite2.887d57ce.svg) no-repeat right
    #214978;
  border: 1px solid #143051;
  -webkit-appearance: none;
  background-position-x: 164px;
  display: flex;
  cursor: pointer;
}

.availableAllowancesYearsDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 80px;
  min-width: 80px;
  line-height: 26px;
  height: 35px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
    #214978;
  border: 1px solid #143051;
  -webkit-appearance: none;
  background-position-x: 56px;
  display: flex;
  cursor: pointer;
  color: #214978;
  background-color: white;
  border: 2px solid #c6d5e4;
}

.availableAllowancesYearsDropdown:hover {
  border-color: #bac5d3;
}
.userAnnLeaveCalcToggleTitle {
  font-weight: 500;
  text-align: left;
  margin-top: 20px;
  color: #143051;
  font-size: 12px;
  line-height: 22px;
  /* margin-left: 8px;
  padding-right: 8px; */
  margin-bottom: 12px;
  padding-left: 15px;
  padding-right: 12px;
}

.selectedYearAllowanceSpan {
  color: #143051;
  font-weight: 500;
}

.annLeaveToggleContainer {
  display: flex;
  justify-content: space-between;
  min-height: 40px;
  align-items: flex-start;
  border-top: 1px solid #ddebf4;

  border-bottom: 1px solid #ddebf4;
  padding-bottom: 14px;
  margin-top: 9px;
  padding-top:15px;
}

.finalToggleContainerBorderBottom {
  border-bottom: 1px solid #ddebf4;
}

.annLeaveToggleContainer:first-of-type {
  border: none;
  margin-top: 10px;
}

.allHoursBoxPaddingTop {
  padding-top: 20px;
  padding-bottom: 20px;
}

.annLeaveToggleLeftTxtBox {
  max-width: 80%;
  text-align: left;
}

.annLeaveToggleTxt1 {
  font-weight: 500;
  font-size: 12px;
  margin-left: 14px;
  color: #143051;
  margin-top:8px;
}

.annLeaveToggleTxt2 {
  font-size: 12px;
  margin-top: 8px;
  margin-left: 18px;
  color: #bbcada;
  line-height: 16px;
  font-weight: 500;
}

.allowanceToggleKnob {
  min-height: 100%;
  /* background-color: blue; */
  margin-top: -62px;
}

.annLeaveOrCustomSelector {
  display: flex;
  min-width: 230px;
  align-items: center;
  justify-content: space-between;
  color: #8ea7c5;
  padding-right: 8px;
}

.annLeaveOrCustomTxt {
  font-size: 0.85em;
  font-weight: 500;
  cursor: pointer;
  min-width: 98px;
  max-width: 98px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.annLeaveOrCustomTxt:hover {
  /* background-color: blue; */
}

.annLeaveOrCustomSelected {
  font-weight: 500;
  color: #fff;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 7px;
  cursor: default;
  background-color: #214978;
  border: 1px solid #143051;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 7px;
  border-radius: 5px;
  min-width: 112px;
  min-height: 35px;
}

.annLeaveOrCustomUnderLineAnnLeave {
  min-width: 60px;
  min-height: 0px;
  background-color: #143051;
  margin-top: 1px;
}

.allowancesTopDiv {
  min-width: 100%;
  min-height: 2px;
  background-color: #ddebf4;
  margin-bottom: 11px;
}

.userAnnLeaveSettingsContainer {
  background-color: #fff;
  border-top: solid 2px #eaf0f5;
  border-radius: 5px 5px 0px 0px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 8px;
  /* background-color: blue; */
  min-height: calc(100% - 51px);
  max-height: calc(100% - 51px);
  /* max-height: 100%;
  min-height: 100%; */
  padding-bottom: 100px;

  overflow-y: scroll;
}

.jamLoadAllowance {
  opacity: 0.4;
  pointer-events: none;
}

.overrideContractedHoursAllowanceZeroVoiderContainer {
  background-color: #eaf3fd;
  max-width: 90%;
  margin: 0 auto;
  padding: 10px;
  border: 1px solid #ddebf4;
  border-radius: 15px;
  /* display: flex;
  justify-content: space-between; */
}

.overrideContractedAllowCalcTxt {
  /* background-color: rgb(160, 160, 43); */
  text-align: left;
  font-size: 0.85em;
  /* max-width: 80%; */
}

.overrideContractedAllowBtnContainer {
  /* background-color: yellow; */
  /* min-width: 100%; */
  display: flex;
  justify-content: space-between;
  /* margin: 0 auto; */
  /* max-width: 190px; */
  /* padding-right: 100px; */
  max-width: 170px;
  margin-top: 15px;
}

.overrideContractedAllowCalcBtn {
  background-color: #0077ff;
  /* min-height: 40px; */
  color: white;
  padding-top: 1px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  border: 1px solid #143051;
  font-weight: 500;
  text-align: center;
}

.overrideContractedAllowCalcBtnCancel {
  /* background-color: #0077ff; */
  color: #7390b1;
  padding-top: 4px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px;
  /* border: 1px solid #143051; */
  font-weight: 500;
  font-size: 0.9em;
}

.selectedMinsAllowanceDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 188px;
  min-width: 188px;
  line-height: 26px;
  height: 35px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
    #214978;
  border: 1px solid #143051;
  -webkit-appearance: none;
  background-position-x: 166px;
  display: flex;
  cursor: pointer;
  color: #496375;
  background-color: white;
  border: 1px solid #dde9f9;
}

.selectedMinsAllowanceDropdown:hover {
  border-color: #bac5d3;
}

.manuallySeAllowContainer {
  display: flex;
  justify-content: flex-start;
  margin-top: 22px;
  padding-left: 6px;

}

.manSetAllowKnob {
  margin-top: 10px;
}

.manuallySetAllowTxt {
  margin-top: 20px;
}

.annLeaveDropdownAndExplainInfoContainer {
  /* background-color: lime; */
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.explainThatAllowBasedOnCurrent {
  font-size: 12px;
  text-align: left;
  margin-top: 8px;
  padding: 4px;
  color: #516b8b;
  font-weight: 400;
  line-height: 16px;
}

.annLeavePercentageDropdownOnUser {
  color: #496375;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left;
  max-width: 68px;
  min-width: 68px;
  line-height: 31px;
  height: 35px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  border: 1px solid #dde9f9;
  -webkit-appearance: none;
  background-position-x: 48px;
  cursor: pointer;
  /* margin-top:6px; */
}

.annLeavePercentageDropdownOnUser:hover {
  border-color: #bac5d3;
}

.userAnnLeaveWeeksPerYearContainer {
  min-width: 120px;
  display: flex;
  justify-content: flex-end;
  padding-right: 8px;
  align-items: flex-end;
  flex-direction: column;
}

.wksPerYrSpan {
  font-size: 12px;
  margin-left: 0px;
  margin-right: 12px;
  font-weight: 400;
  color: #516b8b;
  margin-top: 6px;
}

.loadingBallsUserAllow {
  margin-top: 36px;
}

.fadeUserAllowsOnYearChange {
  opacity: 0.4;
  pointer-events: none;
}

.annLeaveMetaContainer {
  background-color: #f4f8fe;
  max-width: 92%;
  /* margin: 14px auto; */
  /* display: flex; */
  border-radius: 5px;
  border: 1px #e6e6e6 solid;
  margin-top: 42px;
  min-height: 40px;
  padding: 2px;
  cursor: pointer;
  margin-left: 10px;
}

.annLeaveMetaContainer:hover {
  background-color: #f0f4fa;
}

.metaExtended {
  border: 2px solid #e6e6e6;
  padding: 1px;
  border-radius: 5px;
}

.annLeaveAllowanceRemainingContainer {
  background-color: #fff;
  /* max-width: 96%;
  max-width: 260px; */
  /* margin: 0 auto; */
  /* font-size: 0.9em; */
  /* display: flex; */
  border-radius: 5px;
  /* border: 1px #cce3ff solid; */
  margin-top: 8px;
  min-height: 40px;
}
.hourlyRateTimelineUnit {
  min-width: 96%;
  max-width: 96%;
  min-height: 80px;
  background-color: white;
  border: 1px solid #c4d5ea;
  border-radius: 5px;
  margin: 6px auto;
  display: flex;
  justify-content: space-around;
}

.selectedHourlyRateTimelineUnit {
  min-width: 96%;
  max-width: 96%;
  min-height: 140px;
  background-color: white;
  border: 1px solid #c4d5ea;
  border-radius: 5px;
  margin: 6px auto;
  display: flex;
  justify-content: space-around;
}

.hourlyRateTimeinlineUnitContent {
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 13px;
  padding-right: 35px;
}

.hourlyRateTimelineLeftSide {
  /* background-color: yellow; */
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 90%;
}

.hourlyRateTimelineRightSide {
  min-width: 20%;
  /* background-color: yellow; */
  padding-left: 10px;
}

.hourlyRateLeftDivider {
  min-width: 100%;
  min-height: 1px;
  background-color: #c0d6f0;
}

.hourlyRateLeftSideTxt {
  font-size: 14px;
  font-weight: 500;
  color: #214978;
  /* padding-right: 60px; */
  padding-left: 2px;
}

.spanHourlyRateTimelineToSpan {
  color: #8ea7c5;
  margin-left: 4px;
  margin-right: 4px;
}

.editHourlyRateTimelineImg {
  min-width: 25px;
  max-width: 25px;
  margin-left: 8px;
  margin-top: 1px;
  padding-top: 1px;
  cursor: pointer;

  border: 2px solid #e3e8ee;
  padding: 5px;
  border-radius: 5px;
}

.editHourlyRateTimelineImg:hover {
  border: 2px solid #b2bbc7;
}

.hourlyRateTimelineLeftSideSelected {
  /* background-color: yellow; */
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 70%;
  padding-bottom: 10px;
}

.hourlyRateInput {
  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px;
  margin-right: 4px;
  color: #6a8bb1;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  max-width: 60px;
  min-height: 35px;
  padding-left: 5px;
  font-weight: 500;
  margin-top: 8px;
  padding-left: 10px;
  font-size: 14px;
}

.hourlyRateInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 3px;
  background-color: #fdfeff;
  padding-left: 8px;
}

.hourlyRateSelectedRow {
  /* background-color: blue; */
  min-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  /* flex-direction: column; */
}

.hourlyRateSelectedRowRateContainer {
  /* background-color: blue; */
  min-height: 40px;
  display: flex;
  /* flex-direction: column; */
  align-items: center;

  /* flex-direction: column; */
}

.hourlyRateFromToTxt {
  color: #143051;
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  margin-left: 8px;
  margin-top: 10px;
  margin-bottom: 4px;
}

.spanHourlyRateTimelineToSpanEdit {
  color: #8ea7c5;
  font-size: 12px;
  font-weight: 400;
  margin-left: 6px;
  /* margin-top: 10px; */
}

/* 
color: #fff;
border-radius: 10px;
font-size: 0.9em;
font-weight: 500;
padding-left: 12px;
text-align: left !important;
max-width: 80px;
min-width: 80px;
line-height: 26px;
height: 30px;
background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
  #214978;
border: 1px solid #143051;
-webkit-appearance: none;
background-position-x: 56px;
display: flex;
cursor: pointer;

color: #496375;
background-color: white;
border: 1px solid #dde9f9; */

.editPeriodDateInput {
  letter-spacing: 1px;
  max-width: 162px;
  min-width: 162px;
  background: url(/static/media/editUserCal.27a62c52.svg) no-repeat right #f4f8fe;
  -webkit-appearance: none;
  background-position-x: 130px;
  margin-bottom: 8px;
  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px;
  margin-right: 4px;
  color: #6a8bb1;
  min-height: 35px;
  padding-left: 10px;
  font-weight: 500;
  font-size: 14px;
  cursor: text;
  /* margin-left: 6%; */
}

.editPeriodDateInput:focus {
  border: 2px solid #dde9f9;
  padding-left: 8px;
  background-color: #fdfeff;
  background-position-x: 128px;
  /* padding-bottom: 1px; */
}

.selectedHourlyRateContainer {
  /* background-color: yellow; */
  min-height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* min-width: 100%; */
}

.binCircleTimelineImg {
  min-width: 29px;
  max-width: 29px;

  margin-left: 14px;
  margin-top: 20px;
  cursor: pointer;

  border: 2px solid #e3e8ee;
  padding: 5px;
  border-radius: 5px;
}

.binCircleTimelineImg:hover {
  border: 2px solid #b2bbc7;
}

.closeAllOfUserTeamsModalBtn {
  font-size: 0.9em;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 2px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  line-height: 26px;
  border-radius: 10px;
  min-height: 30px;
  max-width: 60px;
  margin: 8px auto;
}

.allowanceInfoIfInitialYearContainer {
  max-width: 96%;
  min-width: 96%;
  margin: 0 auto;
  text-align: left;
  font-size: 0.9em;
  color: #143051;
  /* padding-left: 8px; */
  /* padding-right: 8px; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}


.allowanceRow {
  display: flex;
  /* background-color: blue; */
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ddebf4;
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 100%;
  min-height: 40px;
  max-height: 40px;
  padding-left:10px;
  padding-right:10px;
}

.allowanceRow:last-child {
  /* border-top: none; */
  margin-bottom: 20px;
}

.allowanceRowAccrue {
  display: flex;
  /* background-color: blue; */
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #8ea7c5;
  padding-top: 10px;
  padding-bottom: 10px;
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  padding-right:10px;
}


.allowanceRowAccrue:last-child {
  /* border-top: none; */
  margin-bottom: 20px;
}

.allowanceInfoRowDivider {
  min-height: 1px;
  min-width: 100%;
  background-color: #ddebf4;
  display: none;
}

.extraThickDivider {
  min-height: 1px;
  background-color: #ddebf4;
  margin-top: 2px;
  margin-bottom: 2px;
}

.allowanceInfoKey {
  font-size: 12px;
  color: #143051;
  max-width: 65%;
  font-weight: 500;
}

.allowanceInfoValue {
  /* color: #58bdee; */
  color: #bbcada;
  font-weight: 500;
  text-align: right;
  font-size: 14px;
  min-width: 160px !important;
  /* background: blue; */
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.allowInfoBrackets {
  color: #abbed3;
}

.userAllowCalcTitle {
  font-weight: 400;
  text-align: left;
  padding-left: 15px;
  margin-top: 22px;
  margin-bottom: 12px;
  color: #214978;
  font-size: 12px;
  border-top: 1px solid #ddebf4;
  padding-top: 20px;
}

.xc8383 {
  border-bottom: 1px solid #ddebf4;

}

.showMetaToggle {
  display: flex;
  /* background-color: yellow; */
  justify-content: flex-start;
  max-width: 200px;
  margin-top: 7px;
  margin-left: 12px;
  margin-bottom: 7px;
  cursor: pointer;
}

.showAccrualDataArrowImg {
  min-width: 10px;
}

.dataArrowShowMeta {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  margin-top: 2px;
}

.dataArrowShowMetaActive {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.accruInfoTxt {
  font-weight: 500;
  color: #143051;
  text-align: left;
  margin-left: 10px;
  font-size: 12px;
  margin-top: 3px;
}

.allowanceAdditionsContainer {
  /* background-color: #f4f8fe; */
  max-width: 200px;
  /* margin: 14px auto; */
  /* display: flex; */
  border-radius: 5px;
  border: 1px #e6eaee solid;
  margin-top: 8px;
  min-height: 40px;
  /* max-height: 35px; */
  padding: 2px;
  cursor: pointer;
  margin-left: 10px;
}



.additionsExtended {
  border: 1px solid #e6eaee;
  background-color: #f0f4fa;
  padding: 2px;
    /* padding: 1px; */
  max-width: 93%;

  border-radius: 5px;
  cursor: default;
margin-bottom:15px;
}

/* .additionsExtended:hover {
  border: 2px solid #e6eaee;
  background-color: #f0f4fa;
} */

.additionalMinsTxt {
  background-color: white;
  border: 1px solid #dde9f9;
  padding: 8px;
  font-size: 12px;
  /* padding-left: 6px; */
  /* padding-right: 6px; */
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  /* margin: 15px auto; */
  border-radius: 5px;
  margin-left: 15px;
  margin-top: 10px;
  font-weight: 500;
  text-align: left;
  color: #516b8b;
}

.addedMinsSpan {
  font-weight: 500;
  color:#00aaff
}

.additionsBtnsContainer {
  /* background-color: yellow; */
  min-width: 190px;
  margin-top: 10px;
}

.additionsBtnsContainerRow {
  display: flex;
  /* background-color: pink; */
  max-width: 200px;
  justify-content: space-between;
  margin: 0 auto;
}
.additionsBtnsContainerRow:last-child {
  margin-top: 2px;
}

.plus1m,
.minus1m {
  background-color: white;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  min-width: 46px;
  max-width: 46px;
  min-height: 46px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 14px;
  padding-top: 7px;
  padding-bottom: 7px;
  color: #36495f;
  cursor: pointer;
}

.plus1m:hover {
  border-color: #143051;
}
.minus1m {
  background-color: #eaf3fd;
  cursor: pointer;
}

.minus1m:hover {
  border-color: #143051;
}

.plus1m:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
  border-color: #00ccff;
}

.minus1m:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
  border-color: #00ccff;
}

.saveCanxBtnContainerAllows {
  /* background-color: green; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  margin-left: 12px;
  padding-left: 8px;
  border-left: 1px solid #c0d6f0;
}

.addOrMinusNumberSpan {
  font-size: 1.1em;
  letter-spacing: 2px;
}

.additionsParent {
  display: flex;
  justify-content: flex-start;
  margin-right: 10px;
  margin-bottom: 17px;
  margin-top: 13px;
  border-left: 2px solid #bbcada;
  padding-left: 15px;
}

.submitAdditionsBtn {
  background-color: #0bf;
  border: 1px solid #0af;
  border-radius: 5px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  padding-left: 8px;
  padding-right: 8px;
  min-width: 55px;
  color: #fff;
  cursor: pointer;
}

.submitAdditionsBtn:hover {
  background-color: #0af;
}

.cancelAdditionsBtn {
  background-color: white;
  border: 1px solid #dde9f9;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30px;
  font-weight: 500;
  font-size: 12px;
  padding-left: 8px;
  padding-right: 8px;
  min-width: 55px;
  margin-top: 5px;
  color: #3b597c;
  cursor: pointer;
}

.cancelAdditionsBtn:hover {
  /* background-color: #f4f8fe; */
}

.fadedSubmitAdditionsBtn {
  opacity: 0.4;
  pointer-events: none;
  /* color: #143051; */
  cursor: default;
}

.manipulatedAddedMinsSpan {
  color: #0077ff;
  font-weight: 500;
}

.thirtySpan {
  letter-spacing: 0px;
  font-size: 1.1em;
}

.thirtyDiv {
  display: flex;
}

.hoursToDaysConversion {
  /* background-color: #e3e8ee;  */
  border-right: 1px solid #8ea7c5;
  color: #496375;
  min-height: 30px;
  max-height: 30px;
  /* line-height: 26px; */
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  padding-left: 0px;
  padding-right: 11px;
  font-weight: 500;
  font-size: 0.9em;
  /* border-radius: 5px; */
  margin-top: 15px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 0 auto; */
  /* margin-top: -20px; */
}

.manipulatedDaysConversion {
  /* background-color: #6d1dd5 !important; */
  /* border: 1px solid #430692; */
  color: #6d1dd5;
  /* line-height: 26px; */
}

.equivalentHrsToDaysTxt {
  font-size: 10px;
  max-width: 160px;
  /* margin: 0 auto; */
  text-align: left;
  margin-left: 12px;
  margin-top: 16px;
  font-weight: 400;
  color: #143051;
}

.basedOnHrsToDaysContainer {
  display: flex;
  justify-content: flex-start;
  /* background-color: yellow; */
  max-width: 290px;
  /* min-height: 90px; */
  margin-top: 0px;
  margin-bottom: 24px;
  align-items: center;
  /* display: none; */
}

.addOrDeductTitle {
  font-weight: 500;
  color: #496375;
  text-align: left;
  padding-left: 15px;
  margin-top: 12px;
}

.joinDateMidTermDiv {
  color: #516b8b;
  font-size: 12px;
  text-align: left;
  display: flex;
  margin-top: 20px;
  padding-right: 70px;
  line-height:16px;
}

.allowjoinDateSpan {
  font-weight: 500;
}

.exclaimJoinDateImg {
  min-width: 25px;
  margin-right: 8px;
  margin-top: -52px;
  max-width: 25px;
  margin-left: 12px;
}

.joinDateMidTermInfoTxt {
  
}

.allowanceLoadingImg {
  max-height: 16px;
  margin-top: 1px;
  margin-bottom: 1px;
}

.daysConvertSpan {
  color: #143051;
  padding-left: 12px;
  /* background-color: yellow; */
  border-left: 1px solid #ddebf4;
  margin-left: 12px;
}

.snapOptionsContainer {
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
}
.snapBtnsParent {
  display: flex;
  margin-top: 6px;
  padding-top: 6px;
  border-top: 1px solid #ddebf4;
  justify-content: flex-end;
  padding-bottom: 3px;
}

.snapDownBtn {
  background-color: #0af;
  border: 1px solid #0e94d6;
  color: #fff;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 5px;
  min-width: 96px;
  justify-content: space-between;
  cursor: pointer;
}

.snapUpBtn {
  background-color: #0af;
  border: 1px solid #0e94d6;
  color: #fff;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 5px;
  margin-left: 6px;
  min-width: 84px;
  justify-content: space-between;
  cursor: pointer;
}
.snapDownBtn:hover {
  background-color: #009cea;
}

.snapUpBtn:hover {
  background-color: #009cea;
}

.snapDownImg {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  max-width: 12px;
  /* margin-left: 1px; */
  /* margin-right: 2px; */
}

.snapUpImg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  max-width: 12px;
}

.totalAllowSpanHighlighted {
  font-weight: 500;
  /* background-color: #58bdee; */
  color: #fff;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  opacity: 1;
  padding-right: 0px;
  padding-left: 12px;
  text-align: center;
  /* border-radius: 4px; */
  /* line-height: 35px; */
  padding-top: 6px;
  padding-bottom: 6px;
  margin-left: 5px;
  /* background-color: #0077ff; */
  color: #0077ff;
  /* border: 1px #0af solid; */
  margin-left: 12px;    display: flex;
    align-items: center;

}

.highlightedAllowanceRow {
  /* background-color: #fff;
  border: 1px #d2e6fd solid;
  border-top: none;
  margin-top: -2px;
  padding-left: 4px;
  border-radius: 0px 0px 10px 10px;

  padding-right: 4px; */
}

.mpwTimelineContainer {
  background-color: #f4f9ff;
  /* min-width: 100%; */
  max-width: 100%;
  margin: 0px auto;
  border-top: 2px solid #abbed3;
  border-radius: 12px;
  padding-bottom: 20px;
  border-bottom: 2px solid #abbed3;
  font-size: 1.25em;
  max-width: 96%;
  /* padding-bottom: 12px; */
}

.loadingMpwTimelineHorizBallsImg {
  margin-top: 22px;
  margin-bottom: 18px;
}

.mpwTimelineModalBody {
  background-color: white;
  border-radius: 10px;
  min-width: 340px;
  max-width: 340px;
  cursor: default;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.hourlyRateModalTitle {
  font-size: 18px;
  font-weight: 500;
  margin-top: -4px;
}
.mpwTimelineModalBodyMob {
  min-width: 100vw;
  max-width: 100vw;

  background-color: white;
  border-radius: 15px 15px 0 0;
  position: fixed;
  bottom: 0;
  cursor: default;
}
.mpwTimelineRow {
  /* background-color: yellow; */
  display: flex;
  justify-content: flex-end;
  padding-right: 5%;
  padding-left: 5%;
  margin-top: 18px;
  flex-direction: row-reverse;
}

.viewMpwTimelineBtn {
  background-color: #516b8b;
  /* background-color: #516b8b; */
  border: 1px #592eb6 solid;

  color: white;
  max-width: 160px;
  min-width: 140px;
  /* background-color: #0077ff; */
  display: flex;

  /* min-width: 135px; */
  height: 30px;
  line-height: 29px;
  /* margin-left: 3%; */
  justify-content: space-between;
  border-radius: 10px;
  /* padding-bottom: 2px; */
  border: 1px #143051 solid;
  padding-left: 9px;
  padding-right: 9px;
  /* opacity: 0.8; */
  background-color: white;
  color: #496375;
  border-color: #dde9f9;
}

.activeMpwTimelineBg {
  background-color: #214978;
  opacity: 1;
  border-radius: 10px 10px 0 0;
  border-bottom: none;
  font-size: 1.05em;
  background-color: white;
  color: #496375;
  border-color: #dde9f9;
}

.mpwTimelineBtnTxt {
  font-weight: 500;
  color: #496375;
  font-size: 0.9em;
  margin-top: -1px;
}

.schedHistoryImg {
  max-width: 14px;
  margin-left: 8px;
}

.timelineTopContainer {
  /* display: flex; */
}

.timelineTitle {
  font-weight: 500;
  font-size: 0.7em;
  text-align: left;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;

  border-radius: 5px;
  /* margin-left: 5%; */
  margin-top: 12px;
  margin-bottom: 12px;
  /* background-color: #214978; */
  /* border: 1px solid #143051; */
  /* color: white; */
  /* padding: 8px; */
  font-size: 1em;
  max-width: 210px;
  margin-left: 6%;
  color: #496375;
  /* padding-left: 5%; */
}

.currMpwInfoTxt {
  font-weight: 400;
  font-size: 12px;
  text-align: left;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;

  border-radius: 5px;
  margin-left: 5%;
  margin-top: 15px;
  margin-bottom: 10px;
  /* background-color: #214978; */
  color: #516b8b;
  /* padding: 12px; */
  padding-left: 4px;
  padding-right: 18px;
  line-height: 16px;
}

.showCreatedDs {
  max-width: 96%;
  margin: 0 auto;
  border-radius: 5px;
  color: #516b8b;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  padding: 6px;
  padding-left: 18px;
  padding-right: 60px;
  margin-bottom: 7px;
  line-height: 16px;
}

.opac4SnapBtns {
  opacity: 0.4;
  pointer-events: none;
}

.resetWarningImg {
  max-width: 20px;
  margin-right: 7px;
}

.showResetInstruction {
  text-align: left;
  font-weight: 500;
  max-width: 177px;
  border-radius: 5px;
  font-size: 12px;
  color: #496375;
  padding: 7px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  border: 1px solid #bbcada;
  background-color: #e6eaee;
  text-align: right;
  margin-top: 5px;
  padding-left: 6px;
}

.firstYouMustInstructionTxt {
  /* margin-left: 8px; */
}

.resetInstructionDiv {
  display: flex;
  justify-content: space-between;
}
.resetInstructionBtnHighlight {
  background-color: #0077ff;
  color: white;
  border-color: #143051;
}

.selectedMpwTimelineObjBorder {
  /* border: 1px solid #143051; */
}

.mpwMinsPerWeekTxt {
  text-align: left;
  margin-left: 6px;
  font-size: 12px;
  line-height: 13px;
  margin-top: 6px;
  font-weight: 500;
  /* color: #214978; */
}

.minsPerWeekMpwSpan {
  font-size: 12px;
  /* margin-left: 6px; */
  text-align: left;
  padding-left: 0px;
  /* padding-right: 20px; */
  /* background-color: yellow; */
  max-width: 100px;
  margin-top: -3px;
  max-height: 10px;
  color: #516b8b;
}

.mpwInputTimelineObj {
  font-size: 0.825em;
}

.flashReset {
  background-color: #00ccff;
}

.loadingAdditionsDiv {
  min-width: 100%;
  padding-left: 35px;
  display: flex;
  justify-content: space-between;
  min-height: 32px !important;
  margin-top: 14px;
  /* margin-bottom:0px !important; */
}

.endDateAfterStartMpwUnit {
  background-color: #cc4d4d;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-size: 12px;
  font-weight: 500;
  color: white;
  margin-left: 30px;
  margin-top: -6px;
  border-radius: 0 0 5px 5px;
  text-align: left;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 1px;
  padding-bottom: 3px;
  border-top: 1px solid #ba0000;
}

.mpwUnit {
  margin-top: 0px;
}

.mpwUnit:first-child {
  margin-top: 0px;
}

.overlapsMpwUnitTxt {
  background-color: #cc4d4d;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-size: 12px;
  font-weight: 500;
  color: white;
  margin-left: 30px;
  margin-bottom: -6px;
  border-radius: 5px 5px 0px 0px;
  text-align: left;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 3px;
  padding-bottom: 1px;
  border-bottom: 1px solid #ba0000;
  /* margin-top: 4px; */
}

.redBorderMpwUnit {
  border-color: #ba0000;
  border: 1px solid #ba0000;
  /* margin-top: 13px; */
}

.startAfterEndMpwUnitSpanStart {
  background-color: #cc4d4d;
  color: white;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 5px;
}

.mpwConflictsBtn {
  background-color: #fff;
  color: #ba0000;
  border-radius: 10px;
  /* border: 1px solid #ba0000; */
  font-weight: 500;
  font-size: 0.9em;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  margin-left: 8px;
  opacity: 0.8;
  /* max-height: 30px; */
}

.noBottomBorderRadiusConflicts {
  border-radius: 10px 10px 0 0;
  border-bottom: none;
}

.leftArrowWhiteConflictsImg {
  max-width: 9px;
  margin-right: 6px;
  margin-top: 0px;
}

.opac4LoadingSave {
  opacity: 0.4;
  pointer-events: none;
}

.fromOnwardsMpw {
  font-size: 12px;
  padding: 20px;
  text-align: left;
  margin-top: -7px;
  color: #516b8b;
  font-weight: 400;
  line-height: 16px;
}

.hourlyRateConflictSpan {
  background-color: #cc4d4d;
  border: 1px solid #ba0000;
  border-radius: 7px;
  padding-left: 3px;
  padding-right: 4px;
  margin-left: 6px;
  margin-right: -9px;
  margin-top: 0px;
  color: white;
  font-size: 0.9em;
}

.addOrRemoveMpwPeriodContainer {
  display: flex;
  justify-content: center;
  min-width: 100%;
  /* padding-right: 5%;
  padding-left: 5%; */
  padding-bottom: 4px;
  padding-top: 10px;
  /* background-color: black; */
}

.profPicEditDiv {
  display: flex;
  /* background-color: blue; */
  min-width: 80px;
  justify-content: space-between;
  padding-right: 10px;
  /* max-width: 40%; */
}

.editUserProPicImg {
  max-width: 35px;
  border-radius: 100%;
  /* margin-top: -2px; */
}

.noMaxHeightEditUserRow {
  max-height: 40px;
  min-height: 40px;
  align-items: flex-end;
  border-bottom: none;
  /* padding-bottom: 18px; */
  /* background-color: blue; */
}

.editProfPicFormContainer {
  /* max-width: 60%; */
  text-align: right;
}

.editUserProPicDiv {
  /* display: flex; */
  background-color: #eaf3fd;
  border-radius: 10px;
  padding: 10px;
  min-width: 90%;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
  min-height: 50px;
  margin: 12px auto;
  /* margin-bottom: -8px; */
  /* align-items: flex-end; */
}

.submitProPicBtn {
  max-width: 80px;
  margin-top: 8px;
  background-color: #0077ff;
  border: 1px solid #143051;
  /* border: none; */
  border-radius: 10px;
  color: white;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.fadedSubmitImg {
  opacity: 0.6;
  pointer-events: none;
}

.photoTooBig {
  color: #ba0000;
  font-weight: 500;
  font-size: 0.7em;
  padding-right: 5%;
}

.customLeaveTypeSelectBar {
  /* background-color: #f4f9ff; */
  display: flex;
  overflow-x: scroll;
  /* border-radius: px; */
  max-width: 100%;
  min-width: 100%;
  min-height: 42px;
  max-height: 42px;
  border-bottom: 2px #dde9f9 solid;
  padding-bottom: 12px;
  padding-right: 22px;
  padding-left: 4px;
  margin-top: 12px;
  /* justify-content: spa; */
}

.customLeaveTypeBarUnit {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  margin-left: 14px;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-right: 1px #859dbb solid; */
  padding-right: 10px;
  font-weight: 500;
  color: #859dbb;
  /* font-weight:  */
}

.customLeaveTypeBarUnit:last-child {
  /* border-right: 0px #859dbb solid; */
}

.customLeaveTypeBarUnit:first-child {
  /* min-width: fit-content; */
  margin-left: 6px;
}

.selectedCustomLT {
  border: 1px solid #143051;
  background-color: #19a5c8;
  color: white;
  border-radius: 5px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: 500;
}

.customsWhiteFadePng {
  margin-top: -42px;
  position: fixed;
  min-width: 35px;
  right: 7%;
}

.contractedBasedOnRevagOrCurrContainer {
  /* background-color: blue; */
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  min-width: 100%;
  padding-right: 8px;
  /* padding: 10px; */
  /* background-color: #214978;
  color: white; */
  /* max-width: 80%; */
  /* min-height: 200px; */
}

.reAvgCurrRow {
  min-width: 100%;
  max-width: 100%;
  display: flex;
  background-color: #f4f9ff;
  border: 1px #cce3ff solid;
  border-top: none;
  padding: 10px;
  color: #19a5c8;
  border-radius: 0 0 5px 5px;
  margin-bottom: 0px;
  padding-top: 14px;
  padding-left: 14px;
  /* padding-bottom: 14px; */
  font-weight: 500;
  font-size: 0.95em;
  justify-content: space-between;
  cursor: pointer;
}

.currAvgSpan {
  color: #143051;
  background-color: #fff;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  line-height: 18px;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: center;
  border: 1px solid #e6eaee;
  /* font-size: 0.9em; */
}

.opacCurrAvgRowLine {
  opacity: 0.6;
  /* color: black; */
}

.reAvgCurrRow:hover {
  background-color: #edf3f9;
}

.reAvgCurrRow:first-child {
  padding-bottom: 9px;
  /* margin-bottom: -3px; */
  border-radius: 5px 5px 0 0;
  border-top: 1px #cce3ff solid;
  border-bottom: none;
}

.reAvgCurrRowLeft {
  min-width: 80%;
  max-width: 80%;
  font-size: 0.8em;
  text-align: left;
  display: flex;
  align-items: center;
  /* font-weight: 500; */
  justify-content: space-between;
}

.avgCurrKnob {
  /* margin-left: 40px; */
  /* align-items: center; */
  margin-top: -6px;
  margin-left: 8px;
}

.reAvgCurrRowBox {
  /* display: flex; */
  /* justify-content: space-between; */
  min-width: 90% !important;
  margin-top: 10px;
  /* margin-left: 12px; */
  /* padding: 40px; */
}

.avgCurrDividerContainer {
  background-color: #f4f9ff;
  /* background-color: yellow; */
  border-left: 1px #cce3ff solid;
  border-right: 1px #cce3ff solid;
  display: flex;
  justify-content: center;
  align-items: center;
  /* min-height: 10px; */
  min-width: 100%;
  /* padding-top: 6px; */
}

.reAvgCurrRowDivider {
  min-height: 1px;
  max-height: 1px;
  min-width: 92%;
  max-width: 92%;
  opacity: 0.1;
  background-color: #143051;
  /* margin-top: 5px; */
  /* max-width: 50%; */
}

.contractedOnlyContainer {
  display: flex;
  padding-top: 6px;
}

.downRightContractedHoursArrowImg {
  max-width: 14px;
  margin-left: 5px;
  margin-top: 6px;
}

.allHoursAllowanceToggle {
  margin-top: -40px;
}

.currAvgKnobOn {
  background-color: #00ccff;
}

.timelineIssuesEditUserSubBox {
  background-color: #ba0000;
  opacity: 0.75;
  background: rgb(136, 2, 2);
  background: linear-gradient(
    180deg,
    rgba(136, 2, 2, 1) 0%,
    rgba(186, 0, 0, 1) 21%,
    rgba(186, 0, 0, 1) 100%
  );
  max-width: 85%;
  color: white;
  margin-left: 15px;
  border-radius: 0 0 5px 5px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 0.9em;
  font-weight: 500;
  margin-bottom: 10px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.selectedTimelineIssues {
  border-radius: 0px;
  opacity: 1;

  /* background-color: #143051; */
  background: rgb(136, 2, 2);
  background: linear-gradient(
    180deg,
    rgba(136, 2, 2, 1) 0%,
    rgba(186, 0, 0, 1) 20%,
    rgba(186, 0, 0, 1) 91%,
    rgba(136, 2, 2, 1) 100%
  );
  margin-bottom: 0px;
}

.schedIssueWarningUnit {
  display: flex;
  font-size: 0.8em;
  align-items: center;
}

.paleRedWarningImg {
  max-width: 16px;
  margin-right: 8px;
}

.invisSchedIssue {
  visibility: hidden;
}

.regenSchedDiv {
  min-width: 100%;
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  padding-right: 6%;
  /* margin-top: 8px; */
}

.regenSchedBtn {
  /* text-align: center; */
  min-width: 180px;
  max-width: 180px;
  min-height: 30px;
  max-height: 30px;
  font-size: 0.9em;
  font-weight: 500;
  /* background-color: blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  border: 1px #143051 solid;
  /* margin-right: 5%; */
  background-color: #0077ff;
  color: white;
  /* animation: regenBlinker 0.5s linear infinite; */
}

@-webkit-keyframes regenBlinker {
  50% {
    /* opacity: 0.8; */
    /* border-color: #1964ba; */
    background-color: #0044ff;
  }
}

@keyframes regenBlinker {
  50% {
    /* opacity: 0.8; */
    /* border-color: #1964ba; */
    background-color: #0044ff;
  }
}

.regenExplain {
  font-size: 0.85em;
  text-align: right;
  color: #496375;
  padding-left: 10%;
  padding-right: 6%;
  margin-top: 18px;
  margin-bottom: 14px;
  opacity: 0.8;
  /* max-width: 80%; */
}

.regenExplain:last-of-type {
  margin-bottom: 30px;
}

.updatingSchedTxt {
  margin-bottom: 20px;
  color: #3b597c;
  font-size: 0.8em;
  font-weight: 400;
  padding-right: 5%;
  /* text-align: right; */
}

.loadingHorizImgGenerating {
  margin-top: 20px;
}

.fixedSchedChangesDiv {
  min-width: 94%;
  /* padding-right: 100px; */
  max-width: 94%;
  margin: 0 auto;
  /* margin-left: */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0px;

  /* padding-right: 5%; */
}

.centerRegen {
  text-align: center;
  /* margin-right: 0px; */
}

.regenBtnSched {
  min-width: 180px;
  max-width: 180px;
  min-height: 30px;
  max-height: 30px;
  font-size: 0.9em;
  font-weight: 500;
  /* background-color: blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  border: 1px #143051 solid;
  /* margin-right: 5%; */
  margin: 0px auto;
  background-color: #0077ff;
  color: white;
  margin-top: 10px;
  /* margin-left: 5px; */
}

.regenExplainSched {
  font-size: 0.85em;
  text-align: center;
  color: #496375;
  padding-left: 10%;
  padding-right: 6%;
  margin-top: 20px;
  margin-bottom: 8px;
  opacity: 0.8;
}

.regenExplainSched2 {
  font-size: 0.85em;
  text-align: center;
  color: #496375;
  padding-left: 10%;
  padding-right: 6%;
  margin-top: 10px;
  margin-bottom: 48px;
  opacity: 0.8;
}

.fixedSchedChangesDiv2 {
  min-width: 94%;
  /* padding-right: 100px; */
  max-width: 94%;
  margin: 0 auto;
  /* margin-left: */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -50px;

  /* padding-right: 5%; */
}

.closeManageShiftModalBtnImg {
  /* background-color: blue; */
  min-width: 35px;
  padding: 10px;
  min-height: 35px;
  margin-top: 3px;
  margin-left: 0px;
  cursor: pointer;
  /* max-width: 35px; */
}

.closeManageShiftModalBtnImg:hover {
  opacity: 0.8;
}
.cursorPointer {
  cursor: pointer !important;
}

.timesItemIsNewBg {
  background-color: #efe7f2;
  padding-bottom: 2px;
  margin-top:-3px;
  border: 1px solid #d6c6dd;
  /* border-bottom: 2px solid #1964ba; */
  border-radius: 7px;
  /* justify-content: space-between; */
  /* min-width: 100%; */
  /* margin-top: 10px; */
  /* max-width: 90%; */
}

/* .whiteUntil {
  color: white;
} */

.displayBlock {
  display: block !important;
}
.publishTickImg {
  margin-right: 7px;
  margin-left: 0px;
  max-width: 16px;
}

.pubImmedBg {
  background-color: white;
  color: #143051;
  border-color: #687d97;

  background-color: #a8cc9a;
  border: 1px #6db451 solid;
  color: white;
}

.pubImmedBg:hover {
  background-color: #94b786;
}

.removeTagImg {
  /* margin-left: 24px; */
  /* margin-top: -2px; */
  /* margin-bottom: -10px; */
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.2s !important;
          animation-duration: 0.2s !important;
  cursor: pointer;
  min-width: 22px;
  max-width: 22px;
  /* margin-left: 15px; */
}

.purpleEditChangeTeamIcon {
  max-width: 12px;
  margin-left: 14px;
  margin-bottom: 1px;
  margin-right: 0px;
  cursor: pointer;
}

.addTagModalHeader {
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  padding: 16px;
}

.addTagModalHeaderTitle {
  font-weight: 500;
  font-size: 1.2em;
}

.phantomModalHeaderUnit {
  /* background-color: black; */
  min-width: 15px;
}

.closeAddTagModalImg {
  cursor: pointer;
  margin-left: 4px;
  margin-right: -4px;
}

.closeAddTagModalImg:hover {
  opacity: 0.8;
}

.generateAvailTagsScrollBox {
  /* background-color: yellow; */
  max-height: 292px;
  min-height: 292px;
  overflow-y: scroll;
  max-width: 90%;
  margin-left: 5%;
  border-bottom: 1px solid #143051;
  border-top: 1px solid #c0d1e9;
  padding-bottom: 20px;
}

.addTagRowItem {
  min-height: 35px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  max-width: -moz-fit-content;
  max-width: 96%;
  margin-left: 2%;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #07f;
  border: #0469de 1px solid;
  margin-top: 8px;
}

.addTagRowItem:hover {
  background-color: #0469de;
}

.editUserLoadingModalBox {
  min-width: 200px;
  max-width: 200px;
  min-height: 120px;
  max-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #fff;
}

.editUserHorizLoadingImg {
  max-width: 60px;
  max-height: 20px;
  margin-top: 14px;
  margin-bottom: -14px;
}

.disableEditUserMenu {
  opacity: 0;
  pointer-events: none;
}

.editUserTopProfileSection {
  min-height: 140px;
  /* background-color: yellow; */
}

.editUserProPicIcon {
  min-width: 80px;
  max-width: 80px;
  min-height: 80px;
  max-height: 80px;
  object-fit: cover;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: -5px;
  /* cursor: pointer; */
  border: #e3e8ee solid 1px;
}

.editUserProPicIcon:hover {
  /* border-color: #b2bbc7 */
}

.editUserNameJtDiv {
  margin-top: 20px;
  /* background-color: orange; */
  display: flex;
  justify-content: space-around;
  padding-right: 10px;
  margin-bottom: 20px;
  /* max-width: 90%; */
}

.editUserEditImg {
  /* margin-right: 20px; */
  margin-right: 7px;
  cursor: pointer;
  /* max-width: 26px; */
  /* background-color: blue; */
  min-width: 28px;
  border-radius: 5px;
  border: #e3e8ee solid 2px;
}

.editUserEditImg:hover {
  border-color: #b2bbc7;
}

.editUserProfileDivSideUnitLeft {
  min-width: 0%;
  max-width: 0%;
}

.editUserProfileDivSideUnit {
  min-width: 10px;
  /* background-color: black; */
  align-items: center;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.activeToggleJtNameUnit {
  border-left: 1px solid #f4f8fe;
  padding-left: 10px;
  margin-top: -82px;
  display: flex;
  flex-direction: column;
}

.editUserFullName {
  font-weight: 500;
  font-size: 20px;
}

.editUserJobTitle {
  font-size: 16px;
  margin-top: 5px;
  color: #516b8b;
  font-weight: 500;
}

.editUserJoined {
  font-size: 14px;
  margin-top: 8px;
  color: #8ea7c5;
}

.editUserClockedOnDiv {
  display: flex;
  /* background-color: orange; */
  min-width: 90%;
  max-width: 90%;
  justify-content: space-between;
  min-height: 40px;
  align-items: center;
  margin: 0 auto;
  margin-top: 40px;
}

.editUserClockedOnTxt {
  font-weight: 500;
  color: #143051;
}

.editUserTopProfileSplitter {
  min-height: 1px;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
  background-color: #e0ebf8;
  margin-top: 14px;
}

.editUserClockOffBtn {
  background-color: #07f;
  border: #0469de 1px solid;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 5px;
  cursor: pointer;
}

.editUserClockOffBtn:hover {
  background-color: #0469de;
}
.editUserProfileMiddle {
  min-width: 70%;
  max-width: 70%;
  /* margin-left: -10%; */
}

.editUserProfileMiddle2 {
  min-width: 90%;
  max-width: 90%;
  margin-right: -20%;
  margin-left: -20%;
}

.editNameFirstAndLastNameInputsDiv {
  display: flex;
  justify-content: space-around;
  /* background-color: yellow; */
  /* margin-left: -20px; */
}

.editUserFNameInput {
  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;
  margin-right: 4px;
  /* margin-left: -20px; */
  /* max-width: 136px; */
  color: #6a8bb1;
  min-height: 40px;
  padding-left: 8px;
  font-weight: 500;
  font-size: 16px;
  max-width: 115px;
}

.editUserFNameInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 6px;
  background-color: #fdfeff;
}

.editUserLNameInput {
  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;
  margin-left: 4px;
  /* max-width: 136px; */
  color: #6a8bb1;
  min-height: 40px;
  padding-left: 8px;
  font-weight: 500;
  font-size: 16px;
  max-width: 115px;
}

.editUserLNameInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 6px;
  background-color: #fdfeff;
}

.editNameUnit {
  max-width: 50%;
  min-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
}

.editUserFNameLabel {
  margin-left: 5px;
  font-size: 12px;
  color: #516b8b;
  margin-bottom: 2px;
  font-weight: 500;
}

.editJtUnit {
  /* background-color: blue; */
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 20px;
}

.editUserJobTitleLabel {
  margin-left: 5px;
  font-size: 12px;
  color: #516b8b;
  margin-bottom: 2px;
  font-weight: 500;
}

.editJobTitleInput {
  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;
  margin-right: 4px;
  min-width: 100%;
  color: #6a8bb1;
  min-height: 40px;
  padding-left: 8px;
  font-weight: 500;
  font-size: 16px;
}

.editJobTitleInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 6px;
  background-color: #fdfeff;
}

.saveNameAndJtBtn {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  background-color: #0bf;
  border: 1px solid #0af;
  padding-left: 3px;
  padding-right: 3px;
  max-width: 56px;
  min-width: 56px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}
.saveNameAndJtBtn:hover {
  background-color: #0af;
}

.editUserStartDateInput {
  border-radius: 5px 5px 0 0;
  border: none;
  background-color: #fff;
  color: #6a8bb1;
  /* font-size: 14px; */
  min-height: 35px;
  line-height: 35px;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 5px;
  letter-spacing: 1px;
  padding-top: 1px;
  font-weight: 500;
  max-width: 153px;
  min-width: 153px;
  background: url(/static/media/editUserCal.27a62c52.svg) no-repeat right #f4f8fe;
  -webkit-appearance: none;
  background-position-x: 120px;
  border: none;
  border-bottom: 1px solid #dde9f9;
  margin-left: 0px;
  margin-top: 0px;
}

.editUserStartDateInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 7px;
  background-color: #fdfeff;
  background-position-x: 118px;
}

.invalidEditUserTopDetails {
  cursor: none;
  pointer-events: none;
  opacity: 0.4;
}

.editUserAbsenceRow {
  background-color: #f1f8ff;
  max-width: 90%;
  margin: 20px auto;
  min-height: 50px;
  border-radius: 5px;
  display: flex;
  max-height: 35px !important;
  min-height: 35px !important;
  cursor: pointer;
}

.editUserAbsenceRow:hover {
  background-color: #ecf1f6;
}

.editUserAbsenceYear {
  cursor: pointer;
  font-size: 12px;
  margin-top: 2px;
}

.editUserAbsencePercent {
  cursor: pointer;
}

.editUserAllowBarRow {
  background-color: #f1f8ff;
  max-width: 90%;
  margin: 18px auto;
  min-height: 50px;
  border-radius: 5px;
  display: flex;
  cursor: pointer;
  max-height: 35px !important;
  min-height: 35px !important;
  cursor: pointer;
}

.editUserAllowBarRow:hover {
  background-color: #ecf1f6;
}
.absenceScoreBar {
  background-color: #d2e6fd;
  min-height: 35px;
  /* min-width  */
  min-width: 90%;
  max-height: 35px;
  border-radius: 0 5px 5px 0;
  /* cursor: pointer; */
  /* position: fixed; */
  /* z-index: -1; */
}

.absenceScoreBar:hover {
  background-color: #c8ddf6;
}
.absenceBarTxtDiv {
  z-index: 9999;
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  display: flex;
  justify-content: space-between;
  margin-top: -47px;
  padding-left: 10px;
  padding-right: 10px;
  color: #143051;
  font-weight: 500;
  font-size: 14px;
}

.allowBarTxtDiv {
  z-index: 9999;
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  display: flex;
  justify-content: space-between;
  margin-top: -45px;
  padding-left: 10px;
  padding-right: 10px;
  color: #143051;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.score100 {
  min-width: 100%;
  -webkit-animation-name: bar100 !important;
          animation-name: bar100 !important;
  -webkit-animation-duration: 1s !important;
          animation-duration: 1s !important;
}

.score95 {
  min-width: 95%;
  -webkit-animation-name: bar95 !important;
          animation-name: bar95 !important;
  -webkit-animation-duration: 1s !important;
          animation-duration: 1s !important;
}

.score90 {
  min-width: 90%;
  -webkit-animation-name: bar90 !important;
          animation-name: bar90 !important;
  -webkit-animation-duration: 1s !important;
          animation-duration: 1s !important;
}

.score85 {
  min-width: 85%;
  -webkit-animation-name: bar85 !important;
          animation-name: bar85 !important;
  -webkit-animation-duration: 1s !important;
          animation-duration: 1s !important;
}

.score80 {
  min-width: 80%;
  -webkit-animation-name: bar80 !important;
          animation-name: bar80 !important;
  -webkit-animation-duration: 1s !important;
          animation-duration: 1s !important;
}

.score75 {
  min-width: 75%;
  -webkit-animation-name: bar75 !important;
          animation-name: bar75 !important;
  -webkit-animation-duration: 1s !important;
          animation-duration: 1s !important;
}

.score70 {
  min-width: 70%;
  -webkit-animation-name: bar70 !important;
          animation-name: bar70 !important;
  -webkit-animation-duration: 1s !important;
          animation-duration: 1s !important;
}

.score65 {
  min-width: 65%;
  -webkit-animation-name: bar65 !important;
          animation-name: bar65 !important;
  -webkit-animation-duration: 1s !important;
          animation-duration: 1s !important;
}

.score60 {
  min-width: 60%;
  -webkit-animation-name: bar60 !important;
          animation-name: bar60 !important;
  -webkit-animation-duration: 1s !important;
          animation-duration: 1s !important;
}

.score55 {
  min-width: 55%;
  -webkit-animation-name: bar55 !important;
          animation-name: bar55 !important;
  -webkit-animation-duration: 1s !important;
          animation-duration: 1s !important;
}

.score50 {
  min-width: 50%;
  -webkit-animation-name: bar50 !important;
          animation-name: bar50 !important;
  -webkit-animation-duration: 1s !important;
          animation-duration: 1s !important;
}

.score45 {
  min-width: 45%;
  -webkit-animation-name: bar45 !important;
          animation-name: bar45 !important;
  -webkit-animation-duration: 1s !important;
          animation-duration: 1s !important;
}

.score40 {
  min-width: 40%;
  -webkit-animation-name: bar40 !important;
          animation-name: bar40 !important;
  -webkit-animation-duration: 1s !important;
          animation-duration: 1s !important;
}

.score35 {
  min-width: 35%;
  -webkit-animation-name: bar35 !important;
          animation-name: bar35 !important;
  -webkit-animation-duration: 1s !important;
          animation-duration: 1s !important;
}

.score30 {
  min-width: 30%;
  -webkit-animation-name: bar30 !important;
          animation-name: bar30 !important;
  -webkit-animation-duration: 1s !important;
          animation-duration: 1s !important;
}

.score25 {
  min-width: 25%;
  -webkit-animation-name: bar25 !important;
          animation-name: bar25 !important;
  -webkit-animation-duration: 1s !important;
          animation-duration: 1s !important;
}

.score20 {
  min-width: 20%;
  -webkit-animation-name: bar20 !important;
          animation-name: bar20 !important;
  -webkit-animation-duration: 1s !important;
          animation-duration: 1s !important;
}

.score15 {
  min-width: 15%;
  -webkit-animation-name: bar15 !important;
          animation-name: bar15 !important;
  -webkit-animation-duration: 1s !important;
          animation-duration: 1s !important;
}

.score10 {
  min-width: 10%;
  -webkit-animation-name: bar10 !important;
          animation-name: bar10 !important;
  -webkit-animation-duration: 1s !important;
          animation-duration: 1s !important;
}

.score05 {
  min-width: 5%;
  -webkit-animation-name: bar05 !important;
          animation-name: bar05 !important;
  -webkit-animation-duration: 1s !important;
          animation-duration: 1s !important;
}

.score0 {
  min-width: 0%;
  -webkit-animation-name: bar00 !important;
          animation-name: bar00 !important;
  -webkit-animation-duration: 1s !important;
          animation-duration: 1s !important;
}

.editUserRowTitleAndInput {
  min-width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* background-color: #0044ff; */
}

.editUserRowPartOfTeamsRow {
  min-width: 100%;
}

.editUserValDiv {
  display: flex;
  /* background-color: yellow; */
  min-width: 20%;
  min-height: 40px;
  justify-content: center;
  /* padding-right: 25px; */
  align-items: center;
}

.editUserEditValue {
  margin-top: 4px;
  margin-bottom: -1px;
  font-size: 16px;
  color: #58bdee;
  font-weight: 500;
  text-align: left;
  margin-left: 5%;
  min-height: 40px;
  overflow-wrap: break-word;
  padding-top: 6px;
}

.primaryTeamBold {
  margin-top: 4px;
  margin-bottom: -1px;
  font-size: 16px;
  color: #00ccff;
  font-weight: 500;
  text-align: left;
  margin-left: 5%;
  min-height: 40px;
  overflow-wrap: break-word;
  padding-top: 6px;
}

.reqClockinValContainer {
  /* margin-top: 4px; */
  /* margin-bottom: -1px; */
  font-size: 16px;
  color: #8997a8;
  font-weight: 400;
  text-align: left;
  /* margin-left: 5%; */
  margin-right: 14px;
  min-height: 40px;
  overflow-wrap: break-word;
  /* padding-top: 6px; */
}

.editUserValEditIcon {
  cursor: pointer;
  border: #e3e8ee solid 2px;
  padding: 6px;
  border-radius: 5px;
  max-width: 28px;
}

.editUserValEditIcon:hover {
  /* transform: scale(1.12); */
  /* opacity: 0.8; */
  border: #b2bbc7 solid 2px;
  padding: 6px;
  border-radius: 5px;
}
.saveEditUserValBtn {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  background-color: #0bf;
  border: 1px solid #0af;
  padding-left: 12px;
  padding-right: 12px;
  /* max-width: 50px; */
  /* min-width: 50px; */
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}
.saveEditUserValBtn:hover {
  background-color: #0af;
}

.invalidDs {
  opacity: 0.2;
  pointer-events: none;
}

.cancelEditUserValBtn {
  font-size: 14px;
  font-weight: 500;
  padding-left: 7px;
  padding-right: 7px;
  max-width: 64px;
  min-width: 64px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  margin-right: 15px;
  margin-top: -30px;
}
.cancelEditUserValBtn:hover {
  background-color: #bbcada;
}

.editUserEmailValInput {
  color: #6a8bb1;
  min-height: 35px;
  padding-left: 9px;
  font-weight: 500;
  margin-left: 5%;
  min-width: 90%;
  margin-top: 5px;
  margin-bottom: 3px;
  font-size: 15px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 7px;
  background-color: #fdfeff;
}

.editUserEmailValInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 7px;
  background-color: #fdfeff;
}

.partOfTeamsDiv {
  /* background-color: blue; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  /* flex-direction: column; */
  margin-left: 5%;
  /* margin-top: 12px; */
  margin-bottom: 8px;
  /* min-height: 200px; */
}

.editUserTeamUnit {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  border-radius: 5px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 35px;
  min-height: 35px;
  align-items: center;
  border-radius: 5px;
  color: #496375;
  font-weight: 500;
  font-size: 14px;
  background-color: #e3e8ee;
  color: #4f566b;
  /* animation-name: zoomModalIn !important; */
  /* animation-duration: 0.1s !important; */
  margin-right: 8px;
  margin-top: 8px;
}

.ghostMgrOf {
  background-color: #f0f0f0 !important;
}

.editUserTeamPrimaryUnit {
  /* border: solid 2px #496375; */
  /* padding-right: 4px; */
}

.removeTeamUnitCrossImg {
  min-width: 14px;
  margin-left: 8px;
  margin-right: 4px;
  cursor: pointer;
  padding: 2px;
}

.removeTeamUnitCrossImgBlank {
  min-width: 0px;
  visibility: hidden;
  pointer-events: none;
  margin-left: 0px;
  margin-right: 0px;
  cursor: pointer;
  margin-left: -2px;
  /* padding: 2px; */
}

.toggleAddTeamBtn {
  min-height: 30px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  background-color: #0bf;
  border: 1px solid #0af;
  margin-left: 5%;
  margin-bottom: 18px;
  margin-top: 18px;
  padding-left: 6px;
  padding-right: 6px;
}

.addToOtherTeamBtnTxtPlus {
  font-size: 16px;
  margin-left: 3px;
  margin-top: -2px;
}

.toggleAddTeamBtn:hover {
  opacity: 0.8;
}

.toggleAddTeamDropdown {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 263px;
  min-width: 263px;
  line-height: 26px;
  height: 35px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #516b8b;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 242px;
  margin-left: 5%;
  margin-top: 18px;
  margin-bottom: 18px;
}

.changePrimaryTeamDropdown {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 263px;
  min-width: 263px;
  line-height: 26px;
  height: 35px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #516b8b;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 242px;
  margin-left: 3%;
  margin-top: 14px;
  margin-bottom: 14px;
}

.editUserPTeamExplain {
  color: #516b8b;
  font-size: 12px;
  text-align: left;
  margin-left: 5%;
  margin-bottom: 20px;
  margin-top: 14px;
  padding-right: 20px;
  font-weight: 400;
  line-height: 14px;
  padding-left: 10px;
  border-left: 2px solid #516b8b;
}

.doesNotManageTeamSpan {
  color: #bbcada;
  font-size: 12px;
  text-align: left;
  /* margin-left: 5%; */
  /* margin-bottom: 20px; */
  margin-top: 0px;
  font-weight: 500;
}

.editPTeamIcon {
  /* margin-top: -40px; */
  /* top: 0; */
  /* position: fixed */
}

.noPermissionsEditUserTxt {
  color: #496375;
  /* font-weight: 500; */
}

.noPermissionsCloseBtn {
  font-size: 14px;
  color: #fff;
  background-color: #214978;
  border: solid #143051 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 30px;
  cursor: pointer;
  min-width: 100%;
  margin-top: 30px;
}

.noPermissionsCloseBtn:hover {
  background-color: #143051;
}

.generatePTeamMgrs {
  margin-top: 10px;
  margin-left: 10px;
  line-height: 24px;
  font-weight: 500;
}

.editUserPTeamRow {
  align-items: flex-start;
}

.pTeamMgrBulletSpan {
  min-width: 10px;
  max-width: 10px;
  min-height: 10px;
  max-height: 10px;
  background-color: black;
}

.hourlyRateInputter {
  max-width: 86px;
  min-width: 86px;
}

.hourlyRateInclLoaderDiv {
  display: flex;
  margin-top:-12px
}

.updatingShiftsAniBox {
  font-size: 12px;
  max-width: 180px;
  text-align: left;
  margin-left: 20px;
  margin-top: 8px;
  display: flex;
}

.loadingUpdateShiftsLoaderImg {
  max-width: 40px;
  min-width: 40px;
  max-height: 16px;
  margin-top: 3px;
  margin-left: 12px;
}

.updatingShiftsSpan {
  /* margin-left: 4px; */
  margin-top: 4px;
  opacity: 0.5;
}
.hourlyRateHistoryTimelineBtn {
  /* background-color: blue; */
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: -100px;
  margin-bottom: 60px;
  min-height: 40px;
  align-items: center;
}

.hourlyRateHistoryTimelineBtn2 {
  /* background-color: blue; */
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: -180px;
  margin-bottom: 94px;
  min-height: 40px;
  align-items: center;
}

.hourlyRateTitle {
  min-width: 104px;
}

.salariedRow {
  min-height: 135px;
  max-height: 135px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 16px;
  padding-left: 5%;
  padding-right: 5%;
}

.salariedToggleDiv {
  min-height: 70px;
  max-height: 70px;
  background-color: white;
  /* margin-top: 30px; */
  /* margin-left: 12px; */
  min-width: 112px;
  border: 1px solid #e3e8ee;
  border-radius: 5px;
  max-width: 140px;
  font-size: 16px;
}

.wPattToggleDiv {
  min-height: 105px;
  max-height: 105px;
  background-color: white;
  /* margin-top: 30px; */
  /* margin-left: 12px; */
  min-width: 234px;
  border: 1px solid #e3e8ee;
  border-radius: 5px;
  max-width: 140px;
  font-size: 16px;
}

.allowToggleDiv {
  min-height: 105px;
  /* max-height: 105px; */
  background-color: white;
  /* margin-top: 30px; */
  /* margin-left: 12px; */
  min-width: 264px;
  border: 1px solid #e3e8ee;
  border-radius: 5px;
  max-width: 264px;
  font-size: 16px;
  margin-left: 10px;
  margin-top: 20px;
}

.userHRateHistoryBtn {
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  /* border: 1px solid #eaf3fd; */
  background-color: #b7c9e4;
  border: 1px solid #99adcc;
  padding-left: 7px;
  padding-right: 6px;
  cursor: pointer;
  min-height: 30px;
  display: flex;
  align-items: center;
  /* margin-right: 6%; */
  color: #fff;
  max-height: 30px;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  /* margin-top: 2px; */
}

.editUserPopUpIcon {
  max-width: 15px;
  margin-left: 4px;
}

.contrhourstimelinepopup {
  max-width: 15px;
  margin-left: 4px;
}

.userHRateHistoryBtn:hover {
  background-color: #afc1db;
}

.editHrRateValDiv {
  margin-top: 15px;
}

.hourlyRateTimelineHeader {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  padding-left: 20px;
  margin-top: 5px;
  padding-right: 40px;
  margin-bottom: 20px;
}

.mpwTimelineHeader {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  padding-left: 25px;
  margin-top: 20px;
  padding-right: 40px;
  margin-bottom: 10px;
}

.hourlyRateTimelineCloseImg {
  cursor: pointer;
}

.hourlyRateTimelineCloseImg:hover {
  opacity: 0.8;
}

.hourlyRateTimelineScrollWrapper {
  background-color: #eaf3fd;
  overflow-y: scroll;
  max-height: 300px;
  /* min-height: 300px; */
  max-width: 90%;
  margin-left: 5%;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #cbd7e6;
  padding-top: 8px;
}

.noTimelineHistoryTxt {
  font-size: 12px;
  padding: 20px;
  text-align: left;
  margin-top: -10px;
  color: #516b8b;
  font-weight: 400;
  line-height: 16px;
}

.noLaterThanYesterdaySpan {
  color: #e89292;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 3px;
  margin-left: 12px;
  margin-top: 0px;
}

.overlapWarnInline {
  color: #e89292;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 3px;
  /* margin-left: 12px; */
  margin-top: 10px;
  font-weight: 500;
  font-size: 12px;
}

.editWorkingPatternDropdown {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 223px;
  min-width: 223px;
  line-height: 26px;
  height: 35px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 202px;
  margin-left: 4%;
  margin-top: 8px;
  margin-bottom: 5px;

  margin-top: 16px;
  margin-bottom: -8px;
}

.editWorkingPatternDropdown:hover {
  border-color: #bac5d3;
}

.workingPatternExplain {
  text-align: left;
  padding-left: 10px;
  font-size: 12px;
  margin-top: 30px;
  border-left: 2px solid #516b8b;
  color: #516b8b;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-weight: 400;
  line-height: 16px;
  max-width: 90%;
  margin-bottom: 30px;
}

.workingPatternExplainMobModal {
  max-width: 80vw;
}

.approveClockEditUserRow {
  /* min-height: 280px; */
  padding-top: 110px;
  padding-bottom: 90px;
}

.gpsZoneEditUserRow {
  /* min-height: 280px; */
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  padding-top: 30px;
  padding-bottom: 20px;
}

.approveClockEditUserRowDesktop {
  min-height: 260px;
  padding-bottom: 110px;
}

.payClockedRowMob {
  padding-top: 90px;
  min-height: 270px;
  /* padding-bottom: 30px; */
}

.autoApproveClockKnobContainer {
  margin-top: -80px;
}

.desktopAutoApproveClockDiv {
  padding-left: 0px;
}

.autoApproveClockDiv {
  max-width: 80%;
  padding-left: 8px;
}

.gpsZoneClockDiv {
  max-width: 90%;
  padding-left: 8px;
  padding-top: 10px;
}
.autoApproveExplain {
  text-align: left;
  margin-left: 5%;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 14px;
  color: #516b8b;
  min-height: 72px;
  max-width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 16px;
  font-weight: 400;
  

  /* background-color: orange; */
}

.clockGpsZoneExplain {
  text-align: left;
  margin-left: 5%;
  font-size: 14px;
  margin-top: 14px;
  margin-bottom: 14px;
  color: #bbcada;
  min-height: 72px;
  max-width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-weight: 500;
  line-height: 18px;
}

.amendOwnClocksRow {
  text-align: left;
  margin-left: 5%;
  font-size: 12px;
  margin-top: 14px;
  margin-bottom: 14px;
  color: #516b8b;
  min-height: 72px;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 16px;
  font-weight: 400;
}

.managesOwnTeamRow {
  margin-top: 3px;
  color: #8ea7c5;
}

.managesOwnTeamRow:first-child {
  margin-top: 12px;
}

.wPattEditUserRow {
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  /* max-height: 110px; */
  padding-top: 16px;
  min-width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #ddebf4;
  /* font-size: 1.2em; */
  /* border: none; */
}

.editWPatternSaveContainer {
  margin-left: -5%;
  min-width: 25%;
}

.wPattEditBtn {
  margin-top: -26px;
}

.invisWPattExplain {
  visibility: hidden;
  /* opacity: 0.5    ; */
}

.wPattSubTitle {
  margin-top: 28px;
  color: #214978;
  text-align: left;
  font-size: 16px;
  margin-left: 5%;
  font-weight: 500;
  text-align: left;
}

.fixedSchedEditContainer {
  margin-top: 0px;
}

.fixedSchedDayRow {
  background-color: white;
  padding-left: 5%;
  min-width: 96%;
  /* border-bottom: 1px solid #e6eaee; */
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding-bottom: 10px;
  padding-top: 10px;
  max-width: 96% !important;
  margin: 10px auto;
}

.fixedSchedDayRow:first-child {
  margin-top: 12px;
}

.dayNameAndPowerRow {
  /* background-color: lime; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -6px;
  margin-bottom: -2px;
  min-width: 93%;
}

.editDayFxSchedKnob {
  /* background-color: yellow; */
  margin-bottom: 8px;
}

.fixedSchedDayTitle {
  font-size: 16px;
  font-weight: 500;
  margin-left: 14px;
  margin-top: 6px;
  color: #8997a8;
  display: flex;
}

.dayPowerOn {
  color: #07f;
}

.fadeEditDayFsRow {
  opacity: 0.5;
}

.editDsTimeInput {
  background-color: #fff;
  width: 77px;
  max-width: 77px;
  line-height: 35px;
  padding-top: 1px;
  height: 35px;
  border: 1px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 15px;
  font-weight: 500;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  cursor: text;

  /* border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 3px;
  background-color: #fdfeff; */
}

.editDsTimeInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 3px;
  background-color: #fdfeff;
}

.editDsBrkInput {
  background-color: #fff;
  width: 47px;
  max-width: 47px;
  line-height: 35px;
  padding-top: 1px;
  height: 35px;
  border: 1px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 15px;
  font-weight: 500;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  cursor: text;

  /* border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 3px;
  background-color: #fdfeff; */
}

.editDsBrkInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 3px;
  background-color: #fdfeff;
}
.nonPowerEditDsTimeInput {
  color: #f4f8fe;
}

.selEditDateInputter {
  border: 2px solid #00ccff;
}
.editUserFxDayDataRow {
  display: flex;
}

.editDsDayStartUnit {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 7px;
}

.editDsDayStartUnit:first-child {
  margin-left: 0px;
}

.editDsDayStartTxt {
  margin-left: 4px;
  font-weight: 500;
  margin-bottom: 3px;
  font-size: 12px;
  color: #496375;
}

.editDayDsBrkDiv {
  display: flex;
}

.editDayFsBrkMinsTxt {
  font-weight: 500;
  font-size: 12px;
  margin-top: 8px;
  margin-left: 3px;
}

.fixedScheduledRow {
  background-color: #edf2f9;
  /* margin-top: -50px; */
}

.fixedVariedRow {
  background-color: #edf2f9;
}

.saveDayFSBtn {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  background-color: #0bf;
  border: 1px solid #0af;
  padding-left: 12px;
  padding-right: 12px;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  border-radius: 5px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5%;
  margin-top: 3px;
  cursor: pointer;
}

.saveDayFSBtn:hover {
  background-color: #0af;
}
.editDayFsTopRowDiv {
  display: flex;
  justify-content: space-between;
}

.editedFixedSchedRowHighlight {
  border: #00ccff solid 1px;
}

.fixedSchedDayDurSpan {
  /* background-color: yellow; */
  margin-left: 22px;
  /* color: #0044ff; */
  /* background-color: #859dbb; color: white; border:1px solid #496375; */
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #859dbb;
  /* text-align: left; */
  /* display: flex;
  justify-content: flex-start; */
  /* min-height: 100%; */
}

.dayNameEditUserSpan {
  min-width: 80px;
  text-align: left;
  margin-left: 12px;
  margin-top: 3px;
}

.wPattSubDiv {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
}
.wPattTotalHours {
  margin-top: 60px;
  color: #8ea7c5;
  border-radius: 5px;
  background-color: #fff;
  margin-right: 5%;
  font-weight: 500;
  text-align: center;
  padding-left: 8px;
  padding-right: 8px;
  min-height: 100%;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #e0ebf8;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  border-right: 2px solid;
}

.desktopwPattTotalHours {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-right: 7px;
  padding-left: 7px;
  margin-top: 20px;
}

.fixedVarHMnumInput {
  background-color: #fff;
  width: 49px;
  max-width: 49px;
  /* line-height: 30px; */
  /* padding-top: 1px; */
  height: 35px;
  border: 1px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  color: #496375;
  font-size: 15px;
  font-weight: 500;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  /* border-bottom: 1px solid #dde9f9; */
  background-color: #f4f8fe;
  cursor: text;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  /* padding-left: 3px; */
  background-color: #fdfeff;
  margin-bottom: 4px;
}

/* .fixedVarHMnumInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 3px;
  background-color: #fdfeff;
} */
.editUserFVContainer {
  background-color: #f4f8fe;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  max-width: 220px;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: flex-start; */
  margin-left: 5%;
  /* justify-content: space-between; */
  min-height: 90px;
  padding: 10px;
  margin-top: 0px;
  margin-bottom: 24px;
}

.editUserFvBox {
  display: flex;
  min-height: 66px;
}

.editUserFVleft {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.editUserFVrow {
  display: flex;
  align-items: center;
}

.saveFVrowBtn {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  background-color: #0bf;
  border: 1px solid #0af;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 5px;
  min-height: 35px;
  margin-left: 30px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  cursor: pointer;
}

.saveFVrowBtn:hover {
  background-color: #0af;
}

.editUserFVHoursMinsTxt {
  margin-top: 6px;
  margin-left: 6px;
  font-weight: 500;
  font-size: 14px;
  min-width: 50px;
  text-align: left;
  color: #214978;
}

.fvPerweekTxt {
  font-size: 12px;
  color: #516b8b;
  text-align: left;
  margin-bottom: 9px !important;
  margin-bottom: 8px;
  font-weight: 400;
}

.editUserAllowInfoBox {
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  min-height: 40px;
  margin-top: 40px;
  margin-bottom: -30px;
  /* background-color: yellow; */
}

.allowInfoTxtString {
  font-size: 14px;
  text-align: left;
  color: #bbcada;
  line-height: 22px;
  font-weight: 500;
  line-height: 18px;
}

.editUserProfileTeamDiv {
  background-color: yellow;
  min-height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5%;
  /* margin-top: -200px; */
  margin-bottom: -40px;
  z-index: 9999999999;
}

.editUserPTeamBracketSpan {
  background-color: #e3e8ee;
  color: #4f566b;
  font-weight: 500;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  font-size: 12px;
  padding-bottom: 1px;
}

.teamInlineCursp {
  cursor: pointer;
}

.checkAllowancesHighlighted {
  /* background-color: #00ccff; */
  /* color: #00ccff; */
  border-radius: 5px;
  /* padding-left: 12px; */
  /* padding-right: 5px; */
  margin-left: -12px;
  /* margin-right: -5px; */
}

.checkAllowancesHighlightedSpanBlob {
  min-width: 12px;
  background-color: #0044ff;
  border-radius: 10px;
  margin-right: 2px;
  margin-left: 6px;
}

.checkAllowancesUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.checkAllowModalBox {
  min-width: 300px;
  max-width: 300px;
  background-color: white;
  min-height: 200px;
  border-radius: 5px;
  cursor: default;
}

.checkAllowTxt {
  min-height: 150px;
  max-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: #496375;
  font-size: 15px;
}

.checkAllowBtnBar {
  min-height: 50px;
  max-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row-reverse;
  padding-right: 2%;
  padding-left: 2%;
  margin-bottom: 2px;
}

.checkAllowBtn {
  min-width: 46%;
  background-color: #0077ff;
  color: white;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
}

.checkAllowBtn:hover {
  opacity: 0.9;
}

.checkAllowBtnExit {
  min-width: 46%;
  background-color: #496375;
  color: white;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
}

.checkAllowBtnExit:hover {
  opacity: 0.9;
}

.mpwTimelineHolderDiv {
  min-height: 30px;
  min-width: 40%;
  display: flex;
  justify-content: flex-end;
  margin-top: -185px;
  margin-bottom: 159px;
}

.mpwTimelineMob {
  margin-top: -164px;
  margin-bottom: 134px;

  margin-top: -212px;
  margin-bottom: 171px;
}

.fixedSchedMobMpwTimelineBtn {
  margin-top: -248px;
  margin-bottom: 215px;
}
.fixedSchedMobMpwTimelineBtnDesktop {
  margin-top: -215px;
  margin-bottom: 181px;
}
.mpwTimelineZero {
  /* margin-top: -125px; */
  margin-bottom: 126px;
}

.mpwTimelineButton {
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  /* border: 1px solid #eaf3fd; */
  background-color: #0af;
  border: 1px solid #07f;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  min-height: 35px;
  display: flex;
  align-items: center;
  margin-right: 6%;
  color: #fff;
  max-height: 35px;
  /* box-shadow: 0px 0px 3px rgb(0 0 0 / 20%); */
  margin-top: 12px;
  margin-bottom: 2px;
}

.mpwTimelineButton:hover {
  background-color: #0fa2eb;
}

.workPatternTitle {
  /* background-color: yellow; */
  margin-top: 45px;
}

.zeroWpattEditBtn {
  /* background-color: blue; */
  margin-top: -6px;
}

.lastSavedContractedHoursTxt {
}

.hideRemoveTimelineBtn {
  pointer-events: none;
  opacity: 0.1;
  cursor: default;
}

.hideRemoveTimelineBtn:hover {
  opacity: 0.1;
}

/* .whiteTxtTime {
  color: white;
} */

.x12134 {
  color: #143051;
}

.openUnitTypeDropdown {
  margin-top: 10px;
}

.proPicChangeForm {
  /* background-color: blue; */
  max-width: 90px;
  min-width: 90px;
  margin: -72px auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding-right: 13px;
  margin-bottom: 70px;
  /* padding-left: 5%; */
}

.proPicChangeBtn {
  color: white;
  max-width: 40px;
  opacity: 0;
  /* visibility: hidden; */
  cursor: pointer;
  margin-top: -25px;
  margin-bottom: 5px;
  cursor: pointer;
}

.editProPicPencilIcon {
  min-width: 28px;
  min-height: 28px;
  background-color: white;
  border-radius: 5px;
  border: #e3e8ee solid 2px;
  cursor: pointer !important;
  opacity: 0.8;
}

.editProPicPencilIcon:hover {
  border-color: #b2bbc7;
  opacity: 1;
}

.initialContainer {
  min-width: 80px;
  min-height: 80px;
  max-width: 80px;
  max-height: 80px;
  margin: 20px auto;
  border-radius: 5px;
  /* margin-bottom: -20px; */
}

.profilePicturePlaceholder {
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}

.letter1letter2 {
  display: flex;
  margin-left: 9px;
  justify-content: center;
  align-items: center;
  margin-top: -70px;
  color: white;
  min-width: 60px;
  max-width: 60px;

  font-size: 40px;
  font-weight: 500;
  display: none;
  /* letter-spacing: 170px; */
  /* character-s  */
  /* letter-spacing: 10px; */
}

.pullDown20px {
  margin-bottom: -20px;
}

.clockCardInitials {
  max-width: 40px !important;
  max-height: 40px !important;
  min-width: 40px !important;
  min-height: 40px !important;
}

.removeProPicIcon {
  min-width: 28px;
  min-height: 28px;
  max-width: 28px;
  max-height: 28px;
  background-color: white;
  border-radius: 5px;
  border: #e3e8ee solid 2px;
  cursor: pointer !important;
  padding: 4px;
  margin-bottom: 25px;
  margin-left: -14px;
  margin-right: -16px;
}

.notAdminIsSelf {
  opacity: 0.4;
  cursor: default;
  /* pointer-events: none; */
}

.individualApproveTitle {
  padding-right: 80px;
  line-height: 24px;
  margin-bottom: 10px;
}

.whoCanApproveTitle {
  padding-right: 100px;
}

.whoCanManageUserDiv {
  background-color: blue;
}

.pTeamMgrName {
  color: #516b8b;
  font-size: 12px;
  text-align: left;
}

.whoCanManageUserClockRow {
  /* margin-top: 8px; */
  margin-left: 5%;
  color: #8ea7c5;
  font-weight: 500;
}

.whoCanManageUserClockRow:first-child {
  margin-top: 0px;
}

.finalTeamEditUserRow {
  margin-bottom: 50px;
}

.approveOwnClocksRow {
  min-height: -webkit-fit-content !important;
  min-height: -moz-fit-content !important;
  min-height: fit-content !important;
  max-height: -webkit-fit-content !important;
  max-height: -moz-fit-content !important;
  max-height: fit-content !important;
  padding-top: 40px;
  padding-bottom: 20px;
}

.allStaffZoneBg {
  background-color: #d0d9e5;
  cursor: pointer;
}

.invalidFS {
  opacity: 0.3;
  pointer-events: none;
}

.paddingTopWPatt {
  padding-top: 22px !important;
}

.hoverRowColour:hover {
  background-color: #f7f9fb;
  cursor: pointer;
}

.editUserMoreBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  min-width: 92px;
  max-width: 92px;
  max-height: 35px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  border-radius: 5px;
  min-height: 35px;
  cursor: pointer;
  /* min-width: 100%; */
}

.editUserMoreBtn:hover {
  background-color: #214978;
}

.editUserThreeDotsImg {
  max-width: 17px;
  margin-right: 5px;
  margin-left: 2px;
}

.whitecrossEditUserMoreImg {
  max-width: 14px;
  min-width: 14px;
}

.editUserShowMoreUnderlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 0, 0, 0.2);
  min-width: 100vw;
  min-height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 9999999;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.editUserShowMoreModalBox {
  background-color: #f4f8fe;
  min-width: 100vw;
  max-width: 100vw;
  /* max-height: 500px; */
  min-height: 310px;
  max-height: 310px;

  border-radius: 5px 5px 0 0;

  align-items: center;
  color: #143051;
  position: fixed;
  bottom: 0;
  text-align: center; 
  cursor: default;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-animation-name: moveEditTeamUserBoxUp !important;
          animation-name: moveEditTeamUserBoxUp !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  /* padding-bottom: 20px; */
}

.desktopShowMoreModalBox {
  position: relative;
  min-width: 340px;
  max-width: 340px;
  border-radius: 5px;
  min-height: 200px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.showMoreModalHeader {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 20px;
}

.editUserActionsModalMenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 60%;
  margin-bottom: 10px;
}

.editUserActionsBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 72px;
  padding-left: 13px;
  padding-right: 13px;
  min-height: 35px;
  max-height: 35px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  margin-top: 10px;
}

.editUserActionsBtn:visited {
  color: white;
}
.editUserActionsBtn:hover {
  background-color: #bbcada;
}
.editUserActionSplitter {
  min-height: 1px;
  min-width: 80%;
  max-width: 80%;

  background-color: #214978;
  margin-top: 10px;
}

.editUserMoreFlexBox {
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
}

.editUserMoreFlexRight {
  min-width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.editUserMoreImg {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  padding-left: 9px;
  padding-right: 9px;
  min-height: 50px;
  max-height: 50px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
  max-width: -moz-fit-content;
  max-width: 50px;
  margin-top: 10px;
}

.editUserMoreImg:hover {
  background-color: #bbcada;
}

.editUserMoreFooter {
  /* background-color: yellow; */
  min-width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 10px;
  border-top: 1px solid #bac5d3;
  margin-top: 10px;
}

.editUserMoreCloseBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
}

.editUserMoreCloseBtn:hover {
  background-color: #214978;
}

.messageEditUserActionsColours {
  background-color: #07f;
  border: #0469de 1px solid;
  margin-bottom: 25px;
}

.messageEditUserActionsColours:hover {
  background-color: #0469de;
}

.emailAlreadyExistsSpan {
  /* background-color: #214978; */
  color: #ba0000;
  font-size: 12px;
  margin-left: 8px;
  font-weight: 500;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.reAvgTimelineRow {
  min-width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
}

.reAvgTimelineBtn {
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  font-size: 12px;
  font-weight: 500;
  border-radius: 5px;
  /* border: 1px solid #eaf3fd; */
  background-color: #b7c9e4;
  border: 1px solid #99adcc;
  padding-left: 5px;
  padding-right: 4px;
  cursor: pointer;
  min-height: 26px;
  display: flex;
  align-items: center;
  /* margin-right: 6%; */
  color: #fff;
  max-height: 30px;
  margin-left: 178px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-top: -34px;
}

.reAvgTimelineBtn:hover {
  background-color: #afc1db;
}

.salariedToggleRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-height: 35px;
  padding-left: 3px;
  /* padding-right: 12px; */
  margin-top: 0px;
  font-weight: 500;
  color: #496375;
  padding-bottom: 2px;
  cursor: pointer;
}

.wPattInfoTxt {
  font-size: 10px;
}

.selectedSalariedToggleRow {
  background-color: #f3f7ff;
}

.editUserRow2WPattDiv {
  display: flex;
  justify-content: space-between;
  /* margin-top:10px; */
}

.editUserRow2WPattDivMob {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
}

.wPattExplainDiv {
  margin-top: 0px;
  margin-left: 5px;
  font-size: 12px;
  line-height: 16px;
  background: #f4f8fe;
  text-align: left;
  max-width: 150px;
  min-width: 150px;

  /* border: 1px solid #bbcada; */
  border-radius: 5px;
  padding: 10px;
  color: #8ea7c5;
  margin-left: 15px;
}
.salariedToggleRow:hover {
  background-color: #edf3f9;
}
.salariedToggleRowFirst {
  /* margin-top: -1px; */
  border-bottom: 1px solid #e3e8ee;
  /* padding-bottom: 2px; */
}
.salariedToggleRow:last-child {
  margin-top: -1px;
  border-top: 1px solid #e3e8ee;
  padding-bottom: 2px;
}
.salariedKnob1 {
  margin-top: -5px;
}
.salariedKnob {
  margin-top: -3px;
}
.salariedToggleTitle {
  color: #bac5d3;
  min-width: 68px;
  text-align: left;
  padding-left: 8px;
  font-size: 14px;
}

.salariedSel {
  color: #143051;
}

.salariedRowTop {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  /* background-color: blue; */
}

.wPattTopRow {
  /* background-color: blue; */
}

.workingPattInlineTxt {
  margin-right: 8px;
}

.salariedRowHeading {
  margin-top: 2px;
  color: #214978;
  text-align: left;
  font-size: 12px;
  margin-left: 5%;
  font-weight: 500;
  margin-top: 22px;
}

.salariedRowLower {
  /* background-color: blue; */
  min-height: 70px;
  min-width: 100%;
  max-height: 70px;
  /* margin-top: 20px; */
  display: flex;
  /* justify-content: space-between; */
  justify-content: flex-start;
}

.salariedInputCol {
  min-width: 110px;
  margin-left: 13px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-left: 1px solid #ddebf4;
  padding-left: 7px;
}

@media only screen and (max-width: 380px) {
  .salariedInputCol {
    min-width: 100px;

    margin-left: 10px;
    padding-left: 0px;
    border: none;
  }
}

.salariedToggleAndInputLeftWrap {
  display: flex;
  /* min-width: 75%; */
}

.hourlyRateValueSaved {
  margin-top: 4px;
  margin-bottom: -1px;
  font-size: 16px;
  color: #143051;
  font-weight: 500;
  text-align: left;
  margin-left: 5%;
  min-height: 40px;
  overflow-wrap: break-word;
  padding-top: 6px;

  /* font-size: 18px; */
  /* color: #8997a8; */
  margin-top: 4px;
  margin-left: 4px;
}

.salariedEditSaveCol {
  min-width: 22%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  /* padding-right:8px; */
  padding-top: 12px;
}

.annualSalarySpan {
  font-size: 12px;
}

.hrSalRow {
  display: flex;
  justify-content: flex-start;
  margin-top: 3px;
  cursor: pointer;
}

.hrSalRow:first-child {
  border-bottom: 1px solid #dde9f9;
  padding-bottom: 3px;
}

.hrlySalTickBox {
  background-color: #6a8bb1;
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  cursor: pointer;
  opacity: 0.8;
  border: 1px solid #516b8b;
}

.hrlySalTickBox:hover {
  opacity: 1;
}
.perHourOrSalaryDiv {
  margin-top: 4px;
  margin-left: 5px;
}

.hrlySalTickBoxImg {
}

.nonWorkingDaysDiv {
  /* background-color: #edf2f9; */
  min-height: 120px;
  border-top: 1px solid #ddebf4;
  padding-top: 25px;
  border-bottom:1px solid #ddebf4;
  margin-bottom:10px;
}

.userNonWorkingDayDiv {
  display: flex;
  justify-content: flex-start;
  /* padding-left: 3%; */
  /* padding-right: 5%; */
  margin-top: 23px;
  /* padding-bottom: 30px; */
  border: 1px solid #cbd7e6;
  border-radius: 5px;
  max-width: 90%;
  min-width: 90%;

  margin-left: 4%;
  margin-bottom: 20px;
}

.userNonWorkingDayUnit {
  min-width: 14.31%;
  max-width: 14.31%;

  border-right: 1px solid #cbd7e6;
  cursor: pointer;
  padding-right: 1px;
  background-color: white;
}

@media only screen and (min-width: 821px) {
  .userNonWorkingDayUnit:hover {
    background-color: #f4f8fe;
  }
}

.userNonWorkingDayUnit:last-child {
  border: none;
}

.userNonWorkDayTitle {
  color: #143051;
  min-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
  background-color: #f4f8fe;
  padding-bottom: 2px;
  font-weight: 500;
  font-size: 12px;
}

.usernonWorkKnob {
  margin-right: 0px;
  border-top: 1px solid #dde9f9;
  display: flex;
  justify-content: center;
  padding-bottom: 12px;
  max-height: 52px;
}

.unselectedHpwAvg {
  color: #7f90a4;
}

.addOrDeductContent {
  padding-left: 10px;
}

.noteShiftTagsDiv {
  display: flex;
  flex-wrap: wrap;
  padding-left: 15px;
  padding-bottom: 8px;
}

.editUserMessageBtn {
  background-color: #0077ff;
  border: 1px solid #0469de;
  border-radius: 5px;
  min-height: 35px;
  max-height: 35px;
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 8px;
  cursor: pointer;
  font-size: 14px;
}

.editUserMessageBtn:hover {
  background-color: #0469de;
}

.editUserMsgChatImg {
  max-width: 15px;
  margin-right: 6px;
}

.unarchiveImg {
  max-width: 15px;
  margin-left: -1px;
  margin-right: 7px;
}

.editUserWPattLoadingDiv {
  /* background-color: blue; */
  min-height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wPattLoadingThreeDotsImg {
  max-width: 50px;
  margin-bottom: 50px;
}

.savedFv {
  color: #bbcada;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-left: 44px;
  font-size: 12px;
}

.savedFvTickImg {
  margin-left: 7px;
  display: none;
}

.loadingSalGif {
  max-width: 40px;
  margin-top: 17px;
  margin-left: 5px;
}

.dayNameAndPowerRowLeft {
  min-width: 130px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
}

.mobFixedSchedTitle {
  font-size: 15px !important;
}

.nonWorkableDaysExplain {
  text-align: left;
  margin-left: 5%;
  font-size: 12px;
  line-height: 16px;
  margin-top: 22px;
  margin-bottom: 24px;
  color: #516b8b;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-weight: 400;
  padding-right: 20px;
  padding-left: 10px;
  border-left: 2px solid #516b8b;
}

.allowBorderLeftActive {
  border-left: 3px #00aaff solid;
}

.defaultPointer {
  cursor: default;
}

.fixedVarOrZeroMobMpwTimelineBtnDesktop {
  margin-top: -197px !important;
}

.unselAnnLeaveOrCustom {
  border-bottom: 1px solid #f4f8fe;
}
.unselAnnLeaveOrCustom:hover {
  color: #516b8b;
  border-bottom: 1px solid #e3e8ee;
}

.saveFxWidth {
  min-width: 40%;
}

.payClockTimeOnlyTitle {
  margin-top: 40px;
}

.payClockTimeOnlyDiv {
  /* background-color: orange; */
}

.removeUsrBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 68px;
  padding-left: 7px;
  padding-right: 9px;
  min-height: 35px;
  max-height: 35px;
  color: #fff;
  background-color: #859dbb;
  border: #516b8b 1px solid;
  border-radius: 5px;
  cursor: pointer;
}

.removeUsrBtn:hover {
  background-color: #6e86a3;
}

.mobileHourlyRateTimeline {
  min-width: 100vw;
  position: fixed;
  bottom: 0px;
  border-radius: 15px 15px 0 0;
}

.clockTitleSize {
  font-size: 16px !important;
}

.cancelEditNameJt {
  cursor: pointer;
  border: #e3e8ee solid 2px;
  padding: 6px;
  border-radius: 5px;
  max-width: 28px;
  margin-right: 10px;
  /* margin-bottom: 10px; */
  /* margin-left:30px */
}

.cancelEditNameJt:hover {
  border-color: #b2bbc7;
}

.closeEditMyShiftModalTxt {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  max-width: 66px;
  min-width: 66px;
}

.closeEditMyShiftModalTxt:hover {
  background-color: #214978;
}

.editUserClosedDaysTimelineBtnHolder {
  /* background-color: yellow; */
  padding-right: 5%;
  margin-top: -104px;
  margin-bottom: 48px;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
}

.editUserClosedDaysTimelineBtn {
  background-color: #0af;
  color: white;
  cursor: pointer;
  border: 1px solid #07f;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  max-height: 35px;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 15px;
}

.editUserClosedDaysTimelineBtn:hover {
  background-color: #0fa2eb;
}

.removeUserInfoTxt {
  font-weight: 400;
  color: #bbcada;
  font-size: 14px;
  line-height: 16px;
  margin-top: 12px;
}

.alignLeft {
  text-align: left !important;
}

.canxRemoveUsrBtn {
  text-align: center;
  min-width: 50px;
  color: #bbcada;
  font-size: 14px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 80px !important;
  margin: 15px 5px -5px;
  cursor: pointer;
}

.noHistAvail {
  font-weight: 500;
  text-align: center;
  margin-top: 29px;
  color: #bbcada;
  font-size: 14px;
}

.unarchBtn {
  background-color: #0bf;
  border: 1px solid #0af;
}

.unarchBtn:hover {
  background-color: #0af !important;
}

.removeBtnsSize {
  padding-left: 4px !important;
  padding-right: 4px !important;
  width: 32% !important;
}

.removeBtnsSize2 {
  padding-left: 4px !important;
  padding-right: 4px !important;
  width: 32% !important;
  margin-left: -6%;
}

.smallEditUserTitle {
  font-size: 14px;
}

.editUserRow2 {
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 3%;
  border-bottom: 1px solid #ddebf4;
}

.editUserPicDiv {
  min-width: 90px;
  max-width: 90px;
}
.editUserRowScores {
  /* background-color: pink; */
  min-width: calc(100% - 90px);
}

.fontSize14 {
  font-size: 14px !important;
  line-height: 14px;
}

.editUserRow2Left {
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  min-width: -webkit-fit-content;

  min-width: -moz-fit-content;

  min-width: fit-content;
}

.editUserRow2Right {
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.editUserRowTitle2 {
  font-size: 12px;
  color: #214978;
  font-weight: 400;
  /* margin-left: 4px; */
  margin-bottom: 2px;
  margin-left: 5px;
}

.x2389292111 {
  margin-top: 18px;
}
.editUserRowValue2 {
  font-weight: 500;
  color: #143051;
  min-height: 35px;
  padding-top: 5px;
  text-align: left;
  margin-left: 5px;
}

.saveEditUserBlock {
  display: flex;
  align-items: center;
  margin-right: 7px;
}

.toggleUserNameInputs {
  display: flex;
}

.editUserValInput {
  color: #6a8bb1;
  min-height: 35px;
  padding-left: 9px;
  font-weight: 500;
  /* margin-left: 5%; */
  min-width: 90%;
  /* margin-top: 5px; */
  /* margin-bottom: 3px; */
  font-size: 15px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 7px;
  background-color: #fdfeff;
}

.editUserValInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 7px;
  background-color: #fdfeff;
}

.editUserNameInputSize {
  min-width: 120px;
  max-width: 120px;
}

.editUserNameInputSize2 {
  min-width: 120px;
  max-width: 120px;
  margin-left: 8px;
}

.editUserValInput::-webkit-input-placeholder {
  color: #bbcada;
}

.editUserValInput:-ms-input-placeholder {
  color: #bbcada;
}

.editUserValInput::placeholder {
  color: #bbcada;
}

.nameInputsUnder440 {
  display: flex;
  flex-direction: column;
}

.editUserRow2NameUnder440 {
  min-height: 130px;
}

.editUserNameInputSize2Under440 {
  margin-left: 0px;
  margin-top: 5px;
  min-width: 90%;
  max-width: 90%;
}

.editUserNameInputSizeUnder440 {
  min-width: 90%;
  max-width: 90%;
  margin-top: 5px;
}

.editJtSize {
  min-width: 240px;
  max-width: 240px;
}

.editJtSizeUnder440 {
  min-width: 184px;
  max-width: 184px;
}

.partOfTeamsDiv2 {
  margin-left: 0px;
  min-width: 310px;
  max-width: 310px;

  margin-top: 0px;
  margin-bottom: 10px;
  /* background: yellow; */
}

.editUserTeamUnitName {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  white-space: nowrap !important;
  text-align: left;
}

.partOfPrim {
  font-size: 10px;
  min-width: 3px;
}

.partOfPrim2 {
  margin-left: 5px;
  margin-right: 3px;
  background: #516b8b;
  color: white;
  border-radius: 3px;
  padding: 3px;
}

.mgrOfInline {
  text-align: left;
  font-size: 12px;
  padding-right: 50px;
  margin-top: 12px;
  margin-bottom: 20px;
  font-weight: 500;
  color: #8ea7c5;
  max-width: 350px;
}

.editUserRow2MgrOf {
  font-size: 12px;
  color: #214978;
  border-left: 2px solid #214978;
  padding-left: 7px;
  margin-top: 10px;
  font-weight: 400;
}

.x0101011aa {
  /* white-space: nowrap !important; */
}

.mgrOfInlineMob {
  max-width: 300px;
}

.editUserRow2LeftUnder440 {
  max-width: 250px !important;
  min-width: 250px !important;
}

.fixedSchedInputTable {
  /* background-color: yellow; */
  min-width: 400px;
  max-width: 400px;

  margin-bottom: 50px;
}
.fixedSchedInputTableMobModal {
  min-width: 83vw;
  max-width: 83vw;
}
.fsTableTitleRow {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  margin-bottom: 4px !important;
  /* opacity: 0.7; */
  /* min-width: 300px; */
  /* background-color: orange; */
}

.fsTitleTxt {
  font-size: 12px;
  font-weight: 400;
  max-width: 20%;
  min-width: 20%;
  text-align: left;
  color: #143051;
  margin-left: 5px;
  /* min-width: 60px; */
}

.fsTitleTxt:first-child {
  min-width: 23%;
  max-width: 23%;
  margin-left: 0px;
}

.fsTitleTxtStart {
  margin-left: 4px;
}

.fsTitleTxtStartMobModal {
  min-width: 24%;
  max-width: 24%;
  margin-left: 0px;
}
.fsTitleTxtEnd {
  margin-left: 2px;
}

.fsTitleTxtEndMobModal {
  min-width: 24%;
  max-width: 24%;
}
.fsTitleTxtEndBreak {
  margin-left: 0px;
}

.fsTitleTxtEndBreakMobModal {
  min-width: 24%;
  max-width: 24%;
}

.fsTitleTxtDayMob {
  min-width: 58px !important;
  max-width: 58px !important;
}

.fsTableDataRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3px;
  border-top: 1px solid #e3e8ee;
  padding-top: 3px;
}

.fsTableRow1 {
  /* border-top:none */
}
.fsTableRow1 {
  /* border:none;
      padding-top:0px; */
}

.fsTableDayTxt {
  max-width: 23%;
  min-width: 23%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}

.fsTableDayTxtMob {
  min-width: 58px !important;
  max-width: 58px !important;
}

.fsTitleTxtEndWorkable {
  margin-left: -6px;
  visibility: hidden;
}
.fsTimeInput {
  color: #6a8bb1;
  min-height: 35px;
  font-weight: 500;
  min-width: 70px;
  display: flex;
  justify-content: center;
  max-width: 70px;
  font-size: 15px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  cursor: text;
  /* padding-left: 7px; */
  background-color: #fdfeff;
  /* -webkit-appearance: none; */
  /* appearance: none; */
}

.fsTitleTxtEndWorkableMobModal {
  min-width: calc(28% - 58px);
  max-width: calc(28% - 58px);
}

.fsTimeInputMobModal {
  min-width: 74px;
  padding-left: 2px;
}

.fsTableTimeInputWrap {
  /* background-color: blue; */
  /* margin-left:-2px; */
  max-width: 20%;
  min-width: 20%;
}

.fsTableTimeInputWrapMobModal {
  max-width: 24%;
  min-width: 24%;
}

.fsBrkInput {
  color: #6a8bb1;
  min-height: 35px;
  font-weight: 500;
  min-width: 62px;
  display: flex;
  max-width: 62px;
  font-size: 15px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  text-align: center;
  background-color: #fdfeff;
}

.fsDayPower {
  min-width: 17%;
  max-width: 17%;
  display: flex;
  justify-content: flex-start;
  /* background-color: black; */
}

.fsDayPowerMobModal {
  min-width: calc(28% - 58px);
  max-width: calc(28% - 58px);
}

.fsDayPowerImg {
  max-width: 20px;
  background-color: #fff;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding: 3px;
  min-width: 25px;
  min-height: 25px;
  cursor: pointer;
}

.unworkableFs {
  opacity: 0.4;
}

.unworkFsDay {
  opacity: 0.4;
}

.x23o3io311 {
  max-height: calc(100% - 141px);
  min-height: calc(100% - 141px);
}

.saveFsRow {
  min-height: 35px;
  display: flex;
  min-width: 100%;
  justify-content: space-between;
}

.saveFsBtn {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  background-color: #0bf;
  border: 1px solid #0af;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 15px;
  margin-right: 20px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
}

.fsHoursQty {
  color: #8ea7c5;
  border-radius: 5px;
  background-color: #fff;
  /* margin-right: 5%; */
  font-weight: 500;
  text-align: center;
  padding-left: 8px;
  padding-right: 8px;
  min-height: 100%;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid #e0ebf8;
  max-height: 35px;
  display: flex;
  margin-top: 15px;
  align-items: center;
}

.fsSavedTxt {
  color: #bbcada;
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-right: 33px;
  min-height: 35px;
  margin-top: 15px;
  font-size: 14px;
}

.primInlineTitle {
  font-size: 10px;
  text-align: left;
  color: #7790a5;
  margin-bottom: -2px;
}

.teamMgrColor {
  background-color: #eee9ef;
}

.x893298292 {
  /* margin-top: 25px;
  padding-bottom: 4px !important;
  margin-bottom: 30px; */

      /* margin-top: 25px; */
    /* padding-bottom: 4px !important; */
    /* margin-bottom: 30px; */
    padding-top: 0px;
    max-height: 60px;
}

.tMgrAdminSpan {
  color: white;
  background: #bbcada;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  font-size: 11px;
  margin-left: 4px;
  padding-bottom: 2px;
  padding-top: 2px;
}

.adjAllowInfo {
  text-align: right;
  color: #516b8b;
  font-size: 11px;
  max-width: 132px;
  float: right;
  font-weight: 400;
}
.adjAllowRow {
  display: flex;
  flex-direction: column;
  float: right;
  border: 1px solid #e0ebf8;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 15px;
  background: #fcfcfc;
  padding-left: 10px;
  border-radius: 5px;
}

.adjAllowInfo2 {
  margin-top: 8px;
}

.manAdjSpan {
  color: #214978;
  font-size: 13px;
  line-height: 22px;
  font-weight:500
}

.allHoursRecordTxt {
  color: #516b8b;
  font-size: 12px;
  line-height: 16px;
  border-left: 2px solid #143051;
  padding-left: 20px;
  padding-right: 30px;
  max-width: 80%;
  text-align: left;
  margin-left: 20px;
  margin-top: 20px;
}

.annLeavePercSpanSub {
  font-size: 11px;
  margin-left: 0px;
  margin-right: 12px;
  font-weight: 500;
  margin-top: 3px;
  color: #859dbb;
  border-radius: 5px;
}


.annLeaveMode1Sub {
  min-height:102px;
  border-bottom:1px solid #e3e8ee
}

.annLeaveMode1subHpw {
  font-size: 12px;
  color: #143051;
  font-weight: 500;

}

.x10109192221 {
  border-bottom:1px solid #e3e8ee !important
}

.annLeaveMode1SubRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left:23px;
  color:#bac5d3;
  font-weight: 500;
  font-size:12px;
  min-height:40px;
    border-bottom:1px solid #e3e8ee;
    cursor: pointer;

}

.annLeaveMode1SubRow2 {
  /* border-bottom:2px solid #bbcada */
  border:none
}

.annLeaveMode1SubWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
  font-size: 10px;
  /* color:#8a9db4; */
  color: #8ea7c5;
  font-weight: 400;
}

.subRowDeskto:hover {
  background-color: #edf3f9;
}

.subKnob {
  /* background-color: yellow !important; */
  /* max-width: 14px !important; */
  /* max-height: 14px !important; */
  /* margin-top:-4px !important; */

}

.subKnobOn {
  background-color: #89b6eb !important;
  /* top: 3px !important; */
  /* border-color: #2d619e !important */
}

  .subKnobOff {
    /* background-color: #00ccff !important; */
    /* top: 3px !important; */
    /* left: 3px; */
  }
.knobBgSub {
  /* max-height:14px !important; */
  /* max-width: 23px; */
}

.x2982911112f {
  margin-bottom: 10px;
  margin-top: 8px;}

  .editUserCustomScrollBox {
    display: block;
    max-width: 100%;
  }

  .customArrItem {
    border-bottom: 2px solid #ddebf4;
    /* border-radius: 5px; */
    /* padding-left: 10px; */
    margin-top: 18px;
    /* padding-right: 10px; */
    padding-top: 0px;
    max-width: 98%;
    margin-left: 1%;
  }

  .customArrItem:last-child {
    border-bottom:none
  }

  .customArrTitleRow {
    text-align: left;
    font-weight:500;
    font-size:14px;
  }

  .customItemArr {
    margin-bottom: 10px;
    color: #214978;
    margin-left: 7px;
    margin-top: 10px;
    font-size: 16px;
    /* border-bottom: 1px solid #ddebf4; */
    max-width: -moz-fit-content;
    max-width: -webkit-fit-content;
    max-width: fit-content;
    padding-left: 5px;
    padding-left: 8px;
    padding-right: 2px;
    border-left: 3px solid #214978;
  
  }


  .customDaysorHourSelect {
    color: #496375;
    border-radius: 0px;
    margin-top: 5px;
    font-size: 12px;
    font-weight: 500;
    padding-left: 10px;
    text-align: left;
    max-width: 69px;
    min-width: 69px;
    line-height: 28px;
    height: 30px;
    background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
    border-left: 1px solid #dde9f9;
    -webkit-appearance: none;
    background-position-x: 57px;
    cursor: pointer;
    margin-right: 3px;
  }

  .customAllowAmendRow {
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
    align-items: flex-end;
    padding-bottom: 5px;
    margin-top: 8px;
  }

  .customPlus {
    border-radius: 5px;
    max-width: 20px;
    min-width: 20px;
    min-height:20px;
    max-height:20px;
    padding: 5px;
    background: #214978;
    border: 1px solid #143051;
    cursor: pointer;
    margin-left:8px
    
  }


  .customPlusHrMinDiv {
    display: flex;
    align-items: center;
  }



  .customPlusHr {
    border-radius: 5px;
    max-width: 23px;
    min-width: 23px;
    min-height: 20px;
    max-height: 20px;
    padding: 13px;
    background: #214978;
    border: 1px solid #143051;
    cursor: pointer;
    margin-left: 8px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    
  }



  .customPlusHr2 {
    /* margin-left:  8px */
  }

  .customMinusHr2 {
    /* margin-right:  8px */
  }

  .customMinus {
    border-radius: 5px;
    max-width: 20px;
    min-width: 20px;
    min-height:20px;
    max-height:20px;
    padding: 5px;
    background: #6d84a2;
    border: 1px solid #143051;
    cursor: pointer;
    margin-right:8px;
    /* opacity: 0.7; */
    
  }



  .customMinusColour {
    background: #6d84a2;
    border: 1px solid #143051;
  }

  @media only screen and (min-width: 820px) {
.checkListShowMe:hover {
  opacity: 0.8;
}
  
    .allowanceAdditionsContainer:hover {
      background-color: #f0f4fa;
    }
  .customMinusColour:hover {
    background-color:#647994 ;

  }


  .customMinus:hover {
    background-color:#647994 ;
  }

  .customPlusHr:hover {
    background-color:#143051 ;
  }


  .customPlus:hover {
    background-color:#143051 ;
  }

}
  .customUnlimToggle {

    display: flex;
    align-items: center;
    min-height: 26px;
    color: #bbcada;
    margin-left: 0px;
    font-size: 12px;
    margin-top: -11px;
    margin-right: 7px;
    cursor: pointer;

  }

  .customUnlimCheckedTrue {
    margin-top: -3px;

  }

  .customUnlimToggle:hover {
    color:#7f96ae
  }

  .customUnlimCheck {
    max-width: 20px;
    min-width: 20px;
    min-height:20px;
    max-height:20px;
    background-color: white;
    border-radius:3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left:4px;
    border: 1px solid #ddebf4;
    margin-right:4px;
    margin-left:7px;

  }

  .customCheckUnlimImg {
    max-width: 17px;
    padding:2px;

  }

  .customAddPlusDiv {
    display: flex;
    margin-top: 2px;
    margin-bottom: 6px;
    margin-right: 11px;
  }
  .fadeAllowHpw {
    opacity: 0.4;
  }

  .allowsPholsRow {
    font-size: 12px;
    color: #516b8b;
    font-weight: 500;
    margin-right: 3px;
    margin-top: 0px;
    margin-bottom: 1px;
    border-top: 1px solid #ddebf4;
    padding-top: 8px;
    padding-right: 8px;
    padding-left: 10px;  }

    .pHolQtyAllows {
      color: #0077ff
    }

    .amendCustomHoursRow {
      display: flex;
      align-items: center;
      padding-right: 10px;
      margin-top: -2px;
      min-width: 100%;
      border-bottom: 1px solid #ddebf4;
      padding-bottom: 10px;
      justify-content: flex-end;
    }

    .tilSettingsRow {
      /* background-color: blue; */
      flex-direction: column;
    }

    .tilRowInline {
      display: flex;

    }

    .x198292ss {
      max-width: 60% !important;
      min-width: 60% !important;    }


.allowancesTilUserRow {
  display: flex !important;
  flex-direction: row !important;
  padding-right: 30px;
  border-bottom: 1px solid #ddebf4;
  padding-bottom:40px;
  margin-bottom:19px;
  margin-top:25px !important;

}

.x101081812d {
  margin-top:4px;
}

.editUserDataDateRow {
  min-height: 52px;
  /* background-color: #5c7ba9; */
  padding-top: 4px;
  min-width: 100%;
  /* padding-left: 15px; */
  /* padding-right: 15px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 1px solid #ddebf4; */
  padding-bottom: 10px;

}

.editUserDataDateLeft {
  display: flex;
  /* background-color: blue; */
}

.editUserDataDateBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.editUserDataDateBlock2 {
  margin-left:8px
}

.editUserDataDateTitle {
  font-size:10px;
  color:#214978;
  font-weight:500;
  margin-left:7px
}

.editUserDataDateInput {
  color: #6a8bb1;
  min-height: 30px;
  /* padding-left: 9px; */
  font-weight: 500;
  /* margin-left: 5%; */
  /* min-width: 100px; */
  /* margin-top: 5px; */
  /* margin-bottom: 3px; */
  font-size: 12px;
  border: 2px solid #e3e8ee;
  border-radius: 5px;
  padding-left: 5px;
  background-color: #fdfeff;
  margin-top:2px;
  cursor: text;
  max-width: 110px;
}

.editUserDataDrop {
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  padding-left: 8px;
  text-align: left !important;
  max-width: 85px;
  min-width: 85px;
  line-height: 26px;
  height: 30px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #214978;
  border: 2px solid #e3e8ee;
  -webkit-appearance: none;
  background-position-x: 64px;
  display: flex;
  cursor: pointer;
  color: #516b8b;
  background-color: white;
  margin-top:2px;
  /* border: 2px solid #c6d5e4; */
}

@media only screen and (min-width: 820px) {


.editUserDataDateInput:hover {
  border-color:#c5d1e1
}



.editUserDataDrop:hover {
  border-color:#c5d1e1
}

}
.allowancesTopDiv2 {
  /* background-color: ; */
}

.x99281 {
  border-top: 2px solid #ddebf4;
  border-radius:0px;
  background-color: #f4f8fe;
  overflow:hidden;

}

.editUserDataMain {
  background-color: #fff;
  border-top: solid 2px #eaf0f5;
  overflow-y: scroll;
  border-radius: 5px 5px 0px 0px;
  max-height: calc(100% - 151px);
  min-height: calc(100% - 151px);
  padding-bottom: 90px;

}



.editUserDataDateRowTop {
  display: flex;
  flex-direction: column;
  /* align-itemsspace- : flex-start; */
}

.editUserDataDateRowBottom {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  min-height:26px;
}

.dataTypeSelectDiv {
  display: flex;
  align-items: center;
}

.dataTypeTxt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  background: #214978;
  border: 1px solid #143051;
  border-radius: 4px;
  height: 20px;
  line-height: 20px;
  color: white;
  margin-right:4px;
  padding-left: 3px;
  padding-right: 3px;
  cursor: pointer;
}

@media only screen and (min-width: 820px) {
  .dataTypeTxt:hover  {
  background-color: #143051;
  }
}


.dataTypeCheckImg { 
  width: 10px;
  margin-left: 4px;
}

.unselectedDataType {
  opacity: 0.1;
}

.unselDataTypeTxt {
  background-color: #8ea7c5;
  border-color: #758da9;}


@media only screen and (min-width: 820px) {
  .unselDataTypeTxt:hover  {
    background-color: #758da9;
  }

  .dataTabUserShift:hover {
    background-color: #f6faff;
  }
}

.editUserDataBox {
  border: 1px solid #ddebf4;
  /* min-height: 70px; */
  margin-bottom: 7px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  background-color: #f9fbff;
}

.editUserDataBoxRow {
  border-bottom: 1px solid #ddebf4;
  font-size:11px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  padding-left:10px;
  padding-right:10px;
  min-height:19px;
  display: flex;
  align-items: center;
}

.editUserDataBoxRowTitle {
color:#143051
}

.editUserDataBoxRowValue {
margin-left:20px;
color:#516b8b
}

.dataTypeSelectUpper {
  /* background-color: blue; */
  padding-bottom:6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top:-8px; */
}

.dataTabUserShift {
  border-bottom: 1px solid #bbcada;
  width: 94%;
  margin-left: 3%;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
}

.dataTabUserShiftTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dataTabUserShiftBottom {
  min-height: 16px;
  padding-bottom: 4px;
  min-width: 100%;
  padding-left: 76px;
  display: flex;
  align-items: center;
  margin-top: -4px;
}
.dataTabUserShiftBottomReq {
  padding-left:82px;
}


.dataTabBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top:5px;
  padding-bottom:5px;
}

.dataTabBlockTitle {
  font-size:10px;
  font-weight: 400;
  color:#8ea7c5;
}

.dataTabBlockValue {
  color: #214978;
  font-weight: 600;
  color: #516b8b;
  font-size: 10px;
  display: flex;
  align-items: center;
  /* white-space: nowrap; */
  max-width: 62px;
  /* background: blue; */
  flex-wrap: wrap;
  text-align: left;
  line-height: 12px;
  margin-top: 3px;
}

.dataTabBlockValueTimes {
  font-weight: 600;
  color:#516b8b;
  font-size:10px;
  display: flex;
  align-items: center;
  text-align: left;
}

.dataTabUserShiftLeft {
  display: flex;
  align-items: center;
  min-width: 80%;
}

.dataTabUserShiftRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 20%;

}

.textAlignRight {
  text-align: right !important;
  display: flex;
  justify-content: flex-end;
}

.flexEnd2 {
  align-items: flex-end;
}

.dataTabClockIcon {
  width:9px;
  margin-right:4px;
  opacity: 0.75;

}

.dataTabTimesBlock {
  margin-left:20px;
  font-size:10px !important;
  min-width: 86px;

}

.dataTabBlockFirst {
  min-width: 62px;
}

.dataTabDurMins {
  margin-left:30px;
}

.dataTabBlockValueDurMins {
  font-size:10px
}


.tabRow {
  /* min-width: calc(100% - 62px); */
  display: flex;
  align-items: center;
}

.tabLate {
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  border-radius: 3px;
  background-color: #bb5d5d;
  padding-left: 3px;
  padding-right: 3px;
  margin-left:5px;
}

.sameDataPushDown {
  margin-top:15px
}


.tabDays {
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  border-radius: 3px;
  background-color: #516b8b;
  padding-left: 3px;
  padding-right: 3px;
  margin-bottom: 2px;
}

.tabDaysLeave {
  background-color: #68b7df;
}

.dataTabBlockValueTimesTimes {
color:#b0bfcb
}

.downloadCsvImg {
  max-width: 14px;
  margin-left: 5px;
}

.excessAllowRow {
  margin-top:40px;
  border-top:1px solid #ddebf4;
  padding-bottom:30px;
}

.x19834981 {
  margin-top:10px  !important;
}

.editUserOtPayReqsInfoTxt {
  color: #516b8b;
  text-align: left;
  font-size:12px;
  line-height:16px;
  padding-right:20px;
  margin-left:15px;
  margin-bottom:20px;
  margin-top:10px;
}

.x9191k {
  font-weight:500;
  margin-left:15px  !important
}


.askAdminToManageTeam {
  color: #bbcada;
  text-align: left;
  font-weight: 500;
  margin-left: 21px;
  max-width: 180px;
  font-size: 12px;
  margin-top: 15px;
  margin-bottom: 30px;
  border-left: 2px solid #bbcada;
  padding-left: 10px;
}

.askAdminToManageTeamViewTeam {
  color: #bbcada;
  text-align: left;
  font-weight: 500;
  margin-left: 1px;
  max-width: 180px;
  font-size: 12px;
  margin-top: 0px;
}

.nonAdminPteam {
  color: #214978;
  text-align: left;
  font-weight: 500;
  margin-left: 21px;
  max-width: 180px;
  font-size: 17px;
  margin-top: 0px;
  margin-bottom: 10px;
}

.dataTabBlockTeamName {
  color: #bbcada;
  font-size: 8px;
  text-align: left;
  max-width: 60px;
  font-weight: 500;
  margin-top: 2px;}


  .archivedEditUser {
    background-color:#f1f1f1
  }

  .removedProPic {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);

  }

  .editUsrArchivedSubTxt {
    font-size: 12px;
    color: #bbcada;
    border-bottom: 1px solid #ddebf4;
    min-height: 50px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    line-height: 50px;
    background: #fafafa;
  }

  .removedUserPaddingLeftRight {
    padding-left:10px;
    padding-right:10px;
  }

  .greyScore {
    background-color: #bbcada;
  }

  .greyScore:hover {
    background-color: #bbcada !important;
  }

  .allowsDailyMinsVal {
    font-size:14px;
    color:#516b8b;
    margin-right:12px;
    margin-top:8px;
    font-weight: 400;
  }

  .contractedHPDrow {
    border-top:1px solid #ddebf4;
    border-bottom:none;
    margin-top:30px;
    min-height:60px
  }

  .avgBase {
    font-size: 9px;
    padding-left: 20px;
    border-right: 2px solid #d9eaff;
    padding-right: 6px;
    margin-top: 5px;
    margin-bottom: 4px;
  }


.colour00ccff {
  color: #00ccff !important
}

.pullUp36px {
  margin-top: -48px !important;
  min-height: 71px !important;}


  .convBtnMob {
    margin-top:20px;
  }

  .allLeaveDashControllerMobClosed {
    margin-top: 70px !important;  }


    .x88392727272 {
margin-top:80px !important    }



.editUserCheckListBtn {
  min-width: 102px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  background-color: #e78df7;
  border: 1px solid #9a7ba0;
  padding-left: 7px;
  padding-right: 6px;
  cursor: pointer;
  min-height: 30px;
  display: flex;
  align-items: center;
  color: #fff;
  max-height: 30px;
  margin-top: 8px;
  margin-right: 10px;
  margin-left: -60px;
}

.editUserCheckListBtnMob {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content
}
.checkListActive {
  background: none;
  color: #9a7ba0;
  border: 1px solid #f4f8fe;
  cursor: default;
}


@media only screen and (min-width: 820px) {
  .editUserCheckListBtn:hover {
    background-color: #d07ae0;

  }

  .checkListActive:hover {
    background: none;

  }
}
.todoCheckImg {
  max-width: 14px;
  margin-right: 8px;}


  .userCheckListModal {
    background-color: #f4f8fe;
    min-width: 290px;
    position: fixed;
    right: calc(50vw - 400px);
    border-radius: 10px;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    cursor: default;
    padding-bottom: 15px;
  }



  .userCheckListModalMob {
    right: auto;

  }

  .userCheckListModalLarge {
    right: calc(50vw - 450px);

  }
  .userCheckListHeader {
    display: flex;
    align-items: center;
    min-width: 100%;
    justify-content: space-between;
    min-height: 50px;
    padding-left: 16px;
    padding-right: 15px;
  }

  .closeCheckListImg {
    max-width: 14px;
    cursor: pointer;
  }

  .userCheckListTitle {
    color:#143051;
    font-size: 16px;
    font-weight: 500;
  }

  .completeAllTick {
    max-width: 14px;
    margin-left:8px;
  }

  .completeCheckBtnAll {
    max-width: 90%;
    min-width: 90%;
    margin-left:5%;
    background-color: #0bf;
    border: 1px solid #0af;
  }

  .completeCheckBtnAll:hover {
    background-color: #0af;
  }

  .editUserModalWithCheckList {
    margin-left:-300px;
  }

  .checkListItem {
    border: 1px solid #dde9f9;
    background: white;
    max-width: 90%;
    margin-left: 5%;
    border-radius: 5px;
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom:6px;
    cursor: pointer;
  }

  .checkListItemNum {
    font-size: 14px;
    font-weight: 500;
    color: #0af;
    margin-top: -15px;
   }

  .checkListTxtAndShowMe {
    display: flex;
    flex-direction: column;
    /* background: blue; */
    min-width: 170px;
    align-items: flex-start;
  }

  .checkListItemTitle {
    font-size: 14px;
    font-weight: 500;
    color: #214978; 
  }

  .checkListShowMe {
    font-size: 14px;
    font-weight: 500;
    margin-top: 1px;
    color: #859dbb;
  cursor: pointer;
  }

  .checkListDoItem{
    margin-right: 2px;
    border: 1px solid #dde9f9;
    min-width: 35px;
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    }

  .checkInfoTxt {
    color: #859dbb;
    font-size: 13px;
    max-width: 250px;
    text-align: left;
    margin-left: 21px;
    margin-bottom: 16px;
    font-weight: 400;
    margin-top: 3px; }

    .completedCheckListTickImg {
      max-width: 14px;
      cursor: default;
    }

    .strikeThrough {
      text-decoration: line-through;
cursor: default;
    }



    .userCheckListModalUnderlay {
      background:none ;
      display: flex;
      align-items: center;
    }

    .userCheckListModalUnderlayMob {
      background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  cursor: pointer;
  z-index: 99999999;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
    }


    .showMeRow {
      border: 3px solid #00aaff !important; 
      border-radius: 7px;
      /* margin-top: -5px; */
      background: white;
      padding-left: calc(5% - 3px);
      padding-right: calc(5% - 3px);
      margin-bottom: 5px;
      padding-top: 17px;  }

      .showMeRowFv {
        border-radius: 7px 7px 0 0;
        border-bottom:none !important
      }

      .fvContainer {
/* background-color: blue; */
      }

      .fvContainerShowMe {
        border: 3px solid #00aaff;
        border-radius: 0 0 7px 7px;
        border-top: none;
        margin-top: -5px;
      }

      .editUserAllowsBox {
        padding-bottom: 30px;
        margin-bottom: -29px;      }




.accBtnSel {
  font-weight: 500;
  color: white;
  cursor: pointer;
}

.innerd {
  height: 100%;
  /* background-color: blue; */
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 5px;
}

.accountNavBtn {
  margin-top: 12px;
  font-size: 13px;
  cursor: pointer;
  font-weight:600;
}

.inactiveAccBtn{
  opacity: 0.7;
}

.selectPoint {
  /* margin-top: 2px; */
  /* margin-top: -6px; */
  /* display: none; */
  margin-bottom: 0px;
}

.invis {
  visibility: hidden;
}

.none {
  display: none;
}

.adminBg {
  max-height: 100vh;
  background-color: rgb(251, 251, 255);
  /* background-color: red; */
}

.adminTopContainer {
  background-color: #fff;
  /* background: rgb(20, 48, 81); */
  /* background: linear-gradient(
    0deg,
    rgba(20, 48, 81, 1) 43%,
    rgba(29, 69, 115, 1) 91%
  ); */

  display: flex;
  flex-wrap: wrap;
  min-width: 100vw;
  max-width: 100vw;
  height: 130px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e6eaee;
}

.desktopAdminTopContainerHeight {
  height: 125px;
  border-bottom: 2px solid #e6eaee;
}

.adminTopInnerContainer {
  /* background-color: grey; */
  display: flex;
  justify-content: space-between;
  /* margin-left: 5%; */
  /* width: 90%; */
  /* padding-top: 5px; */
  /* margin-top: 25px; */
  align-items: center;
  text-align: left;
}

.companySettings {
  color: #143051;
  font-weight: 500;
  margin-top: 34px;
  font-size: 28px;
  margin-left: 26px;
}

.statsPie {
  min-width: 30px;
  padding-right: 14px;
}

.adminMenuHolder {
  /* background-color: yellow; */
  overflow: scroll;
  max-width: 95%;
  min-width: 95%;
  margin-left: 5%;
  max-height: 38px;
  margin-top: 18px;
  /* max-width: 100%; */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.adminMenuHolder::-webkit-scrollbar {
  display: none;
}

.adminMenuContainer {
  /* background-color: cyan; */
  display: flex;
  min-width: 980px;
  margin-top: -7px;
}

.adminMenuUnit {
  /* min-width: 60px; */
  text-align: center;
  /* padding-left: 11px;
  padding-right: 11px; */
  margin-left: 8px;
  color: #6a98d1;
  font-weight: 400;
  line-height: 31px;
  padding-top: 10px;
  padding-left: 4px;
  padding-right: 5px;
  font-size: 1.12em;
  cursor: pointer;
  /* background-color: yellow; */
}

.adminMenuUnit:active {
  color: #384a5e;
  color: #9ab9df;
  /* color: #143051; */
  -webkit-transform: scale(0.97);
          transform: scale(0.97);

  /* font-size: 0.95em; */
  /* margin-top: 1px; */
}

.adminMenuUnitFirst {
  text-align: left;
  margin-left: 3px;
}

.adminTitleSel {
  font-weight: 500;
  color: #143051;
  color: #214978;
  border-bottom: 1px #ccdff1 solid;
}

.whiteGradientOverScrollBar {
  background-image: linear-gradient(
    to left,
    rgba(20, 48, 81, 1),
    rgba(20, 48, 81, 0)
  );
  min-height: 25px;
  min-width: 115px;
  position: absolute;
  top: 95px;
  right: 0;
  pointer-events: none;
  display: none;
  /* background-color: black; */
}

.whiteGradientAdmin {
  position: fixed;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  top: 130px;
  min-height: 6px;
  max-width: 90vw;
  min-width: 90vw;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(255, 255, 255, 1)
  );
  background-repeat: repeat-x;
  z-index: 3;
  display: none;
}
.AdminInner {
  /* background-color: rgb(251, 251, 255); */
  margin-top: 12px;
  padding-bottom: 40px;
  padding-left: 10px;
  padding-right: 10px;
}

@media screen and (max-width: 819px) {
  .AdminInner {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.AdminRowContainer {
  min-width: 100%;
  padding-left: 20px;
  padding-right: 40px;
  min-height: 44px;
  margin-top: 10px;
  /* background-color: rgb(251, 251, 255); */
  /* border-bottom: 1px solid #ddebf4; */
  /* animation-name: zoomModalIn !important; */
  /* animation-duration: 0.1s !important; */
  padding-bottom: 30px;
  background-color: white;
  border-radius: 10px;
  /* margin-bottom: 100px; */
}

.AdminRowContainer:first-of-type {
  margin-top: 0px;
}

.overtimeNoBorder {
  border: none !important;
  border-bottom: 1px solid white;
}
.overtimeBorderBottom {
  border-bottom: 1px solid #143051;
}

.adminLogo {
  max-width: 75px;
  max-height: 20px;
}

.pHolRateTitle {
  /* background-color: yellow; */
  min-width: 150px;
  text-align: left;
}

.AdminRowContainer:last-of-type {
  border-bottom: 0px solid #fff;
}

.specialDayHistory {
  margin-right: 15px;
  cursor: pointer;
}

/* USERS & TEAMS */
.UTSettingsContainer {
  /* background-color: pink; */
  /* max-height: calc(100% - 228px); */
  overflow: hidden;
  /* width: 90vw; */
  display: block;
  /* position: absolute;
  margin: auto;
  left: 0;
  right: 0; */
}

.utUpperContainer {
  /* background-color: #f5faff; */
  display: flex;
  color: #143051;
  justify-content: space-around;
  align-items: center;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 25px;
}

.utSelected {
  font-weight: 500;
  border-bottom: 1px solid #143051;
}

.utSearchIcon {
  width: 35px;
  position: fixed;
  top: calc(100% - 210px);
  right: 94px;
  z-index: 999;
  opacity: 0.7;
}

.utSearchIconCanx {
  width: 37px;
  position: fixed;
  top: calc(100% - 213px);
  right: 92px;
  z-index: 9999999;
  opacity: 1;
}

.utSearchIconHidden {
  visibility: hidden;
}

.utLowerContainer {
  /* background-color: rgb(251, 251, 255); */
  /* position: fixed;
  left: 0;
  right: 0; */
  /* max-width: 90vw; */
  /* max-width: calc(100vw - 420px); */
  /* padding-left: 5%; */
  /* padding-right: 5%; */
  top: 130px;
  /* margin: auto; */
  /* overflow: scroll; */
  min-height: 100%;
  max-height: calc(100% - 152px);
  min-height: calc(100% - 152px);
  margin-top: 2px;
  /* margin-left: calc(100vw - 240px); */
  /* border-top: 2px #143051 solid; */
  /* background-color: purple; */
}

.utLowerContainerMob {
  max-width: 90vw;
  min-width: 90vw;
  /* background-color: orange; */
  margin-left: 5vw;
  margin-top: 0px;
}

.utLowerContainer::-webkit-scrollbar {
  display: none;
}

/* Admin Users/Teams: Users */
.adminUsersContainer {
  /* background-color: red; */
  display: block;
  margin-top: 0px;
  /* padding-top: 10px; */
  padding-bottom: 30px;
  padding-right: 14px;
  max-height: calc(100vh - 164px);
  overflow-y: scroll;
  max-width: 350px;
  margin-left: 43px;
  /* margin-left: calc(100vw - 240px); */
}

.adminUsersContainerMob {
  display: block;
  margin-top: 0px;
  /* padding-top: 10px; */
  padding-bottom: 30px;
  /* padding-right: 14px; */
  max-height: calc(100vh - 164px);
  overflow-y: scroll;
  max-width: 100%;
  min-width: 100%;
  /* margin-left: 43px; */
  /* background-color: pink; */
}

.adminUserBg {
  /* background-color: orange; */
  min-width: 100%;
}

.archColor {
  opacity: 0.5;
}

.adminUserUnitBox {
  background-color: white;
  min-height: 54px;
  /* margin-top: 7px; */
  /* border-radius: 5px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #143051;
  border: solid 1px #e0e5ed;
  border-bottom: solid 2px #e0e5ed;
  margin-top: 8px;
  /* margin-bottom: 2px; */
  border-radius: 5px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  cursor: pointer;
}

.adminUserUnitBoxSelected {
  font-weight: 500;
  /* background-color: #143051; */
  border-radius: 5px;
  border-bottom: 1px solid #475974;
  z-index: 999;
  /* color: blue; */
  border: 2px solid #143051;
  padding-top: 4px;
  margin-top: 12px;
  cursor: pointer;
  /* margin-bottom: 10px; */
  /* color: white; */

  /* font-size: 1.04em; */
}

.selectedUserBar {
  min-width: 3px;
  max-width: 3px;
  min-height: 30px;

  /* margin-top: -6px; */
  max-height: 30px;
  margin-bottom: 2px;
  /* margin-top: -2px; */
  background-color: #ccdff1;
  color: white;
  border-radius: 100px;
  display: none;
}

.invis {
  visibility: hidden;
}

.nameCombined {
  /* margin-top: 11px; */
  margin-left: 8px;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  color: #496375;
}

.utName {
  display: inline-flex;
  margin-left: 2%;
  font-size: 0.9em;
  text-align: left;
  align-items: center;
  font-size: 1em;
  /* margin-top: -11px; */
}

.utNameSelected {
  margin-top: -1px;
  /* margin-left: 4px; */
}

.utJobTitle {
  margin-right: 4%;
  font-size: 1em;
  text-align: right;
  font-weight: 400;
  font-size: 0.8em;
  margin-top: -1px;
  color: #496375;
  width: 200px;
}

.mobileNone {
  /* REMOVE THIS FOR THE DESKTOP VERSION */
  display: none;
}

.AddNewUserAdmin {
  position: fixed;
  top: calc(100% - 188px);
  right: 45px;
  background-color: #143051;
  color: white;
  border-radius: 100%;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  line-height: 44px;
  font-size: 3em;
}

.none {
  display: none !important;
}

.whiteColor {
  /* color: white; */
}

.openUserBtn {
  font-weight: 500;
}

/* unarchive user modal */
.unarchiveUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999999999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
}

@-webkit-keyframes underlayFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes underlayFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.unarchiveModalBox {
  background-color: #fff;
  min-width: 90vw;
  max-width: 90vw;
  height: 150px;
  margin-top: -10vh;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #143051;
  font-weight: 500;
  text-align: center;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
}

/* .unarchiveContainer {
  
} */

.unarchiveButton {
  background-color: #8ea7c5;
  border: 2px solid #143051;
  min-height: 35px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  border-radius: 30px;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
  font-size: 1.1em;
}

/* search user modal */
.adminUnderlay {
  /* background: rgba(0, 0, 0, 1); */

  background-color: #536f8f;
  /* height: 130px; */
  min-height: 125px;
  max-height: 125px;
  border-radius: 0 0 15px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999999999;
  top: 0px;
  left: 0;
  -webkit-animation-name: searchBarSlideDown;
          animation-name: searchBarSlideDown;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
}

@-webkit-keyframes searchBarSlideDown {
  from {
    margin-top: -80px;
    opacity: 0;
  }
  to {
    margin-top: 0px;
    opacity: 1;
  }
}

@keyframes searchBarSlideDown {
  from {
    margin-top: -80px;
    opacity: 0;
  }
  to {
    margin-top: 0px;
    opacity: 1;
  }
}

.binoculars {
  max-width: 40px;
  margin-top: -70px;
}

.searchUserModal {
  background-color: white;
  min-height: 55px;
  min-width: 90%;
  max-width: 90%;
  position: absolute;
  top: 52px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.searchUserInput {
  min-height: 40px;
  width: 75%;
  font-size: 1.7em;
  color: #143051;
  text-indent: 3%;
  border: none;
  margin-left: 5%;
  /* border-bottom: 2px solid #143051; */
  font-weight: 500;
  /* color: #143051; */
}

.searchUserInput::-webkit-input-placeholder {
  font-size: 0.7em;
  color: #536f8f;
}

.searchUserInput:-ms-input-placeholder {
  font-size: 0.7em;
  color: #536f8f;
}

.searchUserInput::placeholder {
  font-size: 0.7em;
  color: #536f8f;
}

.cancelSearchImg {
  width: 28px;
  margin-right: 6%;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.keyboardDown {
  width: 24px;
  margin-left: 5%;
  margin-top: 5px;
}

.whiteCurveL {
  position: absolute;
  top: 106px;
  left: 1px;
  z-index: 999999;
  max-width: 14px;
}

.adminCurverCropper {
  min-width: 100vw;
  position: absolute;
  top: 124px;
  max-height: 23px;
  overflow: hidden;
  padding-top: 5px;
  display: none;
  /* background-color: #f4f8fe; */
}

.adminCurve {
  /* min-width: 100vw;
  position: absolute;
  top: 130px; */
  /* border-radius: 18px; */
  background-color: rgb(251, 251, 255);
  /* min-height: 10px; */
  border-top: 1px solid #e6eaee;
  /* -webkit-box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.39); */
  /* box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.225); */
  /* border-top: 2px solid #214978; */
}

/* sub unit box below the user row */
.adminUserUnitBoxSubBox {
  /* background-color: #e0ebf8; */
  min-height: 40px;
  min-width: 85%;
  max-width: 85%;
  -webkit-animation-name: moveSubBoxIn !important;
          animation-name: moveSubBoxIn !important;
  -webkit-animation-duration: 0.15s !important;
          animation-duration: 0.15s !important;
  margin-left: 15px;
  border-radius: 0px 0px 6px 6px;
  background: rgb(88, 112, 150);
  background: linear-gradient(
    0deg,
    rgba(88, 112, 150, 1) 0%,
    rgba(88, 112, 150, 1) 90%,
    rgba(69, 89, 120, 1) 100%
  );
  margin-bottom: 12px;
}

@-webkit-keyframes moveSubBoxIn {
  from {
    margin-top: -40px;
    opacity: 0;
  }
  to {
    margin-top: 0px;
    opacity: 1;
  }
}

@keyframes moveSubBoxIn {
  from {
    margin-top: -40px;
    opacity: 0;
  }
  to {
    margin-top: 0px;
    opacity: 1;
  }
}

.userSubBoxContainer {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  color: white;
}

.subBoxAllowance {
  margin-left: 12px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.9em;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.2s !important;
          animation-duration: 0.2s !important;
}

.userRemainingTxt {
  font-size: 1em;
  margin-left: 6px;
}

.editUser {
  /* background-color: #9a74eb; */
  background-color: #0077ff;
  border: 2px solid #143051;
  color: white;
  border-radius: 5px;
  min-height: 30px;
  line-height: 30px;
  font-size: 0.9em;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 12px;
  cursor: pointer;
  font-weight: 500;

  -moz-box-shadow: inset 0px 2px 0px #1964ba;
  -webkit-box-shadow: inset 0px 2px 0px #1964ba;
  min-width: 62px;
  max-width: 62px;
  box-shadow: inset 0px 2px 0px #1964ba;
}

.editUser:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.loadingBg {
  background-color: #7495ba;
  box-shadow: inset 0px 2px 0px #475974;
  opacity: 1;
  min-width: 62px;
  max-width: 62px;
}

.nameDropArrow {
  width: 7px;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  margin-top: 13px;
}

.nameDropArrowSelected {
  width: 7px;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  margin-top: 2px;
  margin-left: -3px;
  -webkit-animation-name: rotateDropArrow;
          animation-name: rotateDropArrow;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
}

@-webkit-keyframes rotateDropArrow {
  from {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotateDropArrow {
  from {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.adminUserUnitBox:last-of-type {
  border: none;
}

.waitGIF {
  width: 14px;
  /* padding-top: 2px; */
}

.archiveUnitBox {
  /* background-color: grey; */
  /* border-radius: 5px; */
  display: flex;
  justify-content: space-between;
  border-bottom: solid 2px #e0ebf8;
  align-items: center;
  color: #496375;
  height: 55px;
  margin-top: 2px;
}

.archivedName {
  margin-left: 3%;
  text-align: left;
  font-weight: 500;
}

.archJobTitle {
  /* height: 12px;
  line-height: 12px; */
  /* margin-top: 10px; */
  margin-right: 3%;
  font-size: 0.8em;
  font-weight: 500;
  text-align: right;
}

/* ADMIN TEAMS PAGE  - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
.teamUnit {
  max-height: 54px;
  min-height: 54px;
  background-color: #fff;
  /* margin-top: 3px; */
  border-radius: 15px 15px 15px 15px;
  color: #496375;
  margin-top: 12px;
  border: 2px solid #143051;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  /* border: none; */
}

.teamUnitUnselected {
  max-height: 54px;
  min-height: 54px;

  background-color: #fff;

  margin-top: 3px;
  color: #143051;
  border: solid 1px #e0e5ed;
  border-bottom: solid 2px #e0e5ed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  cursor: pointer;
  border-radius: 15px;
  margin-top: 8px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.absenceRowMainText {
  text-align: left;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 14px;
  padding-left: 15px;
  font-size: 14px;
}

.zoomInGPSItems {
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  padding-left: 10px;
  padding-right: 10px;
}

.teamUnitTeamName {
  margin-left: 8px;
  min-width: 250px;
  text-align: left;
  font-size: 1.1em;
  color: #496375;
  font-weight: 500;
}

.teamUnitTeamNameSel {
  margin-left: 12px;
  min-width: 250px;
  text-align: left;
  font-size: 1.1em;
}

.teamUnitQty {
  margin-right: 20px;
  background-color: #8ea7c5;
  color: white;
  border-radius: 55px;
  min-width: 26px;
  min-height: 24px;
  line-height: 24px;
  font-size: 0.9em;
  /* padding-top: 2px; */
  padding-top: 2px;
}

.teamUnitQtyUnsel {
  margin-right: 20px;
  background-color: #384a5e;
  cursor: pointer;

  color: white;
  border-radius: 55px;
  min-width: 26px;
  min-height: 24px;
  line-height: 24px;
  font-size: 0.9em;
  padding-top: 2px;
}

.offtodayspan {
  font-size: 0.8em;
}

.adminTeamsContainer {
  /* background-color: red; */
  position: relative;
  z-index: 1;
  display: block;
  /* margin-top: -5px; */
  /* padding-top: 10px; */
}

.selectedBarAndTeamName {
  /* display: flex; */
  display: flex;
  margin-left: 2%;
  align-items: center;

  /* max-height: 50px; */
}

.subBoxOffToday {
  margin-left: 12px;
  color: white;
}

.teamUnitSubBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  min-width: 85%;
  max-width: 85%;
  -webkit-animation-name: moveSubBoxIn;
          animation-name: moveSubBoxIn;
  -webkit-animation-duration: 0.15s;
          animation-duration: 0.15s;
  margin-left: 5px;
  border-radius: 0px 0px 10px 10px;
  background: rgb(88, 112, 150);

  background: linear-gradient(
    0deg,
    rgba(88, 112, 150, 1) 0%,
    rgba(88, 112, 150, 1) 90%,
    rgba(69, 89, 120, 1) 100%
  );
  margin-left: 15px;
  margin-bottom: 12px;
}

.knobPublicHolsAdmin {
  /* background-color: orange; */
  min-width: 33px;
}

.bottomBorderNavy {
  border-bottom: 1px solid #7495ba;
}

/* .shiftTeamSubBubble {
  /* background-color: #a2bddf;
  background-color: #496375; */
/* color: white; */
/* margin: 0 auto; */
/* padding-left: 10px; */
/* padding-right: 10px; */
/* min-height: 24px; */
/* line-height: 24px; */
/* font-size: 0.75em; */
/* margin-top: -10px; */
/* margin-bottom: 8px; */
/* border-radius: 30px; */
/* max-width: fit-content; */
/* } */
*/ .shiftplaceholdertxt {
  margin-top: 10px;
  font-weight: 500;
  color: #8ea7c5;
}

.redSchedRow {
  background-color: red;
}

.accruedLeaveReportsSpan {
  font-weight: 500;
  color: #143051;
  font-size: 1.05em;
}

.moreDetailsButton {
  background-color: #fff;
  border: solid 1px #e6eaee;
  display: flex;
  /* color: #7495ba; */
  /* color: white; */
  font-size: 16px;
  max-width: 120px;
  min-width: 120px;
  padding-left: 10px;
  text-align: center;
  line-height: 25px;
  height: 26px;
  border-radius: 15px;
  /* margin-top: -24px; */
}

.explainedBookIcon {
  max-width: 15px;
  margin-left: 10px;
}

.addExcessToAllowanceInstead {
  /* background-color: blue; */
  margin-top: 34px;
  min-width: 90vw;
  /* border: 1px solid #a2bddf; */
  border-top: 1px solid #e6eaee;
  /* border-radius: 15px; */
  max-width: 250px;
  min-width: 250px;
  padding: 12px;
  padding-left: 4px;
  display: flex;
  align-items: center;
  color: #496375;
  margin-left: 18px;
}

.addExcessToAllowInsteadText {
  text-align: left;
  font-size: 1.2em;
  /* background-color: blue; */
  max-width: 180px;
  color: #496375;
  /* margin-bottom: 4px; */
}

.addAccruedToAllowKnobContainer {
  margin-top: -7px;
  margin-left: 17px;
  position: relative;
  z-index: 99999999999999;
}

.currencyDropdown {
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  padding-left: 10px;
  text-align: left !important;
  max-width: 214px;
  min-width: 214px;
  line-height: 26px;
  height: 35px;
  /* border: 1px solid #e6eaee; */
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  /* border-color: #2c7eb9; */
  border: 2px solid #dde9f9;
  /* background-color: #0bf; */
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 187px;
}

.currencyDropdown:hover {
  border-color: #bbcada;
}

.adminCurrencySymbolTxt {
  text-align: right;
  margin-right: 5%;
  color: #496375;
  font-weight: 500;
  margin-top: 6px;
  margin-bottom: -8px;
  font-size: 12px;
}

.overtimeTitleContainer {
  background-color: #143051;
  color: #fff;
  line-height: 40px;
  padding-left: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 20px;
  border-radius: 10px 10px 10px 0px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  max-width: 282px;
  margin-top: 20px;
}

.defaultOvertimeTitle {
  /* color: #fff; */
  font-weight: 500;
  font-size: 1.2em;
  text-align: left;
  /* background-color: #143051; */
  /* line-height: 40px;
  padding-left: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 20px;
  border-radius: 15px 25px 25px 0px;
  max-width: fit-content; */
}

.defaultOvertimeDisclaimer {
  text-align: left;
  line-height: 18px;
  padding-bottom: 8px;
  font-size: 0.8em;
  max-width: 80%;
  opacity: 0.8;
}

.overtimeDefaultDownArmShort1 {
  min-width: 8px;
  max-width: 8px;
  position: absolute;
  left: 0px;
  /* top: -10px; */
  margin-top: -21px;
  min-height: 328px;
  background-color: #143051;
  border-radius: 100px;
}

.overtimeDefaultDownArmShort2 {
  min-width: 8px;
  max-width: 8px;
  position: absolute;
  left: 0px;
  /* top: -10px; */
  margin-top: -21px;
  min-height: 140px;
  background-color: #143051;
  border-radius: 100px;
}

.overtimeDefaultDownArmLong1 {
  min-width: 8px;
  max-width: 8px;
  position: absolute;
  left: 0px;
  /* top: -10px; */
  margin-top: -21px;
  min-height: 425px;
  background-color: #143051;
  border-radius: 100px;
}

.overtimeDefaultDownArmLong2 {
  min-width: 8px;
  max-width: 8px;
  position: absolute;
  left: 0px;
  /* top: -10px; */
  margin-top: -21px;
  min-height: 275px;
  background-color: #143051;
  border-radius: 100px;
}

.overtimeRowSubText {
  /* margin-left: 14px; */
  /* min-height: 202px; */
  font-weight: 400;
  /* font-size: 1.2em; */
}

.minHeight202 {
  min-height: 202px;
}

.overtimeSubInstruction {
  text-align: left;
  padding-left: 14px;
  color: #859dbb;
  margin-top: -20px;
  font-size: 0.9em;
  font-weight: 400;
}

.overtimeBg {
  background: rgb(232, 239, 251);
  background: linear-gradient(
    0deg,
    rgba(232, 239, 251, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.overtimeIndividualTableContainer {
  min-width: 100%;
  max-width: 100%;
  padding-bottom: 30px;
}

.indivOTstaffTitle {
  text-align: left;
  background-color: #143051;
  color: white;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 15px;
  padding-right: 15px;
  max-height: 42px;
  line-height: 44px;
  border-radius: 10px;
  margin-bottom: 25px;
  margin-top: 15px;
  font-size: 1em;
}

.OTinvidualsMasterContainer {
  border-top: 1px solid #ddebf4;
  margin-top: 8px;
}

.individualOvertimeUserContainer {
  display: flex;
  justify-content: space-between;
  background-color: #f7fbff;
  /* padding-top: 20px; */
  border-radius: 5px;
  margin-top: 12px;
}

.individualOTName {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  padding-left: 10px;
  /* background-color: blue; */
  font-weight: 500;
  font-size: 0.8em;
}

.payableBold {
  color: #143051;
}

.indivLName {
  margin-top: -20px;
}

.evenBgColorOvertimeUser {
  background-color: #ebf2f9;
  border-radius: 5px;
}

.rightSideDataOvertime {
  /* background-color:  orange; */
  display: flex;
  align-items: center;
}

.otSettingTitle {
  /* background-color: orange; */
  max-width: 60px;
  font-size: 0.7em;
  line-height: 14px;
  display: flex;
  font-weight: 400;
  /* align-items: center; */
  text-align: left;
}

.otUnit1 {
  display: flex;
  align-items: center;
}

.otUnit2 {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 8px;
  padding-left: 10px;
  border-left: 1px solid #143051;
}

.otIndivSettingContainer {
  margin-top: -7px;
  margin-left: -10px;
}

.otIndivSettingContainer2 {
  margin-top: -7px;
  margin-left: 1px;
}

.indiStaffOverTimeInstructionsTitle1 {
  text-align: left;
  text-indent: 3%;
  margin-top: 10px;
  background-color: #859dbb;
  color: white;
  max-width: 140px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 30px;
  font-size: 0.9em;
  line-height: 32px;
  max-height: 30px;
  margin-left: 16px;
}

.indiStaffOverTimeInstructionsTitle2 {
  text-align: left;
  text-indent: 3%;
  margin-top: -10px;
  background-color: #859dbb;
  color: white;
  max-width: 158px;
  border-radius: 30px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 30px;
  font-size: 0.9em;
  line-height: 32px;
  margin-top: 20px;
  max-height: 30px;
  margin-left: 16px;
}
.indiStaffOverTimeInstructionsSub {
  text-align: left;
  margin-left: 3%;
  margin-top: 10px;
  font-weight: 400;
  line-height: 22px;
  font-size: 0.8em;
  font-weight: 500;
  color: #8997a8;
  margin-left: 16px;
}

.infoBtnContainer {
  display: flex;
  border: 1px solid #143051;
  border-radius: 10px;
  max-width: 70px;
  padding-left: 10px;
  max-height: 30px;
  line-height: 30px;
  color: white;
  font-size: 0.9em;
  background-color: #859dbb;
  margin-left: 16px;
  min-width: 70px;
}

.infoBtnContainerActive {
  display: flex;
  border: 1px solid #143051;
  border-radius: 30px;
  max-width: 70px;
  padding-left: 10px;
  max-height: 30px;
  margin-left: 16px;
  line-height: 30px;
  min-width: 70px;

  font-size: 0.9em;
  /* background-color: #31537a; */
}
.infoBtnI {
  max-width: 18px;
  margin-left: 5px;
}

.closeInfoBtn {
  max-width: 10px;
  margin-left: 10px;
}

.infoAndExplainedContainerOvertime {
  display: flex;
  /* background-color: blue; */
  align-items: center;
  max-width: 220px;
  justify-content: space-between;
}

.shortPayAccrueBox {
  max-height: 105px;
}

.mediumPayAccrueBox {
  max-height: 240px;
}

.overtimeDefaultDownArmMedium {
  max-height: 100px !important;
}

.overtimeInfoBtn2 {
  margin-left: 0px;
  margin-top: -10px;
}

.overtimeInfo2SideBar {
  min-width: 4px;
  min-height: 275px;
  max-width: 4px;
  border-radius: 10px;
  margin-top: 10px;
  position: absolute;
  background-color: #143051;
}

.overtimePanelUserIsZero {
  display: flex;
  /* background-color: blue; */
  color: #859dbb;
  min-width: 208px;
  justify-content: flex-end;
  padding-right: 1px;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
  /* margin-right: 12px; */
}

.right14px {
  margin-left: 14px;
}

.dataLoader {
  max-width: 34px;
  margin-top: 15px;
}

.loadingShiftOpenBtn {
  opacity: 0.3;
  pointer-events: none;
}
.overtimeRatesContainer {
  /* text-align: center; */
}

.overtimeRateRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: orange; */
  /* min-width: 120px; */
  /* max-width: 190px; */
  min-width: 90%;
  max-width: 90%;
  margin-top: 10px;
  /* margin: 10px auto; */
  margin-left: 5%;
}

.noBorderAtBottom {
  /* border-bottom: 0px solid white; */
  /* border: none; */
}

.ukRequirementSpan {
  font-weight: 400;
  color: #859dbb;
  font-size: 12px;
  line-height: 12px;
}

.weeksBelowRefPeriodDrop {
  margin-top: -10px;
  margin-left: 36px;
  font-size: 12px;
  /* margin-bottom: -10px;} */
}

.invisAllowPreview {
  visibility: hidden;
}

.loadingAllowPreviewImg {
  max-width: 40px;
  margin-left: 16px;
}

.pubHolExclusionTitle {
  text-align: left;
  line-height: 23px;
  min-height: 30px;
  margin-top: 14px;
  margin-left: 14px;
  font-size: 14px;
}
.holExclusionsContainer {
  max-width: 62%;
  min-width: 62%;
  /* background-color: orange; */
  text-align: left;
  margin-bottom: 40px;
  margin-top: 40px;
}

.exclusionUnit {
  display: flex !important;
  min-height: 40px;
  /* max-height: 40px; */
  align-items: center;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid white;
}

.exclusionUnit:nth-child(even) {
  background-color: #ebf2f9;
  border: 1px solid #ebf2f9;
}

.exclusionUnit:hover {
  border: 1px solid #e7eef5;
}

.exclusionTitle {
  text-align: left !important;
  font-size: 0.8em;
  line-height: 18px;
  font-weight: 400;
}

.excluKnobIgnoreContainer {
  display: flex;
  justify-content: space-between;
  min-width: 80px;
  max-width: 80px;
}

.exclusionKnob {
  margin-top: -5px;
}

.ignoreExcluActive {
  font-size: 0.7em;
  color: #0077ff;
}

.ignoreExcluInactive {
  font-size: 0.6em;
  color: #859dbb;
  padding-top: 1px;
  margin-bottom: -1px;
}

.overtimeRowSubText {
  max-width: 93%;
  min-width: 93%;
}

.payAccruedOvertimeKnob {
}

.overtimeAdminKnob {
  margin-right: 29px;
}
.knobAdjustmentForSelfGenSched1 {
  margin-right: 16px;
}

.knobAdjustmentForSelfGenSched2 {
  margin-right: 52px;
}

.dailyRatesDataContainer {
  margin-top: 22px;
  padding-bottom: 40px;
}

.dailyPayRateRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 60%;
  margin-top: 10px;
  margin-right: 5%;
}

.dailyPayRateDropdown {
  max-width: 80px;
  min-width: 80px;
  max-height: 40px;
  min-height: 35px;
  background-color: #fff;
  font-size: 14px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 54px;
  border-radius: 5px;
  text-align: left;
  color: #496375;
  /* margin-top: 20px; */
  padding-left: 12px;
  border: 2px solid #dde9f9;
  font-weight: 500;
  cursor: pointer;
}

.dailyPayRateTxt {
  margin-right: 15px;
  font-size: 14px;
  font-weight: 500;
  color: #214978;
}

.scheduleRowSubText {
  max-width: 100%;
}

.scheduleDailyRatesLeftBox {
  background-color: orange;
  max-width: 40%;
}

.dailyRatesWrapper {
  background-color: pink;
}

.genSchedDaysQtyInput {
  max-width: 75px;
  min-width: 75px;
  max-height: 40px;
  min-height: 35px;
  padding-top: 2px;
  background-color: #fff;
  font-size: 0.9em;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 50px;
  border-radius: 10px;
  text-align: left;
  color: #496375;
  margin-top: 20px;
  margin-right: 5%;
  font-weight: 500;
  /* padding-left: 10px; */
  /* margin-right: 40px; */
  padding-left: 14px;
  /* font-size: 0.9em; */
  /* text-align: right; */
  /* height: 40px; */
  border: 2px solid #dde9f9;
  /* margin-right: 10px; */
  /* margin-right: 5%; */
}

.monthResetDropdown {
  max-width: 80px;
  min-width: 80px;
  max-height: 40px;
  min-height: 35px;
  background-color: #fff;
  font-size: 14px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 54px;
  border-radius: 5px;
  text-align: left;
  color: #496375;
  margin-top: 20px;
  padding-left: 12px;
  border: 2px solid #dde9f9;
  font-weight: 500;
  cursor: pointer;
}

.monthResetDropdown:hover {
  border-color: #bbcada;
}

.clockOnPeriodDropdown {
  max-width: 105px;
  min-width: 105px;
  max-height: 40px;
  min-height: 35px;
  background-color: #fff;
  font-size: 14px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 82px;
  border-radius: 5px;
  text-align: left;
  color: #496375;
  margin-top: 20px;
  padding-left: 14px;
  border: 2px solid #dde9f9;
  font-weight: 500;
  cursor: pointer;
}

.clockOnPeriodDropdown:hover {
  border-color: #bbcada;
}
.weeksTxtSchedGen {
  margin-top: -14px;
  font-size: 0.9em;
  margin-left: 20px;
  margin-bottom: -10px;
}

.genSchedQtyRowBit {
  max-width: 212px;
  /* margin-left: 15px; */
}

.noBottomBorder {
  border-bottom: none;
}

.knobContainerCompanyClosed {
  /* background-color: orange; */
  /* margin-top: -4px; */
  margin-left: 12px;
  padding-bottom: 8px;
}

.closedDayTxt {
  font-size: 14px;
  color: #143051;
  margin-left: 12px;
  min-width: 50px;
  font-weight: 500;
  cursor: pointer;
}

.dailyRateRowSplitter {
  min-height: 1px;
  max-height: 1px;
  min-width: 100%;
  margin-top: 10px;
  background-color: #e6eaee;
}

.narrowerSub {
}

.monthResetDropdownHolder {
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  /* min-width: 80px; */
}

.clockOnRefSubTxt {
  /* max-width: 80px; */
  font-weight: 400;
}

.openClosedTxtContainer {
  max-width: 66px;
  min-width: 66px;
}

.specialDaysContainer {
  display: flex;
  margin-top: -23px;
  padding-bottom: 40px;
  padding-left: 15px;
  flex-direction: column;
  align-items: flex-end;
}

.specialDaysUpper {
  min-width: 100%;
  display: flex;
  justify-content: flex-end;

  position: relative;
  z-index: 999;
}

.specialDaysHolder {
  /* background-color: lime; */
  padding-top: 25px;
  margin-top: -40px;
}

.specialDaysAddBtn {
  background-color: #a387df;
  border: 1px solid #8e76c1;
  color: #fff;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 9px;
  padding-right: 9px;
  cursor: pointer;
  /* margin-left: 10px; */
  margin-right: 5%;
  margin-top: -14px;
}

.specialDaysAddBtn:hover {
  background-color: #997ed4;
}

.addZoneParent {
  min-width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
  margin-top: 14px;
}
.addGPSBtn {
  font-size: 14px;
  color: #fff;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 6px;
  max-height: 35px;
  border-radius: 5px;
  min-height: 35px;
  max-width: 103px;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.addCustomBtn {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  /* background-color: #a387df;
  border: 1px solid #8e76c1; */
  font-size: 12px;
  min-height: 32px;
  max-height: 32px;
}
.addCustomBtn:hover {
  /* background-color: #997ed4 !important; */
}

.addZonePlus {
  max-width: 12px;
  margin-left: 4px;
}

.closedDaysHistoryBtn {
  font-size: 14px;
  color: #fff;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  line-height: 26px;
  border-radius: 5px;
  min-height: 30px;
  margin-right: 5%;
  max-width: 80px;
  text-align: center;
  margin-top: 18px;
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.closedDaysHistoryPopUp {
  max-width: 14px;
  margin-left: 4px;
}

.closedDaysHistoryBtn:hover {
  background-color: #bbcada;
}

.specialDaysLower {
  /* background-color: rgb(245, 245, 245); */
  border-radius: 10px 0px 0px 10px;
  /* margin-top: 10px; */
  display: flex;
  justify-content: flex-end;
  padding-bottom: 15px;
  /* padding-top: 5px; */
  margin-top: 25px;
  padding-right: 10px;
  margin-bottom: 40px;
  margin-right: 5%;
  border-right: solid 2px #698396;
}

.gpsList {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 15px;
  margin-top: 25px;
  margin-right: 5%;
}
.specialDayUnitsContainer {
  width: 240px;
}

.specialDayUnit {
  /* background-color: #fff; */
  height: 40px;
  line-height: 40px;
  color: #496375;
  border-radius: 6px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  /* border: 2px solid #5f7792; */
  cursor: pointer;
  border: solid 2px #dee5ed;

  /* animation-name: zoomModalIn !important; */
  /* animation-duration: 0.1s !important; */
}


.specialDayDate {
  margin-left: 13px;
  /* background-color: yellow; */
  min-width: 120px;
  font-size: 14px;
  margin-top: -2px;
  text-align: left;
  font-weight: 500;
  line-height: 18px;
  min-height: 38px;
  display: flex;
  align-items: center;
}

.specialDayRate {
  color: #bbcada;
  font-weight: 500;
  font-size: 11px;
  text-align: left;
  margin-left: 13px;
  margin-top: -6px;
  border-top: 1px solid #bbcada;
  line-height: 23px;
}

.specialDaysTrash {
  margin-right: 12px;
  max-width: 12px;
  cursor: pointer;
}

.companyClosed {
  width: 11px;
  margin-top: 4px;
}

.shut {
  font-size: 10px;
  font-weight: 500;
  margin-top: -10px;
  /* width: 50px; */
  /* padding-right: -4px; */
  /* padding-left: -4px; */
}

.trashInvis {
  opacity: 0;
  pointer-events: none;
}

.lighterBgSpecialDay {
  background-color: #fff;
  height: 40px;
  line-height: 40px;
  color: #496375;
  border-radius: 7px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
  font-weight: 500;
  /* border: 2px solid #5f7792; */
  opacity: 0.5;
  border: solid 2px #dee5ed;
  cursor: pointer;
}

/* MODAL */
.specialDayModalUnderlay {
  /* background: rgba(0, 0, 0, 0.25); */
  /* max-height: calc(100% - 58px); */
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.none {
  display: none;
}

.specialDayModalBox {
  background-color: #fff;
  border-radius: 5px;
  min-height: 35px;
  max-width: 338px;
  min-width: 338px;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
}

.specialDayModalText {
  padding: 10px 20px 25px;
  /* color: #496375; */
  color: #496375;
}

.specialDayModalClose {
  font-size: 0.9em;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 2px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 30px;
  line-height: 25px;
  border-radius: 5px;
  min-height: 30px;
  max-width: 70px;
  margin: 22px auto;
  margin-bottom: 0px;
}

.addSpecialDayModalBox {
  /* background-color: #e0ebf8; */
  background-color: white;
  max-width: 338px;
  margin: 0 auto;
  border-radius: 5px;
  overflow: hidden;
  /* height: 366px; */

  -webkit-animation-name: zoomModalIn !important;

          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  cursor: default;
}

.gpsModalBox {
  /* background-color: #e0ebf8; */
  background-color: white;
  max-width: 338px;
  margin: 0 auto;
  border-radius: 5px;
  overflow: hidden;
  height: 392px;

  -webkit-animation-name: zoomModalIn !important;

          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  cursor: default;
}

.addSpecialDayModalBoxExpanded {
  /* max-height: 300px;
  min-height: 300px; */
  overflow: scroll;
  /* margin-top: 5vh; */
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  border-radius: 10px;
}

.addSpecialDayModalBoxExpanded::-webkit-scrollbar {
  display: none;
}

.addSpecialDayContentBox {
  padding: 15px 20px 0px;
  color: #143051;
  background-color: white;
  /* padding-bottom: 25px; */
  min-width: 338px;
}

.specialDayModalHeader {
  display: flex;
  justify-content: space-between;
}

.addSpecialDayTitle {
  font-weight: 400;
  margin-top: 2px;
  margin: 0 auto;
  padding-right: 12px;
}

.specialDayModalMiddle {
  /* background-color: cyan; */
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  /* height: 120px; */
}

.addGpsMiddle {
  /* background-color: cyan; */
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  /* height: 120px; */
}

.specialDayModalFooter {
  /* background-color: pink; */
  margin-top: 2px;
  /* height: 65px; */
}

.dateBubbles {
  display: flex;
  justify-content: space-around;
  /* padding-left: 30px;
  padding-right: 30px; */
  /* background-color: blue; */
}

.bubblesBox {
  /* max-width: 100%; */
  /* min-width: 100%; */
  /* display: flex; */
  /* justify-content: center; */
  /* background-color: orange; */
}
.specialDateBubble {
  background-color: #8ea7c5;
  border: 1px solid #143051;

  color: white;
  min-height: 35px;
  line-height: 35px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 30px;
  text-align: center;
  margin: 0 auto;
}

.bubbleL {
  margin-right: 10px;
  min-width: 40px;
}

.bubbleR {
  margin-left: 10px;
}

.payClosed {
  text-align: right;
  line-height: 20px;
  /* margin-top: -2px; */
}

/* add spcecial day footer */
.specialFooterContainer {
  /* background-color: pink; */
  margin-top: 15px;
  justify-content: space-around;
  padding-bottom: 22px;
  border-bottom: 2px solid #dbe4ee;
}

.rateAndClosedContainer {
  display: flex;
  justify-content: space-between;
}

.rateBox {
  display: flex;
  /* margin-top: -10px; */
  min-height: 40px;
  text-align: center;
  align-items: center;
  max-width: 150px;
  min-width: 150px;
  /* margin: auto 0; */
  font-size: 14px;
  color: #143051;
  border: 2px #dde9f9 solid;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
}

.rateBox:hover {
  border-color: #bbcada;
}

.absencePaidAndRateContainer {
  display: flex;
  justify-content: space-between;
  max-width: 90%;
  margin: 20px auto;
}

.rateBoxAbsence {
  display: flex;
  margin-top: 20px;
  min-height: 40px;
  text-align: center;
  align-items: center;
  max-width: 150px;
  min-width: 150px;
  margin: auto 0;
  font-size: 0.9em;
  color: #496375;
  /* background-color: orange; */
  border: 2px #dde9f9 solid;
  border-radius: 10px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
}

.closedRateBox {
  max-width: 120px;
  min-width: 120px;
}

.absenceRateBox {
  max-width: 105px;
  min-width: 105px;
}

.closeComp {
  margin: 0px auto;
  margin-left: 4px;
}

.closedPay {
  margin: 0 auto;
  margin-left: 4px;
}

.rateTitile {
  font-weight: 500;
  /* margin-right: 0px; */
  line-height: 33px;
  margin-top: 0px;
}

.rateTitileClosed {
  font-weight: 500;
  /* margin-right: 15px; */
  /* margin-left: 15px; */
  line-height: 33px;
  /* margin-top: -2px; */
}

.rateContainerClosed {
  font-weight: 500;
  /* margin-right: 15px; */
  margin-left: 15px;
  /* line-height: 35px; */
  margin-top: -12px;
}

/* name container */
.specialDayNameContainer {
  /* background-color: yellow; */
  width: 100%;
  display: flex;
  min-height: 35px;
  margin-top: 30px;
}

.specialDayNameButtonToOpenInputter {
  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;
  margin-right: 4px;
  color: #6a8bb1;
  min-height: 40px;
  padding-left: 5px;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 12px;
  font-size: 16px;
}

.specialDayNameButtonToOpenInputter:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 6px;
  background-color: #fdfeff;
}

.specialDayNameButtonToOpenInputter::-webkit-input-placeholder {
  color: #c0d6f0;
}

.specialDayNameButtonToOpenInputter:-ms-input-placeholder {
  color: #c0d6f0;
}

.specialDayNameButtonToOpenInputter::placeholder {
  color: #c0d6f0;
}

/* submit btn */
.submitAddSpecialDay {
  font-size: 14px;
  color: #fff;
  background-color: #0bf;
  border: 1px solid #0af;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  max-width: 62px;
  min-width: 62px;
}

.fadeSubmitAddSpecialDay {
  opacity: 0.5;
  pointer-events: none;
}

.specialAddClose {
  width: 15px;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  margin-top: 4px;
  margin-left: 2px;
  cursor: pointer;
}

.specialAddClose:hover {
  opacity: 0.8;
}

.specialDayExplain {
  font-size: 12px;
  line-height: 12px;
  text-align: left;
  margin-top: 0px;
  color: #8997a8;
  line-height: 1.6em;
  font-weight: 400;
  margin-top: 40px;
  /* margin-left: 10px; */
}

.repeatSpecialDay {
  margin-left: 5px;
}

.waitBg {
  opacity: 0.25;
}

.specDayModalTxtTitle {
  font-weight: 500;
  margin-top: 10px;
  font-size: 0.9em;
  margin-left: 3%;
}

.specialDayAddHeaderTitle {
  font-size: 18px;
  font-weight: 500;
  color: #143051;
  /* margin-left: 80px; */
  margin-top: 4px;
}

.addSpecialDayInfoTxt {
  color: #143051;
  text-align: left;
  font-size: 12px;
  padding-left: 10px;
}

.specDayModalTxt {
  font-weight: 400;
  margin-top: 10px;
  font-size: 0.9em;
  margin-right: 3%;
  color: #8997a8;
}

.specDayInfoContainer {
  min-width: 85%;
  display: block;
  /* background-color: yellow; */
  padding-top: -10px;
  text-align: left;
}

.specDayInfoRow {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #8ea7c5;
  align-items: center;
  height: 50px;
  font-size: 1.1em;
}

.payStaffKnob {
  margin-left: 30px;
}

.publicHolsDropDown {
  color: #5d6f85;
  font-weight: 500;
  border-radius: 5px;
  margin-top: -30px;
  background-color: white !important;
  min-height: 35px;
  font-size: 1em;
  /* padding-right: 40px; */
  min-width: 154px;
  /* margin-right: -15px; */
  font-size: 14px;
  text-align: left !important;
  line-height: 30px;
  border: 0;
  height: 30px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
    #214978;
  -webkit-appearance: none;
  background-position-x: 130px;
  border: 2px solid #dde9f9;
  margin-right: 0px;
  padding-left: 11px;
  /* margin-bottom: 13px; */
  cursor: pointer;
}

.rateDropDownShortened {
  max-width: 80px;
  min-width: 80px;
  background-position-x: 57px;
}

/* select.publicHolsDropDown {
  width: 135px;
  text-align-last: left;
} */

select {
  outline: none;
}

.pHolRegionRow {
  margin-top: 11px !important;
  padding-left: 15px;
  margin-bottom: -12px;
}

.pHolRegTitleTxt {
  margin-top: 2px;
  margin-bottom: -2px;
  font-size: 14px;
}
.specialDaysRateDropDown {
  max-width: 95px;
  min-width: 95px;
  max-height: 40px;
  min-height: 35px;
  background-color: #fff;
  font-size: 1em;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 60px;
  border-radius: 10px;
  text-align: right;
  color: #496375;
  margin-top: 0px;
  /* padding-left: 14px; */
  padding-right: 46px;
  margin-right: -24px;
  /* border: 2px solid #dde9f9; */
  font-weight: 500;
  /* cursor: pointer; */
}
.settingTitleWithQuery {
  margin-left: 15px;
  font-size: 14px;
}
.settingsBg {
  /* background-color: yellow; */
  max-height: 100vh;
  /* background-color: black; */
}

.profileTopContainer {
  background-color: #f4f8fe;
  /* background: rgb(20, 48, 81); */
  /* background: linear-gradient(
    0deg,
    rgba(20, 48, 81, 1) 43%,
    rgba(29, 69, 115, 1) 91%
  ); */
  display: flex;
  flex-wrap: wrap;
  min-width: 100vw;
  max-width: 100vw;
  height: 160px;
}

.topBarBgBehindCurveCorrected {
  background-color: #f4f8fe;
  min-height: 25px;
  min-width: 100vw;
  position: absolute;
  top: 160px;
  z-index: 0;
}

.profileTopInnerContainer {
  /* background-color: grey; */
  display: flex;
  margin-left: 5%;
  margin-top: 20px;
  align-items: center;
  text-align: left;
}

.profilePic {
  object-fit: cover;
  max-width: 40px;
  max-height: 40px;
  min-height: 40px;
  border-radius: 10px;
}

.nameAndTitleContainer {
  /* background-color: cyan; */
  margin-left: 10px;
  padding-bottom: 3px;
}

.profileFName {
  font-size: 24px;
  font-weight: 500;
  color: #143051;
}

.profileJobTitle {
  color: #859dbb;
  font-size: 1.1em;
  margin-top: 0px;
  font-weight: 500;
  /* line-height: 1em; */
}

.settingsOrAllowanceContainer {
  display: flex;
  font-size: 1.1em;
  width: 100%;
  margin-left: 7%;
}

.submenuSettings {
  margin-left: 0px;
  color: #143051;
  font-weight: 400;
  margin-top: 8px;
  cursor: pointer;
}

.submenuAllow {
  font-weight: 400;
  margin-top: 8px;
  color: #143051;
  cursor: pointer;
}

/* .submenuAllow::after {
  display: block;
  content: attr(title);
  font-weight: bold;
  height: 1px;
  color: transparent;
  overflow: hidden;
  visibility: hidden;
} */

.accountSettingMenuItemHolderAllowances {
  /* background-color: black; */
  min-width: 100px;
  max-width: 100px;
  display: flex;
  justify-content: center;
}
.accountSettingMenuItemHolderSettings {
  /* background-color: black; */
  min-width: 86px;
  max-width: 86px;
  display: flex;
  justify-content: center;
}
.accountSettingMenuItemHolderAbsence {
  /* background-color: black; */
  min-width: 84px;
  max-width: 84px;
  display: flex;
  justify-content: center;
}

.submenuSelected {
  font-weight: 500;
  border-bottom: 1px solid #143051;
  border-bottom: 2px #6987a9 solid;

  height: 29px;
  color: #143051;
  margin-bottom: -9px;
}

.SettingsContainer {
  /* mgr and admin size */
  background-color: rgb(253, 253, 255);
  max-height: calc(100% - 236px);
  overflow: scroll;
  width: 90vw;
  display: block;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  /* border-top: 1px #859dbb solid; */
  min-width: 100vw;
  padding-left: 5vw;
  padding-right: 5vw;
  /* border-radius: 20px 20px 0 0; */
  z-index: 1;
  border-top: 1px solid #e6eaee;
}

.whiteGradientAdmin {
  /* border-top: 1px solid #214978; */
  display: none;
}
.adminPageBackground {
  min-height: 100vw;
  min-width: calc(100vw - 662px);
  background-color: rgb(251, 251, 255);
}
/* Admin content container (sets height) */
.AdminSettingsContainer {
  background-color: #ebf0f7;

  overflow-y: scroll;
  padding-left: 1%;
  padding-right: 1%;
  display: block;
  min-height: calc(100vh - 147px);
  max-height: calc(100vh - 147px);
  min-width: 420px;
  max-width: 580px;
  z-index: 0;
}

.gpsAdminRow {
  border-radius: 10px;
  background-color: white;
  padding-bottom: 15px;
  margin-top: 10px;
  padding-left: 20px;
}
.gpsAdminRowMob {
  min-width: 94%;
  max-width: 94%;
  margin-left: 3.5%;
  padding-left:10px;
}

.AdminSettingsContainer::-webkit-scrollbar {
  /* display: none; */
  /* width: 5px !important;
  height: 4px !important; */
}

.AdminSettingsContainerMob {
  /* max-width: 100vw !important; */
  /* min-width: 100vw; */
  min-height: calc(100vh - 172px);
  max-height: calc(100vh - 172px);
  min-width: calc(100vw + 4px) !important;
  max-width: calc(100vw + 4px) !important;
  margin-left: -2px;
  border-radius: 20px 20px 0 0;
  border-top: 2px solid #143051;
  border-left: 2px solid #143051;
  border-right: 2px solid #143051;
  /* padding-top: 10px; */
  /* background-color: blue; */
}

.x3213456765 {
  /* border:none; */
  border-top: none;
  border-radius: 0;
}

.AdminSettingsContainerWoNav {
  background-color: rgb(251, 251, 255);
  max-height: calc(100% - 228px);
  overflow: scroll;
  width: 90vw;
  display: block;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
}

.SettingsContainerWithoutAccNav {
  /* background-color: yellow; */
  max-height: calc(100% - 218px);
  overflow: scroll;
  width: 90vw;
  display: block;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  min-width: 100vw;
  padding-left: 5vw;
  padding-right: 5vw;
  border-radius: 20px 20px 0 0;
  z-index: 1;
  background-color: white;
}

.SettingsContainer::-webkit-scrollbar {
  /* display: none; */
}

.AdminSettingsContainer::-webkit-scrollbar {
  /* display: none;  */
}

.SettingsInner {
  /* background-color: red; */
  margin-top: 10px;
}

/* SETTINGS ROW  */
.settingsRowContainer {
  min-width: 100%;
  min-height: 44px;
  /* background-color: lime; */
  border-bottom: 1px solid #ddebf4;
  font-size: 1em;
}

.AllowancesRowContainer {
  min-width: 100%;
  min-height: 44px;
  /* background-color: lime; */
  border-bottom: 1px solid #ddebf4;
}

/* last allowance container */
.AllowancesRowContainer:last-child {
  min-width: 100%;
  min-height: 44px;
  /* background-color: lime; */
  border-bottom: 0px solid #ddebf4;
}

.settingsRowContainer:last-of-type {
  border-bottom: 0px solid #fff;
}

.settingsRow {
  display: flex;
  /* max-width: 95%; */
  /* margin: auto; */
  margin-top: 14px;
  justify-content: space-between;
  color: #496375;
  color: #143051;
  /* background-color: cyan; */
  min-height: 44px;
  line-height: 44px;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  align-items: flex-start;
}

.settingValue {
  font-weight: 400;
  color: #516b8b;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: right;
  line-height: 18px;
  padding-left: 15px;
  margin-top: 9px;
}

.logoutText {
  background-color: #143051;
  /* margin: auto; */
  margin-top: 15px;
  color: white;
  border-radius: 30px;
  max-width: 90px;
  min-height: 35px;
  line-height: 34px;
  font-size: 14px;
  /* display: flex; */
  text-align: center;
  font-size: 1em;
  font-weight: 500;
}

.whiteGradient {
  position: fixed;
  left: 0;
  top: 160px;
  min-height: 6px;
  min-width: 100%;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(255, 255, 255, 1)
  );
  background-repeat: repeat-x;
  opacity: 0;
}

.rowWithSubText {
  /* background-color: yellow; */
  max-width: 85%;
  min-width: 85%;
}

.rowWithSubTextGPS {
  /* background-color: yellow; */
  max-width: 100%;
  min-width: 100%;
}

.narrowRowWithSubText {
  max-width: 60%;
  min-width: 60%;
}

.lookBackOnExplainerTxt {
  max-width: 60% !important;
  min-width: 60%;
  /* background-color: blue; */
}

.x92929948211 {
  display: none;
}
.allowYrSelectContainer {
  background-color: white;
  position: fixed;
  height: 50px;
  width: 80%;
}

/* knob */
/* switches for cog team request settings modal */
.knobContainer {
  width: 34px;
  /* min-height: 44px; */
  /* line-height: 44px; */
  /* background-color: black; */
  margin-top: 6px;
  cursor: pointer;
}

.tallerRowKnobContainer {
  /* background-color: black; */
  min-height: 20px;
  margin-top: 17px;
}
.knobBg {
  background-color: #dedce2;
  height: 18px;
  width: 27px;
  border-radius: 50px;
  /* position: fixed; */
  margin: auto;
  margin-top: -11px;
  margin-left: 5px;
}

.switchKnob {
  width: 18px;
  height: 18px;
  position: relative;
  top: 7px;
  border-radius: 50px;
  border: 1px solid #143051;
}

.knobOn {
  margin-left: 15px;
  background-color: #0077ff;
  transition: margin 0.075s ease-in-out;
}

.knobOnMgr {
  margin-left: 15px;
  background-color: #9d57e7;
  transition: margin 0.075s ease-in-out;
}

.knobOff {
  margin-left: 3px;
  background-color: #fff;
  border: 1px solid #9da6ac;
  transition: margin 0.075s ease-in-out;
}

.feedExportBtn {
  background-color: #496375;
  width: 75px;
  color: white;
  line-height: 25px;
  height: 25px;
  border-radius: 30px;
  font-size: 0.8em;
  opacity: 0.6;
}

.permissionsQuery {
  max-width: 15px;
  position: relative;
  z-index: 0;
  margin-left: 10px;
}

/* Allowances: the allowance span */
.allowanceText {
  color: #19a5c8;
  font-weight: 400;
}

.allowMeasureColourTurq {
  color: #19a5c8;
  font-weight: 400;
}

.allowanceNbr {
  font-weight: 400;
}

.allowancesInner {
  margin-top: 55px;
}

.allowancesResetInfoContainer {
  text-align: left;
  margin-top: -1px;
  text-indent: 2.5%;
  color: #143051;
  font-weight: 400;
  background-color: white;
  padding-top: 33px;
}

.resetDateAllowances {
  color: #496375;
  font-weight: 300;
  margin-left: 7px;
  font-size: 1.1em;
}

.allowsCont {
  /* background-color: yellow; */
  width: 73%;
}

.allowancesResetDivider {
  background-color: #ddebf4;
  height: 1px;
}

.resetAllowsTxt {
  margin-top: 6px;
}

.allowanceYrContainer {
  display: flex;
  justify-content: flex-start;
  color: #496375;
  font-weight: 400;
  margin-top: 15px;
  padding-bottom: 5px;
  margin-left: calc(2.5% - 5px);
  /* max-width: 90%; */
  /* display: none; */
}

.allowanceYrTxt {
  margin-left: 5px;
  line-height: 25px;
  width: 60px;
}

.allowSel {
  font-weight: 500;
  background-color: #8ea7c5;
  color: white;
  margin-left: 5px;
  width: 60px;
  line-height: 25px;
  border-radius: 15px;
}

.none {
  display: none;
}

.removeLogoImg {
  margin-left: 17px;
  min-width: 18px;
  margin-right: 6px;
}
.jammedSubBoxOvertime {
  display: flex;
  display: flex;
}

.overtimeJammedTxt {
  color: #496375;
  text-align: right;
  background-color: #e2e7ed;
  font-size: 0.8em;
  font-weight: 500;
  line-height: 16px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 0 0 10px 10px;
  max-width: 90%;
  margin: 0 auto;
  border-top: 1px #8ea7c5 solid;
}

.fadedTogglesOvertime {
  opacity: 0.5;
  pointer-events: none;
}

.loadingUserOpenImg {
  max-width: 30px;
  /* padding-left: 18px;
  padding-right: 18px; */
}

.none {
  display: none;
}

.adminsInfoContainer {
  /* background-color: #e0ebf8; */
  /* border-bottom: solid 1px #e0ebf8; */
  max-width: calc(100vw - 420px);
  margin: 0 auto;
  margin-top: 28px;
  /* height: 80px; */
  /* padding: 20px; */
  font-size: 1em;
  color: #496375;
  text-align: left;
  /* border-radius: 15px; */
  position: relative;
  /* text-align: left; */
  z-index: 2;
  margin-left: 40px;
  padding-right: 20px;
}

.adminsInfoContainerMob {
  margin-left: 40px;
  max-width: 90vw;
}

.companySettingsSpan {
  font-weight: 500;
}

.adminsDataContainer {
  max-width: calc(90vw - 40px);
  margin-left: 40px;
  overflow: scroll;
  min-height: calc(100vh - 113px);
  max-height: calc(100vh - 113px);
  margin-top: 20px;
  border-top: #d8dee6 solid 1px;
  padding-right: 10px;

  padding-top: 20px;
  padding-bottom: 230px;
  z-index: 999;
  /* background-color: yellow; */
}

.adminsDataContainerDesktop {
  max-width: 340px !important;
  min-height: calc(100vh - 180px);
  max-height: calc(100vh - 180px);
  margin-top: 20px;
  border-top: #d8dee6 solid 1px;
  /* background-color: orange; */
  padding-bottom: 60px;
  padding-right: 10px;
}

.adminUserRow {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color:#143051;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 10px;
  height: 40px;
  /* background-color: white; */
  border: 2px solid #dde9f9;
  border-radius: 5px;
  font-weight: 500;
  padding-left:10px;
}

.adminUserRow:first-of-type {
  margin-top: 0px;
}

.removeAdminBinImg {
  margin-right: 10px;
  min-width: 20px;
  cursor: pointer;
  padding: 5px;
}

.none {
  display: none;
}

.addNewAdminButton {
  color: white;
  border-radius: 5px;
  min-width: 50px;
  min-height: 36px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  max-height: 36px;
  display: flex;
  font-weight: 500;
  align-items: center;
  line-height: 30px;
  font-size: 1em;
  margin: 12px auto;
  padding: 10px;
  background-color: #07f;
  border: #0469de 2px solid;
  cursor: pointer;
}

.addNewAdminButton:active {
  -webkit-transform: scale(0.96);
          transform: scale(0.96);
}

.addAdminUsersModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
}

.addAdminUsersModalBox {
  background-color: #143051;
  color: white;
  min-width: 90vw;
  border-radius: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;
  padding-left: 25px;
  line-height: 45px;
}

.usersToAddAsAdminContainer {
  width: calc(100% - 25px);
  border-top: 1px rgb(255, 255, 255, 0.5) solid;
  padding-top: 10px;
  height: 80px;
  overflow: scroll;
  max-height: 65vh;
  min-height: 65vh;
  font-size: 1.1em;
}

.youColourAdmin {
  background-color: #214978;
  border: 2px solid #143051;
  color: white;
}

.fadeAdmins {
  opacity: 0.8;
  pointer-events: none;
}

.editTeamUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
}

.editTeamBox {
  background-color: #f4f8fe;
  /* background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(200, 200, 200, 1) 0%,
      rgba(255, 255, 255, 1) 9%,
      rgba(255, 255, 255, 1) 100%
    ); */
  min-width: 100vw;
  max-width: 100vw;
  min-height: 90vh;
  max-height: 90vh;
  height: 80vh;
  /* z-index: 999999222229999992222299999922222; */
  border-radius: 15px 15px 0 0;
  /* margin-top: -40px; */
  /* display: flex; */
  /* justify-content: center; */
  align-items: center;
  color: #143051;
  position: fixed;
  bottom: 0;
  /* z-index: 11; */
  /* z-index: 999999999999999999999999999999999999999999999999999999 !important; */
  text-align: center;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -webkit-animation-name: moveEditTeamUserBoxUp !important;
          animation-name: moveEditTeamUserBoxUp !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.specDayEditDateInput {
  border-radius: 5px;
  border: none;
  background-color: #fff;
  color: #496375;
  min-height: 35px;
  line-height: 35px;
  font-size: 16px !important;
  padding-left: 14px;
  padding-right: 8px;
  letter-spacing: 1px;
  padding-top: 1px;
  font-weight: 500;
  max-width: 174px;
  min-width: 174px;
  background: url(/static/media/calicongrey.9c7e1aba.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 142px;
  border: 2px solid #dde9f9;
  margin-left: 0px;
  margin-top: 10px;
  cursor: text;
  /* margin-bottom:-8px; */
}

.specDayEditDateInput:hover {
  border-color: #bbcada;
}

.editTeamModalHeader {
  /* background-color: yellow; */
  display: flex;
  justify-content: space-around;
  min-width: 100%;
  max-width: 100%;
  text-align: left;
  min-height: 30px;
  margin-top: 5px;
  padding-bottom: 10px;
}

.closeEditTeamImg {
  width: 15px;
  /* margin-top: 2px; */
  margin-left: -30px;
}

.leftTeam {
  margin-left: 50%;
  margin-top: 16px;
}

.editTeamNameTitle {
  margin-top: 13px;
  /* margin-left: -10px; */
  font-weight: 500;
  font-size: 1.3em;
  min-width: 76%;
  max-width: 76%;
  display: flex;
  justify-content: center;
}

.headerSideHolderTeam {
  min-width: 12%;
  max-width: 12%;
}

.editTeamNameIcon {
  /* margin-left: 12px; */
  width: 17px;
  margin-top: 16px;
  margin-left: 2px;
}

.none {
  display: none;
}

.editTeamNameInput {
  margin-top: 13px;
  /* background-color: pink; */
  font-weight: 500;
  font-size: 1.2em;
  min-width: 76%;
  max-width: 76%;
  display: flex;
  justify-content: center;
}

.saveTeamNameBtn {
  width: 24px;
  margin-top: 14px;
}

.teamNameInput {
  height: 28px;
  line-height: 28px;
  margin-top: 5px;
  text-align: center;
  color: #143051;
  font-size: 1em;
  border: none;
  padding-bottom: 12px;
  border-bottom: solid 2px #3b597c;
  font-weight: 500;
}

.teamNameInput::-webkit-input-placeholder {
  text-align: center;
}

.teamNameInput:-ms-input-placeholder {
  text-align: center;
}

.teamNameInput::placeholder {
  text-align: center;
}

/* middle of edit team admin modal */
.teamAdminMiddleContainer {
  /* background-color: cyan; */
  height: calc(100% - 52px);
  min-width: 300px;
  position: relative;
  display: flex;
}

.teamMiddleLeftCol {
  /* background-color: yellow; */
  min-width: 70%;
}

.teamMiddleRightCol {
  /* background-color: orange; */
  min-width: 30%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.panelContainer {
  /* background-color: red; */
  min-height: calc(100%);
  min-width: 60px;
  /* padding-bottom: -20px; */
}

.teamKeyContainer {
  /* background-color: pink; */
  display: block;
  justify-content: center;
  min-height: 40px;
  margin-bottom: 30px;
  position: fixed;
  bottom: 0;
  -webkit-animation-name: movePanelUp !important;
          animation-name: movePanelUp !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

@-webkit-keyframes movePanelUp {
  from {
    bottom: -100%;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes movePanelUp {
  from {
    bottom: -100%;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

.teamKeyRow {
  display: flex;
  margin-top: 16px;
  justify-content: center;
  /* background-color: blue; */
  max-width: 50px;
  min-width: 60px;
  max-width: 60px;
}

.lastTeamKeyRow {
  margin-top: 14px;
  padding-bottom: 30px;
}

.teamKeyImgIcon {
  min-width: 30%;
  max-width: 30%;
  margin-left: 8px;
}

.teamKeyValue {
  /* background-color: white; */
  min-width: 70%;
  max-width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;
  font-weight: 500;
}

.temKeySplitter {
  height: 1px;
  margin-top: 14px;
  width: 100%;
  background-color: #8997a8;
}

.addUserToTeamBtn {
  background-color: #3b597c;
  border: 2px solid #143051;
  font-size: 3em;
  color: white;
  width: 60px;
  height: 60px;
  line-height: 50px;
  border-radius: 100%;
  text-align: center;
  /* padding-bottom: 10px; */
}

.deleteTeamContainer {
  margin-left: 25px;
  margin-top: 15px;
}

.deleteTeamIconImg {
  width: 38px;
  margin-top: 15px;
}

.delTeamSure {
  width: 38px;
  margin-top: 15px;
  opacity: 1;
}

.delTeam {
  width: 38px;
  margin-top: 15px;
  opacity: 0.6;
  border-radius: 25px;
}

.teamUsersArrayContainer {
  background-color: white;
  padding-top: 15px;
  overflow: scroll;
  height: calc(100% - 35px);
  margin-top: 10px;
  padding-left: 0px;
  font-size: 1.1em;
  border: 2px #8ea7c5 solid;
  border-radius: 10px;
  margin-left: 15px;
  /* f4f8fe */
}

.teamUsersArrayContainer::-webkit-scrollbar {
  /* display: none; */
}

.arrayUserRow {
  background-color: white;
  max-width: 90%;
  display: flex;
  justify-content: space-between;
  padding-left: 6px;
  padding-right: 6px;
  margin: 0 auto;
  height: 50px;
  line-height: 50px;
  text-align: left;
  border-bottom: 1px solid #8997a8;
}

.borderLessUserInTeam {
  border: none !important;
}

.mgrUserDivider {
  border-bottom: 2px solid black;
}

.arrayUserRow:last-of-type {
  border: none;
}

.userFontColor {
  color: #19a5c8;
  color: #00ccff;
  color: #143051;
  font-weight: 500;
}

.mgrFontColor {
  color: #7d6d9f;
  color: #9a74eb;
  font-weight: 500;
}

/* specific user modal */
.specificUserUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
}

.specificUserModalBox {
  background-color: #c0d6f0;
  background-color: white;
  border-radius: 15px;
  min-width: 90vw;
  min-height: 105px;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.05);
}

.specificModalHeader {
  display: flex;
  justify-content: space-between;
  height: 45px;
}

.closeSpecific {
  margin-left: 17px;
  width: 12px;
}

.specificNameTitle {
  margin-top: 14px;
  font-weight: 500;
}

.specificMiddleContainer {
  /* background-color: orange; */
  max-width: 90%;
  margin: 0 auto;
  min-height: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.removeFromTeam {
  background-color: #818181;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: white;
  border-radius: 25px;
  margin-top: -8px;
  font-size: 0.9em;
  font-weight: 500;
}

.mgrToggler {
  /* background-color: yellow; */
  min-width: 45%;
  display: flex;
  justify-content: space-around;
}

.mgrToggler:last-of-type {
  margin-top: 8px;
}

.pinkKnob {
  background-color: #9a74eb;
}

.blueKnob {
  background-color: #00ccff;
}

.mgrUserTogglerContainer {
  display: block;
  /* margin-left: -20px; */
  background-color: #8997a8;
  background: linear-gradient(
    0deg,
    rgb(103, 128, 169) 0%,
    rgba(88, 112, 150, 1) 90%,
    rgba(69, 89, 120, 1) 100%
  );
  color: white;
  margin-top: 0px;
  padding-bottom: 8px;
  padding-top: 10px;
  max-width: 200px;
  margin-left: 15px;
  border-radius: 0 0 15px 15px;
  font-size: 0.9em;
  border: 2px solid #214978;
  border-top: none;
  max-width: 80%;
  margin-left: 6%;
}

.selfApproveKnob {
  margin-top: 0px !important;
}

.mgrTxt {
  /* margin-right: 12px; */
  font-size: 0.9em;
  line-height: 1.2em;
  margin-top: 10px;
  font-weight: 500;
  opacity: 0.8;
  max-width: 35%;
  min-width: 35%;
  text-align: left;
}

.selfApproveTxt {
  font-size: 0.9em;
  line-height: 1.2em;
  margin-top: 10px;
  font-weight: 500;
  opacity: 0.8;
  max-width: 50%;
  min-width: 50%;
  text-align: left;
  margin-right: -15%;
}

.addUserToTeamModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
}

.userListToAddToTeamBox {
  background-color: #143051;
  color: white;
  min-width: 90vw;
  border-radius: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;
  padding-left: 25px;
  line-height: 45px;
  /* max-height: 80vh; */
  /* height: 80vh; */
}

.addUserToTeamHeaderContainer {
  display: flex;
  justify-content: space-between;
  /* background-color: orange; */
  padding-bottom: 10px;
  margin-top: -10px;
}
.addUserToTeamTitle {
  text-align: center;
  margin-left: -20px;
  font-weight: 500;
}

.closeWhiteAddUserToTeamImg {
  min-width: 15px;
  max-width: 15px;
}

.userDropdown {
  /* background-color: red; */
  width: calc(100% - 25px);
  border-top: 1px rgb(255, 255, 255, 0.5) solid;
  padding-top: 10px;
  height: 80px;
  overflow: scroll;
  max-height: 65vh;
  min-height: 65vh;
  font-size: 1.1em;
}

.addNewTeamBtn {
  position: fixed;
  top: calc(100% - 188px);
  right: 45px;
  background-color: #3b597c;
  border: 2px solid #143051;
  color: white;
  border-radius: 100%;
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  line-height: 42px;
  font-size: 2.5em;
  z-index: 99999;
}

/* modal for mgrs who are not a user */
.MgrNotUserKeyModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
}

.mgrNotUserModalBox {
  background-color: white;
  border-radius: 15px;
  max-width: 80vw;
  position: relative;
  /* z-index: 999999999999; */
}

.mgrNotUserHeading {
  color: #143051;
  font-size: 1.1em;
  max-width: 80%;
  margin: 0 auto;
  padding-top: 20px;
  line-height: 22px;
  padding-bottom: 20px;
  font-weight: 500;
}

.mgrsNotUsersContainer {
  padding-bottom: 30px;
}

.mgrNotUserRow {
  line-height: 35px;
  color: #7d6d9f;
}

.closeMgrsNotUsers {
  font-size: 0.8em;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 2px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  line-height: 26px;
  border-radius: 20px;
  min-height: 30px;
  max-width: 60px;
  margin: 0 auto;
  margin-top: -10px;
  margin-bottom: 20px;
}

/* teamless modal */
.teamlessUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
}

.teamlessModalBox {
  background-color: white;
  border-radius: 15px;
  max-width: 80vw;
  min-width: 80vw;
}

.teamlessUserTxtRow {
  line-height: 35px;
  color: #8997a8;
  font-weight: 500;
  font-size: 1.2em;
}

.mgrKnobContainer {
  margin-top: -10px;
  padding-top: 4px;
  /* padding-bottom: 2px; */
  /* background-color: black; */
}

.knobBgMgr {
  background-color: #c0d6f0;
}

.knobBgMgrOff {
  background-color: #c0d6f0;
}

.selectedUserInTeam {
  position: relative;
  margin-left: 5px;
  /* z-index: -1; */
  -webkit-animation-name: slideSubBarDownNoZoom !important;
          animation-name: slideSubBarDownNoZoom !important;
  -webkit-animation-duration: 0.04s !important;
          animation-duration: 0.04s !important;
}

.selectedUserInTeamRow {
  background-color: #143051;
  padding-left: 18px;
  border-radius: 0 0 10px 10px;
  border: none;
  /* color: white; */
}

.whiteNameColourTxt {
  color: white;
}

/* modal for mgrs who are not a user */
.addedUserTeamStatusModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
}

.addedUserTeamStatusModalBox {
  background-color: white;
  border-radius: 15px;
  max-width: 80vw;
  min-width: 80vw;
  min-height: 170px;
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  color: #143051;
}

.userTeamStatusContent {
  /* background-color: pink; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 80%;
  margin: 10px auto;
  padding-bottom: 14px;
  /* margin-bottom: 10px; */
}

.makeTeamNameBold {
  font-weight: 500;
}

.addedUserTeamStatusTxt {
  margin-top: 28px;
}

.adminUsersPathSpan {
  font-weight: 500;
  font-style: italic;
  color: #58467e;
}

.closeTeamStatusModalBtnContainer {
  /* max-width: 80px; */
  margin: 0 auto;
  margin-top: 25px;
  display: flex;
  justify-content: space-around;
  max-width: 200px;
}

.addedUserTeamArrItem {
  font-weight: 500;
}

.addedUserTeamArrItemInBetween {
  font-weight: 400;
  opacity: 0.8;
}

.generateTeamNamesSpans {
  /* background-color: yellow; */
  /* display: flex; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  width: 100%;
  margin-top: 4px;
}

.yesChangePTeamBtn {
  font-size: 0.8em;
  color: #fff;
  background-color: #0077ff;
  border: solid #143051 2px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  line-height: 26px;
  border-radius: 20px;
  min-height: 30px;
  min-width: 70px;
}

.noChangePTeamBtn {
  font-size: 0.8em;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 2px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  line-height: 26px;
  border-radius: 20px;
  min-height: 30px;
  min-width: 70px;
}

.paddingBottomForYesNoTeamStatusModal {
  padding-bottom: 30px;
  padding-top: 10px;
}

.dsInputForUpdateShiftsFwd {
  margin-top: 25px;
}

.updateDontUpdateBtnCont {
  /* background-color: yellow; */
  min-width: 220px;
}

.disableUpdateBtn {
  opacity: 0.4;
  /* pointer-events: none; */
}

.transferShiftsToTeamDropdown {
  max-width: 277px;
  min-width: 80%;
  background: url(/static/media/downArrowWhite.97cd93aa.svg) no-repeat right #687d97;
  -webkit-appearance: none;
  background-position-x: 92%;
  border-radius: 100px;
  color: white;
  padding-left: 14px;
  font-size: 1em;
  text-align: left;
  min-height: 35px;
  line-height: 30px;
  border: 2px solid #36495f;
  font-weight: 500;
  margin-top: 30px;
}

.transferFromTxt {
  margin-top: 15px;
  margin-bottom: -30px;
  font-weight: 500;
}

.teamStatusDivider {
  min-height: 1px;
  max-height: 1px;
  background-color: #143051;
  min-width: 80%;
  max-width: 80%;
  margin: 0 auto;
  margin-bottom: -10px;
  margin-top: 25px;
}

.disablePointerEvents {
  pointer-events: none;
}

.absenceAdminContainer {
  min-height: 100%;
  position: relative;
  /* background-color: black; */
  z-index: 99999;
  /* overflow: scroll; */
  /* width: 90vw; */
  /* display: block; */
  /* position: absolute; */
  /* margin: auto; */
  /* left: 0; */
  /* right: 0; */
}
.innerAbsence {
  /* background-color: lime; */
  height: 100%;
}

.overtimeContentContainer {
  /* background-color: pink; */
  max-width: 88%;
  min-width: 88%;
}

.overtimeContentContainerSelfLog {
  max-width: 88%;
  /* background-color: orange; */
}

.overtimeIndividualTableContainer .absenceRowMainText {
  text-align: left;
  /* font-size: 2em; */
  /* background-color: blue; */
  line-height: 24px;
  padding-bottom: 14px;
  max-width: 80%;
  /* margin-bottom: 4px; */
}

.absenceRowSubText {
  /* font-size: 0.85em; */
  font-size: 12px;
  text-align: left;
  /* margin-bottom: -0px; */
  margin-top: 0px;
  padding-left: 15px;
  color: #516b8b;
  /* background-color: blue; */
  /* max-width: 90%;
  min-width: 90%; */
  line-height: 16px;
  /* line-height: 20px; */
  font-weight: 400;
  /* max-width: 40%; */
}

.absenceTypesContainer {
  /* background-color: yellow; */
  height: auto;
  display: block;
}

.absenceAdminRow {
  /* background-color: yellow; */
  /* padding-bottom: 14px; */
}

.absenceKnobContainer {
  /* background-color: black; */
  margin-right: 12px;

  /* padding-right: 31px; */
}

.textLeft {
  text-align: left;
}

.absenceTypesHolder {
  padding-top: 16px;
  padding-bottom: 20px;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 4px;
}

.absenceTypeUnit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  max-width: 310px;
  min-width: 310px;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 5px;
  height: 40px;
  background-color: #f7fbff;
  color: #214978;
  border: 2px solid #dde9f9;
  margin-top: 10px;
  /* box-shadow: 0px 5px 4px -2px rgb(0 0 0 / 10%); */
}

.absenceTypeUnit:first-of-type {
  margin-top: 0px;
}

.nonRemoveable {
  background-color: #214978;
  color: white;
  border-color: #143051;
}

.absenceTypeName {
  /* background-color: orange; */
  line-height: 35px;
  font-size: 0.95em;
  min-width: 70%;
  text-align: left;
  text-indent: 14px;
}

.absenceTypePaid {
  /* background-color: lime; */
  min-width: 88px;
  margin-left: -50px;
  /* padding-right: 5px; */
  /* margin-left: -22px; */
  font-size: 0.95em;
  /* font-weight: 500; */
  text-align: right;
}

.absenceTypeBinImg {
  max-width: 18px;
  margin-right: 14px;
  cursor: pointer;
}

.addAbsenceTypeBtn {
  background-color: #0077ff;
  color: white;
  border-radius: 5px;
  min-width: 50px;
  min-height: 30px;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  max-height: 30px;
  display: flex;
  align-items: center;
  line-height: 30px;
  font-size: 0.9em;
  margin: 12px auto;
  margin-left: 6px;
  padding: 8px;
  border: 2px solid #143051;
  cursor: pointer;
  font-weight: 500;
}
.addAbsenceTypeBtn:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}

/* add absence type modal */
.addAbsenceTypeModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 999999999999999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
}

@keyframes underlayFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.addAbsenceModalBox {
  background-color: #fff;
  min-width: 338px;
  max-width: 338px;
  height: 230px;
  margin-top: -10vh;
  border-radius: 15px;
  display: block;
  /* justify-content: center; */
  /* align-items: flex-start; */
  padding-top: 14px;
  color: white;
  text-align: center;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
}

.addAbsenceModalHeader {
  /* background-color: lime; */
  min-width: 100%;
  height: 20px;
  display: flex;
  justify-content: space-between;
}

.closeAddAbsence {
  max-width: 12px;
  /* background-color: orange; */
  margin-left: 18px;
}

.addAbsenceTitle {
  margin-left: 6px;
  line-height: 14px;
  padding-top: 6px;
  font-size: 1.1em;
  font-weight: 500;
  color: #143051;
}

.addAbsenceBodyContainer {
  /* background-color: red; */
  max-width: 90%;
  margin: 0 auto;
  margin-top: 15px;
}

.absenceName {
  text-align: left;
  font-size: 1.2em;
  height: 24px;
  /* background-color: orange; */
}

.absenceNameInput {
  margin-top: 0px;
  width: 86%;
  background-color: #fff;
  border: none;
  border-bottom: solid 1px #143051;
  color: #143051;
  font-size: 1.1em;
  font-weight: 500;
  padding-bottom: 10px;
  padding-top: 2px;
  text-indent: 3%;
  height: 30px;
  line-height: 43px;
}

.absenceQuestionInput {
  margin-top: 20px;
  width: 86%;
  background-color: #fff;
  border: none;
  border-bottom: solid 1px #143051;
  color: #143051;
  font-size: 1.1em;
  font-weight: 500;
  padding-bottom: 10px;
  padding-top: 2px;
  text-indent: 3%;
  height: 30px;
  line-height: 43px;
}

.submitAbsenceType {
  background-color: #8997a8;
  width: 60px;
  min-height: 26px;
  line-height: 26px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 30px;
}

.addAbsenceTypeModalFooter {
  display: flex;
  min-width: 100%;
  margin-top: 16px;
  /* background-color: orange; */
  justify-content: space-around;
}

.addAbsenceTypeBack {
  line-height: 26px;
  font-size: 0.9em;
  margin-top: 2px;
  margin-left: -8px;
  /* margin: 0 auto; */
}

.paidContainer {
  min-height: 68px;
  max-height: 68px;
  /* background-color: pink; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.unpaidPaidHolder {
  /* background-color: lime; */
  min-width: 200px;
  display: flex;
  justify-content: space-around;
}

.paidTxt {
  margin-top: 1px;
  /* background-color: red; */
  min-width: 60px;
  max-width: 60px;
  font-size: 1.1em;
  font-weight: 500;
  color: white;
}

.paidTxtL {
  text-align: right;
  margin-right: 0px;
  min-width: 66px;
  max-width: 66px;
}

.paidTxtR {
  text-align: left;
  margin-left: 0px;
  min-width: 66px;
  max-width: 66px;
}

.paidUnselected {
  opacity: 0.7;
  font-weight: 400;
  font-size: 0.9em;
  margin-top: 3px;
  color: white;
}

.submitAbsenceTypeSave {
  font-size: 0.9em;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 2px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  line-height: 25px;
  border-radius: 10px;
  min-height: 30px;
  max-width: 64px;
  margin: 0px auto;
}

.redDeleteAbsence {
  background-color: #ba0000;
  color: white;
  border: none;
}

.cancelDelAbsenceType {
  position: relative;
  margin-right: 50px;
  width: 20px;
  display: none;
  /* margin-left: 10px; */
}

.addAbsenceAddBtnFooterHolder {
  /* background-color: yellow; */
  min-width: 300px;
  display: flex;
  /* justify-content: flex-start; */
}

.paidKnob {
  margin-top: -5px;
}

.trashRightAbit {
  margin-right: 12px;
}

.rtwQContainer {
  /* background-color: lime; */
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  /* min-height: 100px; */
}

.rtwQUnit {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  line-height: 40px;
  color: #496375;
  border-radius: 6px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  /* border: 2px solid #5f7792; */
  align-items: center;
  border: solid 2px #dee5ed;
  padding-left: 13px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.rtwQUnit:first-of-type {
  margin-top: 0px;
}

.rtwQtxt {
  font-size: 12px;
  text-align: left;
  margin-right: 10px;
  max-width: 210px;
  line-height: 18px;
}

.removeQuestionImg {
  margin-right: 9px;
  min-width: 18px;
  cursor: pointer;
  padding: 4px;
  max-width: 16px;
}

.removeQuestionImg:hover {
  opacity: 0.75;
}

.questionsTitle {
  color: #496375;
  padding-bottom: 10px;
  text-align: right;
  margin-top: 10px;
  padding-right: 10px;
}

.questionsWrapper {
  float: right;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  align-items: flex-end;
}

.addQuestionBtn {
  margin-right: 0px;
  min-width: 118px;
  font-size: 13px;
  padding-left: 9px;
  margin-top: 15px;
}

.annLeaveBox {
  min-width: 94%;
  padding-left: 20px;
  padding-right: 40px;
  min-height: 44px;
  padding-bottom: 30px;
  background-color: white;
  border-radius: 10px;
  max-width: 94%;
  margin-left: 3.5%;
}

.annLeaveBoxTIL {
  margin-top: 18px;
  padding-bottom: 15px;
  background-color: white;

  /* animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important; */
}

.annLeaveHeader {
  display: flex;
  justify-content: space-between;
  min-width: 105%;
}

.annLeaveTitle {
  margin-left: 14px;
  margin-top: 27px;
  font-size: 14px;
  color: #496375;
  color: #143051;
  text-align: left;
  font-weight: 500;
}

.minimiseAnnLeaveImgOpen {
  margin-right: 9px;
  margin-top: 0px;
  max-width: 9px;
  margin-top: -2px;
}

.minimiseAnnLeaveImgClosed {
  margin-right: 25px;
  margin-top: 0px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.minimiseCustomLeaveImgClosed {
  margin-right: 25px;
  margin-top: 17px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.minimiseCustomLeaveImgOpen {
  margin-right: 22px;
  margin-top: 17px;
}

.annLeaveRow {
  /* background-color: yellow; */
  max-width: 94%;
  margin: 0 auto;
  /* margin-top: 15px; */
  color: #143051;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddebf4;
}
.annLeaveRowBlock {
  display: block;
}

.annLeaveBoxDesktop {
  min-width: 100% !important;
  margin-left: 0px;
}

.annLeaveRow:last-of-type {
  border-bottom: none;
}

.annLeaveRowUpper {
  /* background-color: lime; */
  border-bottom: 1px solid #ddebf4;
  /* min-height: 40px; */
  display: flex;
  margin-top: -4px;
  padding-bottom: 10px;
  color: #496375;
  justify-content: space-between;
}

.leaveReqbold {
  font-weight: 500;
  color: #496375;
  /* background-color: orange; */
  min-width: 85px;
}

.annLeaveUpperTxt {
  margin-left: 10px;
  display: flex;
  font-size: 14px;
  color: #496375;
}

.queryLeaveTypesImg {
  min-width: 22px;
  margin-left: 16px;
}

.annLeaveRowLower {
  margin-top: 12px;
  font-size: 16px;
  display: flex;
  font-weight: 500;
  flex-direction: column;
  align-items: flex-start;
}
.annLeaveLowerTxt1 {
  line-height: 30px;
  padding-right: 7px;
}
.annLeaveLowerTxt2 {
  line-height: 17px;
  padding-left: 2px;
  font-weight: 500;
  font-size: 12px;
  color: #496375;
  margin-top: 3px;
}

.annLeaveLowerTxt3 {
  line-height: 17px;
  padding-left: 2px;
  font-weight: 500;
  font-size: 14px;
  color: #496375;
  margin-top: 6px;
}
.minsPerHourDropdown {
  max-width: 75px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 50px;
  border-radius: 10px;
  color: #5d6f85;
  padding-left: 14px;
  text-align: left;
  height: 30px;
  /* background-color: #98a1ac; */
  border: 2px solid #dde9f9;
  font-size: 1.1em;
  font-weight: 500;
}

.minsPerHourDropdownWider {
  max-width: 75px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 55px;
  border-radius: 100px;
  color: #5d6f85;
  padding-left: 14px;
  text-align: center;
  height: 30px;
}

.daysOrHoursCustomLeavDropdown {
  max-width: 90px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 65px;
  border-radius: 10px;
  color: #5d6f85;
  padding-left: 14px;
  text-align: left;
  height: 30px;
  /* background-color: #98a1ac; */
  border: 2px solid #dde9f9;
  font-size: 1.1em;
  font-weight: 500;
}

.minsPerHourDropdownWidth {
  color: #5d6f85;
  font-weight: 500;
  border-radius: 5px;
  background-color: white !important;
  min-height: 35px;
  font-size: 1em;
  min-width: 77px;
  max-width: 77px;
  font-size: 16px;
  text-align: left !important;
  line-height: 30px;
  border: 0;
  height: 30px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
    #214978;
  -webkit-appearance: none;
  background-position-x: 50px;
  border: 2px solid #dde9f9;
  margin-right: 0px;
  padding-left: 14px;
  cursor: pointer;
}

.tilWkDrop {
  background-position-x: 95px;
  min-width: 118px;
  max-width: 118px;
  padding-left: 9px;
  font-size: 14px;
  margin-top: 30px;
}

@media only screen and (min-width: 960px) {
  .tilWkDrop {
    margin-left:60px
  }
}


.allowanceInput {
  max-width: 50px;
  /* background: url('../img/general/downArrowWhite.svg') no-repeat right #214978; */
  -webkit-appearance: none;
  background-position-x: 37px;
  border-radius: 100px;
  color: white;
  padding-left: 14px;
  text-align: center;
  height: 30px;
}

.typeOfUserLoyaltyHeading1 {
  margin-top: 20px;
  font-size: 0.8em;
  text-align: left;
  margin-left: 15px;
  color: #fff;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #93a7b6;
  padding-left: 6px;
  border-radius: 5px;
  max-width: 120px;
}

.typeOfUserLoyaltyHeading2 {
  margin-top: 40px;
  font-size: 0.8em;
  text-align: left;
  margin-left: 15px;
  color: #fff;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #93a7b6;
  padding-left: 6px;
  border-radius: 5px;
  max-width: 128px;
}

.annLeaveLoyaltyTextLeftAbit {
  margin-left: 30px;
}

.leaveReqLimit {
  text-align: right;
}

.adminTilKnob {
  margin-right: 18px;
  margin-top: 12px;
}

.leaveTypeDivider {
  min-height: 1px;
  background-color: #8997a8;
  border-radius: 100px;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.tilExplainer {
  font-size: 14px;
  color: #8997a8;
  text-align: left;
  margin-left: 13px;
  margin-top: 13px;
  max-width: 70%;
  font-weight: 400;
}

.customLeaveBox {
  background-color: #fff;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  cursor: pointer;
}

.editCustomLeaveNameImg {
  width: 14px;
  margin-left: 12px;
}

.customLeaveTopRightIconsContainer {
  /* background-color: lime; */
  display: flex;
  min-width: 70px;
  justify-content: space-between;
}

.customLeaveTitle {
  display: flex;
  align-items: center;
}

.customLeaveMinimiserHolder {
  /* background-color: blue; */
  margin-top: 4px;
  /* padding-top: -4px; */
}

.minimiseAnnLeaveImgOpenCustomLeave {
  margin-top: 17px;
}

.greyPowerUnpublishCustomLeaveImg {
  width: 16px;
  margin-top: 14px;
  /* background-color: white; */
  /* padding-top: 4px; */
  /* padding-bottom: -4px; */
  margin-right: 14px;
}

.customLeaveRowLower {
  /* margin-top: 20px; */
  justify-content: space-between;
}

.customRowLower {
  margin-top: 0px;
}

.customLeaveRateContainer {
  /* background-color: yellow; */
  display: flex;
  margin-right: 8px;
  margin-top: 10px;
}
.customLeaveRateTxt {
  line-height: 30px;
  margin-right: 8px;
}

.paidToggleContainer {
  display: flex;
  height: 30px;
  line-height: 30px;
  margin-top: 10px;

  background-color: white;
  /* border: 1px solid #516b8b; */
}

.paidToggleContainerTxtSelected {
  background-color: #0077ff;
  color: white;
  font-weight: 500;
  padding-left: 13px;
  padding-right: 13px;
  border: 2px solid #143051;
  border-radius: 10px;
  z-index: 1;
  font-size: 1.1em;
  line-height: 26px;
  /* animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important; */
}

.paidToggleContainerTxt {
  background-color: #ddebf4;
  padding-left: 26px;
  padding-right: 10px;
  margin-left: -20px;
  max-height: 26px;
  line-height: 22px;
  margin-top: 2px;
  color: #606c7a;
  border-radius: 10px;
  font-size: 0.95em;
  color: #516b8b;
  border: solid 2px #516b8b;
  font-weight: 500;
}

.unpaidToggleContainerTxtSelected {
  background-color: #72869e;
  color: white;
  font-weight: 500;
  padding-left: 15px;
  padding-right: 15px;
  border: 2px solid #516b8b;
  border-radius: 10px;
  z-index: 1;
  font-size: 1.1em;
  line-height: 26px;
  /* animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important; */
}

.unpaidToggleContainerTxt {
  background-color: #ddebf4;
  padding-left: 14px;
  color: #214978;
  padding-right: 24px;
  margin-right: -18px;
  max-height: 26px;
  line-height: 24px;
  margin-top: 2px;
  border-radius: 10px;
  font-size: 0.9em;

  color: #214978;
  border: solid 2px #516b8b;
}

.customLeaveRateDropdown {
  max-width: 80px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 54px;
  border-radius: 10px;
  color: #5d6f85;
  padding-left: 14px;
  text-align: left;
  height: 30px;
  /* background-color: #98a1ac; */
  border: 2px solid #dde9f9;
  font-size: 1.1em;
  font-weight: 500;
}

.removeCustomLeaveTypeContainer {
  /* background-color: pink; */
  display: flex;
  margin-top: 4px;
  justify-content: flex-end;
}

.removeCustomLeaveTypeBtn {
  margin-right: 10px;
  margin-top: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  font-weight: 500;
  background-color: #8ea7c5;
  border: 2px solid #475974;
  color: white;
  font-size: 0.9em;
  min-width: 84px;
  max-width: 84px;
}

.removeCustomLeaveTypeBtnSure {
  margin-right: 10px;
  margin-top: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  font-weight: 500;
  background-color: #0077ff;
  border: 2px solid #143051;
  color: white;
  font-size: 0.9em;
  min-width: 84px;
  max-width: 84px;
}

.hello {
  text-align: right;
  position: relative;
  right: 0px;
}

.defaultCalcKnob {
  /* background-color: orange; */
  margin-top: -4px;
  margin-right: 11px;
}

.defCalcTxt {
  /* background-color: red; */
  min-width: 160px;
  font-weight: 400;
  text-align: left;
}

.annLeaveUpperTxtSpaceBetween {
  display: flex;
  /* background-color: yellow; */
  min-width: 96%;
  justify-content: space-between;
  margin-left: 10px;
  font-size: 1em;
  margin-top: 0px;
  line-height: 1.5em;
}

.defCalcQcombined {
  /* background-color: pink; */
  min-width: 200px;
  display: flex;
}

.queryDefCalc {
  margin-left: 9px;
}

.heightWhenDefaultCalcEnabled {
  /* background-color: purple; */
  max-height: 50px;
  border: none;
  /* margin-top: -20px; */
}

/* edit custom leave type name modal */
.editCustomLeaveTypeNameUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;

  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
}

.editCustomLeaveTypeNameBox {
  background-color: #fff;
  min-width: 90vw;
  max-width: 90vw;
  height: 180px;
  margin-top: -10vh;
  border-radius: 15px;
  display: block;
  /* justify-content: center; */
  /* align-items: flex-start; */
  padding-top: 14px;
  color: white;
  text-align: center;
  border: 2px solid #dae1eb;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.saveBtnForEditNameLeaveType {
  margin-top: 8px;
}

.enabledOrDisabledLeaveTypes {
  /* background-color: orange; */
  line-height: 20px;
  margin-top: 20px;
  color: #a0b9da;
  display: flex;
  font-weight: 500;
  justify-content: flex-end;
  /* padding-left: 4px; */
}

.enabledLeaveTypes {
  /* margin-left: 5%; */
  margin-right: 15px;
}

.disabledLeaveTypes {
  /* margin-right: 5%; */
}

.selectedLeaveTypes {
  font-weight: 500;
  color: #143051;
}

.customLeaveTypesTitle {
  font-size: 1.1em;
  /* margin-top: 20px; */
  color: white;
  /* max-width: 90%; */
  opacity: 0.7;
  margin: 0 auto;
  border-radius: 10px;
  line-height: 50px;
  background-color: #143051;
  margin-top: 10px;
}

.unpublishedBg {
  background-color: #e8e8e9;
}

.darkBottomBorder {
  border-bottom: 1px solid #aaaeb3;
}

.reduceOpac {
  opacity: 0.5;
}

.paidRowContainer {
  display: flex;
  /* background-color: lime; */
  min-width: 100%;
  justify-content: space-between;
}

.paidTypeBoxL {
  /* margin-left: -10px; */
}

.leaveTypePaidTitle {
  text-align: left;
  margin-bottom: -4px;
  margin-left: 9px;
  color: #516b8b;
  font-size: 1.2em;
  padding-bottom: 4px;
  font-weight: 400;
}

.leaveTypeRate {
  text-align: right;
  margin-right: 22px;
}

.heightWhenIncrementEnabled {
  /* background-color: black; */
  max-height: 66px;
}

.addLeaveTypeBtn {
  background-color: #0077ff;
  color: white;
  border-radius: 5px;
  min-width: 50px;
  min-height: 30px;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  max-height: 30px;
  display: flex;
  align-items: center;
  line-height: 30px;
  font-size: 0.9em;
  margin: 12px auto;
  padding: 8px;
  border: 2px solid #143051;
}

.aaa {
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.enabledSpan {
  color: #516b8b;
  min-width: 45px;
  font-size: 0.7em;
  border-radius: 30px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.customLeaveTypesContainer {
  min-height: 300px;
  margin-bottom: 50px;
}

.annLeaveIllustrationBox {
  margin-top: -14px;
  /* border: 2px solid #ddebf4; */
  /* padding: 10px; */
  border-radius: 5px;
  /* padding-top: 0px; */
  /* max-width: 248px; */
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.annleaveIllustrationTable {
  /* background-color: yellow; */
  min-width: 160px;
  max-width: 190px;
  /* max-width: 70%; */
  /* margin-left: 10px; */
  /* margin-top: 20px; */
  /* padding-right: 3px; */
}

.illusRow {
  display: flex;
  max-width: 190px;
  min-width: 160px;
  border-bottom:1px solid #bbcada
  /* padding-right: 8px; */
  /* background-color: blue; */
}

.illusTitleHeader {
  font-weight: 500;
  font-size: 10px;
  text-align: left;
  min-width: 48%;
  background-color: #8ea7c5;
  color: white;
  border-radius: 5px;
  line-height: 13px;
  max-width: 49%;
  margin-left: 2px;
  display: flex;
  /* padding-left: 12px; */
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 4px;
  padding-right: 4px;
}

.plusPholsTitle {
  color:#516b8b
}

.illusTitleHeader:first-child {
  border-radius: 5px 0 0 0px;
}

.illusTitleHeader:last-child {
  border-radius: 0px 5px 0px 0px;
}

/* todo: media query illusTitleHeader above for iphone5 */

.illusRowHighlighted {
  background-color: #f4f9ff;
  /* max-width: 97%;
  min-width: 97%; */
}

.annLeaveVisualiserContainer {
  display: flex;
  justify-content: space-between;
  margin-top: -4px;
  cursor: pointer;
}
.topIllusRow {
  min-width: 100%;
  border:none;
  max-width: 100%;
}

.illusTitle,
.illusContent {
  min-width: 50%;
  color: #496375;
  font-size: 11px;
  margin-top: 6px;
  margin-bottom: 6px;
  text-align: center;
}

.customAllowanceTxt {
  padding-top: 3px;
  padding-left: 0px;
  /* color: #143051; */
  text-indent: 14px;
  /* font-size: 0.9em; */
  font-weight: 400;
}

.noneLeaveType {
  display: none;
}

.pullCustomAllowanceUpAbit {
  margin-top: 0px;
}

.noBorder {
  border: none !important;
}

.disableCustomLeaveContent {
  opacity: 0.3;
  pointer-events: none;
}

.customLeaveDaysOrHoursDiv {
  /* display: flex; */
  /* justify-content: space-between; */
  max-width: 90%;
  margin: 0 auto;
  margin-top: -8px;
  font-size: 0.7em;
  padding-right: 5px;
  /* margin-right: 10px; */
}

.customLeavesTitle {
  margin-left: 20px;
  margin-top: 17px;
  font-size: 1.1em;
  color: #496375;
  text-align: left;
  font-weight: 500;
}

.absenceKnob {
  margin-right: 3px;
}

.regenDayRatesContainer {
  display: flex;
  justify-content: space-between;
  /* background-color: yellow; */
  border: 1px solid #bbcada;
  border-radius: 5px;
  background-color: #f7fbff;
  font-size: 14px;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  align-items: center;
  margin-bottom: 20px;
  max-width: 330px;
  margin-right: 20px;
  /* min-width: 440px; */
}

.updateRateShiftsTxt {
  max-width: 70%;
  font-size: 12px;
  text-align: left;
  font-weight: 400;
  color: #143051;
  line-height: 16px;
  max-width: 60%;
}

.rateDaySpanBold {
  font-weight: 500;
}

.regenRateBtn {
  color: white;
  min-height: 30px;
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.regenRateBtn:hover {
  background-color: #bbcada;
}
.regenUpdateParent {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.loadingRegenDay {
  font-size: 14px;
  font-weight: 500;
  color: #07f;
  margin-top: 14px;
  margin-bottom: 7px;
  margin-right: 5%;
}

.closedDaysTimelineMainBody {
  max-width: 90%;
  margin: 0 auto;
  min-height: 400px;
  max-height: 400px;
  overflow: scroll;
  padding-bottom: 20px;
  border-radius: 5px 5px 0px 0px;
  background-color: white;
  border-top: solid 2px #e6eaee;
  border-bottom: solid 2px #e6eaee;
}
.historicalClosedDaysTxt {

  padding-bottom: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 6px;
  color: #516b8b;
}

.historyDayUnit {
  display: flex;
  min-width: 100%;
  min-height: 60px;
  max-height: 60px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c0d6f0;
}

.historyDayTitle {
  font-size: 14px;
  margin-left: 25px;
  line-height: 22px;
  color: #214978;
  font-weight: 500;
  min-width: 125px;
  min-height: 22px;
  text-align: left;
}

.historyDataTxtStatus {
  margin-right: 12px;
  font-size: 14px;
  color: #00aaff;
  font-weight: 500;
  text-align: center;
  min-width: 52px;
}

.historyDataTxtStatusClosed {
  color: #bbcada;
}

.knobContainerHistoryDay {
  margin-top: 0px;
  cursor: pointer;
  border: 1px solid #e6eaee;
  padding-bottom: 8px;
  padding-right: 37px !important;
  border-radius: 5px;
  min-width: 35px;
  max-width: 35px;
  max-height: 35px !important;
  min-height: 35px !important;
}

.historySunBorder {
  border-bottom: 2px solid #7495ba;
}

.historyDayUnit:last-child {
  border: none;
}

.historyDayUnitMain {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  min-width: 170px;
}

.historyDayUnitpublicHol {
  color: #0cf;
  font-size: 10px;
  text-align: left;
  margin-left: 25px;
  max-width: 150px;
  line-height: 11px;
  text-align: left;
  /* min-height:50px; */
  /* margin-top: -14px; */
}

.closedDaysHistoryModalHeader {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-top: 19px;
  padding-right: 20px;
}

.selfApproveTitleTxt {
  /* max-width: 220px; */
  margin-left: 0px !important;
  /* margin-bottom: 10px; */
}

.selfApproveRow {
  min-height: 160px !important;
  max-height: 160px !important;
  padding-bottom: 15px;
}

.selfApproveTxtParent {
  /* background-color: yellow; */
  max-width: 70%;
  margin-left: 5%;
}

.selfApproveExplain {
  font-size: 14px;
  text-align: left;
  margin-top: 10px;
  color: #8997a8;
  /* margin-left: 5%; */
}

.selfApproveKnob {
  margin-right: 6% !important;
}

.adminWrapper {
  display: flex;
}

@-webkit-keyframes expandWidth {
  0% { min-width: 0; width: 0; }
  100% { min-width: 160px; width: 160px; }
}

@keyframes expandWidth {
  0% { min-width: 0; width: 0; }
  100% { min-width: 160px; width: 160px; }
}
.adminDesktopMenu {
  background-color: #143051;
  min-height: calc(100vh - 145px);
  max-height: calc(100vh - 145px);
  min-width: 0;
  width: 0;
  padding-top: 20px;
  z-index: 100;
  border-left: 2px solid #143051;
  -webkit-animation: expandWidth 0.5s ease forwards;
          animation: expandWidth 0.5s ease forwards; /* Apply animation */
}


.adminMenuUnitDesktop {
  min-width: 100%;
  min-height: 50px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  color: #a5b5cd;
  padding-left: 24px;
  cursor: pointer;
  font-size: 14px;
  max-height: 40px;
  font-weight: 500;
}

.adminMenuUnitDesktop:hover {
  background-color: #1e3958;
}

.adminTitleDesktopSel {
  font-weight: 500;
  background-color: #214978;
  cursor: default;
  color: white;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.adminTitleDesktopSel:hover {
  cursor: default;
  background-color: #214978;
}

.coordLinkTxt {
  font-weight: 500;
  color: #496375;
}

.coordLinkTxt:hover {
  color: #214978;
}

.longLatInput {
  border-radius: 5px;
  border: 1px solid #dde9f9;
  background-color: #fff;
  color: #496375;
  min-height: 35px;
  font-size: 0.95em;
  font-weight: 500;
  padding-left: 12px;
  padding-right: 8px;
  padding-top: 0px;
  letter-spacing: 1px;
  max-width: 45%;
  min-width: 45%;
  -webkit-appearance: none;
  margin-bottom: 8px;
  margin-top: 8px;
}

.longLatInput:last-child {
  margin-left: 10px;
}

.longLatInput::-webkit-input-placeholder {
  color: #c0d6f0;
}

.longLatInput:-ms-input-placeholder {
  color: #c0d6f0;
}

.longLatInput::placeholder {
  color: #c0d6f0;
}

.longLatInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 11px;
  background-color: #fdfeff;
}

.latLongTitlesDiv {
  display: flex;
  min-width: 100%;
  /* background-color: blue; */
}

.longLatTitle {
  min-width: 50%;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  margin-top: 18px;
  padding-left: 2px;
  color: #859dbb;
  text-transform: uppercase;
  /* margin-bottom: px; */
}

.longLatTitle:last-child {
  padding-left: 0px;
  margin-left: -4px;
}

.radiusExplain {
  font-size: 12px;
  color: #859dbb;
  text-align: left;
  margin-left: 16px;
  margin-top: 8px;
}

.radiusInput {
  border-radius: 5px;
  border: 1px solid #dde9f9;
  background-color: #fff;
  color: #496375;
  min-height: 35px;
  font-size: 0.95em;
  font-weight: 500;
  padding-left: 12px;
  padding-right: 8px;
  padding-top: 0px;
  letter-spacing: 1px;
  max-width: 70px;
  min-width: 70px;
  /* background: url(/static/media/calicongrey.9c7e1aba.svg) no-repeat right #fff; */
  -webkit-appearance: none;
  /* background-position-x: 130px; */
  margin-bottom: 8px;
  margin-top: 8px;
}

.radiusInput::-webkit-input-placeholder {
  color: #c0d6f0;
}

.radiusInput:-ms-input-placeholder {
  color: #c0d6f0;
}

.radiusInput::placeholder {
  color: #c0d6f0;
}

.radiusInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 11px;
  background-color: #fdfeff;
}
.radiusTitle {
  min-width: 80%;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  margin-top: 18px;
  padding-left: 2px;
  color: #859dbb;
  text-transform: uppercase;
}

.radiusDiv {
  display: flex;
  min-width: 100%;
  border-top: 1px solid #ddebf4;
  margin-top: 10px;
}

.gpsModalFooter {
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
  min-height: 46px;
  align-items: center;
  border-top: 1px solid #ddebf4;
  margin-top: 16px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
}

.saveGpsModalBtn {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  background-color: #0bf;
  border: 2px solid #0af;
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 5px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.saveGpsModalBtn:hover {
  background-color: #0af;
}

.closeGpsModalBtn {
  color: #859dbb;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  margin-right: 14px;
}

.closeGpsModalBtn:hover {
  color: #758aa2;
}
.invalidGPS {
  opacity: 0.5;
  pointer-events: none;
}

.latLongInvalidInput {
  border: #e0b7b7 2px solid !important;
}

.gpsZoneTitle {
  margin-left: 5px;
  font-size: 12px;
  color: #859dbb;
  text-transform: uppercase;
  margin-bottom: 2px;
  text-align: left;
  font-weight: 500;
  margin-top: 16px;
}

.gpsInfoTxt {
  color: #214978;
  text-align: left;
  font-size: 14px;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 30px;
  /* padding-left: 18px; */
}

.viewZoneLongLat {
  /* border-radius: 5px; */
  /* border: 1px solid #dde9f9; */
  /* background-color: #fff; */
  color: #496375;
  min-height: 35px;
  font-size: 18px;
  font-weight: 500;
  /* padding-left: 12px; */
  /* padding-right: 8px; */
  /* padding-top: 0px; */
  /* letter-spacing: 1px; */
  max-width: 50%;
  min-width: 50%;
  text-align: left;
  /* background: url(/static/media/calicongrey.9c7e1aba.svg) no-repeat right #fff; */
  -webkit-appearance: none;
  /* background-position-x: 130px; */
  /* margin-bottom: 8px; */
  /* margin-top: 8px; */
  padding-left: 2px;
  margin-top: 5px;
}

.editZoneLongLatInput {
  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;
  margin-right: 4px;
  color: #6a8bb1;
  min-height: 35px;
  padding-left: 8px;
  font-weight: 500;
  max-width: 40%;
  min-width: 40%;
  margin-left: 8%;
  margin-top: 5px;
}

.editZoneLongLatInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 6px;
  background-color: #fdfeff;
}

.editZoneLongLatInput:first-child {
  margin-left: 0px;
}

.viewZoneRadius {
  /* border-radius: 5px; */
  /* border: 1px solid #dde9f9; */
  /* background-color: #fff; */
  color: #496375;
  min-height: 35px;
  font-size: 18px;
  font-weight: 500;
  /* padding-left: 12px; */
  /* padding-right: 8px; */
  /* padding-top: 0px; */
  /* letter-spacing: 1px; */
  max-width: 38%;
  min-width: 38%;
  text-align: left;
  /* background: url(/static/media/calicongrey.9c7e1aba.svg) no-repeat right #fff; */
  -webkit-appearance: none;
  /* background-position-x: 130px; */
  /* margin-bottom: 8px; */
  /* margin-top: 8px; */
  padding-left: 2px;
  margin-top: 5px;
}

.viewRadiusTxt {
  border-radius: 5px;
  border: 1px solid #dde9f9;
  background-color: #fff;
  color: #496375;
  min-height: 35px;
  font-size: 0.95em;
  font-weight: 500;
  padding-left: 12px;
  padding-right: 8px;
  padding-top: 0px;
  letter-spacing: 1px;
  max-width: 70px;
  min-width: 70px;
  /* background: url(/static/media/calicongrey.9c7e1aba.svg) no-repeat right #fff; */
  -webkit-appearance: none;
  /* background-position-x: 130px; */
  margin-bottom: 8px;
  margin-top: 8px;
}

.viewGpsModalBox {
  /* background-color: #e0ebf8; */
  background-color: white;
  max-width: 338px;
  margin: 0 auto;
  border-radius: 5px;
  overflow: hidden;
  min-height: 292px;

  -webkit-animation-name: zoomModalIn !important;

          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  cursor: default;
}
.viewZoneModalBox {
  padding: 15px 20px 20px;
  padding-bottom: 10px;
  color: #143051;
  /* background-color: yellow; */
  min-width: 338px;
  /* max-height: 400px !important; */
}

.viewZoneModalHeader {
  display: flex;
  min-width: 100%;
  /* background-color: blue; */
  justify-content: space-between;
}

.viewModalZoneTitle {
  font-size: 1.2em;
  font-weight: 500;
  color: #143051;
  /* margin-left: 80px; */
  margin-top: 3px;
  text-align: center;
  min-height: 35px;
  padding-top: 6px;
}

.closeViewGpsModalIcon {
  width: 15px;
  margin-top: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.viewZoneModalTopRightBlank {
  min-width: 15px;
}

.longLatTitleView {
  min-width: 50%;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  margin-top: 18px;
  padding-left: 2px;
  color: #859dbb;
  text-transform: uppercase;
  /* margin-bottom: px; */
}

.viewRadiusTitle {
  min-width: 50%;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  margin-top: 18px;
  padding-left: 2px;
  color: #859dbb;
  display: flex;
  align-items: flex-end;
  font-size: 12px;
  text-transform: uppercase;
}

.viewGpsStaffTitle {
  min-width: 50%;
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  margin-top: 18px;
  /* padding-left: 2px; */
  color: #143051;
  margin-left: -31px;
  /* background-color: blue; */
  min-width: 170px;
  min-height: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 16px;
  /* margin-t */
}

.addStaffToGps {
  color: #fff;
  border: solid 1px #0af;
  background-color: #0bf;
  font-size: 14px;
  border-radius: 5px;
  padding-left: 6px;
  padding-right: 6px;
  min-height: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.viewZonePeople {
  /* background-color: purple; */
  border-top: solid 1px #ddebf4;
  padding-top: 5px;
  min-height: 180px;
  max-height: 180px;
  min-width: 170px;
  margin-top: 10px;
  overflow-y: scroll;
  padding-bottom: 10px;
  /* margin-right: 15px; */
  /* display: none; */
}

.viewGpsModalFooter {
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
  min-height: 40px;
  align-items: center;
  border-top: 1px solid #ddebf4;
  margin-top: 0px;
  /* padding-left: 10px;
  padding-right: 10px; */
  padding-top: 8px;
}

.gpsStaffUnit {
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 30px;
  min-height: 30px;
  align-items: center;
  border-radius: 5px;
  color: #496375;
  font-weight: 500;
  font-size: 14px;
  background-color: #e3e8ee;
  color: #4f566b;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
  margin-right: 8px;
  margin-top: 8px;
  font-size: 14px;
  min-width: 170px;
  max-width: 170px;
  margin-left: 2px;
}
.gpsStaffUnit:hover {
  background-color: #cdd9e8;
}
.gpsStaffUnit:first-child {
  margin-top: 0px;
}

.removeGpsStaffImg {
  min-width: 10px;
  cursor: pointer;
}

.addStaffToGpsDropdown {
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 180px;
  min-width: 180px;
  line-height: 26px;
  height: 30px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 1px solid #e6eaee;
  background-color: white;
  color: #496375;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 154px;
  margin-left: -35px;
  margin-top: 18px;
  /* margin-bottom: -6px !important; */
  /* margin- */
}

.gpsZoneDeleteCrossImg {
  margin-right: 8px;
  max-width: 24px;
  padding-left: 7px;
  padding-right: 7px;
  cursor: pointer;
  /* background-color: blue; */
}

.gpsZoneDeleteCrossImg:hover {
  opacity: 0.6;
  /* transform: scale(1.1); */
}

.deleteGpsBtn {
  font-weight: 500;
  font-size: 14px;
  font-weight: 500;
  color: #592eb6;
  text-align: center;
  padding-left: 9px;
  padding-right: 9px;
  display: flex;
  min-height: 30px;
  max-height: 30px;
  background-color: #214978;
  border: 1px solid #143051;
  align-items: center;
  margin-top: 0px;
  border-radius: 5px;
  justify-content: center;
  cursor: pointer;
  color: white;
  /* margin-left: 10px; */
  min-width: 70px;
  max-width: 70px;
}

.deleteGpsBtn:hover {
  background-color: #143051;
}

.unsureDeleteGps {
  font-weight: 500;
  font-size: 14px;
  font-weight: 500;
  color: #592eb6;
  text-align: center;
  padding-left: 9px;
  padding-right: 9px;
  display: flex;
  min-height: 30px;
  max-height: 30px;
  /* line-height: 26px; */
  background-color: #07f;
  border: 1px solid #0469de;
  align-items: center;
  margin-top: 0px;
  border-radius: 5px;
  /* padding-top: 2px; */
  justify-content: center;
  cursor: pointer;
  color: white;
  /* margin-left: 10px; */
  min-width: 70px;
  max-width: 70px;
}

.unsureDeleteGps:hover {
  background-color: #0469de;
}

.viewGpsDeleteAndEditBtnContainer {
  display: flex;
  justify-content: space-between;
  max-width: 132px;
  min-width: 132px;
}

.editGpsBtn {
  font-weight: 500;
  font-size: 14px;
  font-weight: 500;
  color: #592eb6;
  text-align: center;
  padding-left: 9px;
  padding-right: 9px;
  display: flex;
  min-height: 30px;
  max-height: 30px;
  background-color: #8ea7c5;
  border: 1px solid #6987a9;
  align-items: center;
  margin-top: 0px;
  border-radius: 5px;
  justify-content: center;
  cursor: pointer;
  color: white;
  /* margin-left: 10px; */
  min-width: 54px;
  max-width: 54px;
}

.editGpsBtn:hover {
  background-color: #6987a9;
}

.saveEditGpsBtn {
  font-weight: 500;
  font-size: 14px;
  font-weight: 500;
  color: #592eb6;
  text-align: center;
  padding-left: 9px;
  padding-right: 9px;
  display: flex;
  min-height: 30px;
  max-height: 30px;
  background-color: #0bf;
  border: 1px solid #0af;
  align-items: center;
  margin-top: 0px;
  border-radius: 5px;
  justify-content: center;
  cursor: pointer;
  color: white;
  /* margin-left: 10px; */
  min-width: 54px;
  max-width: 54px;
}

.saveEditGpsBtn:hover {
  background-color: #0af;
}

.editRadiusInput {
  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;
  color: #6a8bb1;
  min-height: 35px;
  padding-left: 8px;
  font-weight: 500;
  max-width: calc(38% - 25px);
  min-width: calc(38% - 25px);
  margin-right: 25px;
  margin-top: 5px;
}

.editRadiusInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 6px;
  background-color: #fdfeff;
}

.editGpsTitleInput {
  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;
  margin-right: 4px;
  color: #6a8bb1;
  min-height: 35px;
  padding-left: 8px;
  font-weight: 500;
  max-width: 220px;
  min-width: 220px;
  margin-left: 30px;
  margin-top: 3px;
}

.editGpsTitleInput:focus {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 6px;
  background-color: #fdfeff;
}

.gpsAllStaffBtnSelected {
  cursor: pointer;
  display: flex;
  border: 1px solid #9374d6;
  background-color: #4b1caf;
  color: #fff;
  border-radius: 5px;
  min-height: 30px;
  line-height: 30px;
  padding-left: 8px;
  padding-right: 6px;
  font-size: 14px;
  margin-right: 6px;
  max-width: 93px;
  font-weight: 500;
  margin-left: 82px;
  margin-top: 4px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.gpsAllStaffBtn {
  cursor: pointer;
  display: flex;
  border: 1px solid #9374d6;
  background-color: #4b1caf;
  color: #fff;
  border-radius: 5px;
  min-height: 30px;
  line-height: 30px;
  padding-left: 8px;
  padding-right: 6px;
  font-size: 14px;
  margin-right: 6px;
  max-width: 93px;
  opacity: 0.6;
  font-weight: 500;
  margin-left: 82px;
  margin-top: 4px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.gpsAllStaffCheckBoxImg {
  max-width: 16px;
  margin-left: 9px;
}

.fadeAddStaffToGpsDropdown {
  opacity: 0.5;
  pointer-events: none;
}

.gpsStaffNameSpan {
  cursor: pointer;
}

.noStaffAddedToGpsTxt {
  text-align: right;
  max-width: 100px;
  min-width: 100px;
  font-size: 14px;
  margin-top: 40px;
  margin-bottom: 12px;
  color: #859dbb;
  margin-left: 70px;
}

.myAccountHeader {
  background-color: white;
  min-height: 87px;
  max-height: 87px;
  min-width: 100%;
  max-width: 100%;
  /* padding-left: 27px; */
  border-bottom: solid 2px #e6eaee;
  padding-top: 7px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.myAccountMenuRow {
  min-width: 246px;
  max-width: 246px;
  margin-top: 1px;
}

.myAccountMenuRowMob {
  min-width: 352px;
  max-width: 352px;
  margin-top: 6px;
}

.dashMenuRow {
  min-width: 375px;
  max-width: 375px;
  margin-top: 1px;
}

.dashMenuRowMob {
  min-width: 305px;
  max-width: 305px;
  margin-top:6px;
}

@media only screen and (min-width: 820px) {

.toilDashHelpbtn:hover {
  background-color: #bbcada;
}
  .deleteToilConvBtn:hover {
    background-color: #bbcada;
  }
  .dashAllowProPic:hover {
    opacity: 0.8;
  }
  .dashUserRow:hover {
    background-color: #f6f9ff !important;
  }
  .allLeaveDashController:hover {
    border-color: #8ea7c5 !important
  }

  .enableTilRowBtn:hover {
    border-color: #b2bbc7 !important

  }
  .specialDayUnit:hover {
    border-color: #bbcada  }
  .myAccountTitle {
    position: fixed;
    top: 30px;
    /* left: 280px; */
    /* background-color: yellow; */
    z-index: 9999;
  }

  .avoidWeekend:hover {
    border-color:#bbcada
  }
  .myAccountMenuBar {
    position: fixed;
    top: 80px;
    /* left: 278px; */
    z-index: 9999;
  }
  .addGPSBtn:hover {
    background-color: #bbcada;
  }

  .generatePayDaysBtn:hover {
    background-color: #0469de;
  }



  .dashAddUserBtn:hover {
    background-color: #dde5f3;
  }
}

.myAccountTitle {
  color: #bbcada;
  font-weight: 500;
  font-size: 33px;
  text-align: left;
  margin-top: 10px;
  min-width: 130px;
  margin-left: 22px;
}

.myAccountTitleMob {
  margin-top: 14px;
  margin-bottom: -3px;
}

.myAccountMenuBar {
  display: flex;
  min-width: 139px;
  justify-content: space-between;
  margin-top: 3px;
  margin-left: 24px;
  font-weight: 500;
  align-items: center;
}

.myAccBtnHolder {
  min-height: 27px;
  color: #c2cce9;
  cursor: pointer;
  padding-left: 3px;
  padding-right: 3px;
  margin-top: -3px;
}

.myAccBtnHolder:hover {
  color: #4f79ab;
}

.settingsAccHolder {
}

.myAccountMenuBtn {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accountMenuBtnSelected {
  border-bottom: 1px #e3e8ee solid;
  color: #143051;
}

.accountMenuBtnSelected:hover {
  color: #143051 !important;
  cursor: default !important;
}

.accountBody {
  min-height: calc(100vh - 155px);
  background-color: #ebf0f7;
  border-left: 1px solid #e6eaee;
  /* overflow-y: scroll; */
}

.accountAllowancesDiv {
  min-height: calc(100vh - 155px);
  /* border-right: 1px solid #e0ebf8; */
}

.accountSettingsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 110px;
  padding-left: 12px;
  padding-top: 10px;
  /* flex-direction: column; */
  border-bottom: 1px #e3e8ee solid;
  max-width: 90%;
  margin-left: 5%;
}

.accountSettingsTitle {
  color: #143051;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.accountSettingsValue {
  /* color: #8997a8; */
  margin-top: 4px;
  font-weight: 400;
  color: #516b8b;
  font-size: 14px;
}

.accountSettingsRowLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.accountSettingsEditImg {
  margin-right: 18px;
  cursor: pointer;
}

.accountSettingsEditImg:hover {
  opacity: 0.8;
}

.accountSettingsInfo {
  font-size: 14px;
  text-align: left;
  /* margin-bottom: 0px; */
  margin-top: 11px;
  padding-right: 50px;
  color: #516b8b;
  /* background-color: blue; */
  /* line-height: 12px; */
  line-height: 20px;
  font-weight: 400;
}

.allowSwapsKnob {
  margin-right: 4px;
  margin-top: -10px;
  min-width: 40px;
}

.accountSettingsContainer {
  overflow-y: scroll;
  min-height: calc(100vh - 147px);
  max-height: calc(100vh - 147px);
  padding-bottom: 40px;
  border-right: 1px solid #e0ebf8;
}

.accountSettingsContainerMob {
  /* background-color: orange; */

  min-height: calc(100vh - 204px);
  max-height: calc(100vh - 204px);
  overflow-y: scroll;
}

.accountSettingsContainerMobPermissions3 {
  min-height: calc(100vh - 165px);
  max-height: calc(100vh - 165px);
}

.accountSettingsContainerMob::-webkit-scrollbar {
  /* display: none; */
  /* width: 5px !important;
  height: 4px !important; */
}

.accountSettingsContainerMob::-webkit-scrollbar {
  /* width: 5px;
  height: 8px; */
}

.accountSettingsContainerMob::-webkit-scrollbar-button {
  /* width: 5px;
  height: 5px; */
}
.ccountSettingsContainerMob::-webkit-scrollbar-thumb {
  background-color: #c7daef;
  border-radius: 20px;
  border: 3px solid none;
}

.accountSettingsTeamArray {
  /* background-color: blue; */
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  min-width: 90%;
  margin-bottom: 8px;
  margin-top: 10px;
}

.pTeamNameDiv {
  /* background-color: blue; */
  /* display: flex; */
  /* justify-content: flex-start; */
  /* flex-wrap: wrap; */
  min-width: 90%;
  margin-bottom: 8px;
}

.yourMgrsArr {
  margin-bottom: 15px;
  margin-top: -4px;
}

.accountSettingsTeamItem {
  min-width: -moz-fit-content;
    min-width: -webkit-fit-content;
    min-width: fit-content;
    border-radius: 5px;
    max-width: -moz-fit-content;
    max-width: -webkit-fit-content;
    max-width: fit-content;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    justify-content: space-between;
    /* max-height: 30px; */
    min-height: 30px;
    align-items: center;
    border-radius: 5px;
    color: #496375;
    font-weight: 500;
    font-size: 14px;
    background-color: #e3e8ee;
    color: #4f566b;
    -webkit-animation-name: zoomModalIn !important;
            animation-name: zoomModalIn !important;
    -webkit-animation-duration: 0.1s !important;
            animation-duration: 0.1s !important;
    margin-right: 8px;
    margin-top: 8px;
    background-color: #fff;
    border: #e6e6e6 solid 1px !important;
    cursor: pointer;
    text-align: left;
    padding-top: 4px;
    padding-bottom: 4px;
}

.accountSettingsTeamItem:hover {
  border-color: #c5ced8 !important;
}

.accountProfilePTeamName {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  border-radius: 5px;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  max-height: 26px;
  min-height: 26px;
  align-items: center;
  border-radius: 5px;
  color: #496375;
  font-weight: 500;
  font-size: 12px;
  /* background-color: #e3e8ee; */
  color: #4f566b;
  /* animation-name: zoomModalIn !important;
  animation-duration: 0.1s !important; */
  /* margin-right: 8px; */
  margin-top: 8px;
  border: 1px solid #e6e6e6;
  background-color: white;
  cursor: pointer;
}

.accountProfilePTeamName:hover {
  border-color: #c5ced8 !important;
}

.accountTeamsLeft {
  min-width: 100%;
}

.yourManagersExplain {
  margin-top: 8px;
  margin-bottom: 11px;
  /* max-width: 60%; */
}

.tallerSettingsRowLeft {
  min-height: 120px;
  padding-top: 30px;
}

.notifSettingsDiv {
  /* background-color: blue; */
  min-width: 300px;
  max-width: 300px;
  margin-left: 23px;
  margin-top: 20px;
  border-radius: 5px;
  border: solid #051b35 2px;
  background-color: #143051;
  overflow-y: scroll;
  max-height: calc(100vh - 200px);
}

.notifSettingsEnableDiv {
  display: flex;
  justify-content: flex-start;
  max-width: 280px;
  margin-left: 8px;
  margin-top: -17px;
  margin-bottom: 10px;
}

.notifEnableItem {
  background-color: #294566;
  border: 2px solid #294566;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-size: 13px;
  font-weight: 500;
  padding-left: 8px;
  padding-right: 8px;
  min-height: 35px;
  border-radius: 5px;
  cursor: pointer;
}

.notifEnableItem:last-child {
  margin-left: 8px;
}

.notifEnableItemDisabled {
  background-color: #3b5472;
  border: 2px solid #335174;
}

.notifEnableItemDisabled:hover {
  /* background-color: #294566; */
  /* border: 2px solid #335174; */
}

.notifCheckBoxImg {
  max-width: 20px;
  margin-left: 5px;
}

.notifUpper {
  display: flex;
  /* background-color: yellow; */
  min-height: 50px;
  align-items: center;
  justify-content: space-between;
}
.notifSettingsRow {
  /* display: flex; */
  /* background-color: yellow; */
  min-height: 50px;
  /* align-items: center; */
  /* justify-content: space-between; */
  max-width: 98%;
  margin-left: 3%;
  color: white;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 8px;
  /* cursor: pointer; */
  border-bottom: 1px #627a97 solid;
  border-left: 2px solid #00ccff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notifExplainRow {
  /* border-bottom: 1px #627a97 solid; */
  color: white;
  max-width: 200px;
  font-size: 12px;
  text-align: left;
  padding-left: 0px;
  padding-right: 10px;
  opacity: 0.5;
  margin-top: 5px;
  padding-bottom: 10px;
}

/* .notifSettingsRow:hover {
  background-color: #486382;
} */

.notifSettingsRowMob:hover {
  background: none;
}

.notifSettingsRow:last-child {
  border: none;
}

.notifSettingsKnob {
  margin-top: -5px;
}

.notifLeft {
  min-width: 140px;
  /* background-color: grey; */
}

.notifRight {
  display: flex;
  min-width: 84px;
}

.notifBtnCol {
  /* background-color: blue; */
  min-width: 40px;
  max-width: 40px;
  margin-left: 2px;
  margin-right: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.announcementHolder {
  margin-right: -2px;
  padding-right: 5px;
  padding-left: 6px;
  min-width: 40px;
  /* margin-left: -2px; */
}

.notifsIsMgrTitle {
  color: #00ccff;
  font-weight: 500;
  text-align: left;
  padding-left: 15px;
  margin-top: 24px;
  /* opacity: 0.3; */
  margin-bottom: 10px;
}

.notifsIsMgrTitleMgr {
  color: #de82f7;
  font-weight: 500;
  text-align: left;
  padding-left: 15px;
  margin-top: 30px;
  opacity: 1;
  margin-bottom: 10px;
}

.notifSettingsScrollBox {
  border-top: 1px solid #496382;
}

.notifTypeKnob {
  margin-top: -8px;
  margin-left: 5px;
}

@media only screen and (min-height: 693px) {
  .userNotifSettingHeightDynamic {
    /* max-height: 432px !important;
    min-height: 432px !important; */
  }
}

.yourManagersTitle {
  margin-top: 12px;
}

.accountSettingsProfileRow {
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  background-color: white;
  border-radius: 5px;
  border: 1px #e3e8ee solid;
  max-height: 120px;
  min-height: 120px;
  display: flex;
}

.profileRowLeft {
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: orange; */
}

.profileRowProPicImg {
  max-width: 100px;
  min-width: 100px;
  min-height: 100px;
  max-height: 100px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
}

.profileRowRight {
  min-width: calc(100% - 120px);
  /* background-color: orange; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  padding-left: 4px;
}

.rightRowLower {
  margin-top: 2px;
}

.profileRightRowTitle {
  color: #143051;
  font-size: 16px;
  font-weight: 500;
  margin-top: 12px;
  /* opacity: 0.5; */
}

.profileRightRowValue {
  color: #516b8b;
  margin-top: 1px;
  font-size: 12px;
  font-weight: 500;
}

.notifSettingsMob {
  /* background-color: yellow; */
  min-width: auto;
  max-width: calc(100%);
  margin-top:-10px;
  padding-top:20px;
  margin-left:0px;
  border-radius: 0 0 5px 5px;
  padding-left:10px;
  /* overflow: hidden; */
}

.userNotifMob {
  /* overflow-y: none !important */
}

.mgrNotifMob {
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
}

.noScrollNotifs {
  /* overflow: none;
  min-height: 1000px; */
}

.primaryTeamNameTitleProfile {
  font-size: 10px;
  color: #00aaff;
  margin-top: 2px;
  border-left: 1px solid #00aaff;
  padding-left: 5px;
  display: none;
}

.yearlyTotalsDiv {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
  align-items: center;
}

.yearTotalsYearDropdown {
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 94px;
  min-width: 94px;
  line-height: 26px;
  height: 35px;
  background: url(/static/media/downArrowWhite2.887d57ce.svg) no-repeat right
    rgb(83, 155, 237);
  border: 1px solid #2c7eb9;
  -webkit-appearance: none;
  background-position-x: 64px;
  display: flex;
  cursor: pointer;
  border-color: #2c7eb9;

  border: 2px solid #e6eaee;
  /* background-color: #0bf; */
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 72px;
  margin-right: 20px;
}

.yearTotalsYearDropdown:hover {
  border-color: #d7dfe9;
}

.accountHoursDataBox {
  min-width: 100%;
  margin-top: -50px;
  /* border-right: 2px solid #bbcada; */
  padding-top: 70px;
}

.profileDataRow {
  display: flex;
  min-width: 100%;
  /* background-color: orange; */
  min-height: 50px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #e6eaee;
  padding-left: 10px;
  padding-right: 10px;
}

.profileDataRowTitle {
  font-weight: 500;
  color: #214978;
  font-size: 12px;
  text-align: left;
  max-width: 70%;
}

.profileDataRowValue {
  font-weight: 500;
  color: #496375;
  font-size: 12px;
  margin-right: 15px;
}

.allTheHoursExplain {
  max-width: 100%;
  margin-bottom: 0px;
  margin-top: 20px;
}

.hoursIndent {
  margin-left: 20px;
  color: #496375;
  font-weight: 500;
  opacity: 0.8;
}

.thickBorderTop {
  border-top: solid 2px #bac5d3;
}

.thickBorderBottom {
  border-bottom: solid 1px #bac5d3;
}

.noBorderBottom {
  border-bottom: none;
}

.profileNamesLoaderDiv {
  display: flex;
  min-width: 170px;
  justify-content: space-between;
}

.profileLoading {
  min-height: 20px;
  max-height: 20px;
  max-width: 80px;
  min-width: 80px;
  background-color: #e2ebf5;
  border-radius: 5px;
}

.profileJTloading {
  min-height: 14px;
  max-height: 14px;
  margin-top: 6px;
  max-width: 80px;
  min-width: 80px;
  background-color: #c2cfdc;
  border-radius: 5px;
}

.profileJT2loading {
  min-height: 14px;
  max-height: 14px;
  margin-top: 8px;
  max-width: 60px;
  min-width: 60px;
  background-color: #a9b6c2;
  border-radius: 5px;
  /* opacity: 0.5; */
}

.installTimelifyBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border: #e3e8ee 1px solid;
  border-radius: 5px;
  min-width: 90px;
  padding: 10px;
  font-size: 16px;
  color: #496375;
  font-weight: 500;
  cursor: pointer;
}
.installTimelifyBtn:hover {
  border-color: #bac5d3;
}

.installAppBtnImg {
  max-width: 18px;
}

.allowanceBtnTxtProfile {
  font-weight: 500;
  margin-left: 6px;
  margin-right: 6px;
  /* font-size: 14px; */
}

.profileAllowancesBtn {
  background-color: #fff;
  border: #fff 1px solid;
  display: flex;
  color: #859dbb;
  min-height: 36px;
  align-items: center;
  /* line-height: 34px; */
  padding-left: 2px;
  padding-right: 8px;
  border-radius: 3px;
  text-align: center;
  margin-left: 9px;
  margin-right: 28px;
  min-width: -moz-fit-content !important;
  min-width: -webkit-fit-content !important;
  min-width: fit-content !important;
  max-height: 30px;
  min-height: 30px;
  margin-top: 4px;
  font-size: 12px;
  cursor: pointer;
  position: fixed;
  top: 34px;
  right: -4px;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 20%);
}

.profileAllowancesBtn:hover {
  border-color: #e3e8ee;
}

.supportModalBtn {
  background-color: #fff;
  border: #fff 1px solid;
  display: flex;
  color: #859dbb;
  min-height: 36px;
  align-items: center;
  /* line-height: 34px; */
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 3px;
  text-align: center;
  margin-left: 9px;
  margin-right: 28px;
  min-width: -moz-fit-content !important;
  min-width: -webkit-fit-content !important;
  min-width: fit-content !important;
  max-height: 30px;
  min-height: 30px;
  margin-top: 4px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  position: fixed;
  top: 78px;
  right: -4px;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 20%);
}

.supportModalBtn:hover {
  border-color: #e3e8ee;
}

.profileStatsRotate {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.allowanceStatsImg {
  margin-left: 4px;
}

.profileAdminBtn {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  display: flex;
  color: #fff;
  min-height: 36px;
  align-items: center;
  /* line-height: 34px; */
  padding-left: 9px;
  padding-right: 2px;
  border-radius: 5px;
  text-align: center;
  margin-left: 9px;
  margin-right: 28px;
  min-width: -moz-fit-content !important;
  min-width: -webkit-fit-content !important;
  min-width: fit-content !important;
  max-height: 35px;
  min-height: 35px;
  margin-top: 4px;
  font-size: 16px;
  cursor: pointer;
  position: fixed;
  top: 43px;
  right: -4px;
}

.profileAdminBtn:hover {
  background-color: #bbcada;
}

.pushProAllowsBtnDown {
  top: 75px;
}

.signoutBtn {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  display: flex;
  color: #fff;
  align-items: center;
  /* line-height: 34px; */
  padding-left: 9px;
  padding-right: 2px;
  border-radius: 5px;
  text-align: center;
  margin-left: 9px;
  margin-right: 40px;
  min-width: -moz-fit-content !important;
  min-width: -webkit-fit-content !important;
  min-width: fit-content !important;
  max-height: 35px;
  min-height: 35px;
  /* margin-top: 4px; */
  font-size: 14px;
  cursor: pointer;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.signoutBtn:hover {
  background-color: #bbcada;
}

.signoutRow {
  display: flex;
  justify-content: flex-end;
  min-width: 100%;
  margin-top: -19px;
  padding-right: 8px;
}

.signoutIcon {
  max-width: 10px;
}

.ignoreInstallBtn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 72px;
  padding-left: 9px;
  padding-right: 9px;
  min-height: 35px;
  max-height: 35px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
}

.ignoreInstallBtn:hover {
  background-color: #bbcada;
}

.installCheckBox {
  max-width: 12px;
  margin-left: 8px;
}

.midBreakAllowBtn {
  margin-top: 28px;
}

.notifSettingsRowTitle {
  font-weight: 500;
  max-width: 200px;
  text-align: left;
  margin-top: 20px;
}

.salaryInfoAndHistoryBtnDiv {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  margin-top: 2px;
}

.myHourlyRateTimelineBtn {
  font-size: 14px;
  font-weight: 500;
  background-color: #d0d8e3;
  border: 1px solid #bbcada;
  color: white;
  display: flex;
  justify-content: space-around;
  padding-left: 8px;
  padding-right: 8px;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  max-height: 35px;
  min-height: 35px;
}
.myHourlyRateTimelineBtn:hover {
  background-color: #c5cedb;
}

.mySalaryTimelineDownBtn {
  max-width: 10px;
  margin-left: 6px;
  margin-top: 2px;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

.hideHrRateTimelineRotation {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.mySalaryTimelineDiv {
  border-right: 2px solid #143051;
  min-width: 100%;
  margin-top: -10px;
  margin-bottom: 14px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.mySalTimelineObj {
  border: 1px solid #bac5d3;
  max-width: 280px;
  min-width: 280px;
  margin-bottom: 10px;
  border-radius: 5px;
  background: white;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-right: 10px;
}

.mySalTimelineObj:last-of-type {
  margin-bottom: 0px;
}

.mySalTimelineRow {
  display: flex;
  min-width: 90%;
  justify-content: space-between;
  align-items: center;
  margin-left: 4%;
  max-width: 92%;
  padding-top: 0px;
  padding-bottom: 0px;
  font-weight: 500;
}

.mySaltTimeTitleAndValue {
  text-align: left;
}

.textRight {
  text-align: right;
}

.mySalTimelineTitle {
  color: #214978;
  font-size: 10px;
}

.mySalTimelineVal2 {
  color: #58bdee;
  margin-top: 4px;
  font-size: 14px;
}

.mySalTimelineVal {
  color: #143051;
  font-weight: 500;
  margin-top: 4px;
  font-size: 14px;
}

.secondMySalTimelineRow {
  margin-top: 7px;
  border-top: 1px solid #e3e8ee;
  padding-top: 8px;
}
.accountFixedSched {
  min-width: 100%;
}

.accFSrow {
  display: flex;
  min-width: 240px;
  justify-content: space-between;
  min-height: 60px;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  border-bottom: 1px #e3e8ee solid;
}

.accFSrow:last-child {
  border: none;
  margin-bottom: 20px;
}

.accFSrowTitle {
  color: #496375;
  font-weight: 500;
  font-size: 14px;
}

.accFsRowValue,
.accFsRowNonWorkingDay {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  color: #8997a8;
  font-weight: 500;
  font-size: 14px;
}

.accFsRowNonWorkingDay {
  opacity: 0.5;
}

.accFsRowValueLower {
  /* font-weight:400; */
  margin-top: 4px;
  font-size: 12px;
}

.whatsappModalBox {
  background-color: #f4f8fe;
  border-radius: 5px;
  max-width: 340px;
  max-height: 200px;

  padding: 20px;
  cursor: default;

  -webkit-animation-name: zoomModalIn !important;

          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.whatsappIconImg {
  cursor: pointer;
}

.whatsappIconDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border: solid 1px #e6eaee;
  border-top: solid 2px #e6eaee;
  border-bottom: solid 2px #e6eaee;
  border-radius: 6px;
  padding: 4px;
}

.whatsappBoxHeader {
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  color: #143051;
  font-size: 18px;
  margin-bottom: 16px;
}

.closeWhatsappModalImg {
  max-width: 15px;
  cursor: pointer;
}

.closeWhatsappModalImg:hover {
  opacity: 0.8;
  /* padding: 1px; */
}

.addToContactsWarnTxt {
  /* max-width:210px; */
  font-size: 12px;
  text-align: left;
  max-width: 280px;
  color: #bbcada;
  padding-left: 20px;
  line-height: 16px;
  padding-bottom: 20px;
  /* padding-right:180px; */
}

.emailSpamBoldTitle {
  color: #00ccff;
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  max-width: 270px;
  padding-left: 20px;
  line-height: 16px;
  padding-bottom: 6px;
  margin-top: 18px;
}

.emailSpamEmailSpan {
  color: white;
  font-weight: 500;
}

.profileInitsBlank {
  max-width: 100px;
  min-width: 100px;
  min-height: 100px;
  max-width: 100px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
}

.profileImgProPicPlaceholder {
  min-width: 100%;
  max-width: 100%;
}

.profImgInits {
  font-weight: 500;
  color: white;
  margin-top: -82px;
  font-size: 48px;
  /* opacity: 0.6; */
}

.editmyProPic {
  background-color: white;
  margin-top: -64px;
  margin-left: -31px;
  margin-right: 5px;
  min-width: 25px;
  max-width: 25px;
  min-height: 25px;
  max-height: 25px;
  border-radius: 5px;
  border: #e3e8ee solid 2px;
  cursor: pointer;
}
.editmyProPic:hover {
  border-color: #b2bbc7;
}

.editProPicUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  -webkit-animation-name: underlayFadeIn;
          animation-name: underlayFadeIn;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  cursor: pointer;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.editProPicModalBox {
  background-color: #f4f8fe;
  min-width: 300px;
  max-width: 300px;
  /* height: 280px; */
  margin-top: -10vh;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: default;
  color: #143051;
  font-weight: 500;
  text-align: center;
    -webkit-animation-name: zoomModalIn !important;
            animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
 
}

.editProPicModalContentBox {
  background-color: #fff;
  max-width: 90%;
  min-width: 90%;
  /* padding-top: 40px; */
  border-radius: 5px 5px 0 0;
  border-top: solid 2px #e6eaee;
  border-right: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
  border-bottom: 1px solid #bac5d3;
  /* min-height:120px; */
  /* margin-bottom: 40px; */
}

.editProPicModalHeader {
  min-width: 90%;
  margin-left: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  padding-right: 55px;
  font-weight: 500;
}

.editProPicModalFooter {
  min-width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
}

.closeEditProPicModalIcon {
  max-width: 15px;
  cursor: pointer;
}

.closeEditProPicModalIcon:hover {
  opacity: 0.8;
}

.closeProPicModalBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
}

.removeProPicBtn {
  font-size: 14px;
  color: #fff;
  background-color: #cbd7e6;
  border: solid #bbcada 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
}

.proPicChangeBtnMyAcc {
  max-width: 220px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.waitForProPic {
  margin-top: 26px;
  margin-bottom: 26px;
  font-size: 14px;
  color: #bbcada;
  font-weight: 500;
}

.accSupportIcon {
  max-width: 6px;
  margin-right: 3px;
  margin-left: 7px;
}

.accAllowsBtn {
  max-width: 12px;
}

.companySettings {
  margin-top: 50px;
}

.compSettingsMob {
  font-size: 20px;
  margin-top: 20px;
  margin-left: 20px;
  max-width: 240px;
  text-align: left;
  line-height: 22px;
  min-height: 50px;
  padding-right: 62px;
  display: flex;
  align-items: flex-end;
  color: white;
  position: fixed;
  top: 5px;
  right: -40px;
}

.adminBurger2 {
  max-width: 12px;
  margin-right: 2px;
  margin-left: 11px;
}

.adminBurger {
  max-width: 12px;
  margin-right: 11px;
  margin-left: 2px;
}

.mobAdminMenuDiv {
  display: flex;
  justify-content: space-between;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border: 2px solid #e6eaee;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
  padding-right: 8px;
  padding-left: 8px;
  position: fixed;
  top: 45px;
  left: 14px;
  max-height: 35px;
  min-height: 35px;
  cursor: pointer;
  background-color: white;
  flex-direction: row-reverse;
}

.mobAdminMenuDivActive {
  border-bottom: 2px solid white;
  border-radius: 5px 5px 0 0;
}


.mobAdminMenuDivActiveDash {
border-bottom: 1px solid #e0ebf8;
}

.mobAdminMenuDiv:hover {
  /* border-color: #bac5d3 */
}

.mobAdminPageTitle {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  color: #143051;
  font-weight: 560;
  font-size: 14px;
}

.mobAdminOptions {
  min-height: 401px;
  min-width: 200px;
  background-color: white;
  max-width: 200px;
  position: fixed;
  top: 80px;
  left: 14px;
  z-index: 99999;
  border-radius: 0px 10px 5px 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  -webkit-animation-name: cropDown;
          animation-name: cropDown;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.dashMenuDropper {
  border-radius: 10px 0px 5px 5px;
  top: 161px;
  right: calc(4vw + 2px);
  left: auto;
  min-height: 420px;
  z-index:9999999;
  min-width: 150px;
  -webkit-animation-name: cropDown2;
          animation-name: cropDown2;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
}

@-webkit-keyframes cropDown {
  from {
    min-height: 0px;
    max-height: 0; /* Start with max-height at 0 */
  }
  to {
    max-height: 401px; /* End with max-height at 200px */
    min-height: 401px;
  }
}

@keyframes cropDown {
  from {
    min-height: 0px;
    max-height: 0; /* Start with max-height at 0 */
  }
  to {
    max-height: 401px; /* End with max-height at 200px */
    min-height: 401px;
  }
}

@-webkit-keyframes cropDown2 {
  from {
    min-height: 0px;

    max-height: 0; /* Start with max-height at 0 */
  }
  to {
    max-height: 420px; /* End with max-height at 200px */
    min-height: 420px;
  }
}

@keyframes cropDown2 {
  from {
    min-height: 0px;

    max-height: 0; /* Start with max-height at 0 */
  }
  to {
    max-height: 420px; /* End with max-height at 200px */
    min-height: 420px;
  }
}

.mobAdminOption {
  color: #214978;
  font-weight: 500;
  text-align: left;
  min-height: 44px;
  /* padding-right: 20px; */
  align-items: flex-start;
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  border-top: 1px solid #e0ebf8 !important;
  flex-direction: column;
  padding-left: 20px;
}

.mobDashMenuOption {
  text-align: right;
  align-items: flex-end;
  padding-right: 15px;
  padding-left: 0px !important;
  min-height:35px;
}

.mobAdminOptionSelected {
  color: #143051;
  font-weight: 500;
  border-left: 4px solid #0077ff;
  padding-left: 18px;
}

.mobAdminOptionSelectedDash {
  color: #143051;
  font-weight: 500;
  border-right: 4px solid #0077ff;
  padding-left: 18px;
  /* padding-bottom:5px; */
  min-height:35px !important;
  max-height:35px  !important
}

.bankHolMenuSub {
  font-size: 10px;
  opacity: 0.4;
}

.mobAdminOptionInclBankHol {
  font-size: 10px;
  margin-top: -2px;
  opacity: 0.8;
  margin: left 1px;
}

.generalNoBorder {
  border-top: none !important;
  min-height: 45px;
  padding-top: 5px;
  margin-top: -4px;
}

.detailsNoBorder {
  border-top: none !important;
  min-height: 32px;
  /* padding-top: 1px; */
  margin-top: -4px;
}

.closedDaysTimelineModalBoxMob {
  /* background-color:blue !important; */
  position: fixed;
  bottom: 0;
  border-radius: 10px 10px 0 0;
}

.closedDaysTimelineFooter {
  background-position-x: blue;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
}

.teamsYouManageMargin {
  margin-top: 12px;
}

.adminEditBtnImg {
  min-width: 28px;
  border-radius: 5px;
  border: #e3e8ee solid 2px;
  cursor: pointer;
  margin-left: 15px;
  background-color: #fff;
}

.adminEditBtnImg:hover {
  border-color: #b2bbc7;
}

.settingRowTitle {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  margin-left: 15px;
  font-size: 14px;
  line-height: 17px;
  margin-top: 12px;
}

.editSettingToggledDiv {
  display: flex;
  align-items: center;
}

.mobSettingsToggler {
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  min-height: 77px;
}

.saveSettBtn {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  background-color: #0bf;
  border: 1px solid #0af;
  padding-left: 3px;
  padding-right: 3px;
  max-width: 56px;
  min-width: 56px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  max-height: 35px;
  margin-left: 15px;
}

.saveSettBtn:hover {
  background-color: #0af;
}

.saveSettBtnCanx {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  min-width: 66px;
  max-width: 66px;
}

.saveSettBtnCanx:hover {
  background-color: #bbcada;
}

.settingInputBox {
  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;
  color: #6a8bb1;
  min-height: 35px;
  padding-left: 9px;
  font-weight: 500;
  margin-left: 5%;
  min-width: 150px;
  font-size: 16px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 7px;
  background-color: #fdfeff;
}

.settingInputBoxMob {
  min-width: 190px;
  max-width: 190px;
}

@media only screen and (min-width: 930px) {
  .settingInputBox {
    min-width: 250px !important;
  }

  .editSettingToggledDiv2 {
    min-width: 320px !important;
    max-width: 320px !important;
    padding-right: 322px;
  }
}

.lonLatTitles {
  font-size: 12px;
  line-height: 16px;
  color: #143051;
  font-weight: 400;
  margin-left: 23px;
  padding-top: 18px;
  padding-right: 20px;
}

.gpsZoneRow {
  /* max-height: 80px; */
  border-top: solid 1px #e3e8ee;
  text-align: left;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
}

.lonLatVal {
  font-weight: 500;
  font-size: 18px;
  color: #143051;
  margin-left: 23px;
  margin-top: 8px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  max-width: 86%;
  align-items: center;
  line-height: 20px;
}

.smallZoneName2 {
  font-size: 14px;
}

.viewGpsZoneMapBtn {
  font-size: 14px;
  color: #fff !important;
  font-weight: 500;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
}

.viewGpsZoneMapBtn:hover {
  background-color: #bbcada;
}

.mapImg {
  max-width: 14px;
  margin-left: 7px;
  opacity: 0.7;
}

.lonLatInputDiv {
  display: flex;
  align-items: center;
  margin-top: 6px;
  margin-bottom: 10px;
}

.latLonInput {
  border: none;
  border-bottom: 1px solid #dde9f9;
  background-color: #f4f8fe;
  border-radius: 5px 5px 0 0;
  color: #6a8bb1;
  min-height: 35px;
  padding-left: 9px;
  font-weight: 500;
  margin-left: 5%;
  min-width: 197px !important;
  max-width: 197px !important;

  font-size: 16px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 7px;
  background-color: #fdfeff;
}

.addUserTextInput {
  border: none;
  /* border-bottom: 2px solid #dde9f9; */
  /* background-color: #f4f8fe; */
  /* border-radius: 5px 5px 0 0; */
  color: #143051;
  min-height: 35px;
  padding-left: 9px;
  font-weight: 500;
  margin-left: 5%;
  min-width: 90% !important;
  font-size: 16px;
  padding-left: 7px;
  /* background-color: #f4f8fe; */
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 7px;
  background-color: #fdfeff;
}

.addUserNameInputs {
  min-width: 60% !important;
}

.addUserTextInput:focus {
  border: 2px solid #0af;
  border-radius: 5px;
}

.disableLonLat {
  opacity: 0.4;
  pointer-events: none;
}

.viewMapsAndSaveDiv {
  display: flex;
}

.saveGpsZoneBtn {
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  background-color: #0bf;
  border: 1px solid #0af;
  margin-right: 8px;
}

.saveGpsZoneBtn:hover {
  background-color: #0af;
}

.maxViewZoneModalWidth {
  max-width: 330px !important;
  min-width: 330px !important;
}

.adminPanelInfo {
  margin-left: 26px;
  font-size: 12px;
  text-align: left;
  color: #bbcada;
  font-weight: 400;
  max-width: 390px;
  line-height: 15px;
}

.adminBoxWidther {
  min-width: calc(100vw - 453px);
  max-width: calc(100vw - 453px);
}

@media screen and (min-width: 1100px) {
  .adminBoxWidther {
    min-width: 612px;
    max-width: 612px;
    /* background-color: lime; */
  }
}

@media screen and (min-width: 1800px) {
  .adminBoxWidther {
    min-width: 582px;
    max-width: 582px;
    /* background-color: lime; */
    margin-left: -20px;
  }
}

@media screen and (min-width: 1800px) {
  .adminBoxWidther {
    min-width: 582px;
    max-width: 552px;
    /* background-color: lime; */
    margin-left: 10px;
  }
}

.adminBoxWidtherMob {
  min-width: 98vw;
  max-width: 98vw;
}

.loadingUsersAdmin {
  margin-top: 40px;
}

.adminUserItem {
  background-color: white;
  min-width: 96%;
  max-width: 96%;
  margin-top: 10px;
  border: #e6e6e6 solid 1px;
  border-radius: 5px;
  min-height: 50px;
  margin-bottom: 7px;
  cursor: pointer;
  padding-bottom: 5px;
  /* box-shadow: 0px 0px 2px rgb(0 0 0 / 20%); */
  padding: 10px;
  margin-left: 2px;
}

@media screen and (min-width: 1026px) {
  .adminUserItem {
    max-width: 520px !important;
    min-width: 520px !important;
    margin-left: 10px;
  }
}

.teamAdminItem {
  min-width: 99%;
  max-width: 99%;
  margin-bottom: 13px !important;
  padding: 10px;
}

.adminUserItem:hover {
  border: #bbcada 1px solid;
  box-shadow: none;
  /* padding: 0px; */
  /* padding: 9px; */
}

.adminUserItemMob {
  min-width: 96%;
  max-width: 96%;
  margin-left: 2%;
  border-color: #e6e6e6;
  margin-bottom: 10px;
  border-radius: 10px;
}

.minWidth100vw {
  min-width: 100vw;
  max-width: 100vw;
}

.adminUserItemTop {
  min-width: 90%;
  display: flex;
  align-items: center;
  max-width: 96%;
  /* margin-left: 2%; */
  /* margin-top: 2%; */
}

.adminUserProfPic {
  max-width: 50px;
  min-width: 50px;
  max-height: 60px;
  min-height: 60px;
  object-fit: cover;
  border-radius: 5px;
  border: 2px solid #e6e6e6;
  /* margin-bottom: 2%; */
}

.adminUserProfPicMob {
  max-width: 50px;
  min-width: 50px;
  max-height: 60px;
  min-height: 60px;
  border: 1px solid #e6e6e6;
}

.adminUserInits {
  max-width: 50px;
  min-width: 50px;
  max-height: 60px;
  min-height: 60px;
  border: 2px solid #e6e6e6;
  border-radius: 5px;
}

.adminInitsPlaceholderImg {
  max-width: 50px;
  min-width: 50px;
  max-height: 60px;
  min-height: 60px;
  object-fit: cover;
  margin-left: -2px;
  padding-bottom: 8px;
  background: #516b8b;
  padding-top: 8px;
}

.adminUserInit {
  margin-top: -39px;
  font-weight: 500;
  font-size: 21px;
  color: white;
  opacity: 0.5;
  text-transform: uppercase;
}

.adminUserTopNameAndJt {
  text-align: left;
  font-weight: 500;
  color: #143051;
  margin-left: 8px;
  border-left: 2px solid #859dbb;
  padding-left: 9px;
  /* margin-top: -9px; */
}

.adminUserName {
  font-size: 14px;
  text-align: left;
  line-height: 18px;
  /* margin-left: 15px; */
}

.paddingLeft0 {
  padding-left: 0px !important;
}

.adminUserJt {
  font-size: 12px;
  color: #516b8b;
  font-weight: 500;
  margin-top: 1px;
}

.adminUserJTinfo {
  font-weight: 500;
  text-align: left;
  margin-left: 3%;
  font-size: 10px;
  color: #8ea7c5;
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
  line-height: 23px;
  margin-top: -2px;
}

.adminUserPrimTeamSpan {
  color: #859dbb;
  margin-left: 6px;
  background: #f4f8fe;
  border: 1px solid #dde9f9;
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 4px;
  opacity: 0.5;
  display: flex;
  align-items: center;
  max-height: 18px;
  margin-top: 3px;
  font-weight: 500;
}

.adminUserInlineDivider {
  min-width: 93%;
  min-height: 1px;
  background-color: #e6e6e6;
  margin-top: 3px;
  margin-bottom: 10px;
  max-width: 93%;
  margin-left: 3%;
}

.adminUsersFilterImg {
  position: absolute;
  bottom: 80px;
  right: 75px;
  background-color: #a387df;
  border: 1px solid #8e76c1;
  color: #fff;
  border-radius: 5px;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  line-height: 42px;
  font-size: 2.5em;
  z-index: 1000;
  cursor: pointer;
  opacity: 0.8;
  padding: 12px;
}

.adminUsersFilterImg:hover {
  background-color: #8e76c1;
  /* transform: scale(0.97); */
}

.adminUserAddBtn {
  position: absolute;
  bottom: 80px;
  right: 175px;
  background-color: #d287df;
  border: 1px solid #af76c1;
  color: #fff;
  border-radius: 5px;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  line-height: 42px;
  font-size: 2.5em;
  z-index: 1000;
  cursor: pointer;
  opacity: 0.8;
  padding: 12px;
}

.adminUserAddBtn:hover {
  background-color: #b888c7;
  /* transform: scale(0.97); */
}

.addminDisabledUsrsBtn {
  position: absolute;
  bottom: 80px;
  right: 226px;
  background-color: #8b5395;
  border: 1px solid #613f67;
  color: #fff;
  border-radius: 5px;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  line-height: 42px;
  font-size: 2.5em;
  z-index: 1000;
  cursor: pointer;
  opacity: 0.8;
  padding: 7px;
}

.addminDisabledUsrsBtn:hover {
  background-color: #764e7c;
}

.addminDisabledUsrsBtnMob {
  position: fixed;
  bottom: 298px;
  right: 30px;
  min-width: 50px;
  min-height: 50px;
  padding: 13px;
}

.addUserImgMob {
  position: fixed;
  bottom: 239px;
  right: 30px;
  min-width: 50px;
  min-height: 50px;
  padding: 16px;
}
.sortImg {
  right: 125px;
}

.usersSortBox {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  min-height: 286px;
  max-height: 286px;

  /* background-color: #f9f6ff; */
  position: absolute;
  bottom: 80px;
  right: 125px;
  /* border: 1px solid #e6e0f3; */
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 7px;
  padding-top: 1px;
  background-color: #e6def6;
  border: 1px solid #d1cbdf;
  -webkit-animation-name: slideSortBoxUp !important;
          animation-name: slideSortBoxUp !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

@-webkit-keyframes slideSortBoxUp {
  from {
    min-height: 40px;
    max-height: 40px;
  }
  to {
    min-height: 286px;
    max-height: 286px;
  }
}

@keyframes slideSortBoxUp {
  from {
    min-height: 40px;
    max-height: 40px;
  }
  to {
    min-height: 286px;
    max-height: 286px;
  }
}

.flexRow {
  flex-direction: row !important;
  align-items: center;
  justify-content: flex-start;
}

.employeeCount {
  margin-left: 9px;
  opacity: 0.5;
  border-left: 1px solid white;
  padding-left: 8px;
}

.sortBoxTitle {
  font-weight: 500;
  color: #af99da;
  text-align: left;
  margin-left: 0px;
  margin-top: 9px;
  margin-right: 3px;
  font-size: 14px;
  margin-bottom: 0px;
  /* border-right: 2px solid #af99da; */
  /* padding-right: 7px; */
}

.sortBy {
  background-color: white;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  min-height: 35px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #9287a7;
  border: 2px solid #dcd8ee;
  border-radius: 5px;
  font-size: 14px;
  margin-left: 8px;
  cursor: pointer;
}

.sortBy:hover {
  border-color: #d1bedd;
}

.closeSort {
  width: 20px;
  margin-top: 11px;
  cursor: pointer;
  margin-right: 4px;
  padding: 2px;
}

.closeSort:hover {
  opacity: 0.8;
  -webkit-transform: scale(0.97);
          transform: scale(0.97);
}

.preloadCloseSort {
  visibility: hidden;
  max-width: 0px;
  max-height: 0px;
  position: fixed;
  top: 0;
  left: 0;
}

.sortBySel {
  background: #caacff;
  color: white;
  border-color: #957eb5;
}

.sortBySel:hover {
  border-color: #6f5496;
}

.filterImgMob {
  position: fixed;
  bottom: 120px;
  right: 30px;
  min-width: 50px;
  min-height: 50px;
  padding: 12px;
}

.filterImgMobSort {
  position: fixed;
  bottom: 180px;
  right: 30px;
  min-width: 50px;
  min-height: 50px;
  padding: 12px;
}

.usersSortBoxMob {
  position: fixed;
  bottom: 183px;
  right: 30px;
}

.sortByInline {
  max-width: 14px;
  margin-left: 4px;
}

.adminMenuUnderlay {
  min-width: 100vw;
  min-height: 100vh;
  border-radius:15px;
  background-color: rgb(0 0 0 / 15%);
  position: fixed;
  z-index: 99;
}

.adminMenuUnderlayDash {
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgb(0 0 0 / 4%);
  position: fixed;
  z-index: 99;
  top: 0; left: 0
}

.findUserToggler {
  background-color: #e6def6;
  border: 1px solid #d1cbdf;
  color: white;
  position: absolute;
  bottom: 80px;
  right: 75px;
  min-height: 40px;
  min-width: 300px;
  max-width: 300px;

  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-animation-name: slideTogglerIn !important;
          animation-name: slideTogglerIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

@-webkit-keyframes slideTogglerIn {
  from {
    min-width: 0px;
    max-width: 0px;

    /* bottom: -10%; */
    /* opacity: 1; */
    /* transform: scale(0.85); */
  }
  to {
    min-width: 300px;
    max-width: 300px;

    /* bottom: 0; */
    /* opacity: 1; */
    /* transform: scale(1); */
  }
}

@keyframes slideTogglerIn {
  from {
    min-width: 0px;
    max-width: 0px;

    /* bottom: -10%; */
    /* opacity: 1; */
    /* transform: scale(0.85); */
  }
  to {
    min-width: 300px;
    max-width: 300px;

    /* bottom: 0; */
    /* opacity: 1; */
    /* transform: scale(1); */
  }
}

.findUserTogglerMob {
  /* right:8px; */
  /* top: 94px; */
  border-radius: 5px 15px 0 0;
  min-height: 50px;
  max-height: 50px;
  /* min-width: calc(100% - 16px); */
}

.closeFindToggleImg {
  width: 18px;
  margin-right: 10px;
  cursor: pointer;
  padding: 2px;
}

.closeFindToggleImg:hover {
  opacity: 0.75;
}

.findValueInput {
  background-color: #ffffff;
  border-radius: 4px;
  margin-left: 4px;
  min-height: 32px;
  min-width: 157px;
  font-size: 13px;
  padding-left: 8px;
  color: #143051;
  font-weight: 500;
  border: 1px solid #50405e;
}

.findValueInputMob {
  min-height: 40px;
  min-width: 257px;
}

.findValueInput::-webkit-input-placeholder {
  color: #ab9bcd;
  /* font-size:14px; */
}

.findValueInput:-ms-input-placeholder {
  color: #ab9bcd;
  /* font-size:14px; */
}

.findValueInput::placeholder {
  color: #ab9bcd;
  /* font-size:14px; */
}

.adminUserJoin {
  font-weight: 400;
  font-size: 10px;
  color: #bbcada;
}

.adminUserAnnLeaveValspan {
  margin-left: 6px;
  color: #0af;
  font-weight: 500;
}

.adminUserDayMins {
  color: #859dbb;
  margin-left: 6px;
  background: #f4f8fe;
  border: 1px solid #dde9f9;
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 4px;
  /* opacity: 0.5; */
  display: flex;
  align-items: center;
  max-height: 18px;
  margin-top: 2px;
  font-weight: 500;
}

.addUserMobMmodal {
  min-width: 100vw !important;
  max-width: 100vw !important;
  position: fixed;
  bottom: 0;
  left: 0;
  -webkit-animation-name: moveEditTeamUserBoxUp !important;
          animation-name: moveEditTeamUserBoxUp !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.addUserDate {
  padding-right: 8px;

  background: url(/static/media/calicongrey.9c7e1aba.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 130px;

  cursor: text;

  background-color: #f4f8fe;
  border-radius: 3px;
  margin-left: 5%;
  min-height: 35px;
  min-width: 167px;
  font-size: 16px;
  padding-left: 8px;
  color: #6a8bb1;
  font-weight: 500;
  border: none;
  border-bottom: 2px solid #dde9f9;
  border-left: #f4f8fe 2px solid;
  border-right: #f4f8fe 2px solid;
  border-top: #f4f8fe 2px solid;

  margin-bottom: 5px;
  margin-top: 4px;
}

.addUserDate:focus {
  border: 2px solid #0af;
  border-radius: 5px;
}

.opac05 {
  color: #b1c8e7;
}

.addUserTeamDropdown {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px;
  text-align: left !important;
  max-width: 140px;
  min-width: 140px;
  line-height: 26px;
  height: 35px;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border: 2px solid #dde9f9;
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 116px;
  margin-left: 5%;
  margin-top: 4px;
  margin-bottom: 18px;
}

.addUserTeamDropdownHover:hover {
  border-color: #0af;
}

.pTeamDrop {
  background-position-x: 215px;
  max-width: 240px;
  min-width: 240px;
}

.adminAddUserExplainer {
  color: #bbcada;
  margin-left: 25px;
  font-size: 12px;
  padding-right: 20px;
  margin-top: 6px;
}

.invalidAddUser {
  pointer-events: none;
  opacity: 0.2;
}

.invalidAddUserEmailInput {
  border: 2px solid #cfa8a8;
  border-radius: 5px;
}

.addUserHistNumInput {
  max-width: 50px !important;
  min-width: 50px !important;
}

.historyMinsAddUserDiv {
  margin-left: 10px;
  margin-top: 24px;
  margin-bottom: 20px;
}
.addUserHistHoursTxt {
  font-weight: 500;
  font-size: 14px;
  margin-left: 6px;
  color: #859dbb;
  margin-top: 14px;
}



.adminAddUserLargerTitle {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.addUserScroll {
  max-height: 550px !important;
  overflow-y: auto;
  max-height: 457px !important;
  min-height: 457px !important;
}

.adminAddUserSubmitColours {
  background-color: #07f;
  border: #0469de 1px solid;
}

.adminAddUserSubmitColours:hover {
  background-color: #036ce4;
}

.addUserBackImg {
  max-width: 15px;
  margin-left: 5px;
}

.addUserBackBtn {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  min-width: 35px;
  min-height: 35px;
  border-radius: 5px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.addUserBackBtnDesktop:hover {
  background-color: #bbcada;
}

.addUserPageInfo {
  color: #214978;
  margin-left: 15px;
  font-size: 14px;
  padding-right: 20px;
  text-align: left;
  margin-top: 22px;
  margin-bottom: 16px;
  font-weight: 400;
  /* border-left: 2px solid #00ccff; */
  /* padding-left: 10px; */
}



.adminAddUserAddToOtherTeam {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  font-weight: 500;
  font-size: 12px;
  color: white;
  min-height: 25px;
  max-height: 25px;
  border-radius: 5px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 27px;
}

.adminAddUserAddToOtherTeam:hover {
  background-color: #bbcada;
}

.archivedAdminUserItem {
  background-color: #fff;
}

.archivedAdminUserItem:hover {
  /* border-color: #214978; */
  /* opacity: 0.95; */
}

/* .showDisableGoBack { */
/* min-width: 50px; */
/* padding-bottom: 20px; */
/* padding: 12px !important;} */

.adminInitsRemovedHeight {
  max-height: 40px !important;
  min-height: 40px !important;
  max-width: 40px !important;
  min-width: 40px !important;
}

.adminInitsRemovedHeightImg {
  max-height: 40px !important;
  min-height: 40px !important;
  max-width: 40px !important;
  min-width: 40px !important;
  margin-left: -2px;
  /* margin-top: -2px; */
}

.removedInits {
  margin-top: -38px;
}

.adminUsersMobDivider {
  min-height: 1px;
  background-color: #bbcada;
  min-width: 100%;
  margin-top: -10px;
}

.pullArchNameUp {
  margin-top: -3px;
}

.disabledQty {
  color: #e2c3fe;
  position: absolute;
  bottom: 82px;
  right: 230px;
  font-weight: 500;
  z-index: 99999;
  font-size: 12px;
  text-align: right;
  cursor: pointer;
}

.disabledQtyMob {
  bottom: 219px;
  right: 36px;
}

.grayscale {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  padding-top: 2px;
}

.adminTeamUnit {
  text-align: left;
  font-weight: 500;
  color: #143051;
  margin-left: 8px;
  /* border-left: 2px solid #859dbb; */
  /* padding-left: 9px; */
}

.adminTeamUsersArr {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  align-items: space-between;
  margin-top: 12px;
}

.adminTeamUsr {
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 3px;
  font-weight: 500;
  margin-bottom: 5px;
  margin-right: 5px;
  font-size: 12px;
  border: 1px solid #e0eefe;
  color: #b4bdd8;
}

.adminTeamsUsrTitle {
  font-size: 14px;
  color: #859dbb;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 0px;
  margin-left: 3px;
  display: flex;
  align-items: center;
}

.adminTeamUsrTitleAndUsers {
  /* border-left: 2px solid #bbcada; */
  /* padding-left: 5px; */
  margin-top: 6px;
  margin-bottom: 13px;
}

.adminTeamName {
  margin-left: 2px;
  margin-top: 4px;
  font-size: 18px;
  border-left: 3px solid #143051;
  padding-left: 8px;
}

.teamMgrUsrIcon {
  max-width: 15px;
  margin-right: 4px;
}

.teamNameOver20 {
  font-size: 16px !important;
}

.teamNameOver26 {
  font-size: 14px !important;
}

.editTeamNameImg {
  cursor: pointer;
  min-width: 28px;
  border-radius: 5px;
  border: #e3e8ee solid 2px;
  margin-left: 8px;
}

.editTeamNameImgCanx {
  cursor: pointer;
  border: #e3e8ee solid 2px;
  padding: 6px;
  border-radius: 5px;
  max-width: 28px;
  margin-left: 8px;
}

.adminTeamNameTitle {
  margin-top: 0px;
  font-size: 18px;
  font-weight: 500;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.saveAdminTeamName {
  margin-right: 0px !important;
  margin-left: 8px;
}

.adminTeamNameInput {
  color: #6a8bb1;
  min-height: 40px;
  padding-left: 9px;
  font-weight: 500;
  margin-left: 5%;
  /* min-width: 320px; */
  /* margin-top: 5px; */
  /* margin-bottom: 3px; */
  font-size: 16px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 7px;
  background-color: #fdfeff;
  /* margin-bottom:10px; */
  /* margin-top:10px; */
}

.addAdminTeamNameInput {
  margin-bottom: 10px;
  margin-top: 10px;
  min-width: 300px;
}

.adminTeamModalBody {
  background-color: white;
}

.adminTeamStat {
  background-color: #214978;
  color: white;
  min-height: 60px;
  min-width: 60px;
  max-width: 60px;
  max-height: 60px;
  border-radius: 5px;
  border: 2px solid #143051;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
}

.adminTeamStatMgrs {
  background-color: #a387df;
  border-color: #58467e;
  margin-bottom: 8px;
  margin-top: 20px;
}

.adminTeamStatNum {
  font-weight: 500;
  font-size: 28px;
  line-height: 28px;
}

.adminTeamStatVal {
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  margin-top: 2px;
}

.adminTeamRowTitle {
  font-size: 16px;
  color: #214978;
  font-weight: 500;
  display: flex;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  margin-left: 14px;
}

.adminTeamUsrRow {
  margin-left: 13px;
  margin-top: 8px;
  margin-bottom: 40px;
}

.teamAdminUsrItem {
  border-top: 1px solid #bbcada;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: calc(100% - 13px);
  min-height: 50px;
  font-size: 14px;
  font-weight: 500;
  color: #8ea7c5;
  padding-bottom: 6px;
  padding-top: 6px;
}

.removeUsrFromTeamImg {
  cursor: pointer;
  max-width: 12px;
  opacity: 0.3;
}

.removeUsrFromTeamImg:hover {
  opacity: 0.5;
}

.adminTeamMgrTxt {
  border-left: 2px solid #a387df;
  padding-left: 7px;
  padding-right: 8px;
  cursor: pointer;
  color: #214978;
  /* font-weight:600 */
}

.adminTeamMgrTxtHover:hover {
  color: #6f85a2;
}

.adminTeamUsrTxt {
  border-left: 2px solid #00ccff;
  padding-left: 7px;
  padding-right: 8px;
  cursor: pointer;
  color: #214978;
}

.adminTeamUsrTxt:hover {
  /* color: #6f85a2; */
}

.adminTeamNameInputoTeamDiv {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}
.adminTeamAddUsrDropdown {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 10px;
  text-align: left !important;
  max-width: 180px;
  min-width: 180px;
  line-height: 26px;
  height: 35px;
  border: 1px solid #e6eaee;
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  border-color: #2c7eb9;
  border: 2px solid #7f59cf;
  background-color: white;
  color: #214978;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 156px;
  margin-left: 12px;
  margin-top: 10px;
  /* float:right; */
}

.addUsrToTeamBorder {
  border-color: #0077ff;
}

.teamsUsrCount {
  margin-left: 7px;
  font-size: 11px;
  color: #859dbb;
  font-weight: 400;
  padding-left: 8px;
  border-left: 1px solid #bbcada;
  /* border: solid 1px #bbcada;
  background-color: #cbd7e6;
  padding-left:4px;
  padding-right:4px;
  border-radius:4px;
  font-size:12px;
color: white; */
}

.teamAdminLeft {
  background-color: white;
  text-align: left;
  max-width: 75%;
  min-width: 75%;
  padding-right: 3px;
  padding-top: 15px;
  min-height: 410px;
  max-height: 410px;
  overflow-y: auto;
  border-top: solid 2px #e6eaee;
  border-right: solid 1px #e6eaee;
  border-left: solid 1px #e6eaee;
  padding-bottom: 30px;
  border-radius: 5px 5px 0px 0px;
  -webkit-font-smoothing: antialiased;
  /* margin-bottom: -8px; */
  margin-left: -2px;
}

.teamAdminLeftMob {
  min-height: 490px;
  max-height: 490px;
}

.adminTeamTitleBar {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.addMgrTeam {
  background-color: #0077ff;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 4px;
  border: 1px solid #0469de;
  font-weight: 500;
  font-size: 12px;
  padding: 2px;
  min-height: 28px;
  padding-left: 7px;
  padding-right: 7px;
  margin-right: 10px;
  cursor: pointer;
}

.addMgrTeamMgr {
  background-color: #9b6cff;
  border: 1px #7f59cf solid;
}

.addMgrTeam:hover {
  background-color: #036ce4;
}

.addMgrTeamMgr:hover {
  background-color: #7f59cf !important;
}

.addMgrWhiteAddImg {
  max-width: 10px;
  margin-left: 5px;
}

.canxAddToTeam {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
}

.canxAddToTeam:hover {
  background-color: #bbcada !important;
}

.noUsersTeamTxt {
  font-weight: 500;
  text-align: left;
  font-size: 12px;
  color: #bbcada;
  margin-top: 10px;
  margin-left: 2px;
  margin-bottom: 40px;
}

.numberOfMgrsWhoAreNotMbrs {
  margin-bottom: 8px;
  min-height: -moz-fit-content;
  min-height: -webkit-fit-content;
  min-height: fit-content;
  max-height: -moz-fit-content;
  max-height: -webkit-fit-content;
  max-height: fit-content;
  padding-bottom: 4px;
  line-height: 12px;
  padding-top: 5px;
  background-color: #ffffff;
  border-color: #859dbb;
  color: #859dbb;
  cursor: pointer;
}

.nonMbrHover:hover {
  border-color: #496375;
}

.mgrsNonMbrsLine {
  background-color: #685086;
  min-width: 4px;
  min-height: 6px;
  margin-left: 15px;
  margin-top: -8px;
}

.adminTeamFilterKnob {
  /* background-color: blue !important; */
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 20px;
  padding-bottom: 12px;
  padding-left: 5px;
  margin-top: 8px;
  margin-bottom: 2px;
}

.filterMgrNotUsr {
  background-color: #8200ff;
  border-color: #58467e;
  color: white !important;
}

.adminTeamRowTitleBorder {
  padding-right: 70px;
  padding-left: 8px;
  border-left: 5px solid #a387df;
  color: #6c4daf;
  font-size: 14px;
  line-height: 14px;
}

.adminTeamSelfApproveTxt {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-left: 0px;
  border-left: 2px solid #a387df;
  padding: 2px;

  padding-left: 8px;
  font-size: 11px;
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 2px;
  color: #0af;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-right: 4px;
  /* background-color: pink; */
  min-height: 23px;
}

.adminTeamSelfApproveTxtNonMob:hover {
  background-color: #f2f8ff;
  border-radius: 0 3px 3px 0;
}
.selfAppHover:hover {
  /* color: #07f; */
}

.allowSelfApproveTick {
  background-color: #fff;
  border: 1px solid #dde9f9;
  border-radius: 2px;
  padding: 2px;
  max-width: 15px;
  max-height: 16px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-right: 5px;
}

.selfAppInActive {
  color: #bbcada;
}

.selfAppInActiveHover:hover {
  /* color: #859dbb */
}

.stillMgrSub {
  font-size: 14px;
  color: #516b8b;
  line-height: 18px;
  margin-top: -30px;
  padding-right: 20px;
  padding-left: 10px;
  font-weight: 400;
}

.adminTeamFooter {
  min-width: 90%;
  max-width: 90%;
  margin-left: 5%;
  display: flex;
  justify-content: space-between;
  /* border-top: 1px solid #bac5d3; */
  align-items: center;
  margin-bottom: -14px;
  min-height: 50px;
}

.teamsAddBtn {
  bottom: 80px;
  right: 75px;
}

.teamsAddBtnMob {
  bottom: 120px;
  right: 30px;
}

.accountSettingsProfileRowMob {
  border-radius: 14px;
}

.profileRowProPicImgMob {
  border-radius: 10px 5px 5px 10px;
}

.progessierSubBtn {
  background-color: #294566;
  border: 2px solid #294566;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-size: 13px;
  font-weight: 500;
  /* padding-left: 8px; */
  /* padding-right: 8px; */
  min-height: 35px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-left: 110px;
}

.enablePushDiv {
  min-width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 11px;
}

.noNotifsSize {
  min-height: 110px;
  max-height: 110px;
}

.myAccTitleDesktop {
  margin-top: -15px !important;
  margin-bottom: -6px;
}

.adminHeaderHeightNonMob {
  min-height: 117px;
}

.x8281 {
  margin-top: 0px;
}

.lastUsrNotifsRow {
  border-radius: 0px 0px 0px 10px;
  border-bottom: 2px solid #00ccff;
}

.lastMgrNotifsRow {
  border-radius: 0px 0px 0px 10px;
  border-left: 2px solid #de82f7;
}

.mgrNotifRow {
  border-left: 2px solid #de82f7;
}
.lastMgrNotifsRow {
  border-radius: 0px 0px 0px 10px;
  border-bottom: 2px solid #de82f7;
}

.adminTeamHeaderSettingsRow {
  /* background-color: yellow; */
  min-height: 100px;
  border-bottom: 1px solid #ddebf4;
  min-width: 100%;
  padding-left: 2%;
  padding-right: 2%;
}

.teamAdminSettingsRowPadding {
  padding-left: 5%;
  padding-right: 5%;
}

.x1974274 {
  background-color: white !important;
  max-width: 97% !important;
  min-width: 97% !important;
  margin-left: 0%;
  margin-top: 12px !important;
}

.teamsAdminHeight {
  min-height: calc(100vh - 187px) !important;
  max-height: calc(100vh - 187px) !important;
}

.viewTeamUserProfPic {
  max-width: 24px;
  max-height: 36px;
  min-width: 24px;
  min-height: 36px;
  object-fit: cover;
  border-radius: 3px;
  margin-top: 2px;
  margin-right: 6px;
}

.proPicPlaceholderViewTeam {
  max-width: 24px;
  max-height: 36px;
  min-width: 24px;
  min-height: 36px;
  object-fit: cover;
  border-radius: 3px;
  margin-top: 2px;
  background-color: #214978;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  font-size:11px
}

.x13981971 {
  display: flex;
  align-items: flex-start;
}

.editTeamPenIcon {
  max-width: 22px;
  margin-left: 4px;
  margin-right: -7px;
}

.x1344222 {
  padding-left: 10px;
  background-color: #b49ce7 !important;
  border: 1px solid #a47fd0 !important;
}

.x1344222:hover {
  background-color: #a58fd4 !important;
}

.notifTitleBtnTxt {
  font-size: 10px;
  font-weight: 500;
}

.pushEmailRadio {
  max-width: 32px;
  min-width: 32px;
  max-height: 32px;
  min-height: 32px;
  background-color: white;
  border-radius: 3px;
  font-size: flex;
  align-items: center;
  padding-top: 7px;
  margin-top: 8px;
  cursor: pointer;
  justify-content: center;
  opacity: 0.5;
}

.pushEmailTickImg {
  max-width: 12px;

}

.activeNotifImg {
  opacity: 1;
}

.viewTeamUsrJt {
  color: #bbcada;
  font-size: 10px;
  margin-left: 10px;
}

.viewTeamUsrJt2 {
  color: #516b8b;
  font-size: 10px;
  margin-left: 40px;
  margin-top: -21px;
  margin-bottom: 9px;
}

.x53263 {
  margin-left: 0px;
  margin-bottom: -1px;
}

.myAllowsHeaderLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* margin-top:-8px; */
  margin-left: 4px;
  /* background-color: blue; */
  min-width: calc(100% - 120px);
  min-height: 50px;
  padding-top: 10px;
  margin-top: -20px;
}

.x13211231 {
  margin-top: -4px !important;
}

.x23982983 {
  margin-top: 1px;
}

.hideProfile {
  overflow: hidden !important;
  visibility: hidden;
}

.loadingProfileBallsImg {
  max-width: 120px;
  margin-top: 30px;
}

.primaryAccTeam {
  background-color: #fff;
  color: #496375;
  border: 2px solid #214978 !important;
}

.primaryAccTeam:hover {
  border: 2px solid #143051 !important;
}

.isPrimSpan {
  font-weight: 500;
  font-size: 12px;
  color: #0af;
  border-right: 2px solid #00aaff;
  padding-right: 5px;
}

.x13812214 {
  flex-direction: column;
  align-items: flex-end;
}

.x1212131 {
  border: none;
  padding: 0px;
  color: #bbcada;
  margin-top: -2px;
  margin-bottom: -4px;
}

.teamSwapSettings {
  background-color: white;
  border: 2px solid #bbcada;
  border-radius: 5px;
  min-height: 30px;
  min-width: 60px;
  margin-left: 15px;
  max-width: 60px;
  margin-top: 10px;
  display: flex;
  cursor: pointer;
}

.teamSwapSettings:hover {
  border-color: #a4b3c3;
}

.swapSettingsBtn {
  max-width: 13px;
  margin-left: 1px;
  margin-right: 3px;
}

.swapSettTxt {
  font-size: 11px;
  font-weight: 500;
  text-align: left;
  line-height: 10px;
  margin-top: 8px;
  color: #bac5d3;
  margin-left: 5px;
}

.x139812 {
  max-width: 10px;
  margin-left: 3px;
  opacity: 0.2;
}

.teamSwapRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  /* border-bottom: 1px solid #bbcada; */
  padding-bottom: 10px;
  padding-top: 10px;
  max-width: 90%;
  margin-left: 5%;
  cursor: pointer;
}

.teamSwapRowTop {
  margin-top: 10px;
  border-bottom: 1px solid #bbcada;
}

.teamSwapRowDesktop:hover {
  background-color: #edf3f9;
  cursor: pointer;
}

.teamSwapRowTitle {
  max-width: 130px;
  min-width: 130px;

  font-size: 14px;

  margin-left: 9px;
}

/* fdfaff */
.clickShiftRowAdmin {
  cursor: pointer;
}

.clickShiftRowAdmin:hover {
  background-color: #fdfaff;
}

.mobileAdminRow {
  padding-left: 6px;
  padding-right: 20px;
  min-width: 94%;
  max-width: 94%;
  margin-left: 3.5%;
}

.mobileAdminRow:hover {
  background-color: #fff !important;
}

.adminRowNoSubTxt {
  padding-bottom: 15px;
}

.knobContainerSettings {
  margin-top: 10px !important;
  margin-left: 12px;
}

.pinSpan {
  font-weight: 500;
}

.CompanyClosed {
  margin-top: -7px !important;
}

.readMoreHere {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  color: white !important;
  border-radius: 5px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: 500;
  /* margin-top: 10px; */
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #cbd7e6;    */
  cursor: pointer;
  min-height: 30px;
  float: left;
}

.readMoreHere:hover {
  background-color: #bbcada;
}

.adminUserKeyBar {
  border-right: 1px solid #143051;
  background-color: #143051;
  min-height: 40px;
  min-width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
}

.adminUserKeyBarMob {
  /* border-radius: 15px 15px 0 0; */
  /* min-width: 100%; */
  background-color: #ebf0f7;
  min-width: calc(100vw + 4px) !important;
  max-width: calc(100vw + 4px) !important;
  margin-left: -2px;
  border-radius: 20px 20px 0 0;
  border-top: 2px solid #e6eaee;
  border-left: 2px solid #e6eaee;
  border-right: 2px solid #e6eaee;
  min-height: 50px;
  padding-left:10px;
}
.adminUsersParent {
  /* background-color: #bbcada; */
  /* border-right:1px solid black */
}

.adminUsersBoxHeight {
  min-height: calc(100vh - 187px);
  max-height: calc(100vh - 187px);
}

.adminUsersBoxHeightMob {
  min-height: calc(100vh - 222px);
  max-height: calc(100vh - 222px);
  border-radius: 0;
  border-top: none;
}

.adminAddUsrBtn {
  background-color: #fff;
  border: 1px solid #143051;
  color: #214978;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 8px;
  cursor: pointer;

}

.adminAddUsrBtnMob {
  border-radius: 10px 4px 4px 4px;
  font-size: 13px;
  background-color: white;
  background: none;
  color: #504588;
  border-color: #504588;
}

.adminAddUsrBtnMob:hover {
  background: none !important;
}

.adminUsrSearchBtn {
  border-radius: 4px;
  margin-left: 5px;
  background-color: #a799cb;
  border: 1px solid #8e76c1;
  color: white;
}

.adminUsrSearchBtn:hover {
  background-color: #a799cb !important;
}

.adminAddUsrBtn:hover {
  background-color: #e0ebf8;
}

.adminUsrSearchBtn:hover {
  background-color: #9d8dc6;
}
.addNewEmpIcon {
  max-width: 12px;
  margin-left: 4px;
  margin-right: 2px;
}

@media screen and (min-width: 820px) {
  .addNewEmpIcon {
    max-width: 20px;
    margin-left: 0px;
    margin-right: -4px;
  }

  .tilWkDrop:hover {
    border-color:#bbcada
  }
}
.addNewEmpIcon2 {
  max-width: 11px;
  margin-left: 4px;
  margin-right: 2px;
}

.showUsersTopBorder {
  border-top: 1px solid #bbcada;
}

.archiveAdminUsersBtn {
  float: right;
}

.adminUserKeyLeft {
  display: flex;
}
.adminUserKeyRight {
  display: flex;
}

.usersArchiveRadius {
  border: solid 1px #5e5573;
  color:white;
  background-color: #a17db0;
}

.usersArchiveRadiusMob {
  border-radius: 4px 10px 4px 4px;
  border: solid 1px #504588;
  background: none;
  color: #504588;
}

.usersArchiveRadius:hover {
  background-color: #93779f;
}

.usersArchiveRadiusMob:hover {
  background: none;
}
.x23089472 {
  border-radius: 10px 4px 4px 4px;
  margin-left: 10px;
}

.x239894 {
  max-width: 8px;
  margin-left: 7px;
}

.x293847872 {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
}

.x293847872:hover {
  background-color: #bbcada;
}

.x1383747 {
  min-height: calc(100vh - 222px);
  max-height: calc(100vh - 222px);
}

.adminTeamSettingsContainer {
  min-height: 100px;
  background-color: white;
  padding-left: 10px;
}

.x183973741 {
  padding-bottom: 20px;
  cursor: pointer;
}

.x1314213433 {
  padding-bottom: 20px;
  cursor: pointer;
}

.x1314213433:hover {
  background-color: #fdfaff !important;
  /* background-color: blue !important; */
  border-radius: 5px;
}

.mgrCanEditKnobContainer {
  margin-top: 10px;
}

.historySpecDaysBtn {
  font-size: 13px;
  color: #fff;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  font-weight: 500;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
  max-height: 30px;
  line-height: 26px;
  border-radius: 5px;
  min-height: 30px;
  margin-right: 5%;
  max-width: 100px;
  text-align: center;
  margin-top: 18px;
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.historySpecDaysBtnDesktop:hover {
  background-color: #bbcada;
}

.historicalCheckBoxInstallImg {
  max-width: 13px;
  margin-left: 6px;
}

.addSpecDayFooter {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}
.closeAddSpecDayBtn {
  font-size: 14px;
  color: #fff;
  background-color: #3b597c;
  border: solid #214978 1px;
  font-weight: 500;
  cursor: pointer;
  padding-left: 6px;
  padding-right: 6px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  max-width: 59px;
  min-width: 59px;
}

.specDayEditKnob {
  margin-top: -18px;
}

.x249898233 {
  min-height: 50px;
  margin-bottom: 2px;
  margin-top: -5px;
  padding-right: 6px;
}

.x298492812 {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  margin-right: 0px;
  margin-top: 5px;
  background-position-x: 60px;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  padding-left: 12px;
  padding-right: 0px;
  min-width: 81px;
  max-width: 81px;
  margin-top:50px;
  margin-right:25px;
  /* margin-left: 167px; */

}

.x298492812:hover {
  border-color: #bbcada;
}

.x240893823 {
  margin-top: -20px;
  margin-right: 30px;}

.x248984234 {
  margin-top: -10px;
}

.specDayDsEdit {
  border-radius: 5px;
  border: none;
  background-color: #fff;
  color: #496375;
  min-height: 35px;
  line-height: 35px;
  font-size: 16px !important;
  padding-left: 14px;
  padding-right: 8px;
  letter-spacing: 1px;
  padding-top: 1px;
  font-weight: 500;
  max-width: 174px;
  min-width: 174px;
  background: url(/static/media/calicongrey.9c7e1aba.svg) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 142px;
  border: 2px solid #dde9f9;
  margin-left: 15px;
  cursor: text;
  margin-right: 23px;
}

.annLeaveSubTxt {
  color: #8997a8;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  max-width: 210px;
  margin-top: 20px;
}

.mobIllusTable {
  margin-top: 50px;
}

.mobAnnLeavSelector {
  margin-top: 27px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.x198348927133 {
  margin-top: -30px;
}

.x289492894892 {
  margin-top: 5px;
}

.mobAnnLeaveContainer {
  margin-top: -27px;
}

.x2989423 {
  margin-top: 20px;
  /* background-color: blue; */
}

.x2484882 {
  font-size: 12px;
  margin-top: 2px;
}

.customLeaveTypeUnit {
  min-height: 62px;
}

.customLeaveTypeSubTxt {
  margin-top: -7px;
  text-align: left;
  padding-left: 4px;
  border-top: 2px solid #dde9f9;
  max-height: 30px;
  line-height: 23px;
  margin-left: 9px;
  font-size: 11px;
  min-width: 160px;
  color: #859dbb;
  font-weight: 500;
}

.addTypePlusImg {
  margin-right: 4px;
  margin-left: 5px;
  max-width: 10px;
}

.showRemovedTypes {
  font-size: 12px;
  color: #fff;
  background-color: #a387df;
  border: 1px solid #8e76c1;
  font-weight: 500;
  cursor: pointer;
  padding-left: 11px;
  padding-right: 11px;
  max-height: 35px;
  border-radius: 5px;
  min-height: 35px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  display: flex;
  align-items: center;
  margin-right: 30px;
  float: right;
  margin-bottom: 10px;
  margin-top: 45px;
}

.showRemovedTypes:hover {
  background-color: #997ed4 !important;
}

.showRemovedTypesDivider {
  min-width: 100%;
  min-height: 2px;
  background-color: #214978;
  margin-top: 10px;
}

.removedTypeTxt {
  border: 2px solid #dde9f9;
  max-height: 20px;
  margin-top: 22px;
  border-radius: 3px;
  margin-right: 14px;
  line-height: 16px;
  font-size: 10px;
  padding-left: 3px;
  padding-right: 5px;
  font-weight: 500;
  background: white;
  color: #516b8b;
}

.customAllowFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
  min-height: 35px;
  margin-bottom: 10px;
}

.customAllowInputParent {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.customAllowInputter {
  border: 2px solid #dde9f9;
  border-radius: 5px;
  margin-right: 0px;
  margin-top: 4px;
  background-position-x: 60px;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  padding-left: 12px;
  padding-right: 0px;
  min-width: 55px;
  max-width: 55px;
  min-height: 35px;
  margin-left: 5px;
  margin-right: 5px;
  /* margin-bottom:-8px */
}

.customAllowInputter:hover {
  border-color: #bbcada;
}

.customAddDeductImg {
  max-width: 28px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: -5px;
  margin-right: 4px;
}

.customAddDeductImg:hover {
  border-color: #bbcada;
}
.x1118383 {
  opacity: 0.4;
  pointer-events: none;
  border-color: #dde9f9;
}

.x289389389 {
  border-color: #214978;
}

.x2892989 {
  margin-left: 0px;
  margin-top: 0px;
  margin-right: 3px;
}

.x88292 {
  opacity: 0;
  pointer-events: none;
}

.unlim {
  color: #c7d5e9;
  font-weight: 500;
  margin-left: 23px;
  min-height: 39px;
  padding-top: 13px;
  font-size: 14px;
  min-height: 66px;
}

.customAllowWithDaysOrHours {
  /* background-color: lightblue; */
}

.daysOrHoursDiv {
  display: flex;
  align-items: center;
  margin-left: 21px;
  margin-top: 6px;
}

.daysOrHoursTxt {
  font-size: 12px;
  font-weight: 500;
  color: #143051;
  border: 2px solid #dde9f9;
  display: flex;
  align-items: center;
  border-radius: 3px;
  padding: 1px;
  padding-left: 3px;
  padding-right: 2px;
  cursor: pointer;
}

.daysOrHoursTxt:hover {
  border-color: #bbcada;
}

.daysorHoursCheckbox {
  max-width: 12px;
  margin-left: 3px;
  border-left: 1px solid #dde9f9;
  padding-left: 3px;
  margin-right: 2px;
}

.x2389923 {
  margin-left: 6px;
}

.x298489243 {
  color: #bbcada;
}

.showLeaveTypeVal {
  font-weight: 500;
  color: #143051;
  margin-top: 40px;
}

.x2894982 {
  font-weight: 500;
  color: #143051;
  margin-left: 22px;
  margin-top: 8px;
  margin-bottom: 15px;
}

.x095985858 {
  position: fixed;
  bottom: 80px;
  right: 38px;
}

.x383838383121 {
  /* background-color: orange; */
  position: fixed;
  bottom: 202px;
  right: 38px;
}

.delayShow {
  -webkit-animation-name: delayShowAnimate !important;
          animation-name: delayShowAnimate !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

@-webkit-keyframes delayShowAnimate {
  from {
    bottom: 0px;
  }

  to {
    bottom: 202px;
  }
}

@keyframes delayShowAnimate {
  from {
    bottom: 0px;
  }

  to {
    bottom: 202px;
  }
}

.x328923121 {
  bottom: 202px;
}

.x298484 {
  min-height: 57px;
}

.x2383821232 {
  min-height: 37px;
}

.x3838383 {
  color: #8ea7c5;
}

.maxHeight480 {
  max-height: 480px;
}

.x3838383243 {
  font-weight: 400;
  font-size: 12px;
  color: #516b8b;
  line-height: 16px;
  margin-top: 10px;
  max-width: 100%;
  margin-bottom: 20px;
  padding-right: 0px;
}

.x9389183812 {
  /* background-color: blue; */
  min-width: 60px;
  margin-left: 10px;
  margin-top: -58px;
}

.x38383813121 {
  margin-top: -17px;
}

.x283829812 {
  margin-top: -1px;
}

.x283891389182 {
  margin-top: -39px;
}

.selfLogPaid {
  border-top: 1px solid #bbcada;
  margin-top: 28px;
  display: flex;
  justify-content: space-between;
  padding-top: 18px;
  font-weight: 500;
  color: #496375;
  font-size: 14px;
  padding-left: 15px;
  /* background: blue; */
  /* min-width: calc(100% + 30px); */
  /* margin-left: -15px; */
  padding-bottom: 19px;
  padding-right: 15px;
  margin-bottom: -16px;
  text-align: left;
}

.selfLogPaid:hover {
  border-radius: 0 0 5px 5px;
  background-color: #f2edf9;
}

.x1384289824 {
  margin-top: -7px !important;
}

.x283819123 {
  font-size: 12px;
}

.x8492892381 {
  min-height: 140px;
}

.paddingBottom0 {
  padding-bottom: 0px;
}

.zoomInSlow {
  -webkit-animation-name: zoomTypeIn !important;
          animation-name: zoomTypeIn !important;
  -webkit-animation-duration: 0.3s !important;
          animation-duration: 0.3s !important;
}

@-webkit-keyframes zoomTypeIn {
  from {
    /* bottom: -10%; */
    /* opacity: 0; */
    -webkit-transform: scale(0.55);
            transform: scale(0.55);
  }
  to {
    /* bottom: 0; */
    /* opacity: 1; */
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes zoomTypeIn {
  from {
    /* bottom: -10%; */
    /* opacity: 0; */
    -webkit-transform: scale(0.55);
            transform: scale(0.55);
  }
  to {
    /* bottom: 0; */
    /* opacity: 1; */
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.latLonInput::-webkit-input-placeholder {
  color: #bbcada;
}

.latLonInput:-ms-input-placeholder {
  color: #bbcada;
}

.latLonInput::placeholder {
  color: #bbcada;
}

.addRtwInput {
  color: #6a8bb1;
  min-height: 85px;
  margin-top: 14px;
  font-weight: 500;
  min-width: 227px !important;
  max-width: 227px !important;
  font-size: 16px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  resize: none;
  background-color: #fdfeff;
  padding: 10px;
}

.addRtwInput::-webkit-input-placeholder {
  color: #bbcada;
}

.addRtwInput:-ms-input-placeholder {
  color: #bbcada;
}

.addRtwInput::placeholder {
  color: #bbcada;
}

.addQdiv {
  border-top: 2px solid #214978;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.addQBtn {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-right: 0px;
  margin-top: 10px;
}

.bg58467e {
  background-color: #214978 !important;
}

.adminOtRatesDiv {
  font-size: 12px;
  margin-left: 15px;
}

.x389181 {
  /* background:blue; */
  min-width: 100%;
}

.x13i121 {
  /* background-color: yellow; */
  padding-right: 15px;
}

.x132432222 {
  min-width: 117%;
}

.x2398298322 {
  margin-top: -4px;
  font-size: 12px;
}

.x121441:hover {
  color: #214978;
}

.x1398748713 {
  margin-top: 15px;
  margin-left: 10px;
}

.x2312221 {
  padding-left: 9px;
}

.x21345678542 {
  min-width: 220px;
  max-width: 220px;
  margin-right: 12px;
  border-color: #214978;
  background-position-x: 194px;
}

.pHolsLeaveDiv {
  /* border-radius: 5px; */
  /* border: 1px solid #bbcada; */
  font-size: 12px;
  padding: 5px;
  max-width: 190px;
  margin-top: 15px;
  padding-top: 10px;
  /* padding-left: 10px; */
  /* padding-right: 10px; */
  text-align: left;
}

.pHolYrSelDiv {
  /* border-top: 1px solid #bbcada; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 6px;
  padding-bottom: 0px;
  padding-top: 6px;
  font-weight: 400;
}

.pHolYr {
  cursor: pointer;
  border-bottom: 2px solid #fff;
  margin-left: 4px;
  margin-right: 4px;
  min-height:20px
}

.selPHolYr {
  font-weight: 600;
  border-bottom: 2px solid #bbcada;
  color:#143051
}

.x283819834 {
  color: #1e88e5;
  border-radius: 3px;
  padding-left: 2px;
  padding-right: 2px;
  font-weight: 500;
  font-size:14px;
  min-width: 20px !important;
}

.pHolIn {
  font-weight: 600;
  margin-top: -8px;
  margin-right: 6px;
}

.pHolLeaveSettsBtn {
  font-size: 12px;
  display: flex;
  align-items: center;
  color: #514588;
  margin-top: 4px;
  margin-right: 0px;
  border:1px solid #bbcada;
  border-radius: 4px;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  /* opacity: 0.8; */
  margin-top: 35px;
  margin-bottom: 0px;
  min-height:35px;
  /* margin-bottom:-10px; */
}

.pHolsLeaveDivMob {
  padding-top: 5px;
  font-size: 12px;
  padding-bottom: 5px;
  color: #496375;
  border: none;
  /* padding-right:10px; */
  border-right:2px solid #bbcada;
  /* border-top: 1px solid #bbcada; */
  /* border-bottom: 1px solid #bbcada; */
  border-radius: 0;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 15px;
  margin-top:20px;
  /* margin-bottom:8px; */
}

.pHolLeaveSettsBtn:hover {
  /* border:2px solid #bbcada; */
  opacity: 1;
}

.purCogPH {
  max-width: 12px;
  margin-left: 5px;
  margin-top: 1px;
}

.showDisabledTxt {
  color: #516b8b;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 15px;
  /* padding-right: 210px; */
  padding-left: 17px;
  text-align: left;
  margin-bottom: 16px;
  max-width:90%
}

.showDisabledTxtMob {
  padding-right: 40px;
  padding-bottom: 10px;
  margin-top: 0px;
}

.x2384898488523 {
  background-color: #3d5667;
  pointer-events: none;
}

.notifPurBorder {
  border-left: 2px solid #de82f7 !important;
}

.height60 {
  height: 60px !important;
}

.addUserNum {
  font-weight: 500;
  font-size: 14px;
  margin-left: 23px;
  margin-top: 15px;
  margin-bottom: -11px;
  color: #0077ff;
}

.x2398911991 {
  font-size: 11px;
  color: #bbcada;
  font-weight: 500;
  margin-left: 23px;
  padding-top: 7px;
  padding-right: 20px;
  margin-bottom: -12px;
}


.x2329829119912 {
  font-size: 11px;
  color: #bbcada;
  font-weight: 500;
  margin-left: 23px;
  padding-top: 7px;
  padding-right: 20px;
  margin-bottom: 8px;
}

.myAccHeaderCorrector {
  margin-left: 3px;
}

.signoutLoading {
  background-color: #fff !important;
  border-color: #bbcada !important;
  color: #bbcada !important;
  padding-right: 10px;
}

.editEmailTxtInput {
  border: none;
  /* border-bottom: 2px solid #dde9f9; */
  /* background-color: #f4f8fe; */
  /* border-radius: 5px 5px 0 0; */
  color: #143051;
  min-height: 35px;
  padding-left: 9px;
  font-weight: 500;
  /* margin-left: 5%; */
  min-width: 90% !important;
  font-size: 14px;
  padding-left: 7px;
  /* background-color: #f4f8fe; */
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 7px;
  background-color: #fdfeff;
  min-width: 220px !important;
  margin-top: 10px;
  /* margin-left:-2px; */
}

.editEmailTxtInput:focus {
  border: 2px solid #0af;
  border-radius: 5px;
}

.saveEmailAccBtn {
  font-size: 14px;
  color: #fff;
  background-color: #0bf;
  border: 1px solid #0af;
  font-weight: 500;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  max-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  min-height: 35px;
  max-width: 62px;
  min-width: 62px;
}

.saveEmailAccBtn:hover {
  background-color: #07b0ed;
}

.x28392479211 {
  pointer-events: none;
  opacity: 0.4;
}
.unverifEmailTxt {
  color: #bbcada;
  font-size: 12px;
  padding-left: 10px;
  /* padding-bottom:10px; */
}

.unverifEmailTxt:hover {
  color: #143051;
  cursor: pointer;
}

.x928392741 {
  pointer-events: none;
  cursor: default;
}

.unverifEmailTxt:hover {
  color: #bbcada;
}

.overtimeExample {
  border: 1px solid #e6e6e6;
  padding: 5px;
  border-radius: 10px;
  padding-top: 12px;
}

.overtimeExampleTxt {
  font-weight: 500;
  color: #143051;
  font-size: 14px;
  margin-left: 10px;

  margin-bottom: 10px;
}

.exampleRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #859dbb;
  /* padding-top:5px; */
  padding-bottom: 5px;
  /* border-top: 1px solid #e6e6e6; */
  border-bottom: 1px solid #e6e6e6;
  padding-right: 10px;
  margin-top: 5px;
}

.exampleRow2 {
  border-bottom: 2px solid #214978;
  margin-top: 5px;
  margin-bottom: 10px;
  padding-bottom: 16px;
}

.exampleNameTitle {
  max-width: 40%;
  margin-left: 10px;
  color: #143051;
}

.exampleMainVal {
  color: #0077ff;
  font-weight: 500;
}

.updateOtReqsBtn {
  font-size: 14px;
  line-height: 14px;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  background-color: #07f;
  border: #0469de 1px solid;
  color: white;
  border-radius: 5px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  margin-left: 2%;
  margin-bottom: 19px;
}

@media screen and (min-width: 820px) {
  .updateOtReqsBtn:hover {
    background-color: #036ce4;
  }
  .updatedReqItem:hover {
    background-color: #f4f8fe;
  }
}

.updatereqsImg {
  max-width: 17px;
  margin-left: 6px;
}

.updatedReqsBox {
  border-top: 1px solid #bbcada;
  min-height: 360px;
  max-height: 360px;
  overflow-y: scroll;
  border-bottom: 1px solid #bbcada;
}

.updatedReqItem {
  border-top: 1px solid #bbcada;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  color: #516b8b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.updatedReqItem:first-child {
  border-top: none;
}

.updatedReqNameStrip {
  font-size: 10px;
}

.updatedReqNameStrip {
  line-height: 14px;
  max-width: 90px;
}

.otEachUsrDisclaim {
  color: #143051;
  padding-left: 10px;
  border-left: 2px solid #143051;
  line-height: 16px;
  font-size: 12px;
  margin-top: 20px;
  margin-left: 15px;
  margin-bottom: 18px;
}

.dashboardLeftDiv {
  min-height: calc(100vh - 140px);
  border-right: 1px solid #cbd7e6;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow-y: auto;
  min-width: 220px;
  max-width: 220px;
  max-height: calc(100vh - 140px);
}

.dashboardLeftDivMid {
  min-width: 160px;
  max-width: 160px;
}

.dashboardMasterMain {
  display: flex;

}

.dashMasterMob {
  border-top: 2px solid #e6eaee;
  border-left: 2px solid #e6eaee;
  border-right: 2px solid #e6eaee;
  background-color: #ebf0f7;
  min-width: calc(100vw + 4px);
  margin-left: -2px;
  border-radius: 20px 20px 0 0;

}

.dashUserRow {
  background: white;
  min-height: 40px;
  display: flex;
  padding-left: 15px;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  color: #516b8b;
  text-align: left;
  width: 82%;
  border-bottom: 3px solid #ebf0f7;
  cursor: pointer;
}



@media only screen and (min-width: 820px) {
  .dashUserRow:hover {
    color: #7b429e;
  }
  
}

.dashUserRowFirst {
  border-radius: 10px 10px 0 0;
}
.dashUserRowLast {
  border-radius: 0px 0px 10px 10px;
}

.dashRowSingle {
  border-radius: 5px;
}

.selectedUserDash {
  background-color: #ebddef;
  color: #d534d7;
}

@media only screen and (min-width: 820px) {
  .selectedUserDash:hover {
    color: #d534d7;
  }
}

.dashboardRightDiv {
  /* background-color: blue; */
  min-width: 574px;
  max-width: 574px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 16px;
  /* padding-left:3px; */
}

@media only screen and (max-width: 1034px) {
  .dashboardRightDiv {
    /* background-color: yellow; */
    min-width: calc(100vw - 460px);
    max-width: calc(100vw - 460px);
  }
}

.dashboardRightDivMid {
  min-width: calc(100vw - 400px);
  max-width: calc(100vw - 400px);
}

.dashSubPage {
  background-color: white;
  max-height: calc(100% - 25px);
  min-height: calc(100% - 25px);
  max-width: 93%;
  min-width: 93%;

  /* max-height: 96%; */
  margin-top: 5px;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
}

.unselUsrSubPage {
  background-color: #f9fafd;
}

.selectColl {
  color: #516b8b;
  font-weight: 500;
  margin: 0 auto;
  font-size: 12px;
  margin-top: 90px;
}

.userDashLeft {
  min-width: 120px;
  border-right: 3px solid #ebf0f7;
  min-height: calc(100vh - 181px);
  max-height: calc(100vh - 181px);
  background-color: #ebf0f7;
  overflow-y: auto
}

.userDashLeftBtn {
  background: white;
  min-height: 40px;
  display: flex;
  padding-left: 10px;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  color: #516b8b;
  text-align: left;
  /* width: 90%; */
  border-bottom: 3px solid #ebf0f7;
  cursor: pointer;
}

@media only screen and (min-width: 820px) {
  .userDashLeftBtn:hover {
    color: #7b429e !important;
    background-color: #f6f9ff;
  }

 .userDashFileItem:hover {
  background-color: #f6f9ff !important;
  border-radius:5px;
 }
}

.userDashBtnSel {
  background-color: #ebddef;
  color: #d534d7;
}

.userDashBtnSel:hover {
  color: #d534d7 !important;
  background-color: #ebddef;
cursor: default;
}

.lateStartsDash {
  border-radius: 0 0 0 10px;
}

.dashHistoryArr {
  /* min-height: calc(100vh - 300px); */
  max-height: calc(100vh - 300px);
  overflow: auto;
  padding-right: 3px;

}

.userDashRight {
  min-width: calc(94% - 103px);
  /* margin-top: 2%; */
  margin-left: 2%;
  /* overflow-y: auto; */
  max-height: calc(100vh - 181px);
  padding-right: 10px;
  padding-bottom:15px;
  /* position: fixed;
  top:0;
  left:0;
  min-width: 100vw;
  min-height:100vh;
  background-color: lime; */
}

.userDashRightTeams {
    overflow-y: auto;
    padding-top:10px
}
.userDashDetailsTop {
  display: flex;
  justify-content: space-between;
  background: #f4f6fa;
  border-radius: 5px;
  min-width: 100%;
  align-items: flex-start;
  padding-top: 5px;
  margin-top:10px;
}

.avgMpwDashRow {
  display: flex;
  justify-content: space-between;
  background: #f4f6fa;
  border-radius: 5px;
  min-width: 100%;
  align-items: flex-start;
  padding-top: 11px;
  margin-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  min-height:55px;
}



.userDashProPicImg {
  min-width: 80px;
  min-height: 80px;
  max-width: 80px;
  max-height: 80px;
  object-fit: cover;
  border-radius: 5px;
  border: 2px solid #143051;
}

.userDashProPicImgMid {
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
}

.userDashDetailsLeft {
  min-width: 70%;
  padding-bottom: 10px;
  /* padding-top:10px; */
}

.userDashDetailsLeftRow {
  border-bottom: 1px solid #dde9f9;
  margin-left: 15px;
  text-align: left;
  padding-bottom: 5px;
  padding-top: 5px;
}

.userDashDetailsLeftRowTitle {
  font-size: 11px;
  color: #214978;
  text-align: left;
}

.userDashDetailsLeftRowValue {
  font-weight: 500;
  font-size: 13px;
  color: #143051;
  margin-top: 2px;
  max-width: 210px;
  /* line-height:17px; */
  text-align: left;
}

.userDashDetailsRight {
  margin-right: 15px;
  margin-top: 0px;
}

.editProfileBtnDash {
  display: flex;
  align-items: center;
  background-color: #b49ce7;
  border: 1px solid #a47fd0;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
  min-height: 35px;
  font-weight: 500;
  max-width: 80px;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;
  margin-top: 5px;
  min-width: 60px;
  
}

.editProfileBtnDashMid {
  font-size: 12px;
  min-height: 30px;
}

@media only screen and (min-width: 820px) {
  .editProfileBtnDash:hover {
    background-color: #a58fd4 !important;
  }
}

.editProfilePenIcon {
  width: 10px;
  margin-left: 7px
}

.editProfilePenIconMid {
  width: 9px;
  margin-left: 4px;
}

.dashEmail {
  font-size: 12px;
  cursor: pointer;
}


.avgMpdHistBtn {


  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: -moz-fit-content;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  min-height: 35px;
  max-height: 35px;
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  border-radius: 5px;
  cursor: pointer;
}

  @media only screen and (min-width: 820px) {
    .avgMpdHistBtn:hover {
      border-color: #bbcada !important;
    }
  }

  .avgHistTriangle {
    width: 7px;
    margin-left: 8px;
    margin-right: 2px;
  }

  .avgHistTriangleActive {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }



  .dashExcessArrItem {
    background-color: #fbfbfb;
    width: 255px;
    margin-top: 6px;
    border-radius: 5px;
    min-height: 40px;
    float: right;
    border: 1px solid #d3dae1;
   }

   .maxWidth60pc {
    max-width: 70%;
   }

   .dashExcRow {
 display: flex;
 align-items: center;
 justify-content: space-between;
 /* padding-left:5px;
 padding-right:5px; */
 border-bottom:1px solid #dde9f9;
 max-width: 90%;
 margin-left:5%;
 min-height: 30px;
   }

   .dashExcTitle {
    color: #516b8b;
    font-size: 11px;
    font-weight: 400;
    max-width: 50%;
    text-align: left;
    padding-top: 8px;
    padding-bottom: 8px;
   }

   .dashExcValue {
    font-weight: 500;
    font-size: 11px;
    color: #516b8b;
   }

   /* .x19101133 {
    opacity: 0.6;
   } */


   .x10910 {
    display: flex;
    flex-direction: column;
   }

   .x1910911 {
    min-width: 100%;
    margin-left: 0px;   }

    .x920842 {
      /* background-color: blue; */
      min-width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .dashPayExcess {
      color: #bbcada;
      font-size: 10px;
      max-width: 130px;
      text-align: right;
      margin-top: -3px;    }

      .x392842911 {
        margin-top:6px;
        /* display: flex; */
        /* justify-content: space-between; */
      }

      .x139849111 {
                display: flex;
        justify-content: space-between;
      }

      .worthPlaneImg {
     width:28px;
      }

      .dashUserLoadingimg {
        width:100px;
        margin:30px auto
      }


      .dashTeamItem {
        display: flex;
        /* justify-content: space-between; */
        /* align-items: f; */
        flex-direction: column;
        background: #f4f6fa;
        border-radius: 5px;
        min-width: 100%;
        align-items: flex-start;
        padding-top: 5px;
        margin-bottom:10px;
        padding-bottom:15px;
      }

      .dashAdmin {
        background-color: #bbcada;
        color: white;
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 5px;
        font-size: 11px;
        min-height: 18px;
        max-width: -webkit-fit-content;
        max-width: -moz-fit-content;
        max-width: fit-content;
        display: flex;
        align-items: center;
        font-weight: 500;
        margin-top: 2px;
          }

  .teamDashBtn {
    max-width: 74px;
    min-width: 74px;  }


    .dasthTeamItemUpper {
      display: flex;
      justify-content: space-between;
      min-width: 100%;
      align-items: flex-start;
    }
    
    .dashTeamUserSection {
      min-width: 100%;
      display: flex;
      justify-content: flex-start;
      padding-left:15px;
      /* padding-right:15px; */
    }

    .dashTeamMgrs2 {
      margin-left:15px
    }

    .dashTeamMgrTitle {
      font-size: 11px;
      color: #214978;
      text-align: left;
      border-bottom: 1px solid #dde9f9;
      font-weight: 500;
      line-height: 20px;
      }

      .dashTeamPerson {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #516b8b;
        font-size: 10px;
        margin-top: 8px;
        padding-left: 3px;
        text-align: left;
        cursor: pointer;
        line-height: 12px;

      }

      .dashMgrIcon {
        width: 10px;
        margin-right:3px;
        padding-left:2px;
        padding-right:2px;
      }


      .selectedDashTeamMgr {
        background-color: #d1baf3;
        max-width: -webkit-fit-content;
        max-width: -moz-fit-content;
        max-width: fit-content;
        border-radius: 3px;
        color: white;
        padding-right: 5px;
        min-height: 20px;
        padding-left: 3px;
        margin-left: 0px;      }


      .selectedDashTeamUsr {
        background-color: #a1bbf1;
        max-width: -webkit-fit-content;
        max-width: -moz-fit-content;
        max-width: fit-content;
        border-radius: 3px;
        color: white;
        padding-right: 5px;
        min-height: 20px;
        padding-left: 3px;
        margin-left: 0px;      }

        .dashManageTeamsBtn {
          color: #516b8b;
          font-size: 12px;
          font-weight: 500;
          text-align: right;
          margin-right: 15px;
          cursor: pointer;
          max-width: -webkit-fit-content;
          max-width: -moz-fit-content;
          max-width: fit-content;
          float: right;
          border: 1px solid #bbcada;
          border-radius: 5px;
          min-height: 35px;
          display: flex;
          align-items: center;
          padding-left: 10px;
          padding-right: 10px;
          ;
        }

        @media only screen and (min-width: 820px) {
          .dashManageTeamsBtn:hover {
background-color: #f9fbfc;          }
        
        }


        .x99j {
          margin-top:10px;
          padding-right:2px;
        }

        .x13vf {
          margin-top: 10px;
          margin-right: 30px;
          margin-bottom: 32px;
        }

        .dashYrSelectorDropdown {
          border-radius: 5px;
          font-size: 14px;
          font-weight: 500;
          padding-left: 10px;
          text-align: left !important;
          max-width: 69px;
          min-width: 69px;
          line-height: 26px;
          height: 35px;
          /* border: 1px solid #e6eaee; */
          -webkit-appearance: none;
          display: flex;
          cursor: pointer;
          /* border-color: #2c7eb9; */
          border: 2px solid #dde9f9;
          /* background-color: #0bf; */
          background-color: white;
          color: #6a8bb1;
          background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
          background-position-x: 48px;
        }



        .dashDocsUserSelect {
          border-radius: 5px;
          font-size: 14px;
          font-weight: 500;
          padding-left: 10px;
          text-align: left !important;
          max-width: 229px;
          min-width: 229px;
          line-height: 26px;
          height: 35px;
          /* border: 1px solid #e6eaee; */
          -webkit-appearance: none;
          display: flex;
          cursor: pointer;
          /* border-color: #2c7eb9; */
          border: 2px solid #dde9f9;
          /* background-color: #0bf; */
          background-color: white;
          color: #6a8bb1;
          background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
          background-position-x: 208px;
          margin-right:12px
        }

        .dashDocsUserSelectMini {
          background-position-x: 171px;
          max-width: 192px;
          min-width: 192px;
          font-size: 11px;
        }
        .dashMonthSel {
          background-position-x: 85px;
    max-width: 109px;
    min-width: 109px;
        }

        .dashAllowPercentSpan {
          color:#8ea7c5;
          /* padding-left:12px; */
        }

        .noLongerAvailAllowDash {
          font-size:11px
        }

        .allowsLoadDash {
          margin-top: 0px;
          margin-bottom: -7px;        }


          .redRemaining {
            color:#ba0000
          }



          .dashTabHours {
            border-radius: 5px 5px 0px 0px;
            overflow: auto;
            padding-top: 10px;
            padding-bottom: 15px;
            max-height: calc(100vh - 268px);
          }

          .dashTabHoursAbsInstances {
            max-height: calc(100vh - 283px);
            padding-bottom: 20px;
          }

          .dashTabHoursTimesheets {
            max-height: calc(100vh - 263px);

          }

          .dashTabHoursWithBottomBar {
            min-height:calc(100vh - 300px);
            max-height:calc(100vh - 300px);
          }

          .dashTabHoursTotals {
            border-top:1px solid #143051;
            min-height:30px;
            max-height:30px;
            min-width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }

          .noDashHours {
            color: #bbcada;
            font-weight: 500;
            font-size: 12px;
            margin-top: 30px;          }

            .adminBlob {
              background-color: #d9e3f2;
              min-width: 4px;
              min-height: 20px;
              border-radius: 10px;
              margin-right: 7px;
              margin-left: -11px
            }


            .dashHoursTopRight {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              margin-top:-5px;
            }
            .openDataTabOnUser {
              color: #bbcada;
    font-size: 11px;
    font-weight: 500;
    margin-top: 2px;
    margin-right: 4px;
    cursor: pointer;
            }


            .x1093901111a {
              /* margin-top:4px */
            }

            .x18191x {
              margin-top:-7px
            }
.marginTop20 {
  margin-top:20px
}

.dashAbsMenuSel {
  max-height: 30px;
  padding-top: 10px;
}

.absPage1Height {
  padding-top:0px;
  padding-bottom:0px;
}

.dashAbsMenuDiv {
  display: flex;
  align-items: center;
  min-width: 142px;
  justify-content: space-between;
  margin-left: 10px;
  margin-bottom: -10px;
  margin-top: -2px;
}

.dashAbsMenuDiv2 {
  margin-top:-3px;
}

.dashAbsInfo {
  font-size: 12px;
  font-weight: 500;
  color: #516b8b;
  padding-bottom:5px;
  cursor: pointer;
}

.dashAbsInfo:hover {
  color:#214978
}

.dashAbsInfoSel {
  color: #143051;
  border-bottom: 2px solid #143051;
  padding-bottom: 3px;}

  .x910930112 {
    margin-top: 6px;
    margin-bottom: 4px;
  }

  .dashItem {
    border-bottom: 1px solid #bbcada;
    width: 98%;
    margin-left: 1%;
    padding-left: 7px;
    padding-right: 7px;
    cursor: pointer;
  }


  .remainingColour {
    color:#0077ff
  }

  .x190390111 {
    font-size: 12px;
    min-height: 30px;
    padding-left: 8px;
    padding-right: 8px;
    max-width: 118px;  }


    .noExcHist {
      color: #bbcada;
      font-size: 12px;
      font-weight: 500;
      margin-top: 8px;    }

    .loadingDashAbsImg {
      width:100px;
      margin: 20px auto
    }



.x23894398423:hover {
  background-color: white !important;
}

.dashTsApproveImg {
  min-width: 12px;
  max-width: 12px;
  margin-left: 6px;
}

.noWrap {
  flex-wrap: nowrap !important;
}

.backgroundf6f7f9 {
  background-color: #f6f7f9 !important;
}

.x3298298421 {
  opacity: 0.5;
}

.tsDashKey {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 160px;
}

.tsDashKeyTitle {
  font-weight: 500;
  font-size: 10px;
  color: #8ea7c5;
  margin-bottom: 3px;
  padding-left: 2px;
}

.g89492 {
  margin-top:-6px
}

.dashTabHoursTotTitle {
  font-weight: 500;
  font-size: 10px;
  color: #8ea7c5;
  margin-left: 70px;
  margin-right: 16px;
  display: flex;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  align-items: flex-end;
}

.dashTabHoursTotValue {
  color: #516b8b;
  padding-left: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.tilProfileInfoSpan {
  font-size:10px; 
  color:#859dbb;
}

.X329892422 {
padding-top:20px;

padding-bottom:20px;
}


.x1933819981313 {
  color:#0077ff
}

.dashClockCardBreakIcon {
  opacity: 0.8;
}

.dashClockTimeStrip {
  color:#b1c2d4
}

.x29484298422 {
  margin-bottom: -37px;
  margin-top: 8px;}

  .dashFormsContainer {
    /* background-color: blue; */
    min-height:calc(100vh - 246px);
    max-height:calc(100vh - 246px);

  }

  .notYetCompDashForm {
    min-width: 8px;
    min-height: 8px;
    background-color: #bbcada;
    border-radius: 10px;
    margin-left: 4px;
  }

  .x317931781 {
    /* margin-bottom: -13px; */
  min-width: 154px;
  }

    .completedFormDashTickImg {
      width:10px;
      margin-left:4px;
    }


.absenceDashDataBoard {
  /* background-color: blue; */

  /* overflow-y:auto */
}

.x398428924892 {
  min-height: calc(100vh - 267px);
  max-height: calc(100vh - 267px);
  overflow-y: auto;
  padding-bottom: 20px;
  margin-top: 10px;

}

.adminTeamItem {
  min-width: 96%;
  max-width: 96%;
  border:#e6e6e6 solid 1px;
  margin-top:12px;
  margin-left: 10px}

  .adminTeamItemMob {
    border-radius:10px
  }

 

  .teamEditAdminBtn {
    min-width: 90px;
  }

  @media screen and (min-width: 854px) and (max-width: 1025px) {
    .adminTeamItem {
      min-width: 94%;
      max-width: 94%;
      margin-left: 10px;
    }
  }

@media screen and (min-width: 1026px) {
  .adminTeamItem {
    max-width: 520px !important;
    min-width: 520px !important;
    margin-left: 10px;
  }
}

.variationExplain {
  font-size: 12px;
  color: #516b8b;
  margin-top: 10px;}

  .lateDashMenu {
    margin-bottom: 0px  !important;
    min-width:90px;
  }

  .colorBa0000 {
    color:#ba0000 
  }

  .docsLoadingDash {
    margin-top: -10px;
    margin-bottom: 1px;
 }

    .dashDocsSizeMb {
      color:#bbcada;
      font-weight: 500;
      /* margin-top:2px; */
      /* margin-bottom:3px; */
      font-size:12px;
      line-height:12px;
      max-width: 130px;
      margin-left:10px;
    }

    .dashAllowsScroll {
      max-height: calc(100vh - 268px);
      min-height: calc(100vh - 268px);
    padding-bottom:15px;
      overflow: auto;  
    
    }

    .docSashLeftRow {
      /* background-color: red; */
      display: flex;
      justify-content: space-between;
      min-width: 100%;
      min-height:55px;
      margin-left:0%;
      align-items: center;
    }


    .uploadDocDashBtn {
      background-color: #0bf;
      border: 1px solid #0af;
      height: 35px;
      font-weight: 500;
      /* line-height: 34px; */
      font-size: 14px;
      padding-left: 9px;
      padding-right: 9px;
      border-radius: 5px;
      /* font-size: 0.9em; */
      /* margin-top: 0px; */
      /* padding-bottom: 1px; */
      display: flex;
      align-items: center;
      justify-content: center;
      /* margin-right: 7px; */
      /* margin-left: 2px; */
      /* max-width: 62px; */
      min-width: -webkit-fit-content;
      min-width: -moz-fit-content;
      min-width: fit-content;
      cursor: pointer;
      color:white
    }
    


 
    .x2989248423 {
      padding-right:10px;
    }

    .scrollDashRight {
      overflow: auto;
    }

    .mobDash {
      /* background-color: blue; */
      max-height: calc(100vh - 255px);
    }

    .max25mb {
      font-weight: 500;
      color: #ba0000;
      font-size: 13px;
      margin-left: 4px;
    }

    .dashUserDocsGrid {
      display: grid;
    grid-template-columns: repeat(auto-fill, minmax(117px, 1fr));
    grid-auto-rows: auto;
    grid-auto-flow: row;
    align-content: start; /* Align items to the top */
    min-height: calc(100vh - 246px);
    overflow-y: auto;
    max-height: calc(100vh - 246px);
    padding-bottom:10px;
    

    }
    .dashUserDocsList {
      /* display: flex;
      flex-direction: column; */
    }

    .userDashFileItem {
      min-width: 125px;
      min-height: 130px;
      max-width: 125px;
      max-height: 60px;
      /* border-bottom: 1px solid #e6eaee; */
      /* border-radius: 5px; */
      margin-top: 6px;
      padding-bottom: 10px;
      /* cursor: pointer; */
    }

    .listItemLeft {
      display: flex;
      align-items: center;
      min-width: 60%;
    }

    .userDashFileItemList {
      min-width: 100%;
      min-height: 35px;
      max-height:35px !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      /* max-width: 125px; */
      /* max-height: 60px; */
      border-bottom: 1px solid #e6eaee;
      /* border-radius: 5px; */
      /* margin-top: 6px; */
      /* padding-bottom: 10px; */
    }

    .draggable:active {
      /* Set a transparent background */
      background-color: transparent;
      
      /* Remove the box shadow */
      box-shadow: none;
    
      /* Optionally, you can adjust other properties as needed */
      border: none; /* Remove any border */
      outline: none; /* Remove the outline */
    }

    @media screen and (min-width: 820px) {
      .uploadDocDashBtn:hover {
        background-color: #0af;

      }

      .saveFolderName:hover {
        background-color: #0469de !important;
      }


      .newUserFolder:hover {
        border-color: #bbcada;

      }
      .dashFileIconBtn:hover {
        background-color: #5292de;
        border-color: #3574bf;
        color: white;      }

      .fileIconDelete:hover {
        background-color: white;
        border:1px solid #bac5d3;
        color:#7f91a4
      }


      .editFiltImg:hover {
        background-color: white;
        border:1px solid #bac5d3;
      }
    }


    .dashUserFileIcon {
      max-width: 47px;
      margin-top: 16px;
      opacity: 0.8;
      cursor: pointer;
    }

  
    .dashUserFileName {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 10px;
      padding-left: 6px;
      padding-right: 6px;
      color: #516b8b
       }

       .fileBytes {
        color:#bbcada
       }

       .dashFileIconDiv {
        min-width: 100%;
    min-height: 27px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left:2px;
    padding-right:2px;
       }

       .dashFileIconBtn {
        background-color: #e7f7ff;
        border: 1px solid #c5e4f3;
        border-radius: 3px;
        min-height: 25px;
        width: 47%;
        color: #78ccf4;
        font-weight: 500;
        font-size: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
       }




       .fileIconDelete {
        color: #bbcada;
        background: none;
        border-color: #e6eaee;       }

        .sureDeleteFilenameSpan {
          font-size: 12px;
          line-height:14px;
          color: #bbcada;
          padding-left: 9px;
          padding-right: 9px;
               }


          .newUserFolder {
            background-color: #fff;
            border: 1px solid #e6eaee;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 6px;
            padding-right: 6px;
            border-radius: 5px;
cursor: pointer;            padding-top: 5px;
            padding-bottom: 5px;
            margin-left:8px;
            max-width:37px;
          }



          .newFileNewFolderWrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .newFolderPopIn {
            -webkit-animation-name: zoomModalIn !important;
                    animation-name: zoomModalIn !important;
            -webkit-animation-duration: 0.2s !important;
                    animation-duration: 0.2s !important;
            border-color: #aadbfc;
            border-width: 2px;
          }

          .folderItem {
            /* min-height:60px; */
            border:none
          }

          .folderNameInput {
            color: #6a8bb1;
            min-height: 28px;
            font-weight: 500;
            width: 95%;
            font-size: 11px;
            border: 2px solid #dde9f9;
            border-radius: 3px;
            padding-left: 5px;
            background-color: #fdfeff;
            margin-bottom:-3px;
          }

          .folderNameInput::-webkit-input-placeholder {
            color:#bbcada
          }

          .folderNameInput:-ms-input-placeholder {
            color:#bbcada
          }

          .folderNameInput::placeholder {
            color:#bbcada
          }

          .saveFolderName {
            background-color: #07f;
            border: #0469de 1px solid;
            color:white
          }


.editFolterNameImg {
  width: 21px;
  cursor: pointer;
  padding: 3px;
  margin-bottom: -3px;
  border: 1px solid #fff;
  border-radius: 3px;
  position: relative;
  top: -111px;
  margin-left: 86px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}



.editFolterNameImg:hover {
  border:1px solid #bbcada
}

.privateFileIcon {
  position: relative;
  top: -109px;
  margin-left: 51px;
  width: 19px;
  z-index: 999;

}

.editFolterNameImgPriv {
  top: -134px;
  margin-left: 94px;}

/* .editFolterNameImgList {
  width: 16px;
  cursor: pointer;
  margin-left: 3px;
  padding: 3px;
  border: 1px solid #e3e8ee;
  border-radius: 3px;
} */

.noEllipsis {
  text-overflow: clip;

}

.folderImg {
  margin-bottom: -4px;
  opacity: 0.7;
  margin-top:20px;
  max-width: 67px;
}

.folderImgHovered {
  opacity: 1;
}

.deleteFolderBinImg {
  /* background-color: blue; */
  width:14px;
  /* opacity: 0.6; */
}

.folderDeleteBtn {
  margin-top: 22px;
  margin-left: 98px;
  margin-bottom: -55px;
  max-width: 23px;
  min-width: 23px;
  min-height: 23px;
  max-height: 23px;
  cursor: pointer;
  padding: 3px;
  border: 1px solid #e3e8ee;
  border-radius: 3px;
}

.folderIconNonRename {
  margin-top: 23px;
  cursor: pointer;
  margin-bottom: 4px;}

  .totDocsDash {
    margin-top:-9px;
  }

  .folderIconRename {
    max-width: 62px;
  }

  .backFolderItem {
    background-color: white;
    color: #516b8b;
    /* max-width: 30px; */
    /* font-size: 12px; */
    /* font-weight: 500; */
    /* border: 2px solid #e6eaee; */
    /* border-radius: 5px; */
    /* padding: 6px; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* min-width: 30px; */
    cursor: pointer;
    /* height: 30px; */
    /* min-width: 125px;
    min-height: 120px;
    max-width: 125px;
    max-height: 60px; */

    min-width: 125px;
    min-height: 120px;
    max-width: 125px;
    max-height: 60px;
    border: 1px solid #e6eaee;
    border-radius: 5px;
    margin-top: 6px;
    opacity:1
    /* padding:40px; */
  }

  .folderBack  {
    width:33px;
  }

  .backFolderName {
    font-size:12px;
    font-weight: 500;
    margin-top:18px;
    color:#bbcada
  }

  @media screen and (min-width: 820px) {


    .editFolderRow:hover {
      background-color: #f4f8fe;
    }

    .noHovFold:hover {
      background-color: white !important;
      cursor: default;
    }
    .delFolBtn:hover {
      color: #516b8b
    }

.backFolderItem {
  opacity: 0.8;
}
    .backFolderItem:hover {
      border-color:#bbcada;
      opacity: 1;
    }

  .folderBack:hover {
    border-color: #bbcada
  }


  .editFileImg:hover {
    background-color: white;
    border: 1px solid #bac5d3;
    color: #7f91a4;
  }

}


.dashMenuFilesLeft {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filesize {
  white-space: nowrap;
  overflow: hidden;
  color:#bbcada;
  text-overflow: ellipsis;
  font-size: 10px;
  padding-left: 6px;
  padding-right: 6px;}


  .listFolderImg {
    max-width: 24px;
    margin-top:0px;
    margin-left:10px;
    margin-top:4px;
  }
 

  .listItem {
    border-bottom:1px solid #e6eaee
  }

  .listViewFileName {
    font-size:12px;
  }

  .editFolderRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-bottom: 1px solid #bbcada; */
    padding-left: 10px;
    padding-right: 10px;
    min-height: 50px;
    color: #214978;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    border-radius:5px;
    text-align: left;
 }

 .editFolderNameInput {
  color: #6a8bb1;
  min-height: 35px;
  padding-left: 9px;
  font-weight: 500;
  /* margin-left: 5%; */
  min-width: 96%;
  /* margin-top: 5px; */
  /* margin-bottom: 3px; */
  font-size: 15px;
  border: 2px solid #dde9f9;
  border-radius: 5px;
  padding-left: 7px;
  margin-bottom:10px;
  background-color: #fdfeff;
 }

 .editFolderRowTitle {
  font-size: 12px;
  color: #143051;
  font-weight: 400;
  text-align: left;
  margin-left: 10px;
  margin-bottom: 4px;
  margin-top:25px;
 }

 .delFolBtn {
  color: #bbcada;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  text-align: right;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 0px;
 }

 .editFileImg {
  color: #bbcada;
  background: none;
  border: 1px solid #e6eaee;
    border-radius: 3px;
  height: 25px;
  width: 25px;
  padding:7px;
  /* color: #78ccf4; */
  /* font-weight: 500; */
  /* font-size: 9px; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left:-28px;
 }

 .fileNameBox {
  margin-top: 6px;
  padding-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
 }

 .fileNameInline {
  min-height:-webkit-fit-content;
  min-height:-moz-fit-content;
  min-height:fit-content;
  word-break: break-all;
  overflow-wrap: break-word;
  max-width: 80px;
 }


 .opac04 {
  opacity: 0.4  !important;
 }


 
.dashUserPayRollTop {
  display: flex;
  justify-content: space-between;
  background: #f4f6fa;
  border-radius: 5px;
  min-width: 100%;
  align-items: flex-start;
  /* padding-top: 11px; */
  margin-top: 10px;
  padding-left: 10px;
  padding-right:10px;
  /* padding-bottom: 10px; */
  min-height: 55px;

}

.dashPayRollRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 28px;
  /* border-bottom: 1px solid #e0ebf8; */
  max-width: 96%;
  margin-left: 2%;
  background-color: #f6f4ff;
  border-radius: 5px;
  margin-top:6px;
  margin-bottom:5px
}


.dashPayRollRowSub {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height:40px;
  border-bottom:1px solid #e0ebf8;
  max-width: 96%;
  margin-left:2%;
  max-height: 24px;
  min-height: 24px;
}

.dashPayTitle {
  font-weight: 500;
  color: #143051;
  font-size: 12px;
  padding-left: 6px;
  border-left: 2px solid #f0dfff;
  margin-left: 8px;
  text-align: left;
}

.dashPayTitle2 {
  margin-left: 3px;
  border: none;
  font-size: 14px;

}

.payRollDashDivider {
  min-width: 96%;
  max-width: 96%;
  margin-left:2%;
  min-height:2px;
  background-color: #143051;
  display: none;
  
}

.totUnderline {
  /* border-bottom:2px solid #b3d5ff */
}
.dashPayTitleSub {
  font-weight: 500;
  color: #516b8b;
  font-size: 11px;
  padding-left: 8px;
  border-left: 2px solid #00ccff;
  margin-left: 7px;
  text-align: left;
  line-height:13px;
}

.dashPayValue {
  font-weight: 400;
  color:#99b8d9;
  font-size:11px;
  padding-right:5px;
  min-width: 70px;
  max-width: 70px;
  text-align: right;
  /* background-color: blue; */
}

.dashPayValueCost {
  font-weight: 400;
  color:#99b8d9;
  font-size:11px;
  /* padding-right:5px; */
  min-width: 70px;
  /* max-width: 70px; */
  text-align: right;
  /* background-color: blue; */
}


.dashPayValueWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right:5px;
  /* min-width:  */
}
.makeBold {
  font-weight: 500 !important
}

.dashUserPayRollSheet {
  /* background-color: blue; */
  min-height:calc(100vh - 250px);
  max-height:calc(100vh - 250px);
  overflow-y: auto;
  padding-bottom:30px;
  padding-top:20px;
  padding-left:10px;
  padding-right:10px;
}

.x199ss {
  color: #bbcada;
  padding-left: 4px;
  padding-right: 4px;}

  .dashPayValueRate {
    font-size: 11px;
    color: #a0bddb;
  }

.x8881122 {
  /* border-top:1px solid #143051 */
}

.makeBoldPayRollNum {
  font-weight:500;
  font-size:12px;
  color:#143051
}

.payRollLieuRow {
  background-color: #f2f6f7;
  min-height: 40px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: none;
}



  .lieuPayRowExplain {
    font-size: 9px;
    color: #859dbb;  }

    .totalPayableDashRow {
      background-color: #f4f8fe;
      border: 1px solid #bbcada;
      padding-right: 12px;
      margin-bottom:20px;
      
        }

.totPayDashPayTxt {
  font-size: 14px;
}

.payRollDashHeader {
  color: #214978;
  font-size: 10px;
  font-weight: 500;
}

.exportDashPayRollBtn {
  max-width: 139px;
  float: right;
  margin-right: 2%;
  margin-top: 19px;
  font-size: 13px;
}


.totPaidHoursDashRow {
  background-color:#e6f2ff;
  min-height: 35px;
}

.saveEmailCanx {
  font-size:12px;
  font-weight: 500;
  color:#bbcada;
  margin-top:10px;
cursor: pointer;}

.saveEmailWrap {
  margin-top: 18px;
}

.trackedInfo {

  color: #516b8b;
  font-size: 10px;
  text-align: left;
  padding-left: 10px;
  padding-right: 50px;
  margin-top: -12px;
  margin-bottom: 21px;
  border-left: 2px solid #bbcada;
  margin-left: 15px;
}

.netSal {
  background-color: #214978;
  border-color: #143051;}

  .invalidPayRollDateInput {
    border-color:#ba0000 !important
  }

  .dashAddUserBtn {
    background-color: #e3e9f3;
    border: 2px dashed #bac5d3;
    color: #516b8b;
    border-radius: 7px;
    font-weight: 500;
    font-size: 13px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 17px;
    padding-right: 9px;
    cursor: pointer;
    min-width: 82%;
    margin-bottom: 8px;
    }

    .dashAddUserBtnMob {
      margin-top:8px;
      min-width: 130px;
    }

    .dashAddEmpPlus {
      font-size: 21px;
      font-weight: 600;
      margin-right: 7px;
      margin-top: -4px;
      margin-left: -3px;
    }

    .payDayDrop {
      min-width: 100px;
      max-width: 100px;
      background-position-x: 75px; 
       }

      .weeksPayDayDrop {
        min-width: 50px;
        max-width: 50px;
        font-size: 14px;
        background-position-x: 30px;
        margin-top: 0px;
        padding-left: 14px;
        margin-left:8px;
        margin-right:8px;
      }

      .payDayWeekDayDrop {
        min-width: 129px;
        max-width: 129px;
        font-size: 14px;
        background-position-x: 105px;
        margin-top: 0px;
        padding-left: 14px;
        margin-left:8px;
      }

      .monthPayDayDrop {
        min-width: 77px;
        max-width: 77px;
        font-size: 14px;
        background-position-x: 53px;
        margin-top: 0px;
        padding-left: 14px;
        margin-left: 8px;
      }

      .everyNumDrop {
        min-width: 57px;
        max-width: 57px;
        font-size: 14px;
        background-position-x: 40px;
        margin-top: 0px;
        padding-left: 13px;
        margin-left: 8px;
      }

      .onTheDrop {
        min-width: 77px;
        max-width: 77px;
        font-size: 14px;
        background-position-x: 53px;
        margin-top: 0px;
        padding-left: 14px;
        margin-left: 8px;
      }

      .sameDiffDrop {
        min-width: 141px;
        max-width: 141px;
        font-size: 14px;
        background-position-x: 117px;
        margin-top: 0px;
        padding-left: 14px;
        margin-left: 8px;
      }

      .beforeAfterDrop {
        min-width: 91px;
        max-width: 91px;
        font-size: 14px;
        background-position-x: 67px;
        margin-top: 0px;
        padding-left: 14px;
        margin-left: 3px;
      }


.payDaySelectorParent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* border-bottom: 1px solid #dde9f9; */
  /* padding-bottom: 20px; */
}

.payDaySubSelectDiv {
  display: flex;
  align-items: center;
  margin-top:13px;
}

.payDaySubTxt {
  font-size:12px;
  line-height: 12px;
}


.mobpayDayleft {
  min-width: 40%;
  max-width: 40%;
}

.generatePayDaysBtn {
  font-size: 14px;
  color: #fff;
  border: solid 1px #0469de;
  background-color: #0077ff;
  font-weight: 500;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 8px;
  max-height: 35px;
  border-radius: 5px;
  min-height: 35px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  display: flex;
  align-items: center;
  margin-top: 20px;

}

.genPayDayImg {
  max-width: 23px;
  margin-left: 2px;
}


.genpayDayInfo {
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  color: #bbcada;
  text-align: right;
  margin-top: 10px;
  margin-right: 0px;
  border-right: 1px solid #bbcada;
  padding-right: 10px;
  padding-left: 20px;}


  .noPays {
    font-size: 11px;
    border: 2px dashed #e6eaee;
    max-height: 35px;
    line-height: 30px;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    margin-top: 20px;
    border-radius: 5px;
    padding-left: 10px;
    min-height: 35px;
    padding-right: 10px;
    color: #516b8b;
   
  }

  .avoidWeekend {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #dde9f8;
    border-radius: 5px;
    max-height: 32px;
    font-size: 12px;
    padding-left: 6px;
    color: #516b8b;
    margin-top:15px;
    background-color: #f4f8fe;
    cursor: pointer;
  }

  .avoidCheck {
    background-color: #dde9f9;
    max-width: 15px;
    max-height: 15px;
    border-radius: 3px;
    display: flex;
    margin-right: 7px;
    margin-left: 6px;  }

  .avoidWeekendCheckImg {
    max-width: 16px;
    padding: 3px;
    }

    .nearestTxt {
      max-width: 89px;
      line-height: 10px;
      font-size: 10px;
  
     }

.payDaysMap {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #bbcada;
  min-width: 190px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;}


.payDayUnit {
  height: 38px;
      line-height: 38px;
      color: #496375;
      border-radius: 5px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 500;
      min-width: 190px;
      border: solid 2px #dee5ed;  max-width: 120px;


}

.payDayTxt {
  margin-left: 10px;
  font-size: 13px;
  margin-top: -2px;
  text-align: left;
  font-weight: 500;
  line-height: 18px;
  min-height: 38px;
  display: flex;
  align-items: center;
}

.editPayDay {
  margin-right: 6px;
  max-width: 24px;
  padding-left: 7px;
  padding-right: 7px;
  cursor: pointer;
}


.showPayDaysBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #bbcada;
  border-radius: 5px;
  max-height: 32px;
  font-size: 12px;
  padding-left: 8px;
  color: #516b8b;
  margin-top: 15px;
  background-color: #f5f5f5;
  cursor: pointer;
  padding-right: 8px;

}

.payDayArrImg {
  width: 11px;
  margin-left: 5px;
  margin-top: 2px;}

.showPayDaysActive {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  margin-top: -1px;}


  .editPayDayDate {
    border: none;
    background-color: #fff;
    color: #496375;
    min-height: 31px;
    line-height: 30px;
    font-size: 12px !important;
    padding-left: 14px;
    padding-right: 8px;
    letter-spacing: 1px;
    padding-top: 0px;
    /* text-align: center; */
    font-weight: 500;
    max-width: 130px;
    min-width: 130px;
    background: url(/static/media/calicongrey.9c7e1aba.svg) no-repeat right #fff;
    -webkit-appearance: none;
    background-position-x: -500px;
    border-right: 2px solid #dde9f9;
    margin-left: 0px;
    margin-top: 2px;
    cursor: text;

  }

  .savePayDay {
    border: solid 1px #0469de;
    background-color: #0077ff;
    border-radius: 5px;
    max-height: 22px;
    margin-top: 6px;
    max-width: 22px;
    min-width: 22px;
    min-height: 22px;
    padding: 6px;
    margin-left:5px;
  }

  .savePayDayBtns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -5px;
  }

  .binPayDay {
    border: 1px solid #bbcada;
    border-radius: 5px;
    max-height: 22px;
    margin-top: 6px;
    max-width: 22px;
    min-width: 22px;
    min-height: 22px;
    padding: 5px;
    margin-left: 4px;
    margin-right: 0px;
  }

  .payDayLeftWidth {
    min-width: 40%;
    max-width: 40%;
  }

  .addPayDayBtn {
    max-width: 113px; 
margin-right: 0px;
   
        margin-top: 10px;

    

  }



.editDashPencil {
  max-width: 10px;
  min-width: 10px;
  margin-left: 6px;
  margin-top: -1px;
  opacity: 1;
  margin-right: 11px;
}

.convertImg {
  max-width: 21px;
  margin-left: 6px;
  margin-top: -1px;
  opacity: 1;
  margin-right: 9px;
}
  .mobDashContent {
    min-width: 92vw;
    min-height: 50px;
    margin-left: 4vw;
    border-bottom: 1px solid #e6eaee;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: orange; */
    padding-top:0px;
  }



  .mobDashUserSelectDropDown {
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    padding-left: 12px;
    text-align: left !important;
    max-width: 180px;
    min-width: 180px;
    line-height: 26px;
    height: 35px;
    -webkit-appearance: none;
    display: flex;
    cursor: pointer;
    border: 1px solid #e6eaee;
    background-color: white;
    color: #143051;
    background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right white;
    background-position-x: 155px;
  }

  .mobDashMenu {
    top: 126px;
    right: 4%;
    left: auto;
    padding-right:10px;
    z-index: 99999;
    min-height:35px;
    flex-direction: row;
  }

  .dashSubPageMob {
    position: fixed;
    top: 164px;
    max-height: calc(100% - 255px);
    min-height: calc(100% - 255px);
    min-width: 100vw;
    margin-left: 0px;
    max-width: 100vw;
    left: 0;
    border-radius: 0; 
  background-color: #ebf0f7 !important;
  /* overflow: auto */
  }



  .dashAllowancesContainer {
    min-width: 579px;
    max-width: 579px;
    min-height: calc(100vh - 147px);
    max-height: calc(100vh - 147px);
border-right:1px solid #cbd7e6;
  }




  /* mobile dynamic: */
  @media only screen and (max-width: 820px) {

    .editProPicModalBox {
      border-radius:10px 10px 0 0;
      min-width: 100vw;
      position: fixed;
      bottom:0;
      -webkit-animation-name: moveEditTeamUserBoxUp !important;
              animation-name: moveEditTeamUserBoxUp !important;
      -webkit-animation-duration: 0.1s !important;
              animation-duration: 0.1s !important;
    }
.userDashDetailsTop {
  background-color: white;
  min-width: 95% !important;
  max-width: 95%;
  margin-left: 2%;
}

.avgMpwDashRow {
  background-color: white;
  min-width: 95% !important;
  max-width: 95%;
  margin-left: 2%;
}

.avgMpwDashRowSal {
  min-width: 93% !important;
  max-width: 94%;
  margin-left: 4%;
}

.x10910 {
  margin-left: 3%;
  min-width: 92% !important;
  max-width: 92% !important;
}

.x183981 {
  min-width: 94% !important;
  max-width: 94% !important;}

.dashAllowsScroll {
  /* background-color: blue; */
  min-width: 100vw;
  margin-left: 0px;
}

.dashTeamItem {
  background-color: white;
  min-width: 94% !important;
  max-width: 94% !important;
  margin-left: 3%;
}

.userDashRightTeams {
  min-width: 100% !important;
  max-width: 100% !important;
  padding-bottom: 50px;
  margin-left: 0px;
  max-height: calc(100vh - 255px);
  padding-top:10px;
  overflow: auto;
  padding-top:15px;

}

.userDashRightAllows {
  /* min-width: 98% !important;
  max-width: 98% !important;
  padding-bottom: 50px;
  margin-left: 2%; */
  min-width: 100vw;
  max-width: 100vw;
  margin-left:0px;
  /* padding-left:5px; */

}


.dashAllowsScroll {
  max-height: calc(100vh - 330px);
  min-height: calc(100vh - 330px);


}
.userDashRightSal {
  min-width: 100% !important;
  max-width: 100% !important;
  padding-bottom: 50px;
  margin-left: 0px;
  max-height: calc(100vh - 255px);

}
.dashUserPayRollTop   {
  background-color: white;
  max-width: 92vw;
  min-width: 92vw;
  margin-left:4vw;

  /* padding-left:2%; */
  /* padding-right:2%; */
}

.dashUserPayRollSheet {
  /* background-color: white; */
}

.absrowMob {
  margin-left: 4%;
  min-width: 94% !important;
  max-width: 94% !important;
  margin-top:0px;
}

  .absenceDashDataBoard {
    margin-left: 4%;
    min-width: 94% !important;
    max-width: 94% !important;
    /* min-height: calc(100vh - 350px); */
    max-height: calc(100vh - 350px);
    /* margin-bottom:20px; */
  }

  .dashRightMob100w {
    min-width: 100vw;
    margin-left: 0px;  }


    .mobDashLateHeader {
      margin-left: 4%;
      min-width: 94% !important;
      max-width: 94% !important;    }

      .dashHistoryArr {
        margin-top: 20px;
        /* min-height: calc(100vh - 400px); */
        max-height: calc(100vh - 400px);
        padding-right: 10px;
      }

    .x992c {
      /* background-color: blue; */
      padding-left:5px;
    }

    .absenceDashDataBoard {
      overflow: auto;
    }

    .x139849111 {
      margin-top:0px;
    }

    .x1910911 {
      min-height:48px
    }



    .dashFormsContainer {
      min-height: calc(100vh - 325px);
      max-height: calc(100vh - 325px);
      min-width: 100vw;
      padding-left:3%;
      padding-right:4%
    }

    .dashTabHoursAbsInstances {
      min-height: calc(100vh - 362px);
      max-height: calc(100vh - 362px);
      min-width: 100vw;
      padding-left:3%;
      padding-right:4%
    }

    .dashTabHours {
      min-height: calc(100vh - 320px);
      max-height: calc(100vh - 320px);
      min-width: 100vw;
      padding-left:3%;
      padding-right:4%
    }

    .editFolterNameImg {
      background-color: white;
      border:1px solid white
    }

    .editFolterNameImg:hover {
      border:1px solid white
    }

    .dashUserDocsList {
      min-width: 100vw;
      margin-top:10px;
      min-height: calc(100vh - 330px);
      max-height: calc(100vh - 330px);
      padding-left:3%;
      padding-right:3%;
    }

    .userPayRollDashComponent {
      /* background-color: yellow; */
      min-width: 100vw;
      max-width: 100vw;
      margin-left:0px;
    }

    .dashUserPayRollSheet {
      min-width: 100vw;
      max-width: 100vw;
      margin-left:0px;
      margin-top:20px;
      padding-top:0px;
      min-height:calc(100vh - 344px);
      max-height:calc(100vh - 344px);

    }

    .userDashRightPayRoll {
      /* background-color: blue; */
      min-width: 100vw;
      margin-left:0px;
    }

    .dashPayValue {
      color:#214978
    }
    .dashPayValueRate {
      color:#214978
    }
    .dashPayValueCost {
      color:#214978
    }

    .payRollLieuRow {
      background-color: white;
    }

    .docsTopBar {
      min-width: 94vw !important;
      
    }


    .hoursDashTopBar {
      margin-left: 2vw;
      min-width: 92vw !important;
      max-width: 92vw !important;
     }

     .dashTabHoursTimesheets {
      max-width: 100vw;
      margin-left: 0px;
      position: fixed;
      left: 0;
      top: 248px;
      min-height: calc(100vh - 333px);
      max-height: calc(100vh - 333px);
     }

     .dashAllowancesContainer {
      min-width: 100vw;
      max-width: 100vw;
      border:none;
      min-height: calc(100vh - 204px);
      max-height: calc(100vh - 204px);
     }

     .allowAdminBtn:hover {
      background-color:#cbd7e6 !important
     }

     .dashAllowsUsersBox {
      min-height: calc(100vh - 254px) !important;
      padding-bottom:20px;
      max-height: calc(100vh - 254px) !important;  }
  }
/* end of mobile dynamic */
  

  .noOverflow {
    overflow: hidden !important;
  }

  .paddingBottom20 {
    padding-bottom: 20px !important
  }



  .dashAllowsTopBar {
    border-bottom:1px solid #cbd7e6;
    min-width: 92%;
    max-width: 92%;
    margin-left:4%;
    min-height:50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .allowAdminBtn {
    border: solid 1px #bbcada;
    background-color: #cbd7e6;
    display: flex;
    color: #fff !important;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    text-align: center;
    min-width: 35px !important;
    max-height: 35px;
    min-height: 35px;
    font-size: 14px;
    cursor: pointer;
    font-weight: 500 !important;
    max-width: -moz-fit-content;
    max-width: -webkit-fit-content;
    max-width: fit-content;
  }

  .allowancesAdminCogImg {
    max-width: 14px;
  }

  .allowAdminBtn:hover {
    background-color: #bbcada;
  }

  .dashAllowsUsersBox {
    padding-top:15px;
overflow:auto;
    min-width: 100%;
    min-height: calc(100vh - 197px);
    max-height: calc(100vh - 197px);  }


    .dashAllowRow {
      background-color: white;
      min-width: 90%;
      max-width: 90%;
      margin-left:5%;
      border-radius:5px;
      margin-bottom:8px;
      min-height:60px;
      padding-top:4px;
      /* display: flex; */
      /* align-items: center; */
      /* justify-content: space-between; */
      cursor: pointer;
      border:1px solid #e0ebf8;
    }

    .dashAllowRowSelected {
      min-height:120px
    }

    .dashAllowRow:hover {
      border:1px solid #cfdae6;
    
    }

    @media only screen and (max-width: 820px) {
.dashAllowRow:hover {
  border:1px solid #e0ebf8;

}


    }


    .dashAllowProPic {
      border-radius:5px;
      min-width: 40px;
      max-width: 40px;
      max-height:50px;
      min-height: 50px;
      object-fit: cover;
      margin-left:5px;

    }

    .dashAllowUserInfo {
      display: flex;
      align-items: center;
    }

    .dashAllowUserName {
      font-weight: 500;
      color:#143051;
      margin-left:15px;
      font-size:13px;
      text-align: left;
      line-height:16px;
      max-width: 150px;  
      min-width: 150px;  

      padding-right:20px;
      cursor: pointer;
    }

    .dashAllowUserNameMob {
      font-size: 12px;
      max-width: 100px;  
      min-width: 100px;  

    }

      .dashallowArrImg {
        max-width: 31px;
        margin-right: 10px;
        padding: 10px;
      }

      .dashAllowArrSel {
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg);
      }

      .dashAllowDataSec {
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* padding-left:15px; */
        min-width: 150px;
      }
/* harry */
.dashAllowItem {
  min-width: 90px;
  max-width: 90px;
  font-weight: 500;
  text-align: left;
}
.dashAllowItemTot {
  margin-right:14px;
  border-right:1px solid #bbcada
}


@media only screen and (min-width: 820px) {
  .dashAllowItem {
    min-width: 86px;
    max-width: 86px;
    font-weight: 500;
    text-align: left;
  }
  .dashAllowItemTot {
    margin-right:30px;
    border-right:1px solid #bbcada
  }
  
  
  }

.dashAllowItemTitle {
  font-size: 10px;
  color:#143051;
  font-weight: 400;
}

.dashAllowItemValue {
  font-size: 13px;
  margin-top:2px;
  color:#214978
}



.dashAllowMain {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
}

.dashAllowsUserTable {
  /* background-color: yellow; */
  max-width: 80%;
  margin-left:15%;
  margin-top:10px;
  margin-bottom:20px;
}

@media only screen and (max-width: 820px) {
.dashAllowsUserTable {
  max-width: 90%;
  margin-left:5%
}

}
.dashAllowTableRow {
  /* background-color: blue; */
  display: flex;
  align-items: center;
  border-bottom:1px solid #bbcada;  
    color: #143051;
  font-weight: 500;
  font-size:14px;
}
.dashAllowsUserTable {
  /* border:1px solid #bbcada */
  cursor: default;
}

.dashAllowTableRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom:1px solid #bbcada;
  padding-bottom:8px;
  padding-top:8px;
  padding-left:10px;
  padding-right: 10px;
}

.dashAllowTableTitle {
  font-size: 12px;
  max-width: 100px;
  min-width: 100px;
  text-align: left;
  line-height: 12px;

}

.dashAllowTableItem {
  text-align: left;
}

.dashAllowTableItemTitle {
  font-size: 9px;
  font-weight: 400;
}

.dashAllowTableItemValue {
  color:#214978;
  font-size:12px;
}

.dashAllowTableItem {
  min-width: 70px;
  max-width: 70px;
  /* background-color: yellow; */
}

.dashAllowRightSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 210px;
  max-width: 210px;
}

.colourGreen {color:#82c26a !important}


.annLeaveDashMainRow {
  /* border-bottom:2px solid #214978; */
  padding-bottom:12px;
  border-bottom:none;
  /* margin-bottom:4px; */
}

.allLeaveDashController {
  min-height: 35px;
  max-height: 35px;
  border: 1px solid #bbcada;
  margin-bottom: 13px;
  border-radius: 5px;
  background: #f9fcff;
  margin-top: 10px;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  margin-left: 35%;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right:20px;
}

.dashAddDeductTitle {
  font-weight: 500;
  font-size: 12px;
  max-width: 160px;
  text-align: left;
  line-height: 12px;
  margin-left: 10px;
  color: #214978;
  display: flex;
  align-items: center;
  margin-right: 2px;

}


.yourEarningsDiv {
  display: flex;
  flex-direction: column;
  border: 1px solid #e3e8ee;
  border-radius: 5px;
  background: white;
  padding: 10px;
  margin-top: 10px;
  color: #143051;
  font-size: 12px;
  min-width: 100%;
  margin-bottom:20px;

}

.earnRow {
  border-bottom: 1px solid #e3e8ee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 30px;
  padding-left: 5px;
  padding-right: 5px;
}

.earnTitle {
  font-weight: 500;
}

.earnValue {
  font-weight: 500;
}

.disabledActivate {
  opacity: 0.1;
}

.editUserInactivated {
  border: 2px solid #ba0000;
  border-radius: 5px;
  max-width: 90%;
  margin-left: 5%;
  margin-top: 10px;
  color: #ba0000;
  font-weight: 500;
  padding:10px;
  font-size:14px;
  text-align: left;
}

.activateSideBox {
  /* background-color: blue; */
  min-width: 35vw;
  max-width: 35vw;
/* padding-left:10%; */
  /* border-right:1px solid #bbcada; */
  /* min-width: 100px; */
  /* margin-right:60px; */
  min-height:300px;
  /* padding-right:80px */
}

.resetPassPageDesktop {
  flex-direction: row-reverse;

}

.resetpassMain {
  flex-direction: column;
    min-width: 35vw;
  max-width: 35vw;
  /* justify-content: center; */
  display: flex;
  align-items: center;
}

.paddingRight10pc {
  /* padding-right: 10%; */
}

.activateImg {
  min-width: 250px;
  max-width: 250px;
}

.mobPassMain {
  min-width: 90vw;
  max-width: 90vw;
}

.flexGpsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.avgTool {
  font-weight: 600;
  color: #143051;
  cursor: pointer;
}


.dashAllowEditBtnHolder {
  min-width: 100%;
  display: flex;
  justify-content: flex-end;
  /* padding-right: 5%; */
  border-bottom: 1px solid #e0ebf8;
  /* margin-top: 8px; */
  padding-bottom: 10px;
}


.toilBal {
  text-align: right;
  margin-right:10px;
}


.toilWeeksLimitTxt {
  text-align: left;
  /* border-left: 1px solid #cbd7e6; */
  padding-left: 5px;
}
.toilWeeksLimTitle {
  font-weight: 500;
  color:#bbcada;
  font-size:10px;
}

.toilWeeksLimValue {
  font-weight: 500;
  font-size:12px;
  color:#143051
}

.dashTilList {
  max-width: 300px;
  min-width: 300px;
  margin-left: calc(100% - 300px);
  padding-top: 20px;
  background: #f4f8fe;
  padding-left: 10px;
  padding-right: 10px;
  /* border-radius: 0 0 10px 10px; */
  border-radius:10px;
  border: 1px solid #e0ebf8;
  /* border-top: none; */
}

.dashTilListItem {
  background-color: white;
  border:1px solid #dee5f0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom:10px;
  padding-top:10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.x248924894 {
  background-color: #e7f1ff;
}


@media only screen and (min-width: 820px) {


.dashTilListItem:hover {
  border-color:#bbcada
}
.x248924894:hover {
  border-color:#dee5f0
}
}

.dashTilListItemType {
  font-weight: 500;
  font-size:10px;
  color:#bbcada;
  text-align: right;
  max-width: 148px;
}

.dashTilListItemRight {

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color:#143051;
  margin-right:10px;

}

.dashTilListItemLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color:#143051;
  margin-left:10px;
}

.dashTilListItemDs {
  font-size:12px;
  font-weight: 500;
}

.dashTilListItemTimeStrip {
  font-size: 12px;
  color: #82c26a;
  font-weight: 500;
  text-align: left;
  margin-top: 3px;
  min-width: 126px;
}


  .dashTilListItemDurMins {
    font-weight: 500;
    font-size: 14px;
    color: #82c26a;
}



.convertSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: calc(5% + 5px);
  padding-right: calc(5% + 5px);
  margin-top: 12px;
}

.convertOptionTitle {
  font-weight: 500;
  color:#143051;
  font-size: 12px;
  margin-left:8px;
}

.convertOptionBar {
  display: flex;
align-items: center;
justify-content: space-between;
}

.convertOption {
  background-color: white;
}

.convertKey {
  margin-top:7px;
}

.convertKeyTitle {
  font-size: 11px;
  margin-bottom: 13px;
  margin-top: 3px;
}

.convertToilAnnLeaveDaysSub {
  font-size: 12px;
  color: #bac5d3;}

  .fromToToilTitle {
    text-align: left;
    max-width: 130px;  }

    .toiConvertNewBalance{
      color: #0077ff;
      font-weight: 500;
      font-size: 14px;
      margin-right: 26px;  }


      .toilconvertPreviewDaySpan {
        font-size: 12px;
        color: #bac5d3;
        font-weight: 500;
        margin-right: 24px;
        margin-top: 2px;
        min-width: 77px;
        text-align: center;
          }


.toilConvertInputDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top:5px;
}

.toilConvRowMid {
  min-width: 90%;
  border-left: 2px solid #00ccff;
  border-right: 2px solid #00ccff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 90%;
  margin-left: 5%;
}
.convertArrImg {
  width: 20px;
  margin-right: 64px;
  margin-top: 10px;}




.toilConvertInputDivBtns {
  display: flex;
  align-items: flex-end;
}

    .tilConvMins {
      font-size: 10px;
      font-weight: 500;
      color: #bbcada;
      margin-left: 3px;
      margin-bottom: 10px;    }

      .toilHeaderTxtSmaller {
        font-size:14px;
        min-height:22px;
        max-height:22px;
        line-height:24px;
      }

      .disabled {
        opacity: 0.3;
        /* pointer-events: none; */
      }

      .x238929842122 {
        margin-bottom:10px;
      }

      .itemconvAddedBy {
        font-size:10px;
        margin-top:2px;
      }

      .convArrinline {
        max-width: 10px;
        margin-bottom: 0px;
        margin-right: 4px;
     }

        .minWith76 {
          min-width: 76px;
        }

  


.enableTilRowBtn {
  min-height: 35px;
  border: 1px solid #bbcada;
  margin-bottom: 13px;
  border-radius: 5px;
  background: #fff;
  margin-top: 8px;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  /* margin-left: 35%; */
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  padding-left: 9px;
  font-size: 12px;
  font-weight: 500;
  color: #214978;
  border-radius: 5px;
  border: #e3e8ee solid 2px;
}


.enableTilRowDiv {
  min-width: 100%;
  display: flex;
  justify-content: flex-end;
}

.tilRowKnob {
  margin-top: -7px;
  margin-right: 10px;
margin-left:10px
}

.dashTilType {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 8px;
  border-left: 2px solid #bbcada;
  text-align: left;
  padding-left: 6px;}

  .padRight62 {
    padding-right:62px;

  }

  .dashTilRowTable {
    /* display: flex; */
    /* justify-content: space-between; */
    /* min-width: 100%; */
  }

  .dashProfDashBtn {
    margin-top: 3px;
    min-height: 28px;
    max-height: 28px;
    font-size: 12px;
    max-width: 64px;
    padding-left: 1px;
    margin-left: 12px;
    padding-right: 0px;}

    .dashNameProfSectionSel {
      margin-top:10px
    }
    .dashNameProfSection {
      /* margin-top:-20px; */
      /* padding-top:20px; */
    }

    .dashAllowProPicSel {
      margin-top:-16px;
    }


  .toilDashEditProf {
    min-height: 35px;
    max-height: 35px;
    min-width: 81px;
    margin-top: 8px;
    /* margin-left: -10px; */
    /* margin-right: 21px; */
  }

  .deleteToilConvBtn {
    border: solid 1px #bbcada;
    background-color: #cbd7e6;    color: white;
    font-weight: 500;
    font-size: 12px;
    margin-top: 8px;
    border-radius: 5px;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
  }


  .userAllowToilConvRow {
    max-width: 90%;
    margin-top: 10px;
    margin-left: 15px;
  }

  .userAllowsConversionsArr {
    border-left: 2px solid #bbcada;
    margin-top: 10px;
    margin-bottom: 10px;  }


    .additionalMinsTxtMobLieu {
max-width:85%    }


.padRight20 {
  padding-right:25px;
}

.toilHelpBtns {
  display: flex;
  align-items: center;
}

.toilDashHelpbtn {
  border: solid 1px #bbcada;
  min-height: 35px;
  background-color: #cbd7e6;
  min-width: 66px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 9px;
  padding-right: 9px;
  font-weight: 500;
  color: white !important;
  font-size: 14px;
  border-radius: 5px;
  margin-right: 7px;
}

.toilPopup {
  max-width: 14px;
  margin-left: 2px;
}

.markAbsShLeft {
  /* background-color: blue; */
}

.markAbsShType1 {
  font-size: 10px;
  opacity: 0.5;
  max-height: 14px;
  line-height: 13px;
  text-align: left;
  font-weight: 500;
}

.weekklyEarningsDrop {
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  padding-left: 8px;
  text-align: left !important;
  max-width: 69px;
  min-width: 70px;
  line-height: 26px;
  height: 30px;
  border: 1px solid #9374d6;
  -webkit-appearance: none;
  background-position-x: 66px;
  display: flex;
  cursor: pointer;
  border-color: #2c7eb9;
  border: 1px solid #e6eaee;
  /* background-color: #0bf; */
  background-color: white;
  color: #143051;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 60px;
  margin-bottom: 0px;
  border: none;
}

.noFolders {
  color:#bbcada;
  font-size:10px
}

.moveToFolderDrop {
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  padding-left: 10px;
  text-align: left !important;
  max-width: 149px;
  min-width: 149px;
  line-height: 26px;
  height: 35px;
  /* border: 1px solid #e6eaee; */
  -webkit-appearance: none;
  display: flex;
  cursor: pointer;
  /* border-color: #2c7eb9; */
  border: 2px solid #dde9f9;
  /* background-color: #0bf; */
  background-color: white;
  color: #6a8bb1;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right #fff;
  background-position-x: 128px;
}

.myDocsTitle {
  margin-right:8%;
}

.myDocsModalBox {
  background-color: #214978;
  border-color:#143051
}

.x4289489231 {
  background-color: #143051;
}
.x4289489231:hover {
  background-color: #143051;
  opacity: 0.8;
}

.myDocsIsAdminTxt {
  color: white;
  font-size: 12px;
  font-weight: 400;
  padding-right: 15%;
  text-align: left;
  margin-bottom: 15px;
  padding-left: 8%;
}

.uploadDisclaim {
  font-size: 10px;
  max-width: 85%;
  text-align: left;
  margin-left: 8%;
  margin-bottom: 15px;
  color: #516b8b;
  font-weight: 400;
}

.myDocsBox {
  background-color: #456a98;
  min-height: calc(100vh - 400px);
  max-height: calc(100vh - 400px);

  max-width: 94%;
  margin-left: 3%;
  border:1px solid #7d9ec8;
  border-radius:5px;
  margin-top:10px;
  overflow-y:auto
}

.myDocsFooter {
  min-height: 40px;
  /* border-top: #ffffff1c solid 2px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-left: 5%; */
  /* padding-right: 5%; */
  max-width: 90%;
  margin-left: 3%;
  margin-bottom: 7px;
  margin-top: -3px;
  padding-left:4%
}


.myDocsFolderRow {
  background-color: #567cab;
  border-bottom: 1px solid #919ec4;
  min-height: 50px;
display: flex;
align-items: center;
justify-content: space-between;
color:white;
font-weight: 500;
font-size: 14px;
cursor: pointer;
}

.myDocsFolderRowActive {
  border-bottom:1px solid #567cab
}


@media only screen and (min-width: 820px) {
.myDocsFolderRow:hover {
  background-color: #6692ca;
}

}


.myDocsFolderRowLeft {
  display: flex;
  align-items: center;
  text-align: left;
}
.myDocsFolderRowRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right:15px;
}
.myDocsFolderImg {
  max-width: 22px;
  margin-left:10px;
  margin-right:10px;
}

.myDocsFolderQty {
  font-size: 10px;
  opacity: 0.5;
  /* margin-right:10px; */
}

.myDocsWhiteTriImg {
  max-width: 10px;
  margin-left:10px;
}

.myDocsWhiteTriImgActive {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.myDocsFileName {
  font-size:10px;
  max-width: 180px;
}

.myDocsFolderOpen {
  /* background-color: blue; */
  /* border-bottom:1px solid #919ec4; */

  margin-bottom:4px;
}

.myDocsFolderRowIndent {
  max-width: 95%;
  margin-left:5%;
  border-left:3px solid #919ec4;

}

.myDocsFolderRowIndentFinal {
  border-bottom:3px solid #919ec4;

}

.noFilesInSub {
  text-align: left;
  color:white;
  margin-left:10%;
  font-size:12px;
  font-weight: 500;
  margin-top:8px;
  margin-bottom:8px;
  opacity: 0.5;
}

.firstMyDocsFile {
  margin-top:10px
}

.myFileDs {
  font-size:10px;
  color:#143051
}

.docFileType {
  font-size:10px;
  color:#143051
}

.docFileRight {
  text-align: right;
}

.x238942223 {
  color:#bbcada
}

.x32453234332 {
  color:#516b8b;
  max-width: 90%;
  margin-left:5%
}

.x318924982423 {
  font-size: 10px  !important;
  color:#bbcada  !important
}

.convArr {

}

.jukl {
  max-width: 10px;
  margin-left: 5px;
  margin-right: -1px;

}

.myUplFolder {
  margin-bottom:10px;
}

.dashTilKey {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom:15px
}

.dashTilKeyTitle {
  font-weight: 500;
  font-size:12px;
  color:#516b8b;
  padding-bottom:8px;
  cursor: pointer;
}

@media only screen and (min-width: 820px) {

  .addUserBulkRow:hover {
    background-color: #bbcada;
  }
.dashTilKeyTitle:hover {
  color:#214978;
  border-bottom: 2px solid #d8e9ff;
  padding-bottom:6px
}

.dashTilKeyTitleActive:hover {
  color: #143051;  
  border-bottom:2px solid #143051

}
.addMultiUserBtn:hover {
  background-color: #a58fd4;
}

}
.dashTilKeyTitleActive {
  font-weight: 500;
  color:#143051;
  padding-bottom:6px;
  border-bottom:2px solid #143051
}

.tilListNil {
  color:#bbcada;
  font-size:12px;
  text-align: left;
  font-weight: 500;
  max-width: 90%;
  margin-left:5%;
  margin-top:20px;
  margin-bottom:20px;
  border-left: 2px solid #bbcada;
  padding-left: 8px;
}

.addMultiUserBtn {
  background-color: #b49ce7;
  border: 1px solid #a47fd0;
  display: flex;
  color: #fff;
  /* min-height: 36px; */
  padding-left: 11px;
  padding-right: 11px;
  border-radius: 5px;
  text-align: center;
  /* margin-left: 9px; */
  /* margin-right: 28px; */
  /* margin-right: 108px; */
  min-width: -moz-fit-content !important;
  min-width: -webkit-fit-content !important;
  min-width: fit-content !important;
  max-height: 35px;
  min-height: 35px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  align-items: center;
}

.bulkAddUserModal {
min-width: 800px !important;
}

.bulkAddUsersBox {
  min-width: 100%;
  min-height: 210px;
  border: solid 1px #e6eaee;
  border-top: solid 2px #e6eaee;
  border-bottom: none;
  border-radius: 6px 6px 0px 0px;
  overflow: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  background-color: #f4f8fe;
  max-height:600px;
  overflow-y: auto;
}

.multiUserRow {
     /* display: flex; */
    /* align-items: center; */
    /* justify-content: space-between; */
    padding-left: 10px;
    padding-right: 15px;
    border: 4px solid #214978;
    min-height: 100px;
    background: white;
    margin-top: 4px;
    border-radius: 10px;


}

.multiUserRowFNameBlock {
  /* background-color: blue; */
  margin-right:8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
}

.multiRowUserTitle {
  font-size: 12px;
  line-height: 22px;
  color: #143051;
  font-weight: 500;
  margin-left: 3px;
  padding-top: 8px;
  padding-right: 20px;
  text-align: left;
}

.multiNameInputter {
  margin-left: 0px;
  min-width: 130px;
  max-width: 130px;
  margin-top:2px;
}

.multiEmailInputter {
  min-width: 272px !important;
  max-width: 272px !important;
}


.invalidMultiEmailInput {
  border-color:#ebf1f9
}

.addUserPre {
  min-height: 0px !important;
  max-height: 260px !important;
  overflow:hidden !important
}

.multiRowLeft {
  display:flex;

}

.multiRowRight {
  display:flex;
justify-content: flex-end;
/* margin-right:20px; */
}
.multiUserRowDateBlock {
  margin-top:0px;
}
.multiUserStartDate {
  margin-top:2px;
  margin-bottom:0px;
  margin-left:0px;
  background-color: #fdfeff;
 border:2px solid #dde9f9;
  border-radius:5px;
  color:#143051
  /* max-height:166px; */
  /* min-height:166px */
  /* margin-right: 3px; */
}

.x384298821 {
  margin-left:15px;
}

.multiUserRow1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
}

.multiUserRow2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid #dde9f9;
  min-width: 100%;
  margin-top: 10px;
}

.multiUserRow3 {
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  border-top:1px solid #dde9f9;
  margin-left:-18px
}

.addUserBulkRow {
  border: solid 1px #bbcada;
  background-color: #cbd7e6;
  max-width: 120px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 10px;
  /* margin-right: 35px; */
  margin-bottom: 15px;
}



.partOfTeamsDivBulk {
  /* background-color: blue; */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  /* flex-direction: column; */
  /* margin-left: 5%; */
  margin-top: -6px;
  /* margin-bottom: 8px; */
  /* min-height: 200px; */
}


.bulkTeamSel {
  /* background-color: yellow; */
  margin-left:0px;
  margin-top:10px
}

.marginTop2 {
  margin-top:2px;
}

.multiTeamBlock {
max-width:270px;
}

.bulkAddTeamFirst {
  font-size:12px;
  font-weight: 500;
  color:#bbcada;
  margin-top:12px;
  padding-left: 8px;
  border-left:2px solid #bbcada
}

.multiUserRow3Block {
  max-width: 98%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #dde9f9;
}

.row3BlockLeft {
  width: 80%;
  margin-left: 10px;}

.row3BlockRight {
  width: 14%;
  align-items: center !important;
  margin-top: 33px;}


  .removeMultiUser {
    background-color: #516b8b;
    color: white;
    border: 1px solid #214978;
    border-radius: 5px;
    font-weight: 500;
    font-size: 12px;
    min-height: 28px;
    display: flex;
    align-items: center;
    padding-left: 7px;
    padding-right: 7px;
    margin-top: 10px;
    cursor:pointer
  }

  .removeMultiUser:hover {
    background-color: #486f9e;
  }

  .primBulkDrop {
    margin-top:2px
  }

  .multiJtInput {
    max-width: 180px;
  }

  .color00aaff {
    color:#00aaff
  }

  .addUserBulkRowNil {
    margin-top:80px
  }

  .addUserSingleImg {
    max-width: 15px;
    margin-left: -4px;
    margin-right: 6px;  }

    .addUserSingleImgSingle {
      margin-left: -7px;
      margin-right: 2px;  
    }

    .borderRed {
      border-color:#ba0000
    }

    .multiMetric {
      margin-top: 7px;
      font-size: 12px;
      line-height: 13px;    }


      .newUserBlob {
        min-width: 12px;
        min-height: 57px;
        color: #0af;
        border-radius: 5px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 13px;
      }


.infoModalBehind {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
}

.infoModalBox {
  background-color: white;
  border-radius: 15px;
  min-height: 35px;
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width: 90%;
  min-width: 90%;
}

.infoModalText {
  padding: 45px 20px 35px;
  color: #496375;
}

.infoModalClose {
  margin-top: 35px;
  color: #143051;
  font-weight: 500;
}

.none {
  display: none;
}

/* stats modal */
.statsModalBox {
  background-color: #214978;
  color: white;
  border-radius: 15px;
  min-height: 35px;
  position: absolute;
  top: 45%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width: 90%;
  min-width: 90%;
}

.statsModalText {
  padding: 25px 20px 25px;
  color: white;
}

.statsModalClose {
  margin-top: 25px;
  color: white;
  font-weight: 500;
  font-weight: 100;
}

.statsTitleTxt {
  font-weight: 500;
  padding-bottom: 14px;
}

.statsRow {
  /* background-color: red; */
  display: flex;
  justify-content: space-between;
  line-height: 45px;
  border-bottom: solid 1px #8ea7c5;
}

.statsRowBottom {
  border-bottom: solid 0px;
}

/* ERROR MODAL IN ADMIN AREA */
.none {
  display: none;
}

.errorModalUnderlay {
  background: rgba(0, 0, 0, 0.25);
  min-height: 100vh;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
}

.errorModalBox {
  background-color: #214978;
  color: white;
  border-radius: 15px;
  min-height: 35px;
  position: absolute;
  top: 45%;
  left: 50%;
  padding-top: 40px;
  padding-bottom: 30px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width: 90%;
  min-width: 90%;
}

.errorCodeSpan {
  font-weight: 500;
  color: #00ccff;
}

.errorCloseBtn {
  background-color: white;
  color: #143051;
  line-height: 30px;
  height: 30px;
  border-radius: 15px;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.inputterBg {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 1000;
  /* -webkit-animation: slide 0.05s forwards;
  -webkit-animation-delay: 0.05s;
  animation: slide 0.05s forwards; */
  /* animation-delay: 2s; */
}

/* @-webkit-keyframes slide {
  100% {
    left: 0;
  }
}

@keyframes slide {
  100% {
    left: 0;
  }
} */

.noneInputter {
  display: none;
}

.inputterContainer {
  /* background-color: yellow; */
  display: block;
  width: 100vw;
  height: 230px;
}

.backAndTitleContainer {
  /* background-color: orange; */
  max-height: 20px;
  display: flex;
  align-content: center;
  min-width: 130px;
  margin-top: 40px;
  margin-left: 7%;
}

.closeInputter {
  height: 17px;
}

.inputterTitle {
  line-height: 20px;
  font-size: 1.2em;
  margin-top: -1px;
  color: #143051;
  padding-bottom: 4px;
  min-height: 35px;
  font-weight: 500;
  margin-left: 20px;
}

#textInput {
  min-width: 90%;
  margin-top: 50px;
  font-size: 1.5em;
  height: 48px;
  color: #143051;
  font-weight: 400;
  text-indent: 10px;
  font-weight: 500;
  border: none;
  border-bottom: 1px solid #496375;
}

#textInput::-webkit-input-placeholder {
  color: #496375;
  font-weight: 300;
  text-indent: 10px;
}

#textInput:-ms-input-placeholder {
  color: #496375;
  font-weight: 300;
  text-indent: 10px;
}

#textInput::placeholder {
  color: #496375;
  font-weight: 300;
  text-indent: 10px;
}

.saveBtnContainer {
  min-width: 100vw;
  display: flex;
  justify-content: flex-end;
}

.inputSave {
  background-color: #0077ff;
  height: 35px;
  line-height: 37px;
  min-width: 80px;
  color: white;
  border-radius: 300px;
  margin-top: 23px;
  margin-right: 7%;
  font-weight: 500;
  font-size: 1.1em;
}

.fadedInputter {
  opacity: 0.35;
  pointer-events: none;
}

.uneditable {
  opacity: 0.5;
  /* font-style: italic; */
}

.towerContainer {
  position: fixed;
  top:0;
  left:0;
  background-color: #f5f5f5;
  min-height: 100vh;
  max-height: 100vh;
  min-width: 100vw;
}

.none {
  display: none;
}

.jamDivider {
  min-width: 2px;
  background-color: white;
  opacity: 0.8;
  min-height: 100%;
  max-height: 60px;
  margin-top: 8px;
  margin-left: 4px;
  margin-right: 4px;
}

.noPermissionsForTower {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.towerHeaderContainer {
  min-height: 100px;
  background-color: #1a293a;
  color: white;
  display: flex;
  align-items: center;
  font-weight: 500;
  justify-content: space-around;
  border-bottom: 4px #493d62 solid;
  padding-top: 2px;
}

.towerLogoImg {
  max-width: 160px;
}

.towerHeaderSide {
  min-width: 30%;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.towerHelloTxt {
  font-weight: 400;
  font-size: 0.9em;
  opacity: 0.7;
}
.towerSignoutBtn {
  margin-left: 40px;
  background-color: #73a9d0;
  min-height: 30px;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px;
  cursor: pointer;
  border: 4px #4e6580 solid;
}

.towerBody {
  display: flex;
  min-width: 100%;
  min-height: 100vh;
  max-height: 100vh;
}

.towerSidebar {
  background-color: #143051;
  min-width: 140px;
  max-width: 140px;

  min-height: 100%;
}

.towerMenuContainer {
  /* background-color: orange; */
  min-height: 250px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-around;
  text-align: left;
  /* padding-left: 10px; */
}

.towerMenuRow {
  /* background-color: red; */
  /* margin: 0 auto; */
  min-height: 35px;
  align-items: center;
  display: flex;
  max-width: 100%;
  padding-right: 20px;
  cursor: pointer;
  padding-left: 10px;

  /* display: flex; */
  /* justify-content: space-between; */
}

.towerMenuRow:hover {
  background-color: #102743;
}

.towerMenuTxt {
  margin-left: 14px;
  color: #73a9d0;
  font-weight: 500;
  font-size: 0.9em;
  padding-top: 1px;
}
.whiteColTowerMenu {
  color: white;
  -webkit-animation-name: zoomBtnIn !important;
          animation-name: zoomBtnIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.towerSelctedMenuAccent {
  background-color: #9ac8ea;
  min-width: 4px;
  max-width: 4px;
  min-height: 20px;
  border-radius: 10px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.towerInvisAccent {
  visibility: hidden;
}

.towerContent {
  min-width: calc(100% - 140px);
  /* background-color: yellow; */
  /* margin-top: 20px; */
  /* min-height: 300px; */
  max-height: 100vh;
}

.towerBodyInnerContainer {
  /* background-color: orange; */
  min-height: 100%;
  max-height: 100vh;
  min-width: 100%;
}

.towerNotePadHeader {
  background-color: #9ac8ea;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 80px;
  padding-right: 80px;
  border-bottom: 4px #95a7b8 solid;
}

.towerNoteHeaderDataContainer {
  /* background-color: yellow; */
  display: flex;
  justify-content: space-between;
  min-width: 200px;
}

.towerAddNoteBtn {
  background-color: #0077ff;
  color: white;
  border: 2px solid #143051;
  border-radius: 20px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 500;
  cursor: pointer;
  font-size: 0.9em;
  min-width: 88px;
  text-align: center;
}

.cancelTowerAddNote {
  background-color: #95a7b8;
}

.towerViewDoneBtn {
  background-color: #58467e;
  color: white;
  border: 2px solid #143051;
  border-radius: 20px;
  min-height: 30px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 500;
  margin-left: 10px;
  cursor: pointer;
  font-size: 0.9em;
}

.showAddNoteContainer {
  min-height: 400px;
  background-color: #1a293a;
  min-width: 100%;
  border-bottom: solid 4px #58467e;
  border-left: solid 4px #58467e;
}

.viewDoneNotesNumberSpan {
  padding-left: 10px;
  padding-right: 6px;
  color: #00ccff;
}

.towerNotesContainer {
  /* background-color: blue; */
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
  /* max-height: 100vh; */
  max-height: calc(100vh - 160px);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: scroll;
  padding-bottom: 50px;
}

.towerNoteUnit {
  background-color: white;
  border: 1px solid rgba(89, 89, 89, 0.267);
  border-bottom: 3px solid rgba(89, 89, 89, 0.267);
  max-width: 300px;
  min-width: 300px;
  max-height: 360px;
  /* min-height: 370px; */
  margin-top: 30px;
  margin-left: 30px;
  border-radius: 15px;
  padding-bottom: 30px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.todoNoteBorder {
  border: 1px solid rgba(78, 128, 255, 0.867);
  border-bottom: 3px solid rgba(78, 128, 255, 0.867);
}

.towetNoteContentTxt {
  /* border-bottom: 1px solid #143051; */
  max-width: 90%;
  margin: 5% auto;
  border-radius: 5px;
  color: #4e6580;
  font-size: 0.9em;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  cursor: pointer;
  overflow: scroll;
  max-height: 140px;
  background-color: #f5f5f5;
  padding: 10px;
}

.towerNoteUnitSplitter {
  min-height: 1px;
  min-width: 90%;
  max-width: 90%;
  margin: 0 auto;
  background-color: #d2d5e9;
  margin-bottom: 4px;
}

.towerNoteUnitFooter {
  min-height: 30px;
  /* max-height: 30px; */
  /* background-color: yellow; */
  min-width: 100%;
  margin-bottom: -5%;
  display: flex;
}

.towerNoteUnitHeader {
  display: flex;
  /* background-color: orange; */
  min-width: 100%;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 7px;
}

.towerNoteTs {
  font-size: 0.9em;
  font-weight: 500;
  margin-top: 10px;
  margin-left: 14px;
  color: #4e6580;
  /* opacity: 0.4; */
}

.closeTowerNoteUnitImg {
  max-width: 14px;
  margin-right: 14px;
  margin-top: 10px;
  cursor: pointer;
}

.towerNoteFooterLeft {
  /* background-color: lime; */
  min-width: 50%;
  max-width: 50%;
  font-size: 0.7em;
  text-align: left;
  padding-left: 5%;
  display: flex;
  flex-direction: column;
  min-height: 90px;
  max-height: 90px;
  justify-content: flex-start;
  color: #4e6580;
  padding-top: 10px;
}

.towerNoteFooterRight {
  /* background-color: lime; */
  min-width: 50%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 5%;
  padding-top: 8px;
}

.addTowerUserToNoteBtn {
  background-color: #00ccff;
  color: #143051;
  border: 2px solid #143051;
  border-radius: 20px;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 3px;
  padding-right: 3px;
  font-weight: 500;
  cursor: pointer;
  font-size: 0.7em;
  min-width: 66px;
  max-width: 66px;
  text-align: center;
  margin-right: 6px;
}

.addTowerUserToNoteUnderlay {
  background: rgba(0, 0, 0, 0.05);
  height: 100%;
  min-width: 100vw;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgb(119 119 119 / 50%);
}

.addTowerUserToNoteModalBox {
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  /* min-height: 500px; */
  min-width: 400px;
  max-width: 80%;
  margin: 10% auto;
  border-radius: 20px;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  /* max-height: 500px; */
}

.addUserToNoteTowerTitle {
  font-weight: 500;
  color: #143051;
}
.towerUsersToAddGenerateBox {
  /* background-color: lime; */
  padding-top: 40px;
  overflow: scroll;
  max-height: 300px;
}
.towerUserToAdd {
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0077ff;
  border-bottom: 1px #4e6580 solid;
  max-width: 80%;
  margin: 0 auto;
}
.towerUserToAdd:last-child {
  border-bottom: none;
}
.listOfTowerUsersAddedToNoteContainer {
  margin-top: 8px;
  /* background-color: yellow; */
  overflow: scroll;
  max-height: 90px;
  border-top: 1px solid #4e6580;
}

.towerUserAddedToNoteItem {
  display: flex;
  /* background-color: red; */
  min-width: 130px;
  justify-content: space-between;
  padding-right: 10px;
  margin-top: 6px;
  border-bottom: #73a9d0 1px solid;
  padding-bottom: 6px;
  padding-top: 3px;
  -webkit-animation-name: zoomModalIn !important;
          animation-name: zoomModalIn !important;
  -webkit-animation-duration: 0.1s !important;
          animation-duration: 0.1s !important;
}

.towerUserAddedToNoteItem:last-child {
  border-bottom: none;
}

.towerUserAddedToNoteItemTxt {
  font-size: 0.8em;
  color: #2e4657;
}

.removeTowerUserFromNoteImg {
  max-width: 12px;
  cursor: pointer;
}

.none {
  display: none;
}

.towerNoteFooterClient {
  margin-top: 8px;
}

.towerNoteFooterClient:hover {
  font-weight: 500;
  cursor: pointer;
  color: #0077ff;
}

.towerNoteInput {
  resize: none;
  overflow: scroll;
  border: none;
  background-color: #f5f5f5;
  border: 1px solid #dfdfdf;
  /* margin-top: 3px; */
  border-radius: 15px;
  padding: 8px;
  min-width: 90%;
  min-height: 140px;
  max-height: 140px;
  /* margin-top: 18px; */
}

.saveNoteContentBtn {
  background-color: #0077ff;
  color: white;
  border: 2px solid #143051;
  border-radius: 20px;
  min-height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 500;
  cursor: pointer;
  font-size: 1.1em;
  /* margin-bottom: 10px; */
  min-width: 68px;
  text-align: center;
  max-width: 80px;
  margin-top: -4px;
}

.saveBtnContentNoteDivider {
  min-height: 1px;
  background-color: #e6e6e6;
  min-width: 100%;
  margin-top: 8px;
}

.yourNoteSpan {
  color: #143051;
  margin-right: 8px;
}

.noteTsMadeTimeSpan {
  font-size: 0.9em;
  opacity: 0.6;
  padding-left: 8px;
}

.numberOfNotesTxt {
  background-color: #58467e;
  color: white;
  border: 2px solid #143051;
  border-radius: 20px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 500;
  cursor: pointer;
  font-size: 0.9em;
  min-width: 88px;
  text-align: center;
  display: flex;
}

.numberOfNotesTxtLeft {
  /* background-color: #95a7b8; */
  color: #143051;
  /* border: 2px solid #143051; */
  border-radius: 20px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 500;
  cursor: pointer;
  font-size: 0.9em;
  min-width: 88px;
  text-align: center;
  display: flex;
}

.selectedNumberOfTodoNotes {
  background-color: #143051;
}

.whitecrossFilterToDoImg {
  max-width: 10px;
  margin-right: 8px;
}

.towerNoteTodoContainer {
  display: flex;
  /* background-color: pink; */
  /* padding-bottom: 12px; */
  /* margin-top: 10px; */
  max-width: 73px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.towerTodoTxt {
  font-weight: 500;
  margin-top: 14px;
  color: #143051;
  font-size: 1.2em;
}

.disableRemoveUserFromNote {
  pointer-events: none;
  opacity: 0;
}

.towerClientsParent {
  min-width: 100%;
  background-color: yellow;
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
  overflow: scroll;
}

.towerClientClientsBar {
  background-color: #9ac8ea;
  min-width: 240px;
  border-right: 4px #95a7b8 solid;
  overflow: scroll;
  max-height: calc(100vh - 100px);
}

.towerClientUsersSection {
  background-color: #f5f5f5;
  min-width: calc(100% - 240px);
}

.clientUnit {
  min-width: 220px;
  max-width: 220px;
  min-height: 100px;
  background-color: white;
  border: 2px #143051 solid;
  color: #143051;
  border-radius: 15px;
  margin: 10px auto;
  cursor: pointer;
  font-weight: 500;
  padding: 10px;
  text-align: left;
}

.selectedClient {
  background-color: #143051;
  color: white;
}

.clientID {
  cursor: text;
  -webkit-user-select: all; /* Chrome 49+ */ /* Firefox 43+ */
  -ms-user-select: all; /* No support yet */
  user-select: all; /* Likely future */
  font-size: 0.8em;
  margin-top: 3px;
  /* opacity: 0.5; */
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.clientUsersQty {
  font-size: 0.9em;
  margin-top: 8px;
  color: #00ccff;
}

.clientArchivedUsersQty {
  font-size: 0.9em;
  margin-top: 2px;
  opacity: 0.4;
}

.clientCreated {
  font-size: 0.9em;
  margin-top: 7px;
  opacity: 0.6;
}

.totalClientsTxt {
  font-weight: 500;
  color: #143051;
  text-align: left;
  margin-left: 15px;
  margin-top: 8px;
  /* border-bottom: 1px solid #143051; */
}

.totCliSpan {
  color: #0077ff;
}

.towerClientClientDateContainer {
  background-color: #58467e;
  border-bottom: 2px solid #143051;
  min-height: 125px;
  max-width: 100vw;
  overflow-x: scroll;
  display: flex;
  justify-content: flex-start;
  padding: 4px;
  padding-left: 6px;
  padding-right: 6px;
}

.clientBox1 {
  min-width: 200px;
  max-width: 200px;
  text-align: left;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  /* border-right: 2px solid #75659a; */
  background-color: #462e7e;
  border: 2px solid #6f6092;
  padding: 5px;
  border-radius: 5px;
}

.clientBox1Item {
  display: flex;
  min-width: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  justify-content: space-between;
  padding-right: 12px;
  padding-left: 8px;
  min-height: 34px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #95a7b8;
  /* padding-left:  */
}

.cliBox1CompanyName {
  font-size: 0.8em;
}

.editClientDataValue {
  max-width: 14px;
  cursor: pointer;
}

.dexterContentBox {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  align-items: space-between;
  padding-right: 12px;
  padding-left: 5px;
  min-height: 34px;
  /* align-items: center; */
  border-radius: 4px;
  text-align: left;
  margin-top: 3px;
}

.clientBoxTitle {
  text-align: left;
  font-size: 0.7em;
  margin-top: 2px;
  font-weight: 500;
}

.clientBoxVal {
  font-size: 0.6em;
  cursor: text;
  padding-top: 3px;
  -webkit-user-select: all; /* Chrome 49+ */ /* Firefox 43+ */
  -ms-user-select: all; /* No support yet */
  user-select: all; /* Likely future */
}

.clientBox2 {
  min-width: 240px;
  max-width: 240px;
  text-align: left;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 6px;
  /* border-right: 2px solid #75659a; */
  background-color: #462e7e;
  border: 2px solid #6f6092;
  padding: 5px;
  border-radius: 5px;
  /* padding-left: 8px; */
  /* background-color: rgba(255, 255, 255, 0.1); */
}

.yearStartTitle {
  color: #58467e;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border-radius: 5px 5px 0 0;
  background-color: white;
  margin-left: 5px;
  font-size: 0.8em;
  font-weight: 500;
  padding-left: 5px;
  padding-right: 5px;
}

.yrStartValSpan {
  font-weight: 500;
}

.towerYearStartDropdown {
  color: #fff;
  border-radius: 25px;
  font-size: 0.9em;
  padding-left: 12px;
  text-align: left;
  max-width: 52px;
  min-width: 52px;
  line-height: 24px;
  height: 28px;
  background: url(/static/media/downArrowGrey.e64c50a7.svg) no-repeat right
    #7495ba;
  /* background: url(../../img/general/downArrowNavy.svg) no-repeat right #fff; */
  border: 2px solid #143051;
  -webkit-appearance: none;
  background-position-x: 30px;
  font-weight: 500;
  padding-top: 0px;
  margin-left: 5px;
  /* margin: 10px auto; */
}

.editYearStartDrops {
  display: flex;
  font-size: 0.8em;
  align-items: center;
}

.beyondYearStartYes {
  color: #6ac651;
  font-size: 0.7em;
  font-weight: 500;
  margin-left: 8px;
  text-align: right;
  /* margin-top: -50px; */
}

.beyondYearStartNo {
  color: #d0a3a3;
  font-size: 0.7em;
  font-weight: 500;
  margin-left: 8px;
  text-align: right;
}

.towerInvis {
  visibility: hidden;
}

.refPeriodWidth {
  max-width: 100px;
  min-width: 100px;
}

.refPeriodAndWeekStartContainer {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  position: relative;
  z-index: 0;
  margin-top: 10px;
}

.refPeriodWeekStartUnit {
  /* margin-left: */
  /* padding-top: 50px; */
  position: relative;
  z-index: 0;
}

.yearStartTitleBox {
  display: flex;
  justify-content: space-between;
}

.contactBoxTower {
  margin-top: 8px;
}

.accOwnerEmail {
  cursor: text;
  -webkit-user-select: all; /* Chrome 49+ */ /* Firefox 43+ */
  -ms-user-select: all; /* No support yet */
  user-select: all; /* Likely future */
}

.clientLoadingSvg {
  margin-top: 40px;
}

.trialBoxFaded {
  opacity: 0.4;
  pointer-events: none;
}

.trialLengthInput {
  max-width: 50px;
}

.clientStatusSpan {
  background-color: #0077ff;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 10px;
  color: white;
  margin-right: 8px;
  font-size: 0.9em;
  -webkit-user-select: none; /* Chrome 49+ */ /* Firefox 43+ */
  -ms-user-select: none; /* No support yet */
  user-select: none; /* Likely future */
}

.activeCli {
  background-color: #28a705;
}

.lapsedCli {
  background-color: #ba0000;
}

.trialCli {
  background-color: #4e6580;
}

.trialEndsTxt {
  font-size: 0.55em;
  padding-top: 2px;
  margin-bottom: -2px;
  text-align: right;
}

.towerSettingsBatchContainer {
  background-color: #2e4657;
  color: white;
  min-height: 70px;
  display: flex;
  border-bottom: 3px solid #1a293a;
}

.batchUnit {
  display: flex;
  flex-direction: column;
  background-color: #4e6580;
  border: 2px solid #75879b;
  min-width: 220px;
  max-width: 200px;
  border-radius: 10px;
  margin: 4px;
  font-size: 0.9em;
  cursor: pointer;
  padding-right: 3px;
}

.selectedBatchUnit {
  background-color: #143051;
  border-color: #95a7b8;
}

.batchUnitRow1 {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  /* padding-right: px; */
  margin-top: 10px;
  margin-bottom: -10px;
  font-weight: 500;
}

.batchKnob {
  margin-top: -5px;
  /* margin-left: 8px; */
}

.batchUnitRow2 {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 18px;
  margin-bottom: 7px;
  opacity: 0.8;
}

.batchSecondContainer {
  text-align: left;
  /* background-color: blue; */
  min-height: 300px;
}

.batchUnitDropdowner {
  background-color: #73a9d0;
  border-bottom: 3px solid #75879b;
  min-height: 100px;
  -webkit-animation-name: heightDown;
          animation-name: heightDown;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.batchRunningLight {
  background-color: #6ac651;
  border-radius: 10px;
  padding-left: 2px;
  padding-right: 2px;
  margin-right: 8px;
  opacity: 1;
  -webkit-animation: blinker 0.5s linear infinite;
          animation: blinker 0.5s linear infinite;
}

@-webkit-keyframes blinker {
  50% {
    opacity: 0.3;
  }
}

@keyframes blinker {
  50% {
    opacity: 0.3;
  }
}

.batchRunningLightOff {
  background-color: #75879b;
  border-radius: 10px;
  padding-left: 2px;
  padding-right: 2px;
  margin-right: 8px;
}

.towerUsersContainer {
  max-height: 100%;
  overflow: scroll;
  max-height: calc(100vh - 250px);
  padding-top: 4px;
  min-width: calc(100% - 190px);
  max-width: calc(100% - 190px);
  /* display: flex; */
  /* padding-bottom: 380px; */
}

.towerUserUnit {
  background-color: white;
  min-width: 98%;
  max-width: 98%;
  margin: 4px auto;
  border: 2px #d2d5e9 solid;
  border-radius: 10px;
  min-height: 150px;
  max-height: 150px;
  display: flex;
  align-items: center;
}

.towerNotePadAndUsersContainer {
  display: flex;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  /* disp */
}

.towerClientsNotepadPanelContainer {
  background-color: #d2d5e9;
  min-width: 190px;
  border-right: 4px solid #95a7b8;
  padding: 8px;
}

.clientNotesToDoQty {
  background-color: #0077ff;
  /* border: 1px #00ccff solid; */
  font-size: 0.9em;
  color: #f5f5f5;
  margin-left: 28px;
  border-radius: 15px;
  padding-left: 5px;
  padding-right: 5px;
  min-height: 18px;
  cursor: default;
  -webkit-user-select: none; /* Chrome 49+ */ /* Firefox 43+ */
  -ms-user-select: none; /* No support yet */
  user-select: none; /* Likely future */
  /* margin-bottom: px; */
  /* line-height: 12px; */
  /* padding-top: 1px; */
  /* padding-bottom: 1px; */
}

.clientNoteUnit {
  border: 2px #2e4657 solid;
  background-color: #dbd6f0;
  border-radius: 5px;
  margin-top: 12px;
}

.clientNoteBox {
  font-size: 0.8em;
  text-align: left;
  color: #143051;
  border: 1px solid #95a7b8;
  background-color: #f3eefe;
  padding: 3px;
  padding-bottom: 8px;
  max-width: 96%;
  margin: 3px auto;
  border-radius: 3px;
  /* cursor: pointer; */
}

.clientNoteBoxInput {
  font-size: 0.8em;
  text-align: left;
  color: #143051;
  border: 1px solid #95a7b8;
  background-color: white;
  padding: 3px;
  padding-bottom: 8px;
  max-width: 96%;
  min-width: 96%;
  margin: 3px auto;
  border-radius: 3px;
  min-height: 200px;
}

.clientNoteDone {
  /* background-color: orange; */
  display: flex;
  justify-content: space-between;
}

.noteTodoDone {
  min-width: 14px;
  min-height: 14px;
  max-height: 14px;
  max-width: 14px;
  background-color: #95a7b8;
  border-radius: 20px;
  border: 2px solid #75879b;
  margin-top: 3px;
  margin-left: 8px;
  opacity: 0.5;
  margin-bottom: 6px;
  cursor: pointer;
}

.clientToDoIconTxtDone {
  opacity: 0.5;
  font-size: 0.8em;
  color: #143051;
  margin-top: 2px;
  margin-left: 4px;
}

.noteTodoTodo {
  min-width: 14px;
  min-height: 14px;
  max-height: 14px;
  max-width: 14px;
  background-color: #00ccff;
  border-radius: 20px;
  border: 2px solid #0077ff;
  margin-top: 3px;
  margin-left: 8px;
  /* opacity: 0.5; */
  margin-bottom: 6px;
  cursor: pointer;
}

.clientToDoIconTxt {
  /* opacity: 0.5; */
  font-size: 0.8em;
  color: #143051;
  margin-top: 2px;
  margin-left: 4px;
}

.noteBoxClientWrapper {
  display: flex;
  /* background-color: yellow; */
}

.editClientUnitNoteBtnImg {
  max-width: 24px;
  margin-right: 8px;
  margin-top: 2px;
  cursor: pointer;
}

.clientUnitParentUnit {
  display: flex;
}

.selectedUnitClientBobble {
  background-color: #1d3b5e;
  color: #1d3b5e;
  min-width: 10px;
  min-height: 90px;
  max-height: 90px;
  max-width: 10px;
  margin-top: 33px;
  border-left: 1px solid #102743;
  margin-left: -10px;
  /* z-index: 100; */
}

.clientActionButtonBox {
  min-width: 80px;
  max-width: 80px;
  text-align: left;
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  /* border-right: 2px solid #75659a; */
  background-color: #462e7e;
  border: 2px solid #6f6092;
  padding: 5px;
  border-radius: 5px;
  margin-right: 5px;
}

.deleteClientButton {
  background-color: #6d4b6d;
  border-radius: 5px;
  font-size: 0.85em;
  font-weight: 500;
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
  border: solid 2px #382a57;
  color: #fff;
  cursor: pointer;
  min-height: 30px;
}

.deleteClientButtonSelected {
  background-color: #ba0000 !important;

  border-radius: 5px;
  font-size: 0.8em;
  font-weight: 500;
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
  border: solid 2px #382a57;
  color: #fff;
  cursor: pointer;
  min-height: 30px;
}

.removeClientLoading {
  pointer-events: none;
  cursor: none;
  opacity: 0.4;
}

.clientCounterBar {
  display: flex;
  justify-content: space-between;
}

.refreshClients {
  background-color: #1d3b5e;
  color: white;
  border: #143051 2px solid;
  border-radius: 10px;
  font-size: 0.8em;
  font-weight: 500;
  margin-top: 5px;
  margin-right: 8px;
  padding-top: 3px;
  padding-bottom: 5px;
  padding-left: 4px;
  padding-right: 4px;
  cursor: pointer;
}

.refreshClients:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
  opacity: 0.7;
}

.towerUserUnitBox1 {
  border: 2px solid #e6eef5;
  border-radius: 10px;
  max-height: 140px;
  min-height: 140px;
  margin-left: 4px;
  text-align: left;
  min-width: 100px;
  max-width: 100px;
  padding: 4px;
  font-size: 0.9em;
  color: #143051;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: space-between; */
}

.removeUserTower {
  /* background-color: #6d4b6d; */
  border-radius: 5px;
  font-size: 0.85em;
  font-weight: 500;
  text-align: left;
  max-width: 66px;
  padding-left: 3px;
  /* padding-top: 4px;
  padding-bottom: 4px; */
  /* border: solid 2px #382a57; */
  color: #c7a2c7;
  cursor: pointer;
  min-height: 20px;
  max-height: 20px;
  line-height: 20px;
}

.sureRemoveUserTower {
  color: #ba0000;
}

.userNameFilterDiv {
  /* background-color: orange; */
}

.userNameSelctFilter {
  min-width: 100%;
  background-position-x: 140px;
  color: white;
  padding-left: 10px;
  font-size: 0.8em;
  font-weight: 500;
}

.permissionsBox {
  font-weight: 500;
  font-size: 0.8em;
  background-color: #7895b6;
  border: 2px solid #4e6580;
  color: white;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border-radius: 10px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.userCreatedBox {
  font-weight: 500;
  font-size: 0.8em;
  background-color: #73a9d0;
  border: 2px solid #4e6580;
  color: white;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border-radius: 10px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.userCreatedSpan {
  font-size: 0.8em;
}

.towerUserBox1NameTxt {
  font-size: 1em;
  font-weight: 500;
  padding-left: 2px;
}

.towerUserUnitTitle {
  font-size: 0.85em;
  font-weight: 500;
}

.hourlyRateBox {
  min-width: 140px;
  justify-content: flex-start;
  background-color: #eaf1fc;
}

.mpwTimelineBox {
  min-width: 140px;
  justify-content: flex-start;
  background-color: #f5eafc;
}

.towerHourlyRateTimelineDiv {
  /* background-color: blue; */
  max-height: 110px;
  font-size: 0.9em;
  font-weight: 500;
  overflow: scroll;
  border-radius: 5px;
  border: 1px solid #95a7b8;
  margin-top: 4px;
}

.hourlyRateTimelineLeftSection {
  color: #4e6580;
  font-size: 0.9em;
}

.towerHourlyRateObj {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid #d2d5e9;
  /* border-radius: 5px; */
  padding-right: 5px;
  /* margin-top: 2px; */
  padding-left: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: white;
}

.towerHourlyRateObj:first-child {
  border-top: none;
}

.towerUserHourlyRateDiv {
  display: flex;
  justify-content: space-between;
  padding-left: 4px;
  padding-right: 4px;
}

.rateOverlapWarn {
  background-color: #ffb7b7;
}

.towerCurrRate {
  font-size: 0.9em;
  margin-top: -2px;
}

.towerWorkingPatternSpan {
  font-size: 0.7em;
  font-weight: 500;
  background-color: #0077ff;
  color: white;
  border-radius: 3px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 3px;
  padding-right: 3px;
  margin-bottom: 2px;
}

.tHrRate {
  font-size: 0.8em;
  background-color: #7895b6;
  color: white;
  border-radius: 7px;
  padding: 4px;
}

.towerUserSplitter {
  min-height: 3px;
  min-width: 100%;
  background-color: #c4c8e6;
}

.jamBatchUnit {
  max-width: 220px;
  background-color: #1a293a;
  min-width: 200px;
}

.batchUnitDropUnit1 {
  background-color: ;
}

.batchUnitDropDownUnitSplitter {
  min-width: 2px;
  background-color: #143051;
  min-height: 80px;
  max-height: 80px;
  margin-left: 4px;
  margin-right: 4px;
}

.runBatchBtn {
  background-color: #0077ff;
  color: white;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #143051;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 500;
  cursor: pointer;
  font-size: 0.9em;
}

.none {
  display: none;
}

.idleBatchLight {
  background-color: #537949;
  border-radius: 10px;
  padding-left: 2px;
  padding-right: 2px;
  margin-right: 8px;
}

.jamActiveLight {
  background-color: #fff;
  border-radius: 10px;
  padding-left: 2px;
  padding-right: 2px;
  margin-right: 8px;
  opacity: 1;
  -webkit-animation: blinker 0.5s linear infinite;
          animation: blinker 0.5s linear infinite;
}

@-webkit-keyframes blinkerJam {
  50% {
    opacity: 0.9;
    border-color: #fff;
  }
}

@keyframes blinkerJam {
  50% {
    opacity: 0.9;
    border-color: #fff;
  }
}
.jamBlueBg {
  background-color: #0077ff;
  border-color: #2a3e55;
  -webkit-animation: blinkerJam 0.5s linear infinite;
          animation: blinkerJam 0.5s linear infinite;
}

.powerMonitorButton {
  min-width: 50px;
  max-width: 50px;
  border-radius: 5px;
  /* display: flex; */
  background-color: #1a293a;
  padding-top: 4px;
  align-items: center;
  justify-content: center;
  max-height: 50px;
  min-height: 50px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 4px;
  cursor: pointer;
}

.powerBtnTxt {
  color: white;
  text-align: center;
  font-size: 0.5em;
  font-weight: 500;
}

.towerPowerBtnImg {
  min-width: 30px;
}
@-webkit-keyframes blinkerJamPower {
  50% {
    opacity: 0.5;
    background-color: #5a8d64;
  }
}
@keyframes blinkerJamPower {
  50% {
    opacity: 0.5;
    background-color: #5a8d64;
  }
}
.activePowerBtnImg {
  background-color: #5a8d64;
  -webkit-animation: blinkerJamPower 0.5s linear infinite;
          animation: blinkerJamPower 0.5s linear infinite;
}

@-webkit-keyframes blinkThrottle {
  50% {
    opacity: 0.5;
    background-color: #ba0000;
  }
}

@keyframes blinkThrottle {
  50% {
    opacity: 0.5;
    background-color: #ba0000;
  }
}
.throttlingIcon {
  background-color: #ba0000;
  -webkit-animation: blinkThrottle 0.5s linear infinite;
          animation: blinkThrottle 0.5s linear infinite;

  max-width: 100px;
  display: flex;
  align-items: center;
  font-size: 0.8em;
  font-weight: 500;
  max-height: 50px;
  border-radius: 5px;
  margin-top: 10px;
  margin-left: 3px;
  margin-right: 3px;
}

.fadeRunMidnightBtn {
  opacity: 0.3;
  pointer-events: none;
}

